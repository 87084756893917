import React, { useContext } from 'react';
import { Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Tab } from 'components/lib';
import { InputField, PAGE_HEADER_PADDING, PageHeader } from '../../common';
import { CommunitiesContext } from '../../common/communities/CommunitiesContext';
import { CommunityAssetsContext } from '../CommunityAssetsContext';

import { Links } from '../../chats/RelatedCommunities/Links';

export const UserCommunityVideos = () => {
  const { state } = useContext(CommunitiesContext);
  const { onUpdateAssets } = useContext(CommunityAssetsContext);
  const { activeCommunity } = state;

  const onSubmit = (values) => {
    onUpdateAssets(values);
  };

  const VALIDATION_SCHEMA = Yup.object({
    videos_url: Yup.string().url().nullable(),
    videos: Yup.array().of(Yup.string()),
  });

  const initialValues = { videos: activeCommunity?.media?.videos, videos_url: activeCommunity?.media?.videos_url };

  return (
    <Tab.Pane padding="30px">
      <Formik initialValues={initialValues} validationSchema={VALIDATION_SCHEMA} enableReinitialize onSubmit={onSubmit}>
        {({ dirty, isSubmitting, handleSubmit }) => (
          <Form>
            <PageHeader
              title="Edit Videos Links"
              subtitle="Preview and manage video links and fallback webpage address"
              padding={PAGE_HEADER_PADDING.VERTICAL}
              actions={
                <Button
                  primary
                  type="button"
                  disabled={!dirty}
                  loading={isSubmitting}
                  content="Save"
                  onClick={handleSubmit}
                />
              }
            />
            <InputField name="videos_url" label="Videos Fallback URL" placeholder="Webpage URL" />
            <Form.Field>
              <label>Videos</label>
              <Links name="videos" />
            </Form.Field>
          </Form>
        )}
      </Formik>
    </Tab.Pane>
  );
};
