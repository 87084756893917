import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CommunitiesContext } from '../../common/communities/CommunitiesContext';
import { STATUSES } from '../../../constants';
import { PageHeader } from '../../common';
import { CareTypesContextProvider } from './CareTypesContext';
import { CommunityCareTypesForm } from './CommunityCareTypesForm';

const CareTypesWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  padding: 2rem;
`;

export const CommunityCareTypes = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { communityId } = useParams();
  const { state } = useContext(CommunitiesContext);
  const { assignedCommunities, status } = state;

  if (status !== STATUSES.LOADED) {
    return <Loader active inline="centered" size="large" />;
  }

  const community = assignedCommunities.find((c) => c.id === parseInt(communityId));

  if (!community) {
    history.push(`/settings/care-types`);
    toast.error(`Unable to fetch ${t('community')} details!`);
  }

  return (
    <CareTypesContextProvider>
      <CareTypesWrapper>
        <PageHeader title="Care Types" subtitle={`Specify ${t('community')} amenities and offers`} block />
        <CommunityCareTypesForm />
      </CareTypesWrapper>
    </CareTypesContextProvider>
  );
};
