import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { createNewFloorPlan, updateFloorPlan } from 'apis';
import UserCommunityFloorPlanForm from './UserCommunityFloorPlanForm';

const UserCommunityFloorPlanModal = ({ open, editableFloorPlan, onClose }) => {
  const { communityId } = useParams();
  const [updateLoading, setUpdateLoading] = useState(false);

  const populate_floor_plan_image = (value, formData) => {
    const FLOOR_PLAN_IMAGE_FORM_FIELD = 'floor_plan_image';

    if (value) {
      const blob = new Blob([value], { type: value.type });
      formData.append(FLOOR_PLAN_IMAGE_FORM_FIELD, blob, value.name);
    } else if (value === '') {
      // Indicates image deletion
      formData.append(FLOOR_PLAN_IMAGE_FORM_FIELD, '');
    }
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.entries(data).forEach((entry) => {
      const [key, value] = entry;

      if (key === 'floor_plan_url') {
        return;
      }

      if (key === 'floorPlanImage') {
        populate_floor_plan_image(value, formData);
        return;
      }

      if (value) {
        if (key === 'care_type') {
          value.forEach((e) => {
            formData.append('care_type', e);
          });
        } else {
          formData.append(key, value);
        }
      } else if (value === '') {
        formData.append(key, '');
      }
    });

    try {
      setUpdateLoading(true);
      if (data.id) {
        await updateFloorPlan(communityId, data.id, formData);
        toast.success('Floor plan edited successfully!');
      } else {
        await createNewFloorPlan(communityId, formData);
        toast.success('Floor plan added successfully!');
      }
    } catch (error) {
      const errorText = data.id ? 'Trouble updating floor plan!' : 'Trouble adding new floor plan!';
      const msg = error?.response?.data?.reason ? error.response.data.reason : errorText;
      console.error(msg, error?.response);
      toast.error(msg);
    } finally {
      onClose();
      setUpdateLoading(false);
    }
  };

  return (
    <Modal open={open}>
      <Modal.Header>{editableFloorPlan.id ? 'Edit' : 'Create new'} floor plan entry</Modal.Header>
      <Modal.Content>
        <UserCommunityFloorPlanForm
          onSubmit={onSubmit}
          onCancel={onClose}
          loading={updateLoading}
          editableFloorPlan={editableFloorPlan}
        />
      </Modal.Content>
    </Modal>
  );
};

export default UserCommunityFloorPlanModal;
