import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  font-family: var(--font-family);
  color: var(--light-text);
  padding: 50px;
`;

export const PanelNoContent = () => {
  return (
    <Wrapper>
      <span>There is nothing here.</span>
    </Wrapper>
  );
};
