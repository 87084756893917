import React from 'react';
import styled from 'styled-components';
import { add, eachHourOfInterval, format, isSameDay, startOfToday, toDate } from 'date-fns';

import { Arrow } from './icons';
import { Module } from './Module';
import { Message } from './Message';

class CalendarDay extends React.Component {
  render() {
    const { firstLineFormat, secondLineFormat, day, selected } = this.props;
    const name = format(day, firstLineFormat || 'EEE');
    const date = format(day, secondLineFormat || 'dd');
    return (
      <DayOuter className={selected ? 'selected' : 'day-option'}>
        <DayInner className={selected ? 'selected' : 'day-option'}>
          <strong>{date}</strong>
          <div>{name}</div>
        </DayInner>
      </DayOuter>
    );
  }
}

class ChatCalendar extends React.Component {
  constructor() {
    super();
    const startDay = new Date();
    const selectedDay = toDate(startDay);

    this.state = {
      daysCount: 4,
      selectedDay,
      startDay,
      selectedTime: null,
    };
  }

  checkSelectedDay = (day) => {
    const { selectedDay } = this.state;
    return isSameDay(selectedDay, day);
  };

  getMonthAndYear = () => {
    const { selectedDay, startDay } = this.state;
    const date = selectedDay || startDay;
    return format(date, 'MMMM yyyy');
  };

  renderDays(weekdays) {
    const { todayText, unavailableText } = this.props;
    const dayComps = weekdays.map((day) => (
      <CalendarDay
        todayText={todayText}
        unavailableText={unavailableText}
        day={day}
        key={format(day, 'yyyy-MM-dd')}
        selected={this.checkSelectedDay(day)}
      />
    ));
    return <DaysContainer>{dayComps}</DaysContainer>;
  }

  /**
   * Render time slots.
   */
  renderTimes(times) {
    const timeComps = times.map((time) => {
      const renderedTime = format(time, 'h:mm a');

      return (
        <Time key={format(time, 'hh')} className="time-option">
          {renderedTime}
        </Time>
      );
    });
    return <TimeContainer className="time">{timeComps}</TimeContainer>;
  }

  /**
   * Get all possible time slots - from 9 a.m. to 4 p.m.
   */
  getAllTimeSlots = () => {
    const timeSlotsStart = add(startOfToday(), { hours: 9 }); // 9 a.m.

    const result = eachHourOfInterval({
      start: timeSlotsStart,
      end: add(timeSlotsStart, { hours: 7 }), // add 7 hours
    });
    return result;
  };

  render() {
    const { selectedDay, startDay, daysCount, selectedTime } = this.state;

    const weekdays = [];
    for (let index = 0; weekdays.length < daysCount; index += 1) {
      const day = add(toDate(startDay), { days: index });
      weekdays.push(day);
    }

    const startTimes = this.getAllTimeSlots();
    const days = this.renderDays(weekdays);
    const times = this.renderTimes(startTimes);
    const disabled = selectedDay && selectedTime ? '' : 'disabled';

    const timezoneSuffix = '';
    return (
      <Container>
        <Slider>
          <Nav>
            <h3>{this.getMonthAndYear()}</h3>
          </Nav>
          <Arrow className="arrow left" fillColor="#2185d0" />
          <Arrow className="arrow right" fillColor="#2185d0" />

          {days}
          <h4>Select Date and Time {timezoneSuffix}</h4>
          {times}

          <ConfirmButton className={`${disabled} calendar-confirm`}>Next</ConfirmButton>
        </Slider>
      </Container>
    );
  }
}

export const Calendar = ({ node }) => {
  const { module } = node;
  const { text } = module;

  return (
    <Module node={node}>
      {text && <Message>{text}</Message>}
      <ChatCalendar />
    </Module>
  );
};

const ConfirmButton = styled.div`
  background: #2185d0;
  border-radius: 6px;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  margin: 10px 0 0;
  color: #fff;

  &.disabled {
    background: #eaeff6;
    color: #2185d0;
  }
`;

const Time = styled.div`
  font-style: normal;
  text-align: center;
  letter-spacing: 0.28px;
  color: #595d61;
  width: 33%;
  margin: 5px 0;
  padding: 5px 0;
  border-radius: 30px;
  font-size: 14px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &.selected {
    background: #2185d0;
    color: #fff;
  }
  &.unavailable {
    cursor: not-allowed;
  }
`;

const DaysContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 28px;
  border-top: 1px solid rgb(34, 59, 117, 0.12);
  border-bottom: 1px solid rgb(34, 59, 117, 0.12);
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.time {
    flex-wrap: wrap;
    justify-content: left;
    align-items: left;
  }
`;

const Slider = styled.div`
  mix-blend-mode: normal;

  h4 {
    margin: 5px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    text-align: left;
    padding-left: 10px;
    letter-spacing: 0.245px;
    color: #6d7a84;
  }

  .arrow {
    position: absolute;
    top: 70px;

    border-radius: 50%;
    z-index: 1;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow.right {
    transform: rotate(180deg);
    right: 9px;
  }

  .arrow.left {
    left: 9px;
  }
`;

const Nav = styled.div`
  position: relative;

  h3 {
    margin: 0;
    padding: 10px 0;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.28px;
  }
`;

const Container = styled.div`
  display: block;
  position: relative;
  padding: 10px 10px;
  max-width: 100%;
  min-width: 310px;
  margin-top: 2.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 9px rgba(217, 217, 217, 0.5);
  border-radius: 11px;
  user-select: none;

  * {
    user-select: none;
  }
`;

const DayInner = styled.div`
  font-family: Arial;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.28px;
  color: #595d61;
  padding: 10px 15px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    font-size: 12px;
  }

  strong {
    font-size: 16px;
  }

  &.selected {
    box-shadow: 0px 5px 5px #c5c5c599;
    background: #2185d0;
    color: #fff;
  }

  &.unavailable {
    border-color: #a8a8a8;
    color: #a8a8a8;
  }

  &.today:not(.selected) {
    color: #2185d0;
  }
`;

const DayOuter = styled.div`
  border: none;
  margin: 10px 0px;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &:first-of-type:not(.selected):after,
  &:last-of-type:not(.selected):after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &:first-of-type:not(.selected):after {
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0.01));
  }

  &:last-of-type:not(.selected):after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.01), white);
  }
`;
