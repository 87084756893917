import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { Modal, Icon, TextArea, Form, Button } from 'components/lib';
import { InputField } from 'components/common';
import { ActionButton } from './ActionButton';

const Content = styled.div`
  padding: 20px 30px;
  height: 248px;
`;

const StyledModal = styled(Modal)`
  &.ui.large.modal {
    width: 570px;
    height: 500px;
    background-image: linear-gradient(334deg, #333059 97%, #444077 18%);
    .header,
    .content,
    .actions {
      background-color: transparent;
    }
    .header {
      display: flex;
      justify-content: space-between;
      height: 110px;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 97px;
  &&& {
    .icon {
      height: auto;
      opacity: 1;
    }
  }
`;

const PhoneNumber = styled.div`
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
`;

const Name = styled.span`
  font-weight: 500;
`;

const HeaderPhoneData = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

const Duration = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  display: flex;
  flex-direction: column;
`;

const CommunityName = styled.span`
  color: var(--60-white);
`;

const HeaderIcons = styled.div`
  display: flex;
  .icon {
    cursor: pointer;
    margin-left: 10px;
  }
`;

const Divider = styled.div`
  width: 570px;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(250, 250, 250, 0) 0%,
    rgba(255, 255, 255, 0.25) 50%,
    rgba(250, 250, 250, 0)
  );
`;

const CustomTextArea = styled(InputField)`
  &&&& {
    textarea {
      width: 100%;
      border: none;
      background-color: transparent;
      outline: none;
      font-family: var(--font-family);
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: normal;
      color: var(--60-white);
      padding: 0;
      height: 210px;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;

const FooterText = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--60-white);
`;

const initialValues = {
  note: '',
};

export const CallCenterModal = ({ modules, open, onClose, onMinimize, ...props }) => {
  return (
    <StyledModal open size="large">
      <Modal.Header>
        <HeaderPhoneData>
          <PhoneNumber>
            (202) 555-0166 · <Name>Adam Smith</Name>
          </PhoneNumber>
          <Duration>
            00:26
            <CommunityName>Community Name</CommunityName>
          </Duration>
        </HeaderPhoneData>
        <HeaderIcons>
          <Icon name="remove" size="24" color="#89919d" onClick={onMinimize} />
          <Icon name="close" size="24" color="#89919d" />
        </HeaderIcons>
      </Modal.Header>
      <Modal.Content>
        <Divider />
        <Content>
          <Formik enableReinitialize validateOnMount initialValues={initialValues}>
            {() => (
              <Form>
                <CustomTextArea name="note" control={TextArea} placeholder="Enter a note here..." />
              </Form>
            )}
          </Formik>
        </Content>
        <Divider />
      </Modal.Content>
      <Modal.Actions>
        <Footer>
          <ButtonsWrapper>
            <ActionButton iconName="mic_off" text="Mute" />
            <ActionButton iconName="pause" text="Hold" />
            <ActionButton iconName="radio_button_checked" iconActiveColor="#da2726" text="Stop Recording" />
            <ActionButton iconName="swap_horiz" text="Transfer" />
          </ButtonsWrapper>
          <ActionWrapper>
            <FooterText>Taking this call via (202) 310-1270.</FooterText>
            <Button primary color="red">
              <Icon name="call_end" color="#fff" />
              End Call
            </Button>
          </ActionWrapper>
        </Footer>
      </Modal.Actions>
    </StyledModal>
  );
};
