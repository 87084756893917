import React from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

import { Grid, Dimmer, Loader } from 'components/lib';
import { MessageBox } from '../common';
import { TableContentRow } from './TableContentRow';
import { TableHeaderRow } from './TableHeaderRow';

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledGrid = styled(Grid)`
  &&&& {
    gap: 10px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;

    ${({ scrollable }) =>
      scrollable &&
      `
      min-height: 30px;
      max-height: 190px;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 5px;
      }`}

    .row {
      padding-left: 5px;
      padding-right: 0px;
      color: var(--text);
      font-size: 12px;
      height: 28px !important;
      max-height: 28px;

      .column {
        padding: 0;
        padding-left: 5px;
        height: 100%;
        &: first-of-type {
          padding-left: 0px;
        }
        &: last-of-type {
          padding-right: 5px;
        }
      }
    }

    .row.info-box {
      height: 20px;
      max-height: 20px;
      padding-left: 0px;
    }

    .no-padding {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      .column {
        padding: 0;
        padding-left: 5px;
      }
    }
    .header {
      font-size: 14px;
      font-weight: 900;
      height: 14px !important;
    }
  }
`;

export const SalesInfoForm = ({ isLoading, data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader active inverted />
      </Dimmer>
      <MessageBox>
        Contact info is missing for some of the targeted {t('communities')}. Please enter it below.
      </MessageBox>
      <ContentBox>
        <StyledGrid>
          <TableHeaderRow />
        </StyledGrid>
        <StyledGrid scrollable={1}>
          <FieldArray>
            {() =>
              data.map((community, index) => <TableContentRow key={community.id} community={community} index={index} />)
            }
          </FieldArray>
        </StyledGrid>
      </ContentBox>
    </>
  );
};
