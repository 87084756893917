import React from 'react';
import { Label } from 'semantic-ui-react';

import { Module } from './Module';
import { Code } from './Code';
import { Answers } from './Answers';

export const Eval = ({ node }) => {
  const { module } = node;
  const { text } = module;

  return (
    <Module node={node}>
      <Label color="black">Evaluate Custom JavaScript</Label>
      <Code>{text}</Code>
      <Answers node={node} />
    </Module>
  );
};
