import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Label, Button, Modal, Checkbox, Popup } from 'semantic-ui-react';

import { Icon } from 'components/lib';
import { ChatModulesContext } from './ChatModulesContext';
import { If } from '../../If';

export const Wrapper = styled.div`
  position: relative;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;

  .ui.label {
    background-color: #eaeff7;
    margin: 0;
  }

  .ui.label + q,
  .ui.label + span {
    font-size: 1.2rem;
    color: #444;
    display: block;
    padding-left: 1rem;
    line-height: 1.7;
  }
  .ui.form {
    flex: 1;
    margin-left: 1rem;

    textarea {
      font-family: 'Courier New', Courier, monospace;
      font-size: 1.1rem;
    }
  }
`;

const ScrollAnchor = styled.div`
  position: absolute;
  top: -5rem;
  left: 0;
  width: 0;
  height: 0;
`;

export const MarkButtonWrapper = styled.div`
  &:not(.selected) {
    visibility: hidden;
  }
`;

const ModuleInfo = styled(Label)`
  &&&&& {
    margin: auto;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  gap: 10px;

  .icon {
    cursor: default;
  }
`;

export const ModuleHeader = ({ node, nextNode, onEdit, onDelete }) => {
  const { moveUp, moveDown, previewMode } = useContext(ChatModulesContext);

  const { module, reachable, parentNode } = node;
  const { number } = module;
  const [confirmDelete, setConfirmDelete] = useState({
    showDialog: false,
    deleteRelated: false,
  });

  const controlsEnabled = !previewMode;
  const moveDownAvailable = controlsEnabled && node.isFirstStep() && nextNode?.isRegularStep();
  const moveAvailable = controlsEnabled && node.isRegularStep() && !nextNode?.isBranchingPoint();
  const moveUpAvailable =
    controlsEnabled && ((node.isLastStep() && parentNode?.isRegularStep()) || nextNode?.isBranchingPoint());

  return (
    <>
      <Wrapper>
        <ScrollAnchor id={`module-${number}`} />
        <HeaderWrapper>
          <ModuleInfo color={reachable ? 'grey' : 'red'}>
            {!reachable && 'UNREACHABLE:'} MODULE {number}
          </ModuleInfo>
          <If condition={module?.has_module_recordings}>
            <Popup
              hoverable
              content="Some communities have custom module recordings."
              position="bottom right"
              trigger={
                <div>
                  <Icon name="record_voice_over" variant="outlined" size="24" />
                </div>
              }
            />
          </If>
        </HeaderWrapper>
        <Button.Group size="tiny">
          <If condition={moveDownAvailable}>
            <Button
              basic
              icon="chevron down"
              onClick={() => {
                moveDown(node);
              }}
            />
          </If>
          <If condition={moveAvailable}>
            <>
              <Button
                basic
                icon="chevron up"
                onClick={() => {
                  moveUp(node);
                }}
              />
              <Button
                basic
                icon="chevron down"
                onClick={() => {
                  moveDown(node);
                }}
              />
            </>
          </If>
          <If condition={moveUpAvailable}>
            <Button
              basic
              icon="chevron up"
              onClick={() => {
                moveUp(node);
              }}
            />
          </If>
          <If condition={controlsEnabled}>
            <Button
              basic
              icon="edit"
              onClick={() => {
                if (typeof onEdit === 'function') {
                  onEdit();
                }
              }}
            />
          </If>
          <If condition={controlsEnabled}>
            <Button
              basic
              icon="trash alternate outline"
              onClick={() => {
                setConfirmDelete({ showDialog: true, deleteRelated: false });
              }}
            />
          </If>
        </Button.Group>
      </Wrapper>

      <Modal size="mini" open={confirmDelete.showDialog}>
        <Modal.Content>Are you sure you wish to delete module {number}?</Modal.Content>
        {reachable && (
          <Modal.Actions style={{ textAlign: 'left' }}>
            <Checkbox
              label="Also delete related modules"
              checked={confirmDelete.deleteRelated}
              onChange={() => {
                setConfirmDelete({ ...confirmDelete, deleteRelated: !confirmDelete.deleteRelated });
              }}
            />
          </Modal.Actions>
        )}
        <Modal.Actions>
          <Button
            onClick={() => {
              setConfirmDelete({ showDialog: false, deleteRelated: false });
            }}
          >
            Cancel
          </Button>
          <Button
            color="red"
            onClick={() => {
              setConfirmDelete({ showDialog: false, deleteRelated: false });
              if (typeof onDelete === 'function') {
                onDelete(confirmDelete.deleteRelated);
              }
            }}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
