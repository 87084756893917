import React, { useContext } from 'react';

import { CalendarAvailability } from './CalendarAvailability';
import { MessageContext } from '../MessageContext';
import { CalendarRequestInfo } from './CalendarRequestInfo';
import { CALENDAR_REQUEST_TYPE } from './constants';
import { CalendarRequestContext } from './CalendarRequests';

export const CalendarRequest = ({ type }) => {
  const { lead } = useContext(MessageContext);

  const isTourRequest = lead.tour && type === CALENDAR_REQUEST_TYPE.TOUR;
  const isCallRequest = lead.call && type === CALENDAR_REQUEST_TYPE.CALL;

  const hasRequest = isTourRequest || isCallRequest;

  return (
    <CalendarRequestContext.Provider value={{ type }}>
      {hasRequest ? <CalendarRequestInfo /> : <CalendarAvailability type={type} />}
    </CalendarRequestContext.Provider>
  );
};
