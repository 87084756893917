import React from 'react';
import styled from 'styled-components';

const TableHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props?.nubmerOfHeaders}, 1fr)`};
  grid-template-columns: ${(props) => `${props?.headersWidths.map((width) => width).join(' ')};`};
  grid-column-gap: ${(props) => props?.columnGap};

  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 1;
  padding: 15px;
`;

const TableHeader = styled.div`
  font-family: var(--font-family-bold);
  font-size: 12px;
  letter-spacing: 0.5px;
  color: var(--text);
  text-transform: uppercase;
`;

export const SettingsTableHeaders = ({ headers, headersWidths, columnGap = '0' }) => {
  const nubmerOfHeaders = headers.length;
  return (
    <TableHeaderContainer nubmerOfHeaders={nubmerOfHeaders} headersWidths={headersWidths} columnGap={columnGap}>
      {headers.map((header, index) => {
        return <TableHeader key={index}>{header}</TableHeader>;
      })}
    </TableHeaderContainer>
  );
};
