import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TabsWithNavigation } from 'components/lib';
import { OutbandCalls } from './OutbandCalls';
import { CallRecordingWhisper } from './CallRecordingWhisper';
import { PreCallSms } from './PreCallSms';

const OUTBOUND_CALLS_TABS = [
  {
    tabName: 'Outbound Calls',
    path: '/advanced/outbound-calls/calls',
    Content: OutbandCalls,
  },
  {
    tabName: 'Call Recording & Whisper',
    path: '/advanced/outbound-calls/call-recording-whisper',
    Content: CallRecordingWhisper,
  },
  {
    tabName: 'Pre-Call SMS',
    path: '/advanced/outbound-calls/pre-call-sms',
    Content: PreCallSms,
  },
];

const INITIAL_TAB = OUTBOUND_CALLS_TABS[0].path;

export const OutbandCallsRoot = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/advanced/outbound-calls') {
      history.replace(INITIAL_TAB);
    }
  }, []);

  return <TabsWithNavigation tabs={OUTBOUND_CALLS_TABS} />;
};
