import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Input } from 'components/lib';
import LeadMessagesNotificationModal from 'components/common/LeadMessagesNotificationModal';
import LeadUserReminderDropdown from 'components/common/LeadUserReminderDropdown';
import InputMaxLength from 'components/common/InputMaxLength';
import { DEFAULT_LEAD_USER_REMINDER, RESPONSE_STEP } from './constants';
import { CONVERSATION_TYPE, MAX_MESSAGE_LENGTH, STATUSES } from '../../../constants';
import { MessageContext } from './MessageContext';
import { ConversationResponseBlocks } from './ConversationResponseBlocks';
import { ConversationResponseTextInput } from './ConversationResponseTextInput';

const MessageActions = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2rem;

  span {
    margin-right: 10px;
  }

  .spacer {
    flex: 1;
  }

  .limit {
    margin-right: 10px;
  }
`;

export const ConversationResponse = () => {
  const { type: messageType, messageId } = useParams();
  const {
    step,
    setStep,
    status,
    messages,
    sendMessage,
    messageStatus,
    content,
    fullContent,
    openingLine,
    setOpeningLine,
    leadReminder,
    checkForNewMessages,
    getSMSMessages,
    getEmailMessages,
    reminderStatus,
    getAutomatedAnswer,
    openingAnswerLabels,
    closingAnswerLabels,
  } = useContext(MessageContext);
  const [openSetReminder, setOpenSetReminder] = useState(false);
  const [replyReminder, setReplyReminder] = useState(DEFAULT_LEAD_USER_REMINDER);
  const [openConfirmSend, setOpenConfirmSend] = useState(false);

  useEffect(() => {
    if (leadReminder) {
      setReplyReminder(leadReminder);
    }
  }, [leadReminder]);

  useEffect(() => {
    if (messages) {
      getAutomatedAnswer(messageType);
    }
  }, [status, messages]);

  const handleSetLeadUserReminder = async ({ scheduleType, reminderDate = null }) => {
    const data = {
      schedule_type: scheduleType,
      scheduled_at: reminderDate || new Date(),
    };

    setReplyReminder(data);
    setOpenSetReminder(false);
  };

  const onOpeningLineChange = (e) => setOpeningLine(e.target.value);

  const handleRemoveLeadUserReminder = async () => {
    setReplyReminder(DEFAULT_LEAD_USER_REMINDER);
    setOpenSetReminder(false);
  };

  const handleCloseMessagesModal = () => {
    if (messageType === CONVERSATION_TYPE.SMS) {
      getSMSMessages();
    } else {
      getEmailMessages();
    }
    setOpenConfirmSend(false);
  };

  const handleSendMessage = async () => {
    const latestMessage = messages?.length ? messages[messages.length - 1] : null;
    let hasNewMessages;

    if (latestMessage) {
      hasNewMessages = await checkForNewMessages({ id: latestMessage.id, messageType });

      if (hasNewMessages === undefined) return;
    }

    if (hasNewMessages) {
      setOpenConfirmSend(true);
    } else {
      sendMessage(messageType, messageId, replyReminder);
    }
  };

  return (
    <>
      <Input
        list="openings"
        fluid
        placeholder="Choose or enter opening line"
        value={openingLine}
        onChange={onOpeningLineChange}
      />
      <datalist id="openings">
        {openingAnswerLabels?.map((l, i) => (
          <option value={l.text} key={i} />
        ))}
      </datalist>
      <If condition={step === RESPONSE_STEP.BLOCKS}>
        <ConversationResponseBlocks />
      </If>
      <If condition={step === RESPONSE_STEP.TEXT}>
        <ConversationResponseTextInput />
      </If>
      <If condition={step === RESPONSE_STEP.BLOCKS}>
        <Button primary fluid content="Edit" icon="edit" compact onClick={() => setStep(RESPONSE_STEP.TEXT)} />
      </If>
      <If condition={step === RESPONSE_STEP.TEXT}>
        <MessageActions>
          <span>Reminder:</span>
          <LeadUserReminderDropdown
            open={openSetReminder}
            loading={reminderStatus === STATUSES.LOADING}
            leadUserReminder={replyReminder}
            removeMessage="Reply without reminder"
            onSelect={handleSetLeadUserReminder}
            onRemove={handleRemoveLeadUserReminder}
          />
          <div className="spacer">&nbsp;</div>
          <InputMaxLength className="limit" value={fullContent} limit={MAX_MESSAGE_LENGTH} />
          <Button
            content="Send"
            primary
            disabled={messageStatus === STATUSES.LOADING || !content.length}
            loading={messageStatus === STATUSES.LOADING}
            onClick={handleSendMessage}
          />
        </MessageActions>
      </If>
      <LeadMessagesNotificationModal open={openConfirmSend} onClickHandle={handleCloseMessagesModal} />
    </>
  );
};
