import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { InputField } from 'components/common';
import { parseErrorResponse, parseFieldErrors } from 'apis/utils';
import { createAttribute, getActions, getAttributeTypes } from 'apis';
import { addAttribute, useQuestionReviews } from './context';
import { STATUSES } from '../../../constants';

const AttributeCreate = ({ open, onClose }) => {
  const [, dispatch] = useQuestionReviews();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [actionOptions, setActionOptions] = useState([]);
  const [belongsToTypeOptions, setBelongsToTypeOptions] = useState([]);

  const initialValues = {
    name: '',
    label: '',
    action: undefined,
    belongs_to_type: [],
  };
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    label: Yup.string().required('Label is required'),
    action: Yup.number(),
    belongs_to_type: Yup.array().required('Type is required'),
  });

  useEffect(() => {
    const fetchFormOptions = async () => {
      try {
        const [actions, belongsToType] = await Promise.all([getActions(), getAttributeTypes()]);

        setActionOptions(
          actions.map((a) => ({
            key: a.id,
            value: a.id,
            text: a.name,
          }))
        );

        setBelongsToTypeOptions(
          belongsToType.map((b) => ({
            key: b.id,
            value: b.id,
            text: b.name,
          }))
        );
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch form options');
      }
    };

    fetchFormOptions();
  }, []);

  const handleCreateAttribute = async (values, { setErrors }) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const { id, name } = await createAttribute(values);

      dispatch(addAttribute(id, name));
      toast.success(`Label ${name} created successfully`);
      setLoaderStatus(STATUSES.LOADED);
      onClose();
    } catch (e) {
      setErrors(parseFieldErrors(e));
      toast.error(parseErrorResponse(e, 'Unable to create label.'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleCreateAttribute}>
        {({ handleSubmit }) => (
          <>
            <Modal.Header>Add new label</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Field>
                  <InputField type="text" label="Name" name="name" placeholder="Name" />
                </Form.Field>
                <Form.Field>
                  <InputField type="text" label="Label" name="label" placeholder="Label" />
                </Form.Field>
                <Form.Field>
                  <InputField
                    type="text"
                    label="Action"
                    name="action"
                    placeholder="Action"
                    control={Dropdown}
                    options={actionOptions}
                    fluid
                    search
                    selection
                    clearable
                  />
                </Form.Field>
                <Form.Field>
                  <InputField
                    type="text"
                    label="Belongs to type"
                    name="belongs_to_type"
                    placeholder="Belongs to type"
                    control={Dropdown}
                    options={belongsToTypeOptions}
                    multiple
                    fluid
                    search
                    selection
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button content="Close" onClick={onClose} disabled={loaderStatus === STATUSES.LOADING} />
              <Button
                primary
                type="submit"
                content="Save"
                onClick={handleSubmit}
                loading={loaderStatus === STATUSES.LOADING}
                disabled={loaderStatus === STATUSES.LOADING}
              />
            </Modal.Actions>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default AttributeCreate;
