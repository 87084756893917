import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { differenceInSeconds } from 'date-fns';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import { forwardLeadConversationMessage } from 'apis';
import { If } from 'components/If';
import { PromptButton } from 'components/common';
import { STATUSES } from '../../../constants';
import { MessageCountdown } from './MessageCountdown';

const MessageSegment = styled(Segment)`
  &.ui.segment {
    margin: 0;
    padding: 0.2rem;
    &.raised {
      padding: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

export const ConversationMessage = ({ message, child, basic = false, type, last }) => {
  const { leadId, messageId } = useParams();
  const containerRef = useRef();
  const [forwardingStatus, setForwardingStatus] = useState(STATUSES.IDLE);
  const [countdownSeconds, setCountdownSeconds] = useState(0);

  const messageFocused = message.id === parseInt(messageId);
  const pendingDelayedMessage = message?.is_delayed && Boolean(countdownSeconds);

  const highlighted = messageFocused || pendingDelayedMessage;

  useEffect(() => {
    if (highlighted || last) {
      setTimeout(
        () => containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' }),
        0
      );
    }
  }, [highlighted, last]);

  useEffect(() => {
    if (last && message?.delayed_message_eta) {
      const lastMessageTimestamp = new Date(message.delayed_message_eta);
      const diff = differenceInSeconds(lastMessageTimestamp, new Date());
      setCountdownSeconds(diff);
    }
  }, [last, message]);

  const onForwardConfirm = async () => {
    setCountdownSeconds(0);
    try {
      setForwardingStatus(STATUSES.LOADING);
      await forwardLeadConversationMessage(leadId, message.id, type);
      setForwardingStatus(STATUSES.LOADED);
    } catch (e) {
      setForwardingStatus(STATUSES.FAILURE);
    }
  };

  // Timeout added to avoid rerendering both this and MessageCountdown component at the same time
  const onTimeElapsed = () => {
    setTimeout(() => {
      setCountdownSeconds(0);
    }, 200);
  };

  return (
    <MessageSegment clearing basic={basic && !highlighted} raised={highlighted}>
      <div ref={containerRef}>
        {child}
        <If condition={countdownSeconds && last}>
          <MessageCountdown countdown={countdownSeconds} onTimeElapsed={onTimeElapsed} />
        </If>
        <If condition={highlighted}>
          <PromptButton
            floated="right"
            icon="upload"
            content="Forward To Community"
            title="Are you sure you want to forward message to community sales team?"
            onConfirm={onForwardConfirm}
            loading={forwardingStatus === STATUSES.LOADING}
          />
        </If>
      </div>
    </MessageSegment>
  );
};
