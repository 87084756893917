import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
  width: 24px;
  height: 16px;
  display: flex;
  padding: 0 6px;
  border-radius: 4px;
  background-color: #ffe0ef;
  font-family: var(--font-family-bold);
  font-size: 12px;
  text-align: center;
  color: #ff5b98;
  line-height: 19px;
`;

export const AiIcon = () => {
  return <IconContainer>AI</IconContainer>;
};
