import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getCommunitiesByUserId, updateCommunitiesContactInfo } from 'apis';
import { updatePricing } from 'api';
import { STATUSES } from '../../constants';

export const SettingsContext = createContext();

export const SettingsContextProvider = (props) => {
  const [communities, setCommunities] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  useEffect(() => {
    fetchCommunities();
  }, []);

  const fetchCommunities = async () => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      const response = await getCommunitiesByUserId({});
      if (response?.results) {
        const communities = response.results.sort((p1, p2) => (p1.name < p2.name ? -1 : 1));
        setCommunities(communities);
      } else {
        toast.warning('Missing results for location communities');
      }

      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error('Error fetching location communities');
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const updateCommunitiesFromContactInfo = async (updatedCommunities) => {
    await updateCommunitiesContactInfo(updatedCommunities);

    const communitiesData = communities.map((community) => {
      const updatedCommunity = updatedCommunities.find((updatedCommunity) => community.id === updatedCommunity.id);

      if (!updatedCommunity) {
        return community;
      }

      return {
        ...community,
        ...updatedCommunity,
      };
    });

    setCommunities(communitiesData);
  };

  const updateCommunityDiscounts = async (updatedPrices) => {
    await updatePricing({ pricing: updatedPrices, starting_costs: [], ending_costs: [] });

    const communitiesData = communities.map((community) => {
      if (community.prices?.length) {
        const { prices } = community;

        prices.forEach((price, index) => {
          const updatedPrice = updatedPrices.find((priceItem) => priceItem.id === price.id);
          if (updatedPrice) {
            prices[index] = {
              ...prices[index],
              ...updatedPrice,
            };
          }
        });
      }

      return community;
    });

    setCommunities(communitiesData);
  };

  const updateCommunityPricings = async ({
    pricing: updatedPrices,
    starting_costs: updatedStartingCosts,
    ending_costs: updatedEndingCosts,
  }) => {
    await updatePricing({
      pricing: updatedPrices,
      starting_costs: updatedStartingCosts,
      ending_costs: updatedEndingCosts,
    });

    const communitiesData = communities.map((community) => {
      const updatedCommunityStartingCost = updatedStartingCosts.find((updatedCost) => updatedCost.id === community.id);
      const updatedCommunityEndingCost = updatedEndingCosts.find((updatedCost) => updatedCost.id === community.id);

      if (community.prices?.length) {
        const { prices } = community;

        prices.forEach((price, index) => {
          const updatedPrice = updatedPrices.find((priceItem) => priceItem.id === price.id);
          if (updatedPrice) {
            prices[index] = {
              ...prices[index],
              ...updatedPrice,
            };
          }
        });
      }

      if (!updatedCommunityStartingCost && !updatedCommunityEndingCost) {
        return community;
      }

      return {
        ...community,
        ...updatedCommunityStartingCost,
        ...updatedCommunityEndingCost,
      };
    });

    setCommunities(communitiesData);
  };

  const provide = {
    communities,
    loaderStatus,
    fetchCommunities,
    setCommunities,
    updateCommunityDiscounts,
    updateCommunityPricings,
    updateCommunitiesFromContactInfo,
  };

  return <SettingsContext.Provider value={provide} {...props} />;
};
