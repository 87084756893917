import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { If } from 'components/If';
import { PromptButton } from 'components/common';
import { Checkbox, Form, Button } from 'components/lib';
import { SearchField } from './SearchField';

const StyledCheckbox = styled(Checkbox)`
  && label {
    font-size: 14px;
    font-weight: 900;
    color: var(--text);
    text-transform: capitalize;

    :before {
      border: 2px solid #777777;
      width: 18px;
      height: 18px;
      top: -3px;
    }

    :after {
      top: -3px;
    }
  }
`;

const MainActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  .row-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const CommunitiesContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  height: 235px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const Error = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: var(--validation-red);
`;
const RowWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 38px;
  .resend {
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
    font-family: var(--font-family-bold);
    line-height: 1.43;
    color: #ffffff;
    min-width: 104px;
    background-color: var(--vivid-blue);
    border-color: var(--vivid-blue);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--hover-blue);
      color: #ffffff;
    }
  }
`;

const validationSchema = Yup.object({
  emailIntegrationIds: Yup.array().required('Please select at least one email integration'),
});

const Content = ({ items, loading, disabled, handleClose }) => {
  const { values, setFieldValue, setFieldTouched, errors, touched, handleSubmit, isValid } = useFormikContext();
  const errorMessage = touched.emailIntegrationIds && errors.emailIntegrationIds;
  const [searchValue, setSearchValue] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  const selectedItems = useMemo(() => {
    if (!values?.emailIntegrationIds || values.emailIntegrationIds.length === 0) return {};

    return values.emailIntegrationIds.reduce((acc, communityId) => ({ ...acc, [communityId]: true }), {});
  }, [values.emailIntegrationIds]);

  const filteredItems = useMemo(() => {
    let newItems = items;

    if (!newItems || newItems.length === 0) {
      return [];
    }

    if (searchValue) {
      newItems = items.filter((community) => community.email?.toLowerCase().includes(searchValue.toLowerCase()));
    }
    return newItems;
  }, [items, selectedItems, searchValue]);

  const changeSearchValue = (value) => {
    setSearchValue(value);
    setFieldTouched('emailIntegrationIds', true);
  };

  const changeSelectAll = (e, d) => {
    setSelectAll(!selectAll);
    setFieldTouched('emailIntegrationIds', true);

    if (!d.checked) {
      setFieldValue('emailIntegrationIds', []);
      return;
    }

    const newItems = filteredItems.filter((community) => !selectedItems[community.id]).map((community) => community.id);

    setFieldValue('emailIntegrationIds', [...values.emailIntegrationIds, ...newItems]);
  };

  const selectItem = (community) => () => {
    setFieldTouched('emailIntegrationIds', true);
    const oldItems = values.emailIntegrationIds;
    if (oldItems.includes(community.id)) {
      const newItems = oldItems?.filter((i) => i !== community.id);
      setFieldValue('emailIntegrationIds', newItems);
      return;
    }

    setFieldValue('emailIntegrationIds', oldItems?.concat(community.id));
  };

  return (
    <>
      <MainActionsContainer>
        <div className="row-item">
          <RowWrapper>
            <StyledCheckbox
              disabled={disabled || loading}
              label={<label>Email addresses</label>}
              onChange={changeSelectAll}
              checked={selectAll}
            />
            <If condition={errorMessage}>
              <Error>{errorMessage}</Error>
            </If>
          </RowWrapper>
        </div>
        <div className="row-item">
          <SearchField placeholder="Search" value={searchValue} onSearch={changeSearchValue} disabled={loading} />
        </div>
      </MainActionsContainer>
      <If condition={filteredItems && filteredItems.length > 0}>
        <CommunitiesContainer>
          {filteredItems.map((item) => (
            <Checkbox
              disabled={disabled || loading}
              key={item.id}
              label={<label>{item.email}</label>}
              onChange={selectItem(item)}
              checked={!!selectedItems[item.id]}
            />
          ))}
        </CommunitiesContainer>
      </If>
      <Footer>
        <PromptButton
          floated="right"
          content="Resend"
          title="Resend Invite?"
          description="Resend invites to a Google or Microsoft domain email address.
          It will be sent a follow-up email invite asking to authenticate."
          onConfirm={handleSubmit}
          loading={loading}
          actionLabel="Resend"
          triggerClassName="resend"
          disabled={!isValid}
        />

        <Button primary onClick={handleClose}>
          Cancel
        </Button>
      </Footer>
    </>
  );
};

export const ResendInvitesModalContent = (props) => {
  const { selectedItem, onSubmit } = props;
  const initialFormValues = {
    emailIntegrationIds: selectedItem?.id ? [selectedItem.id] : [],
  };
  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Content {...props} />
        </Form>
      )}
    </Formik>
  );
};
