import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

const Wrapper = styled.div`
  .field-type {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #777;
    margin: 1rem 0;

    .field-id {
      text-transform: none;
    }
  }

  > div {
    display: flex;
    gap: 1ch;
    margin-bottom: 0.75rem;
    color: #777;
  }

  label {
    color: #777;
    width: 8rem;
  }

  .value {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15rem;
    color: #fa7550;
  }
`;

export const FieldInfo = ({ field, ...props }) => {
  const [formikField, meta, helpers] = useField({ ...props, value: field.name });

  return (
    <Wrapper>
      <div className="field-type">
        {field.tag} · [{field.type}] · [#{field.id}]
      </div>

      <div>
        <label>Name:</label>
        {field.name}
      </div>
      {field.placeholder && (
        <div>
          <label>Placeholder:</label>
          {field.placeholder}
        </div>
      )}

      {field.innerText && (
        <div>
          <label>Inner text:</label>
          {field.innerText}
        </div>
      )}

      <div>
        <label>Value:</label>
        <span className="value">{field.value}</span>
      </div>
    </Wrapper>
  );
};
