import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { pluralize } from 'utils/helpers';
import { STATUSES } from 'constants';
import SearchInput from 'components/SearchInput';
import { Icon, Loader } from 'components/lib';
import { If } from 'components/If';
import { CommunitiesGroupsContext, CommunitiesList } from '.';
import { SettingsContext } from '../SettingsContext';

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
  .input {
    max-width: 400px;
  }
`;

const GroupsListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--line-standard);
  padding: 0 30px 13px;
  h6 {
    width: 50%;
    font-family: var(--font-family-bold);
    font-size: 12px;
    line-height: 1.83;
    letter-spacing: 0.5px;
    color: var(--text);
    margin: 0;
    text-transform: uppercase;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
`;

const ListItem = styled.div`
  border-bottom: 1px solid var(--line-standard);
  display: flex;
  padding: 13px 0;
`;
const ListItemNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 50%;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ListItemName = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  h4 {
    margin: 0;
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--link);
  }
  span {
    font-family: var(--font-family);
    font-size: 12px;
    color: #89919d;
  }
`;

const ListItemCommunitiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
`;

const NoGroups = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  font-family: var(--font-family);
  font-size: 14px;
  color: #89919d;
  flex: 1 0 auto;
  h4 {
    font-family: var(--font-family-bold);
    font-size: 18px;
    color: var(--text);
    margin: 0;
  }
  p {
    max-width: 600px;
    font-family: var(--font-family);
    font-size: 14px;
    text-align: center;
    color: #89919d;
  }
`;

export const CommunitiesGroupsList = () => {
  const { t } = useTranslation();
  const { groups, loaderStatus, search, setSearch, handelModalOpen, setEditingGroup, searchLoading } =
    useContext(CommunitiesGroupsContext);
  const { communities, loaderStatus: communitiesLoader } = useContext(SettingsContext);

  const isLoading = loaderStatus === STATUSES.LOADING || communitiesLoader === STATUSES.LOADING;
  const isSearching = searchLoading === STATUSES.LOADING;
  const hasGroups = groups.length > 0;

  const handleGroupEidt = (id) => {
    setEditingGroup(id);
    handelModalOpen();
  };

  return (
    <>
      <SearchContainer>
        <SearchInput value={search} onSearch={setSearch} placeholder={`Search by group or ${t('community')} name`} />
      </SearchContainer>
      <If condition={!hasGroups && !isLoading && search}>
        <NoGroups>
          <h4>There is nothing here.</h4>
          <p>Try adding some data or changing your search parameters.</p>
        </NoGroups>
      </If>
      <If condition={hasGroups && !isLoading}>
        <GroupsListHeader>
          <h6>GROUP NAME</h6>
          <h6> IN GROUP</h6>
        </GroupsListHeader>
        <List>
          <Loader active={isLoading || isSearching} size="large" />
          <If condition={hasGroups && !isLoading && !isSearching}>
            {groups.map((group, i) => {
              const userCommunities = communities.filter((community) => group.communities.includes(community.id));
              return (
                <ListItem key={i}>
                  <ListItemNameContainer onClick={() => handleGroupEidt(group.id)}>
                    <NameContainer>
                      <Icon name="local_offer" variant="outlined" color={group.color} size="24" />
                      <ListItemName>
                        <h4>{group.name}</h4>
                        <span>{pluralize(userCommunities.length, t('communities'), t('community'))}</span>
                      </ListItemName>
                    </NameContainer>
                  </ListItemNameContainer>
                  <ListItemCommunitiesContainer>
                    <CommunitiesList communities={userCommunities} />
                  </ListItemCommunitiesContainer>
                </ListItem>
              );
            })}
          </If>
        </List>
      </If>
    </>
  );
};
