import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { getBusinessActivities } from 'apis';
import { formatAnyDate } from 'components/utils';
import { parseErrorResponse } from 'apis/utils';
import { getDateTimeRange, useUrlSearchParams } from 'utils/hooks';
import { Dimmer, Loader } from 'components/lib';
import { PAGINATION, SORT_ORDER, STATUSES } from '../../../constants';
import { BusinessActivityTable } from './BusinessActivityTable';
import { BusinessActivityFilters } from './BusinessActivityFilters';
import { DEFAULT_ACTION_TYPE_VALUE, DEFAULT_AUTHOR_ROLE_VALUE, ORDER_BY } from './constants';
import { BusinessActivityChangeModal } from './BusinessActivityChangeModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
`;

const Header = styled.div`
  font-size: 60px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const useBusinessActivitiesFilters = () => {
  const { setUrlSearchParams } = useUrlSearchParams();
  const queryParams = new URLSearchParams(window.location.search);
  const [startDate, endDate] = getDateTimeRange(queryParams, 7);

  const [filters, setFilters] = useState({
    page: queryParams.get('page') || PAGINATION.PAGE,
    pageSize: queryParams.get('pageSize') || PAGINATION.PAGE_SIZE,
    startDate: formatAnyDate(startDate, 'yyyy-MM-dd'),
    endDate: formatAnyDate(endDate, 'yyyy-MM-dd'),
    search: queryParams.get('search') || '',
    authorRole: queryParams.get('authorRole') || DEFAULT_AUTHOR_ROLE_VALUE,
    actionType: queryParams.get('actionType') || DEFAULT_ACTION_TYPE_VALUE,
    ordering: queryParams.get('order') || `-${ORDER_BY.CREATED_ON}`,
  });

  useEffect(() => {
    setUrlSearchParams(filters, true);
  }, [filters]);

  return [filters, setFilters];
};

export const BusinessActivities = () => {
  const [loader, setLoader] = useState(STATUSES.IDLE);
  const [error, setError] = useState();
  const [businessActivities, setBusinessActivities] = useState();
  const [totalPages, setTotalPages] = useState();
  const [filters, setFilters] = useBusinessActivitiesFilters();
  const [orderingColumn, setOrderingColumn] = useState({
    name: undefined,
    order: undefined,
  });
  const [businessActivityId, setBusinessActivityId] = useState();

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoader(STATUSES.LOADING);
        const { results, count } = await getBusinessActivities(filters);

        setBusinessActivities(results);
        setTotalPages(Math.ceil(count / filters.pageSize));
        setLoader(STATUSES.LOADED);
      } catch (e) {
        const message = parseErrorResponse(e, 'Unable to get events. Please try again.');
        setError(message);
        setLoader(STATUSES.FAILURE);
      }
    };

    fetch();
  }, [filters]);

  useEffect(() => {
    const { ordering } = filters;

    if (!ordering) {
      setOrderingColumn({
        name: null,
        order: null,
      });
    } else {
      const sortOrder = ordering.startsWith('-') ? SORT_ORDER.DESC : SORT_ORDER.ASC;
      const sortName = sortOrder === SORT_ORDER.DESC ? ordering.substring(1) : ordering;

      setOrderingColumn({
        name: sortName,
        order: sortOrder,
      });
    }
  }, [filters.ordering]);

  const handleFiltersChange = (data) => {
    setFilters((draft) => ({
      ...draft,
      ...data,
      page: PAGINATION.PAGE,
    }));
  };

  const handlePageChange = (page) => {
    setFilters((draft) => ({ ...draft, page }));
  };

  const handleChangeOrdering = (name) => () => {
    const { ordering } = filters;
    const sortOrder = ordering && ordering.startsWith('-') ? SORT_ORDER.DESC : SORT_ORDER.ASC;

    const orderName = `${sortOrder === SORT_ORDER.ASC ? '-' : ''}${name}`;
    handleFiltersChange({ ordering: orderName });
  };

  const handleViewBusinessActivity = (id) => () => {
    setBusinessActivityId(id);
  };

  const handleCloseViewBusinessActivity = () => {
    setBusinessActivityId(false);
  };

  return (
    <Container>
      <Dimmer active={loader === STATUSES.LOADING} inverted>
        <Loader active />
      </Dimmer>
      <Header>Events</Header>
      <BusinessActivityFilters filters={filters} onChange={handleFiltersChange} />
      <BusinessActivityTable
        businessActivities={businessActivities}
        loader={loader}
        error={error}
        filters={filters}
        totalPages={totalPages}
        orderingColumn={orderingColumn}
        onPageChange={handlePageChange}
        onChangeOrdering={handleChangeOrdering}
        onViewBusinessActivity={handleViewBusinessActivity}
      />

      <BusinessActivityChangeModal businessActivityId={businessActivityId} onClose={handleCloseViewBusinessActivity} />
    </Container>
  );
};
