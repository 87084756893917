import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Icon, Button, Accordion, Transition } from 'semantic-ui-react';

import { ChatModulesContext } from './ChatModulesContext';
import { OptionTitle } from './OptionTitle';
import { Node } from './Node';
import { jumpToModule } from '../../utils';
import { AddModule } from './AddModule';

const Wrapper = styled.div`
  padding: 0 0.5rem;
  margin: 0 -0.5rem;

  .ui.accordion {
    margin: 0;

    .title {
      padding: 0;
    }

    .active.content {
      padding: 0.2rem 0;
    }
  }
`;

const Indent = styled.div`
  padding-top: 0.5rem;
  padding-left: 2.5rem;
  position: relative;
`;

export const Branch = ({ node, answer }) => {
  const { module } = node;
  const [active, setActive] = useState(false);
  const { addChildModule } = useContext(ChatModulesContext);

  const { text, action } = answer;
  let childNode = node.child(action);
  if (node.isRootNode() && node.degree === 1 && answer.rank !== 0) {
    // Need a special case for the root node, when all actions point to the same module.
    // With this, option buttons on root module will be expandable, but the target flow will
    // not repeat for each option (we'll only show it under the first option, and for rest,
    // we'll show a "Jump to module X" label).
    childNode = null;
  }
  const [addChildFormVisible, setAddChildFormVisible] = useState(false);

  const submitNewChildModule = (child) => {
    setAddChildFormVisible(false);
    addChildModule(child, module, answer);
  };

  const onDropdownClick = () => {
    setActive(!active);
  };

  return (
    <Wrapper>
      <OptionTitle>
        <Button fluid icon labelPosition="left" color="blue" size="small" onClick={onDropdownClick}>
          <Icon name={active ? 'chevron down' : 'chevron right'} />
          {text}
        </Button>
      </OptionTitle>

      <Accordion fluid>
        <Accordion.Content active={active}>
          <Transition visible={active} animation="slide down" duration={150}>
            <Indent>
              {childNode && <Node node={childNode} />}

              {!childNode && action !== module.number && (
                <Button
                  fluid
                  icon="reply all"
                  size="tiny"
                  color="orange"
                  content={`Jump to module ${action}`}
                  onClick={() => {
                    jumpToModule(action);
                  }}
                />
              )}

              {!childNode && action === module.number && !addChildFormVisible && (
                <Button
                  fluid
                  color="grey"
                  size="tiny"
                  type="button"
                  icon="add"
                  content="Add Module"
                  onClick={() => {
                    setAddChildFormVisible(true);
                  }}
                />
              )}

              {addChildFormVisible && (
                <AddModule
                  onSubmit={submitNewChildModule}
                  onCancel={() => {
                    setAddChildFormVisible(false);
                  }}
                />
              )}
            </Indent>
          </Transition>
        </Accordion.Content>
      </Accordion>
    </Wrapper>
  );
};
