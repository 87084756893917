import React from 'react';

import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

export const SettingsHeader = ({ children }) => {
  return <Header>{children}</Header>;
};
