import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

const DeleteUserSubjectOverrideModal = ({ defaultEmailSubjectText, emailType, open, loading, onClose, onConfirm }) => (
  <Modal open={open} onClose={onClose}>
    <Modal.Header>Reset To System Default?</Modal.Header>
    <Modal.Content>
      <p>
        The subject for the <strong>{emailType}</strong> email type will be reset to the default value defined by the
        system:
      </p>
      <div>
        <strong>{defaultEmailSubjectText}</strong>
      </div>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={onConfirm} disabled={loading} loading={loading}>
        Delete
      </Button>
      <Button onClick={onClose} disabled={loading}>
        Cancel
      </Button>
    </Modal.Actions>
  </Modal>
);

export default DeleteUserSubjectOverrideModal;
