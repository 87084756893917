import React from 'react';
import styled from 'styled-components';

import EmailPreview from 'components/common/EmailPreview';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns = 3 }) => columns}, 1fr);
  gap: 20px 30px;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
`;

const Template = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
`;

const TemplatePreviewWrapper = styled.div`
  width: 225px;
  height: 225px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  overflow: hidden;
`;

const TemplateName = styled.h3`
  margin: 0;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
`;

const Label = styled.div`
  margin-top: 8px;

  height: 20px;
  padding: 3px 9px 3px 8px;
  border-radius: 4px;
  font-family: Avenir;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: ${({ isPublished }) => (isPublished ? '#defff3' : '#f3f3f1')};
  color: ${({ isPublished }) => (isPublished ? 'var(--emerald-green)' : '#89919d')};
`;

const statusMap = {
  true: 'Published',
  false: 'Draft',
};

export default function List({ templates, onTemplateSelect, columns, maxWidth }) {
  return (
    <Grid columns={columns} maxWidth={maxWidth}>
      {Object.keys(templates).map((template) => (
        <Template key={template} onClick={() => onTemplateSelect(templates[template])}>
          <TemplatePreviewWrapper>
            <EmailPreview
              html={templates[template].html}
              style={templates[template].style}
              width={225}
              height={225}
              scale={0.3}
              wrapToHtml
            />
          </TemplatePreviewWrapper>
          <TemplateName>{templates[template].name}</TemplateName>
          <Label isPublished={templates[template].isPublished}>{statusMap[templates[template].isPublished]}</Label>
        </Template>
      ))}
    </Grid>
  );
}
