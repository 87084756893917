import React from 'react';
import { useTranslation } from 'react-i18next';

import { Statistic, getChangeIcon } from 'components/common';
import { formatCount, formatPercent, formatDuration } from 'components/utils';

const SalesAssistantBasicStats = ({ metrics = {}, prevMetrics = {} }) => {
  const { t } = useTranslation();

  return (
    <>
      <Statistic.Group name="Leads Totals">
        <Statistic
          label="Leads Reached"
          value={formatCount(metrics.total_reached_leads)}
          changeIcon={getChangeIcon(metrics.total_reached_leads, prevMetrics.total_reached_leads)}
          tooltip="Leads that are reached through defined campaign messages"
        />
        <Statistic
          label="Active Leads"
          value={formatCount(metrics.active_leads)}
          changeIcon={getChangeIcon(metrics.active_leads, prevMetrics.active_leads)}
          tooltip="Leads that replied with at least one message that is not STOP"
        />
        <Statistic
          label="Leads Conversations"
          value={formatCount(metrics.total_replied_leads)}
          changeIcon={getChangeIcon(metrics.total_replied_leads, prevMetrics.total_replied_leads)}
          tooltip="Active leads that we replied to at least once"
        />
        <Statistic
          label="Stops"
          value={formatCount(metrics.stop_leads)}
          tooltip="Leads that replied with STOP message"
          changeIcon={getChangeIcon(metrics.stop_leads, prevMetrics.stop_leads, true)}
        />
      </Statistic.Group>
      <Statistic.Group name="Messages Totals" marginTop="2rem">
        <Statistic
          label="Lead Messages"
          value={formatCount(metrics.total_lead_messages)}
          tooltip="Messaged sent by leads"
          changeIcon={getChangeIcon(metrics.total_lead_messages, prevMetrics.total_lead_messages)}
        />
        <Statistic
          label="Replied Messages"
          value={formatCount(metrics.total_replied_messages)}
          secondaryValue={formatPercent(metrics.total_replied_messages, metrics.total_lead_messages)}
          changeIcon={getChangeIcon(metrics.total_replied_messages, prevMetrics.total_replied_messages)}
          tooltip="Number of replied lead messages"
        />
        <Statistic
          label="Avg. Response Time"
          value={metrics.avg_response_humanized}
          tooltip={`Average time between ${t('lead')}'s message and a reply message`}
          changeIcon={getChangeIcon(metrics.avg_response, prevMetrics.avg_response, true, formatDuration)}
        />
        <Statistic
          label="Avg. First Message Resp. Time"
          value={metrics.avg_response_first_message_humanized}
          changeIcon={getChangeIcon(
            metrics.avg_response_first_message,
            prevMetrics.avg_response_first_message,
            true,
            formatDuration
          )}
          tooltip={`Average time between ${t('lead')}'s message and a reply message for the very first ${t(
            'lead'
          )}'s message`}
        />
      </Statistic.Group>
    </>
  );
};

export default SalesAssistantBasicStats;
