import React from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import { addProtocolToUrl } from 'utils/helpers';

const ChatInstanceWrapper = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  > span {
    font-size: 0.8rem;
    color: #666;
    font-family: monospace;
  }
`;

const ChatInstance = ({ chatProperty }) => {
  if (!chatProperty) return null;

  return (
    <ChatInstanceWrapper>
      <a href={addProtocolToUrl(chatProperty.community?.url)} target="_blank" rel="noopener noreferrer">
        {chatProperty.community?.name}
      </a>
      <span>INSTANCE: {chatProperty.unique_id}</span>
    </ChatInstanceWrapper>
  );
};

export const ChatVisibility = ({ chat }) => {
  const chatShownLocations = chat.chat_properties.filter((p) => p.show_chat);
  const chatHiddenLocations = chat.chat_properties.filter((p) => !p.show_chat);

  return (
    <Segment secondary>
      <h4>Chat is currently live at these locations:</h4>
      <ul>
        {chatShownLocations.map((p, i) => (
          <ChatInstance key={i} chatProperty={p} />
        ))}
      </ul>
      {chatHiddenLocations.length > 0 && (
        <>
          <h4>Chat is hidden at these locations:</h4>
          <ul>
            {chatHiddenLocations.map((p, i) => (
              <ChatInstance key={i} chatProperty={p} />
            ))}
          </ul>
        </>
      )}
    </Segment>
  );
};
