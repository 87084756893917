import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useImmerReducer } from 'use-immer';

import { deleteReport, getSavedReports } from 'apis';
import { Tab } from 'semantic-ui-react';
import { PageSection } from 'components/common';
import { STATUSES } from '../../../constants';
import ReportsList from './ReportsList';

const initialState = {
  status: STATUSES.IDLE,
  reports: null,
};

const reducer = (state, event) => {
  switch (event.type) {
    case 'SET-STATUS':
      state.status = event.payload;
      return;
    case 'SET-REPORTS':
      state.reports = event.payload;
      state.status = STATUSES.LOADED;
      return;
    default:
      console.warn('Unknown reducer type', event);
      return state;
  }
};

const ReportsTab = () => {
  const [{ reports, status }, dispatch] = useImmerReducer(reducer, initialState);

  useEffect(() => {
    const getReports = async () => {
      try {
        dispatch({ type: 'SET-STATUS', payload: STATUSES.LOADING });
        const response = await getSavedReports();

        dispatch({ type: 'SET-REPORTS', payload: response.reports });
      } catch (error) {
        dispatch({ type: 'SET-STATUS', payload: STATUSES.FAILURE });
        const msg = 'Could not load saved reports';
        console.error(msg, error?.response);
        toast.error(msg);
      }
    };
    getReports();
  }, [dispatch]);

  const handleDelete = async (reportId) => {
    try {
      await deleteReport(reportId);
      toast.success('Report deleted successfully');
      const reportsFiltered = reports.filter((report) => report.publicId !== reportId);
      dispatch({ type: 'SET-REPORTS', payload: reportsFiltered });
    } catch (error) {
      const msg = 'Could not delete report';
      console.error(msg, error?.response);
      toast.error(msg);
    }
  };

  return (
    <Tab.Pane>
      <PageSection title="Saved reports" loaderStatus={status}>
        <ReportsList reports={reports} onDelete={handleDelete} />
      </PageSection>
    </Tab.Pane>
  );
};

export default ReportsTab;
