import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { UserAccountsContextProvider } from './UserAccountsContext';
import UserAccountList from './UserAccountList';
import UserAccountCreate from './UserAccountCreate';
import UserAccountDetails from './UserAccountDetails';
import UserAccountEdit from './UserAccountEdit';

const UserAccounts = () => (
  <UserAccountsContextProvider>
    <Switch>
      <Route path="/settings/user-accounts" component={UserAccountList} exact />
      <Route path="/settings/user-accounts/new" component={UserAccountCreate} exact />
      <Route path="/settings/user-accounts/:userId" component={UserAccountDetails} exact />
      <Route path="/settings/user-accounts/:userId/edit" component={UserAccountEdit} exact />
    </Switch>
  </UserAccountsContextProvider>
);

export default UserAccounts;
