import React from 'react';
import styled from 'styled-components';

import { Dropdown } from 'components/lib';

const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown {
    font-size: 12px;
    display: flex;
    align-items: center;

    .icon {
      top: -2px;
      font-size: 10px;
      color: var(--link);
      align-self: baseline;
    }

    &&&&& {
      .divider.text {
        font-size: 12px;
        color: var(--text);
        font-weight: 500;
      }

      .text {
        font-size: 14px;
        font-weight: 500;
      }

      .menu {
        right: 0;
        left: auto;
      }
    }
  }
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 900;
  gap: 5px;
`;

export const FilterDropdown = ({ value, options, onChange }) => (
  <FilterContainer>
    <span>Show</span> <StyledDropdown floating inline options={options} value={value} onChange={onChange} />
  </FilterContainer>
);
