import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 8px;
  color: ${({ isOverLimit }) => (isOverLimit ? 'var(--salmon)' : 'var(--light-text)')};
`;

const InputMaxLength = ({ value, limit, className, hideEmpty }) => {
  if (hideEmpty && (!value || value.length === 0)) {
    return null;
  }

  const count = value.length;
  const remaining = limit - count;
  const isOverLimit = remaining <= 0;

  return (
    <Container className={className} isOverLimit={isOverLimit}>
      {count}/{limit}
    </Container>
  );
};

export default InputMaxLength;
