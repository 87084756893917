import { useHistory, useLocation } from 'react-router';
import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { UserContext } from './UserContext';

const demoSafeRoutes = [
  '/$',
  '/startup$',
  '/support-mode$',
  '/stats$',
  '/stats/overview$',
  '/stats/insights$',
  '/stats/leads$',
  '/stats/keywords$',
  '/stats/chat-flows$',
  '/stats/instant-answers$',
  '/stats/benchmarks$',
  '/stats/calls$',
  '/advanced$',
  '/overview$',
  '/forbidden$',
].map((regexString) => new RegExp(regexString));

export const DemoRoutesOnly = ({ children, router }) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const demoMode = user?.impersonated_session?.demo_mode;
  const demoSafeRoute = demoSafeRoutes.find((regex) => regex.test(location.pathname));
  const shouldRedirect = demoMode && !demoSafeRoute;

  useEffect(() => {
    if (shouldRedirect) {
      // we're not on a demo safe route, redirect to a known safe route
      toast.warning('Page is not allowed in demo mode');
      history.replace('/stats/overview');
    }
  }, [shouldRedirect]);

  if (shouldRedirect) {
    return null;
  }

  return children;
};
