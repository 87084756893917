import React from 'react';
import { useParams, Link, Route } from 'react-router-dom';
import { Menu, Button } from 'semantic-ui-react';

export const TeasersMenu = ({ teasers }) => {
  const { chatId, teaserId } = useParams();

  return (
    <>
      <Menu secondary vertical fluid>
        {teasers?.map((t, i) => (
          <Menu.Item key={i} active={t.id === parseInt(teaserId)} as={Link} to={`/chats/${chatId}/teasers/${t.id}`}>
            <h5>Teaser {i + 1}</h5>
            <p>{t.query_selector}</p>
          </Menu.Item>
        ))}
        <Route exact path={`/chats/${chatId}/teasers/new`}>
          <Menu.Item active={true}>New Teaser</Menu.Item>
        </Route>
      </Menu>
      <Button as={Link} to={`/chats/${chatId}/teasers/new`} content="Add new teaser" icon="plus" basic fluid />
    </>
  );
};
