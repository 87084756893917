import constants from './constants';

export const setLoaderStatus = (data) => ({
  type: constants.SET_LOADER_STATUS,
  payload: data,
});

export const setUpdateLoaderStatus = (data) => ({
  type: constants.SET_UPDATE_LOADER_STATUS,
  payload: data,
});

export const setQuestionsCount = (data) => ({
  type: constants.SET_QUESTIONS_COUNT,
  payload: data,
});

export const setAttributes = (data) => ({
  type: constants.SET_ATTRIBUTES,
  payload: data,
});

export const setQuestions = (results, count) => ({
  type: constants.SET_QUESTIONS,
  payload: { results, count },
});

export const setFilters = (filters, forceQuestionsUpdate = false) => ({
  type: constants.SET_FILTERS,
  payload: { filters, forceQuestionsUpdate },
});

export const resetIgnoredQuestionsCount = () => ({
  type: constants.RESET_IGNORED_QUESTIONS_COUNT,
});

export const resetLabeledQuestionsCount = () => ({
  type: constants.RESET_LABELED_QUESTIONS_COUNT,
});

export const setCurrentQuestionId = (question) => ({
  type: constants.SET_CURRENT_QUESTION_ID,
  payload: question,
});

export const updateQuestionsCount = ({
  newQuestionsCount = 0,
  labeledQuestionCount = 0,
  ignoredQuestionsCount = 0,
}) => ({
  type: constants.UPDATE_QUESTIONS_COUNT,
  payload: { newQuestionsCount, labeledQuestionCount, ignoredQuestionsCount },
});

export const addAttribute = (id, name) => ({
  type: constants.ADD_ATTRIBUTE,
  payload: { key: id, text: name, value: id },
});

export const changeSort = (sortBy) => ({
  type: constants.CHANGE_SORT,
  payload: sortBy,
});
