import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import classNames from 'classnames';
// import {  } from 'semantic-ui-react';

import { Icon, Popup } from '.';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: var(--white);
  cursor: pointer;
`;

const SelectedColor = styled.div`
  width: 28px;
  height: 28px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

const PopupContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 120px;
  gap: 10px;
`;

const Color = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  cursor: pointer;
  position: relative;

  &.active {
    &::after {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: solid 1px var(--vivid-blue);
    }
  }
`;

const COLORS = [
  '#5f5fff',
  '#1f58b0',
  '#2791ff',
  '#49b9d1',
  '#0ab776',
  '#d9a707',
  '#fa7550',
  '#ef172a',
  '#fc436a',
  '#89919d',
];

export const ColorPickerDropDown = ({ onChanged, ...props }) => {
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;

  const handleChange = (value) => {
    setValue(value);
    if (typeof onChanged === 'function') {
      onChanged(value);
    }
  };

  return (
    <Popup
      basic
      on={['click']}
      position="bottom right"
      className="attached"
      trigger={
        <Container>
          <SelectedColor $backgroundColor={value} />
          <Icon name="expand_more" size="24" color="#89919d" />
        </Container>
      }
    >
      <PopupContent>
        {COLORS.map((c, i) => (
          <Color $color={c} key={i} onClick={() => handleChange(c)} className={classNames({ active: c === value })} />
        ))}
      </PopupContent>
    </Popup>
  );
};
