import React from 'react';

import { Button, Checkbox, Icon, Table } from 'components/lib';
import { InputField } from 'components/common';

const CRMLeadSourceRow = ({ value, baseFieldName, onRemove }) => {
  return (
    <Table.Row verticalAlign="top">
      <Table.Cell>
        <InputField name={`${baseFieldName}.external_id`} placeholder="CRM ID" />
      </Table.Cell>
      <Table.Cell>
        <InputField name={`${baseFieldName}.display_name`} placeholder="Name" />
      </Table.Cell>
      <Table.Cell>
        <InputField control={Checkbox} checked={!!value.enable_nurturing} name={`${baseFieldName}.enable_nurturing`} />
      </Table.Cell>
      <Table.Cell>
        <Icon name="delete" color="#fa7550" onClick={onRemove} />
      </Table.Cell>
    </Table.Row>
  );
};

const CRMLeadSourceTable = ({ fieldName, leadSources = [], onPush, onRemove }) => {
  const handleAdd = () => {
    onPush({
      external_id: '',
      display_name: '',
      enable_nurturing: true,
    });
  };

  return (
    <Table basic="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>CRM ID</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Nurture</Table.HeaderCell>
          <Table.HeaderCell>Archive</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {leadSources.map((leadSource, index) => (
          <CRMLeadSourceRow
            key={index}
            value={leadSource}
            baseFieldName={`${fieldName}[${index}]`}
            onRemove={onRemove(index)}
          />
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="4">
            <Button type="button" primary size="tiny" onClick={handleAdd}>
              Add lead source
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default CRMLeadSourceTable;
