import React from 'react';
import styled from 'styled-components';

import { Form as SemanticForm } from 'semantic-ui-react';

const StyledForm = styled(SemanticForm)``;

export const Form = ({ ...props }) => <StyledForm {...props} />;

Form.Field = SemanticForm.Field;
Form.Group = SemanticForm.Group;
Form.Input = SemanticForm.Input;
Form.Checkbox = SemanticForm.Checkbox;
Form.Radio = SemanticForm.Radio;
Form.TextArea = SemanticForm.TextArea;
