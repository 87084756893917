import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon, Button, Table, Menu, Accordion } from 'semantic-ui-react';

import { InputField } from 'components/common/fields/InputField';
import { FieldArray, useFormikContext } from 'formik';

const AccordionBox = styled.div`
  padding: 0 1rem;
`;

const SectionTitle = styled.span`
  padding-right: 5px;
  color: #222;

  span {
    color: #a6a6a6;
  }
`;

const MapTableCell = styled(Table.Cell)`
  &&&&& {
    padding-bottom: 15px;
    padding-top: 15px;

    > div {
      > .field {
        margin: 0;
      }

      // error message adjustment
      > span {
        font-size: 10px;
        padding-top: 0px;
      }
    }
  }
`;

const Mapping = ({ name, value }) => {
  const { values, errors, submitCount } = useFormikContext();
  const mappingValues = (values && values[name]) || [];
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (submitCount && errors && name in errors) {
      setExpanded(true);
    }
  }, [errors, submitCount]);

  const handleAdd = (onPush) => () => {
    onPush({
      key: '',
      value: '',
    });
  };

  const handleRemove = (index, onRemove) => () => {
    onRemove(index);
  };

  return (
    <Accordion fluid as={Menu} vertical>
      <Accordion.Title active={expanded} onClick={() => setExpanded(!expanded)}>
        <AccordionBox>
          <Icon name="dropdown" />
          <SectionTitle>
            {value} <span>({mappingValues.length})</span>
          </SectionTitle>
        </AccordionBox>
      </Accordion.Title>
      <Accordion.Content active={expanded}>
        <AccordionBox>
          <FieldArray name={name}>
            {({ push, remove }) => (
              <Table basic="very">
                <Table.Body>
                  {mappingValues.map((_, index) => (
                    <Table.Row key={index}>
                      <MapTableCell width={7}>
                        <InputField size="small" newError name={`${name}[${index}].key`} placeholder="Map from" />
                      </MapTableCell>
                      <MapTableCell width={7}>
                        <InputField size="small" newError name={`${name}[${index}].value`} placeholder="Map to" />
                      </MapTableCell>
                      <Table.Cell width={1} textAlign="center">
                        <Button type="button" icon negative size="mini" onClick={handleRemove(index, remove)}>
                          <Icon name="trash" />
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>

                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell colSpan="3">
                      <Button icon labelPosition="left" primary size="small" onClick={handleAdd(push)}>
                        <Icon name="plus" /> Add
                      </Button>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            )}
          </FieldArray>
        </AccordionBox>
      </Accordion.Content>
    </Accordion>
  );
};

export default Mapping;
