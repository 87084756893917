import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Button, Dimmer, Modal, Loader } from 'components/lib';
import { parseErrorResponse } from 'apis/utils';
import { getContactRuleById } from 'apis';
import { useContactRules } from './ContactRuleContext';
import ContactRuleForm from './ContactRuleForm';

const Header = styled.div`
  margin: 29px 0 10px 30px;
  font-size: 20px;
  color: var(--text);
  font-family: var(--font-family-bold);
`;

const Content = styled.div`
  min-height: 340px;
  margin: 10px 30px 40px;
  font-size: 14px;
  line-height: 1.43;
  color: var(--light-text);
  font-family: var(--font-family);
`;

const ModalActions = styled(Modal.Actions)`
  && {
    display: flex;
    justify-content: flex-end;
    && > .delete-button {
      margin-left: 0;
      margin-right: auto;
    }
  }
`;

const INITIAL_STATE = {
  name: '',
  match: '',
  condition: '',
  value: '',
  emails: undefined,
  communities: [],
};

const ContactRuleUpdateModal = () => {
  const {
    editContactRule,
    updateLoading,
    createLoading,
    openModal,
    handleCloseModal,
    contactRuleId,
    removeContactRule,
    deleteLoading,
    addContactRule,
  } = useContactRules();
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [initialValues, setInitialValues] = useState(INITIAL_STATE);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter a name'),
    match: Yup.string().required('Please select a match'),
    condition: Yup.string().required('Please select a condition'),
    value: Yup.string().required('Please enter a value'),
    emails: Yup.array().of(Yup.string().email('Invalid email address')),
    communities: Yup.array().of(Yup.number()).required('Please select at least one community'),
  });

  useEffect(() => {
    const fetchContactRule = async () => {
      try {
        setLoading(true);
        const response = await getContactRuleById(contactRuleId);
        setInitialValues(response);
        setInitialized(true);
      } catch (error) {
        toast.error(parseErrorResponse(error, 'Something went wrong while fetching contact rule'));
      } finally {
        setLoading(false);
      }
    };

    if (openModal) {
      if (contactRuleId) {
        fetchContactRule();
      } else {
        setInitialized(true);
      }
    }

    return () => {
      setInitialized(false);
      setInitialValues(INITIAL_STATE);
    };
  }, [openModal, contactRuleId]);

  const handleSubmit = (values) => {
    if (contactRuleId) {
      editContactRule(values);
    } else {
      addContactRule(values);
    }
  };

  const handleDelete = () => {
    removeContactRule();
  };

  const actionsDisabled = createLoading || updateLoading || deleteLoading;

  return (
    <Modal onClose={handleCloseModal} open={openModal} closeIcon>
      <Header>{contactRuleId ? 'Update contact rule' : 'Create new rule'}</Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <Content>
              <Dimmer active={loading} inverted>
                <Loader active inverted />
              </Dimmer>
              {initialized && <ContactRuleForm values={values} setFieldValue={setFieldValue} />}
            </Content>
            <ModalActions>
              {contactRuleId && (
                <Button
                  className="delete-button link"
                  color="red"
                  onClick={handleDelete}
                  disabled={actionsDisabled}
                  loading={deleteLoading}
                >
                  Delete
                </Button>
              )}
              <Button
                primary
                color="blue"
                type="submit"
                onClick={handleSubmit}
                disabled={actionsDisabled}
                loading={createLoading || updateLoading}
              >
                {contactRuleId ? 'Update' : 'Create'}
              </Button>
              <Button basic onClick={handleCloseModal} disabled={actionsDisabled}>
                Cancel
              </Button>
            </ModalActions>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ContactRuleUpdateModal;
