import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';

import { WithTopBar } from 'components/WithTopBar';
import { CreateChat } from './CreateChat';
import { CloneChat } from './CloneChat';
import { SetLive } from './SetLive';
import { ChatEditor } from './ChatEditor';
import { MarketingAnalytics } from './Analytics/MarketingAnalytics';
import { Chats } from './Chats';
import { ChatsContextProvider } from './ChatsContext';
import { MEDIA_BREAK_POINTS } from '../../constants';

const Wrapper = styled.div`
  padding: 30px 15px;
  width: 100%;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    padding: 30px;
  }
`;

export const ChatsRoutes = () => (
  <WithTopBar>
    <Wrapper>
      <ChatsContextProvider>
        <Switch>
          <Route exact path="/chats" component={Chats} />
          <Route path="/chats/analytics" component={MarketingAnalytics} />
          <Route path="/chats/create" component={CreateChat} />
          <Route path="/chats/:chatId/clone" component={CloneChat} />
          <Route path="/chats/:chatId/set-live" component={SetLive} />
          <Route path="/chats/:chatId" component={ChatEditor} />
        </Switch>
      </ChatsContextProvider>
    </Wrapper>
  </WithTopBar>
);
