import React, { useState, useEffect } from 'react';
import { Button, Message } from 'semantic-ui-react';
import { useQuery } from 'react-query';

import { PageSection } from 'components/common';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import { getOwnersBreakdown } from 'apis';
import queryConstants from 'utils/queries/constants';
import { MetricsBreakdownTable } from './MetricsBreakdownTable';
import { useOwnersDashboardContext } from './OwnersDashboardContext';

const COMPANY = 'company';
const COMMUNITY = 'community';

export const MetricsBreakdown = () => {
  const { filters, managementCompanyId, communityId } = useOwnersDashboardContext();
  const [groupBy, setGroupBy] = useState(managementCompanyId ? 'community' : 'company');

  const { data, error, status, isFetching } = useQuery(
    [queryConstants.OWNERS_BREAKDOWN, { filters: { ...filters, group_by: groupBy } }],
    () => getOwnersBreakdown({ filters: { ...filters, group_by: groupBy } }),
    { placeholderData: [] }
  );

  useEffect(() => {
    setGroupBy(managementCompanyId ? 'community' : 'company');
  }, [managementCompanyId]);

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    return (
      <Message error>
        <Message.Header>Error Loading Analytic Data</Message.Header>
        <Message.Content>
          Something went wrong and we couldn't fetch your analytic data. Please try again, or reach out over email for
          help.
        </Message.Content>
      </Message>
    );
  }

  return (
    <PageSection
      title="Metrics Breakdown"
      loaderStatus={loaderStatus}
      actions={
        !managementCompanyId &&
        !communityId && (
          <Button.Group size="small" basic>
            <Button
              content="By Company"
              active={groupBy === COMPANY}
              onClick={() => {
                setGroupBy(COMPANY);
              }}
            />
            <Button
              content="By Community"
              active={groupBy === COMMUNITY}
              onClick={() => {
                setGroupBy(COMMUNITY);
              }}
            />
          </Button.Group>
        )
      }
    >
      <MetricsBreakdownTable data={data} />
    </PageSection>
  );
};
