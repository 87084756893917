import React, { useState } from 'react';
import styled from 'styled-components';

import { Dropdown, Icon } from 'components/lib';
import { If } from 'components/If';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin-left: 20px;
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  }
  .close {
    margin-left: auto;
  }
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    width: 242px;
  }
`;

const RuleDropdown = ({ rule, multiple = false, onChange, onRemove, preview, selectedRule }) => {
  const { options: ruleOptions, defaultValue } = rule;
  const [value, setValue] = useState(
    selectedRule ? (!multiple ? selectedRule?.options[0] : selectedRule?.options) : defaultValue
  );
  const options = ruleOptions.map((option) => ({ key: option.id, text: option.name, value: option.id }));
  const handleOnChange = (selected) => {
    setValue(selected);
  };
  const handleOnClick = () => {
    if (preview) {
      return;
    }
    if (multiple) {
      onChange({
        id: rule.ruleId || rule.id,
        options: value,
        ruleId: rule.id,
        operator: null,
        value: null,
      });
    } else {
      const selectedOption = options.find((option) => option.value === parseInt(value));
      onChange({
        id: rule.id,
        ruleId: rule.id,
        options: [selectedOption?.value],
        value: null,
        operator: null,
      });
    }
  };
  return (
    <Wrapper disabled={(multiple && !(value && value.length)) || (!multiple && !value)}>
      <If condition={!preview}>
        <StyledDropdown
          placeholder="Select"
          fluid
          selection
          options={options}
          value={value}
          multiple={multiple}
          onChange={(e, { value }) => handleOnChange(value)}
        />
      </If>
      <If condition={!preview}>
        <Icon name="add_circle" size="20" color="#007aff" className="icon" onClick={handleOnClick} />
      </If>
      <If condition={preview}>
        <Icon name="close" size="24" color="#89919d" onClick={onRemove} className="close" />
      </If>
    </Wrapper>
  );
};

export default RuleDropdown;
