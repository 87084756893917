import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

const LeadMessagesNotificationModal = ({ open, onClickHandle }) => (
  <Modal open={open} onClose={onClickHandle}>
    <Modal.Header>WARNING</Modal.Header>
    <Modal.Content>
      WAIT! Someone has already replied to this message. Please refresh the conversation before you send this message.
    </Modal.Content>
    <Modal.Actions>
      <Button content="Update Conversation" onClick={onClickHandle} />
    </Modal.Actions>
  </Modal>
);

export default LeadMessagesNotificationModal;
