import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { TabsWithNavigation } from 'components/lib';
import CRMAdminLogsLeads from './CRMAdminLogsLeads';
import CRMAdminLogsTours from './CRMAdminLogsTours';
import CRMAdminLogsStatistics from './CRMAdminLogsStatistics';

const CRM_ADMIN_TABS = [
  {
    tabName: 'Statistics',
    path: '/admin/crm-admin-logs/statistics',
    Content: CRMAdminLogsStatistics,
  },
  {
    tabName: 'Leads',
    path: '/admin/crm-admin-logs/leads',
    Content: CRMAdminLogsLeads,
  },
  {
    tabName: 'Tours',
    path: '/admin/crm-admin-logs/tours',
    Content: CRMAdminLogsTours,
  },
];
const Wrapper = styled.div`
  padding: 35px 35px 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

const TitleWrapper = styled.h1`
  font-family: var(--font-family-bold);
  font-size: 60px;
  line-height: 60px;
`;

const INITIAL_TAB = CRM_ADMIN_TABS[0].path;

const CRMAdminLogs = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/admin/crm-admin-logs') {
      history.replace(INITIAL_TAB);
    }
  }, [location.pathname, history]);

  return (
    <>
      <Wrapper>
        <TitleWrapper>CRM Admin Logs</TitleWrapper>
      </Wrapper>
      <TabsWithNavigation tabs={CRM_ADMIN_TABS} contentPadding="0px" />
    </>
  );
};

export default CRMAdminLogs;
