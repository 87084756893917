import humps from 'humps';

import { API } from '../api';

export const getBusinessActivities = async (query) => {
  const params = humps.decamelizeKeys(query);
  const response = await API.get('/api/business-activities/', { params });
  return humps.camelizeKeys(response.data);
};

export const getBusinessActivityDetails = async (id) => {
  const response = await API.get(`/api/business-activities/${id}`);
  return humps.camelizeKeys(response.data);
};
