import React from 'react';
import { useTranslation } from 'react-i18next';

import PromptModal from 'components/common/PromptModal';
import { capitalize } from 'utils/helpers';

const RemoveScheduledMessageModal = ({ open, leadName, loading, onConfirm, onClose }) => {
  const { t } = useTranslation();

  return (
    <PromptModal
      open={open}
      title={`Remove ${capitalize(t('leads'))} From Outreach?`}
      description={
        <>
          The {t('lead')} <span className="bold">{leadName}</span> will be removed from campaign outreach, and all
          scheduled messages in this campaign type will be removed and cleared from their conversation view. Are you
          sure you want to do this?
        </>
      }
      actionLabel="Remove"
      loading={loading}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
};

export default RemoveScheduledMessageModal;
