import React from 'react';
import styled from 'styled-components';

import { Branch } from './Branch';
import { OptionButton } from './OptionButton';
import { ACTION_TYPES } from './ModuleGraph';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const AnswerWrapper = styled.div`
  position: relative;
`;

export const Answers = ({ node }) => {
  const { module } = node;
  const { answers } = module;

  return (
    <>
      {answers?.length > 0 && (
        <Wrapper>
          {answers
            .sort((a, b) => a.rank - b.rank)
            .map((a, i) => {
              const branchAnswer =
                node.isRootNode() || [ACTION_TYPES.BRANCH, ACTION_TYPES.BRANCH_JUMP].includes(a.meta?.actionType);

              return (
                <AnswerWrapper key={i}>
                  {branchAnswer ? <Branch node={node} answer={a} /> : <OptionButton node={node} answer={a} />}
                </AnswerWrapper>
              );
            })}
        </Wrapper>
      )}
    </>
  );
};
