import React from 'react';
import styled from 'styled-components';

import { Form } from 'semantic-ui-react';

const StyledRadio = styled(Form.Radio)`
  &&& {
    display: flex;
    .ui.toggle.checkbox {
      min-height: 26px;
    }
    .ui.toggle.checkbox input {
      width: 50px;
      height: 26px;
      padding: 0;
    }
    .ui.toggle.checkbox label {
      min-height: 26px;
    }

    .ui.toggle.checkbox label:before {
      width: 50px;
      height: 26px;
      box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.25);
      border: solid 1px rgba(171, 171, 171, 0.29);
      background-image: linear-gradient(to bottom, #efefef, #dedbdb);
      transition: all 0.2s ease;
    }

    .ui.toggle.checkbox label:after {
      left: 3px;
      top: 3px;
      width: 20px;
      height: 20px;
      border: none;
      transition: all 0.2s ease;
    }

    .ui.toggle.checkbox input:checked ~ label:after {
      left: 26px;
      transition: all 0.2s ease;
    }

    .ui.toggle.checkbox input:checked ~ label:before {
      transition: all 0.2s ease;
      box-shadow: inset 0 2px 4px 0 #3ea3b9;
      background: var(--highlight-blue) !important;
    }
  }
`;

export const Toggle = ({ ...props }) => <StyledRadio toggle {...props} />;
