import React from 'react';
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';

const HeaderTitle = styled.div`
  font-weight: 800;
`;

const PlaceholderValues = styled.div`
  margin-top: 10px;
`;

const PlaceholderLabel = styled(Label)`
  cursor: pointer;
`;

const SelectPlaceholderSection = ({ placeholders, onLabelClick }) => (
  <>
    <HeaderTitle>Insert Placeholder Tag</HeaderTitle>
    <PlaceholderValues>
      {placeholders.map((placeholder) => (
        <PlaceholderLabel key={placeholder.key} onClick={() => onLabelClick(`{${placeholder.key}}`)}>
          {placeholder.key}
        </PlaceholderLabel>
      ))}
    </PlaceholderValues>
  </>
);

export default SelectPlaceholderSection;
