import React, { useContext, useEffect, useMemo } from 'react';
import { Tab } from 'semantic-ui-react';

import SearchInput from 'components/SearchInput';
import { UserContext } from 'components/UserContext';
import { CsvExport } from 'components/common';
import { PAGINATION, STATUSES, USER_TYPES } from '../../../constants';
import QuestionReviewList from './QuestionReviewList';
import {
  fetchQuestions,
  useQuestionReviews,
  setFilters,
  editQuestionReview,
  exportQuestions,
  updateQuestionsCount,
  refreshQuestionsList,
  resetLabeledQuestionsCount,
  changeSort,
} from './context';
import TabHeader, { IncludeExportedQuestionsCheckbox, TabActionsBox, TabFiltersBox } from './TabHeader';

const LabeledQuestionsTab = () => {
  const { isUserType } = useContext(UserContext);
  const [state, dispatch] = useQuestionReviews();
  const { questions, totalPages, loaderStatus, filters } = state;
  const labeledQuestionsFilter = useMemo(() => ({ ...filters, isLabeled: true, isIgnored: false }), [filters]);

  useEffect(() => {
    fetchQuestions(dispatch, labeledQuestionsFilter);
  }, [dispatch, labeledQuestionsFilter]);

  const handlePageChange = (page) => {
    dispatch(setFilters({ page }));
  };

  const handleSearch = (search) => {
    dispatch(setFilters({ page: PAGINATION.PAGE, search }));
  };

  const handleIncludeExported = (e, { checked }) => {
    dispatch(setFilters({ includeExported: checked }));
  };

  const handleOrdering = (orderBy) => {
    dispatch(changeSort(orderBy));
  };

  const handleQuestionUpdate = async (questionReviewId, attributes, isIgnored) => {
    const questionReview = questions.find((q) => q.id === questionReviewId);
    const response = await editQuestionReview(dispatch, questionReviewId, attributes, isIgnored);

    if (response && isIgnored) {
      if (!questionReview.processed_at) {
        dispatch(
          updateQuestionsCount({
            labeledQuestionCount: -1,
            ignoredQuestionsCount: 1,
          })
        );
      }

      refreshQuestionsList(state, dispatch, questionReviewId);
    }
  };

  const handleExportAsCSV = async () => {
    await exportQuestions(dispatch, { ...filters, isLabeled: true, isIgnored: false });
    await fetchQuestions(dispatch, labeledQuestionsFilter);
    dispatch(resetLabeledQuestionsCount());
  };

  return (
    <Tab.Pane>
      <TabHeader>
        <TabFiltersBox>
          <SearchInput
            value={filters.search}
            onSearch={handleSearch}
            loading={loaderStatus === STATUSES.LOADING}
            placeholder="Search question..."
          />
          <IncludeExportedQuestionsCheckbox
            label="Include exported questions"
            toggle
            checked={filters.includeExported}
            onChange={handleIncludeExported}
          />
        </TabFiltersBox>
        {isUserType(USER_TYPES.STAFF) && (
          <TabActionsBox>
            <CsvExport content="Export as CSV" exportAction={handleExportAsCSV} skipDownload />
          </TabActionsBox>
        )}
      </TabHeader>
      <QuestionReviewList
        loaderStatus={loaderStatus}
        questions={questions}
        activePage={filters.page}
        ordering={filters.ordering}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        onQuestionUpdate={handleQuestionUpdate}
        onOrdering={handleOrdering}
      />
    </Tab.Pane>
  );
};

export default LabeledQuestionsTab;
