import { USER_TYPES } from '../../constants';

export const BASE_NURTURE_PATHNAME = '/messaging';

export const NURTURE_CAMPAIGNS_ROUTE = '/messaging/campaigns';

export const DEFAULT_NURTURE_USER_ROUTE = {
  [USER_TYPES.REGULAR]: NURTURE_CAMPAIGNS_ROUTE,
  [USER_TYPES.STAFF]: NURTURE_CAMPAIGNS_ROUTE,
  [USER_TYPES.MEMBER]: '/messaging/campaign-scheduled-messages',
  [USER_TYPES.REGIONAL]: NURTURE_CAMPAIGNS_ROUTE,
};

export const NURTURE_TAB_ITEMS = [
  {
    key: 'campaign',
    label: 'Campaigns',
    to: NURTURE_CAMPAIGNS_ROUTE,
    allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.REGIONAL],
  },
  {
    key: 'conversations',
    label: 'Conversations',
    to: '/messaging/conversations',
    allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.REGIONAL],
  },
  {
    key: 'scheduled_campaign',
    label: 'Scheduled Messages',
    to: '/messaging/campaign-scheduled-messages',
    allowedTypes: [USER_TYPES.STAFF, USER_TYPES.MEMBER, USER_TYPES.REGULAR, USER_TYPES.REGIONAL],
  },
  {
    key: 'campaign-statistics',
    label: 'Campaign Statistics',
    to: '/messaging/campaign-statistics',
    allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.REGIONAL],
  },
  {
    key: 'team-response-statistics',
    label: 'Team Response Statistics',
    to: '/messaging/team-response-statistics',
    allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.REGIONAL],
  },
];

export const FORMAT_RELATIVE_LOCALE = {
  lastWeek: 'EEE h:mm a',
  yesterday: "'Yesterday' h:mm a",
  today: "'Today at' h:mm a",
  tomorrow: "'Tomorrow at' h:mm a",
  nextWeek: "EEEE 'at' h:mm a",
  other: "MMM d 'at' h:mm a",
};

export const CAMPAIGN_TYPE = {
  SALES: 'sales',
  FOLLOW_UP: 'follow_up',
  ONE_TIME: 'one_time',
};

export const CAMPAIGN_TYPE_NAME = {
  [CAMPAIGN_TYPE.SALES]: 'Sales',
  [CAMPAIGN_TYPE.FOLLOW_UP]: 'Follow-up',
  [CAMPAIGN_TYPE.ONE_TIME]: 'One-time',
};

export const CAMPAIGN_TYPE_LABEL = {
  SALES: 'Sales',
  FOLLOW_UP: 'Follow-up',
  ONE_TIME: 'One-time',
};

export const CAMPAIGN_PRIORITY_ACTIONS = {
  UP: 'up',
  DOWN: 'down',
};

export const CAMPAIGN_MESSAGE_TYPE = {
  EMAIL: 'email',
  EMAIL_TEMPLATE: 'email_template',
  SMS: 'sms',
};

export const CAMPAIGN_WARNINGS_TYPE = {
  CONTACT_INFO: 'Contact Info',
  MESSAGING_ASSISTANT: 'Messaging Assistant',
  EMAIL_INTEGRATION: 'Email Integration',
};

export const CAMPAIGN_VALIDATION_TYPE = {
  CONTACT_INFO: 'sales',
  EMAIL_INTEGRATION: 'email',
};

export const CRITERION_NAME = {
  TOUR_SCHEDULED: 'tour_scheduled',
  MOVED_IN: 'moved_in',
  TOUR_SCHEDULED_EXTERNAL: 'tour_scheduled_external',
};

export const EMAIL_TEMPLATE_TYPE = {
  USER: 'user',
  FURTHER: 'further',
};
