import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Checkbox, Dimmer, Icon, Loader, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { completeTakeOverMessagingSession, getTakeOverMessagingSession, startTakeOverMessagingSession } from 'apis';
import { STATUSES } from '../../../constants';

const StyledCheckbox = styled.div`
  transform: scale(1.5);
  margin: 0 20px;

  i {
    margin-left: 10px;
  }
`;

const InstantResponsesTakeOverBox = () => {
  const { t } = useTranslation();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [takeOverMessagingActive, setTakeOverMessagingActive] = useState(false);

  useEffect(() => {
    const loadSession = async () => {
      setLoaderStatus(STATUSES.LOADING);
      try {
        const response = await getTakeOverMessagingSession();
        if (response?.completed !== undefined) {
          setTakeOverMessagingActive(true);
        }
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        toast.error(`There was an error with loading take over messaging status`);
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    loadSession();
  }, []);

  const toggleTakeOverMessaging = async (e, { checked }) => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      if (checked) {
        await startTakeOverMessagingSession();
      } else {
        await completeTakeOverMessagingSession();
      }

      setTakeOverMessagingActive(checked);
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      toast.error(`There was an error with deactivating messaging`);
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <StyledCheckbox>
        <Checkbox
          checked={takeOverMessagingActive}
          toggle
          onChange={toggleTakeOverMessaging}
          label="Take Over Messaging"
        />
        <Popup
          trigger={<Icon name="question circle outline" />}
          content={`When this is turned on all messages to ${t('leads')} will be sent with 4 minutes delay`}
          position="bottom right"
        />
      </StyledCheckbox>
    </>
  );
};

export default InstantResponsesTakeOverBox;
