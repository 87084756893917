import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Pagination } from 'semantic-ui-react';

import { Table } from 'components/lib';
import { getCommunityFeaturesOverview } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PAGINATION, STATUSES } from '../../../constants';

import FeaturesOverviewCommunityEntries from './FeaturesOverviewCommunityEntries';
import { FeaturesOverviewFilters } from './FeaturesOverviewFilters';
import { useFeatureOverviewFilters } from './hooks';

const FeaturesOverviewCommunity = () => {
  const { userId, communityId } = useParams();

  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [featureEntries, setFeatureEntries] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [filters, setFilters] = useFeatureOverviewFilters();

  useEffect(() => {
    const fetchFeatureEntries = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await getCommunityFeaturesOverview(userId, communityId, filters);

        setFeatureEntries(results);
        setTotalPages(Math.ceil(count / filters.pageSize));
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch community feature entries');
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    fetchFeatureEntries();
  }, [filters]);

  const handleFiltersChange = (data) => {
    setFilters((draft) => ({
      ...draft,
      ...data,
      page: PAGINATION.PAGE,
    }));
  };

  const handlePageChange = (page) => {
    setFilters((draft) => ({ ...draft, page }));
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading feature entries...</Loader>
      </Dimmer>

      <FeaturesOverviewFilters onChange={handleFiltersChange} filters={filters} />

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Actor</Table.HeaderCell>
            <Table.HeaderCell>Author</Table.HeaderCell>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>Feature</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <FeaturesOverviewCommunityEntries entries={featureEntries} />
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="8" textAlign="left">
              {totalPages > 1 && (
                <Pagination activePage={filters.page} onPageChange={handlePageChange} totalPages={totalPages} />
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default FeaturesOverviewCommunity;
