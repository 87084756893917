import React from 'react';
import { Label, Icon, Header } from 'semantic-ui-react';

import { Module } from './Module';
import { Answers } from './Answers';
import { Code } from './Code';

export const Submit = ({ node }) => {
  const text = node?.module?.text;

  return (
    <Module node={node}>
      <Label color="grey">Background Operation</Label>
      <Label color="black">
        <Icon name="send" /> Submit Lead Info
      </Label>
      <Header as="h5">Analytics tracking code:</Header>
      <Code>{text || 'NONE'}</Code>
      <Answers node={node} />
    </Module>
  );
};
