import { Button, Dimmer, Loader, Sticky } from 'semantic-ui-react';
import React, { useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import ChatSaveModal from 'components/chats/ChatSaveModal';
import SearchInput from 'components/SearchInput';
import ChatValidationModal from 'components/chats/ChatValidation/ChatValidationModal';
import { parseErrorResponse } from 'apis/utils';
import { createValidateChats, exportPricing } from 'apis';
import { downloadFile, formatAnyDate, getObjectDiff } from 'components/utils';
import { createPublishChats } from '../../../api';
import { SettingsContext } from '../SettingsContext';
import { PricingForm } from '../../common/PricingForm';
import { STATUSES, STICKY_OFFSETS } from '../../../constants';
import { PageHeader } from '../../common';
import { ImportPricingDialog } from './ImportPricingDialog';

const Wrapper = styled.div`
  position: relative;
  min-height: 60vh;
  padding: 17px 30px 0 30px;

  h4.ui.header {
    color: #666;
  }
`;

const ImportExportContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4rem;
`;

export const Pricing = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { communities, loaderStatus, fetchCommunities, setCommunities, updateCommunityPricings } =
    useContext(SettingsContext);
  const formRef = useRef();
  const wrapperRef = useRef();

  const [values, setValues] = useState();
  const [requestData, setRequestData] = useState();

  const [openSaveModal, setOpenSaveModal] = useState(false);

  const [validationStatus, setValidationStatus] = useState(STATUSES.IDLE);
  const [validationErrors, setValidationErrors] = useState();
  const [openValidateModal, setOpenValidateModal] = useState(false);

  const [exportStatus, setExportStatus] = useState(STATUSES.IDLE);
  const [importDialogOpen, setImportDialogOpen] = useState(false);

  const onSubmit = () => {
    if (formRef.current) {
      if (!formRef.current.isValid) {
        toast.warning('Data validation error. Please provide valid inputs.');
        return;
      }
      formRef.current.handleSubmit();
      const diff = getObjectDiff(formRef.current.values, formRef.current.initialValues);

      const diffKeys = Object.keys(diff).map((key) => key.split('.')[1]);
      const diffCommunities = diffKeys.map((key) => {
        const { id, name, starting_cost, ending_cost, prices } = communities[key];
        return { id, name, starting_cost, ending_cost, prices };
      });
      window?.analytics?.track('Pricing Options Updated', {
        communities: diffCommunities,
      });
    }
  };

  const isStartingCostChanged = (communityId, startingCost) => {
    const community = communities.find((p) => p.id === communityId);
    return parseInt(community.starting_cost) !== parseInt(startingCost);
  };

  const isEndingCostChanged = (communityId, endingCost) => {
    const community = communities.find((p) => p.id === communityId);
    return parseInt(community.ending_cost) !== parseInt(endingCost);
  };

  const isPriceCostChanged = (communityId, priceId, priceCost) => {
    const community = communities.find((c) => c.id === communityId);
    const price = community.prices.find((p) => p.id === priceId);
    return parseInt(price.cost) !== parseInt(priceCost);
  };

  const getRequestData = (shouldPublish) => {
    const chatsForPublish = new Set();
    const starting_costs = values.communities.map((community) => {
      if (shouldPublish && community.chats && isStartingCostChanged(community.id, community.starting_cost)) {
        community.chats.forEach((chat) => {
          chatsForPublish.add(chat);
        });
      }
      return { id: community.id, starting_cost: community.starting_cost };
    });
    const ending_costs = values.communities.map((community) => {
      if (shouldPublish && community.chats && isEndingCostChanged(community.id, community.starting_cost)) {
        community.chats.forEach((chat) => {
          chatsForPublish.add(chat);
        });
      }
      return { id: community.id, ending_cost: community.ending_cost };
    });
    const pricing = values.prices
      .flatMap((pd) => Object.values(pd))
      .map((price) => {
        const community = communities.find((c) => c.id === price.community);
        if (
          community &&
          community.chats &&
          shouldPublish &&
          isPriceCostChanged(price.community, price.id, price.cost)
        ) {
          community.chats.forEach((chat) => {
            chatsForPublish.add(chat);
          });
        }

        return { id: price.id, cost: price.cost };
      });

    return { pricing, starting_costs, ending_costs, chats: Array.from(chatsForPublish) };
  };

  const savePricing = async () => {
    try {
      setLoading(true);

      const { pricing, starting_costs, ending_costs } = getRequestData(false);
      await updateCommunityPricings({ pricing, starting_costs, ending_costs });

      toast.success('Pricing options saved');
      setOpenSaveModal(false);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error('Error updating pricing options. Please try again later, or contact us for help.');
      setLoading(false);
    }
  };

  const savePricingAndPublish = async () => {
    try {
      setLoading(true);

      const { pricing, starting_costs, ending_costs, chats } = requestData;

      await updateCommunityPricings({ pricing, starting_costs, ending_costs });
      await createPublishChats({ chats });

      toast.success('Pricing options saved and published');
      setOpenValidateModal(false);
      setRequestData(null);
      setValues(null);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error('Error updating pricing options. Please try again later, or contact us for help.');
      setLoading(false);
    }
  };

  const handleOpenSaveModal = (values) => {
    setOpenSaveModal(true);
    setValues(values);
  };

  const handleCancelSave = () => {
    setOpenSaveModal(false);
    setValues(null);
  };

  const validateChats = async () => {
    try {
      setValidationStatus(STATUSES.LOADING);
      setOpenValidateModal(true);
      const { pricing, starting_costs, ending_costs, chats } = getRequestData(true);

      const response = await createValidateChats({ chats });

      setRequestData({ pricing, starting_costs, ending_costs, chats });
      setValidationErrors(response);
      setValidationStatus(STATUSES.LOADED);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to validate chats.'));
    }
  };

  const handleConfirmSave = async ({ shouldPublish }) => {
    if (shouldPublish) {
      setOpenSaveModal(false);
      await validateChats();
    } else {
      await savePricing();
    }
  };

  const handleCloseValidation = () => {
    setValidationErrors(null);
    setRequestData(null);
    setOpenValidateModal(false);
  };

  const handleImport = () => {
    setImportDialogOpen(true);
  };

  const handleExport = async () => {
    setExportStatus(STATUSES.LOADING);
    try {
      const response = await exportPricing();
      downloadFile({ data: response }, `Pricing_${formatAnyDate(new Date(), 'yyyyMMdd_hhmmss')}.csv`);
      setExportStatus(STATUSES.LOADED);
    } catch (error) {
      setExportStatus(STATUSES.FAILURE);
      console.log('error', error);
      toast.error(parseErrorResponse(error, 'Unable to Export pricing.'));
    }
  };

  const handleImportDialogClose = (shouldRefresh) => {
    setImportDialogOpen(false);
    if (shouldRefresh) {
      setCommunities([]);
      fetchCommunities();
    }
  };

  const communitiesList = useMemo(
    () =>
      !searchTerm || !communities
        ? communities
        : communities.filter((community) => community?.name?.toLowerCase().includes(searchTerm.toLowerCase())),
    [communities, searchTerm]
  );

  return (
    <Wrapper ref={wrapperRef}>
      <Sticky context={wrapperRef}>
        <PageHeader
          title="Pricing Options"
          subtitle="Define prices for available rooms and service options"
          block
          actions={
            <Button primary type="submit" content="Save" onClick={onSubmit} loading={loading} disabled={loading} />
          }
        />
      </Sticky>

      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <ImportExportContainer>
        <Button primary onClick={handleImport}>
          Import
        </Button>
        <Button primary onClick={handleExport} loading={exportStatus === STATUSES.LOADING}>
          Export
        </Button>
      </ImportExportContainer>
      <SearchInput
        value={searchTerm}
        fluid
        onSearch={setSearchTerm}
        placeholder={`Search ${t('communities')} by name`}
        style={{ marginBottom: '1em' }}
      />
      <PricingForm
        communities={communitiesList}
        formRef={formRef}
        onSubmit={handleOpenSaveModal}
        tableHeaderOffset={STICKY_OFFSETS.SMALL_OFFSET}
      />

      <ChatSaveModal open={openSaveModal} loading={loading} onConfirm={handleConfirmSave} onCancel={handleCancelSave} />
      <ChatValidationModal
        open={openValidateModal}
        loading={validationStatus === STATUSES.LOADING}
        publishing={loading}
        validationErrors={validationErrors}
        onConfirm={savePricingAndPublish}
        onClose={handleCloseValidation}
      />
      <ImportPricingDialog importDialogOpen={importDialogOpen} onDialogClose={handleImportDialogClose} />
    </Wrapper>
  );
};
