import { Button, Dimmer, Loader, Sticky } from 'semantic-ui-react';
import React, { useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import SearchInput from 'components/SearchInput';
import { SettingsContext } from '../SettingsContext';
import { ContactInfoForm } from './ContactInfoForm';
import { STATUSES, STICKY_OFFSETS } from '../../../constants';
import { PageHeader } from '../../common';

const Wrapper = styled.div`
  position: relative;
  min-height: 60vh;
  padding: 17px 30px 0 30px;

  h4.ui.header {
    color: #666;
  }
`;

export const ContactInfo = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { communities, loaderStatus, updateCommunitiesFromContactInfo } = useContext(SettingsContext);

  const formRef = useRef();
  const wrapperRef = useRef();

  const onSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const onSave = async (communities) => {
    try {
      setLoading(true);
      await updateCommunitiesFromContactInfo(communities);
      toast.success('Contact info saved');
      setLoading(false);
    } catch (e) {
      toast.error('Error saving contact info. Please try again later, or contact us for help.');
      setLoading(false);
    }
  };

  const communitiesList = useMemo(
    () =>
      !searchTerm || !communities
        ? communities
        : communities.filter((community) => community?.name?.toLowerCase().includes(searchTerm.toLowerCase())),
    [communities, searchTerm]
  );

  return (
    <Wrapper ref={wrapperRef}>
      <Sticky context={wrapperRef}>
        <PageHeader
          title="Define Contact Info"
          subtitle={`Define contact emails and phone numbers for your locations. We'll send email notifications and SMS notification with new ${t(
            'lead'
          )}'s information to these addresses and phone numbers.`}
          block
          actions={
            <Button
              floated="right"
              primary
              type="submit"
              content="Save"
              onClick={onSubmit}
              loading={loading}
              disabled={loading}
            />
          }
        />
      </Sticky>

      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <SearchInput
        value={searchTerm}
        fluid
        onSearch={setSearchTerm}
        placeholder={`Search ${t('communities')} by name`}
        style={{ marginBottom: '1em' }}
      />
      <ContactInfoForm
        communities={communitiesList}
        formRef={formRef}
        onSubmit={onSave}
        tableHeaderOffset={STICKY_OFFSETS.SMALL_OFFSET}
      />
    </Wrapper>
  );
};
