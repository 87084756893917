import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { uploadCommunityAssets } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES, MAX_FILE_UPLOAD_SIZE, MAX_FILE_UPLOAD_SIZE_MB } from '../../../constants';
import { UploadDropzone } from '../../common/upload/UploadDropzone';
import { ACTIONS, CommunitiesContext } from '../../common/communities/CommunitiesContext';

export const UserCommunityImagesUpload = ({ disabled }) => {
  const { communityId } = useParams();
  const { dispatch } = useContext(CommunitiesContext);

  const [open, setOpen] = useState(false);
  const [uploadingStatus, setUploadingStatus] = useState(STATUSES.IDLE);
  const [images, setImages] = useState([]);

  const onConfirmSelection = async () => {
    try {
      setUploadingStatus(STATUSES.LOADING);
      const formData = new FormData();

      for (const image of images) {
        if (image.size > MAX_FILE_UPLOAD_SIZE) {
          toast.error(`File size of ${image.name} should be less than ${MAX_FILE_UPLOAD_SIZE_MB}MB`);
          setUploadingStatus(STATUSES.FAILURE);
          return;
        }
        formData.append('images', image);
      }

      const community = await uploadCommunityAssets(communityId, formData);
      dispatch({ type: ACTIONS.SET_COMMUNITY, payload: { communityId: community.id, communityData: community } });

      toast.success('Images uploaded successfully!');
      setUploadingStatus(STATUSES.LOADED);
      setOpen(false);
    } catch (e) {
      const message = parseErrorResponse(e, 'Trouble uploading images!');
      toast.error(message);
      setUploadingStatus(STATUSES.FAILURE);
    }
  };

  const isUploading = uploadingStatus === STATUSES.LOADING;

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={<Button icon="upload" content="Upload" disabled={disabled} />}
    >
      <Modal.Header>Select images from your computer</Modal.Header>
      <Modal.Content>
        <UploadDropzone
          onSelected={(files) => setImages(files)}
          buttonLabel="Select Images"
          formats="image/*"
          maxFiles={20}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={isUploading} onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button primary disabled={isUploading} loading={isUploading} onClick={onConfirmSelection}>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
