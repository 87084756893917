import React, { useCallback, useMemo } from 'react';
import { Editor, defaultEditorConfig } from 'components/editor';
import { debounce } from 'components/utils';
import { DEBOUNCE_TIMEOUT } from 'constants';

export default function EmailEditor({
  initialValue,
  onChange,
  editorRef,
  emailStyle,
  loading = false,
  tags = [],
  imageList = [],
}) {
  const debouncedOnChange = useCallback(
    debounce((evt, editor) => {
      onChange(evt, editor.getContent());
    }, DEBOUNCE_TIMEOUT),
    [onChange]
  );
  const onChangeHandler = useCallback(
    (evt, editor) => {
      debouncedOnChange(evt, editor);
    },
    [debouncedOnChange]
  );

  const memoizedInitialValue = useMemo(() => initialValue, []);

  const editorConfig = useMemo(
    () => ({
      ...defaultEditorConfig,
      mergetags_prefix: '{',
      mergetags_suffix: '}',
      mergetags_list: tags.filter((tag) => tag.enabled).map((tag) => ({ value: tag.name, title: tag.key })),
      content_style: emailStyle,
      advtemplate_templates: [
        {
          title: 'CTA Button',
          content: '<a class="cta">CTA</a>',
        },
        {
          title: 'Header',
          content: '<h3>{community_name}</h3><h1>Title</h1><hr/>',
        },
        {
          title: 'Footer',
          content:
            '<div class="footer"><hr/>{community_name} · {address}<br/><a href="{community_url}">Community Website</a></div>',
        },
        {
          title: 'Community Logo',
          content:
            '<img style="width: 36px; height: 36px; border-radius: 50%; background-color: #d8d8d8; margin-bottom: 20px;" src="{community_logo}" width="36px" height="36px">',
        },
      ],
      automatic_uploads: true,
      file_picker_types: 'image',
      image_list: imageList,
      images_upload_url: `${process.env.REACT_APP_BASE_URL}/api/campaigns/file-upload`.replace(/(?<!\:)\/\//, '/'),
      images_upload_credentials: true,
      image_class_list: [{ title: 'Default', value: 'user-uploaded-image' }],
    }),
    [tags, emailStyle, imageList]
  );

  if (loading) {
    return null;
  }

  return (
    <Editor
      initialValue={memoizedInitialValue}
      init={editorConfig}
      editorRef={editorRef}
      onEditorChange={onChangeHandler}
    />
  );
}
