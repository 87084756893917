import React from 'react';
import styled from 'styled-components';

import { Button, Image } from 'components/lib';
import { useCheckMobileScreen } from 'utils/hooks';
import MagicLinkImage from '../../assets/images/email-magic-link-icon.png';

const IconWrapper = styled.div`
  display: inline-block;
  top: 5px;
  left: 15px;
  position: absolute;
`;

const LabelWrapper = styled.div`
  width: 100%;
  position: relative;
  line-height: 48px;
  display: inline-block;
  font-weight: normal;
`;

const MagicLinkButton = styled(Button)`
  &&& {
    position: relative;
    height: 48px;
    padding: 0px;
    border: solid 1px #777;
  }
`;

const EmailMagicLinkButton = ({ loading, disabled, onClick }) => {
  const isMobile = useCheckMobileScreen();

  return (
    <MagicLinkButton primary fluid color="black" loading={loading} disabled={disabled} onClick={onClick}>
      {!isMobile && (
        <IconWrapper>
          <Image src={MagicLinkImage} size="mini" />
        </IconWrapper>
      )}
      <LabelWrapper>Log in with an email link instead</LabelWrapper>
    </MagicLinkButton>
  );
};

export default EmailMagicLinkButton;
