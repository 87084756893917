export const parseNumberArrayQueryParam = (queryParamArray) => {
  if (!queryParamArray) {
    return [];
  }
  return queryParamArray.split(',').reduce((acc, value) => {
    const valueNumber = Number(value);

    if (!Number.isNaN(valueNumber)) {
      acc.push(valueNumber);
    }

    return acc;
  }, []);
};
