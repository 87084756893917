import { Table } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { InputField } from '../common/fields/InputField';
import { CsvField } from '../CsvField';
import { scrollIntoView } from '../utils';
import { StickyTableHeader } from '../common/PricingTable';
import { STICKY_OFFSETS } from '../../constants';

const validationSchema = Yup.object({
  lead_email: Yup.string().email(),
  other_lead_emails: Yup.array().of(Yup.string().email()),
});

const Row = React.memo(({ community, index, isTableFooter }) => {
  const emailValidator = Yup.string().email('Must be a valid email address or a list of valid email addresses');
  const Cell = isTableFooter ? Table.HeaderCell : Table.Cell;

  const [isDataValid, setIsDataValid] = useState(true);

  useEffect(() => {
    const checkData = async () => {
      const response = await validationSchema.isValid({
        lead_email: community.lead_email,
        other_lead_emails: community.other_lead_emails,
      });

      setIsDataValid(response);
    };
    checkData();
  }, [community]);

  return (
    <Table.Row negative={!isDataValid} className="ui form">
      <Cell>{community.name}</Cell>
      <Cell>
        <InputField
          name={`communities[${index}].lead_email`}
          placeholder="Main Contact"
          onError={scrollIntoView}
          fluid
        />
      </Cell>
      <Cell>
        <CsvField
          name={`communities[${index}].other_lead_emails`}
          placeholder="Secondary Contacts"
          csvElementValidator={emailValidator}
          onError={scrollIntoView}
        />
      </Cell>
    </Table.Row>
  );
});

export const ContactInfoTable = ({ values, isTableFooter, headerOffset = STICKY_OFFSETS.NO_OFFSET }) => (
  <Table fixed singleLine>
    <StickyTableHeader offset={headerOffset}>
      <Table.Row>
        <Table.HeaderCell width={4}>Location</Table.HeaderCell>
        <Table.HeaderCell width={4}>Main Contact Info</Table.HeaderCell>
        <Table.HeaderCell width={8}>Secondary Contacts</Table.HeaderCell>
      </Table.Row>
    </StickyTableHeader>

    <Table.Body>
      {values.communities &&
        values.communities.map((community, i) => (
          <Row key={i} community={community} index={i} isTableFooter={isTableFooter} />
        ))}
    </Table.Body>
  </Table>
);
