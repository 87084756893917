import React from 'react';

import { Table, Button, Icon } from 'semantic-ui-react';

import MappingRuleRow from './MappingRuleRow';

const MappingRulesTable = ({
  ruleTypes,
  fieldName,
  mappingRules = [],
  onSwap,
  onRemove,
  onPush,
  onReplace,
  disabled,
}) => {
  const onRuleMappingTypeChange = (rowId) => (mappingType) => {
    const rule = ruleTypes.find((rt) => rt.mappingType === mappingType);
    onReplace(rowId, {
      mapping_type: mappingType,
      ...rule.defaults,
    });
  };

  const handleAddRule = () => {
    const rule = ruleTypes[0];
    onPush({
      mapping_type: rule.mappingType,
      ...rule.defaults,
    });
  };

  const handleMoveUp = (rowId) => () => {
    onSwap(rowId, rowId - 1);
  };

  const handleMoveDown = (rowId) => () => {
    onSwap(rowId, rowId + 1);
  };

  const handleRemove = (rowId) => () => {
    onRemove(rowId);
  };

  return (
    <Table basic="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>Order</Table.HeaderCell>
          <Table.HeaderCell width={1}>Mapping Type</Table.HeaderCell>
          <Table.HeaderCell width={12}>Rule (If)</Table.HeaderCell>
          <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {mappingRules.map((rule, index) => (
          <MappingRuleRow
            key={index}
            baseFieldName={`${fieldName}[${index}]`}
            rowId={index}
            rowsCount={mappingRules.length}
            rule={rule}
            ruleTypes={ruleTypes}
            onRuleMappingTypeChange={onRuleMappingTypeChange(index)}
            handleMoveUp={handleMoveUp(index)}
            handleMoveDown={handleMoveDown(index)}
            handleRemove={handleRemove(index)}
            disabled={disabled}
          />
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="4">
            <Button
              type="button"
              icon
              labelPosition="left"
              primary
              size="tiny"
              onClick={handleAddRule}
              disabled={disabled}
            >
              <Icon name="fork" /> Add rule
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default MappingRulesTable;
