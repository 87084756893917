import React from 'react';
import styled from 'styled-components';

import { Question } from './Question';
import { Answers } from './Answers';

export const NodeWrapper = styled.div`
  background: white;
`;

export const Node = ({ node, parent, data, tree, parentCount }) => {
  const { source, source_type } = node;

  const isSubmitOrEval = ['submit', 'eval'].includes(source_type);

  const answers = data.filter((d) => d.source === source);
  const answerCount = isSubmitOrEval ? parentCount : answers.reduce((r, v) => r + v.count, 0);
  const showQuestion = !isSubmitOrEval;

  return (
    <NodeWrapper>
      {showQuestion && <Question node={node} parent={parent} answerCount={answerCount} parentCount={parentCount} />}
      <Answers
        node={node}
        answers={answers}
        data={data}
        tree={tree}
        source={source}
        sourceModuleType={source_type}
        answerCount={answerCount}
        parentCount={parentCount}
      />
    </NodeWrapper>
  );
};
