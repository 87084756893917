import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Button, Dimmer, Loader } from 'components/lib';
import { Segment } from 'semantic-ui-react';
import ContactRuleList from './ContactRuleList';
import { useContactRules } from './ContactRuleContext';
import ContactRuleFilter from './ContactRuleFilter';
import ContactRuleModal from './ContactRuleModal';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 60px;
    font-family: var(--font-family-bold);
    color: var(--text);
  }

  .actions {
    padding-right: 20px;
  }
`;
const ContactRulesRoot = () => {
  const { filters, fetchContactRules, loading, handleOpenModal } = useContactRules();

  useEffect(() => {
    fetchContactRules(filters);
  }, [filters]);

  return (
    <Segment basic>
      <Dimmer active={loading} inverted>
        <Loader inverted active>
          Loading
        </Loader>
      </Dimmer>
      <Header>
        <div className="title">Contact rules</div>
        <div className="actions">
          <Button primary color="blue" onClick={handleOpenModal}>
            Add new rule
          </Button>
        </div>
      </Header>
      <ContactRuleFilter />
      <ContactRuleList />
      <ContactRuleModal />
    </Segment>
  );
};

export default ContactRulesRoot;
