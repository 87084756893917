import React, { useContext, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { debounce } from 'components/utils';
import { OnboardingContext } from '../OnboardingContext';
import { Container } from './common';
import { QualifyingQuestionsForm } from '.';

const VALIDATION_SCHEMA = Yup.object({
  qualifyingQuestions: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      question: Yup.string().required('Please enter a question.'),
      responses: Yup.array().of(Yup.string().required('Please enter a response.')).required(),
    })
  ),
});

export const QualifyingQuestions = () => {
  const { handleNextStep, onboarding } = useContext(OnboardingContext);
  const [numberOfColumns, setNumberOfColumns] = useState(0);
  const conatinerRef = useRef(null);

  const initialFormValues = {
    qualifyingQuestions: onboarding?.collectedData?.qualifyingQuestions || [],
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      debounce(() => {
        setNumberOfColumns(Math.max(Math.floor((conatinerRef.current.clientWidth - 180) / 300), 1));
      }),
      20
    );
    resizeObserver.observe(conatinerRef?.current);
    return () => resizeObserver.unobserve(conatinerRef?.current);
  }, [conatinerRef?.current]);

  const onFormSubmit = (values) => {
    handleNextStep(values);
  };
  const numberOfRows = numberOfColumns && Math.ceil(onboarding?.qualifyingQuestions?.length / numberOfColumns);

  return (
    <Container maxWidth="90rem" ref={conatinerRef}>
      <h2>Ask your leads questions</h2>
      <p>
        Select up to five questions that you would like to ask your leads and specify your answer.
        <br /> We always ask for <b>name, email and phone</b> number, so no need to add those.
      </p>
      <Formik
        initialValues={initialFormValues}
        onSubmit={onFormSubmit}
        validationSchema={VALIDATION_SCHEMA}
        enableReinitialize
      >
        <QualifyingQuestionsForm numberOfColumns={numberOfColumns} numberOfRows={numberOfRows} />
      </Formik>
    </Container>
  );
};
