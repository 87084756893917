import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';

const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 55px;
  height: 26px;
  padding: 1px 8px;
  border-radius: 13px;

  background-color: ${(props) => props.backgroundColor};
`;

export const SettingsOnOffIndicator = ({ status, onTrue = () => {}, onFlase = () => {} }) => {
  const iconColor = status ? '#0ab776' : '#fa7550';
  const iconName = status ? 'check_circle' : 'cancel';
  const backgroundColor = status ? '#defff3' : '#fce8e2';

  const handleOnClick = () => (status ? onTrue() : onFlase());
  return (
    <Container backgroundColor={backgroundColor} onClick={handleOnClick}>
      <Icon name={iconName} color={iconColor} />
      <Icon name="navigate_next" color={iconColor} />
    </Container>
  );
};
