import React, { useContext } from 'react';
import styled from 'styled-components';

import { ThemeContext } from './ThemeContext';
import { MEDIA_BREAK_POINTS } from '../constants';

const Container = styled.div`
  display: flex;
  height: 100%;
  --global-color-primary: ${(props) => `#${props.brandColors.primary}`};
  --global-color-primary-lighter: ${(props) => `#${props.brandColors.primaryLighter}`};
  --global-color-primary-darker: ${(props) => `#${props.brandColors.primaryDarker}`};
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex: 1 1 auto;
  }
`;
export const GlobalStyleOverrides = ({ children }) => {
  const { brandColors } = useContext(ThemeContext);
  return <Container brandColors={brandColors}>{children}</Container>;
};
