import { useEffect, useState } from 'react';

export const usePreventUnload = () => {
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      if (dirty) {
        return 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    }

    return () => {
      window.onbeforeunload = undefined;
    };
  }, [dirty]);

  return [dirty, setDirty];
};
