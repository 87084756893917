import React from 'react';
import styled from 'styled-components';

import { Dimmer, Loader, List, Pagination, Message, Table, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import SearchInput from 'components/SearchInput';
import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import { CsvExport } from 'components/common';
import { Checkbox } from 'components/lib';

const Toggles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const CampaignConversationList = ({
  conversations,
  loading,
  page,
  search,
  filters,
  totalPages,
  onPageChange,
  onSearch,
  onToggleRepliedByLead,
  onToggleRepliedBySales,
  exportAsCSV,
}) => {
  const { t } = useTranslation();
  const { repliedByLead, repliedBySales } = filters;

  const handleSelectConversation = (conversation) => () => {
    window.open(`/conversations/${conversation.lead.id}`, '_blank');
  };

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <If condition={conversations?.length === 0}>
        <Message info>No messages</Message>
      </If>
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <SearchInput fluid onSearch={onSearch} placeholder="Search" value={search} />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="4">
              <Toggles>
                <Checkbox
                  slider
                  label={`Replied by ${t('lead')}`}
                  checked={repliedByLead}
                  onChange={onToggleRepliedByLead}
                />
                <Checkbox slider label="Replied by Sales" checked={repliedBySales} onChange={onToggleRepliedBySales} />
              </Toggles>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Table.HeaderCell textAlign="left" width={2}>
              {capitalize(t('lead'))}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left" width={5}>
              Last Message
            </Table.HeaderCell>
            <Table.HeaderCell width={4}>Campaign</Table.HeaderCell>
            <Table.HeaderCell width={2}>{capitalize(t('community'))}</Table.HeaderCell>
            <Table.HeaderCell>Message Type</Table.HeaderCell>
            <Table.HeaderCell>Replied by {capitalize(t('lead'))}</Table.HeaderCell>
            <Table.HeaderCell>Replied by Sales</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {conversations?.map((c, i) => (
            <Table.Row textAlign="center" key={i} onClick={handleSelectConversation(c)}>
              <Table.Cell textAlign="left">{c.lead.name}</Table.Cell>
              <Table.Cell textAlign="left">{c.lastMessage?.message}</Table.Cell>
              <Table.Cell>{c.campaign.name}</Table.Cell>
              <Table.Cell>{c.lead.community.name}</Table.Cell>
              <Table.Cell>
                {c.lastMessage?.messageType === 'sms' && <List.Item>SMS</List.Item>}
                {c.lastMessage?.messageType === 'email' && <List.Item>Email</List.Item>}
              </Table.Cell>
              <Table.Cell>
                <If condition={c.repliedByLead}>
                  <Icon name="check" color="grey" />
                </If>
              </Table.Cell>
              <Table.Cell>
                <If condition={c.repliedBySales}>
                  <Icon name="check" color="grey" />
                </If>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4">
              <Pagination
                activePage={page}
                totalPages={totalPages || 1}
                onPageChange={(e, { activePage }) => onPageChange(activePage)}
                siblingRange={6}
              />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="3" textAlign="right">
              <If condition={conversations?.length > 0}>
                <CsvExport content="CSV" exportAction={exportAsCSV} skipDownload />
              </If>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default CampaignConversationList;
