import React, { createContext } from 'react';
import { BRANDING_KEYS, DEFAULT_BRANDING_COLORS } from '../constants';

export const ThemeContext = createContext();

export const ThemeContextProvider = (props) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  Object.keys(params)
    .filter((p) => Object.values(BRANDING_KEYS).some((key) => p === key))
    .map((p) => window.sessionStorage.setItem(p, params[p]));

  const isWhitelabeled = Boolean(
    (window.sessionStorage.getItem(BRANDING_KEYS.WHITELABELED) || '').replace(/\s*(false|null|undefined|0)\s*/i, '')
  );

  const brandColors = {
    primary: window.sessionStorage.getItem(BRANDING_KEYS.BRAND_COLOR) || DEFAULT_BRANDING_COLORS.BRAND_COLOR,
    primaryLighter:
      window.sessionStorage.getItem(BRANDING_KEYS.BRAND_COLOR_LIGHTER) || DEFAULT_BRANDING_COLORS.BRAND_COLOR_LIGHTER,
    primaryDarker:
      window.sessionStorage.getItem(BRANDING_KEYS.BRAND_COLOR_DARKER) || DEFAULT_BRANDING_COLORS.BRAND_COLOR_DARKER,
  };

  const provide = { brandColors, isWhitelabeled };

  return <ThemeContext.Provider value={provide} {...props} />;
};
