import React from 'react';
import styled from 'styled-components';

import { Table, Button, Pagination } from 'components/lib';
import { PromptButton } from 'components/common';
import { STATUSES } from 'constants';
import { If } from 'components/If';

const FloorPlanActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1ch;
`;

export const FloorPlanImage = styled.img`
  max-width: 50px;
`;

const UserCommunityFloorPlansTable = ({
  currentFloorPlans = [],
  onPageChange,
  activePage,
  totalPages,
  onConfirm,
  loading,
  onEdit,
}) => {
  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.HeaderCell>Room size</Table.HeaderCell>
          <Table.HeaderCell>Square feet</Table.HeaderCell>
          <Table.HeaderCell>Number of baths</Table.HeaderCell>
          <Table.HeaderCell>Get Image</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {currentFloorPlans.map((c) => (
          <Table.Row key={c.id}>
            <Table.Cell>{c.title}</Table.Cell>
            <Table.Cell>{c.room_size}</Table.Cell>
            <Table.Cell>{c.sq_feet}</Table.Cell>
            <Table.Cell>{c.num_baths}</Table.Cell>
            <Table.Cell>
              <If condition={c.floor_plan_url}>
                <a href={c.floor_plan_url} target="_blank" rel="noreferrer">
                  <FloorPlanImage src={c.floor_plan_url} alt={c.title} />
                </a>
              </If>
            </Table.Cell>
            <Table.Cell>
              <FloorPlanActions>
                <PromptButton
                  content="Delete"
                  title="Are you sure you want to delete floor plan?"
                  onConfirm={() => onConfirm(c.id)}
                  loading={loading === STATUSES.LOADING}
                />
                <Button primary onClick={() => onEdit(c)}>
                  Edit
                </Button>
              </FloorPlanActions>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <If condition={totalPages > 1}>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="8" textAlign="left">
              <Pagination activePage={activePage} onPageChange={handlePageChange} totalPages={totalPages} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </If>
    </Table>
  );
};

export default UserCommunityFloorPlansTable;
