import React, { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  createOrUpdateCommunityEmailSubject,
  getClientDetails,
  getEmailSubjectUserOverrides,
  getEmailSubjectCommunityOverrides,
  deleteUserEmailSubject,
  removeAllCommunitySubjects,
} from 'apis';
import { parseErrorResponse } from 'apis/utils';
import EmailSubjectsCustomizationOverview from './EmailSubjectsCustomizationsOverview';
import { STATUSES } from '../../../constants';
import { PageHeader, PAGE_HEADER_MARGIN } from '../../common';

const EmailSubjectsCustomizationEdit = () => {
  const history = useHistory();
  const { userId } = useParams();

  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [selectedClient, setSelectedClient] = useState(null);
  const [userEmailSubjects, setUserEmailSubjects] = useState([]);
  const [communityEmailSubjects, setCommunityEmailSubjects] = useState([]);

  useEffect(() => {
    const fetchUserAndCommunitySubjects = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);

        const [userSubjects, communitySubjects] = await Promise.all([
          getEmailSubjectUserOverrides(parseInt(userId)),
          getEmailSubjectCommunityOverrides(parseInt(userId)),
        ]);

        setUserEmailSubjects(userSubjects);
        setCommunityEmailSubjects(communitySubjects);
        setLoaderStatus(STATUSES.LOADED);
      } catch (error) {
        toast.error(parseErrorResponse(error));
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    fetchUserAndCommunitySubjects(parseInt(userId));
  }, [userId]);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const clientDetail = await getClientDetails(userId);
        setSelectedClient(clientDetail);
      } catch (error) {
        toast.error(parseErrorResponse(error));
        history.replace('/admin/custom-email-subjects');
      }
    };

    fetchClientDetails();
  }, [userId, history]);

  const onCommunityEmailSubjectDelete = async (emailSubject) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      await createOrUpdateCommunityEmailSubject(selectedClient.id, {
        text: emailSubject.text,
        type: emailSubject.type,
        community_ids_to_delete: emailSubject.communities.map((item) => item.id),
      });

      const filteredCommunitySubjects = communityEmailSubjects.filter(
        (item) =>
          !emailSubject.communities.find(
            (subject) => emailSubject.type === item.type && subject.id === item.community.id
          )
      );
      setCommunityEmailSubjects(filteredCommunitySubjects);

      setLoaderStatus(STATUSES.LOADED);
    } catch (error) {
      toast.error(parseErrorResponse(error));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const onUserEmailSubjectDelete = async (emailSubject) => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      await deleteUserEmailSubject(emailSubject.id);
      setUserEmailSubjects(userEmailSubjects.filter((subject) => subject.id !== emailSubject.id));

      setLoaderStatus(STATUSES.LOADED);
    } catch (error) {
      toast.error(parseErrorResponse(error));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const onAllCommunityEmailSubjectsDelete = async () => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      await removeAllCommunitySubjects(selectedClient.id);
      setCommunityEmailSubjects([]);

      setLoaderStatus(STATUSES.LOADED);
    } catch (error) {
      toast.error(parseErrorResponse(error));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <>
      <PageHeader
        title="Customize Email Subjects"
        subtitle="Edit the email subject customization for the client."
        margin={PAGE_HEADER_MARGIN.SMALL}
      />
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <EmailSubjectsCustomizationOverview
        loaderStatus={loaderStatus}
        eligibleClients={selectedClient ? [selectedClient] : []}
        userEmailSubjects={userEmailSubjects}
        communityEmailSubjects={communityEmailSubjects}
        onUserEmailSubjectDelete={onUserEmailSubjectDelete}
        onCommunityEmailSubjectDelete={onCommunityEmailSubjectDelete}
        onAllCommunityEmailSubjectsDelete={onAllCommunityEmailSubjectsDelete}
      />
    </>
  );
};

export default EmailSubjectsCustomizationEdit;
