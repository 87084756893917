import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const TableContainer = styled.div`
  display: table;
  width: 100%;
`;

const Row = styled.div`
  display: table-row;
  position: relative;
  &:nth-child(2) {
    > div {
      padding-top: 20px;
    }
  }
`;

const ThCell = styled.div`
  display: table-cell;
  padding: 10px;

  font-family: var(--font-family-bold);
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.5px;
  color: var(--text);
`;
const Cell = styled.div`
  display: table-cell;
  padding: 10px;
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const Divider = styled.div`
  width: 540px;
  left: -30px;
  top: 35px;
  height: 1px;
  background-color: var(--line-standard);
  position: absolute;
`;

const Copy = styled.div`
  font-family: var(--font-family);
  font-size: 10px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--link);
  cursor: pointer;
`;

export const DnsInfo = ({ rows = [{}, {}] }) => {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };
  return (
    <TableContainer>
      <Row>
        <ThCell>TYPE</ThCell>
        <ThCell>HOST</ThCell>
        <ThCell>VALUE</ThCell>
        <Divider />
      </Row>
      {rows?.map((row, index) => (
        <Row key={index}>
          <Cell>CNAME</Cell>
          <Cell>
            em1902.sample.ict.com<Copy onClick={() => handleCopy('test1')}>Copy</Copy>
          </Cell>
          <Cell>
            u42301723052.u32092sendgrid.net<Copy onClick={() => handleCopy('test2')}>Copy</Copy>
          </Cell>
        </Row>
      ))}
    </TableContainer>
  );
};
