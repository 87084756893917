import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';

import { getAuthentication } from 'apis';
import { Button, Image } from 'components/lib';
import { ConversationContext } from 'components/conversation/ConversationContext';
import { Calendar, Google, Outlook, Office365, ICloud } from '../../../assets/images';
import { CONVERSATION_ACTIONS } from './constants';
import {
  ConversationModal,
  ConversationModalContent,
  ConversationModalFooter,
  ConversationModalHeader,
} from './ConversationModal';

const Description = styled.p`
  margin-top: 10px;
  font-family: var(--font-family);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--light-text);
`;

const RightActions = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: end;
`;

const CalendarIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 37px 0 56px 0;
`;

const CalendarProvidersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
  padding: 0 22px;
`;

export const CronofyIntegrationModal = () => {
  const { selectedConversation, activeModal, showModal, calendars, nextModalAction, setNextModalAction } =
    useContext(ConversationContext);

  const handleLink = useCallback(async () => {
    try {
      if (selectedConversation) {
        const location = window.location.href;
        const redirectUrl = nextModalAction ? `${location}?action=${nextModalAction}` : location;
        const calendarLink = await getAuthentication(selectedConversation.community.id, redirectUrl);
        window.open(calendarLink, '_self');
      }
    } catch (error) {
      console.error('error', error);
    }
  }, [selectedConversation, nextModalAction]);

  const handleNotNowClick = () => {
    const modalType = calendars.length > 0 && nextModalAction ? nextModalAction : null;
    showModal(modalType);
  };

  const handleOnClose = () => {
    setNextModalAction(null);
    showModal(null);
  };

  return (
    <ConversationModal
      closeIcon
      onClose={handleOnClose}
      onOpen={() => showModal(CONVERSATION_ACTIONS.LINK)}
      open={activeModal === CONVERSATION_ACTIONS.LINK}
      size="tiny"
    >
      <ConversationModalContent>
        <ConversationModalHeader>
          <h4>Set Up Tours and Calls</h4>
        </ConversationModalHeader>

        <Description>
          To use tour and call scheduling, you need to integrate your calendar using{' '}
          <a href="https://www.cronofy.com/" target="_blank" rel="noreferrer">
            Cronofy
          </a>{' '}
          so that events appear on it automatically.
        </Description>

        <CalendarIconContainer>
          <Image src={Calendar} width="90px" />
        </CalendarIconContainer>
        <CalendarProvidersContainer>
          <Image src={Google} height="26" />
          <Image src={Office365} height="26" />
          <Image src={ICloud} height="26" />
          <Image src={Outlook} height="26" />
        </CalendarProvidersContainer>
      </ConversationModalContent>
      <ConversationModalFooter>
        <RightActions>
          <Button primary color="blue" onClick={handleLink}>
            Next
          </Button>
          <Button basic onClick={handleNotNowClick}>
            Not Now
          </Button>
        </RightActions>
      </ConversationModalFooter>
    </ConversationModal>
  );
};
