import React from 'react';
import styled from 'styled-components';

import { InputField } from 'components/common';
import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import { Input } from 'components/lib';
import Display from './Display';
import Dropdown from '../../../form/fields/Dropdown';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  .helper-text {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
    text-align: center;
  }
`;

const Box = styled.div`
  display: flex;
  gap: 5px;

  .display {
    flex: 3;
  }

  .operator {
    flex: 3;
  }

  .input {
    flex: 1;
    max-width: 80px;
    width: 100%;
  }
`;

const Integer = ({ name, criterion }) => {
  const { state, hasEditDeletePermission } = useCampaignDetails();
  const { criteria } = state;
  const { operators, helpText } = criteria.find((c) => c.id === criterion.criterionId);
  const fieldName = `${name}.value`;

  return (
    <Container>
      <Box>
        <Display className="display" size="sm">
          {criterion.displayName}
        </Display>
        <InputField
          name={`${name}.operator`}
          control={Dropdown}
          fluid
          selection
          options={operators.map((o) => ({ key: o.id, text: o.displayName, value: o.id }))}
          placeholder="Operator"
          className="operator"
          disabled={!hasEditDeletePermission}
        />
        <InputField name={fieldName} control={Input} className="input" disabled={!hasEditDeletePermission} />
      </Box>
      {helpText && <div className="helper-text">{helpText}</div>}
    </Container>
  );
};

export default Integer;
