import React, { useContext } from 'react';
import styled from 'styled-components';
import { Message } from 'semantic-ui-react';

import { If } from 'components/If';
import { MessageContext } from './MessageContext';
import { STATUSES } from '../../../constants';

const ConversationWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding: 0 0.4rem;
`;

export const ConversationContainer = ({ info, children }) => {
  const { status } = useContext(MessageContext);

  return (
    <ConversationWrapper>
      <If condition={status === STATUSES.LOADED && info}>
        <Message info content="No messages in this conversation!" />
      </If>
      {children}
    </ConversationWrapper>
  );
};
