import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import { Form } from 'components/lib';
import { FieldLabel, FieldLinkLabel, FieldLabelWrapper } from '../AuthLayout';
import { AuthButton } from '../AuthButton';
import { PasswordInput } from '../PasswordInput';

const LoginPasswordFieldForm = ({ loading, disabled, onSubmit }) => {
  const passwordFieldRef = useRef();

  const history = useHistory();

  const initialValues = {
    password: '',
  };

  const submit = async ({ password }, { setFieldError }) => {
    try {
      await onSubmit(password);
    } catch (error) {
      setFieldError('password', 'Your login info is incorrect');
      passwordFieldRef?.current?.focus();
    }
  };

  const onForgotPassword = () => {
    history.replace('/login/forgot-password');
  };

  return (
    <Formik initialValues={initialValues} validateOnBlur={false} validateOnChange={false} onSubmit={submit}>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <PasswordInput
            ref={passwordFieldRef}
            autoFocus
            label={
              <FieldLabelWrapper>
                <FieldLabel>Password</FieldLabel>
                <FieldLinkLabel onClick={onForgotPassword}>Forgot password?</FieldLinkLabel>
              </FieldLabelWrapper>
            }
            disabled={disabled || loading}
          />
          <AuthButton label="Log In" loading={loading} disabled={disabled || loading} />
        </Form>
      )}
    </Formik>
  );
};

export default LoginPasswordFieldForm;
