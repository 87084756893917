import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { calendarAvailability } from 'apis';
import { TIME_SLOTS } from './constants';

export const fetchDayTimeSlots = async (day, community) => {
  if (!community || !day) {
    return TIME_SLOTS;
  }

  const { id, timezone } = community;

  const { availability } = await calendarAvailability(id, { date: format(day, 'yyyy-MM-dd'), days_offset: 1 });
  const dayKey = format(day, 'MM/dd/yyyy');

  const timeSlotsOptions = availability[dayKey]
    .filter((slot) => slot.available)
    .map((slot) => utcToZonedTime(slot.start_utc, timezone))
    .map((start) => ({
      key: parseInt(format(start, 'H')),
      value: parseInt(format(start, 'H')),
      text: format(start, 'h:mm a'),
    }));

  return timeSlotsOptions;
};
