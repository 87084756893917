import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button, Input } from 'semantic-ui-react';

import { saveAnalyticsReport } from 'apis';

const UpdateBox = styled.div`
  display: flex;
  align-items: center;

  input {
    margin-bottom: 5px;
  }

  div {
    .float-right {
      float: right;
    }
  }
`;

const SaveReportBox = ({ report, filters }) => {
  const [isUpdateBoxVisible, setIsUpdateBoxVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState('');

  const handleSaveReport = async () => {
    setIsSaving(true);

    try {
      const payload = { name, ...filters };

      if (!payload.start_date) {
        payload.start_date = report.start_date;
      }

      // if (!payload.communities) {
      //   payload.communities = report.communities.map((p) => p.id);
      // }
      await saveAnalyticsReport(payload);

      setIsSaving(false);
      setIsUpdateBoxVisible(false);
      setName('');
      toast.success('Report saved successfully');
    } catch (error) {
      setIsSaving(false);
      const msg = 'There was an error saving your report';
      console.error(msg, error?.response);
      toast.error(msg);
    }
  };

  return (
    <>
      {!isUpdateBoxVisible && (
        <Button primary onClick={() => setIsUpdateBoxVisible(true)}>
          Save Report
        </Button>
      )}
      {isUpdateBoxVisible && (
        <UpdateBox>
          <Input type="text" size="mini" placeholder="Enter report name" action loading={isSaving}>
            <input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Button icon="check" disabled={isSaving || !name.trim()} onClick={handleSaveReport} loading={isSaving} />
            <Button
              icon="cancel"
              onClick={() => {
                setName('');
                setIsUpdateBoxVisible(false);
              }}
            />
          </Input>
        </UpdateBox>
      )}
    </>
  );
};

export default SaveReportBox;
