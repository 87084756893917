import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { Table, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { STATUSES } from '../../../constants';
import { deleteCommunityCareType } from '../../../apis';
import { CareTypesContext } from './CareTypesContext';
import { EditRankCell } from './EditRankCell';

const StyledTableRow = styled(Table.Row)`
  .action-icons {
    display: none;
  }

  &:hover {
    .action-icons {
      display: inline-block;
    }
  }
`;

export const CommunityCareTypesListItem = ({ careType }) => {
  const { communityId } = useParams();
  const { setStatus, setEditCareType, refreshCareTypes } = useContext(CareTypesContext);

  const onEdit = () => {
    setEditCareType(careType.id);
  };

  const onRemoveCareType = async () => {
    try {
      setStatus(STATUSES.LOADING);
      await deleteCommunityCareType(communityId, careType.id);
      await refreshCareTypes();
      setStatus(STATUSES.LOADED);
    } catch (e) {
      setStatus(STATUSES.FAILURE);
    }
  };

  return (
    <StyledTableRow key={careType.id}>
      <EditRankCell careType={careType} />
      <Table.Cell width={2}>{careType.rank}</Table.Cell>
      <Table.Cell width={5}>
        <h5>{careType.care_type.name}</h5>
      </Table.Cell>
      <Table.Cell width={5}>{careType.display_name}</Table.Cell>
      <Table.Cell width={2} textAlign="center">
        <Icon name="edit outline" className="action-icons" link onClick={onEdit} />
        <Icon name="trash alternate outline" className="action-icons" link onClick={onRemoveCareType} />
      </Table.Cell>
    </StyledTableRow>
  );
};
