import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Pagination } from 'semantic-ui-react';
import styled from 'styled-components';

import { getFeaturesOverviewUsers } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import useDataList from 'utils/hooks/useDataList';
import { Table } from 'components/lib';
import { STATUSES } from '../../../constants';
import SearchInput from '../../SearchInput';
import FeaturesOverviewUserCounts from './FeaturesOverviewUserCounts';
import FeaturesOverviewListHeader from './FeaturesOverviewListHeaders';

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
  }
`;

const FeaturesOverviewUsers = () => {
  const history = useHistory();

  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { items, totalPages, filters, setItems, setPage, setSearch } = useDataList({
    initialState: { items: [] },
  });

  useEffect(() => {
    const fetchFeatureUsage = async ({ page, pageSize, search }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await getFeaturesOverviewUsers({
          page,
          page_size: pageSize,
          search,
        });

        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch users for feature usage overview');
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    fetchFeatureUsage(filters);
  }, [filters]);

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const onRowClick = (userId) => {
    history.push(`/admin/features/${userId}`);
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading users...</Loader>
      </Dimmer>

      <SearchInput value={filters.search} fluid onSearch={handleSearch} placeholder="Search users by name or email" />
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <FeaturesOverviewListHeader />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((user) => (
            <Table.Row key={user.id} onClick={() => onRowClick(user.id)}>
              <Table.Cell>{user.id}</Table.Cell>
              <Table.Cell>
                <VerticalContainer>
                  {user.first_name} {user.last_name}
                  <span>{user.email}</span>
                </VerticalContainer>
              </Table.Cell>
              <FeaturesOverviewUserCounts user={user} />
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="8" textAlign="left">
              {totalPages > 1 && (
                <Pagination activePage={filters.page} onPageChange={handlePageChange} totalPages={totalPages} />
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default FeaturesOverviewUsers;
