import React from 'react';
import { Field as FormikField, useFormikContext } from 'formik';
import { Form, Input, TextArea } from 'semantic-ui-react';
import { InputField, Radio, ToggleSwitch } from 'components/common';
import { CodeArea } from '../CodeArea';
import { LabeledNumberInput } from '../LabeledNumberInput';
import { OptionalField } from '../OptionalField';
import { If } from '../../If';

export const BehaviorSection = ({ chat }) => {
  const { errors } = useFormikContext();
  return (
    <>
      <Form.Group inline>
        <label>Chat position:</label>
        <Radio name="position" value="left" label="Left" />
        <Radio name="position" value="right" label="Right" />
      </Form.Group>

      <Form.Group>
        <Form.Field width={6}>
          <label>Delay loading</label>
          <FormikField
            as={Input}
            name="delay_loading"
            type="number"
            min="0"
            max="120"
            label={{ content: 'seconds' }}
            labelPosition="right"
          />
          <If condition={errors.delay_loading}>
            <div className="ui pointing above prompt label" role="alert" aria-atomic="true">
              Required
            </div>
          </If>
        </Form.Field>

        <Form.Field width={6}>
          <OptionalField
            as={LabeledNumberInput}
            name="show_max_times"
            type="number"
            min="1"
            max="10000"
            fieldLabel="Limit how many times chat is shown"
            enabled={chat.show_max_times !== null}
            label={{ content: 'times' }}
            labelPosition="right"
            defaultValue={10}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Pause before responding</label>
          <FormikField
            as={Input}
            name="pause"
            type="number"
            min="0"
            max="10"
            label={{ content: 'seconds' }}
            labelPosition="right"
          />
          <If condition={errors.pause}>
            <div className="ui pointing above prompt label" role="alert" aria-atomic="true">
              Required
            </div>
          </If>
        </Form.Field>
        <Form.Field width={3}>
          <label>Teaser scroll delay</label>
          <FormikField
            as={Input}
            name="teaser_scroll_delay"
            type="number"
            min="0"
            max="100"
            label={{ content: 'px' }}
            labelPosition="right"
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={4}>
          <label>Maximum fake number attempts</label>
          <FormikField
            as={Input}
            name="max_phone_attempts"
            type="number"
            min="1"
            max="10"
            label={{ content: 'times' }}
            labelPosition="right"
          />
        </Form.Field>
      </Form.Group>

      <Form.Field>
        <ToggleSwitch name="space_bar_hijacked" label="Hijack spacebar" />
      </Form.Field>
      <Form.Field>
        <ToggleSwitch name="show_title_flash" label="Show title flash" />
      </Form.Field>
      <Form.Field>
        <ToggleSwitch name="disable_mobile" label="Disable mobile devices" />
      </Form.Field>
      <Form.Field>
        <ToggleSwitch name="advanced_modules" label="Use advanced modules" />
      </Form.Field>
      <Form.Field>
        <ToggleSwitch name="show_all_options_in_teaser" label="Show all options in teaser" />
      </Form.Field>
      <Form.Field>
        <ToggleSwitch name="show_text_us_call_us_badges" label="Show Text Us and Call Us badges" />
      </Form.Field>
      <Form.Field>
        <ToggleSwitch name="use_client_side_routing" label="Use client-side routing" />
      </Form.Field>

      <Form.Group widths="equal">
        <Form.Field>
          <OptionalField
            as={CodeArea}
            name="custom_javascript"
            fieldLabel="Custom JavaScript"
            enabled={chat.custom_javascript !== null}
            placeholder="Paste JS snippet here..."
          />
        </Form.Field>
        <Form.Field>
          <OptionalField
            as={CodeArea}
            name="custom_css"
            fieldLabel="Custom CSS"
            enabled={chat.custom_css !== null}
            placeholder="Paste CSS snippet here..."
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};
