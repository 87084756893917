import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

import { Module } from './Module';

export const InstantAnswers = ({ node }) => (
  <Module node={node}>
    <Label>
      <Icon name="chat" /> Interactive responses for Instant Answers
    </Label>
  </Module>
);
