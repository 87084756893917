import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Dimmer, Divider, Loader } from 'semantic-ui-react';

import { getApplicantById } from 'apis';
import { If } from 'components/If';
import FailureMessage from 'components/common/FailureMessage';
import { STATUSES } from '../../constants';
import ApplicantDetails from './ApplicantDetails';

const Applicant = () => {
  const { applicantId } = useParams();
  const [loader, setLoader] = useState(STATUSES.IDLE);
  const [applicant, setApplicant] = useState();

  useEffect(() => {
    const fetchApplicant = async () => {
      try {
        setLoader(STATUSES.LOADING);
        const result = await getApplicantById(applicantId);

        setApplicant(result);
        setLoader(STATUSES.LOADED);
      } catch (e) {
        setLoader(STATUSES.FAILURE);
        toast.error('Unable to fetch job applicant');
      }
    };

    fetchApplicant();
  }, [applicantId]);

  return (
    <>
      <Dimmer active={loader === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <ApplicantDetails applicant={applicant} />
      <If condition={loader === STATUSES.FAILURE}>
        <Divider hidden />
        <FailureMessage icon="search" content="Job applicant could not be loaded" />
      </If>
    </>
  );
};

export default Applicant;
