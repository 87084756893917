import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Icon } from 'components/lib';
import { FIELD_TYPE } from './constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 64px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #ffffff;
  padding: 20px 15px;
  margin-bottom: 10px;
`;

const Name = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-left: 10px;
  padding-top: 5px;
  width: ${(props) => (props.preview ? '362px' : props.width || 'auto')};
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ComponentWrapper = styled.div`
  margin-left: auto;
  width: 282px;
`;

const Result = styled.span`
  font-weight: 900;
`;

const Condition = ({ rule = {}, onChange, onRemove, onClose, preview, selectedRule }) => {
  const { fieldType, displayName } = rule;
  const Component = FIELD_TYPE[fieldType]?.Component;
  return (
    <Container>
      <NameWrapper>
        <Icon
          name={FIELD_TYPE[fieldType]?.icon}
          size="24"
          color="var(--line-standard)"
          variant={FIELD_TYPE[fieldType]?.variant}
        />
        <Name preview={preview} width={FIELD_TYPE[fieldType]?.width}>
          {displayName}
          <If condition={selectedRule}>
            <Result>
              <If condition={selectedRule?.options?.length === 1}>
                : {rule?.options.find((option) => option.id === selectedRule?.options[0])?.name}
              </If>
              <If condition={selectedRule?.value || selectedRule?.value === 0}>
                {' '}
                {rule?.operators.find((operator) => operator.id === selectedRule?.operator)?.displayName}{' '}
                {selectedRule?.value}
              </If>
              <If condition={selectedRule?.options?.length > 1}>
                : {selectedRule?.options.map((option) => rule?.options.find((o) => o.id === option)?.name).join(', ')}
              </If>
            </Result>
          </If>
        </Name>
      </NameWrapper>
      <If condition={Component}>
        <ComponentWrapper>
          <Component
            rule={rule}
            onChange={onChange}
            onRemove={onRemove}
            onClose={onClose}
            preview={preview}
            selectedRule={selectedRule}
          />
        </ComponentWrapper>
      </If>
    </Container>
  );
};

export default Condition;
