import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Dimmer, Divider } from 'semantic-ui-react';

import { getAsUsers } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { If } from 'components/If';
import { UserContext } from 'components/UserContext';
import CRMSettingsUserSelector from './CRMSettingsUserSelector';
import CRMTabs from './CRMTabs';
import { USER_TYPES, STATUSES } from '../../../constants';
import { useCRM, setUserId } from './context';
import CRMGlobalSEttings from './CRMGlobalSettings';

const Wrapper = styled.div`
  position: relative;
  min-height: 60vh;
  padding: 17px 30px 0 30px;
`;

const CRMRoot = () => {
  const { user } = useContext(UserContext);
  const [{ userId, loaderStatus }, dispatch] = useCRM();
  const [asUsers, setAsUsers] = useState();

  useEffect(() => {
    const { id } = user;

    dispatch(setUserId(id));
  }, [dispatch, user]);

  useEffect(() => {
    const fetchAsUsers = async () => {
      try {
        const { results } = await getAsUsers();

        setAsUsers(results.map((u) => ({ key: u.name, text: `${u.name} (${u.email})`, value: u.id })));
      } catch (e) {
        toast.error(parseErrorResponse(e));
      }
    };

    if (user.type === USER_TYPES.STAFF) {
      fetchAsUsers();
    }
  }, [dispatch, user]);

  const handleSelectAsUser = (asUser) => {
    dispatch(setUserId(asUser));
  };

  return (
    <Wrapper>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted />
      <If condition={asUsers}>
        <CRMSettingsUserSelector users={asUsers} selectedUser={userId} onSelectUser={handleSelectAsUser} />
        <Divider hidden />
      </If>
      <If condition={userId}>
        <CRMGlobalSEttings />
        <CRMTabs />
      </If>
    </Wrapper>
  );
};

export default CRMRoot;
