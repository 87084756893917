import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import {
  checkNewMessages,
  createLeadUserReminder,
  getLeadConversation,
  sendLeadConversationMessage,
  updateLeadUserReminder,
  getLeadInfo,
  getLeadAutomatedAnswer,
  getAnswerLabels,
} from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { debounce } from 'utils/helpers';
import {
  DEFAULT_LEAD_USER_REMINDER,
  RESPONSE_STEP,
  SALES_ASSISTANT_TAB_FILTERS,
  SALES_ASSISTANT_TABS,
} from './constants';
import {
  CONVERSATION_TYPE,
  LEAD_USER_REMINDER_SCHEDULE_TYPE,
  LEAD_USER_REMINDER_TYPE,
  PAGINATION,
  STATUSES,
} from '../../../constants';
import useDataList from '../../../utils/hooks/useDataList';

const freeTextToBlocks = (text, missingLabels) => {
  if (missingLabels) {
    const labels = [];
    let remaining = text;
    for (let i = 0; i < missingLabels.length; i++) {
      const l = missingLabels[i];
      const placehoderless = l.answer.replace(/{.*?}/g, '(.*?)');
      if (placehoderless !== l.answer) {
        const match = RegExp(placehoderless, 'g').exec(remaining);
        if (match) {
          if (match.index > 0) {
            labels.push({ answer: remaining.substring(0, match.index) });
          }
          labels.push({ ...l, modifiedAnswer: match[0] });
          remaining = text.substring(match.index + match[0].length);
        }
      }
    }
    if (remaining) {
      labels.push({ answer: remaining });
    }
    return labels;
  }
  return [{ answer: text }];
};

const answerToBlocks = (answer, blocks) => {
  if (!blocks.length || (blocks.length === 1 && !blocks[0].id)) {
    return [{ answer }];
  }
  const newBlocks = [];
  let start = 0;
  let missingLabels = [];
  for (let i = 0; i < blocks.length; i++) {
    const l = blocks[i];
    if (!l.id) {
      continue;
    }
    const idx = answer.indexOf(l.answer, start);
    if (idx > -1) {
      if (idx > 0) {
        newBlocks.splice(newBlocks.length, 0, ...freeTextToBlocks(answer.substring(start, idx), missingLabels));
        missingLabels = [];
      }
      newBlocks.push(l);
      start = idx + l.answer.length;
    } else {
      missingLabels.push(l);
    }
  }
  if (start < answer.length - 1) {
    newBlocks.splice(newBlocks.length, 0, ...freeTextToBlocks(answer.substring(start), missingLabels));
  }
  return newBlocks.filter((b) => b.answer !== ' ');
};

// for better responsiveness we need to debounce this calculation
// and because of how debounce works we need to keep this function outside of context
const debounceAnswerToBlocks = debounce((answer, blocks, setBlocks) => {
  setBlocks(answerToBlocks(answer, blocks));
}, 1000);

const blocksToAnswer = (blocks) =>
  blocks
    .map((block, i) =>
      block.id && i > 0 && blocks[i - 1].id
        ? ` ${block.modifiedAnswer || block.answer}`
        : block.modifiedAnswer || block.answer
    )
    .join('');

export const MessageContext = createContext();

export const MessageContextProvider = (props) => {
  const [status, setStatus] = useState(STATUSES.IDLE);
  const [messageStatus, setMessageStatus] = useState(STATUSES.IDLE);
  const [attributesStatus, setAttributesStatus] = useState(STATUSES.IDLE);
  const [reminderStatus, setReminderStatus] = useState(STATUSES.IDLE);
  const [step, setStep] = useState(RESPONSE_STEP.TEXT);

  const [lead, setLead] = useState(null);
  const [leadStatus, setLeadStatus] = useState(STATUSES.IDLE);
  const [leadReminder, setLeadReminder] = useState(DEFAULT_LEAD_USER_REMINDER);
  const [messages, setMessages] = useState([]);
  const [showAllMessages, setShowAllMessages] = useState(false);
  const [isFurtherArchived, setIsFurtherArchived] = useState(undefined);

  const [answerLabels, setAnswerLabels] = useState(undefined);
  const [answerLabelsStatus, setAnswerLabelsStatus] = useState(STATUSES.IDLE);
  const [automatedAnswer, setAutomatedAnswer] = useState(undefined);
  const [automatedAnswerStatus, setAutomatedAnswerStatus] = useState(STATUSES.IDLE);

  const [blocks, setBlocks] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [defaultAttributes, setDefaultAttributes] = useState([]);
  const [content, setContent] = useState('');
  const [contentTouched, setContentTouched] = useState(false);
  const [openingLine, setOpeningLine] = useState('');

  const [activeTab, setActiveTab] = useState(SALES_ASSISTANT_TABS.CAMPAIGN_MESSAGES);
  const messagesList = useDataList({
    initialState: {
      items: undefined,
      totalPages: undefined,
      filters: {
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.PAGE_SIZE,
        search: '',
        hasNurtureAssistant: true,
        ...SALES_ASSISTANT_TAB_FILTERS[activeTab],
      },
    },
  });

  const labels = useMemo(() => blocks.filter((b) => b.id), [blocks]);

  const setLabels = useCallback(
    (labels) => {
      setBlocks(labels);
      setContent(blocksToAnswer(labels));
      setContentTouched(true);
    },
    [setBlocks, setContent, setContentTouched]
  );

  const addLabel = (att) => {
    const updated = [...blocks, att];
    setLabels(updated);
  };

  const removeLabel = (att) => {
    const filtered = blocks.filter((label) => label.id !== att.id);
    setLabels(filtered);
  };

  const clearLabels = () => {
    const filtered = blocks.filter((label) => !label.id);
    setLabels(filtered);
  };

  const resetResponse = () => {
    setBlocks([]);
    setAutomatedAnswer([]);
    setContent('');
    setContentTouched(false);
    setOpeningLine('');
  };

  const resetMessagePreview = () => {
    setLead(undefined);
    setLeadStatus(STATUSES.IDLE);
    setLeadReminder(DEFAULT_LEAD_USER_REMINDER);
    setMessages([]);
    setStatus(STATUSES.IDLE);
    setMessageStatus(STATUSES.IDLE);
    resetResponse();
  };

  const sendMessage = useCallback(
    async (type, messageId, replyReminder) => {
      try {
        setMessageStatus(STATUSES.LOADING);
        // because of debounce we need to make sure that blocks are up to date
        const usedLabels = answerToBlocks(content, blocks).map((b) => {
          if (b.label) {
            return b.modifiedAnswer ? `${b.label}*` : b.label;
          }
          return '__txt__';
        });
        const openingLabel = openingLine && openingAnswerLabels.find((l) => openingLine === l.text);
        const fullContent = openingLine ? `${openingLine}\n${content}` : content;
        const data = {
          message_id: messageId,
          content: fullContent,
          used_labels: usedLabels,
          used_opening: openingLine ? openingLabel?.intent || '__txt__' : undefined,
        };
        const response = await sendLeadConversationMessage(lead.id, type, data);

        if (
          replyReminder &&
          replyReminder.schedule_type &&
          replyReminder.schedule_type !== LEAD_USER_REMINDER_SCHEDULE_TYPE.NO_REMINDER
        ) {
          await createOrUpdateReminder(replyReminder.schedule_type, replyReminder.scheduled_at);
        }

        setMessages(response);
        resetResponse();
        setMessageStatus(STATUSES.LOADED);
        toast.success('Message successfully sent to lead!');
      } catch (error) {
        toast.error(parseErrorResponse(error, 'Trouble sending response message!'));
        console.error(error.message);
        setMessageStatus(STATUSES.FAILURE);
      }
    },
    [blocks, content, lead, openingLine, openingAnswerLabels]
  );

  const createOrUpdateReminder = async (scheduleType, scheduledAt) => {
    try {
      setReminderStatus(STATUSES.LOADING);
      const { id } = leadReminder;
      const data = {
        schedule_type: scheduleType,
        reminder_date: scheduledAt,
      };

      const response = id
        ? await updateLeadUserReminder(lead.id, LEAD_USER_REMINDER_TYPE.INSTANT_RESPONSES, id, data)
        : await createLeadUserReminder(lead.id, LEAD_USER_REMINDER_TYPE.INSTANT_RESPONSES, data);

      setReminderStatus(STATUSES.LOADED);
      setLeadReminder(response);
      toast.success('Lead reminder set successfully');
    } catch (e) {
      setReminderStatus(STATUSES.FAILURE);
      toast.error(parseErrorResponse(e, 'Unable to set reminder'));
    }
  };

  const checkForNewMessages = async ({ id, messageType }) => {
    try {
      setMessageStatus(STATUSES.LOADING);
      const { new_messages } = await checkNewMessages(lead.id, {
        message_type: messageType,
        message_id: id,
        same_type: true,
      });

      setMessageStatus(STATUSES.LOADED);
      return new_messages;
    } catch (error) {
      toast.error(parseErrorResponse(error));
      setMessageStatus(STATUSES.FAILURE);
    }
  };

  const getEmailMessages = useCallback(async () => {
    if (lead) {
      try {
        setStatus(STATUSES.LOADING);
        const messages = await getLeadConversation(lead.id, CONVERSATION_TYPE.EMAIL);

        setMessages(messages);
        setStatus(STATUSES.LOADED);
      } catch (e) {
        setStatus(STATUSES.FAILURE);
        toast('Failure to load email conversation messages!');
        console.error(e);
      }
    }
  }, [lead]);

  const getSMSMessages = useCallback(async () => {
    if (lead) {
      try {
        setStatus(STATUSES.LOADING);
        const messages = await getLeadConversation(lead.id, CONVERSATION_TYPE.SMS);

        setMessages(messages);
        setStatus(STATUSES.LOADED);
      } catch (e) {
        setStatus(STATUSES.FAILURE);
        toast('Failure to load SMS conversation messages!');
        console.error(e.response);
      }
    }
  }, [lead]);

  const getLead = async (leadId) => {
    try {
      setLeadStatus(STATUSES.LOADING);
      const response = await getLeadInfo(leadId);

      setLead(response);
      setIsFurtherArchived(response.is_further_archived);
      setLeadStatus(STATUSES.LOADED);
      return response;
    } catch (e) {
      setLeadStatus(STATUSES.FAILURE);
      toast('Failure to load lead details!');
      console.error(e);
    }
  };

  const getAutomatedAnswer = useCallback(
    async (messageType = CONVERSATION_TYPE.SMS) => {
      if (status !== STATUSES.LOADED) {
        return;
      }
      // do not try getting automated answer if there is already some content
      if (content) {
        return;
      }
      const lastMessage = messages && messages.length && messages[messages.length - 1];
      if (!lead || !lastMessage || lastMessage.sender_type !== 'lead') {
        setAutomatedAnswer([]);
        setAutomatedAnswerStatus(STATUSES.LOADED);
        return;
      }
      try {
        setAutomatedAnswerStatus(STATUSES.LOADING);
        const answer = await getLeadAutomatedAnswer(lead.id, messageType);
        setAutomatedAnswer(answer);
        setAutomatedAnswerStatus(STATUSES.LOADED);
        if (answer?.id && answer.id !== lead?.last_automated_answer_id) {
          getLead(lead.id);
        }
      } catch (e) {
        setAutomatedAnswerStatus(STATUSES.FAILURE);
        toast('Failure to load automated answer!');
        console.error(e);
      }
    },
    [lead, messages, status]
  );

  const loadAnswerLabels = async (leadId) => {
    try {
      setAnswerLabelsStatus(STATUSES.LOADING);
      setAnswerLabels(await getAnswerLabels(leadId));
      setAnswerLabelsStatus(STATUSES.LOADED);
    } catch (e) {
      setAnswerLabelsStatus(STATUSES.FAILURE);
      toast('Failure to load answer labels!');
      console.error(e);
    }
  };

  const getAnswerLabelsByType = (type) => answerLabels?.filter((l) => l.type === type);

  const openingAnswerLabels = useMemo(() => getAnswerLabelsByType('OPENING'), [answerLabels]);

  const contentLabels = useMemo(
    () =>
      answerLabels
        ?.filter((l) => l.type === 'ANSWER' || l.type === 'CUSTOM')
        ?.sort((l1, l2) => (l1.intent < l2.intent ? -1 : 1)),
    [answerLabels]
  );

  const fullContent = useMemo(() => [content, openingLine].filter((v) => v).join('\n'), [content, openingLine]);

  const onContentChange = (newContent) => {
    debounceAnswerToBlocks(newContent, blocks, setBlocks);
    setContent(newContent);
    setContentTouched(true);
  };

  useEffect(() => {
    if (labels && automatedAnswer && automatedAnswer.suggested_opening) {
      const suggestedOpening = openingAnswerLabels?.find((l) => l.intent === automatedAnswer.suggested_opening);
      if (suggestedOpening) {
        setOpeningLine(suggestedOpening.text);
      }
    }
  }, [automatedAnswer, openingAnswerLabels]);

  const provide = {
    status,
    setStatus,
    messageStatus,
    lead,
    leadStatus,
    setLead,
    leadReminder,
    setLeadReminder,
    messages,
    setMessages,
    resetMessagePreview,
    showAllMessages,
    setShowAllMessages,
    answerLabels,
    openingAnswerLabels,
    answerLabelsStatus,
    contentLabels,
    loadAnswerLabels,
    automatedAnswer,
    automatedAnswerStatus,
    getAutomatedAnswer,
    defaultAttributes,
    setDefaultAttributes,
    attributesStatus,
    setAttributesStatus,
    attributes,
    setAttributes,
    content,
    contentTouched,
    setContent: onContentChange,
    openingLine,
    setOpeningLine,
    fullContent,
    step,
    setStep,
    isFurtherArchived,
    setIsFurtherArchived,
    labels,
    addLabel,
    removeLabel,
    reminderStatus,
    setReminderStatus,
    activeTab,
    setActiveTab,
    messagesList,
    setLabels,
    clearLabels,
    sendMessage,
    createOrUpdateReminder,
    checkForNewMessages,
    getEmailMessages,
    getSMSMessages,
    getLead,
  };

  return <MessageContext.Provider value={provide} {...props} />;
};
