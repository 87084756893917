import React, { useState } from 'react';
import styled from 'styled-components';

import Background from 'assets/images/my_profile_bg.png';

const ImageContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center, center;
  transform: scale(1.1);
  background-attachment: fixed;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  left: 0;
  top: 0;
`;

export const BackgroundImage = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <ImageContainer>
      <img src={Background} alt="background" style={{ display: 'none' }} onLoad={handleImageLoad} />
      <Image loaded={imageLoaded} />
      <Overlay loaded={imageLoaded} />
    </ImageContainer>
  );
};
