import React from 'react';
import styled from 'styled-components';

import { formatAnyDate } from 'components/utils';

const Container = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  white-space: nowrap;
`;

const Date = styled.div`
  font-size: 14px;
  line-height: 1.14;
  color: var(--text);
`;

const Time = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: #1d252d;
`;

export const BusinessActivityDateTime = ({ date }) => {
  return (
    <Container>
      <Date>{formatAnyDate(date, 'MMM d, yyyy')}</Date>
      <Time>{formatAnyDate(date, 'hh:mm a')}</Time>
    </Container>
  );
};
