import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Divider, Header, Label } from 'semantic-ui-react';

import { createUnitLevelPriceDataModel } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES } from '../../constants';
import UnitLevelPricingStepActions from './UnitLevelPricingStepActions';
import UnitDetails from './UnitDetails';
import { useUnitLevelPricing, CARE_TYPES, UNIT_TYPES, setNextStep, setLoaderStatus } from './context';

const ListItemsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const IconBox = styled.div`
  border-right: 1px solid #e4e4e4;
  padding: 2rem;
  margin-right: 2rem;
`;

const ContentBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem 2rem 0;
`;

const ContentDetailsBox = styled.div`
  flex: 4;
`;

const ContentCareTypeBox = styled.div`
  flex: 2;
  margin-left: 0.5rem;
`;

const ContentPriceBox = styled.div`
  flex: 2;
  text-align: center;
`;

const Price = styled.div`
  color: #333333;
  font-weight: 600;
  font-size: 1.2rem;
`;

const PerMonth = styled.div`
  color: #979797;
`;

const RoomTypeIconStyle = { width: '3.5rem', height: '3.5rem', fill: '#443e3e' };

const SummaryStep = () => {
  const [{ units, token, loaderStatus }, dispatch] = useUnitLevelPricing();

  const handleSubmit = async () => {
    try {
      dispatch(setLoaderStatus(STATUSES.LOADING));
      const data = [];
      units.forEach((u) =>
        u.careTypes
          .filter((ct) => ct.unitsAvailable)
          .forEach((ct) => {
            data.push({
              unit_type: u.unitType,
              number_of_beds: u.numberOfBeds,
              number_of_bathrooms: u.numberOfBathrooms,
              unit_size: u.unitSize,
              is_private: u.isPrivate,
              care_type: ct.id,
              min_price: ct.minPrice,
              max_price: ct.maxPrice,
              units_available: ct.unitsAvailable,
            });
          })
      );

      await createUnitLevelPriceDataModel(token, { unit_types: data });

      dispatch(setLoaderStatus(STATUSES.LOADED));
      dispatch(setNextStep());
    } catch (e) {
      toast.error(parseErrorResponse(e));
      dispatch(setLoaderStatus(STATUSES.FAILURE));
    }
  };

  return (
    <>
      <Header as="h3">
        <Header.Content>
          Confirm your data
          <Header.Subheader>
            Please, review your data and then, if everything looks correct, click Save button to submit it to us.
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Divider hidden />
      <ListItemsBox>
        {units.map((u, unitIndex) =>
          u.careTypes
            .filter((ct) => ct.unitsAvailable)
            .map((ct, careTypeIndex) => {
              const careType = CARE_TYPES.find((c) => c.id === ct.id);
              const roomType = UNIT_TYPES.find((c) => c.id === u.unitType);

              return (
                <ListItem key={`${unitIndex}_${careTypeIndex}`}>
                  <IconBox>{React.createElement(roomType.icon, RoomTypeIconStyle)}</IconBox>
                  <ContentBox>
                    <ContentDetailsBox>
                      <UnitDetails unit={u} unitsAvailable={ct.unitsAvailable} />
                    </ContentDetailsBox>
                    {React.createElement(careType?.icon, { width: '2rem', height: '2rem', fill: 'black' })}
                    <ContentCareTypeBox>{careType?.title}</ContentCareTypeBox>
                    <ContentPriceBox>
                      {ct?.minPrice && ct?.maxPrice ? (
                        <div>
                          <Price>
                            $ {ct?.minPrice} - $ {ct?.maxPrice}
                          </Price>
                          <PerMonth>per month</PerMonth>
                        </div>
                      ) : (
                        <Label content="Not defined" />
                      )}
                    </ContentPriceBox>
                  </ContentBox>
                </ListItem>
              );
            })
        )}
      </ListItemsBox>
      <UnitLevelPricingStepActions
        onNextStep={handleSubmit}
        nextStepLabel="Save"
        disabled={loaderStatus === STATUSES.LOADING}
      />
    </>
  );
};

export default SummaryStep;
