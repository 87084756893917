import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { SettingsOff } from '../common';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--text);
  width: 330px;
`;

const Rules = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 34px;
  height: 22px;
  border-radius: 12px;
  padding: 3px 3px 0;
  background-color: #eaeaea;
  font-family: var(--font-family-bold);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  margin-left: 5px;
`;

const TableRow = ({ rules, item }) => {
  const data = {};
  if (item.leadQualificationRules?.groups?.length > 0) {
    const firstGroup = item.leadQualificationRules.groups[0];
    if (firstGroup.rules.length > 0) {
      const firstRule = firstGroup.rules[0];
      data.rule = rules.find((r) => r.id === firstRule.ruleId);
      data.currentRule = firstRule;
    }
    data.restOfTheRules = item.leadQualificationRules.groups.reduce((acc, group) => acc + group.rules.length, 0) - 1;
  }
  return (
    <Container>
      {data.rule?.displayName}
      <If condition={data.currentRule?.options?.length === 1}>
        : {data.rule?.options.find((option) => option.id === data.currentRule.options[0])?.name}
      </If>
      <If condition={data.currentRule?.value || data.currentRule?.value === 0}>
        {' '}
        {data.rule?.operators.find((operator) => operator.id === data.currentRule.operator)?.displayName}{' '}
        {data.currentRule?.value}
      </If>
      <If condition={data.currentRule?.options?.length > 1}>
        : {data.currentRule?.options.map((option) => data.rule?.options.find((o) => o.id === option)?.name).join(', ')}
      </If>
      <If condition={data.restOfTheRules > 0}>
        <Rules>+ {data.restOfTheRules}</Rules>
      </If>
      <If condition={!data.rule && !data.restOfTheRules}>
        <SettingsOff />
      </If>
    </Container>
  );
};

export default TableRow;
