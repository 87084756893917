import React, { useCallback, useState, useEffect } from 'react';
import { addMonths } from 'date-fns';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { formatAnyDate } from 'components/utils';
import { parseErrorResponse } from 'apis/utils';
import { Dimmer, Loader } from 'components/lib';
import { settingsUpdateLeadQualificationsRules } from 'apis';
import { CONNECTOR } from './constants';
import { STATUSES } from '../../../constants';

const Container = styled.div``;

const Text = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const DataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Unqualified = styled.span`
  font-family: var(--font-family);
  font-size: 26px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const Percentage = styled.span`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-left: 5px;
`;

const NumberWrapper = styled.div`
  display: flex;
  margin-right: 5px;
  align-items: baseline;
  margin-right: 30px;
`;

const Preview = ({ selectedItems, excludedItems, values }) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [preview, setPreview] = useState({});
  const { t } = useTranslation();

  const fetchPreview = useCallback(
    async (values) => {
      const today = new Date();
      const range = {
        startDate: formatAnyDate(addMonths(today, -12), "yyyy-MM-dd'T'HH:mm"),
        endDate: formatAnyDate(today, "yyyy-MM-dd'T'HH:mm"),
      };
      const data = {
        preview: {
          startDate: range.startDate,
          endDate: range.endDate,
        },
        include_communities: selectedItems,
        exclude_communities: excludedItems,
        connector: CONNECTOR.OR,
        groups: values.groups.map((group) => ({
          connector: CONNECTOR.AND,
          rules: group.rules.map((rule) => ({
            id: rule.ruleId,
            value: rule.value,
            operator: rule.operator,
            options: rule.options,
          })),
        })),
      };
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { preview } = await settingsUpdateLeadQualificationsRules(data);
        setPreview(preview);
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch preview data');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setPreview]
  );

  useEffect(() => {
    fetchPreview(values);
  }, [values, fetchPreview]);
  const loading = loaderStatus === STATUSES.LOADING;
  const { unqualifiedLeadsCount, leadsCount, unqualifiedMoveInsCount, moveInsCount } = preview;
  return (
    <Container>
      <Dimmer active={loading} inverted>
        <Loader active />
      </Dimmer>
      <Text>Over the next 12 months, this should filter out:</Text>
      <DataWrapper>
        <NumberWrapper>
          <Unqualified>{unqualifiedLeadsCount}</Unqualified>
          <Percentage>
            {t('leads')} <If condition={leadsCount > 0}>({Math.round((unqualifiedLeadsCount / leadsCount) * 100)}%)</If>
          </Percentage>
        </NumberWrapper>
        <NumberWrapper>
          <Unqualified>{unqualifiedMoveInsCount}</Unqualified>
          <Percentage>potential {t('move_ins')}</Percentage>
        </NumberWrapper>
      </DataWrapper>
    </Container>
  );
};

export default Preview;
