import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';

import { deleteChatProperty, createChatProperty, reassignCommunityProperty, getCommunitiesForLoggedUser } from 'apis';
import { STATUSES } from '../../../constants';

export const CommunitiesContext = createContext();

const initialState = {
  status: STATUSES.IDLE,
  assignedCommunities: [],
  unassignedCommunities: [],
  activeCommunity: null,
  activeModal: null,
};

const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
const SET_ASSIGNED_COMMUNITIES = 'SET_ASSIGNED_COMMUNITIES';
const CLEAR_ASSIGNED_COMMUNITIES = 'CLEAR_ASSIGNED_COMMUNITIES';
const ADD_ASSIGNED_COMMUNITIES = 'ADD_ASSIGNED_COMMUNITIES';
const REMOVE_ASSIGNED_COMMUNITIES = 'REMOVE_ASSIGNED_COMMUNITIES';
const SET_UNASSIGNED_COMMUNITIES = 'SET_UNASSIGNED_COMMUNITIES';
const ASSIGN_COMMUNITY = 'ASSIGN_COMMUNITY';
const REMOVE_UNASSIGNED_COMMUNITIES = 'REMOVE_UNASSIGNED_COMMUNITIES';
const SET_COMMUNITY = 'SET_COMMUNITY';
const SET_ACTIVE_COMMUNITY = 'SET_ACTIVE_COMMUNITY';
const EDIT_COMMUNITY = 'EDIT_COMMUNITY';
const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';

export const ACTIONS = {
  SET_LOADING_STATUS,
  SET_ASSIGNED_COMMUNITIES,
  CLEAR_ASSIGNED_COMMUNITIES,
  ADD_ASSIGNED_COMMUNITIES,
  REMOVE_ASSIGNED_COMMUNITIES,
  SET_UNASSIGNED_COMMUNITIES,
  ASSIGN_COMMUNITY,
  REMOVE_UNASSIGNED_COMMUNITIES,
  SET_COMMUNITY,
  SET_ACTIVE_COMMUNITY,
  EDIT_COMMUNITY,
  SET_ACTIVE_MODAL,
};

const reducer = (state, action) => {
  const actions = {
    SET_LOADING_STATUS: (state, payload) => {
      state.status = payload;
    },
    SET_ASSIGNED_COMMUNITIES: (state, { communities }) => {
      state.assignedCommunities = communities;
    },
    CLEAR_ASSIGNED_COMMUNITIES: (state) => {
      state.assignedCommunities = [];
    },
    ADD_ASSIGNED_COMMUNITIES: (state, { communities }) => {
      state.assignedCommunities = state.assignedCommunities
        .concat(communities)
        .sort((p1, p2) => (p1.name < p2.name ? -1 : 1));
    },
    REMOVE_ASSIGNED_COMMUNITIES: (state, { communities }) => {
      state.assignedCommunities = state.assignedCommunities.filter((ap) => !communities.some((p) => p.id === ap.id));
    },
    SET_UNASSIGNED_COMMUNITIES: (state, { communities }) => {
      state.unassignedCommunities = communities;
    },
    REMOVE_UNASSIGNED_COMMUNITIES: (state, { communities }) => {
      state.unassignedCommunities = state.unassignedCommunities.filter(
        (ap) => !communities.some((p) => p.id === ap.id)
      );
    },
    SET_COMMUNITY: (state, { communityId, communityData }) => {
      const communityIndex = state.assignedCommunities.findIndex((p) => p.id === communityId);

      if (communityId === state.activeCommunity?.id) {
        state.activeCommunity = communityData;
      }

      if (communityIndex > -1) {
        state.assignedCommunities[communityIndex] = communityData;
      }
    },
    SET_ACTIVE_COMMUNITY: (state, { communityId }) =>
      (state.activeCommunity = state.assignedCommunities.find((c) => c.id === parseInt(communityId))),
    EDIT_COMMUNITY: (state, { map_img, community_id }) => {
      const communityIndex = state.assignedCommunities.findIndex((p) => p.id === community_id);
      if (communityIndex > -1) {
        state.assignedCommunities[communityIndex].location.map_img = map_img;
      }
    },
    SET_ACTIVE_MODAL: (state, { activeModal }) => {
      state.activeModal = activeModal;
    },
  };

  if (actions[action.type]) {
    actions[action.type](state, action.payload);
  } else {
    console.error('Unknown reducer type', action);
  }
};

export const CommunitiesContextProvider = (props) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const linkPrefix = props.linkPrefix;

  const getCommunityChatPropertiesByType = ({ community, vsaType }) => {
    if (!community.chat_properties) return [];

    return community.chat_properties.filter((property) => property.vsa_type === vsaType);
  };

  // Currently only used for the REGULAR VSAs and reassignment actions.
  const assignCommunity = async (community, chatProperty, chatId) => {
    let newChatProperty;

    if (!!chatProperty) {
      newChatProperty = await reassignCommunityProperty(chatProperty.id, { chat: chatId });
    } else {
      newChatProperty = await createChatProperty({ community: community.id, chat: chatId });
    }

    dispatch({
      type: ADD_ASSIGNED_COMMUNITIES,
      payload: { communities: [{ ...community, chat_properties: [...community.chat_properties, newChatProperty] }] },
    });
  };

  // Currently only used for the EMBEDDED VSAs and assignment/creation actions.
  const assignProperty = async (community, chatId) => {
    const chatProperty = await createChatProperty({ community: community.id, chat: chatId });

    dispatch({
      type: ADD_ASSIGNED_COMMUNITIES,
      payload: {
        communities: [{ ...community, chat_properties: [...community.chat_properties, chatProperty] }],
      },
    });
  };

  const unassignCommunity = async (community, chatPropertyId) => {
    await deleteChatProperty(chatPropertyId);
    dispatch({ type: REMOVE_ASSIGNED_COMMUNITIES, payload: { communities: [community] } });
  };

  const removeCommunity = async (community) => {
    dispatch({ type: REMOVE_ASSIGNED_COMMUNITIES, payload: { communities: [community] } });
  };

  const loadCommunitiesForLoggedUser = async (params) => {
    dispatch({ type: ACTIONS.SET_LOADING_STATUS, payload: STATUSES.LOADING });

    try {
      const response = await getCommunitiesForLoggedUser(params);
      dispatch({ type: ACTIONS.SET_UNASSIGNED_COMMUNITIES, payload: { communities: response?.results } });
      dispatch({ type: ACTIONS.SET_LOADING_STATUS, payload: STATUSES.LOADED });
    } catch (e) {
      console.error(e);
      dispatch({ type: ACTIONS.SET_LOADING_STATUS, payload: STATUSES.FAILURE });
    }
  };

  return (
    <CommunitiesContext.Provider
      value={{
        state,
        dispatch,
        linkPrefix,
        assignCommunity,
        unassignCommunity,
        assignProperty,
        getCommunityChatPropertiesByType,
        loadCommunitiesForLoggedUser,
        removeCommunity,
      }}
      {...props}
    />
  );
};
