import React from 'react';
import styled from 'styled-components';

import { formatAnyDate } from 'components/utils';
import { Icon, Table } from 'components/lib';

const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #89919d;
  word-break: break-word;
  margin-bottom: 10px;

  & b {
    color: #222;
  }
`;

const buildCheckIcon = (value) => {
  if (value === true) {
    return <Icon name="check" color="green" />;
  }
  if (value === false) {
    return <Icon name="close" color="red" />;
  }

  return '-';
};

export const LeadSubmitToCRMManual = ({ businessActivity }) => {
  const { author, owner, contentObjectName, createdOn, changes } = businessActivity;

  return (
    <>
      <Description>
        Lead <b>{contentObjectName}</b> manually submitted to CRM by
        {author && (
          <span>
            &nbsp;by <b>{author.displayName}</b>
          </span>
        )}
        {owner && (
          <span>
            &nbsp;for the client <b>{owner.displayName}</b>
          </span>
        )}
        , on&nbsp;
        <b>{formatAnyDate(createdOn, "MMM d, yyyy 'at' hh:mm a")}</b>.
      </Description>
      <Table basic="very" celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell>CRM Type</Table.Cell>
            <Table.Cell>{changes.crmType ? changes.crmType.name : '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Parsable</Table.Cell>
            <Table.Cell>{buildCheckIcon(changes.isParsable)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Valid</Table.Cell>
            <Table.Cell>{buildCheckIcon(changes.isValid)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Result</Table.Cell>
            <Table.Cell>{changes.rawResult || '-'}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};
