import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Loader } from 'components/lib';
import { If } from 'components/If';
import { STATUSES } from '../../constants';
import { ConversationsListPlaceholder } from './ConversationsListPlaceholder';
import { ConversationsListItem } from './ConversationsListItem';
import { MessagesContext } from './MessagesContext';

const MessagesContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  will-change: scroll-position;
  &:-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NextPageLoadTrigger = styled.div`
  height: ${(p) => (p.visible ? '100px' : 0)};
`;

export const ConversationsListPage = ({ pageNumber, items }) => {
  const pageVisibilityRef = useRef();
  const { leadId } = useParams();

  const { setIsVisible, handleSelectConversation } = useContext(MessagesContext);

  const selectedConversationId = parseInt(leadId);

  useEffect(() => {
    const onVisibilityChange = (visible) => {
      setIsVisible(pageNumber, visible);
    };

    const options = {
      root: document.documentElement,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        onVisibilityChange(entry.intersectionRatio > 0);
      });
    }, options);

    if (pageVisibilityRef.current) {
      observer.observe(pageVisibilityRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [pageVisibilityRef.current]);

  return (
    <>
      <div ref={pageVisibilityRef}>
        {items?.map((conversation, i) => (
          <ConversationsListItem
            key={`${conversation.id}_${i}_${conversation.has_unread}`}
            conversation={conversation}
            onMessageSelect={handleSelectConversation}
            active={conversation.id === selectedConversationId}
          />
        ))}
      </div>
      <If condition={items?.length === 0}>
        <EmptyState>No messages</EmptyState>
      </If>
    </>
  );
};

export const ConversationsList = () => {
  const nextPageLoadTriggerRef = useRef();
  const { conversationPages, hasMorePages, moreLoader, loadedInitial, loadMoreConversations, conversationPagesSorted } =
    useContext(MessagesContext);

  useEffect(() => {
    const onVisibilityChange = (visible) => {
      if (visible && hasMorePages) {
        loadMoreConversations();
      }
    };
    const options = {
      root: document.documentElement,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        onVisibilityChange(entry.intersectionRatio > 0);
      });
    }, options);

    if (nextPageLoadTriggerRef.current) {
      observer.observe(nextPageLoadTriggerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [nextPageLoadTriggerRef.current, conversationPages, hasMorePages]);

  return (
    <MessagesContainer>
      <ConversationsListPlaceholder loading={!loadedInitial && moreLoader === STATUSES.LOADING} />
      {conversationPagesSorted.map(({ pageNumber, items }) => (
        <ConversationsListPage key={pageNumber} pageNumber={pageNumber} items={items} />
      ))}

      <NextPageLoadTrigger ref={nextPageLoadTriggerRef} visible={loadedInitial && hasMorePages}>
        <Loader fullwidth={true} active={loadedInitial && moreLoader === STATUSES.LOADING} />
      </NextPageLoadTrigger>
    </MessagesContainer>
  );
};
