import React, { createContext, useEffect, useReducer, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import { subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';

import useDataList, { INITIAL_STATE as DATA_LIST_INITIAL_STATE } from 'utils/hooks/useDataList';
import { useUrlSearchParams } from 'utils/hooks';
import {
  getCommunitiesGroups,
  getMessagingCampaigns,
  getMessagingStatistics,
  getMessagingCommunities,
  getInvalidCampaigns,
} from 'apis';
import { formatAnyDate } from 'components/utils';
import { parseIds } from 'utils/hooks/useSearchQuery';
import { UserContext } from '../../UserContext';
import { PAGINATION } from '../../../constants';

const NurtureContext = createContext();

const today = new Date();
export const last30Days = formatAnyDate(subDays(today, 30), 'yyyy-MM-dd');
export const formatedToday = formatAnyDate(today, 'yyyy-MM-dd');

const actions = {
  SET_FILTERS: 'nurture/SET_FILTERS',
  SET_COMMUNITIES: 'nurture/SET_COMMUNITIES',
  SET_GROUPS: 'nurture/SET_GROUPS',
  SET_CAMPAIGNS: 'nurture/SET_CAMPAIGNS',
  SET_STATISTICS: 'nurture/SET_STATISTICS',
  SET_AVAILABLE_CAMPAIGNS: 'nurture/SET_AVAILABLE_CAMPAIGNS',
  SET_CAMPAIGNS_LOADING: 'nurture/SET_CAMPAIGNS_LOADING',
  SET_STATISTICS_LOADING: 'nurture/SET_STATISTICS_LOADING',
  SET_TOTALS: 'nurture/SET_TOTALS',
  UPDATE_CAMPAIGN: 'nurture/UPDATE_CAMPAIGN',
  ADD_CAMPAIGN: 'nurture/ADD_CAMPAIGN',
  DELETE_CAMPAIGN: 'nurture/DELETE_CAMPAIGN',
  RELOAD_CAMPAIGNS: 'nurture/RELOAD_CAMPAIGNS',
  SET_FILTERS_DATEPICKER: 'nurture/SET_FILTERS_DATEPICKER',
  SET_RESET_IS_DATEPICKER_FILTER: 'nurture/SET_RESET_IS_DATEPICKER_FILTER',
  SET_RELATED_COMMUNITIES: 'nurture/SET_RELATED_COMMUNITIES',
  SET_RELATED_COMMUNITIES_LOADING: 'nurture/SET_RELATED_COMMUNITIES_LOADING',
  SET_QUICK_SETUP_LOADING: 'nurture/SET_QUICK_SETUP_LOADING',
  SET_GROUPS_LOADING: 'nurture/SET_GROUPS_LOADING',
  SET_ADD_CAMPAIGN_MODAL_OPEN: 'nurture/SET_ADD_CAMPAIGN_MODAL_OPEN',
  SET_RESET_CAMPAIGNS: 'nurture/SET_RESET_CAMPAIGNS',
  SET_INVALID_CAMPAIGNS: 'nurture/SET_INVALID_CAMPAIGNS',
};

const searchParams = new URLSearchParams(window.location.search);

export const INITIAL_STATE = {
  campaigns: null,
  statistics: null,
  communities: null,
  groups: null,
  availableCampaigns: null,
  totals: {
    leads: 0,
    scheduled_tours: 0,
    moveins: 0,
    messages_sent: 0,
    messages_delivered: 0,
    messages_clicked: 0,
    message_replies: 0,
  },
  campaignMessages: [],
  filters: {
    start_date: searchParams.get('start_date') || last30Days,
    end_date: searchParams.get('end_date') || formatedToday,
    communities: parseIds(searchParams.get('communities')),
    groups: parseIds(searchParams.get('groups')),
    selected_campaigns: parseIds(searchParams.get('selected_campaigns')),
    pageSize: PAGINATION.MAX_PAGE_SIZE,
  },
  campaignsLoading: false,
  campaignsLoaded: false,
  statisticsLoading: false,
  statisticsLoaded: false,
  reload: 0,
  currentCampaign: undefined,
  isDatePickerFilter: false,
  communitiesCount: 0,
  relatedCommunities: [],
  relatedCommunitiesLoading: false,
  quickSetupLoading: false,
  groupsLoading: false,
  addCampaignModal: {
    isOpen: false,
    campaignType: null,
    label: '',
  },
  invalidCampaignIds: [],
};

function nurtureReducer(state, action) {
  switch (action.type) {
    case actions.SET_FILTERS: {
      return { ...state, filters: action.data };
    }
    case actions.SET_FILTERS_DATEPICKER: {
      return { ...state, filters: action.data, isDatePickerFilter: true };
    }
    case actions.SET_RESET_IS_DATEPICKER_FILTER: {
      return { ...state, isDatePickerFilter: false };
    }
    case actions.SET_COMMUNITIES: {
      return { ...state, communities: action.data, communitiesCount: action.data?.length };
    }
    case actions.SET_GROUPS: {
      return { ...state, groups: action.data };
    }
    case actions.SET_CAMPAIGNS: {
      return {
        ...state,
        campaigns: action.data,
        campaignsLoaded: true,
      };
    }
    case actions.SET_STATISTICS: {
      return {
        ...state,
        statistics: action.data,
        statisticsLoaded: true,
      };
    }
    case actions.SET_AVAILABLE_CAMPAIGNS: {
      if (state.availableCampaigns === null) {
        return { ...state, availableCampaigns: action.data };
      }
      return state;
    }
    case actions.SET_CAMPAIGNS_LOADING: {
      return { ...state, campaignsLoading: action.data };
    }
    case actions.SET_GROUPS_LOADING: {
      return { ...state, groupsLoading: action.data };
    }
    case actions.SET_STATISTICS_LOADING: {
      return { ...state, statisticsLoading: action.data };
    }
    case actions.RELOAD_CAMPAIGNS: {
      return { ...state, reload: state.reload + 1 };
    }
    case actions.SET_TOTALS: {
      return { ...state, totals: action.data };
    }
    case actions.UPDATE_CAMPAIGN: {
      const campaigns = [...state.campaigns];
      const index = campaigns.findIndex((c) => c.id === action.data.id);
      campaigns[index] = action.data;
      const communities = [...state.communities];

      const campaignProfiles = action.data?.communities;
      if (campaignProfiles) {
        campaignProfiles.forEach((profile) => {
          const foundIndex = communities.findIndex((item) => item.id === profile.id);
          if (foundIndex !== -1) {
            communities[foundIndex] = profile;
          }
        });
      }

      return { ...state, campaigns, communities };
    }
    case actions.ADD_CAMPAIGN: {
      return { ...state, campaigns: [...state.campaigns, action.data], reload: state.reload + 1 };
    }
    case actions.DELETE_CAMPAIGN: {
      const campaigns = [...state.campaigns];
      const updatedCampaigns = campaigns.filter((c) => c.id !== action.data.id);
      return { ...state, campaigns: updatedCampaigns };
    }
    case actions.SET_RELATED_COMMUNITIES: {
      return {
        ...state,
        relatedCommunities: [
          ...state.relatedCommunities,
          { campaignId: action.data?.campaignId, communities: action.data?.communities },
        ],
      };
    }
    case actions.SET_RELATED_COMMUNITIES_LOADING: {
      return { ...state, relatedCommunitiesLoading: action.data };
    }
    case actions.SET_QUICK_SETUP_LOADING: {
      return { ...state, quickSetupLoading: action.data };
    }
    case actions.SET_ADD_CAMPAIGN_MODAL_OPEN: {
      return {
        ...state,
        addCampaignModal: {
          isOpen: action.data.isOpen,
          campaignType: action.data.campaignType,
          label: action.data.label,
        },
      };
    }
    case actions.SET_RESET_CAMPAIGNS: {
      return {
        ...state,
        campaigns: null,
        campaignsLoaded: false,
      };
    }
    case actions.SET_INVALID_CAMPAIGNS: {
      return {
        ...state,
        invalidCampaignIds: action.data,
      };
    }
    default: {
      return state;
    }
  }
}

function CampaignsProvider({ children }) {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [state, dispatch] = useReducer(nurtureReducer, INITIAL_STATE);
  const { urlSearchParams } = useUrlSearchParams();
  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters } = useDataList({
    initialState: {
      ...DATA_LIST_INITIAL_STATE,
      filters: {
        ...DATA_LIST_INITIAL_STATE.filters,
        replied_by_lead: true,
        replied_by_sales: false,
        search: '',
        ...urlSearchParams,
      },
    },
  });

  const fetchCampaigns = async () => {
    try {
      dispatch({ type: actions.SET_CAMPAIGNS_LOADING, data: true });
      const updatedFilters = { ...state.filters };
      delete updatedFilters.start_date;
      const campaigns = await getMessagingCampaigns(updatedFilters);
      dispatch({ type: actions.SET_CAMPAIGNS, data: campaigns?.results });
      dispatch({ type: actions.SET_CAMPAIGNS_LOADING, data: false });
    } catch (e) {
      console.error(e);
      toast.error('Error fetching messaging campaigns');
      dispatch({ type: actions.SET_CAMPAIGNS_LOADING, data: false });
    }
  };

  const checkInvalidCampaigns = useCallback(async () => {
    const invalidCampaignIds = await getInvalidCampaigns();
    dispatch({ type: actions.SET_INVALID_CAMPAIGNS, data: invalidCampaignIds });
  }, [dispatch]);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await getMessagingCommunities({ pageSize: PAGINATION.MAX_PAGE_SIZE });
        if (response) {
          const communities = response?.results?.sort((p1, p2) => p1.name.localeCompare(p2.name));
          dispatch({ type: actions.SET_COMMUNITIES, data: communities });
        } else {
          toast.warning('Missing results for location communities');
        }
      } catch (e) {
        console.error(e);
        toast.error('Error fetching location communities');
      }
    };

    const loadCommunitiesGroups = async () => {
      try {
        dispatch({ type: actions.SET_GROUPS_LOADING, data: true });
        const response = await getCommunitiesGroups();
        dispatch({ type: actions.SET_GROUPS, data: response.results });
        dispatch({ type: actions.SET_GROUPS_LOADING, data: false });
      } catch (e) {
        toast.error(`We cannot load ${t('community')} groups. Please try again later.`);
        console.error(e);
        dispatch({ type: actions.SET_GROUPS_LOADING, data: false });
      }
    };

    const fetchStatistics = async () => {
      try {
        dispatch({ type: actions.SET_STATISTICS_LOADING, data: true });
        const statistics = await getMessagingStatistics(state.filters);
        dispatch({ type: actions.SET_STATISTICS, data: statistics?.results });
        dispatch({ type: actions.SET_STATISTICS_LOADING, data: false });
        dispatch({ type: actions.SET_RESET_IS_DATEPICKER_FILTER });
      } catch (e) {
        console.error(e);
        toast.error('Error fetching messaging statistics');
        dispatch({ type: actions.SET_STATISTICS_LOADING, data: false });
        dispatch({ type: actions.SET_RESET_IS_DATEPICKER_FILTER });
      }
    };

    if (!state.isDatePickerFilter && !state.campaignsLoading && !state.campaignsLoaded) {
      fetchCampaigns();
    }

    if (!state.statisticsLoading) {
      fetchStatistics();
    }

    if (!state.groups && !state.groupsLoading) {
      loadCommunitiesGroups();
    }

    if (!state.communities) {
      fetchProfiles();
    }
    checkInvalidCampaigns();
  }, [state.filters, state.reload, state.communities, state.availableCampaigns]);

  const value = {
    state,
    dispatch,
    user,
    actions,
    items,
    totalPages,
    filters,
    setItems,
    setPage,
    setSearch,
    setFilters,
    fetchCampaigns,
  };
  return <NurtureContext.Provider value={value}>{children}</NurtureContext.Provider>;
}

function useCampaigns() {
  const context = React.useContext(NurtureContext);
  if (context === undefined) {
    throw new Error('useCampaigns must be used within a CampaignsProvider');
  }
  return context;
}

export { CampaignsProvider, useCampaigns };
