import React, { useMemo } from 'react';

import { Dropdown, Table, Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { InputField } from 'components/common/fields/InputField';

import { MEDIA_BREAK_POINTS } from '../../../constants';

export const RuleInputsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
    flex-basis: 0;
    padding-bottom: 5px;
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
    & > div {
      padding-bottom: 0px;
    }
  }
`;

const MappingRuleRow = ({
  baseFieldName,
  rowId,
  rowsCount,
  rule,
  ruleTypes,
  onRuleMappingTypeChange,
  handleMoveUp,
  handleMoveDown,
  handleRemove,
  disabled,
}) => {
  const mappingTypeOptions = useMemo(
    () =>
      ruleTypes.map((rt) => ({
        key: rt.mappingType,
        text: rt.name,
        value: rt.mappingType,
      })),
    [ruleTypes]
  );
  const ruleType = ruleTypes.find((rt) => rt.mappingType === rule.mapping_type);
  const Rule = ruleType?.component;
  if (!Rule) {
    return null;
  }

  return (
    <Table.Row verticalAlign="top">
      <Table.Cell textAlign="left">
        <Button.Group basic size="mini">
          <Button
            type="button"
            disabled={disabled || rowId === rowsCount - 1}
            icon="chevron down"
            onClick={handleMoveDown}
          />
          <Button type="button" disabled={disabled || rowId === 0} icon="chevron up" onClick={handleMoveUp} />
        </Button.Group>
      </Table.Cell>

      <Table.Cell>
        <InputField
          control={Dropdown}
          name={`${baseFieldName}.mapping_type`}
          placeholder="Mapping Type"
          selection
          options={mappingTypeOptions}
          onChanged={onRuleMappingTypeChange}
          disabled={disabled}
        />
      </Table.Cell>

      <Table.Cell>
        <RuleInputsBox>
          <Rule rule={rule} baseFieldName={baseFieldName} value={rule.value} disabled={disabled} />
        </RuleInputsBox>
      </Table.Cell>
      <Table.Cell>
        <Button type="button" icon negative size="tiny" onClick={handleRemove} disabled={disabled}>
          <Icon name="trash" />
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default MappingRuleRow;
