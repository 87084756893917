import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Message, Pagination, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { capitalize } from 'utils/helpers';
import { CsvExport } from 'components/common';

const AppliedTime = styled.div`
  font-size: 12px;
`;

const ApplicantTable = ({ applicants, page, totalPages, onPageChange, handleExport }) => {
  const { t } = useTranslation();
  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  if (!applicants) {
    return null;
  }

  if (applicants.length === 0) {
    return <Message info header="Job applicants not found" content="Please select different filters" />;
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{capitalize(t('lead'))}</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Phone</Table.HeaderCell>
          <Table.HeaderCell>{capitalize(t('community'))}</Table.HeaderCell>
          <Table.HeaderCell>Applied</Table.HeaderCell>
          <Table.HeaderCell>Traffic Source</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {applicants.map((a) => (
          <Table.Row key={a.id}>
            <Table.Cell>
              <Link
                to={{
                  pathname: `/job-applicants/${a.id}`,
                  state: { fromJobApplicants: true },
                }}
              >
                {a.full_name}
              </Link>
            </Table.Cell>
            <Table.Cell>{a.email}</Table.Cell>
            <Table.Cell>{a.phone}</Table.Cell>
            <Table.Cell>{a.community_name}</Table.Cell>
            <Table.Cell>
              <div>{format(a.created_on, 'MMM d, yyyy')}</div>
              <AppliedTime>{format(a.created_on, 'h:mm a')}</AppliedTime>
            </Table.Cell>
            <Table.Cell colSpan="2">{a.traffic_source}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          {totalPages > 1 && (
            <Table.HeaderCell colSpan="5" textAlign="left">
              <Pagination activePage={page} onPageChange={handlePageChange} totalPages={totalPages} />
            </Table.HeaderCell>
          )}
          <Table.HeaderCell colSpan={totalPages > 1 ? '1' : '6'} textAlign="right">
            <CsvExport content="CSV" exportAction={handleExport} filename="job_applicants" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default ApplicantTable;
