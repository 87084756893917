import UrlLocationRule from './UrlLocationRule';
import UrlParameterRule from './UrlParameterRule';
import UrlParametersMatchRule from './UrlParametersMatchRule';

export const URL_MAPPING_TYPES = {
  URL_PARAMETERS: 'url_parameters',
  URL_LOCATION: 'url_location',
  URL_PARAMETERS_MATCH: 'url_parameters_match',
  REFERRER_LOCATION: 'referrer_location',
};

export const URL_MAPPING_RULES = [
  {
    mappingType: URL_MAPPING_TYPES.URL_LOCATION,
    name: 'Url Location',
    component: UrlLocationRule,
    defaults: {
      keyword: '',
      result_value: '',
    },
  },
  {
    mappingType: URL_MAPPING_TYPES.REFERRER_LOCATION,
    name: 'Referrer Location',
    component: UrlLocationRule,
    defaults: {
      keyword: '',
      result_value: '',
    },
  },
  {
    mappingType: URL_MAPPING_TYPES.URL_PARAMETERS,
    name: 'Url Parameters',
    component: UrlParameterRule,
    defaults: {
      result_value: '',
      clauses: [{ id: null, key_value: '', keyword: '' }],
    },
  },
  {
    mappingType: URL_MAPPING_TYPES.URL_PARAMETERS_MATCH,
    name: 'Url Parameters Match',
    component: UrlParametersMatchRule,
    defaults: {
      keyword: '',
    },
  },
];
