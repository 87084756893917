import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TabActionsBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const IncludeExportedQuestionsCheckbox = styled(Checkbox)`
  margin: 0 1rem;
`;

export const TabFiltersBox = styled.div`
  display: flex;
  align-items: center;
`;

const TabHeader = ({ children }) => <HeaderBox>{children}</HeaderBox>;

export default TabHeader;
