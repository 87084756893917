import React from 'react';
import styled from 'styled-components';

import { Icon, Table, Message, Loader, Popup } from 'components/lib';
import { ModuleRecordingsTableHeader } from './ModuleRecordingsTableHeader';
import { SimpleAudioWidget } from './SimpleAudioWidget';

import { MODULE_UPLOAD_STATUS, MODULE_TYPE_VALUES, MODULE_ACTION_STATUS, MODULE_UPLOAD_ACTIONS } from './constants';

const MenuIcon = styled(Icon)`
  && {
    display: unset;
    margin: 0;
    width: 21px;
    height: 21px;
    cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
    user-select: none;

    &.required {
      &::after {
        color: var(--salmon);
        content: '*';
        position: relative;
        top: -15px;
        font-size: 14px;
      }
    }
  }
`;

const MenuLoader = styled(Loader)`
  && {
    width: 21px;
    height: 21px;
  }
`;

const CellIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTable = styled(Table)`
  &.ui.table.basic.selectable {
    margin: 0;
    tr:last-child td {
      border-bottom: 1px solid var(--line-standard);
    }
  }
`;

const ClickableRow = styled(Table.Row)`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

const DEFAULT_ACTION_STATUS_ITEM = {
  display: false,
  text: null,
  formDisabled: false,
  loading: false,
  component: null,
};

const ACTION_STATUS_COMPONENTS = {
  [MODULE_ACTION_STATUS.UPLOADING]: {
    ...DEFAULT_ACTION_STATUS_ITEM,
    display: true,
    text: 'Uploading module recording',
    formDisabled: true,
    component: <MenuLoader inline active size="medium" transparent />,
  },
  [MODULE_ACTION_STATUS.UPLOADED]: {
    ...DEFAULT_ACTION_STATUS_ITEM,
    display: true,
    text: 'Upload completed',
    component: <MenuIcon size="large" name="done" color="green" />,
  },
  [MODULE_ACTION_STATUS.REQUIRED]: {
    ...DEFAULT_ACTION_STATUS_ITEM,
    display: true,
    text: 'Upload is required.',
    formDisabled: true,
    component: <MenuIcon className="required" size="large" name="file_upload" color="var(--salmon)" clickable />,
  },
  [MODULE_ACTION_STATUS.OPTIONAL]: {
    ...DEFAULT_ACTION_STATUS_ITEM,
    display: true,
    text: 'Upload is optional',
    component: <MenuIcon size="large" name="file_upload" color="var(--light-text)" clickable />,
  },
  [MODULE_ACTION_STATUS.NOT_PLAYABLE]: {
    ...DEFAULT_ACTION_STATUS_ITEM,
    display: true,
    text: "Won't play any audio",
    component: <MenuIcon size="large" name="volume_off" color="var(--light-text)" />,
  },
  [MODULE_ACTION_STATUS.NOT_ALLOWED]: {
    ...DEFAULT_ACTION_STATUS_ITEM,
    display: true,
    text: 'Upload is not allowed',
    component: <MenuIcon size="large" name="file_upload_off" color="var(--light-text)" />,
  },
  [MODULE_ACTION_STATUS.FAILED]: {
    ...DEFAULT_ACTION_STATUS_ITEM,
    display: true,
    text: 'Error occurred, upload not completed',
    component: <MenuIcon size="large" name="priority_high" color="orange" variant="outlined" clickable />,
  },
};

const ModuleRecordingsRow = ({ module, isDraft, onRowClick }) => {
  const getModuleActionStatus = (module) => {
    if (module?.recording?.uploadStatus === MODULE_UPLOAD_STATUS.IN_PROGRESS) return MODULE_ACTION_STATUS.UPLOADING;

    if (module?.recording?.uploadStatus === MODULE_UPLOAD_STATUS.COMPLETED && module?.recording?.resourceUrl)
      return MODULE_ACTION_STATUS.UPLOADED;

    if (module?.recording?.uploadStatus === MODULE_UPLOAD_STATUS.FAILED) return MODULE_ACTION_STATUS.FAILED;

    if (module?.uploadAction === MODULE_UPLOAD_ACTIONS.OPTIONAL && !isDraft) return MODULE_ACTION_STATUS.NOT_PLAYABLE;

    if (module?.uploadAction === MODULE_UPLOAD_ACTIONS.OPTIONAL) return MODULE_ACTION_STATUS.OPTIONAL;

    if (module?.uploadAction === MODULE_UPLOAD_ACTIONS.REQUIRED) return MODULE_ACTION_STATUS.REQUIRED;

    if (module?.uploadAction === MODULE_UPLOAD_ACTIONS.NOT_ALLOWED) return MODULE_ACTION_STATUS.NOT_ALLOWED;

    return MODULE_ACTION_STATUS.FAILED;
  };

  const moduleActionType = getModuleActionStatus(module);
  const moduleActionItem = ACTION_STATUS_COMPONENTS[moduleActionType];

  const onIconClick = (event) => {
    event.stopPropagation();
    if (onClickAction) {
      onClickAction();
    }
  };

  const isRowClickable = isDraft && moduleActionType !== MODULE_ACTION_STATUS.NOT_ALLOWED;
  const onClickAction = isRowClickable ? onRowClick : null;

  return (
    <ClickableRow key={module.id} onClick={onClickAction} clickable={isRowClickable ? 1 : 0}>
      <Table.Cell textAlign="center" verticalAlign="middle">
        {module.number}
      </Table.Cell>
      <Table.Cell>{MODULE_TYPE_VALUES[module.moduleType]}</Table.Cell>
      <Table.Cell>
        {moduleActionType === MODULE_ACTION_STATUS.UPLOADED ? (
          <CellIconWrapper>
            <SimpleAudioWidget
              url={module.recording.resourceUrl}
              duration={0}
              text="Playing the module recording"
              playerOffset={115}
            />
          </CellIconWrapper>
        ) : (
          <>
            {moduleActionItem?.component && (
              <Popup
                content={moduleActionItem.text}
                trigger={<CellIconWrapper onClick={onIconClick}>{moduleActionItem.component}</CellIconWrapper>}
              />
            )}
          </>
        )}
      </Table.Cell>
      <Table.Cell>{module.text}</Table.Cell>
    </ClickableRow>
  );
};

export const ModuleRecordingsTable = ({ modules, community, onEditRecording }) => {
  if (!modules || modules.length === 0) {
    return <Message warning icon="file audio" content="No items found." />;
  }

  const isEnabled = !!community?.chatModuleRecordingSettings?.isEnabled;

  return (
    <>
      <StyledTable basic selectable={!isEnabled} striped>
        <ModuleRecordingsTableHeader />
        <Table.Body>
          {modules?.map((module) => (
            <ModuleRecordingsRow
              isDraft={!isEnabled}
              key={module.id}
              module={module}
              onRowClick={onEditRecording(module)}
            />
          ))}
        </Table.Body>
      </StyledTable>
    </>
  );
};
