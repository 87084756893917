import React from 'react';

import Dropdown from '../../form/fields/Dropdown';

const Selector = ({ options, onChange }) => {
  return (
    <Dropdown
      fluid
      selection
      options={options}
      selectOnBlur={false}
      placeholder="Select filter"
      onChange={onChange}
      search
    />
  );
};

export default Selector;
