export default {
  SET_IS_MULTIPLE_SELECTION: 'SET_IS_MULTIPLE_SELECTION',
  SET_SELECTED_ITEM: 'SET_SELECTED_ITEM',
  SET_SELECTED_PAGES: 'SET_SELECTED_PAGES',
  SET_SELECT_ALL_ITEMS: 'SET_SELECT_ALL_ITEMS',
  SET_RESET_IS_MULTIPLE_SELECTION: 'SET_RESET_IS_MULTIPLE_SELECTION',
  SET_RESET_SELECTED_PAGES: 'SET_RESET_SELECTED_PAGES',
  SET_RESET_SELECTED_ITEMS: 'SET_RESET_SELECTED_ITEMS',
  SET_RESET_EXCLUDED_ITEMS: 'SET_RESET_EXCLUDED_ITEMS',
  SET_RESET_ALL_ITEMS_SELECTED: 'SET_RESET_ALL_ITEMS_SELECTED',
  RESET_TABLE_SELECTION: 'RESET_TABLE_SELECTION',
  SET_RESET: 'SET_RESET',
};
