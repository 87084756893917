import React from 'react';
import { useHistory, useParams } from 'react-router';

import { VIEW_TYPE } from 'components/conversation/constants';
import { ConversationMain } from '../conversation';
import OngoingConversationNotSelected from './OngoingConversationNotSelected';
import { useMessagesContext } from './MessagesContext';
import { CONVERSATION_TYPES } from './constants';
import ArchivedConversationNotSelected from './ArchivedConversationNotSelected';

export const MessagesMainPanel = () => {
  const history = useHistory();
  const { leadId } = useParams();

  const { conversationPagesSorted, conversationType } = useMessagesContext();

  const onArchived = (conversation) => {
    const allConversations = conversationPagesSorted.map((p) => p.items).flat();
    let nextConversationId = null;
    const index = allConversations.findIndex((c) => c.id === conversation.id);

    if (allConversations[index + 1]) {
      nextConversationId = allConversations[index + 1].id;
    } else {
      console.warn('Next conversation could not be found');
    }

    if (nextConversationId) {
      history.replace(`/messages/${nextConversationId}`);
    } else {
      history.replace(`/messages`);
    }
  };

  const onUnarchived = (conversation) => {
    history.push(`/messages/${conversation.id}`);
  };

  const onModalClose = () => {
    history.push('/messages');
  };

  if (!leadId) {
    return conversationType === CONVERSATION_TYPES.ONGOING ? (
      <OngoingConversationNotSelected />
    ) : (
      <ArchivedConversationNotSelected />
    );
  }

  return (
    <ConversationMain
      viewType={VIEW_TYPE.MESSAGING}
      onArchived={onArchived}
      onUnarchived={onUnarchived}
      onModalClose={onModalClose}
    />
  );
};
