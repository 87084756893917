import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/lib';
import { ConversationsContext } from './ConversationsContext';
import { MultiSelectFilter } from '../common';

const GroupIcon = (props) => <Icon name="local_offer" variant="outlined" {...props} />;
const GROUPS = 'groups';

export const ConversationsDropdownCommunities = () => {
  const { t } = useTranslation();
  const { communities, groups, filters, handleFiltersChange } = useContext(ConversationsContext);
  const [selected, setSelected] = useState(null);
  const [selectedType, setSelectedType] = useState('communities');

  const options = {
    communities,
    groups: groups.map((g) => ({ ...g, Icon: GroupIcon.bind(null, { color: g.color }) })),
  };

  useEffect(() => {
    if (filters.communities.length > 0) {
      setSelectedType('communities');
    }
    if (filters.groups.length > 0) {
      setSelectedType(GROUPS);
    }
  }, [filters.communities, filters.groups]);

  useEffect(() => {
    const filterByType = filters[selectedType];
    setSelected(filterByType?.length > 0 ? filterByType : null);
  }, [selectedType, filters.communities, filters.groups]);

  const onSelectedChange = (selected, st) => {
    setSelectedType(st);
    // groups is a subset of communities, so if all groups are selected, it does not mean all communities are selected
    const allSelected = st !== GROUPS && selected?.length === options[st]?.length;
    const resetType = st !== selectedType && Object.keys(options).filter((k) => k !== st)[0];

    handleFiltersChange({
      [st]: allSelected ? [] : selected || [],
      [resetType]: [],
    });
  };

  return (
    <MultiSelectFilter
      placeholder={`Any ${t('community')} or group`}
      options={options}
      selectedValues={selected}
      onChange={onSelectedChange}
      selectedOptionTypeDefault={selectedType}
    />
  );
};
