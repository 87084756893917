import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, Dropdown, Icon } from 'components/lib';
import PromptModal from 'components/common/PromptModal';
import { capitalize } from 'utils/helpers';
import { STATUSES } from '../../../constants';
import { ConversationContext } from '../ConversationContext';
import { VIEW_TYPE } from '../constants';
import MessagingViewOptions from './MessagingViewOptions';
import ConversationViewOptions from './ConversationViewOptions';
import ConversationActions from './ConversationActions';

const StyledDropdown = styled(Dropdown)`
  &&&& {
    .item {
      border-bottom: 1px solid var(--line-standard);
      &.item-disabled {
        pointer-events: none;
      }
      padding: 8px 10px !important;
    }
    .header {
      border-bottom: solid 1px var(--line-standard);
      background-color: #f9f9f9;
      margin: 0;
      font-family: var(--font-family-bold);
      font-size: 10px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #89919d;
      padding: 5px 10px;
    }
  }
`;

const ConversationOptions = () => {
  const { t } = useTranslation();
  const {
    viewType,
    selectedConversation,
    markAsUnread,
    markAsRead,
    archiveConversation,
    unarchiveConversation,
    resubmitToCRM,
    resubmitStatus,
    resubmitLeadActionsToCRM,
    resubmitTourToCRM,
    resubmitCallToCRM,
    handleDeleteLead: deleteLead,
    deleteLeadLoading,
  } = useContext(ConversationContext);

  const [openResubmitToCRM, setOpenResubmitToCRM] = useState(false);
  const [openResubmitAdditionalActionsToCRM, setOpenResubmitAdditionalActionsToCRM] = useState(false);
  const [openResubmitTourToCRM, setOpenResubmitTourToCRM] = useState(false);
  const [openResubmitCallToCRM, setOpenResubmitCallToCRM] = useState(false);
  const [openDeleteLead, setOpenDeleteLead] = useState(false);

  if (!selectedConversation) {
    return null;
  }

  const handleUnarchiveConversation = () => {
    unarchiveConversation(selectedConversation);
  };

  const handleArchiveConversation = () => {
    archiveConversation(selectedConversation);
  };

  const handleMarkAsRead = () => {
    markAsRead(selectedConversation.id);
  };

  const handleMarkAsUnread = () => {
    markAsUnread(selectedConversation.id);
  };

  const toggleOpenResubmitToCRMModal = () => {
    setOpenResubmitToCRM(!openResubmitToCRM);
  };

  const toggleOpenResubmitAdditionalActionsToCRMModal = () => {
    setOpenResubmitAdditionalActionsToCRM(!openResubmitAdditionalActionsToCRM);
  };

  const toggleOpenResubmitTourToCRMModal = () => {
    setOpenResubmitTourToCRM(!openResubmitTourToCRM);
  };

  const toggleOpenResubmitCallToCRMModal = () => {
    setOpenResubmitCallToCRM(!openResubmitCallToCRM);
  };

  const toggleOpenDeleteLead = () => setOpenDeleteLead(!openDeleteLead);

  const handleResubmitToCRM = async () => {
    const response = await resubmitToCRM();

    if (response) {
      setOpenResubmitToCRM(false);
    }
  };

  const handleResubmitAdditionalActionsToCRM = async () => {
    const response = await resubmitLeadActionsToCRM();

    if (response) {
      setOpenResubmitAdditionalActionsToCRM(false);
    }
  };

  const handleResubmitTourToCRM = async () => {
    const response = await resubmitTourToCRM();

    if (response) {
      setOpenResubmitTourToCRM(false);
    }
  };

  const handleResubmitCallToCRM = async () => {
    const response = await resubmitCallToCRM();

    if (response) {
      setOpenResubmitCallToCRM(false);
    }
  };

  const handleDeleteLead = async () => {
    const response = await deleteLead();

    if (response) {
      setOpenDeleteLead(false);
    }
  };

  if (selectedConversation?.details?.is_processing_active) {
    return null;
  }

  return (
    <>
      <StyledDropdown
        icon={null}
        direction="left"
        trigger={
          <Button type="submit" primary color="blue">
            Actions
            <Icon className="button" name="expand_more" color="#fff" size="24" />
          </Button>
        }
      >
        <StyledDropdown.Menu>
          <ConversationActions />
          <StyledDropdown.Header content="Other" />
          {viewType === VIEW_TYPE.MESSAGING ? (
            <MessagingViewOptions
              conversation={selectedConversation}
              onUnarchiveConversation={handleUnarchiveConversation}
              onArchiveConversation={handleArchiveConversation}
              onMarkAsRead={handleMarkAsRead}
              onMarkAsUnread={handleMarkAsUnread}
            />
          ) : (
            <ConversationViewOptions
              conversation={selectedConversation}
              onResubmitToCRM={toggleOpenResubmitToCRMModal}
              onResubmitAdditionalActionToCRM={toggleOpenResubmitAdditionalActionsToCRMModal}
              onResubmitTourToCRM={toggleOpenResubmitTourToCRMModal}
              onResubmitCallToCRM={toggleOpenResubmitCallToCRMModal}
              onDeleteLead={toggleOpenDeleteLead}
            />
          )}
        </StyledDropdown.Menu>
      </StyledDropdown>

      <PromptModal
        open={openResubmitToCRM}
        title={`Submit ${t('lead')} to CRM`}
        description={`Are you sure you want to submit ${t('lead')} and all related entities to CRM?`}
        loading={resubmitStatus === STATUSES.LOADING}
        onConfirm={handleResubmitToCRM}
        onClose={toggleOpenResubmitToCRMModal}
      />
      <PromptModal
        open={openResubmitAdditionalActionsToCRM}
        title={`Resubmit ${t('lead')} Actions to CRM`}
        description={`Are you sure you want to resubmit ${t('lead')} additional actions to CRM?`}
        loading={resubmitStatus === STATUSES.LOADING}
        onConfirm={handleResubmitAdditionalActionsToCRM}
        onClose={toggleOpenResubmitAdditionalActionsToCRMModal}
      />
      <PromptModal
        open={openResubmitTourToCRM}
        title={`Resubmit ${capitalize(t('tour'))} to CRM`}
        description="Are you sure you want to resubmit tour to CRM?"
        loading={resubmitStatus === STATUSES.LOADING}
        onConfirm={handleResubmitTourToCRM}
        onClose={toggleOpenResubmitTourToCRMModal}
      />
      <PromptModal
        open={openResubmitCallToCRM}
        title="Resubmit Call to CRM"
        description="Are you sure you want to resubmit call to CRM?"
        loading={resubmitStatus === STATUSES.LOADING}
        onConfirm={handleResubmitCallToCRM}
        onClose={toggleOpenResubmitCallToCRMModal}
      />
      <PromptModal
        open={openDeleteLead}
        title={`Delete ${t('lead')}`}
        description={`Are you sure you want to delete ${t('lead')}? This action cannot be undone.`}
        loading={deleteLeadLoading}
        onConfirm={handleDeleteLead}
        onClose={toggleOpenDeleteLead}
      />
    </>
  );
};

export default ConversationOptions;
