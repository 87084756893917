import React from 'react';

import { VSA_LINE_TYPE } from '../../constants';
import { EventMessage } from './chat/EventMessage';
import { FurtherMessage } from './chat/FurtherMessage';
import { UserMessage } from './chat/UserMessage';
import { VSAMessage } from './chat/VSAMessage';
import { CalendarMessage, ConversationStopped } from './chat';
import { CONVERSATION_MESSAGE_TYPE, MESSAGE_SENDER_TYPE } from './constants';
import { ConversationPhoneCallInfo } from './actions/ConversationPhoneCallInfo';
import { ForwardedCallEventMessage } from './chat/ForwardedCallEventMessage';
import { LeadNote } from './chat/LeadNote';

const ConversationAppMessage = ({ data, ...props }) => {
  const { id, text, sender_type, message_sent_at, attachment, status, subject } = data;

  if (sender_type === MESSAGE_SENDER_TYPE.LEAD) {
    return <UserMessage text={text} timestamp={message_sent_at} attachment={attachment} {...props} />;
  }

  const furtherReplied =
    sender_type === MESSAGE_SENDER_TYPE.SALES_ASSISTANT || sender_type === MESSAGE_SENDER_TYPE.AUTOMATED;
  return (
    <FurtherMessage
      messageId={id}
      text={text}
      status={status}
      subject={subject}
      timestamp={message_sent_at}
      attachment={attachment}
      furtherReplied={furtherReplied}
      {...props}
    />
  );
};

const ConversationVSAMessage = ({ data, user }) => {
  const { text, line_type, created_on, additional_data, vsa_type } = data;
  if (!text) {
    return null;
  }

  switch (line_type) {
    case VSA_LINE_TYPE.VSA:
      return <VSAMessage text={text} vsaType="VSA" />;
    case VSA_LINE_TYPE.EVSA:
      return <VSAMessage text={text} vsaType="CW" />;
    case VSA_LINE_TYPE.SURVEY:
      return <VSAMessage text={text} vsaType="Survey" />;
    case VSA_LINE_TYPE.VVSA:
      return <VSAMessage text={text} vsaType="IVR" />;
    case VSA_LINE_TYPE.PROSPECT:
      return (
        <UserMessage
          text={text}
          user={user}
          timestamp={created_on}
          messageType={CONVERSATION_MESSAGE_TYPE.VSA}
          vsa_type={vsa_type}
        />
      );
    case VSA_LINE_TYPE.FORWARDED_CALL:
      return <ForwardedCallEventMessage data={data} />;
    case VSA_LINE_TYPE.EVENT:
      return <EventMessage text={text} user={user} timestamp={created_on} additional_data={additional_data} />;
    default:
      return null;
  }
};

export const ConversationMessage = ({ message, user, community, onAttachmentLoad, onResendMessage }) => {
  const { message_type, data } = message;

  if (message_type === CONVERSATION_MESSAGE_TYPE.CALENDAR_REQUEST) {
    return <CalendarMessage data={data} user={user} community={community} messageType={message_type} />;
  }

  if (message_type === CONVERSATION_MESSAGE_TYPE.CALL) {
    return <ConversationPhoneCallInfo data={data} />;
  }

  if (message_type === CONVERSATION_MESSAGE_TYPE.VSA) {
    return <ConversationVSAMessage data={data} user={user} />;
  }

  if (message_type === CONVERSATION_MESSAGE_TYPE.CONVERSATION_STOPPED) {
    return <ConversationStopped data={data} />;
  }

  if (message_type === CONVERSATION_MESSAGE_TYPE.NOTE) {
    return <LeadNote text={data} />;
  }

  return (
    <ConversationAppMessage
      data={data}
      messageType={message_type}
      user={user}
      community={community}
      onAttachmentLoad={onAttachmentLoad}
      onResendMessage={onResendMessage}
    />
  );
};
