import React from 'react';

import { Module } from './Module';
import { MessageInputField } from './MessageInputField';
import { SendIcon } from './icons';
import { Answers } from './Answers';

export const Slack = ({ node }) => (
  <Module node={node}>
    <Answers node={node} />
    <MessageInputField>
      <span>Type here...</span>
      <SendIcon />
    </MessageInputField>
  </Module>
);
