import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { formatCount, formatDurationAsTime } from 'components/utils';
import { getReportMetrics } from 'apis';
import { toast } from 'react-toastify';
import { parseErrorResponse } from 'apis/utils';
import queryConstants from 'utils/queries/constants';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import { PercentCircle, SimpleMetric } from 'components/common/analytics';

const metrics = [
  'calls.lead',
  'calls.lead.missed',
  'calls.lead.followup',
  'calls.lead.duration',
  'calls.firstlead.duration',
];

const Leads = ({ filters, publicId }) => {
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.METRICS, { publicId, filters, metrics }],
    () => getReportMetrics({ public_id: publicId, filters: { ...filters, metrics } }),
    { placeholderData: {} }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  const parsedData = useMemo(() => {
    if (!data || !data['calls.lead']) {
      return data;
    }
    const total = data['calls.lead'].value;
    const missed = data['calls.lead.missed'].value;
    const answered = total - missed;
    const followup = data['calls.lead.followup'].value;
    const avgDuration = data['calls.lead.duration'].value;
    const firstAvgDuration = data['calls.firstlead.duration'].value;
    const chartData = {
      Answered: answered,
      Missed: missed,
    };

    return { chartData, total, missed, answered, followup, avgDuration, firstAvgDuration };
  }, [data]);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch lead call data'));
  }

  return (
    <PercentCircle
      loaderStatus={loaderStatus}
      data={parsedData?.chartData}
      radius={120}
      circleWidth={12}
      units="lead calls"
      color="#5f5fff"
      additionalMetrics={
        <>
          <SimpleMetric
            metric={formatDurationAsTime(parsedData?.avgDuration)}
            unit="avg. lead call"
            fontSize="20px"
            unitFontSize="14px"
          />
          <SimpleMetric
            metric={formatDurationAsTime(parsedData?.firstAvgDuration)}
            unit="avg. first lead call"
            fontSize="14px"
            unitFontSize="14px"
          />
        </>
      }
    />
  );
};

export default Leads;
