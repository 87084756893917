import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon, Loader } from 'components/lib';

const Invitation = styled.div`
  color: ${(props) => props.color || 'var(--text)'};
  cursor: ${(props) => (props.readOnly ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  gap: 5px;

  span {
    margin-bottom: 3px;
  }
`;

const LoaderBox = styled.div`
  display: flex;
  padding-left: 25px;
`;

const ManagementUserInvite = ({ managementUser, onSendInvite }) => {
  const [sendingInvite, setSendingInvite] = useState(false);
  const { user, sendInvite } = managementUser;
  const [inviteSent, setInviteSent] = useState(false);

  const handleSendInvite = async () => {
    setSendingInvite(true);
    await onSendInvite(managementUser);
    setSendingInvite(false);
    setInviteSent(true);
  };

  if (user.lastLogin) {
    return (
      <Invitation readOnly color="var(--emerald-green)">
        <Icon name="check_circle" color="var(--emerald-green)" />
        Logged in
      </Invitation>
    );
  }

  if (inviteSent) {
    return (
      <Invitation readOnly color="var(--emerald-green)">
        <Icon name="mail" color="var(--emerald-green)" />
        Invite sent
      </Invitation>
    );
  }

  if (sendingInvite) {
    return (
      <LoaderBox>
        <Loader active inverted size="tiny" />
      </LoaderBox>
    );
  }

  return (
    <Invitation onClick={handleSendInvite} color="var(--link)">
      <Icon name={sendInvite ? 'refresh' : 'send'} color="var(--link)" />
      {sendInvite ? 'Send reminder' : 'Send invite'}
    </Invitation>
  );
};

export default ManagementUserInvite;
