import React, { useContext } from 'react';
import { Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { uploadIcon } from 'api';
import { UserCommunityPanelHeader } from './UserCommunityPanelHeader';
import { ImagePicker } from '../../chats/ImagePicker';
import { CommunityAssetsContext } from '../CommunityAssetsContext';
import { CommunitiesContext } from '../../common/communities/CommunitiesContext';
import { STATUSES, MAX_FILE_UPLOAD_SIZE, MAX_FILE_UPLOAD_SIZE_MB } from '../../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const UserCommunityIcon = () => {
  const { state } = useContext(CommunitiesContext);
  const { updateStatus, onUpdateAssets } = useContext(CommunityAssetsContext);
  const { activeCommunity } = state;

  const handleIconSelected = async (icon) => {
    try {
      if (icon.size > MAX_FILE_UPLOAD_SIZE) {
        toast.error(`Image size should not be greater than ${MAX_FILE_UPLOAD_SIZE_MB}MB`);
        return null;
      }

      const { url } = await uploadIcon(icon);
      return url;
    } catch (e) {
      console.error(e);
      toast.error('Error uploading icon');
    }
  };

  const handleUpdate = ({ icon }) => onUpdateAssets({ icon: icon || '' });

  const handleDelete = (setFieldValue) => {
    setFieldValue('icon', null);
  };

  const updateStatusActive = updateStatus === STATUSES.LOADING;

  return (
    <Formik initialValues={{ icon: activeCommunity?.media?.icon }} onSubmit={handleUpdate}>
      {({ handleSubmit, dirty, setFieldValue }) => (
        <Container>
          <UserCommunityPanelHeader
            title="Edit Icon"
            disabled={!dirty || updateStatusActive}
            loading={updateStatusActive}
            onUpdate={handleSubmit}
          />
          <Form.Field>
            <ImagePicker
              name="icon"
              onImageSelected={handleIconSelected}
              onImageDelete={() => handleDelete(setFieldValue)}
            />
          </Form.Field>
        </Container>
      )}
    </Formik>
  );
};
