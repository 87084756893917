import { Popup, Icon, List } from 'semantic-ui-react';
import React from 'react';
import { formatCount } from 'components/utils';
import { ListWrapper } from './CampaignStatisticsTable';

export const CommunityListPopup = ({ campaign }) => (
  <Popup
    hoverable
    content={
      <ListWrapper
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <List relaxed size="small">
          {campaign.relatedProfiles.map((p, i) => (
            <List.Item key={i}>
              <List.Icon name="marker" color="grey" />
              <List.Content>{p.name}</List.Content>
            </List.Item>
          ))}
        </List>
      </ListWrapper>
    }
    trigger={
      <span>
        {formatCount(campaign.relatedProfiles?.length)}
        &nbsp; &nbsp;
        <Icon name="eye" color="grey" />
      </span>
    }
    position="bottom center"
    wide
  />
);
