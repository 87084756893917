import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const UserName = styled.div`
  font-size: 14px;
  color: var(--text);
  white-space: nowrap;
`;

const SubtitleBox = styled.div`
  font-size: 12px;
  color: #1d252d;
`;

const UserType = styled.span`
  text-transform: capitalize;
`;

export const BusinessActivityAuthor = ({ author, owner, source }) => {
  if (!author) {
    return `System${source ? `/${source}` : ''}`;
  }

  return (
    <Container>
      <UserName>{author.displayName}</UserName>
      <SubtitleBox>
        <UserType>{author.role}</UserType>
        {owner && <span>,&nbsp;supporting {owner.displayName}</span>}
      </SubtitleBox>
    </Container>
  );
};
