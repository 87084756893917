import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 24px;
`;

export const IconsWrapper = ({ children }) => {
  return <Container>{children}</Container>;
};
