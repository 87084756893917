import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { Form, Radio } from 'components/lib';
import { STATUSES } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';
import { FORM_OPTIONS, SETTINGS } from './constants';

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 10px;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const RadioBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:first-child) {
    margin-left: 30px;
  }
  > span {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 6px;
  }
`;

const getSettingsValue = ({ strictUrl, isFallbackConfig }) => {
  if (strictUrl) {
    if (isFallbackConfig) {
      return SETTINGS.ON_INCLUDING_SUBPAGES;
    }
    return SETTINGS.ON;
  }

  return SETTINGS.OFF;
};

const EditModal = ({ isModalOpen, selectedItem, totalItemsCount, onSave, onClose }) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    const value = !values.value ? null : values.value;
    const data = {
      strict_chat_url: value === FORM_OPTIONS.ON.value || value === FORM_OPTIONS.ON_INCLUDING_SUBPAGES.value,
      is_fallback_chat_config: value === FORM_OPTIONS.ON_INCLUDING_SUBPAGES.value,
    };
    await onSave({ web_assistant_strict_url: data });

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const initialValues = {
    value: selectedItem
      ? getSettingsValue({
          strictUrl: selectedItem.strict_chat_url,
          isFallbackConfig: selectedItem.is_fallback_chat_config,
        })
      : '',
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue }) => (
        <SettingsModal
          loading={loading}
          open={isModalOpen}
          onModalClose={() => handleClose(resetForm)}
          size="tiny"
          width={540}
        >
          <SettingsModalHeader
            title="Web Assistant strict URL"
            community={selectedItem || null}
            totalItemsCount={totalItemsCount}
            type="header"
          />
          <div type="body">
            <BodyText>Choose if web assistants show on a strict URL rather than the main page.</BodyText>
            <RadioContainer>
              {Object.keys(FORM_OPTIONS).map((key) => {
                const option = FORM_OPTIONS[key];
                return (
                  <RadioBox key={key}>
                    <Form.Field
                      control={Radio}
                      name="value"
                      value={String(option.value)}
                      checked={String(values.value) === String(option.value)}
                      onChange={(e, { value }) => setFieldValue('value', String(value))}
                    />
                    <span>{option.text}</span>
                  </RadioBox>
                );
              })}
            </RadioContainer>
          </div>
          <SettingsModalActions
            onSave={handleSubmit}
            onCancel={() => handleClose(resetForm)}
            type="actions"
            disabled={!dirty || !touched}
            saveText="Save and Publish"
          />
        </SettingsModal>
      )}
    </Formik>
  );
};

export default EditModal;
