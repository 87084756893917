import React from 'react';
import { Table, Button, Icon, Dropdown } from 'semantic-ui-react';
import { useFormikContext } from 'formik';

import { InputField } from 'components/common/fields/InputField';
import { CheckboxField } from 'components/common';
import { createDefaultUnitType, UNIT_TYPES, useUnitLevelPricing } from './context';

const LivingAccommodationTable = ({ arrayHelpers }) => {
  const [{ careTypes }] = useUnitLevelPricing();
  const { values } = useFormikContext();
  const unitTypeOptions = UNIT_TYPES.map((ut, index) => ({ key: index, value: ut?.id, text: ut?.title }));

  if (!values) {
    return null;
  }

  const { units } = values;

  if (!units) {
    return null;
  }

  const handleAddUnitType = () => {
    arrayHelpers.push(createDefaultUnitType(careTypes));
  };

  const handleRemoveUnitType = (index) => () => {
    arrayHelpers.remove(index);
  };

  return (
    <>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Number of beds</Table.HeaderCell>
            <Table.HeaderCell>Number of bathrooms</Table.HeaderCell>
            <Table.HeaderCell>Size (Sq Ft)</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Private</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {units.map((u, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <InputField
                  control={Dropdown}
                  name={`units[${index}].unitType`}
                  placeholder="Select room type"
                  selection
                  options={unitTypeOptions}
                  fluid
                />
              </Table.Cell>
              <Table.Cell>
                <InputField placeholder="Number of beds" name={`units[${index}].numberOfBeds`} type="number" fluid />
              </Table.Cell>
              <Table.Cell>
                <InputField
                  placeholder="Number of bathrooms"
                  name={`units[${index}].numberOfBathrooms`}
                  type="number"
                  fluid
                />
              </Table.Cell>
              <Table.Cell>
                <InputField placeholder="Unit size" name={`units[${index}].unitSize`} type="number" fluid />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <CheckboxField name={`units[${index}].isPrivate`} />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon
                  name="trash alternate outline"
                  color="red"
                  link
                  onClick={handleRemoveUnitType(index)}
                  circular
                  inverted
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Button type="button" basic color="green" content="Add Unit type" icon="plus" onClick={handleAddUnitType} />
    </>
  );
};

export default LivingAccommodationTable;
