import React, { useContext } from 'react';
import { Confirm } from 'semantic-ui-react';

import { InstantAnswersContext } from './InstantAnswersContext';

export const InstantAnswersUnsavedChangesConfirm = () => {
  const { showConfirmUnsavedIA, selectedAttribute, setShowConfirmUnsavedIA, onAttributeSelected } =
    useContext(InstantAnswersContext);

  const onCancel = () => {
    setShowConfirmUnsavedIA(false);
  };

  const onConfirm = () => {
    onAttributeSelected(selectedAttribute);
    setShowConfirmUnsavedIA(false);
  };

  return (
    <Confirm
      content="You have unsaved changes, are you sure that you want to continue?"
      cancelButton="No"
      confirmButton="Yes"
      open={showConfirmUnsavedIA}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};
