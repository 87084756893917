import React, { useState } from 'react';
import styled from 'styled-components';

import { FURHTER_ICON, Icon, Modal } from 'components/lib';
import { ReactComponent as ConversionEngineImage } from 'assets/images/conversion-engine.svg';
import { MEDIA_BREAK_POINTS } from '../../constants';
import { ConversionEngineModal } from '.';

const ConversionEngineTrigger = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  padding: 12px 21px 12px 30px;
  border-radius: 32px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: var(--white);
  margin-top: 60px;
  cursor: pointer;

  svg {
    margin-right: 20px;
  }
  .${FURHTER_ICON} {
    margin-left: auto;
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    width: calc(100% - 180px);
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    margin: 0;
    font-family: var(--font-family-bold);
    font-size: 16px;
    color: var(--text);
  }

  p {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 500;
    color: #89919d;
    span {
      color: var(--link);
    }
  }
`;

export const ConversionEngine = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      size="small"
      trigger={
        <ConversionEngineTrigger>
          <ConversionEngineImage width={118} height={40} />
          <TextContainer>
            <h3>Further AI-powered Conversion Engine</h3>
            <p>
              View opportunities for Further to drive occupancy performance at your communities.{' '}
              <span>How does this work?</span>
            </p>
          </TextContainer>
          <Icon name="chevron_right" color="#222222" />
        </ConversionEngineTrigger>
      }
    >
      <ConversionEngineModal onClose={handleClose} />
    </Modal>
  );
};
