import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { If } from 'components/If';
import { getUserProfile } from 'apis';
import { Dimmer, Loader } from 'components/lib';
import { parseErrorResponse } from 'apis/utils';
import { MyProfileInfo } from './MyProfileInfo';
import { MyProfileDivider } from './MyProfileDivider';
import { MyProfilePassword } from './MyProfilePassword';

const Container = styled.div`
  position: relative;
`;

export const MyProfilePersonalTab = () => {
  const [isLoading, setIsLoading] = useState();
  const [profileData, setProfileData] = useState({});

  const fetchProfileData = async () => {
    try {
      setIsLoading(true);
      const response = await getUserProfile();
      setIsLoading(false);
      setProfileData(response);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to fetch profile data!'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  return (
    <Container>
      <Dimmer active={isLoading} inverted transparent>
        <Loader active transparent />
      </Dimmer>
      <If condition={!isLoading}>
        <MyProfileInfo data={profileData} setProfileData={setProfileData} />
        <MyProfileDivider data={profileData} />
        <MyProfilePassword data={profileData} />
      </If>
    </Container>
  );
};
