import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Modal } from 'components/lib';
import useInterval from 'utils/hooks/useInterval';

import Img1 from 'assets/images/WebsiteScanningAnimation/img-1.png';
import Img2 from 'assets/images/WebsiteScanningAnimation/img-2.png';
import Img3 from 'assets/images/WebsiteScanningAnimation/img-3.png';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 64vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 540px) {
    padding: 30px;
  }

  .onboarding-highlight {
    font-family: var(--font-family);
    color: #222;
  }

  .onboarding-modal-heading {
    font-family: var(--font-family-bold);
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .onboarding-grey {
    color: #89919d;
    font-size: 12px;
    line-height: 18px;
  }

  .onboarding-loading-img {
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    height: 118px;
  }

  .onboarding-img-1 {
    width: 120px;
    background-image: url(${Img1});
    background-size: 120px 118px;
  }

  .onboarding-img-2 {
    width: 136px;
    background-image: url(${Img2});
    background-size: 136px 118px;
  }

  .onboarding-img-3 {
    width: 180px;
    background-image: url(${Img3});
    background-size: 180px 118px;
  }

  .onboarding-loading {
    width: 300px;
    margin: 0 auto;
  }

  .onboarding-loading-label {
    display: inline-block;
    font-size: 14px;
    font-family: var(--font-family-bold);
  }

  .onboarding-progress-bar {
    box-sizing: border-box;
    width: 100%;
    height: 5px;
    background-color: #eaeaeb;
    border-radius: 10px;
  }

  .onboarding-progress-bar .progress {
    height: 5px;
    background-image: linear-gradient(to right, #275aff, #27b4ff);
    border-radius: 10px;
    margin-top: 14px;
  }

  .onboarding-progress-bar .progress.initial {
    width: 3%;
  }

  .onboarding-progress-bar .progress.first {
    width: 50%;
  }

  .onboarding-progress-bar .progress.second {
    width: 75%;
  }

  .onboarding-progress-bar .progress.third {
    width: 95%;
  }

  div.onboarding-spinner {
    position: relative;
    width: 22px;
    height: 22px;
    display: inline-block;
    top: 5px;
    margin-right: 10px;
  }

  div.onboarding-spinner div {
    width: 10%;
    height: 26%;
    background: #c1c1c1;
    position: absolute;
    left: 49%;
    top: 43%;
    opacity: 0;
    border-radius: 50px;
    animation: onboarding-fade 1s linear infinite;
  }

  @keyframes onboarding-fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.25;
    }
  }

  div.onboarding-spinner div.bar1 {
    transform: rotate(0deg) translate(0, -130%);
    animation-delay: 0s;
  }

  div.onboarding-spinner div.bar2 {
    transform: rotate(30deg) translate(0, -130%);
    animation-delay: -0.9167s;
  }

  div.onboarding-spinner div.bar3 {
    transform: rotate(60deg) translate(0, -130%);
    animation-delay: -0.833s;
  }
  div.onboarding-spinner div.bar4 {
    transform: rotate(90deg) translate(0, -130%);
    animation-delay: -0.7497s;
  }
  div.onboarding-spinner div.bar5 {
    transform: rotate(120deg) translate(0, -130%);
    animation-delay: -0.667s;
  }
  div.onboarding-spinner div.bar6 {
    transform: rotate(150deg) translate(0, -130%);
    animation-delay: -0.5837s;
  }
  div.onboarding-spinner div.bar7 {
    transform: rotate(180deg) translate(0, -130%);
    animation-delay: -0.5s;
  }
  div.onboarding-spinner div.bar8 {
    transform: rotate(210deg) translate(0, -130%);
    animation-delay: -0.4167s;
  }
  div.onboarding-spinner div.bar9 {
    transform: rotate(240deg) translate(0, -130%);
    animation-delay: -0.333s;
  }
  div.onboarding-spinner div.bar10 {
    transform: rotate(270deg) translate(0, -130%);
    animation-delay: -0.2497s;
  }
  div.onboarding-spinner div.bar11 {
    transform: rotate(300deg) translate(0, -130%);
    animation-delay: -0.167s;
  }
  div.onboarding-spinner div.bar12 {
    transform: rotate(330deg) translate(0, -130%);
    animation-delay: -0.0833s;
  }

  .animate__animated {
    animation-duration: 3.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }

  @keyframes onboardingAnimate {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    25% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    75% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }

  @keyframes onboardingAnimate {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    25% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    75% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }
  .animate__onboardingAnimate {
    animation-name: onboardingAnimate;
    animation-name: onboardingAnimate;
  }
`;

export const WebsiteScanningAnimation = ({ visible }) => {
  const [step, setStep] = useState(0);

  const steps = [
    {
      progress: 'initial',
      text: 'Gathering facts...',
      img: 'onboarding-img-1',
      delay: 1, // seconds
    },
    {
      progress: 'first',
      text: 'Gathering facts...',
      img: 'onboarding-img-1',
      delay: 20, // seconds
    },
    {
      progress: 'second',
      text: 'Extrapolating visitor intents...',
      img: 'onboarding-img-2',
      delay: 20, // seconds
    },
    {
      progress: 'third',
      text: 'Generating qualifying questions...',
      img: 'onboarding-img-3',
      delay: 20, // seconds
    },
  ];

  const currentStep = steps[step % steps.length];

  useEffect(() => {
    if (visible && step < 3) {
      const delay = steps[step % steps.length].delay * 1000;

      const timeout = setTimeout(() => {
        setStep((step) => step + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [visible, step]);

  if (!visible) return null;

  return (
    <Wrapper>
      <h5 className="onboarding-modal-heading">Scanning your website with AI...</h5>
      <div className="onboarding-grey">
        We are fetching some data from your website so that we can generate the appropriate options and questions for
        your web assistant with <span className="onboarding-highlight">AI</span>. This can take{' '}
        <span className="onboarding-highlight">a minute or two</span>.
      </div>

      <div className={`onboarding-loading-img animate__animated animate__onboardingAnimate ${currentStep.img}`} />

      <div className="onboarding-loading">
        <div className="onboarding-spinner">
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
          <div className="bar4"></div>
          <div className="bar5"></div>
          <div className="bar6"></div>
          <div className="bar7"></div>
          <div className="bar8"></div>
          <div className="bar9"></div>
          <div className="bar10"></div>
          <div className="bar11"></div>
          <div className="bar12"></div>
        </div>
        <div className="onboarding-loading-label">{currentStep.text}</div>
        <div className="onboarding-progress-bar">
          <div className={`progress ${currentStep.progress}`}></div>
        </div>
      </div>
    </Wrapper>
  );
};
