import React from 'react';

import { ChatModulesPreview } from './ChatModulesPreview';
import { ChatModulesContextProvider } from './ChatModulesContext';

export const ChatModules = (props) => {
  return (
    <ChatModulesContextProvider preview={props.preview} {...props}>
      <ChatModulesPreview />
    </ChatModulesContextProvider>
  );
};
