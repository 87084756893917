import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  background-color: #1f57bd;
  padding: 0.5rem 0.8rem;
  border-radius: 50rem;
  color: white;
  line-height: 1.5rem;
  margin-top: 1rem;
  cursor: pointer;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  letter-spacing: 0.5px;
`;

const NewMessagesBox = ({ show, count, onClick }) => {
  if (!show) {
    return null;
  }

  return (
    <Container>
      <Message onClick={onClick}>
        Check {count} new {count === 1 ? 'message' : 'messages'} ...
      </Message>
    </Container>
  );
};

export default NewMessagesBox;
