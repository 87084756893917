import React from 'react';
import { Route, useParams } from 'react-router-dom';

import { Sidebar } from 'components/common';
import { MapPin, Mail, Home, Dollar, Settings } from '../Icons';
import { Pricing } from './Pricing';
import { RoomAndServiceOptions } from './RoomAndServiceOptions';
import { ContactInfo } from './ContactInfo';
import { Locations } from './Locations';
import { StepIndicator } from './StepIndicator';
import { StepWrapper } from './StepWrapper';
import { Main } from './Main';
import { ChatSettings } from './ChatSettings';

const steps = [
  {
    icon: MapPin,
    title: 'Locations',
    component: Locations,
  },
  {
    icon: Mail,
    title: 'Contact Info',
    component: ContactInfo,
  },
  {
    icon: Home,
    title: 'Room & Service Options',
    component: RoomAndServiceOptions,
  },
  {
    icon: Settings,
    title: 'Chat Settings',
    component: ChatSettings,
  },
  {
    icon: Dollar,
    title: 'Pricing',
    component: Pricing,
  },
];

export const Steps = () => {
  const { stepNumber } = useParams();

  return (
    <StepWrapper>
      <Sidebar>
        <StepIndicator steps={steps} activeStep={stepNumber} />
      </Sidebar>
      <Main>
        {steps.map((s, i) => (
          <Route key={i} path={`/onboarding/steps/${i + 1}`} component={s.component} />
        ))}
      </Main>
    </StepWrapper>
  );
};
