import { capitalize } from 'utils/helpers';

export const LEAD_STATUS = (t, eventType) => ({
  NONE: undefined,
  FINANCIALLY_UNQUALIFIED: 'Financially Unqualified',
  INBOUND_CALL: 'Incoming Call',
  OUTBOUND_CALL: 'Outgoing Call',
  CALENDAR_REQUEST_SCHEDULED: `${capitalize(t(eventType))} Requested`,
  CALENDAR_REQUEST_CONFIRMED: `${capitalize(t(eventType))} Confirmed`,
  CALENDAR_REQUEST_OFFERED: `${capitalize(t(eventType))} Offered`,
  MOVED_IN: capitalize(t('move_in')),
});
