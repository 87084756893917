import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { resendEmailVerificationCode } from 'apis';
import { Icon, Loader } from 'components/lib';
import { If } from 'components/If';
import { OnboardingContext } from '../OnboardingContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  height: fit-content;
  flex: 1 0 auto;
`;

const IconContainer = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 10px;
  background-color: #ebf5ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h3`
  font-family: var(--font-family-bold);
  font-size: 60px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.97;
  letter-spacing: normal;
  text-align: center;
  color: #222;
  margin: 30px 0;
`;

const Subtitle = styled.p`
  font-family: var(--font-family);
  font-size: 16px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #222;
  strong {
    font-family: var(--font-family-bold);
  }
  .resend {
    color: #1f58b0;
    cursor: pointer;
  }
  .sent {
    color: #0ab776;
  }
`;

export const CheckYourEmail = () => {
  const { onboarding } = useContext(OnboardingContext);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [anotherEmailSent, setAnotherEmailSent] = useState(false);

  const handleResendLink = async () => {
    setSendingEmail(true);
    try {
      await resendEmailVerificationCode({
        onboardingUuid: onboarding.onboardingUuid,
        email: onboarding.collectedData.email,
      });
      setSendingEmail(false);
      setAnotherEmailSent(true);
    } catch (error) {
      console.error('error', error);
      setSendingEmail(false);
    }
  };

  if (!onboarding) return null;

  return (
    <Wrapper>
      <IconContainer>
        <Icon name="send" variant="outlined" color="#275AFF" size="56" />
      </IconContainer>
      <Title>Check your email</Title>
      <Subtitle>
        We just sent a verification email to <strong>{onboarding.collectedData.email}</strong>.
        <br />
        It has a security verification link. Click on it to continue.
        <br />
        <br />
        <If condition={!sendingEmail}>
          <If condition={anotherEmailSent}>
            <span className="sent">Sent another link. </span>
          </If>
          Didn’t get anything?{' '}
          <span className="resend" onClick={handleResendLink}>
            Resend link
          </span>
        </If>
      </Subtitle>
      <Loader size="small" active={sendingEmail} />
    </Wrapper>
  );
};
