import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { Radio, Form, Checkbox } from 'components/lib';
import google from 'assets/images/google.png';
import office365 from 'assets/images/office365.png';
import iCloud from 'assets/images/iCloud.png';
import outlook from 'assets/images/outlook.png';
import { CONNECTED_CALENDARS_VALUES } from '../../../constants';
import ConnectedCalendarsUsers from './ConnectedCalendarsUsers';
import { useSettings } from '../context/SettingsContext';

const Text = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 10px;
`;

const FormField = styled(Form.Field)`
  &&&& {
    margin-bottom: 0;
  }
`;

const CustomRadio = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 6px;
  }
  &.off {
    margin-top: 15px;
  }
  &.on {
    margin-top: 14px;
    margin-bottom: 14px;
    max-width: 460px;
    flex-wrap: wrap;
  }
`;

const Image = styled.img`
  margin-right: 10px;
  margin-top: 4px;
  height: 15px;
`;

const OnList = styled.ul`
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  padding-left: 40px;
`;

const StyledForm = styled(Form)`
  height: 320px;
  display: flex;
  justify-content: space-between;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${({ paddingLeft }) => paddingLeft}px;
  margin-top: 10px;
  span {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
    margin-left: 10px;
  }
`;

const ConnectedCalendarsModalBody = ({ values, setFieldValue }) => {
  const [{ selectedItems }] = useSettings();
  const { t } = useTranslation();
  return (
    <StyledForm>
      <div>
        <Text>Choose whether to sync personal calendars.</Text>
        <CustomRadio className="off">
          <FormField
            control={Radio}
            name="connectedCalendar"
            value={CONNECTED_CALENDARS_VALUES.OFF.value}
            checked={values.connectedCalendar === CONNECTED_CALENDARS_VALUES.OFF.value}
            onChange={(e, { value }) => {
              setFieldValue(`connectedCalendar`, value);
            }}
          />
          <span>{CONNECTED_CALENDARS_VALUES.OFF.text}</span>
        </CustomRadio>
        <CustomRadio className="on">
          <FormField
            control={Radio}
            name="connectedCalendar"
            value={CONNECTED_CALENDARS_VALUES.ENHANCED.value}
            checked={values.connectedCalendar === CONNECTED_CALENDARS_VALUES.ENHANCED.value}
            onChange={(e, { value }) => {
              setFieldValue(`connectedCalendar`, value);
            }}
          />
          <span>{CONNECTED_CALENDARS_VALUES.ENHANCED.text}</span>
          <Image src={google} alt="google" width={44} />
          <Image src={office365} alt="google" width={65} />
          <Image src={iCloud} alt="google" width={23} />
          <Image src={outlook} alt="google" width={73} />
          <OnList>
            <li>Handle {t('tour')} requests from Further</li>
            <li>
              Facilitate rescheduling without client interaction, providing available slots based on your calendar
            </li>
            <li>Keep teammates informed about the {t('tour')} request status automatically</li>
            <li>Include tour events in your calendar upon confirmation</li>
            <li>
              Showcase available slots on web assistants according to calendar availability (make sure to turn on{' '}
              <Link to="/advanced/tour-availability" target="_blank">
                real-time availability
              </Link>{' '}
              after syncing a calendar).
            </li>
          </OnList>
        </CustomRadio>
        <If
          condition={
            values.connectedCalendarCurrentValue === CONNECTED_CALENDARS_VALUES.BASIC.value &&
            selectedItems.length === 1
          }
        >
          <CustomRadio>
            <FormField
              control={Radio}
              name="connectedCalendar"
              value={CONNECTED_CALENDARS_VALUES.BASIC.value}
              checked={values.connectedCalendar === CONNECTED_CALENDARS_VALUES.BASIC.value}
              onChange={(e, { value }) => {
                setFieldValue(`connectedCalendar`, value);
              }}
            />
            <span>{CONNECTED_CALENDARS_VALUES.BASIC.text} (not recommended)</span>
          </CustomRadio>
          <OnList>
            <li>Include a calendar event file in invite emails for requested {t('tours')}</li>
          </OnList>
          <CheckboxWrapper paddingLeft={54}>
            <Checkbox
              name="includeAllSalesEmailsInCalendarInvite"
              checked={values.includeAllSalesEmailsInCalendarInvite}
              onChange={(e, { checked }) => {
                setFieldValue(`includeAllSalesEmailsInCalendarInvite`, checked);
              }}
              disabled={values.connectedCalendar !== CONNECTED_CALENDARS_VALUES.BASIC.value}
            />
            <span>Include all salespeople when sending these invites</span>
          </CheckboxWrapper>
        </If>
      </div>
      <If condition={values.connectedCalendar === CONNECTED_CALENDARS_VALUES.ENHANCED.value}>
        <ConnectedCalendarsUsers values={values} setFieldValue={setFieldValue} />
      </If>
    </StyledForm>
  );
};

export default ConnectedCalendarsModalBody;
