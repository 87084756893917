import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { NavBar } from 'components/navigation';
import { Content, FullWidthContainer } from 'components/common';
import { Steps } from './Steps';
import { ThankYou } from './ThankYou';
import { OnboardingContextProvider } from './OnboardingContext';

export const Onboarding = () => (
  <OnboardingContextProvider>
    <FullWidthContainer>
      <NavBar showUserInfo />
      <Switch>
        <Route path="/onboarding/steps/:stepNumber">
          <Content noPadding>
            <Steps />
          </Content>
        </Route>
        <Route exact path="/onboarding/thankyou" component={ThankYou} />
        <Redirect to="/onboarding/steps/1" />
      </Switch>
    </FullWidthContainer>
  </OnboardingContextProvider>
);
