import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Button, Dropdown, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { getSalesAssistantLeadStatuses, setSalesAssistantLeadNotation, submitLeadCRM } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import queryConstants from 'utils/queries/constants';
import { MessageContext } from './MessageContext';
import { If } from '../../If';
import { STATUSES } from '../../../constants';
import { PromptButton } from '../../common';
import LeadProcessing from './LeadProcessing';
import { BudgetQualificationTable } from './BudgetQualificationTable';

const StyledSegment = styled(Segment)`
  display: flex;
  justify-content: space-between;

  span {
    line-height: 2;
  }
`;

const CommunitySubmissionInfo = styled.div`
  color: red;
  margin: 6px 0px;
  div {
    padding: 8px 14px;
  }
`;

const LeadInfoName = styled.span`
  font-weight: 600;
  margin-right: 8px;
`;

const LeadInfoValue = styled.span`
  color: ${(props) => props.color};
  word-break: break-word;
`;

const StyledDropdown = styled(Dropdown)`
  div.divider.text,
  .menu .item span {
    white-space: pre-wrap;
    line-height: 20px;
  }
`;

const LeadInfoItem = ({ name, value, color, ...props }) => (
  <div {...props}>
    <LeadInfoName>{name}</LeadInfoName>
    <LeadInfoValue color={color}>{value}</LeadInfoValue>
  </div>
);

export const LeadInformation = () => {
  const { lead, setLead, automatedAnswer } = useContext(MessageContext);
  const [submitStatus, setSubmitStatus] = useState(STATUSES.IDLE);
  const [status, setStatus] = useState(null);

  const { data: statusOptions, isFetching } = useQuery(
    [queryConstants.SALES_ASSISTANT_LEAD_STATUSES],
    () => getSalesAssistantLeadStatuses(),
    {
      placeholderData: [],
      select: (data) =>
        data?.results?.map((leadStatus) => ({
          key: leadStatus.id,
          text: `${leadStatus.is_qualified ? 'Qualified' : 'Non Qualified'}:\n${leadStatus.description}`,
          value: leadStatus.id,
        })) || [],
    }
  );

  useEffect(() => {
    if (lead) {
      setStatus(lead.sales_assistant_lead_status);
    }
  }, [lead]);

  const onStatusSelect = async (e, { value }) => {
    setStatus(value);
    try {
      const data = { status: value };
      await setSalesAssistantLeadNotation(lead.id, data);
      toast.success('Lead status changed.');
    } catch (error) {
      toast.error('Trouble saving lead status!');
      console.error(error.message);
    }
  };

  const onSubmitLead = async () => {
    try {
      setSubmitStatus(STATUSES.LOADING);

      const response = await submitLeadCRM(lead.id);

      setLead({ ...lead, lead_submitted: true });
      toast.success('Lead successfully manually submitted to CRM.');
      setSubmitStatus(STATUSES.LOADED);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Trouble submitting lead manually to CRM. Please try again later.'));
      console.error(e);
      setSubmitStatus(STATUSES.FAILURE);
    }
  };

  return (
    <Segment.Group>
      <StyledSegment>
        <span>Lead Information</span>
        <Button size="mini" className="ui button">
          <Link to={`/conversations/${lead.id}`} target="_blank" rel="noopener noreferrer">
            Open Lead Details
          </Link>
        </Button>
      </StyledSegment>
      <Segment secondary>
        <LeadInfoItem name="Name:" value={lead?.name} />
        <LeadInfoItem name="Email:" value={lead?.email} />
        <LeadInfoItem name="Phone:" value={lead?.phone} />
        <LeadInfoItem
          name="Community Submission:"
          value={lead?.lead_submitted ? 'Submitted' : 'Not Submitted'}
          color={lead?.lead_submitted ? 'green' : 'red'}
        />

        <If condition={lead.active_processing}>
          <LeadProcessing processing={lead.active_processing} />
        </If>

        <If condition={!lead.lead_submitted && !lead.active_processing}>
          <CommunitySubmissionInfo>
            <span>
              This lead, and all associated messages will not be forwarded to the community until it is submitted to
              CRM, either manually or automatically upon responding.
            </span>
            <div>
              <PromptButton
                content="Submit To CRM Manually"
                title="Are you sure you want to manually submit lead to CRM?"
                size="small"
                fluid
                onConfirm={onSubmitLead}
                loading={submitStatus === STATUSES.LOADING}
              />
            </div>
          </CommunitySubmissionInfo>
        </If>
        <LeadInfoItem name="Status:" style={{ marginTop: 12 }} />
        <StyledDropdown
          loading={isFetching}
          placeholder="Select Lead Status"
          clearable
          search
          selection
          selectOnBlur={false}
          options={statusOptions}
          onChange={onStatusSelect}
          value={status}
        />
        <BudgetQualificationTable lead={lead} automatedAnswer={automatedAnswer} />
      </Segment>
    </Segment.Group>
  );
};
