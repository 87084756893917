import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { formatChangeInPercent, formatPercentNumber } from 'components/utils';

const StatsContainer = styled.div`
  display: flex;
  padding: 20px 44px 12px 41px;
  border-radius: 60px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #fff;

  width: 346px;
  height: 120px;
`;

const StatsContainerSide = styled.div`
  display: flex;
  flex-direction: column;
  h3,
  h4,
  h6 {
    margin: 0;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }
  h4 {
    font-family: var(--font-family-bold);
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0.5px;
    color: #222;
  }
  h3 {
    font-family: var(--font-family);
    font-size: 42px;
    font-weight: 500;
    color: #222;
    span {
      font-size: 21px;
    }
  }
  h6 {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    color: #0ab776;
  }
`;

const StatsContainerDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  ::before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: #d7d7d7;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 0;
  }
  .small-circle {
    width: 7px;
    height: 7px;
    border: solid 1px #d7d7d7;
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
  }
  .icon-container {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #d7d7d7;
    background-color: #fff;
    margin: 12px 20px 7px 20px;
  }
  .diff {
    z-index: 1;
    padding: 2px 8px;
    border-radius: 10px;
    border: solid 1px #d7d7d7;
    background-color: #fff;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #222;
  }
`;

export const ExperimentEffects = ({ effects }) => {
  if (!effects) {
    return null;
  }
  return (
    <StatsContainer>
      <StatsContainerSide>
        <h4>ENGAGEMENT</h4>
        <h3>
          {effects.current_engagement === 100 ? 100 : effects.current_engagement.toFixed(1)}
          <span>%</span>
        </h3>
        <h6>{formatChangeInPercent(effects.engagement_change)}</h6>
      </StatsContainerSide>
      <StatsContainerDivider>
        <div className="small-circle"></div>
        <div className="icon-container">
          <Icon name="merge_type" size={24} color="#89919d" />
        </div>
        <div className="diff">23</div>
      </StatsContainerDivider>
      <StatsContainerSide>
        <h4>CONVERSION</h4>
        <h3>
          {effects.current_conversion === 100 ? 100 : effects.current_conversion.toFixed(1)}
          <span>%</span>
        </h3>
        <h6>{formatChangeInPercent(effects.conversion_change)}</h6>
      </StatsContainerSide>
    </StatsContainer>
  );
};
