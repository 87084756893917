import React from 'react';
import styled from 'styled-components';

import { InputField } from 'components/common';
import { Icon, Input } from 'components/lib';
import { If } from 'components/If';

const AreaCodeContainer = styled.div`
  display: flex;
  background-color: ${(props) => `${props.color}`};
  padding: 20px;
  justify-content: space-between;

  > .text-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  > .title {
    font-size: 14px;
    font-weight: 900;
    color: ${(props) => `${props.color || '#e8a947'}`};
  }
  > .subtitle {
    font-size: 12px;
    font-weight: 500;
    color: var(--text);
  }
`;

const AreaCodeField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  > span {
    color: var(--text);
  }

  &&&& input {
    max-width: 105px;
  }
`;

export const AreaCodeDisclaimerField = ({ hasErrors, hasPurchasedNumbers = false }) => {
  const backgroundColor = hasErrors ? '#fff4d4' : '#eaebec';

  return (
    <AreaCodeContainer color={backgroundColor}>
      <If condition={hasErrors}>
        <div className="text-wrapper">
          <Icon name="info" color="#e8a947" />
          <HeaderContainer>
            <div className="title">Couldn't find phone numbers in that location</div>
            <div className="subtitle">Try a different area code (i.e. a neighboring city or town)</div>
          </HeaderContainer>
        </div>
      </If>
      <If condition={!hasErrors}>
        <div className="text-wrapper">
          <Icon name="info" color="##89919d" />
          <HeaderContainer color="#222222">
            {!hasPurchasedNumbers ? (
              <div className="title">Search phone numbers by providing area code</div>
            ) : (
              <div className="title">Existing phone numbers found for the community</div>
            )}

            <div className="subtitle">Changing area code is not allowed.</div>
          </HeaderContainer>
        </div>
      </If>
      <AreaCodeField>
        <span>Area Code</span>
        <InputField control={Input} placeholder="Enter Code" name="areaCode" disabled={hasPurchasedNumbers} />
      </AreaCodeField>
    </AreaCodeContainer>
  );
};
