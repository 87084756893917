import styled from 'styled-components';

export const OptionTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: grab;
  width: 100%;
  padding: 0.2rem 0;

  .ui.button {
    margin: 0;
  }
`;
