import React, { useEffect, useState } from 'react';
import { Message, Popup, Statistic, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { format, parseISO, addDays } from 'date-fns';

import { getChatFlow } from 'apis';
import { capitalize } from 'utils/helpers';
import { STATUSES } from '../../../constants';
import { CsvExport, PageSection } from '../../common';
import { formatCount, formatPercent } from '../../utils';
import ChatFlowCommunities from './ChatFlowCommunites';
import SectionTitle from './SectionTitle';
import { ChatFlowTree } from './ChatFlowTree';

const Wrapper = styled.div`
  .ui.header .content {
    max-width: 25rem;
  }
`;
const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 30rem;
  overflow-y: auto;
`;

const ChatFlow = ({ filters, publicId, chat, latestChatVersion }) => {
  const { t } = useTranslation();
  const [flowData, setFlowData] = useState();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [actionsAfterPricing] = useState([]); // TBD

  const latestUpdateDate = flowData?.latest_change_date && new Date(flowData.latest_change_date);
  const startDate = filters.startDate ? parseISO(filters.start_date) : addDays(new Date(), -30);
  const restricted = latestChatVersion && latestUpdateDate && latestUpdateDate > startDate;

  useEffect(() => {
    let mounted = true;
    const loadChatFlow = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);

        // channels filter is not supported so set it to null at the moment
        const flowData = await getChatFlow({
          public_id: publicId,
          filters: { ...filters, chat_id: chat.id, latest_version: latestChatVersion, channels: null },
        });
        if (mounted) {
          setFlowData(flowData);
          setLoaderStatus(STATUSES.LOADED);
        }
      } catch (e) {
        console.error(e);
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    loadChatFlow();
    return () => {
      mounted = false;
    };
  }, [filters, chat, publicId, latestChatVersion]);

  const prepareCsvContent = (actionsAfterPricing) => {
    const headers = ['Action', '#', 'Percentage'];

    const content = actionsAfterPricing.map((source) => [source.action, source.number, source.percentage]);

    return [headers, content];
  };

  const renderCommunitiesNames = () => (
    <PopupContent>
      {chat.communityNames?.map((name, i) => (
        <span key={i}>{name}</span>
      ))}
    </PopupContent>
  );

  const [csvHeaders, csvContent] = prepareCsvContent(actionsAfterPricing);

  return (
    <Wrapper>
      <PageSection
        as="h2"
        title={chat.name}
        subtitle={
          <Popup
            trigger={<ChatFlowCommunities chat={chat} />}
            content={renderCommunitiesNames}
            position="bottom center"
            size="small"
            hoverable
          />
        }
        actions={
          <>
            {flowData && flowData.chat_engagement && (
              <div>
                <Statistic
                  label="Website Visitors"
                  value={formatCount(flowData.chat_engagement.visitors)}
                  size="mini"
                />
                <Statistic
                  label="Interaction Rate"
                  value={formatPercent(flowData.chat_engagement.interactions, flowData.chat_engagement.visitors)}
                  size="mini"
                />
                <Statistic
                  label={capitalize(t('leads'))}
                  value={formatCount(flowData.chat_engagement.leads)}
                  size="mini"
                />
                <Statistic
                  label="Conversion Rate"
                  value={formatPercent(flowData.chat_engagement.leads, flowData.chat_engagement.visitors)}
                  size="mini"
                />
                <Statistic
                  label={capitalize(t('move_ins'))}
                  value={formatCount(flowData.chat_engagement.move_ins)}
                  size="mini"
                />
              </div>
            )}
          </>
        }
        loaderStatus={loaderStatus}
      >
        {restricted && (
          <Message>
            <Message.Header>Restricted Time Range</Message.Header>
            <p>
              This chat was changed on {format(latestUpdateDate, 'MMMM, dd yyyy HH:mm')} and therefore the data is only
              displayed from that moment onwards. If you want to see the full data, please disable the "Latest Chat
              Version Only" toggle.
            </p>
          </Message>
        )}

        {flowData && <ChatFlowTree chatInteractions={flowData.chat_interactions} />}

        {flowData && flowData.chat_engagement.interactions === 0 && (
          <Message>
            <Message.Header>No Interactions</Message.Header>
            <p>
              This chat did not have any visitor interactions within the selected date range. Try changing the date
              range or {t('community')} filters.
            </p>
          </Message>
        )}

        {actionsAfterPricing.length > 0 && (
          <>
            <SectionTitle>Actions After Pricing</SectionTitle>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                  <Table.HeaderCell>Count</Table.HeaderCell>
                  <Table.HeaderCell>Percentage</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {actionsAfterPricing.map((action, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{action.action}</Table.Cell>
                    <Table.Cell>{action.number}</Table.Cell>
                    <Table.Cell>{action.percentage}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan={7} textAlign="right">
                    <CsvExport data={csvContent} headers={csvHeaders} filename="devices_breakdown.csv" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        )}
      </PageSection>
    </Wrapper>
  );
};

export default ChatFlow;
