import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Header, Button } from 'semantic-ui-react';

import { MEDIA_BREAK_POINTS } from '../../constants';
import { CheckSquare } from '../Icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    height: calc(100vh - 4.42rem);
  }
`;

const Container = styled.div`
  padding: 4rem;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.3rem;
  box-shadow: 0 5px 5px -2px rgb(190 206 234);
  text-align: center;
  color: #333;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    width: 33rem;
    margin: 3rem auto;
  }

  .ui.header {
    margin-bottom: 4rem;
  }
  p {
    margin-bottom: 4rem;
    font-size: 1.1rem;
  }
`;

const IconWrapper = styled.div`
  position: relative;
  top: 0.5rem;
  margin-right: 1rem;
  display: inline-block;
  svg {
    width: 2rem;
    height: 2rem;
    stroke: #979797;

    #check-square-checkline {
      stroke: #00c213;
    }
  }
`;

export const ThankYou = () => (
  <Wrapper>
    <Container>
      <Header as="h2">
        <IconWrapper>
          <CheckSquare />
        </IconWrapper>
        All Done
      </Header>

      <p>
        Your account is ready.
        <br />
        Thank you for using FURTHER!
      </p>
      <Button as={Link} to="/stats" content="Continue" primary fluid size="large" />
    </Container>
  </Wrapper>
);
