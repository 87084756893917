import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { MultiSelectFilter } from 'components/common';
import queryConstants from 'utils/queries/constants';
import { getMetrics } from 'apis';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const metric = 'answers.reviewed';
const groupBy = 'review__reviewer__email';
const limit = 100;

const ReviewersFilter = ({
  selectedValues,
  onChange,
  allItemsSelectedText = 'All Reviewers',
  name = 'Reviewers',
  ...other
}) => {
  const { getExtendedDateRange, automatedAnswerFilters: filters } = useSystemStatsFilters();
  const { start_date: start, end_date: end } = getExtendedDateRange();

  const {
    data = {},
    error,
    status,
    isFetching,
  } = useQuery(
    [queryConstants.METRICS, { metric, ...filters }],
    () => getMetrics([metric], filters, groupBy, limit, 'review__reviewed_on'),
    { placeholderData: {} }
  );

  const senderOptions = useMemo(() => {
    const available = data
      ? new Set(data[metric]?.groups?.map(({ review__reviewer__email }) => review__reviewer__email))
      : new Set();
    if (filters.reviewers) {
      filters.reviewers.split(',').forEach((email) => available.add(email));
    }
    return Array.from(available).map((email) => ({ id: email, name: email }));
  }, [data, isFetching, selectedValues]);

  return (
    <MultiSelectFilter
      disabled={isFetching}
      name={name}
      allItemsSelectedText={allItemsSelectedText}
      options={senderOptions}
      selectedValues={selectedValues}
      onChange={onChange}
      {...other}
    />
  );
};

export { ReviewersFilter };
