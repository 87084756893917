import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';

import { If } from 'components/If';
import { RecordingSettingsModal } from './RecordingSettingsModal';
import { ModuleRecordingsPageHeader } from './ModuleRecordingsPageHeader';
import { ModuleRecordingsTable } from './ModuleRecordingsTable';
import { EditModuleRecordingsModal } from './EditModuleRecordingsModal';
import { useModuleRecordings } from './ModuleRecordingsContext';

export const ModuleRecordings = () => {
  const { communityId } = useParams();

  const [selectedModule, setSelectedModule] = useState(null);
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const { state, updateModuleRecording, setSelectedCommunity, changeCommunitySettingsStatus } = useModuleRecordings();

  const {
    communities,
    selectedCommunity,
    communityModuleRecordings,
    isLoadingCommunities,
    isLoadingModules,
    isCommunitySaving,
    isModuleSaving,
  } = state;

  useEffect(() => {
    const parsedCommunityId = parseInt(communityId);
    if (communities?.length > 0 && parsedCommunityId && selectedCommunity?.id !== parsedCommunityId) {
      const community = communities.find((item) => item.id === parsedCommunityId);
      setSelectedCommunity(community);
    }
  }, [communities, communityId]);

  const onSettingsStatusChange = async () => {
    changeCommunitySettingsStatus({ isEnabled: !selectedCommunity?.chatModuleRecordingSettings?.isEnabled });
    onSettingsModalChange();
  };

  const onSettingsModalChange = () => {
    setSettingsModalOpen(!isSettingsModalOpen);
  };

  const onEditModuleRecording = async ({ audio = null }) => {
    await updateModuleRecording(selectedModule, audio);
    setEditModalOpen(false);
  };

  const onEditRecording =
    (module = null) =>
    () => {
      setSelectedModule(module);
      setEditModalOpen(!isEditModalOpen);
    };

  const onEditModalClose = () => {
    setEditModalOpen(false);
    setSelectedModule(null);
  };

  return (
    <>
      <Dimmer active={!selectedCommunity || isLoadingCommunities} inverted>
        <Loader inverted active>
          Loading
        </Loader>
      </Dimmer>
      <If condition={!isLoadingModules}>
        <ModuleRecordingsPageHeader
          onEnableSettings={onSettingsModalChange}
          onDisableSettings={onSettingsModalChange}
        />

        <ModuleRecordingsTable
          modules={communityModuleRecordings}
          community={selectedCommunity}
          onEditRecording={onEditRecording}
        />

        <RecordingSettingsModal
          isOpen={isSettingsModalOpen}
          loading={isCommunitySaving}
          community={selectedCommunity}
          handleConfirm={onSettingsStatusChange}
          handleCancel={onSettingsModalChange}
        />
        <EditModuleRecordingsModal
          isOpen={isEditModalOpen}
          module={selectedModule}
          loading={isModuleSaving}
          handleConfirm={onEditModuleRecording}
          handleCancel={onEditModalClose}
        />
      </If>
    </>
  );
};
