import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Icon } from 'semantic-ui-react';
import {
  differenceInDays,
  differenceInMonths,
  isEqual,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  lastDayOfMonth,
  parseISO,
  startOfDay,
  startOfToday,
  subDays,
  subMonths,
} from 'date-fns';
import { Formik } from 'formik';
import styled from 'styled-components';

import { If } from 'components/If';
import { formatAnyDate } from 'components/utils';
import { DatePickerField } from 'components/DatePickerField';

import { MEDIA_BREAK_POINTS } from '../../constants';

const DATE_OPTIONS = {
  TODAY: 'today',
  LAST_7_DAYS: 'last-7-days',
  LAST_30_DAYS: 'last-30-days',
  MONTH_TO_DATE: 'month-to-date',
  LAST_MONTH: 'last-month',
  LAST_6_MONTHS: 'last-6-months',
  LAST_12_MONTHS: 'last-12-months',
  CUSTOM: 'custom',
};

const DATE_RANGE_FORMAT = 'yyyy-MM-dd';
const CUSTOM_DATE_FORMAT = 'MMM d, yyyy';

export const DateRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
  }

  .react-datepicker-wrapper {
    width: 124px;
    padding: 0;
  }

  .react-datepicker {
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0 8px 17px -4px rgba(0, 0, 0, 0.2);
  }

  .react-datepicker-popper {
    z-index: 1002;
  }

  .react-datepicker__header--custom {
    padding-top: 0;
  }
  .react-datepicker__header.custom {
    padding-top: 8px;
  }

  .react-datepicker__day--selected {
    background-color: #1f57bd;
  }

  .ui.form input {
    background-color: #f9f9f9;
    color: rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(34, 36, 38, 0.05);
    font-weight: bold;
    font-size: 0.92857143rem;

    &.react-datepicker-ignore-onclickoutside {
      background-color: #f0f0f0;
      color: rgba(0, 0, 0, 0.95);
    }

    &:focus {
      border: 1px solid rgba(34, 36, 38, 0.1);
    }
  }

  .ui.form .fields {
    margin: 0px;

    .field {
      padding: 0px;
      border: solid 1px #ddd;

      &:first-child {
        border-left: none;
        border-right: none;
      }
      background-color: var(--input-field-fill);

      input {
        color: var(--text-color);
        border: none;
      }
    }
  }
`;

export const DateRangeDropdown = styled(Dropdown)`
  display: flex;
  align-self: flex-start;
  margin-right: 0;

  &.ui.selection.dropdown {
    border: 1px solid var(--line-standard);
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
    border-radius: 0px;
    background-color: white;
    font-weight: 500;
    font-size: 14px;
    min-height: 36px;
    max-height: 36px;

    font-family: var(--font-family);
    color: var(--text-color);
  }

  &.ui.selection.dropdown .menu {
    max-height: 400px;
    & > .item.divider {
      padding: 0 !important; //need to add !important to override semantic-ui-css
      margin: 0;
      border-bottom: 1px solid #e0e0e0;
    }
  }
`;

const Actions = styled.div`
  width: 8rem;
  height: 2.53rem;
`;

const ActionButtons = styled.div`
  display: flex;
  .ui.basic.button {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ItemShortcut = styled.span`
  color: #ccc;
  margin-left: 0.5rem;
`;

const NoEndDateButton = styled(Button)`
  &.ui.button {
    margin: 1rem 0;
  }
`;

const DropDowndItem = ({ text, shortcut }) => (
  <ItemContainer>
    <span>{text}</span>
    <If condition={shortcut}>
      <ItemShortcut>{shortcut}</ItemShortcut>
    </If>
  </ItemContainer>
);

const CustomDatePickerHeader = ({
  headerProps: { date, decreaseMonth, increaseMonth, nextMonthButtonDisabled, prevMonthButtonDisabled },
  setDate,
}) => (
  <>
    <button
      className="react-datepicker__navigation react-datepicker__navigation--previous"
      type="button"
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
    >
      Previous Month
    </button>
    <button
      className="react-datepicker__navigation react-datepicker__navigation--next"
      type="button"
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
    >
      Next Month
    </button>
    <div className="react-datepicker__header custom">
      <div className="react-datepicker__current-month">{formatAnyDate(date, 'LLLL yyyy')}</div>
      <NoEndDateButton size="mini" onClick={() => setDate('end', null)} type="button">
        No End Date
      </NoEndDateButton>
    </div>
  </>
);

const dateRangeOptions = [
  { key: 1, text: 'Today', value: DATE_OPTIONS.TODAY, content: <DropDowndItem text="Today" shortcut="D" /> },
  { key: 2, className: 'divider' },
  {
    key: 3,
    text: 'Last 7 days',
    value: DATE_OPTIONS.LAST_7_DAYS,
    content: <DropDowndItem text="Last 7 days" shortcut="W" />,
  },
  {
    key: 4,
    text: 'Last 30 days',
    value: DATE_OPTIONS.LAST_30_DAYS,
    content: <DropDowndItem text="Last 30 days" shortcut="T" />,
  },
  { key: 5, className: 'divider' },
  {
    key: 6,
    text: 'Month to Date',
    value: DATE_OPTIONS.MONTH_TO_DATE,
    content: <DropDowndItem text="Month to Date" shortcut="M" />,
  },
  {
    key: 7,
    text: 'Last month',
    value: DATE_OPTIONS.LAST_MONTH,
    content: <DropDowndItem text="Last month" shortcut="P" />,
  },
  { key: 8, className: 'divider' },
  {
    key: 9,
    text: 'Last 6 months',
    value: DATE_OPTIONS.LAST_6_MONTHS,
    content: <DropDowndItem text="Last 6 months" shortcut="S" />,
  },
  {
    key: 10,
    text: 'Last 12 months',
    value: DATE_OPTIONS.LAST_12_MONTHS,
    content: <DropDowndItem text="Last 12 months" shortcut="Y" />,
  },
  { key: 11, className: 'divider' },
  {
    key: 12,
    text: 'Custom Range',
    value: DATE_OPTIONS.CUSTOM,
    content: <DropDowndItem text="Custom Range" shortcut="C" />,
  },
];

export const DATE_RANGE_TYPE = {
  LEAD_CREATED: 'lead_created',
};

const DATE_RANGE_PREFIXES = {
  [DATE_RANGE_TYPE.LEAD_CREATED]: {
    label: 'Created in the',
    [DATE_OPTIONS.TODAY]: 'Created',
  },
};

export const DateRangePicker = ({ className, dateRange, onFilterChange, disabled, type, resetFilterChange = true }) => {
  const today = startOfToday();
  const [start, setStart] = useState(dateRange.start_date ? parseISO(dateRange.start_date) : subDays(today, 30));
  const [end, setEnd] = useState(dateRange.end_date ? parseISO(dateRange.end_date) : null);
  const [selectedOption, setSelectedOption] = useState(DATE_OPTIONS.LAST_30_DAYS);

  useEffect(() => {
    const getSelectedOption = (dateRange) => {
      const { start_date, end_date } = dateRange;
      const now = startOfToday();
      const startDate = startOfDay(parseISO(start_date));
      const endDate = startOfDay(end_date ? parseISO(end_date) : now);
      const diffInDays = differenceInDays(endDate, startDate);
      const diffInMonths = differenceInMonths(endDate, startDate);

      setStart(startDate);

      const isLastMonth =
        isFirstDayOfMonth(startDate) &&
        isLastDayOfMonth(endDate) &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getMonth() === subMonths(now, 1).getMonth() &&
        endDate.getMonth() === subMonths(now, 1).getMonth();

      const isMonthToDated =
        isFirstDayOfMonth(startDate) &&
        isEqual(endDate, now) &&
        startDate.getMonth() === now.getMonth() &&
        endDate.getMonth() === now.getMonth();

      if (diffInDays === 0 && isEqual(endDate, now)) {
        setEnd(endDate);
        return DATE_OPTIONS.TODAY;
      }
      if (diffInDays === 7 && isEqual(endDate, now)) {
        setEnd(endDate);
        return DATE_OPTIONS.LAST_7_DAYS;
      }
      if (isMonthToDated) {
        setEnd(endDate);
        return DATE_OPTIONS.MONTH_TO_DATE;
      }
      if (isLastMonth) {
        setEnd(endDate);
        return DATE_OPTIONS.LAST_MONTH;
      }
      if (diffInDays === 30 && isEqual(endDate, now)) {
        setEnd(endDate);
        return DATE_OPTIONS.LAST_30_DAYS;
      }
      if (diffInMonths === 6 && isEqual(endDate, now)) {
        setEnd(endDate);
        return DATE_OPTIONS.LAST_6_MONTHS;
      }
      if (diffInMonths === 12 && isEqual(endDate, now)) {
        setEnd(endDate);
        return DATE_OPTIONS.LAST_12_MONTHS;
      }
      return DATE_OPTIONS.CUSTOM;
    };

    setSelectedOption(getSelectedOption(dateRange));
  }, [dateRange]);

  const handleCustomFocus = () => {
    if (selectedOption !== DATE_OPTIONS.CUSTOM) {
      setSelectedOption(DATE_OPTIONS.CUSTOM);
    }
  };

  const handleCustomChange = ({ start, end }) => {
    const newDateRange = {
      startDate: formatAnyDate(start, DATE_RANGE_FORMAT),
      endDate: formatAnyDate(end, DATE_RANGE_FORMAT),
    };
    onFilterChange(newDateRange);
  };

  const handleCustomReset = (reset, dirty) => {
    reset();

    if (dirty) {
      return;
    }

    const startDate = subDays(today, 30);
    setSelectedOption(DATE_OPTIONS.LAST_30_DAYS);
    setStart(startDate);
    setEnd(today);

    if (resetFilterChange) {
      const newDateRange = {
        startDate: formatAnyDate(startDate, DATE_RANGE_FORMAT),
        endDate: formatAnyDate(today, DATE_RANGE_FORMAT),
      };
      onFilterChange(newDateRange);
    }
  };

  const handleKeyPress = (e) => {
    switch (e.key.toLowerCase()) {
      case 'c':
        handlePickerChange(e, { value: DATE_OPTIONS.CUSTOM });
        break;
      case 'd':
        handlePickerChange(e, { value: DATE_OPTIONS.TODAY });
        break;
      case 'w':
        handlePickerChange(e, { value: DATE_OPTIONS.LAST_7_DAYS });
        break;
      case 't':
        handlePickerChange(e, { value: DATE_OPTIONS.LAST_30_DAYS });
        break;
      case 'm':
        handlePickerChange(e, { value: DATE_OPTIONS.MONTH_TO_DATE });
        break;
      case 'p':
        handlePickerChange(e, { value: DATE_OPTIONS.LAST_MONTH });
        break;
      case 's':
        handlePickerChange(e, { value: DATE_OPTIONS.LAST_6_MONTHS });
        break;
      case 'y':
        handlePickerChange(e, { value: DATE_OPTIONS.LAST_12_MONTHS });
        break;
      default:
        break;
    }
    e.target.blur();
  };

  const handlePickerChange = (e, data) => {
    const prevMonth = subMonths(today, 1);
    let start = subDays(today, 30);
    let end = today;

    setSelectedOption(data.value);

    switch (data.value) {
      case DATE_OPTIONS.CUSTOM:
        return;
      case DATE_OPTIONS.TODAY:
        start = today;
        break;
      case DATE_OPTIONS.LAST_7_DAYS:
        start = subDays(today, 7);
        break;
      case DATE_OPTIONS.LAST_30_DAYS:
        start = subDays(today, 30);
        break;
      case DATE_OPTIONS.MONTH_TO_DATE:
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        break;
      case DATE_OPTIONS.LAST_MONTH:
        start = new Date(prevMonth.getFullYear(), prevMonth.getMonth(), 1);
        end = lastDayOfMonth(prevMonth);
        break;
      case DATE_OPTIONS.LAST_6_MONTHS:
        start = subMonths(today, 6);
        break;
      case DATE_OPTIONS.LAST_12_MONTHS:
        start = subMonths(today, 12);
        break;
      default:
        start = subDays(today, 30);
        break;
    }
    const newDateRange = {
      startDate: formatAnyDate(start, DATE_RANGE_FORMAT),
      endDate: formatAnyDate(end, DATE_RANGE_FORMAT),
    };

    onFilterChange(newDateRange);
  };

  const isCustom = selectedOption === DATE_OPTIONS.CUSTOM;
  const selectedOptionItem = dateRangeOptions.find((item) => item.value === selectedOption);
  const dropdownTypePrefix =
    selectedOptionItem &&
    type in DATE_RANGE_PREFIXES &&
    (DATE_RANGE_PREFIXES[type][selectedOptionItem.value] || DATE_RANGE_PREFIXES[type].label);

  const dropdownText = dropdownTypePrefix ? `${dropdownTypePrefix} ${selectedOptionItem.text.toLowerCase()}` : '';

  return (
    <DateRangeContainer className={className}>
      <DateRangeDropdown
        disabled={disabled}
        className={className}
        onKeyPress={handleKeyPress}
        options={dateRangeOptions}
        selection
        value={selectedOption}
        onChange={handlePickerChange}
        text={dropdownText}
      />
      <Formik initialValues={{ start, end }} onSubmit={handleCustomChange} enableReinitialize={!isCustom}>
        {({ handleSubmit, dirty, resetForm, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Field>
                <DatePickerField
                  dateFormat={CUSTOM_DATE_FORMAT}
                  maxDate={end}
                  onFocus={handleCustomFocus}
                  name="start"
                  selected={start}
                  onChange={(date) => setStart(date)}
                  disabled={disabled}
                />
              </Form.Field>
              <Form.Field>
                <DatePickerField
                  dateFormat={CUSTOM_DATE_FORMAT}
                  maxDate={today}
                  minDate={start}
                  onFocus={handleCustomFocus}
                  name="end"
                  selected={end}
                  onChange={(date) => setEnd(date)}
                  renderCustomHeader={(props) => <CustomDatePickerHeader headerProps={props} setDate={setFieldValue} />}
                  disabled={disabled}
                />
              </Form.Field>
              <Actions>
                <If condition={isCustom}>
                  <ActionButtons>
                    <Button icon basic disabled={!dirty} type="submit">
                      <Icon name="check" color="green" />
                    </Button>
                    <Button icon basic type="reset" onClick={() => handleCustomReset(resetForm, dirty)}>
                      <Icon name="close" />
                    </Button>
                  </ActionButtons>
                </If>
              </Actions>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </DateRangeContainer>
  );
};
