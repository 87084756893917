import React from 'react';
import { Form, Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { confirmMoveInData } from '../../../api';
import { CheckboxField } from '../../common/fields/CheckboxField';

const ERROR_COLUMN = 'Errors';
const SELECTION_COLUMN = 'Selection';

export const MoveInUploadPreview = ({ formRef, data, onLoading, onUpdated }) => {
  const { t } = useTranslation();
  const { filename, records } = data;
  const columns = Object.keys(records[0]);

  const onSubmit = async (values) => {
    try {
      onLoading(true);
      const filter = values.selection.filter((item) => !!item);
      const response = await confirmMoveInData({ filename, filter });
      toast.success(`Successfully updated ${response?.updated} leads`);
      onUpdated(response?.updated);
    } catch (error) {
      const msg = `Trouble updating leads ${t('move_in')} data!`;
      console.error(msg, error?.response);
      toast.error(msg);
    }

    onLoading(false);
  };

  const initialValues = {
    selection: records?.map((record) => record[SELECTION_COLUMN]),
  };

  const validationSchema = Yup.object().shape({
    selection: Yup.array()
      .compact((v) => !v)
      .required(),
  });

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Table singleLine textAlign="center">
            <Table.Header>
              <Table.Row>
                {columns.map((name) => (
                  <Table.HeaderCell key={name}>{name}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {records.map((row, i) => (
                <Table.Row key={i} negative={!!row[ERROR_COLUMN]}>
                  {columns.map((columnName, j) => (
                    <Table.Cell key={j}>
                      {columnName !== SELECTION_COLUMN ? (
                        row[columnName]
                      ) : (
                        <CheckboxField name={`selection[${i}]`} disabled={!!row[ERROR_COLUMN]} />
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Form>
      )}
    </Formik>
  );
};
