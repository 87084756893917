import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/lib';
import AddConditionButton from './AddConditionButton';
import { CONNECTOR } from './constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

const Title = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
`;

const Subtitle = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #89919d;
  margin-bottom: 20px;
`;

const NoConditions = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Icon name="account_box" size="24" color="var(--line-standard)" />
      <Title>There are no conditions to mark unqualified {t('leads')}.</Title>
      <Subtitle>Start by adding a condition below.</Subtitle>
      <AddConditionButton type={CONNECTOR.AND} text="Add condition" onClick={onClick} />
    </Container>
  );
};

export default NoConditions;
