import React from 'react';
import { Route, Switch } from 'react-router';

import { WithTopBar } from 'components/WithTopBar';
import { Content } from 'components/common';
import { PermissionRoute } from 'components/common/routes/PermissionRoute';
import permissions from 'utils/permissions';
import ManagementSession from './ManagementSession';
import ManagementUsers from './ManagementUsers';

const Management = () => {
  return (
    <WithTopBar>
      <Content>
        <Switch>
          <Route exact path="/management/session">
            <ManagementSession />
          </Route>
          <PermissionRoute permission={permissions.MANAGEMENT_USER.FULL} exact path="/management">
            <ManagementUsers />
          </PermissionRoute>
        </Switch>
      </Content>
    </WithTopBar>
  );
};

export default Management;
