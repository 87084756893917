import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { useUserContext } from 'components/UserContext';
import { Icon } from 'components/lib';
import { logOut } from '../../../api';

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: var(--font-family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  .logout {
    margin-right: 4px;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-decoration: none;
  padding-top: 62px;
  padding-bottom: 17px;
  &:hover {
    color: #fff;
  }
`;

export const Header = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { setUser } = useUserContext();

  const goBack = () => {
    history.goBack();
  };

  const handleLogout = async () => {
    try {
      await logOut();
    } catch (e) {
      console.error(e);
    } finally {
      queryClient.clear();
      setUser(null);
      history.push('/login');
    }
  };
  return (
    <HeaderContainer>
      <StyledLink to="#" onClick={goBack}>
        <Icon name="chevron_left" />
        Back to Dashboard
      </StyledLink>
      <StyledLink to="#" onClick={handleLogout}>
        <Icon name="logout" className="logout" />
        Log out
      </StyledLink>
    </HeaderContainer>
  );
};
