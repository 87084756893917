import React, { useContext } from 'react';

import { Dropdown } from 'components/lib';
import { InputField } from '../common/fields/InputField';
import { scrollIntoView } from '../utils';
import { ConversationContext } from './ConversationContext';
import { Actions, ActionTitle } from './ConversationMessageComposer';
import { useCheckSmallMobileScreen } from '../../utils/hooks';
import { If } from '../If';
import { CONVERSATION_MESSAGE_TYPE } from './constants';

export const ConversationMessageComposerSendOptions = () => {
  const isSmallMobile = useCheckSmallMobileScreen();
  const { selectedConversation } = useContext(ConversationContext);

  const SEND_FROM_OPTIONS = [
    {
      key: CONVERSATION_MESSAGE_TYPE.SMS,
      value: CONVERSATION_MESSAGE_TYPE.SMS,
      text: 'SMS',
    },
    {
      key: CONVERSATION_MESSAGE_TYPE.EMAIL,
      value: CONVERSATION_MESSAGE_TYPE.EMAIL,
      text: selectedConversation?.has_email_integration ? 'Email' : 'No email integration',
      disabled: !selectedConversation?.has_email_integration,
    },
  ];

  return (
    <Actions>
      <If condition={!isSmallMobile}>
        <ActionTitle>Send from</ActionTitle>
      </If>
      <InputField
        selection
        basic
        floating
        onError={scrollIntoView}
        control={Dropdown}
        name="from"
        options={SEND_FROM_OPTIONS}
      />
    </Actions>
  );
};
