import React from 'react';
import styled from 'styled-components';

import { TourDateTimeCard } from './TourDateTimeCard';

const Header = styled.div`
  display: flex;
  max-width: 600px;
  width: 100%;
  justify-content: center;
  border-radius: 4px;
  background-color: #f3f6fc;
  padding: 30px 0px;
`;

export const TourDateTimeContainer = (props) => (
  <Header>
    <TourDateTimeCard {...props} />
  </Header>
);
