import React from 'react';
import styled from 'styled-components';
import { Dimmer, Loader, Message, Pagination, Table, Transition } from 'semantic-ui-react';

import StickyHeader from 'components/StickyHeader';
import QuestionReviewListRow from './QuestionReviewListRow';
import { STATUSES } from '../../../constants';
import { useQuestionReviews, COLUMN_SORT_NAMES } from './context';

const TableWrapper = styled.div`
  && {
    .ui.sortable.table thead th {
      border-left: none;

      &.sorted {
        background: #f9fafb;
        color: inherit;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
`;

const QuestionReviewList = ({
  questions,
  loaderStatus,
  activePage,
  totalPages,
  ordering,
  onPageChange,
  onQuestionUpdate,
  onOrdering,
}) => {
  const [{ attributes, currentQuestionId, updateLoaderStatus }] = useQuestionReviews();
  const { orderBy, orderAs } = ordering;

  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  const handleAttributesChange = async (questionReviewId, attributes) => {
    await onQuestionUpdate(questionReviewId, attributes, false);
  };

  const handleIgnoreQuestion = async (questionReviewId) => {
    await onQuestionUpdate(questionReviewId, null, true);
  };

  const handleOrdering = (column) => () => {
    onOrdering(column);
  };

  if (!questions) {
    return null;
  }

  if (questions.length === 0) {
    return <Message icon="search" content="No questions found." />;
  }

  return (
    <TableWrapper>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {questions?.length > 0 && (
        <Table sortable striped>
          <Table.Header>
            <Table.Row>
              <StickyHeader>Question</StickyHeader>
              <StickyHeader
                sorted={orderBy === COLUMN_SORT_NAMES.UPDATED_ON ? orderAs : null}
                onClick={handleOrdering(COLUMN_SORT_NAMES.UPDATED_ON)}
              >
                Updated On
              </StickyHeader>
              <StickyHeader>Attribute</StickyHeader>
              <StickyHeader />
            </Table.Row>
          </Table.Header>
          <Transition.Group as={Table.Body}>
            {questions.map((q) => (
              <QuestionReviewListRow
                key={q.id}
                loaderStatus={updateLoaderStatus}
                currentQuestionId={currentQuestionId}
                questionReview={q}
                attributes={attributes}
                onAttributesChange={handleAttributesChange}
                onIgnoreQuestion={handleIgnoreQuestion}
              />
            ))}
          </Transition.Group>
          {totalPages > 1 && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  <Pagination activePage={activePage} onPageChange={handlePageChange} totalPages={totalPages} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      )}
    </TableWrapper>
  );
};

export default QuestionReviewList;
