import constants, { TABS } from './constants';

const reducer = (draft, action) => {
  switch (action.type) {
    case constants.SET_LOADER_STATUS:
      draft.loaderStatus = action.payload;
      break;
    case constants.SET_ACTIVE_TAB:
      draft.activeTab = action.activeTab;
      break;
    case constants.SET_USER_ID:
      draft.userId = action.payload;
      draft.activeTab = TABS.CRM_SETTINGS;
      break;
    case constants.SET_CRM_GLOBAL_SETTINGS:
      draft.crmGlobalSettings = action.payload;
      break;
    case constants.SET_CRM_SETTINGS:
      draft.crmSettings = action.payload;
      draft.defaultCRMSettings = action.payload.find((cs) => cs.is_default);
      draft.crmSettingsOptions = action.payload.map((cs) => ({
        key: cs.id,
        value: cs.id,
        text: `${cs.name} (${cs.integration_type_name})`,
      }));
      break;
    case constants.SET_COMMUNITIES:
      draft.communities = action.payload.map((p) => {
        if (!p.crm_settings_id && draft.defaultCRMSettings) {
          p.inherits_crm_settings = true;
          p.crm_settings_id = draft.defaultCRMSettings.id;
          p.crm_settings_name = draft.defaultCRMSettings.name;
          p.crm_settings_integration_name = draft.defaultCRMSettings.integration_type_name;
        }
        if (draft.crmCommunityInvalidSettings && draft.crmCommunityInvalidSettings.some((i) => i.id === p.id)) {
          p.has_errors = true;
        }

        return p;
      });
      break;
    case constants.UPDATE_COMMUNITY: {
      const { id, crm_settings_id } = action.payload;

      draft.communities = draft.communities.map((c) => {
        if (c.id === id) {
          return {
            ...c,
            ...action.payload,
            inherits_crm_settings: !crm_settings_id,
          };
        }

        return c;
      });
      break;
    }
    case constants.REMOVE_COMMUNITY_CRM_SETTINGS: {
      const { id } = action.payload;

      draft.communities = draft.communities.map((c) => {
        if (c.id === id) {
          if (draft.defaultCRMSettings) {
            c.crm_settings_id = draft.defaultCRMSettings.id;
            c.crm_settings_name = draft.defaultCRMSettings.name;
            c.crm_settings_integration_name = draft.defaultCRMSettings.integration_type_name;
            c.crm_location_code_overrides = [];
            c.inherits_crm_settings = true;
          } else {
            c.crm_settings_id = null;
            c.crm_settings_name = null;
            c.crm_settings_integration_name = null;
            c.crm_location_code_overrides = [];
            c.inherits_crm_settings = false;
          }
        }

        return c;
      });
      break;
    }
    case constants.SET_CRM_LOCATION_CODE_RULES:
      draft.crmLocationCodeRules = action.payload;
      draft.crmLocationCodeRuleOptions = action.payload.map((c) => ({ key: c.id, value: c.id, text: c.name }));
      break;
    case constants.SET_CRM_COMMUNITY_INVALID_SETTINGS:
      draft.crmCommunityInvalidSettings = action.payload;

      if (draft.communities && action.payload) {
        draft.communities = draft.communities.map((c) => {
          if (action.payload.some((a) => a.id === c.id)) {
            return {
              ...c,
              has_errors: true,
            };
          }

          return {
            ...c,
            has_errors: false,
          };
        });
      }
      break;
    case constants.SET_CRM_SOURCE_MAPPING_TRAFFIC_SOURCES:
      draft.eligibleTrafficSources = action.payload;
      break;
    case constants.SET_CRM_SOURCE_MAPPING_LEAD_SOURCES:
      draft.eligibleLeadSources = action.payload;
      break;
    case constants.SET_CRM_SOURCE_MAPPING_URL_PARAMETERS:
      draft.eligibleUrlParameters = action.payload;
      break;
    default:
      console.warn(`Unhandled action ${JSON.stringify(action)}`);
      break;
  }

  return draft;
};

export default reducer;
