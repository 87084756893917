import React, { useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

const Container = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 500px;
  height: 78px;
  padding: 14px 26px 14px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #fff;
  & + & {
    margin-top: 10px;
  }
  input {
    display: none;
  }
  label {
    cursor: pointer;
  }
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border: solid 1px #777;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  transition: all 0.2s ease;
  &:after {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
  }
  &.selected {
    background-color: #0f71fd;
    border: solid 1px #0f71fd;
    transition: all 0.2s ease;
  }
`;

const Text = styled.div`
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  && > label {
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: 1.43;
    color: #222;
  }
  && > p {
    margin: 0;
    font-family: var(--font-family);
    font-size: 12px;
    color: #89919d;
    text-align: left;
  }
`;

const IconContainer = styled.div`
  flex: 1 0 auto;
  width: fit-content;
  display: flex;
`;

/*
 * Semantic UI's Form.Radio isn't implemented using `<input type="radio" />`
 * Because of that, it doesn't work with Formik out-of-the-box.
 * */
export const RadioWithMarkup = (props) => {
  const { icon = null, label = null, description = null } = props;
  const [field, meta, helpers] = useField(props);
  const [id] = useState(uuidv4());
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(meta.value === props.value);
  }, [meta.value, props.value]);

  const selectedClassName = classNames({ selected });

  return (
    <Container htmlFor={id}>
      <Circle className={selectedClassName}></Circle>
      <Field type="radio" id={id} {...props} />
      <Text>
        <label htmlFor={id}>{label}</label>
        <p>{description}</p>
      </Text>
      {icon && <IconContainer>{icon}</IconContainer>}
    </Container>
  );
};
