import React, { useContext } from 'react';
import { Divider, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { ChatsContext } from '../ChatsContext';

const Wrapper = styled.div`
  pre {
    white-space: pre-wrap;
  }
`;

export const AnalyticsChatIntegrations = ({ integrations }) => {
  const { analytics } = useContext(ChatsContext);

  return (
    <Wrapper>
      <Divider horizontal section content="Current Analytics Integrations" />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Type</Table.HeaderCell>
            <Table.HeaderCell>Code</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {integrations?.map((integration) => (
            <Table.Row key={integration.id}>
              <Table.Cell>
                <b>{analytics?.types[integration.type] || integration.type}</b>
              </Table.Cell>
              <Table.Cell>
                <pre>{integration.code}</pre>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Wrapper>
  );
};
