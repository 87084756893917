import React from 'react';
import styled from 'styled-components';

import { Icon, List, Popup } from 'semantic-ui-react';

const MetricIndicator = styled.span`
  cursor: pointer;
  color: ${(props) =>
    props.positive === null || props.positive === undefined ? 'inherit' : props.positive ? 'green' : 'red'};
`;

const MetricDivider = styled.span`
  margin: 0 5px;
`;

const StyledList = styled(List)`
  max-height: 400px;
  overflow-y: auto;
`;

const ProfilesMetricIndicator = ({ name, completedProfiles, incompleteProfiles }) => {
  const renderProfilesMetric = (name, profiles, isPositive = true) => {
    if (!profiles || profiles.length === 0) {
      return <MetricIndicator>0</MetricIndicator>;
    }

    const indicator = <MetricIndicator positive={isPositive}>{profiles.length}</MetricIndicator>;
    const listItemIcon = <Icon name={isPositive ? 'check' : 'close'} color={isPositive ? 'green' : 'red'} />;

    return (
      <Popup key={name} trigger={indicator} hoverable wide>
        <StyledList>
          {profiles.map((p) => (
            <List.Item key={p} icon={listItemIcon} content={p} />
          ))}
        </StyledList>
      </Popup>
    );
  };

  return (
    <>
      {renderProfilesMetric(name, completedProfiles)}
      <MetricDivider>|</MetricDivider>
      {renderProfilesMetric(`${name}-incomplete`, incompleteProfiles, false)}
    </>
  );
};

export default ProfilesMetricIndicator;
