import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  cursor: pointer;
  background-color: #79aec8;
  color: #fff;
  margin-right: 5px;
  border-style: none;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  user-select: none;
  outline-style: none;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const Button = ({ children, ...props }) => <StyledButton {...props}>{children}</StyledButton>;

export default Button;
