import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Message, Dimmer, Loader } from 'semantic-ui-react';

import { getCampaignStatisticsOverview } from 'apis';
import { If } from 'components/If';
import { useUrlSearchParams } from 'utils/hooks';
import { STATUSES } from '../../../constants';
import { useNurtureFilter } from '../NurtureFilterContext';
import { Metrics } from './Metrics';
import { CampaignStatisticsTable } from './CampaignStatisticsTable';

export const CampaignStatistics = () => {
  const { setUrlSearchParams } = useUrlSearchParams();
  const { state, buildQueryParams } = useNurtureFilter();
  const { filters } = state;
  const [statistics, setStatistics] = useState();
  const [loading, setLoading] = useState(STATUSES.IDLE);

  setUrlSearchParams(filters);

  useEffect(() => {
    const fetchCampaignStatistics = async () => {
      try {
        setLoading(STATUSES.LOADING);
        const params = buildQueryParams(filters);
        const response = await getCampaignStatisticsOverview(params);
        setStatistics(response);
        setLoading(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error('Error fetching campaign statistics');
        setLoading(STATUSES.FAILURE);
      }
    };

    fetchCampaignStatistics();
  }, [filters]);

  return (
    <div>
      <Dimmer active={loading === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <If condition={loading === STATUSES.LOADED}>
        <Metrics metrics={statistics?.metrics} />
        <CampaignStatisticsTable
          campaignStatistics={statistics?.results}
          exportAsCSV={() => getCampaignStatisticsOverview(buildQueryParams({ ...filters, export: true }))}
        />
      </If>

      <If condition={loading === STATUSES.FAILURE}>
        <Message error content="Unable to fetch campaigns statistics" />
      </If>
    </div>
  );
};
