import React from 'react';
import styled from 'styled-components';
import { Dimmer, Loader, Message } from 'semantic-ui-react';

import { STATUSES } from '../../../constants';
import { If } from '../../If';
import { ChatForm } from './ChatForm';
import { useChatEditor } from '../ChatEditorContext';

const Wrapper = styled.div`
  position: relative;
  min-height: 35rem;
`;

export const ChatSettings = () => {
  const [state, , , loaderStatus] = useChatEditor();
  const { chat } = state;

  return (
    <>
      <Wrapper>
        <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <If condition={loaderStatus === STATUSES.LOADED}>
          <ChatForm chat={chat} />
        </If>
        <If condition={loaderStatus === STATUSES.FAILURE}>
          <Message negative>
            <Message.Header>We're sorry we can't fetch your chat settings</Message.Header>
            <p>Something went wrong and we couldn't load your chat settings. Please try again later.</p>
          </Message>
        </If>
      </Wrapper>
    </>
  );
};
