import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { Button, Icon } from 'components/lib';
import { OnboardingContext } from '../OnboardingContext';
import { NextStep } from './NextStep';

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.4286rem;
  margin: 4.2857rem 0;
  max-width: ${(p) => (p.count <= 6 ? '75rem' : '100%')};
`;

const Option = styled.div`
  position: relative;
  flex: 1 0 250px;
  height: 72px;
  padding: 15px 30px 15px 20px;
  border-radius: 36px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    margin: 0 0 0 10px;
    font-size: 14px;
    text-align: left;
    padding-top: 3px;
    white-space: pre-line;
  }
  &.active,
  &.alwaysActive {
    box-shadow: 0 5px 10px 0 rgba(21, 158, 97, 0.17);
    border: solid 1px #2cda99;
    background-color: #eafffe;
    p {
      color: #0ab776;
    }
  }
  &.alwaysActive {
    pointer-events: none;
  }
`;

const AlwaysActiveConatiner = styled.div`
  position: absolute;
  top: -12px;
  right: calc(50% - 125px / 2);
  width: 125px;
  height: 24px;
  padding: 2px 10px 1px 8px;
  border-radius: 36px;
  background-color: #2cda99;
  font-family: var(--font-family);
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  && > p {
    font-size: 14px;
    color: #fff;
    margin: 0;
  }
`;

export const CustomerIntentForm = () => {
  const { values, setValues, handleSubmit, isValid } = useFormikContext();
  const { onboarding } = useContext(OnboardingContext);

  // AI-generated icons are usually wrong, so we hide them for now
  const showIcons = onboarding?.scrapingStatus !== 'completed';
  const disabled = !isValid || values.intents.length < 1;

  const handleOptionSelect = (option) => {
    if (values.intents.map((intent) => intent.tag).includes(option.tag)) {
      setValues({
        intents: values.intents.filter((intent) => intent.tag !== option.tag),
      });
    } else if (values.intents.length < 5) {
      setValues({ intents: [...values.intents, option] });
    }
  };

  return (
    <>
      <OptionsContainer count={onboarding?.intents?.length || 1}>
        {onboarding?.intents?.map((option, i) => {
          // we should keep this if we ever want to add always active options back
          // const alwaysActive = option.mainGoals.includes(onboarding?.collectedData.mainGoal);
          const alwaysActive = false;
          const active = values.intents.map((i) => i.tag).includes(option.tag);
          const className = classNames({ active, alwaysActive });
          return (
            <Option key={i} onClick={() => handleOptionSelect(option)} className={className}>
              {alwaysActive && (
                <AlwaysActiveConatiner>
                  <Icon name="lock" color="#ffffff" size="14" variant="outlined" />
                  <p>Always Active</p>
                </AlwaysActiveConatiner>
              )}
              {showIcons && (
                <Icon name={option.icon} variant="outlined" color={active || alwaysActive ? '#0ab776' : '#222222'} />
              )}
              <p>{option.text}</p>
            </Option>
          );
        })}
      </OptionsContainer>
      <Button size="big" fluid primary color="blue" type="submit" onClick={handleSubmit} disabled={disabled}>
        Next Step
      </Button>
      <NextStep disabled={disabled} />
    </>
  );
};
