import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';

const PreviewSection = styled.div`
  background: #e8e8e8;
  padding: 20px;
`;

const PreviewHeader = styled.div`
  font-weight: 600;
`;

const PreviewContent = styled.div`
  font-size: 11px;
`;

const WarningMessage = styled.div`
  color: orange;
  font-size: 11px;
`;

const AllowedPlaceholdersList = styled.span`
  color: black;
  font-weight: 800;
`;

const SubjectPreviewSection = ({ value, allowedPlaceholders }) => {
  const hasInvalidPlaceholderFormats = (sentence) => {
    let occurrencesCount = 0;
    const openingTag = '{';
    const closingTag = '}';

    if (!sentence) return false;

    for (const character of sentence) {
      if (character === openingTag) {
        occurrencesCount += 1;
      } else if (character === closingTag) {
        occurrencesCount -= 1;
      }

      if (occurrencesCount < 0 || occurrencesCount > 1) return true;
    }

    return occurrencesCount !== 0;
  };

  const hasInvalidPlaceholders = (sentence, allowedPlaceholders) => {
    const matches = sentence.matchAll(/{[^{}]+}>*/g); // Returns groups of placeholders in format -> {matched_value}.

    if (!sentence || allowedPlaceholders.length === 0) return false;

    for (const match of matches) {
      const foundPlaceholder = match[0].substr(1, match[0].length - 2);
      const matchedPlaceholder = allowedPlaceholders.find((item) => item.key === foundPlaceholder);
      if (!matchedPlaceholder) return true;
    }

    return false;
  };

  const generatePreviewedSubject = (sentence) => {
    allowedPlaceholders.forEach((placeholder) => {
      const regexPattern = new RegExp(`{${placeholder.key}}`, 'g');
      sentence = sentence.replace(regexPattern, placeholder.preview_value);
    });
    return sentence;
  };

  return (
    <>
      <PreviewSection>
        <PreviewHeader>Preview</PreviewHeader>
        <PreviewContent>{generatePreviewedSubject(value)}</PreviewContent>
      </PreviewSection>
      <If condition={hasInvalidPlaceholderFormats(value)}>
        <WarningMessage>Invalid placeholder tags. </WarningMessage>
      </If>
      <If condition={hasInvalidPlaceholders(value, allowedPlaceholders)}>
        <WarningMessage>
          Unsupported placeholder values. Allowed placeholders:{' '}
          <AllowedPlaceholdersList>{allowedPlaceholders.map((pl) => pl.key).join(', ')}</AllowedPlaceholdersList>.
        </WarningMessage>
      </If>
    </>
  );
};

export default SubjectPreviewSection;
