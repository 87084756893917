import React from 'react';

import { Table } from 'components/lib';

export const ModuleRecordingsTableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>#</Table.HeaderCell>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>Audio</Table.HeaderCell>
        <Table.HeaderCell>Module Text</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};
