import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { AiIcon, ProgressBar } from 'components/lib';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  p {
    margin: 0 7px 0 0;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 12px;
    color: var(--text);
    position: relative;
  }
`;

const Likelihood = styled.h3`
  margin: 0 7px;
  font-family: var(--font-family-bold);
  font-size: 18px;
  color: var(--text);
`;

export const MoveinLikelihoodIndicator = ({ myref }) => {
  const { t } = useTranslation();

  return (
    <Container ref={myref}>
      <Content>
        <AiIcon />
        <Likelihood>High</Likelihood>
        <p>chance of {t('move_in')}!</p>
      </Content>
      <ProgressBar progress={100} width="200px" color="linear-gradient(to right, #ff5b98 35%, #5b80ff 99%)" />
    </Container>
  );
};
