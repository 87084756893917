import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Icon, Button, Loader } from 'components/lib';
import { ActionButton } from './ActionButton';
import { NoteBox } from './NoteBox';
import CallStatusLabel from './CallStatusLabel';
import { CALL_STATUS } from './constants';

const PhoneCallContainer = styled.div`
  position: fixed;
  top: 20px;
  left: calc(50% - 200px);
  width: 400px;
  color: #fff;
  border-radius: 18px;
  background-image: linear-gradient(308deg, #333059, #444077 40%);
  z-index: 99999;
`;

const HangupButton = styled(Button)`
  && {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    span {
      rotate: 135deg;
    }
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PhoneBox = styled.div`
  padding: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  .edit-note {
    margin-left: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  display: flex;
  flex-direction: column;
`;

const Subtitle = styled.span`
  font-weight: 500;
  color: var(--60-white);
`;

export const MinimizedCall = ({
  loading,
  phoneCall,
  muted,
  status,
  onAcceptCall,
  onHangupCall,
  onMute,
  onSaveNote,
  onClose,
  onEditMode,
}) => {
  const [isNoteOpened, setIsNoteOpened] = useState(false);

  useEffect(() => {
    onEditMode(isNoteOpened);
  }, [isNoteOpened]);

  const toggleNote = () => {
    setIsNoteOpened(!isNoteOpened);
  };

  return (
    <PhoneCallContainer>
      <PhoneBox>
        <Row>
          <Title>
            {phoneCall.leadPhoneNumber} {phoneCall.leadName && <>&#8226; {phoneCall.leadName}</>}
            <Subtitle>
              <CallStatusLabel communityName={phoneCall.communityName} status={status} />
            </Subtitle>
          </Title>
          <Loader size="tiny" active={loading} transparent />
          <Icon
            name="close"
            size="18"
            color="#89919d"
            onClick={onClose}
            disabled={status.value !== CALL_STATUS.CLOSED}
          />
        </Row>
        <ControlsWrapper>
          <ButtonsWrapper>
            <ActionButton iconName={muted ? 'mice_none' : 'mice_off'} active={!muted} onClick={onMute} />
            {phoneCall.id && <ActionButton iconName="edit_note" className="edit-note" onClick={toggleNote} />}
          </ButtonsWrapper>
          <div>
            {status.value === CALL_STATUS.PENDING && (
              <HangupButton color="green">
                <Icon name="call" className="button" color="#fff" size={24} onClick={onAcceptCall} />
              </HangupButton>
            )}
            {status.value !== CALL_STATUS.CLOSED && (
              <HangupButton color="red" disabled={status.value === CALL_STATUS.CLOSED}>
                <Icon name="phone" className="button" color="#fff" size={24} onClick={onHangupCall} />
              </HangupButton>
            )}
          </div>
        </ControlsWrapper>
      </PhoneBox>
      <If condition={isNoteOpened}>
        <NoteBox onSave={onSaveNote} onCancel={toggleNote} />
      </If>
    </PhoneCallContainer>
  );
};
