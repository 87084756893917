import React, { useContext, useEffect } from 'react';
import { Label, Message, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { MessageContext } from './MessageContext';
import { LabelItem } from './Labelitem';
import { STATUSES } from '../../../constants';

const StyledAnswer = styled.div`
  margin-bottom: 5px;

  .other-answers {
    margin: 2px 5px;
    color: #888;
  }

  .missed-answers {
    margin-top: 10px;
    .answer {
      margin: 2px 5px;
      font-style: italic;
    }
  }
`;

const IntentAnswer = ({ intent, score, answers, options }) => {
  if (!answers?.length) {
    return (
      <Message warning key={`missing_${intent}`}>
        Intent <strong>{intent}</strong> is missing an answer.
      </Message>
    );
  }

  return (
    <StyledAnswer>
      <LabelItem label={intent} options={options} />
      <Label pointing="left" size="mini" color="green" basic content={score} />
    </StyledAnswer>
  );
};

export const AutomatedAnswer = ({ groupedLabels }) => {
  const { automatedAnswer, automatedAnswerStatus, contentTouched, setLabels } = useContext(MessageContext);

  useEffect(() => {
    if (!contentTouched && automatedAnswer?.intents?.length) {
      const labels = automatedAnswer.intents
        .filter((a) => a.answers?.length)
        .map((a) => ({
          id: a.answers[0].answer_template?.id,
          label: a.intent,
          answer: a.answers[0].answer,
        }));
      setLabels(labels);
    }
  }, [contentTouched, automatedAnswer, setLabels]);

  if (!groupedLabels || automatedAnswerStatus === STATUSES.LOADING) {
    return <Loader active inline size="mini" />;
  }
  if (automatedAnswerStatus === STATUSES.FAILURE) {
    return <Message error size="mini" content="Failed to load automated answer" />;
  }
  if (automatedAnswerStatus === STATUSES.LOADED && !automatedAnswer?.intents?.length) {
    return <Message warning size="mini" content="No automated answer" />;
  }
  return (
    <Label.Group>
      {automatedAnswer?.intents?.map((answer) => (
        <IntentAnswer key={answer.intent} {...answer} options={groupedLabels[answer.intent]} />
      ))}
    </Label.Group>
  );
};
