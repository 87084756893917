import { Table } from 'semantic-ui-react';
import React from 'react';

import { InputField } from '../../common/fields/InputField';
import { scrollIntoView } from '../../utils';
import { StickyTableHeader } from '../../common/PricingTable';
import { STICKY_OFFSETS } from '../../../constants';

export const DiscountsTable = ({ values }) => {
  const columnNames = ['Location', 'Care Type', 'Discounted Price', 'Annual Text'];

  return (
    <Table fixed>
      <StickyTableHeader offset={STICKY_OFFSETS.SMALL_OFFSET}>
        <Table.Row>
          {columnNames.map((c, i) => (
            <Table.HeaderCell key={i} width={2}>
              {c}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </StickyTableHeader>

      <Table.Body>
        {values.prices &&
          values.prices.map((price, i) => (
            <Table.Row key={i}>
              <Table.Cell key="loc-name">{price.locationName}</Table.Cell>
              <Table.Cell key="price-name">{price.name}</Table.Cell>

              <Table.Cell key="discount-amount">
                <InputField
                  name={`prices[${i}].discount_amount`}
                  type="number"
                  min="0"
                  icon="dollar"
                  iconPosition="left"
                  fluid
                  onError={scrollIntoView}
                />
              </Table.Cell>

              <Table.Cell key="discount-text">
                <InputField name={`prices[${i}].discount_text`} type="text" min="0" fluid onError={scrollIntoView} />
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};
