import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { PercentCell } from '../../common/PercentCell';

const Wrapper = styled.div`
  .ui.table {
    margin-top: -1px;

    td {
      cursor: pointer;
    }

    td[rowspan] {
      border-right: 1px solid rgba(34, 36, 38, 0.1);
      color: #d9dadb;
    }

    ${(p) =>
      p.expandable &&
      css`
        &:hover {
          background: #f9fafb;
        }
      `}
  }
`;

export const AnswersTable = ({ answers, totalCount, branchCount, showHeader, collapsed, expandable }) => (
  <Wrapper expandable={expandable}>
    <Table structured>
      {showHeader && (
        <Table.Header>
          <Table.Row>
            {expandable && <Table.HeaderCell></Table.HeaderCell>}
            <Table.HeaderCell width={11}>Answer</Table.HeaderCell>
            <Table.HeaderCell width={3} textAlign="right">
              Count
            </Table.HeaderCell>

            <Table.HeaderCell width={3} textAlign="right">
              Conversion
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      )}
      <Table.Body>
        {answers.map((answer, j) => (
          <Table.Row key={j}>
            {expandable && j === 0 && (
              <Table.Cell rowSpan={answers.length} textAlign="center">
                <Icon name={`chevron ${collapsed ? 'right' : 'down'}`} />
              </Table.Cell>
            )}
            <Table.Cell width={11}>{answer.answer}</Table.Cell>
            <Table.Cell width={3} textAlign="right">
              {answer.count}
            </Table.Cell>

            <Table.Cell width={3} textAlign="right">
              <PercentCell value={(answer.count * 100) / totalCount} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </Wrapper>
);
