import React, { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { getEligibleClients } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import EmailSubjectsCustomization from './EmailSubjectsCustomizationsOverview';
import { useEmailSubjects } from './EmailSubjectsContext';
import { STATUSES } from '../../../constants';
import { PageHeader, PAGE_HEADER_MARGIN } from '../../common';

const EmailSubjectsCustomizationNew = () => {
  const { loaderStatus } = useEmailSubjects();
  const [eligibleClients, setEligibleClients] = useState([]);

  useEffect(() => {
    const fetchEligibleClients = async () => {
      try {
        const data = await getEligibleClients();
        setEligibleClients(data?.results || []);
      } catch (error) {
        toast.error(parseErrorResponse(error));
      }
    };

    fetchEligibleClients();
  }, []);

  return (
    <>
      <PageHeader
        title="Customize Email Subjects"
        subtitle="Select the client and customize the email subjects."
        margin={PAGE_HEADER_MARGIN.SMALL}
      />
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <EmailSubjectsCustomization
        loaderStatus={loaderStatus}
        eligibleClients={eligibleClients}
        userEmailSubjects={[]}
        communityEmailSubjects={[]}
      />
    </>
  );
};

export default EmailSubjectsCustomizationNew;
