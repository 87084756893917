import React from 'react';
import { Label } from 'semantic-ui-react';
import { Module } from './Module';

export const UnknownModuleType = ({ node }) => {
  const { module } = node;

  return (
    <Module node={node}>
      <Label color="grey">Unknown Module Type: {module.module_type}</Label>
      <Label color="grey">Do nothing</Label>
    </Module>
  );
};
