import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ConversationsContext } from './ConversationsContext';
import { debounce } from '../utils';
import SearchInput from '../SearchInput';
import { Icon } from '../lib';

const StyledInput = styled(SearchInput)`
  flex-grow: 1;

  .icon {
    color: var(--light-text);
  }
`;

export const ConversationsSearch = () => {
  const { filters, handleFiltersChange } = useContext(ConversationsContext);

  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(filters.search);
  }, [filters.search]);

  const onSearch = (e, { value }) => {
    setSearch(value);
    debouncedOnFilterChange({ search: value });
  };

  const searchAndSegmentEvent = useCallback(
    (filters) => {
      handleFiltersChange(filters);
      const { search } = filters;
      const event = {
        search,
      };
      window?.analytics?.track('Search all conversation', event);
    },
    [filters]
  );

  const debouncedOnFilterChange = useCallback(debounce(searchAndSegmentEvent, 1000), [filters]);

  return (
    <StyledInput
      fluid
      placeholder=""
      iconPosition="left"
      onChange={onSearch}
      value={search}
      icon={<Icon name="search" size="24" color="var(--light-text)" />}
    />
  );
};
