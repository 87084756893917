import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CalendarPicker, Icon, Loader, Popup } from 'components/lib';
import { updateLeadMoveInDate } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { If } from 'components/If';
import { formatAnyDate } from 'components/utils';
import { capitalize } from 'utils/helpers';
import { ConversationContext } from '../ConversationContext';

const MoveInDateContainer = styled.div`
  min-width: 166px;
  max-width: 300px;
  height: 36px;
  padding: 8px 10px 7px;
  font-family: var(--font-family);
  box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
  border: solid 1px var(--line-standard);
  background-color: var(--white);
  color: var(--text);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & .title {
    font-size: 14px;
    line-height: 14px;
  }

  & .subtitle {
    font-size: 12px;
    line-height: 12px;
  }
`;
const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  &.loader {
    align-items: center;
  }
`;

export const MoveInDatePicker = () => {
  const { t } = useTranslation();
  const { selectedConversation, setSelectedConversation } = useContext(ConversationContext);
  const { id: leadId = null, details: { move_in_date: moveInDate = null, created_on } = {} } =
    selectedConversation || {};

  const [selected, setSelected] = useState(moveInDate ? new Date(moveInDate) : null);
  const leadCreationDate = new Date(created_on);
  const [loader, setLoader] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  if (!leadId) {
    return null;
  }

  const handleChange = async (date) => {
    setLoader(true);
    try {
      const response = await updateLeadMoveInDate(leadId, format(date, 'yyyy-MM-dd'));
      if (response) {
        setLoader(false);
        setSelected(date);
        setSelectedConversation({
          ...selectedConversation,
          details: { ...selectedConversation.details, move_in_date: date },
        });
        setPopupOpen(false);
      }
    } catch (e) {
      console.error(e);
      setLoader(false);
      toast.error(parseErrorResponse(e));
    }
  };

  const dateState = () => {
    return !loader;
  };

  return (
    <Popup
      on="click"
      basic
      position="bottom right"
      offset={[0, -10]}
      style={{ padding: '0', borderRadius: '0px' }}
      open={popupOpen}
      onOpen={() => setPopupOpen(true)}
      onClose={() => setPopupOpen(false)}
      trigger={
        <MoveInDateContainer>
          <DateContainer className={classNames({ loader })}>
            <Loader active={loader} />
            <If condition={!loader}>
              <If condition={selected}>
                <span className="title">{capitalize(t('move_in'))} date</span>
                <span className="subtitle">{formatAnyDate(selected, 'MMM d, yyyy')}</span>
              </If>
              <If condition={!selected}>
                <span>Select {t('move_in')} date</span>
              </If>
            </If>
          </DateContainer>
          <Icon name="expand_more" color="var(--text)" size="10" />
        </MoveInDateContainer>
      }
      content={
        <CalendarPicker
          color="var(--link)"
          onChange={handleChange}
          selectedDate={selected}
          minDate={leadCreationDate}
          filterDate={dateState}
        />
      }
    />
  );
};
