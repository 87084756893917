import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { If } from 'components/If';
import { Icon } from 'components/lib';
import { useCheckMobileScreen } from 'utils/hooks';
import { MEDIA_BREAK_POINTS } from '../../../constants';
import { advancedPages } from './constants';
import { UserContext } from '../../UserContext';

const MainWrapper = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: column;
    gap: 0;
  }
`;

const Column = styled.div`
  flex: 1;

  @media (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    margin-bottom: 30px;
  }
`;

const Section = styled.div`
  font-family: var(--font-family);
  width: 100%;
  margin-bottom: 30px;
`;

const SectionTitle = styled.h3`
  font-family: var(--font-family-bold);
  font-size: 20px;
  color: var(--text);
  text-transform: capitalize;
`;

const SectionContent = styled.div`
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  background-color: var(--white);
  overflow: hidden;
`;

const SectionItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 0 10px 0;
  &:not(:first-child) {
    border-top: 1px solid #d7d7d7;
  }
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  &:hover {
    background-color: ${(props) => (props.disabled ? '#ffffff' : '#fafafa')};
  }
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 900;
  color: var(--text);
  line-height: 1.5;
  letter-spacing: normal;
  text-transform: capitalize;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SubTitle = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #89919d;
  line-height: normal;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const Beta = styled.div`
  display: inline-flex;
  padding: 2px 5px;
  border-radius: 4px;
  background-color: #f0f0ff;
  font-family: var(--font-family);
  font-size: 8px;
  text-align: center;
  color: #5f5fff;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: ${(props) => props?.iconWrapperBgColor || 'var(--white)'};
  box-shadow: ${(props) => props?.iconWrapperBoxShadow || 'unset'};
  background-image: ${(props) => props?.iconWrapperBgImage || 'unset'};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  flex-shrink: 0;
  > span {
    transform: ${(props) => props.iconRotate || 'none'};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

const ChevronWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

const renderSectionItems = (items, user, originalUser) => {
  return items.map((item) => {
    const Wrapper = item.path && !item.blankPagePath ? Link : 'div';
    const isDisabled =
      (!item.path && !item.onClick && !item.blankPagePath) ||
      !item.allowedTypes?.includes(item.beta ? originalUser.type : user.type);
    const history = useHistory();
    const handleOnClick = () => {
      if (item.blankPagePath) {
        history.push(item.blankPagePath);
      } else if (item.onClick) {
        item.onClick();
      }
    };
    return (
      <SectionItem as={Wrapper} to={item.path} onClick={handleOnClick} key={item.title} disabled={isDisabled}>
        <IconWrapper {...item} disabled={isDisabled}>
          <Icon name={item.iconName} color={item.iconColor} variant={item.iconVariant} />
        </IconWrapper>
        <TextWrapper disabled={isDisabled}>
          <TitleContainer>
            <Title>{item.title}</Title>
            <If condition={item.beta}>
              <Beta>BETA</Beta>
            </If>
          </TitleContainer>
          <SubTitle>{item.subTitle}</SubTitle>
        </TextWrapper>
        <ChevronWrapper disabled={isDisabled}>
          <Icon name="chevron_right" color="#89919d" size="24" />
        </ChevronWrapper>
      </SectionItem>
    );
  });
};

export const Main = () => {
  const isMobile = useCheckMobileScreen();
  const { user, getSessionOriginalUser } = useContext(UserContext);
  const originalUser = getSessionOriginalUser();
  const { t } = useTranslation();
  const pages = advancedPages(t);
  const sortedData = isMobile
    ? pages
    : pages.sort((a, b) => {
        return a.columnIndex - b.columnIndex;
      });
  const midIndex = sortedData.findIndex((section) => section.columnIndex === 1);
  const leftColumnData = isMobile ? pages : sortedData.slice(0, midIndex);
  const rightColumnData = isMobile ? [] : sortedData.slice(midIndex);

  return (
    <MainWrapper>
      <Column>
        {leftColumnData.map((section) => (
          <Section key={section.title}>
            <SectionTitle>{section.title}</SectionTitle>
            <SectionContent>{renderSectionItems(section.items, user, originalUser)}</SectionContent>
          </Section>
        ))}
      </Column>
      <Column>
        {rightColumnData.map((section) => (
          <Section key={section.title}>
            <SectionTitle>{section.title}</SectionTitle>
            <SectionContent>{renderSectionItems(section.items, user, originalUser)}</SectionContent>
          </Section>
        ))}
      </Column>
    </MainWrapper>
  );
};
