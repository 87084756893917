import React, { useState } from 'react';
import styled from 'styled-components';

import { AudioPlayer, Icon } from 'components/lib';

const Container = styled.div.attrs(({ playerOffset }) => ({
  style: {
    left: `calc(50% - ${playerOffset}px)`,
  },
}))`
  display: inline-flex;
  border-radius: 18px;
  border: solid 1px var(--emerald-green);
  background-color: #eefff9;
  padding: 0 3px 0 4px;
  cursor: pointer;
`;
const PlayerContainer = styled.div`
  display: flex;
  position: fixed;
  padding: 6px 10px;
  height: 36px;
  background: #fff;
  border-radius: 18px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.12);
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5000;
  align-items: center;
`;

const CloseIcon = styled(Icon)`
  margin-left: 10px;
`;

export const SimpleAudioWidget = ({ url, duration, color = 'var(--emerald-green)', text, playerOffset = 0 }) => {
  const [playerVisible, setPlayerVisible] = useState(false);
  const [playing, setPlaying] = useState(undefined);

  const handleClose = (e) => {
    e.stopPropagation();

    setPlaying(false);
    setPlayerVisible(false);
  };

  const handlePlayerOpen = async () => {
    setPlayerVisible(true);
  };

  const togglePlay = (e) => {
    e.stopPropagation();

    if (!playerVisible) {
      handlePlayerOpen();
    }
    setPlaying(!playing);
  };

  const onClick = (e) => {
    e.stopPropagation();
  };

  const statusIcon = playing ? 'pause' : 'play_arrow';
  const backgroundColor = playing ? color : '#eefff9';
  const iconColor = playing ? '#fff' : color;

  return (
    <>
      <Container onClick={togglePlay} style={{ backgroundColor }}>
        <Icon name={statusIcon} color={iconColor} size="22" />
      </Container>

      {playerVisible && (
        <PlayerContainer onClick={onClick} playerOffset={playerOffset}>
          <AudioPlayer
            url={url}
            duration={duration}
            text={text}
            color={color}
            overRidePlayPause={playing}
            outSideState={setPlaying}
          />
          <CloseIcon name="close" size="24px" color="#89919d" onClick={handleClose} />
        </PlayerContainer>
      )}
    </>
  );
};
