export const STATUSES = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILURE: 'failure',
};

export const MAX_FILE_UPLOAD_SIZE_MB = 2.5;
export const MAX_FILE_UPLOAD_SIZE = MAX_FILE_UPLOAD_SIZE_MB * 1024 * 1024;

export const FORM_ACTIONS = {
  UPDATE: 'update',
  CREATE: 'create',
};

export const STICKY_OFFSETS = {
  NO_OFFSET: 0,
  SMALL_OFFSET: 60,
};

export const US_CANADA_POSTAL_CODE_REGEX =
  /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] \d[A-CEGHJ-NPRSTV-Z]\d)$/;
export const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const WEB_REGEX = /(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/g;
export const SIMPLE_US_PHONE_REGEX = /^\+1\d{10}/;

export const VSA_LINE_TYPE = {
  VSA: 1,
  PROSPECT: 2,
  EVENT: 3,
  EVSA: 4,
  VVSA: 5,
  SURVEY: 6,
  FORWARDED_CALL: 7,
};

export const VSA_LINE_TYPE_NAME = {
  [VSA_LINE_TYPE.VSA]: 'Virtual Sales Assistant',
  [VSA_LINE_TYPE.EVSA]: 'Conversational WebForm',
  [VSA_LINE_TYPE.VVSA]: 'Phone Call',
  [VSA_LINE_TYPE.PROSPECT]: 'Prospect',
  [VSA_LINE_TYPE]: 'Event',
};
export const VSA_LINE_TYPE_NAME_SHORT = {
  [VSA_LINE_TYPE.VSA]: 'VSA',
  [VSA_LINE_TYPE.EVSA]: 'CW',
  [VSA_LINE_TYPE.VVSA]: 'IVR',
  [VSA_LINE_TYPE.SURVEY]: 'Survey',
};

export const IA_TYPES = {
  LIST: 'list',
  TEXT: 'text',
};

export const IA_TYPE_VALIDATION = {
  LIST: "List iteam can't be empty. If you want to remove this item please use the trash icon on the right.",
  TEXT: "Text iteam can't be empty. If you want to remove this item please use the trash icon in the top right corner.",
};

export const USER_TYPES = {
  STAFF: 'staff',
  MEMBER: 'member',
  REGIONAL: 'regional',
  OWNER: 'owner',
  REGULAR: 'regular',
  LABELING: 'labeling',
};

export const USER_TYPES_DEFAULT_ROUTES = {
  STAFF: 'admin/client-management',
  MEMBER: '/messages',
  REGIONAL: '/stats',
  OWNER: '/owners-dashboard',
  REGULAR: '/stats',
  LABELING: '/admin/questions-labeling',
};

export const PAGINATION = {
  PAGE: 1,
  PAGE_SIZE: 30,
  MEDIUM_PAGE_SIZE: 300,
  MAX_PAGE_SIZE: 1000,
  MAX_LARGE_PAGE_SIZE: 10000,
};

export const SORT_ORDER = {
  ASC: 'ascending',
  DESC: 'descending',
};

export const DEBOUNCE_TIMEOUT = 500;

export const TOUR_START_TIMES = [
  {
    key: '8 AM',
    text: '8 AM',
    value: 8,
  },
  {
    key: '9 AM',
    text: '9 AM',
    value: 9,
  },
  {
    key: '10 AM',
    text: '10 AM',
    value: 10,
  },
  {
    key: '11 AM',
    text: '11 AM',
    value: 11,
  },
  {
    key: '12 PM',
    text: '12 PM',
    value: 12,
  },
  {
    key: '1 PM',
    text: '1 PM',
    value: 13,
  },
  {
    key: '2 PM',
    text: '2 PM',
    value: 14,
  },
];

export const TOUR_END_TIMES = [
  {
    key: '2 PM',
    text: '2 PM',
    value: 14,
  },
  {
    key: '3 PM',
    text: '3 PM',
    value: 15,
  },
  {
    key: '4 PM',
    text: '4 PM',
    value: 16,
  },
  {
    key: '5 PM',
    text: '5 PM',
    value: 17,
  },
  {
    key: '6 PM',
    text: '6 PM',
    value: 18,
  },
  {
    key: '7 PM',
    text: '7 PM',
    value: 19,
  },
  {
    key: '8 PM',
    text: '8 PM',
    value: 20,
  },
];

export const WEEKDAYS = [
  {
    key: 'Monday',
    text: 'Monday',
    value: 1,
  },
  {
    key: 'Tuesday',
    text: 'Tuesday',
    value: 2,
  },
  {
    key: 'Wednesday',
    text: 'Wednesday',
    value: 3,
  },
  {
    key: 'Thursday',
    text: 'Thursday',
    value: 4,
  },
  {
    key: 'Friday',
    text: 'Friday',
    value: 5,
  },
  {
    key: 'Saturday',
    text: 'Saturday',
    value: 6,
  },
  {
    key: 'Sunday',
    text: 'Sunday',
    value: 0,
  },
];

export const IA_ATTRIBUTE_TYPES = {
  SENIOR_LIVING: 'Senior Living',
  MULTI_FAMILY: 'Multi Family',
};

export const TALK_FURTHER = {
  SUPPORT_EMAIL: 'support@talkfurther.com',
};

export const LOCATIONS = [
  [
    'Summit Vista',
    'ASSISTED_LIVING',
    'https://www.summitvista.com/',
    '3390 W. Signal Peak Dr.',
    '(801) 758-3188',
    'Taylorsville',
    'UT',
    '84129',
    'https://www.summitvista.com/wp-content/uploads/2020/10/logo-summit-vista-_1_.png',
  ],
];

export const LOCATION_HEADERS = [
  'Name',
  'Type',
  'URL',
  'Address',
  'Phone',
  'City',
  'State',
  'ZIP',
  'Icon url',
  'Main photo url',
  'About Text',
  'Lead Email',
  'Other Lead Emails',
  'Floor plan url',
];

export const BRANDING_KEYS = {
  BRAND_COLOR: 'brandColor',
  BRAND_COLOR_LIGHTER: 'brandColorLighter',
  BRAND_COLOR_DARKER: 'brandColorDarker',
  WHITELABELED: 'whitelabeled',
};

export const DEFAULT_BRANDING_COLORS = {
  BRAND_COLOR: '1f57bd',
  BRAND_COLOR_LIGHTER: '1678c2',
  BRAND_COLOR_DARKER: '1f3c70',
  FURTHER_BRAND_COLOR: 'FB8778',
};

export const MEDIA_BREAK_POINTS = {
  SMALL_MOBILE: 400,
  MOBILE: 992,
  WEB: 1280,
  TABLET: 1540,
};

export const SSO_PAGE = {
  UNAUTHORIZED_HEADER: 'We were unable to locate your account within Sherpa Chat.',
  UNAUTHORIZED_MESSAGE:
    'Please contact your support representative to ensure your company and user account are properly set up within Sherpa Chat.',
  UNEXPECTED_ERROR_HEADER:
    'We apologize but an unexpected error has occurred while attempting to sign you into Sherpa Chat.',
  UNEXPECTED_ERROR_MESSAGE: 'Please try again or contact your support representative for assistance.',
};

export const LEAD_USER_REMINDER_TYPE = {
  MESSAGES: 'messages',
  INSTANT_RESPONSES: 'instant_responses',
};

export const LEAD_USER_REMINDER_SCHEDULE_TYPE = {
  DAY: 'day',
  TWO_DAYS: 'two_days',
  WEEK: 'week',
  TWO_WEEKS: 'two_weeks',
  MONTH: 'month',
  THREE_MONTHS: 'three_months',
  SIX_MONTHS: 'six_months',
  CUSTOM: 'custom',
  NO_REMINDER: 'no_reminder',
  SELECT: 'select',
};

export const LEAD_USER_REMINDER_SCHEDULE_TYPE_OPTIONS = [
  { key: 'day', value: LEAD_USER_REMINDER_SCHEDULE_TYPE.DAY, text: '1 day' },
  { key: 'two_days', value: LEAD_USER_REMINDER_SCHEDULE_TYPE.TWO_DAYS, text: '2 days' },
  { key: 'week', value: LEAD_USER_REMINDER_SCHEDULE_TYPE.WEEK, text: 'a week' },
  { key: 'two_weeks', value: LEAD_USER_REMINDER_SCHEDULE_TYPE.TWO_WEEKS, text: '2 weeks' },
  { key: 'month', value: LEAD_USER_REMINDER_SCHEDULE_TYPE.MONTH, text: 'a month' },
  { key: 'three_months', value: LEAD_USER_REMINDER_SCHEDULE_TYPE.THREE_MONTHS, text: '3 months' },
  { key: 'six_months', value: LEAD_USER_REMINDER_SCHEDULE_TYPE.SIX_MONTHS, text: '6 months' },
  { key: 'no_reminder', value: LEAD_USER_REMINDER_SCHEDULE_TYPE.NO_REMINDER, text: 'No reminder' },
];

export const CRM_LOCATION_CODE_RULE_INPUT_TYPE = {
  INPUT: 'input',
  OPTIONS: 'options',
};

export const EMAIL_PROVIDERS = {
  MICROSOFT: 'microsoft',
  GOOGLE: 'google',
  FURTHER: 'further',
};

export const OAUTH_STATUS = {
  ACTIVE: 'active',
  FAILURE: 'failure',
};

export const CONVERSATION_TYPE = {
  EMAIL: 'email',
  SMS: 'sms',
};

export const LEAD_EVENT_MESSAGE_TYPES = {
  1: 'sms',
  2: 'email',
};

export const LEAD_EVENT_TYPE = {
  SMS_MESSAGE: 1,
  EMAIL_MESSAGE: 2,
  STOP_CONVERSATION: 3,
};

export const SENDER_TYPE = {
  LEAD: 'Prospect',
  SALES_ASSISTANT: 'Sales Assistant',
  COMMUNITY_MANAGER: 'Community Manager',
  SYSTEM: 'System',
  AUTOMATED: 'Automated',
};

export const DEFAULT_DJANGO_TIMEZONE = 'America/New_York';

export const LEAD_PROCESSING_STATUS = {
  PENDING: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
  FAILED: 4,
};

export const LEAD_PROCESSING_STATUS_DISPLAY = {
  [LEAD_PROCESSING_STATUS.PENDING]: 'Pending',
  [LEAD_PROCESSING_STATUS.IN_PROGRESS]: 'In progress',
  [LEAD_PROCESSING_STATUS.COMPLETED]: 'Completed',
  [LEAD_PROCESSING_STATUS.FAILED]: 'Failed',
};

export const LEAD_PROCESSING_ACTION = {
  PREPROCESS: 1,
  VALIDATION: 2,
  NURTURE: 3,
  SEND_TO_CRM: 4,
  SEND_COMMUNITY_EMAIL: 5,
  SEND_TOUR_CALENDAR_EVENT: 6,
  DIRECT_CONNECT: 7,
  SEND_LEAD_REVISIT_COMMUNITY_EMAIL: 8,
  SEND_LEAD_REVISIT_TO_CRM: 9,
};

export const LEAD_PROCESSING_ACTION_DISPLAY = (t) => ({
  [LEAD_PROCESSING_ACTION.PREPROCESS]: 'Preprocess',
  [LEAD_PROCESSING_ACTION.VALIDATION]: 'Validation',
  [LEAD_PROCESSING_ACTION.NURTURE]: 'Nurture',
  [LEAD_PROCESSING_ACTION.SEND_TO_CRM]: 'Send to CRM',
  [LEAD_PROCESSING_ACTION.SEND_COMMUNITY_EMAIL]: `Send ${t('community')} email`,
  [LEAD_PROCESSING_ACTION.SEND_TOUR_CALENDAR_EVENT]: `Send ${t('tour')} calendar event`,
  [LEAD_PROCESSING_ACTION.DIRECT_CONNECT]: 'Direct connect',
  [LEAD_PROCESSING_ACTION.SEND_LEAD_REVISIT_COMMUNITY_EMAIL]: `Send revisit ${t('community')} email`,
  [LEAD_PROCESSING_ACTION.SEND_LEAD_REVISIT_TO_CRM]: 'Send revisit note to CRM',
});

export const LEAD_PROCESSING_ACTION_STATUS = {
  PENDING: 1,
  COMPLETED: 2,
  FAILED: 3,
};

export const MAX_MESSAGE_LENGTH = 1600;

export const MARKET_TYPE = {
  DEFAULT: 1,
  SENIOR_LIVING: 2,
  HOME_HEALTH: 3,
  BEHAVIORAL_HEALTH: 4,
  DENTIST: 5,
  VET: 6,
  MED_SPA: 7,
  MULTI_FAMILY: 8,
  AGENCY: 9,
};

export const MARKET_TYPE_LABEL = {
  [MARKET_TYPE.DEFAULT]: 'Default',
  [MARKET_TYPE.SENIOR_LIVING]: 'Senior Living',
  [MARKET_TYPE.HOME_HEALTH]: 'Home Health',
  [MARKET_TYPE.BEHAVIORAL_HEALTH]: 'Behavioral Health',
  [MARKET_TYPE.DENTIST]: 'Dentist',
  [MARKET_TYPE.VET]: 'Vet',
  [MARKET_TYPE.MED_SPA]: 'Med Spa',
  [MARKET_TYPE.MULTI_FAMILY]: 'Multi Family',
  [MARKET_TYPE.AGENCY]: 'Agency',
};

export const TRAILING_30_DAYS_REPORT_FREQUENCY = {
  NEVER: 1,
  WEEKLY: 2,
  MONTHLY: 3,
};

export const TOUR_AVAILABILTY_TIME_SLOTS = [
  { key: '7', text: '7:00 AM', value: 7 },
  { key: '8', text: '8:00 AM', value: 8 },
  { key: '9', text: '9:00 AM', value: 9 },
  { key: '10', text: '10:00 AM', value: 10 },
  { key: '11', text: '11:00 AM', value: 11 },
  { key: '12', text: '12:00 PM', value: 12 },
  { key: '13', text: '1:00 PM', value: 13 },
  { key: '14', text: '2:00 PM', value: 14 },
  { key: '15', text: '3:00 PM', value: 15 },
  { key: '16', text: '4:00 PM', value: 16 },
  { key: '17', text: '5:00 PM', value: 17 },
  { key: '18', text: '6:00 PM', value: 18 },
  { key: '19', text: '7:00 PM', value: 19 },
  { key: '20', text: '8:00 PM', value: 20 },
  { key: '21', text: '9:00 PM', value: 21 },
];

export const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const CONNECTED_CALENDARS_STATUSES = {
  NOT_CONNECTED: 'not_connected',
  PENDING: 'pending',
  CONNECTED: 'connected',
};

export const CONNECTED_CALENDARS_ACTIONS = {
  SEND_REMINDER: 'send_reminder',
  REMOVE: 'remove',
  RESEND_REMINDER: 'resend_reminder',
};

export const CONNECTED_CALENDARS_VALUES = {
  OFF: {
    value: null,
    text: 'Off',
    dropdownValue: 'off',
  },
  BASIC: {
    value: 'google',
    text: 'Legacy',
  },
  ENHANCED: {
    value: 'cronofy',
    text: 'On',
  },
};

export const PARTICIPATE_IN_BANCHMARK_DATA = {
  ON: {
    value: false,
    text: 'On',
  },
  OFF: {
    value: true,
    text: 'Off',
  },
};

export const EMAIL_PHONE_REQUIREMENTS_VALUES = {
  REQUIRE_BOTH: {
    value: false,
    text: 'Require both',
  },
  REQUIRE_EITHER: {
    value: true,
    text: 'Require either',
  },
};

export const EMAIL_ADDITIONAL_NOTE = {
  ON: {
    value: true,
    dropdownValue: true,
    text: 'On',
  },
  OFF: {
    value: null,
    dropdownValue: false,
    text: 'Off',
  },
};
export const PHONE_INPUT_WARNING_TEXT = {
  CUSTOM: {
    value: true,
    dropdownValue: true,
    text: 'Custom',
  },
  DEFAULT: {
    value: null,
    dropdownValue: false,
    text: 'Default',
  },
};

export const SETTING_STATE_ON_OFF = {
  ON: {
    value: true,
    text: 'On',
  },
  OFF: {
    value: false,
    text: 'Off',
  },
};

export const MOBILE_DISPLAY_STATES = {
  STRING: {
    value: true,
    dropdownValue: true,
    text: 'String',
  },
  PHONE_NUMBER: {
    value: null,
    dropdownValue: false,
    text: 'Phone Number',
  },
};

export const isEmptyParam = (p) => p === '' || (Array.isArray(p) && !p.length);

export const setUrlSearchParams = (searchParams, emptyAsNull) => {
  const url = new URL(window.location);

  Object.entries(searchParams).forEach((p) => {
    if (p[1] === null || p[1] === undefined || (emptyAsNull && isEmptyParam(p[1]))) {
      url.searchParams.delete(p[0]);
    } else {
      url.searchParams.set(p[0], p[1]);
    }
  });
  window.history.pushState({}, '', url);
};

export const BANNER_DEFAULT_START_TIME = '09:00';
