import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader, Popup, Radio, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { updateScheduledMessage } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { If } from 'components/If';
import { formatAnyDate } from 'components/utils';
import { useScheduledMessages } from './ScheduledMessageContext';

const ScheduledDate = styled.div`
  font-weight: 700;
`;

const Scheduled = styled.div`
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  font-weight: 700;
`;

const MessageTemplate = styled.div`
  text-align: justify;
`;

const PreviewMessage = styled.span`
  font-size: 0.8rem;
  color: #4183c4;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
`;

const ScheduledMessageListRow = ({ message }) => {
  const [loading, setLoading] = useState(false);
  const messagePreviewRef = useRef();
  const [, dispatch] = useScheduledMessages();
  const { lead, campaignMessage, community } = message;

  const handleUpdate = async (e, { checked }) => {
    try {
      setLoading(true);
      const data = {
        useFirstNameInMessaging: !checked,
      };
      const result = await updateScheduledMessage(message.id, data);

      dispatch({ type: 'UPDATE_MESSAGE', payload: { leadId: lead.id, data: result } });
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to update scheduled message'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Table.Row>
      <Table.Cell width={2}>
        <Link to={`/conversations/${lead.id}`}>{lead.name}</Link>
      </Table.Cell>
      <Table.Cell width={6}>
        <MessageTemplate ref={messagePreviewRef}>{campaignMessage.message}</MessageTemplate>
        <Popup
          wide
          content={message.messagePreview}
          context={messagePreviewRef}
          on="click"
          pinned
          trigger={<PreviewMessage>preview</PreviewMessage>}
          position="right center"
        />
      </Table.Cell>
      <Table.Cell width={3}>{community.name}</Table.Cell>
      <Table.Cell width={3}>
        <ScheduledDate>
          {message.sendCampaignMessageAt && formatAnyDate(message.sendCampaignMessageAt, 'MMMM do yyyy, h:mm a')}
        </ScheduledDate>
        {message.isToday && <Scheduled>Today</Scheduled>}
        {message.isThisWeek && !message.isToday && <Scheduled>This week</Scheduled>}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <If condition={!loading}>
          <Radio disabled={loading} toggle checked={!lead.useFirstNameInMessaging} onChange={handleUpdate} />
        </If>
        <Loader active={loading} inline size="tiny" />
      </Table.Cell>
    </Table.Row>
  );
};

export default ScheduledMessageListRow;
