import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import SearchInput from 'components/SearchInput';
import { Radio } from 'components/lib';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  div {
    flex-basis: 25%;
  }
`;

const JobApplicantEmailFilter = ({ filters, onFilter, disabled }) => {
  const { t } = useTranslation();
  const { search, showOnlyWithoutEmails } = filters;

  const handleSearch = (value) => {
    onFilter({ ...filters, search: value });
  };

  const handleShowOnlyWithoutEmails = () => {
    onFilter({ ...filters, showOnlyWithoutEmails: !showOnlyWithoutEmails });
  };

  return (
    <Container>
      <SearchInput
        value={search}
        disabled={disabled}
        placeholder={`Search ${t('communities')} by name`}
        onSearch={handleSearch}
      />
      <Radio
        toggle
        label="Show only without emails"
        checked={showOnlyWithoutEmails}
        onChange={handleShowOnlyWithoutEmails}
        disabled={disabled}
      />
    </Container>
  );
};

export default JobApplicantEmailFilter;
