import React, { useEffect, useState } from 'react';
import { format, startOfWeek, startOfMonth } from 'date-fns';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { getLeadValueScoresGraph } from 'apis';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { parseErrorResponse } from 'apis/utils';
import { capitalize, queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { PageSection } from '../../common';

const GraphMode = {
  days: 'days',
  weeks: 'weeks',
  months: 'months',
};

const Wrapper = styled.div`
  height: 25rem;
`;

const AverageLeadValueScoreGraph = ({ filters, publicId }) => {
  const { t } = useTranslation();
  const [initData, setInitData] = useState({
    labels: [],
    average_lead_scores: [],
  });

  const { data, status, error, isFetching } = useQuery(
    [queryConstants.AVERAGE_LEAD_VALUE_SCORE_GRAPH, { publicId, filters }],
    () => getLeadValueScoresGraph({ public_id: publicId, filters })
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, `Unable to fetch ${t('move_in')} likelihood graph`));
  }

  useEffect(() => {
    if (data) {
      setInitData({
        labels: data.map((d) => d.date),
        average_lead_scores: data.map((d) => d.avg_lead_score),
      });
    }
  }, [data]);

  const [graphData, setGraphData] = useState({});
  const [period, setPeriod] = useState(GraphMode.days);

  useEffect(() => {
    const prepareGraphData = (labels, avgLVS) => {
      const dataset = {
        labels: labels.slice(),
        datasets: [
          {
            label: `Avg. ${capitalize(t('move_ins'))} Likelihood`,
            backgroundColor: 'rgba(31, 87, 189, 0.1)',
            borderColor: 'rgba(31, 87, 189, 1)',
            borderWidth: 1,
            minBarLength: 5,
            hoverBackgroundColor: 'rgba(31, 119, 180, 0.4)',
            hoverBorderColor: 'rgba(31, 119, 180, 1)',
            data: avgLVS.slice().map((v) => v.toFixed(1)),
          },
        ],
      };

      setGraphData(dataset);
    };

    if (period === GraphMode.days) {
      const { labels, average_lead_scores } = initData;
      prepareGraphData(labels, average_lead_scores);
      return;
    }

    const config =
      period === GraphMode.weeks
        ? {
            fn: startOfWeek,
            format: 'yyyy-LL-dd',
          }
        : {
            fn: startOfMonth,
            format: 'LL/yyyy',
          };

    const { labels, average_lead_scores } = initData;
    const groupedData = {};
    const keys = new Set();
    labels.forEach((element, index) => {
      const groupLabel = format(config.fn(new Date(element)), config.format);

      if (!keys.has(groupLabel)) {
        keys.add(groupLabel);
        groupedData[groupLabel] = {
          scores: [],
        };
      }

      groupedData[groupLabel].scores.push(average_lead_scores[index]);
    });

    const groupedLabels = [];
    const groupedLVS = [];

    Object.keys(groupedData).forEach((key) => {
      const totalScore = groupedData[key].scores.reduce((total, curr) => total + curr, 0);
      const scoresCount = groupedData[key].scores.length;

      let avgScore = scoresCount > 0 ? totalScore / scoresCount : 0;
      avgScore = Math.round(avgScore * 100) / 100;

      groupedLabels.push(key);
      groupedLVS.push(avgScore);
    });

    prepareGraphData(groupedLabels, groupedLVS);
  }, [period, initData]);

  const switchTo = (period = GraphMode.days) => {
    setPeriod(period);
  };

  return (
    <PageSection
      title={`Avg. ${capitalize(t('move_ins'))} Likelihood`}
      loaderStatus={loaderStatus}
      actions={
        <Button.Group size="mini">
          <Button active={period === GraphMode.days} onClick={() => switchTo(GraphMode.days)}>
            Day
          </Button>
          <Button active={period === GraphMode.weeks} onClick={() => switchTo(GraphMode.weeks)}>
            Week
          </Button>
          <Button active={period === GraphMode.months} onClick={() => switchTo(GraphMode.months)}>
            Month
          </Button>
        </Button.Group>
      }
    >
      <Wrapper>
        <Line
          data={graphData}
          width={100}
          height={300}
          options={{
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    borderDash: [2, 10],
                    drawBorder: false,
                  },
                  ticks: {
                    padding: 16,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    borderDash: [2, 10],
                    drawBorder: false,
                  },
                  ticks: {
                    min: 0,
                    padding: 16,
                  },
                },
              ],
            },
          }}
        />
      </Wrapper>
    </PageSection>
  );
};

export default AverageLeadValueScoreGraph;
