import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import { useField } from 'formik';
import DatePicker from 'components/lib/DatePicker';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .helper-text {
    margin-top: 15px;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #89919d;
    padding-left: 2px;
  }
`;

const Name = styled.div`
  font-weight: 700;
`;

const Box = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const DateRange = ({ name, criterion }) => {
  const { state, hasEditDeletePermission } = useCampaignDetails();
  const { criteria } = state;
  const { helpText } = criteria.find((c) => c.id === criterion.criterionId);
  const [field1, _1, field1Helper] = useField(`${name}.value[0]`);
  const [field2, _2, field2Helper] = useField(`${name}.value[1]`);
  const [startDate, setStartDate] = useState(new Date(field1.value));
  const [endDate, setEndDate] = useState(new Date(field2.value));

  useEffect(() => {
    const currentStartDate = new Date(field1.value);
    const currentEndDate = new Date(field2.value);

    if (
      startDate.toDateString() !== currentStartDate.toDateString() ||
      endDate.toDateString() !== currentEndDate.toDateString()
    ) {
      field1Helper.setValue(startDate.toISOString());
      field2Helper.setValue(endDate.toISOString());
    }
  }, [startDate, endDate]);

  const handleChangeStartDate = (date) => {
    setStartDate(date);
    const newEndDate = date > endDate ? date : endDate;
    setEndDate(newEndDate);
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);
    const newStartDate = date < startDate ? date : startDate;
    setStartDate(newStartDate);
  };

  return (
    <Container>
      <Name>{criterion.displayName}</Name>
      <Box>
        <DatePicker
          dateFormat="MMMM d yyyy"
          name="start"
          selected={startDate}
          onChange={handleChangeStartDate}
          disabled={!hasEditDeletePermission}
        />
        to
        <DatePicker
          dateFormat="MMMM d yyyy"
          minDate={startDate}
          name="end"
          selected={endDate}
          onChange={handleChangeEndDate}
          disabled={!hasEditDeletePermission}
        />
      </Box>
      {helpText && <div className="helper-text">{helpText}</div>}
    </Container>
  );
};

export default DateRange;
