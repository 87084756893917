import React, { useState, useRef, useContext, useMemo } from 'react';
import { Table, Form, Loader, Dimmer, Message, Icon, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import { InputField } from 'components/common';
import { Divider, Dropdown } from 'components/lib';
import { saveRoomAndServiceOptions } from 'api';
import { STATUSES } from '../../constants';
import { StickyTableHeader } from '../common/PricingTable';
import { StepHeader } from './StepHeader';
import { CsvField } from '../CsvField';
import { Checkbox } from '../Checkbox';
import { OnboardingContext } from './OnboardingContext';
import { ChatTemplateItem } from '../chats/ChatTemplateItem';
import { chatTmplateOrderByType } from '../chats/CreateChat';

export const RoomAndServiceOptions = () => {
  const history = useHistory();
  const { chats, chatsStatus, communities, fetchChats, fetchCommunities, chatTemplates } =
    useContext(OnboardingContext);

  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const submitOptions = async (data, isTemplateSelected) => {
    try {
      setLoading(true);
      await saveRoomAndServiceOptions(data);
      await fetchCommunities();
      // When `New Chat` option is selected we need to fetch newly created object
      if (isTemplateSelected) {
        await fetchChats();
      }
      toast.success('Room & service options saved');
      history.push('/onboarding/steps/4');
    } catch (e) {
      console.error(e);
      toast.error('Error saving room & service options. Please try again later, or contact us for help.');
      setLoading(false);
    }
  };

  const onSave = (values) => {
    const data = values.communities.map((community) => ({
      chat_id: values.chat || undefined,
      profile_id: community.id,
      options: Object.keys(community.options).filter((o) => community.options[o]),
      chat_template: values.template || undefined,
    }));
    const isTemplateSelected = !!values.template;
    submitOptions(data, isTemplateSelected);
  };

  const initialValues = {
    chats: chats?.map((chat) => ({ id: chat.id, name: chat.name })),
    communities: communities?.map((p) => ({
      id: p.id,
      name: p.name,
      options: {},
    })),
    options: [],
    template: undefined,
    chat: undefined,
  };

  const chatsLoading = chatsStatus === STATUSES.LOADING;

  const chatTemplatesList = useMemo(() => {
    return chatTemplates
      .sort((a, b) => {
        return chatTmplateOrderByType(a.vsa_type) - chatTmplateOrderByType(b.vsa_type);
      })
      .map(({ id, name, vsa_type }) => ({
        key: id,
        value: id,
        text: `New chat - ${name} template`,
        content: <ChatTemplateItem text={`New chat - ${name} template`} type={vsa_type} />,
      }));
  }, [chatTemplates]);

  return (
    <>
      <StepHeader
        title="Define Room & Service Options"
        subtitle="Do you offer special room types or care service options?"
        loading={loading || chatsLoading}
        onContinue={() => {
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }}
      />

      <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSave} enableReinitialize>
        {({ values, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Dimmer active={chatsLoading} inverted>
              <Loader content="Loading Chats" />
            </Dimmer>

            <Message info>
              <Icon name="info" />
              Choose a pre-existing chat or initiate a new one to assign new communities.
            </Message>
            <Segment attached="bottom">
              <InputField
                control={Dropdown}
                label="Chat Template"
                name="template"
                placeholder="Please select chat template"
                search
                selection
                fluid
                basic
                clearable
                disabled={values.chat}
                options={chatTemplatesList}
              />
              <Divider horizontal>Or</Divider>
              <InputField
                control={Dropdown}
                label="Chat"
                name="chat"
                placeholder="Please select chat"
                search
                selection
                fluid
                basic
                clearable
                disabled={values.template}
                options={values.chats?.map(({ id, name }) => ({
                  key: id,
                  value: id,
                  text: name,
                }))}
              />
            </Segment>

            <CsvField
              autoFocus
              name="options"
              sortable
              onChange={(options) => {
                setFieldValue(
                  'communities',
                  values.communities?.map((p) => {
                    const newOptions = options
                      .filter((option) => option.length)
                      .reduce(
                        (acc, option) => ({
                          ...acc,
                          [option]: typeof p.options[option] !== 'undefined' ? p.options[option] : true,
                        }),
                        {}
                      );

                    return {
                      ...p,
                      options: newOptions,
                    };
                  })
                );
              }}
            />

            <Table fixed singleLine>
              <StickyTableHeader>
                <Table.Row>
                  <Table.HeaderCell width={4}>Location</Table.HeaderCell>
                  {values.options.map((o, i) => (
                    <Table.HeaderCell key={i}>{o}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </StickyTableHeader>

              <Table.Body>
                {values.communities &&
                  values.communities.map((p, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{p.name}</Table.Cell>
                      {values.options.map((o, j) => (
                        <Table.Cell key={j}>
                          <Checkbox name={`communities[${i}].options.${o}`} />
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Form>
        )}
      </Formik>
    </>
  );
};
