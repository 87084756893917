import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { getCommunityAttributes, getAttributeAnswers, getCommunityDetails, getPlaceholders } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES } from '../../../constants';
import { getInitialValues } from './utils';

export const InstantAnswersContext = createContext();

export const InstantAnswersContextProvider = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { community_id } = useParams();
  const wrapperRef = useRef();

  const [community, setCommunity] = useState();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [communityAttributes, setCommunityAttributes] = useState([]);
  const [activeMenuItem, setActiveMenuItem] = useState(0);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [activeValue, setActiveValue] = useState([]);
  const [placeholders, setPlaceholders] = useState();
  const [importErrors, setImportErrors] = useState([]);

  const [showConfirmUnsavedIA, setShowConfirmUnsavedIA] = useState(false);
  const [showConfirmDeleteIA, setShowConfirmDeleteIA] = useState(false);
  const [showImportDialog, setShowImportDialog] = useState(false);

  const loadAttributeAnswers = async (attributeId) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const attributeValues = await getAttributeAnswers(community_id, attributeId);
      if (attributeValues) {
        setInitialValues(getInitialValues(attributeValues));
        setActiveValue(attributeValues.map((a, i) => ({ ...a, drag_id: i + 1 })));
        setActiveMenuItem(attributeId);
      }

      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      setLoaderStatus(STATUSES.FAILURE);
      toast.error('Error loading answers. Please try again later, or contact us for help.');
    }
  };

  const loadCommunityDetails = useCallback(async () => {
    setLoaderStatus(STATUSES.LOADING);
    try {
      const [communityResponse, communityAttributesResponse] = await Promise.all([
        getCommunityDetails(community_id),
        getCommunityAttributes(community_id),
      ]);

      setCommunity(communityResponse);
      setCommunityAttributes(communityAttributesResponse);
      if (communityAttributesResponse.length) {
        await loadAttributeAnswers(communityAttributesResponse[0].id);
      }
    } catch (e) {
      console.error(e);
      setLoaderStatus(STATUSES.FAILURE);
      const defaultErrorMessage = `Error loading ${t(
        'community'
      )} attribute details. Please try again later, or contact us for help.`;
      toast.error(parseErrorResponse(e, defaultErrorMessage));
      history.goBack();
    }
  }, [community_id]);

  useEffect(() => {
    const loadPlaceholders = async () => {
      try {
        const response = await getPlaceholders();
        setPlaceholders(response);
      } catch (e) {
        console.error(e);
        toast.error('Error loading placeholders.');
      }
    };

    loadCommunityDetails();
    loadPlaceholders();
  }, [community_id, loadCommunityDetails]);

  const onAttributeSelected = (attributeId) => {
    setLoaderStatus(STATUSES.LOADING);
    setActiveValue([]); // must reset active value to trigger a rerender.
    loadAttributeAnswers(attributeId);
  };

  const provide = {
    wrapperRef,
    community,
    loaderStatus,
    communityAttributes,
    activeMenuItem,
    initialValues,
    activeValue,
    placeholders,
    importErrors,
    selectedAttribute,
    showConfirmUnsavedIA,
    showConfirmDeleteIA,
    showImportDialog,
    setLoaderStatus,
    setCommunityAttributes,
    setSelectedAttribute,
    setImportErrors,
    setShowConfirmUnsavedIA,
    setShowConfirmDeleteIA,
    setShowImportDialog,
    setActiveValue,
    loadCommunityDetails,
    loadAttributeAnswers,
    onAttributeSelected,
  };

  return <InstantAnswersContext.Provider value={provide} {...props} />;
};
