import React from 'react';
import { Button } from 'semantic-ui-react';
import { PageHeader } from '../common';

export const StepHeader = ({ title, subtitle, loading, onContinue }) => (
  <PageHeader
    as="h2"
    title={title}
    subtitle={subtitle}
    actions={
      <Button
        primary
        type="button"
        content="Save & Continue"
        icon="chevron right"
        labelPosition="right"
        loading={loading}
        disabled={loading}
        onClick={() => {
          if (typeof onContinue === 'function') {
            onContinue();
          }
        }}
      />
    }
  />
);
