import { API } from '../api';

export const getEmailSubjectOverrides = async (search = '') => {
  const query = search ? new URLSearchParams({ search }).toString() : '';
  const response = await API.get(`api/email-subjects/overrides?${query}`);

  return response.data;
};

export const getEmailSubjectDefaultOverrides = async () => {
  const response = await API.get(`api/email-subjects/defaults`);
  return response.data;
};

export const getEmailSubjectUserOverrides = async (userId) => {
  const response = await API.get(`api/email-subjects/users/${userId}`);
  return response.data;
};

export const getEmailSubjectCommunityOverrides = async (userId) => {
  const response = await API.get(`api/email-subjects/users/${userId}/communities`);
  return response.data;
};

export const getEmailSubjectPlaceholders = async () => {
  const response = await API.get('api/email-subjects/placeholders');
  return response.data;
};

export const getEmailSubjectTypes = async () => {
  const response = await API.get('api/email-subjects/types');
  return response.data;
};

export const createOrUpdateUserEmailSubject = async (userId, data) => {
  const response = await API.post(`api/email-subjects/users/${userId}`, data);
  return response.data;
};

export const deleteUserEmailSubject = async (userSubjectId) => {
  const response = await API.delete(`api/user-email-subjects/${userSubjectId}`);
  return response.data;
};

export const createOrUpdateCommunityEmailSubject = async (userId, data) => {
  const response = await API.post(`api/email-subjects/users/${userId}/communities`, data);
  return response.data;
};

export const removeAllCommunitySubjects = async (userId) => {
  const response = await API.delete(`api/email-subjects/users/${userId}/communities`);
  return response.data;
};

export const restoreSubjectOverrideToSystemDefaults = async (userId) => {
  const response = await API.delete(`api/email-subjects/users/${userId}/restore-all`);
  return response.data;
};

export const getEligibleClients = async () => {
  const response = await API.get('api/email-subjects/eligible-users');
  return response.data;
};

export const getClientDetails = async (userId) => {
  const response = await API.get(`api/email-subjects/eligible-users/${userId}`);
  return response.data;
};
