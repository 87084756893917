import React from 'react';
import { Table } from 'semantic-ui-react';

import { PercentCell, DeltaCell } from 'components/common';
import { formatCount, percent } from '../utils';

export const MetricsBreakdownTable = ({ data }) => (
  <Table className="fixed-size tall">
    <Table.Header>
      <Table.Row textAlign="right">
        <Table.HeaderCell textAlign="left">Management Company</Table.HeaderCell>
        <Table.HeaderCell>Website Visitors</Table.HeaderCell>
        <Table.HeaderCell>VSA Users</Table.HeaderCell>
        <Table.HeaderCell>Leads</Table.HeaderCell>
        <Table.HeaderCell>Avg. Move-in Likelihood</Table.HeaderCell>
        <Table.HeaderCell>Visitor Conversion</Table.HeaderCell>
        <Table.HeaderCell>VSA User Conversion</Table.HeaderCell>
        <Table.HeaderCell>Scheduled Tours</Table.HeaderCell>
        <Table.HeaderCell>Predicted Move-Ins</Table.HeaderCell>
        <Table.HeaderCell>Confirmed Move-Ins</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {data?.map((d, i) => (
        <Table.Row textAlign="right" key={i}>
          <Table.Cell textAlign="left">{d.name}</Table.Cell>
          <Table.Cell>
            <DeltaCell value={formatCount(d.visitors)} secondaryValue={d.visitors - d.previous_visitors} />
          </Table.Cell>
          <Table.Cell>
            <DeltaCell value={formatCount(d.interactions)} secondaryValue={d.interactions - d.previous_interactions} />
          </Table.Cell>
          <Table.Cell>
            <DeltaCell value={formatCount(d.leads)} secondaryValue={d.leads - d.previous_leads} />
          </Table.Cell>
          <Table.Cell>
            <DeltaCell
              value={formatCount(d.avg_lead_value_score?.toFixed(1))}
              secondaryValue={(d.avg_lead_value_score - d.previous_avg_lead_value_score)?.toFixed(1)}
            />
          </Table.Cell>
          <Table.Cell>
            <PercentCell
              value={percent(d.leads, d.visitors)}
              delta={percent(d.leads, d.visitors) - percent(d.previous_leads, d.previous_visitors)}
            />
          </Table.Cell>
          <Table.Cell>
            <PercentCell
              value={percent(d.leads, d.interactions)}
              delta={percent(d.leads, d.interactions) - percent(d.previous_leads, d.previous_interactions)}
            />
          </Table.Cell>
          <Table.Cell>
            <DeltaCell value={formatCount(d.tours)} secondaryValue={d.tours - d.previous_tours} />
          </Table.Cell>
          <Table.Cell>
            <DeltaCell
              value={d.predicted_move_ins}
              secondaryValue={d.predicted_move_ins - d.previous_predicted_move_ins}
              formatter={(v) => v?.toFixed(1)}
            />
          </Table.Cell>
          <Table.Cell>
            <DeltaCell value={formatCount(d.move_ins)} secondaryValue={d.move_ins - d.previous_move_ins} />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);
