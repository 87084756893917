import React from 'react';
import { Table } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { getDevicesBreakdown } from 'apis';
import { toast } from 'react-toastify';
import { parseErrorResponse } from 'apis/utils';
import queryConstants from 'utils/queries/constants';
import { capitalize, queryStatusToLoaderStatus } from 'utils/helpers';
import { PageSection, CsvExport } from '../../common';
import { formatCount, percent } from '../../utils';
import { PercentCell } from '../../common/PercentCell';

const prepareCsvContent = (devicesBreakdown, t) => {
  const totalLeads = devicesBreakdown.reduce((r, v) => r + v.leads, 0);

  const headers = [
    'Device',
    'Website Visitors',
    'VSA Users',
    'Interaction Rate (%)',
    capitalize(t('leads')),
    `% of ${capitalize(t('leads'))} (%)`,
    `Avg. ${capitalize(t('move_ins'))} Likelihood`,
    'Visitor Conversion Rate (%)',
    'VSA User Conversion Rate (%)',
    'Financially Unqualified (%)',
    `Requested ${capitalize(t('tours'))}`,
    capitalize(t('move_ins')),
  ];
  const content = devicesBreakdown.map((b) => [
    b.client_device,
    b.visitors,
    b.interactions,
    percent(b.interactions, b.visitors, { zeroBased: true }).toFixed(4),
    b.leads,
    percent(b.leads, totalLeads, { zeroBased: true }).toFixed(4),
    b.avg_lead_score?.toFixed(1) || '-',
    percent(b.leads, b.visitors, { zeroBased: true }).toFixed(4),
    percent(b.leads, b.interactions, { zeroBased: true }).toFixed(4),
    percent(b.unqualified, b.leads, { zeroBased: true }).toFixed(4),
    b.tours,
    b.moveins,
  ]);

  return [headers, content];
};

const DevicesBreakdown = ({ filters, publicId }) => {
  const { t } = useTranslation();
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.DEVICE_BREAKDOWN, { publicId, filters }],
    () => getDevicesBreakdown({ public_id: publicId, filters }),
    { placeholderData: [] }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch devices breakdown'));
  }

  const totalLeads = data && data.reduce((r, v) => r + v.leads, 0);
  const [csvHeaders, csvContent] = prepareCsvContent(data || [], t);

  return (
    <PageSection title="Devices Breakdown" loaderStatus={loaderStatus}>
      <Table className="fixed-size">
        <Table.Header>
          <Table.Row textAlign="right">
            <Table.HeaderCell textAlign="left">Device</Table.HeaderCell>
            <Table.HeaderCell>Website Visitors</Table.HeaderCell>
            <Table.HeaderCell>VSA Users</Table.HeaderCell>
            <Table.HeaderCell>Interaction Rate</Table.HeaderCell>
            <Table.HeaderCell>{capitalize(t('leads'))}</Table.HeaderCell>
            <Table.HeaderCell>% of {capitalize(t('leads'))}</Table.HeaderCell>
            <Table.HeaderCell>Avg. {capitalize(t('move_ins'))} Likelihood</Table.HeaderCell>
            <Table.HeaderCell>Visitor Conversion Rate</Table.HeaderCell>
            <Table.HeaderCell>VSA User Conversion Rate</Table.HeaderCell>
            <Table.HeaderCell>Financially Unqualified</Table.HeaderCell>
            <Table.HeaderCell>Requested {capitalize(t('tours'))}</Table.HeaderCell>
            <Table.HeaderCell>{capitalize(t('move_ins'))}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data &&
            data.map((b, i) => (
              <Table.Row textAlign="right" key={i}>
                <Table.Cell textAlign="left">{b.client_device}</Table.Cell>
                <Table.Cell>{formatCount(b.visitors)}</Table.Cell>
                <Table.Cell>{formatCount(b.interactions)}</Table.Cell>
                <Table.Cell>
                  <PercentCell value={percent(b.interactions, b.visitors)} />
                </Table.Cell>
                <Table.Cell>{formatCount(b.leads)}</Table.Cell>
                <Table.Cell>
                  <PercentCell value={percent(b.leads, totalLeads)} />
                </Table.Cell>

                <Table.Cell>{b.avg_lead_score?.toFixed(1) || '-'}</Table.Cell>
                <Table.Cell>
                  <PercentCell value={percent(b.leads, b.visitors)} />
                </Table.Cell>
                <Table.Cell>
                  <PercentCell value={percent(b.leads, b.interactions)} />
                </Table.Cell>
                <Table.Cell>
                  <PercentCell value={percent(b.unqualified, b.leads)} />
                </Table.Cell>
                <Table.Cell>{formatCount(b.tours)}</Table.Cell>
                <Table.Cell>{formatCount(b.moveins)}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={8} textAlign="right">
              <CsvExport data={csvContent} headers={csvHeaders} filename="devices_breakdown.csv" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </PageSection>
  );
};

export default DevicesBreakdown;
