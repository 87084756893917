import React, { createContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { differenceInDays, endOfMonth, format, parse, startOfMonth, subDays } from 'date-fns';
import { getInstantResponsesCommunities, getInstantResponsesUsers } from 'apis';
import { useUrlSearchParams } from 'utils/hooks';

export const DATE_FORMAT = 'yyyy-MM-dd';
const TODAY = format(new Date(), DATE_FORMAT);

const searchParams = new URLSearchParams(window.location.search);
const INITIAL_DATE_RANGE = {
  start_date: searchParams.get('start_date') || TODAY,
  end_date: searchParams.get('end_date') || TODAY,
};

const INITIAL_USERS = (searchParams.get('sa_users') || '')
  .split(',')
  .map((id) => Number.parseInt(id, 10))
  .filter(Number.isFinite);

const INITIAL_COMMUNITIES = (searchParams.get('sa_communities') || '')
  .split(',')
  .map((id) => Number.parseInt(id, 10))
  .filter(Number.isFinite);

const INITIAL_ADDITIONAL_FILTERS = Array.from(searchParams.keys()).reduce((filters, key) => {
  if (['start_date', 'end_date', 'sa_users', 'sa_communities'].indexOf(key) > -1) {
    return filters;
  }
  filters[key] = searchParams.get(key);
  return filters;
}, {});

export const SystemStatsFiltersContext = createContext();

export const SystemStatsFiltersProvider = (props) => {
  const { setUrlSearchParams } = useUrlSearchParams();
  const [dateRange, _setDateRange] = useState(INITIAL_DATE_RANGE);
  const [salesAssistantUsers, setSalesAssistantUsers] = useState();
  const [salesAssistantAllCommunities, setSalesAssistantAllCommunities] = useState([]);
  const [salesAssistantCommunities, setSalesAssistantCommunities] = useState();
  const [selectedUsers, _setSelectedUsers] = useState(INITIAL_USERS);
  const [selectedCommunities, _setSelectedCommunities] = useState(INITIAL_COMMUNITIES);
  const [additionalFilters, setAdditionalFilters] = useState(INITIAL_ADDITIONAL_FILTERS);

  const fetchSalesAssistantUsers = async () => {
    try {
      const users = await getInstantResponsesUsers(dateRange);
      setSalesAssistantUsers(users.map((u) => ({ ...u, name: u.email })));
    } catch (e) {
      toast.error('Error fetching sales assistant users');
    }
  };

  if (!salesAssistantUsers) {
    setSalesAssistantUsers([]);
    fetchSalesAssistantUsers();
  }

  const fetchSalesAssistantCommunities = async () => {
    try {
      const communities = await getInstantResponsesCommunities(dateRange);
      setSalesAssistantAllCommunities(communities);
      if (selectedUsers.length) {
        setSalesAssistantCommunities(communities.filter((c) => selectedUsers.indexOf(c.user_id) > -1));
      } else {
        setSalesAssistantCommunities(communities);
      }
    } catch (e) {
      toast.error('Error fetching sales assistant communities');
    }
  };

  if (!salesAssistantCommunities) {
    setSalesAssistantCommunities([]);
    fetchSalesAssistantCommunities();
  }

  const setDateRange = ({ startDate: start_date, endDate: end_date }) => {
    _setDateRange({ start_date, end_date });
    setUrlSearchParams({ start_date, end_date });
  };

  const setSelectedUsers = (sa_users) => {
    _setSelectedUsers(sa_users);
    setUrlSearchParams({ sa_users }, true);
    // filter available/selected communities to match selected user
    if (sa_users && sa_users.length) {
      const userCommunities = salesAssistantAllCommunities.filter((c) => sa_users.indexOf(c.user_id) > -1);
      setSalesAssistantCommunities(userCommunities);
      if (selectedCommunities.length) {
        setSelectedCommunities(selectedCommunities.filter((id) => userCommunities.find((c) => c.id === id)));
      }
    } else {
      setSalesAssistantCommunities(salesAssistantAllCommunities);
    }
  };

  const setSelectedCommunities = (sa_communities) => {
    _setSelectedCommunities(sa_communities);
    setUrlSearchParams({ sa_communities }, true);
  };

  const getPreviousDateRange = () => {
    const start = parse(dateRange.start_date, DATE_FORMAT, new Date());
    const end = parse(dateRange.end_date, DATE_FORMAT, new Date());
    const span = differenceInDays(end, start) + 1;
    return {
      start_date: format(subDays(start, span), DATE_FORMAT),
      end_date: format(subDays(start, 1), DATE_FORMAT),
      day_span: span,
    };
  };

  const getExtendedDateRange = () => {
    let start = startOfMonth(parse(dateRange.start_date, DATE_FORMAT, new Date()));
    const startSpan = differenceInDays(new Date(), start);
    // ensure at least 7 days of data
    if (startSpan < 7) {
      start = subDays(start, 7 - startSpan);
    }
    const end = endOfMonth(parse(dateRange.end_date, DATE_FORMAT, new Date()));
    const span = differenceInDays(end, start) + 1;
    return {
      start_date: format(start, DATE_FORMAT),
      end_date: format(end, DATE_FORMAT),
      day_span: span,
    };
  };

  const setAdditionalFilter = (key, value) => {
    const strVal = typeof value !== 'undefined' ? String(value) : undefined;
    setAdditionalFilters({ ...additionalFilters, [key]: strVal });
    setUrlSearchParams({ [key]: value }, true);
  };

  const automatedAnswerFilters = useMemo(() => {
    const filters = { ...dateRange };
    if (selectedUsers && selectedUsers.length) {
      filters.sa_users = selectedUsers.join(',');
    }
    if (selectedCommunities && selectedCommunities.length) {
      filters.sa_communities = selectedCommunities.join(',');
    }
    if (additionalFilters.first_message === 'true') {
      filters.first_message = true;
    }
    if (additionalFilters.automated_only === 'true') {
      filters.automated_only = true;
    }
    if (additionalFilters.suggested_labels) {
      filters.suggested_labels = additionalFilters.suggested_labels;
    }
    if (additionalFilters.used_labels) {
      filters.used_labels = additionalFilters.used_labels;
    }
    if (additionalFilters.sender_ids) {
      filters.sender_ids = additionalFilters.sender_ids;
    }
    if (additionalFilters.sender_types) {
      filters.sender_types = additionalFilters.sender_types;
    }
    if (additionalFilters.ma_statuses) {
      filters.ma_statuses = additionalFilters.ma_statuses;
    }
    if (Number.isInteger(parseInt(additionalFilters.score_min, 10))) {
      filters.score_min = additionalFilters.score_min;
    }
    if (Number.isInteger(parseInt(additionalFilters.score_max, 10))) {
      filters.score_max = additionalFilters.score_max;
    }
    return filters;
  }, [dateRange, selectedUsers, selectedCommunities, additionalFilters]);

  const reviewFilters = useMemo(() => {
    const filters = { ...automatedAnswerFilters };
    if (additionalFilters.reviewers) {
      filters.reviewers = additionalFilters.reviewers;
    }
    if (additionalFilters.review__suggested_labels) {
      filters.review__suggested_labels = additionalFilters.review__suggested_labels;
    }
    if (additionalFilters.ma_statuses) {
      filters.ma_statuses = additionalFilters.ma_statuses;
    }
    return filters;
  }, [automatedAnswerFilters, additionalFilters]);

  const saleAssistantFilters = useMemo(() => {
    const filters = { ...dateRange };
    if (selectedUsers && selectedUsers.length) {
      filters.sa_users = selectedUsers.join(',');
    }
    if (selectedCommunities && selectedCommunities.length) {
      filters.sa_communities = selectedCommunities.join(',');
    }
    if (additionalFilters.sender_ids) {
      filters.sender_ids = additionalFilters.sender_ids;
    }
    return filters;
  }, [dateRange, selectedUsers, selectedCommunities, additionalFilters]);

  const provide = {
    dateRange,
    setDateRange,
    getPreviousDateRange,
    getExtendedDateRange,
    salesAssistantUsers,
    selectedUsers,
    setSelectedUsers,
    salesAssistantCommunities,
    selectedCommunities,
    setSelectedCommunities,
    additionalFilters,
    setAdditionalFilter,
    automatedAnswerFilters,
    reviewFilters,
    saleAssistantFilters,
  };

  return <SystemStatsFiltersContext.Provider value={provide} {...props} />;
};

export const useSystemStatsFilters = () => {
  const context = React.useContext(SystemStatsFiltersContext);
  if (context === undefined) {
    throw new Error('useSystemStatsFilters must be used within a SystemStatsFiltersProvider');
  }
  return context;
};
