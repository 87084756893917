import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { NavigationItem } from './NavigationItem';

const Wrapper = styled.div`
  .navlink {
    backgoround-color: red;
    justify-content: space-between;
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
    padding-right: 0.75rem;
  }
`;

const Title = styled(NavigationItem.Text)`
  margin: 0;
  line-height: 1;
`;

const Subtitle = styled.div`
  font-size: 0.714rem;
  font-weight: 500;
  color: ${(p) => (p.color ? p.color : 'var(--highlight-blue)')};
  line-height: 1;
`;

const Count = styled(NavigationItem.Text)`
  position: relative;
  top: 0.1rem;
  text-align: right;
  justify-self: flex-end;
  font-size: 1.2rem;
  line-height: 1;
`;

const IconContainer = styled.div`
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
`;

const TitleWrapper = styled.div`
  margin-left: 11px;
`;

export const TasksNavigationItem = ({ to, title, subtitle, count, newTasksIndicatorVisible }) => {
  return (
    <Wrapper>
      <NavigationItem to={to}>
        {newTasksIndicatorVisible && (
          <IconContainer>
            <Icon name="fiber_manual_record" color="var(--highlight-blue)" size="10" />
          </IconContainer>
        )}

        {count === 0 && (
          <IconContainer>
            <Icon name="check" color="var(--success)" size="16" />
          </IconContainer>
        )}
        <TitleWrapper>
          <Title>{title}</Title>
          {count > 0 && <Subtitle>{subtitle}</Subtitle>}
          {count === 0 && <Subtitle color="var(--light-green)">All done!</Subtitle>}
        </TitleWrapper>
        {count > 0 && <Count>{count}</Count>}
      </NavigationItem>
    </Wrapper>
  );
};
