import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TabsWithNavigation } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { EmailAddresses } from './EmailAddresses';
import { CommunityAssignment } from './CommunityAssignment';

export const MarketingEmailRoot = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const MARKETING_EMAIL_TABS = [
    {
      tabName: 'Email Addresses',
      path: '/advanced/marketing-email/email-addresses',
      Content: EmailAddresses,
    },
    {
      tabName: `${capitalize(t('community'))} Assignment`,
      path: '/advanced/marketing-email/community-assignment',
      Content: CommunityAssignment,
    },
  ];

  const INITIAL_TAB = MARKETING_EMAIL_TABS[0].path;

  useEffect(() => {
    if (location.pathname === '/advanced/marketing-email') {
      history.replace(INITIAL_TAB);
    }
  }, []);

  return <TabsWithNavigation tabs={MARKETING_EMAIL_TABS} />;
};
