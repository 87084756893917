export const CALENDAR_REQUEST_TYPE = {
  TOUR: 'tour',
  CALL: 'call',
};

export const CALENDAR_REQUEST_TYPE_NAME = (t) => ({
  [CALENDAR_REQUEST_TYPE.TOUR]: t('tour'),
  [CALENDAR_REQUEST_TYPE.CALL]: 'call',
});

export const CALENDAR_REQUEST_STATUS = {
  REQUESTED: 'requested',
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
};

export const CALENDAR_MAX_NOTE_LENGTH = 1000;
