import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Radio, TextArea, Checkbox, Icon } from 'components/lib';
import humps from 'humps';

import { If } from 'components/If';
import { STATUSES, BANNER_DEFAULT_START_TIME } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';

const ModalBody = styled.div``;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 10px;
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  > span {
    margin-left: 10px;
  }
`;

const CustomDateInput = styled.div`
  position: relative;
  display: inline-block;
  input[type='date'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 24px;
    width: 125px;
    height: 36px;
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 0 10px;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;

const CustomTimeInput = styled.div`
  position: relative;
  display: inline-block;
  input[type='time'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 24px;
    width: 100px;
    height: 36px;
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 0 10px;
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #89919d;
  }
`;

const RadioText = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
`;

const RadioTextTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--text);
  margin-bottom: 5px;
`;

const TextAreaField = styled(Form.Field)`
  display: flex;
  textarea {
    width: 100%;
    height: 102px;
    margin: 38px 0 10px 10px;
    padding: 8px 12px !important;
    border: solid 1px #d7d7d7 !important;
    background-color: #f8f8f8 !important;
    color: black;

    &::placeholder {
      color: #89919d;
    }
  }
  label {
    margin: 45px 1px 103px 0px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    color: var(--text);
    letter-spacing: normal;
    font-stretch: normal;
  }
`;

const TimeInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  position: relative;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 26px;
`;

export const VSABannerModal = ({ isModalOpen, selectedItem, onSave, onClose, totalItemsCount }) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [isEndsOnDate, setIsEndsOnDate] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);
    const expiresAt = values.bannerActive && isEndsOnDate ? new Date(`${values.endDate}T${values.endTime}`) : null;

    const payload = {
      banner: humps.decamelizeKeys({
        isEnabled: values.bannerActive,
        content: values.bannerText.trim() !== '' ? values.bannerText : null,
        expiresAt: expiresAt ? expiresAt.toISOString() : null,
      }),
    };

    await onSave(payload);

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;

  const validationSchema = Yup.object({
    bannerText: Yup.string().when('bannerActive', {
      is: true,
      then: Yup.string().required('Text is required when banner is ON'),
    }),
    endDate: Yup.string().when(['bannerActive', 'isEndsOnDate'], {
      is: (bannerActive, isEndsOnDate) => bannerActive && isEndsOnDate,
      then: Yup.string().required('End date is required when banner is ON and Ends on specific date is checked'),
    }),
    endTime: Yup.string().when(['bannerActive', 'isEndsOnDate'], {
      is: (bannerActive, isEndsOnDate) => bannerActive && isEndsOnDate,
      then: Yup.string().required('End time is required when banner is ON and Ends on specific date is checked'),
    }),
  });

  const initialValues = {
    bannerText: (selectedItem && selectedItem.banner && selectedItem.banner.content) || '',
    bannerActive: selectedItem?.banner?.is_enabled,
    endDate:
      selectedItem && selectedItem.banner && selectedItem.banner.expires_at
        ? new Date(selectedItem.banner.expires_at).toISOString().split('T')[0]
        : new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split('T')[0],
    endTime:
      selectedItem && selectedItem.banner && selectedItem.banner.expires_at
        ? new Date(selectedItem.banner.expires_at).toLocaleTimeString('en-GB', { hour12: false }).substring(0, 5)
        : BANNER_DEFAULT_START_TIME,
    isEndsOnDate: (selectedItem && selectedItem.banner && selectedItem.banner.expires_at) || false,
  };

  useEffect(() => {
    setIsEndsOnDate(initialValues.isEndsOnDate);
  }, [initialValues.isEndsOnDate]);

  const handleDateChange = (value, setFieldValue) => {
    setFieldValue('endDate', value);
  };

  const handleTimeChange = (value, setFieldValue) => {
    setFieldValue('endTime', value);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue, isValid }) => {
        const isBannerActive = Boolean(values.bannerActive);
        const isSaveDisabled =
          (!dirty && !touched.bannerActive) ||
          (isBannerActive && !values.bannerText.trim()) ||
          (!isValid && isEndsOnDate);

        return (
          <SettingsModal
            loading={loading}
            open={isModalOpen}
            onModalClose={() => handleClose(resetForm)}
            size="tiny"
            width={540}
          >
            <SettingsModalHeader
              title="VSA Banner"
              community={selectedItem || null}
              totalItemsCount={totalItemsCount}
              type="header"
            />
            <ModalBody type="body">
              <RadioText>
                <RadioTextTitle>Banner Activation</RadioTextTitle>
                Add a banner to show above the Virtual Sales Assistant and specify its content and duration.
              </RadioText>
              <RadioWrapper>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name="bannerActive"
                    value={true}
                    checked={isBannerActive}
                    onChange={() => {
                      setFieldValue('bannerActive', true);
                    }}
                  />
                  <span>On</span>
                  <If condition={isBannerActive}>
                    <CheckboxWrapper>
                      <Form.Field
                        control={Checkbox}
                        name="isEndsOnDate"
                        checked={isEndsOnDate}
                        onChange={(e, { checked }) => {
                          setIsEndsOnDate(checked);
                          setFieldValue('isEndsOnDate', checked);
                          if (checked) {
                            const nextWeek = new Date();
                            nextWeek.setDate(nextWeek.getDate() + 7);
                            setFieldValue('endDate', nextWeek.toISOString().split('T')[0]);
                            setFieldValue('endTime', BANNER_DEFAULT_START_TIME);
                          } else {
                            setFieldValue('endDate', '');
                            setFieldValue('endTime', '');
                          }
                        }}
                      />
                      Ends on specific date
                      <If condition={isEndsOnDate}>
                        <>
                          <Form.Field>
                            <CustomDateInput>
                              <input
                                type="date"
                                name="endDate"
                                value={values.endDate}
                                onChange={(e) => handleDateChange(e.target.value, setFieldValue)}
                              />
                            </CustomDateInput>
                          </Form.Field>
                          <Form.Field>
                            <TimeInputContainer>
                              <CustomTimeInput>
                                <input
                                  type="time"
                                  name="endTime"
                                  value={values.endTime}
                                  onChange={(e) => handleTimeChange(e.target.value, setFieldValue)}
                                />
                                <Icon name="expand_more" size="22" />
                              </CustomTimeInput>
                            </TimeInputContainer>
                          </Form.Field>
                        </>
                      </If>
                    </CheckboxWrapper>
                  </If>
                </CustomRadio>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name="bannerActive"
                    value={false}
                    checked={values.bannerActive === false}
                    onChange={() => {
                      setFieldValue('bannerActive', false);
                      setIsEndsOnDate(false);
                    }}
                  />
                  <span>Off</span>
                </CustomRadio>
              </RadioWrapper>
              <TextAreaField
                control={TextArea}
                name="bannerText"
                label="Text"
                value={values.bannerText}
                placeholder="Enter the text to appear in the banner."
                onChange={(e, { value }) => {
                  setFieldValue('bannerText', value);
                }}
              />
            </ModalBody>
            <SettingsModalActions
              onSave={handleSubmit}
              onCancel={() => handleClose(resetForm)}
              type="actions"
              disabled={isSaveDisabled}
              saveText="Save"
            />
          </SettingsModal>
        );
      }}
    </Formik>
  );
};
