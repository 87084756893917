import React from 'react';
import styled from 'styled-components';

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `${props?.columnWidths.map((width) => width).join(' ')};`};
  grid-column-gap: ${(props) => props?.columnGap};
`;

export const SettingsTableRowContainWithMultipleColumns = ({ children, columnWidths, columnGap = '0' }) => {
  return (
    <RowWrapper columnWidths={columnWidths} columnGap={columnGap}>
      {children}
    </RowWrapper>
  );
};
