import styled from 'styled-components';
import { MarkButtonWrapper, Wrapper } from './ModuleHeader';

export const ModuleWrapper = styled.div`
  position: relative;
  border: 1px solid #f0f0f0;
  padding: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 0.1rem 0.15rem 0 rgba(0, 0, 0, 0.4);
  width: 25rem;

  &:hover {
    > ${Wrapper} {
      ${MarkButtonWrapper} {
        visibility: visible;
      }
    }
  }
`;
