import React, { useContext } from 'react';
import { List, Divider } from 'semantic-ui-react';
import { ImageBox } from '../ImageBox';
import { CommunityAssetsContext } from '../CommunityAssetsContext';

export const UserCommunityScrapeResults = () => {
  const { scrapeResults } = useContext(CommunityAssetsContext);

  return (
    <>
      <Divider section horizontal>
        Results
      </Divider>
      <List celled size="large">
        <List.Item>
          <List.Header>Addresses</List.Header>
          <List.List>
            {scrapeResults?.addresses?.map((address, index) => (
              <List.Item key={index}>
                {address.zip} {address.state} {address.city} {address.address}
              </List.Item>
            ))}
          </List.List>
        </List.Item>
        <List.Item>
          <List.Header>Phones</List.Header>
          <List.List>
            {scrapeResults?.phones?.map((phone) => (
              <List.Item key={phone}>{phone}</List.Item>
            ))}
          </List.List>
        </List.Item>
        <List.Item>
          <List.Header>Floor plans</List.Header>
          <List.List>
            {scrapeResults?.plans.map((url, index) => (
              <List.Item key={index}>
                <a href={url}>{url}</a>
              </List.Item>
            ))}
          </List.List>
        </List.Item>
        <List.Item>
          <List.Header>Jobs</List.Header>
          <List.List>
            {scrapeResults?.jobs.map((url, index) => (
              <List.Item key={index}>
                <a href={url}>{url}</a>
              </List.Item>
            ))}
          </List.List>
        </List.Item>
        <List.Item>
          <List.Header>Galleries</List.Header>
          <List.List>
            {scrapeResults?.galleries.map((url, index) => (
              <List.Item key={index}>
                <a href={url}>{url}</a>
              </List.Item>
            ))}
          </List.List>
        </List.Item>
        <List.Item>
          <List.Header>Images</List.Header>
          <List.List>
            <ImageBox size="tiny" images={scrapeResults?.images} />
          </List.List>
        </List.Item>
      </List>
    </>
  );
};
