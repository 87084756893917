import React from 'react';
import styled from 'styled-components';

import { ConversationsDateRangeFilter } from './ConversationsDateRangeFilter';
import { ConversationsSearch } from './ConversationsSearch';
import { ConversationsDownload } from './ConversationsDownload';
import { ConversationsDropdownFilters } from './ConversationsDropdownFilters';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 10px;
  flex-wrap: wrap;
`;

const SearchWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: min(500px, 80%);
  height: 36px;
`;

export const ConversationsControls = () => {
  return (
    <Wrapper>
      <Row>
        <ConversationsDateRangeFilter />
        <SearchWrapper>
          <ConversationsDownload />
          <ConversationsSearch />
        </SearchWrapper>
      </Row>
      <ConversationsDropdownFilters />
    </Wrapper>
  );
};
