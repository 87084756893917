import styled from 'styled-components';

import { MEDIA_BREAK_POINTS } from '../../../constants';

export const OperationSign = styled.div`
  flex-grow: 0 !important;
  padding: 0 5px;
  max-height: 35px;

  display: flex;
  align-items: center;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    width: 55px;
    max-height: 35px;
  }
`;

export const OperationBox = styled.div`
  display: flex;

  & > div {
    flex-grow: 1;
    padding: 0 5px;
  }

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    & > div {
      flex-grow: 1;
      padding: 0;
      margin: 0;
    }
  }
`;
