import React, { useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { resendEmailVerificationCode } from 'apis';
import { Icon, Input, Button } from 'components/lib';
import { InputField } from 'components/common';
import { If } from 'components/If';
import { OnboardingContext } from '../OnboardingContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  height: fit-content;
  flex: 1 0 auto;
`;

const IconContainer = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 10px;
  background-color: #ffebf5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h3`
  font-family: var(--font-family-bold);
  font-size: 60px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.97;
  letter-spacing: normal;
  text-align: center;
  color: #222;
  margin: 30px 0;
`;

const Subtitle = styled.p`
  font-family: var(--font-family);
  font-size: 16px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #222;
  margin: 0;
  .mail {
    color: #1f58b0;
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
  margin-top: 30px;
  & > label {
    flex: 1 0 auto;
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: 1.14;
    text-align: right;
    color: #222;
    margin-right: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100px;
    word-break: keep-all;
  }
`;

const StyledInput = styled(Input)`
  &&&& {
    input {
      width: 100% !important;
      max-width: 360px !important;
    }
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
`;

const Mesage = styled.p`
  font-family: var(--font-family);
  font-size: 14px;
  margin: 30px 0;
`;

const initialFormValues = {
  email: '',
};

const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().required('Please enter a valid email address').email('Please enter a valid email address'),
});

export const EmailVerificationFailed = () => {
  const { onboardingUuid } = useContext(OnboardingContext);
  const [submitted, setSubmitted] = useState(false);

  const handleResendLink = async (values) => {
    setSubmitted(true);
    try {
      await resendEmailVerificationCode({ onboardingUuid, email: values?.email });
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <Wrapper>
      <IconContainer>
        <Icon name="error_outline" color="#FF5B98" size="56" />
      </IconContainer>
      <Title>Something went wrong</Title>
      <Subtitle>
        Something is not right with your verification link. Perhaps it expired. <br />
        Please enter the email you signed up with to get another verification link.
        <br /> If this persists, please contact{' '}
        <a className="mail" href="mailto:support@talkfurther.com">
          support@talkfurther.com
        </a>
        .
      </Subtitle>

      <Formik initialValues={initialFormValues} onSubmit={handleResendLink} validationSchema={VALIDATION_SCHEMA}>
        {({ handleSubmit, isSubmitting }) => (
          <>
            <If condition={!submitted}>
              <StyledForm onSubmit={handleSubmit}>
                <InputContainer>
                  <label>Email</label>
                  <InputField
                    autoComplete="off"
                    control={StyledInput}
                    fluid
                    type="text"
                    name="email"
                    placeholder="Enter the email address you signed up with"
                    newError
                  />
                </InputContainer>
                <Button size="big" fluid primary color="blue" type="submit" loading={isSubmitting}>
                  Resend Verification Email
                </Button>
              </StyledForm>
            </If>
            <If condition={submitted}>
              <Mesage>If your address is recognized, a new verification email will be dispatched to your inbox.</Mesage>
            </If>
          </>
        )}
      </Formik>
    </Wrapper>
  );
};
