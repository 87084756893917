import React, { useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

import { validateMappings, captureWebFormLead, createWebForm, updateWebFormMappingCandidate } from 'apis';
import { Form } from 'components/lib';
import { guessMappingTarget, guessParseType, guessIgnored, sorted } from './utils';
import { PreviewPanel } from './PreviewPanel';
import { FieldsTable } from './FieldsTable';

const StyledForm = styled(Form)`
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-top: 2rem;

  table {
    max-width: 1200px;
  }

  .flags {
    display: flex;
    align-items: center;
    gap: 3ch;
    margin: 1.5rem 0;
    color: #777;

    .field {
      margin: 0;
    }
  }
`;

const cleanupMappings = (mappings) => {
  return mappings
    .filter((m) => !m.ignored)
    .map((m) => {
      const mapped = {
        field_name: m.field_name,
        map_to: m.map_to,
        parse_as: m.parse_as,
        required: m.required,
      };

      if (m.map_to === 'extra_information' && m.parse_as === 'json' && m.json_path) {
        mapped.map_to += `.${m.json_path}`;
      }

      return mapped;
    });
};

export const MappingForm = ({ mappingCandidate, fetchMappingCandidate }) => {
  const history = useHistory();
  const [leadPreview, setLeadPreview] = useState();
  const [lead, setLead] = useState();

  const fields = mappingCandidate ? Object.values(mappingCandidate.form.fields) : [];

  const mappings = fields.map((field, i) => {
    const mappingTarget = guessMappingTarget(field);
    const parseType = guessParseType(field, mappingTarget);
    const ignored = guessIgnored(field, mappingTarget, parseType);

    return {
      index: i,
      field_name: field.name,
      map_to: mappingTarget,
      parse_as: parseType,
      required: !!field.required && !ignored, // TODO make sure field.requried is included in WebFormSubmitEvent.extra_data
      ignored,
    };
  });

  const initialValues = {
    mappings: sorted(mappings),
  };

  const validationSchema = Yup.object().shape({
    mappings: Yup.array().of(
      Yup.object().shape({
        map_to: Yup.string()
          .ensure()
          .when('ignored', {
            is: false,
            then: Yup.string().required('Required'),
          }),
        parse_as: Yup.string()
          .ensure()
          .when('ignored', {
            is: false,
            then: Yup.string().required('Required'),
          }),
      })
    ),
  });

  const validate = async (values) => {
    const mappings = cleanupMappings(values.mappings);
    const response = await validateMappings(mappingCandidate.id, { mappings });
    setLeadPreview(response.lead_info);
    return response.errors;
  };

  const submit = async (values) => {
    try {
      const mappings = cleanupMappings(values.mappings);
      const payload = {
        mapping_candidate_id: mappingCandidate.id,
        community_id: mappingCandidate.community_id,
        url_location: mappingCandidate.form.url_location,
        query_selector: mappingCandidate.form.query_selector,
        enable: true,
        mappings,
      };

      await createWebForm(payload);
      const mappingCandidateReloaded = await fetchMappingCandidate();
      toast.success(`Mapping candidate linked to WebForm: ${mappingCandidateReloaded.mapped_to_hash}`);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const generateLead = async () => {
    try {
      const lead = await captureWebFormLead({
        event: mappingCandidate.event,
        web_form_hash: mappingCandidate.mapped_to_hash,
      });
      setLead(lead);
      toast.success(`Lead generated: ${lead.id}`);
    } catch (error) {
      const errorMessage = error?.response?.data ? Object.values(error.response.data).join(', ') : '';
      console.error('error', errorMessage);
      toast.error(`Failed to generate lead: ${errorMessage}`);
    }
  };

  const ignoreMappingCandidate = async () => {
    try {
      await updateWebFormMappingCandidate(mappingCandidate.id, {
        ignored: true,
      });
      toast.success('Mapping candidate ignored');
      history.push('/admin/web-forms/mapping-candidates/');
    } catch (error) {
      console.error(error);
      toast.error('Failed to ignore mapping candidate');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validationSchema={validationSchema}
      validateOnBlur={true}
      onSubmit={submit}
      enableReinitialize
    >
      {() =>
        mappingCandidate && (
          <StyledForm id="mapping-candidate-form">
            <FieldsTable mappingCandidate={mappingCandidate} />
            <PreviewPanel
              mappingCandidate={mappingCandidate}
              leadPreview={leadPreview}
              lead={lead}
              forceSubmit={submit}
              generateLead={generateLead}
              ignoreMappingCandidate={ignoreMappingCandidate}
            />
          </StyledForm>
        )
      }
    </Formik>
  );
};
