import { formatAnyDate } from 'components/utils';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { Accordion, Icon } from '../../lib';
import { ConversationContext } from '../ConversationContext';
import { CallDetails } from './CallDetails';

const StyledAccordion = styled(Accordion)`
  &&&&& {
    border-top: none;
  }
`;

const Title = styled(Accordion.Title)`
  &&&.title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
    padding: 6px 30px 6px 4px;
    .answer {
      color: ${({ $is_call_answered }) => ($is_call_answered ? 'var(--emerald-green)' : 'var(--salmon)')};
      font-family: Avenir;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 14px;
      letter-spacing: normal;
      text-align: right;
      margin-left: auto;
    }
    .material-icons {
      rotate: ${({ active }) => (active ? '0' : '-90deg')};
    }
  }
`;

export const CallsPanels = () => {
  const { selectedConversation } = useContext(ConversationContext);

  const { phone_calls } = selectedConversation || {};

  const panels = phone_calls.map((call, index) => ({
    key: index,
    title: (
      <Title $is_call_answered={call.is_call_answered}>
        <Icon name="arrow_drop_down" />
        Call from {formatAnyDate(call.created_on, 'MMM dd yyyy, h:mm a')}
        <span className="answer">{call.is_call_answered ? 'Answered' : 'No Answer '}</span>
      </Title>
    ),
    content: {
      content: <CallDetails call={call} />,
    },
  }));

  return <StyledAccordion fluid defaultActiveIndex={[0]} panels={panels} exclusive={false} />;
};
