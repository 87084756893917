import { PAGINATION } from '../../constants';

export const USER_ROLES = ['regional', 'member', 'owner', 'labeling'];

export const USER_TYPES = ['Pilot', 'Demo', 'Paying Customer'];

export const DEFAULT_FILTER = {
  page: PAGINATION.PAGE,
  pageSize: PAGINATION.PAGE_SIZE,
  search: '',
  role: '',
  type: '',
};
