import React from 'react';
import styled from 'styled-components';

import { Tab } from 'components/lib';
import { useAnalytics } from '../context/AnalyticsContext';
import Callers from './Callers';
import CallsGraph from './CallsGraph';
import CommunityBreakdown from './CommunityBreakdown';
import Leads from './Leads';
import Totals from './Totals';

const Metrics = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  margin: 30px 0 16px 0;

  > div {
    position: relative;
  }

  .callers {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: right;
  }
`;

const CallsTab = () => {
  const [{ filters, publicId, report }] = useAnalytics();
  // channels filter is not supported for calls
  const callerFilters = { ...filters, channels: null };

  return (
    <Tab.Pane>
      <Metrics>
        <div className="totals">
          <Totals filters={callerFilters} publicId={publicId} />
        </div>
        <div className="leads">
          <Leads filters={callerFilters} publicId={publicId} />
        </div>
        <div className="callers">
          <Callers filters={callerFilters} publicId={publicId} />
        </div>
      </Metrics>
      <CallsGraph filters={callerFilters} publicId={publicId} />
      <CommunityBreakdown
        filters={callerFilters}
        publicId={publicId}
        limit={report?.communities?.length}
        communities={report?.communities}
      />
    </Tab.Pane>
  );
};

export default CallsTab;
