import React from 'react';
import styled from 'styled-components';
import { formatAnyDate } from 'components/utils';
import { utcToZonedTime } from 'date-fns-tz';
import { If } from 'components/If';

const BoldReminderDate = styled.span`
  font-family: var(--font-family-bold);
`;

const ReminderContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ConversationReminderSet = ({ lead }) => {
  if (!lead) {
    return null;
  }

  const displayDate = lead.reminder.scheduled_at;
  const displayDateValid = !!displayDate;
  const displayDateZoned = utcToZonedTime(displayDate, lead.community?.timezone || 'UTC');
  const formattedDate = displayDateValid && formatAnyDate(displayDateZoned, "MMM d yyyy 'at' h:mm a");

  return (
    <ReminderContainer>
      <If condition={displayDateValid}>
        <BoldReminderDate>{formattedDate}</BoldReminderDate>
      </If>
      <If condition={!displayDateValid}>-</If>
    </ReminderContainer>
  );
};

export default ConversationReminderSet;
