import { USER_TYPES } from 'components/UserContext';

export const campaignRow = ({ campaign, totalCommunitiesCount, isUserType, statistics, t, invalidCampaignIds }) => {
  const isMessagingAssistantActiveWithError =
    campaign?.relatedCommunitiesWithoutMessagingAssistant > 0 && campaign?.useNurtureConversationSupport;
  const messagingAssistantText = isMessagingAssistantActiveWithError
    ? `Messaging Assistant active, but disabled for some of the ${t('communities')} in this campaign.`
    : 'Messaging Assistant active';
  const communitiesCount = isUserType([USER_TYPES.REGIONAL])
    ? campaign?.relatedCommunitiesCount
    : totalCommunitiesCount;
  const isAllCommunities = communitiesCount === campaign?.relatedCommunitiesCount && communitiesCount > 0;
  const matchingStatisticsData = statistics?.find((statistic) => statistic.id === campaign.id);
  const invalid = invalidCampaignIds?.includes(campaign.id);
  return {
    ...campaign,
    isMessagingAssistantActiveWithError,
    messagingAssistantText,
    communitiesCount,
    isAllCommunities,
    matchingStatisticsData,
    invalid,
  };
};

export const sortCampaignMessages = (messages) => {
  return messages?.sort((m1, m2) => {
    const m1Days = m1.days || 0;
    const m2Days = m2.days || 0;
    const m1Hours = m1.hours || 0;
    const m2Hours = m2.hours || 0;
    const m1Minutes = m1.minutes || 0;
    const m2Minutes = m2.minutes || 0;
    const m1Time = m1.isNegativeTime
      ? -1 * (m1Days * 24 * 60 + m1Hours * 60 + m1Minutes)
      : m1Days * 24 * 60 + m1Hours * 60 + m1Minutes;
    const m2Time = m2.isNegativeTime
      ? -1 * (m2Days * 24 * 60 + m2Hours * 60 + m2Minutes)
      : m2Days * 24 * 60 + m2Hours * 60 + m2Minutes;
    return m1Time - m2Time;
  });
};

export function toAmPmHours(hours) {
  if (hours === 0) return 'Midnight';
  return hours < 12 ? `${hours} AM` : `${hours % 12 || 12} PM`;
}
