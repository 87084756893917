import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 312px;
  padding: 0 30px 30px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

export const StepsContent = ({ children }) => {
  return <Container>{children}</Container>;
};
