import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const EVSA_SCRIPT = process.env.REACT_APP_EVSA_SCRIPT || 'https://evsa.talkfurther.com/embedded-vsa.js';

const TeaserPreviewContainer = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  background: #f0f0f0;
  padding: 2rem;
  border-radius: 10px;
`;

const Note = styled.div`
  color: #666;
  margin-top: 1rem;
  margin-bottom: 4rem;
`;

export const EmbeddedVSAPreview = ({ chat, teaser, theme, customCss }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!chat) {
      return;
    }

    let evsaScript = document.querySelector('script[src="http://localhost:3100/embedded-vsa.js"]');

    if (!evsaScript) {
      evsaScript = document.createElement('script');
      evsaScript.src = EVSA_SCRIPT;
      // evsaScript.src = 'http://localhost:3100/embedded-vsa.js';
      document.body.appendChild(evsaScript);
    }
  }, [chat]);

  useEffect(() => {
    window.FurtherEmbeddedVSA?.setTheme(theme);
  }, [theme]);

  if (!chat) {
    return <Wrapper>Loading...</Wrapper>;
  }

  const propId = chat.chat_properties[0]?.id || 0;

  if (!propId) {
    return <Wrapper>Can't preview a Teaser for a VSA that has no related {t('communities')}</Wrapper>;
  }

  const propInfo = {
    id: propId,
    vsa_type: 'embedded',
    show: true,
    strict_chat_url: false,
    teaser_placements: [
      {
        query_selector: '#evsa-teaser-preview-container',
        replace_container_content: true,
        css_theme: theme,
        custom_css: customCss,
      },
    ],
  };

  return (
    <>
      <Wrapper>
        <TeaserPreviewContainer id="evsa-teaser-preview-container"></TeaserPreviewContainer>
        <further-embedded-vsa property-info={JSON.stringify(propInfo)}></further-embedded-vsa>
      </Wrapper>
      <Note>
        <b>Please note:</b> The styling of the teaser might be affected by Custom CSS defined in either settings or
        related {t('communities')}.
      </Note>
    </>
  );
};
