import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Loader, Tab } from 'components/lib';
import { scrollMainContent } from 'components/utils';
import { useGroupedAnswers, IntentsList, PlaceholderList } from 'components/common/automated_answers';
import { EditIntent } from './EditIntent';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const DetailView = styled.div`
  flex: 1;
  padding: 0 1.5rem;
`;

const RightPanel = styled.div`
  max-width: 20rem;
`;

const Placeholders = styled.div`
  padding: 14px;
`;

const ManageAnswersTab = ({ answerTypes = 'ANSWER,CUSTOM' }) => {
  const { isLoading, groups, intents } = useGroupedAnswers({ type__in: answerTypes });
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);
  const intent = query.get('intent');
  const history = useHistory();

  useEffect(() => history.listen(() => scrollMainContent()), [history]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <IntentsList
        groups={groups}
        intents={intents}
        selectedIntent={intent}
        onSelectIntent={(intent) => {
          history.push({
            pathname: location.pathname,
            search: `intent=${intent}`,
          });
        }}
      />
      <DetailView>
        {intent ? (
          <EditIntent
            intent={intent}
            group={groups[intent]}
            onCancel={() => {
              history.push(`/admin/answers`);
            }}
            key={intent}
          />
        ) : (
          <p>Select label on the left to get started.</p>
        )}
      </DetailView>
      <RightPanel>
        <Tab
          showBorder
          panes={[
            {
              menuItem: 'Placeholders',
              render: () => (
                <Tab.Pane>
                  <Placeholders>
                    <PlaceholderList />
                  </Placeholders>
                </Tab.Pane>
              ),
            },
          ]}
        />
      </RightPanel>
    </Container>
  );
};

export default ManageAnswersTab;
