import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { format, startOfToday } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import styled from 'styled-components';

import { calendarAvailability } from 'apis';
import { CalendarPicker, Loader } from 'components/lib';
import { If } from 'components/If';
import { formatAnyDate } from 'components/utils';
import { STATUSES } from 'constants';
import { MessageContext } from '../MessageContext';
import { CalendarCreateTour } from './CalendarCreateTour';
import { CALENDAR_REQUEST_TYPE } from './constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SlotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  gap: 15px;
  padding: 10px;
  align-items: center;
`;
const Slot = styled.div`
  border: solid 1px #d6d6d7;
  background-color: var(--white);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
`;

const DayDisplay = styled.span`
  font-family: var(--font-family-bold);
  font-size: 16px;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #d6d6d7;
  width: 100%;
  padding-bottom: 5px;
`;
const SlotsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  padding: 10px;
`;

export const Calendar = ({ type }) => {
  const { lead } = useContext(MessageContext);
  const [selectedSlot, setSelectedSlot] = useState(undefined);
  const [availabilityStatus, setAvailabilityStatus] = useState(STATUSES.IDLE);
  const [selectedDate, setSelectedDate] = useState(startOfToday());
  const [hasTimeSlots, setHasTimeSlots] = useState(false);
  const [slots, setSlots] = useState([]);

  const fetchAvailability = async () => {
    try {
      setAvailabilityStatus(STATUSES.LOADING);

      const { timezone } = lead?.community;

      const { availability } = await calendarAvailability(lead?.community.id, {
        date: format(selectedDate, 'yyyy-MM-dd'),
        days_offset: 1,
      });

      const selectedDateKey = format(selectedDate, 'MM/dd/yyyy');

      if (selectedDateKey in availability && availability[selectedDateKey].length > 0) {
        // Awkward conversion to a zoned Date() object, just to stay backwards compatible with the rest of the code
        const availableDaySlots = availability[selectedDateKey]
          .filter((slot) => slot.available)
          .map((slot) => ({
            ...slot,
            start: utcToZonedTime(slot.start_utc, timezone),
          }));
        setSlots(availableDaySlots);
        setHasTimeSlots(availableDaySlots.length > 0);
      } else {
        setHasTimeSlots(false);
      }

      setAvailabilityStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error('Unable to fetch calendar availability!');
      setAvailabilityStatus(STATUSES.FAILURE);
    }
  };

  useEffect(() => {
    getSlots();
  }, [selectedDate]);

  const getSlots = () => {
    fetchAvailability();
  };

  const onSlotSelected = (slot) => {
    setSelectedSlot(slot);
  };

  const isLoading = availabilityStatus === STATUSES.LOADING;
  const color = type === CALENDAR_REQUEST_TYPE.TOUR ? 'var(--link)' : 'var(--emerald-green)';

  return (
    <Container>
      <CalendarCreateTour type={type} slot={selectedSlot} onCloseModal={() => setSelectedSlot(undefined)} />
      <CalendarPicker color={color} small onChange={setSelectedDate} selectedDate={selectedDate} minDate={new Date()} />
      <SlotsWrapper>
        <Loader active={isLoading} />
        <If condition={!isLoading}>
          <DayDisplay>{formatAnyDate(selectedDate, 'MMM dd, yyyy')}</DayDisplay>
          <SlotsContainer>
            <If condition={hasTimeSlots}>
              {slots.map((slot, i) => (
                <Slot key={i} onClick={() => onSlotSelected(slot)}>
                  <span>{slot.labels.time}</span>
                </Slot>
              ))}
            </If>
            <If condition={!hasTimeSlots}>
              <div>No available time slots</div>
            </If>
          </SlotsContainer>
        </If>
      </SlotsWrapper>
    </Container>
  );
};
