import React, { useState } from 'react';
import { Button, Dimmer, Icon, Loader, Message, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { importPricing } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { UploadDropzone } from 'components/common/upload/UploadDropzone';
import { STATUSES } from '../../../constants';

export const ImportPricingDialog = ({ importDialogOpen, onDialogClose }) => {
  const [csvFile, setCsvFile] = useState(null);
  const [importing, setImporting] = useState(STATUSES.IDLE);

  const onImportFiles = async () => {
    setImporting(STATUSES.LOADING);
    try {
      const data = new FormData();
      data.append('file', csvFile);
      const response = await importPricing(data);
      setImporting(STATUSES.LOADED);
      onDialogClose(true);
      toast.success(`Successfully imported ${response?.imported_records || ''} pricing rows.`);
    } catch (error) {
      setImporting(STATUSES.FAILURE);
      const msg = 'Trouble uploading pricing file!';
      console.error(msg, error?.response);
      toast.error(parseErrorResponse(error, msg));
    }
  };

  return (
    <Modal open={importDialogOpen} onClose={onDialogClose} closeOnDimmerClick={importing !== STATUSES.LOADING}>
      <Modal.Header>Import Pricing</Modal.Header>
      <Dimmer active={importing === STATUSES.LOADING} inverted>
        <Loader inverted>Importing...</Loader>
      </Dimmer>
      <Modal.Content>
        <UploadDropzone onSelected={(files) => setCsvFile(files[0])} buttonLabel="Select CSV File" />
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onDialogClose}>
          Cancel
        </Button>
        <Button
          content="Import"
          labelPosition="right"
          icon="cloud upload"
          onClick={onImportFiles}
          positive
          disabled={!csvFile}
        />
      </Modal.Actions>
    </Modal>
  );
};
