import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Modal, Button, Grid, Form } from 'semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { CheckboxField } from 'components/common';
import { InputField } from 'components/common/fields/InputField';
import { capitalize } from 'utils/helpers';
import { ANALYTICS_TABS } from '../constants';
import { STATUSES } from '../../../constants';

const FieldContainer = styled.div`
  .field {
    margin-bottom: 0;
  }
`;

const validationSchema = Yup.object({
  recipient_email: Yup.string().email('Must be a valid email address').required('Mail is required'),
});

const SendAsPDFModal = ({ user, loading, open, onClose, onSendAsPDF }) => {
  const { t } = useTranslation();
  const initialValues = {
    [ANALYTICS_TABS.OVERVIEW]: true,
    [ANALYTICS_TABS.INSIGHTS]: true,
    [ANALYTICS_TABS.LEAD_VALUE_SCORE]: true,
    [ANALYTICS_TABS.KEYWORDS]: true,
    [ANALYTICS_TABS.INSTANT_ANSWERS]: true,
    [ANALYTICS_TABS.BENCHMARKS]: true,
    recipient_email: user?.email,
  };

  const isLoading = loading && loading === STATUSES.LOADING;

  const handleSubmit = async (values, { resetForm }) => {
    await onSendAsPDF(values);
    resetForm();
  };

  const handleClose = (resetForm) => () => {
    resetForm();
    onClose();
  };

  const validateForm = (values) => {
    const errors = {};
    const checkedValues = Object.values(values)
      .filter((value) => typeof value === 'boolean')
      .filter((value) => !!value);

    if (checkedValues.length === 0) {
      Object.values(ANALYTICS_TABS).forEach((tab) => {
        errors[tab] = 'At least one tab has to be selected.';
      });
    }

    return errors;
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validate={validateForm}
    >
      {({ handleSubmit, resetForm, isValid }) => (
        <Modal size="mini" open={open} onClose={handleClose(resetForm)} closeOnDimmerClick={!isLoading}>
          <Modal.Header>Send as PDF</Modal.Header>
          <Modal.Content>
            <Form loading={isLoading}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div>Send report to:</div>
                    <InputField name="recipient_email" placeholder="Recipient email" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                  <Grid.Column width={16} stretched>
                    <div>Select tabs you would like to export:</div>
                    <FieldContainer>
                      <CheckboxField name={ANALYTICS_TABS.OVERVIEW} label="Overview" />
                    </FieldContainer>
                    <FieldContainer>
                      <CheckboxField name={ANALYTICS_TABS.INSIGHTS} label="Insights" />
                    </FieldContainer>
                    <FieldContainer>
                      <CheckboxField
                        name={ANALYTICS_TABS.LEAD_VALUE_SCORE}
                        label={`${capitalize(t('move_ins'))} Prediction`}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <CheckboxField name={ANALYTICS_TABS.KEYWORDS} label="Keywords" />
                    </FieldContainer>
                    <FieldContainer>
                      <CheckboxField name={ANALYTICS_TABS.INSTANT_ANSWERS} label="Instant Answers Analytics" />
                    </FieldContainer>
                    <FieldContainer>
                      <CheckboxField name={ANALYTICS_TABS.BENCHMARKS} label="Benchmarks Beta" />
                    </FieldContainer>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleClose(resetForm)} disabled={isLoading}>
              Cancel
            </Button>
            <Button content="Send" primary type="submit" disabled={!isValid || isLoading} onClick={handleSubmit} />
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

export default SendAsPDFModal;
