import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Icon } from 'semantic-ui-react';

const BackLink = ({ as = 'h4', to, label }) => (
  <Header as={as} color="blue">
    <Link to={to}>
      <Icon name="arrow left" color="blue" />
      {label}
    </Link>
  </Header>
);

export default BackLink;
