import { API } from '../api';

export const getSEOAnalysis = async (analysisId) => {
  const response = await API.get(`/api/seo-analysis/${analysisId}`);
  return response.data;
};

export const listSEOAnalyses = async (communityId) => {
  const response = await API.get(`/api/seo-analysis/${communityId}/list`);
  return response.data;
};

export const runSEOAnalysis = async (communityId) => {
  const response = await API.post(`/api/seo-analysis/${communityId}/analyze`);
  return response.data;
};
