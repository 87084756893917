import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import queryConstants from 'utils/queries/constants';
import {
  acceptReview,
  assignMoreReviews,
  bulkOverrideAnswer,
  declineReview,
  getAnswerOverrides,
  getAnswers,
  getAssignedReviews,
  resetOverrides,
} from 'apis';
import { toast } from 'react-toastify';

export const useAnswers = (params) =>
  useQuery([queryConstants.INSTANT_RESPONSES_ANSWERS, params], () => getAnswers(params));

export const useAnswerOverrides = ({ community, enabled, ...params }) =>
  useQuery(
    [queryConstants.INSTANT_RESPONSES_ANSWERS, 'overrides', community, params],
    () => {
      return getAnswerOverrides(community, params);
    },
    { enabled }
  );

export const useResetOverrides = (queryClient) =>
  useMutation({
    mutationFn: (payload) => {
      return resetOverrides(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryConstants.INSTANT_RESPONSES_ANSWERS);
    },
  });

export const useGroupedAnswers = (params) => {
  const { data, isLoading } = useAnswers(params);
  const groups = useMemo(() => groupAnswersByIntent(data || []), [data]);
  const intents = useMemo(() => Object.keys(groups).sort(), [groups]);
  return { answers: data, isLoading, groups, intents };
};

const groupAnswersByIntent = (answers) =>
  answers.reduce(
    (acc, answer) => ({
      ...acc,
      [answer.intent]: [...(acc[answer.intent] || []), answer],
    }),
    {}
  );

export const useAssignedReviews = () => {
  const queryClient = useQueryClient();
  const queryKey = [queryConstants.INSTANT_RESPONSES_REVIEWS];
  const { data: reviews, isLoading, isFetching } = useQuery(queryKey, () => getAssignedReviews());

  // optimistic update for accepting reviews
  const { mutateAsync: handleAccept } = useMutation({
    mutationFn: acceptReview,
    onMutate: async ({ automated_answer_id }) => {
      await queryClient.cancelQueries({ queryKey });
      queryClient.setQueryData(queryKey, (old) => old.filter((r) => automated_answer_id !== r.automated_answer_id));
    },
    onError: (err, newTodo, context) => {
      queryClient.invalidateQueries({ queryKey });
      toast.error('There was an unknown server error');
    },
  });

  // handle getting new reviews
  const { mutateAsync: handleNewSession, isLoading: isLoadingNewSession } = useMutation({
    mutationFn: assignMoreReviews,
    onError: () => {
      toast.error('There was an unknown server error');
    },
    onSuccess: (hasAssigned) => {
      if (!hasAssigned) {
        toast.warn('There are no more messages to review. Please try later.');
      }
      return hasAssigned;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  // early update when declining reviews
  const { mutateAsync: handleDecline } = useMutation({
    mutationFn: ({ review, values }) => declineReview(review, values),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });
    },
    onSuccess: async ({ automated_answer_id }) => {
      queryClient.setQueryData(queryKey, (old) => old.filter((r) => automated_answer_id !== r.automated_answer_id));
    },
    onSettled: (err, newTodo, context) => {
      queryClient.invalidateQueries({ queryKey });
    },
    onError: () => {
      toast.error('There was an unknown server error');
    },
  });

  return { reviews, isLoading, handleAccept, handleDecline, handleNewSession, isLoadingNewSession, isFetching };
};
