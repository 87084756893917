export const NODE_TYPES = {
  FIRST_STEP: 'FIRST_STEP',
  STEP: 'STEP',
  LAST_STEP: 'LAST_STEP',
  BRANCHING_POINT: 'BRANCHING_POINT',
};

export const ACTION_TYPES = {
  CLOSE: 'CLOSE',
  STEP: 'STEP',
  BRANCH: 'BRANCH',
  JUMP: 'JUMP',
  BRANCH_JUMP: 'BRANCH_JUMP',
};

class Node {
  module = null;

  graph = null;

  constructor(module, graph) {
    this.module = module;
    this.graph = graph;
  }

  get number() {
    return this.module.number;
  }

  get text() {
    return this.module.text;
  }

  get degree() {
    return [...new Set(this.module.answers.map((a) => a.action))].length;
  }

  get parentNode() {
    return this.graph.spanningTree.edges
      .filter((e) => e[1] === this.number)
      .map((e) => this.graph.getNode(e[0]))
      .pop();
  }

  get parentNodeAnswer() {
    return this.parentNode?.module.answers.find((a) => a.action === this.number);
  }

  get adjecent() {
    if (
      this.module.module_type !== 'multi_option' &&
      this.module.answers.length > 0 &&
      this.module.answers.some((a) => a.action !== null)
    ) {
      return this.module.answers.reduce((edges, answer) => {
        if (answer.action && this.graph.moduleExists(answer.action)) {
          edges.push(answer.action);
        }
        return edges;
      }, []);
    }
    if (this.module.action && this.graph.moduleExists(this.module.action)) {
      return [this.module.action];
    }
    return [];
  }

  get outgoing() {
    return this.adjecent.map((adj) => [this.number, adj]);
  }

  get reachable() {
    return !this.graph._unreachableNodes.includes(this);
  }

  get type() {
    // Root node should always be a branching point because that's how we
    // greet users when they first open the chat window (i.e. by showing
    // common options)
    if (this.isBranchingPoint() || (this.isRootNode() && this.module.module_type === 'option'))
      return NODE_TYPES.BRANCHING_POINT;

    if (this.isFirstStep()) return NODE_TYPES.FIRST_STEP;
    if (this.isLastStep()) return NODE_TYPES.LAST_STEP;

    return NODE_TYPES.STEP;
  }

  isRootNode() {
    return this.graph?.rootNode?.number === this.number;
  }

  isRootNodeOption() {
    return this.graph?.rootNode?.number === this.number && this;
  }

  isFirstStep() {
    return [ACTION_TYPES.BRANCH, ACTION_TYPES.BRANCH_JUMP].includes(this.parentNodeAnswer?.meta?.actionType);
  }

  isLastStep() {
    return !this.isBranchingPoint() && this.adjecent.every((adj) => !this.graph.edgeIsInSpanningTree(this.number, adj));
  }

  isOnlyStep() {
    return this.isFirstStep() && this.isLastStep();
  }

  isRegularStep() {
    return !(this.isFirstStep() || this.isLastStep() || this.isBranchingPoint());
  }

  isBranchingPoint() {
    return (
      this.degree > 1 &&
      this.module.answers
        .map((a) => a.meta?.actionType)
        .every((actionType) => [ACTION_TYPES.BRANCH, ACTION_TYPES.BRANCH_JUMP, ACTION_TYPES.CLOSE].includes(actionType))
    );
  }

  rendersButtons() {
    return ['option', 'care_option', 'locations', 'click'].includes(this.module.module_type);
  }

  augmentAnswers() {
    const actionIsClose = (action) => action === null;
    const actionIsLoop = (action) => action === this.number;
    const actionIsStep = (action) =>
      !actionIsLoop(action) &&
      (action === 0 || this.degree === 1 || this.adjecent.filter((adj) => adj === action).length > 1);
    const actionIsBranchJump = (action) =>
      // If the (module.number, action) pair is not in the graph's spanning tree, we show
      // a "Jump to module {action}" button in the UI.
      !actionIsLoop(action) && this.degree > 1 && !this.graph.edgeIsInSpanningTree(this.number, action);
    const getActionType = (action) => {
      if (actionIsClose(action)) return ACTION_TYPES.CLOSE;
      if (actionIsStep(action)) return ACTION_TYPES.STEP;
      if (actionIsBranchJump(action)) return ACTION_TYPES.BRANCH_JUMP;
      return ACTION_TYPES.BRANCH;
    };

    this.module.answers.forEach((a) => {
      a.meta = {
        actionType: getActionType(a.action),
      };
    });
  }

  child(action) {
    // Module Graph is a directed cyclic graph. All dialog flows usually end with:
    //    1. an action to jump back to the root module (beginning of the conversation)
    //    2. an action to close the chat window
    // If we just rendered all module actions as child Nodes (either branches or steps) in
    // the chat editor, it would lead to a recursively nested UI where brances can be expanded
    // forever. Instead, we only render module nodes which are contained in the graph's
    // spanning tree (rooted at module.number == 1).
    const children = this.graph.spanningTree.edges.filter((e) => e[0] === this.number).map((e) => e[1]);
    const isBranchingPoint =
      this.isRootNode() ||
      this.module.answers.find((a) => a.action === action && a.meta?.actionType === ACTION_TYPES.BRANCH);
    if (isBranchingPoint && children.includes(action)) {
      return this.graph.getNode(action);
    }
  }

  getNext() {
    const type = this.type;

    if (type === NODE_TYPES.FIRST_STEP || type === NODE_TYPES.STEP) {
      if (this.rendersButtons()) {
        const targetsInSpanningTree = this.graph.spanningTree.edges
          .filter((e) => e[0] === this.number)
          .map((e) => e[1]);
        const nextActions = [
          ...new Set(this.module.answers.filter((a) => a.meta?.actionType === ACTION_TYPES.STEP).map((a) => a.action)),
        ];
        if (nextActions.length === 1 && targetsInSpanningTree.includes(nextActions[0])) {
          return this.graph.getNode(nextActions[0]);
        }
      } else if (this.graph.edgeIsInSpanningTree(this.number, this.module.action)) {
        return this.graph.getNode(this.module.action);
      }
    }
  }

  getJumpNumber() {
    if (this.isLastStep() || this.isOnlyStep()) {
      return this.adjecent[0];
    }
  }

  linkChild(childModuleNumber, parentAnswer) {
    if (parentAnswer) {
      parentAnswer.action = childModuleNumber;
    } else {
      this.module.answers.push({
        text: 'New Option',
        rank: this.module.answers.length,
        action: childModuleNumber,
      });
    }
  }

  linkNext(nextModuleNumber) {
    if (this.module.module_type === 'option') {
      const uniqueAdjecent = [...new Set(this.adjecent)];

      // A transitive branch is a module action which goes off into a separate dialog branch,
      // but then merges back into the same dialog flow as its sibling actions. It's like
      // going on a tangent in a conversation, but then returning to the main point.
      // In this case, we want to show such actions as branching points, while treating other
      // actions as steps (just because it works better on the UI side)
      const transitiveBranches = [];
      uniqueAdjecent.forEach((u) => {
        uniqueAdjecent.forEach((v) => {
          if (u !== v && this.graph.reachable(u, v)) {
            transitiveBranches.push(u);
          }
        });
      });

      let oldNextModuleNumber;
      this.module.answers
        .filter((a) => a.meta?.actionType === ACTION_TYPES.STEP)
        .forEach((a) => {
          oldNextModuleNumber = a.action;
          a.action = nextModuleNumber;
        });

      if (transitiveBranches.length > 0) {
        const findAnswerInDescendants = (root, action) => {
          if (root && action) {
            const answer = root.module.answers.find((a) => a.action === action);
            if (answer) return answer;

            for (let i = 0; i < root.adjecent.length; i++) {
              const answer = findAnswerInDescendants(this.graph.getNode(root.adjecent[i]), action);
              if (answer) return answer;
            }
          }
        };

        transitiveBranches.forEach((t) => {
          const transitiveMergePoint = findAnswerInDescendants(this.graph.getNode(t), oldNextModuleNumber);
          if (transitiveMergePoint) {
            transitiveMergePoint.action = nextModuleNumber;
          }
        });
      }
    } else {
      this.module.action = nextModuleNumber;
    }
  }

  replaceAction(originalNumber, futureNumber) {
    this.module.answers.filter((a) => a.action === originalNumber).map((a) => (a.action = futureNumber));

    if (this.module.action === originalNumber) {
      this.module.action = futureNumber;
    }
  }

  deleteAction(number) {
    this.module.answers = this.module.answers.filter((a) => a.action !== number);
    if (this.module.action === number) {
      this.module.action = null;
    }
  }

  moveUp() {
    this.parentNode?.moveDown();
  }

  moveDown() {
    const next = this.getNext();
    if (this.isFirstStep()) {
      this.parentNodeAnswer.action = next?.number;
    } else {
      this.parentNode?.linkNext(next?.number);
    }
    this.linkNext(next?.getNext()?.number);
    next?.linkNext(this.number);
  }

  toJSON() {
    return {
      module: this.module,
    };
  }
}

export class ModuleGraph {
  _nodes = {};

  _spanningTree = {
    nodes: [],
    edges: [],
  };

  _transitiveClosure = {};

  _unreachableNodes = [];

  constructor(modules) {
    this.setModules(modules);
    this.cleanupDanglingActions();
  }

  setModules(modules) {
    this._nodes = {};
    modules.forEach((m) => {
      this._nodes[m.number] = new Node(m, this);
    });
    this.updateGraphMetadata();
  }

  updateGraphMetadata() {
    if (this.rootNode) {
      this.constructSpanningTree();
      this.constructTransitiveClosure();
      this.augmentModuleMetadata();
      this.findUnreachableNodes();
    }
  }

  getNode(number) {
    return this._nodes[number];
  }

  get rootNode() {
    return this._nodes[this.lowestModuleNumber];
  }

  get nodes() {
    return Object.values(this._nodes);
  }

  get edges() {
    return this.nodes.flatMap((n) => n.outgoing);
  }

  get modules() {
    return this.nodes.map((n) => n.module);
  }

  get spanningTree() {
    return this._spanningTree;
  }

  get lowestModuleNumber() {
    return Math.min(...this.nodes.map((n) => n.module.number));
  }

  get highestModuleNumber() {
    return Math.max(...this.nodes.map((n) => n.module.number));
  }

  get unreachableNodes() {
    return this._unreachableNodes;
  }

  moduleExists(number) {
    return !!this._nodes[number];
  }

  nodeIsInSpanningTree(n) {
    return this.spanningTree.nodes.includes(n);
  }

  edgeIsInSpanningTree(n1, n2) {
    return this.spanningTree.edges.some((e) => e[0] === n1 && e[1] === n2);
  }

  findUnreachableNodes() {
    this._unreachableNodes = [];
    const nodeNumbers = this.nodes.map((node) => node.number);
    nodeNumbers
      .filter((nodeNumber) => nodeNumber !== this.rootNode.number)
      .forEach((nodeNumber) => {
        const reachable = nodeNumbers
          .filter((otherNodeNumber) => otherNodeNumber !== nodeNumber)
          .some((otherNodeNumber) => this.reachable(otherNodeNumber, nodeNumber));

        if (!reachable) {
          this._unreachableNodes.push(this.getNode(nodeNumber));
        }
      });
  }

  getPathModules(startModule, endModule) {
    if (!this.reachable(startModule.number, endModule.number)) return;

    const segmentModules = [];
    const startNode = this._nodes[startModule.number];
    let currentNode = this._nodes[endModule.number];

    while (currentNode !== startNode) {
      segmentModules.push(currentNode.module);
      currentNode = currentNode.parentNode;
    }

    segmentModules.push(startNode.module);
    return segmentModules;
  }

  addModule(module, parent, parentAnswer) {
    const number = module.number ? module.number : this.highestModuleNumber + 1;
    this._nodes[number] = new Node({ ...module, number }, this);
    if (parent && this._nodes[parent.number]) {
      if (parentAnswer && parentAnswer.meta?.actionType === ACTION_TYPES.BRANCH) {
        this._nodes[parent.number].linkChild(number, parentAnswer);
      } else {
        this._nodes[parent.number].linkNext(number);
      }
    }

    this.updateGraphMetadata();
  }

  updateModule(module) {
    if (this._nodes[module?.number]) {
      this._nodes[module.number] = new Node(module, this);
    }

    this.updateGraphMetadata();
  }

  deleteModule(number, deleteRelated) {
    const deleteNode = (number, deleteRelated) => {
      if (deleteRelated) {
        const adjecentInTree = this.spanningTree.edges.filter((e) => e[0] === number).map((e) => e[1]);
        adjecentInTree.forEach((adj) => {
          deleteNode(adj, deleteRelated);
        });
      }

      const node = this._nodes[number];

      // Delete target node
      delete this._nodes[number];

      if (!deleteRelated && node.type !== NODE_TYPES.BRANCHING_POINT) {
        // Try to merge remaining nodes in path
        const nextNode = node.getNext();

        if (!nextNode) return;

        this.nodes.forEach((n) => {
          n.replaceAction(number, nextNode.number);
        });
      } else {
        // Delete actions leading to deleted node
        this.nodes.forEach((n) => {
          n.deleteAction(number);
        });
      }
    };

    if (this._nodes[number]) {
      deleteNode(number, deleteRelated);

      this.updateGraphMetadata();
    }
  }

  reachable(n1, n2) {
    return this._transitiveClosure[n1] && !!this._transitiveClosure[n1][n2];
  }

  constructSpanningTree() {
    const nodes = [];
    const edges = [];

    const bfs = (node) => {
      const queue = [node];
      const visited = [node.number];
      while (queue.length > 0) {
        const v = queue.shift();
        nodes.push(v.number);
        v.adjecent.forEach((adjecentNodeNumber) => {
          if (!visited.includes(adjecentNodeNumber)) {
            const w = this.getNode(adjecentNodeNumber);
            if (w) {
              edges.push([v.number, adjecentNodeNumber]);
              queue.push(w);
              visited.push(adjecentNodeNumber);
            }
          }
        });
      }
    };

    if (this.rootNode) {
      bfs(this.rootNode);
    }

    this._spanningTree = { nodes, edges };
  }

  constructTransitiveClosure() {
    const dfs = (adjList, tc, root, descendant) => {
      adjList[descendant].forEach((adjecent) => {
        if (adjecent !== this.rootNode.number && !tc[root][adjecent]) {
          tc[root][adjecent] = true;
          dfs(adjList, tc, root, adjecent);
        }
      });
    };

    const adjList = {};
    const tc = {};

    this.nodes.forEach((u) => {
      adjList[u.number] = u.adjecent;
      tc[u.number] = {};
    });

    Object.keys(tc).forEach((k) => {
      tc[k][k] = true;
      dfs(adjList, tc, k, k);
    });

    this._transitiveClosure = tc;
  }

  getDisconnectedNodes() {
    return this.nodes.map((n) => n.number).filter((n) => !this.spanningTree.nodes.includes(n));
  }

  augmentModuleMetadata() {
    this.nodes.forEach((n) => n.augmentAnswers());
  }

  cleanupDanglingActions() {
    this.modules.forEach((m) => {
      m.answers.forEach((a) => {
        if (m.module_type === 'multi_option') {
          a.action = null;
        } else if (!this.nodes.some((n) => n.number === a.action)) {
          const actionType = a.meta?.actionType;

          // Setting a module's action to be the same as its own number is a convention introduced
          // here in order to be able to differentiate "empty" branches from branches which actually
          // lead to some module. Doesn't have any adverse effects on the Chat app (it just repeats
          // the same chat message)
          if (actionType === ACTION_TYPES.BRANCH) return m.number;

          // Setting action to 0 for module *answers* which don't have an "actual" action
          // is a convention introduced here, to be able to differentiate such answers
          // from the ones which are supposed to just close the chat. Doesn't have any adverse
          // effects on the Chat app (it's just ignored).
          if (actionType === ACTION_TYPES.STEP) return 0;
          return null;
        }
      });
    });
  }

  moveNodeUp(node) {
    node.moveUp();
    this.updateGraphMetadata();
  }

  moveNodeDown(node) {
    node.moveDown();
    this.updateGraphMetadata();
  }

  toJSON() {
    return {
      nodes: this.nodes,
      edges: this.edges,
    };
  }
}
