import React, { useContext } from 'react';

import { ConversationContext } from '../ConversationContext';
import LeadProcessing from './LeadProcessing';

export const ConversationSidebarProcessingTab = () => {
  const { selectedConversation } = useContext(ConversationContext);
  const { id } = selectedConversation;

  return <LeadProcessing leadId={id} />;
};
