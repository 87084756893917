import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'components/UserContext';
import { If } from 'components/If';
import { NavigationSection } from './NavigationSection';
import { NavigationItem } from './NavigationItem';

export const UserNavigationRegular = () => {
  const { user, getSessionOriginalUser } = useContext(UserContext);
  const { t } = useTranslation();
  const originalUser = getSessionOriginalUser();

  return (
    <NavigationSection>
      <If condition={user.show_conversion_dashboard}>
        <NavigationItem to="/overview" label="Overview" icon="data_usage" />
      </If>
      <NavigationItem to="/stats" label="Stats" icon="auto_graph" />
      <NavigationItem to="/chats" label="Web Assistant" icon="view_agenda-outlined" />
      <NavigationItem to="/conversations" label="All Conversations" icon="textsms-outlined" />
      <NavigationItem to="/job-applicants" label="Job Applicants" icon="business_center-outlined" />
      <NavigationItem to="/messaging" label="Messaging" icon="favorite_border" />
      <NavigationItem to="/communities" label={t('communities')} icon="holiday_village" />
      <NavigationItem to="/advanced" label="Settings" icon="tune" />
    </NavigationSection>
  );
};
