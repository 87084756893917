import React from 'react';
import styled from 'styled-components';

import SearchInput from 'components/SearchInput';

const StyledSearchInput = styled(SearchInput)`
  &&&& {
    width: 728px;
    > input {
      background-color: var(--input-field-fill);
    }
  }
`;

export const SearchField = ({ value, onSearch, loading, placeholder }) => (
  <StyledSearchInput value={value} onSearch={onSearch} loading={loading} placeholder={placeholder} />
);
