import { useEffect } from 'react';
import { beamerInitialize } from 'react-beamer';

// On initial user load run Beamer script and fetch available post respecting set segment filters
export const useBeamer = (user) => {
  useEffect(() => {
    if (!process.env.REACT_APP_BEAMER_PRODUCT_ID) return;
    if (!user) return;

    const PAYING_CUSTOMER_FILTER = 'user:paying_customer';
    const segmentFilters = [
      `market_type:${user?.market_type_name}`,
      `type:${user?.type}`,
      ...(user?.paying_customer ? [PAYING_CUSTOMER_FILTER] : []),
    ].join(';');

    beamerInitialize(process.env.REACT_APP_BEAMER_PRODUCT_ID, false, {
      button: false,
      selector: '.beamerButton',
      filter: segmentFilters,
      top: -4,
      right: -4,
      user_firstname: user.first_name,
      user_lastname: user.last_name,
      user_email: user.email,
      user_id: user.id,
    });
  }, [user]);
};
