import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Input, Menu } from 'components/lib';
import { Intent } from '../../admin/ManageAnswers/Intent';
import { If } from '../../If';
import { capitalize } from '../../../utils/helpers';
import SearchInput from '../../SearchInput';

const StyledMenu = styled(Menu)`
  &.ui.vertical.menu {
    border-radius: 0;
    margin-left: -1px;

    .item {
      font-size: 14px;
      padding-left: 20px;
      border-left: 1px solid #d7d7d7;
    }

    .item.active {
      background-color: var(--vivid-blue);
      border-left: 1px solid var(--vivid-blue);
      color: white;
    }

    & > .item:last-child,
    & > .item:first-child {
      border-radius: 0;
    }
  }
`;

const SearchMenuItem = styled(Menu.Item)`
  .ui.vertical.menu > &.item {
    font-family: var(--font-family);
    padding: 10px;
  }
`;

const formatIntentDisplay = (intent) => capitalize(intent.replaceAll('_', ' '), true);

export const IntentsList = ({ groups, overrides, intents, selectedIntent, onSelectIntent, hideSearch, className }) => {
  const [searchText, setSearchText] = useState();
  const visibleIntents = useMemo(
    () =>
      searchText
        ? intents.filter((i) => formatIntentDisplay(i).toLowerCase().includes(searchText.toLowerCase()))
        : intents,
    [searchText, intents]
  );
  const overridenIntents = useMemo(() => {
    const intents = new Set();
    overrides?.forEach((o) => intents.add(o.intent));
    return intents;
  }, [overrides]);
  return (
    <StyledMenu vertical size="large" className={className}>
      <If condition={!hideSearch}>
        <SearchMenuItem>
          <SearchInput placeholder="Search" onChange={(e) => setSearchText(e.target.value)} value={searchText} />
        </SearchMenuItem>
      </If>
      {visibleIntents.map((intent) => (
        <Menu.Item key={intent} name={intent} active={selectedIntent === intent} onClick={() => onSelectIntent(intent)}>
          <Intent accent={overridenIntents.has(intent)}>{formatIntentDisplay(intent)}</Intent>
        </Menu.Item>
      ))}
    </StyledMenu>
  );
};
