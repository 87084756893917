import React from 'react';
import styled from 'styled-components';

import { WithTopBar } from 'components/WithTopBar';
import { ExperimentsProvider } from './context/ExperimentsContext';
import { ExperimentsMain } from './ExperimentsMain';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 30px;
`;

export const ExperimentsRoot = () => {
  return (
    <WithTopBar>
      <ExperimentsProvider>
        <Wrapper>
          <ExperimentsMain />
        </Wrapper>
      </ExperimentsProvider>
    </WithTopBar>
  );
};
