import { SORT_ORDER } from '../../../../constants';
import constants from './constants';

const reducer = (draft, action) => {
  switch (action.type) {
    case constants.SET_LOADER_STATUS:
      draft.loaderStatus = action.payload;
      break;
    case constants.SET_UPDATE_LOADER_STATUS:
      draft.updateLoaderStatus = action.payload;
      break;
    case constants.SET_QUESTIONS: {
      const { results, count } = action.payload;

      draft.questions = results;
      draft.questionsCount = count;
      draft.totalPages = Math.ceil(count / draft.filters.pageSize);
      break;
    }
    case constants.SET_QUESTIONS_COUNT: {
      const { new_questions_count, labeled_questions_count, ignored_questions_count } = action.payload;

      draft.newQuestionsCount = new_questions_count;
      draft.labeledQuestionsCount = labeled_questions_count;
      draft.ignoredQuestionsCount = ignored_questions_count;
      break;
    }
    case constants.SET_ATTRIBUTES:
      draft.attributes = action.payload;
      break;
    case constants.SET_FILTERS: {
      const { filters, forceQuestionsUpdate } = action.payload;

      draft.filters = { ...draft.filters, ...filters };

      if (forceQuestionsUpdate) {
        draft.questions = null;
        draft.totalPages = 0;
      }
      break;
    }
    case constants.RESET_IGNORED_QUESTIONS_COUNT:
      draft.ignoredQuestionsCount = 0;
      break;
    case constants.RESET_LABELED_QUESTIONS_COUNT:
      draft.labeledQuestionsCount = 0;
      break;
    case constants.SET_CURRENT_QUESTION_ID:
      draft.currentQuestionId = action.payload;
      break;
    case constants.UPDATE_QUESTIONS_COUNT: {
      const { newQuestionsCount, labeledQuestionCount, ignoredQuestionsCount } = action.payload;

      draft.newQuestionsCount += newQuestionsCount;
      draft.labeledQuestionsCount += labeledQuestionCount;
      draft.ignoredQuestionsCount += ignoredQuestionsCount;
      break;
    }
    case constants.ADD_ATTRIBUTE:
      draft.attributes.push(action.payload);
      draft.attributes.sort((a, b) => a.text.localeCompare(b.text));
      break;
    case constants.CHANGE_SORT: {
      if (draft.filters.ordering.orderBy === action.payload) {
        draft.filters.ordering.orderAs =
          draft.filters.ordering.orderAs === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
      } else {
        draft.filters.ordering.orderBy = action.payload;
        draft.filters.ordering.orderAs = SORT_ORDER.DESC;
      }

      break;
    }
    default:
      console.warn(`Unhandled action ${JSON.stringify(action)}`);
      break;
  }

  return draft;
};

export default reducer;
