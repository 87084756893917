import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import humps from 'humps';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { formatAnyDate } from 'components/utils';
import { If } from 'components/If';
import { updateUserProfile } from 'apis';
import { parseErrorResponse, parseFieldErrors } from 'apis/utils';
import { Form, Modal as ComponentsModal, Button } from 'components/lib';
import { useUserContext } from 'components/UserContext';
import { MyProfileInputField } from './MyProfileInputField';
import { logOut } from '../../../api';

const Modal = styled(ComponentsModal)`
  && {
    & > .header {
      border-bottom: none;
    }
    &.tiny,
    &.large {
      width: 541px;
      height: 365px;
    }
  }
`;

const HeaderTitle = styled.div`
  font-family: var(--font-family);
  font-weight: 900; // override semantic-ui inherited font-weight: 700;
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const HeaderSubtitle = styled.div`
  font-family: var(--font-family);
  font-weight: 500; // override semantic-ui inherited font-weight: 700;
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  margin-top: 11px;
`;

const Content = styled.div`
  padding: 0 30px 30px 30px;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  border-top: 1px solid var(--line-standard);
  padding: 0 30px;
  margin-top: 28px;
`;

const Date = styled.span`
  color: var(--text);
`;

export const MyProfilePasswordModal = ({ open, onClose, data = {} }) => {
  const [isLoading, setIsLoading] = useState();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { setUser } = useUserContext();

  const handleLogout = async () => {
    try {
      await logOut();
    } catch (e) {
      console.error(e);
    } finally {
      queryClient.clear();
      setUser(null);
      history.push('/login');
    }
  };

  const update = async (values, setErrors) => {
    const formValues = {
      newPassword: values?.newPassword,
      confirmPassword: values?.confirmPassword,
    };
    if (data?.hasPassword) {
      formValues.password = values?.password;
    }
    try {
      setIsLoading(true);
      const response = await updateUserProfile(formValues);
      setIsLoading(false);
      onClose();
      toast.success('Profile updated successfully!');
      handleLogout();
    } catch (e) {
      setErrors(humps.camelizeKeys(parseFieldErrors(e)));
      toast.error(parseErrorResponse(e, 'Unable to update profile data!'));
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object({
    password: Yup.string().when('hasPassword', {
      is: true,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    newPassword: Yup.string().required('Required').min(8, 'Passwords must be at least 8 characters long'),
    confirmPassword: Yup.string()
      .required('Required')
      .min(8, 'Passwords must be at least 8 characters long')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'), // Add this validation
  });

  const initialValues = {
    password: '',
    newPassword: '',
    confirmPassword: '',
    ...data,
  };

  const onSubmit = async (values, { setErrors }) => {
    const data = {
      password: values?.password || null,
      newPassword: values?.newPassword,
      confirmPassword: values?.confirmPassword,
    };
    update(data, setErrors);
  };

  const onCancel = (resetForm) => () => {
    resetForm();
    onClose();
  };
  const title = data?.hasPassword ? 'Change' : 'Create';
  return (
    <Modal open={open} closeIcon size="large" onClose={onClose}>
      <Modal.Header>
        <HeaderTitle>{title} Password</HeaderTitle>
        <HeaderSubtitle>
          {title} your password below.{' '}
          <If condition={data?.hasPassword}>
            <If condition={data?.passwordUpdatedOn}>
              Last updated <Date>{formatAnyDate(data?.passwordUpdatedOn, 'MMM dd yyyy')}.</Date>
            </If>
          </If>
        </HeaderSubtitle>
      </Modal.Header>
      <Modal.Content>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ dirty, handleSubmit, touched, isValid, resetForm, values }) => (
            <Form onSubmit={handleSubmit}>
              <Content>
                <If condition={data?.hasPassword}>
                  <MyProfileInputField
                    label="Old Password"
                    name="password"
                    placeholder="Old Password"
                    width={322}
                    labelPosition={251}
                    value={values.password}
                    validateOnKeyUp
                    type="password"
                  />
                </If>
                <MyProfileInputField
                  label="New Password"
                  name="newPassword"
                  placeholder="New Password"
                  width={322}
                  labelPosition={251}
                  value={values.newPassword}
                  validateOnKeyUp
                  type="password"
                />
                <MyProfileInputField
                  label="Repeat New Password"
                  name="confirmPassword"
                  placeholder="Repeat New Password"
                  width={322}
                  labelPosition={251}
                  value={values.confirmPassword}
                  validateOnKeyUp
                  type="password"
                />
              </Content>
              <Actions>
                <Button
                  primary
                  color="blue"
                  type="submit"
                  disabled={!dirty || !touched || !isValid}
                  loading={isLoading}
                >
                  {title}
                </Button>
                <Button primary type="button" onClick={onCancel(resetForm)}>
                  Cancel
                </Button>
              </Actions>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
