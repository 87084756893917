import React from 'react';
import styled from 'styled-components';
import { BackgroundImage } from './BackgroundImage';
import { Header } from './Header';
import { MainContaner } from './MainContaner';
import { MainContainerTop } from './MainContainerTop';
import { Logo } from './Logo';
import { MyProfileTitle } from './MyProfileTitle';
import { MyProfileTabs } from './MyProfileTabs';
import { MyProfilePersonalTab } from './MyProfilePersonalTab';

const Wrapper = styled.div`
  width: 934px;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 686px;
`;

export const MyProfile = () => {
  return (
    <>
      <BackgroundImage />
      <Wrapper>
        <Header />
        <MainContaner>
          <MainContainerTop>
            <Logo />
            <MyProfileTitle />
            <MyProfileTabs />
          </MainContainerTop>
          <MyProfilePersonalTab />
        </MainContaner>
      </Wrapper>
    </>
  );
};
