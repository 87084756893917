import React from 'react';
import styled, { css } from 'styled-components';

import { Button, Modal as ComponentsModal, Dimmer, Loader } from 'components/lib';
import { If } from 'components/If';

const Modal = styled(ComponentsModal)`
  && {
    & > .header {
      padding: 30px 30px 20px 30px;
      border-bottom: none;
      font-family: var(--font-family);
      font-size: 20px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--text);
    }
    &.tiny,
    &.large {
      width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
      height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
    }
  }
`;

const scrollableCss = css`
  overflow-y: auto;
  max-height: ${({ scrollable }) => scrollable}px;
`;

const Content = styled.div`
  padding: 0 30px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--light-text);
  margin-bottom: 30px;
  ${({ scrollable }) => scrollable && scrollableCss}
`;

export const CampaignModal = ({ children, open, onModalClose, loading, size, width, height, scrollableContent }) => {
  const findChildByType = (type) => {
    return React.Children.toArray(children).find((child) => {
      if (React.isValidElement(child) && child.props.type === type) {
        return child;
      }
      return null;
    });
  };

  const header = findChildByType('header');
  const body = findChildByType('body');
  const actions = findChildByType('actions');

  return (
    <Modal open={open} closeIcon onClose={onModalClose} size={size} width={width} height={height}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Content scrollable={scrollableContent}>
          <Dimmer active={loading} inverted>
            <Loader active size="large" transparent />
          </Dimmer>
          {body}
        </Content>
      </Modal.Content>
      <Modal.Actions>{actions}</Modal.Actions>
    </Modal>
  );
};

export const CampaignModalActions = ({
  onCancel,
  onSave,
  disabled,
  hideUpdate = false,
  loading = false,
  saveText = 'Save',
  saveType = 'button',
  cancelText = 'Cancel',
  children,
}) => (
  <>
    {children}
    <If condition={!hideUpdate}>
      <Button primary loading={loading} color="blue" onClick={onSave} disabled={disabled} type={saveType}>
        {saveText}
      </Button>
    </If>
    <Button primary onClick={onCancel}>
      {cancelText}
    </Button>
  </>
);
