import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;

  thead {
    background-color: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom;

    th {
      border-left: 1px solid #cbcbcb;
      border-bottom-width: 0;
      border-right-width: 0;
      border-top-width: 0;
      font-size: inherit;
      margin: 0;
      overflow: visible;
    }
  }

  th,
  td {
    padding: 0.5em 1em;
  }

  tr {
    td {
      background-color: transparent;
    }

    &.odd {
      td {
        background-color: #f2f2f2;
      }
    }
  }
`;

const Table = ({ children, ...rest }) => <StyledTable {...rest}>{children}</StyledTable>;

export default Table;
