import React, { useContext, useEffect, useState } from 'react';
import { Tab, Menu, Label, Button, Popup } from 'semantic-ui-react';

import { PageHeader, PAGE_HEADER_MARGIN } from 'components/common';
import { UserContext } from 'components/UserContext';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import { STATUSES, USER_TYPES } from '../../../constants';
import { DEFAULT_FILTERS, fetchAttributes, fetchQuestionsCount, setFilters, useQuestionReviews } from './context';
import AttributeCreate from './AttributeCreate';
import NewQuestionsTab from './NewQuestionsTab';
import LabeledQuestionsTab from './LabeledQuestionsTab';
import IgnoredQuestionsTab from './IgnoredQuestionsTab';

const QuestionReviewsTabs = () => {
  const { isUserType } = useContext(UserContext);
  const [openCreateAttribute, setOpenCreateAttribute] = useState(false);
  const [{ newQuestionsCount, labeledQuestionsCount, ignoredQuestionsCount, loaderStatus }, dispatch] =
    useQuestionReviews();

  const toggleOpenCreateAttribute = () => {
    setOpenCreateAttribute((draft) => !draft);
  };

  useEffect(() => {
    fetchQuestionsCount(dispatch);
    fetchAttributes(dispatch);
  }, [dispatch]);

  const panes = [
    {
      menuItem: <Menu.Item key="new">New{!!newQuestionsCount && <Label>{newQuestionsCount}</Label>}</Menu.Item>,
      render: () => <NewQuestionsTab />,
    },
    {
      menuItem: (
        <Menu.Item key="labeled">Labeled{!!labeledQuestionsCount && <Label>{labeledQuestionsCount}</Label>}</Menu.Item>
      ),
      render: () => <LabeledQuestionsTab />,
    },
    {
      menuItem: (
        <Menu.Item key="ignored">Ignored{!!ignoredQuestionsCount && <Label>{ignoredQuestionsCount}</Label>}</Menu.Item>
      ),
      render: () => <IgnoredQuestionsTab />,
    },
  ];

  const handleTabChange = () => {
    dispatch(setFilters(DEFAULT_FILTERS, true));
  };

  return (
    <>
      <PageHeader
        title="Question labeling"
        subtitle="Select labels for the question. Invalid questions can be removed. All labeled questions can be reviewed
            before processing."
        margin={PAGE_HEADER_MARGIN.SMALL}
        actions={
          isUserType(USER_TYPES.STAFF) && (
            <Popup
              inverted
              content="Disabled until we adjust survey imports."
              trigger={
                <div>
                  <Button disabled content="Add new label" onClick={toggleOpenCreateAttribute} />
                </div>
              }
            />
          )
        }
      />

      <Tab panes={panes} onTabChange={handleTabChange} />

      {openCreateAttribute && <AttributeCreate open={openCreateAttribute} onClose={toggleOpenCreateAttribute} />}

      <If condition={loaderStatus === STATUSES.FAILURE}>
        <FailureMessage icon="search" content="Questions could not be loaded" />
      </If>
    </>
  );
};

export default QuestionReviewsTabs;
