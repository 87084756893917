import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Table, Button, Header, Dimmer, Loader, Message, Modal } from 'semantic-ui-react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { parseErrorResponse } from 'apis/utils';
import { attemptReassignCommunityProperty } from 'apis';
import { capitalize } from 'utils/helpers';
import { STATUSES } from '../../../constants';
import { If } from '../../If';
import { FormWrapper } from '../FormWrapper';
import { CommunitiesContext, ACTIONS } from '../../common/communities/CommunitiesContext';
import { VSA_TYPE } from '../constants';

const Row = ({ community, onAssign }) => {
  const { getCommunityChatPropertiesByType } = useContext(CommunitiesContext);

  const chatProperty = useMemo(() => {
    if (!community) return null;

    const properties = getCommunityChatPropertiesByType({ community, vsaType: VSA_TYPE.REGULAR });
    if (properties.length === 0) return null;

    return properties[0];
  }, [community]);

  const handleAssign = () => {
    onAssign(chatProperty);
  };

  return (
    <Table.Row>
      <Table.Cell>{community?.name}</Table.Cell>
      <Table.Cell>
        {chatProperty ? <Link to={`/chats/${chatProperty.chat}`}>{chatProperty.chat}</Link> : 'NONE'}
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button style={{ width: '8rem' }} onClick={handleAssign}>
          {chatProperty ? 'Re-assign' : 'Assign'}
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

const AssignCommunityModal = ({ open, loading, community, chatProperty, onConfirm, onClose }) => {
  const [isLastProperty, setIsLastProperty] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      try {
        const { isLastProperty } = await attemptReassignCommunityProperty(chatProperty.id);

        setIsLastProperty(isLastProperty);
      } catch (e) {
        toast.error(parseErrorResponse(e), 'Unable to get chat details. Please try again');
        setIsLastProperty(false);
      }
    };

    if (chatProperty) {
      fetch();
    } else {
      setIsLastProperty(false);
    }
  }, [chatProperty]);

  if (!community) {
    return null;
  }

  return (
    <Modal size="mini" open={open}>
      <Modal.Content>
        Are you sure you want to assign
        <em>
          <q>{community.name}</q>
        </em>{' '}
        to this chat?
        {isLastProperty && (
          <>
            <br />
            <br />
            <strong>
              You are about to remove the last ${t('community')} assigned to this chat. This action removes the chat as
              well, and it will not be visible to you anymore.
            </strong>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button color="orange" disabled={loading} onClick={onConfirm} loading={loading}>
          {chatProperty ? 'Re-assign' : 'Assign'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const AssignCommunityRegular = () => {
  const { t } = useTranslation();
  const { chatId } = useParams();
  const { state, dispatch, loadCommunitiesForLoggedUser, assignCommunity } = useContext(CommunitiesContext);
  const { unassignedCommunities, status } = state;

  const [openAssignCommunityModal, setOpenAssignCommunityModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [community, setCommunity] = useState();
  const [chatProperty, setChatProperty] = useState();

  const communities = useMemo(
    () => unassignedCommunities?.slice().sort((c1, c2) => c1.name.localeCompare(c2.name)),
    [unassignedCommunities]
  );

  useEffect(() => {
    loadCommunitiesForLoggedUser({ without_chat_id: chatId });
  }, [chatId, dispatch]);

  const handleOpenAssignCommunityModal = (community) => (chatProperty) => {
    setCommunity(community);
    setChatProperty(chatProperty);
    setOpenAssignCommunityModal(true);
  };

  const handleCloseAssignCommunityModal = () => {
    setCommunity(null);
    setChatProperty(null);
    setOpenAssignCommunityModal(false);
  };

  const handleConfirmAssignCommunity = async () => {
    try {
      setLoading(true);

      await assignCommunity(community, chatProperty, chatId);

      dispatch({ type: ACTIONS.REMOVE_UNASSIGNED_COMMUNITIES, payload: { communities: [community] } });
      toast.success(`"${community.name}" successfully linked to chat ${chatId}`);
      handleCloseAssignCommunityModal();
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e, `Unable to link ${community.name} to chat ${chatId}`));
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormWrapper>
      <If condition={status === STATUSES.LOADING}>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </If>

      <If condition={status === STATUSES.LOADED}>
        <Header as="h2">
          <Header.Content>
            Link {capitalize(t('community'))}
            <Header.Subheader>Make this chat visible on the {t('community')} website</Header.Subheader>
          </Header.Content>
        </Header>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Currently linked chat</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Link to chat {chatId}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {communities.map((community) => (
              <Row community={community} key={community.id} onAssign={handleOpenAssignCommunityModal(community)} />
            ))}
          </Table.Body>
        </Table>
      </If>

      <If condition={status === STATUSES.FAILURE}>
        <Message negative>
          <Message.Header>We're sorry we can't fetch your unassigned {t('communities')}</Message.Header>
          <p>Something went wrong and we couldn't load your {t('communities')}. Please try again later.</p>
        </Message>
      </If>

      <AssignCommunityModal
        open={openAssignCommunityModal}
        loading={loading}
        community={community}
        chatProperty={chatProperty}
        onConfirm={handleConfirmAssignCommunity}
        onClose={handleCloseAssignCommunityModal}
      />
    </FormWrapper>
  );
};
