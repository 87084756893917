import React from 'react';

export const Delta = ({ value, formatter = (v) => v }) => {
  let v = value;
  if (typeof value === 'number') {
    // Ensures deltas like "0.001" don't get colored in the UI (since we're only showing
    // 1 decimal place in the UI)
    v = Number(value?.toFixed(1));
  }

  if (v > 0) {
    return <span style={{ color: '#21ba45' }}>+{formatter(value)}</span>;
  }
  if (v < 0) {
    return <span style={{ color: '#db2828' }}>{formatter(value)}</span>;
  }
  return <span>{formatter(v)?.toString()}</span>;
};
