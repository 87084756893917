import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 12px;
  height: 12px;
  padding: 3px 1px 1px 5px;
  border-radius: 1px;
  background-color: #bfd9ff;
`;

const Box = styled.div`
  width: 6px;
  height: 8px;
  border-radius: 1px;
  background-color: #537fc4;
`;

export const Regular = () => (
  <Wrapper>
    <Box />
  </Wrapper>
);
