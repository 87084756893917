import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Form, TextArea, Icon } from 'components/lib';
import { InputField } from 'components/common';
import { StepTitle, StepSubtitle, WizardActions, WizardContent } from '../TemplateWizard/index';
import { MESSAGE_TYPE } from '../constants';
import { useCampaignsTemplate } from '../CampaignsTemplateContext';

const MessagesContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const MessageType = styled.div`
  width: 151px;
  height: 62px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #fff;
  display: flex;
  font-family: var(--font-family);
  font-size: 16px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-right: 20px;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  cursor: pointer;
  &.selected {
    box-shadow: 0 2px 4px 0 rgba(0, 122, 255, 0.2);
    border: solid 1px #73b8ff;
    background-image: linear-gradient(to bottom, #f7fbff, #ebf5ff);
    color: #007aff;
  }
`;

const PromptWrapper = styled.div`
  display: flex;
  height: 254px;
`;

const CustomTextArea = styled(InputField)`
  &&&& {
    textarea {
      width: 560px;
      height: 213px;
      border: solid 1px var(--line-standard);
      background-color: var(--input-field-fill);
      outline: none;
    }
  }
`;

const PromptExamplesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const PromptTitle = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 15px;
  padding-left: 33px;
`;

const PromptExample = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  > span {
    margin-top: -3px;
  }
`;

const PromptText = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  width: 268px;
  margin-left: 10px;
`;

const validationSchema = Yup.object().shape({
  messageType: Yup.string().required('Message type is required'),
});

export const Messages = () => {
  const { state } = useCampaignsTemplate();
  const { wizardValues } = state;

  const initialFormValues = {
    messageType: wizardValues.messageType || '',
    aiPrompt: wizardValues.aiPrompt || '',
  };

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} enableReinitialize>
      {({ setFieldValue, values }) => (
        <Form>
          <WizardContent>
            <StepTitle>What type of messages would you like to send?</StepTitle>
            <StepSubtitle>You can choose email, SMS, or both at the same time for a wider outreach.</StepSubtitle>
            <MessagesContainer>
              {MESSAGE_TYPE.map((messageType) => (
                <MessageType
                  key={messageType.value}
                  className={values.messageType === messageType.value ? 'selected' : ''}
                  onClick={() => setFieldValue('messageType', messageType.value)}
                >
                  {messageType.label}
                </MessageType>
              ))}
            </MessagesContainer>
            <StepTitle>Type an additional prompt for the AI (optional)</StepTitle>
            <StepSubtitle>
              Type a prompt below to fine-tune details regarding your campaigns and the included messages.
            </StepSubtitle>
            <PromptWrapper>
              <CustomTextArea name="aiPrompt" control={TextArea} placeholder="Type a prompt here…" />
              <PromptExamplesWrapper>
                <PromptTitle>Prompt Examples</PromptTitle>
                <PromptExample>
                  <Icon name="format_quote" color="#d7d7d7" size="24" />
                  <PromptText>
                    Include the recipient's name, references to past interactions, and expressions of genuine interest.
                    Use language that conveys empathy and understanding.
                  </PromptText>
                </PromptExample>
                <PromptExample>
                  <Icon name="format_quote" color="#d7d7d7" size="24" />
                  <PromptText>
                    Adopt a conversational tone, integrating friendly language, appropriate humor, and natural flow.
                    Encourage the use of open-ended questions for engaging communication.
                  </PromptText>
                </PromptExample>
              </PromptExamplesWrapper>
            </PromptWrapper>
          </WizardContent>
          <WizardActions disabled={!values.messageType} values={values} />
        </Form>
      )}
    </Formik>
  );
};
