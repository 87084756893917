import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { SCHEDULE_TYPES } from './constants';

const Container = styled.div`
  padding: 10px 20px 6px;
  border-radius: 16px;
  box-shadow: 0 3px 5px 0 rgba(216, 216, 216, 0.85);
  background-color: var(--white);
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 620px;
  p {
    font-family: var(--font-family);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #89919d;
    margin: 0 30px 0 10px;
    span {
      color: var(--text);
    }
  }
`;

const Time = styled.span`
  font-family: var(--font-family);
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: right;
  color: #89919d;
  flex: 1 0 auto;
`;

export const ConversationFloatingReminderAlternativeDatesSent = ({ type, data }) => {
  const { t } = useTranslation();
  const reminderType = SCHEDULE_TYPES(t)[type];

  const { created_on, extra_data, user } = data;
  const numberOfDates = extra_data?.reschedule_dates?.length;
  return (
    <Container>
      <Icon name={reminderType.icon} size={16} color={reminderType.color} />
      <p>
        <span>{user?.name}</span> offered the {t('lead')} {numberOfDates} alternative {type} dates.
      </p>
      <Time>{formatAnyDate(created_on, 'h:mm a')}</Time>
    </Container>
  );
};
