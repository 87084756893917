import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Icon, Message, Pagination, Table } from 'semantic-ui-react';
import { capitalize } from 'utils/helpers';

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > i {
    margin: 6px;
  }
`;

const ViewLink = styled(Link)`
  margin-right: 0.5rem;
`;

const UserAccountTable = ({ users, activePage, totalPages, onPageChange }) => {
  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  if (!users) {
    return null;
  }

  if (users.length === 0) {
    return <Message warning icon="users" content="Users not found" />;
  }

  return (
    <Table selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>First name</Table.HeaderCell>
          <Table.HeaderCell>Last name</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Invited</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Logged in</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {users.map((u) => (
          <Table.Row key={u.id}>
            <Table.Cell>{u.email}</Table.Cell>
            <Table.Cell>{u.first_name}</Table.Cell>
            <Table.Cell>{u.last_name}</Table.Cell>
            <Table.Cell>{capitalize(u.type)}</Table.Cell>
            <Table.Cell textAlign="center">{u.invited && <Icon name="check circle" color="green" />}</Table.Cell>
            <Table.Cell textAlign="center">{u.last_login && <Icon name="check circle" color="green" />}</Table.Cell>
            <Table.Cell>
              <Actions>
                <ViewLink to={`/settings/user-accounts/${u.id}`}>
                  <Icon name="eye" color="blue" link />
                </ViewLink>
                <Link to={`/settings/user-accounts/${u.id}/edit`}>
                  <Icon name="edit" color="green" link />
                </Link>
              </Actions>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      {totalPages > 1 && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="8" textAlign="left">
              <Pagination activePage={activePage} onPageChange={handlePageChange} totalPages={totalPages} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
};

export default UserAccountTable;
