import React, { useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Dimmer, Divider, Button, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { If } from 'components/If';
import { PageHeader } from 'components/common';
import { MessageContext } from './MessageContext';
import { ConversationSMSMessages } from './ConversationSMSMessages';
import { ConversationEmailMessages } from './ConversationEmailMessages';
import { ConversationResponse } from './ConversationResponse';
import { STATUSES, CONVERSATION_TYPE } from '../../../constants';
import CallAction from '../../conversation/CallAction';

const Wrapper = styled.div`
  display: block;

  .conversation-messages {
    min-height: 100px;
  }
`;

export const Conversation = ({ leadId, messageType }) => {
  const { status, lead } = useContext(MessageContext);
  const history = useHistory();

  const changeMessageType = (messageType) => () => {
    history.push(`/admin/instant-responses/${leadId}/${messageType}`);
  };

  const actions = (
    <>
      <If condition={lead?.phone && lead?.community?.enable_outbound_calls}>
        <CallAction lead={lead} />
      </If>
      <Button.Group size="small">
        <Button
          content="SMS"
          primary={messageType === CONVERSATION_TYPE.SMS}
          onClick={changeMessageType(CONVERSATION_TYPE.SMS)}
        />
        <Button
          content="Email"
          primary={messageType === CONVERSATION_TYPE.EMAIL}
          onClick={changeMessageType(CONVERSATION_TYPE.EMAIL)}
        />
      </Button.Group>
    </>
  );

  return (
    <Wrapper>
      <PageHeader
        title="Conversation"
        subtitle={`with ${lead?.name || '...'}`}
        margin={0}
        padding={0}
        actions={actions}
      />
      <Divider />
      <div className="conversation-messages">
        <Switch>
          <Route path="/admin/instant-responses/:leadId/sms/:messageId?" component={ConversationSMSMessages} />
          <Route path="/admin/instant-responses/:leadId/email/:messageId?" component={ConversationEmailMessages} />
        </Switch>
      </div>
      <Divider horizontal section content="Instant Response" />
      <ConversationResponse />
      <Dimmer active={status !== STATUSES.LOADED} inverted>
        <Loader inverted>Loading conversation</Loader>
      </Dimmer>
    </Wrapper>
  );
};
