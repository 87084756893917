import React from 'react';

import { If } from 'components/If';
import { Button } from 'components/lib';

export const SettingsModalActions = ({
  onCancel,
  onSave,
  disabled,
  saveText = 'Save',
  cancelText = 'Cancel',
  hideSave = false,
  children,
}) => {
  return (
    <>
      {children}
      <If condition={!hideSave}>
        <Button primary color="blue" onClick={onSave} disabled={disabled} type="button">
          {saveText}
        </Button>
      </If>
      <Button primary onClick={onCancel}>
        {cancelText}
      </Button>
    </>
  );
};
