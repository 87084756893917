import React from 'react';
import styled from 'styled-components';

const Off = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  text-align: right;
  color: #89919d;
  padding-top: 3px;
  white-space: nowrap;
`;

export const SettingsOff = ({ text = 'Off' }) => {
  return <Off>{text}</Off>;
};
