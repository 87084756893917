import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { format, subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { getOverviewMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';

import { formatCount, formatPercent } from 'components/utils';
import { capitalize } from 'utils/helpers';
import { ConversationContext } from '../ConversationContext';
import { STATUSES } from '../../../constants';
import { If } from '../../If';

const Wrapper = styled.div`
  padding-bottom: 30px;
`;

const StatisticsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 30px;
  border-bottom: solid 1px #eaeaeb;
  font-family: var(--font-family);

  &:nth-child(odd) {
    background-color: #fcfcfc;
  }
`;

const StatisticsRowValue = styled.div`
  font-family: var(--font-family-bold);
`;

export const CommunityStatistics = () => {
  const { t } = useTranslation();
  const { selectedConversation } = useContext(ConversationContext);
  const [status, setStatus] = useState(STATUSES.IDLE);
  const [metrics, setMetrics] = useState();

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        setStatus(STATUSES.LOADING);

        const startDate = format(subDays(new Date(), 30), 'yyyy-MM-dd');
        const endDate = format(new Date(), 'yyyy-MM-dd');

        const params = {
          start_date: startDate,
          end_date: endDate,
          sa_communities: selectedConversation.community.id,
        };

        const response = await getOverviewMetrics(params);
        setMetrics(response);
        setStatus(STATUSES.LOADED);
      } catch (e) {
        toast.error(parseErrorResponse(e, 'Error fetching overview metrics'));
        setStatus(STATUSES.FAILURE);
      }
    };

    fetchMetrics();
  }, []);

  return (
    <Wrapper>
      <If condition={status === STATUSES.LOADED}>
        <StatisticsRow>
          <span>Emails sent to community</span>
          <StatisticsRowValue>{formatCount(metrics?.emails_sent_to_communities)}</StatisticsRowValue>
        </StatisticsRow>
        <StatisticsRow>
          <span>Emails opened by community</span>
          <StatisticsRowValue>
            {formatPercent(metrics?.emails_opened_by_communities, metrics?.emails_sent_to_communities)}
          </StatisticsRowValue>
        </StatisticsRow>
        <StatisticsRow>
          <span>{capitalize(t('leads'))} sent to community</span>
          <StatisticsRowValue>{formatCount(metrics?.lead_created_emails)}</StatisticsRowValue>
        </StatisticsRow>
        <StatisticsRow>
          <span>{capitalize(t('leads'))} who replied to a message</span>
          <StatisticsRowValue>{formatCount(metrics?.active_leads)}</StatisticsRowValue>
        </StatisticsRow>
        <StatisticsRow>
          <span>{capitalize(t('leads'))} who received a sales message</span>
          <StatisticsRowValue>{formatCount(metrics?.community_replied_leads)}</StatisticsRowValue>
        </StatisticsRow>
        <StatisticsRow>
          <span>{capitalize(t('leads'))} who replied to a sales message</span>
          <StatisticsRowValue>
            {formatPercent(metrics?.community_active_leads, metrics?.community_replied_leads)}
          </StatisticsRowValue>
        </StatisticsRow>
        <StatisticsRow>
          <span>{capitalize(t('tours'))} scheduled by Further</span>
          <StatisticsRowValue>{formatCount(metrics?.scheduled_tours)}</StatisticsRowValue>
        </StatisticsRow>
        <StatisticsRow>
          <span>Calls scheduled by Further</span>
          <StatisticsRowValue>{formatCount(metrics?.scheduled_calls)}</StatisticsRowValue>
        </StatisticsRow>
      </If>
    </Wrapper>
  );
};
