import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/lib';
import { SCHEDULE_TYPES } from './constants';
import { ReminderTime } from './ReminderTime';

const Container = styled.div`
  padding: 10px 20px 6px;
  border-radius: 16px;
  box-shadow: 0 3px 5px 0 rgba(216, 216, 216, 0.85);
  background-color: var(--white);
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 620px;
  p {
    font-family: var(--font-family);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #89919d;
    margin: 0 30px 0 10px;
    span {
      color: var(--text);
    }
  }
`;

export const ConversationFloatingReminderConfirmed = ({ type, data }) => {
  const t = useTranslation();
  const reminderType = SCHEDULE_TYPES(t)[type];
  const { created_on, extra_data, user } = data;
  const calendar = extra_data?.calendar.user_id === user.id ? 'their own' : extra_data.calendar.user;

  return (
    <Container>
      <Icon name={reminderType.icon} size={24} color={reminderType.color} />
      <p>
        <span>
          {user?.name} confirmed a {type} for {extra_data?.start}
        </span>{' '}
        and added it to {calendar} calendar.
      </p>
      <ReminderTime date={created_on} />
    </Container>
  );
};
