import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Header, Button, Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { sendPublishChange, validateChat } from '../../api';
import { useChatEditor, actions } from './ChatEditorContext';
import ChatValidationModal from './ChatValidation/ChatValidationModal';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  h2 {
    margin: 0;
  }
`;

const PublishPopupBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
`;

const PublishWarningIcon = styled(Icon)`
  && {
    color: #fa7550;
    font-size: 1.5rem;
  }
`;

const PublishPopupTitleBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const PublishPopupTitle = styled.div`
  font-weight: 900;
  line-height: 1.43;
  color: #222;
`;

const PublishPopupDescription = styled.div`
  font-weight: 500;
  color: #89919d;
  font-size: 0.8rem;
`;

const RELOADABLE_PATHNAME_PARTS = '/module-recordings';

export const Heading = () => {
  const { chatId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const [{ startValidateChat, isPublished, shouldRefreshPage }, dispatch] = useChatEditor();
  const [confirmPublish, setConfirmPublish] = useState(false);
  const [loadingValidation, setLoadingValidation] = useState(false);
  const [validationErrors, setValidationErrors] = useState();
  const [loadingPublish, setLoadingPublish] = useState(false);

  const checkChatValidation = useCallback(async () => {
    try {
      setConfirmPublish(true);
      setLoadingValidation(true);
      setValidationErrors(null);
      const response = await validateChat(chatId);

      setValidationErrors(response);
    } catch (e) {
      console.error(e);
      setConfirmPublish(false);
      toast.error('Something went wrong. Validation check failed');
    } finally {
      setLoadingValidation(false);
    }
  }, [chatId]);

  useEffect(() => {
    if (startValidateChat) {
      checkChatValidation();
    }
  }, [checkChatValidation, startValidateChat]);

  useEffect(() => {
    if (shouldRefreshPage) {
      history.push(`/chats/${chatId}`); // Go back to settings page
      dispatch({ type: actions.SET_REFRESH_PAGE, payload: false });
    }
  }, [shouldRefreshPage]);

  const publishChanges = async () => {
    try {
      setLoadingPublish(true);
      await sendPublishChange(chatId);
      dispatch({ type: actions.SET_IS_PUBLISHED, payload: true });
      toast.success('Changes successfully published');

      if (pathname.includes(RELOADABLE_PATHNAME_PARTS)) {
        dispatch({ type: actions.SET_REFRESH_PAGE, payload: true });
      }
    } catch (e) {
      console.error(e);
      toast.error('Something went wrong. The changes you made were not published');
    } finally {
      setLoadingPublish(false);
      setConfirmPublish(false);
    }
  };

  const handleCloseConfirmPublish = () => {
    setConfirmPublish(false);
  };

  return (
    <>
      <Wrapper>
        <Header as="h2">
          <Header.Content>
            Chat Editor
            <Header.Subheader>Manage your chat integration preferences</Header.Subheader>
          </Header.Content>
        </Header>
        <div>
          <Popup
            open={isPublished === false}
            wide="very"
            position="left center"
            trigger={
              <Button
                primary
                onClick={() => {
                  checkChatValidation();
                }}
              >
                Publish Changes
              </Button>
            }
          >
            <Popup.Content>
              <PublishPopupBox>
                <PublishWarningIcon name="warning circle" />
                <PublishPopupTitleBox>
                  <PublishPopupTitle>Some changes have not been published yet.</PublishPopupTitle>
                  <PublishPopupDescription>They will only go live after publishing.</PublishPopupDescription>
                </PublishPopupTitleBox>
              </PublishPopupBox>
            </Popup.Content>
          </Popup>
        </div>
      </Wrapper>

      <ChatValidationModal
        open={confirmPublish}
        loading={loadingValidation}
        publishing={loadingPublish}
        validationErrors={validationErrors}
        onClose={handleCloseConfirmPublish}
        onConfirm={publishChanges}
      />
    </>
  );
};
