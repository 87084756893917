import React from 'react';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';
import * as Yup from 'yup';

import { Form, Field, Formik } from 'formik';
import { MEDIA_BREAK_POINTS } from '../../constants';
import { CARE_TYPES, setNextStep, setCareTypes, useUnitLevelPricing } from './context';
import UnitLevelPricingStepActions from './UnitLevelPricingStepActions';

const CareTypeBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
    margin-top: 5rem;
    justify-content: space-between;
  }
`;

const CareTypeLabel = styled.label`
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 3px;
  height: 180px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
  background-color: #ffffff;
  border: 2px solid #e4e4e4;

  &:hover {
    background-color: rgba(31, 87, 189, 0.05);
    border: 2px solid #1f57bd;
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    margin: 0;
  }
`;

const CareTypeTitle = styled.div`
  margin-top: 1rem;
`;

const CareTypeCheckBox = styled(Field)`
  display: none;

  &:checked + ${CareTypeLabel} {
    background-color: rgba(31, 87, 189, 0.05);
    border: 2px solid #1f57bd;
  }
`;

const CareTypeIconStyle = { width: '3.5rem', height: '3.5rem', fill: 'gray' };

const CareTypesStep = () => {
  const [{ careTypes }, dispatch] = useUnitLevelPricing();
  const initialValues = {
    careTypes,
  };

  const validationSchema = Yup.object().shape({
    careTypes: Yup.array().required(),
  });

  const handleSubmit = (values) => {
    const { careTypes } = values;

    dispatch(setCareTypes(careTypes));
    dispatch(setNextStep());
  };

  return (
    <>
      <Header as="h3">
        <Header.Content>
          What types of care do you offer?
          <Header.Subheader>
            Select all care types you provide and click continue to add the accommodations you provide.
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ handleSubmit, isValid }) => (
          <Form>
            <CareTypeBox>
              {CARE_TYPES.map((ct) => (
                <div key={ct?.id}>
                  <CareTypeCheckBox type="checkbox" id={`careTypes[${ct?.id}]`} name="careTypes" value={`${ct?.id}`} />
                  <CareTypeLabel htmlFor={`careTypes[${ct?.id}]`}>
                    {React.createElement(ct?.icon, CareTypeIconStyle)}
                    <CareTypeTitle>{ct?.title}</CareTypeTitle>
                  </CareTypeLabel>
                </div>
              ))}
            </CareTypeBox>

            <UnitLevelPricingStepActions onNextStep={handleSubmit} disabled={!isValid} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CareTypesStep;
