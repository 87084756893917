import React, { useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Header } from 'semantic-ui-react';

import { ChatModulesContext } from './ChatModulesContext';
import { Node } from './Node';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
`;

const UnreachableNodes = styled.div`
  background: #f0f0f0;
  border: 1px solid #d4d4d5;
  border-radius: 0.5rem;
  padding: 1.5rem;
`;

const RootNode = styled.div``;

export const ModuleGraphEditor = () => {
  const { moduleGraph, hideUnreachable, disabledMode } = useContext(ChatModulesContext);

  const showUnreachableNodes = moduleGraph.unreachableNodes.length > 0 && !hideUnreachable;
  const wrapperClass = classNames({ disabled: disabledMode });

  return (
    <Wrapper className={wrapperClass}>
      <RootNode>{moduleGraph.rootNode && <Node node={moduleGraph.rootNode} />}</RootNode>
      {showUnreachableNodes && (
        <UnreachableNodes>
          <Header as="h3">Unreachable Chat Modules</Header>
          {moduleGraph.unreachableNodes.map((n, i) => (
            <Node key={i} node={n} />
          ))}
        </UnreachableNodes>
      )}
    </Wrapper>
  );
};
