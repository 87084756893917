import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import queryConstants from 'utils/queries/constants';
import { getAnswerPlaceholders } from 'apis';
import { PlaceholderButton } from './PlaceholderButton';

const usePlaceholders = (params) =>
  useQuery([queryConstants.INSTANT_RESPONSES_PLACEHOLDERS, params], () => getAnswerPlaceholders(params));

const Container = styled.div``;

export const PlaceholderList = ({ showSafePlaceholdersOnly }) => {
  const { data } = usePlaceholders(showSafePlaceholdersOnly ? { safe_only: 1 } : undefined);
  const sorted = useMemo(() => (data ? data.sort() : []), [data]);
  return (
    <Container>
      {sorted.map((placeholder) => (
        <PlaceholderButton key={placeholder}>{placeholder}</PlaceholderButton>
      ))}
    </Container>
  );
};
