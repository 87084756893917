import React from 'react';
import { useTranslation } from 'react-i18next';

import { BooleanSettingsPage } from '../common';

export default function SendNotificationEmails() {
  const { t } = useTranslation();
  return (
    <BooleanSettingsPage
      flagName="send_notification_emails_for_leads"
      modalText={`Choose if an email is sent each time a new ${t('lead')} is created.`}
      modalTitle={`Notifications about each new ${t('lead')}`}
    />
  );
}
