import React from 'react';
import { Table } from 'semantic-ui-react';

import { SEOAnalysisRow } from './SEOAnalysisRow';
import { If } from '../../If';

export const SEOAnalysisTable = ({ title, items, attached }) => (
  <Table attached={attached}>
    <If condition={title}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={2}>{title}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    </If>
    <Table.Body>
      {Object.entries(items).map(([item_title, item_value]) => (
        <SEOAnalysisRow key={item_title} title={item_title} value={item_value} />
      ))}
    </Table.Body>
  </Table>
);
