import React from 'react';
import styled from 'styled-components';

import { NavBar } from 'components/navigation';

import { OwnersDashboardContextProvider } from './OwnersDashboardContext';
import { Portfolio } from './Portfolio';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OwnersDashboard = () => (
  <OwnersDashboardContextProvider>
    <Container>
      <NavBar showUserInfo />
      <Portfolio />
    </Container>
  </OwnersDashboardContextProvider>
);
