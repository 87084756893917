import React, { useState } from 'react';

import { CampaignModal, CampaignModalActions } from '../common';

export const ActivateCampaignModal = ({ isOpen, onSave, onClose }) => {
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    await onSave();
    setLoading(false);
  };

  return (
    <CampaignModal loading={loading} open={isOpen} onModalClose={onClose} size="tiny" width={540}>
      <div type="header">Activate Campaign?</div>
      <div type="body">The campaign will be activated and start sending messages.</div>
      <CampaignModalActions onSave={handleSave} onCancel={onClose} type="actions" saveText="Activate" />
    </CampaignModal>
  );
};
