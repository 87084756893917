import React, { useContext } from 'react';
import styled from 'styled-components';

import { MEDIA_BREAK_POINTS } from '../../constants';
import ConversationOptions from './actions/ConversationOptions';
import CallAction from './CallAction';
import { If } from '../If';
import { ConversationContext } from './ConversationContext';

const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    padding-top: 15px;
    justify-content: flex-end;
    flex-direction: row;
    gap: 10px;
  }
`;

export const ConversationHeaderActions = () => {
  const { selectedConversation = {} } = useContext(ConversationContext);
  return (
    <ActionsContainer>
      <If condition={selectedConversation?.phone && selectedConversation?.community?.enable_outbound_calls}>
        <CallAction lead={selectedConversation} />
      </If>
      <ConversationOptions />
    </ActionsContainer>
  );
};
