import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { Form, Radio, AudioPlayer } from 'components/lib';
import { STATUSES } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';

const ModalBody = styled.div``;

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 10px;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 460px;
  height: 60px;
  padding: 12px 20px 12px 20px;
  border: solid 1px var(--line-standard);
  &:not(:last-child) {
    border-bottom: none;
  }
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  > span {
    margin-left: 10px;
    padding-top: 6px;
  }
`;

const VoicesWrapper = styled.div`
  min-height: 330px;
  max-height: 330px;
  margin-top: 20px;
  padding-bottom: 30px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const AudioPlayerWrapper = styled.div`
  margin-left: auto;
  width: 112px;
  height: 36px;
  padding: 7px 44px 5px 10px;
  border-radius: 18px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.12);
`;

export const VoiceModal = ({
  isModalOpen,
  selectedItem,
  totalItemsCount,
  onSave,
  onClose,
  isMultipleSelection,
  voices,
  defaultVoice,
}) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);
    await onSave(values);
    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const initialValues = {
    voice_id: selectedItem?.voice_id || defaultVoice?.id,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue }) => {
        return (
          <SettingsModal
            loading={loading}
            open={isModalOpen}
            onModalClose={() => handleClose(resetForm)}
            size="tiny"
            width={540}
            height={534}
          >
            <SettingsModalHeader
              title="Voice"
              community={selectedItem || null}
              totalItemsCount={totalItemsCount}
              type="header"
            />
            <ModalBody type="body">
              <BodyText>Select a voice to use with the automated phone system.</BodyText>
              <VoicesWrapper>
                {voices?.map((voice) => (
                  <RadioWrapper key={voice.id}>
                    <CustomRadio>
                      <Form.Field
                        control={Radio}
                        name="voice_id"
                        value={voice.id}
                        checked={values.voice_id === voice.id}
                        onChange={() => setFieldValue('voice_id', voice.id)}
                      />
                      <span>{voice.name}</span>
                    </CustomRadio>
                    <AudioPlayerWrapper>
                      <AudioPlayer
                        text="Play"
                        hideProgressBar
                        playingText="Playing..."
                        textMinWidth={57}
                        playIconColor="var(--emerald-green)"
                        pauseIconColor="#89919d"
                        url={voice.resourceUrl}
                        disabled={!voice.resourceUrl}
                      />
                    </AudioPlayerWrapper>
                  </RadioWrapper>
                ))}
              </VoicesWrapper>
            </ModalBody>
            <SettingsModalActions
              onSave={handleSubmit}
              onCancel={() => handleClose(resetForm)}
              type="actions"
              disabled={!isMultipleSelection && (!dirty || !touched)}
              saveText="Save"
            />
          </SettingsModal>
        );
      }}
    </Formik>
  );
};
