import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Button, Table, Dropdown, Form, Header, Input } from 'semantic-ui-react';

import { MODULE_TYPES } from 'components/chats/ChatModules/constants';
import { SIMPLE_US_PHONE_REGEX } from '../../../constants';

const Wrapper = styled.div`
  .ui.table {
    tfoot {
      .field {
        margin-bottom: 0;
      }
    }

    .trait-value {
      overflow-wrap: anywhere;
    }
  }
`;

const InvalidModuleText = styled.div`
  color: var(--warning);

  .delete {
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 700;
  }
`;

const getModuleList = (modules) =>
  modules
    .filter((m) => m.module_type === MODULE_TYPES.DIAL_COMMUNITY)
    .map((m) => ({
      key: m.id,
      value: m.id,
      text: `Module ${m?.number} [${m?.module_type}]`,
      search: `Module ${m?.number} [${m?.module_type} ${m?.text}]`,
      content: <Header as="h5" content={`Module ${m?.number} [${m?.module_type}]`} subheader={m?.text} />,
    }));

const ModuleDisplay = ({ moduleId, modules }) => {
  if (!moduleId || !modules) {
    return '-';
  }

  const module = modules.find((m) => m.id === moduleId);

  if (!module) {
    return (
      <InvalidModuleText>
        <div>Invalid module</div>
        <div className="delete">Safe to delete</div>
      </InvalidModuleText>
    );
  }

  return (
    <div>
      Module {module.number} [{module.module_type}]
    </div>
  );
};

// Not a real form because nesting <form /> within another <form />
// is not allowed.
const AddModuleContactPhoneNumber = ({
  modules,
  contactPhoneNumbers,
  moduleContacts,
  onAddContact,
  onChangeContacts,
  onSubmit,
}) => {
  const [contactPhoneNumber, setContactPhoneNumber] = useState('');
  const [contactPhoneNumberName, setContactPhoneNumberName] = useState('');
  const [module, setModule] = useState('');
  const [moduleError, setModuleError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [contactNameError, setContactNameError] = useState(false);
  const [rowId, setRowId] = useState('');

  const moduleList = getModuleList(modules);
  const contactPhoneNumbersList = useMemo(
    () =>
      contactPhoneNumbers.map((t) => ({
        key: t.id || t.tempId,
        value: t.id || t.tempId,
        text: t.phoneNumber,
        content: t.phoneNumber,
      })),
    [contactPhoneNumbers]
  );

  const reset = () => {
    setContactPhoneNumber('');
    setPhoneNumberError(false);
    setContactPhoneNumberName('');
    setContactNameError(false);
    setModule('');
    setRowId('');
    setModuleError(false);
  };

  const submit = () => {
    let valid = true;

    if (!contactPhoneNumber || contactPhoneNumber.length > 12 || !contactPhoneNumber.match(SIMPLE_US_PHONE_REGEX)) {
      valid = false;
      setPhoneNumberError(true);
      setPhoneNumberError({ content: 'Please follow the valid phone number format' });
    }

    const hasAlreadyDefinedModuleContactNumber = moduleContacts.some(
      (contact) => contact.module === module && contact.phone_number === contactPhoneNumber
    );
    if (hasAlreadyDefinedModuleContactNumber) {
      valid = false;
      setPhoneNumberError(true);
      setPhoneNumberError({ content: 'The same phone number is already defined for this module' });
    }

    if (!contactPhoneNumberName) {
      valid = false;
      setContactNameError({ content: 'Please enter the contact number name' });
    }

    if (!module || module === '') {
      valid = false;
      setModuleError(true);
    }

    if (valid && typeof onSubmit === 'function') {
      onSubmit({
        name: contactPhoneNumberName,
        phone_number: contactPhoneNumber,
        module,
        uniqueId: rowId,
      });

      reset();
    }
  };

  return (
    <Table.Row>
      <Table.HeaderCell verticalAlign="top">
        <Form.Field
          error={moduleError}
          control={Dropdown}
          fluid
          placeholder="Select module"
          selection
          options={moduleList}
          search={(options, query) => options.filter((o) => o.search.includes(query.toLowerCase()))}
          value={module}
          onChange={(_, d) => {
            setModule(d.value);
            setModuleError(false);
          }}
        />
      </Table.HeaderCell>
      <Table.HeaderCell verticalAlign="top">
        <Form.Field
          control={Dropdown}
          placeholder="+1xxxxxxxxxx"
          fluid
          options={contactPhoneNumbersList}
          selection
          allowAdditions
          search
          value={rowId}
          additionLabel={<i>Add new contact phone number: </i>}
          onAddItem={(e, { value }) => {
            const tempRowId = Date.now();
            onAddContact(contactPhoneNumberName, value, tempRowId);
            setRowId(tempRowId);
            setContactPhoneNumber(value);
            setPhoneNumberError(value.length === 0);
          }}
          onChange={(e, { value }) => {
            const contact = contactPhoneNumbers.find((t) => t.id === value || t.tempId === value);
            if (contact) {
              setRowId(contact.id || contact.tempId || '');
              setContactPhoneNumberName(contact.name);
              setContactPhoneNumber(contact.phoneNumber);
              setPhoneNumberError(false);
              return;
            }
            setContactPhoneNumber(value);
            setPhoneNumberError(value.length === 0);
          }}
          error={phoneNumberError}
        />
      </Table.HeaderCell>
      <Table.HeaderCell verticalAlign="top">
        <Form.Field
          control={Input}
          placeholder="Contact Name"
          value={contactPhoneNumberName}
          onChange={(e, { value }) => {
            setContactPhoneNumberName(value);
            setContactNameError(!value);
          }}
          error={contactNameError}
        />
      </Table.HeaderCell>
      <Table.HeaderCell verticalAlign="top">
        <Button.Group floated="right">
          <Button type="button" icon="add" content="Add Contact" size="small" basic onClick={submit} />
        </Button.Group>
      </Table.HeaderCell>
    </Table.Row>
  );
};

export const ModuleContactPhoneNumbers = ({ modules, contactPhoneNumbers, ...props }) => {
  const [, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const moduleContacts = [...meta.value];
  const [communityContacts, setCommunityContacts] = useState([]);

  useEffect(() => {
    setCommunityContacts(contactPhoneNumbers);
  }, [contactPhoneNumbers]);

  const orderModuleContacts = (a, b) => {
    if (a.module !== b.module) {
      return a.module - b.module;
    }
    if (a.id && b.id) {
      return a.id - b.id;
    }
    return b - a;
  };

  const addModuleContact = (contact) => {
    const { id, name, phone_number, module, uniqueId } = contact;
    setCommunityContacts(
      communityContacts.map((ct) => {
        if (!ct.id && ct.tempId && uniqueId && ct.tempId === uniqueId) {
          return { id: null, tempId: ct.tempId, name, phoneNumber: phone_number, module };
        }
        if (ct.id === id) {
          return { id, name, phoneNumber: phone_number, module };
        }

        return ct;
      })
    );
    const contacts = [...moduleContacts, { id, module, name, phone_number }];
    setValue(contacts.sort(orderModuleContacts));
  };

  const removeModuleContact = (index) => () => {
    moduleContacts.splice(index, 1);
    setValue(moduleContacts);
  };

  const addCommunityContact = (name, phoneNumber, tempId) => {
    setCommunityContacts([...communityContacts, { id: null, tempId, name, phoneNumber }]);
  };

  return (
    <Wrapper>
      <Table>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={4}>Module</Table.HeaderCell>
            <Table.HeaderCell width={4}>Phone Number</Table.HeaderCell>
            <Table.HeaderCell width={5}>Contact Name</Table.HeaderCell>
            <Table.HeaderCell width={3}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {moduleContacts?.map((mc, index) => (
            <Table.Row key={index} textAlign="center">
              <Table.Cell>
                <ModuleDisplay moduleId={mc.module} modules={modules} />
              </Table.Cell>
              <Table.Cell>{mc.phone_number}</Table.Cell>
              <Table.Cell>{mc.name}</Table.Cell>
              <Table.Cell textAlign="right">
                <Button.Group floated="right">
                  <Button type="button" icon="times" size="small" basic onClick={removeModuleContact(index)} />
                </Button.Group>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer fullWidth>
          {modules.length > 0 && (
            <AddModuleContactPhoneNumber
              modules={modules}
              contactPhoneNumbers={communityContacts}
              moduleContacts={moduleContacts}
              onAddContact={addCommunityContact}
              onSubmit={addModuleContact}
            />
          )}
        </Table.Footer>
      </Table>
    </Wrapper>
  );
};
