import React, { useMemo } from 'react';
import { CAMPAIGN_MESSAGE_TYPE } from 'components/nurture/constants';
import { If } from 'components/If';
import EmailPreview from 'components/common/EmailPreview';
import styled from 'styled-components';
import { toAmPmHours } from 'components/nurture/Campaigns/common/helpers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 735px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const Header = styled.div`
  height: 36px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #adb3bc;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
`;

const HeaderText = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  > span {
    font-family: var(--font-family-bold);
  }
`;

const Type = styled.div`
  width: fit-content;
  padding: 4px 8.5px 1px;
  border-radius: 10px;
  background-color: var(--emerald-green);
  font-family: var(--font-family-bold);
  font-size: 9px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  &.email {
    background-color: #2791ff;
  }
`;

const Body = styled.div`
  ${({ isEmail }) => !isEmail && 'padding: 21px 20px 20px;'}
  border-bottom-left-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: var(--input-field-fill);
  overflow: hidden;
`;

const BodyTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  margin-top: 8px;
`;

const Subject = styled.div`
  padding: 10px 20px;
  color: var(--text);
  font-weight: 500;
  font-size: 12px;
  border-bottom: solid 1px var(--line-standard);
  strong {
    font-weight: 900;
  }
`;

const calculateSendTime = (m) => {
  if (m.days === 0 && m.hours === 0 && m.minutes === 0) {
    return 'Immediately';
  }
  if (m.isNegativeTime) {
    return `${m.days}d ${m.hours}h ${m.minutes}min before`;
  }
  return `${m.days}d ${m.hours}h ${m.minutes}min after`;
};

export const Message = ({ data, onClick, emailStyle }) => {
  const { message, messageType, subject, deliveryHoursFrom, deliveryHoursTo, disabledOnWeekends, limitDeliveryHours } =
    data;
  const weekendText = disabledOnWeekends ? '· Not on weekends' : '';
  const nightText = limitDeliveryHours
    ? `· Between ${toAmPmHours(deliveryHoursFrom)} and ${toAmPmHours(deliveryHoursTo)}`
    : '';
  const isEmail = messageType === CAMPAIGN_MESSAGE_TYPE.EMAIL;
  const html = useMemo(() => {
    return `<style>html{max-width:100%; overflow:hidden;}${emailStyle}</style>${message}`;
  }, [message]);
  return (
    <Wrapper onClick={onClick}>
      <Header>
        <HeaderText>
          <span>{calculateSendTime(data)}</span> {weekendText} {nightText}
        </HeaderText>
        <Type className={messageType}>{messageType}</Type>
      </Header>
      <Body isEmail={isEmail}>
        <If condition={isEmail}>
          <Subject>
            <strong>Subject:</strong> {subject}
          </Subject>
          <EmailPreview html={html} width={733} fullEmailHeight wrapToHtml />
        </If>
        <If condition={!isEmail}>
          <BodyTitle>{subject}</BodyTitle>
          <BodyText>{message}</BodyText>
        </If>
      </Body>
    </Wrapper>
  );
};
