import React from 'react';
import { Image, Segment, Label } from 'semantic-ui-react';
import img from '../../../assets/square-image.png';

export const Gallery = ({ module }) => {
  const { role } = module;
  const galleryType = role === 'videos' ? 'Video' : 'Image';

  return (
    <>
      <Segment attached="top" secondary>
        <Label color="blue">Open {galleryType} Gallery (full-screen)</Label>
      </Segment>
      <Segment attached="bottom">
        <Image src={img} fluid rounded />
      </Segment>
    </>
  );
};
