import React from 'react';
import styled from 'styled-components';

import { useUrlSearchParams } from 'utils/hooks';
import { PageSection } from 'components/common';
import { CampaignDetailsProvider } from './CampaignDetailsContext';
import CampaignSalesList from './CampaignSalesList';
import CampaignFollowUpList from './CampaignFollowUpList';
import { useCampaigns } from './CampaignsContext';
import { CampaignsFilters } from './CampaignsFilters';
import { AddCampaignModal } from './common';
import CampaignOneTimeList from './CampaignOneTimeList';

const CampaignsWrapper = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;

export const CampaignsList = () => {
  const { setUrlSearchParams } = useUrlSearchParams();

  const {
    state: { filters },
  } = useCampaigns();

  const updatedFilters = { ...filters };
  delete updatedFilters.pageSize;
  setUrlSearchParams(updatedFilters);

  return (
    <>
      <CampaignsFilters />
      <PageSection>
        <CampaignsWrapper>
          <CampaignSalesList />
          <ColumnBox>
            <CampaignFollowUpList />
            <CampaignOneTimeList />
          </ColumnBox>
        </CampaignsWrapper>
        <CampaignDetailsProvider>
          <AddCampaignModal />
        </CampaignDetailsProvider>
      </PageSection>
    </>
  );
};
