import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/lib';
import { useCheckMobileScreen } from 'utils/hooks';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-family: var(--font-family);
  background-color: white;
  margin: 1rem;
  border-radius: 1.5rem;
  box-shadow: 0 0.21rem 0.36rem 0 rgba(216, 216, 216, 0.85);
  line-height: 1.4;
  overflow: hidden;
`;

const Content = styled.div`
  padding: 1rem 2rem 1rem 3.5rem;
`;

const TitleIcon = styled(Icon)`
  position: absolute;
  top: 1rem;
  left: 1.6rem;
  margin-right: 0.5rem;
`;

const Subtitle = styled.div`
  font-size: 0.9rem;
  color: var(--light-text);
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid #f0f0f0;
  padding: 1rem 2rem;
  color: var(--link);
  cursor: pointer;
  line-height: 1;

  &:hover {
    background-color: #fafafa;
  }
  &:active {
    background-color: #f5f5f5;
  }
`;

const InlineAction = styled.span`
  color: var(--link);
  cursor: pointer;

  &:hover {
    color: var(--vivid-blue);
  }
`;

export const ArchiveSuggestion = ({ onClickArchive }) => {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();

  return (
    <Wrapper>
      <Content>
        <div>
          <TitleIcon name="done_all" size="16" color="#0ab776" />
          Done with the conversation? <InlineAction onClick={onClickArchive}>Archive it</InlineAction>
        </div>
        <Subtitle>It will reappear in your inbox if the {t('lead')} replies, or a reminder activates.</Subtitle>
      </Content>
      {!isMobile && <Action onClick={onClickArchive}>Archive</Action>}
    </Wrapper>
  );
};
