import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Formik, Field as FormikField } from 'formik';
import { Form, Dimmer, Divider, Button, Loader, Sticky, Message, Icon, Popup } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { formatAnyDateToTimeZone } from 'components/utils';
import { ToggleSwitch, PageHeader, PAGE_HEADER_MARGIN } from 'components/common';
import { updateChatCommunity, getCommunityTraits, getCommunityContactPhoneNumbers } from 'apis';
import { uploadIcon, getChatModules } from 'api';
import { If } from 'components/If';
import { parseErrorResponse } from 'apis/utils';
import { capitalize } from 'utils/helpers';
import { InputField } from '../../common/fields/InputField';
import { ColorPicker } from '../ColorPicker';
import { MODULE_TYPES, MODULE_ROLES } from '../ChatModules/constants';
import { VSA_TYPE } from '../constants';
import { FormWrapper } from '../FormWrapper';
import { LabeledNumberInput } from '../LabeledNumberInput';
import { OptionalField } from '../OptionalField';
import { ImagePicker } from '../ImagePicker';
import { UnloadPrompt } from '../UnloadPrompt';
import { CommunitiesContext, ACTIONS } from '../../common/communities/CommunitiesContext';
import { Prices } from './Prices';
import { ModuleTraits } from './ModuleTraits';
import { WrappedFontPicker } from '../../WrappedFontPicker';
import ChatSaveModal from '../ChatSaveModal';
import { actions, useChatEditor } from '../ChatEditorContext';
import { PAGINATION, MAX_FILE_UPLOAD_SIZE, MAX_FILE_UPLOAD_SIZE_MB } from '../../../constants';
import { ModuleContactPhoneNumbers } from './ModuleContactPhoneNumbers';
import { ModuleTimeBasedModal } from './ModuleTimeBasedModal';
import { ModuleTimeBasedPreview } from './ModuleTimeBasedPreview';
import { ModuleCallForwardingTimeouts } from './ModuleCallForwardingTimeouts';

const VALIDATION_SCHEMA = Yup.object({
  chat_property: Yup.object().shape({}),
});

const CommunityActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const CommunityWrapper = styled.div`
  .ui.form {
    ${CommunityActions} {
      .field {
        margin-bottom: 0;
        margin-right: 3rem;
        font-weight: bold;
      }
    }
  }
`;

export const Community = () => {
  const { t } = useTranslation();
  const { chatId, communityId } = useParams();
  const { state, dispatch } = useContext(CommunitiesContext);
  const [, chatEditorDispatch] = useChatEditor();
  const { assignedCommunities } = state;
  const [community, setCommunity] = useState();
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const wrapperRef = useRef();
  const [values, setValues] = useState();
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [chatProperty, setChatProperty] = useState();
  const [communityTraits, setCommunityTraits] = useState([]);
  const [communityContactPhoneNumbers, setCommunityContactPhoneNumbers] = useState([]);
  const [isTimeBasedModalOpen, setIsTimeBasedModalOpen] = useState(false);

  useEffect(() => {
    if (assignedCommunities && assignedCommunities.length !== 0 && communityId) {
      const community = assignedCommunities.find((community) => community.id === parseInt(communityId));
      setCommunity(community);

      const property = community?.chat_properties?.find((item) => item?.chat === parseInt(chatId));
      setChatProperty(
        property
          ? {
              ...property,
            }
          : null
      );
    }
  }, [assignedCommunities, communityId, chatId]);

  useEffect(() => {
    const fetchRelatedData = async () => {
      try {
        const { modules } = await getChatModules(chatId);
        const { results: traits } = await getCommunityTraits(communityId, PAGINATION.MAX_LARGE_PAGE_SIZE);
        const { results: contactPhoneNumbers } = await getCommunityContactPhoneNumbers(communityId);

        setModules(modules);
        setCommunityTraits(traits);
        setCommunityContactPhoneNumbers(contactPhoneNumbers);
      } catch (e) {
        console.error(e);
        toast.error('Error fetching module related data');
      }
    };

    if (community && chatProperty) {
      fetchRelatedData();
    }
  }, [chatId, community, chatProperty]);

  const hasTimeRuleModule = useMemo(() => {
    const timeRuleRole = modules.find(
      (module) => module.module_type === MODULE_TYPES.OPTION && module.role === MODULE_ROLES.TIME_RULE_BASED_FLOW
    );

    return !!timeRuleRole;
  }, [modules]);

  const hasDialCommunityModule = useMemo(() => {
    const dialCommunityModule = modules.find((module) => module.module_type === MODULE_TYPES.DIAL_COMMUNITY);
    return !!dialCommunityModule;
  }, [modules]);

  const buildChatPropertyRequest = (chatProperty) => {
    const removeKeys = ['community'];
    if (chatProperty.amenities === null) {
      removeKeys.push('amenities');
    }
    // Remove time based rules if time rule module is not present
    if (
      !modules.find(
        (module) => module.module_type === MODULE_TYPES.OPTION && module.role === MODULE_ROLES.TIME_RULE_BASED_FLOW
      )
    ) {
      chatProperty.module_time_based_rules = [];
    }

    return Object.keys(chatProperty).reduce((result, key) => {
      if (!removeKeys.includes(key)) {
        result[key] = chatProperty[key];
      }
      return result;
    }, {});
  };

  const submit = async ({ shouldPublish }) => {
    try {
      setLoading(true);
      // Remove properties not needed for update payload

      if (values.starting_cost === '') {
        values.starting_cost = null;
      }
      if (values.ending_cost === '') {
        values.ending_cost = null;
      }

      const requestData = { ...values, chat_property: buildChatPropertyRequest(values.chat_property) };
      const updatedCommunity = await updateChatCommunity(community.id, requestData);

      dispatch({
        type: ACTIONS.SET_COMMUNITY,
        payload: { communityId: community.id, communityData: updatedCommunity },
      });
      chatEditorDispatch({ type: actions.SET_START_VALIDATE_CHAT, payload: shouldPublish });
      toast.success(`${community.name} updated`);
      setValues(null);
      setOpenSaveModal(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      toast.error(parseErrorResponse(e, `Something went wrong and the ${t('community')} was not updated`));
    }
  };

  const onImageSelected = async (image) => {
    try {
      if (image.size > MAX_FILE_UPLOAD_SIZE) {
        toast.error(`Image size should not be greater than ${MAX_FILE_UPLOAD_SIZE_MB}MB`);
        return null;
      }

      const resp = await uploadIcon(image);
      return resp.url;
    } catch (e) {
      console.error(e);
      toast.error('Error uploading image');
    }
  };

  const handleSubmit = (values) => {
    setValues(values);
    setOpenSaveModal(true);
  };

  const handleCancel = () => {
    setOpenSaveModal(false);
    setValues(null);
  };

  const editTimeBasedRule = () => {
    setIsTimeBasedModalOpen(true);
  };

  const closeTimeBasedModal = () => {
    setIsTimeBasedModalOpen(false);
  };

  if (!community) {
    return (
      <Message info>
        <Message.Header>Please select community</Message.Header>{' '}
        <Message.Content>Select community or link a new one</Message.Content>
      </Message>
    );
  }

  // Distinct properties used by form
  const initialValues = {
    id: community.id,
    name: community.name,
    chat_property: chatProperty,
    starting_cost: community.starting_cost,
    ending_cost: community.ending_cost,
    settings: community.settings,
    prices: community.prices,
  };

  return (
    <CommunityWrapper>
      <FormWrapper ref={wrapperRef}>
        <Formik
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <UnloadPrompt>
              <Form key={formik.values.id} onSubmit={formik.handleSubmit}>
                <Sticky context={wrapperRef}>
                  <PageHeader
                    title={initialValues.name}
                    subtitle={<pre>{initialValues?.chat_property?.name}</pre>}
                    block
                    margin={PAGE_HEADER_MARGIN.MEDIUM}
                    actions={
                      <CommunityActions>
                        <ChatSaveModal
                          open={openSaveModal}
                          loading={loading}
                          onConfirm={submit}
                          onCancel={handleCancel}
                        />
                        <ToggleSwitch name="chat_property.show_chat" label="Chat is Live" />
                        <Button
                          type="button"
                          primary
                          disabled={loading || !formik.dirty || !formik.isValid}
                          onClick={formik.submitForm}
                        >
                          Save
                        </Button>
                      </CommunityActions>
                    }
                  />
                </Sticky>
                {chatProperty && (
                  <>
                    <Form.Field>
                      <label>Prices</label>
                      <Prices name="prices" />
                    </Form.Field>

                    <Form.Group>
                      <Form.Field width={6}>
                        <OptionalField
                          as={LabeledNumberInput}
                          fieldLabel="Starting cost"
                          name="starting_cost"
                          type="number"
                          min="0"
                          max="50000"
                          icon="dollar"
                          iconPosition="left"
                          enabled={formik.values.starting_cost !== null}
                        />
                      </Form.Field>

                      <Form.Field width={6}>
                        <OptionalField
                          as={LabeledNumberInput}
                          fieldLabel="Ending cost"
                          name="ending_cost"
                          type="number"
                          min="0"
                          max="50000"
                          icon="dollar"
                          iconPosition="left"
                          enabled={formik.values.ending_cost !== null}
                        />
                      </Form.Field>
                    </Form.Group>

                    <Divider section horizontal>
                      Chat settings overrides
                    </Divider>

                    <Form.Field>
                      <ToggleSwitch name="settings.use_client_side_routing" label="Use client-side routing" />
                    </Form.Field>

                    <Form.Field>
                      <ToggleSwitch
                        name="chat_property.show_pricing_not_available"
                        label='Show "Pricing not available"'
                      />
                    </Form.Field>

                    <Form.Group>
                      <Form.Field>
                        <OptionalField
                          as={ColorPicker}
                          name="chat_property.brand_color"
                          fieldLabel="Brand color"
                          enabled={formik.values.chat_property?.brand_color !== null}
                          defaultValue="#000000"
                        />
                      </Form.Field>
                      <Form.Field>
                        <OptionalField
                          as={ColorPicker}
                          name="chat_property.background_color"
                          fieldLabel="Chat background"
                          enabled={formik.values.chat_property?.background_color !== null}
                          defaultValue="#000000"
                        />
                      </Form.Field>
                      <Form.Field>
                        <OptionalField
                          as={ColorPicker}
                          name="chat_property.button_color"
                          fieldLabel="Button color"
                          enabled={formik.values.chat_property?.button_color !== null}
                          defaultValue="#000000"
                        />
                      </Form.Field>
                      <Form.Field>
                        <OptionalField
                          as={ColorPicker}
                          name="chat_property.color"
                          fieldLabel="Chat text"
                          enabled={formik.values.chat_property?.color !== null}
                          defaultValue="#000000"
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Group>
                      <Form.Field width={6}>
                        <OptionalField
                          as={FormikField}
                          satellite={WrappedFontPicker}
                          satelliteClass="top-padded field"
                          name="chat_property.font_family"
                          type="text"
                          fieldLabel="Font family"
                          enabled={formik.values.chat_property?.font_family !== null}
                          defaultValue="inherit"
                          placeholder="CSS font-family..."
                        />
                      </Form.Field>
                      <Form.Field>
                        <OptionalField
                          as={LabeledNumberInput}
                          name="chat_property.font_size"
                          type="number"
                          min="8"
                          max="24"
                          fieldLabel="Font size"
                          enabled={formik.values.chat_property?.font_size !== null}
                          label={{ content: 'px' }}
                          labelPosition="right"
                          defaultValue={14}
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Group>
                      <Form.Field width={6}>
                        <OptionalField
                          as={ImagePicker}
                          name="chat_property.icon_logo"
                          enabled={formik.values.chat_property?.icon_logo !== null}
                          fieldLabel="Chat icon"
                          onImageSelected={onImageSelected}
                        />
                      </Form.Field>
                      <Form.Field width={6}>
                        <OptionalField
                          as={ImagePicker}
                          name="chat_property.backdrop_image"
                          enabled={formik.values.chat_property?.backdrop_image !== null}
                          fieldLabel="Backdrop image"
                          onImageSelected={onImageSelected}
                          onImageDelete={() => null}
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Group>
                      <Form.Field width={6}>
                        <OptionalField
                          as={FormikField}
                          name="chat_property.tour_slide_button_text"
                          enabled={formik.values.chat_property?.tour_slide_button_text !== null}
                          fieldLabel={`${capitalize(t('tours'))} slide button text`}
                        />
                      </Form.Field>
                      <Form.Field width={6}>
                        <OptionalField
                          as={FormikField}
                          name="chat_property.out_of_my_budget_alert_text"
                          enabled={formik.values.chat_property?.out_of_my_budget_alert_text !== null}
                          fieldLabel="Out Of My Budget alert text"
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={6}>
                        <OptionalField
                          as={FormikField}
                          name="chat_property.delay_loading"
                          enabled={formik.values.chat_property?.delay_loading !== null}
                          fieldLabel="Delay loading VSA (seconds)"
                          type="number"
                          min="0"
                          max="120"
                          label={{ content: 'seconds' }}
                          labelPosition="right"
                        />
                      </Form.Field>
                    </Form.Group>
                    <Divider section horizontal>
                      Chat module overrides
                    </Divider>
                    <If
                      condition={[VSA_TYPE.REGULAR, VSA_TYPE.EMBEDDED, VSA_TYPE.SURVEY].includes(chatProperty.vsa_type)}
                    >
                      <Dimmer.Dimmable as={Form.Field} dimmed={!modules?.length}>
                        <label>Module Traits</label>
                        <ModuleTraits name="chat_property.module_traits" modules={modules} traits={communityTraits} />
                        <Dimmer active={!modules?.length} inverted>
                          <Loader inverted>Loading</Loader>
                        </Dimmer>
                      </Dimmer.Dimmable>
                    </If>
                    <If condition={chatProperty.vsa_type === VSA_TYPE.PHONE}>
                      <If condition={hasDialCommunityModule}>
                        <Dimmer.Dimmable as={Form.Field} dimmed={!modules?.length}>
                          <label>Module Call Forwarding Timeouts</label>
                          <ModuleCallForwardingTimeouts
                            name="chat_property.module_call_forwarding_timeouts"
                            modules={modules}
                          />
                          <Dimmer active={!modules?.length} inverted>
                            <Loader inverted>Loading</Loader>
                          </Dimmer>
                        </Dimmer.Dimmable>
                        <Dimmer.Dimmable as={Form.Field} dimmed={!modules?.length}>
                          <label>
                            Module Contact Phone Number &nbsp;
                            <Popup hoverable trigger={<Icon name="question circle outline" />}>
                              Assigning multiple phone numbers to the same module enables simultaneous calls
                            </Popup>
                          </label>
                          <ModuleContactPhoneNumbers
                            name="chat_property.module_contact_phone_numbers"
                            modules={modules}
                            contactPhoneNumbers={communityContactPhoneNumbers}
                          />
                          <Dimmer active={!modules?.length} inverted>
                            <Loader inverted>Loading</Loader>
                          </Dimmer>
                        </Dimmer.Dimmable>
                      </If>
                      <If condition={hasTimeRuleModule}>
                        <If condition={isTimeBasedModalOpen}>
                          <ModuleTimeBasedModal
                            name="chat_property.module_time_based_rules"
                            modules={modules}
                            open={isTimeBasedModalOpen}
                            onClose={closeTimeBasedModal}
                          />
                        </If>
                        <Dimmer.Dimmable as={Form.Field} dimmed={!modules?.length}>
                          <label>
                            Module Time Rules &nbsp;
                            <Popup hoverable trigger={<Icon name="question circle outline" />}>
                              The first answer would be the default flow option if no time rule is defined, or none of
                              defined rules are satisfied.
                            </Popup>
                          </label>
                          <ModuleTimeBasedPreview
                            rules={formik?.values?.chat_property?.module_time_based_rules}
                            modules={modules}
                            onEdit={editTimeBasedRule}
                          />
                          <Dimmer active={!modules?.length} inverted>
                            <Loader inverted>Loading</Loader>
                          </Dimmer>
                        </Dimmer.Dimmable>
                      </If>
                    </If>
                  </>
                )}
              </Form>
            </UnloadPrompt>
          )}
        </Formik>
      </FormWrapper>
    </CommunityWrapper>
  );
};
