import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { ReactComponent as LogoType } from 'assets/images/new-logotype.svg';
import { WEB_REGEX, STATUSES } from 'constants';

import { parseErrorResponse } from 'apis/utils';
import { recaptcha } from 'apis';

import { WelcomeForm } from './WelcomeForm';
import { WebsiteScanningAnimation } from './WebsiteScanningAnimation';
import { OnboardingContext } from '../OnboardingContext';
import { Container } from './common';

const WelcomeFormContainer = styled.div`
  display: ${(p) => (p.visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 100%;
  padding-bottom: 30px;
  width: 100%;
`;

const VALIDATION_SCHEMA = Yup.object({
  website: Yup.string().required('Please enter a website').matches(WEB_REGEX, 'Website is not valid'),
  name: Yup.string().required('Please enter a community name'),
});

const RECAPTCHA_SITE_KEY = '6Ldn7wcoAAAAAAiObtHL8222eR3I1t7n1-ARB2HO';
const RECAPTCHA_ACTION = 'verify';

export const Welcome = () => {
  const { onBoardingData, startOnboarding } = useContext(OnboardingContext);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);

  const initialValues = {
    name: onBoardingData?.name || '',
    website: onBoardingData?.website || '',
  };

  useEffect(() => {
    // test recaptcha on load
    window?.grecaptcha?.execute(RECAPTCHA_SITE_KEY, { action: 'verify' }).then(async (token) => {
      const data = await recaptcha({
        recaptcha_token: token,
      });
      toggleRecaptchaClassOnBody();
      if (data?.score > 0.3 && data?.action === RECAPTCHA_ACTION) {
        setRecaptchaVerified(true);
      } else {
        toast.error('Recaptcha failed. Please try again');
      }
    });
  }, []);

  const toggleRecaptchaClassOnBody = () => {
    const RECAPTCHA_CLASS = 'recaptcha-visible';
    const hasRecaptchaClass = document.body.classList.contains(RECAPTCHA_CLASS);
    if (!hasRecaptchaClass) {
      document.body.classList.add(RECAPTCHA_CLASS);
    } else {
      document.body.classList.remove(RECAPTCHA_CLASS);
    }
  };

  const onFormSubmit = async (values, { setFieldError }) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      await startOnboarding(values);
      toggleRecaptchaClassOnBody();
    } catch (e) {
      setLoaderStatus(STATUSES.ERROR);
      console.log('error', e);
      setFieldError('website', parseErrorResponse(e));
    }
  };

  return (
    <Container maxWidth="35.7143rem">
      <WelcomeFormContainer visible={loaderStatus !== STATUSES.LOADING}>
        <LogoType width={28} height={28} />
        <h2>Welcome!</h2>
        <p>
          Engage and delight every lead that visits your website with a Virtual Assistant. <br />
          Ready to build your own? Let’s get started!
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          validationSchema={VALIDATION_SCHEMA}
          enableReinitialize
        >
          <WelcomeForm disabled={loaderStatus === STATUSES.LOADING} recaptchaVerified={recaptchaVerified} />
        </Formik>
      </WelcomeFormContainer>
      <WebsiteScanningAnimation visible={loaderStatus === STATUSES.LOADING} />
    </Container>
  );
};
