import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Table, Button } from 'components/lib';
import { MODULE_TYPES, MODULE_ROLES, DAYS_OF_WEEK } from 'components/chats/ChatModules/constants';

const Time = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: #89919d;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TimeDisplay = ({ time }) => {
  const timeInt = parseInt(time);
  if (isNaN(timeInt)) return '-';

  if (timeInt === 12) return 'Noon';

  if (timeInt === 0) return 'Midnight';

  return timeInt > 12 ? `${timeInt - 12}:00 PM` : `${timeInt}:00 AM`;
};

const getModuleList = (modules) =>
  modules
    .filter((m) => m.module_type === MODULE_TYPES.OPTION && m.role === MODULE_ROLES.TIME_RULE_BASED_FLOW)
    .map((m) => ({
      id: m.id,
      moduleNumber: m.number,
      moduleType: m.module_type,
      answers: m.answers,
    }));

export const ModuleTimeBasedPreview = ({ rules, modules, onEdit }) => {
  return (
    <Table>
      <If condition={rules.length}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Module</Table.HeaderCell>
            <Table.HeaderCell>Option</Table.HeaderCell>
            {DAYS_OF_WEEK.map((day, i) => (
              <Table.HeaderCell key={i} textAlign="center">
                {day.substring(0, 3)}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rules.map((rule, i) => {
            const module = getModuleList(modules).find((m) => m.id === rule.module_id);
            const answer =
              module?.answers.find((a) => a.id === rule.answer_id) ||
              module?.answers.find((a) => a.legacy_answer_id === rule.answer_id);
            return (
              <Table.Row key={i}>
                <Table.Cell>
                  <div>Module {module?.moduleNumber}</div>
                  <div>[{module?.moduleType}]</div>
                </Table.Cell>
                <Table.Cell>
                  <div>
                    {answer?.text} [{answer?.rank + 1}]
                  </div>
                </Table.Cell>
                {DAYS_OF_WEEK.map((day, i) => (
                  <Table.Cell>
                    <Time>
                      <span>
                        <TimeDisplay time={rule?.active_periods?.[day]?.[0]} />
                      </span>
                      <span>
                        <TimeDisplay time={rule?.active_periods?.[day]?.[1]} />
                      </span>
                    </Time>
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>
      </If>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan={9} textAlign="right">
            <Button color="blue" onClick={onEdit} type="button">
              {rules.length === 0 ? 'Add rule' : 'Edit'}
            </Button>
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};
