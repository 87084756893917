import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Button } from 'components/lib';
import { STEPS } from './constants';

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  border-top: 1px solid var(--line-standard);
`;

const StepActions = ({ state, disabled, handleClose, loading, onSave, onNext }) => {
  const { currentStep } = state;
  const step = STEPS[currentStep - 1];
  return (
    <Footer>
      <If condition={!step?.hasSave}>
        <Button primary color="blue" disabled={disabled} onClick={onNext} loading={loading}>
          Next
        </Button>
      </If>
      <If condition={step?.hasSave}>
        <Button primary color="blue" disabled={disabled} onClick={onSave} loading={loading}>
          Save
        </Button>
      </If>
      <Button primary onClick={handleClose}>
        Cancel
      </Button>
    </Footer>
  );
};

export default StepActions;
