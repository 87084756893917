import React, { useState } from 'react';
import styled from 'styled-components';

import { formatAnyDate } from 'components/utils';
import { MyProfileField } from './MyProfileField';
import { MyProfileButton } from './MyProfileButton';
import { MyProfilePasswordModal } from './MyProfilePasswordModal';

const Container = styled.div`
  display: flex;
  padding: 30px 62px 0 148px;
`;

export const MyProfilePassword = ({ data = {} }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const title = data?.hasPassword ? 'Change' : 'Create';
  const passwordUpdatedOn = data?.hasPassword
    ? `${data?.passwordUpdatedOn ? `Last updated ${formatAnyDate(data?.passwordUpdatedOn, 'MMM dd yyyy')}` : ''}`
    : 'Password not created yet';

  return (
    <Container>
      <MyProfileField label="Password" text={passwordUpdatedOn} />
      <MyProfileButton iconName="key" text={title} onClick={handleOnClick} />
      <MyProfilePasswordModal open={isModalOpen} onClose={handleCloseModal} data={data} />
    </Container>
  );
};
