import React, { useContext } from 'react';

import { Message } from 'components/lib';
import { ToursContext } from './ToursContext';
import { TourDetails } from './TourDetails';
import { CONNECTED_CALENDARS_VALUES } from '../../constants';

export const TourPreview = () => {
  const { tour } = useContext(ToursContext);

  if (tour?.lead?.community?.connected_calendar !== CONNECTED_CALENDARS_VALUES.BASIC.value) {
    return <Message info>Trouble fetching calendar request information</Message>;
  }

  return <TourDetails />;
};
