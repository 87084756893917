import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { Button, Input, Modal } from 'components/lib';
import { InputField } from 'components/common';
import { STATUSES } from '../../../constants';
import { usePhoneSystem } from './PhoneSystemContext';

const ModalBody = styled.div`
  padding: 30px;
`;

const Header = styled(Modal.Header)`
  &&& {
    border-bottom: none;
    padding-bottom: 0;
    h6 {
      margin: 0;
      font-family: var(--font-family);
      font-size: 14px;
      color: #89919d;
    }
  }
`;

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  label {
    flex: 0 0 100px;
  }
  .field {
    width: 100%;
    .input {
      width: 100%;
    }
  }
`;

export const GlobalQuerySelectorModal = ({ isModalOpen, onSave, onClose }) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { globalQuerySelector, fetchGlobalQuerySelector } = usePhoneSystem();

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    try {
      await onSave(values.global_web_selector);

      handleClose(resetForm);
      setLoaderStatus(STATUSES.LOADED);
    } catch (error) {
      setLoaderStatus(STATUSES.ERROR);
      toast.error('Unable to save global web selector');
    }
  };

  const handleClose = (resetForm) => {
    fetchGlobalQuerySelector();
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const initialValues = { global_web_selector: globalQuerySelector };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue }) => (
        <Modal
          closeIcon
          loading={loading}
          open={isModalOpen}
          onClose={() => handleClose(resetForm)}
          size="tiny"
          width={540}
        >
          <Header>
            Global Web Selector
            <h6>Applies everywhere unless overridden on community</h6>
          </Header>
          <ModalBody>
            <BodyText>Enter a web selector where the phone numbers will be inserted.</BodyText>
            <InputContainer>
              <label>Web Selector</label>
              <InputField
                control={Input}
                name="global_web_selector"
                value={values.global_web_selector}
                onChange={(e) => setFieldValue('global_web_selector', e.target.value)}
              />
            </InputContainer>
          </ModalBody>
          <Modal.Actions>
            <Button primary color="blue" onClick={handleSubmit} disabled={!dirty || !touched}>
              Save
            </Button>
            <Button basic onClick={() => handleClose(resetForm)}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};
