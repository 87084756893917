import React, { useContext, useEffect, useState } from 'react';
import { Dimmer, Grid, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { useHistory, useParams } from 'react-router-dom';
import { AnalyticsChatModulesMenu } from './AnalyticsChatModulesMenu';
import { AnalyticsChatModulesForm } from './AnalyticsChatModulesForm';
import { AnalyticsChatIntegrations } from './AnalyticsChatIntegrations';
import { STATUSES } from '../../../constants';
import { getChatAnalyticsIntegrations } from '../../../apis';
import { ChatsContext } from '../ChatsContext';
import { If } from '../../If';

const Wrapper = styled.div`
  padding-top: 30px;
`;

export const AnalyticsChatModules = () => {
  const { chatId } = useParams();
  const { chats } = useContext(ChatsContext);

  const history = useHistory();

  const [integrations, setIntegrations] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results } = await getChatAnalyticsIntegrations(chatId);
        setIntegrations(results);
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        setLoaderStatus(STATUSES.FAILURE);
        console.error(e);
      }
    };

    if (chatId) {
      fetchModules();
    } else if (chats) {
      history.push(`/chats/analytics/details/${chats[0].id}`);
    }
  }, [chatId, chats, history]);

  return (
    <Wrapper>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <AnalyticsChatModulesMenu />
          </Grid.Column>
          <Grid.Column width={12}>
            <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>

            <If condition={loaderStatus === STATUSES.LOADED}>
              <AnalyticsChatModulesForm integrations={integrations} />
              <AnalyticsChatIntegrations integrations={integrations} />
            </If>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Wrapper>
  );
};
