import React from 'react';
import styled from 'styled-components';
import { Button, Grid, Icon, Modal } from '../../../lib';
import { ProfileCircle } from '../../../common/profile/ProfileCircle';
import { useCampaignDetails } from '../CampaignDetailsContext';

const COMMUNITIES_TITLE_COLOR = {
  warning: '#fff1ed',
  regular: '#e1fff4',
};

const CommunitiesTitle = styled.div`
  background-color: ${({ type }) => COMMUNITIES_TITLE_COLOR[type]};
  padding: 1px 10px;

  > span:last-of-type {
    font-size: 0.7rem;
    margin-left: ${({ type }) => (type === 'warning' ? '-6px' : '10px')};
    font-weight: 900;
  }
  display: flex;
  align-items: center;
  align-content: start;
`;

const ModalContent = styled(Modal.Content)`
  &&& {
    padding: 20px 30px;
  }
`;

const CommunitiesColumn = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const CommunityRow = styled(Grid.Row)`
  display: flex;
  align-items: center;
  margin: 10px 0 0 10px;
  & p {
    margin-left: 10px;
    line-height: 12px;
    padding-top: 2px;
  }
  font-size: 0.85rem;
`;

const Community = ({ name }) => (
  <CommunityRow>
    <ProfileCircle size="18" name={name} />
    <p>{name}</p>
  </CommunityRow>
);

export const ReviewCommunitiesModal = ({ isModalOpen, onClose }) => {
  const {
    state: { surveyMessageWarnings },
  } = useCampaignDetails();

  if (!surveyMessageWarnings) {
    return null;
  }

  return (
    <Modal open={isModalOpen} onClose={onClose} closeIcon>
      <Modal.Header>Review Communities</Modal.Header>
      <ModalContent>
        <p>
          Make sure that the targeted communities for this message are assigned both to the selected web assistant and
          this campaign, or their leads will not get messages.
        </p>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <CommunitiesTitle type="regular">
                <Icon name="check" color="var(--success)" size="16" />
                <span>Using both web assistant and campaign</span>
              </CommunitiesTitle>
              <CommunitiesColumn>
                {surveyMessageWarnings.bothCommunities.map(({ id, name }) => (
                  <Community key={id} name={name} />
                ))}
              </CommunitiesColumn>
            </Grid.Column>
            <Grid.Column>
              <CommunitiesTitle type="warning">
                <Icon name="error_outlined" color="#fa7550" size="16" />
                <span>Using only this web assistant</span>
              </CommunitiesTitle>
              <CommunitiesColumn>
                {surveyMessageWarnings.chatOnlyCommunities.map(({ id, name }) => (
                  <Community key={id} name={name} />
                ))}
              </CommunitiesColumn>
            </Grid.Column>
            <Grid.Column>
              <CommunitiesTitle type="warning">
                <Icon name="error_outlined" color="#fa7550" size="16" />
                <span>Using only this campaign</span>
              </CommunitiesTitle>
              <CommunitiesColumn>
                {surveyMessageWarnings.campaignOnlyCommunities.map(({ id, name }) => (
                  <Community key={id} name={name} />
                ))}
              </CommunitiesColumn>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ModalContent>
      <Modal.Actions>
        <Button basic onClick={onClose}>
          Back
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
