import React, { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import { getChat } from 'api';
import { STATUSES } from '../../../constants';
import { If } from '../../If';
import { AssignCommunityRegular } from './AssignCommunityRegular';
import { AssignCommunityEmbedded } from './AssignCommunityEmbedded';
import { AssignCommunityVoice } from './AssignCommunityVoice';
import { VSA_TYPE } from '../constants';
import { AssignCommunitySurvey } from './AssignCommunitySurvey';

export const AssignCommunity = () => {
  const { chatId } = useParams();
  const [chat, setChat] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(STATUSES.IDLE);

  useEffect(() => {
    const getChatById = async () => {
      try {
        setLoadingStatus(STATUSES.LOADING);
        const chatObj = await getChat(chatId);
        setChat(chatObj);
        setLoadingStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        setLoadingStatus(STATUSES.FAILURE);
      }
    };

    getChatById();
  }, [chatId]);

  return (
    <>
      <If condition={loadingStatus === STATUSES.LOADING}>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </If>
      <If condition={chat?.vsa_type === VSA_TYPE.REGULAR}>
        <AssignCommunityRegular />
      </If>
      <If condition={chat?.vsa_type === VSA_TYPE.EMBEDDED}>
        <AssignCommunityEmbedded />
      </If>
      <If condition={chat?.vsa_type === VSA_TYPE.SURVEY}>
        <AssignCommunitySurvey />
      </If>
      <If condition={chat?.vsa_type === VSA_TYPE.PHONE}>
        <AssignCommunityVoice />
      </If>
    </>
  );
};
