import React, { useState } from 'react';
import { useField } from 'formik';
import { Input } from 'semantic-ui-react';

const stripUnit = (value, unit) => {
  if (typeof value !== 'string') {
    return [value, false];
  }

  const stripped = value.replace(unit, '');
  const parsed = parseInt(stripped);

  if (Number.isNaN(parsed)) {
    return [value, false];
  }

  return [parsed, true];
};

export const UnitInputField = ({ unit, onError, onChanged, ...props }) => {
  const [field, meta, { setValue }] = useField(props);
  const [unitlessValue, parsedSuccessfully] = stripUnit(field.value);
  const { touched, error } = meta;
  const { onBlur } = field;

  const handleChange = (e, d) => {
    const value = d ? d.value : e.target.value;

    setValue(`${value}${unit}`);

    if (typeof onChanged === 'function') {
      onChanged(value);
    }
  };

  const handleBlur = (e) => {
    if (!e.target.name) {
      e.target.name = props?.name;
    }
    onBlur(e);
  };

  if (error && touched) {
    if (typeof onError === 'function') {
      onError(field.name);
    }
  }

  return (
    <Input
      style={{ position: 'relative' }}
      {...props}
      type={parsedSuccessfully ? 'number' : 'text'}
      value={parsedSuccessfully ? unitlessValue : ''}
      label={{ content: unit }}
      labelPosition="right"
      error={touched && error}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
