import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, OptionsList } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { ConversationContext } from '../ConversationContext';
import { CONVERSATION_ACTIONS } from './constants';
import { ConversationModalContent, ConversationModalHeader } from './ConversationModal';

export const MoreOptionsModal = () => {
  const { t } = useTranslation();
  const { activeModal, showModal, eventTypeName } = useContext(ConversationContext);
  const [selected, setSelected] = useState(null);

  const OPTIONS = [
    {
      id: CONVERSATION_ACTIONS.RESCHEDULE,
      title: `Reschedule ${eventTypeName}`,
      description: `Choose this if you have been in touch with the ${t(
        'lead'
      )} and you have agreed on a different date. The ${eventTypeName} will be confirmed immediately.`,
      value: CONVERSATION_ACTIONS.RESCHEDULE,
    },
    {
      id: CONVERSATION_ACTIONS.ALTERNATIVE_DATES,
      title: 'Offer Alternative Dates',
      description: `Choose this if you have not been in touch with the ${t(
        'lead'
      )} and would like them to choose from up to 3 alternative dates.`,
      value: CONVERSATION_ACTIONS.ALTERNATIVE_DATES,
    },
    {
      id: CONVERSATION_ACTIONS.CANCEL,
      title: `Cancel ${eventTypeName}`,
      description: `The ${eventTypeName} will be canceled.`,
      value: CONVERSATION_ACTIONS.CANCEL,
    },
  ];

  const handleSetOption = (option) => {
    setSelected(option);
  };

  const handleNextModalAction = () => {
    showModal(selected);
  };

  const nextModalActionDisabled = !selected;

  return (
    <Modal
      closeIcon
      onClose={() => showModal(null)}
      onOpen={() => showModal(CONVERSATION_ACTIONS.MORE_OPTIONS)}
      open={activeModal === CONVERSATION_ACTIONS.MORE_OPTIONS}
      size="tiny"
    >
      <ConversationModalContent>
        <ConversationModalHeader>
          <h4>{`More ${capitalize(eventTypeName)} Options`}</h4>
        </ConversationModalHeader>
        <OptionsList options={OPTIONS} onChange={handleSetOption} />
      </ConversationModalContent>

      <Modal.Actions>
        <Button primary color="blue" onClick={() => handleNextModalAction()} disabled={nextModalActionDisabled}>
          Next
        </Button>
        <Button primary onClick={() => showModal(null)}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
