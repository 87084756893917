import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import { UserContext } from 'components/UserContext';
import { WithTopBar } from 'components/WithTopBar';
import { getDefaultRouteForUser } from 'components/common/routes/common';
import { getLoggedInUser } from 'api';
import { parseErrorResponse } from 'apis/utils';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import { STATUSES } from '../../constants';

const SupportMode = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(STATUSES.IDLE);
  const [error, setError] = useState();
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    const getUser = async () => {
      try {
        setLoader(STATUSES.LOADING);
        const user = await getLoggedInUser();
        const redirectRoute = getDefaultRouteForUser(user);

        setUser(user);
        setLoader(STATUSES.LOADED);

        history.push(redirectRoute);
      } catch (e) {
        setLoader(STATUSES.FAILURE);
        setError(parseErrorResponse(e, 'Unable to activate support mode. Please try again'));
      }
    };

    getUser();
  }, []);

  return (
    <WithTopBar>
      <Segment basic>
        <Dimmer active={loader === STATUSES.LOADING} inverted>
          <Loader active />
        </Dimmer>
        <If condition={loader === STATUSES.FAILURE}>
          <FailureMessage icon="warning" content={error} />
        </If>
      </Segment>
    </WithTopBar>
  );
};

export default SupportMode;
