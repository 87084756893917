import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.svg`
  position: relative;
  top: 0.15rem;
  fill: ${(p) => p.color || 'white'};
  height: 2.5rem;
`;

export const Logo = ({ color, ...otherProps }) => (
  <Wrapper
    {...otherProps}
    color={color}
    version="1.1"
    id="further-logo"
    x="0px"
    y="0px"
    viewBox="0 0 126 21"
    enableBackground="new 0 0 126 21"
  >
    <g transform="translate(.365)" fillRule="nonzero" fill="none">
      <path
        d="M66.204 7.73V7.7a3.183 3.183 0 0 0-.92-2.25A4.601 4.601 0 0 0 62 4.283h-4.89v11.498h1.506v-4.48h3l3.442 4.48h1.907l-3.643-4.718c1.815-.443 2.882-1.652 2.882-3.333zm-7.58-1.987h3.347c1.744 0 2.744.732 2.744 2.003v.034c0 1.28-1.096 2.112-2.79 2.112H58.64l-.016-4.15zM29.02 15.769h1.505v-4.881h6.099V9.445h-6.099V5.73h6.793V4.283H29.02zM49.753 10.934c0 2.304-1.15 3.576-3.237 3.576s-3.296-1.33-3.296-3.652V4.283h-1.506v6.65c0 3.087 1.828 5.02 4.773 5.02 2.944 0 4.768-1.966 4.768-5.111V4.283h-1.506l.004 6.65zM121.353 11.05c1.815-.418 2.882-1.639 2.882-3.32V7.7a3.2 3.2 0 0 0-.92-2.25 4.601 4.601 0 0 0-3.284-1.167h-4.881v11.498h1.506v-4.48h2.999l3.438 4.48H125l-3.647-4.73zm-4.697-5.307h3.346c1.744 0 2.744.732 2.744 2.003v.034c0 1.28-1.096 2.112-2.79 2.112h-3.284l-.016-4.15zM102.497 10.704h6.178V9.26h-6.178V5.73h6.868V4.283h-8.374V15.77h8.454v-1.443h-6.948zM71.629 5.73h3.76V15.77h1.506V5.73h3.756V4.283h-9.022zM93.613 9.273h-6.199v-4.99h-1.506V15.77h1.506V10.72h6.199v5.049h1.506V4.283h-1.506z"
        fill="#FFF"
      />
      <path
        d="M14.865 5.567H2.785A2.786 2.786 0 0 1 0 2.781 2.786 2.786 0 0 1 2.786 0h17.638l-5.559 5.567z"
        fill="#F97C6B"
      />
      <path
        d="M7.428 12.996H2.786A2.786 2.786 0 0 1 0 10.21a2.786 2.786 0 0 1 2.786-2.794H13l-5.572 5.58z"
        fill="#F6D052"
      />
      <circle fill="#255DB7" cx="2.807" cy="17.417" r="2.807" />
    </g>
  </Wrapper>
);
