import React from 'react';
import styled from 'styled-components';

export const MESSAGE_PILL_TYPE = {
  USER_SMS: {
    container: {
      backgroundColor: 'rgba(10, 183, 68, 0.15)',
    },
    text: {
      color: 'var(--emerald-green)',
    },
  },
  USER_EMAIL: {
    container: {
      backgroundColor: 'rgba(15, 113, 253, 0.15)',
    },
    text: {
      color: '#4c81d2',
    },
  },
  USER_VSA: {
    container: {
      backgroundColor: 'rgba(137, 145, 157, 0.18)',
    },
    text: {
      color: '#6c7481',
    },
  },
  FURTHER_SMS: {
    container: {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
    text: {
      color: '#24892d',
    },
  },
  FURTHER_EMAIL: {
    container: {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
    text: {
      color: 'var(--vivid-blue)',
    },
  },
  SCHEDULED_MESSAGE: {
    container: {
      backgroundColor: 'rgba(10, 183, 68, 0.15)',
    },
    text: {
      color: 'var(--emerald-green)',
      fontWeight: '900px',
    },
  },
};

const Text = styled.span`
  height: 12px;
  font-family: var(--font-family-bold);
  font-size: 9px;
  text-align: center;
  line-height: 14px;
`;

const Container = styled.div`
  height: 16px;
  padding: 2px 3px;
  border-radius: 9px;
  min-width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MessageTypePill = ({ type, text }) => (
  <Container style={type.container}>
    <Text style={type.text}>{text}</Text>
  </Container>
);
