import React, { useState } from 'react';
import styled from 'styled-components';
import { formatRelative } from 'date-fns';
import { enUS } from 'date-fns/locale';
import cn from 'classnames';

import { useCheckMobileScreen } from 'utils/hooks';
import { UserCircle } from 'components/UserCircle';
import { Button, Icon } from 'components/lib';
import RemoveScheduledMessageModal from 'components/conversation/RemoveScheduledMessageModal';
import { FORMAT_RELATIVE_LOCALE } from '../constants';

import { MEDIA_BREAK_POINTS } from '../../../constants';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 20px;
  align-items: flex-start;
  border-top: solid 1px var(--line-standard);
  &:last-child {
    border-bottom: solid 1px var(--line-standard);
  }
  &.active {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      background-color: var(--salmon);
    }
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    align-items: center;
    padding: 20px 30px;
    flex-direction: row;
  }
`;

const StyledUserCircle = styled(UserCircle)`
  display: none;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    display: block;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1 0 auto;
  margin: 0 0 10px 0;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: column;
    margin: 0 16px;
  }
`;

const LeadInfo = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span,
  strong,
  i {
    font-family: var(--font-family);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--text);
  }
  strong {
    font-family: var(--font-family-bold);
  }
  i {
    font-size: 10px;
    margin: 0 5px;
    display: none;
  }
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    align-items: center;
    flex-direction: row;
    i {
      display: block;
    }
  }
`;

const MessageInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  min-width: 0;

  span:not(.material-icons-outlined) {
    margin-left: 5px;
    font-family: var(--font-family);
    word-break: keep-all;
    margin-right: 10px;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--text);
  }
  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-family: var(--font-family);
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--light-text);
    width: calc(100vw - 611px);
  }
`;

export const ScheduledMessage = ({ scheduledMessage, onRemove }) => {
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const isMobile = useCheckMobileScreen();
  const locale = {
    ...enUS,
    formatRelative: (token) => FORMAT_RELATIVE_LOCALE[token],
  };
  const formatDate = formatRelative(new Date(scheduledMessage.sendCampaignMessageAt), new Date(), { locale });

  const handleRemove = () => {
    setOpenRemoveModal(false);
    onRemove(scheduledMessage);
  };

  const handleOpenRemoveModal = () => {
    setOpenRemoveModal(true);
  };

  const handleCloseRemoveModal = () => {
    setOpenRemoveModal(false);
  };

  const { lead, community, campaignMessage, isToday } = scheduledMessage;
  const color = `var(--${isToday ? 'salmon' : 'text'})`;

  return (
    <Container className={cn({ active: isToday })}>
      <StyledUserCircle user={{ first_name: lead.firstName, last_name: lead.lastName, email: lead.email }} />
      <Content>
        <LeadInfo href={`/conversations/${lead.id}`} target="_blank">
          <strong>
            {lead.firstName} {lead.lastName}
          </strong>
          <i>•</i>
          <span>{community.name}</span>
        </LeadInfo>
        <MessageInfo>
          <Icon name="campaign" color={color} variant="outlined" size="16" />
          <span style={{ color }}>{formatDate}</span>
          <h2 title={campaignMessage.subject}>{campaignMessage.message}</h2>
        </MessageInfo>
      </Content>
      <Button fluid={isMobile} basic content="Remove" onClick={handleOpenRemoveModal} />
      <RemoveScheduledMessageModal
        open={openRemoveModal}
        leadName={`${lead.firstName} ${lead.lastName}`}
        onConfirm={handleRemove}
        onClose={handleCloseRemoveModal}
      />
    </Container>
  );
};
