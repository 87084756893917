import React from 'react';
import styled from 'styled-components';

import { formatPhoneNumber } from '../utils';

const PhoneNumbersContainer = styled.div`
  color: var(--text);
  font-weight: 500;
`;

const PhoneNumberLabel = styled.span`
  color: var(--link) !important;
  > .separator {
    color: var(--text);
  }
`;

export const PhoneNumbersList = ({ phoneNumbers }) => {
  if (!phoneNumbers || phoneNumbers.length === 0 || !phoneNumbers[0]) {
    return <PhoneNumbersContainer>-</PhoneNumbersContainer>;
  }

  return (
    <PhoneNumbersContainer>
      {phoneNumbers.map((phoneNumber, index) => (
        <PhoneNumberLabel key={index}>
          {formatPhoneNumber(phoneNumber)}
          {index < phoneNumbers.length - 1 && <span className="separator">,&nbsp;</span>}
        </PhoneNumberLabel>
      ))}
    </PhoneNumbersContainer>
  );
};
