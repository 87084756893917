import React, { useContext } from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { CommunityPricingTable } from './CommunityPricingTable';
import { MessageContext } from './MessageContext';
import { EmailRequest } from './calendar/EmailRequest';
import { BudgetQualificationTable } from './BudgetQualificationTable';

const HeaderSegment = styled(Segment)`
  display: flex;
  justify-content: space-between;

  span {
    line-height: 2;
  }
`;

const StyledSegment = styled(Segment)`
  word-break: break-word;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const CommunityInformation = () => {
  const { lead, automatedAnswer } = useContext(MessageContext);

  return (
    <Segment.Group>
      <HeaderSegment>
        <span>Community</span>
        <Button size="mini" className="ui button">
          <Link to={`/admin/communities/${lead.community.id}/survey`} target="_blank" rel="noopener noreferrer">
            Edit Community Details
          </Link>
        </Button>
      </HeaderSegment>
      <StyledSegment secondary>
        <h5>{lead?.community?.name}</h5>
        <h5>
          Contact email:&nbsp;
          {(lead?.community?.sales_contact_emails && lead?.community?.sales_contact_emails[0]) || '-'}
        </h5>
        <h5>{lead?.community?.url}</h5>

        <CommunityPricingTable community={lead?.community} />

        <Actions>
          <Button
            fluid
            as={Link}
            to={`/settings/ia/${lead?.community.id}`}
            size="small"
            icon="edit"
            content="Edit Instant Answers"
          />
          <EmailRequest />
        </Actions>
      </StyledSegment>
    </Segment.Group>
  );
};
