import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const SidebarHeader = styled.div`
  width: 100%;
  height: 20px;
  padding: 2px 5px 2px 5px;
  background-color: #007aff;
  color: var(--white);
  font-family: var(--font-family-bold);
  font-size: 12px;
  margin-bottom: 15px;
`;

const SidebarBoxContent = styled.div`
  margin-bottom: 15px;
`;

const ContentSelectorWrapper = styled.div`
  display: flex;
  padding: 5px;
  margin-bottom: 15px;
`;

const TypeContainer = styled.div`
  height: 26px;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 13px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 1.5;
  background-color: var(--white);
  color: var(--text);
  text-transform: capitalize;
  cursor: pointer;

  &.active {
    background-color: var(--vivid-blue);
    color: var(--white);
  }
`;

export const Sidebar = ({ children }) => {
  return <SidebarWrapper>{children}</SidebarWrapper>;
};

export const SidebarBox = ({ title, children }) => {
  return (
    <div className="box">
      <SidebarHeader>{title}</SidebarHeader>
      <SidebarBoxContent>{children}</SidebarBoxContent>
    </div>
  );
};

export const SideBarTypeOptions = ({ optionTypes, selectedOptionType, setSelectedOptionType }) => {
  const { t } = useTranslation();
  return (
    <ContentSelectorWrapper>
      {optionTypes.map((type) => (
        <TypeContainer
          key={type}
          className={classNames({ active: selectedOptionType === type })}
          onClick={() => setSelectedOptionType(type)}
        >
          {t(type)}
        </TypeContainer>
      ))}
    </ContentSelectorWrapper>
  );
};
