import React from 'react';
import styled from 'styled-components';
import { TextArea } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { InputField, ToggleSwitch } from 'components/common';
import { Button, Form, Modal, Radio } from 'components/lib';
import { RatingField } from 'components/common/RatingField';
import { capitalize } from 'utils/helpers';

const ModalContent = styled(Modal.Content)`
  &&&& {
    padding: 30px;
    min-height: 200px;
  }
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
  &&&& label {
    font-weight: 700;
    font-size: 0.92rem;
    font-family: var(--font-family);
  }
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 7px;
  > label {
    margin-left: 10px;
    margin-right: 30px;
    padding-top: 6px;
    cursor: pointer;
  }
`;

const UserCommunityTestimonialsModal = ({ testimonial = null, open, loading, onSave, onClose }) => {
  const initialValues = {
    first_name: testimonial?.first_name || '',
    last_name: testimonial?.last_name || '',
    rating: testimonial?.rating || 5,
    text: testimonial?.text || '',
    show_on_embedded_vsa: testimonial?.show_on_embedded_vsa || false,
    type: testimonial?.type || 'testimonial',
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().when('type', {
      is: 'testimonial',
      then: Yup.string().required('First name is required'),
    }),
    last_name: Yup.string().when('type', {
      is: 'testimonial',
      then: Yup.string().required('Last name is required'),
    }),
    rating: Yup.number().when('type', {
      is: 'testimonial',
      then: Yup.number().required('Rating is required'),
    }),
    text: Yup.string().when('type', {
      is: 'testimonial',
      then: Yup.string().required('Testimonial text is required').max(350, `Can't be longer than the 350 characters.`),
      otherwise: Yup.string().required('Fact text is required').max(350, `Can't be longer than the 350 characters.`),
    }),
    type: Yup.string().required('Type is required'),
  });

  const setTestimonialItem = ({ first_name, last_name, rating, text, show_on_embedded_vsa, type }) => {
    onSave({ first_name, last_name, rating, text, show_on_embedded_vsa, type });
    onClose();
  };

  return (
    <Modal size="small" closeIcon open={open} onClose={onClose}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={setTestimonialItem}>
        {({ handleSubmit, isValid, dirty, setFieldValue, values }) => {
          const isTestimonial = values.type === 'testimonial';
          return (
            <>
              <Modal.Header>Set {capitalize(values.type)}</Modal.Header>
              <ModalContent scrolling>
                <Form onSubmit={handleSubmit}>
                  <Form.Group widths="equal">
                    <CustomRadio>
                      <Form.Field
                        id="testimonial"
                        control={Radio}
                        name="type"
                        value="testimonial"
                        label=""
                        checked={Boolean(values.type === 'testimonial')}
                        onChange={() => {
                          setFieldValue('type', 'testimonial');
                        }}
                      />
                      <label htmlFor="testimonial">Testimonial</label>
                    </CustomRadio>
                    <CustomRadio>
                      <Form.Field
                        id="fact"
                        control={Radio}
                        name="type"
                        value="fact"
                        checked={Boolean(values.type === 'fact')}
                        onChange={() => {
                          setFieldValue('type', 'fact');
                        }}
                      />
                      <label htmlFor="fact">Fact</label>
                    </CustomRadio>
                  </Form.Group>
                  {isTestimonial && (
                    <Form.Group widths="equal">
                      <Form.Field>
                        <InputField type="text" label="First Name" name="first_name" placeholder="First Name" />
                      </Form.Field>
                      <Form.Field>
                        <InputField type="text" label="Last Name" name="last_name" placeholder="Last Name" />
                      </Form.Field>
                    </Form.Group>
                  )}
                  <Form.Group widths="equal">
                    {isTestimonial && (
                      <Form.Field>
                        <RatingField name="rating" label="Rating" maxRating={5} />
                      </Form.Field>
                    )}
                    <Form.Field>
                      <StyledToggleSwitch name="show_on_embedded_vsa" label="Show on VSA" />
                    </Form.Field>
                  </Form.Group>
                  <Form.Field>
                    <InputField
                      control={TextArea}
                      type="text"
                      label={capitalize(values.type)}
                      name="text"
                      placeholder={`${capitalize(values.type)} Text`}
                    />
                  </Form.Field>
                </Form>
              </ModalContent>
              <Modal.Actions>
                <Button primary loading={loading} color="orange" onClick={handleSubmit} disabled={!isValid || !dirty}>
                  {!testimonial ? 'Add' : 'Edit'}
                </Button>
                <Button basic onClick={onClose} disabled={loading}>
                  Close
                </Button>
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default UserCommunityTestimonialsModal;
