import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { pluralize } from '../../../utils/helpers';

const CommunityName = styled.span`
  a {
    font-family: var(--font-family);
    color: var(--link);
    font-weight: 500;
  }
  font-size: 14px;
  letter-spacing: normal;
  line-height: normal;
`;

const SubHeader = styled.div`
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 14px;
  color: #89919d;
  margin-top: 8px;
`;

export const SettingsModalSelectedCommunity = ({ community, count }) => {
  const { t } = useTranslation();
  return (
    <>
      {community?.name ? (
        <CommunityName>
          <Link
            to={{
              pathname: `/communities/${community?.id}`,
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {community.name}
          </Link>
        </CommunityName>
      ) : (
        <SubHeader>{pluralize(count, t('communities'), t('community'))} selected</SubHeader>
      )}
    </>
  );
};
