import React from 'react';
import styled from 'styled-components';

import { BooleanSettingsPage } from '../common';

const ModalText = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  > div {
    font-size: 14px;
    color: var(--text);
    font-weight: 500;
  }
`;

export const OutbandCalls = () => {
  return (
    <BooleanSettingsPage
      flagName="enable_outbound_calls"
      modalText={
        <ModalText>
          <div>Activate the ability for salespeople to call prospects from the dashboard.</div> If this is on,
          salespeople will be able to start a call with prospects directly from the conversation screen on the
          dashboard.
        </ModalText>
      }
      modalTitle="Outbound Calls"
      modalSaveText="Save"
      settingsDescription={
        <span>
          Activate the ability for salespeople to <strong>call prospects</strong> from the dashboard.
        </span>
      }
    />
  );
};
