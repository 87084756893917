import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from '../lib';
import { ConversationsContext } from './ConversationsContext';

const StyledButton = styled(Button)`
  &&&& {
    color: var(--link);
  }
`;

export const ConversationsClearFilters = () => {
  const { handleFilterReset } = useContext(ConversationsContext);

  return (
    <StyledButton className="link" onClick={handleFilterReset}>
      Clear Filters
    </StyledButton>
  );
};
