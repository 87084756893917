import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { ProfileCircle } from 'components/common/profile/ProfileCircle';

const MessageContainer = styled.div`
  margin-bottom: 15px;
  font-size: 13px;
  color: var(--text);
`;

const InformantContainer = styled.div`
  height: 20px;
  background-color: #fff1ed;
  padding-left: 5px;

  display: flex;
  gap: 10px;
  align-items: center;

  .label {
    font-size: 10px;
    font-weight: 900;
    height: 100%;
    line-height: 2.5;
    color: var(--text);
  }
`;

const CommunityNameContainer = styled.div`
  width: ${(props) => props.width};
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .name {
    font-size: 12px;
    color: var(--text);
  }
`;

export const CommunityNameBox = ({ name, width = '100%' }) => {
  return (
    <CommunityNameContainer width={width}>
      <ProfileCircle name={name} parts={1} size="18" />
      <span className="name">{name}</span>
    </CommunityNameContainer>
  );
};

export const MessageBox = ({ children }) => <MessageContainer>{children}</MessageContainer>;

export const InformantBox = ({ text }) => (
  <InformantContainer>
    <Icon name="error_outline" color="var(--validation-red)" size={16} />
    <div className="label">{text}</div>
  </InformantContainer>
);
