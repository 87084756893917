import React from 'react';

import { InputField } from 'components/common/fields/InputField';
import Label from './Label';

const Any = ({ name, value, defaultValue, ...props }) => {
  return <InputField name={name} label={<Label name={value} defaultValue={defaultValue} />} {...props} />;
};

export default Any;
