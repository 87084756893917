import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';
import Condition from './Condition';

const GlobalStyles = createGlobalStyle`
  .ui.dimmer.modals:not(:last-child) {
    z-index: -1;
  }
`;

const ModalBody = styled.div``;

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 10px;
`;

const AddConditionModal = ({ isModalOpen, onClose, onChange, rules, currentRules }) => {
  const filteredRules = rules.filter((rule) => !currentRules.find((currentRule) => currentRule.ruleId === rule.id));
  return (
    <SettingsModal open={isModalOpen} onModalClose={onClose} size="tiny" width={540}>
      <SettingsModalHeader title="Add a Condition" type="header" hideCommunites />
      <ModalBody type="body">
        <GlobalStyles />
        <BodyText>
          {filteredRules.map((rule) => (
            <Condition key={rule.id} rule={rule} onChange={onChange} />
          ))}
        </BodyText>
      </ModalBody>
      <SettingsModalActions onCancel={onClose} type="actions" cancelText="Back" hideSave />
    </SettingsModal>
  );
};

export default AddConditionModal;
