export default {
  SET_LOADER_STATUS: 'SET_LOADER_STATUS',
  SET_UPDATE_LOADER_STATUS: 'SET_UPDATE_LOADER_STATUS',
  SET_QUESTIONS: 'SET_QUESTIONS',
  SET_QUESTIONS_COUNT: 'SET_QUESTIONS_COUNT',
  SET_ATTRIBUTES: 'SET_ATTRIBUTES',
  SET_FILTERS: 'SET_FILTERS',
  RESET_IGNORED_QUESTIONS_COUNT: 'RESET_IGNORED_QUESTIONS_COUNT',
  RESET_LABELED_QUESTIONS_COUNT: 'RESET_LABELED_QUESTIONS_COUNT',
  SET_CURRENT_QUESTION_ID: 'SET_CURRENT_QUESTION_ID',
  UPDATE_QUESTIONS_COUNT: 'UPDATE_QUESTIONS_COUNT',
  ADD_ATTRIBUTE: 'ADD_ATTRIBUTE',
  CHANGE_SORT: 'CHANGE_SORT',
};
