import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/lib';

const Wrapper = styled.div`
  font-family: var(--font-family);
  background-color: var(--light-salmon);
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border: solid 1px var(--salmon);
  border-radius: 1.5rem;
  box-shadow: 0 0.21rem 0.36rem 0 rgba(216, 216, 216, 0.85);
  padding: 1rem 2rem 1rem 3.5rem;
  overflow: hidden;
`;

const TitleIcon = styled(Icon)`
  position: absolute;
  top: 1rem;
  left: 1.6rem;
  margin-right: 0.5rem;
  color: var(--salmon);
`;

const Subtitle = styled.div`
  font-size: 0.9rem;
  color: var(--light-text);
`;

const HighlightedTitle = styled.span`
  color: var(--salmon);
`;

export const DisqualifiedNotification = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div>
        <TitleIcon name="money_off" size="16" />
        Based on the conversation with Further's Messaging Assistant, this {t('lead')} has indicated they are{' '}
        <HighlightedTitle>not qualified.</HighlightedTitle>
      </div>
      <Subtitle>
        If you would like to continue the conversation to ask additional questions, please reply. If you do not want to
        continue this conversation, please mark this {t('lead')} as closed in your CRM.
      </Subtitle>
    </Wrapper>
  );
};
