import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FieldArray, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, Dropdown, Form, Modal, Message, Accordion, Icon, Menu, Divider } from 'semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { updateCRMCommunity } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { InputField } from 'components/common/fields/InputField';
import { capitalize } from 'utils/helpers';
import { STATUSES } from '../../../constants';
import { useCRM, updateCommunity, fetchCommunityInvalidCRMSettings } from './context';
import CRMLocationCodeOverrideTable from './CRMLocationCodeOverrideTable';

const AccordionBox = styled.div`
  padding: 0 1rem;
`;

const LargeDivider = styled(Divider)`
  && {
    margin: 2rem 0;
  }
`;

const ActionBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionTitle = styled.div`
  div:first-child {
    font-size: 1.1rem;
    font-weight: bold;
  }

  div:nth-of-type(2) {
    font-size: 0.9rem;
    color: gray;
  }
`;

const CRMCommunityManageModal = ({ open, onClose, community, onRemove }) => {
  const { t } = useTranslation();
  const [{ userId, crmSettingsOptions }, dispatch] = useCRM();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [initialValues, setInitialValues] = useState();
  const [openOverride, setOpenOverride] = useState(false);

  useEffect(() => {
    if (community) {
      const crmLocationCodeOverrides = Object.assign([], community.crm_location_code_overrides);

      setInitialValues({
        crmLocationCode: community.crm_location_code || '',
        crmSettings: community.crm_settings_id,
        crmLocationCodeOverrides: crmLocationCodeOverrides
          .sort((a, b) => a.order > b.order)
          .map((c) => ({
            rule: c.rule_id,
            operation: c.operation,
            value: c.value || c.value_option,
            crmLocationCode: c.crm_location_code,
          })),
      });
      setOpenOverride(crmLocationCodeOverrides.length !== 0);
    }
  }, [community]);

  const validationSchema = Yup.object({
    crmLocationCode: Yup.string().required('CRM location code is required'),
    crmSettings: Yup.number().required('CRM settings is required'),
    crmLocationCodeOverrides: Yup.array()
      .notRequired()
      .of(
        Yup.object({
          rule: Yup.number().required('Rule is required'),
          operation: Yup.string().required('Operation is required'),
          value: Yup.string().required('Value is required'),
          crmLocationCode: Yup.string().required('CRM Location Code is required'),
        })
      ),
  });

  const handleSubmit = async (values) => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      const {
        crm_location_code,
        crm_settings_id,
        crm_settings_integration_name,
        crm_settings_name,
        crm_location_code_overrides,
      } = await updateCRMCommunity(userId, community.id, {
        crm_location_code: values.crmLocationCode,
        crm_settings: values.crmSettings,
        crm_location_code_overrides: values.crmLocationCodeOverrides.map((c, index) => ({
          rule_id: c.rule,
          operation: c.operation,
          value: c.value,
          crm_location_code: c.crmLocationCode,
          order: index,
        })),
      });

      dispatch(
        updateCommunity(
          community.id,
          crm_location_code,
          crm_settings_id,
          crm_settings_integration_name,
          crm_settings_name,
          crm_location_code_overrides
        )
      );
      toast.success(`${capitalize(t('community'))} CRM settings updated successfully`);

      if (community.has_errors) {
        await fetchCommunityInvalidCRMSettings(userId, dispatch);
      }

      setLoaderStatus(STATUSES.LOADED);
      onClose();
    } catch (e) {
      toast.error(parseErrorResponse(e, `Unable to update ${t('community')} CRM settings`));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const toggleOverride = () => {
    setOpenOverride(!openOverride);
  };

  if (!community || !initialValues) {
    return null;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Modal open={open} onClose={onClose} closeOnDimmerClick={loaderStatus !== STATUSES.LOADING} size="large">
          <Modal.Header>
            Update <b>{community.name}</b> CRM settings
          </Modal.Header>
          <Modal.Content>
            <Form loading={loaderStatus === STATUSES.LOADING}>
              <InputField name="crmLocationCode" label="CRM Location Code" />
              <InputField
                name="crmSettings"
                control={Dropdown}
                label="CRM Settings"
                options={crmSettingsOptions}
                placeholder="CRM Settings"
                fluid
                selection
              />

              <Accordion fluid as={Menu} vertical>
                <Accordion.Title active={openOverride} onClick={toggleOverride}>
                  <AccordionBox>
                    <Icon name="dropdown" />
                    CRM Location Code Override
                  </AccordionBox>
                </Accordion.Title>
                <Accordion.Content active={openOverride}>
                  <AccordionBox>
                    <Message
                      info
                      size="tiny"
                      header="The rules will be applied in this order"
                      content="You can change the order by moving rows."
                    />
                    <FieldArray name="crmLocationCodeOverrides">
                      {({ remove, push, swap }) => (
                        <CRMLocationCodeOverrideTable
                          crmLocationCodeOverrides={values.crmLocationCodeOverrides}
                          onSetFieldValue={setFieldValue}
                          onSwap={swap}
                          onPush={push}
                          onRemove={remove}
                        />
                      )}
                    </FieldArray>
                  </AccordionBox>
                </Accordion.Content>
              </Accordion>
            </Form>
            <LargeDivider />
            <ActionBox>
              <ActionTitle>
                <div>Remove CRM settings from {t('community')}</div>
                <div>Reset {t('community')} CRM settings to default</div>
              </ActionTitle>
              <Button
                disabled={
                  !community.crm_settings_id || community.inherits_crm_settings || loaderStatus === STATUSES.LOADING
                }
                content="Remove"
                color="orange"
                onClick={onRemove}
              />
            </ActionBox>
          </Modal.Content>
          <Modal.Actions>
            <Button disabled={loaderStatus === STATUSES.LOADING} onClick={onClose}>
              Cancel
            </Button>
            <Button primary onClick={handleSubmit} type="submit" disabled={loaderStatus === STATUSES.LOADING}>
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

export default CRMCommunityManageModal;
