import React from 'react';
import { useField } from 'formik';
import { Form } from 'semantic-ui-react';

/*
 * Semantic UI's Form.Radio isn't implemented using `<input type="radio" />`
 * Because of that, it doesn't work with Formik out-of-the-box.
 * */
export const Radio = (props) => {
  const [field, meta, helpers] = useField(props);

  return (
    <Form.Radio
      label="Chat is active"
      {...field}
      {...props}
      checked={meta.value === props.value}
      onChange={(e, { value }) => {
        helpers.setValue(value);
      }}
    />
  );
};
