import React from 'react';
import { Form } from 'semantic-ui-react';
import { MODULE_ROLES_OPTIONS } from '../../constants';
import { SelectField } from '../../../../common';

const ModuleFormRole = () => (
  <Form.Field>
    <label>Role</label>
    <SelectField fluid name="module.role" options={MODULE_ROLES_OPTIONS} placeholder="Role..." size="small" />
  </Form.Field>
);

export default ModuleFormRole;
