import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { loginPathWithNext } from 'utils/helpers';
import { UserContext } from '../../UserContext';

export const PermissionRoute = ({ permission, ...props }) => {
  const { user, hasPermission } = useContext(UserContext);

  if (!user) {
    const next = loginPathWithNext();
    return <Redirect to={next} />;
  }

  if (!hasPermission(permission)) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};
