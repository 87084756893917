import React, { useCallback, useRef, useEffect, useState } from 'react';

import { DEBOUNCE_TIMEOUT } from '../constants';
import { Icon, Input } from './lib';

const SearchInput = ({ value, placeholder, loading, onSearch, ...rest }) => {
  const timeoutRef = useRef();
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(value);
  }, [value]);

  const handleSearchChange = useCallback(
    (e, data) => {
      setSearch(data.value);
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        onSearch(data.value);
      }, DEBOUNCE_TIMEOUT);
    },
    [onSearch]
  );

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    []
  );

  return (
    <Input
      iconPosition="left"
      icon={<Icon name="search" size="22" color="var(--light-text)" />}
      onChange={handleSearchChange}
      loading={loading}
      placeholder={placeholder}
      value={search}
      {...rest}
    />
  );
};

export default SearchInput;
