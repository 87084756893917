import styled from 'styled-components';

export const NodeWrapper = styled.div`
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 25rem;
  z-index: 1;
  margin-right: 2rem;
  flex: 0 0 25rem;

  .ui.tertiary.segment {
    padding: 0;
  }
`;
