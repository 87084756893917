import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Radio as SemanticRadio } from 'semantic-ui-react';

const StyledRadio = styled(SemanticRadio)`
  && {
    display: flex;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // Toggle
    &.ui.toggle.checkbox {
      min-height: 26px;
    }

    &.ui.toggle.checkbox input {
      width: 50px;
      height: 26px;
      padding: 0;
    }

    &.ui.toggle.checkbox label {
      min-height: 26px;
    }

    &.ui.toggle.checkbox label:before {
      width: 50px;
      height: 26px;
      box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.25);
      border: solid 1px rgba(171, 171, 171, 0.29);
      background-image: linear-gradient(to bottom, #efefef, #dedbdb);
      transition: all 0.2s ease;
    }

    &.ui.toggle.checkbox label:after {
      left: 3px;
      top: 3px;
      width: 20px;
      height: 20px;
      border: none;
      transition: all 0.2s ease;
    }

    &.ui.checked.toggle.checkbox label:after {
      left: 26px;
      transition: all 0.2s ease;
    }

    &.ui.checked.toggle.checkbox label:before {
      transition: all 0.2s ease;
      box-shadow: inset 0 2px 4px 0 #3ea3b9;
      background: var(--highlight-blue) !important;
    }

    // Radio
    &.ui.radio.checkbox {
      min-height: 20px;
    }

    &.ui.radio.checkbox label {
      font-family: var(--font-family);
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--text);
      padding-top: 3px;
    }

    &.ui.radio.checkbox label:before {
      width: 20px;
      height: 20px;
    }

    &.ui.radio.checkbox input:checked ~ label:before {
      background-color: #0f71fd;
      border-color: #0f71fd;
    }

    &.ui.radio.checkbox input:checked ~ label:after {
      background-color: var(--white);
      top: 4px;
      left: 3px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }

    &.ui.radio.checkbox input:indeterminate ~ label:after {
      visibility: hidden;
    }
  }
`;

export const Radio = forwardRef((props, ref) => <StyledRadio ref={ref} {...props} />);
