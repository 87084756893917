import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const Container = styled.div`
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const HeaderContent = styled.p`
  font-size: 7rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const HeaderSubheader = styled.p`
  color: gray;
  margin-top: 3rem;
  text-align: center;
  line-height: 1.5rem;
`;

const CompletedStep = () => (
  <Container>
    <div>
      <HeaderContent>Thank you!</HeaderContent>
      <Icon name="check" color="green" size="massive" />
      <HeaderSubheader>
        Thank you for sharing your data. We will share the prices of your competitors in your area with you. We'll send
        you an email with pricing information so you can analyze them and make your prices more competitive in the
        marketplace.
      </HeaderSubheader>
    </div>
  </Container>
);

export default CompletedStep;
