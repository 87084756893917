export { Editor } from './Editor';

export const defaultEditorConfig = {
  height: 500,
  menubar: true,
  plugins: [
    'advtemplate',
    'autolink',
    'lists',
    'link',
    'image',
    'anchor',
    'searchreplace',
    'insertdatetime',
    'table',
    'mergetags',
    'code',
  ],
  statusbar: false,
  paste_data_images: false,
  toolbar:
    'undo redo | blocks | ' +
    'bold italic forecolor link | hr | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent' +
    'removeformat image inserttemplate',
  link_target_list: false,
};
