import React, { useContext } from 'react';
import { useField } from 'formik';
import { Form, Header, Dropdown } from 'semantic-ui-react';
import { ChatModulesContext } from './ChatModulesContext';

export const ActionField = (props) => {
  const [{ value }, , { setValue }] = useField(props);
  const { moduleGraph } = useContext(ChatModulesContext);
  const moduleList = moduleGraph.modules.map((m) => ({
    key: m.number,
    value: m.number,
    text: `MODULE ${m?.number}`,
    search: `MODULE ${m?.number} [${m?.module_type} ${m?.text}]`,
    content: <Header as="h5" content={`MODULE ${m?.number} [${m?.module_type}]`} subheader={m?.text} />,
  }));

  const onChange = (e, d) => {
    d.value === '' ? setValue(null) : setValue(d.value);
  };

  return (
    <Form.Field>
      <label>Action</label>
      <Dropdown
        fluid
        selection
        clearable
        options={moduleList}
        value={value}
        onChange={onChange}
        search={(options, query) => options.filter((o) => o.search.toLowerCase().includes(query.toLowerCase()))}
      />
    </Form.Field>
  );
};
