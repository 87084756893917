import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Dimmer, Loader } from 'components/lib';
import { If } from 'components/If';

const Wrapper = styled.div`
  margin-bottom: 25px;
`;

const StatisticsBar = styled.div`
  display: flex;
  justify-content: flex-start;

  .item {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex-grow: 1;
  }
`;

const StatisticsBarItem = styled.div`
  display: flex;
`;

const MainColoredBar = styled.div`
  background-color: ${({ color }) => color};
  width: 15px;
  height: 3px;
`;

const ShadowColoredBar = styled.div`
  width: 160px;
  height: 3px;
  background-color: ${({ color }) => color};
`;

const StatisticsBarDetails = styled.div``;

const NameCell = styled.div`
  color: ${({ color }) => color || 'var(--text)'};
  font-size: 12px;
  font-style: var(--font-family);
  font-weight: 900;
  letter-spacing: 0.5px;

  text-transform: uppercase;
  margin-bottom: 10px;
`;

const NumberCell = styled.div`
  color: ${({ color }) => color || 'var(--text)'};
  font-size: 26px;
  font-weight: 500;
  font-style: var(--font-family-bold);
  letter-spacing: normal;
`;

const Sign = styled.span`
  color: ${({ color }) => color || 'var(--text)'};
  font-size: 12px;
  font-weight: 500;
  font-style: var(--font-family-bold);
  letter-spacing: normal;
`;

export const StatisticsOverview = ({ statistics, loading = true }) => {
  const shouldShowItem = statistics && !loading;
  const { t } = useTranslation();

  return (
    <Dimmer.Dimmable as={Wrapper} dimmed={loading}>
      <Dimmer active={loading} inverted>
        <Loader active inverted />
      </Dimmer>
      <StatisticsBar>
        <div className="item">
          <StatisticsBarItem>
            <MainColoredBar color="#5f5fff" />
            <ShadowColoredBar color="rgba(137, 145, 157, 0.22)" />
          </StatisticsBarItem>
          <StatisticsBarDetails>
            <NameCell color="#5f5fff">{t('leads')}</NameCell>
            <If condition={shouldShowItem}>
              <NumberCell>{statistics?.leadsCount || 0}</NumberCell>
            </If>
          </StatisticsBarDetails>
        </div>
        <div className="item">
          <StatisticsBarItem>
            <MainColoredBar color="#89919d" />
            <ShadowColoredBar color="rgba(34, 207, 209, 0.22)" />
          </StatisticsBarItem>
          <StatisticsBarDetails>
            <NameCell color="#89919d">Reply rate</NameCell>
            <If condition={shouldShowItem}>
              <NumberCell>
                {statistics?.replyRate || 0}
                <Sign>%</Sign>
              </NumberCell>
            </If>
          </StatisticsBarDetails>
        </div>
        <div className="item">
          <StatisticsBarItem>
            <MainColoredBar color="#22cfd1" />
            <ShadowColoredBar color="rgba(246, 187, 95, 0.3)" />
          </StatisticsBarItem>
          <StatisticsBarDetails>
            <NameCell color="#22cfd1">{t('tours')}</NameCell>
            <If condition={shouldShowItem}>
              <NumberCell>{statistics?.toursCount || 0}</NumberCell>
            </If>
          </StatisticsBarDetails>
        </div>
        <div className="item">
          <StatisticsBarItem>
            <MainColoredBar color="#f6bb5f" />
          </StatisticsBarItem>
          <StatisticsBarDetails>
            <NameCell color="#f6bb5f">{t('move_ins')}</NameCell>
            <If condition={shouldShowItem}>
              <NumberCell>{statistics?.moveInsCount || 0}</NumberCell>
            </If>
          </StatisticsBarDetails>
        </div>
      </StatisticsBar>
    </Dimmer.Dimmable>
  );
};
