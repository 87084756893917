export default {
  SET_LOADER_STATUS: 'SET_LOADER_STATUS',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SET_USER_ID: 'SET_USER_ID',
  SET_CRM_SETTINGS: 'SET_CRM_SETTINGS',
  SET_CRM_GLOBAL_SETTINGS: 'SET_CRM_GLOBAL_SETTINGS',
  SET_COMMUNITIES: 'SET_COMMUNITIES',
  UPDATE_COMMUNITY: 'UPDATE_COMMUNITY',
  REMOVE_COMMUNITY_CRM_SETTINGS: 'REMOVE_COMMUNITY_CRM_SETTINGS',
  SET_CRM_LOCATION_CODE_RULES: 'SET_CRM_LOCATION_CODE_RULES',
  SET_CRM_COMMUNITY_INVALID_SETTINGS: 'SET_CRM_COMMUNITY_INVALID_SETTINGS',
  SET_CRM_SOURCE_MAPPING_URL_PARAMETERS: 'SET_CRM_SOURCE_MAPPING_URL_PARAMETERS',
  SET_CRM_SOURCE_MAPPING_TRAFFIC_SOURCES: 'SET_CRM_SOURCE_MAPPING_TRAFFIC_SOURCES',
  SET_CRM_SOURCE_MAPPING_LEAD_SOURCES: 'SET_CRM_SOURCE_MAPPING_LEAD_SOURCES',
};

export const TABS = {
  CRM_SETTINGS: 0,
  COMMUNITIES: 1,
};
