import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { setBulkBudgetQualification } from 'apis';
import { Modal, Button, Dimmer, Loader, Icon, Divider, RangeInput, SubtitledCheckboxField } from 'components/lib';
import { SettingsHeader, SettingsModalHeader } from 'components/advanced/common';
import { InputField } from '../../../common';

const StyledModal = styled(Modal)`
  @media only screen and (min-width: 1200px) {
    &.ui.medium.modal {
      width: 700px;
    }
  }

  &.ui.modal > .header {
    padding-bottom: 10px;
  }
`;

const Content = styled.div`
  padding: 30px;

  .ui.divider {
    margin: 32px -30px;
  }

  .titled-slider {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      display: flex;
      gap: 13px;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
    }

    .slider {
      display: flex;
      gap: 10px;
      flex-direction: row;
      align-items: flex-start;
      line-height: 1;

      .field {
        flex: 1;
      }
    }
  }

  .sliders {
    margin-top: 30px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    gap: 60px;
  }
`;

const StyledHeader = styled(Modal.Header)`
  .ui.modal > &.header {
    border-bottom: 0;
  }
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  padding: 2px 3px 3px 2px;
  border-radius: 4px;
`;

const TourIcon = styled(IconContainer)`
  background-image: linear-gradient(to bottom, #f7fbff, #ebf5ff);
  box-shadow: 0 2px 4px 0 rgba(0, 122, 255, 0.2);
  color: #007aff;
`;

const CallIcon = styled(IconContainer)`
  background-image: linear-gradient(to bottom, #f1fffa, #defff3);
  box-shadow: 0 2px 4px 0 rgba(10, 183, 118, 0.2);
  color: var(--emerald-green);
`;

const VALIDATION_SCHEMA = Yup.object({
  ask_questions: Yup.bool(),
  use_thresholds: Yup.bool(),
  call_threshold: Yup.number().nullable(),
  tour_threshold: Yup.number().nullable(),
});

const Sliders = ({ style, values, setFieldValue }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (parseInt(values.call_threshold) === 0 && parseInt(values.tour_threshold) === 0) {
      setFieldValue('use_thresholds', false);
    }
  }, [values, setFieldValue]);

  return (
    <div className="sliders" style={style}>
      <div className="titled-slider">
        <div className="title">
          <TourIcon>
            <Icon name="tour" variant="outlined" size={15}></Icon>
          </TourIcon>
          <span>Ask for {t('tour')} when budget is at least:</span>
        </div>
        <div className="slider">
          <span>0%</span>
          <InputField min="0" max="100" control={RangeInput} name="tour_threshold" type="range" step={5} />
          <span>100%</span>
        </div>
      </div>
      <div className="titled-slider">
        <div className="title">
          <CallIcon>
            <Icon name="call" size={15}></Icon>
          </CallIcon>
          <span>Ask for {t('call')} when budget is at least:</span>
        </div>
        <div className="slider">
          <span>0%</span>
          <InputField min={0} max={100} control={RangeInput} name="call_threshold" type="range" step={5} />
          <span>100%</span>
        </div>
      </div>
    </div>
  );
};

const DEFAULT_VALUES = { ask_questions: false, call_threshold: 60, tour_threshold: 60, use_thresholds: true };

export const BudgetQualificationModal = ({
  selectedItemsCount,
  selectedItems,
  selectedCommunity,
  refetchCommunities,
  open,
  onClose,
  onAfterSaveAndClose,
}) => {
  const { t } = useTranslation();
  const { mutateAsync } = useMutation({
    mutationFn: setBulkBudgetQualification,
    onSuccess: () => {
      toast.success('Successfully updated budget qualification');
      refetchCommunities();
      onClose();
      onAfterSaveAndClose();
    },
    onError: () => {
      toast.error('Unknown server error');
    },
  });

  const onSubmit = useCallback(
    async ({ ask_questions, use_thresholds, call_threshold, tour_threshold }) => {
      return mutateAsync({
        filter: { communities: selectedItems },
        ask_questions,
        use_thresholds,
        call_threshold,
        tour_threshold,
      });
    },
    [mutateAsync, selectedItems]
  );

  const defaultValues = useMemo(() => {
    if (selectedItems.length > 1) {
      return DEFAULT_VALUES;
    }

    return selectedCommunity?.budget_qualification || DEFAULT_VALUES;
  }, [selectedCommunity, selectedItems]);

  return (
    <Formik initialValues={defaultValues} onSubmit={onSubmit} validationSchema={VALIDATION_SCHEMA} enableReinitialize>
      {({ handleSubmit, values, isValid, isSubmitting, setFieldValue }) => (
        <StyledModal open={open} closeIcon onClose={onClose} size="medium">
          <StyledHeader>
            <SettingsModalHeader
              title="Budget Qualification"
              community={selectedCommunity || null}
              totalItemsCount={selectedItemsCount}
              type="header"
            />
          </StyledHeader>
          <Modal.Content>
            <Content>
              <Dimmer inverted>
                <Loader active size="large" transparent />
              </Dimmer>
              <SubtitledCheckboxField
                name="ask_questions"
                title="Ask qualifying questions via Messaging Assistant"
                subtitle={`If this is checked, the ${t(
                  'lead'
                )} will be asked questions about their budget in Messaging Assistant conversations.`}
              />
              <Divider />
              <SubtitledCheckboxField
                name="use_thresholds"
                title={`Set action thresholds at which ${t('lead')} is asked for a ${t('tour')} or ${t('call')}`}
                subtitle={`If this is checked, the ${t(
                  'lead'
                )} will have to satisfy a certain budget threshold in order to be asked for a ${t('tour')} or ${t(
                  'call'
                )}. This will also apply if their budget came from elsewhere, like a survey. Uncheck to always ask for ${t(
                  'tour'
                )} or ${t('call')}.`}
                onChange={() => {
                  // reset thresholds to default values
                  setFieldValue('call_threshold', DEFAULT_VALUES.call_threshold);
                  setFieldValue('tour_threshold', DEFAULT_VALUES.tour_threshold);
                }}
              />
              <Sliders
                values={values}
                setFieldValue={setFieldValue}
                style={{ opacity: values.use_thresholds ? 1 : 0 }}
              />
            </Content>
          </Modal.Content>
          <Modal.Actions>
            <Button primary color="blue" onClick={handleSubmit} disabled={!isValid || isSubmitting}>
              Save
            </Button>
            <Button primary onClick={onClose}>
              Cancel
            </Button>
          </Modal.Actions>
        </StyledModal>
      )}
    </Formik>
  );
};
