import React, { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from 'yup';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { createPortal } from 'react-dom';
import { FieldArray, Formik } from 'formik';

import { bulkOverrideAnswer } from 'apis';
import { Button, Form, Radio } from 'components/lib';
import { Conditions } from 'components/common/automated_answers';
import { InputField } from 'components/common';
import { UnloadPrompt } from '../../../chats/UnloadPrompt';
import queryConstants from '../../../../utils/queries/constants';

const useBulkUpdate = (queryClient) =>
  useMutation({
    mutationFn: (payload) => {
      return bulkOverrideAnswer(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryConstants.INSTANT_RESPONSES_ANSWERS);
    },
  });

const VALIDATION_SCHEMA = Yup.object({
  answers: Yup.array(
    Yup.object({
      text: Yup.string().required('Text is required'),
      conditions: Yup.array(Yup.object()).nullable(),
      text_override: Yup.string().when('use_override', {
        is: true,
        then: Yup.string().required('Text is required'),
      }),
      use_override: Yup.bool().required('Must select an option'),
    })
  ),
});

const AnswerRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 2rem;

  .answer-form {
    flex: 1;
  }
`;

const RadioRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 29px;
`;

const StyledForm = styled(Form)`
  &.ui.form textarea {
    font-family: var(--font-family);
    background-color: var(--input-field-fill);
    border-radius: 0;
    resize: none;
  }

  &.ui.form .disabled.field > .disabled.field {
    opacity: 1;
  }
`;

/**
 * Merges text overrides of a particular community with the answers to form a structure more suitable
 * for working with forms.
 * @param answers list of answers from the API
 * @param overrides list of overrides from the API
 */
const mergeOverrides = (answers, overrides) => {
  return answers.map((a) => {
    const override = overrides?.find((o) => o.answer === a.id);
    return { ...a, text_override: override?.text, use_override: !!override };
  });
};

/**
 * Merges applied text overrides when editing multiple communities to ensure that form is not in dirty state after save.
 * @param answers list of answers from the API
 * @param appliedOverrides list of overrides as applied when submit was called
 * @returns {*}
 */
const mergeAppliedOverrides = (answers, appliedOverrides) => {
  return answers.map((a) => {
    const override = appliedOverrides?.find((o) => o.answer === a.id);
    if (!override) {
      return a;
    }
    return { ...a, text_override: override?.text, use_override: !!override && !override.use_default };
  });
};

export const EditCommunityIntent = ({
  buttonsRef,
  communities,
  intent,
  group,
  communityOverrides,
  formikRef,
  refetchCommunities,
  onSaveAndClose,
  onCancel,
}) => {
  const queryClient = useQueryClient();
  const mutation = useBulkUpdate(queryClient);
  const [appliedOverrides, setAppliedOverrides] = useState([]);
  const initialValues = useMemo(
    () => ({
      answers:
        communities?.length === 1
          ? mergeOverrides(group, communityOverrides)
          : mergeAppliedOverrides(group, appliedOverrides),
    }),
    [group, communityOverrides, appliedOverrides, communities]
  );

  const onSubmit = async (values, { resetForm }) => {
    const { answers } = values;
    const overrides = answers.map((answer) => ({
      answer: answer.id,
      text: answer.text_override,
      use_default: !answer.use_override,
    }));
    const payload = {
      filter: {
        communities,
      },
      overrides,
    };
    try {
      await mutation.mutateAsync(payload);
      setAppliedOverrides(overrides);
      refetchCommunities();
      toast.success('Answer(s) updated successfully');
    } catch (e) {
      console.log(e);
      toast.error('Unable to update answers due to unknown server error');
      throw e;
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={VALIDATION_SCHEMA}
      innerRef={formikRef}
      enableReinitialize
      isInitialValid={communities?.length > 1}
    >
      {({ dirty, values, handleSubmit, handleChange, handleBlur, isValid, isSubmitting, setFieldValue, errors }) => (
        <UnloadPrompt>
          <StyledForm onSubmit={handleSubmit}>
            <FieldArray name="answers">
              {({ remove, swap }) => (
                <div>
                  {values.answers.map((answer, index) => (
                    <AnswerRow key={index}>
                      <div className="answer-form">
                        <Form.Field>
                          <Conditions value={values.answers[index].conditions} />
                        </Form.Field>
                        <RadioRow>
                          <Form.Field>
                            <Radio
                              label="Default answer"
                              name={`answers.${index}.use_override`}
                              checked={values.answers[index].use_override === false}
                              onChange={(e) => {
                                setFieldValue(`answers.${index}.use_override`, false);
                              }}
                            />
                          </Form.Field>
                          <Form.Field>
                            <Radio
                              label="Custom answer"
                              name={`answers.${index}.use_override`}
                              checked={values.answers[index].use_override === true}
                              onChange={(e) => {
                                setFieldValue(`answers.${index}.use_override`, true);
                              }}
                            />
                          </Form.Field>
                        </RadioRow>
                        <div style={{ display: values.answers[index].use_override ? 'block' : 'none' }}>
                          <InputField
                            control={Form.TextArea}
                            name={`answers.${index}.text_override`}
                            value={values.answers[index].text_override}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                            placeholder="Enter answer here"
                          />
                        </div>
                        <div style={{ display: values.answers[index].use_override ? 'none' : 'block' }}>
                          <InputField
                            control={Form.TextArea}
                            name={`answers.${index}.text`}
                            value={
                              values.answers[index].use_override === undefined
                                ? 'Select an option above'
                                : values.answers[index].text
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </AnswerRow>
                  ))}
                </div>
              )}
            </FieldArray>
          </StyledForm>
          {buttonsRef.current &&
            createPortal(
              <>
                <Button
                  primary
                  color="blue"
                  disabled={!dirty || !isValid}
                  style={{ width: '120px' }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
                <Button
                  primary
                  color="blue"
                  disabled={!dirty || !isValid}
                  style={{ width: '146px' }}
                  onClick={() => {
                    handleSubmit();
                    onSaveAndClose();
                  }}
                >
                  Save & Close
                </Button>
                <Button primary onClick={onCancel} style={{ width: '120px' }}>
                  Cancel
                </Button>
              </>,
              buttonsRef.current,
              'submitButton'
            )}
        </UnloadPrompt>
      )}
    </Formik>
  );
};
