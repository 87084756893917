import React, { useContext, useEffect, useState } from 'react';

import { useFormikContext, useField } from 'formik';
import { Button, Form } from 'semantic-ui-react';

import { ChatModulesContext } from '../../ChatModulesContext';
import { DropZone } from '../../DropZone';
import { ACTION_TYPES } from '../../ModuleGraph';
import Answer from './ModuleFormAnswer';
import { MODULE_TYPES } from '../../constants';

export const CARE_TYPES_ANSWERS_PLACEHOLDER = 'Care Types Placeholder';

export const AnswersField = (props) => {
  const [field, { error, initialValue }, { setValue }] = useField(props);
  const { values: node } = useFormikContext();
  const { moduleGraph } = useContext(ChatModulesContext);
  const { module } = node;
  const answers = field.value;

  useEffect(() => {
    const answers_list = field.value;
    const is_care_type_options_module = module.module_type === MODULE_TYPES.CARE_OPTION;
    const care_type_placeholder_index = answers_list.findIndex(
      (element) => element.text === CARE_TYPES_ANSWERS_PLACEHOLDER
    );

    if (is_care_type_options_module && care_type_placeholder_index === -1) {
      addAnswer(CARE_TYPES_ANSWERS_PLACEHOLDER);
    } else if (!is_care_type_options_module && care_type_placeholder_index > -1) {
      deleteAnswer(care_type_placeholder_index);
    }
  }, [module, field]);

  const addAnswer = (answer_text = '') => {
    console.log(answer_text);
    const existingModule = moduleGraph.getNode(module?.number);
    const nextStepNumber = existingModule ? existingModule.getNext()?.number : module?.action;

    const newAnswer = {
      text: answer_text,
      rank: answers.length,
      action: nextStepNumber || 0,
      meta: {
        actionType: ACTION_TYPES.STEP,
      },
    };
    setValue([...answers, newAnswer]);
  };

  const updateAnswer = (answer, index) => {
    setValue(answers.map((a, i) => (i === index ? answer : a)));
  };

  const deleteAnswer = (index) => {
    setValue(answers.filter((a, i) => i !== index).map((a, i) => ({ ...a, rank: i })));
  };

  const onDrop = (droppedItem, destinationIndex) => {
    const { answer, index } = droppedItem.value;
    const newAnswers = [...answers];
    const tmp = newAnswers[destinationIndex];
    newAnswers[destinationIndex] = answer;
    newAnswers[index] = tmp;
    setValue(newAnswers.map((a, i) => ({ ...a, rank: i })));
  };

  const hasDuplicateAnswer = (a) => answers.filter((answer) => answer.text === a.text).length > 1;

  return (
    <Form.Field>
      <label>Options</label>
      {answers.map((a, i) => (
        <DropZone
          key={i}
          index={i}
          onDrop={(d) => {
            onDrop(d, i);
          }}
        >
          <Answer
            answer={a}
            index={i}
            error={error && hasDuplicateAnswer(a)}
            initialValue={initialValue.find(
              (initialAnswer) => initialAnswer.id === a.id || initialAnswer.text === a.text
            )}
            onChange={(changedAnswer) => {
              updateAnswer(changedAnswer, i);
            }}
            onDelete={() => {
              deleteAnswer(i);
            }}
          />
        </DropZone>
      ))}
      <Button type="button" icon="add" content="Add Option" fluid onClick={() => addAnswer()} />
    </Form.Field>
  );
};
