import React, { useState, useRef, useContext } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { updatePricing } from 'api';
import { StepHeader } from './StepHeader';
import { PricingForm } from '../common/PricingForm';
import { OnboardingContext } from './OnboardingContext';

export const Pricing = () => {
  const history = useHistory();
  const { communities, fetchCommunities } = useContext(OnboardingContext);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const onSave = async (values) => {
    try {
      const pricing = values.prices
        .flatMap((pd) => Object.values(pd))
        .map((price) => ({ id: price.id, cost: price.cost }));
      const starting_costs = values.communities.map((p) => ({ id: p.id, starting_cost: p.starting_cost }));
      const ending_costs = values.communities.map((p) => ({ id: p.id, ending_cost: p.ending_cost }));

      setLoading(true);
      await updatePricing({ pricing, starting_costs, ending_costs });
      await fetchCommunities();
      toast.success('Room & service options saved');
      history.push('/onboarding/thankyou');
    } catch (e) {
      console.error(e);
      toast.error('Error saving room & service options. Please try again later, or contact us for help.');
      setLoading(false);
    }
  };

  return (
    <>
      <StepHeader
        title="Define Room & Service Options"
        subtitle="Do you offer special room types or care service options?"
        loading={loading}
        onContinue={() => {
          if (formRef.current) {
            if (!formRef.current.isValid) {
              toast.warning('Data validation error. Please provide valid inputs.');
              return;
            }
            formRef.current.handleSubmit();
          }
        }}
      />

      <PricingForm communities={communities} formRef={formRef} onSubmit={onSave} />
    </>
  );
};
