import React, { createContext, useContext, useEffect, useState } from 'react';
import { useImmerReducer } from 'use-immer';

import { fetchExperiments } from 'apis';
import { STATUSES } from 'constants';
import { reducer } from './reducer';
import { ORDERING_VALUES } from '../constants';

const ExperimentsContext = createContext();

export const ExperimentsProvider = (props) => {
  const INITIAL_STATE = {
    search: '',
    types: '',
    ordering: ORDERING_VALUES[0].value,
  };

  const [experiments, setExperiments] = useState([]);
  const [experimentEffects, setExperimentEffects] = useState();
  const [status, setStatus] = useState(STATUSES.IDLE);
  const [filters, setFilters] = useState(INITIAL_STATE);

  const [state, dispatch] = useImmerReducer(reducer, {
    ...INITIAL_STATE,
  });

  const getExperiments = async (data = {}) => {
    setStatus(STATUSES.LOADING);
    try {
      const { results, effects } = await fetchExperiments(data);
      setExperiments(results);
      setExperimentEffects(effects);
      setStatus(STATUSES.LOADED);
    } catch (error) {
      console.error('Error fetching experiments', error);
      setStatus(STATUSES.ERROR);
    }
  };

  useEffect(() => {
    getExperiments(filters);
  }, [filters]);

  const value = { state, dispatch, experiments, getExperiments, status, filters, setFilters, experimentEffects };
  return <ExperimentsContext.Provider value={value}>{props.children}</ExperimentsContext.Provider>;
};

export function useExperiments() {
  const context = useContext(ExperimentsContext);

  if (!context) {
    throw new Error(`useExperiments must be used within ExperimentsProvider`);
  }

  return context;
}
