import React, { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, Divider, Grid, Icon, Segment } from 'semantic-ui-react';

import { Answer } from './Answer';
import { IA_TYPES } from '../../../constants';

export const AnswersPane = ({
  answers,
  onOrderChange,
  onRemoveItem,
  onSelectPlaceholder,
  values,
  addItem,
  placeholders,
}) => {
  const moveCard = useCallback((dragIndex, hoverIndex) => onOrderChange(dragIndex, hoverIndex), [onOrderChange]);

  return (
    <DndProvider backend={HTML5Backend}>
      {answers.map((a, i) => (
        <Answer
          index={i}
          answer={a}
          id={a?.drag_id}
          key={a?.drag_id}
          moveCard={moveCard}
          onRemoveItem={onRemoveItem}
          onSelectPlaceholder={onSelectPlaceholder}
          values={values}
          placeholders={placeholders}
        />
      ))}
      <Segment>
        <Grid columns={2} stackable textAlign="center">
          <Divider vertical>Or</Divider>

          <Grid.Row verticalAlign="middle">
            <Grid.Column>
              <Button positive icon labelPosition="left" type="button" onClick={() => addItem(IA_TYPES.TEXT)}>
                <Icon name="add" />
                Add Text
              </Button>
            </Grid.Column>

            <Grid.Column>
              <Button positive icon labelPosition="left" type="button" onClick={() => addItem(IA_TYPES.LIST)}>
                <Icon name="add" />
                Add List
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </DndProvider>
  );
};
