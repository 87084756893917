import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  max-width: 30rem;
  flex: 3;

  h1 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
    font-family: var(--font-family);
  }

  p {
    color: rgba(255, 255, 255, 0.69);
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 1.7;
  }

  a {
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 1.43rem;
    border: solid 1px rgba(255, 255, 255, 0.76);
    color: white;
    height: 40px;
    line-height: 40px;
  }
`;

export const LetsTalk = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h1>Automating Conversations To Increase Occupancy</h1>
      <p>
        We empower communities with digital {t('lead')} engagement solutions and actionable insights to help teams go
        further than ever before.
      </p>
      <a target="_blank" rel="noreferrer" href="https://www.talkfurther.com/contact-us">
        Let's Talk
      </a>
    </Wrapper>
  );
};
