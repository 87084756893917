import React from 'react';
import styled from 'styled-components';
import { If } from '../If';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8rem 1rem;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  h4 {
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: rgba(0, 0, 0, 0.5);
    margin: 0 20px 0 0;
    flex: 1 0 auto;
  }
`;

export const LineDivider = styled.div`
  border-bottom: 0.5px solid rgba(127, 127, 127, 0.4);
  width: 100%;
`;

export const SectionContent = ({ title, children }) => (
  <Container>
    <If condition={title}>
      <Title>
        <h4>{title}</h4>
        <LineDivider />
      </Title>
    </If>
    {children}
  </Container>
);
