import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { loginPathWithNext } from 'utils/helpers';
import { UserContext } from '../../UserContext';
import { useNavigationContext } from '../../navigation';

export const AuthRoute = ({ hideNavigation = false, ...props }) => {
  const { user } = useContext(UserContext);
  const { setShowNavigation } = useNavigationContext();

  useEffect(() => {
    setShowNavigation(!hideNavigation);
  }, [hideNavigation]);

  if (!user) {
    const next = loginPathWithNext();
    return <Redirect to={next} />;
  }

  return <Route {...props} />;
};
