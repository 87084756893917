import React from 'react';
import { Tab } from 'semantic-ui-react';

import InstantResponsesMetricsOverview from './InstantResponsesMetricsOverview';

const PERIODS = {
  ONE_DAY: 1,
  SEVEN_DAYS: 7,
  ONE_MONTH: 30,
};

const InstantResponsesMetrics = () => {
  const panes = [
    {
      menuItem: '1D',
      render: () => <InstantResponsesMetricsOverview period={PERIODS.ONE_DAY} />,
    },
    {
      menuItem: '7D',
      render: () => <InstantResponsesMetricsOverview period={PERIODS.SEVEN_DAYS} />,
    },
    {
      menuItem: '1M',
      render: () => <InstantResponsesMetricsOverview period={PERIODS.ONE_MONTH} />,
    },
  ];

  return <Tab menu={{ secondary: true }} panes={panes} renderActiveOnly />;
};

export default InstantResponsesMetrics;
