import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { formatChangeInPercent } from 'components/utils';

const StatContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding: 12px 19px 12px 20px;
    border-radius: 10px;
    position: relative;
    h3,
    h4,
    h5 {
      margin: 0;
      font-family: var(--font-family);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #222;
    }
    h3 {
      font-size: 26px;
    }
    h4 {
      font-size: 12px;
    }
    h5 {
      font-size: 14px;
      color: #222;
      &.positive {
        color: #0ab776;
      }
      &.negative {
        color: #fa7550;
      }
    }

    &.highlighted {
      ::after {
        position: absolute;
        content: '';
        font-family: 'Material Icons';
        font-size: 14px;
        color: #fff;
        bottom: 12px;
        right: 19px;
      }
      &.positive {
        ::after {
          content: '\\e8e5';
        }
        background-color: #0ab776;
      }
      &.negative {
        ::after {
          content: '\\e8e3';
        }
        background-color: #fa7550;
      }
      &.neutral {
        background-color: #89919d;
        h5 {
          color: #fff;
        }
      }
      h3,
      h4 {
        color: #fff;
      }
      h5 {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
`;

export const ExperimentStat = ({ stat, highlighted, ...props }) => {
  const containerClasses = classNames({
    highlighted,
    positive: stat.change > 0,
    negative: stat.change < 0,
    neutral: stat.change === 0,
  });

  const changeClasses = classNames({
    positive: stat.change > 0,
    negative: stat.change < 0,
  });

  if (stat.name === 'visitors') {
    return (
      <StatContainer className={containerClasses} {...props}>
        <h3>{stat?.new || '-'}</h3>
      </StatContainer>
    );
  }

  return (
    <StatContainer className={containerClasses} {...props}>
      <h5 className={changeClasses}>{formatChangeInPercent(stat.change)}</h5>
      <h3>{stat?.new || '-'}</h3>
      {stat.old && <h4>vs {stat.old}</h4>}
    </StatContainer>
  );
};
