import React from 'react';
import styled from 'styled-components';
import { CONNECTOR } from '../constants';

export const ConnectorLabel = styled.div`
  font-family: var(--font-family-bold);
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  color: ${({ connector }) => (connector === CONNECTOR.AND ? '#007aff' : 'var(--salmon)')};
  background-color: ${({ connector }) => (connector === CONNECTOR.AND ? '#e2f0ff' : '#ffeae5')};
  text-transform: uppercase;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 10px 12px;
  line-height: 1;
  position: relative;
  border-radius: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, #fafafa 0%, var(--line-standard) 50%, #fafafa);
  position: relative;
  top: 23px;
`;

export default function Connector({ connector, children }) {
  return (
    <Wrapper>
      <Line />
      <ConnectorLabel connector={connector}>{children}</ConnectorLabel>
    </Wrapper>
  );
}
