import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 10px 20px;
  border-radius: 18px;
  background-color: var(--input-field-fill);

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const ResultCount = ({ current, total }) => {
  return (
    <Container>
      Showing {current || 0} of {total || 0} records
    </Container>
  );
};

export default ResultCount;
