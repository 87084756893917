import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'components/lib';
import { MEDIA_BREAK_POINTS } from '../../constants';

const Wrapper = styled.div`
  padding: 35px 35px 0 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    font-size: 60px;
  }
`;

const TitleWrapper = styled.h1`
  font-family: var(--font-family-bold);
  font-size: 45px;
  line-height: 60px;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    font-size: 60px;
  }
`;
const ButtonWrapper = styled.div`
  padding-top: 6px;
`;

const UserManagementHeader = () => {
  return (
    <Wrapper>
      <TitleWrapper>User Management</TitleWrapper>
      <ButtonWrapper>
        <Link to="/admin/user-management/user">
          <Button className="blue" primary content="Add user" />
        </Link>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default UserManagementHeader;
