import React from 'react';
import { useHistory } from 'react-router';

import BusinessActivityValueChange from './change-value';
import { ACTION_TYPE } from './constants';
import { AnswerValueChange } from './change-value/AnswerValueChange';

export const BusinessActivityChanges = ({ id, actionType, changes, showOldValue, onViewBusinessActivity }) => {
  const history = useHistory();

  const onViewLead = (lead_id) => {
    history.replace(`/conversations/${lead_id}`);
  };

  switch (actionType) {
    case ACTION_TYPE.MESSAGING_ASSISTANT_USER:
    case ACTION_TYPE.MESSAGING_ASSISTANT_COMMUNITY_OVERRIDES:
    case ACTION_TYPE.MESSAGING_ASSISTANT_COMMUNITY:
    case ACTION_TYPE.MESSAGING_ASSISTANT_CAMPAIGN:
      return showOldValue ? (
        <BusinessActivityValueChange.Boolean value={changes.oldValue} />
      ) : (
        <BusinessActivityValueChange.Boolean value={changes.newValue} />
      );
    case ACTION_TYPE.CAMPAIGN_UPDATE:
      return showOldValue ? (
        <BusinessActivityValueChange.Boolean value={changes.oldValue} positiveTitle="Active" negativeTitle="Inactive" />
      ) : (
        <BusinessActivityValueChange.Boolean
          value={changes.newValue}
          positiveTitle={`Active ${changes.withMessagingAssistant ? '(with Messaging Assistant)' : ''}`}
          negativeTitle="Inactive"
        />
      );
    case ACTION_TYPE.CAMPAIGN_COMMUNITIES_ADD:
    case ACTION_TYPE.CAMPAIGN_COMMUNITIES_REMOVE: {
      if (showOldValue) {
        return '-';
      }

      const isAddAction = actionType === ACTION_TYPE.CAMPAIGN_COMMUNITIES_ADD;
      return (
        <BusinessActivityValueChange
          title={`${changes.count} Communities ${isAddAction ? 'Added' : 'Removed'}`}
          color="blue"
          onTitleClick={onViewBusinessActivity(id)}
        />
      );
    }
    case ACTION_TYPE.LEAD_MOVE_IN_DATE_ADD:
    case ACTION_TYPE.LEAD_MOVE_IN_DATE_UPDATE: {
      if (showOldValue) {
        return <BusinessActivityValueChange.Date value={changes.oldValue} />;
      }

      return <BusinessActivityValueChange.Date value={changes.newValue} />;
    }
    case ACTION_TYPE.COMMUNITY_OFFBOARDING:
      if (showOldValue) {
        return <BusinessActivityValueChange title={changes.oldValue.name || changes.oldValue.email} />;
      }
      return <BusinessActivityValueChange title={changes.newValue.name || changes.newValue.email} />;
    case ACTION_TYPE.COMMUNITY_REASSIGN:
      if (showOldValue) {
        return <BusinessActivityValueChange title={changes.oldValue.name || changes.oldValue.email} />;
      }
      return <BusinessActivityValueChange title={changes.newValue.name || changes.newValue.email} />;
    case ACTION_TYPE.CHAT_PROPERTY_CREATE:
      if (showOldValue) {
        return '-';
      }

      return <BusinessActivityValueChange title={changes.communityName} color="green" />;
    case ACTION_TYPE.CHAT_PROPERTY_DELETE:
      if (showOldValue) {
        return '-';
      }

      return <BusinessActivityValueChange title={changes.communityName} color="orange" />;
    case ACTION_TYPE.CHAT_COMMUNITIES_ADD:
      if (showOldValue) {
        return '-';
      }

      return (
        <BusinessActivityValueChange
          title={`${changes.count} Communities Added`}
          color="blue"
          onTitleClick={onViewBusinessActivity(id)}
        />
      );
    case ACTION_TYPE.LEAD_CREATED_MANUALLY:
      if (showOldValue) {
        return '-';
      }

      return (
        <BusinessActivityValueChange
          title={changes.newValue.leadName}
          color="blue"
          onTitleClick={() => onViewLead(changes.newValue.leadId)}
        />
      );
    case ACTION_TYPE.ANSWER_UPDATE:
      if (!changes.hasOldValues) {
        if (showOldValue) {
          return '-';
        }
        return <span>{JSON.stringify(changes)}</span>;
      }
      return (
        <span>
          <AnswerValueChange changes={changes} showOldValue={showOldValue} />
        </span>
      );
    case ACTION_TYPE.LEAD_SUBMIT_TO_CRM_MANUAL: {
      if (showOldValue) {
        return '-';
      }

      const title = changes?.crmType?.name || 'CRM result not available';
      return <BusinessActivityValueChange title={title} color="blue" onTitleClick={onViewBusinessActivity(id)} />;
    }
    default:
      return '-';
  }
};
