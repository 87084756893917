import React, { createContext, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DEFAULT_OVERVIEW_RANGE } from './constants';
import { UserContext, USER_TYPES } from '../UserContext';

export const OverviewContext = createContext();
export const OverviewContextProvider = (props) => {
  const history = useHistory();
  const [range, setRange] = useState(DEFAULT_OVERVIEW_RANGE);
  const { user } = useContext(UserContext);

  if (!user.show_conversion_dashboard && user.impersonated_session?.type !== USER_TYPES.STAFF) {
    history.replace('/');
    toast.error('You do not have access to this page. Please contact support.');
  }

  const handleRangeChange = (e, { value }) => {
    setRange(value);
  };

  const provide = {
    range,
    handleRangeChange,
  };

  return <OverviewContext.Provider value={provide} {...props} />;
};
