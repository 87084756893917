import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { If } from 'components/If';
import { Button, Icon } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { MessageTypePill, MESSAGE_PILL_TYPE } from 'components/common/chat';
import { capitalize } from 'utils/helpers';
import ChatMessageAttachment from './ChatMessageAttachment';
import { CONVERSATION_MESSAGE_NAME, CONVERSATION_MESSAGE_TYPE, MESSAGE_STATUS } from '../constants';
import { MEDIA_BREAK_POINTS } from '../../../constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  max-width: 450px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 20px;
  box-shadow: 0 3px 5px 0 #d8d8d8;

  background: ${({ isSMS }) =>
    `linear-gradient(to right, ${isSMS ? '#299A32 0%, #21B23A' : '#4e4af4 0%, #0f71fd'} 100%)`};

  img {
    max-width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 17px;
`;

const Text = styled.span`
  font-family: var(--font-family);
  padding: 0 30px;
  font-size: 13px;
  line-height: 1.38;
  color: var(--white);
  word-break: break-word;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 13px;
    margin: 0;
    font-family: var(--font-family);
    line-height: 1.4285em;
    font-weight: 400;
  }
`;

const ContentHeader = styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 5px;
`;

const NameTimeBox = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.span`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: 1.43;
  color: var(--white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
`;

const Time = styled.span`
  font-family: var(--font-family);
  font-size: 10px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 20px;
  margin-right: 10px;
`;

const FurtherReplied = styled.div`
  display: ${({ furtherReplied }) => (furtherReplied ? 'flex' : 'none')};
  padding: 10px 30px;
  border-radius: 20px 20px 0 0;
  background: ${({ isSMS }) => `linear-gradient(to left, ${isSMS ? '#00A206 0%,#128F16' : '#284AD5 0%,#4642EE'} 100%)`};
  .text {
    margin-left: 11px;
    font-family: var(--font-family-bold);
    font-size: 9px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
  }
`;

const MessageStatusContainer = styled.div`
  font-family: var(--font-family);
  color: ${({ error }) => (error ? '#fa7550' : '#89919d')};
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;

  .box {
    display: flex;
    align-items: center;
    cursor: ${({ error }) => (error ? 'pointer' : 'inherit')};

    @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      align-items: flex-start;
    }

    .text {
      font-size: 12px;
    }

    .icon {
      margin-right: 2px;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
    background-color: #3b37d6;
    width: 100%;
    color: var(--white);
    &:hover,
    &:focus,
    &:active {
      background-color: #3b37d6;
      color: var(--white);
    }

    &.disabled {
      background-color: #3b37d6;
      opacity: 0.8 !important;
    }
  }
`;

const MESSAGE_STATUS_STYLE = {
  [MESSAGE_STATUS.SENT]: {
    text: 'Sent',
  },
  [MESSAGE_STATUS.PENDING]: {
    text: 'Pending',
  },
  [MESSAGE_STATUS.DELIVERED]: {
    text: 'Delivered',
    icon: 'done',
  },
  [MESSAGE_STATUS.FAILED]: {
    text: 'Not delivered',
    icon: 'refresh',
    error: true,
    resend: true,
  },
};

export const FurtherMessage = ({
  messageId,
  text,
  status,
  timestamp,
  messageType,
  attachment,
  community,
  onAttachmentLoad,
  furtherReplied,
  onResendMessage,
  subject,
}) => {
  const { t } = useTranslation();
  const formattedTime = formatAnyDate(timestamp, 'h:mm a');
  const isSMS = messageType === CONVERSATION_MESSAGE_TYPE.SMS;
  const senderName = furtherReplied ? 'Further' : community?.name || capitalize(t('community'));
  const statusStyle = MESSAGE_STATUS_STYLE[status];
  const { leadId } = useParams();
  const handleResend = () => {
    if (statusStyle.resend) {
      onResendMessage({ messageId, messageType });
    }
  };

  return (
    <Wrapper>
      <Container isSMS={isSMS}>
        <FurtherReplied furtherReplied={furtherReplied} isSMS={isSMS}>
          <Icon name="question_answer" variant="outlined" color="rgba(255, 255, 255, 0.6)" size="13" />
          <span className="text">This was sent via Further’s Messaging Assistant on your behalf.</span>
        </FurtherReplied>
        <Content>
          <ContentHeader>
            <NameTimeBox>
              <Name>{senderName}</Name>
              <Time>{formattedTime}</Time>
            </NameTimeBox>
            <MessageTypePill
              text={CONVERSATION_MESSAGE_NAME[messageType]}
              type={isSMS ? MESSAGE_PILL_TYPE.FURTHER_SMS : MESSAGE_PILL_TYPE.FURTHER_EMAIL}
            />
          </ContentHeader>
          <Text>
            <If condition={isSMS}>
              <ReactMarkdown>{text}</ReactMarkdown>
            </If>
            <If condition={!isSMS}>
              <If condition={subject}>Subject: {subject}</If>
              <If condition={text}>
                <ReactMarkdown>{text}</ReactMarkdown>
              </If>
              <If condition={!text}>
                <Link to={`/conversations/${leadId}/sent/${messageId}`}>
                  <StyledButton>View Email</StyledButton>
                </Link>
              </If>
            </If>
            <If condition={attachment}>
              <ChatMessageAttachment url={attachment} onImgLoad={onAttachmentLoad} />
            </If>
          </Text>
        </Content>
      </Container>
      <If condition={statusStyle}>
        <MessageStatusContainer error={statusStyle.error}>
          <div className="box" onClick={handleResend}>
            {statusStyle.icon && (
              <div className="icon">
                <Icon name={statusStyle.icon} size="16" />
              </div>
            )}
            <div className="text">{statusStyle.text}</div>
          </div>
        </MessageStatusContainer>
      </If>
    </Wrapper>
  );
};
