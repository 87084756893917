import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { parseErrorResponse } from 'apis/utils';
import { If } from 'components/If';
import { ProfileCircle } from 'components/common/profile/ProfileCircle';
import { capitalize } from 'utils/helpers';
import { Button, Dimmer, Loader } from 'components/lib';
import { getRelatedCommunities } from 'apis';
import { STATUSES } from '../../../constants';

const Container = styled.div``;

const Title = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 10px;
`;

const Communities = styled.div`
  min-height: 200px;
  max-height: 290px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
`;

const Name = styled.h4`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin: 0 0 0 10px;
`;

const NotAssignedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 300px;
  justify-content: center;
`;

const NotAssignedTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
`;

const NotAssignedSubtitle = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #89919d;
  max-width: 420px;
  margin-top: 6px;
  margin-bottom: 20px;
`;

export const EmailAddressesCommunities = ({ relatedCommunities, setRelatedCommunities, selectedItem, onClose }) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { t } = useTranslation();
  const history = useHistory();

  const fetchCommunities = async (id) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const response = await getRelatedCommunities(id);
      setRelatedCommunities(response?.communities || []);
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e), 'Unable to fetch data!');
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const handleOnClick = () => {
    onClose();
    history.push('/advanced/marketing-email/community-assignment');
  };

  useEffect(() => {
    fetchCommunities(selectedItem?.id);
  }, []);

  const loading = loaderStatus === STATUSES.LOADING;

  return (
    <Container>
      <Dimmer active={loading} inverted>
        <Loader active size="large" transparent />
      </Dimmer>
      <If condition={relatedCommunities?.length > 0 && !loading}>
        <Title>Assigned to the following related {t('communities')}:</Title>
        <Communities>
          {relatedCommunities?.map((community) => (
            <NameWrapper>
              <ProfileCircle name={community?.name} picture={community?.pictureUrl} size="18" />
              <Name key={community.id}>{community.name}</Name>
            </NameWrapper>
          ))}
        </Communities>
      </If>
      <If condition={relatedCommunities?.length === 0 && !loading}>
        <NotAssignedWrapper>
          <NotAssignedTitle>Not assigned to any related {t('communities')}.</NotAssignedTitle>
          <NotAssignedSubtitle>
            Use the {capitalize(t('community'))} Assignment page to assign this email address to one or more related{' '}
            {t('communities')}.
          </NotAssignedSubtitle>
          <Button primary color="blue" onClick={handleOnClick}>
            {capitalize(t('community'))} Assignment
          </Button>
        </NotAssignedWrapper>
      </If>
    </Container>
  );
};
