import React from 'react';
import { Button, Label } from 'semantic-ui-react';
import styled from 'styled-components';

const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  .ui.label {
    background-color: #eaeff7;
  }
`;

const ModuleFormHeader = ({ number, disabled, submitForm, onCancel }) => {
  const onSubmitFormClick = () => {
    if (typeof submitForm === 'function') {
      submitForm();
    }
  };

  const onCancelClick = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  return (
    <FormHeader>
      {number && <Label>MODULE {number}</Label>}
      {!number && <Label color="blue">NEW MODULE</Label>}
      <Button.Group size="tiny">
        <Button type="button" icon="check" color="green" disabled={disabled} onClick={onSubmitFormClick} />
        <Button type="button" icon="cancel" onClick={onCancelClick} />
      </Button.Group>
    </FormHeader>
  );
};

export default ModuleFormHeader;
