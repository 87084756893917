import React, { useEffect, useState, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { Form, Modal as ComponentsModal, Button, Radio, Icon, Dropdown } from 'components/lib';
import { If } from 'components/If';
import { formatSecondsToTime } from 'utils/helpers';
import { sendOutboundCallSMSNotification } from 'apis';

const Modal = styled(ComponentsModal)`
  && {
    & > .header {
      border-bottom: none;
    }
    &.tiny,
    &.large {
      width: 540px;
    }
  }
`;

const HeaderTitle = styled.div`
  font-family: var(--font-family);
  font-weight: 900; // override semantic-ui inherited font-weight: 700;
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const HeaderSubtitle = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  margin-top: 11px;
`;

const Content = styled.div`
  padding: 0 30px;
  position: relative;
  &&&& {
    .field {
      margin-bottom: 0;
    }
  }
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  border-top: 1px solid var(--line-standard);
  padding: 0 30px;
  margin-top: 28px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 10px;
`;

const CustomRadio = styled.div`
  display: flex;
  align-items: flex-start;
`;

const RadioText = styled.div`
  margin-left: 10px;
`;

const RadioTextTitle = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  padding-top: 3px;
`;

const RadioTextSubtitle = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
`;

const Divider = styled.div`
  width: calc(100% + 60px);
  height: 1px;
  background-image: linear-gradient(to right, #fafafa 0%, var(--line-standard) 50%, #fafafa);
  margin-left: -30px;
  margin-top: 20px;
`;

const InfoBox = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  display: flex;
  gap: 10px;
  padding-top: 10px;
`;

const RADIO_FIELD_NAME = 'delay_call';

const validationSchema = Yup.object({
  [RADIO_FIELD_NAME]: Yup.boolean().required('Required'),
});

const SelectPhoneNumberWrapper = styled.span`
  display: block;
  margin-bottom: 20px;
  label {
    font-size: 14px;
    font-weight: 900;
    margin-right: 10px;
  }
`;

export const CallModal = ({
  closeModal,
  lead,
  CallIcon,
  makeCall,
  delay,
  phoneNumbers,
  callerId,
  handlePhoneNumberChange,
  disabled,
}) => {
  const initialValues = { [RADIO_FIELD_NAME]: true };
  const [submitted, setSubmitted] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const delayInSeconds = delay * 60;

  const onSubmit = async (values) => {
    if (!values[RADIO_FIELD_NAME]) {
      makeCall();
    } else {
      sendOutboundCallSMSNotification(lead.id);
      setCountdown(delayInSeconds);

      const intervalId = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            makeCall();
            clearInterval(intervalId);
          }
          return prev - 1;
        });
      }, 1000);
      setSubmitted(intervalId);
    }
  };

  useEffect(() => {
    return () => {
      if (submitted) {
        clearInterval(submitted);
      }
    };
  }, [submitted]);

  const handleClose = () => {
    closeModal();
  };

  const onCancel = (resetForm) => () => {
    resetForm();
    handleClose();
  };

  const formatTime = useCallback((seconds) => {
    if (seconds > 60) {
      return formatSecondsToTime(seconds);
    }
    return seconds;
  });

  return (
    <Modal open closeIcon size="large" onClose={handleClose}>
      <Modal.Header>
        <HeaderTitle>Call {lead?.name}?</HeaderTitle>
        <HeaderSubtitle>
          The call will be displayed above the conversation.
          <br /> You will be able to replay the call and view its summary later.
        </HeaderSubtitle>
      </Modal.Header>
      <Modal.Content>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ handleSubmit, resetForm, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Content>
                <If condition={phoneNumbers.length > 1}>
                  <SelectPhoneNumberWrapper>
                    <label htmlFor="callerId">Call From</label>
                    <Dropdown
                      id="callerId"
                      selection
                      options={phoneNumbers}
                      value={callerId}
                      onChange={handlePhoneNumberChange}
                    />
                    <Divider />
                  </SelectPhoneNumberWrapper>
                </If>
                <RadioWrapper>
                  <CustomRadio>
                    <Form.Field
                      control={Radio}
                      name={RADIO_FIELD_NAME}
                      value={true}
                      disabled={Boolean(submitted)}
                      checked={Boolean(values[RADIO_FIELD_NAME])}
                      onChange={() => {
                        setFieldValue(RADIO_FIELD_NAME, true);
                      }}
                    />
                    <RadioText>
                      <RadioTextTitle>
                        Send pre-call SMS and start call countdown ({delay} minute{delay > 1 ? 's' : ''})
                      </RadioTextTitle>
                      <RadioTextSubtitle>
                        The prospect will receive an SMS that you are about to call them.
                        <br /> A countdown will display here, and the call will start after.
                      </RadioTextSubtitle>
                    </RadioText>
                  </CustomRadio>
                  <CustomRadio>
                    <Form.Field
                      control={Radio}
                      name={RADIO_FIELD_NAME}
                      disabled={Boolean(submitted)}
                      value={false}
                      checked={String(values[RADIO_FIELD_NAME]) === String(false)}
                      onChange={() => {
                        setFieldValue(RADIO_FIELD_NAME, false);
                      }}
                    />
                    <RadioText>
                      <RadioTextTitle>Call immediately</RadioTextTitle>
                    </RadioText>
                  </CustomRadio>
                </RadioWrapper>
                <If condition={lead?.community?.enable_outbound_call_recoding}>
                  <Divider />
                  <InfoBox>
                    <Icon name="info" color="#f6bb5f" width="20" variant="outlined" />
                    <span>
                      The prospect will receive an audio warning that they will be recorded.
                      <br /> This will happen during the dial tone on your side.
                    </span>
                  </InfoBox>
                </If>
              </Content>
              <Actions>
                <Button primary color="green" type="submit" disabled={Boolean(submitted) || disabled}>
                  <CallIcon width={20} height={20} />
                  <If condition={submitted}>Calling in {formatTime(countdown)}s...</If>
                  <If condition={!submitted}>
                    <If condition={values[RADIO_FIELD_NAME]}>Start Countdown</If>
                    <If condition={!values[RADIO_FIELD_NAME]}>Call</If>
                  </If>
                </Button>
                <Button primary type="button" onClick={onCancel(resetForm)}>
                  Cancel
                </Button>
              </Actions>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
