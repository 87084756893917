import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

import { BooleanSettingsPage } from '../common';

const ModalText = styled.div`
  font-size: 14px;

  .text {
    font-size: 12px;
    color: var(--light-text);
  }
`;

export const Availability = () => {
  const { t } = useTranslation();
  return (
    <BooleanSettingsPage
      flagName="use_nurture_conversation_support"
      modalText={
        <ModalText>
          Set availability of Messaging Assistant for this {t('community')}.
          <div className="text">
            Note that the selected {t('community')} needs to be assigned to a{' '}
            <Link to="/messaging/campaigns" target="_blank">
              campaign
            </Link>{' '}
            with Messaging Assistant ON for this feature to work properly.
          </div>
        </ModalText>
      }
      modalTitle="Messaging Assistant"
    />
  );
};
