import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const Tab = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  cursor: pointer;
  height: 28px;
  &.active {
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
    border-bottom: 1px solid var(--text);
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
`;

export const MyProfileTabs = () => {
  return (
    <Container>
      <Tab className="active">Profile</Tab>
    </Container>
  );
};
