import React, { useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Dimmer, Accordion, Form, Grid, Icon, Loader } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { StepHeader } from './StepHeader';
import { Checkbox } from '../Checkbox';
import { updateChatById, copyChatDemoInstantAnswers } from '../../api';
import { BrandingSection } from '../chats/ChatSettings/BrandingSection';
import { If } from '../If';
import { STATUSES } from '../../constants';
import { OnboardingContext } from './OnboardingContext';

export const ChatSettings = () => {
  const history = useHistory();

  const { chats, chatsStatus, selectedChat, communities } = useContext(OnboardingContext);

  const [accordionState, setAccordionState] = useState(false);
  const [loading, setLoading] = useState(STATUSES.IDLE);

  const formRef = useRef();
  const chat = selectedChat ? chats?.find((chat) => chat.id === selectedChat) : chats && chats[0];

  const initialValues = {
    ...chat,
    font_size: chat?.font_size?.replace('px', ''),
    teaser_scroll_delay: chat?.teaser_scroll_delay?.replace('px', ''),
    ia_demo: false,
  };

  const onSubmit = async (values) => {
    try {
      setLoading(STATUSES.LOADING);
      const { ia_demo, ...chatValues } = values;
      const _chat = {
        ...chatValues,
        font_size: `${values.font_size}px`,
      };

      await updateChatById(chat.id, _chat);
      if (ia_demo) {
        await copyChatDemoInstantAnswers(chat.id, { communities: communities.map((community) => community.id) });
      }

      setLoading(STATUSES.LOADED);
      toast.success('Chat settings saved');
      history.push('/onboarding/steps/5');
    } catch (e) {
      setLoading(STATUSES.FAILURE);
      toast.error('Error: something went wrong and chat settings were not saved');
      console.error(e);
    }
  };

  const chatsLoading = chatsStatus === STATUSES.LOADING;
  const submitLoading = loading === STATUSES.LOADING;

  return (
    <>
      <StepHeader
        title="Configure Chat Settings"
        subtitle="During this step you can optionally make your chat look more custom tailored."
        loading={submitLoading || chatsLoading}
        onContinue={() => {
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }}
      />
      <If condition={STATUSES.LOADED}>
        <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Dimmer active={chatsLoading} inverted>
                <Loader content="Loading Chat" />
              </Dimmer>

              <Grid>
                <Grid.Row>
                  <Grid.Column width={11}>
                    <BrandingSection chat={chat} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Accordion styled>
                      <Accordion.Title active={accordionState} onClick={() => setAccordionState(!accordionState)}>
                        <Icon name="dropdown" />
                        For Internal Use Only
                      </Accordion.Title>
                      <Accordion.Content active={accordionState}>
                        <Checkbox name="ia_demo" label="Activate Instant Answers Demo" />
                      </Accordion.Content>
                    </Accordion>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </If>
    </>
  );
};
