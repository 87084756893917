import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import { getClientOnboardingMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import SearchInput from 'components/SearchInput';
import useDataList from 'utils/hooks/useDataList';
import { STATUSES } from '../../../constants';
import OnboardingMetricsTable from './OnboardingMetricsTable';

const OnboardingMetrics = () => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { items, totalPages, filters, setItems, setPage, setSearch } = useDataList();

  const fetchOnboardingMetrics = useCallback(
    async ({ page, pageSize, search }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await getClientOnboardingMetrics({
          page,
          page_size: pageSize,
          search,
        });

        const items = results.map((r) => ({
          id: r.id,
          email: r.email,
          payingCustomerFrom: r.paying_customer_from,
          providerProfilesCount: r.provider_profiles_count,
          ...r.provider_profiles.reduce(
            (accumulator, p) => {
              p.has_contact_info
                ? accumulator.contactInfoAdded.push(p.name)
                : accumulator.contactInfoMissing.push(p.name);

              p.has_pricing ? accumulator.pricingAdded.push(p.name) : accumulator.pricingMissing.push(p.name);

              p.is_regular_vsa_live
                ? accumulator.regularVsaLive.push(p.name)
                : accumulator.regularVsaNotLive.push(p.name);

              p.is_embedded_vsa_live
                ? accumulator.embeddedVsaLive.push(p.name)
                : accumulator.embeddedVsaNotLive.push(p.name);

              p.images_uploaded ? accumulator.imagesUploaded.push(p.name) : accumulator.imagesNotUploaded.push(p.name);

              return accumulator;
            },
            {
              contactInfoAdded: [],
              contactInfoMissing: [],
              pricingAdded: [],
              pricingMissing: [],
              regularVsaLive: [],
              regularVsaNotLive: [],
              embeddedVsaLive: [],
              embeddedVsaNotLive: [],
              imagesUploaded: [],
              imagesNotUploaded: [],
            }
          ),
        }));

        setItems({ results: items, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        toast.error(parseErrorResponse(e), 'Unable to fetch onboarding metrics');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchOnboardingMetrics(filters);
  }, [fetchOnboardingMetrics, filters]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <SearchInput value={filters.search} fluid onSearch={handleSearch} placeholder="Search users by email" />
      <OnboardingMetricsTable
        clients={items}
        activePage={filters.page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default OnboardingMetrics;
