import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { add, format, differenceInDays, parseISO } from 'date-fns';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { ReportComponent, TimeSeriesChart } from 'components/common/analytics';
import { percent, roundTo } from 'components/utils';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { STATUSES } from 'constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const METRIC_DATASET = {
  accepted: {
    label: 'Fully Accepted %',
    borderColor: '#17bd2a',
    backgroundColor: '#00000000',
  },
  any: {
    label: 'Any Accepted %',
    borderColor: '#289eb5',
    backgroundColor: '#00000000',
  },
  text: {
    label: 'Text Answers %',
    borderColor: '#b33024',
    backgroundColor: '#00000000',
  },
  suggested: {
    label: 'Suggestions %',
    borderColor: '#f7c86f',
    backgroundColor: '#00000000',
  },
  automated: {
    label: 'Automated %',
    borderColor: '#102a61',
    backgroundColor: '#00000000',
  },
  automated_conversations: {
    label: 'Automated Conversations %',
    borderColor: '#000',
    backgroundColor: '#00000000',
  },
};

const tooltip = (
  <ul style={{ width: '400px' }}>
    <li>Fully Accepted %: Percentage of sent answers that were sent as suggested.</li>
    <li>Any Accepted %: Percentage of sent answers that have at least one label as suggested.</li>
    <li>Text Answers %: Percentage of sent answers that include free text.</li>
    <li>Suggestions %: Percentage of opened messages that resulted in any suggestion.</li>
    <li>Automated %: Percentage of messages that were automatically sent.</li>
    <li>Automated Conversations %: Percentage of conversations that are with all automated messages.</li>
  </ul>
);

const groupBy = 'time';
const metrics = [
  'answers.total',
  'answers.answered',
  'answers.unsent',
  'answers.accepted',
  'answers.partially_accepted',
  'answers.automated',
  'answers.text',
  'answers.conversations',
  'answers.conversations.automated',
];

const SuccessGraph = () => {
  const { dateRange, automatedAnswerFilters: filters } = useSystemStatsFilters();
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.ADMIN_METRICS, { ...filters, metrics }],
    () => getMetrics(metrics, filters, groupBy),
    { placeholderData: {} }
  );

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch calls metrics'));
  }

  const chartData = useMemo(() => {
    if (!data || !data[metrics[0]]) {
      return { labels: [], datasets: [] };
    }
    const start = parseISO(dateRange.start_date);
    const end = parseISO(dateRange.end_date);
    const diff = differenceInDays(end, start);
    const dataLookup = metrics.reduce((lookup, m) => {
      lookup[m] = data[m].groups.reduce((r, obj) => {
        r[obj.time] = obj.value;
        return r;
      }, {});
      return lookup;
    }, {});

    const datasetsLookup = Object.keys(METRIC_DATASET).reduce((lookup, m) => {
      lookup[m] = {
        ...METRIC_DATASET[m],
        data: [],
      };
      return lookup;
    }, {});

    const labels = [];
    for (let i = 0; i <= diff; i += 1) {
      const day = format(add(start, { days: i }), 'yyyy-MM-dd');
      labels.push(day);
      const total = dataLookup['answers.total'][day] || 0;
      const suggested = dataLookup['answers.answered'][day] || 0;
      const unsent = dataLookup['answers.unsent'][day] || 0;
      const sent = total - unsent;
      const accepted = dataLookup['answers.accepted'][day] || 0;
      const partiallyAccepted = dataLookup['answers.partially_accepted'][day] || 0;
      const automated = dataLookup['answers.automated'][day] || 0;
      const text = dataLookup['answers.text'][day] || 0;
      const conversations = dataLookup['answers.conversations'][day] || 0;
      const automatedConversations = dataLookup['answers.conversations.automated'][day] || 0;

      datasetsLookup.accepted.data.push(roundTo(percent(accepted, sent)));
      datasetsLookup.any.data.push(roundTo(percent(accepted + partiallyAccepted, sent)));
      datasetsLookup.text.data.push(roundTo(percent(text, sent)));
      datasetsLookup.suggested.data.push(roundTo(percent(suggested, total)));
      datasetsLookup.automated.data.push(roundTo(percent(automated, sent)));
      datasetsLookup.automated_conversations.data.push(roundTo(percent(automatedConversations, conversations)));
    }

    const datasets = Object.keys(datasetsLookup).map((m) => ({
      ...datasetsLookup[m],
      aggFunction: (data, groups, dataset) => {
        return Object.keys(groups).map((d) => {
          const groupLabels = groups[d].map((index) => labels[index]);
          const total = groupLabels.reduce((r, label) => r + (dataLookup['answers.total'][label] || 0), 0);
          if (dataset.label === METRIC_DATASET.suggested.label) {
            const answered = groupLabels.reduce((r, label) => r + (dataLookup['answers.answered'][label] || 0), 0);
            return roundTo(percent(answered, total));
          }
          const unsent = groupLabels.reduce((r, label) => r + (dataLookup['answers.unsent'][label] || 0), 0);
          const sent = total - unsent;
          if (dataset.label === METRIC_DATASET.text.label) {
            const text = groupLabels.reduce((r, label) => r + (dataLookup['answers.text'][label] || 0), 0);
            return roundTo(percent(text, sent));
          }
          const accepted = groupLabels.reduce((r, label) => r + (dataLookup['answers.accepted'][label] || 0), 0);
          if (dataset.label === METRIC_DATASET.any.label) {
            const partiallyAccepted = groupLabels.reduce(
              (r, label) => r + (dataLookup['answers.partially_accepted'][label] || 0),
              0
            );
            return roundTo(percent(accepted + partiallyAccepted, sent));
          }
          if (dataset.label === METRIC_DATASET.automated.label) {
            const automated = groupLabels.reduce((r, label) => r + (dataLookup['answers.automated'][label] || 0), 0);
            return roundTo(percent(automated, sent));
          }
          if (dataset.label === METRIC_DATASET.automated_conversations.label) {
            const automated = groupLabels.reduce(
              (r, label) => r + (dataLookup['answers.conversations.automated'][label] || 0),
              0
            );
            const conversations = groupLabels.reduce(
              (r, label) => r + (dataLookup['answers.conversations'][label] || 0),
              0
            );
            return roundTo(percent(automated, conversations));
          }
          return roundTo(percent(accepted, sent));
        });
      },
    }));

    return { labels, datasets };
  }, [data, dateRange]);

  return (
    <ReportComponent title="Success Metrics" tooltip={tooltip} loading={loaderStatus === STATUSES.LOADING}>
      <TimeSeriesChart data={chartData} height={300} />
    </ReportComponent>
  );
};

export default SuccessGraph;
