import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { ReportComponent, TimeSeriesChart, getLabels } from 'components/common/analytics';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { STATUSES } from 'constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const METRIC_DATASET = {
  total: {
    label: 'Leads with MA',
    borderColor: '#cfcf1f',
    backgroundColor: 'transparent',
  },
  reached: {
    label: 'Reached Leads',
    borderColor: '#17bd2a',
    backgroundColor: 'transparent',
  },
  active: {
    label: 'Active Leads',
    borderColor: '#289eb5',
    backgroundColor: 'transparent',
  },
  opted_out: {
    label: 'Opted Out',
    borderColor: '#b33024',
    backgroundColor: 'transparent',
  },
  ma_active: {
    label: 'MA Engaged',
    borderColor: '#17bd2a',
    backgroundColor: 'transparent',
  },
};

const tooltip = (
  <ul>
    <li>Leads with MA: Leads that belong to community that has MA enabled.</li>
    <li>Reached Leads: Leads that were included in a campaign.</li>
    <li>Active: Leads that answered with at least one message and did not opt out.</li>
    <li>Opted Out: Leads that answered with stop message.</li>
    <li>MA Engaged: Leads that received at least one message from MA.</li>
  </ul>
);

const groupBy = 'time';
const metrics = ['leads.total', 'leads.reached', 'leads.replied', 'leads.opted_out', 'leads.ma_active'];

const LeadTrendsGraph = () => {
  const { dateRange, saleAssistantFilters: filters } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };

  const { data, error, status, isFetching } = useQuery(
    [queryConstants.ADMIN_METRICS, { ...maFilters, metrics }],
    () => getMetrics(metrics, maFilters, groupBy),
    { placeholderData: {} }
  );

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch lead trend metrics'));
  }

  const chartData = useMemo(() => {
    const labels = getLabels(dateRange.start_date, dateRange.end_date);
    const left = { labels, datasets: [] };
    const right = { labels, datasets: [] };
    if (!data || !data[metrics[0]]) {
      return [left, right];
    }
    const dataLookup = metrics.reduce((lookup, m) => {
      lookup[m] = data[m].groups.reduce((r, obj) => {
        r[obj.time] = obj.value;
        return r;
      }, {});
      return lookup;
    }, {});

    left.datasets = ['leads.total', 'leads.reached'].map((m) => ({
      ...METRIC_DATASET[m.substring(6)],
      data: labels.map((l) => dataLookup[m][l] || 0),
    }));

    right.datasets = [
      {
        ...METRIC_DATASET.active,
        data: labels.map((l) => dataLookup['leads.replied'][l] || 0),
      },
      ...['leads.ma_active', 'leads.opted_out'].map((m) => ({
        ...METRIC_DATASET[m.substring(6)],
        data: labels.map((l) => dataLookup[m][l] || 0),
      })),
    ];

    return [left, right];
  }, [data, dateRange]);

  return (
    <ReportComponent title="New Leads over Time" tooltip={tooltip} loading={loaderStatus === STATUSES.LOADING}>
      <TimeSeriesChart data={chartData} height={300} />
    </ReportComponent>
  );
};

export default LeadTrendsGraph;
