import React, { useState } from 'react';
import { FieldArray, useField } from 'formik';
import styled from 'styled-components';

import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import { MEDIA_BREAK_POINTS } from '../../../../../../constants';
import Field from './Fields';
import Selector from './Selector';
import { CRITERIA_DEFAULT_VALUE, FLOW_TYPE, CONNECTOR, CRITERIA_VALUE_TYPE } from '../constants';
import Connector from './Connector';

const AddCriterionButton = styled.div`
  cursor: pointer;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  color: ${({ connector }) => (connector === CONNECTOR.AND ? 'var(--link)' : 'var(--salmon)')};
  display: flex;
  justify-content: center;
  margin-top: 15px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const CriterionRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.WEB}px) {
    width: 78%;
  }
`;

const CriterionAddContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 7px;
  width: 100%;
  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.WEB}px) {
    width: 78%;
  }
`;

const CriterionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const NoCriteria = styled.div`
  text-transform: capitalize;
  font-family: var(--font-family);
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--line-standard);
`;

const Criteria = ({ flowType }) => {
  const { state, hasEditDeletePermission } = useCampaignDetails();
  const { criteria, campaign, triggerCriterion } = state;
  const [showSelect, setShowSelect] = useState(false);
  const fieldName = flowType === FLOW_TYPE.ENTRY ? 'entryCriteriaRootGroup' : 'exitCriteriaRootGroup';
  const criteriaFieldName = `${fieldName}.criteria`;
  const [field] = useField(fieldName);

  const handleSelectorShow = () => {
    if (hasEditDeletePermission) {
      setShowSelect(true);
    }
  };

  const handleAddCriterion =
    (onAdd) =>
    (e, { value }) => {
      setShowSelect(false);
      const selectedCriterion = criteria.find((c) => c.id === value);
      const isOption =
        selectedCriterion.valueType === CRITERIA_VALUE_TYPE.OPTION ||
        selectedCriterion.valueType === CRITERIA_VALUE_TYPE.OPTION_DYNAMIC;
      onAdd({
        criterionId: selectedCriterion.id,
        isTrigger: false,
        name: selectedCriterion.name,
        displayName: selectedCriterion.displayName,
        valueType: selectedCriterion.valueType,
        value: CRITERIA_DEFAULT_VALUE[selectedCriterion.valueType],
        values: [
          !isOption && {
            value: CRITERIA_DEFAULT_VALUE[selectedCriterion.valueType],
          },
        ].filter(Boolean),
        options: [],
        operator: isOption ? selectedCriterion.operators[0].id : '',
        dynamicOptions: [],
      });
    };

  const handleRemoveCriterion = (onRemove, index) => () => {
    if (hasEditDeletePermission) {
      onRemove(index);
    }
  };

  if (!campaign || criteria.length === 0) {
    return null;
  }

  const selectedCriteria = field.value.criteria.map((c) => c.criterionId);
  const criteriaOptions = criteria
    .filter(
      (c) =>
        c.triggeredBy.find((tb) => tb.triggerId === triggerCriterion?.criterionId && tb.flowType === flowType) &&
        !selectedCriteria.includes(c.id)
    )
    .map((c) => ({ key: c.id, text: c.displayName, value: c.id }));

  return (
    <CriterionContainer>
      {field.value.criteria.length === 0 && criteriaOptions.length === 0 && (
        <NoCriteria>No {flowType} Criteria</NoCriteria>
      )}
      <FieldArray name={criteriaFieldName}>
        {({ remove, push }) => (
          <>
            {field.value.criteria
              .sort((c1, c2) => Number(c2.isTrigger) - Number(c1.isTrigger))
              .map((c, index) => (
                <CriterionRow key={index}>
                  <Field
                    index={index}
                    name={criteriaFieldName}
                    criterion={c}
                    onRemove={handleRemoveCriterion(remove, index)}
                  />
                  {field.value.criteria.length - 1 !== index && (
                    <Connector connector={field.value.connector}>{field.value.connector}</Connector>
                  )}
                </CriterionRow>
              ))}
            {criteriaOptions.length !== 0 && (
              <CriterionAddContainer>
                {showSelect ? (
                  <Selector options={criteriaOptions} onChange={handleAddCriterion(push)} />
                ) : (
                  <AddCriterionButton
                    connector={field.value.connector}
                    disabled={!hasEditDeletePermission ? 1 : 0}
                    onClick={handleSelectorShow}
                  >
                    {field.value.criteria.length === 0 ? '+ Add' : `+ Add ${field.value.connector}`}
                  </AddCriterionButton>
                )}
              </CriterionAddContainer>
            )}
          </>
        )}
      </FieldArray>
    </CriterionContainer>
  );
};

export default Criteria;
