import React from 'react';
import styled from 'styled-components';
import { Icon, Label, Message, Popup, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { capitalize } from 'utils/helpers';

const CommunitiesHeaderText = styled.span`
  margin-right: 0.4rem;
`;

const CRMSettingsName = styled.span`
  margin-right: 1rem;
`;

const CRMSettingsList = ({ crmSettings, onRemoveCRMSettings, onEditCRMSettings }) => {
  const { t } = useTranslation();

  if (!crmSettings) {
    return null;
  }

  if (!crmSettings.length) {
    return <Message content="No CRM settings found" icon="search" />;
  }

  const handleRemove = (cs) => () => {
    onRemoveCRMSettings(cs);
  };

  const handleEdit = (cs) => () => {
    onEditCRMSettings(cs);
  };

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Integration</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Enabled</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            <CommunitiesHeaderText>{capitalize(t('community'))}</CommunitiesHeaderText>
            <Popup
              content={`${capitalize(t('communities'))} without CRM settings will use default settings`}
              trigger={<Icon name="info circle" color="grey" />}
            />
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {crmSettings.map((cs) => (
          <Table.Row key={cs.id}>
            <Table.Cell>
              <CRMSettingsName>{cs.name}</CRMSettingsName>
              {cs.is_default && <Label basic color="blue" content="DEFAULT" size="tiny" />}
            </Table.Cell>
            <Table.Cell>{cs.integration_type_name}</Table.Cell>
            <Table.Cell textAlign="center">
              {cs.is_enabled ? <Icon name="check circle" color="green" /> : <Icon name="close" color="red" />}
            </Table.Cell>
            <Table.Cell textAlign="center">{cs.communities_count}</Table.Cell>
            <Table.Cell textAlign="center">
              <Icon name="edit" color="green" link onClick={handleEdit(cs)} />
              <Icon name="trash" color="red" link onClick={handleRemove(cs)} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default CRMSettingsList;
