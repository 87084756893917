import { LEAD_USER_REMINDER_SCHEDULE_TYPE } from '../../../constants';

export const RESPONSE_STEP = {
  BLOCKS: 'blocks',
  TEXT: 'text',
};

export const ANSWER_TYPES = {
  TEXT: 'text',
  LIST: 'list',
};

export const ANSWER_TEXT_PLACEHOLDER = 'Empty Answer';

export const SALES_ASSISTANT_TABS = {
  CAMPAIGN_MESSAGES: 0,
  NOT_FIN_UNQUALIFIED: 1,
  FIN_UNQUALIFIED: 2,
  ARCHIVED: 3,
  FURTHER_MESSAGES: 4,
  UNCONFIRMED_TOURS_CALLS: 5,
  NEW_CONVERSATIONS: 6,
};

export const SALES_ASSISTANT_TAB_FILTERS = {
  [SALES_ASSISTANT_TABS.CAMPAIGN_MESSAGES]: {
    page: 1,
    isArchived: false,
    hasFurtherMessages: false,
    hasUnconfirmedCalendarRequests: false,
    financiallyUnqualified: undefined,
    replied: undefined,
  },
  [SALES_ASSISTANT_TABS.NOT_FIN_UNQUALIFIED]: {
    page: 1,
    isArchived: false,
    hasFurtherMessages: false,
    hasUnconfirmedCalendarRequests: false,
    financiallyUnqualified: false,
    replied: undefined,
  },
  [SALES_ASSISTANT_TABS.FIN_UNQUALIFIED]: {
    page: 1,
    isArchived: false,
    hasFurtherMessages: false,
    hasUnconfirmedCalendarRequests: false,
    financiallyUnqualified: true,
    replied: undefined,
  },
  [SALES_ASSISTANT_TABS.ARCHIVED]: {
    page: 1,
    isArchived: true,
    hasFurtherMessages: false,
    hasUnconfirmedCalendarRequests: false,
    financiallyUnqualified: undefined,
    replied: undefined,
  },
  [SALES_ASSISTANT_TABS.FURTHER_MESSAGES]: {
    page: 1,
    isArchived: undefined,
    hasFurtherMessages: true,
    hasUnconfirmedCalendarRequests: false,
    financiallyUnqualified: undefined,
    replied: undefined,
  },
  [SALES_ASSISTANT_TABS.UNCONFIRMED_TOURS_CALLS]: {
    page: 1,
    isArchived: undefined,
    hasFurtherMessages: undefined,
    hasUnconfirmedCalendarRequests: true,
    financiallyUnqualified: undefined,
    replied: undefined,
  },
  [SALES_ASSISTANT_TABS.NEW_CONVERSATIONS]: {
    page: 1,
    isArchived: false,
    hasFurtherMessages: false,
    hasUnconfirmedCalendarRequests: false,
    financiallyUnqualified: undefined,
    replied: false,
  },
};

export const DEFAULT_LEAD_USER_REMINDER = {
  id: undefined,
  schedule_type: LEAD_USER_REMINDER_SCHEDULE_TYPE.NO_REMINDER,
  scheduled_at: new Date(),
};
