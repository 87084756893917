import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';

import { leadFurtherArchive } from 'apis';
import { PromptButton } from 'components/common';
import { Dimmer, Loader } from 'components/lib';
import SearchInput from 'components/SearchInput';
import { If } from 'components/If';
import { pluralize } from 'utils/helpers';
import NewMessagesBox from './NewMessagesBox';
import InstantResponsesTable from './InstantResponsesTable';

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .ui.input {
    flex-grow: 1;
  }

  .ui.checkbox {
    margin: 0px 20px;
    width: 250px;
  }

  .ui.button {
    margin-left: 20px;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    max-width: 170px;
  }
`;

const InstantResponsesList = ({
  messages,
  filters,
  totalPages,
  newMessagesCount,
  selectable,
  onPageChange,
  onSearch,
  onToggleArchive,
  onNurtureAssistantToggle,
  onNewMessages,
}) => {
  const { page, search, hasNurtureAssistant } = filters;
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [archiving, setArchiving] = useState(false);

  const handleArchiveSelectedMessaged = async () => {
    setArchiving(true);
    const results = [];
    for (let i = 0; i < selectedMessages.length; i++) {
      results.push(leadFurtherArchive(selectedMessages[i], { is_further_archived: true }));
    }
    try {
      await Promise.all(results);
      setSelectedMessages([]);
      toast.success(`${pluralize(selectedMessages.length, 'messages', 'message')} archived successfully`);
      onNewMessages();
    } catch (e) {
      console.error(e);
      toast.error('Error archiving messages. Please try again later, or contact us for help.');
    }
    setArchiving(false);
  };

  if (!messages) {
    return null;
  }

  return (
    <>
      <Dimmer active={archiving} inverted>
        <Loader inverted>Archiving</Loader>
      </Dimmer>
      <ControlsWrapper>
        <SearchInput value={search} fluid onSearch={onSearch} placeholder="Search by leads, community" />
        <If condition={selectable}>
          <PromptButton
            light
            disabled={!selectedMessages.length || archiving}
            content="Archive Selected"
            title="Archive Selected"
            description={`Are you sure you want to archive ${pluralize(
              selectedMessages.length,
              'messages',
              'message'
            )}?`}
            actionLabel="Archive"
            onConfirm={handleArchiveSelectedMessaged}
          />
        </If>
        <ToggleWrapper>
          <Checkbox toggle fitted checked={hasNurtureAssistant} onChange={onNurtureAssistantToggle} />
          <p>MA only communities</p>
        </ToggleWrapper>
      </ControlsWrapper>

      <NewMessagesBox show={!!newMessagesCount} count={newMessagesCount} onClick={onNewMessages} />

      <InstantResponsesTable
        messages={messages}
        activePage={page}
        totalPages={totalPages}
        selectedMessages={selectedMessages}
        onPageChange={onPageChange}
        onToggleArchive={onToggleArchive}
        onSelectedMessagesChange={selectable ? setSelectedMessages : undefined}
      />
    </>
  );
};

export default InstantResponsesList;
