import React from 'react';
import { CommunityPanels } from './CommunityPanels';
import { CommunityInfoPanel } from './CommunityInfoPanel';

export const ConversationSidebarCommunityTab = () => (
  <>
    <CommunityInfoPanel />
    <CommunityPanels />
  </>
);
