import React, { useMemo, useState } from 'react';
import { add, differenceInDays, format, isDate, parse, parseISO, startOfWeek, startOfMonth } from 'date-fns';
import { Bar, Line } from 'react-chartjs-2';
import styled from 'styled-components';

import { Button, Grid } from 'components/lib';

const ChartWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;
const ChartContainer = styled.div`
  height: 300px;
`;

const ChartSwitcher = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
`;

const ChartSwitcherButton = styled(Button)`
  &&& {
    font-size: 12px;
    background: none;
    padding: 10px 0;
    margin-right: 0;
    &:hover {
      background: none;
    }
  }
`;

const ChartHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  h4 {
    margin: 0;
    padding-left: 35px;
    font-family: var(--font-family-bold);
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.5px;
    color: var(--text);
  }
`;

const GraphMode = {
  days: 'days',
  weeks: 'weeks',
  months: 'months',
};

const DEFAULT_CHART_OPTIONS = {
  maintainAspectRatio: false,
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  defaultLineHeight: 50,
  legend: {
    position: 'bottom',
    align: 'end',
    labels: {
      usePointStyle: true,
      boxWidth: 6,
      fontSize: 12,
      fontFamily: 'AvenirLTPro-Heavy, Lato, sans-serif',
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          fontFamily: 'AvenirLTPro-Medium, Lato, sans-serif',
          padding: 16,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
        },
        ticks: {
          fontFamily: 'AvenirLTPro-Medium, Lato, sans-serif',
          min: 0,
          padding: 16,
          maxTicksLimit: 6,
        },
      },
    ],
  },
};

const AGG_FUNCTIONS = {
  sum: (data, groups) =>
    Object.keys(groups).map((groupLabel) =>
      groups[groupLabel].map((idx) => data[idx]).reduce((sum, value) => sum + value, 0)
    ),
  avg: (data, groups) =>
    // we should avoid using average because average of averages is not the same as average of all values
    Object.keys(groups).map(
      (groupLabel) =>
        groups[groupLabel].map((idx) => data[idx]).reduce((sum, value) => sum + value, 0) / groups[groupLabel].length
    ),
  min: (data, groups) => Object.keys(groups).map((groupLabel) => Math.min(groups[groupLabel].map((idx) => data[idx]))),
  max: (data, groups) => Object.keys(groups).map((groupLabel) => Math.max(groups[groupLabel].map((idx) => data[idx]))),
};

const DEFAULT_AGG = AGG_FUNCTIONS.sum;

const getAggFunction = (aggFunction, defaultAggFunction) =>
  typeof aggFunction === 'function' ? aggFunction : AGG_FUNCTIONS[aggFunction] || defaultAggFunction;

const groupLabels = (labels, period) => {
  const groups = {};
  const roundDate = period === GraphMode.weeks ? startOfWeek : startOfMonth;
  labels.forEach((element, index) => {
    const groupLabel = format(roundDate(new Date(element)), 'yyyy-MM-dd');

    if (!groups[groupLabel]) {
      groups[groupLabel] = [index];
    } else {
      groups[groupLabel].push(index);
    }
  });
  return groups;
};

const aggregateData = (chartData, period, onAggregateDatasets) => {
  if (period === GraphMode.days) {
    return {
      ...chartData,
      labels: chartData.labels.map((label) => format(new Date(label), 'MMM d')),
      datasets: chartData.datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.slice(),
      })),
    };
  }

  const groups = groupLabels(chartData.labels, period);
  const labelFormat = period === GraphMode.weeks ? 'MMM d' : 'MMM yyyy';
  return {
    labels: Object.keys(groups).map((d) => format(parse(d, 'yyyy-MM-dd', 0), labelFormat)),
    datasets: onAggregateDatasets
      ? onAggregateDatasets(chartData.datasets, groups, period)
      : chartData.datasets.map((dataset, i) => ({
          ...dataset,
          data: getAggFunction(dataset.aggFunction, DEFAULT_AGG)(dataset.data, groups, dataset, i),
        })),
  };
};

const getLabels = (startDate, endDate) => {
  const start = isDate(startDate) ? startDate : parseISO(startDate);
  const end = isDate(endDate) ? endDate : parseISO(endDate);
  const diff = differenceInDays(end, start);
  const labels = [];
  for (let i = 0; i <= diff; i += 1) {
    const day = format(add(start, { days: i }), 'yyyy-MM-dd');
    labels.push(day);
  }
  return labels;
};

const TimeSeriesChart = ({
  data,
  title,
  onAggregateDatasets,
  loaderStatus,
  height = 300,
  type,
  options = DEFAULT_CHART_OPTIONS,
}) => {
  const [period, setPeriod] = useState(GraphMode.days);
  const SeriesType =
    {
      bar: Bar,
      line: Line,
    }[type] || Line;

  const switchTo = (period = GraphMode.days) => {
    setPeriod(period);
  };

  const periodData = useMemo(() => {
    if (!data || !data) {
      return data;
    }
    return Array.isArray(data)
      ? data.map((chartData) => aggregateData(chartData, period, onAggregateDatasets))
      : aggregateData(data, period, onAggregateDatasets);
  }, [data, period, onAggregateDatasets]);

  const charts = Array.isArray(periodData) ? (
    <Grid stackable>
      <Grid.Row columns={data.length}>
        {periodData.map((chartData, index) => (
          <Grid.Column key={index}>
            <SeriesType data={chartData} width={100} height={height} options={options} />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  ) : (
    <SeriesType data={periodData} width={100} height={height} options={options} />
  );
  return (
    <ChartWrapper>
      <ChartHeader>
        <h4>{title}</h4>
        <ChartSwitcher>
          <ChartSwitcherButton active={period === GraphMode.days} onClick={() => switchTo(GraphMode.days)}>
            Day
          </ChartSwitcherButton>
          <ChartSwitcherButton active={period === GraphMode.weeks} onClick={() => switchTo(GraphMode.weeks)}>
            Week
          </ChartSwitcherButton>
          <ChartSwitcherButton active={period === GraphMode.months} onClick={() => switchTo(GraphMode.months)}>
            Month
          </ChartSwitcherButton>
        </ChartSwitcher>
      </ChartHeader>

      <ChartContainer>{charts}</ChartContainer>
    </ChartWrapper>
  );
};

export { getLabels };
export default TimeSeriesChart;
