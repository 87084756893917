import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TabsWithNavigation } from 'components/lib';
import { PhoneNumbersImport } from 'components/admin/PhoneNumbersImport/PhoneNumbersImport';
import { CallTracking } from './CallTracking';
import { Transcripts } from './Transcripts';
import { MobileDisplay } from './MobileDisplay';
import { PhoneSystemProvider } from './PhoneSystemContext';
import { Voice } from './Voice';
import { UserContext } from '../../UserContext';
import { CallWhisper } from './CallWhisper';
import { DigitSpeech } from './DigitSpeech';

const PHONE_ASSISTANT_TABS = [
  {
    tabName: 'Call Tracking',
    path: '/advanced/phone-assistant/call-tracking',
    Content: CallTracking,
  },
  {
    tabName: 'Call Whisper',
    path: '/advanced/phone-assistant/call-whisper',
    Content: CallWhisper,
  },
  {
    tabName: 'Transcripts',
    path: '/advanced/phone-assistant/transcripts',
    Content: Transcripts,
  },
  {
    tabName: 'Voice',
    path: '/advanced/phone-assistant/voice',
    Content: Voice,
  },
  {
    tabName: 'Mobile Display',
    path: '/advanced/phone-assistant/mobile-display',
    Content: MobileDisplay,
  },
  {
    tabName: 'Answer Timeouts',
    path: '/advanced/phone-assistant/answer-timeouts',
    Content: DigitSpeech,
  },
];

const IMPORT_PHONE_NUMBERS_TAB = {
  tabName: 'Import Phone Numbers',
  path: '/advanced/phone-assistant/import-phone-numbers',
  Content: PhoneNumbersImport,
};

const INITIAL_TAB = PHONE_ASSISTANT_TABS[0].path;

export const PhoneAssistantRoot = () => {
  const history = useHistory();
  const location = useLocation();
  const { supportingRegularUser } = useContext(UserContext);

  useEffect(() => {
    if (location.pathname === '/advanced/phone-assistant') {
      history.replace(INITIAL_TAB);
    }
  }, []);

  const tabs = supportingRegularUser ? [...PHONE_ASSISTANT_TABS, IMPORT_PHONE_NUMBERS_TAB] : PHONE_ASSISTANT_TABS;

  return (
    <PhoneSystemProvider>
      <TabsWithNavigation tabs={tabs} />
    </PhoneSystemProvider>
  );
};
