import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Header, Message, Segment } from 'semantic-ui-react';
import { formatDistanceStrict, format, parseISO } from 'date-fns';

import { If } from 'components/If';
import { MessageContext } from './MessageContext';

export const MessageInformation = () => {
  const { messageId } = useParams();
  const { messages } = useContext(MessageContext);

  const targetMessage = messages.find((message) => message.id === parseInt(messageId));
  const responseMessage = messages
    .filter((message) => message.created_on > targetMessage?.created_on && (message.from_user || message.is_system))
    .find(() => true);

  const targetMessageTime = targetMessage && parseISO(targetMessage?.created_on);
  const responseMessageTime = responseMessage && parseISO(responseMessage?.created_on);
  const responseTime =
    targetMessageTime && responseMessageTime && formatDistanceStrict(targetMessageTime, responseMessageTime);

  return (
    <Segment.Group>
      <Segment>Message Information</Segment>
      <Segment secondary>
        <If condition={targetMessage}>
          <Header sub>Response time</Header>
          <span>{responseTime}</span>

          <Header sub>Message received</Header>
          <span>{targetMessageTime && format(targetMessageTime, 'MM/dd/yyyy hh:mm a')}</span>

          <If condition={responseMessage}>
            <Header sub>First message response</Header>
            <span>{responseMessageTime && format(responseMessageTime, 'MM/dd/yyyy hh:mm a')}</span>
          </If>
        </If>

        <If condition={!targetMessage}>
          <Message content="Could not find specified user message" error />
        </If>

        <If condition={targetMessage && !responseMessage}>
          <Message content="Response for message never sent!" warning />
        </If>
      </Segment>
    </Segment.Group>
  );
};
