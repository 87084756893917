import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';

const Conatiner = styled.div`
  display: flex;
`;

const Text = styled.p.attrs(({ $color }) => ({
  style: {
    color: $color,
  },
}))`
  margin: 0;
  margin-left: 5px;
  font-size: 12px;
`;

export const VSA_TYPE_ICON = {
  embedded: 'wysiwyg',
  survey: 'wysiwyg',
  regular: 'question_answer',
  phone: 'phone',
};

export const VSA_TYPE_COLOR = {
  embedded: 'rgb(95, 95, 255)',
  survey: 'rgb(95, 95, 255)',
  regular: '#2791ff',
  phone: '#0ab776',
};

export const ChatTemplateItem = ({ type, text }) => {
  const icon = VSA_TYPE_ICON[type] || 'chat';
  const color = VSA_TYPE_COLOR[type] || 'black';
  return (
    <Conatiner>
      <Icon name={icon} color={color} variant="outlined" size="12" />
      <Text $color={color}>{text}</Text>
    </Conatiner>
  );
};
