import * as Yup from 'yup';

const getUserValidationSchema = (inviteSectionEnabled) => {
  return Yup.object().shape({
    firstName: Yup.string().max(150, 'First name cannot be larger than 150 characters'),
    lastName: Yup.string().max(150, 'Last name cannot be larger than 150 characters'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string()
      .nullable()
      .matches(/^[\d()+-]+$/, 'Phone number can only contain digits, "+", "(", ")", "-"'),
    marketType: Yup.string().required('Market type is required'),
    companyName: Yup.string()
      .max(500, 'Company name cannot be larger than 500 characters')
      .when('payingCustomer', {
        is: true,
        then: Yup.string().required('Company name is required for paying customers'),
        otherwise: Yup.string().nullable(),
      }),
    accountManager: Yup.string().nullable(),
    type: Yup.string().nullable(),
    isAgency: Yup.boolean(),
    expiresIn: Yup.number()
      .nullable()
      .when([], {
        is: () => inviteSectionEnabled,
        then: Yup.number()
          .required('Expiration days is required')
          .min(1, 'Expiration days must be greater than or equal to 1'),
        otherwise: Yup.number().nullable(),
      }),
    invite: Yup.object().nullable(),
  });
};

export default getUserValidationSchema;
