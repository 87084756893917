import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Icon, Input } from 'components/lib';
import CommunityRow from 'components/common/communities/CommunityRow';
import { UserCircle } from 'components/UserCircle';
import { If } from '../../If';
import { UserContext } from '../../UserContext';
import { CommunitiesContext, ACTIONS } from './CommunitiesContext';
import { ConversationsListPlaceholder } from '../../messages/ConversationsListPlaceholder';
import { MEDIA_BREAK_POINTS, STATUSES, USER_TYPES } from '../../../constants';

const CommunitiesMenuWrapper = styled.div`
  background: var(--white);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    width: 340px;
    border-right: 1px solid var(--line-standard);
  }
`;

const CommunitiesHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 10px 10px 10px;
  width: 100%;
`;

const CommunitiesHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  h3 {
    font-family: var(--font-family-bold);
    margin-right: auto;
    font-size: 20px;
    color: var(--text);
    text-transform: capitalize;
  }
  span {
    margin-left: 16px;
  }
`;

const CommunitiesList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  will-change: scroll-position;
  &:-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  & > div:not(:first-child) {
    margin-left: -3px;
  }
`;

export const UserCommunitiesList = ({ communitiesCallback }) => {
  const { communityId } = useParams();
  const history = useHistory();
  const { dispatch, state, linkPrefix } = useContext(CommunitiesContext);
  const { assignedCommunities, activeCommunity } = state;
  const [filter, setFilter] = useState('');
  const { t } = useTranslation();

  const { user, isUserType } = useContext(UserContext);

  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_COMMUNITY,
      payload: { communityId: parseInt(communityId) },
    });
  }, [communityId]);

  useEffect(() => {
    const loadCommunities = async () => {
      dispatch({ type: ACTIONS.SET_LOADING_STATUS, payload: STATUSES.LOADING });
      try {
        const data = await communitiesCallback();

        if (data?.results?.length > 0) {
          dispatch({ type: ACTIONS.SET_ASSIGNED_COMMUNITIES, payload: { communities: data.results } });
        } else {
          dispatch({ type: ACTIONS.CLEAR_ASSIGNED_COMMUNITIES });
        }

        dispatch({ type: ACTIONS.SET_LOADING_STATUS, payload: STATUSES.LOADED });
      } catch (e) {
        console.error(e);
        dispatch({ type: ACTIONS.SET_LOADING_STATUS, payload: STATUSES.FAILURE });
      }
    };

    if (typeof communitiesCallback === 'function') {
      loadCommunities();
    }
  }, [user, dispatch]);

  const handleCommunitySelect = (community) => {
    history.push(`${linkPrefix}${community}`);
  };

  const renderUsers = (community) => {
    if (community.users?.length === 0) {
      return null;
    }

    return (
      <UserContainer>
        {community?.users?.map((user, i) => (
          <UserCircle key={i} user={user} size="18" />
        ))}
      </UserContainer>
    );
  };

  const filteredCommunities = assignedCommunities?.filter(
    (community) => !filter || community?.name?.toLowerCase().includes(filter.toLowerCase())
  );

  const hasCommunities = assignedCommunities?.length > 0;

  return (
    <CommunitiesMenuWrapper>
      <CommunitiesHeaderContainer>
        <CommunitiesHeader>
          <h3>{t('communities')}</h3>
          <If condition={isUserType(USER_TYPES.REGULAR)}>
            <Link to="/onboarding/steps/1">
              <Icon name="add" size="22" color="grey" />
            </Link>
          </If>
        </CommunitiesHeader>

        <Input
          disabled={!hasCommunities}
          icon={<Icon name="search" color="#89919d" size="22" />}
          iconPosition="left"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Escape') {
              setFilter('');
            }
          }}
        />
      </CommunitiesHeaderContainer>
      <If condition={hasCommunities}>
        <CommunitiesList>
          {filteredCommunities?.map((community, i) => {
            return (
              <CommunityRow
                key={i}
                community={community}
                onItemSelect={handleCommunitySelect}
                active={activeCommunity?.id === community?.id}
                subtitle={community?.location?.address}
                additionalElement={renderUsers(community)}
              />
            );
          })}
        </CommunitiesList>
      </If>
      <ConversationsListPlaceholder loading={!hasCommunities} />
    </CommunitiesMenuWrapper>
  );
};
