import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import FormFields from './UserManagementFormFields';
import getUserValidationSchema from './UserManagementValidationSchema';

const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  marketType: '',
  payingCustomer: false,
  inPilot: false,
  demoAccount: false,
  companyName: '',
  accountManager: '',
  type: null,
  isAgency: false,
  managingUsers: [],
  invite: null,
  expiresIn: null,
};

const UserManagementForm = ({ user, loading, onSubmit }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState();
  const [isAgencyChecked, setIsAgencyChecked] = useState(false);
  const [inviteSectionEnabled, setInviteSectionEnabled] = useState(true);

  const isEditMode = !!user;

  useEffect(() => {
    if (user) {
      const {
        firstName,
        lastName,
        email,
        phone,
        companyName,
        accountManager,
        marketType,
        payingCustomer,
        inPilot,
        demoAccount,
        isAgency,
        managingUsers,
        invite,
        type,
      } = user;

      const manageUsersIds = managingUsers.map((user) => user.id);

      setInitialValues({
        firstName: firstName || '',
        lastName: lastName || '',
        email: email || '',
        phone: phone || '',
        marketType: marketType.toString(),
        payingCustomer: !!payingCustomer,
        inPilot: !!inPilot,
        demoAccount: !!demoAccount,
        companyName: companyName || '',
        accountManager: accountManager?.id,
        type: type || null,
        isAgency: !!isAgency,
        managingUsers: managingUsers || [],
        manageUsers: manageUsersIds || [],
        invite: null,
        expiresIn: null,
      });
      setInviteSectionEnabled(false);
      if (user.isAgency) {
        setIsAgencyChecked(true);
      }
    } else {
      setInitialValues(INITIAL_VALUES);
      setInviteSectionEnabled(true);
    }
  }, [user]);

  if (!initialValues) {
    return null;
  }

  const handleAgencyCheckboxChange = (setFieldValue, values) => {
    setFieldValue('isAgency', !values.isAgency);
    setIsAgencyChecked(!isAgencyChecked);
  };

  const handleToggleChange = (setFieldValue, values) => {
    const newInviteSectionEnabled = !inviteSectionEnabled;
    setInviteSectionEnabled(newInviteSectionEnabled);

    if (!newInviteSectionEnabled) {
      setFieldValue('invite', null);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={getUserValidationSchema(inviteSectionEnabled)}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <FormFields
            values={values}
            user={user}
            loading={loading}
            isEditMode={isEditMode}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            inviteSectionEnabled={inviteSectionEnabled}
            handleAgencyCheckboxChange={handleAgencyCheckboxChange}
            handleToggleChange={handleToggleChange}
            isAgencyChecked={isAgencyChecked}
          />
        );
      }}
    </Formik>
  );
};

export default UserManagementForm;
