import React, { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';

import { STATUSES } from '../../../../constants';
import reducer from './reducer';
import { TABS } from './constants';

const INITIAL_STATE = {
  loaderStatus: STATUSES.IDLE,
  activeTab: TABS.CRM_SETTINGS,
  userId: undefined,
  crmSettings: undefined,
  crmGlobalSettings: undefined,
  defaultCRMSettings: undefined,
  crmSettingsOptions: [],
  communities: undefined,
  crmLocationCodeRules: undefined,
  crmLocationCodeRuleOptions: [],
  crmCommunityInvalidSettings: undefined,
  eligibleTrafficSources: [],
  eligibleLeadSources: [],
  eligibleUrlParameters: [],
};

export const CRMContext = createContext();

const CRMContextProvider = (props) => {
  const [state, dispatch] = useImmerReducer(reducer, INITIAL_STATE);

  const value = [state, dispatch];

  return <CRMContext.Provider value={value}>{props.children}</CRMContext.Provider>;
};

export function useCRM() {
  const context = useContext(CRMContext);

  if (!context) {
    throw new Error('useCRM must be used within CRMContextProvider');
  }

  return context;
}

export default CRMContextProvider;
