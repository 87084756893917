import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { getCareTypes, getCommunityCareTypes, updateCommunityCareType } from 'apis';
import { STATUSES } from 'constants';

export const CareTypesContext = createContext();

export const CareTypesContextProvider = (props) => {
  const { communityId } = useParams();
  const [status, setStatus] = useState(STATUSES.IDLE);
  const [careTypes, setCareTypes] = useState([]);
  const [communityCareTypes, setCommunityCareTypes] = useState([]);
  const [editCareType, setEditCareType] = useState(undefined);

  const fetchCareTypes = async () => {
    try {
      setStatus(STATUSES.LOADING);
      const { results } = await getCareTypes({ community: communityId });
      setCareTypes(results);
      setStatus(STATUSES.LOADED);
    } catch (e) {
      setStatus(STATUSES.FAILURE);
    }
  };

  const fetchCommunityCareTypes = async () => {
    try {
      setStatus(STATUSES.LOADING);
      const { results } = await getCommunityCareTypes(communityId);
      setCommunityCareTypes(results);
      setStatus(STATUSES.LOADED);
    } catch (e) {
      setStatus(STATUSES.FAILURE);
    }
  };

  const refreshCareTypes = async () => {
    fetchCareTypes();
    fetchCommunityCareTypes();
  };

  const changeCommunityCareType = async (data, careTypeId) => {
    try {
      setStatus(STATUSES.LOADING);

      await updateCommunityCareType(communityId, careTypeId, data);
      await fetchCommunityCareTypes();

      setEditCareType(undefined);
      setStatus(STATUSES.LOADED);
    } catch (e) {
      setStatus(STATUSES.FAILURE);
    }
  };

  useEffect(() => {
    refreshCareTypes();
  }, [communityId]);

  const provide = {
    status,
    careTypes,
    communityCareTypes,
    editCareType,
    setStatus,
    setCareTypes,
    setCommunityCareTypes,
    setEditCareType,
    fetchCareTypes,
    fetchCommunityCareTypes,
    refreshCareTypes,
    changeCommunityCareType,
    communityId,
  };

  return <CareTypesContext.Provider value={provide} {...props} />;
};
