import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import { useParams } from 'react-router';

import { formatAnyDate, debounce } from 'components/utils';
import { If } from 'components/If';
import { splitName } from 'utils/helpers';
import { Notifications } from './chat/Notifications';
import { MEDIA_BREAK_POINTS, VSA_LINE_TYPE } from '../../constants';
import { ConversationMessage } from './ConversationMessage';
import ChatRow from './chat/ChatRow';
import ScheduledMessage from './chat/ScheduledMessage';
import { ConversationContext } from './ConversationContext';
import { ConversationFloatingReminderConfirm } from './actions';
import { CONVERSATION_MESSAGE_TYPE, CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS, ROW_TYPE_CLASS_NAME } from './constants';
import { ConversationTypeFilter } from './ConversationTypeFilter';
import { ConversationNoteModal } from './ConversationNoteModal';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 1rem;
  background-color: #f6f7fa;
  overflow-y: auto;
  align-items: center;
  height: 0px;
  justify-content: space-between;
  position: relative;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    margin-top: 0;
    padding: 0 30px 0 31px;
  }
`;

const RowContainer = styled.div`
  max-width: 1024px;
  width: 100%;
`;

const Day = styled.span`
  width: 100%;
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: var(--light-text);
  display: block;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const CampaignName = styled.div`
  align-self: flex-start;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #89919d;

  & span {
    color: var(--link);
  }
`;

export const ConversationMessageList = ({ conversation, viewPortContained, lead, onResendMessage }) => {
  const { conversationId } = useParams();
  const { removeOutreachLoading, removeFromOutreach, selectedConversation } = useContext(ConversationContext);
  const containerRef = useRef();
  const [scroll, setScroll] = useState(0);
  const [filterValue, setFilterValue] = useState(null);

  const resizeObserver = new ResizeObserver(
    debounce(() => {
      setScroll(containerRef?.current?.scrollHeight);
    }),
    20
  );

  useEffect(() => {
    resizeObserver.observe(containerRef?.current);
    return () => resizeObserver.unobserve(containerRef?.current);
  }, []);

  useEffect(() => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [conversationId]);

  if (viewPortContained && containerRef?.current?.scrollHeight && scroll !== containerRef?.current?.scrollHeight) {
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
    setScroll(containerRef?.current?.scrollHeight);
  }

  const handleLoad = () => {
    if (viewPortContained) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const rowTypeClassName = (message) => {
    if (message.message_type === CONVERSATION_MESSAGE_TYPE.VSA && message.data?.line_type === VSA_LINE_TYPE.EVENT) {
      return ROW_TYPE_CLASS_NAME.USER_EVENT;
    }

    return ROW_TYPE_CLASS_NAME.OTHER_TYPES;
  };

  const className = classNames({ viewPortContained });

  const filteredConversation = useMemo(() => {
    let newConversation = conversation;

    if (!newConversation || newConversation.length === 0) {
      return [];
    }

    if (filterValue) {
      newConversation = conversation.filter((message) => message.message_type === filterValue);
    }

    return newConversation;
  }, [conversation, filterValue]);

  const groupedByDay = filteredConversation.reduce((r, a) => {
    const { created_on } = a;
    const day = formatAnyDate(created_on, 'MMM d, y');

    r[day] = r[day] || [];
    r[day].push(a);
    return r;
  }, Object.create(null));

  const { scheduled_message, campaigns, community } = lead;
  const leadData = {
    ...splitName(lead.name),
    email: lead.email,
    id: lead.id,
  };

  const groupedDayKeys = Object.keys(groupedByDay);

  return (
    <Container className={className} ref={containerRef}>
      <ConversationTypeFilter setFilterValue={setFilterValue} filterValue={filterValue} />
      <ConversationNoteModal />
      {groupedDayKeys.map((key) => (
        <RowContainer key={key}>
          <Day>{key}</Day>
          {groupedByDay[key].map((message, i) => (
            <ChatRow className={rowTypeClassName(message)} key={i}>
              <ConversationMessage
                message={message}
                user={leadData}
                community={community}
                onAttachmentLoad={handleLoad}
                onResendMessage={onResendMessage}
              />
            </ChatRow>
          ))}
        </RowContainer>
      ))}
      <If condition={!filterValue || filterValue === CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS.OTHER_EVENTS}>
        {scheduled_message && (
          <RowContainer>
            <ChatRow>
              <ScheduledMessage
                lead={lead}
                campaign={campaigns.find((c) => c.id === scheduled_message.campaign_id)}
                message={scheduled_message}
                loading={removeOutreachLoading}
                onRemoveFromOutreach={removeFromOutreach}
              />
            </ChatRow>
          </RowContainer>
        )}

        <If condition={selectedConversation?.tour}>
          <If
            condition={
              !selectedConversation?.tour?.cancelled &&
              !selectedConversation?.tour?.confirmed &&
              !selectedConversation?.tour?.reschedule_id
            }
          >
            <ConversationFloatingReminderConfirm type="tour" />
          </If>
        </If>
        <If condition={selectedConversation?.call}>
          <If
            condition={
              !selectedConversation?.call?.cancelled &&
              !selectedConversation?.call?.confirmed &&
              !selectedConversation?.call?.reschedule_id
            }
          >
            <ConversationFloatingReminderConfirm type="call" />
          </If>
        </If>
        <Notifications />
        {campaigns && !!campaigns.length && (
          <RowContainer>
            <CampaignName>
              Campaigns: <span>{campaigns.map((c) => c.name).join(', ')}</span>
            </CampaignName>
          </RowContainer>
        )}
      </If>
    </Container>
  );
};
