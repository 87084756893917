import React from 'react';
import styled from 'styled-components';

import Button from '../Button';

const StyledButton = styled(Button)`
  color: #79aec8;
  text-decoration: none;
  background-color: transparent;

  &:focus {
    text-decoration: underline;
    box-shadow: none;
  }

  &:hover {
    color: #0056b3;
  }
`;

export const LinkButton = ({ children, ...props }) => <StyledButton {...props}>{children}</StyledButton>;
