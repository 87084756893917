import React, { useState, useRef, useContext } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { updateCommunitiesContactInfo, validateContactInfo } from 'apis';
import { StepHeader } from './StepHeader';
import { ContactInfoForm } from './ContactInfoForm';
import { OnboardingContext } from './OnboardingContext';

export const ContactInfo = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { communities, fetchCommunities } = useContext(OnboardingContext);
  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  const onSave = async (communities) => {
    try {
      setLoading(true);
      await validateContactInfo(communities);
      await updateCommunitiesContactInfo(communities);
      await fetchCommunities();
      toast.success('Contact info saved!');
      history.push('/onboarding/steps/3');
    } catch (e) {
      console.error(e);
      toast.error('Error saving contact info. Please try again later, or contact us for help.');
      setLoading(false);
    }
  };

  return (
    <>
      <StepHeader
        title="Define Contact Info"
        subtitle={`Enter the contact point emails for your locations. We'll send email notifications with new ${t(
          'lead'
        )}'s information to these addresses.`}
        loading={loading}
        onContinue={() => {
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }}
      />

      <ContactInfoForm communities={communities} formRef={formRef} onSubmit={onSave} />
    </>
  );
};
