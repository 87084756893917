import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

import { Radio } from 'components/lib';
import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import Display from './Display';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .display {
    flex: 2;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  min-height: 36px;
  height: 36px;
  flex: 1;
`;

const Bool = ({ name, criterion }) => {
  const { hasEditDeletePermission } = useCampaignDetails();
  const fieldName = `${name}.value`;
  const [field, _, helpers] = useField(fieldName);

  const onRadioChange = (_, { value }) => {
    helpers.setValue(value);
    helpers.setTouched(true);
  };

  return (
    <Container>
      <RadioContainer>
        <Radio
          disabled={!hasEditDeletePermission}
          value={true}
          checked={field.value}
          label="Yes"
          onChange={onRadioChange}
        />
        <Radio
          disabled={!hasEditDeletePermission}
          value={false}
          checked={!field.value}
          label="No"
          onChange={onRadioChange}
        />
      </RadioContainer>
      <Display size="md" className="display">
        {criterion.displayName}
      </Display>
    </Container>
  );
};

export default Bool;
