import React, { useContext, useEffect, useState } from 'react';
import { Header, Table, Button, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { CSVReader } from 'react-papaparse';
import styled from 'styled-components';
import * as Yup from 'yup';

import { getInviteLocations } from 'api';
import { createCommunitiesFromInviteLocations, validateLocations } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { Accordion, Icon, Message } from 'components/lib';
import { CsvExport } from '../common';
import { LocationForm } from './LocationForm';
import { StepHeader } from './StepHeader';
import { StickyTableHeader } from '../common/PricingTable';
import { OnboardingContext } from './OnboardingContext';
import { LOCATIONS, LOCATION_HEADERS } from '../../constants';

const PaddedBox = styled.div`
  padding-left: 2rem;
`;

const LOCATION_TYPES = {
  ASSISTED_LIVING: 'ASSISTED_LIVING',
};

const validationSchema = Yup.object({
  name: Yup.string().max(300, `Can't be longer than 300 characters`).required('Required'),
  url: Yup.string().url('Must be a valid URL').max(300, `Can't be longer than 300 characters`).nullable(),
});

const Row = ({ location, index, locationTypes, onUpdate, onDelete, locations }) => {
  const urlValidation = Yup.string()
    .url('Must be a valid URL')
    .max(300, `Can't be longer than 300 characters`)
    .nullable();

  const [editing, setEditing] = useState(false);
  const [isValidData, setIsValidData] = useState(true);

  useEffect(() => {
    const validateData = async () => {
      const response = await validationSchema.isValid({
        name: location.name,
        type: location.type,
        url: location.url,
      });
      const floorPlanValid = await urlValidation.isValid(location.floor_plan_url);

      setIsValidData(response && floorPlanValid);
    };

    validateData();
  }, [location, urlValidation]);

  return editing ? (
    <LocationForm
      locations={locations}
      location={location}
      locationTypes={locationTypes}
      onSubmit={(updatedLocation) => {
        setEditing(false);
        if (typeof onUpdate === 'function') {
          onUpdate(updatedLocation, index);
        }
      }}
      onCancel={() => {
        setEditing(false);
      }}
    />
  ) : (
    <Table.Row negative={!isValidData}>
      <Table.Cell>
        <Header as="h5">{location.name}</Header>
      </Table.Cell>
      <Table.Cell>{location.url}</Table.Cell>
      <Table.Cell textAlign="right">
        <Button.Group>
          <Button
            icon="edit"
            onClick={() => {
              setEditing(true);
            }}
          />
          <Button
            icon="delete"
            onClick={() => {
              if (typeof onDelete === 'function') {
                onDelete(index);
              }
            }}
          />
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );
};

export const Locations = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);
  const { setCommunities } = useContext(OnboardingContext);
  const [locationsModalActive, setLocationsModalActive] = useState(false);
  const [tempLocations, setTempLocations] = useState(null);
  const [duplicateUrls, setDuplicateUrls] = useState();
  const [activeIndex, setActiveIndex] = useState();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const { locations, location_types } = await getInviteLocations();
        setLocations(locations);
        setLocationTypes(location_types);
      } catch (e) {
        console.error(e);
        toast.error(
          'Error fetching predefined locations. Please fill in location info manually, or contact us for help.'
        );
      }
    };

    fetchLocations();
  }, []);

  const addLocation = (location) => {
    const hasDuplicateLocation = locations.some((item) => item.url === location.url);
    if (hasDuplicateLocation) {
      toast.error('Location with this url already entered');
    } else {
      setLocations([...locations, location]);
    }
  };

  const updateLocation = (location, index) => {
    const hasDuplicateLocation = locations.some((item, i) => index !== i && item.url === location.url);
    if (hasDuplicateLocation) {
      toast.error('Location with this url already entered');
    } else {
      setLocations(locations.map((l, i) => (i === index ? location : l)));
    }
  };

  const deleteLocation = (index) => {
    setLocations(locations.filter((l, i) => i !== index));
  };

  const onSave = async () => {
    try {
      setLoading(true);
      const validationResult = await validateLocations(locations);

      if (locations.length === 0) {
        toast.error('You must define at least one location');
      }
      if (validationResult.name_type_url.length) {
        toast.error(`You have invalid location types,urls or names: ${validationResult.name_type_url}`);
      }
      if (validationResult.floor_plan_urls.length) {
        toast.error(`Invalid floor plan urls: ${validationResult.floor_plan_urls}`);
      }
      if (validationResult.city_states.length) {
        const message = validationResult.city_states.map((cityState) => `${cityState.city}/${cityState.state}`);
        toast.error(`City/state doesn't exist in the system: ${message} `);
      }
      if (validationResult.duplicate_location_urls.length) {
        toast.error(`Duplicate urls in the file: ${validationResult.duplicate_location_urls}`);
      }
      if (validationResult.duplicate_facilities.length) {
        const message = validationResult.duplicate_facilities.map((facility) => `[${facility.name} ${facility.url}]`);
        toast.error(`Duplicate facilities in the system: ${message}`);
      }
      if (validationResult.duplicate_location_attributes.length) {
        toast.error(
          `Duplicate locations in the file (slug/type/city/state): ${validationResult.duplicate_location_attributes}`
        );
      }
      if (validationResult.invalid_field_lengths.length) {
        toast.error(
          `Text under these fields exceed max number of characters: ${validationResult.invalid_field_lengths}`
        );
      }
      if (validationResult?.duplicate_urls?.length) {
        setDuplicateUrls(validationResult?.duplicate_urls);
      }
      if (locations.length && validationResult.valid) {
        const communities = await createCommunitiesFromInviteLocations({ locations });

        setCommunities(communities);
        toast.success('Location communities successfully created');
        history.push('/onboarding/steps/2');
      }
    } catch (e) {
      console.error(e);
      const parsedErr = parseErrorResponse(e);

      if (parsedErr) {
        toast.error(parsedErr);
      } else {
        toast.error('Error creating location communities. Please try again later, or contact us for help.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFileLoaded = (payload, fileInfo) => {
    setTempLocations(payload.map((item) => item.data));
  };

  const handleUpload = () => {
    if (tempLocations === null) {
      return;
    }
    const locationNames = locations.map((location) => location.name);
    const locationTypeKeys = locationTypes.map((type) => type[0]);
    const newLocations = locations.concat(
      tempLocations
        .filter((location) => location.name && location.type && location.url && !locationNames.includes(location.name))
        .map((location) => ({ ...location, type: locationTypeKeys.includes(location.type) ? location.type : null }))
    );

    setLocations(newLocations);
    setLocationsModalActive(false);
  };

  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
  };

  const handleClickImportLocations = () => {
    setLocationsModalActive(true);
    setTempLocations(null);
  };

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <>
      <StepHeader
        title="Define Locations"
        subtitle="Fill in all the facility names and website URLs where you want to use Further"
        loading={loading}
        onContinue={() => {
          onSave();
        }}
      />
      <div style={{ marginBottom: '10px' }}>
        <Button primary onClick={handleClickImportLocations}>
          Import Locations
        </Button>
        <div style={{ float: 'right' }}>
          <CsvExport
            data={LOCATIONS}
            headers={LOCATION_HEADERS}
            filename="Facility template.csv"
            content="Download template CSV"
          />
        </div>
      </div>

      <Message hidden={!duplicateUrls} warning>
        <Message.Header>Duplicate URLs</Message.Header>
        <p>
          The following URLs are duplicated in our system. Please expand the urls to see the communities that are
          associated with the url
        </p>
        <Accordion>
          {duplicateUrls &&
            duplicateUrls.map((url, i) => (
              <>
                <Accordion.Title key={url.url} active={activeIndex === i} index={i} onClick={handleAccordionClick}>
                  <Icon className="caret" name="chevron_right" size="22" variant="outlined" />
                  {url.url}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === i}>
                  <PaddedBox>
                    <Message.List>
                      {url.communities.map((community) => (
                        <Message.Item key={community}>{community}</Message.Item>
                      ))}
                    </Message.List>
                  </PaddedBox>
                </Accordion.Content>
              </>
            ))}
        </Accordion>
      </Message>
      <Table fixed singleLine>
        <StickyTableHeader>
          <Table.Row>
            <Table.HeaderCell width={6}>Name</Table.HeaderCell>
            <Table.HeaderCell width={8}>URL</Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="right">
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </StickyTableHeader>

        <Table.Body>
          {locations &&
            locations.map((location, i) => (
              <Row
                key={i}
                location={location}
                locations={locations}
                locationTypes={locationTypes}
                index={i}
                onUpdate={updateLocation}
                onDelete={deleteLocation}
              />
            ))}
        </Table.Body>

        <Table.Footer fullWidth>
          <LocationForm
            locations={locations}
            location={{ name: '', url: '', type: LOCATION_TYPES.ASSISTED_LIVING }}
            locationTypes={locationTypes}
            onSubmit={addLocation}
            isTableFooter
          />
        </Table.Footer>
      </Table>
      <Modal size="small" open={locationsModalActive}>
        <Modal.Header>CSV Import</Modal.Header>
        <Modal.Content>
          <div className="container" style={{ textAlign: 'center' }}>
            <CSVReader
              onFileLoad={handleFileLoaded}
              defaultProps={parseOptions}
              inputName="input"
              addRemoveButton
              inputStyle={{ textAlign: 'center' }}
              config={parseOptions}
              onRemoveFile={() => setTempLocations(null)}
              noProgressBar={true}
            >
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setLocationsModalActive(false)}>Cancel</Button>
          <Button primary onClick={() => handleUpload()} disabled={!tempLocations}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
