import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { getHours, isSameHour, setHours, isPast, isValid, isSameDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

import { createTour, confirmTour } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { ConversationContext } from 'components/conversation/ConversationContext';
import { Button, CalendarPicker, Icon, Modal, Picker, Loader } from 'components/lib';
import { If } from 'components/If';
import { formatAnyDate } from 'components/utils';
import { CONVERSATION_ACTIONS, SCHEDULE_TYPES, TIME_SLOTS } from './constants';
import { CalendarSelector } from './CalendarSelector';
import { CALENDAR_REQUEST_SOURCE_TYPE } from '../constants';
import {
  ConversationModal,
  ConversationModalFooter,
  ConversationModalHeader,
  ConversationModalSubtitle,
} from './ConversationModal';
import { MEDIA_BREAK_POINTS, STATUSES } from '../../../constants';
import { fetchDayTimeSlots } from './utils';

const ModalHeader = styled(ConversationModalHeader)`
  padding: 30px;
  h4 {
    margin: 0;
    margin-top: 3px;
  }
  span {
    margin-right: 10px;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  border-right: solid 1px var(--line-standard);
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: stretch;
  border-top: 1px solid var(--line-standard);
  & > div {
    flex: 1 0 50%;
  }

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: column;
    ${Left} {
      border-right: none;
    }
  }
`;

const RightActions = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: end;
`;

const Right = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledLoader = styled(Loader)`
  &&&& {
    color: var(--light-text);
    :after {
      border-color: #767676 transparent transparent;
    }
  }
`;

export const ScheduleTourCallModal = () => {
  const { t } = useTranslation();
  const { fetchConversationDetails, selectedConversation, eventType, selectedCalendar, activeModal, showModal } =
    useContext(ConversationContext);

  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDayTime, setSelectedDayTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSameDate, setIsSameDate] = useState(false);
  const [isPastDate, setIsPastDate] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [timeSlotsStatus, setTimeSlotsStatus] = useState(STATUSES.IDLE);
  const [activeEvent, setActiveEvent] = useState(null);

  const communityTimezone = selectedConversation?.community?.timezone;

  useEffect(() => {
    const event = selectedConversation && selectedConversation[eventType];
    const presentEvent = activeModal && event && !event.cancelled;
    const eventDateTimezoned = event && isValid(event.start) ? utcToZonedTime(event?.start, communityTimezone) : null;

    setActiveEvent(presentEvent ? event : null);
    setSelectedDay(presentEvent ? eventDateTimezoned : null);
    setSelectedTime(presentEvent ? getHours(eventDateTimezoned) : null);
    setTimeSlots([]);
  }, [activeModal, eventType]);

  useEffect(() => {
    const selectedDayDate = new Date(selectedDay);
    const selectedDayTimeValue = setHours(selectedDayDate, selectedTime);
    const isSelectedDatePastDue = isPast(selectedDay) && !isSameDay(selectedDay, new Date());

    setSelectedDayTime(selectedDayTimeValue);
    setIsPastDate(isSelectedDatePastDue);

    if (activeEvent) {
      const event = selectedConversation && selectedConversation[eventType];
      const activeEventDate = new Date(event.start);
      const shouldDisable =
        isSameDay(selectedDayTimeValue, activeEventDate) && isSameHour(selectedDayTimeValue, activeEventDate);
      setIsSameDate(shouldDisable);
    }
  }, [selectedDay, selectedTime]);

  useEffect(() => {
    const getTimeSlots = async () => {
      try {
        setTimeSlotsStatus(STATUSES.LOADING);
        setIsLoading(true);
        const community = selectedConversation?.community;
        const slots = await fetchDayTimeSlots(selectedDay, community);
        setTimeSlots(slots);
      } catch (e) {
        toast.warning('Trouble fetching calendar time slots. Loading predefined slots.');
        setTimeSlots(TIME_SLOTS);
      } finally {
        setTimeSlotsStatus(STATUSES.LOADED);
        setIsLoading(false);
      }
    };

    if (selectedDay && [CONVERSATION_ACTIONS.SCHEDULE, CONVERSATION_ACTIONS.RESCHEDULE].includes(activeModal)) {
      getTimeSlots();
    }
  }, [selectedDay, activeModal]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (activeEvent) {
        const updateData = {
          date: formatAnyDate(selectedDayTime, 'M/d/yyyy'),
          time: formatAnyDate(selectedDayTime, 'h:mm a'),
          calendar: selectedCalendar,
        };

        await confirmTour(activeEvent.hash, updateData);
      } else {
        const date = `date-${formatAnyDate(selectedDayTime, 'yyyy-MM-dd')}`;
        const hour = parseInt(formatAnyDate(selectedDayTime, 'h'));
        const am_pm = formatAnyDate(selectedDayTime, 'a');

        const data = {
          date,
          hour,
          minute: '00',
          am_pm,
          request_type: eventType,
          calendar: selectedCalendar,
          source: CALENDAR_REQUEST_SOURCE_TYPE.MESSAGING_ASSISTANT,
          confirmed: true,
        };

        await createTour(selectedConversation.id, data);
      }

      showModal(null);
      setIsLoading(false);
      fetchConversationDetails(selectedConversation.id);
      toast.success(`${actionType.label} ${activeEvent ? 'rescheduled' : 'scheduled'} successfully!`);
    } catch (error) {
      console.error('error', error);
      toast.error(
        parseErrorResponse(error, `${actionType.label} was not ${activeEvent ? 'rescheduled' : 'scheduled'}`)
      );
      setIsLoading(false);
    }
  };

  const actionType = SCHEDULE_TYPES(t)[eventType];
  const showScheduleModal = [CONVERSATION_ACTIONS.SCHEDULE, CONVERSATION_ACTIONS.RESCHEDULE].includes(activeModal);
  const slotsLoading = timeSlotsStatus === STATUSES.LOADING;
  const disabledConfirmButton = !selectedDay || !selectedTime || isSameDate || isPastDate || slotsLoading;

  return (
    <ConversationModal closeIcon onClose={() => showModal(null)} open={showScheduleModal} size="small">
      <ModalHeader>
        <Icon name={actionType.icon} variant="outlined" size="22" color={actionType.color} />
        <h4>{activeEvent ? actionType.modalHeaderReschedule : actionType.modalHeader}</h4>
        <ConversationModalSubtitle>
          {activeEvent ? actionType.subtitleReschedule : actionType.subtitle}
        </ConversationModalSubtitle>
        <CalendarSelector />
      </ModalHeader>
      <Modal.Content>
        <ModalContent>
          <Left>
            <CalendarPicker
              color={actionType.color}
              fadedColor={actionType.fadedColor}
              onChange={(date) => setSelectedDay(date)}
              disabled={isLoading}
              selectedDate={selectedDay}
            />
          </Left>
          <Right>
            <If condition={timeSlotsStatus === STATUSES.LOADING}>
              <StyledLoader active inline="centered" />
            </If>
            <If condition={timeSlotsStatus === STATUSES.LOADED}>
              <Picker options={timeSlots} type={actionType} onChange={setSelectedTime} initial={[selectedTime]} />
            </If>
          </Right>
        </ModalContent>
      </Modal.Content>
      <ConversationModalFooter>
        <RightActions>
          <Button
            primary
            color={actionType.class}
            disabled={disabledConfirmButton}
            onClick={handleSave}
            loading={isLoading}
          >
            Confirm
          </Button>
          <Button basic onClick={() => showModal(null)} disabled={isLoading}>
            Cancel
          </Button>
        </RightActions>
      </ConversationModalFooter>
    </ConversationModal>
  );
};
