import React from 'react';
import { Table, Button, Dropdown } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { InputField } from '../common/fields/InputField';

const stripUrl = (url) => {
  if (!url) {
    return url;
  }
  return url.replace('http://', '').replace('https://', '').replace('www.', '').replace(/\/+$/, '');
};

export const LocationForm = ({ location, onSubmit, onCancel, isTableFooter, locations }) => {
  const initialValues = location;
  const Cell = isTableFooter ? Table.HeaderCell : Table.Cell;
  const validationSchema = Yup.object({
    name: Yup.string().max(300, `Can't be longer than 300 characters`).required('Required'),
    url: Yup.string()
      .url('Must be a valid URL')
      .max(300, `Can't be longer than 300 characters`)
      .required('Required')
      .test('is-url-unique', 'URL must be unique', (value) => {
        if (!value || !locations || !Array.isArray(locations)) return true;
        return !locations.some((item) => stripUrl(item.url) === stripUrl(value));
      }),
  });

  const onEnter = (e, formik) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const submit = (values, helpers) => {
    helpers.resetForm();
    if (typeof onSubmit === 'function') {
      onSubmit(values);
    }
  };

  const cancel = (formik) => {
    formik.resetForm();
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      {(formik) => (
        <Table.Row textAlign="right" className="ui form">
          <Cell>
            <InputField name="name" placeholder="Name" onKeyUp={(e) => onEnter(e, formik)} />
          </Cell>
          <Cell>
            <InputField name="url" placeholder="URL" onKeyUp={(e) => onEnter(e, formik)} />
          </Cell>
          <Cell>
            <Button.Group>
              <Button
                type="submit"
                icon="check"
                onClick={() => {
                  formik.handleSubmit();
                }}
              />
              <Button
                icon="delete"
                onClick={() => {
                  cancel(formik);
                }}
              />
            </Button.Group>
          </Cell>
        </Table.Row>
      )}
    </Formik>
  );
};
