import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Divider, Menu, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { useCheckMobileScreen } from 'utils/hooks';
import { Icon, Loader, Toggle } from 'components/lib';
import { If } from 'components/If';
import { useUserContext } from './UserContext';
import { UserCircle } from './UserCircle';
import { logOut } from '../api';
import { MEDIA_BREAK_POINTS } from '../constants';
import ContactCenterSession from './contact-center/ContactCenterSession';

export const WITH_TOP_BAR_ID = 'WithTopBar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'var(--white)')};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    min-height: 100%;
  }
`;

const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 30px;

  // reset Beamer style.
  .beamer_icon.active {
    width: 5px;
    height: 5px;
    animation: none;
    top: 0 !important;
    right: 0 !important;
    background-color: #fa7550;
    font-size: 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  height: 100%;

  &.contained {
    overflow: hidden;
    @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      height: calc(100vh - 70px);
    }
  }
`;

const FirstName = styled.span`
  margin-right: 15px;
  margin-left: 30px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: right;
  color: ${(props) => (props.isLightMode ? 'var(--white)' : '#222')};
`;

const MenuItemWithIcon = styled(Menu.Item)`
  // Need to override semantic-ui-react's default style.
  &&&.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const UserInfo = styled(Menu.Header)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${FirstName} {
    margin-top: 15px;
  }

  span {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
`;

export const WithTopBar = ({ variant = 'dark', children, contentClasses, bgColor }) => {
  const { isImpersonatedSession, getSessionOriginalUser, setUser } = useUserContext();
  const user = getSessionOriginalUser();
  const isLightMode = variant === 'light';

  const history = useHistory();
  const queryClient = useQueryClient();
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useCheckMobileScreen();
  const handleMenuClick = () => setMenuOpen(!menuOpen);
  const handleLogout = async () => {
    setMenuOpen(false);
    try {
      await logOut();
    } catch (e) {
      console.error(e);
    } finally {
      queryClient.clear();
      setUser(null);
      history.push('/login');
    }
  };

  const handleProfileClick = () => {
    setMenuOpen(false);
    history.push('/my-profile');
  };

  return (
    <Container id={WITH_TOP_BAR_ID} bgColor={bgColor}>
      <If condition={user && !isMobile}>
        <TopBarContainer>
          <ContactCenterSession />

          <Icon
            name="notifications_none"
            color={isLightMode ? 'var(--white)' : '#444444'}
            className="beamerButton"
            size="22"
          />

          <FirstName isLightMode={isLightMode} title={user?.email}>
            {user?.first_name || user?.email}
          </FirstName>

          <Popup
            position="bottom right"
            offset={[5, 0]}
            on={['click', 'focus']}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            trigger={<UserCircle onClick={handleMenuClick} user={user} />}
          >
            <Menu secondary vertical>
              <UserInfo>
                <UserCircle size="60" user={user} />
                <FirstName>
                  {user?.first_name} {user?.last_name}
                </FirstName>
                <span title={user?.email}>{user?.email}</span>
              </UserInfo>
              <Divider />
              <MenuItemWithIcon onClick={handleProfileClick}>
                Profile
                <Icon name="account_box" color="black" />
              </MenuItemWithIcon>
              <MenuItemWithIcon onClick={handleLogout}>
                Logout
                <Icon name="logout" color="black" />
              </MenuItemWithIcon>
            </Menu>
          </Popup>
        </TopBarContainer>
      </If>

      <ContentContainer className={contentClasses}>{children}</ContentContainer>
    </Container>
  );
};
