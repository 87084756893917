import React from 'react';
import styled from 'styled-components';

import SearchInput from 'components/SearchInput';

const StyledSearchInput = styled(SearchInput)`
  &&&& {
    width: ${(props) => `${props.width}px`};
    margin-left: 10px;
    > input {
      background-color: #f8f8f8;
    }
  }
`;

export const SettingsSearch = ({ value, onSearch, loading, placeholder, width = 400 }) => {
  return (
    <StyledSearchInput
      value={value || ''}
      onSearch={onSearch}
      loading={loading}
      placeholder={placeholder}
      width={width}
    />
  );
};
