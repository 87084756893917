import React, { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';

import { UNIT_LEVEL_PRICING_FIRST_STEP } from './constants';
import reducer from './reducer';

const INITIAL_STATE = {
  invitation: undefined,
  token: undefined,
  isTokenInvalid: false,
  activeStep: UNIT_LEVEL_PRICING_FIRST_STEP,
  careTypes: [],
  unitTypes: [],
};

export const UnitLevelPricingContext = createContext();

const UnitLevelPricingContextProvider = (props) => {
  const [state, dispatch] = useImmerReducer(reducer, INITIAL_STATE);

  const value = [state, dispatch];

  return <UnitLevelPricingContext.Provider value={value}>{props.children}</UnitLevelPricingContext.Provider>;
};

export function useUnitLevelPricing() {
  const context = useContext(UnitLevelPricingContext);

  if (!context) {
    throw new Error(`useUnitLevelPricing must be used within UnitLevelPricingContextProvider`);
  }

  return context;
}

export default UnitLevelPricingContextProvider;
