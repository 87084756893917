import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { Dimmer, Loader, Message } from 'semantic-ui-react';

import { NavBar } from 'components/navigation';
import { If } from 'components/If';
import { getUnitLevelPriceInvite } from 'apis';
import { STATUSES } from '../../constants';
import { useUnitLevelPricing, setInvitation, setLoaderStatus, setTokenInvalid } from './context';
import UnitLevelPricingContent from './UnitLevelPricingContent';

const Container = styled.div`
  max-width: 105rem;
  min-height: 70vh;
  margin: 3rem auto 5rem;
`;

const UnitLevelPricing = () => {
  const { token } = useParams();
  const [{ loaderStatus, isTokenInvalid }, dispatch] = useUnitLevelPricing();

  useEffect(() => {
    const fetch = async (token) => {
      try {
        dispatch(setLoaderStatus(STATUSES.LOADING));
        const invitation = await getUnitLevelPriceInvite(token);

        dispatch(setInvitation({ invitation, token }));
        dispatch(setLoaderStatus(STATUSES.LOADED));
      } catch (e) {
        dispatch(setLoaderStatus(STATUSES.FAILURE));
        dispatch(setTokenInvalid());
      }
    };

    if (token) {
      fetch(token);
    }
  }, [dispatch, token]);

  return (
    <div>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <NavBar />

      <Container>
        <UnitLevelPricingContent />

        <If condition={isTokenInvalid}>
          <Message info icon="search" header="Invitation not found" content="Invitation token invalid or expired!" />
        </If>
      </Container>
    </div>
  );
};

export default UnitLevelPricing;
