import React from 'react';
import styled from 'styled-components';

import { Icon, FURHTER_ICON } from 'components/lib';

const Container = styled.div`
  height: 24px;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: ${({ $color }) => `${$color}1A`};
  font-family: var(--font-family);
  span:not(.${FURHTER_ICON}) {
    color: ${({ $color }) => $color};
    font-size: 12px;
    margin-left: 8px;
    line-height: 12px;
  }
`;

export const GroupTag = ({ group }) => {
  return (
    <Container $color={group?.color}>
      <Icon name="local_offer" color={group?.color} variant="outlined" size="16" />
      <span>{group?.name}</span>
    </Container>
  );
};
