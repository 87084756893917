import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, Dimmer, Loader, Dropdown, Grid, Form } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { If } from 'components/If';
import { InputField } from 'components/common';
import { createOrUpdateUserEmailSubject, deleteUserEmailSubject } from 'apis';
import DeleteUserSubjectOverrideModal from './DeleteUserSubjectOverrideModal';
import DotsActionButton from './DotsActionButton';
import SelectPlaceholderSection from './SelectPlaceholderSection';
import SubjectPreviewSection from './SubjectPreviewSection';
import { PageHeader, PAGE_HEADER_MARGIN } from '../../common';
import { useEmailSubjects } from './EmailSubjectsContext';
import { STATUSES } from '../../../constants';
import { getDefaultEmailSubjectBySelectedType } from './utils';
import { DEFAULT_EMAIL_STATUS } from './constants';

const VALIDATION_SCHEMA = Yup.object({
  text: Yup.string().required('Email subject text is required.'),
  type: Yup.string().required('Email Type is required'),
});

const EditGlobalEmailSubjectOverview = () => {
  const history = useHistory();
  const location = useLocation();
  const { userId } = useParams();

  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [openRestoreToDefaultsModal, setRestoreToDefaultsModal] = useState(false);
  const [selectedEmailSubject, setSelectedEmailSubject] = useState(null);
  const [enteredSubject, setEnteredSubject] = useState('');
  const { defaultEmailSubjects, emailTypes, placeholders } = useEmailSubjects();

  const placeholdersByType = placeholders.filter((placeholder) => placeholder.type === selectedEmailSubject?.type);
  const emailTypeOptions = Object.entries(emailTypes).map(([key, value]) => ({ key, value: key, text: value }));

  const initialFormValues = {
    type: selectedEmailSubject?.type,
    text: enteredSubject,
    communities: 'Any Community',
  };

  useEffect(() => {
    if (!location.state) {
      history.replace('/admin/custom-email-subjects');
      return;
    }

    setSelectedEmailSubject(location.state.selectedEmailSubject);
  }, [location, history]);

  useEffect(() => {
    const selectedSubject = selectedEmailSubject ? selectedEmailSubject.text : '';
    setEnteredSubject(selectedSubject);
  }, [selectedEmailSubject]);

  const onPlaceholderSelect = (value) => {
    setEnteredSubject(`${enteredSubject} ${value}`);
  };

  const onActionCancel = () => {
    if (!history) return;
    history.goBack();
  };

  const onFormSubmit = async (values) => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      await createOrUpdateUserEmailSubject(userId, {
        text: enteredSubject,
        type: values.type,
      });

      setLoaderStatus(STATUSES.LOADED);
      toast.success('Email subject successfully updated!');
      history.replace(`/admin/custom-email-subjects/${userId}/edit`);
    } catch (error) {
      toast.error('Trouble with updating email subject!');
      console.error(error.message);
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const onConfirm = async () => {
    if (!selectedEmailSubject) return;

    try {
      setLoaderStatus(STATUSES.LOADING);

      await deleteUserEmailSubject(selectedEmailSubject.id);

      setLoaderStatus(STATUSES.LOADED);
      toast.success('Email subject successfully deleted!');
      history.replace(`/admin/custom-email-subjects/${userId}/edit`);
    } catch (error) {
      toast.error('Trouble with deleting email subject!');
      console.error(error.message);
      setLoaderStatus(STATUSES.FAILURE);
    }

    setRestoreToDefaultsModal(false);
  };

  const onCloseModal = () => {
    setRestoreToDefaultsModal(false);
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted />
      </Dimmer>
      <Formik
        initialValues={initialFormValues}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={onFormSubmit}
        enableReinitialize
      >
        {({ handleSubmit, isValid }) => (
          <Form>
            <PageHeader
              title="Edit Global Email Subject"
              margin={PAGE_HEADER_MARGIN.MEDIUM}
              actions={
                <>
                  <Button type="submit" onClick={handleSubmit} disabled={!isValid}>
                    Save
                  </Button>{' '}
                  <Button onClick={onActionCancel}>Cancel</Button>
                  <If condition={selectedEmailSubject?.status !== DEFAULT_EMAIL_STATUS.SYSTEM_DEFAULT}>
                    <DotsActionButton onClick={setRestoreToDefaultsModal} title="Reset To System Default" />
                  </If>
                </>
              }
            />
            <Grid>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Form.Field>
                    <InputField
                      label="Email Subject"
                      name="text"
                      placeholder="Email Subject Override"
                      onChanged={setEnteredSubject}
                      value={enteredSubject}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Form.Field>
                    <InputField
                      control={Dropdown}
                      label="Type"
                      name="type"
                      placeholder="Email Type"
                      search
                      selection
                      disabled={true}
                      options={emailTypeOptions || []}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <If condition={placeholdersByType?.length > 0}>
                  <Grid.Column>
                    <SelectPlaceholderSection placeholders={placeholdersByType} onLabelClick={onPlaceholderSelect} />
                  </Grid.Column>
                </If>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <SubjectPreviewSection value={enteredSubject} allowedPlaceholders={placeholdersByType} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <InputField label="Communities" placeholder="Any Community" disabled name="communities" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
      <DeleteUserSubjectOverrideModal
        open={openRestoreToDefaultsModal}
        loading={loaderStatus === STATUSES.LOADING}
        onClose={onCloseModal}
        onConfirm={onConfirm}
        emailType={emailTypes[selectedEmailSubject?.type]}
        defaultEmailSubjectText={getDefaultEmailSubjectBySelectedType(selectedEmailSubject, defaultEmailSubjects)}
      />
    </>
  );
};

export default EditGlobalEmailSubjectOverview;
