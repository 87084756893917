import React, { useContext } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'components/lib';
import { MARKET_TYPE_LABEL } from '../../constants';

export const StyledMDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
    border: solid 1px var(--line-standard);
    border-radius: 0px;
    background-color: white;
    min-width: 17rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
    padding: 11px 21px;
    margin-right: 3px;
    min-height: 36px;
    height: 36px;

    .icon {
      right: 18px;
    }

    .default {
      color: var(--text-color);
    }
  }
`;

const UserManagementDropdownMarket = ({ value, onChange }) => {
  const marketTypeOptions = Object.entries(MARKET_TYPE_LABEL).map(([value, text]) => ({
    key: value,
    value,
    text,
  }));

  marketTypeOptions.unshift({ key: 'any', value: '', text: 'Any market' });

  const onMarketTypeChange = (e, { value }) => {
    onChange(value);
  };

  return (
    <StyledMDropdown
      name="Market Type"
      placeholder="Any market"
      selection
      value={value}
      options={marketTypeOptions}
      onChange={onMarketTypeChange}
    />
  );
};

export default UserManagementDropdownMarket;
