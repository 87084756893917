import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/lib';
import { PRODUCTS } from './constants';
import { Product } from '.';

const Container = styled.div`
  position: relative;
  margin-top: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: var(--white);
`;

const SpecialBadge = styled.div`
  display: flex;
  align-items: center;
  width: 38px;
  height: 24px;
  padding: 3px 10px;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fad650;
  position: absolute;
  top: -20px;
  /* half the width of the container minus half the width of the element 180/2 - 38/2 */
  right: 71px;
  z-index: 1;
`;

const BENEFIT_MAPPING = {
  TOUR: 'Scheduled Tours',
  MOVE_IN: 'Added Move-ins',
  CALL: 'Answered Calls',
};

const getProduct = (productDef, productData = {}, communitiesCount = 0) => {
  const data = productData[productDef.id];
  return {
    ...productDef,
    active: data?.monthsActive > 0,
    benefitValue: data?.value,
    benefit: BENEFIT_MAPPING[data?.valueType],
    isAIpowered: data?.isEstimated,
    numberOfCommunitiesThatDontUseFeature: communitiesCount - data?.communitiesActive,
    isActivatedRecently: data?.monthsActive > 0 && data?.monthsActive < 6,
  };
};

export const ProductTable = ({ productData, communitiesCount }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SpecialBadge>
        <Icon name="auto_awesome" variant="outlined" color="#fff" size={18} />
      </SpecialBadge>
      {PRODUCTS(t).map((product, i) => (
        <Product key={i} product={getProduct(product, productData, communitiesCount)} />
      ))}
    </Container>
  );
};
