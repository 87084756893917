import React, { createContext, useEffect, useState } from 'react';

import { getCommunitiesGroups, deleteCommunitiesGroup } from 'apis';

import { STATUSES } from '../../../constants';

export const CommunitiesGroupsContext = createContext();

export const CommunitiesGroupsContextProvider = (props) => {
  const [groups, setGroups] = useState([]);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(STATUSES.IDLE);
  const [editingGroup, setEditingGroup] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const handelModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setEditingGroup(null);
    setModalOpen(false);
  };

  const handleConfirmDeleteOpen = () => {
    setConfirmDeleteOpen(true);
    setModalOpen(false);
  };

  const handleConfirmDeleteClose = () => setConfirmDeleteOpen(false);

  const getGroups = async () => {
    search ? setSearchLoading(STATUSES.LOADING) : setLoaderStatus(STATUSES.LOADING);
    try {
      const response = await getCommunitiesGroups({ search });
      setGroups(response.results);
      search ? setSearchLoading(STATUSES.LOADED) : setLoaderStatus(STATUSES.LOADED);
    } catch (error) {
      search ? setSearchLoading(STATUSES.FAILURE) : setLoaderStatus(STATUSES.FAILURE);
      console.error(error);
    }
  };

  const handleGroupDelete = async () => {
    setLoaderStatus(STATUSES.LOADING);
    try {
      await deleteCommunitiesGroup(editingGroup);
      const groupDeleteindex = groups.findIndex((group) => group.id === editingGroup);
      if (groupDeleteindex > -1) {
        setGroups(groups.filter((group) => group.id !== editingGroup));
      }
      setEditingGroup(null);
      handleConfirmDeleteClose();
      getGroups();
      setLoaderStatus(STATUSES.LOADED);
    } catch (error) {
      setLoaderStatus(STATUSES.FAILURE);
      console.error('error', error);
    }
  };

  useEffect(() => {
    getGroups();
  }, [search]);

  const provide = {
    confirmDeleteOpen,
    editingGroup,
    getGroups,
    groups,
    handelModalOpen,
    handleConfirmDeleteClose,
    handleConfirmDeleteOpen,
    handleGroupDelete,
    handleModalClose,
    loaderStatus,
    modalOpen,
    search,
    setEditingGroup,
    setGroups,
    setLoaderStatus,
    setSearch,
    searchLoading,
  };

  return <CommunitiesGroupsContext.Provider value={provide} {...props} />;
};
