import React, { createContext, useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { subDays } from 'date-fns';

import { getOwnersProfiles } from 'apis';
import { formatAnyDate } from 'components/utils';
import { useNoRenderRef } from 'utils/hooks/useNoRenderRef';
import { STATUSES } from '../../constants';

const OwnersDashboardContext = createContext();
const today = new Date();
export const last30Days = formatAnyDate(subDays(today, 30), 'yyyy-MM-dd');

export const OwnersDashboardContextProvider = ({ children }) => {
  const { managementCompanyId, communityId } = useParams();

  const [communities, setCommunities] = useState([]);
  const [communitiesLoader, setCommunitiesLoader] = useState(STATUSES.IDLE);
  const [filters, setFilters] = useState({
    start_date: last30Days,
    end_date: null,
    communities: communityId ? [Number(communityId)] : null,
    group_by: 'company',
  });
  const filtersRef = useNoRenderRef(filters);

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        setCommunitiesLoader(STATUSES.LOADING);
        const response = await getOwnersProfiles();
        setCommunities(response.results);
        setCommunitiesLoader(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        setCommunitiesLoader(STATUSES.FAILURE);
      }
    };

    fetchCommunities();
  }, []);

  useEffect(() => {
    let communitiesFilter = [];
    if (communityId) {
      communitiesFilter = [Number(communityId)];
    } else if (managementCompanyId) {
      communitiesFilter = communities
        .filter((p) => p.management_company.id === Number(managementCompanyId))
        .map((p) => p.id);
    } else {
      communitiesFilter = null;
    }
    setFilters({
      ...filtersRef.current,
      communities: communitiesFilter,
    });
  }, [communities, managementCompanyId, communityId, filtersRef]);

  return (
    <OwnersDashboardContext.Provider
      value={{
        communities,
        communitiesLoader,
        filters,
        setFilters,
        managementCompanyId: Number(managementCompanyId),
        communityId: Number(communityId),
      }}
    >
      {children}
    </OwnersDashboardContext.Provider>
  );
};

export const useOwnersDashboardContext = () => {
  const context = useContext(OwnersDashboardContext);
  if (typeof context === 'undefined') {
    throw new Error('useOwnersDashboardContext() must be used within OwnersDashboardContextProvider');
  }
  return context;
};
