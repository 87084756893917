import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Form } from 'semantic-ui-react';

import { saveAttributeAnswers } from 'apis';
import { STATUSES } from '../../../constants';
import { InstantAnswersHeader } from './InstantAnswersHeader';
import { InstantAnswersContext } from './InstantAnswersContext';
import { formatValues, getInitialValues, validateForm } from './utils';
import { InstantAnswersAttributes } from './InstantAnswersAttributes';
import { InstantAnswersUnsavedChangesConfirm } from './InstantAnswersUnsavedChangesConfirm';
import { InstantAnswersDeleteConfirm } from './InstantAnswersDeleteConfirm';
import { InstantAnswersImportDialog } from './InstantAnswersImportDialog';

export const InstantAnswersForm = () => {
  const { community_id } = useParams();
  const { communityAttributes, setLoaderStatus, activeMenuItem, initialValues, activeValue, setCommunityAttributes } =
    useContext(InstantAnswersContext);

  const submit = (val, resetForm) => {
    const newFormValue = activeValue.map((av) => ({
      id: av.id,
      type: av.type,
      values: formatValues(av, val),
    }));

    resetForm({ values: getInitialValues(newFormValue) });

    const newData = newFormValue.map((item, index) => ({
      ...item,
      index,
    }));

    onSave(newData);
  };

  const onSave = async (data) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      await saveAttributeAnswers(community_id, activeMenuItem, { data });
      const activeMenuItemIndex = communityAttributes.findIndex((mi) => mi.id === activeMenuItem);
      const tempArray = [...communityAttributes];

      tempArray[activeMenuItemIndex] = {
        ...tempArray[activeMenuItemIndex],
        count: activeValue.length,
      };

      setCommunityAttributes(tempArray);
      toast.success('Answers saved successfully');
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error('Error saving answers. Please try again later, or contact us for help.');
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <Formik
      onSubmit={(val, { resetForm }) => submit(val, resetForm)}
      validate={(values) => validateForm(values, activeValue)}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <InstantAnswersHeader />
          <InstantAnswersAttributes />
          <InstantAnswersUnsavedChangesConfirm />
          <InstantAnswersDeleteConfirm />
          <InstantAnswersImportDialog />
        </Form>
      )}
    </Formik>
  );
};
