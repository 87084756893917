import React, { useContext, useState, useEffect } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DateRangePicker, MultiSelectFilter } from 'components/common';
import { Checkbox, Icon } from 'components/lib';
import { useUrlSearchParams } from 'utils/hooks';
import { capitalize } from 'utils/helpers';
import { ANALYTICS_CHANNELS } from './constants';
import { setFilters } from './context/actions';
import { useAnalytics } from './context/AnalyticsContext';
import { UserContext } from '../UserContext';

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
`;

const MainFilters = styled.div`
  flex: 2;
  margin-bottom: 20px;
  display: flex;
  & > div:first-child {
    flex: 1;
  }

  .move-in-date-toggle,
  .tour-date-toggle {
    margin: 0 1rem;
  }
`;

const Subfilters = styled.div`
  display: flex;
  gap: 1rem;
  flex-flow: nowrap;

  .ui.button.reset-filters {
    height: 2.53rem;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.92857143rem;
    background-color: #f9f9f9;
    border: 1px solid rgba(34, 36, 38, 0.05);

    &:hover {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  @media (max-width: 1455px) {
    flex-direction: column;
  }
`;

const GroupIcon = (props) => <Icon name="local_offer" variant="outlined" {...props} />;
const GROUPS = 'groups';

const CHANNEL_OPTIONS = Object.keys(ANALYTICS_CHANNELS).map((id) => ({ id, name: ANALYTICS_CHANNELS[id] }));

const AnalyticsFilters = ({ disabled = false, disabledFilters = [] }) => {
  const { t } = useTranslation();
  const [{ report, filters, trafficSources }, dispatch] = useAnalytics();
  const { user } = useContext(UserContext);
  const { setUrlSearchParams } = useUrlSearchParams();
  const [selectedType, setSelectedType] = useState('communities');
  const [selected, setSelected] = useState(null);

  const communityAndGroupFilterOptions = {
    communities: report.communities,
    groups: report.groups?.map((g) => ({ ...g, Icon: GroupIcon.bind(null, { color: g.color }) })),
  };

  useEffect(() => {
    if (filters.communities?.length > 0) {
      setSelectedType('communities');
    }
    if (filters.groups?.length > 0) {
      setSelectedType('groups');
    }
  }, [filters.communities, filters.groups]);

  useEffect(() => {
    const filterByType = filters[selectedType];
    setSelected(filterByType?.length > 0 ? filterByType : null);
  }, [selectedType, filters.communities, filters.groups]);

  const areFiltersApplied = !!filters.start_date;
  const dateRange = {
    start_date: areFiltersApplied ? filters.start_date : report.start_date,
    end_date: areFiltersApplied ? filters.end_date : report.end_date,
  };

  const onFiltersChange = (change) => {
    setUrlSearchParams(change);
    dispatch(setFilters(change));
  };

  const handleDateRangeChange = ({ startDate, endDate }) => {
    onFiltersChange({ start_date: startDate, end_date: endDate });
  };

  const handleSelectedCommunitiesChange = (selected, st) => {
    setSelectedType(st);
    // groups is a subset of communities, so if all groups are selected, it does not mean all communities are selected
    const allSelected = st !== GROUPS && selected?.length === communityAndGroupFilterOptions[st]?.length;
    const resetType = st !== selectedType && Object.keys(communityAndGroupFilterOptions).filter((k) => k !== st)[0];

    onFiltersChange({
      [st]: allSelected ? null : selected || null,
      [resetType]: null,
    });
  };

  const handleSourcesChange = (sources) => {
    onFiltersChange({ traffic_sources: sources });
  };

  const handleChannelsChange = (channels) => {
    onFiltersChange({ channels });
  };

  const handleToggleUseMoveInDate = () => {
    onFiltersChange({ use_movein_date: !filters?.use_movein_date });
  };

  const handleToggleUseTourDate = () => {
    onFiltersChange({ use_tour_date: !filters?.use_tour_date });
  };

  const resetAllFilters = () => {
    onFiltersChange({
      start_date: null,
      end_date: null,
      communities: null,
      groups: null,
      traffic_sources: null,
      channels: null,
      care_types: null, // Temporarily not used #2640
      use_movein_date: true,
      use_tour_date: true,
    });
  };

  return (
    <FiltersContainer>
      <MainFilters>
        <DateRangePicker dateRange={dateRange} onFilterChange={handleDateRangeChange} disabled={disabled} />
        <div className="tour-date-toggle">
          <Popup
            content={
              <>
                Use this switch to toggle between displaying {t('tour')} numbers based on {t('tour')} created date or{' '}
                {t('lead')} created date. By default, reporting is based on {t('tour')} created date, as it provides a
                more accurate representation of {t('tours')}. However, if you would like to view reporting based on{' '}
                {t('lead')} created date, you can untoggle the switch. If you have any questions or concerns about this
                feature or if the {t('move_in')} data appears to be incorrect, please contact
                <a href="mailto:support@talkfurther.com"> support@talkfurther.com</a>.
              </>
            }
            trigger={
              <Checkbox
                slider
                checked={filters?.use_tour_date}
                onChange={handleToggleUseTourDate}
                label={`${capitalize(t('tour'))} Date Reporting`}
                disabled={disabled}
              />
            }
          />
        </div>
        <div className="move-in-date-toggle">
          <Popup
            content={
              <>
                Use this switch to toggle between displaying reporting based on {t('move_in')} date or {t('lead')}{' '}
                created date. By default, reporting is based on {t('move_in')} date, as it provides a more accurate
                representation of {t('move_ins')}. However, if you would like to view reporting based on {t('lead')}{' '}
                created date, you can untoggle the switch. If you have any questions or concerns about this feature or
                if the {t('move_in')} data appears to be incorrect, please contact
                <a href="mailto:support@talkfurther.com"> support@talkfurther.com</a>.
              </>
            }
            trigger={
              <Checkbox
                slider
                checked={filters?.use_movein_date}
                onChange={handleToggleUseMoveInDate}
                label={`${capitalize(t('move_in'), true)} Date Reporting`}
                disabled={disabled}
              />
            }
          />
        </div>
      </MainFilters>

      <Subfilters>
        <MultiSelectFilter
          closeOnApply
          placeholder={`Any ${t('community')} or group`}
          options={communityAndGroupFilterOptions}
          selectedValues={selected}
          onChange={handleSelectedCommunitiesChange}
          selectedOptionTypeDefault={selectedType}
          hideEmptyOptionTypes
          name={capitalize(t('communities'))}
          disabled={disabled || disabledFilters.includes('communities')}
        />
        <Popup
          content="Please login to make changes."
          disabled={!!user}
          trigger={
            <div>
              <MultiSelectFilter
                name="Sources"
                placeholder="Filter Traffic Sources"
                options={trafficSources}
                selectedValues={filters && filters.traffic_sources}
                onChange={handleSourcesChange}
                disabled={disabled || trafficSources.length === 0 || disabledFilters.includes('traffic_sources')}
              />
            </div>
          }
        />
        <Popup
          content="Please login to make changes."
          disabled={!!user}
          trigger={
            <div>
              <MultiSelectFilter
                name="Channels"
                placeholder="Filter Channels"
                options={CHANNEL_OPTIONS}
                selectedValues={filters && filters.channels}
                onChange={handleChannelsChange}
                disabled={disabled || disabledFilters.includes('channels')}
              />
            </div>
          }
        />
        <Button icon="close" className="reset-filters" onClick={resetAllFilters} />
      </Subfilters>
    </FiltersContainer>
  );
};

export default AnalyticsFilters;
