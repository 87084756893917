import React from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';

import { useCRM } from './context';
import CRMLocationCodeOverrideRow from './CRMLocationCodeOverrideRow';

const CRMLocationCodeOverrideTable = ({ crmLocationCodeOverrides, onSetFieldValue, onSwap, onRemove, onPush }) => {
  const [{ crmLocationCodeRuleOptions, crmLocationCodeRules }] = useCRM();

  const handleCRMLocationRuleChange = (rowId) => () => {
    onSetFieldValue(`crmLocationCodeOverrides[${rowId}].operation`, '', false);
    onSetFieldValue(`crmLocationCodeOverrides[${rowId}].value`, '', false);
  };

  const handleAddRule = () => {
    onPush({ rule: undefined, operation: undefined, value: '', crmLocationCode: '' });
  };

  return (
    <Table basic="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Order</Table.HeaderCell>
          <Table.HeaderCell>Rule</Table.HeaderCell>
          <Table.HeaderCell>CRM Location Code</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {crmLocationCodeOverrides.map((crmLocationCodeRule, index) => (
          <CRMLocationCodeOverrideRow
            key={index}
            baseFieldName={`crmLocationCodeOverrides[${index}]`}
            rowId={index}
            rowsCount={crmLocationCodeOverrides.length}
            crmLocationCodeRules={crmLocationCodeRules}
            ruleId={crmLocationCodeRule.rule}
            crmLocationCodeRuleOptions={crmLocationCodeRuleOptions}
            onCRMLocationRuleChange={handleCRMLocationRuleChange(index)}
            onSwap={onSwap}
            onRemove={onRemove}
          />
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="4">
            <Button icon labelPosition="left" primary size="tiny" onClick={handleAddRule}>
              <Icon name="fork" /> Add rule
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default CRMLocationCodeOverrideTable;
