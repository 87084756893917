import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Input as SemanticInput } from 'semantic-ui-react';
import { FURHTER_ICON } from './Icon';

const StyledInput = styled(SemanticInput)`
  &&&& {
    height: 36px;
    border-radius: 0;
    align-items: center;

    input {
      height: 36px;
      font-family: var(--font-family);
      border-radius: 0;
      border: 1px solid var(--line-standard);
      background-color: var(--input-field-fill);

      &:focus {
        border-color: var(--link);
      }
    }
    &.white {
      input {
        background-color: var(--white);
      }
    }

    &.ui.action > .dropdown {
      padding: 8px 4px 7px 10px;
      border-radius: 0;
      box-shadow: none;
      > .icon {
        font-size: 18px;
      }
    }

    // Specific styling when using custom library Icon component
    &.icon {
      & > .${FURHTER_ICON} {
        pointer-events: none;
        position: absolute;
        right: 0;
        margin: 0px 10px;
      }

      &.left > .${FURHTER_ICON} {
        left: 0;
      }
    }
  }
`;

export const Input = forwardRef((props, ref) => <StyledInput ref={ref} {...props} />);
