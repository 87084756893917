import React from 'react';
import { Icon, Image } from 'semantic-ui-react';
import styled from 'styled-components';

const ChatImage = styled(Image)`
  &.ui.image {
    width: 100%;
    max-width: 200px;
    height: auto;
  }
`;

const ChatMessageAttachment = ({ url, onImgLoad }) => {
  if (!url || typeof url !== 'string') {
    return null;
  }

  const isPdf = url?.split('.').pop().toLowerCase() === 'pdf';

  if (isPdf) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Icon name="file pdf outline" size="huge" />
      </a>
    );
  }

  return <ChatImage onLoad={onImgLoad} src={url} bordered rounded />;
};

export default ChatMessageAttachment;
