import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { createAccount } from 'apis';
import { parseErrorResponse } from 'apis/utils';

import { toast } from 'react-toastify';
import { OnboardingContext } from '../OnboardingContext';
import { Container } from './common';
import { CreateAccountForm, CheckYourEmail, EmailVerificationFailed } from '.';

const initialFormValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
};

const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().required('Please enter a valid email address').email('Please enter a valid email address'),
  password: Yup.string().required('Please enter a password.'),
});

export const CreateAccount = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const errorverification = queryParams.get('errorverification') === 'true';

  const { onboarding, updateOnboardingData, setHidePreviousStep } = useContext(OnboardingContext);

  useEffect(() => {
    if (errorverification) {
      setHidePreviousStep(true);
    }
  }, []);

  const onFormSubmit = async (values, { setFieldError }) => {
    const shouldGoToNextStep = await handleAccountCreate(values, setFieldError);
    if (shouldGoToNextStep) {
      const { email, firstName, lastName } = values;
      updateOnboardingData({ email, firstName, lastName });
      setHidePreviousStep(true);
    }
  };

  const handleAccountCreate = async (values, setFieldError) => {
    const requestData = {
      ...values,
      onboardingUuid: onboarding.onboardingUuid,
    };
    try {
      const response = await createAccount(requestData);
      if (response) {
        return true;
      }
    } catch (error) {
      console.error('error', error.response.data);
      const data = error?.response?.data;
      if (data) {
        if (data.community_detail) {
          toast.error(data.community_detail);
        }
        if (data.email) {
          setFieldError('email', data.email);
        }

        if (data.password) {
          setFieldError('password', data.password.join('\n'));
        }
      }
      return false;
    }
  };

  if (!onboarding) {
    return null;
  }

  if (errorverification && !onboarding.hasCreatedAccount) {
    return <EmailVerificationFailed />;
  }

  if (onboarding.hasCreatedAccount) {
    return <CheckYourEmail />;
  }

  return (
    <Container maxWidth="35.7143rem">
      <Formik
        initialValues={initialFormValues}
        onSubmit={onFormSubmit}
        validationSchema={VALIDATION_SCHEMA}
        enableReinitialize
      >
        <CreateAccountForm />
      </Formik>
    </Container>
  );
};
