import { If } from 'components/If';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { pluralize } from 'utils/helpers';
import { UNIT_TYPES } from './context';

const UnitTypeBox = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`;

const UnitDetailsBox = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 0.3rem;
  flex-wrap: wrap;
`;

const UnitTypeDetail = styled.div`
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  padding: 0.3rem 0.8rem;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.2rem;
`;

const IsPrivateRoom = styled.div`
  min-width: 8rem;
  color: ${({ isPrivate }) => (isPrivate ? '#199A38' : '#FF7324')};
  background: ${({ isPrivate }) => (isPrivate ? 'rgba(24, 220, 114, 0.1)' : 'rgb(255, 115, 36, 0.1)')};
  border-radius: 25px;
  padding: 0.2rem 0.5rem;
  text-align: center;
  margin-left: 0.5rem;
`;

const UnitDetails = ({ unit, unitsAvailable }) => {
  const findRoomTypeName = (roomTypeValue) => {
    const roomType = UNIT_TYPES.find((i) => i.id === roomTypeValue);
    if (!roomType) {
      return null;
    }

    return roomType.title;
  };

  return (
    <div>
      <UnitTypeBox>
        {findRoomTypeName(unit.unitType)}
        <IsPrivateRoom isPrivate={unit.isPrivate}>{unit.isPrivate ? 'Private' : 'Shared'}</IsPrivateRoom>
      </UnitTypeBox>
      <UnitDetailsBox>
        <UnitTypeDetail>
          <Icon name="home" color="grey" /> {unit.unitSize} Sq Ft
        </UnitTypeDetail>
        <UnitTypeDetail>
          <Icon name="bed" color="grey" /> {pluralize(unit.numberOfBeds, 'Beds', 'Bed')}
        </UnitTypeDetail>
        <UnitTypeDetail>
          <Icon name="bath" color="grey" /> {pluralize(unit.numberOfBathrooms, 'Baths', 'Bath')}
        </UnitTypeDetail>
        <If condition={unitsAvailable}>
          <UnitTypeDetail>
            <Icon name="point" color="green" /> {unitsAvailable} units available
          </UnitTypeDetail>
        </If>
      </UnitDetailsBox>
    </div>
  );
};

export default UnitDetails;
