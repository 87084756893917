import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FurtherIcon } from 'components/common';
import { Icon } from 'components/lib';
import { capitalize, numberFormatter } from 'utils/helpers';
import { MEDIA_BREAK_POINTS } from '../../constants';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    font-family: var(--font-family-bold);
    font-size: 16px;
    text-align: center;
    color: var(--text);
    margin: 0;
    height: 22px;
  }

  & > span {
    font-family: var(--font-family);
    font-size: 12px;
    text-align: center;
    color: #89919d;
  }
`;

const StatsContainer = styled.div`
  position: relative;
  display: flex;
  margin-top: 15px;
  padding: 13px 55px;
  border-radius: 53px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: var(--white);
`;

const StatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h6 {
    margin: 0;
    font-family: var(--font-family-bold);
    font-size: 12px;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--text);
    white-space: nowrap;
    text-transform: uppercase;
  }
  p {
    margin: 0;
    font-family: var(--font-family);
    font-size: 42px;
    text-align: center;
    color: var(--text);
    position: relative;
    &::before {
      position: absolute;
      content: '+';
      content: ${(props) => (props.stat > 0 ? '+' : '-')};
      color: var(--emerald-green);
      left: 0px;
      font-size: 24px;
      transform: translateX(-100%);
    }
    @media only screen and (max-width: ${MEDIA_BREAK_POINTS.WEB}px) {
      font-size: 36px;
    }
  }
`;

const StatsDivider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
  position: relative;
  &::before {
    content: '';
    top: 0;
    left: 50%;
    position: absolute;
    background-color: var(--line-standard);
    width: 1px;
    height: 100%;
  }
`;

const IconContainer = styled.div`
  width: 42px;
  height: 42px;
  padding: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: var(--white);
  border-radius: 50%;
  z-index: 1;
`;

export const ValueAdded = ({ leads = 0, moveIns = 0 }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <h3>
          Value added by <FurtherIcon /> Further
        </h3>
        <span>
          {capitalize(t('leads'))} and {t('move_ins')} added by Further
        </span>
        <StatsContainer>
          <StatBox stat={leads}>
            <h6>{t('leads')}</h6>
            <p>{numberFormatter(leads, 1)}</p>
          </StatBox>
          <StatsDivider>
            <IconContainer>
              <Icon name="chevron_right" color="#89919d" />
            </IconContainer>
          </StatsDivider>
          <StatBox stat={moveIns}>
            <h6>{t('move_ins')}</h6>
            <p>{numberFormatter(moveIns, 1)}</p>
          </StatBox>
        </StatsContainer>
      </Header>
    </Container>
  );
};
