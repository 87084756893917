import React, { useContext } from 'react';
import styled from 'styled-components';

import { formatPrices } from 'components/utils';
import { ConversationContext } from '../ConversationContext';
import { PanelNoContent } from './PanelNoContent';

const Wrapper = styled.div`
  padding-bottom: 30px;
`;

const PricingRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 30px;
  border-bottom: solid 1px #eaeaeb;
  font-family: var(--font-family);

  &:nth-child(odd) {
    background-color: #fcfcfc;
  }
`;

const PricingRowCost = styled.div`
  color: var(--emerald-green);
`;

export const CommunityPricing = () => {
  const { selectedConversation } = useContext(ConversationContext);
  const prices = formatPrices(selectedConversation?.community);

  if (prices?.length === 0) return <PanelNoContent />;

  return (
    <Wrapper>
      {prices?.map((price) => (
        <PricingRow key={price.name}>
          <span>{price.name}</span>
          <PricingRowCost>{price.cost}</PricingRowCost>
        </PricingRow>
      ))}
    </Wrapper>
  );
};
