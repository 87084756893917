import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 811px;
  height: 1px;
  background-color: var(--line-standard);
  margin: 0 auto;
`;

export const MyProfileDivider = () => {
  return <Container />;
};
