import React from 'react';
import styled from 'styled-components';

import { TextArea as SemanticTextArea } from 'semantic-ui-react';

const StyledTextArea = styled(SemanticTextArea)`
  &&&& {
    height: ${({ height }) => height};
    border-radius: 0;
    resize: none;
    padding: 18px 20px;
    border: solid 1px #ddd;
    background-color: var(--white);
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 1.43;
    color: #222;
    &:focus {
      border-color: var(--link);
    }
  }
`;

export const TextArea = ({ ...props }) => <StyledTextArea {...props} />;
