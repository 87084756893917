import React from 'react';
import { Header, Divider } from 'semantic-ui-react';
import { Formik, FieldArray, Form } from 'formik';
import * as Yup from 'yup';

import UnitLevelPricingStepActions from './UnitLevelPricingStepActions';
import { useUnitLevelPricing, setUnits, UNIT_TYPES, setNextStep } from './context';
import LivingAccommodationTable from './LivingAccommodationTable';

const LivingAccommodationStep = () => {
  const [{ units }, dispatch] = useUnitLevelPricing();
  const initialValues = { units };

  const validationSchema = Yup.object({
    units: Yup.array()
      .required()
      .of(
        Yup.object({
          unitType: Yup.string()
            .required('Room type is required')
            .oneOf(UNIT_TYPES.map((r) => r.id)),
          numberOfBeds: Yup.number().min(1, 'Number of beds must be grater than 0.'),
          numberOfBathrooms: Yup.number().min(1, 'Number of bathrooms must be grater than 0.'),
          unitSize: Yup.number().min(1, 'Room size must be grater than 0.'),
          isPrivate: Yup.boolean(),
        })
      ),
  });

  const handleSubmit = ({ units }) => {
    dispatch(setUnits(units));
    dispatch(setNextStep());
  };

  return (
    <>
      <Header as="h3">
        <Header.Content>
          What room types (unit types) do you offer?
          <Header.Subheader>
            Select unit type, add number of baths and beds included, add its size and select dou you offer it as private
            or sharing option.
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Divider hidden />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <Form>
            <FieldArray name="units">
              {(arrayHelpers) => <LivingAccommodationTable arrayHelpers={arrayHelpers} />}
            </FieldArray>
            <UnitLevelPricingStepActions onNextStep={handleSubmit} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LivingAccommodationStep;
