import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import { BooleanSettingsPage } from '../common';

const ModalText = styled.div`
  font-size: 14px;

  .text {
    font-size: 12px;
    color: var(--light-text);
  }
`;

export const Availability = () => {
  const { t } = useTranslation();
  return (
    <BooleanSettingsPage
      flagName="use_experimental_instant_answers"
      modalText={<ModalText>Set availability of Web Automated Answers for this {t('community')}.</ModalText>}
      modalTitle="Web Automated Answers"
    />
  );
};
