import React, { useContext } from 'react';
import styled from 'styled-components';

import { Table } from 'components/lib';
import { FeaturesContext } from './FeaturesContext';

import { FEATURE_ACTIVE_STATE } from './constants';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
`;

const StyledCount = styled.div`
  color: red;
  padding: 0 4px;

  &.active {
    color: green;
  }
`;

const FeaturesOverviewUserCounts = ({ user }) => {
  const { FEATURE_TYPES } = useContext(FeaturesContext);

  return (
    <>
      {Object.keys(FEATURE_TYPES).map((featureType) => {
        const featureCounts = user.features.filter((feature) => feature.type === parseInt(featureType));
        const featureActive = featureCounts.find((feature) => feature.status === FEATURE_ACTIVE_STATE);
        const featureInactive = featureCounts.find((feature) => feature.status !== FEATURE_ACTIVE_STATE);

        const featureActiveCount = featureActive ? featureActive.total : 0;
        const featureInactiveCount = featureInactive ? featureInactive.total : 0;

        return (
          <Table.Cell key={featureType} textAlign="center">
            <Container>
              <StyledCount className="active">{featureActiveCount}</StyledCount>
              <span>|</span>
              <StyledCount>{featureInactiveCount}</StyledCount>
            </Container>
          </Table.Cell>
        );
      })}
    </>
  );
};

export default FeaturesOverviewUserCounts;
