import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory, Link } from 'react-router-dom';

import { Modal, Button } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { If } from 'components/If';
import EmailPreview from 'components/common/EmailPreview';
import { ConversationContext } from '../ConversationContext';
import RemoveScheduledMessageModal from '../RemoveScheduledMessageModal';

const Header = styled(Modal.Header)`
  &&&& {
    font-size: 14px;
    font-weight: 500;
    padding: 15px 30px;
    strong {
      font-weight: 900;
    }
  }
`;

const Content = styled(Modal.Content)`
  && {
    iframe {
      height: 60vh;
    }
  }
`;

const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &&&& {
    button {
      margin-left: 0;
      margin-right: 0;
    }
    button:only-child {\
      margin-left: auto;
    }
  }
`;

export default function EmailPreviewModal() {
  const { selectedConversation, removeOutreachLoading, removeFromOutreach } = useContext(ConversationContext);
  const [openConfirm, setOpenConfirm] = useState(false);

  const history = useHistory();
  const { leadId, type, messageId } = useParams();
  const close = useCallback(() => {
    history.push(`/conversations/${leadId}`);
  }, [history, leadId]);
  const message = useMemo(() =>
    type === 'scheduled'
      ? selectedConversation?.scheduled_message || {}
      : selectedConversation?.conversation?.find((message) => message.data.id === parseInt(messageId))?.data || {}
  );
  const timestamp = type === 'scheduled' ? message?.scheduled_at : message?.message_sent_at;
  const formattedDate = formatAnyDate(timestamp, 'MMM d, yyyy');
  const formattedTime = formatAnyDate(timestamp, 'h:mm a');
  const campaign =
    type === 'scheduled'
      ? selectedConversation?.campaigns?.find((campaign) => campaign.id === parseInt(message.campaign_id))
      : {};
  const html = useMemo(() => {
    if (type === 'scheduled') {
      return `<style>${message.style || campaign?.email_style}</style>${message?.text}`;
    }
    return message?.html || message?.text;
  }, [messageId, selectedConversation, message]);

  const toggleOpenConfirm = useCallback(() => {
    setOpenConfirm(!openConfirm);
  });

  const handleRemoveFromOutreach = useCallback(async () => {
    await removeFromOutreach();
    setOpenConfirm(false);
    close();
  });

  return (
    <Modal open={true} closeIcon onClose={close}>
      <Header>
        <If condition={type === 'scheduled'}>
          <strong>
            Scheduled for {formattedDate} at {formattedTime}
          </strong>{' '}
          via campaign <Link to={`/messaging/campaigns/1/details/${campaign?.id}`}>{campaign?.name}</Link>
        </If>
        <If condition={type !== 'scheduled'}>
          <strong>
            Sent at {formattedDate} at {formattedTime}
          </strong>{' '}
          via {message?.conversation_type}
        </If>
        <div>
          <strong>Subject:</strong> {message?.subject}
        </div>
      </Header>
      <Content>
        <EmailPreview html={html} width={900} />
      </Content>
      <Actions>
        <If condition={type === 'scheduled'}>
          <Button primary onClick={toggleOpenConfirm}>
            Remove From Outreach
          </Button>
        </If>
        <Button primary color="blue" onClick={close}>
          Close
        </Button>
      </Actions>
      <RemoveScheduledMessageModal
        open={openConfirm}
        leadName={selectedConversation?.name}
        loading={removeOutreachLoading}
        onConfirm={handleRemoveFromOutreach}
        onClose={toggleOpenConfirm}
      />
    </Modal>
  );
}
