import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';
import styled from 'styled-components';
import { If } from '../../If';

const StyledBreadcrumbs = styled(Breadcrumb)`
  && {
    margin: 20px 0px;
  }
`;

const FeaturesOverviewNavigation = () => {
  const { userId, communityId } = useParams();
  const history = useHistory();

  const onNavigationSelection = (path) => {
    history.push(path);
  };

  return (
    <StyledBreadcrumbs size="large">
      <Breadcrumb.Section onClick={() => onNavigationSelection('/admin/features')}>Users</Breadcrumb.Section>

      <If condition={!!userId}>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section onClick={() => onNavigationSelection(`/admin/features/${userId}`)}>
          Communities
        </Breadcrumb.Section>
      </If>

      <If condition={!!userId && !!communityId}>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section>Features</Breadcrumb.Section>
      </If>
    </StyledBreadcrumbs>
  );
};

export default FeaturesOverviewNavigation;
