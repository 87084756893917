import React, { useState, createContext, useContext, useCallback, useRef } from 'react';

import { getMessagesSummary, getCampaignScheduledMessagesMetrics } from 'apis';

const NavigationContext = createContext();

export const NavigationContextProvider = (props) => {
  const subNavigationPortalRef = useRef();
  const [showNavigation, setShowNavigation] = useState(false);
  const [showSubNavigation, setShowSubNavigation] = useState(false);
  const [messagesSummary, setMessagesSummary] = useState({
    active_count: -1,
    unread_count: -1,
  });
  const [scheduledMessagesSummary, setScheduledMessagesSummary] = useState({
    scheduled_messages: -1,
    removed_messages: -1,
  });

  const loadMessagesSummary = useCallback(async () => {
    try {
      const response = await getMessagesSummary();
      if (response) {
        setMessagesSummary(response);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadScheduledMessagesSummary = useCallback(async () => {
    try {
      const response = await getCampaignScheduledMessagesMetrics({
        skip_review: false,
      });
      setScheduledMessagesSummary(response);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const provide = {
    subNavigationPortalRef,
    showNavigation,
    setShowNavigation,
    showSubNavigation,
    setShowSubNavigation,
    messagesSummary,
    loadMessagesSummary,
    scheduledMessagesSummary,
    loadScheduledMessagesSummary,
  };

  return <NavigationContext.Provider value={provide} {...props} />;
};

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    console.error('useNavigationContext must be used within NavigationContextProvider');
  }

  return context;
};
