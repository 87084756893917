import React from 'react';
import styled from 'styled-components';

import { MEDIA_BREAK_POINTS } from '../../constants';
import { ConversationCreateLead } from './ConversationsCreateLead';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TitleWrapper = styled.div`
  font-family: var(--font-family-bold);
  font-size: 48px;
  line-height: 60px;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    font-size: 60px;
  }
`;

export const ConversationsHeader = () => {
  return (
    <Wrapper>
      <TitleWrapper>All Conversations</TitleWrapper>
      <ConversationCreateLead />
    </Wrapper>
  );
};
