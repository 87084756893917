import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { Icon } from 'components/lib';
import { CsvExport } from 'components/common/CsvExport';
import { useExperiments } from './context/ExperimentsContext';
import { ExperimentTypeBadge } from './ExperimentTypeBadge';
import { EXPERIMENT_TYPES } from './constants';
import { ExperimentStat } from './ExperimentStat';

const columnWidths = ['2fr', 'repeat(5, 137px)', '1fr'];

const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 20px;

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .gap-10 {
    gap: 10px;
  }

  .mt-10 {
    margin-top: 10px;
  }
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: ${columnWidths.join(' ')}; // 2fr 137px 137px 137px 137px 137px 1fr;
  border-bottom: 1px solid #d7d7d7;
  padding: 0 30px 7px 30px;
  > h6 {
    font-family: var(--font-family-bold);
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222;
    margin: 0 0 3px 0;
    display: flex;
    align-items: flex-end;
    &:not(:last-child):not(:first-child) {
      padding-left: 20px;
    }
    &:last-child {
      justify-self: end;
    }
  }
  > button.button {
    justify-self: end;
  }
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: ${columnWidths.join(' ')}; // 2fr 137px 137px 137px 137px 137px 1fr;
  border-bottom: 1px solid #d7d7d7;
  padding: 5px 30px;
  &.merge_recomended {
    background-image: linear-gradient(to left, #f9fdfa 100%, #f3fff7);
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 0;
    &:last-child {
      justify-self: end;
    }
  }
`;

const TestNameCell = styled.div`
  display: flex;
  flex-direction: column;
  .duration,
  .importance {
    font-family: var(--font-family-bold);
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0ab776;
  }
  .duration {
    font-family: var(--font-family);
    font-weight: 500;
    color: #222;
  }
  .divider {
    color: #222;
    margin: 0 5px;
  }
  h5 {
    margin: 0;
    font-family: var(--font-family-bold);
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222;
  }
`;

const ActionCell = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    h6 {
      font-family: var(--font-family);
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #0ab776;
    }
  }
`;

export const ExperimentsTable = () => {
  const { experiments } = useExperiments();
  return (
    <TableWrapper>
      <TableHeader>
        <h6>TEST NAME</h6>
        <h6>VISITORS</h6>
        <h6>ENGAGEMENT</h6>
        <h6>LEADS</h6>
        <h6>TOURS</h6>
        <h6>MOVE-INS</h6>
        <CsvExport />
      </TableHeader>
      {experiments &&
        experiments.map((experiment, i) => {
          const rowClasses = classNames({
            merge_recomended: experiment.merge_recomended,
          });
          return (
            <TableRow key={i} className={rowClasses}>
              <TestNameCell>
                <div className="row">
                  {experiment.duration ? (
                    <span className="duration">{experiment.duration}</span>
                  ) : (
                    <span className="duration">NEVER ACTIVATED</span>
                  )}
                  {experiment.is_significant && (
                    <>
                      <span className="divider">&middot;</span>
                      <span className="importance">Significant</span>
                    </>
                  )}
                </div>
                <h5>{experiment.name}</h5>
                <div className="row gap-10 mt-10">
                  {experiment.types.map((type, key) => (
                    <ExperimentTypeBadge key={key} experimentType={EXPERIMENT_TYPES[type]} />
                  ))}
                </div>
              </TestNameCell>
              {experiment.stats.map((stat, i) => (
                <ExperimentStat key={i} stat={stat} highlighted={experiment.highlighted_stat === stat.name} />
              ))}
              <ActionCell>
                {experiment.merge_recomended && (
                  <h6>
                    Merge <br /> Recommended
                  </h6>
                )}
                <Icon name="chevron_right" color="#89919d" size={24} />
              </ActionCell>
            </TableRow>
          );
        })}
    </TableWrapper>
  );
};
