import React from 'react';
import { Rating } from 'semantic-ui-react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';

const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  label {
    font-weight: 700;
    font-size: 0.92rem;
    font-family: var(--font-family);
  }
`;

const StyledRating = styled(Rating)`
  &&&& i {
    &.selected.icon {
      color: ${(props) => (props.color ? 'props.color' : 'var(--emerald-green)')} !important;
    }
    &.active.icon {
      color: ${(props) => (props.color ? 'props.color' : 'var(--emerald-green)')} !important;
    }
  }
`;

export const RatingField = ({ label, onChange, defaultRating, maxRating, color, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  return (
    <ComponentWrapper>
      {label && <label>{label}</label>}
      <StyledRating
        {...field}
        {...props}
        rating={field.value || null}
        maxRating={maxRating}
        defaultRating={defaultRating}
        onRate={(e, { rating }) => {
          setFieldValue(field.name, rating);
          onChange && onChange(rating);
        }}
      />
    </ComponentWrapper>
  );
};
