import React, { useContext } from 'react';
import styled from 'styled-components';

import { useCheckMobileScreen } from 'utils/hooks';
import { UserContext, USER_TYPES } from 'components/UserContext';
import { MobileNavigation } from 'components/navigation/MobileNavigation';
import { SupportModeSelect } from 'components/support-mode';
import { ManagementUserActive } from 'components/management';
import { NavigationLogo } from './NavigationLogo';
import { useNavigationContext } from './NavigationContext';
import { Sidebar } from './Sidebar';
import { NavigationItemsContainer } from './NavigationItemsContainer';
import { UserNavigationMember } from './UserNavigationMember';
import { UserNavigationRegular } from './UserNavigationRegular';
import { UserNavigationLabeling } from './UserNavigationLabeling';
import { UserNavigationStaff } from './UserNavigationStaff';
import { UserNavigationRegional } from './UserNavigationRegional';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-width: ${({ hasSubNavigation }) => `${hasSubNavigation ? 440 : 230}px`};
`;

const USER_TYPE_NAVIGATIONS = {
  [USER_TYPES.STAFF]: UserNavigationStaff,
  [USER_TYPES.REGULAR]: UserNavigationRegular,
  [USER_TYPES.REGIONAL]: UserNavigationRegional,
  [USER_TYPES.MEMBER]: UserNavigationMember,
  [USER_TYPES.LABELING]: UserNavigationLabeling,
};

const SupportModeContainer = styled.div`
  margin: 0 10px 20px 10px;
`;

const ManagementContainer = styled.div`
  margin: 0 10px 10px 10px;
`;

export const Navigation = () => {
  const { user, getSessionOriginalUser } = useContext(UserContext);
  const originalUser = getSessionOriginalUser();
  const isMobile = useCheckMobileScreen();
  const { subNavigationPortalRef, showNavigation, showSubNavigation } = useNavigationContext();

  if (!user || !showNavigation) {
    return null;
  }

  const NavItems = USER_TYPE_NAVIGATIONS[user.type];

  if (!NavItems) {
    return null;
  }

  if (isMobile) {
    return (
      <MobileNavigation>
        {originalUser && originalUser.type === USER_TYPES.STAFF && (
          <SupportModeContainer>
            <SupportModeSelect />
          </SupportModeContainer>
        )}
        <NavItems />
      </MobileNavigation>
    );
  }

  return (
    <Wrapper hasSubNavigation={showSubNavigation}>
      <Sidebar primary>
        <NavigationLogo />
        {originalUser && originalUser.type === USER_TYPES.STAFF && (
          <SupportModeContainer>
            <SupportModeSelect />
          </SupportModeContainer>
        )}
        {originalUser && originalUser.type !== USER_TYPES.STAFF && (
          <ManagementContainer>
            <ManagementUserActive />
          </ManagementContainer>
        )}
        <NavigationItemsContainer>
          <NavItems />
        </NavigationItemsContainer>
      </Sidebar>
      <div ref={subNavigationPortalRef}></div>
    </Wrapper>
  );
};
