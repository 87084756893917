import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Dimmer, Loader, Message, Sticky } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { uploadPhoneNumbers } from 'api';
import { If } from 'components/If';
import { PhoneNumbersPreview } from './PhoneNumbersPreview';
import { UploadDropzone } from '../../common/upload/UploadDropzone';
import { PageHeader } from '../../common';

const Wrapper = styled.div`
  position: relative;
  min-height: 60vh;
  padding: 17px 30px 0 30px;

  h4.ui.header {
    color: #666;
  }
`;

const STATES = {
  UPLOAD: 'FILE_UPLOAD',
  CONFIRM: 'FILE_CONFIRM',
};

export const PhoneNumbersImport = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(STATES.UPLOAD);
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [updatedCount, setUpdatedCount] = useState(0);

  const wrapperRef = useRef();
  const formRef = useRef();

  const onUpload = async () => {
    try {
      setLoading(true);
      const data = new FormData();
      data.append('file', file);
      const response = await uploadPhoneNumbers(data);

      setData(response);

      if (response?.records?.length) {
        setState(STATES.CONFIRM);
      }
    } catch (error) {
      const msg = 'Trouble uploading phone numbers file!';
      console.error(msg, error?.response);
      toast.error(msg);
    }
    setLoading(false);
  };

  const onConfirm = () => {
    if (formRef.current) {
      if (!formRef.current.isValid) {
        toast.warning('Must select at least one row!');
        return;
      }
      formRef.current.handleSubmit();
    }
  };

  const onReset = () => {
    setFile(null);
    setData(null);
    setState(STATES.UPLOAD);
  };

  const onUpdateComplete = (updated) => {
    onReset();
    setUpdatedCount(updated);
  };

  const hasInvalidRecords = data?.records?.some((record) => record.Errors);

  return (
    <Wrapper ref={wrapperRef}>
      <Dimmer active={loading} inverted>
        <Loader inverted>Uploading</Loader>
      </Dimmer>

      <Sticky context={wrapperRef}>
        <PageHeader
          title="Import Phone Numbers"
          subtitle="Intended to be used for Campaign numbers only!"
          block
          actions={
            <>
              <If condition={state === STATES.UPLOAD}>
                <Button floated="right" primary type="submit" onClick={onUpload} content="Upload" disabled={!file} />
              </If>
              <If condition={state === STATES.CONFIRM}>
                <Button
                  floated="right"
                  primary
                  type="submit"
                  onClick={onConfirm}
                  content="Confirm"
                  disabled={hasInvalidRecords}
                />
                <Button floated="right" onClick={onReset} content="Reset" />
              </If>
            </>
          }
        />
      </Sticky>

      <If condition={!file && updatedCount}>
        <Message info>
          Successfully updated {updatedCount} {updatedCount > 1 ? 'leads' : 'lead'}!
        </Message>
      </If>

      <If condition={state === STATES.UPLOAD}>
        <UploadDropzone onSelected={(files) => setFile(files[0])} buttonLabel="Select CSV File" />
      </If>

      <If condition={data !== null && data?.records.length === 0}>
        <Message warning>No records found in uploaded file!</Message>
      </If>

      <If condition={state === STATES.CONFIRM && data?.records?.length}>
        <PhoneNumbersPreview
          formRef={formRef}
          data={data}
          setLoading={setLoading}
          onUpdateComplete={onUpdateComplete}
        />
      </If>
    </Wrapper>
  );
};
