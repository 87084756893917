import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { MoveInLikelihoodModal } from './MoveInLikelihoodModal';
import { MoveinLikelihoodIndicator } from './MoveinLikelihoodIndicator';

const LearnMore = styled.span`
  color: var(--link);
  display: none;
  position: absolute;
  background-color: white;
  width: 85px;
  padding-left: 5px;
  right: -85px;
  top: 2px;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  &:hover {
    ${LearnMore} {
      display: inline;
    }
  }
`;

export const MoveinLikelihood = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  return (
    <>
      <Container>
        <MoveinLikelihoodIndicator />
        <LearnMore onClick={handleModalOpen}>
          <Icon name="help_outline" variant="outlined" color="#89919d" />
        </LearnMore>
      </Container>
      <MoveInLikelihoodModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};
