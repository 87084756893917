import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { pluralize } from 'utils/helpers';

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 30rem;
  overflow-y: auto;
`;

const ChatFlowCommunities = ({ chat }) => {
  const { t } = useTranslation();
  const renderCommunitiesNames = () => (
    <PopupContent>
      {chat.communityNames?.map((name, i) => (
        <span key={i}>{name}</span>
      ))}
    </PopupContent>
  );

  const communitiesCount = <div>[{pluralize(chat.communities.length, t('communities'), t('community'))}]</div>;

  return (
    <Popup
      trigger={communitiesCount}
      content={renderCommunitiesNames}
      position="bottom center"
      size="small"
      hoverable
    />
  );
};

export default ChatFlowCommunities;
