import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import { getCampaignMessagePlaceholders } from 'apis';
import {
  Modal as ComponentsModal,
  Icon,
  TextArea,
  Radio,
  Checkbox,
  Button,
  Form,
  Popup,
  Dimmer,
  Loader,
} from 'components/lib';
import { InputField } from 'components/common';
import useUploadedImages from 'components/editor/hooks/useUploadedImages';
import { toAmPmHours } from 'components/nurture/Campaigns/common/helpers';
import { MAX_FILE_UPLOAD_SIZE_MB, MAX_FILE_UPLOAD_SIZE } from '../../../../constants';
import { CAMPAIGN_TYPE, CAMPAIGN_MESSAGE_TYPE, CRITERION_NAME } from '../../constants';
import { DeleteMessageModal } from '../EditCampaigns/DeleteMessageModal';
import { WebAssistantDropdownField } from '../EditCampaigns/form/fields/WebAssistantDropdownField';
import EmailEditor from './EmailEditor';
import { CustomPlaceholdersModal } from './CustomPlaceholdersModal';
import Dropdown from '../EditCampaigns/form/fields/Dropdown';

const SEND_TIME = {
  BEFORE: 'before',
  AT: 'at',
  AFTER: 'after',
};

const Modal = styled(ComponentsModal)`
  && {
    & > .header {
      border-bottom: none;
    }
    &.tiny,
    &.large {
      width: 1172px;
      height: 87vh;
      margin: 0 auto !important;
      top: 50px !important;
    }
    & > .content {
      height: 100%;
    }
    .ui.form {
      height: 100%;
    }
    .tox-tinymce {
      height: 100% !important;
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  && {
    .field {
      margin: 0;
      position: relative;
    }
    .ui.pointing.label {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 1;
    }
  }
`;

const Sidebar = styled.div`
  width: 232px;
  min-height: 100%;
  border-right: 1px solid var(--line-standard);
  padding: 20px;
  overflow-y: scroll;
`;

const Content = styled.div`
  height: 100%;
  width: 940px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 76px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
  z-index: 1;
`;

const SubjectWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const SubjectInputTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const SubjectInput = styled(InputField)`
  &&&&& {
    input {
      font-family: var(--font-family);
      font-size: 14px;
      line-height: normal;
      letter-spacing: normal;
      color: var(--text);
      width: 700px;
      height: 36px;
      border: solid 1px var(--line-standard);
      background-color: var(--input-field-fill);
      margin-left: 10px;
      &:focus {
        outline: none;
      }
    }
  }
`;

const HeaderActions = styled.div`
  height: 100%;
  display: flex;
  width: 141px;
  align-items: center;
  justify-content: flex-end;
  > span {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

const Body = styled.div`
  width: 100%;
  background-color: var(--input-field-fill);
  height: calc(100% - 156px);
`;

const Footer = styled.div`
  width: 100%;
  height: 80px;
  border-top: 1px solid var(--line-standard);
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-between;
`;

const CustomTextArea = styled(InputField)`
  &&&& {
    textarea {
      width: 100%;
      height: 484px;
      border: none;
      background-color: transparent;
      outline: none;
    }
  }
`;

const MessageType = styled.div`
  width: fit-content;
  padding: 4px 8.5px 1px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: var(--emerald-green);
  font-family: var(--font-family-bold);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  &.email {
    background-color: #2791ff;
  }
`;

const SchedulingTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-top: 20px;
  margin-bottom: 10px;
`;

const CustomRadio = styled(Radio)`
  margin-bottom: 10px;
`;

const TimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Time = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeInput = styled(InputField)`
  &&&&& {
    input {
      border: solid 1px var(--line-standard);
      background-color: var(--input-field-fill);
      font-family: var(--font-family);
      font-size: 14px;
      line-height: normal;
      letter-spacing: normal;
      color: var(--text);
      width: 50px;
      height: 36px;
      padding: 0 9px;
      text-align: center;
      &:focus {
        outline: none;
        border: solid 1px var(--line-standard);
      }
    }
  }
`;

const PlaceholdersTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-top: 30px;
  margin-bottom: 10px;
`;

const PlaceholdersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;
const PlaceholderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  .edit-placeholder {
    cursor: pointer;
  }
`;

const Placeholder = styled.div`
  padding: 3px 6px 3px 5px;
  border-radius: 4px;
  border-radius: ${({ isSystem }) => (isSystem ? '4px 0 0 4px' : '4px')};
  background-color: #e1f0ff;
  font-family: var(--font-family);
  font-size: 10px;
  line-height: normal;
  letter-spacing: normal;
  color: #2791ff;
  margin-bottom: 5px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-right: ${({ isSystem }) => (!isSystem ? '5px' : '0')};
`;

const CustomCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
`;

const InvisibleInput = styled.input`
  display: none;
`;

const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .title {
    margin-top: 4px;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const ActionsWrapper = styled.div`
  margin-left: auto;
`;

const FixedHeightForm = styled(Form)`
  height: 100%;
`;

const ReviewCommunitiesWarning = styled.div`
  margin-top: 10px;
  font-weight: 500;
  font-size: 10px;
  color: var(--light-text);
  > span.warning-title {
    font-weight: 900;
    color: #f6bb5f;
  }
  > span.strong {
    color: var(--link);
  }
`;

const AddPlaceholder = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--vivid-blue);
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1px;
  > span {
    &:last-child {
      padding-top: 4px;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 18px;
  background-color: #f2f9ff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #e1f0ff;
  border-left: none;
`;

const InlineLabelInput = styled(InputField)`
  display: flex;
  padding-left: 26px;
  align-items: center;
  padding-bottom: 10px;
  label {
    width: 54px;
    text-align: right;
    padding-right: 10px;
  }
`;

const WORKING_HOURS = Array.from({ length: 24 }, (_, i) => ({ key: i, value: i, text: toAmPmHours(i) }));

const getSendTimeOptionsText = (t) => ({
  [CRITERION_NAME.TOUR_SCHEDULED]: {
    [SEND_TIME.BEFORE]: `Before the ${t('tour')}`,
    [SEND_TIME.AT]: `At time of ${t('tour')}`,
    [SEND_TIME.AFTER]: `After the ${t('tour')}`,
  },
  [CRITERION_NAME.MOVED_IN]: {
    [SEND_TIME.BEFORE]: `Before the ${t('move_in')}`,
    [SEND_TIME.AT]: `At time of ${t('move_in')}`,
    [SEND_TIME.AFTER]: `After the ${t('move_in')}`,
  },
  [CRITERION_NAME.TOUR_SCHEDULED_EXTERNAL]: {
    [SEND_TIME.BEFORE]: `Before the ${t('tour')}`,
    [SEND_TIME.AT]: `At time of ${t('tour')}`,
    [SEND_TIME.AFTER]: `After the ${t('tour')}`,
  },
});

const resolveInitialSendTime = (campaign, message, sendTimeOptions) => {
  if (message?.id) {
    if (message.isNegativeTime) {
      return SEND_TIME.BEFORE;
    }
    if (message.days === 0 && message.hours === 0 && message.minutes === 0) {
      return SEND_TIME.AT;
    }

    return SEND_TIME.AFTER;
  }

  if (sendTimeOptions.length !== 0) {
    return sendTimeOptions[0].value;
  }

  if (campaign.campaignType === CAMPAIGN_TYPE.SALES) {
    return SEND_TIME.AT;
  }

  return null;
};

const FOCUSED_ELEMENT = {
  SUBJECT: 'subject',
  MESSAGE: 'message',
};

export const EditModal = ({ campaign = {}, open, onClose, onSave, onDelete, campaignType }) => {
  const { state, actions, dispatch, hasEditDeletePermission } = useCampaignDetails();
  const { currentMessage: message = {}, availableSurveyVSAs, triggerCriterion } = state;
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState(null);
  const { userFiles, isLoading: isUserFilesLoading } = useUploadedImages();
  const editorRef = useRef(null);
  const [attachmentUrl, setAttachmentUrl] = useState(message?.attachment);
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const textAreaRef = useRef(null);
  const subjectRef = useRef(null);
  const [focusedElement, setFocusedElement] = useState(null);

  const toggleTimeInput = ({ checked }, setFieldValue, setFieldTouched) => {
    setFieldValue('days', checked ? 1 : 0);
    setFieldValue('hours', 0);
    setFieldValue('minutes', 0);
    setFieldValue('sendTime', checked ? SEND_TIME.AFTER : SEND_TIME.AT);
    setTimeout(() => {
      setFieldTouched('days', true);
      setFieldTouched('hours', true);
      setFieldTouched('minutes', true);
    }, 0);
  };

  const handleAttachmentChange = (event, setFieldValue) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size > MAX_FILE_UPLOAD_SIZE) {
      toast.error(`File size should be less than ${MAX_FILE_UPLOAD_SIZE_MB}MB`);
      event.target.value = null;
      setFieldValue('attachment', '');
      setFile(null);
      setAttachmentUrl(null);
      setFieldValue('previewUrl', '');
    } else {
      const fileURL = URL.createObjectURL(fileUploaded);
      setFile(fileUploaded);
      setFieldValue('attachment', fileUploaded);
      setFieldValue('previewUrl', fileURL);
    }
  };

  const removeAttachment = (setFieldValue) => {
    setFieldValue('attachment', '');
    setFile(null);
    setAttachmentUrl(null);
    setFieldValue('previewUrl', '');
  };

  const handleSelectAttachment = (event) => {
    hiddenFileInput.current.click();
  };

  const getFileType = (filename) => {
    if (filename) {
      return filename.split('.').pop() === 'pdf' ? 'picture_as_pdf' : 'image';
    }
  };

  const buildFieldValueWithPlaceholder = (field, placeholder) => {
    if (!field) {
      return `{${placeholder}}`;
    }

    if (field.selectionStart || field.selectionStart === 0) {
      const startPos = field.selectionStart;
      const endPos = field.selectionEnd;
      return `${field.value.substring(0, startPos)}{${placeholder}}${field.value.substring(
        endPos,
        field.value.length
      )}`;
    }

    return `${field.value}{${placeholder}}`;
  };

  const onTagClick = (setFieldValue, key) => () => {
    if (focusedElement === FOCUSED_ELEMENT.SUBJECT) {
      const subjectField = subjectRef?.current?.children?.item(1)?.querySelector('input');
      const content = buildFieldValueWithPlaceholder(subjectField, key);
      setFieldValue('subject', content);
    } else if (editorRef?.current) {
      editorRef.current.execCommand('mceInsertContent', false, `{${key}}`);
    } else {
      const content = buildFieldValueWithPlaceholder(
        textAreaRef?.current?.children?.item(0)?.querySelector('textarea'),
        key
      );
      setFieldValue('message', content);
    }
  };

  const tagButtonPopupConfig = (tag) => {
    let text = `Disabled because some communities in this campaign are missing the "${tag.key}" value`;
    let popupDisabled = true;
    const disabled = false;

    if (tag.name === 'google_maps_link') {
      text = 'Please double-check that a proper location was entered for each community under this campaign.';
      popupDisabled = false;
    }

    return {
      text,
      disabled,
      popupDisabled,
    };
  };

  const onCancel = (resetForm) => () => {
    resetForm();
    onClose();
  };

  const onMessageDelete = (resetForm) => () => {
    resetForm();
    onDelete(message);
    onClose();
  };

  const fetchPlaceholders = async () => {
    try {
      dispatch({ type: actions.SET_MESSAGE_PLACEHOLDERS_LOADING, data: true });
      const response = await getCampaignMessagePlaceholders(campaign.id);
      dispatch({ type: actions.SET_MESSAGE_PLACEHOLDERS, data: response?.results });
      dispatch({ type: actions.SET_MESSAGE_PLACEHOLDERS_LOADING, data: false });
    } catch (e) {
      console.error(e);
      toast.error('Error fetching placeholders');
      dispatch({ type: actions.SET_MESSAGE_PLACEHOLDERS_LOADING, data: false });
    }
  };

  useEffect(() => {
    if (state?.placeholders?.length === 0) {
      fetchPlaceholders();
    }
  }, []);

  const handleSendTimeChange = (value, { days, hours, minutes }, setFieldValue) => {
    // Set default values for days, hours, minutes for time option.
    if (value === SEND_TIME.AT) {
      setFieldValue('days', 0);
      setFieldValue('hours', 0);
      setFieldValue('minutes', 0);
    } else if (days === 0 && hours === 0 && minutes === 0) {
      setFieldValue('days', 1);
      setFieldValue('hours', 0);
      setFieldValue('minutes', 0);
    }

    setFieldValue('sendTime', value);
  };

  const onDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const buildSendTimeOptions = () => {
    if (!triggerCriterion) {
      return [];
    }

    const sendTimeOptionsText = getSendTimeOptionsText(t)[triggerCriterion.name];
    if (!sendTimeOptionsText) {
      return [];
    }

    return [
      { key: SEND_TIME.BEFORE, text: sendTimeOptionsText[SEND_TIME.BEFORE], value: SEND_TIME.BEFORE },
      { key: SEND_TIME.AT, text: sendTimeOptionsText[SEND_TIME.AT], value: SEND_TIME.AT },
      { key: SEND_TIME.AFTER, text: sendTimeOptionsText[SEND_TIME.AFTER], value: SEND_TIME.AFTER },
    ];
  };

  const handleOnSave = (values) => {
    const messageData = {
      ...values,
      isNegativeTime: values.sendTime === SEND_TIME.BEFORE,
    };
    if (!file && values?.attachment !== '') {
      delete messageData.attachment;
    }
    if (values?.warnings) {
      delete messageData.warnings;
    }
    onSave(messageData);
  };

  const handleFocusedElement = (element) => () => {
    setFocusedElement(element);
  };

  const handleCustomPlaceholderClick = (placeholder) => {
    dispatch({ type: actions.SET_IS_PLACEHOLDERS_MODAL_OPEN, data: true });
    if (placeholder) {
      dispatch({ type: actions.SET_CURRENT_PLACEHOLDER, data: placeholder });
    }
  };

  const isSubmitDisabled = useCallback(({ dirty, isValid, values }) => {
    const dirtyOrInitiallyValid = dirty || (values.subject && values.message);
    return !dirtyOrInitiallyValid || !isValid;
  }, []);

  const sendTimeOptions = buildSendTimeOptions(triggerCriterion);

  const initialValues = {
    sendTime: resolveInitialSendTime(campaign, message, sendTimeOptions),
    days: campaign?.campaignType === CAMPAIGN_TYPE.FOLLOW_UP ? 1 : 0,
    hours: 0,
    minutes: 0,
    disabledOnWeekends: true,
    limitDeliveryHours: true,
    deliveryHoursFrom: 8,
    deliveryHoursTo: 20,
    webAssistant: null,
    ...message,
  };

  const validationSchema = Yup.object().shape({
    subject: Yup.string().max(256, `Can't be longer than 256 characters`).required('Required'),
    message: Yup.string().required('Required'),
    messageType: Yup.string().required('Message type is required'),
    days: Yup.number().required('Required').min(0),
    hours: Yup.number().required('Required').max(24).min(0),
    minutes: Yup.number().required('Required').max(60).min(0),
    sendTime: Yup.string().nullable(),
  });

  return (
    <Modal open={open} width={600} size="large" onClose={onClose}>
      <Modal.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSave}
          enableReinitialize
        >
          {({ values, handleSubmit, setFieldValue, resetForm, isValid, dirty, touched, setFieldTouched }) => {
            const disableTimeInputs = values.sendTime === SEND_TIME.AT && sendTimeOptions.length !== 0;

            return (
              <FixedHeightForm onSubmit={handleSubmit}>
                <ContentWrapper>
                  <Sidebar>
                    <MessageType className={values.messageType}>{values.messageType}</MessageType>
                    <p>Linked Web Assistant</p>
                    <WebAssistantDropdownField
                      name="webAssistant"
                      clearable
                      options={availableSurveyVSAs.map((vsa) => ({
                        id: vsa.chat_id,
                        text: vsa.name,
                        value: vsa.chat_id,
                      }))}
                      value={values.webAssistant}
                    />
                    <If condition={Boolean(message.warnings) && !dirty}>
                      <ReviewCommunitiesWarning>
                        <span className="warning-title">Warning: </span>Some communities using this web assistant
                        <span className="strong"> do not match </span>communities in this campaign.
                      </ReviewCommunitiesWarning>
                    </If>
                    <SchedulingTitle>Scheduling</SchedulingTitle>
                    <If condition={campaignType === CAMPAIGN_TYPE.SALES}>
                      <CustomCheckbox
                        label="Delay sending by:"
                        onChange={(e, data) => toggleTimeInput(data, setFieldValue, setFieldTouched)}
                        checked={values.sendTime === SEND_TIME.AFTER}
                      />
                    </If>
                    <If condition={campaignType === CAMPAIGN_TYPE.FOLLOW_UP}>
                      {sendTimeOptions.map((option) => (
                        <CustomRadio
                          key={option.key}
                          label={option.text}
                          value={option.value}
                          checked={values.sendTime === option.value}
                          onChange={(e, { value }) => handleSendTimeChange(value, values, setFieldValue)}
                        />
                      ))}
                    </If>
                    <TimeWrapper>
                      <Time>
                        <TimeInput label="Days" name="days" type="number" min="0" disabled={disableTimeInputs} />
                      </Time>
                      <Time>
                        <TimeInput
                          label="Hours"
                          name="hours"
                          type="number"
                          max="24"
                          min="0"
                          disabled={disableTimeInputs}
                        />
                      </Time>
                      <Time>
                        <TimeInput
                          label="Minutes"
                          name="minutes"
                          type="number"
                          max="60"
                          min="0"
                          disabled={disableTimeInputs}
                        />
                      </Time>
                    </TimeWrapper>
                    <CustomCheckbox
                      name="disabledOnWeekends"
                      label="Allow delivery on weekends"
                      checked={!values.disabledOnWeekends}
                      onChange={(e, { checked }) => {
                        setFieldValue('disabledOnWeekends', !checked);
                      }}
                    />
                    <CustomCheckbox
                      name="disabledAtNight"
                      label="Limit delivery hours"
                      checked={values.limitDeliveryHours}
                      onChange={(e, { checked }) => {
                        setFieldValue('limitDeliveryHours', checked);
                      }}
                    />
                    <If condition={values.limitDeliveryHours}>
                      <InlineLabelInput
                        name="deliveryHoursFrom"
                        label="From"
                        control={Dropdown}
                        fluid
                        selection
                        placeholder="Select"
                        options={WORKING_HOURS}
                      />
                      <InlineLabelInput
                        name="deliveryHoursTo"
                        label="To"
                        control={Dropdown}
                        fluid
                        selection
                        placeholder="Select"
                        options={WORKING_HOURS.filter((hour) => hour.value >= values.deliveryHoursFrom)}
                      />
                    </If>
                    <PlaceholdersTitle>Placeholders</PlaceholdersTitle>
                    <PlaceholdersWrapper>
                      <Dimmer active={state?.placeholdersLoading} inverted>
                        <Loader active inverted />
                      </Dimmer>
                      {state?.placeholders?.map((tag) => {
                        const tagConfig = tagButtonPopupConfig(tag);
                        return (
                          <>
                            <Popup
                              key={tag.name}
                              disabled={tagConfig.popupDisabled}
                              content={tagConfig.text}
                              trigger={
                                <PlaceholderWrapper>
                                  <Placeholder
                                    disabled={!tag?.enabled || tagConfig.disabled}
                                    onClick={tag?.enabled ? onTagClick(setFieldValue, tag.name) : undefined}
                                    isSystem={tag.isSystem}
                                  >
                                    {tag.key}
                                  </Placeholder>
                                  <If condition={tag.isSystem}>
                                    <IconWrapper>
                                      <Icon
                                        name="edit"
                                        variant="outlined"
                                        size="14"
                                        color="#2791ff"
                                        className="edit-placeholder"
                                        onClick={() => handleCustomPlaceholderClick(tag)}
                                      />
                                    </IconWrapper>
                                  </If>
                                </PlaceholderWrapper>
                              }
                            />
                          </>
                        );
                      })}
                      <AddPlaceholder onClick={() => handleCustomPlaceholderClick()}>
                        <Icon name="add" size="16" color="var(--vivid-blue)" />
                        <span> Add placeholder</span>
                      </AddPlaceholder>
                    </PlaceholdersWrapper>
                    <CustomPlaceholdersModal campaign={campaign} fetchPlaceholders={fetchPlaceholders} />
                  </Sidebar>
                  <Content>
                    <Header>
                      <SubjectWrapper ref={subjectRef}>
                        <SubjectInputTitle>Subject</SubjectInputTitle>
                        <SubjectInput
                          name="subject"
                          placeholder="Subject"
                          onFocus={handleFocusedElement(FOCUSED_ELEMENT.SUBJECT)}
                        />
                        <HeaderActions>
                          <If condition={hasEditDeletePermission && values?.id}>
                            <Icon name="delete_outline" size="24" color="#fa7550" onClick={onDeleteClick} />
                          </If>
                          <Icon name="close" size="24" color="#89919d" onClick={onClose} />
                        </HeaderActions>
                      </SubjectWrapper>
                    </Header>
                    <Body ref={textAreaRef} onFocus={handleFocusedElement(FOCUSED_ELEMENT.MESSAGE)}>
                      {values.messageType === CAMPAIGN_MESSAGE_TYPE.EMAIL ? (
                        <EmailEditor
                          emailStyle={initialValues?.style || campaign.emailStyle}
                          tags={state?.placeholders}
                          loading={state?.placeholdersLoading || isUserFilesLoading}
                          editorRef={editorRef}
                          initialValue={initialValues?.message}
                          imageList={userFiles}
                          onChange={(e, value) => {
                            setFieldValue('message', value);
                          }}
                        />
                      ) : (
                        <CustomTextArea name="message" control={TextArea} placeholder="Message" />
                      )}
                    </Body>
                    <Footer>
                      <If condition={values.messageType === CAMPAIGN_MESSAGE_TYPE.SMS && hasEditDeletePermission}>
                        <Button primary="true" color="white" type="button" onClick={handleSelectAttachment}>
                          <Icon name="attach_file" size="24" color="#275aff" />
                          Attach...
                        </Button>
                      </If>
                      <InvisibleInput
                        name="attachment"
                        type="file"
                        ref={hiddenFileInput}
                        onChange={(e) => handleAttachmentChange(e, setFieldValue)}
                        accept=".pdf,.jpg,.jpeg,.gif,.png,"
                      />
                      {file && (
                        <AttachmentContainer>
                          <Icon name={getFileType(file.name)} variant="outlined" size="24" />
                          <span className="title">{file.name}</span>
                          <Icon
                            name="delete"
                            variant="outlined"
                            color="red"
                            size="24"
                            onClick={() => removeAttachment(setFieldValue)}
                          />
                        </AttachmentContainer>
                      )}
                      {!file && !!attachmentUrl && (
                        <AttachmentContainer>
                          <a
                            className="title"
                            href={values?.previewUrl || attachmentUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View attachment
                          </a>
                          <Icon
                            name="delete"
                            variant="outlined"
                            color="red"
                            size="24"
                            onClick={() => removeAttachment(setFieldValue)}
                          />
                        </AttachmentContainer>
                      )}
                      <ActionsWrapper>
                        <If condition={hasEditDeletePermission}>
                          <Button
                            primary="true"
                            color="blue"
                            type="button"
                            onClick={handleSubmit}
                            disabled={isSubmitDisabled({ dirty, touched, isValid, values })}
                            loading={state?.isMesssageCrudLoading}
                          >
                            Confirm
                          </Button>
                          <Button primary="true" color="white" type="button" onClick={onCancel(resetForm)}>
                            Cancel
                          </Button>
                        </If>
                      </ActionsWrapper>
                    </Footer>
                  </Content>
                  <DeleteMessageModal
                    isOpen={isDeleteModalOpen}
                    onClose={onDeleteModalClose}
                    onSave={onMessageDelete(resetForm)}
                  />
                </ContentWrapper>
              </FixedHeightForm>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
