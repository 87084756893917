import React from 'react';
import { UserCommunityModal } from './UserCommunityModal';
import { USER_COMMUNITY_FLOWS } from './constants';

export const UserCommunityRemoveModal = () => {
  return (
    <UserCommunityModal
      modalHeader="Remove Community"
      flow={USER_COMMUNITY_FLOWS.REMOVE}
      modalSubTitle="Removing the community will result in the following changes:"
    />
  );
};
