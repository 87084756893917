import React from 'react';
import styled from 'styled-components';

import { Checkbox, FURHTER_ICON } from 'components/lib';
import { useSettings } from '../context/SettingsContext';
import { setSelectedItem } from '../context/actions';

const TableRow = styled.div`
  background-color: #ffffff;
  &:not(:last-child) {
    border-bottom: solid 1px #d7d7d7;
  }
  padding: 15px 0 15px 15px;
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: 15px;
  }
  cursor: ${(props) => (props.multipleSelectionEnabled ? 'default' : 'pointer')};
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &:hover {
    background-color: ${(props) => (props.multipleSelectionEnabled ? '#ffffff' : '#fafafa')};
    .list-item {
      background-color: ${(props) => (props.multipleSelectionEnabled ? '#ffffff' : '#fafafa')};
    }
    .number-indicator {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.2s ease;
      .${FURHTER_ICON} {
        display: block;
      }
    }
  }
`;

const TableRowContent = styled.div`
  flex-grow: 1;
`;

export const SettingsTableRow = ({ children, item, onClick, items, currentPage }) => {
  const [{ isMultipleSelection, allItemsSelected, excludedItems, selectedItems }, dispatch] = useSettings();
  const handleOnChange = (id) => {
    dispatch(setSelectedItem({ id, items, currentPage }));
  };
  return (
    <TableRow onClick={onClick}>
      {isMultipleSelection && (
        <Checkbox
          onChange={() => handleOnChange(item.id)}
          checked={allItemsSelected ? !excludedItems.includes(item.id) : selectedItems.includes(item.id)}
        />
      )}
      <TableRowContent>{children}</TableRowContent>
    </TableRow>
  );
};
