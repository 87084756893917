import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import { CRITERIA_VALUE_TYPE } from 'components/nurture/Campaigns/EditCampaigns/sidebar/constants';
import Constant from './Constant';
import Bool from './Bool';
import Integer from './Integer';
import Option from './Option';
import RangeInteger from './RangeInteger';
import DateField from './Date';
import DateRange from './DateRange';
import OptionDynamic from './OptionDynamic';

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;

  .field {
    margin: 0 !important;
  }

  &&&& .close-icon {
    margin-right: 5px;
    margin-top: 5px;
  }
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const Field = ({ index, name, criterion, onRemove }) => {
  const { hasEditDeletePermission } = useCampaignDetails();

  const fieldResolver = () => {
    switch (criterion.valueType) {
      case CRITERIA_VALUE_TYPE.CONSTANT:
        return Constant;
      case CRITERIA_VALUE_TYPE.BOOLEAN:
        return Bool;
      case CRITERIA_VALUE_TYPE.INTEGER:
        return Integer;
      case CRITERIA_VALUE_TYPE.OPTION:
        return Option;
      case CRITERIA_VALUE_TYPE.RANGE_INTEGER:
        return RangeInteger;
      case CRITERIA_VALUE_TYPE.DATE:
        return DateField;
      case CRITERIA_VALUE_TYPE.DATE_RANGE:
        return DateRange;
      case CRITERIA_VALUE_TYPE.OPTION_DYNAMIC:
        return OptionDynamic;
      default:
        throw new Error(`Invalid criterion type: ${criterion.valueType}`);
    }
  };

  const FieldComponent = fieldResolver();
  return (
    <Container>
      <FieldContainer>
        <FieldComponent name={`${name}[${index}]`} index={index} criterion={criterion} />
      </FieldContainer>
      {!criterion.isTrigger && hasEditDeletePermission && (
        <div>
          <Icon className="close-icon" name="close" size="16" color="var(--light-text)" onClick={onRemove} />
        </div>
      )}
    </Container>
  );
};

export default Field;
