import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon, Popup } from 'components/lib';
import { CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS, CONVERSATION_MESSAGE_TYPE_NAME } from './constants';

const Container = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1;
  margin-left: auto;
`;

const Options = styled.div`
  &,
  a {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
  }
  width: 165px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #ffffff;
`;

const Option = styled.div`
  height: 36px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid var(--line-standard);
  }
  &.active {
    font-weight: 900;
  }
`;

const PopupStyle = {
  borderRadius: 0,
  backgroundColor: 'transparent',
  padding: 0,
  border: 'none',
  boxShadow: 'none',
};

const Trigger = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--text);
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Type = styled.span`
  color: var(--link);
`;

const OPTIONS = [
  { text: 'All activity', value: null },
  ...Object.keys(CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS).map((key) => ({
    text: CONVERSATION_MESSAGE_TYPE_NAME[CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS[key]],
    value: CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS[key],
  })),
];

export const ConversationTypeFilter = ({ filterValue, setFilterValue }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuClick = () => setMenuOpen(!menuOpen);
  const currentFilter = OPTIONS.find((option) => option.value === filterValue);

  const handleOptionClick = (value) => {
    setFilterValue(value);
    setMenuOpen(false);
  };

  return (
    <Container>
      <Popup
        trigger={
          <Trigger onClick={handleMenuClick}>
            Show <Type>&nbsp;{currentFilter?.text}</Type>
            <Icon name="keyboard_arrow_down" size="20" color="var(--link)" />
          </Trigger>
        }
        position="bottom right"
        style={PopupStyle}
        basic
        offset={[0, -10]}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        hoverable
      >
        <Options>
          {OPTIONS.map((option) => (
            <Option
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
              className={filterValue === option.value && 'active'}
            >
              {option.text}
            </Option>
          ))}
        </Options>
      </Popup>
    </Container>
  );
};
