import React, { useCallback, useEffect, useRef, useState } from 'react';
import { add } from 'date-fns';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { Dropdown, Dimmer, Loader, Icon } from 'semantic-ui-react';

import { If } from 'components/If';
import { formatAnyDate } from 'components/utils';
import { LEAD_USER_REMINDER_SCHEDULE_TYPE, LEAD_USER_REMINDER_SCHEDULE_TYPE_OPTIONS } from '../../constants';

const MIN_DATE = add(new Date(), { days: 1 });
const MAX_DATE = add(new Date(), { months: 6 });

const Wrapper = styled.div`
  .ui.disabled.dropdown {
    z-index: 100;
  }
`;

const DatePickerWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeadUserReminderDropdown = ({
  open,
  leadUserReminder,
  loading,
  removeMessage = 'Remove reminder',
  onClose,
  onSelect,
  onRemove,
  children,
}) => {
  const wrapperRef = useRef(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dropdownMenuText, setDropdownMenuText] = useState('');

  // Dropdown options used when sending SMS messages will be applied after successfully sending message.
  // Dropdown in question doesn't have children elements.
  const futureReminderSelector = !children;

  useEffect(() => {
    if (!open) {
      setOpenDatePicker(false);
    }
  }, [open]);

  const handleClickOutside = useCallback(
    (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && typeof onClose === 'function') {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (leadUserReminder) {
      const { schedule_type, scheduled_at } = leadUserReminder;

      const selectedScheduleType = schedule_type || LEAD_USER_REMINDER_SCHEDULE_TYPE.NO_REMINDER;
      const selectedTypeOptions = LEAD_USER_REMINDER_SCHEDULE_TYPE_OPTIONS.find(
        (item) => item.key === selectedScheduleType
      );

      if (selectedScheduleType === LEAD_USER_REMINDER_SCHEDULE_TYPE.CUSTOM) {
        setDropdownMenuText(`Reminder at ${formatAnyDate(scheduled_at, 'MMM dd, yyyy')}`);
      } else if (selectedScheduleType) {
        setDropdownMenuText(selectedTypeOptions.text);
      }
    }
  }, [leadUserReminder, setDropdownMenuText]);

  const handleSelectDate = (value) => {
    onSelect({ scheduleType: LEAD_USER_REMINDER_SCHEDULE_TYPE.CUSTOM, reminderDate: value });
  };

  const toggleDatePicker = (e) => {
    setOpenDatePicker(!openDatePicker);
    e.stopPropagation();
  };

  const handleSelectScheduleType = (e, { value }) => {
    onSelect({ scheduleType: value });
  };

  if (!leadUserReminder) {
    return null;
  }

  const { id, schedule_type, scheduled_at } = leadUserReminder;
  const showRemoveReminderOption = Boolean(id && schedule_type);
  const dropdownItems = LEAD_USER_REMINDER_SCHEDULE_TYPE_OPTIONS.filter(
    (item) => item.value !== LEAD_USER_REMINDER_SCHEDULE_TYPE.NO_REMINDER
  );

  return (
    <Wrapper ref={wrapperRef}>
      <Dropdown
        text={futureReminderSelector ? dropdownMenuText : null}
        trigger={children}
        icon={children && null}
        loading={loading}
        button={futureReminderSelector}
      >
        <Dropdown.Menu>
          <Dimmer inverted active={loading}>
            <Loader inverted />
          </Dimmer>

          {dropdownItems.map((o) => (
            <Dropdown.Item
              key={o.key}
              value={o.value}
              onClick={handleSelectScheduleType}
              selected={o.value === schedule_type}
            >
              {o.value === schedule_type && <Icon name="check" color="green" />} {o.text}
            </Dropdown.Item>
          ))}
          <Dropdown.Item
            selected={schedule_type === LEAD_USER_REMINDER_SCHEDULE_TYPE.CUSTOM}
            onClick={toggleDatePicker}
          >
            <>
              <div>
                <Icon name="calendar alternate outline" /> Custom
              </div>
              {openDatePicker && (
                <>
                  <DatePickerWrapper>
                    <DatePicker
                      selected={new Date(scheduled_at)}
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      onChange={handleSelectDate}
                      inline
                    />
                  </DatePickerWrapper>
                </>
              )}
            </>
          </Dropdown.Item>
          <If condition={showRemoveReminderOption}>
            <Dropdown.Divider />
            <Dropdown.Item icon="trash alternate outline" content={removeMessage} onClick={onRemove} />
          </If>
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};

export default LeadUserReminderDropdown;
