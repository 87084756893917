import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { If } from 'components/If';
import { Button } from 'components/lib';
import { parseErrorResponse } from 'apis/utils';
import { settingsEmailIntegrationsUnnasignCommunities } from 'apis';
import { PromptButton } from 'components/common';
import { STATUSES } from '../../../constants';
import { SettingsModal } from '../common';
import { EmailAddressesCommunities } from './EmailAddressesCommunities';
import { DnsInfo } from './DnsInfo';
import { EMAIL_INTEGRATION_STATUS, EMAIl_INTEGRATION_VALUES, INVITABLE_PROVIDERS } from './constants';

const ModalBody = styled.div`
  height: 336px;
`;

const ModalHeader = styled.div``;

const ModalHeaderTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 20px;
  color: var(--text);
  font-weight: 500;
  letter-spacing: normal;
`;

const Status = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
`;

const ResendInvite = styled.span`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  color: var(--link);
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
  border-radius: 13px;
  padding: 0 11px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  cursor: pointer;
  text-transform: capitalize;
  &.active {
    background-color: var(--vivid-blue);
    color: #ffffff;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .prompt {
    border: solid 1px var(--salmon);
    background-color: rgba(250, 117, 80, 0.09);
    color: var(--salmon);
    font-family: var(--font-family-bold);
    &:hover,
    &:focus,
    &:active {
      border: solid 1px var(--salmon);
      background-color: #fde8e2;
      color: var(--salmon);
    }
  }
`;

const CloseButton = styled.div`
  margin-left: auto;
`;

export const EmailAddressesModal = ({ isModalOpen, selectedItem, onClose, handleOnResendInvitesModalOpen }) => {
  const [isUnassignAllLoading, setIsUnassignAllLoading] = useState(STATUSES.IDLE);
  const activeTab = 1;
  const [relatedCommunities, setRelatedCommunities] = useState([]);
  const { t } = useTranslation();

  const tabs = [
    { id: 1, title: t('communities'), Component: EmailAddressesCommunities },
    { id: 2, title: 'DNS Setup Info', Component: DnsInfo },
  ];

  const handleClose = () => {
    onClose();
  };

  const handleOnResendInvites = () => {
    handleOnResendInvitesModalOpen();
  };

  const handleUnassignAll = async () => {
    setIsUnassignAllLoading(STATUSES.LOADING);
    const data = {
      unassignAll: true,
    };
    try {
      await settingsEmailIntegrationsUnnasignCommunities(selectedItem?.id, data);
      toast.success('Unassigning email addresses successfully!');
      handleClose();
    } catch (error) {
      const errorText = 'Trouble unassigning email addresses!';
      const msg = parseErrorResponse(error, errorText);
      toast.error(msg);
      setIsUnassignAllLoading(STATUSES.FAILURE);
    } finally {
      setIsUnassignAllLoading(STATUSES.LOADED);
    }
  };

  const ActiveTabComponent = tabs.find((tab) => tab.id === activeTab)?.Component;
  return (
    <SettingsModal open={isModalOpen} onModalClose={handleClose} size="tiny" width={540} height={502}>
      <ModalHeader type="header">
        <ModalHeaderTitle>{selectedItem?.email}</ModalHeaderTitle>
        <Status className={selectedItem?.status}>
          {EMAIl_INTEGRATION_VALUES[selectedItem?.status]?.text}{' '}
          <If
            condition={
              selectedItem?.status !== EMAIL_INTEGRATION_STATUS.active &&
              INVITABLE_PROVIDERS.includes(selectedItem?.provider)
            }
          >
            &#183; <ResendInvite onClick={handleOnResendInvites}>Resend invite</ResendInvite>
          </If>
        </Status>
      </ModalHeader>
      <ModalBody type="body">
        {/* TODO: add tabs later */}
        {/*     <TabsWrapper>
          {tabs.map((tab) => (
            <Tab key={tab.id} className={tab.id === activeTab ? 'active' : ''} onClick={() => setActiveTab(tab.id)}>
              {tab.title}
            </Tab>
          ))}
        </TabsWrapper> */}
        <ActiveTabComponent
          onClose={handleClose}
          selectedItem={selectedItem}
          setRelatedCommunities={setRelatedCommunities}
          relatedCommunities={relatedCommunities}
        />
      </ModalBody>

      <Footer type="actions">
        <If condition={relatedCommunities.length > 0}>
          <PromptButton
            floated="right"
            content="Unassign All"
            title="Unassign All"
            description="Unassign this email address from all communities?
          This will result in disruption of email campaign services."
            onConfirm={handleUnassignAll}
            loading={isUnassignAllLoading === STATUSES.LOADING}
            actionLabel="Unassign"
            triggerClassName="prompt"
          />
        </If>

        {/* TODO: add remove button later */}
        {/*         <PromptButton
          floated="right"
          content="Remove"
          title="Remove this email address?"
          description="You can re-add this email address later by selecting Add, choosing the associated domain, and then entering the domain username agan."
          onConfirm={handleRemove}
          loading={loading}
          actionLabel="Remove"
          triggerClassName="prompt"
        /> */}
        <CloseButton>
          <Button primary onClick={handleClose}>
            Close
          </Button>
        </CloseButton>
      </Footer>
    </SettingsModal>
  );
};
