import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Transition } from 'semantic-ui-react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import {
  Button,
  Modal as ComponentsModal,
  CustomMessage,
  Accordion as SemanticAccordion,
  Icon,
  Checkbox,
  Dimmer,
  Loader,
  FilterDropdown,
} from 'components/lib';
import { pluralize } from 'utils/helpers';
import { UserCircle } from 'components/UserCircle';
import SearchInput from 'components/SearchInput';
import { If } from 'components/If';
import SearchClients from 'assets/images/search-clients.png';
import { getOffboardingData, getOffboardingUsers, reassignCommunity } from 'apis';
import FailureMessage from 'components/common/FailureMessage';
import { EMBEDDED_VSA_LABEL, REGULAR_VSA_LABEL, VOICE_BASED_VSA_LABEL, VSA_TYPE } from 'components/chats/constants';
import { ACTIONS, CommunitiesContext } from '../common/communities/CommunitiesContext';
import { UserContext } from '../UserContext';
import { STATUSES } from '../../constants';
import { USER_COMMUNITY_FLOWS } from './constants';
import Block from '../../assets/images/block.svg';
import Person from '../../assets/images/person.svg';
import Call from '../../assets/images/call.svg';

const StyledDropdown = styled(FilterDropdown)`
  &&&&.ui.selection.dropdown {
    min-width: 180px;
    img {
      vertical-align: middle;
      margin-top: -0.6em;
    }
    .item {
      padding-left: 21px !important;
    }
  }
`;

const Modal = styled(ComponentsModal)`
  && {
    & > .header {
      border-bottom: none;
    }
  }
`;

const HeaderLink = styled.span`
  font-size: 14px;
  font-family: var(--font-family);
  color: var(--link);
  display: block;
`;

const Content = styled.div`
  padding: 0 30px;
`;

const ItemsText = styled.span`
  font-size: 14px;
  font-family: var(--font-family-bold);
  color: var(--text);
  display: inline-block;
  margin: 20px 0 10px;
`;

const Accordion = styled(SemanticAccordion)`
  &&&&& {
    .title {
      border-bottom: transparent;
      flex-wrap: wrap;
    }
  }
`;

const AccordionWrapper = styled.div`
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #d7d7d7;
  border-top: none;
  margin-bottom: 30px;
  .accordion-icon {
    margin-right: 5px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: var(--font-family);
  color: var(--text);
  width: 100%;
  padding: 10px 20px 10px 16px;
`;

const Count = styled.div`
  min-width: 34px;
  height: 20px;
  border-radius: 13px;
  background-color: #f3f3f1;
  font-size: 12px;
  font-family: var(--font-family);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
  margin-left: auto;
  padding-top: 4px;
`;

const UsersRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const UsersRowType = styled.div`
  padding: 3px 3px 3px 41px;
  background-color: #e6e8ee;
  font-size: 10px;
  font-weight: var(--font-weight-bold);
  color: var(--text);
  text-transform: capitalize;
`;

const UsersRowName = styled.div`
  font-size: 12px;
  font-family: var(--font-family);
  color: var(--text);
`;

const UsersRowLink = styled.a`
  font-size: 10px;
  font-family: var(--font-family);
  color: var(--link);
`;

const UsersRowDataWrapper = styled.div`
  display: flex;
  background-color: #f8f8f8;
  padding: 10px 20px 10px 41px;
  align-items: center;
  justify-content: space-between;
`;

const UsersRowNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
`;

const ContentRow = styled.div`
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
`;
const ContentName = styled.a`
  background-color: #f8f8f8;
  font-size: 12px;
  font-family: var(--font-family);
  line-height: 1.83;
  color: var(--link);
  margin-left: 9px;
`;
const ContentType = styled.div`
  font-size: 12px;
  font-family: var(--font-family);
  line-height: 1.83;
  text-align: right;
  color: #89919d;
  margin-left: auto;
  text-transform: capitalize;
`;

const ContentWrapper = styled.div`
  background-color: #f8f8f8;
  padding: 7px 20px 7px 20px;
  .icon {
    width: 14px;
  }
`;

const CrmContent = styled.div`
  display: flex;
  padding-left: 21px;
`;

const CrmKey = styled.div`
  font-size: 12px;
  color: var(--text);
  margin-right: 2px;
`;

const CrmValue = styled.div`
  font-size: 12px;
  color: #89919d;
  margin-left: 2px;
`;

const CustomLink = styled(RouterLink)`
  background-color: #f8f8f8;
  font-size: 12px;
  font-family: var(--font-family);
  line-height: 1.83;
  color: var(--link);
  margin-left: 9px;
`;

const PhoneSettingsContent = styled.div`
  padding: 5px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TotalPhoneNumbers = styled.div`
  font-size: 12px;
  color: var(--text);
`;

const OtherPhoneNumbers = styled.div`
  font-size: 10px;
  font-family: var(--font-family);
  color: var(--link);
`;

const NumberWrappers = styled.div`
  display: flex;
  flex-direction: column;
`;

const UsersDropdown = ({ user, onChange, isAssignFlow, disabled }) => {
  const options = [
    {
      key: 'revoke',
      value: `revoke-${user?.id}`,
      text: 'Revoke Access',
      image: { src: Block },
    },
    { key: 'keep', value: `keep-${user?.id}`, text: 'Keep Access', image: { src: Person } },
  ];
  const [value, setValue] = useState(options[0].value);
  const handleOnChange = (e, { value }) => {
    setValue(value);
    const id = value.split('-')[1];
    onChange(parseInt(id));
  };
  return (
    <StyledDropdown
      selection
      options={options}
      value={value}
      onChange={handleOnChange}
      disabled={!isAssignFlow || disabled}
    />
  );
};

const Users = ({ data, submitData, setSubmitData, isAssignFlow }) => {
  const onChange = (id) => {
    if (submitData.usersToKeep.includes(id)) {
      setSubmitData({
        ...submitData,
        usersToKeep: submitData.usersToKeep.filter((user) => user !== id),
      });
    } else {
      setSubmitData({
        ...submitData,
        usersToKeep: [...submitData.usersToKeep, id],
      });
    }
  };
  return (
    data &&
    Object.keys(data).map((type) => (
      <UsersRow key={type}>
        <UsersRowType>{type}</UsersRowType>
        {data[type].map((user) => (
          <UsersRowDataWrapper key={user?.id}>
            <UsersRowNameWrapper>
              <UsersRowName>
                {user?.first_name} {user?.last_name}
              </UsersRowName>
              <UsersRowLink>{user?.email}</UsersRowLink>
            </UsersRowNameWrapper>
            <UsersDropdown user={user} onChange={onChange} isAssignFlow={isAssignFlow} disabled={type === 'client'} />
          </UsersRowDataWrapper>
        ))}
      </UsersRow>
    ))
  );
};

const WebAssistants = ({ data }) => {
  const chatTypeLabelMapper = {
    [VSA_TYPE.EMBEDDED]: {
      title: EMBEDDED_VSA_LABEL,
      Icon: <Icon name="wysiwyg" size="14" color="#2791ff" className="icon" />,
    },
    [VSA_TYPE.REGULAR]: {
      title: REGULAR_VSA_LABEL,
      Icon: <Icon name="question_answer" size="14" color="#2791ff" className="icon" />,
    },
    [VSA_TYPE.PHONE]: {
      title: VOICE_BASED_VSA_LABEL,
      Icon: <Icon name="phone'" size="14" color="#0ab776" className="icon" />,
    },
  };
  return (
    <ContentWrapper>
      {data &&
        data.map((chat) => {
          const chatType = chatTypeLabelMapper[chat?.vsa_type];
          return (
            <ContentRow key={chat?.id}>
              {chatType?.Icon}
              <ContentName>{chat?.name}</ContentName>
              <ContentType>{chatType?.title?.toLocaleLowerCase()}</ContentType>
            </ContentRow>
          );
        })}
    </ContentWrapper>
  );
};

const Campaigns = ({ data }) => {
  return (
    <ContentWrapper>
      {data &&
        data.map((campaign) => (
          <ContentRow key={campaign.id}>
            <Icon name="campaign" size="14" color="#89919d" className="icon" />
            <CustomLink to={`/messaging/campaigns/${campaign.id}/details`} target="_blank">
              {campaign.name}
            </CustomLink>
          </ContentRow>
        ))}
    </ContentWrapper>
  );
};

const CRMSettings = ({ data }) => {
  return (
    <ContentWrapper>
      <CrmContent>
        <CrmKey>CRM Location Code:</CrmKey>
        <CrmValue>{data?.code}</CrmValue>
      </CrmContent>
      <CrmContent>
        <CrmKey>CRM Settings:</CrmKey>
        <CrmValue>{data?.name}</CrmValue>
      </CrmContent>
      <CrmContent>
        <CrmKey>CRM Intergration:</CrmKey>
        <CrmValue>{data?.integration}</CrmValue>
      </CrmContent>
    </ContentWrapper>
  );
};

const PhoneSettings = ({ data, submitData, setSubmitData, isAssignFlow }) => {
  const [value, setValue] = useState(true);
  const countMap = {
    1: 0,
    2: 0,
    3: 0,
  };

  for (const phoneNumber of data) {
    countMap[phoneNumber.number_type]++;
  }

  const result = {
    total: data.length,
    countType1: countMap[1],
    countType2: countMap[2],
    countType3: countMap[3],
  };

  const options = [
    {
      key: 'deactivate',
      value: true,
      text: 'Deactivate',
      image: { src: Block },
    },
    {
      key: 'keep',
      value: false,
      text: 'Keep Active',
      image: { src: Call },
    },
  ];

  const onChange = (e, { value }) => {
    setValue(value);
    setSubmitData({
      ...submitData,
      deactivate_phone_system: value,
    });
  };

  return (
    <ContentWrapper>
      <PhoneSettingsContent>
        <NumberWrappers>
          <TotalPhoneNumbers>{pluralize(result.total, 'phone numbers', 'phone number')}</TotalPhoneNumbers>{' '}
          <OtherPhoneNumbers>
            {' '}
            {result.countType1} website, {result.countType3} backup,{' '}
            {pluralize(result.countType2, 'campaign numbers', 'campaign number')}
          </OtherPhoneNumbers>
        </NumberWrappers>
        {data?.length > 0 && (
          <StyledDropdown selection options={options} onChange={onChange} value={value} disabled={!isAssignFlow} />
        )}
      </PhoneSettingsContent>
    </ContentWrapper>
  );
};

const NewClientWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 72px;
`;

const NewClientText = styled.div`
  font-size: 14px;
  font-family: var(--font-family);
  color: #89919d;
  margin-bottom: 20px;
`;

const NewClientName = styled.span`
  color: var(--text);
`;

const NewClientList = styled.ul`
  padding-left: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #d7d7d7;
  margin-top: 0;
  max-height: 240px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  scrollbar-width: thin;
  &.old {
    overflow-y: visible;
    box-shadow: none;
  }
  &.new {
    margin-top: 20px;
  }
`;

const NewClientListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 12px 10px;
  &:not(:last-child) {
    border-bottom: 1px solid #d7d7d7;
  }
  &.old {
    background-color: #f8f8f8;
    position: relative;
  }
  &.clickable {
    cursor: pointer;
  }
`;

const NewClientNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const NewClientListName = styled.div`
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  color: var(--text);
`;

const NewClientListEmail = styled.span`
  font-size: 12px;
  font-family: var(--font-family);
  color: var(--link);
`;

const Type = styled.div`
  min-width: 42px;
  height: 20px;
  margin: 8px 0 9px 212px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  background-color: #ffffff;
  margin-left: auto;
  font-size: 12px;
  font-family: var(--font-family);
  text-align: center;
  color: var(--text);
  padding-top: 3px;
  margin-right: 34px;
  &.green {
    background-color: #0ab776;
    color: #ffffff;
    margin-right: 5px;
  }
`;

const Oval = styled.div`
  width: 42px;
  height: 42px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 5px;
  .close {
    cursor: pointer;
  }
`;

const ClientSearchImg = styled.img`
  width: 100px;
  align-self: center;
  margin-top: 72px;
`;

const AssignPreviewWrapper = styled.div`
  padding-bottom: 72px;
  .checkbox {
    margin-top: 30px;
  }
`;

const TitleText = styled.div`
  font-size: 12px;
  font-family: var(--font-family);
  color: #89919d;
  width: 100%;
  padding-left: 42px;
  padding-right: 35px;
`;

const Highlight = styled.span`
  color: #e8a947;
`;

const NewClient = ({ submitData, setSubmitData, onSubmit, activeCommunity, newClientText }) => {
  const [loadingUsers, setLoadingUsers] = useState();
  const [users, setUsers] = useState();
  const [search, setSearch] = useState('');
  const handleSearch = (value) => {
    setSearch(value);
  };
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoadingUsers(true);
        const { results, next } = await getOffboardingUsers(activeCommunity?.id, {
          search: search || undefined,
          page_size: 30,
        });

        setUsers(results);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchUsers();
  }, [search]);
  const onClick = (user) => {
    setSubmitData({ ...submitData, newClient: user });
    onSubmit();
  };
  return (
    <NewClientWrapper>
      <NewClientText>{newClientText}</NewClientText>
      <SearchInput
        autoFocus
        value={search}
        loading={loadingUsers}
        fluid
        iconPosition="left"
        icon={<Icon name="search" color="#89919d" size="22" />}
        onSearch={handleSearch}
      />
      {!users?.length && <ClientSearchImg src={SearchClients} alt="Logo" />}
      {users?.length > 0 && (
        <NewClientList>
          {users.map((user) => (
            <NewClientListItem key={user.id} className="clickable" onClick={() => onClick(user)}>
              <UserCircle user={user} />
              <NewClientNameWrapper>
                <NewClientListName>{user.name}</NewClientListName>
                <NewClientListEmail>{user.email}</NewClientListEmail>
              </NewClientNameWrapper>
            </NewClientListItem>
          ))}
        </NewClientList>
      )}
    </NewClientWrapper>
  );
};

const AssignPreview = ({ submitData, setSubmitData, user, onSubmit, flow, newClientText }) => {
  const newClient = submitData?.newClient;
  const onClick = () => {
    setSubmitData({ ...submitData, newClient: null, accept: false });
    onSubmit(flow === USER_COMMUNITY_FLOWS.ASSIGN ? 2 : 1);
  };
  const onChange = () => {
    setSubmitData({ ...submitData, accept: !submitData?.accept });
  };
  return (
    <AssignPreviewWrapper>
      <NewClientText>{newClientText}</NewClientText>
      <NewClientList className="old">
        <NewClientListItem className="old">
          <UserCircle user={user || {}} />
          <NewClientNameWrapper>
            <NewClientListName>{user?.name}</NewClientListName>
            <NewClientListEmail>{user?.email}</NewClientListEmail>
          </NewClientNameWrapper>
          <Type>Old</Type>
          <Oval>
            <Icon name="expand_more" size="24" color="#89919d" />
          </Oval>
        </NewClientListItem>
      </NewClientList>
      <NewClientList className="new">
        <NewClientListItem>
          <UserCircle user={newClient || {}} />
          <NewClientNameWrapper>
            <NewClientListName>{newClient?.name}</NewClientListName>
            <NewClientListEmail>{newClient?.email}</NewClientListEmail>
          </NewClientNameWrapper>
          <ActionWrapper>
            <Type className="green">New</Type>
            <Icon name="close" size="24" className="close" color="#89919d" onClick={onClick} />
          </ActionWrapper>
        </NewClientListItem>
      </NewClientList>
      <Checkbox label="I understand and wish to proceed" onChange={onChange} className="checkbox" />
    </AssignPreviewWrapper>
  );
};

const SelectUsersToKeep = (props) => {
  const { accordionData, modalSubTitle } = props;
  const [activeIndex, setActiveIndex] = useState();
  const handleAccordion = (e, { index }) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <CustomMessage type="warning" title="The changes below are permanent and cannot be undone." />
      <ItemsText>{modalSubTitle}</ItemsText>
      <AccordionWrapper>
        {accordionData.map(({ title, count, Content, data, titleText }, index) => {
          return (
            <Accordion fluid key={title}>
              <Accordion.Title active={activeIndex === index} index={index} onClick={handleAccordion}>
                <TitleBox>
                  <Icon
                    name={activeIndex === index ? 'expand_more' : 'chevron_right'}
                    size="20"
                    className="accordion-icon"
                  />
                  <span>{title}</span>
                  {(count || count === 0) && <Count>{count}</Count>}
                </TitleBox>
                {activeIndex === index && titleText}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index} index={index}>
                <Transition visible={activeIndex === index} animation="slide down" duration={150}>
                  <Content data={data} {...props} />
                </Transition>
              </Accordion.Content>
            </Accordion>
          );
        })}
      </AccordionWrapper>
    </>
  );
};

export const UserCommunityModal = ({ modalHeader, flow, modalSubTitle }) => {
  const { state, dispatch, removeCommunity } = useContext(CommunitiesContext);
  const { activeModal, activeCommunity } = state;
  const [modalData, setModalData] = useState(null);
  const [modalLoaderStatus, setModalLoaderStatus] = useState(STATUSES.IDLE);
  const { user } = useContext(UserContext);
  const [step, setStep] = useState(1);
  const [submitData, setSubmitData] = useState({
    usersToKeep: [],
    newClient: null,
    accept: false,
    deactivate_phone_system: true,
  });
  const history = useHistory();

  const onModalClose = () => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_MODAL,
      payload: { activeModal: null },
    });
  };

  const onSubmit = async (customStep) => {
    if (flow === USER_COMMUNITY_FLOWS.ASSIGN) {
      const newStep = customStep || step + 1;
      if (newStep === 4) {
        try {
          setModalLoaderStatus(STATUSES.LOADING);
          await reassignCommunity(activeCommunity?.id, {
            user_id: submitData?.newClient?.id,
            remove: false,
            keep_manager_ids: submitData.usersToKeep,
            deactivate_phone_system: submitData.deactivate_phone_system,
          });
          toast.success('Community reassigned successfully!');
          history.push('/communities');
          removeCommunity({ id: activeCommunity?.id });
          onModalClose();
        } catch (e) {
          console.error(e);
          toast.error('Error reassigning community! Please try again later, or contact us for help.');
          setModalLoaderStatus(STATUSES.LOADED);
        }
      } else {
        setStep(newStep);
      }
    }
    if (flow === USER_COMMUNITY_FLOWS.REMOVE) {
      const newStep = customStep || step + 2;
      if (newStep === 5) {
        try {
          setModalLoaderStatus(STATUSES.LOADING);
          await reassignCommunity(activeCommunity?.id, {
            user_id: null,
            remove: true,
            keep_manager_ids: submitData.usersToKeep,
            deactivate_phone_system: submitData.deactivate_phone_system,
          });
          toast.success('Community removed successfully!');
          history.push('/communities');
          removeCommunity({ id: activeCommunity?.id });
          onModalClose();
        } catch (e) {
          console.error(e);
          toast.error('Error removing community! Please try again later, or contact us for help.');
          setModalLoaderStatus(STATUSES.LOADED);
        }
      } else {
        // in the remove flow we need to set the new client to the Further Account
        // alos we skip the step were we select the new client that is the reason we set the step to + 2
        setSubmitData({
          ...submitData,
          newClient: {
            id: 1,
            name: 'Further',
            email: 'further@email.com',
          },
          accept: false,
        });
        setStep(newStep);
      }
    }
  };
  /* GET ALL DATA */
  const getAllData = async () => {
    setModalLoaderStatus(STATUSES.LOADING);
    try {
      const response = await getOffboardingData(activeCommunity?.id);
      let groupedUsers = response?.managers?.reduce((grouped, user) => {
        (grouped[user.type] = grouped[user.type] || []).push(user);
        return grouped;
      }, {});
      groupedUsers = {
        client: [response?.user],
        ...groupedUsers,
      };
      setModalData({
        users: {
          count: response?.managers?.length,
          data: groupedUsers,
        },
        webAssistants: {
          count: response?.chat_properties?.length,
          data: response?.chat_properties,
        },
        campaigns: {
          count: response?.campaigns?.length,
          data: response?.campaigns,
        },
        crmSettings: {
          count: response?.crm_settings?.length,
          data: { ...response?.crm_settings, code: response?.crm_location_code },
        },
        phoneSettings: {
          data: response?.phone_numbers,
        },
      });
      setModalLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      setModalLoaderStatus(STATUSES.FAILURE);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);
  /* GET ALL DATA */

  const accordionData = [
    {
      title: 'Users',
      titleText: (
        <TitleText>
          Access to the community will be revoked for current user accounts. If there are specific user accounts that
          should still have access to manage the community, select 'Keep Access' for each of those accounts.
        </TitleText>
      ),
      count: modalData?.users?.count,
      Content: Users,
      data: modalData?.users?.data,
    },
    {
      title: 'Web assistants',
      titleText: (
        <TitleText>
          The community will be removed from all previously associated web assistants. As a result, none of the web
          assistants will be active on the website, and their functionalities will no longer be available.
        </TitleText>
      ),
      count: modalData?.webAssistants?.count,
      Content: WebAssistants,
      data: modalData?.webAssistants?.data,
    },
    {
      title: 'Campaigns',
      count: modalData?.campaigns?.count,
      titleText: (
        <TitleText>
          The community will no longer be associated with existing campaigns and any upcoming messages related to those
          campaigns will be canceled
        </TitleText>
      ),
      Content: Campaigns,
      data: modalData?.campaigns?.data,
    },
    {
      title: 'CRM Settings',
      titleText: <TitleText>The community will be detached from the current CRM settings.</TitleText>,
      Content: CRMSettings,
      data: modalData?.crmSettings?.data,
    },
    {
      title: 'Call Tracking',
      titleText: (
        <TitleText>
          Existing phone numbers associated with the community will be deactivated. To retain the current phone numbers,
          select 'Keep Active,' but note that creating a new web assistant for Conversational IVR is necessary for the
          phone system to function properly.
        </TitleText>
      ),
      Content: PhoneSettings,
      data: modalData?.phoneSettings?.data,
    },
  ];
  const isAssignFlow = flow === USER_COMMUNITY_FLOWS.ASSIGN;
  const newClientText = isAssignFlow ? (
    <>
      The community will be unassigned from <NewClientName>{user?.name}</NewClientName> and assigned to the client you
      select below.
    </>
  ) : (
    <>
      The community will be unassigned from <NewClientName>{user?.name}</NewClientName> and archived into a default{' '}
      <NewClientName>Further</NewClientName> account. If you change your mind later, you can reassign it to someone else
      from there.
    </>
  );

  const STEPS = {
    DATA_PREVIEW: 1,
    CLIENT_SELECTION: 2,
    PREVIEW: 3,
  };

  const isSaveButtonDisabled = step >= 2 && (!submitData.newClient || !submitData.accept);
  return (
    <Modal open={!!activeModal} closeIcon onClose={onModalClose} size="tiny">
      <Modal.Header>
        {modalHeader}
        <HeaderLink>{activeCommunity?.name}</HeaderLink>
      </Modal.Header>
      <Modal.Content scrolling style={{ maxHeight: 404 }}>
        <Content>
          <Dimmer active={modalLoaderStatus === STATUSES.LOADING} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <If condition={modalLoaderStatus !== STATUSES.FAILURE}>
            <If condition={step === STEPS.DATA_PREVIEW}>
              <SelectUsersToKeep
                accordionData={accordionData}
                submitData={submitData}
                setSubmitData={setSubmitData}
                modalSubTitle={modalSubTitle}
                isAssignFlow={isAssignFlow}
              />
            </If>
            <If condition={step === STEPS.CLIENT_SELECTION}>
              <NewClient
                submitData={submitData}
                setSubmitData={setSubmitData}
                onSubmit={onSubmit}
                user={user}
                flow={flow}
                activeCommunity={activeCommunity}
                newClientText={newClientText}
              />
            </If>
            <If condition={step === STEPS.PREVIEW}>
              <AssignPreview
                submitData={submitData}
                setSubmitData={setSubmitData}
                user={user}
                onSubmit={onSubmit}
                flow={flow}
                newClientText={newClientText}
              />
            </If>
          </If>
          <If condition={modalLoaderStatus === STATUSES.FAILURE}>
            <FailureMessage icon="search" content="Data could not be loaded" />
          </If>
        </Content>
      </Modal.Content>
      <Modal.Actions>
        <Button primary color="blue" onClick={() => onSubmit()} disabled={isSaveButtonDisabled}>
          {step >= 2 ? 'Save' : 'Next'}
        </Button>
        <Button primary onClick={onModalClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
