import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatDuration } from 'components/utils';
import { capitalize } from 'utils/helpers';
import { Statistic } from '../../common/Statistic';
import { buildSalesTeamResponseRate } from './util';

const duration = (seconds) => {
  if (!seconds) return '-';
  return formatDuration(seconds);
};

export const Metrics = ({ metrics }) => {
  const { t } = useTranslation();
  const { avgSalesFirstResponseTime, avgSalesResponseTime, salesResponseCount, prospectResponseCount } = metrics;

  return (
    <Statistic.Group name="Average Sales Team Response Time">
      <Statistic label={`Responses From ${capitalize(t('lead'))}`} value={prospectResponseCount} />
      <Statistic
        label="Sales Team Response Rate"
        value={buildSalesTeamResponseRate(salesResponseCount, prospectResponseCount)}
      />
      <Statistic label="Average First Response Time" value={duration(avgSalesFirstResponseTime)} />
      <Statistic label="Average Response Time Per Message" value={duration(avgSalesResponseTime)} />
    </Statistic.Group>
  );
};
