import React from 'react';

import styled from 'styled-components';

import { MultiSelectFilter } from 'components/common';

const FiltersRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
`;

const DashboardFilterDropdowns = ({
  accountManagers,
  customers,
  crmTypes,
  filters,
  handleManagerChange,
  handleCustomerChange,
  handleCrmTypeChange,
}) => (
  <FiltersRow>
    <MultiSelectFilter
      name="Customer"
      placeholder="Any Customer"
      options={customers}
      selectedValues={filters.customer}
      onChange={handleCustomerChange}
    />
    <MultiSelectFilter
      name="Account Manager"
      placeholder="Any Manager"
      options={accountManagers}
      selectedValues={filters.accountManager}
      onChange={handleManagerChange}
    />
    <MultiSelectFilter
      name="CRM Type"
      placeholder="Any CRM"
      options={crmTypes}
      selectedValues={filters.crmType}
      onChange={handleCrmTypeChange}
    />
  </FiltersRow>
);

export default DashboardFilterDropdowns;
