import React from 'react';
import styled from 'styled-components';

const Title = styled.div`
  font-family: var(--font-family-bold);
  font-size: 40px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-top: 10px;
  margin-bottom: 15px;
`;

export const MyProfileTitle = () => {
  return <Title>My Profile</Title>;
};
