import { Communities } from './Communities';
import { Templates as AITemplates } from '../AI/Templates';
import { Templates } from '../Template/Templates';
import { Messages } from '../AI/Messages';

export const TEMPLATE_STEPS = [
  {
    component: Communities,
    hasPreviousStep: false,
  },
  {
    component: Templates,
    hasPreviousStep: true,
  },
];

export const AI_STEPS = [
  {
    component: Communities,
    hasPreviousStep: false,
  },
  {
    component: AITemplates,
    hasPreviousStep: true,
  },
  {
    component: Messages,
    hasPreviousStep: true,
  },
];
