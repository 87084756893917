import React, { useMemo } from 'react';
import { useCRM } from './context';
import UrlParameterRule from '../MappingRules/UrlParameterRule';

const CRMSourceMappingUrlParameterRule = (props) => {
  const [{ eligibleUrlParameters }] = useCRM();

  const urlParameterOptions = useMemo(
    () =>
      eligibleUrlParameters.map((urlParameter) => ({
        key: urlParameter,
        value: urlParameter,
        text: urlParameter,
      })),
    [eligibleUrlParameters]
  );

  return <UrlParameterRule {...props} urlParameterOptions={urlParameterOptions} />;
};

export default CRMSourceMappingUrlParameterRule;
