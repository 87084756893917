import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { STATUSES } from 'constants';
import { Button, Icon, Loader } from 'components/lib';
import { If } from 'components/If';
import { CommunitiesGroupsContext, CommunitiesGroupsHeader, CommunitiesGroupsList } from '.';
import { SettingsContext } from '../SettingsContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* WithTopBar + settings heading heights = 86px */
  height: calc(100vh - 86px);
`;

const GroupsDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  h2 {
    margin: 0;
    font-family: var(--font-family-bold);
    font-size: 18px;
    color: var(--text);
  }
  span {
    font-family: var(--font-family);
    font-size: 14px;
    color: #89919d;
  }
`;

const NoGroups = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  font-family: var(--font-family);
  font-size: 14px;
  color: #89919d;
  flex: 1 0 auto;
  h4 {
    font-family: var(--font-family-bold);
    font-size: 18px;
    color: var(--text);
    margin: 0;
  }
  p {
    max-width: 600px;
    font-family: var(--font-family);
    font-size: 14px;
    text-align: center;
    color: #89919d;
    margin-top: 6px;
    margin-bottom: 20px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  justify-content: center;
  flex: 1 0 auto;
`;

export const CommunitiesGroupsIndex = () => {
  const { t } = useTranslation();
  const { groups, loaderStatus, search, handelModalOpen } = useContext(CommunitiesGroupsContext);
  const { loaderStatus: communitiesLoader } = useContext(SettingsContext);

  const isLoading = loaderStatus === STATUSES.LOADING || communitiesLoader === STATUSES.LOADING;
  const hasGroups = groups.length > 0;
  const TEXT = `Use this feature to group ${t(
    'communities'
  )} that belong to the same organization or have common properties. You can filter by ${t(
    'community'
  )} groups on screens like All Conversations.`;
  return (
    <Container>
      <CommunitiesGroupsHeader />
      <If condition={isLoading}>
        <LoaderContainer>
          <Loader active size="large" />
        </LoaderContainer>
      </If>
      <If condition={!hasGroups && !isLoading && !search}>
        <NoGroups>
          <h4>What are {t('community')} groups?</h4>
          <p>{TEXT}</p>
          <Button primary color="blue" onClick={handelModalOpen}>
            Create a Group
          </Button>
        </NoGroups>
      </If>
      <If condition={(hasGroups || search) && !isLoading}>
        <GroupsDescription>
          <h2>What are {t('community')} groups?</h2>
          <span>{TEXT}</span>
        </GroupsDescription>
        <CommunitiesGroupsList />
      </If>
    </Container>
  );
};
