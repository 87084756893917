import React, { useContext } from 'react';
import { Table, Form, Icon } from 'semantic-ui-react';
import { Formik } from 'formik';
import { useParams } from 'react-router';
import * as Yup from 'yup';

import { CareTypesContext } from './CareTypesContext';
import { InputField } from '../../common';
import { EditRankCell } from './EditRankCell';

export const CommunityCareTypesListItemEdit = ({ careType }) => {
  const { setEditCareType, changeCommunityCareType } = useContext(CareTypesContext);

  const onCancel = async () => {
    setEditCareType(undefined);
  };

  const initialValues = {
    display_name: careType.display_name,
  };

  const validationSchema = Yup.object({
    display_name: Yup.string().max(30, `Can't be longer than 30 characters`),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(data) => changeCommunityCareType(data, careType.id)}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <Table.Row key={careType.id}>
          <EditRankCell careType={careType} />
          <Table.Cell width={2}>{careType.rank}</Table.Cell>
          <Table.Cell width={5}>{careType.care_type.name}</Table.Cell>
          <Table.Cell width={5}>
            <Form>
              <InputField name="display_name" placeholder="Display Name" />
            </Form>
          </Table.Cell>
          <Table.Cell width={2} textAlign="center">
            <Icon name="check" link onClick={handleSubmit} />
            <Icon name="cancel" link onClick={onCancel} />
          </Table.Cell>
        </Table.Row>
      )}
    </Formik>
  );
};
