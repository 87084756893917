import React from 'react';
import styled from 'styled-components';
import { Table } from 'semantic-ui-react';
import { useFormikContext } from 'formik';

import { InputField } from 'components/common/fields/InputField';
import UnitDetails from './UnitDetails';

const InputDivider = styled.div`
  margin: 0.3rem 0;
`;

const PriceStepTable = ({ careTypes }) => {
  const { values } = useFormikContext();

  if (!values) {
    return null;
  }

  const { units } = values;

  if (!units) {
    return null;
  }

  return (
    <Table celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Unit Type</Table.HeaderCell>
          {careTypes.map((ct) => (
            <Table.HeaderCell key={ct?.id}>{ct?.title}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {units.map((u, unitIndex) => (
          <Table.Row key={unitIndex}>
            <Table.Cell>
              <UnitDetails unit={u} />
            </Table.Cell>
            {careTypes.map((ct, careTypeIndex) => (
              <Table.Cell key={`${unitIndex}-${careTypeIndex}`}>
                <InputField
                  placeholder="Min (Optional)"
                  icon="dollar"
                  iconPosition="left"
                  name={`units[${unitIndex}].careTypes[${careTypeIndex}].minPrice`}
                  type="number"
                  fluid
                />
                <InputDivider />
                <InputField
                  placeholder="Max (Optional)"
                  icon="dollar"
                  iconPosition="left"
                  name={`units[${unitIndex}].careTypes[${careTypeIndex}].maxPrice`}
                  type="number"
                  fluid
                />
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default PriceStepTable;
