import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Popup } from 'components/lib';
import { AudioPlayPauseWidget } from './AudioPlayPauseWidget';

const LastMessageContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 5px;

  span {
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--link);
  }
`;

const SmallText = styled.span`
  font-size: 12px;
  &:not(:empty) {
    margin-top: 3px;
  }
`;

export const ConversationsTableLastMessage = ({ lead }) => {
  if (!lead) {
    return null;
  }

  return (
    <LastMessageContainer>
      <If condition={lead.phone_call}>
        <Popup
          flowing
          trigger={
            <AudioPlayPauseWidget
              text={`Playing call from ${lead.name}`}
              callId={lead.phone_call?.id}
              playerOffset={115}
            />
          }
          content="Listen to call"
        />
      </If>
      <SmallText>{lead.conversation?.last_message}</SmallText>
    </LastMessageContainer>
  );
};
