import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const StyledIcon = styled.span`
  &.pointer {
    cursor: pointer;
  }
`;

export const FURHTER_ICON = 'further-icon';

export const Icon = ({ name, size, variant, color, onClick, ...otherProps }) => {
  const classes = classNames(
    'icon',
    otherProps.className,
    FURHTER_ICON,
    `material-icons${variant ? `-${variant}` : ''}`,
    {
      pointer: typeof onClick === 'function',
    }
  );

  return (
    <StyledIcon className={classes} style={{ color, fontSize: `${size}px` }} onClick={onClick}>
      {name}
    </StyledIcon>
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', '', 'round', 'sharp', 'two-tone']),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  size: '20',
  variant: '',
};
