import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  activatePhoneSystem,
  checkPhoneCommunitiesSync,
  deactivatePhoneSystem,
  editPhoneSystem,
  getGlobalSelector,
  getPhoneSystemData,
  updatePhoneSystemGlobals,
} from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { downloadFile, formatAnyDate } from 'components/utils';
import { PAGINATION, setUrlSearchParams } from '../../../constants';
import {
  DEFAULT_PHONE_NUMBER_USAGE_VALUE,
  PHONE_NUMBER_FILTERS,
  SYNC_INTERVAL,
  SYNC_STATUS,
  ERROR_STATUS_TYPE,
} from './constants';
import { getUrlFilterAndSearch } from '../common/constants';

const PhoneSystemContext = createContext();

export const PhoneSystemProvider = ({ children }) => {
  const [globalQuerySelector, setGlobalQuerySelector] = useState('');
  const { urlFilterValue, urlSearchValue } = getUrlFilterAndSearch({ flagName: 'filter' });
  const [communities, setCommunities] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    search: urlSearchValue,
    filter: urlFilterValue || DEFAULT_PHONE_NUMBER_USAGE_VALUE,
    page: PAGINATION.PAGE,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [isGlobalsSaving, setIsGlobalsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [syncingCommunities, setSyncingCommunities] = useState({});

  // Hooks
  useEffect(() => {
    if (
      Object.values(syncingCommunities)
        ?.map((item) => item.queuedToSync)
        .includes(true)
    ) {
      const syncTimeout = setTimeout(() => {
        handleCheckSync();
      }, SYNC_INTERVAL);

      return () => clearTimeout(syncTimeout);
    }
  }, [syncingCommunities, handleCheckSync]);

  useEffect(() => {
    fetchCommunities(filters);
  }, [filters]);

  const fetchCommunities = useCallback(async (filters) => {
    setIsLoading(true);
    const { search, filter, page } = filters;
    try {
      const { results, count } = await getPhoneSystemData({
        search,
        page,
        pageSize: PAGINATION.PAGE_SIZE,
        ...prepareFilterValues(filter),
      });

      setCommunities(results);
      setTotalPages(Math.ceil(count / PAGINATION.PAGE_SIZE));
      setTotalCount(count);

      setSyncingCommunities((draft) => {
        return {
          ...draft,
          ...results.filter((r) => r.queuedToSync).reduce((acc, r) => ({ ...acc, [r.id]: r }), {}),
        };
      });
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to fetch communities'));
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchGlobalQuerySelector = useCallback(async () => {
    try {
      const { phoneNumberQuerySelector } = await getGlobalSelector();
      setGlobalQuerySelector(phoneNumberQuerySelector);
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to fetch global query selector'));
    }
  }, []);

  // Filters
  const prepareFilterValues = (filterKey) => {
    const filterItem = PHONE_NUMBER_FILTERS[filterKey];
    return {
      [filterItem.key]: filterItem.value,
    };
  };

  const handleFiltersChange = (search, filter) => {
    setCommunities([]);

    setFilters((draft) => {
      setUrlSearchParams({ search, filter });
      return { ...draft, search, filter, page: PAGINATION.PAGE };
    });
  };

  const handlePageChange = (page) => {
    setFilters((draft) => {
      return { ...draft, page };
    });
  };

  // Actions handlers
  const handleGlobalsUpdate = async (querySelector) => {
    setIsGlobalsSaving(true);

    try {
      const data = { globalQuerySelector: querySelector };
      await updatePhoneSystemGlobals(data);
      setGlobalQuerySelector(querySelector);
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to update global query selector.'));
    }

    setIsGlobalsSaving(false);
  };

  const handleCheckSync = useCallback(async () => {
    try {
      const checkCommunities = { communityIds: Object.keys(syncingCommunities) };
      const results = await checkPhoneCommunitiesSync(checkCommunities);

      setSyncingCommunities(results.reduce((acc, r) => ({ ...acc, [r.id]: r }), {}));
      setCommunities((draft) =>
        draft.map((c) => {
          const community = results.find((o) => o.id === c.id);
          if (community) {
            return community;
          }

          return c;
        })
      );
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to check sync progress'));
      setSyncingCommunities({});
    }
  }, [syncingCommunities]);

  const onPhoneSystemActivate = async (community, data) => {
    try {
      setIsSaving(true);
      const updatedCommunity = await activatePhoneSystem(community.id, data);
      const newCommunities = communities.map((c) => {
        if (c.id !== updatedCommunity.id) return c;
        return updatedCommunity;
      });

      setCommunities(newCommunities);
      setSyncingCommunities(
        newCommunities.filter((c) => c.queuedToSync).reduce((acc, r) => ({ ...acc, [r.id]: r }), {})
      );
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to activate phone system'));
    } finally {
      setIsSaving(false);
    }
  };

  const onPhoneSystemDeactivate = async (community) => {
    try {
      setIsSaving(true);

      await deactivatePhoneSystem(community.id);
      const newCommunities = communities.map((c) => {
        if (c.id !== community.id) return c;
        return { ...c, queuedToSync: true, isEnabled: false };
      });

      setCommunities(newCommunities);
      setSyncingCommunities(
        newCommunities.filter((c) => c.queuedToSync).reduce((acc, r) => ({ ...acc, [r.id]: r }), {})
      );
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to deactivate phone system'));
    } finally {
      setIsSaving(false);
    }
  };

  const handlePhoneSystemEdit = async (community, data) => {
    try {
      setIsSaving(true);

      const updatedCommunity = await editPhoneSystem(community.id, data);
      const newCommunities = communities.map((c) => {
        if (c.id !== updatedCommunity.id) return c;
        return updatedCommunity;
      });

      setCommunities(newCommunities);
      setSyncingCommunities(
        newCommunities.filter((c) => c.queuedToSync).reduce((acc, r) => ({ ...acc, [r.id]: r }), {})
      );
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to edit phone system settings'));
    } finally {
      setIsSaving(false);
    }
  };

  const getErrorSyncStatus = (community) => {
    if (!community.purchaseErrorStatus) {
      return SYNC_STATUS.NONE;
    }

    if (community.isEnabled && !!community.purchaseErrorStatus) {
      return SYNC_STATUS.GENERAL_ERROR;
    }

    switch (community.purchaseErrorStatus) {
      case ERROR_STATUS_TYPE.GENERAL_ERROR:
        return SYNC_STATUS.GENERAL_ERROR;
      case ERROR_STATUS_TYPE.GEOLOCATION_PURCHASE_ERROR:
        return SYNC_STATUS.GEOLOCATION_PURCHASE_ERROR;
      case ERROR_STATUS_TYPE.AREA_CODE_PURCHASE_ERROR:
        return SYNC_STATUS.AREA_CODE_PURCHASE_ERROR;
      default:
        return SYNC_STATUS.NONE;
    }
  };

  const getCommunitySyncStatus = (community) => {
    if (!community?.queuedToSync && !community?.purchaseErrorStatus) {
      if (community?.isEnabled) {
        return SYNC_STATUS.ON;
      }
      return SYNC_STATUS.OFF;
    }

    const syncingCommunity = syncingCommunities[community.id];

    if (!syncingCommunity) {
      return getErrorSyncStatus(community);
    }

    if (syncingCommunity.queuedToSync === null || syncingCommunity.queuedToSync === undefined) {
      if (!!syncingCommunity.purchaseErrorStatus) {
        return getErrorSyncStatus(community);
      }
      if (syncingCommunity.isEnabled) {
        return SYNC_STATUS.COMPLETED;
      }
      return SYNC_STATUS.NONE;
    }

    if (syncingCommunity.queuedToSync) {
      return SYNC_STATUS.ACTIVE;
    }

    if (!syncingCommunity.queuedToSync && !!syncingCommunity.purchaseErrorStatus) {
      return getErrorSyncStatus(community);
    }

    return SYNC_STATUS.COMPLETED;
  };

  const exportPhoneNumbers = async () => {
    const { search, filter } = filters;
    const response = await getPhoneSystemData({
      search,
      ...prepareFilterValues(filter),
      export: true,
    });
    downloadFile({ data: response }, `phone_numbers_${formatAnyDate(new Date(), 'yyyyMMdd_hhmmss')}.csv`);
  };

  const value = {
    // State
    globalQuerySelector,
    communities,
    totalPages,
    totalCount,
    isSaving,
    filters,
    isLoading,
    isGlobalsSaving,
    syncingCommunities,
    selectedCommunity,
    // API
    fetchCommunities,
    fetchGlobalQuerySelector,
    handleFiltersChange,
    handleGlobalsUpdate,
    handlePageChange,
    onPhoneSystemActivate,
    onPhoneSystemDeactivate,
    handlePhoneSystemEdit,
    getCommunitySyncStatus,
    setGlobalQuerySelector,
    setSelectedCommunity,
    exportPhoneNumbers,
  };

  return <PhoneSystemContext.Provider value={value}>{children}</PhoneSystemContext.Provider>;
};

export const usePhoneSystem = () => {
  const ctx = useContext(PhoneSystemContext);

  if (!ctx) {
    throw new Error('usePhoneSystem must be used within the PhoneSystemProvider');
  }

  return ctx;
};
