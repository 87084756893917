import React from 'react';
import useCallTimer from './useCallTimer';

const Timer = ({ startedAt }) => {
  const { formattedTime } = useCallTimer(startedAt);

  return <div>{formattedTime}</div>;
};

export default Timer;
