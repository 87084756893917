import React from 'react';
import styled from 'styled-components';

import { UserCircle } from 'components/UserCircle';
import { formatPhoneNumber } from 'utils/helpers';

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px 20px 8px;
  box-shadow: 0 3px 5px 0 rgba(216, 216, 216, 0.6);
  border: solid 1px var(--line-standard);
  background-color: var(--white);
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  h3 {
    margin: 0;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const People = ({ user }) => {
  return (
    <UserContainer>
      <UserCircle size="30" user={user} />
      <UserInfo>
        <h3>
          {user?.first_name} {user?.last_name}
        </h3>
        <span>
          {user?.phone && `${formatPhoneNumber(user?.phone)} • `}
          <a href={`mailto:${user?.email}`} target="_blank" rel="noreferrer">
            {user?.email}
          </a>
        </span>
      </UserInfo>
    </UserContainer>
  );
};
