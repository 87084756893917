import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Form } from 'components/lib';
import { LoginInputField } from '../LoginInputField';
import { FieldLabel, FieldLinkLabel, FieldLabelWrapper } from '../AuthLayout';
import { validateLoginField } from '../utils';
import { AuthButton } from '../AuthButton';

const CodeResendLabel = styled(FieldLinkLabel)`
  animation: fadeIn 0.25s;
`;

const CodeSendLabel = styled(FieldLinkLabel)`
  animation: fadeIn 0.25s;
`;

const VALIDATION_SCHEMA = Yup.object({
  login_code: Yup.string().required('Code is required'),
});

const LoginMagicLinkForm = ({ loginLoading, requestMagicLink, onSubmit }) => {
  const magicLinkFieldRef = useRef();

  const [magicLinkClicked, setMagicLinkClicked] = useState(false);

  const initialValues = {
    login_code: '',
  };

  const submit = async ({ login_code }, { setFieldError }) => {
    const isValid = await validateLoginField({ login_code }, VALIDATION_SCHEMA, setFieldError);
    if (!isValid) {
      magicLinkFieldRef?.current?.focus();
      return;
    }

    const loginCode = login_code?.replaceAll('-', '');
    try {
      await onSubmit(loginCode);
      setFieldError('login_code', null);
    } catch (error) {
      setFieldError('login_code', 'The code is incorrect or has expired');
      magicLinkFieldRef?.current?.focus();
    }
  };

  const onRequestMagicLink = async () => {
    setMagicLinkClicked(true);
    await requestMagicLink();
  };

  return (
    <Formik initialValues={initialValues} validateOnBlur={false} onSubmit={submit}>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <LoginInputField
            autoFocus
            ref={magicLinkFieldRef}
            disabled={loginLoading}
            name="login_code"
            placeholder="Enter code here"
            maxLength={24}
            label={
              <FieldLabelWrapper>
                <FieldLabel>Login Code</FieldLabel>
                {!magicLinkClicked ? (
                  <CodeResendLabel position="right" variant="link" onClick={onRequestMagicLink}>
                    Didn't get it? Resend
                  </CodeResendLabel>
                ) : (
                  <div>
                    <FieldLabel position="right">Code sent.&nbsp;</FieldLabel>
                    <CodeSendLabel position="right" variant="link" onClick={onRequestMagicLink}>
                      Send another
                    </CodeSendLabel>
                  </div>
                )}
              </FieldLabelWrapper>
            }
          />
          <AuthButton label="Log In" loading={loginLoading} disabled={loginLoading} />
        </Form>
      )}
    </Formik>
  );
};

export default LoginMagicLinkForm;
