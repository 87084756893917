import React from 'react';
import styled from 'styled-components';

import { Table } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { ProfileCircle } from 'components/common/profile/ProfileCircle';

const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #89919d;
  word-break: break-word;

  & b {
    color: #222;
  }
`;

const CommunityDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const HighlightedText = styled.span`
  color: #222;
`;

export const ChatPropertyAssigmentChanged = ({ businessActivity }) => {
  const { author, owner, contentObjectName, createdOn, changes } = businessActivity;

  return (
    <>
      <Description>
        The following communities were added
        {author && (
          <span>
            &nbsp;by <HighlightedText>{author.displayName}</HighlightedText>
          </span>
        )}
        {owner && (
          <span>
            &nbsp;for the client <HighlightedText>{owner.displayName}</HighlightedText>,
          </span>
        )}{' '}
        to the web assistant <HighlightedText>{contentObjectName}</HighlightedText>,&nbsp;on&nbsp;
        <HighlightedText>{formatAnyDate(createdOn, "MMM d, yyyy 'at' hh:mm a")}</HighlightedText>.
      </Description>
      <div>
        {changes.communities && (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Added Communities</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {changes.communities.map((o) => (
                <Table.Row key={o.id}>
                  <Table.Cell>
                    <CommunityDisplay>
                      <ProfileCircle name={o.name} picture={o.logo} /> {o.name}
                    </CommunityDisplay>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </>
  );
};
