import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

import { useCheckSmallMobileScreen } from 'utils/hooks';
import { Button, Icon, TextArea } from 'components/lib';
import LeadMessagesNotificationModal from 'components/common/LeadMessagesNotificationModal';
import { checkNewMessages } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import InputMaxLength from 'components/common/InputMaxLength';
import {
  MAX_FILE_UPLOAD_SIZE,
  MAX_FILE_UPLOAD_SIZE_MB,
  MAX_MESSAGE_LENGTH,
  MEDIA_BREAK_POINTS,
  STATUSES,
  LEAD_EVENT_MESSAGE_TYPES,
} from '../../constants';
import { If } from '../If';
import { InputField } from '../common/fields/InputField';
import { scrollIntoView } from '../utils';
import { ConversationContext } from './ConversationContext';
import { CONVERSATION_MESSAGE_NAME, CONVERSATION_MESSAGE_TYPE, INPUT_FIELD_ROWS } from './constants';

import { ConversationMessageComposerSendOptions } from './ConversationMessageSendOptions';
import { ConversationMessageComposerReminder } from './ConversationMessageReminder';
import { useCheckMobileScreen } from '../../utils/hooks';
import { ConversationAddNoteButton } from './ConversationAddNoteButton';

const FullWidthContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f7fa;
`;

const FormContainer = styled.div`
  padding: 0 1rem 1rem 1rem;
  background-color: #f6f7fa;
  max-width: 1084px;
  width: 100%;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    padding: 1rem;
    padding: 10px 30px 30px 30px;
  }
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .field {
    flex: 1;
  }

  .circular {
    margin-right: 20px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  span {
    margin-right: 12px;
  }
`;

const InvisibleInput = styled.input`
  display: none;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: flex-end;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    margin-bottom: 20px;
    justify-content: flex-start;
    margin-bottom: 0px;
    &:nth-child(2) {
      margin-right: auto;
      margin-left: 30px;
    }
  }

  .limit {
    margin-right: 30px;
  }
`;

export const ActionTitle = styled.span`
  margin-right: 10px;
`;

const StyledInputField = styled(InputField)`
  &&&& {
    @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      textarea {
        padding: 10px;
      }
    }
  }
`;

export const ConversationMessageComposer = () => {
  const { conversationId } = useParams();
  const isSmallMobile = useCheckSmallMobileScreen();
  const isMobile = useCheckMobileScreen();

  const {
    selectedConversation,
    handleSetLeadUserReminder,
    fetchConversationDetails,
    setLoaderStatus,
    sendMessage,
    setShowArchiveSuggestion,
  } = useContext(ConversationContext);

  const formRef = useRef();
  const hiddenFileInput = React.useRef(null);
  const [file, setFile] = useState(null);
  const [openConfirmSend, setOpenConfirmSend] = useState(false);
  const [mostRecentMessage, setMostRecentMessage] = useState(undefined);

  useEffect(() => {
    const allMessages = selectedConversation?.conversation || [];
    const lastMessage = allMessages
      .filter(({ message_type }) =>
        [CONVERSATION_MESSAGE_TYPE.SMS, CONVERSATION_MESSAGE_TYPE.EMAIL].includes(message_type)
      )
      .pop();
    setMostRecentMessage(lastMessage);
  }, [selectedConversation]);

  useEffect(() => {
    formRef.current.resetForm();
    setFile(null);
  }, [conversationId]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const getSendMessageType = () => {
    const isEmailType =
      selectedConversation?.has_email_integration &&
      mostRecentMessage?.message_type === CONVERSATION_MESSAGE_TYPE.EMAIL;

    return isEmailType ? CONVERSATION_MESSAGE_TYPE.EMAIL : CONVERSATION_MESSAGE_TYPE.SMS;
  };

  const getMessageTypeName = (message_type) => {
    const message_type_name = CONVERSATION_MESSAGE_NAME[message_type];
    return message_type_name.toLowerCase();
  };

  const handleChange = (event, setFieldValue) => {
    const fileUploaded = event.target.files[0];
    if (!fileUploaded) {
      return;
    }
    if (fileUploaded.size > MAX_FILE_UPLOAD_SIZE) {
      toast.error(`File size should be less than ${MAX_FILE_UPLOAD_SIZE_MB}MB`);
      setFieldValue('file', '');
      setFile(null);
      event.target.value = null;
    } else {
      setFile(fileUploaded);
      setFieldValue('file', fileUploaded?.name);
    }
  };

  const removeAttachment = (setFieldValue) => {
    setFieldValue('file', '');
    setFile(null);
  };

  const getFileType = (filename) => {
    if (filename) {
      return filename.split('.').pop() === 'pdf' ? 'picture_as_pdf' : 'image';
    }
  };

  const handleCloseMessagesModal = () => {
    setOpenConfirmSend(false);
    fetchConversationDetails(selectedConversation.id);
  };

  const hasNewMessages = async () => {
    const data = {
      message_type: null,
      message_id: null,
    };

    if (mostRecentMessage) {
      data.message_type = getMessageTypeName(mostRecentMessage.message_type);
      data.message_id = mostRecentMessage.data.id;
    }

    const { new_messages } = await checkNewMessages(selectedConversation.id, data);

    return new_messages;
  };

  const handleSendMessage = async ({ message, reminder, from }) => {
    let anyNewMessages = false;

    try {
      setLoaderStatus(STATUSES.LOADING);
      anyNewMessages = await hasNewMessages();

      if (anyNewMessages) {
        setOpenConfirmSend(true);
      } else {
        const data = new FormData();
        const messageTypeName = getMessageTypeName(from);

        data.append('message_type', messageTypeName);
        data.append('text', message || ' ');

        if (file) {
          data.append('attachment', file);
        }

        window?.analytics?.track('Message sent', {
          community: selectedConversation?.community?.name,
          community_id: selectedConversation?.community?.id,
          lead_source: selectedConversation?.details?.lead_source,
          message_type: messageTypeName,
        });

        await sendMessage(selectedConversation.id, data);

        if (reminder) {
          handleSetLeadUserReminder({ scheduleType: reminder });
        }

        if (!selectedConversation.messages_archived) {
          setShowArchiveSuggestion(true);
        }
      }
      setLoaderStatus(STATUSES.LOADED);
    } catch (error) {
      toast.error(parseErrorResponse(error));
      setLoaderStatus(STATUSES.FAILURE);
    } finally {
      if (!anyNewMessages) {
        formRef.current.resetForm();
        setFile(null);
        fetchConversationDetails(selectedConversation.id, false);
      }
    }
  };

  const initialValues = {
    message: '',
    file: '',
    from: getSendMessageType(),
  };

  const optOutConversationTypes = selectedConversation.conversation
    .filter((conversation) => conversation.message_type === CONVERSATION_MESSAGE_TYPE.CONVERSATION_STOPPED)
    .map((conversation) => conversation.data.conversation_type);

  return (
    <FullWidthContainer>
      <ConversationAddNoteButton />
      <FormContainer>
        <Formik
          className="ui segment"
          innerRef={formRef}
          onSubmit={handleSendMessage}
          initialValues={initialValues}
          enableReinitialize
        >
          {({ handleSubmit, setFieldValue, isSubmitting, values }) => {
            const dirty = !!values?.message || !!values?.file;
            const conversationType = LEAD_EVENT_MESSAGE_TYPES[values.from];
            const isDisabled =
              selectedConversation.is_conversation_stopped && optOutConversationTypes.includes(conversationType);

            return (
              <Form onSubmit={handleSubmit}>
                <InputRow>
                  <StyledInputField
                    placeholder="Type a response"
                    onError={scrollIntoView}
                    control={TextArea}
                    name="message"
                    rows={isMobile ? INPUT_FIELD_ROWS.ONE : INPUT_FIELD_ROWS.TWO}
                    required
                    width={16}
                    disabled={isDisabled}
                    maxLength={MAX_MESSAGE_LENGTH}
                  />

                  <ActionsContainer>
                    <If condition={!isSmallMobile}>
                      <ConversationMessageComposerSendOptions />
                      <ConversationMessageComposerReminder disabled={isDisabled} />
                    </If>

                    <Actions>
                      <InputMaxLength className="limit" value={values.message} limit={MAX_MESSAGE_LENGTH} hideEmpty />
                      <Button
                        icon
                        type="button"
                        onClick={handleClick}
                        circular
                        className="raised"
                        disabled={isDisabled}
                      >
                        <Icon size="22" name="attach_file" variant="outlined" color="var(--light-text)" />
                      </Button>
                      <Button
                        color="green"
                        type="submit"
                        className="pill"
                        disabled={!dirty || isSubmitting || isDisabled}
                      >
                        <span>Send</span>
                        <Icon size="22" name="send" variant="outlined" />
                      </Button>
                      <InvisibleInput
                        name="file"
                        type="file"
                        ref={hiddenFileInput}
                        onChange={(e) => handleChange(e, setFieldValue)}
                        accept=".pdf,.jpg,.jpeg,.gif,.png,"
                      />
                    </Actions>
                  </ActionsContainer>
                </InputRow>
                <If condition={file}>
                  <AttachmentContainer>
                    <Icon name={getFileType(file?.name)} variant="outlined" size="24" />
                    <span>{file?.name}</span>
                    <Icon
                      name="delete"
                      variant="outlined"
                      color="red"
                      size="24"
                      onClick={() => removeAttachment(setFieldValue)}
                    />
                  </AttachmentContainer>
                </If>
              </Form>
            );
          }}
        </Formik>

        <LeadMessagesNotificationModal open={openConfirmSend} onClickHandle={handleCloseMessagesModal} />
      </FormContainer>
    </FullWidthContainer>
  );
};
