import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Dimmer, Loader, Modal } from 'components/lib';
import { getBusinessActivityDetails } from 'apis';
import { STATUSES } from '../../../constants';
import { ACTION_TYPE_NAME } from './constants';
import BusinessActivityChangeModalContent from './BusinessActivityChangeModalContent';

const ModalContent = styled(Modal.Content)`
  &&&& {
    padding: 30px;
    min-height: 200px;
  }
`;

const ModalTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 12px;
`;

export const BusinessActivityChangeModal = ({ businessActivityId, onClose }) => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(STATUSES.IDLE);
  const [businessActivity, setBusinessActivity] = useState();

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoader(STATUSES.LOADING);
        const result = await getBusinessActivityDetails(businessActivityId);

        setBusinessActivity(result);
        setLoader(STATUSES.LOADED);
      } catch (e) {
        setLoader(STATUSES.FAILURE);
      }
    };

    if (businessActivityId) {
      fetch();
      setOpen(true);
    } else {
      setBusinessActivity(null);
      setLoader(STATUSES.IDLE);
      setOpen(false);
    }
  }, [businessActivityId]);

  const handleClose = () => {
    setBusinessActivity(null);
    setLoader(STATUSES.IDLE);
    onClose();
  };

  return (
    <Modal size="tiny" closeIcon open={open} onClose={handleClose}>
      <Dimmer active={loader === STATUSES.LOADING} inverted>
        <Loader active inverted />
      </Dimmer>
      <ModalContent scrolling>
        {businessActivity && (
          <>
            <ModalTitle>{ACTION_TYPE_NAME[businessActivity.actionType]}</ModalTitle>
            <BusinessActivityChangeModalContent businessActivity={businessActivity} />
          </>
        )}
      </ModalContent>
      <Modal.Actions>
        <Button basic onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
