import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ConversationContext } from 'components/conversation/ConversationContext';
import { Radio } from 'components/lib';
import { CONVERSATION_ACTIONS } from './constants';

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:first-child) {
    margin-left: 30px;
  }
  > span {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 6px;
  }
`;

const Container = styled.div``;

const Text = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
`;

const NewCalendar = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--link);
  cursor: pointer;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--line-standard);
  position: absolute;
  bottom: -10px;
`;

export const ConfirmCalendarSelector = () => {
  const { calendars, selectedCalendar, setSelectedCalendar, showModal } = useContext(ConversationContext);
  const [calendarOptions, setCalendarOptions] = useState([]);

  useEffect(() => {
    const options = calendars?.map((calendar) => ({
      key: calendar.id,
      value: calendar.id,
      text: calendar.email,
    }));

    setCalendarOptions(options);
  }, [calendars]);

  const handleCalendarChange = (value) => {
    setSelectedCalendar(value);
  };

  const handleOnNewCalendarClick = () => {
    showModal(CONVERSATION_ACTIONS.NEW_CALENDAR);
  };
  return (
    <Container>
      <TextWrapper>
        <Text>Select a calendar for this event:</Text>
        <NewCalendar onClick={handleOnNewCalendarClick}>+ New Calendar</NewCalendar>
        <Divider />
      </TextWrapper>
      {calendarOptions.map((calendar) => (
        <RadioWrapper key={calendar.key}>
          <CustomRadio>
            <Radio
              name="calendaer"
              value={calendar.id}
              checked={selectedCalendar === calendar.value}
              onChange={() => handleCalendarChange(calendar.value)}
              disabled={calendarOptions.length === 1}
            />
            <span>{calendar.text}</span>
          </CustomRadio>
        </RadioWrapper>
      ))}
    </Container>
  );
};
