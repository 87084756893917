import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Icon, TableHeaderCell } from 'semantic-ui-react';

import { Message, Pagination, Table, StyledTable } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { getCRMLogsLeadsDetails } from 'apis';
import CRMAdminLogsLeadsModal from './CRMAdminLogsLeadsModal';

const LeftPaddedContent = styled.div`
  padding-left: 20px;
`;

const RightPaddedContent = styled.div`
  padding-right: 20px;
`;

const SmallText = styled.span`
  font-size: 12px;
  color: #666;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-right: 20px;
`;

const TableWrapper = styled.div`
  cursor: pointer;
`;

const StyledMessage = styled(Message)`
  && {
    margin: 0 auto;
    max-width: calc(100% - 60px);
  }
`;

const CRMAdminLogsLeadsTable = ({ leads, activePage, totalPages, onPageChange }) => {
  const [selectedLead, setSelectedLead] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rawResult, setRawResult] = useState('');

  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  const fetchLeadDetails = useCallback(async (leadId) => {
    try {
      const leadDetails = await getCRMLogsLeadsDetails(leadId);
      setSelectedLead(leadDetails);
      setRawResult(leadDetails.rawResult);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch lead details', error);
    }
  }, []);

  const handleRowClick = (lead) => {
    fetchLeadDetails(lead.id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLead(null);
    setRawResult('');
  };

  if (!leads) {
    return null;
  }

  if (leads.length === 0) {
    return (
      <StyledMessage warning icon="users" content="No CRM logs matching selected filters. Please change filters" />
    );
  }

  return (
    <>
      <TableWrapper>
        <StyledTable basic selectable striped>
          <Table.Header>
            <Table.Row>
              <TableHeaderCell>
                <LeftPaddedContent>LEAD</LeftPaddedContent>
              </TableHeaderCell>
              <TableHeaderCell>COMMUNITY</TableHeaderCell>
              <TableHeaderCell>CRM TYPE</TableHeaderCell>
              <TableHeaderCell>PARSABLE</TableHeaderCell>
              <TableHeaderCell>VALID</TableHeaderCell>
              <TableHeaderCell textAlign="right">
                <RightPaddedContent>CREATED ON</RightPaddedContent>
              </TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {leads.map((lead) => (
              <Table.Row key={lead?.id} onClick={() => handleRowClick(lead)}>
                <Table.Cell>
                  <LeftPaddedContent>
                    <VerticalContainer>
                      <span>{`${lead?.lead?.firstName ?? ''} ${lead?.lead?.lastName ?? ''}`}</span>
                      <SmallText>{`${lead?.lead?.email ?? '-'} · ${lead?.lead?.phone ?? '-'}`}</SmallText>
                    </VerticalContainer>
                  </LeftPaddedContent>
                </Table.Cell>
                <Table.Cell>
                  <VerticalContainer>
                    <span>{lead?.community?.name ?? '-'}</span>
                    <SmallText>{lead?.user?.email ?? '-'}</SmallText>
                  </VerticalContainer>
                </Table.Cell>
                <Table.Cell>{lead?.crmType?.name ?? '-'}</Table.Cell>
                <Table.Cell>
                  {lead?.isParsable ? (
                    <Icon size="normal" name="check circle" color="green" />
                  ) : (
                    <Icon size="normal" name="times circle" color="red" />
                  )}
                </Table.Cell>
                <Table.Cell>
                  {lead?.isValid === true ? (
                    <Icon size="normal" name="check circle" color="green" />
                  ) : lead?.isValid === false ? (
                    <Icon size="normal" name="times circle" color="red" />
                  ) : (
                    '-'
                  )}
                </Table.Cell>

                <Table.Cell textAlign="right">
                  <RightPaddedContent> {formatAnyDate(lead?.createdOn, 'MMMM d, yyyy, h:mm a')}</RightPaddedContent>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </StyledTable>
      </TableWrapper>
      <Footer>
        <Pagination activePage={activePage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Footer>

      <CRMAdminLogsLeadsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        leadDetails={selectedLead}
        rawResult={rawResult}
      />
    </>
  );
};

export default CRMAdminLogsLeadsTable;
