import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';

export const SalesAssistantNotesModal = ({ text, handleNotesSave, openModal, setIsModalOpen }) => {
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (text) {
      setNotes(text);
    }
  }, [text]);

  const handleChange = (e, { value }) => setNotes(value);

  const onSaveNotes = () => {
    handleNotesSave(notes);
  };

  const resetChanges = () => {
    setNotes(text);
    setIsModalOpen(false);
  };

  return (
    <Modal open={openModal} onClose={resetChanges}>
      <Modal.Header>Messaging Assistant Notes</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.TextArea
            label="Notes"
            placeholder="Edit Messaging Assistant Notes..."
            value={notes}
            onChange={handleChange}
            rows={16}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={resetChanges}>
          Cancel
        </Button>
        <Button content="Save" labelPosition="right" icon="save" onClick={onSaveNotes} positive />
      </Modal.Actions>
    </Modal>
  );
};
