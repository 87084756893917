import React from 'react';
import { Message, Pagination, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import TableCount from 'components/TableCount';
import { If } from 'components/If';
import { formatDuration, formatPercent, formatPercentNumber } from 'components/utils';
import { CsvExport } from 'components/common';
import { capitalize } from 'utils/helpers';

const TeamResponseStatisticsTable = ({
  items,
  totalCount,
  page,
  totalPages,
  onPageChange,
  onSortChange,
  exportAsCSV,
  sortKey,
  sortReverse,
}) => {
  const { t } = useTranslation();

  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  if (!items) {
    return null;
  }

  if (!items.length) {
    return <Message>No {t('communities')} with messages found</Message>;
  }

  const sortDirection = sortReverse ? 'descending' : 'ascending';

  return (
    <Table striped sortable>
      <Table.Header>
        <Table.Row textAlign="center">
          <Table.HeaderCell textAlign="left">Name</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortKey === 'messageRepliesCount' ? sortDirection : undefined}
            onClick={() => onSortChange('messageRepliesCount')}
          >
            Responses From {capitalize(t('lead'))}
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortKey === 'messageResponseRate' ? sortDirection : undefined}
            onClick={() => onSortChange('messageResponseRate')}
          >
            Sales Team Response Rate
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortKey === 'avgFirstResponseTime' ? sortDirection : undefined}
            onClick={() => onSortChange('avgFirstResponseTime')}
          >
            Average First Response Time
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortKey === 'avgResponseTimePerMessage' ? sortDirection : undefined}
            onClick={() => onSortChange('avgResponseTimePerMessage')}
          >
            Average Response Time Per Message
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((i) => (
          <Table.Row key={i.id} textAlign="center">
            <Table.Cell textAlign="left">{i.name}</Table.Cell>
            <Table.Cell>{i.messageRepliesCount}</Table.Cell>
            <Table.Cell>{formatPercentNumber(i.messageResponseRate)}</Table.Cell>
            <Table.Cell>{i.avgFirstResponseTime ? formatDuration(i.avgFirstResponseTime) : '-'}</Table.Cell>
            <Table.Cell>{i.avgResponseTimePerMessage ? formatDuration(i.avgResponseTimePerMessage) : '-'}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="1">
            <TableCount current={items.length} total={totalCount} />
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="3" textAlign="right">
            {totalPages > 1 && <Pagination activePage={page} onPageChange={handlePageChange} totalPages={totalPages} />}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="3" textAlign="right">
            <If condition={items?.length > 0}>
              <CsvExport content="CSV" exportAction={exportAsCSV} skipDownload />
            </If>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default TeamResponseStatisticsTable;
