import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { RouterPrompt } from 'components/RouterPrompt';
import { usePreventUnload } from './usePreventUnload';

export const UnloadPrompt = ({ children }) => {
  const [, setDirty] = usePreventUnload();
  const { dirty, isSubmitting } = useFormikContext();

  useEffect(() => {
    setDirty(dirty && !isSubmitting);
  }, [dirty, setDirty]);

  return (
    <>
      {children}
      <RouterPrompt when={dirty && !isSubmitting} />
    </>
  );
};
