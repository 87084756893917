import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { getCampaignProgress } from 'apis';
import { useCampaigns } from 'components/nurture/Campaigns/CampaignsContext';
import { Modal as ComponentsModal } from 'components/lib';
import { useCampaignsTemplate } from '../CampaignsTemplateContext';
import { CreatingAnimation } from './CreatingAnimation';

const Modal = styled(ComponentsModal)`
  && {
    &.tiny,
    &.large {
      width: 540px;
      height: 400px;
    }
  }
`;

export const CreatingCampaignsModal = ({ open, source }) => {
  const { state, dispatch, actions } = useCampaignsTemplate();
  const { buildRequestId } = state;
  const { fetchCampaigns } = useCampaigns();
  const [progress, setProgress] = useState({ current: 0, total: 1 });
  const timer = useRef(null);

  const pingForProgress = async (buildRequestId) => {
    const interval = 10000; // 10 seconds

    const fetchProgress = async () => {
      try {
        const progressData = await getCampaignProgress(buildRequestId);
        setProgress({
          current: progressData.completedTemplates + 1,
          total: progressData.totalTemplates + 1,
        });
        if (progressData.isExecuted) {
          setProgress({
            current: progressData.completedTemplates + 1,
            total: progressData.totalTemplates + 1,
          });
          timer.current = setTimeout(() => {
            toast.success('Campaign created successfully');
            fetchCampaigns();
            dispatch({ type: actions.SET_RESET_STATE });
          }, 1000);
        } else {
          timer.current = setTimeout(fetchProgress, interval);
        }
      } catch (error) {
        dispatch({ type: actions.SET_RESET_STATE });
        toast.error('Error creating campaign');
      }
    };

    fetchProgress();
  };

  useEffect(() => {
    if (buildRequestId) {
      pingForProgress(buildRequestId);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [buildRequestId]);

  return (
    <Modal open={open} size="large">
      <Modal.Content>
        <CreatingAnimation visible current={progress.current} total={progress.total} source={source} />
      </Modal.Content>
    </Modal>
  );
};
