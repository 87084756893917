import humps from 'humps';

import { API } from '../api';

export const getEmailIntegrations = async () => {
  const response = await API.get('api/chat/email-integrations');
  return response.data;
};

export const deleteEmailIntegration = async (id) => {
  const response = await API.delete(`api/chat/email-integrations/${id}`);
  return response.data;
};

export const resolveEmailProvider = async (data) => {
  const response = await API.post('api/chat/email-integrations/resolve-email-provider', data);
  return response.data;
};

export const setFurtherProvider = async (params) => {
  const response = await API.post('api/chat/email-integrations/connect/further', params);
  return response.data;
};

export const getIntegrationsCommunities = async (params) => {
  const response = await API.get('api/chat/email-integrations/communities', { params });
  return response.data;
};

export const updateIntegrationCommunity = async (id, data) => {
  const response = await API.put(`api/chat/email-integrations/communities/${id}`, data);
  return response.data;
};

export const removeIntegrationCommunity = async (id) => {
  const response = await API.delete(`api/chat/email-integrations/communities/${id}`);
  return response.data;
};

export const getSettingsEmailIntegrations = async (params) => {
  const response = await API.get('api/chat/email-integrations', { params });
  return humps.camelizeKeys(response.data);
};

export const updateEmailIntegrationCommunities = async (data) => {
  const response = await API.post(`/api/chat/email-integrations/communities`, data);
  return response.data;
};

export const settingsEmailIntegrationsUnnasignCommunities = async (id, data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.delete(`api/chat/email-integrations/${id}/communities`, requestData);
  return response.data;
};

export const getDomains = async () => {
  const response = await API.get('api/chat/email-integrations/domains');
  return humps.camelizeKeys(response.data);
};

export const addDomain = async (data) => {
  const response = await API.post('api/chat/email-integrations/domains', data);
  return response.data;
};

export const addDomainEmails = async (domainId, data) => {
  const response = await API.post(`api/chat/email-integrations/domains/${domainId}/emails/bulk`, data);
  return response.data;
};

export const createDefaultEmailSubdomain = async () => {
  const response = await API.post('api/chat/email-integrations/create-default-subdomain');
  return humps.camelizeKeys(response.data);
};

export const resendInvites = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.post('api/chat/email-integrations/resend-invites', requestData);
  return response.data;
};

export const getRelatedCommunities = async (id) => {
  const response = await API.get(`api/chat/email-integrations/${id}/communities`);
  return humps.camelizeKeys(response.data);
};

export const getIntegrationCheck = async (params = {}) => {
  const response = await API.get(`api/chat/email-integrations/invite?`, { params: humps.decamelizeKeys(params) });
  return humps.camelizeKeys(response.data);
};
