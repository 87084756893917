import React from 'react';
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { useUnitLevelPricing } from './context';
import UnitLevelPricingStepper from './UnitLevelPricingStepper';

const UnitLevelPricingContent = () => {
  const { t } = useTranslation();
  const [{ invitation }] = useUnitLevelPricing();

  if (!invitation) {
    return null;
  }

  if (invitation.has_unit_level_price) {
    return (
      <Message
        warning
        icon="warning circle"
        header={`Unit level prices for this ${t('community')} already created!`}
        content="Please contact our customer support for more information."
      />
    );
  }

  return <UnitLevelPricingStepper />;
};

export default UnitLevelPricingContent;
