import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Checkbox } from 'components/lib';
import { useSettings } from '../context/SettingsContext';
import { setSelectedPages } from '../context/actions';

const TableWrapper = styled.div`
  margin-top: 20px;
`;

const TableNameWrapper = styled.div`
  display: flex;
  align-items: center;
  > div:first-child {
    margin-left: 15px;
  }
`;

const TableName = styled.div`
  font-family: var(--font-family-bold);
  font-size: 12px;
  letter-spacing: 0.5px;
  color: var(--text);
  margin-left: 15px;
  padding-top: 3px;
  text-transform: uppercase;
`;

const SettingName = styled.span`
  font-family: var(--font-family-bold);
  font-size: 12px;
  letter-spacing: 0.5px;
  text-align: right;
  color: var(--text);
  text-transform: uppercase;
  margin-left: auto;
  padding-right: 20px;
`;

export const SettingsTable = ({ children, currentPage, tableName, settingName, items }) => {
  const [{ isMultipleSelection, selectedPages }, dispatch] = useSettings();
  const handleOnChange = () => {
    dispatch(setSelectedPages({ currentPage, items }));
  };
  return (
    <TableWrapper>
      <TableNameWrapper>
        <If condition={isMultipleSelection}>
          <Checkbox checked={selectedPages?.includes(currentPage)} onChange={handleOnChange} />
        </If>
        <TableName>{tableName}</TableName>
        <If condition={!isMultipleSelection}>
          <SettingName>{settingName}</SettingName>
        </If>
      </TableNameWrapper>
      {children}
    </TableWrapper>
  );
};
