import React, { useEffect } from 'react';
import { useParams, Switch, Route, useHistory, useLocation } from 'react-router-dom';

import { Grid, Loader, Dimmer } from 'components/lib';
import { CommunitiesMenu } from './CommunitiesMenu';
import { ModuleRecordings } from './ModuleRecordings';
import { SelectCommunityBox } from './SelectCommunityBox';
import { useModuleRecordings } from './ModuleRecordingsContext';

export const ChatModuleRecordings = () => {
  const { chatId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();

  const { state } = useModuleRecordings();
  const { communities, isLoadingCommunities } = state;

  useEffect(() => {
    if (!pathname.includes('/communities') && communities?.length > 0) {
      history.push(`/chats/${chatId}/module-recordings/communities/${communities[0].id}`);
    }
  }, [communities]);

  return (
    <>
      <Dimmer active={isLoadingCommunities} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <Grid>
        <Grid.Row>
          <Switch>
            <Route exact path="/chats/:chatId/module-recordings">
              <Grid.Column width={4}>
                <CommunitiesMenu />
              </Grid.Column>
              <Grid.Column width={12}>
                <SelectCommunityBox />
              </Grid.Column>
            </Route>
            <Route path="/chats/:chatId/module-recordings/communities/:communityId">
              <Grid.Column width={4}>
                <CommunitiesMenu />
              </Grid.Column>
              <Grid.Column width={12}>
                <ModuleRecordings />
              </Grid.Column>
            </Route>
          </Switch>
        </Grid.Row>
      </Grid>
    </>
  );
};
