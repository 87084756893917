import { API } from '../api';

export const getUnitLevelPriceInvite = async (token) => {
  const response = await API.get(`/api/unit-level-pricing/invites/${token}`);
  return response.data;
};

export const createUnitLevelPriceDataModel = async (token, data) => {
  const response = await API.post(`/api/unit-level-pricing/invites/${token}/data-model`, data);
  return response.data;
};
