import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Confirm } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { deleteIA } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES } from '../../../constants';
import { InstantAnswersContext } from './InstantAnswersContext';

export const InstantAnswersDeleteConfirm = () => {
  const { t } = useTranslation();
  const { community_id } = useParams();
  const { showConfirmDeleteIA, setShowConfirmDeleteIA, setLoaderStatus } = useContext(InstantAnswersContext);

  const history = useHistory();

  const onDeleteIA = async () => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      await deleteIA(community_id);

      toast.success('Answers deleted successfully');
      setLoaderStatus(STATUSES.LOADED);
      history.push('/settings/ia');
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e, 'Error deleting answers. Please try again later, or contact us for help.'));
      setLoaderStatus(STATUSES.FAILURE);
    } finally {
      setShowConfirmDeleteIA(false);
    }
  };

  const onDeleteIACancel = () => {
    setShowConfirmDeleteIA(false);
  };

  return (
    <Confirm
      content={`Are you sure you want to remove ALL instant answers for this ${t(
        'community'
      )}? You cannot undo this action.`}
      cancelButton="NO! Take me back"
      confirmButton="YES! I'm sure"
      open={showConfirmDeleteIA}
      onCancel={onDeleteIACancel}
      onConfirm={onDeleteIA}
    />
  );
};
