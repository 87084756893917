import React from 'react';
import { Popup } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageSection } from 'components/common';
import { formatCount, formatPercent } from 'components/utils';
import { getEngagement } from 'apis';
import { toast } from 'react-toastify';
import { parseErrorResponse } from 'apis/utils';
import queryConstants from 'utils/queries/constants';
import { capitalize, queryStatusToLoaderStatus } from 'utils/helpers';
import { ANALYTIC_ITEM_DESCRIPTIONS } from '../constants';
import { useItemDescription } from '../hooks';
import { Metric } from '../common';

const EngagementMetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  font-weight: normal;
`;

const LeadsPopupContainer = styled.div`
  font-weight: normal;
`;

const PopupStyle = {
  width: '270px',
};

const LeadsCountPopup = ({ vsaLeads, webFormLeads, conversationalWebformLeads, conversationalIVRLeads }) => {
  const { description } = useItemDescription();
  const { t } = useTranslation();

  if (!webFormLeads && !conversationalWebformLeads) {
    return description(ANALYTIC_ITEM_DESCRIPTIONS.NEW_LEADS);
  }
  const leadTypeBreakdown = [
    <li key="vsa">
      VSA {capitalize(t('leads'))}: {vsaLeads}
    </li>,
  ];
  if (webFormLeads) {
    leadTypeBreakdown.push(
      <li key="wf">
        Web Form {capitalize(t('leads'))}: {webFormLeads}
      </li>
    );
  }
  if (conversationalWebformLeads) {
    leadTypeBreakdown.push(
      <li key="evsa">
        Conversational Webform {capitalize(t('leads'))}: {conversationalWebformLeads}
      </li>
    );
  }
  if (conversationalIVRLeads) {
    leadTypeBreakdown.push(
      <li key="ivr">
        Conversational IVR {capitalize(t('leads'))}: {conversationalIVRLeads}
      </li>
    );
  }
  return (
    <div>
      <LeadsPopupContainer>
        <div style={{ width: '270px' }}>
          {description(ANALYTIC_ITEM_DESCRIPTIONS.NEW_LEADS)}
          {leadTypeBreakdown}
        </div>
      </LeadsPopupContainer>
    </div>
  );
};

const Engagement = ({ filters, publicId }) => {
  const { t } = useTranslation();
  const { description } = useItemDescription();
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.ENGAGEMENT, { publicId, filters }],
    () => getEngagement({ public_id: publicId, filters }),
    { placeholderData: [] }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch engagement data'));
  }

  return (
    <PageSection loaderStatus={loaderStatus}>
      <EngagementMetricsContainer>
        <Popup
          position="top left"
          content={description(ANALYTIC_ITEM_DESCRIPTIONS.NEW_WEBSITE_VISITOR)}
          trigger={
            <div>
              <Metric label="Visitors" value={formatCount(data?.visitors)} />
            </div>
          }
        />
        <Popup
          position="top center"
          content={description(ANALYTIC_ITEM_DESCRIPTIONS.VSA_USERS)}
          trigger={
            <div>
              <Metric
                label="Interaction"
                value={formatCount(data?.interactions)}
                description={data?.interactions && `${formatPercent(data?.interactions, data?.visitors)} of visitors`}
              />
            </div>
          }
        />
        <Popup
          position="top center"
          content={
            <LeadsCountPopup
              vsaLeads={data?.vsa_leads}
              webFormLeads={data?.web_form_leads}
              conversationalWebformLeads={data?.conversational_webform}
              conversationalIVRLeads={data?.conversational_ivr}
            />
          }
          trigger={
            <div>
              <Metric
                label={`${t('leads')}`}
                value={formatCount(data?.leads)}
                description={data?.leads && `${formatPercent(data?.leads, data?.interactions)} of interaction`}
              />
            </div>
          }
          style={PopupStyle}
        />
        <Popup
          position="top center"
          content={description(ANALYTIC_ITEM_DESCRIPTIONS.TOURS_SCHEDULED)}
          trigger={
            <div>
              <Metric
                label={`${t('tours')} Scheduled`}
                value={data?.tours}
                description={data?.tours && `${formatPercent(data?.tours, data?.leads)} of ${t('leads')}`}
              />
            </div>
          }
        />
        <Popup
          position="top right"
          content={description(ANALYTIC_ITEM_DESCRIPTIONS.CONFIRMED_MOVE_INS)}
          trigger={
            <div>
              <Metric
                label={`${t('move_ins')}`}
                value={data?.move_ins}
                description={data?.move_ins && `${formatPercent(data?.move_ins, data?.leads)} of ${t('leads')}`}
              />
            </div>
          }
        />
      </EngagementMetricsContainer>
    </PageSection>
  );
};

export default Engagement;
