import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Button, Modal } from 'components/lib';

const Header = styled.div`
  margin: 29px 0 10px 30px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const Content = styled.div`
  margin: 10px 30px 40px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #89919d;
`;

export const RouterPrompt = ({
  when,
  onConfirm = () => true,
  onCancel = () => false,
  onClose = () => false,
  title = 'Are you sure?',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  message = 'You have unsaved changes. Are you sure you want to leave this page?',
  proceedOnCancel = false,
}) => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const unblockRef = useRef();

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when) {
        setCurrentPath(location.pathname + (location.search || ''));
        setShowPrompt(true);
        return false;
      }
      return true;
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when]);

  const handleProceed = () => {
    if (unblockRef) {
      unblockRef.current();
    }

    setShowPrompt(false);
    history.push(currentPath);
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }

    handleProceed();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }

    if (proceedOnCancel) {
      handleProceed();
    } else {
      setShowPrompt(false);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setShowPrompt(false);
  };

  return (
    <Modal open={showPrompt} closeIcon onClose={handleClose} size="tiny">
      <Header>{title}</Header>
      <Content>{message}</Content>
      <Modal.Actions>
        <Button primary color="blue" onClick={handleConfirm}>
          {confirmText}
        </Button>
        <Button basic onClick={handleCancel}>
          {cancelText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
