import React from 'react';

import { Icon } from 'components/lib';
import { UserCircle } from 'components/UserCircle';
import SupportModeBox from './SupportModeBox';

const SupportModeActive = ({ user, onRemove, loading }) => (
  <SupportModeBox
    leftIcon={<UserCircle user={user} size="20" />}
    description={user.demo_mode ? undefined : 'Now supporting'}
    title={user.demo_mode ? 'Demo user' : user.name || user.email}
    rightIcon={<Icon name="close" color="rgba(255, 255, 255, 0.6)" onClick={() => onRemove()} />}
    loading={loading}
  />
);

export default SupportModeActive;
