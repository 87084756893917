import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { If } from 'components/If';
import { getConnectedCalendarsSettings, updateConnectedCalendarsSettings } from 'apis';
import useDataList from 'utils/hooks/useDataList';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES, PAGINATION, CONNECTED_CALENDARS_VALUES } from '../../../constants';

import {
  SettingsTable,
  SettingsContainer,
  SettingsDropdown,
  SettingsFiltersContainer,
  SettingsHeader,
  SettingsSearch,
  SettingsTableBody,
  SettingsTableToggle,
  SettingsTableRow,
  SettingsPagination,
  SettingsOn,
  SettingsOff,
  SettingsTableRowContainer,
  SettingsBooleanContainer,
  SettingsModal,
  SettingsSelectedCountModal,
  SettingsModalHeader,
  SettingsModalActions,
  CommunityRow,
} from '../common';
import { useSettings } from '../context/SettingsContext';
import { resetState, setSelectedItem } from '../context/actions';
import ConnectedCalendarsModalBody from './ConnectedCalendarsModalBody';
import { getUrlFilterAndSearch } from '../common/constants';

const dropdownOptions = [
  { key: '1', text: 'Any status', value: null },
  { key: '2', text: CONNECTED_CALENDARS_VALUES.OFF.text, value: CONNECTED_CALENDARS_VALUES.OFF.dropdownValue },
  { key: '3', text: CONNECTED_CALENDARS_VALUES.ENHANCED.text, value: CONNECTED_CALENDARS_VALUES.ENHANCED.value },
  { key: '4', text: CONNECTED_CALENDARS_VALUES.BASIC.text, value: CONNECTED_CALENDARS_VALUES.BASIC.value },
];

const ConnectedCalendars = () => {
  const { urlFilterValue, urlSearchValue } = getUrlFilterAndSearch({ flagName: 'connectedCalendar' });
  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters, totalItemsCount } = useDataList({
    initialState: {
      filters: {
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.PAGE_SIZE,
        search: urlSearchValue,
        connectedCalendar: urlFilterValue,
      },
      setUrlParams: true,
    },
  });
  const [{ isMultipleSelection, selectedItems, excludedItems }, dispatch] = useSettings();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const fetchCommunities = useCallback(
    async ({ page, pageSize, search, connectedCalendar }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await getConnectedCalendarsSettings({
          page,
          page_size: pageSize,
          search,
          connectedCalendar,
        });
        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch data');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchCommunities(filters);
  }, [fetchCommunities, filters]);

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
  };

  const handleDropdownChange = (e, { value }) => {
    setFilters({ connectedCalendar: value });
    dispatch(resetState());
  };

  const handleOnSearch = (value) => {
    setSearch(value);
    dispatch(resetState());
  };

  const handleOnRowClick = (data) => {
    if (!isMultipleSelection) {
      setIsModalOpen(true);
      dispatch(setSelectedItem(data));
    }
  };

  const handleOnModalClose = (resetForm) => {
    setIsModalOpen(false);
    resetForm();
    dispatch(resetState());
  };

  const handleOnSelectedCountModalClick = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);
    const data = {
      includeCommunities: selectedItems,
      excludeCommunities: excludedItems,
      connectedCalendar: !values.connectedCalendar ? null : values.connectedCalendar,
      includeAllSalesEmailsInCalendarInvite: values.includeAllSalesEmailsInCalendarInvite,
      users: [...values.users, ...values.usersToRemove],
    };

    try {
      await updateConnectedCalendarsSettings(data);
      toast.success('Settings updated successfully!');
    } catch (error) {
      const errorText = 'Trouble updating settings!';
      const msg = parseErrorResponse(error, errorText);
      console.error(msg, error?.response);
      toast.error(msg);
      setLoaderStatus(STATUSES.FAILURE);
    } finally {
      handleOnModalClose(resetForm);
      dispatch(resetState());
      setLoaderStatus(STATUSES.LOADED);
      setFilters({
        page: PAGINATION.PAGE,
        page_size: PAGINATION.PAGE_SIZE,
        search: null,
        connectedCalendar: null,
      });
    }
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const connectedCalendar =
    selectedItems.length === 1 ? items?.find((item) => item.id === selectedItems[0])?.connectedCalendar : '';
  const initialValues = {
    connectedCalendar,
    includeAllSalesEmailsInCalendarInvite:
      selectedItems.length === 1
        ? items?.find((item) => item.id === selectedItems[0])?.includeAllSalesEmailsInCalendarInvite
        : false,
    users: [],
    usersToRemove: [],
    sendReminders: false,
    connectedCalendarCurrentValue: connectedCalendar,
  };

  return (
    <SettingsContainer loading={loading} totalItemsCount={totalItemsCount}>
      <If condition={!isModalOpen}>
        <SettingsSelectedCountModal
          totalItemsCount={totalItemsCount}
          items={items}
          filters={filters}
          totalPages={totalPages}
          onClick={handleOnSelectedCountModalClick}
        />
      </If>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
        {({ dirty, touched, handleSubmit, values, resetForm, ...otherProps }) => (
          <SettingsModal
            loading={loading}
            open={isModalOpen}
            onModalClose={() => handleOnModalClose(resetForm)}
            size={values.connectedCalendar === CONNECTED_CALENDARS_VALUES.ENHANCED.value ? 'large' : 'tiny'}
            width={values.connectedCalendar === CONNECTED_CALENDARS_VALUES.ENHANCED.value ? 1000 : 540}
            height={502}
          >
            <SettingsModalHeader
              title="Connected calendars"
              community={selectedItems.length === 1 ? items?.find((item) => item.id === selectedItems[0]) : null}
              totalItemsCount={totalItemsCount}
              type="header"
            />
            <ConnectedCalendarsModalBody type="body" values={values} {...otherProps} />
            <SettingsModalActions
              onSave={handleSubmit}
              onCancel={() => handleOnModalClose(resetForm)}
              type="actions"
              disabled={!dirty || !touched}
              saveText={values.users.length > 0 ? 'Save & Send Reminders' : 'Save'}
            />
          </SettingsModal>
        )}
      </Formik>
      <SettingsHeader>
        <SettingsTableToggle />
        <SettingsFiltersContainer>
          <SettingsDropdown
            options={dropdownOptions}
            value={filters.connectedCalendar}
            onChange={handleDropdownChange}
            placeholder="Any status"
          />
          <SettingsSearch
            onSearch={handleOnSearch}
            value={filters.search}
            placeholder={`Search ${t('communities')}`}
            loading={loading}
          />
        </SettingsFiltersContainer>
      </SettingsHeader>
      <SettingsTable tableName={`${t('community')}`} currentPage={filters.page} items={items}>
        <SettingsTableBody>
          {items?.map((item, i) => (
            <SettingsTableRow
              item={item}
              key={i}
              onClick={() => handleOnRowClick({ id: item.id, items, currentPage: filters.page })}
              items={items}
              currentPage={filters.page}
            >
              <SettingsTableRowContainer>
                <CommunityRow name={item.name} picture={item.picture_url} clickable={!isMultipleSelection} />
                <If condition={!isMultipleSelection}>
                  <SettingsBooleanContainer>
                    <If condition={item?.connectedCalendar === CONNECTED_CALENDARS_VALUES.OFF.value}>
                      <SettingsOff />
                    </If>
                    <If condition={item?.connectedCalendar === CONNECTED_CALENDARS_VALUES.ENHANCED.value}>
                      <SettingsOn text={CONNECTED_CALENDARS_VALUES.ENHANCED.text} />
                    </If>
                    <If condition={item?.connectedCalendar === CONNECTED_CALENDARS_VALUES.BASIC.value}>
                      <SettingsOn text={CONNECTED_CALENDARS_VALUES.BASIC.text} color="#f6bb5f" bold={false} />
                    </If>
                  </SettingsBooleanContainer>
                </If>
              </SettingsTableRowContainer>
            </SettingsTableRow>
          ))}
        </SettingsTableBody>
      </SettingsTable>
      <SettingsPagination filters={filters} totalPages={totalPages} onPageChange={handlePageChange} />
    </SettingsContainer>
  );
};

export default ConnectedCalendars;
