import React, { useContext } from 'react';
import styled from 'styled-components';
import { utcToZonedTime } from 'date-fns-tz';

import { MessageContext } from '../MessageContext';
import { TourDateTimeContainer } from './TourDateTimeContainer';
import { CALENDAR_REQUEST_STATUS, CALENDAR_REQUEST_TYPE } from './constants';
import { CalendarRequestNote } from './CalendarRequestNote';
import { CalendarRequestReminder } from './CalendarRequestReminder';
import { CalendarRequestContext } from './CalendarRequests';
import { If } from '../../../If';

const Container = styled.div`
  border: 1px solid var(--line-standard);
  border-radius: 5px;
  min-height: 200px;
  background-color: #f3f6fc;
  padding: 10px 25px;
`;

export const CalendarRequestInfo = () => {
  const { lead } = useContext(MessageContext);
  const { type } = useContext(CalendarRequestContext);

  const request = type === CALENDAR_REQUEST_TYPE.TOUR ? lead.tour : lead.call;

  const requestDate = utcToZonedTime(request?.start, lead.community.timezone);
  const requestType = type === CALENDAR_REQUEST_TYPE.TOUR ? 'TOUR' : ' CALL';
  const requestStatus = request.confirmed
    ? CALENDAR_REQUEST_STATUS.CONFIRMED
    : request.cancelled
    ? CALENDAR_REQUEST_STATUS.CANCELLED
    : CALENDAR_REQUEST_STATUS.REQUESTED;

  const showRequestReminder = requestStatus === CALENDAR_REQUEST_STATUS.REQUESTED;

  return (
    <Container>
      <TourDateTimeContainer label={`${requestType} ${requestStatus.toUpperCase()}`} date={requestDate} />
      <If condition={showRequestReminder}>
        <CalendarRequestReminder />
      </If>
      <CalendarRequestNote />
    </Container>
  );
};
