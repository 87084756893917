import React from 'react';
import styled from 'styled-components';

import { OnboardingRoutes } from '.';

const OnboardingWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  align-items: center;
  height: fit-content;
`;

export const Onboarding = () => {
  return (
    <OnboardingWrapper>
      <OnboardingRoutes />
    </OnboardingWrapper>
  );
};
