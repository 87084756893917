import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { ConversationContext } from '../ConversationContext';
import { DetailsRow } from './helpers';
import ConversationLeadStatus from '../ConversationLeadStatus';
import ConversationCRMTourRequested from '../ConversationLeadCRMTourRequested';
import ConversationReminderSet from '../ConversationReminderSet';
import { LEAD_STATUS_STYLE } from '../constants';

export const GeneralDetails = () => {
  const { t } = useTranslation();
  const { selectedConversation } = useContext(ConversationContext);

  if (!selectedConversation?.details) {
    return null;
  }

  return (
    <>
      <DetailsRow>
        <span>Status</span>
        <ConversationLeadStatus lead={selectedConversation} style={LEAD_STATUS_STYLE.BOLD} />
      </DetailsRow>
      <If condition={selectedConversation.tour_date}>
        <DetailsRow>
          <span>Tour Requested in CRM</span>
          <ConversationCRMTourRequested lead={selectedConversation} />
        </DetailsRow>
      </If>
      <If condition={selectedConversation.reminder && selectedConversation.reminder.scheduled_at}>
        <DetailsRow>
          <span>Reminder Set</span>
          <ConversationReminderSet lead={selectedConversation} />
        </DetailsRow>
      </If>
    </>
  );
};
