import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Form, useFormikContext } from 'formik';

import { Button, Input } from 'components/lib';
import { InputField } from 'components/common';

import { OnboardingContext } from '../OnboardingContext';
import { NextStep } from './NextStep';

const WebsiteInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 16px;
  width: 100%;
  & > label {
    flex: 1 0 auto;
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: 1.14;
    text-align: center;
    color: #222;
    margin-bottom: 10px;
  }
  > div {
    max-width: 350px;
    &:first-of-type {
      margin-bottom: 30px;
    }
  }
`;

const FullWidthForm = styled(Form)`
  width: 100%;
`;

export const WelcomeForm = ({ disabled, recaptchaVerified }) => {
  const { values, isValid } = useFormikContext();

  const isSubmitDisabled = disabled || values.website.length === 0 || !isValid || !recaptchaVerified;

  return (
    <FullWidthForm>
      <WebsiteInputContainer>
        <label>Company name:</label>
        <InputField
          newError
          control={Input}
          fluid
          type="text"
          name="name"
          placeholder="Please enter the name of your company"
          disabled={disabled}
        />
        <label>Paste the address of your website here:</label>
        <InputField
          newError
          control={Input}
          fluid
          type="text"
          name="website"
          placeholder="www.website.com"
          disabled={disabled}
        />
      </WebsiteInputContainer>
      <Button size="big" fluid primary color="blue" type="submit" disabled={isSubmitDisabled}>
        Next Step
      </Button>
      <NextStep disabled={isSubmitDisabled} />
    </FullWidthForm>
  );
};
