import React from 'react';

import { PageHeader, PAGE_HEADER_MARGIN } from 'components/common';
import OnboardingMetrics from './OnboardingMetrics';

const ClientManagement = () => (
  <>
    <PageHeader title="Client Management" subtitle="Onboarding metrics per user" margin={PAGE_HEADER_MARGIN.NONE} />
    <OnboardingMetrics />
  </>
);

export default ClientManagement;
