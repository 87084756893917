import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { humanizeString } from 'components/utils';
import { AnswersPane } from './AnswersPane';
import { IA_TYPES } from '../../../constants';
import { InstantAnswersContext } from './InstantAnswersContext';
import { array_move } from './utils';

export const InstantAnswersAttributeValues = () => {
  const { values, setFieldValue } = useFormikContext();

  const { communityAttributes, activeMenuItem, activeValue, placeholders, setActiveValue } =
    useContext(InstantAnswersContext);

  const orderChange = (dragIndex, hoverIndex) => {
    const tempArray = array_move(activeValue, dragIndex, hoverIndex).slice();
    setActiveValue(tempArray);
    setFieldValue(
      'order',
      tempArray.map((a) => a.id),
      false
    );
  };

  const removeItem = (item) => {
    const tempArray = activeValue.filter((val) => val.id !== item.id);
    setActiveValue(tempArray);
    setFieldValue(
      'order',
      tempArray.map((a) => a.id),
      false
    );
  };

  const addItem = (type) => {
    const tempArray = [
      ...activeValue,
      {
        id: activeValue.length,
        type,
        drag_id: activeValue.length + 1,
        values: [
          {
            index: 0,
            value: '',
          },
        ],
      },
    ];

    setActiveValue(tempArray);

    if (type === IA_TYPES.text) {
      setFieldValue(activeValue.length, '', false);
    } else {
      setFieldValue(activeValue.length, [''], false);
    }
  };

  const activeAttributeLabel = communityAttributes?.find((item) => item.id === activeMenuItem)?.label;

  return (
    <>
      {activeAttributeLabel && <h2>{humanizeString(activeAttributeLabel)}</h2>}
      <AnswersPane
        values={values}
        answers={activeValue}
        placeholders={placeholders}
        onOrderChange={(dragIndex, hoverIndex) => {
          orderChange(dragIndex, hoverIndex);
        }}
        onRemoveItem={(item) => removeItem(item)}
        onSelectPlaceholder={(field, value) => setFieldValue(field, value)}
        addItem={(type) => addItem(type)}
      />
    </>
  );
};
