import React, { useContext, useEffect, useState } from 'react';
import { Link, Switch, Redirect, Route, useLocation } from 'react-router-dom';
import { Button, Dimmer, Loader, Menu, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { useCheckMobileScreen } from 'utils/hooks';
import { Content, PageHeader, PAGE_HEADER_MARGIN } from 'components/common';
import { If } from 'components/If';
import { getAnalyticsReport, getTrafficSourceFilterValues } from 'apis';
import { toast } from 'react-toastify';

import { UserContext } from 'components/UserContext';
import { capitalize } from 'utils/helpers';
import { STATUSES } from '../../constants';
import { useAnalytics } from './context/AnalyticsContext';
import { setReport, setActiveTab, setTrafficSources, setCareTypes, setChannels } from './context/actions';
import CallsTab from './Calls/CallsTab';
import OverviewTab from './Overview/OverviewTab';
import InsightsTab from './Insights/InsightsTab';
import LeadsTab from './Leads/LeadsTab';
import KeywordsTab from './Keywords/KeywordsTab';
import ChatFlowsTab from './ChatFlows/ChatFlowsTab';
import InstantAnswersTab from './InstantAnswers/InstantAnswersTab';
import ReportCommandsBox from './Commands/ReportCommandsBox';
import ReportsTab from './Reports/ReportsTab';
import BenchmarksTab from './Benchmarks/BenchmarksTab';
import AnalyticsFilters from './AnalyticsFilters';
import AnalyticsTabs from './AnalyticsTabs';

const AnalyticsRoot = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [{ filters, publicId, activeTab, report, pageTitle }, dispatch] = useAnalytics();
  const { user, getSessionOriginalUser } = useContext(UserContext);
  const isMobile = useCheckMobileScreen();
  const { title, subtitle } = pageTitle;
  const publicView = !!publicId;

  let prefix = 'stats';
  if (publicView) {
    prefix = `public-report/${publicId}`;
  }
  const reports = [
    {
      key: 'overview',
      path: `/${prefix}/overview`,
      exact: true,
      label: 'Overview',
      component: OverviewTab,
    },
    {
      key: 'insights',
      path: `/${prefix}/insights`,
      exact: true,
      label: 'Insights',
      component: InsightsTab,
    },
    {
      key: 'leads',
      path: `/${prefix}/leads`,
      exact: true,
      label: `${capitalize(t('move_in'))} Prediction`,
      component: LeadsTab,
    },
    {
      key: 'keywords',
      path: `/${prefix}/keywords`,
      exact: true,
      label: 'Keywords',
      component: KeywordsTab,
    },
    {
      key: 'chat-flows',
      path: `/${prefix}/chat-flows`,
      exact: true,
      label: 'Chat Analytics',
      component: ChatFlowsTab,
      filterProps: {
        disabledFilters: ['channels'],
      },
    },
    {
      key: 'instant-answers',
      path: `/${prefix}/instant-answers`,
      exact: true,
      label: 'Instant Answers Analytics',
      component: InstantAnswersTab,
      filterProps: {
        disabledFilters: ['channels'],
      },
    },
    {
      key: 'benchmarks',
      path: `/${prefix}/benchmarks`,
      exact: true,
      label: 'Benchmarks',
      component: BenchmarksTab,
    },
    {
      key: 'calls',
      path: `/${prefix}/calls`,
      exact: true,
      label: 'Call Analytics',
      component: CallsTab,
      filterProps: {
        disabledFilters: ['channels'],
      },
    },
  ];

  if (!publicView) {
    reports.push({
      key: 'saved-reports',
      path: `/${prefix}/saved-reports`,
      exact: true,
      label: 'Saved Reports',
      component: ReportsTab,
      filterProps: {
        disabled: true,
      },
    });
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const report = await getAnalyticsReport({ public_id: publicId, filters });

        dispatch(setReport(report));
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        const msg =
          publicId && e?.response?.status === 404
            ? 'Report could not be found'
            : 'Could not load analytics for the user';

        toast.error(msg);
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    fetch();
  }, [dispatch, filters, publicId]);

  useEffect(() => {
    const loadReportFilters = async () => {
      try {
        if (report?.traffic_sources && !user) {
          dispatch(setTrafficSources([...report.traffic_sources]));
        }
        if (report?.care_types && !user) {
          dispatch(setCareTypes([...report.care_types]));
        }
        if (report?.channels && !user) {
          dispatch(setChannels([...report.channels]));
        }
      } catch (e) {
        console.error(e);
      }
    };

    loadReportFilters();
  }, [dispatch, user, report]);

  useEffect(() => {
    const loadUserFilters = async () => {
      try {
        if (user) {
          const sources = await getTrafficSourceFilterValues();
          dispatch(setTrafficSources(sources.map((item) => item.value).sort()));
        }
      } catch (e) {
        console.error(e);
      }
    };

    loadUserFilters();
  }, [dispatch, user]);

  const handleTabChange = (activeTab) => dispatch(setActiveTab(activeTab));
  const filterProps = reports[activeTab].filterProps || {};

  return (
    <Content>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <PageHeader
        as="h2"
        title={title}
        subtitle={subtitle}
        actions={
          report && <ReportCommandsBox user={user} isPublicView={publicView} report={report} filters={filters} />
        }
        margin={PAGE_HEADER_MARGIN.SMALL}
        padding={PageHeader.PADDING.VERTICAL}
      />

      <If condition={!!report && loaderStatus === STATUSES.LOADED}>
        <AnalyticsFilters {...filterProps} />

        <AnalyticsTabs>
          <Menu size="large" secondary pointing vertical={isMobile}>
            {reports.map((item, i) => (
              <Menu.Item
                key={item.key}
                as={Link}
                to={`${item.path}${location.search}`}
                active={location.pathname === item.path}
                onClick={() => handleTabChange(i)}
              >
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
          <Switch>
            {reports.map((item) => (
              <Route {...item} />
            ))}
            <Redirect to={reports[0].path} />
          </Switch>
        </AnalyticsTabs>
      </If>

      <If condition={loaderStatus === STATUSES.FAILURE}>
        <Message negative>
          <Message.Header>Couldn't load Chat Analytics Report</Message.Header>
          <p>Something went wrong and we couldn't load your analytics. Please try again.</p>

          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Try again
          </Button>
        </Message>
      </If>
    </Content>
  );
};

export default AnalyticsRoot;
