import React from 'react';
import styled from 'styled-components';

import { Button } from 'components/lib';
import {
  RecordingsModal,
  RecordingsModalHeader,
  RecordingsModalContent,
  RecordingsModalActions,
} from './RecordingsModal';
import { ACTION_TYPE } from './constants';

const HighlightText = styled.span`
  font-weight: 900;
  font-style: italic;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RecordingSettingsModal = ({ isOpen, community, loading, handleConfirm, handleCancel }) => {
  const communityName = community?.name || '';

  const moduleContent = {
    [ACTION_TYPE.DISABLE]: {
      title: `Disable custom module recordings for ${communityName}?`,
      component: (
        <ModalContent>
          <div>
            You are about to <HighlightText>disable</HighlightText> the custom module recordings for&nbsp;
            <HighlightText>{communityName}</HighlightText>
          </div>
          <div>
            All module recording will be <strong>suspended</strong>.
          </div>
        </ModalContent>
      ),
    },
    [ACTION_TYPE.ENABLE]: {
      title: `Enable custom module recordings ${communityName}?`,
      component: (
        <ModalContent>
          <div>
            You are about to <HighlightText>enable</HighlightText> the custom module recordings for&nbsp;
            <HighlightText>{communityName}</HighlightText>
          </div>
          <div>
            Custom module recording will be <strong>used during phone calls</strong>.
          </div>
        </ModalContent>
      ),
    },
  };
  const nextActionType = community?.chatModuleRecordingSettings?.isEnabled ? ACTION_TYPE.DISABLE : ACTION_TYPE.ENABLE;
  const title = moduleContent[nextActionType]?.title;
  const content = moduleContent[nextActionType]?.component;

  return (
    <RecordingsModal open={isOpen} closeIcon onClose={handleCancel} size="tiny">
      <RecordingsModalHeader>{title}</RecordingsModalHeader>
      <RecordingsModalContent>{content}</RecordingsModalContent>
      <RecordingsModalActions>
        <Button primary color="blue" disabled={loading} onClick={handleConfirm}>
          Confirm
        </Button>
        <Button basic disabled={loading} onClick={handleCancel}>
          Cancel
        </Button>
      </RecordingsModalActions>
    </RecordingsModal>
  );
};
