import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Header, Menu, Button, Input, Icon, Modal } from 'semantic-ui-react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { CommunitiesContext } from '../../common/communities/CommunitiesContext';
import { VSA_TYPE } from '../constants';

const CommunitiesMenuWrapper = styled.div`
  padding: 0.5rem;

  .ui.header .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .basic.tiny.button {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .ui.input {
    margin-bottom: 2rem;
  }

  .ui.vertical.menu {
    margin: 0;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    span {
      display: inline-block;
      width: 90%;
    }

    .item > i.icon {
      color: #a9a9a9;

      &:hover {
        color: #333;
      }
    }
  }
`;

const ModalText = styled.p`
  font-size: 1.1rem;
`;

export const CommunitiesMenu = () => {
  const { t } = useTranslation();
  const { chatId, communityId } = useParams();
  const history = useHistory();

  const { state, unassignCommunity } = useContext(CommunitiesContext);
  const { assignedCommunities } = state;

  const [filter, setFilter] = useState('');
  const [unassigning, setUnassigning] = useState(null);

  const visibleCommunities = assignedCommunities.filter(
    (p) => !filter || p.name?.toLowerCase().includes(filter.toLowerCase())
  );
  const [unassignLoading, setUnassignLoading] = useState(false);

  const goToFiltered = () => {
    if (visibleCommunities.length > 0) {
      const id = visibleCommunities[0].id;
      setFilter('');
      history.push(`/chats/${chatId}/communities/${id}`);
    }
  };

  const getChatProperty = (community, chatId) => {
    if (!community) return null;

    if (community.chat_property) {
      return community.chat_property.id;
    }

    return community?.chat_properties.find((item) => item.chat === parseInt(chatId));
  };

  const unassigningChatType = useMemo(() => getChatProperty(unassigning, chatId)?.vsa_type, [chatId, unassigning]);

  const unassign = async (community) => {
    const chatProperty = getChatProperty(community, chatId);

    if (!chatProperty) {
      console.error('Missing chat property.');
      setUnassignLoading(false);
      return;
    }

    try {
      setUnassignLoading(true);
      await unassignCommunity(community, chatProperty.id);
      setUnassigning(null);
      toast.success(`"${community.name}" unlinked from chat ${chatId}`);

      if (assignedCommunities.length <= 1) {
        history.replace(`/chats`);
      }
    } catch (e) {
      console.error(e);
      toast.error(`ERROR: ${community.name} was NOT unlinked`);
    }
    setUnassignLoading(false);
  };

  return (
    <CommunitiesMenuWrapper>
      <Header as="h4">
        <Header.Content style={{ textTransform: 'uppercase' }}>
          linked {t('communities')}
          <Button
            basic
            content="Add"
            icon="add"
            size="tiny"
            floated="right"
            as={Link}
            to={`/chats/${chatId}/communities/assign`}
          />
        </Header.Content>
      </Header>
      <Input
        transparent
        icon="search"
        iconPosition="left"
        placeholder="Filter by name..."
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            goToFiltered();
          }
          if (e.key === 'Escape') {
            setFilter('');
          }
        }}
      />
      <Menu secondary vertical fluid>
        {visibleCommunities.map((p, i) => (
          <Menu.Item
            key={p.id}
            active={p.id === parseInt(communityId)}
            as={Link}
            to={`/chats/${chatId}/communities/${p.id}`}
          >
            <span>{p.name}</span>
            <Icon name="times" onClick={() => setUnassigning(p)} />
          </Menu.Item>
        ))}
      </Menu>

      <Modal size="mini" open={!!unassigning}>
        <Modal.Content>
          <ModalText>
            Are you sure you want to unlink{' '}
            <em>
              <q>{unassigning?.name}</q>
            </em>{' '}
            from this chat?
            {unassigningChatType === VSA_TYPE.REGULAR && (
              <>
                <br />
                <br />
                <em>
                  <q>{unassigning?.name}</q>
                </em>{' '}
                will have no linked pop-up chats once the changes are published.
              </>
            )}
            {assignedCommunities.length === 1 && (
              <>
                <br />
                <br />
                <strong>
                  You are about to remove the last {t('community')} assigned to this chat. This action removes the chat
                  as well, and it will not be visible to you anymore.
                </strong>
              </>
            )}
            {unassigningChatType === VSA_TYPE.SURVEY && assignedCommunities.length === 1 && (
              <>
                <br />
                <br />
                <strong>
                  Additionally, all campaign messages containing survey link associated with this Survey may not work
                  properly.
                </strong>
              </>
            )}
          </ModalText>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setUnassigning(null);
            }}
            disabled={unassignLoading}
          >
            Cancel
          </Button>
          <Button
            color="orange"
            disabled={unassignLoading}
            onClick={() => {
              unassign(unassigning);
            }}
            loading={unassignLoading}
          >
            Unlink
          </Button>
        </Modal.Actions>
      </Modal>
    </CommunitiesMenuWrapper>
  );
};
