import React from 'react';
import { Tab } from 'semantic-ui-react';

import { useAnalytics } from '../context/AnalyticsContext';
import InstantAnswersAnalytics from './InstantAnswersAnalytics';

const InstantAnswersTab = () => {
  const [{ filters, publicId }] = useAnalytics();
  // channels filter is not supported for calls
  const iaFilters = { ...filters, channels: null };
  return (
    <Tab.Pane>
      <InstantAnswersAnalytics filters={iaFilters} publicId={publicId} />
    </Tab.Pane>
  );
};

export default InstantAnswersTab;
