import React from 'react';
import { Message } from 'semantic-ui-react';

import { SEOAnalysisTable } from './SEOAnalysisTable';

export const SEOAnalysisPreview = ({ analysis }) => {
  if (!analysis) {
    return <Message icon="info" size="small" warning content="Could not load analysis content!" />;
  }

  const { average_monthly_visitors, last_month_visitors, page_content_analysis, scraped_analysis } = analysis;
  const {
    page_word_count,
    page_city_count,
    page_care_type_count,
    city_in_page_title,
    care_type_in_page_title,
    care_type_in_meta_tag,
    care_type_in_h2_tag,
    care_type_in_h3_tag,
    script_json_ld_present,
    script_json_ld_missing_attributes,
  } = page_content_analysis;

  const {
    google_page_speed,
    google_maps_rank,
    bing_rank,
    google_reviews_count,
    google_reviews_average_rating,
    contact_info_analysis,
  } = scraped_analysis;

  const { matching_contact_information, contact_information } = contact_info_analysis;

  const visitorAnalysisItems = {
    'Average Monthly Visitors': average_monthly_visitors,
    'Last Month Visitors': last_month_visitors,
  };

  const pageContentItems = {
    'Word Count': page_word_count,
    'City Word Count': page_city_count,
    'Care Type Word Count': page_care_type_count,
    'Title Contains City': city_in_page_title,
    'Title Contains Care Type': care_type_in_page_title,
    'Meta Contains Care Type': care_type_in_meta_tag,
    'Secondary Headers Contains Care Type': care_type_in_h2_tag,
    'Tertiary Headers Contains Care Type': care_type_in_h3_tag,
    'Contains JSON+LD Script': script_json_ld_present,
  };

  const pageContentAttributes = {
    'JSON+LD Script Missing Attributes': script_json_ld_missing_attributes,
  };

  const servicesItems = {
    'Google Page Speed': google_page_speed,
    'Google Reviews Count': google_reviews_count,
    'Google Reviews Average Rating': google_reviews_average_rating,
    'Google Maps Rank': google_maps_rank,
    'Bing Search Rank': bing_rank,
  };

  const contactItems = {
    'Matching Contact Information': matching_contact_information,
    'Google My Business': contact_information.google_my_business,
    Caring: contact_information.caring,
    Seniorly: contact_information.seniorly,
    'A Place For Mom': contact_information.a_place_for_mom,
  };

  return (
    <>
      <SEOAnalysisTable title="Chat User Visitor Analysis" items={visitorAnalysisItems} />
      <SEOAnalysisTable title="Page Content Analysis" items={pageContentItems} attached />
      <SEOAnalysisTable items={pageContentAttributes} attached="bottom" />
      <SEOAnalysisTable title="Third Party Services Analysis" items={servicesItems} />
      <SEOAnalysisTable items={contactItems} />
    </>
  );
};
