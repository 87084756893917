import React from 'react';
import styled from 'styled-components';

import SearchInput from 'components/SearchInput';
import { Dropdown } from 'components/lib';
import { MEDIA_BREAK_POINTS } from '../../../constants';
import { useContactRules } from './ContactRuleContext';
import { ANY_MATCH_FILTER } from './constants';

const Container = styled.div`
  display: flex;
  padding: 8px 0;
  gap: 30px;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    gap: 10px;
  }
`;

const SearchBox = styled.div`
  width: 400px;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    width: 100%;
  }
`;

const DropdownBox = styled.div`
  width: 300px;
`;

const ContactRuleFilter = () => {
  const { filters, handleFiltersChange, formConfig } = useContactRules();
  const { search, match } = filters;
  const matchOptions = [
    { key: ANY_MATCH_FILTER, text: 'Any match', value: ANY_MATCH_FILTER },
    ...formConfig?.matchOptions?.map((o) => ({ key: o.value, text: o.text, value: o.value })),
  ];

  return (
    <Container>
      <SearchBox>
        <SearchInput
          fluid
          value={search}
          onSearch={(value) => handleFiltersChange({ search: value })}
          placeholder="Search by name or emails"
        />
      </SearchBox>
      <DropdownBox>
        <Dropdown
          basic
          fluid
          selection
          options={matchOptions}
          onChange={(e, { value }) => handleFiltersChange({ match: value })}
          value={match}
        />
      </DropdownBox>
    </Container>
  );
};

export default ContactRuleFilter;
