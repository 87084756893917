import React, { useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { Icon } from 'components/lib';
import { ConversationContext } from '../ConversationContext';
import { ConversationSidebarNavigationItems } from './ConversationSidebarNavigationItems';
import { MEDIA_BREAK_POINTS } from '../../../constants';

const IconWrapper = styled.div`
  span {
    &.flipped {
      transform: rotateY(180deg);
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: var(--font-family);
  top: 1px;
  max-width: 360px;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.SMALL_MOBILE}px) {
    margin-top: 10px;
    top: 0px;

    ${IconWrapper} {
      padding-bottom: 10px;
    }
  }
`;

const NavigationWrapper = styled.div`
  display: none;
  width: 340px;

  &.expanded {
    display: flex;
  }
`;

export const ConversationSidebarNavigation = () => {
  const { isViewBreakpoint, sidebarExpanded, setSidebarExpanded } = useContext(ConversationContext);

  const onExpandedToggle = () => {
    setSidebarExpanded((prevState) => !prevState);
  };

  const iconClasses = classNames({ flipped: sidebarExpanded });
  const navigationClasses = classNames({ expanded: sidebarExpanded && !isViewBreakpoint });

  return (
    <Wrapper>
      <IconWrapper>
        <Icon name="menu_open" size="24" className={iconClasses} onClick={onExpandedToggle} />
      </IconWrapper>
      <NavigationWrapper className={navigationClasses}>
        <ConversationSidebarNavigationItems />
      </NavigationWrapper>
    </Wrapper>
  );
};
