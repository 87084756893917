import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { ConversationContext } from './ConversationContext';
import { If } from '../If';
import { VIEW_TYPE } from './constants';

const Wrapper = styled.div`
  font-family: var(--font-family-bold);
  font-size: 12px;
  color: var(--link);
  text-transform: uppercase;
  padding-bottom: 8px;
`;

const StyledIcon = styled(Icon)`
  &&& {
    color: var(--light-text);
    margin: 0 6px;
  }
`;

const StyledLink = styled.span`
  color: var(--link);
  cursor: pointer;
`;

const StyledName = styled.span`
  color: var(--light-text);
`;

export const ConversationBreadcrumbs = () => {
  const history = useHistory();
  const { selectedConversation, viewType } = useContext(ConversationContext);
  const leadName = selectedConversation?.name;
  const communityName = selectedConversation?.community?.name;

  const handleBack = (to, goBack) => () => {
    if (goBack) {
      history.goBack();
    } else {
      history.push(to);
    }
  };

  return (
    <Wrapper>
      <If condition={viewType === VIEW_TYPE.MESSAGING}>
        <span>{communityName}</span>
      </If>

      <If condition={viewType === VIEW_TYPE.CONVERSATION}>
        <StyledLink onClick={handleBack('/conversations', !!history.location.state?.fromConversations)}>
          ALL CONVERSATIONS
        </StyledLink>
        <StyledIcon name="angle right" />
        <StyledName>
          {leadName} ({communityName})
        </StyledName>
      </If>
    </Wrapper>
  );
};
