import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

import { getReportMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import queryConstants from 'utils/queries/constants';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import { PercentLine } from 'components/common/analytics';

const metrics = ['calls.callers', 'calls.multicallers'];

const Callers = ({ filters, publicId }) => {
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.METRICS, { publicId, filters, metrics }],
    () => getReportMetrics({ public_id: publicId, filters: { ...filters, metrics } }),
    { placeholderData: {} }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  const parsedData = useMemo(() => {
    if (!data || !data['calls.callers']) {
      return data;
    }
    const callers = data['calls.callers'].value;
    const multicallers = data['calls.multicallers'].value;
    const singlecallers = callers - multicallers;
    const chartData = {
      'Made multiple calls': multicallers,
      'Made one call': singlecallers,
    };

    return { chartData, callers, singlecallers, multicallers };
  }, [data]);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch calls data'));
  }

  return (
    <PercentLine
      height={120}
      lineWidth={6}
      loaderStatus={loaderStatus}
      data={parsedData?.chartData}
      units="Callers"
      color="#22cfd1"
      backColor="var(--pale-lilac)"
    />
  );
};

export default Callers;
