import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Modal, Button, Form } from 'semantic-ui-react';
import * as Yup from 'yup';

import { getCRMSettingsDetails, getCRMTypes } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { CRMForm } from './CRMForm';
import { STATUSES } from '../../../constants';
import { SOURCE_MAPPING_TYPES } from './constants';
import {
  useCRM,
  fetchSourceMappingTrafficSources,
  fetchSourceMappingUrlParameters,
  fetchSourceMappingLeadSources,
} from './context';
import CRMSourceMappingRules from './CRMSourceMappingRules';
import CRMLeadSources from './CRMLeadSources';

const COMMON_FIELDS_INITIAL_VALUES = {
  name: '',
  is_default: false,
  is_enabled: true,
  integration_type: '',
};

const COMMON_FIELDS_VALIDATIONS = {
  name: Yup.string().required('Name is required'),
  is_default: Yup.boolean().required('Default is required'),
  is_enabled: Yup.boolean().required('Enabled is required'),
  integration_type: Yup.number().required('CRM type is required'),
};

const SOURCE_MAPPING_RULE_VALIDATIONS = {
  source_mapping_rules: Yup.array()
    .notRequired()
    .of(
      Yup.object({
        mapping_type: Yup.string().required('Value is required'),
        value: Yup.string().when('mapping_type', {
          is: (mapping_type) =>
            [SOURCE_MAPPING_TYPES.TRAFFIC_SOURCE, SOURCE_MAPPING_TYPES.CHANNEL_SOURCE].includes(mapping_type),
          then: Yup.string().required('Values are required'),
        }),
        result_value: Yup.string().when('mapping_type', {
          is: (mapping_type) =>
            [
              SOURCE_MAPPING_TYPES.TRAFFIC_SOURCE,
              SOURCE_MAPPING_TYPES.CHANNEL_SOURCE,
              SOURCE_MAPPING_TYPES.URL_PARAMETERS,
              SOURCE_MAPPING_TYPES.URL_LOCATION,
            ].includes(mapping_type),
          then: Yup.string().required('Values are required'),
        }),
        keyword: Yup.string().when('mapping_type', {
          is: (mapping_type) =>
            [SOURCE_MAPPING_TYPES.URL_PARAMETERS_MATCH, SOURCE_MAPPING_TYPES.URL_LOCATION].includes(mapping_type),
          then: Yup.string().required('Values are required'),
        }),
        clauses: Yup.array().when('mapping_type', {
          is: SOURCE_MAPPING_TYPES.URL_PARAMETERS,
          then: Yup.array()
            .of(
              Yup.object({
                key_value: Yup.string().required('Key value is required'),
                keyword: Yup.string().required('Keyword value is required'),
              })
            )
            .required(),
        }),
      })
    ),
};

const LEAD_SOURCES_VALIDATION = {
  lead_sources: Yup.array()
    .notRequired()
    .of(
      Yup.object({
        external_id: Yup.string().notRequired(),
        display_name: Yup.string().required('Name is required'),
        enable_nurturing: Yup.boolean(),
      })
    ),
};

const VALIDATION_SCHEMA = Yup.object(COMMON_FIELDS_VALIDATIONS);
const FIELDS_GROUP = {
  MESSAGING: 'messaging',
};

const getFieldsFromObject = (config, group = null) => {
  if (!config) {
    return [];
  }

  return Object.entries(config)
    .filter((c) => c[0] !== 'defaults')
    .map(([key, value]) => ({
      key,
      name: group ? `${group}.${key}` : key,
      value,
    }));
};

const getFieldValue = (name, form, defaults) => {
  if (form && form[name] !== undefined && form[name] !== null) {
    return form[name];
  }
  if (defaults && defaults[name] !== undefined && defaults[name] !== null) {
    return defaults[name];
  }

  return '';
};

export const standardKeys = ['key', 'password', 'custom_url', 'integration_type'];

const getFieldValidation = (fieldTypes, field, fieldValues) => {
  switch (fieldTypes[field.name]) {
    case 'mapping':
      return Yup.array().of(
        Yup.object({
          key: Yup.string().required('Map from value is required'),
          value: Yup.string().required('Map to value is required'),
        })
      );
    case 'dropdown':
      return Yup.string().oneOf(
        fieldValues[field.name].map((i) => String(i.value)),
        'Please select a value'
      );
    default:
      return Yup.string().required(`${field.value} is required`);
  }
};

const CRMSettingsManageModal = ({ open, loading, onClose, crmSettings, onAddCRMSettings, onEditCRMSettings }) => {
  const [{ userId }, dispatch] = useCRM();
  const [crmTypes, setCrmTypes] = useState([]);
  const [selectedCRMType, setSelectedCRMType] = useState();
  const [initialValues, setInitialValues] = useState();
  const [validationSchema, setValidationSchema] = useState();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  useEffect(() => {
    const fetchCRMTypes = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const results = await getCRMTypes();
        await Promise.all([
          fetchSourceMappingTrafficSources(dispatch),
          fetchSourceMappingLeadSources(dispatch),
          fetchSourceMappingUrlParameters(dispatch),
        ]);

        const crmTypes = results.map((type) => {
          const { additional_fields, defaults, field_types, field_values, ...main_fields } = type.config;
          const crmTypeFields = [...getFieldsFromObject(main_fields), ...getFieldsFromObject(additional_fields)];

          return {
            ...type,
            defaults: defaults || {},
            field_types: field_types || {},
            field_values: field_values || [],
            fields: crmTypeFields,
            messaging_fields: getFieldsFromObject(type.messaging_config, FIELDS_GROUP.MESSAGING),
            messaging_defaults: type.messaging_config?.defaults || {},
            text: type.name,
            value: type.id,
            integration_type: type.id,
          };
        });

        setCrmTypes(crmTypes);
        setInitialValues(COMMON_FIELDS_INITIAL_VALUES);
        setValidationSchema(VALIDATION_SCHEMA);
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        setLoaderStatus(STATUSES.FAILURE);
        toast.error(parseErrorResponse(e, 'Unable to load CRM types.'));
      }
    };

    fetchCRMTypes();
  }, []);

  useEffect(() => {
    const fetchCRMSettings = async () => {
      const { id } = crmSettings;

      try {
        setLoaderStatus(STATUSES.LOADING);
        const response = await getCRMSettingsDetails(userId, id);
        const {
          name,
          is_default,
          is_enabled,
          integration_type,
          key,
          password,
          custom_url,
          additional_fields,
          messaging_fields,
          source_mapping_rules,
          lead_sources,
        } = response;
        const crmType = crmTypes.find((t) => t.id === integration_type);
        const defaults = crmType.defaults || {};
        const field_types = crmType.field_types || {};
        const field_values = crmType.field_values || [];
        const messagingDefaults = crmType.messaging_defaults || {};

        setSelectedCRMType(crmType);
        setInitialValues({
          ...crmType.fields.reduce((o, field) => ({ ...o, [field.name]: '' }), {}),
          [FIELDS_GROUP.MESSAGING]: crmType.messaging_fields.reduce(
            (o, field) => ({ ...o, [field.key]: getFieldValue(field.key, messaging_fields, messagingDefaults) }),
            {}
          ),
          name,
          is_default,
          is_enabled,
          integration_type,
          key: key || '',
          password: password || '',
          custom_url: custom_url || '',
          ...defaults,
          ...additional_fields,
          field_types,
          field_values,
          source_mapping_rules,
          lead_sources,
        });

        setValidationSchema(
          Yup.object({
            ...COMMON_FIELDS_VALIDATIONS,
            ...crmType.fields.reduce(
              (o, field) => ({
                ...o,
                [field.name]: getFieldValidation(field_types, field, field_values),
              }),
              {}
            ),
            ...(source_mapping_rules?.length > 0 ? SOURCE_MAPPING_RULE_VALIDATIONS : {}),
            ...(lead_sources?.length > 0 ? LEAD_SOURCES_VALIDATION : {}),
          })
        );

        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e, 'Unable to load CRM settings.'));
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    if (open) {
      if (crmSettings) {
        fetchCRMSettings();
      } else {
        setSelectedCRMType(null);
        setInitialValues(COMMON_FIELDS_INITIAL_VALUES);
        setValidationSchema(VALIDATION_SCHEMA);
      }
    }
  }, [crmSettings, crmTypes, open, userId]);

  const handleCRMTypeChanged = (setFieldValue) => (crmTypeId) => {
    const crmType = crmTypes.find((type) => type.value === crmTypeId);
    const defaults = crmType.defaults || {};
    const messagingDefaults = crmType.messaging_defaults || {};
    setSelectedCRMType(crmType);
    setInitialValues({
      ...COMMON_FIELDS_INITIAL_VALUES,
      ...crmType.fields.reduce((o, field) => ({ ...o, [field.name]: defaults[field.name] }), {}),
      [FIELDS_GROUP.MESSAGING]: crmType.messaging_fields.reduce(
        (o, field) => ({ ...o, [field.key]: messagingDefaults[field.key] }),
        {}
      ),
      integration_type: crmTypeId,
    });
    crmType.fields.map((f) => setFieldValue(f.name, ''));
    crmType.messaging_fields.map((f) => setFieldValue(f.name, ''));

    setValidationSchema(
      Yup.object({
        ...COMMON_FIELDS_VALIDATIONS,
        ...crmType.fields.reduce(
          (o, field) => ({
            ...o,
            [field.name]: getFieldValidation(crmType.field_types, field, crmType.field_values),
          }),
          {}
        ),
        ...(crmType.has_source_mapping_rules_support ? SOURCE_MAPPING_RULE_VALIDATIONS : {}),
        ...(crmType.has_lead_sources_support ? LEAD_SOURCES_VALIDATION : {}),
      })
    );
  };

  const handleSubmit = (values, { resetForm }) => {
    const requestData = {
      name: values.name,
      is_default: values.is_default,
      is_enabled: values.is_enabled,
      integration_type: selectedCRMType.integration_type,
      ...Object.assign(
        {},
        ...selectedCRMType.fields
          .filter((field) => standardKeys.includes(field.name))
          .map((field) => ({ [field.name]: values[field.name] }))
      ),
      additional_fields: {
        ...Object.assign(
          {},
          ...selectedCRMType.fields
            .filter((field) => !standardKeys.includes(field.name))
            .map((field) => ({ [field.name]: values[field.name] }))
        ),
      },
      messaging_fields: values.messaging,
      source_mapping_rules:
        values?.source_mapping_rules?.map((rule, index) => {
          rule.order = index + 1;
          return rule;
        }) || [],
      lead_sources: values.lead_sources,
    };

    if (crmSettings) {
      onEditCRMSettings(requestData);
    } else {
      onAddCRMSettings(requestData, resetForm);
    }
  };

  const handleClose = (resetForm) => () => {
    resetForm();
    onClose();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, setFieldValue, values, resetForm }) => (
        <Modal open={open} onClose={handleClose(resetForm)} closeOnDimmerClick={!loading}>
          <Modal.Header>{crmSettings ? 'Update' : 'Create'} CRM settings</Modal.Header>
          <Modal.Content>
            <Form loading={loading || loaderStatus === STATUSES.LOADING}>
              <CRMForm
                selectedCRMType={selectedCRMType}
                crmTypes={crmTypes}
                onCRMTypeChanged={handleCRMTypeChanged(setFieldValue)}
              />
              <CRMSourceMappingRules
                sourceMappingRules={values?.source_mapping_rules || []}
                setFieldValue={setFieldValue}
                hasSourceMappingRulesSupport={selectedCRMType?.has_source_mapping_rules_support || false}
              />
              <CRMLeadSources fieldName="lead_sources" enabled={selectedCRMType?.has_lead_sources_support || false} />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleClose(resetForm)} disabled={loading}>
              Cancel
            </Button>
            <Button content="Save" primary type="submit" onClick={handleSubmit} disabled={loading} />
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

export default CRMSettingsManageModal;
