import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { STATUSES } from 'constants';
import useDataList from 'utils/hooks/useDataList';
import { createStaff, deleteStaff, getStaff, updateStaff } from 'apis';
import { Dimmer, Loader, Modal, Form, TextArea, Button, Icon } from 'components/lib';
import { InputField, PromptButton, NewImagePicker } from 'components/common';
import { parseErrorResponse } from 'apis/utils';
import { FILE_SIZE_FACTOR, MAX_STAFF_IMAGE_SIZE, STAFF_EXCERPT_WORD_LIMIT } from '../constants';

const BioContainer = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #89919d;
  margin-top: 12px;
  max-width: 286px;
  .staff {
    background-color: transparent;
    color: #1f58b0;
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 12px;
    &:hover {
      background-color: transparent;
      color: #1f58b0;
    }
  }
`;

const ViewMore = styled.span`
  color: #1f58b0;
  cursor: pointer;
`;

const Edit = styled.span`
  color: #1f58b0;
  cursor: pointer;
`;

const Pre = styled.pre`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  color: #89919d;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Bio = ({ bio, onEdit, onDelete, loading, user }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  const generateExcerpt = (bio) => {
    const words = bio?.split(' ');
    if (words?.length > STAFF_EXCERPT_WORD_LIMIT) {
      return `${words.slice(0, STAFF_EXCERPT_WORD_LIMIT).join(' ')}...`;
    }
    return bio;
  };

  return (
    <BioContainer>
      <Pre>{showMore ? bio : generateExcerpt(bio)}</Pre>
      {bio?.split(' ')?.length > STAFF_EXCERPT_WORD_LIMIT && (
        <>
          <ViewMore onClick={toggleShowMore} type="button">
            {showMore ? 'View less' : 'View more'}
          </ViewMore>{' '}
          &middot;{' '}
        </>
      )}
      <Edit onClick={onEdit}>Edit</Edit> &middot;{' '}
      <PromptButton
        content="Delete"
        title={`Delete ${user?.name}?`}
        description={
          <span>
            The staff member <strong>{user?.name}</strong> will be permanently deleted, and will no longer appear in any
            web assistant flows. If you want to add them later, you will have to re-enter their info. Are you sure you
            want to do this?
          </span>
        }
        onConfirm={onDelete}
        loading={loading === STATUSES.LOADING}
        triggerClassName="staff"
      />
    </BioContainer>
  );
};

const StaffInfoContainer = styled.div`
  font-family: var(--fontFamily);
  width: 100%;
`;

const StaffHeader = styled.h2`
  font-size: 26px;
  font-weight: 900;
  color: var(--text);
  padding: 10px 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 30px;
  }
`;

const Person = styled.div`
  display: flex;
  margin: 0 60px 30px 0;
`;

const Picture = styled.div`
  min-width: 64px;
  min-height: 64px;
  max-width: 64px;
  max-height: 64px;
  border-radius: 50%;
  background-color: #f5f5f5;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Data = styled.div`
  margin-left: 20px;
`;

const StaffName = styled.h3`
  font-size: 14px;
  font-weight: 900;
  color: #222;
  margin: 5px 0 10px;
`;

const Role = styled.div`
  min-width: 54px;
  min-height: 22px;
  width: fit-content;
  padding: 3px 10.5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #222;
`;

const ModalHeader = styled.div`
  & > span:first-child {
    font-size: 20px;
    font-weight: 900;
    color: var(--text);
    display: block;
  }
  & > span:last-child {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #89919d;
  }
`;

const FormContainer = styled.div`
  padding: 30px;
  .ui.form .field {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .ui.pointing.above.prompt.label {
      margin-left: 60px;
    }
    .ui.input,
    textarea {
      width: 398px !important;
    }
    > label {
      width: 50px;
      text-align: right;
      margin-right: 10px;
    }
    &:nth-child(4) {
      align-items: flex-start;
      > label {
        padding-top: 10px;
      }
    }
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px 19px 0;
`;

const UserCommunityStaff = () => {
  const { communityId } = useParams();
  const [loading, setLoading] = useState(STATUSES.IDLE);
  const { items, filters, setItems } = useDataList();
  const [image, setImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingMember, setEditingMember] = useState(null);

  const initialValues = {
    name: '',
    position: '',
    description: '',
    ...editingMember,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required').max(100, 'Ensure this field has no more than 100 characters'),
    position: Yup.string().required('Required').max(60, 'Ensure this field has no more than 60 characters'),
  });

  const closeModal = () => {
    setIsModalOpen(false);
    setEditingMember(null);
    setImage(null);
  };

  const onImageSelected = (img) => {
    if (img?.size > MAX_STAFF_IMAGE_SIZE * FILE_SIZE_FACTOR) {
      toast.warning(`Image size should not be greater than ${MAX_STAFF_IMAGE_SIZE}MB!`);
      return;
    }
    setImage(img);
    const imageUrl = URL.createObjectURL(img); // Create object URL for the selected image
    return imageUrl;
  };

  const onEdit = (item) => {
    setEditingMember(item);
  };

  const onDelete = async (item) => {
    setLoading(STATUSES.LOADING);
    try {
      await deleteStaff(communityId, item.id);
      await fetchStaff(filters);
      toast.success('Staff member deleted successfully!');
    } catch (error) {
      const errorText = 'Trouble deleting new staff member!';
      const msg = parseErrorResponse(error, errorText);
      console.error(msg, error?.response);
      toast.error(msg);
      setLoading(STATUSES.FAILURE);
    } finally {
      setLoading(STATUSES.LOADED);
    }
  };

  const onImageDeleted = (setFieldValue) => {
    setImage('');
    setFieldValue('photo_url', '');
  };

  useEffect(() => {
    if (editingMember) {
      setIsModalOpen(true);
    }
  }, [editingMember]);

  const populatePhoto = (value, formData) => {
    const PHOTO_FORM_FIELD = 'image';

    if (value) {
      const blob = new Blob([value], { type: value.type });
      formData.append(PHOTO_FORM_FIELD, blob, value.name);
    } else if (value === '') {
      // Indicates image deletion
      formData.append(PHOTO_FORM_FIELD, '');
    }
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.entries(data).forEach((entry) => {
      const [key, value] = entry;

      if (key === 'image_url') {
        return;
      }

      if (key === 'image') {
        populatePhoto(value, formData);
        return;
      }

      formData.append(key, value);
    });

    setLoading(STATUSES.LOADING);
    try {
      if (data.id) {
        await updateStaff(communityId, data.id, formData);
        toast.success('Staff member edited successfully!');
      } else {
        await createStaff(communityId, formData);
        toast.success('Staff member added successfully!');
      }
    } catch (error) {
      const errorText = data.id ? 'Trouble updating staff member!' : 'Trouble adding new staff member!';
      const msg = parseErrorResponse(error, errorText);
      console.error(msg, error?.response);
      toast.error(msg);
      setLoading(STATUSES.FAILURE);
    } finally {
      await fetchStaff(filters);
      closeModal();
      setLoading(STATUSES.LOADED);
    }
  };

  const fetchStaff = async ({ page, pageSize }) => {
    setLoading(STATUSES.LOADING);
    try {
      const response = await getStaff(communityId, {
        page,
        page_size: pageSize,
      });
      setItems({ results: response?.results, count: response?.count });
      setLoading(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error("Couldn't fetch current staff members! Please try again");
      setLoading(STATUSES.FAILURE);
    }
  };

  useEffect(() => {
    fetchStaff(filters);
  }, [communityId, filters]);

  const wording = editingMember ? 'Edit' : 'Add';

  return (
    <StaffInfoContainer>
      <Dimmer active={loading === STATUSES.LOADING} inverted>
        <Loader active size="large">
          Loading
        </Loader>
      </Dimmer>
      <StaffHeader>
        Staff
        <Button primary color="blue" content="Add Staff" onClick={() => setIsModalOpen(true)} />
      </StaffHeader>
      <Wrapper>
        {items?.map((item) => (
          <Person key={item.id}>
            <Picture src={item.image_url}>
              {!item.image_url && <Icon name="person_outline" color="#89919d" size="huge" />}
            </Picture>
            <Data>
              <StaffName>{item.name}</StaffName>
              <Role>{item.position}</Role>
              <Bio
                bio={item.description}
                onEdit={() => onEdit(item)}
                onDelete={() => onDelete(item)}
                loading={loading}
                user={item}
              />
            </Data>
          </Person>
        ))}
      </Wrapper>
      <Modal open={isModalOpen} closeIcon onClose={closeModal} style={{ width: 540 }}>
        <Dimmer active={loading === STATUSES.LOADING} inverted>
          <Loader active size="large">
            Loading
          </Loader>
        </Dimmer>
        <Modal.Header>
          <ModalHeader>
            <span>{wording} Staff Member</span>
            <span>Add a staff member, their photo, position and bio for display in web assisstants.</span>
          </ModalHeader>
        </Modal.Header>
        <Modal.Content>
          <FormContainer>
            <Formik
              enableReinitialize
              onSubmit={(data) => onSubmit({ ...data, image })}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, isValid, dirty, setFieldValue }) => (
                <Form>
                  <Form.Field>
                    <label>Photo</label>
                    <NewImagePicker
                      name="image_url"
                      openOnClick
                      newDesign
                      onImageSelected={onImageSelected}
                      onImageDelete={() => onImageDeleted(setFieldValue)}
                      trashIconTop="5px"
                      trashIconRight="5px"
                    />
                  </Form.Field>
                  <InputField name="name" label="Name" placeholder="Enter staff member name" />
                  <InputField name="position" label="Position" placeholder="Enter staff member position" />
                  <InputField control={TextArea} name="description" label="Bio" placeholder="Enter staff member bio" />
                  <Actions>
                    <Button
                      primary
                      color="blue"
                      type="submit"
                      content="Save"
                      onClick={handleSubmit}
                      disabled={!(isValid && (dirty || image))}
                    />
                    <Button type="button" content="Cancel" onClick={closeModal} />
                  </Actions>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </Modal.Content>
      </Modal>
    </StaffInfoContainer>
  );
};

export default UserCommunityStaff;
