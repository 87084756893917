import styled from 'styled-components';

import { Dropdown as BaseDropdown } from 'components/lib';

const Dropdown = styled(BaseDropdown)`
  &.ui.selection.dropdown {
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
    border: solid 1px var(--line-standard);
    border-radius: 0px;
    background-color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
    padding: 6px;
    min-height: 36px;
    height: 36px;

    display: flex;
    align-items: center;

    .default {
      color: var(--text-color);
    }

    &&&& div.divider.text {
      padding-right: 30px;
      width: inherit;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      line-height: 1.5;
    }
  }
`;

export default Dropdown;
