import React, { useEffect, useState, useRef } from 'react';
import { Formik, useField } from 'formik';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Dimmer, Loader, Button, TextArea, Dropdown, Checkbox, Form, Popup, Icon } from 'components/lib';
import { InputField } from 'components/common';
import { getFloorPlanAssets } from 'apis';
import { STATUSES, MAX_FILE_UPLOAD_SIZE, MAX_FILE_UPLOAD_SIZE_MB } from 'constants';
import { If } from 'components/If';
import { ImagePicker } from 'components/chats/ImagePicker';
import { capitalize } from 'utils/helpers';

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  > div {
    margin-bottom: 1em;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1ch;
  margin-top: 5px;
`;

const FloorPlanWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 0 1em;
  > div {
    width: 100%;
  }
  &.edit {
    > div {
      width: 50%;
    }
  }
`;

const ImagePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    margin-left: 0.5em;
  }
  &.image-present > div {
    &.full {
      width: auto;
      max-height: 80px;
    }
    img {
      max-height: 80px !important;
    }
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const CareTypes = ({ name, careTypes = [], values, isCareTypeTouched, setIsCareTypeTouched }) => {
  const [, , { setValue }] = useField(name);
  const initialValues = values?.care_type || [];
  return (
    <CheckboxWrapper>
      {careTypes?.map((careType) => (
        <Checkbox
          key={careType?.key}
          name={careType?.name}
          label={careType?.name}
          value={careType?.key}
          checked={initialValues?.includes(careType?.key)}
          onChange={(e, d) => {
            if (!isCareTypeTouched) {
              setIsCareTypeTouched(true);
            }
            if (d.checked) {
              setValue([...initialValues, d.value]);
            } else {
              setValue(initialValues.filter((c) => c !== d.value));
            }
          }}
        />
      ))}
    </CheckboxWrapper>
  );
};

const initialValues = {
  title: '',
};

const CustomLabel = ({ label, description }) => {
  return (
    <LabelContainer title={description}>
      <label>{label}</label>
      <Popup
        className="tight"
        position="top center"
        content={description}
        trigger={<Icon name="help_outline" size={14} color="var(--text)" />}
      />
    </LabelContainer>
  );
};

const UserCommunityFloorPlanForm = ({ onSubmit, onCancel, loading, editableFloorPlan = {} }) => {
  const formRef = useRef();
  const { t } = useTranslation();
  const [floorPlanAssets, setFloorPlanAssets] = useState(null);
  const [loaderState, setLoaderState] = useState(STATUSES.IDLE);
  const [isCareTypeTouched, setIsCareTypeTouched] = useState(false);
  const [floorPlanImage, setFloorPlanImage] = useState(null);

  const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    cost: Yup.number()
      .integer()
      .min(0, 'Must be a positive number or zero')
      .nullable()
      .max(2147483647, 'Ensure this value is less than or equal to 2147483647.'),
    sq_feet: Yup.number()
      .integer()
      .min(0, 'Must be a positive number or zero')
      .nullable()
      .max(2147483647, 'Ensure this value is less than or equal to 2147483647.'),
    availability: Yup.number()
      .integer()
      .min(0, 'Must be a positive number or zero')
      .nullable()
      .max(2147483647, 'Ensure this value is less than or equal to 2147483647.'),
    num_baths: Yup.number()
      .integer()
      .min(0, 'Must be a positive number or zero')
      .nullable()
      .max(2147483647, 'Ensure this value is less than or equal to 2147483647.'),
  });

  const roomSizeOptions = floorPlanAssets
    ? floorPlanAssets.rooms?.map((item) => ({
        key: item.key,
        text: item.name,
        value: item.key,
      }))
    : [];

  const onImageSelected = (image) => {
    if (image.size > MAX_FILE_UPLOAD_SIZE) {
      toast.error(`Image size should not be greater than ${MAX_FILE_UPLOAD_SIZE_MB}MB`);
      return;
    }
    setFloorPlanImage(image);
  };

  const onImageDeleted = (setFieldValue) => {
    setFloorPlanImage('');
    setFieldValue('floor_plan_url', '');
  };

  useEffect(() => {
    const getAssets = async () => {
      setLoaderState(STATUSES.LOADING);
      try {
        const response = await getFloorPlanAssets();
        setFloorPlanAssets(response);
        setLoaderState(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error("Couldn't fetch floor plan assets! Please try again");
        setLoaderState(STATUSES.FAILURE);
      }
    };
    getAssets();
  }, []);

  return (
    <>
      <Dimmer active={loaderState === STATUSES.LOADING || loading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
      <Formik
        innerRef={formRef}
        initialValues={{ ...initialValues, ...editableFloorPlan }}
        enableReinitialize
        onSubmit={(data) => onSubmit({ ...data, floorPlanImage })}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, errors, values, isValid, dirty, setFieldValue }) => (
          <Form>
            <InputField
              name="title"
              label={<CustomLabel label="Title" description="Not shown on VSA" />}
              placeholder="Title"
            />
            <InputField
              control={Dropdown}
              fluid
              search
              selection
              label={<CustomLabel label="Room size" description="Shown on VSA" />}
              name="room_size"
              options={roomSizeOptions}
              placeholder="Room size"
            />
            <Form.Field className={errors.care_type && isCareTypeTouched && 'error'}>
              <CustomLabel label="Care types" description="Shown on VSA" />
              <CareTypes
                name="care_type"
                careTypes={floorPlanAssets?.care_types}
                initialValues={editableFloorPlan?.care_type}
                values={values}
                isCareTypeTouched={isCareTypeTouched}
                setIsCareTypeTouched={setIsCareTypeTouched}
              />
              <If condition={errors.care_type && isCareTypeTouched}>
                <div className="ui pointing above prompt label" role="alert" aria-atomic="true">
                  Required
                </div>
              </If>
            </Form.Field>
            <FloorPlanWrapper className={values?.floor_plan_url && values?.id && 'edit'}>
              <Form.Field>
                <CustomLabel label="Floor plan image" description="Shown on VSA" />
                <ImagePickerWrapper className={values?.floor_plan_url && 'image-present'}>
                  <ImagePicker
                    name="floor_plan_url"
                    onImageSelected={onImageSelected}
                    onImageDelete={() => onImageDeleted(setFieldValue)}
                    className="full"
                    trashIconTop="5px"
                    trashIconRight="5px"
                    openOnClick
                  />
                  <span>{floorPlanImage?.name}</span>
                </ImagePickerWrapper>
              </Form.Field>
            </FloorPlanWrapper>
            <InputField type="number" name="cost" label={<CustomLabel label="Cost" description="Shown on VSA" />} />
            <InputField
              type="number"
              name="num_baths"
              label={<CustomLabel label="Number of baths" description="Shown on VSA" />}
            />
            <InputField
              control={TextArea}
              name="care_description"
              label={<CustomLabel label="Care description" description="Not shown on VSA" />}
            />
            <InputField
              type="number"
              name="sq_feet"
              label={<CustomLabel label="Square feet" description="Shown on VSA" />}
            />
            <InputField
              name="move_in_special"
              label={<CustomLabel label={`${capitalize(t('move_in'))} special`} description="Not shown on VSA" />}
            />
            <InputField
              type="number"
              name="availability"
              label={<CustomLabel label="Availability" description="Not shown on VSA" />}
            />
            <Actions>
              <Button type="button" content="Cancel" onClick={onCancel} />
              <Button
                primary
                type="submit"
                content={editableFloorPlan?.id ? 'Save' : 'Add'}
                onClick={handleSubmit}
                disabled={!(isValid && (dirty || floorPlanImage))}
              />
            </Actions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserCommunityFloorPlanForm;
