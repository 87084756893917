import React from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { formatPrices } from 'components/utils';

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

export const CommunityPricingTable = ({ community }) => {
  const prices = formatPrices(community);

  if (!prices.length) {
    return (
      <Wrapper>
        <Label>
          <Icon name="dollar sign" />
          No pricing information
        </Label>
      </Wrapper>
    );
  }

  return (
    <Table compact striped celled singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2">
            <Icon name="dollar sign" />
            Pricing Information
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {prices.map((price, index) => (
          <Table.Row key={index}>
            <Table.Cell>{price.name}</Table.Cell>
            <Table.Cell>{price.cost}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
