import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { CONNECTOR } from './constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ type }) => (type === CONNECTOR.AND ? '#e2f0ff' : '#ffeeea')};
  width: 100%;
  cursor: pointer;
`;

const Text = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  font-weight: 900;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: ${({ type }) => (type === CONNECTOR.AND ? '#007aff' : 'var(--salmon)')};
  margin-left: 5px;
  padding-top: 3px;
`;

const AddConditionButton = ({ type, text, onClick }) => {
  const iconColor = type === CONNECTOR.AND ? '#007aff' : 'var(--salmon)';
  return (
    <Container type={type} onClick={onClick}>
      <Icon name="add_circle" size="16" color={iconColor} />
      <Text type={type}>{text}</Text>
    </Container>
  );
};

export default AddConditionButton;
