import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { Icon, FURHTER_ICON, Modal } from 'components/lib';
import { If } from 'components/If';
import { pluralize } from 'utils/helpers';
import { TALK_FURTHER } from '../../constants';
import { IconContainer } from '.';

const ModalContainer = styled(Modal.Content)`
  &&&& {
    padding: 30px;
    min-height: 578px;
    display: flex;
    flex-direction: column;
  }
`;

const ModalHeader = styled(Modal.Header)`
  display: flex;
  align-items: center;
`;
const Header = styled.div`
  margin-left: 20px;
  h6 {
    font-family: var(--font-family-bold);
    font-size: 32px;
    color: var(--text);
    margin: 0;
  }
`;
const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  p {
    margin-right: 5px;
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--text);
    margin: 0;
  }
  && > span {
    color: ${(p) => (p.$active ? 'var(--emerald-green)' : 'var(--salmon)')};
  }
  && > .notUsed {
    margin-left: 5px;
    color: #89919d;
  }
`;

const ModalContent = styled.div`
  display: flex;
  padding: 25px 50px;
  justify-content: space-between;
`;

const ProductImage = styled.img`
  height: 350px;
`;

const ProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  h6 {
    font-family: var(--font-family-bold);
    font-size: 20px;
    color: var(--text);
    margin: 0;
    margin-left: 35px;
  }
  ul {
    margin: 0;
    padding-left: 25px;
    max-width: 334px;
    li {
      font-family: var(--font-family);
      font-size: 14px;
      padding-left: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      &::marker {
        font-size: 22px;
      }
    }
  }
`;

const CallToActionContainer = styled.div`
  margin-left: 35px;
  margin-top: 30px;
`;

const ActivateProductContainer = styled.div`
  width: 298px;
  height: 56px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ebf5ff;
  &.active {
    background-color: #defff3;
    flex-direction: row;
    span:not(.${FURHTER_ICON}) {
      margin-left: 10px;
      font-family: var(--font-family);
      font-size: 14px;
      color: var(--emerald-green);
    }
  }
`;

const Fotter = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
  text-align: center;
  color: #89919d;
`;

export const ProductModal = ({ product }) => {
  const {
    ProductIcon,
    productImage,
    iconBancground,
    name,
    subtitle,
    active,
    benefitValue,
    benefit,
    isAIpowered,
    numberOfCommunitiesThatDontUseFeature,
    isActivatedRecently,
    description,
  } = product;
  return (
    <ModalContainer>
      <ModalHeader>
        <IconContainer backgoundColor={iconBancground}>
          <ProductIcon />
        </IconContainer>
        <Header>
          <h6>{name}</h6>
          <Subtitle $active={active}>
            <p>{subtitle} ·</p>&nbsp;
            <span>{active ? 'Active ' : 'Not active'}</span>{' '}
            {numberOfCommunitiesThatDontUseFeature > 0 && (
              <span className="notUsed">
                · Not used on {`${pluralize(numberOfCommunitiesThatDontUseFeature, 'communities', 'community')}`}
              </span>
            )}
          </Subtitle>
        </Header>
      </ModalHeader>
      <ModalContent>
        <ProductImage src={productImage} alt={name} />
        <ProductDescription>
          <h6>Features</h6>
          <ul>{description && description.map((item, index) => <li key={index}>{item}</li>)}</ul>
          <CallToActionContainer>
            <ActivateProductContainer className={classNames({ active })}>
              <If condition={!active}>
                <span>To activate this, send an email to</span>
                <a href={`mailto:${TALK_FURTHER.SUPPORT_EMAIL}`}>{TALK_FURTHER.SUPPORT_EMAIL}</a>
              </If>
              <If condition={active}>
                <Icon name="done" color="var(--emerald-green)" />
                <span>Active</span>
              </If>
            </ActivateProductContainer>
          </CallToActionContainer>
        </ProductDescription>
      </ModalContent>
      <Fotter>
        <If condition={active}>This service is active on your account.</If>
        <If condition={!active}>
          A request to activate a service will be processed shortly, and we will get back to you with an offer.
        </If>
      </Fotter>
    </ModalContainer>
  );
};
