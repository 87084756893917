import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

import { Module } from './Module';
import { Answers } from './Answers';

export const SubmitCallerPhone = ({ node }) => (
  <Module node={node}>
    <Label color="grey">Background Operation</Label>
    <Label color="black">
      <Icon name="send" /> Submit Caller Phone
    </Label>
    <Answers node={node} />
  </Module>
);
