import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Table, Button, Header, Dimmer, Loader, Message } from 'semantic-ui-react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { capitalize } from 'utils/helpers';
import { STATUSES } from '../../../constants';
import { If } from '../../If';
import { FormWrapper } from '../FormWrapper';
import { CommunitiesContext, ACTIONS } from '../../common/communities/CommunitiesContext';
import { VSA_TYPE } from '../constants';

// This component is just temporarary solution for covering the part of the business cases related to the Phone VSA.
// The main goal was to avoid complicated changes on the existing logic for REGULAR VSA.

const Row = ({ community, onChange }) => {
  const { chatId } = useParams();
  const { assignCommunity, getCommunityChatPropertiesByType } = useContext(CommunitiesContext);
  const [loadingCommunities, setLoadingCommunities] = useState(false);

  const assign = async (community) => {
    try {
      setLoadingCommunities(true);
      await assignCommunity(community, chatProperty, chatId);
      setLoadingCommunities(false);

      onChange(community);
      toast.success(`"${community.name}" successfully linked to chat ${chatId}`);
    } catch (e) {
      console.error(e);
      setLoadingCommunities(false);
    }
  };

  const chatProperty = useMemo(() => {
    if (!community) return null;

    const properties = getCommunityChatPropertiesByType({ community, vsaType: VSA_TYPE.PHONE });
    if (properties.length === 0) return null;

    return properties[0];
  }, [community]);

  return (
    <Table.Row>
      <Table.Cell>{community?.name}</Table.Cell>
      <Table.Cell>
        {chatProperty ? <Link to={`/chats/${chatProperty.chat}`}>{chatProperty.chat}</Link> : 'NONE'}
      </Table.Cell>
      <Table.Cell>
        <Button
          style={{ width: '8rem' }}
          disabled={loadingCommunities}
          loading={loadingCommunities}
          onClick={() => {
            assign(community);
          }}
        >
          {chatProperty ? 'Re-assign' : 'Assign'}
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export const AssignCommunityVoice = () => {
  const { t } = useTranslation();
  const { chatId } = useParams();
  const { state, dispatch, loadCommunitiesForLoggedUser } = useContext(CommunitiesContext);
  const { unassignedCommunities, status } = state;

  const communities = useMemo(
    () => unassignedCommunities?.slice().sort((c1, c2) => c1.name.localeCompare(c2.name)),
    [unassignedCommunities]
  );

  useEffect(() => {
    loadCommunitiesForLoggedUser({ without_chat_id: chatId });
  }, [chatId, dispatch]);

  const updateCommunities = (community) => {
    dispatch({ type: ACTIONS.REMOVE_UNASSIGNED_COMMUNITIES, payload: { communities: [community] } });
  };

  return (
    <FormWrapper>
      <If condition={status === STATUSES.LOADING}>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </If>

      <If condition={status === STATUSES.LOADED}>
        <Header as="h2">
          <Header.Content>
            Link {capitalize(t('community'))}
            <Header.Subheader>Make this chat available when calling community phone number</Header.Subheader>
          </Header.Content>
        </Header>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Currently linked Voice-Based VSA</Table.HeaderCell>
              <Table.HeaderCell>Link to Voice-Based VSA {chatId}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {communities.map((community) => (
              <Row community={community} key={community.id} onChange={updateCommunities} />
            ))}
          </Table.Body>
        </Table>
      </If>

      <If condition={status === STATUSES.FAILURE}>
        <Message negative>
          <Message.Header>We're sorry we can't fetch your unassigned {t('communities')}</Message.Header>
          <p>Something went wrong and we couldn't load your {t('communities')}. Please try again later.</p>
        </Message>
      </If>
    </FormWrapper>
  );
};
