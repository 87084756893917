import React, { useState } from 'react';
import { Dimmer, Loader, Divider } from 'components/lib';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { parseErrorResponse, parseFieldErrors } from 'apis/utils';
import { createAdminUser } from 'apis';
import { STATUSES } from '../../constants';
import UserManagementForm from './UserManagementForm';

const UserManagementCreate = () => {
  const history = useHistory();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const handleCreateUser = async (values, { setErrors }) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      if (values.type === 'null') {
        values.type = null;
      }
      const { id } = await createAdminUser(values);
      toast.success('User created successfully');
      setLoaderStatus(STATUSES.LOADED);

      history.push(`/admin/user-management/user/${id}`);
    } catch (e) {
      setErrors(parseFieldErrors(e));
      toast.error(parseErrorResponse(e, 'Unable to create user.'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <UserManagementForm loading={loaderStatus === STATUSES.LOADING} onSubmit={handleCreateUser} />
    </>
  );
};

export default UserManagementCreate;
