import React, { useContext } from 'react';
import styled from 'styled-components';
import { isBefore, isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { Button } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { capitalize } from 'utils/helpers';
import { ConversationContext } from '../ConversationContext';
import { CALENDAR_REQUEST_SOURCE } from '../constants';
import { USER_TYPES } from '../../../constants';
import { UserContext } from '../../UserContext';
import { CONVERSATION_ACTIONS } from './constants';
import { LeadNote } from '../chat/LeadNote';

const TYPE_OPTIONS = (t) => ({
  call: {
    btnColor: 'blue',
    color: '#0ab776',
    fadedColor: '#effaf6',
    icon: 'phone_in_talk',
    label: 'Call',
  },
  tour: {
    btnColor: 'blue',
    color: 'var(--link)',
    fadedColor: '#eff3ff',
    icon: 'tour',
    label: capitalize(t('tour')),
  },
});

const Container = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

const Right = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const Via = styled.span`
  font-size: 12px;
  color: #89919d;
`;

export const ConversationFloatingReminderConfirm = ({ type }) => {
  const { t } = useTranslation();
  const { selectedConversation, setEventType, showModal } = useContext(ConversationContext);
  const { user } = useContext(UserContext);

  const options = TYPE_OPTIONS(t)[type];
  const showReminderActions = ![USER_TYPES.REGIONAL, USER_TYPES.REGULAR, USER_TYPES.LABELING].includes(user.type);

  const handleMoreOptions = () => {
    setEventType(type);
    showModal(CONVERSATION_ACTIONS.MORE_OPTIONS);
  };

  const handleConfirm = () => {
    setEventType(type);
    showModal(CONVERSATION_ACTIONS.CONFIRM);
  };

  const event = selectedConversation[type];
  const eventDateTimezoned = utcToZonedTime(event?.start, selectedConversation?.community.timezone);
  const eventDateValid = isValid(eventDateTimezoned);
  const eventDateFormatted = eventDateValid && formatAnyDate(eventDateTimezoned, "MMM dd, yyyy 'at' h:mm a");

  const currentDateTimezoned = utcToZonedTime(new Date(), selectedConversation?.community.timezone);
  const confirmItemDisabled = isBefore(eventDateTimezoned, currentDateTimezoned) || !eventDateValid;
  const source = event?.source ? CALENDAR_REQUEST_SOURCE[event.source] : undefined;
  const titlePostfix = eventDateValid ? `on ${eventDateFormatted}` : 'with unspecified date';
  return (
    <Container>
      <LeadNote
        text={{
          is_further: true,
          icon: options.icon,
          borderRadius: event?.note ? '16px 16px 0 0' : false,
          hideAddedNote: true,
          title: (
            <>
              {`${options.label} requested ${titlePostfix} `}
              {source && <Via>via {source}</Via>}
            </>
          ),
        }}
      >
        <If condition={showReminderActions}>
          <Right>
            <Button disabled={confirmItemDisabled} primary color={options.btnColor} onClick={handleConfirm}>
              Confirm {options.label}
            </Button>
            <Button onClick={handleMoreOptions} primary>
              More Options
            </Button>
          </Right>
        </If>
      </LeadNote>
      <If condition={event?.note}>
        <LeadNote
          text={{
            content: event?.note,
            is_further: true,
            eventNote: true,
            borderRadius: '0 0 16px 16px',
            hideAddedNote: true,
          }}
        />
      </If>
    </Container>
  );
};
