import React from 'react';
import styled from 'styled-components';

import { Dimmer as SemanticDimmer } from 'semantic-ui-react';

const StyledDimmer = styled(SemanticDimmer)``;

export const Dimmer = ({ ...props }) => <StyledDimmer {...props} />;

Dimmer.Dimmable = SemanticDimmer.Dimmable;
