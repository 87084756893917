import React, { useContext } from 'react';
import styled from 'styled-components';

import { MEDIA_BREAK_POINTS } from '../../constants';
import { ConversationContext } from './ConversationContext';
import { ConversationBreadcrumbs } from './ConversationBreadcrumbs';
import { ConversationHeaderActions } from './ConversationHeaderActions';

const ConversationHeaderLeadInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const Name = styled.div`
  font-family: var(--font-family-bold);
  font-size: 22px;
  color: #222;
  margin-top: 5px;
  margin-bottom: 10px;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    margin-top: 0;
    font-size: 40px;
    line-height: 40px;
  }
`;

export const ConversationHeaderTop = () => {
  const { selectedConversation = {} } = useContext(ConversationContext);

  return (
    <ConversationHeaderLeadInfo>
      <NameContainer>
        <ConversationBreadcrumbs />
        <Name>{selectedConversation?.name}</Name>
      </NameContainer>
      <ConversationHeaderActions />
    </ConversationHeaderLeadInfo>
  );
};
