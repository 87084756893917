import React from 'react';

import { Loader, TabsWithNavigation } from 'components/lib';
import { STATUSES } from 'constants';
import { ExperimentsRootTopSection } from './ExperimentsRootTopSection';
import { EXPERIMENTS_TABS } from './constants';
import { useExperiments } from './context/ExperimentsContext';

export const ExperimentsMain = () => {
  const { status } = useExperiments();
  return (
    <>
      <ExperimentsRootTopSection />
      <TabsWithNavigation
        tabs={EXPERIMENTS_TABS}
        contentPadding="20px 0"
        tabContentLoading={status === STATUSES.LOADING}
      />
    </>
  );
};
