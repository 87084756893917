import React from 'react';
import { useFormikContext } from 'formik';
import { Dropdown, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { ToggleSwitch, InputField } from 'components/common';
import { capitalize } from 'utils/helpers';
import { TOUR_END_TIMES, TOUR_START_TIMES, WEEKDAYS } from '../../../constants';

export const GeneralSection = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const onDisabledDaysChange = (e, { value }) => {
    setFieldValue('disabled_days', value.sort());
  };

  return (
    <>
      <Form.Group widths="equal">
        <InputField name="name" label="Chat name" />
        <InputField name="advisor_name" label="Advisor name" />
        <InputField name="vsa_type" label="Type" disabled />
      </Form.Group>

      <Form.Group widths="equal">
        <InputField name="intro_text" label="Intro text" />
        <InputField name="teaser" label="Teaser" />
      </Form.Group>

      <Form.Group widths="equal">
        <InputField name="pricing_services_text" label="Pricing services text" />
        <InputField name="pricing_gallery_text" label="Pricing gallery text" />
        <InputField name="pricing_type" label="Pricing type" />
      </Form.Group>

      <Form.Group>
        <Form.Field width="10">
          <label>Price filtering</label>
          <ToggleSwitch name="filter_pricing" label="Show only the price for the care type user has selected" />
        </Form.Field>

        <Form.Field width="6">
          <label>When no price</label>
          <ToggleSwitch name="show_pricing_not_available" label='Show "Pricing not available"' />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        {/* <Form.Field>
          <label>Same day {t('tours')}</label>
          <ToggleSwitch name="same_day_tour" label={`Offer same day ${t('tours')}`} />
        </Form.Field>

        <Form.Field>
          <label>Next day {t('tours')}</label>
          <ToggleSwitch name="next_day_tour" label={`Offer next day ${t('tours')}`} />
        </Form.Field> */}

        <Form.Field>
          <label>Visitor names</label>
          <ToggleSwitch name="full_name_required" label="Full name required" />
        </Form.Field>

        <Form.Field>
          <label>Weekend notice</label>
          <ToggleSwitch name="weekend_inactive" label="Show 'Chat Inactive' notice on weekends" />
        </Form.Field>

        <Form.Field>
          <label>Use fancy {t('tour')} button</label>
          <ToggleSwitch name="use_fancy_tour_button" />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <InputField name="tour_slide_button_text" label={`${capitalize(t('tours'))} slide button text`} />
        <InputField name="out_of_my_budget_alert_text" label="Out Of My Budget alert text" />
      </Form.Group>
    </>
  );
};
