import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import { FormikContext } from 'formik';

import { Button } from 'components/lib';

export const NextStep = ({ onNextStep, text = 'Next Step', disabled, loading }) => {
  const formikContext = useContext(FormikContext);
  const portalTarget = document.getElementById('nextStepContainer');
  if (!portalTarget) return null;

  const handleNextStep = () => {
    if (formikContext?.handleSubmit) {
      formikContext.handleSubmit();
      return;
    }
    if (onNextStep) {
      onNextStep();
    }
  };

  return createPortal(
    <Button
      size="small"
      fluid
      primary
      color="blue"
      type="submit"
      onClick={handleNextStep}
      disabled={disabled}
      loading={loading}
    >
      {text}
    </Button>,
    portalTarget
  );
};
