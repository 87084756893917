import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

const DropZoneOverlay = styled.div`
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  height: calc(100% + 1rem);
  width: calc(100% + 1rem);
  background: white;
  opacity: 0.8;
`;
const DropZoneWrapper = styled.div`
  position: relative;
  display: block;
`;
export const DropZone = ({ children, onDrop }) => {
  const { values: node } = useFormikContext();
  const { module } = node;

  const [{ isOver }, drop] = useDrop({
    accept: `options_in_module_${module?.number}`,
    drop: (item) => {
      if (typeof onDrop === 'function') {
        onDrop(item);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <DropZoneWrapper ref={drop}>
      {children}

      {isOver && <DropZoneOverlay />}
    </DropZoneWrapper>
  );
};
