import React, { useMemo } from 'react';

import { DialCommunity } from './DialCommunity';
import { Links } from './Links';
import { Option } from './Option';
import { MultiOption } from './MultiOption';
import { Sms } from './Sms';
import { Submit } from './Submit';
import { UnknownModuleType } from './UnknownModuleType';
import { UserInput } from './UserInput';
import { Slack } from './Slack';
import { Eval } from './Eval';
import { OutOfBudget } from './OutOfBudget';
import { Click } from './Click';
import { Calendar } from './Calendar';
import { SubmitCallerPhone } from './SubmitCallerPhone';
import { SubmitJobApplicant } from './SubmitJobApplicant';
import { SubmitSurvey } from './SubmitSurvey';
import { Voicemail } from './Voicemail';
import TextDisplay from './TextDisplay';
import { InstantAnswers } from './InstantAnswers';
import { StartCallRecording } from './StartCallRecording';
import { AgentAssignmentAndDial } from './AgentAssignmentAndDial';

export const Node = ({ node }) => {
  const module = node.module;

  const renderers = useMemo(
    () => ({
      option: Option,
      multi_option: MultiOption,
      care_option: Option,
      link: Links,
      text: UserInput,
      multi_line_text: UserInput,
      text_display: TextDisplay,
      email: UserInput,
      phone: UserInput,
      date_picker: Calendar,
      submit: Submit,
      sms: Sms,
      slack: Slack,
      eval: Eval,
      instant_answers: InstantAnswers,
      out_of_budget: OutOfBudget,
      out_of_budget_slider: UserInput,
      click: Click,
      submit_job_applicant: SubmitJobApplicant,
      submit_survey: SubmitSurvey,
      caller_phone_submit: SubmitCallerPhone,
      voicemail: Voicemail,
      dial_community: DialCommunity,
      start_call_recording: StartCallRecording,
      contact_info: UserInput,
      agent_assignment_and_dial: AgentAssignmentAndDial,
    }),
    []
  );

  const type = module.module_type;
  if (!renderers[type]) {
    return <UnknownModuleType node={node} />;
  }

  const Comp = renderers[type];
  return <Comp node={node} />;
};
