import React from 'react';
import { ConversationsContextProvider } from './ConversationsContext';
import { ConversationsList } from './ConversationsList';

export const Conversations = () => {
  return (
    <ConversationsContextProvider>
      <ConversationsList />
    </ConversationsContextProvider>
  );
};
