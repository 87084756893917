import { toast } from 'react-toastify';

import { getCRMCommunitiesInvalidSettings, getEligibleTrafficSources, getEligibleUrlParameters } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import {
  setCRMCommunityInvalidSettings,
  setCRMSourceMappingLeadSources,
  setCRMSourceMappingTrafficSources,
  setCRMSourceMappingUrlParameters,
} from './actions';
import { getEligibleLeadSources } from '../../../../apis';

export const fetchCommunityInvalidCRMSettings = async (userId, dispatch) => {
  try {
    const response = await getCRMCommunitiesInvalidSettings(userId);
    dispatch(setCRMCommunityInvalidSettings(response));
  } catch (e) {
    toast.error(parseErrorResponse(e, 'Unable to fetch communities with invalid CRM settings'));
  }
};

export const fetchSourceMappingTrafficSources = async (dispatch) => {
  try {
    const response = await getEligibleTrafficSources();
    dispatch(setCRMSourceMappingTrafficSources(response.traffic_source_names));
  } catch (e) {
    toast.error(parseErrorResponse(e, 'Unable to fetch traffic source options.'));
  }
};

export const fetchSourceMappingLeadSources = async (dispatch) => {
  try {
    const response = await getEligibleLeadSources();

    dispatch(setCRMSourceMappingLeadSources(response.lead_sources));
  } catch (e) {
    toast.error(parseErrorResponse(e, 'Unable to fetch lead source options.'));
  }
};

export const fetchSourceMappingUrlParameters = async (dispatch) => {
  try {
    const response = await getEligibleUrlParameters();
    dispatch(setCRMSourceMappingUrlParameters(response.url_parameter_names));
  } catch (e) {
    toast.error(parseErrorResponse(e, 'Unable to fetch url parameters options.'));
  }
};
