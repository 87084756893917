import React from 'react';
import styled from 'styled-components';

import { Content } from 'components/common';
import { PortfolioSidebar } from './PortfolioSidebar';
import { SummaryOverview } from './SummaryOverview';

export const SidebarLayout = styled.div`
  display: flex;
`;

const Main = styled.div`
  padding: 2rem;
  width: 100%;
  background-color: #fff;
`;

export const Portfolio = () => (
  <Content noPadding>
    <SidebarLayout>
      <PortfolioSidebar />
      <Main>
        <SummaryOverview />
      </Main>
    </SidebarLayout>
  </Content>
);
