import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Switch, Route, Link, useParams, useRouteMatch } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { parseErrorResponse } from 'apis/utils';
import { capitalize } from 'utils/helpers';
import { If } from 'components/If';
import { getChatPublishStatus } from '../../api';
import { Heading } from './Heading';
import { Communities, CommunitiesContextProvider } from './RelatedCommunities';
import { ChatSettings, ChatSettingsContextProvider } from './ChatSettings';
import { Teasers } from './Teasers';
import { ChatModules } from './ChatModules';
import { Pricing } from '../settings/Pricing';
import { ContactInfo } from '../settings/ContactInfo';
import { actions, ChatEditorProvider, useChatEditor } from './ChatEditorContext';
import { ChatModuleRecordings } from './ChatModuleRecordings/ChatModuleRecordings';
import { ModuleRecordingsProvider } from './ChatModuleRecordings/ModuleRecordingsContext';
import { VSA_TYPE } from './constants';

const PaneContentWrapper = styled.div`
  position: relative;
  margin-top: 1rem;

  .ui.header {
    color: #333;
    margin-bottom: 2rem;
  }
`;

const ChatEditorTabs = ({ chat }) => {
  const { t } = useTranslation();
  const { chatId } = useParams();
  const chatMatch = useRouteMatch({
    path: '/chats/:chatId',
    exact: true,
  });
  const cloneMatch = useRouteMatch('/chats/:chatId/clone');
  const modulesMatch = useRouteMatch('/chats/:chatId/modules');
  const communitiesMatch = useRouteMatch('/chats/:chatId/communities');
  const teasersMatch = useRouteMatch('/chats/:chatId/teasers');
  const recordingsMatch = useRouteMatch('/chats/:chatId/module-recordings');

  return (
    <Menu size="large" secondary pointing>
      <Menu.Item key="chat" as={Link} to={`/chats/${chatId}`} active={!!chatMatch || !!cloneMatch}>
        Settings
      </Menu.Item>
      <Menu.Item key="modules" as={Link} to={`/chats/${chatId}/modules`} active={!!modulesMatch}>
        Dialog Flows
      </Menu.Item>
      <Menu.Item key="communities" as={Link} to={`/chats/${chatId}/communities`} active={!!communitiesMatch}>
        Related {capitalize(t('communities'))}
      </Menu.Item>
      <Menu.Item key="teasers" as={Link} to={`/chats/${chatId}/teasers`} active={!!teasersMatch}>
        Teaser Settings
      </Menu.Item>
      <If condition={chat?.vsa_type === VSA_TYPE.PHONE}>
        <Menu.Item key="recordings" as={Link} to={`/chats/${chatId}/module-recordings`} active={!!recordingsMatch}>
          Custom Module Recordings
        </Menu.Item>
      </If>
    </Menu>
  );
};

const ChatEditorRoot = () => {
  const { chatId } = useParams();
  const [state, dispatch, loadChat] = useChatEditor();

  useEffect(() => {
    const fetchPublishStatus = async () => {
      try {
        const { is_latest_change_published } = await getChatPublishStatus(chatId);
        dispatch({ type: actions.SET_IS_PUBLISHED, payload: is_latest_change_published });
      } catch (e) {
        toast.error(parseErrorResponse(e, 'Failed to fetch chat latest change publish status'));
      }
    };

    fetchPublishStatus();
    loadChat(chatId);
  }, [chatId, dispatch]);

  return (
    <>
      <Heading />
      <ChatEditorTabs chat={state.chat} />
      <PaneContentWrapper>
        <Switch>
          <Route exact path="/chats/:chatId">
            <ChatSettingsContextProvider>
              <ChatSettings />
            </ChatSettingsContextProvider>
          </Route>
          <Route exact path="/chats/:chatId/modules">
            <ChatModules />
          </Route>
          <Route path="/chats/:chatId/communities">
            <CommunitiesContextProvider>
              <Communities />
            </CommunitiesContextProvider>
          </Route>
          <Route path="/chats/:chatId/pricing">
            <Pricing />
          </Route>
          <Route path="/chats/:chatId/contact">
            <ContactInfo />
          </Route>
          <Route path="/chats/:chatId/teasers">
            <Teasers />
          </Route>
          <If condition={state?.chat?.vsa_type === VSA_TYPE.PHONE}>
            <Route path="/chats/:chatId/module-recordings">
              <ModuleRecordingsProvider>
                <ChatModuleRecordings />
              </ModuleRecordingsProvider>
            </Route>
          </If>
        </Switch>
      </PaneContentWrapper>
    </>
  );
};

export const ChatEditor = () => (
  <ChatEditorProvider>
    <ChatEditorRoot />
  </ChatEditorProvider>
);
