import styled from 'styled-components';

export const MessageInputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.1rem 1.3rem;
  border-radius: 1rem;
  border: 2px solid #d5e2f4;
  color: #999999;
  font-weight: 300;
  letter-spacing: 0.3px;
  font-size: 14px;
  font-family: arial;
  margin-top: 2rem;
  cursor: pointer;
`;
