import styled from 'styled-components';
import { Modal } from '../../lib';

export const ConversationModal = styled(Modal)`
  &&&& {
    .actions {
      padding: 20px 30px !important;
    }
    z-index: 2004;
  }
`;

export const ConversationModalHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h4 {
    font-family: var(--font-family-bold);
    font-size: 20px;
    color: var(--text);
  }
`;

export const ConversationModalSubtitle = styled.p`
  margin-top: 11px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  color: var(--light-text);
  white-space: pre-line;
  width: 100%;
`;

export const ConversationModalContent = styled(Modal.Content)`
  &&&& {
    padding: 30px;
  }
`;

export const ConversationModalFooter = styled(Modal.Actions)`
  display: flex;
`;
