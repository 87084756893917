import { useTranslation } from 'react-i18next';

import { capitalize } from 'utils/helpers';
import { ANALYTIC_ITEM_DESCRIPTIONS } from './constants';

export function useItemDescription() {
  const { t } = useTranslation();

  const description = (item) => {
    switch (item) {
      case ANALYTIC_ITEM_DESCRIPTIONS.NEW_WEBSITE_VISITOR:
        return 'This is all traffic that comes to your websites where the VSA is installed. We remove bot traffic from this metric to give you a more accurate number of real visitors';
      case ANALYTIC_ITEM_DESCRIPTIONS.VSA_USERS:
        return 'This is the number of website visitors who interacted with the VSA';
      case ANALYTIC_ITEM_DESCRIPTIONS.VSA_INTERACTION_RATE:
        return 'This is the percentage of website visitors who clicked on at least one answer within the VSA';
      case ANALYTIC_ITEM_DESCRIPTIONS.NEW_LEADS:
        return `This is the number of ${t('leads')} we generated during this time period. A ${t(
          'lead'
        )} is defined as someone who provides their name, email and phone`;
      case ANALYTIC_ITEM_DESCRIPTIONS.VISITOR_CONVERSION:
        return `This is the percentage of website traffic that turns into a ${t('lead')} using the VSA`;
      case ANALYTIC_ITEM_DESCRIPTIONS.VSA_USER_CONVERSION:
        return `This is the percentage of VSA Users (users who interact with the VSA), that turn into a ${t('lead')}`;
      case ANALYTIC_ITEM_DESCRIPTIONS.TOURS_SCHEDULED:
        return `This is the number of ${t('leads')} generated during this period that scheduled a ${t('tour')}`;
      case ANALYTIC_ITEM_DESCRIPTIONS.CONFIRMED_MOVE_INS:
        return `This is the number of ${t('move_ins')} uploaded for this time period. ${capitalize(
          t('move_in')
        )} data is by default based on move-in date. However, if you would like to see this number based on the ${t(
          'lead'
        )} creation date, you can untoggle 'Move-in Date Reporting' switch. We update ${t(
          'move_in'
        )} data as often as we can, but there is usually a delay, and recent months always look worse, due to the time from inquiry to ${t(
          'move_in'
        )}.`;
      default:
        return '';
    }
  };

  return { description };
}
