import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { Bar, HorizontalBar } from 'react-chartjs-2';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { ReportComponent } from 'components/common/analytics';
import { STATUSES } from 'constants';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const datasetDefaults = {
  label: 'Count',
  borderColor: 'rgb(200, 200, 200)',
  backgroundColor: 'rgba(200, 200, 200, 0.5)',
};

const chartOptions = {
  maintainAspectRatio: false,
  legend: false,
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const defaultLabeler = (b, groupBy) => b[groupBy];

const defaultValue = (b) => b.value;

const TopNGraph = ({
  metric,
  filters,
  groupBy,
  title,
  tooltip,
  horizontal,
  toLabel = defaultLabeler,
  toValue = defaultValue,
  ...datasetOverrides
}) => {
  const {
    data = {},
    error,
    status,
    isFetching,
  } = useQuery(
    [queryConstants.ADMIN_METRICS, { ...filters, metric, groupBy }],
    () => getMetrics([metric], filters, groupBy),
    { placeholderData: {} }
  );

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);
  const loading = loaderStatus === STATUSES.LOADING;

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, `Unable to fetch top N metric ${metric}`));
  }

  const metrics = useMemo(() => {
    const res = data[metric]?.groups.filter((b) => b.value > 0) || [];
    const labels = res.map((b) => toLabel(b, groupBy));
    const datasets = [
      {
        ...datasetDefaults,
        data: res.map(toValue),
        ...datasetOverrides,
      },
    ];
    return { labels, datasets };
  }, [data, metric, groupBy, datasetOverrides]);

  const BarComponent = horizontal ? HorizontalBar : Bar;

  return (
    <ReportComponent title={title} tooltip={tooltip} loading={loading} contentHeight="300px">
      <BarComponent data={metrics} height={300} options={chartOptions} />
    </ReportComponent>
  );
};

export default TopNGraph;
