import React from 'react';

import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';

import { InputField } from 'components/common/fields/InputField';
import { OperationSign, OperationBox } from './wrappers';

const InformantIconBox = styled.span`
  padding: 0 5px;
`;

const UrlParametersMatchRule = ({ baseFieldName, disabled }) => {
  return (
    <OperationBox>
      <OperationSign>contains</OperationSign>
      <div>
        <InputField name={`${baseFieldName}.keyword`} placeholder="Keyword Value" type="text" disabled={disabled} />
      </div>
      <Popup
        hoverable
        trigger={
          <InformantIconBox>
            <Icon name="info" color="blue" size="small" link circular />
          </InformantIconBox>
        }
        content="The result value is the value of the matched URL parameter."
        position="top center"
        style={{ zIndex: 10000 }}
      />
    </OperationBox>
  );
};

export default UrlParametersMatchRule;
