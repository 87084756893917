import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { MessageField } from '../../MessageField';
import { If } from '../../../../If';

const LabelContainer = styled.label`
  span {
    float: right;
    padding-bottom: 10px;
    color: #1f57bd;
    cursor: pointer;
  }
`;

const ModuleFormText = ({ module }) => {
  const [preview, setPreview] = useState(false);

  return (
    <Form.Field>
      <LabelContainer>
        Text
        <If condition={module.number !== 1}>
          <span
            onClick={() => {
              setPreview(!preview);
            }}
          >
            {`${preview ? 'Hide' : 'Show'} Preview`}
          </span>
        </If>
      </LabelContainer>
      <MessageField name="module.text" preview={preview} markdown={module.number !== 1} />
    </Form.Field>
  );
};

export default ModuleFormText;
