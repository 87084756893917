import React from 'react';
import { Message, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { formatCount } from 'components/utils';
import { PageSection } from 'components/common';
import { capitalize } from 'utils/helpers';
import ComparisonCell from '../ComparisonCell';

const BenchmarkTraffic = ({ data, sourceTitle = 'Traffic Source', ...other }) => {
  const { t } = useTranslation();

  return (
    <PageSection {...other}>
      {data && data.length ? (
        <Table className="fixed-size">
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell textAlign="left">{sourceTitle}</Table.HeaderCell>
              <Table.HeaderCell>Website Visitors</Table.HeaderCell>
              <Table.HeaderCell>{capitalize(t('leads'))}</Table.HeaderCell>
              <Table.HeaderCell>Financially Unqualified</Table.HeaderCell>
              <Table.HeaderCell>Avg. {capitalize(t('move_ins'))} Likelihood</Table.HeaderCell>
              <Table.HeaderCell>Requested {capitalize(t('tours'))}</Table.HeaderCell>
              <Table.HeaderCell>{capitalize(t('move_ins'))}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((a) => (
              <Table.Row textAlign="center" key={a.source}>
                <Table.Cell textAlign="left">{a.source}</Table.Cell>
                <ComparisonCell
                  valueA={formatCount(a.websiteVisitors.internal)}
                  valueB={formatCount(a.websiteVisitors.industry)}
                  trend={a.websiteVisitors.trend}
                />
                <ComparisonCell
                  valueA={formatCount(a.leads.internal)}
                  valueB={formatCount(a.leads.industry)}
                  trend={a.leads.trend}
                />
                <ComparisonCell
                  valueA={a.financiallyUnqualified.internal}
                  valueB={a.financiallyUnqualified.industry}
                  trend={a.financiallyUnqualified.trend}
                  ascColor="red"
                  descColor="green"
                />
                <ComparisonCell
                  valueA={formatCount(a.avgLeadScore.internal)}
                  valueB={formatCount(a.avgLeadScore.industry)}
                  trend={a.avgLeadScore.trend}
                />
                <ComparisonCell
                  valueA={formatCount(a.tours.internal)}
                  valueB={formatCount(a.tours.industry)}
                  trend={a.tours.trend}
                />
                <ComparisonCell
                  valueA={formatCount(a.moveIns.internal)}
                  valueB={formatCount(a.moveIns.industry)}
                  trend={a.moveIns.trend}
                />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <Message>No data for this period</Message>
      )}
    </PageSection>
  );
};

export default BenchmarkTraffic;
