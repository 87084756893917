import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

import { confirmTour } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { ConversationContext } from 'components/conversation/ConversationContext';
import { Button, Icon, Modal } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { CONVERSATION_ACTIONS, DEFAULT_EVENT_TYPE, SCHEDULE_TYPES } from './constants';
import {
  ConversationModal,
  ConversationModalFooter,
  ConversationModalHeader,
  ConversationModalSubtitle,
} from './ConversationModal';
import { ConfirmCalendarSelector } from './ConfirmCalendarSelector';

const ModalHeader = styled(ConversationModalHeader)`
  padding: 30px 30px 10px 30px;
  h4 {
    color: var(--text-color);
    margin: 0;
    margin-top: 3px;
  }
  span {
    margin-right: 10px;
  }
`;

const ModalSubtitle = styled(ConversationModalSubtitle)`
  color: var(--light-text);
  white-space: pre-line;
  width: 100%;
  span {
    font-family: var(--font-family-bold);
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
`;

const RightActions = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: end;
`;

export const ConfirmTourCallModal = () => {
  const { t } = useTranslation();
  const {
    selectedConversation,
    eventType,
    selectedCalendar,
    fetchConversationDetails,
    activeModal,
    showModal,
    eventTypeName,
  } = useContext(ConversationContext);

  const [isLoading, setIsLoading] = useState(false);

  const actionType = SCHEDULE_TYPES(t)[eventType || DEFAULT_EVENT_TYPE];
  const event = selectedConversation && selectedConversation[eventType];

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      const response = await confirmTour(event?.hash, { calendar: selectedCalendar });

      if (response) {
        toast.success(`The ${eventTypeName} has been confirmed.`);
        fetchConversationDetails(selectedConversation.id);
        setIsLoading(false);
        showModal(null);
      }
    } catch (error) {
      console.error('error', error);
      toast.error(parseErrorResponse(error, `The ${eventTypeName} was not confirmed.`));
      setIsLoading(false);
    }
  };

  const dateTimezoned = event?.start && utcToZonedTime(event?.start, selectedConversation?.community.timezone);
  const currentDateTimezoned = utcToZonedTime(new Date(), selectedConversation?.community.timezone);
  const date = formatAnyDate(dateTimezoned, "MMM do yyyy 'at' h:mm a");

  const eventPastDue = isBefore(dateTimezoned, currentDateTimezoned);
  const confirmDisabled = eventPastDue || isLoading || !selectedCalendar;

  return (
    <ConversationModal
      closeIcon
      onClose={() => showModal(null)}
      onOpen={() => showModal(CONVERSATION_ACTIONS.CONFIRM)}
      open={activeModal === CONVERSATION_ACTIONS.CONFIRM}
      size="tiny"
    >
      <ModalHeader>
        <Icon name={actionType.icon} variant="outlined" size="22" color={actionType.color} />
        <h4>{actionType.modalHeaderConfirm}</h4>
      </ModalHeader>
      <Modal.Content>
        <ModalContent>
          <ModalSubtitle>
            The {t('leads')} <span>{selectedConversation?.name}</span> will have a {t('tour')} on <span>{date}</span>.
            <br />
            Please note that this event will be added to the selected calendar.
          </ModalSubtitle>
          <ConfirmCalendarSelector />
        </ModalContent>
      </Modal.Content>
      <ConversationModalFooter>
        <RightActions>
          <Button
            primary
            color={actionType.class}
            onClick={handleConfirm}
            loading={isLoading}
            disabled={confirmDisabled}
          >
            Confirm
          </Button>
          <Button basic onClick={() => showModal(null)} disabled={isLoading}>
            Cancel
          </Button>
        </RightActions>
      </ConversationModalFooter>
    </ConversationModal>
  );
};
