import React from 'react';
import classNames from 'classnames';
import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
`;

const Spinner = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  display: inline-block;
  background: var(--white);
  &.transparent {
    background: transparent;
  }
  &.tiny {
    width: 16px;
    height: 16px;
  }
  &.mini {
    width: 18px;
    height: 18px;
  }
  &.small {
    width: 24px;
    height: 24px;
  }
  &.large {
    width: 60px;
    height: 60px;
  }
  .bar {
    width: 5%;
    height: ${({ small }) => (small ? '15%' : '16%')};
    background: #bbb;
    position: absolute;
    left: 49%;
    top: 43%;
    opacity: 0;
    border-radius: 50px;
    animation: ${fade} 1s linear infinite;
  }

  & > .bar1 {
    -webkit-transform: rotate(0deg) translate(0, -130%);
    -webkit-animation-delay: 0s;
  }

  & > .bar2 {
    -webkit-transform: rotate(30deg) translate(0, -130%);
    -webkit-animation-delay: -0.9167s;
  }

  & > .bar3 {
    -webkit-transform: rotate(60deg) translate(0, -130%);
    -webkit-animation-delay: -0.833s;
  }
  & > .bar4 {
    -webkit-transform: rotate(90deg) translate(0, -130%);
    -webkit-animation-delay: -0.7497s;
  }
  & > .bar5 {
    -webkit-transform: rotate(120deg) translate(0, -130%);
    -webkit-animation-delay: -0.667s;
  }
  & > .bar6 {
    -webkit-transform: rotate(150deg) translate(0, -130%);
    -webkit-animation-delay: -0.5837s;
  }
  & > .bar7 {
    -webkit-transform: rotate(180deg) translate(0, -130%);
    -webkit-animation-delay: -0.5s;
  }
  & > .bar8 {
    -webkit-transform: rotate(210deg) translate(0, -130%);
    -webkit-animation-delay: -0.4167s;
  }
  & > .bar9 {
    -webkit-transform: rotate(240deg) translate(0, -130%);
    -webkit-animation-delay: -0.333s;
  }
  & > .bar10 {
    -webkit-transform: rotate(270deg) translate(0, -130%);
    -webkit-animation-delay: -0.2497s;
  }
  & > .bar11 {
    -webkit-transform: rotate(300deg) translate(0, -130%);
    -webkit-animation-delay: -0.167s;
  }
  & > .bar12 {
    -webkit-transform: rotate(330deg) translate(0, -130%);
    -webkit-animation-delay: -0.0833s;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ fullwidth }) => (fullwidth ? '100%' : 'auto')};
  height: ${({ fullwidth }) => (fullwidth ? '100%' : 'auto')};
  &.overlay {
    z-index: 50000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    ${Spinner} {
      background: transparent;
      .bar {
        background: white;
      }
    }
    &.inverted {
      background-color: rgba(255, 255, 255, 0.25);

      ${Spinner} {
        .bar {
          background: #bbb;
        }
      }
    }
  }
`;

export const Loader = ({ size, active, fullwidth, transparent, overlay, inverted }) => {
  if (!active) return null;

  const spinnerClasses = classNames(size, { transparent });
  const containerClasses = classNames({ overlay, inverted });

  return (
    <Container fullwidth={fullwidth} className={containerClasses}>
      <Spinner className={spinnerClasses}>
        {[...Array(12)].map((_, i) => (
          <div key={i} className={`bar bar${i + 1}`}></div>
        ))}
      </Spinner>
    </Container>
  );
};
