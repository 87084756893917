import React from 'react';
import styled from 'styled-components';
import { Label, Icon, Popup } from 'semantic-ui-react';

import { Module } from './Module';
import { Role } from './Role';
import { Answers } from './Answers';
import { Message } from './Message';
import { MODULE_ROLES } from './constants';

const HeaderWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Option = ({ node }) => {
  const { module } = node;
  const { role, text } = module;

  const shouldShowMessage = ![MODULE_ROLES.PRICING, MODULE_ROLES.TIME_RULE_BASED_FLOW].includes(role);

  return (
    <Module node={node}>
      {role === MODULE_ROLES.TIME_RULE_BASED_FLOW && (
        <HeaderWrapper>
          <Label color="grey">Background Operation</Label>
          <Label color="green">
            <Icon name="shuffle" /> Time Resolution
          </Label>
          <Popup hoverable trigger={<Icon name="question circle outline" />}>
            The first answer would be the default flow option if no time rule is defined, or none of defined rules are
            satisfied.
          </Popup>
        </HeaderWrapper>
      )}
      {shouldShowMessage && text && <Message>{text}</Message>}
      <Role module={module} />
      <Answers node={node} />
    </Module>
  );
};
