import React from 'react';
import UAParser from 'ua-parser-js';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { DetailsRowValue, DetailsRow, DetailsListValues } from './helpers';

export const TechnicalDetails = ({ data = {} }) => {
  const { t } = useTranslation();
  const {
    email_events,
    url_parameters,
    score,
    visited_pages,
    lead_source,
    browser,
    traffic_source,
    viewed_images,
    viewed_videos,
  } = data;

  const userAgent = browser && UAParser(browser);

  const emailEvents = (email_events && Object.keys(email_events)) || [];

  const deviceType = userAgent?.device?.type || '-';
  const browserName = userAgent?.browser?.name ? `/${userAgent?.browser?.name}` : '-';
  const operatingSystemName = userAgent?.os?.name ? `/${userAgent?.os?.name}` : '-';

  const browserInfo = userAgent && `${deviceType}${operatingSystemName}${browserName}`;
  const browserVersion = userAgent?.browser?.version;

  const urlParams = (url_parameters && Object.entries(url_parameters)) || [];

  return (
    <>
      <DetailsRow>
        <span>{capitalize(t('lead'))} Source</span>
        <DetailsRowValue>{lead_source || '-'}</DetailsRowValue>
      </DetailsRow>
      <DetailsRow>
        <span>Move-in Score</span>
        <DetailsRowValue>{score !== null ? score : '-'}</DetailsRowValue>
      </DetailsRow>
      <DetailsRow className="dense">
        <span>Browser</span>
        <DetailsRowValue>
          {browserInfo ? (
            <>
              <span>{browserInfo}</span>
              <span className="footnote">v{browserVersion}</span>
            </>
          ) : (
            '-'
          )}
        </DetailsRowValue>
      </DetailsRow>
      <DetailsRow className="dense">
        <span>Traffic Source</span>
        <DetailsRowValue>{traffic_source || '-'}</DetailsRowValue>
      </DetailsRow>
      <DetailsRow>
        <span>Visited Pages</span>
        <DetailsRowValue>{visited_pages || '-'}</DetailsRowValue>
      </DetailsRow>
      <DetailsRow>
        <span>Viewed Images</span>
        <DetailsRowValue>{viewed_images ? <Icon name="check" color="green" /> : '-'}</DetailsRowValue>
      </DetailsRow>
      <DetailsRow>
        <span>Viewed Videos</span>
        <DetailsRowValue>{viewed_videos ? <Icon name="check" color="green" /> : '-'}</DetailsRowValue>
      </DetailsRow>
      <DetailsRow>
        <span>URL Parameters</span>
        <DetailsListValues>
          {urlParams.length === 0 && '-'}
          {urlParams.map(([key, value]) => (
            <DetailsRowValue key={key}>
              <span>{value}</span>
              <span className="footnote">{key}</span>
            </DetailsRowValue>
          ))}
        </DetailsListValues>
      </DetailsRow>
      <DetailsRow>
        <span>Email Events</span>
        <DetailsListValues>
          {emailEvents.length === 0 && '-'}
          {emailEvents.map((eventName) => (
            <DetailsRowValue key={eventName}>
              <span>{eventName}</span>
              <span className="footnote">{email_events[eventName].join(', ')}</span>
            </DetailsRowValue>
          ))}
        </DetailsListValues>
      </DetailsRow>
    </>
  );
};
