import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import queryConstants from 'utils/queries/constants';
import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { queryStatusToLoaderStatus } from 'utils/helpers';

export const useMetrics = (metrics, filters, timeField) => {
  const { data, error, status, isLoading, isFetching } = useQuery(
    [queryConstants.ADMIN_METRICS, { ...filters, metrics, timeField }],
    () => getMetrics(metrics, filters, null, null, timeField),
    {
      placeholderData: {},
      onError: () => {
        toast.error(parseErrorResponse(error, 'Unable to fetch calls metrics'));
      },
    }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);
  return { data, error, status, isLoading, loaderStatus };
};
