import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Background from 'assets/images/auth-backsplash.jpg';
import WhiteLogo from 'assets/images/white-logo.png';
import { Button } from 'components/lib';
import { If } from 'components/If';
import { MEDIA_BREAK_POINTS, TALK_FURTHER } from '../../constants';

const BackgroundImage = styled.div`
  background-image: url(${Background});
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center, center;
  background-color: rgba(0, 0, 0, 0.4);

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    pointer-events: none;
  }
`;

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const Content = styled.div`
  display: flex;
  gap: 5rem;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
`;

const StatusCode = styled.div`
  font-size: 200px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
`;

const ContentData = styled.div`
  margin-top: 14px;
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
`;

const Description = styled.div`
  max-width: 350px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
  margin-top: 10px;
`;

const Contact = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
  margin-top: 20px;
  margin-bottom: 30px;
`;

const Link = styled.a`
  color: inherit;
  text-decoration: underline;
  &:hover {
    color: inherit;
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding-bottom: 55px;
`;

const FooterText = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
`;

const GO_BACK_URL = {
  DEFAULT: '/startup',
  MESSAGES: '/messages',
  CONVERSATIONS: '/conversations',
  NURTURE_CONVERSATIONS: '/messaging/conversations',
};

const Forbidden = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const q = queryParams.get('q');
  const goBackURL = useMemo(() => {
    if (!q) {
      return GO_BACK_URL.DEFAULT;
    }
    if (q.startsWith('/messages')) {
      return GO_BACK_URL.MESSAGES;
    }
    if (q.startsWith('/conversations')) {
      return GO_BACK_URL.CONVERSATIONS;
    }
    if (q.startsWith('/messaging/conversations')) {
      return GO_BACK_URL.NURTURE_CONVERSATIONS;
    }

    return GO_BACK_URL.DEFAULT;
  }, [q]);

  const handleGoBack = () => {
    history.replace(goBackURL);
  };

  return (
    <>
      <BackgroundImage />
      <Container>
        <Content>
          <StatusCode>403</StatusCode>
          <ContentData>
            <Title>Whoops</Title>
            <Description>You do not seem to have the permission to access that.</Description>
            <Contact>
              Contact <Link href={`mailto:${TALK_FURTHER.SUPPORT_EMAIL}`}>{TALK_FURTHER.SUPPORT_EMAIL}</Link> for more.
            </Contact>
            <If condition={goBackURL}>
              <Button basic circular inverted onClick={handleGoBack}>
                Go Back
              </Button>
            </If>
          </ContentData>
        </Content>
        <Footer>
          <img src={WhiteLogo} alt="Logo" width="20px" height="19px" />
          <FooterText>
            Go back to the <Link href="/startup">Further website</Link>
          </FooterText>
        </Footer>
      </Container>
    </>
  );
};

export { Forbidden };
