import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Dimmer, Divider, Tab, Loader } from 'components/lib';
import { If } from 'components/If';
import useDataList from 'utils/hooks/useDataList';
import { getFloorPlans, deleteFloorPlan } from 'apis';
import { STATUSES } from 'constants';

import { UserCommunityPanelHeader } from './UserCommunityPanelHeader';
import UserCommunityFloorPlansTable from './UserCommunityFloorPlansTable';
import UserCommunityFloorPlanModal from './UserCommunityFloorPlanModal';
import UserCommunityDefaultFloorPlanForm from './UserCommunityDefaultFloorPlanForm';

export const UserCommunityFloorPlans = () => {
  const { communityId } = useParams();
  const [showAddNewPlanForm, setShowAddNewPlanForm] = useState(false);
  const [currentPlansLoading, setCurrentPlansLoading] = useState(STATUSES?.IDLE);
  const [deletePlanLoading, setDeletePlanLoading] = useState(STATUSES?.IDLE);
  const [editableFloorPlan, setEditableFloorPlan] = useState({});
  const { items, totalPages, filters, setItems, setPage } = useDataList();

  const getCurrentFloorPlans = async ({ page, pageSize }) => {
    setCurrentPlansLoading(STATUSES?.LOADING);
    try {
      const response = await getFloorPlans(communityId, {
        page,
        page_size: pageSize,
      });
      setItems({ results: response?.results, count: response?.count });
      setCurrentPlansLoading(STATUSES?.LOADED);
    } catch (e) {
      console.error(e);
      toast.error("Couldn't fetch current floor plans! Please try again");
      setCurrentPlansLoading(STATUSES?.FAILURE);
    }
  };

  const handleDeleteFloorPlan = async (floorPlanId) => {
    try {
      setDeletePlanLoading(STATUSES?.LOADING);
      await deleteFloorPlan(communityId, floorPlanId);
      await getCurrentFloorPlans(filters);
      toast.success('Floor plan deleted successfully!');
      setShowAddNewPlanForm(false);
    } catch (e) {
      console.error(e);
      toast.error('Unable to delete floor plan!');
    } finally {
      setDeletePlanLoading(STATUSES?.LOADED);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleClose = () => {
    setShowAddNewPlanForm(false);
    getCurrentFloorPlans(filters);
    setEditableFloorPlan({});
  };

  useEffect(() => {
    getCurrentFloorPlans(filters);
  }, [filters, communityId]);

  useEffect(() => {
    setShowAddNewPlanForm(false);
    setEditableFloorPlan({});
  }, [communityId]);

  return (
    <Tab.Pane padding="30px">
      <If condition={!showAddNewPlanForm}>
        <UserCommunityPanelHeader content="Add new floor plan" onUpdate={() => setShowAddNewPlanForm(true)} />
      </If>

      <UserCommunityFloorPlanModal
        open={showAddNewPlanForm}
        editableFloorPlan={editableFloorPlan}
        onClose={handleClose}
      />

      <UserCommunityDefaultFloorPlanForm />

      <Divider section horizontal>
        Current floor plans
      </Divider>
      <Dimmer active={currentPlansLoading === STATUSES?.LOADING} inverted>
        <Loader active size="large">
          Loading
        </Loader>
      </Dimmer>
      <UserCommunityFloorPlansTable
        currentFloorPlans={items}
        onPageChange={handlePageChange}
        activePage={filters.page}
        totalPages={totalPages}
        onConfirm={handleDeleteFloorPlan}
        loading={deletePlanLoading}
        onEdit={(floorPlan) => {
          setShowAddNewPlanForm(true);
          setEditableFloorPlan(floorPlan);
        }}
      />
    </Tab.Pane>
  );
};
