import React from 'react';
import { Switch } from 'react-router';

import { PublicRoute } from '../common/routes';
import { LoginPassword } from './LoginPassword/LoginPassword';
import { LoginMagicLink } from './LoginMagicLink';
import { ForgotPassword } from './ForgotPassword';

const AuthRoutes = () => {
  return (
    <Switch>
      <PublicRoute exact path="/login/pwd">
        <LoginPassword />
      </PublicRoute>
      <PublicRoute exact path="/login/otp">
        <LoginMagicLink />
      </PublicRoute>
      <PublicRoute path="/login/forgot-password">
        <ForgotPassword />
      </PublicRoute>
    </Switch>
  );
};

export default AuthRoutes;
