import React from 'react';
import styled from 'styled-components';
import { Header, Dimmer, Loader } from 'semantic-ui-react';

import { If } from 'components/If';
import { STATUSES } from '../../constants';

const Wrapper = styled.div`
  position: relative;
  margin-top: 1rem;
  margin-bottom: 4rem;
  padding: 1rem 0;

  .ui.labeled.icon.button,
  .ui.labeled.icon.buttons .button {
    padding: 0.9rem;
    font-size: 1rem;
  }

  .ui.statistic > .value,
  .ui.statistics .statistic > .value,
  .ui.statistic > .label,
  .ui.statistics .statistic > .label,
  :not(.ui.green.statistics) {
    color: #333;
    font-weight: 100;
  }

  .ui.table {
    &.fixed-size {
      font-size: 0.9rem;

      th:first-child,
      td:first-child {
        width: 20%;
      }

      th,
      td {
        width: 10%;
      }

      thead {
        tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }
      }

      tbody {
        max-height: 25rem;
        min-height: 3rem;
        overflow-y: auto;
        display: block;
        will-change: transform;

        tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }
      }

      &.tall {
        tbody {
          max-height: 50rem;
        }
      }
    }

    &.selectable {
      td {
        cursor: pointer;
      }
    }
  }
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  .ui.header {
    margin: 0;
    color: #444;
  }

  .ui.header:first-child {
    margin-top: 0;
  }
`;

const Content = styled.div`
  position: relative;
  min-height: 5rem;
`;

export const PageSection = ({ as, title, subtitle, loaderStatus, loading, actions, children }) => {
  const isLoading = loading || loaderStatus === STATUSES.LOADING;

  return (
    <Wrapper>
      <If condition={title || subtitle || actions}>
        <TitleBar>
          <Header as={as || 'h3'}>
            <Header.Content>
              {title}
              {subtitle && <Header.Subheader>{subtitle}</Header.Subheader>}
            </Header.Content>
          </Header>
          <div>{actions}</div>
        </TitleBar>
      </If>

      <Content>
        <If condition={!isLoading}>{children}</If>

        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Content>
    </Wrapper>
  );
};
