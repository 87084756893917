import React from 'react';

const AnswerValue = ({ children }) => {
  if (children && typeof children === 'object') {
    return <pre>{JSON.stringify(children)}</pre>;
  }
  return <span>{children}</span>;
};

export const AnswerValueChange = ({ changes, showOldValue }) => {
  const { hasOldValues, ...valueChanges } = changes;
  const props = Object.entries(valueChanges).sort((a, b) => (a[0] < b[0] ? -1 : 1));

  if (!hasOldValues) {
    return null;
  }

  return (
    <>
      {props.map(([key, value], idx) => (
        <div key={idx}>
          <b>{key}</b>: <AnswerValue>{value[showOldValue ? 0 : 1]}</AnswerValue>
        </div>
      ))}
    </>
  );
};
