import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Message } from 'semantic-ui-react';

import { getTrackingAnalytics } from '../../../apis';
import { PAGE_HEADER_MARGIN, PageSection } from '../../common';
import TabMenu from '../../common/TabMenu';
import { ANALYTICS_MENU_ITEMS } from './constants';
import { AnalyticsIntegration } from './AnalyticsIntegration';
import { AnalyticsChatModules } from './AnalyticsChatModules';
import { ChatsContext } from '../ChatsContext';
import { If } from '../../If';
import { STATUSES } from '../../../constants';

export const MarketingAnalytics = () => {
  const { chats, loadChats, loaderStatus, setAnalytics } = useContext(ChatsContext);

  useEffect(() => {
    loadChats();
  }, []);

  useEffect(() => {
    const loadAnalytics = async () => {
      try {
        const analytics = await getTrackingAnalytics();
        setAnalytics(analytics);
      } catch (e) {
        toast.error('Trouble fetching analytics integration settings!');
        console.error(e);
      }
    };

    loadAnalytics();
  }, [setAnalytics]);

  const hasChats = chats?.length > 0;
  return (
    <PageSection
      as="h2"
      title="Marketing Analytics"
      subtitle="Apply analytics integration codes to individual chat module analytics."
      margin={PAGE_HEADER_MARGIN.LARGE}
    >
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <Message
        warning
        icon="info circle"
        content="No chats found!"
        hidden={hasChats || loaderStatus !== STATUSES.LOADED}
      />

      <If condition={hasChats}>
        <TabMenu items={ANALYTICS_MENU_ITEMS} />
        <Switch>
          <Route exact path="/chats/analytics">
            <AnalyticsIntegration />
          </Route>
          <Route exact path="/chats/analytics/details/:chatId?">
            <AnalyticsChatModules />
          </Route>
        </Switch>
      </If>
    </PageSection>
  );
};
