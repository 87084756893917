import styled from 'styled-components';

import { Modal } from 'components/lib';

export const RecordingsModal = styled(Modal)`
  &&&& {
    .actions {
      padding: 20px 30px !important;
    }
  }
`;

export const RecordingsModalHeader = styled(Modal.Header)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h4 {
    font-family: var(--font-family-bold);
    font-size: 20px;
    color: var(--text);
  }
`;

export const RecordingsModalContent = styled(Modal.Content)`
  &&&& {
    padding: 30px;
  }
`;

export const RecordingsModalActions = styled(Modal.Actions)``;
