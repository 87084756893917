import React, { useEffect, useCallback, useContext, useState } from 'react';
import { Button, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getManagedUsers } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import SearchInput from 'components/SearchInput';
import { PageHeader, PAGE_HEADER_MARGIN } from 'components/common';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import { UserAccountsContext } from './UserAccountsContext';
import { SettingsContext } from '../SettingsContext';
import { MEDIA_BREAK_POINTS, STATUSES } from '../../../constants';
import UserAccountTable from './UserAccountTable';
import { MultiSelectFilter } from '../../common';

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  & > .input {
    flex: 1 0 auto;
    margin-bottom: 10px;
  }
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
  }
`;

const StyledMultiSelectFilter = styled(MultiSelectFilter)`
  .ui.dropdown {
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
    border: solid 1px var(--line-standard);
    border-radius: 0px;
    background-color: white;
    min-width: 20rem;
    font-weight: 500;
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--text-color);
    height: 36px;
  }
`;

const UserAccountList = () => {
  const { t } = useTranslation();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters } = useContext(UserAccountsContext);
  const { communities } = useContext(SettingsContext);

  const fetchUsers = useCallback(
    async ({ page, pageSize, search, communities }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await getManagedUsers({
          page,
          page_size: pageSize,
          search,
          managed_communities: communities && communities.join(','),
        });

        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        toast.error(parseErrorResponse(e), 'Unable to fetch user accounts');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchUsers(filters);
  }, [fetchUsers, filters]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const onSelectedCommunitiesChange = (communities) => {
    setFilters({ communities });
  };

  return (
    <Segment basic padded="very">
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <If condition={loaderStatus !== STATUSES.FAILURE}>
        <PageHeader
          title="User accounts"
          subtitle={`Manage user accounts and ${t('communities')}.`}
          block
          margin={PAGE_HEADER_MARGIN.SMALL}
          actions={
            <Link to="/settings/user-accounts/new">
              <Button primary content="Add user" />
            </Link>
          }
        />
        <FilterContainer>
          <StyledMultiSelectFilter
            fullWidth
            name={capitalize(t('communities'))}
            placeholder={`Any ${t('community')}`}
            options={communities}
            selectedValues={filters.managed_communities}
            onChange={onSelectedCommunitiesChange}
          />
          <SearchInput value={filters.search} fluid onSearch={handleSearch} placeholder="Search users by email, name" />
        </FilterContainer>
        <UserAccountTable
          users={items}
          activePage={filters.page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </If>
      <If condition={loaderStatus === STATUSES.FAILURE}>
        <FailureMessage icon="search" content="Users could not be loaded" />
      </If>
    </Segment>
  );
};

export default UserAccountList;
