import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Divider } from 'components/lib';
import { resetOtpLink } from '../../api';
import { TALK_FURTHER } from '../../constants';
import { useUserContext } from '../UserContext';
import { AuthLayout, FormFooter } from './AuthLayout';

export const OtpReset = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { user } = useUserContext();
  // code refers to code of the previos / expired Link
  const { next_url, code } = Object.fromEntries(new URLSearchParams(window.location.search).entries());

  useEffect(() => {
    if (user) {
      // There's no point in showing the Login Code Expired prompt if the user is already logged-in
      history.push('/startup');
    }
  }, [user]);

  const reset = async () => {
    try {
      setLoading(true);
      await resetOtpLink({ next_url, code });
      history.push('/login?showLoginCodeForm=true');
    } catch (e) {
      console.error(e);
      toast.error(
        `Something went wrong and your request couldn't be processed. Please contact ${TALK_FURTHER.SUPPORT_EMAIL}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <h1>Login Code Expired</h1>
      <p>
        You can request a new one by clicking the button below. If you don't get a new code, please email&nbsp;
        <a href={`mailto:${TALK_FURTHER.SUPPORT_EMAIL}`}>{TALK_FURTHER.SUPPORT_EMAIL}</a>
      </p>

      <Divider horizontal />

      <Button
        primary
        color="orange"
        fluid
        content="Send me a New Login Code"
        loading={loading}
        disabled={loading}
        onClick={() => {
          reset();
        }}
      />

      <FormFooter>
        <span
          onClick={() => {
            history.push('/login');
          }}
        >
          Log in with different account
        </span>
      </FormFooter>
    </AuthLayout>
  );
};
