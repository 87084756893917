import React, { useState, useContext, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { getPhoneCallDetails } from 'apis';
import { formatSecondsToTime } from 'utils/helpers';
import { UserContext } from 'components/UserContext';
import { AudioPlayer, Icon } from 'components/lib';
import { If } from 'components/If';
import { formatAnyDate } from 'components/utils';
import { CALL_DIRECTION_TYPES } from './constants';
import { PhoneCallNoteModal } from '../call/EditNote';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const noteStyles = css`
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const Container = styled.div`
  padding: 8px 20px 8px 10px;
  border-radius: 18px;
  box-shadow: 0 3px 5px 0 rgba(216, 216, 216, 0.85);
  background-color: var(--white);
  min-width: min(95%, 400px);
  &.outbound {
    min-width: min(95%, 625px);
    background-image: linear-gradient(284deg, #333059, #444077 40%);
    p,
    span {
      color: var(--white);
    }
  }
  margin: 10px 0;
  ${({ $note }) => $note && noteStyles}
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div:first-child {
    width: 100%;
  }
  gap: 10px;
`;

const Time = styled.span`
  font-family: var(--font-family);
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: right;
  color: #89919d;
  width: fit-content;
  text-wrap: nowrap;
`;
const CallDuration = styled.span`
  margin: 0 5px;
  color: #89919d;
`;

const MessageBox = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  color: var(--text);
  margin: 0;
  span {
    margin-top: 2px;
  }
`;

const NoteBox = styled.div`
  background-color: var(--white);
  min-width: min(95%, 625px);
  padding: 8px 18px;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #89919d;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Action = styled.p`
  font-size: 14px;
  color: var(--text);
`;

export const ConversationPhoneCallInfo = ({ data }) => {
  const { id, created_on, call_duration_seconds, is_playable, direction, note, caller_user } = data;
  const { user } = useContext(UserContext);
  const [playing, setPlaying] = useState(false);
  const [localUrl, setLocalUrl] = useState(null);
  const [localNote, setLocalNote] = useState(note);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const isOutbound = direction === CALL_DIRECTION_TYPES.OUTBOUND;
  const message = isOutbound ? 'Outgoing call' : 'Incoming call';

  const updateNote = useCallback(
    (newNote) => {
      setLocalNote(newNote);
    },
    [setLocalNote]
  );

  const toggleModal = useCallback(() => {
    setEditModalOpened((prev) => !prev);
  }, [setEditModalOpened]);

  const handlePlay = async () => {
    const { recordingUrl } = await getPhoneCallDetails(id);
    setLocalUrl(recordingUrl);

    if (is_playable) {
      setPlaying(true);
      return;
    }

    window.open(recordingUrl, '_blank');
  };

  const callerName = isOutbound ? caller_user?.name || caller_user?.email : undefined;
  const playingText = isOutbound ? `Playing call from ${callerName}` : 'Playing call';

  return (
    <Wrapper>
      <Container className={direction} $note={Boolean(note)}>
        <If condition={!playing}>
          <Icon name="play_arrow" size="24" color="var(--emerald-green)" onClick={handlePlay} />
          <MessageBox>
            <span>{message}</span>
            <CallDuration>{formatSecondsToTime(call_duration_seconds)}</CallDuration>
          </MessageBox>
        </If>
        <If condition={playing && localUrl}>
          <AudioPlayer
            url={localUrl}
            playPauseIconColor={isOutbound ? 'var(--white)' : 'var(--text)'}
            duration={call_duration_seconds}
            text={playingText}
            overRidePlayPause={playing}
          />
        </If>
        <Time>{formatAnyDate(created_on, 'h:mm a')}</Time>
      </Container>
      <If condition={note}>
        <NoteBox>
          <If condition={caller_user?.id === user?.id}>
            <Icon name="edit_note" size="14" color="var(--vivid-blue)" onClick={toggleModal} />
          </If>
          <div>
            <Action>{callerName} added a note.</Action>
            {localNote}
          </div>
        </NoteBox>
      </If>
      <If condition={editModalOpened}>
        <PhoneCallNoteModal data={data} id={id} closeModal={toggleModal} updateNote={updateNote} />
      </If>
    </Wrapper>
  );
};
