import React from 'react';
import styled from 'styled-components';

const StyledRating = styled.span`
  color: var(--emerald-green);
  font-size: 16px;
`;

const MAXIMUM_RATING = 5;

export const Rating = ({ number = MAXIMUM_RATING }) =>
  [...Array(number)].map((_, index) => (
    <StyledRating key={index} className="material-icons">
      star
    </StyledRating>
  ));
