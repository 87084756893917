import React, { useState } from 'react';
import styled from 'styled-components';

import { Dropdown } from 'components/lib';
import SearchInput from 'components/SearchInput';
import { EXPERIMENT_TYPES_OPTIONS, ORDERING_VALUES } from './constants';
import { ExperimentsTable } from './ExperimentsTable';
import { useExperiments } from './context/ExperimentsContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  padding: 0 30px;
  && {
    > .dropdown {
      width: 170px;
    }
    > .input {
      width: 360px;
      margin-left: auto;
    }
  }
`;

export const ExperimentsActive = () => {
  const { filters, setFilters } = useExperiments();
  const { search, types, ordering } = filters;
  const handleSearch = (value) => {
    setFilters({ ...filters, search: value });
  };

  const handleTypeChange = (e, { value }) => {
    setFilters({ ...filters, types: value });
  };

  const handleOrderingChange = (e, { value }) => {
    setFilters({ ...filters, ordering: value });
  };

  return (
    <Wrapper>
      <Filters>
        <Dropdown
          className="types-dropdown"
          basic
          selection
          fluid
          options={EXPERIMENT_TYPES_OPTIONS}
          value={types}
          onChange={handleTypeChange}
          placeholder="Any type"
        />
        <Dropdown
          className="types-dropdown"
          basic
          selection
          fluid
          value={ordering}
          options={ORDERING_VALUES}
          onChange={handleOrderingChange}
        />
        <SearchInput value={search} onSearch={handleSearch} placeholder="Search by test name" />
      </Filters>
      <ExperimentsTable />
    </Wrapper>
  );
};
