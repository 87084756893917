import React from 'react';
import styled from 'styled-components';

import { formatPositiveCount } from 'components/utils';
import { Statistic } from 'components/lib';

const Container = styled.div`
  display: flex;
  gap: 20px;
`;

export const Metrics = ({ metrics = {} }) => {
  const { scheduledMessagesCount, notReviewedMessagesCount } = metrics;

  return (
    <Container>
      <Statistic title="Scheduled" subtitle="SINCE LAST TIME" value={formatPositiveCount(notReviewedMessagesCount)} />
      <Statistic title="Total" subtitle="Scheduled" value={formatPositiveCount(scheduledMessagesCount)} />
    </Container>
  );
};
