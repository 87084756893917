import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { Segment } from 'semantic-ui-react';
import { add, format, differenceInDays, parseISO } from 'date-fns';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { ReportComponent, TimeSeriesChart } from 'components/common/analytics';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { STATUSES } from 'constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const METRIC_DATASET = {
  reviewed_accepted: {
    label: 'Accepted',
    borderColor: '#275aff',
    backgroundColor: '#275aff',
  },
  reviewed_declined: {
    label: 'Declined',
    borderColor: '#fa7550',
    backgroundColor: '#fa7550',
  },
};

const tooltip = (
  <ul style={{ width: '400px' }}>
    <li>Accepted: Automated answer accepted by reviewer</li>
    <li>Declined: Automated answer declined by reviewer</li>
  </ul>
);

const chartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          borderDash: [2, 10],
          drawBorder: false,
        },
        ticks: {
          padding: 16,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          borderDash: [2, 10],
          drawBorder: false,
        },
        ticks: {
          min: 0,
          padding: 16,
        },
      },
    ],
  },
};

const groupBy = 'time';
const metrics = ['answers.reviewed_accepted', 'answers.reviewed_declined'];

export const ReviewsTimeSeriesChart = () => {
  const { dateRange, reviewFilters: filters } = useSystemStatsFilters();
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.ADMIN_METRICS, { ...filters, metrics }],
    () => getMetrics(metrics, filters, groupBy, null, 'review__reviewed_on'),
    { placeholderData: {} }
  );

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch calls metrics'));
  }

  const chartData = useMemo(() => {
    const start = parseISO(dateRange.start_date);
    const end = parseISO(dateRange.end_date);
    const diff = differenceInDays(end, start);
    const labels = [];
    for (let i = 0; i <= diff; i += 1) {
      const day = format(add(start, { days: i }), 'yyyy-MM-dd');
      labels.push(day);
    }
    if (!data || !data[metrics[0]]) {
      return { labels, datasets: [] };
    }
    const dataLookup = metrics.reduce((lookup, m) => {
      lookup[m] = data[m].groups.reduce((r, obj) => {
        r[obj.time] = obj.value;
        return r;
      }, {});
      return lookup;
    }, {});

    const datasets = metrics.map((m) => ({
      ...METRIC_DATASET[m.substring(8)],
      data: labels.map((l) => dataLookup[m][l] || 0),
    }));
    return { labels, datasets };
  }, [data, dateRange]);

  return (
    <ReportComponent title="Reviews" tooltip={tooltip} loading={loaderStatus === STATUSES.LOADING}>
      <TimeSeriesChart data={chartData} height={300} options={chartOptions} type="bar" />
    </ReportComponent>
  );
};
