import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

import { If } from 'components/If';
import { MEDIA_BREAK_POINTS } from '../../constants';
import { setPrevStep, UNIT_LEVEL_PRICING_FIRST_STEP, useUnitLevelPricing } from './context';

const ActionsBox = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column-reverse;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
    justify-content: end;
  }
`;

const ActionButton = styled(Button)`
  && {
    margin-bottom: 0.5rem;

    @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      width: 15rem;
    }
  }
`;

const UnitLevelPricingStepActions = ({ onNextStep, nextStepLabel = 'Continue', disabled }) => {
  const [{ activeStep }, dispatch] = useUnitLevelPricing();

  const handlePrevStep = () => {
    dispatch(setPrevStep());
  };

  return (
    <ActionsBox>
      <If condition={activeStep !== UNIT_LEVEL_PRICING_FIRST_STEP}>
        <ActionButton type="button" content="Back" onClick={handlePrevStep} />
      </If>
      <ActionButton type="submit" primary content={nextStepLabel} disabled={disabled} onClick={onNextStep} />
    </ActionsBox>
  );
};

export default UnitLevelPricingStepActions;
