import React from 'react';
import styled from 'styled-components';

import { UserCommunityHomeSidebarShortcuts } from './UserCommunityHomeSidebarShortcuts';
import { UserCommunityHomeSidebarPricing } from './UserCommunityHomeSidebarPricing';

const Container = styled.div`
  margin-left: auto;
`;

export const UserCommunityHomeSidebar = () => {
  return (
    <Container>
      <UserCommunityHomeSidebarShortcuts />
      <UserCommunityHomeSidebarPricing />
    </Container>
  );
};
