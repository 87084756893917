import { API } from '../api';

export const getStates = async () => {
  const response = await API.get('/api/chat/locations/states');
  return response.data;
};

export const getCitiesByState = async (stateCode, search = '') => {
  const query = new URLSearchParams({ search }).toString();
  const url = `/api/chat/locations/states/${stateCode}/cities?${query}`;
  const response = await API.get(url);
  return response.data;
};

export const validateLocations = async (data) => {
  const response = await API.post('/api/chat/locations/validation', data);
  return response.data;
};

export const validateContactInfo = async (data) => {
  const response = await API.post('/api/chat/locations/contact-info-validation', data);
  return response.data;
};
