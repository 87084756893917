import React, { createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import reducer from './reducer';

import { setIsMultipleSelection } from './actions';

const SettingsContext = createContext();

export const SettingsProvider = (props) => {
  const INITIAL_STATE = {
    selectedItems: [],
    selectedPages: [],
    allItemsSelected: false,
    excludedItems: [],
    isMultipleSelection: false,
    editModalOpen: false,
    singleSelection: false,
  };

  const [state, dispatch] = useImmerReducer(reducer, {
    ...INITIAL_STATE,
  });

  const { location } = useHistory();

  useEffect(() => {
    if (state.isMultipleSelection) {
      dispatch(setIsMultipleSelection());
    }
  }, [location.pathname]);

  const value = [state, dispatch];

  return <SettingsContext.Provider value={value}>{props.children}</SettingsContext.Provider>;
};

export function useSettings() {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error(`useSettings must be used within SettingsProvider`);
  }

  return context;
}
