import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { USER_TYPES, useUserContext } from 'components/UserContext';
import { If } from 'components/If';
import { Icon, Button, Popup, Dimmer, Loader } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { updateMessagingCampaignsPriority } from 'apis';
import {
  Column,
  Header,
  HeaderIconWrapper,
  HeaderTitle,
  HeaderSubtitle,
  ListWrapper,
  ListRow,
  CampaignListButtonWrapper,
  StatisticsContent,
  StatisticsTypesContent,
  campaignRow,
  CampaignListIcons,
} from './common';
import { EmptyCampaigns } from './common/EmptyCampaigns';
import { useCampaigns } from './CampaignsContext';
import { CAMPAIGN_TYPE, CAMPAIGN_PRIORITY_ACTIONS, NURTURE_CAMPAIGNS_ROUTE, CAMPAIGN_TYPE_LABEL } from '../constants';

const OrderingIndicator = styled.div`
  width: 22px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid var(--line-standard);
  background-color: #fff;
  position: absolute;
  right: -12px;
`;

const Arrow = styled.div`
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--line-standard);
`;

const AddCampaignText = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  max-width: 344px;
`;

const Options = styled.div`
  &,
  a {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
  }
  width: 160px;
  height: 71px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #ffffff;
`;

const Option = styled.div`
  height: 36px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid var(--line-standard);
  }
`;

const PopupStyle = {
  borderRadius: 0,
  backgroundColor: 'transparent',
  padding: 0,
  border: 'none',
  boxShadow: 'none',
};

const AddCampaignWrapper = styled.div`
  .trigger {
    width: 160px;
    height: 40px;
    margin-top: 20px;
  }
`;

const getSalesCampaigns = (campaigns) => {
  return campaigns?.filter((campaign) => campaign?.campaignType === CAMPAIGN_TYPE.SALES);
};

const CampaignSalesList = () => {
  const { t } = useTranslation();
  const {
    state: {
      campaigns,
      campaignsLoading,
      statisticsLoading,
      statistics,
      communitiesCount: totalCommunitiesCount,
      quickSetupLoading,
      invalidCampaignIds,
    },
    dispatch,
    actions,
    fetchCampaigns,
  } = useCampaigns();
  const { isUserType } = useUserContext();
  const history = useHistory();

  const campaignsList = getSalesCampaigns(campaigns);

  const updateCampaignPriority = async (e, data) => {
    e.stopPropagation();
    const params = {
      id: data?.campaign?.id,
      action: data?.action,
    };

    try {
      dispatch({ type: actions.SET_CAMPAIGNS_LOADING, data: true });
      await updateMessagingCampaignsPriority(params);
      dispatch({ type: actions.SET_CAMPAIGNS_LOADING, data: false });
      toast.success('Sales campaigns reordered.');
      fetchCampaigns();
    } catch (e) {
      console.error(e);
      toast.error('Error reordering sales campaigns.');
      dispatch({ type: actions.SET_CAMPAIGNS_LOADING, data: false });
    }
  };

  const viewCampaignDetails = (campaign) => {
    history.push({
      pathname: `${NURTURE_CAMPAIGNS_ROUTE}/${CAMPAIGN_TYPE.SALES}/details/${campaign.id}`,
    });
  };

  const openAddCampaignModal = () => {
    dispatch({
      type: actions.SET_ADD_CAMPAIGN_MODAL_OPEN,
      data: { isOpen: true, campaignType: CAMPAIGN_TYPE.SALES, label: CAMPAIGN_TYPE_LABEL.SALES },
    });
  };

  const listCampaigns = campaignsList?.map((campaign) =>
    campaignRow({
      campaign,
      totalCommunitiesCount,
      isUserType,
      statistics,
      t,
      invalidCampaignIds,
    })
  );

  return (
    <Column>
      <Dimmer active={!campaignsList || campaignsLoading} inverted>
        <Loader active size="large" transparent />
      </Dimmer>
      <Header>
        <HeaderIconWrapper>
          <Icon name="campaign" size="24" color="#2791ff" />
        </HeaderIconWrapper>
        <div>
          <HeaderTitle>Sales Campaigns</HeaderTitle>
          <HeaderSubtitle>Campaigns for newly created {t('leads')} who have not responded yet.</HeaderSubtitle>
        </div>
      </Header>
      <If condition={!campaignsLoading && campaignsList?.length === 0}>
        <EmptyCampaigns
          title="There are no sales campaigns."
          subtitle={
            isUserType([USER_TYPES.STAFF, USER_TYPES.REGULAR])
              ? 'You can set up standard sales campaigns with a single click.'
              : ''
          }
        >
          <Dimmer active={quickSetupLoading} inverted>
            <Loader active size="large" transparent />
          </Dimmer>

          <If condition={isUserType([USER_TYPES.STAFF, USER_TYPES.REGULAR])}>
            <AddCampaignWrapper>
              <Button primary color="blue" onClick={openAddCampaignModal} type="button" className="trigger">
                Add Campaign
              </Button>
            </AddCampaignWrapper>
          </If>
        </EmptyCampaigns>
      </If>
      <If condition={campaignsList?.length > 0}>
        <ListWrapper>
          {listCampaigns?.map((campaign) => {
            return (
              <ListRow key={campaign?.id} onClick={() => viewCampaignDetails(campaign)}>
                <CampaignListIcons campaign={campaign} />
                <StatisticsContent campaign={campaign} statistics={statistics} statisticsLoading={statisticsLoading} />
                <StatisticsTypesContent campaign={campaign} />
                <If condition={isUserType([USER_TYPES.STAFF, USER_TYPES.REGULAR])}>
                  <OrderingIndicator className="ordering-indicator">
                    <If condition={campaign?.priority !== 1}>
                      <Arrow
                        onClick={(e) => updateCampaignPriority(e, { campaign, action: CAMPAIGN_PRIORITY_ACTIONS.UP })}
                      >
                        <Icon name="keyboard_arrow_up" size="20" color="#89919d" />
                      </Arrow>
                    </If>
                    <If condition={campaign?.priority !== 1 && campaign?.priority !== campaignsList?.length}>
                      <Divider />
                    </If>
                    <If condition={campaign?.priority !== campaignsList?.length}>
                      <Arrow
                        onClick={(e) => updateCampaignPriority(e, { campaign, action: CAMPAIGN_PRIORITY_ACTIONS.DOWN })}
                      >
                        <Icon name="keyboard_arrow_down" size="20" color="#89919d" />
                      </Arrow>
                    </If>
                  </OrderingIndicator>
                </If>
              </ListRow>
            );
          })}
          <ListRow>
            <CampaignListButtonWrapper>
              <AddCampaignText>
                The order above matters! {capitalize(t('leads'))} will not receive messages from more than one sales
                campaign above.
              </AddCampaignText>
              <If condition={isUserType([USER_TYPES.STAFF, USER_TYPES.REGULAR])}>
                <Button onClick={openAddCampaignModal} primary="true" color="blue" type="button" className="add-button">
                  Add campaign
                </Button>
              </If>
            </CampaignListButtonWrapper>
          </ListRow>
        </ListWrapper>
      </If>
    </Column>
  );
};

export default CampaignSalesList;
