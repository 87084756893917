import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const MessageContainer = styled(ReactMarkdown)`
  background-color: #eaeff7;
  position: relative;
  margin-bottom: 1rem;
  border-radius: 1.71rem 1.71rem 1.71rem 0.21rem;
  padding: 1rem 1.85rem 1.1rem 1.5rem;
  line-height: 1.43rem;
  max-width: 20rem;
`;

export const Message = ({ children }) => <MessageContainer linkTarget="_blank">{children}</MessageContainer>;
