import { API } from '../api';

export const getClients = async (params) => {
  const query = new URLSearchParams(params).toString();
  const response = await API.get(`/api/chat/instant-response/clients?${query}`);
  return response.data;
};

export const getClientOnboardingMetrics = async (params) => {
  const query = new URLSearchParams(params).toString();
  const response = await API.get(`/api/chat/clients/onboarding-metrics?${query}`);
  return response.data;
};
