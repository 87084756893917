import React from 'react';
import styled from 'styled-components';
import { Icon, Popup, Table } from 'semantic-ui-react';

const PopupHeader = styled(Popup.Header)`
  margin-bottom: 1rem;
`;

const CRMCommunityLocationCodeOverridesPopup = ({ community }) => {
  if (!community) {
    return null;
  }

  const { crm_location_code_overrides } = community;

  if (!crm_location_code_overrides.length) {
    return null;
  }

  return (
    <Popup trigger={<Icon name="info circle" color="blue" />} basic hoverable wide>
      <PopupHeader>CRM Location Code Overrides</PopupHeader>
      <Popup.Content>
        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Rule</Table.HeaderCell>
              <Table.HeaderCell>Operation</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
              <Table.HeaderCell>Code</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {crm_location_code_overrides.map((c) => (
              <Table.Row key={c.id}>
                <Table.Cell>{c.rule_name}</Table.Cell>
                <Table.Cell textAlign="center">{c.operation}</Table.Cell>
                <Table.Cell>{c.value || c.value_option_name}</Table.Cell>
                <Table.Cell>{c.crm_location_code}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Popup.Content>
    </Popup>
  );
};

export default CRMCommunityLocationCodeOverridesPopup;
