import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { Dimmer, Icon, Loader, Modal, Popup, Table } from 'components/lib';
import { CsvExport } from 'components/common';
import { formatAnyDate } from 'components/utils';
import { getConversationUrl } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { If } from 'components/If';
import { getAutomatedAnswersSamples, getAutomatedAnswersSamplesExport } from 'apis';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const PAGE_SIZE = 10;
const EXPORT_HEADERS = [
  'Timestamp',
  'Message',
  'Suggested Labels',
  'Suggested Response',
  'Used Labels',
  'Score',
  'Response',
  'Sender',
  'Conversation URL',
];
const EXPORT_REVIEW_HEADERS = ['Reviewer Labels', 'Reviewer Notes', 'Reviewer'];
const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm:ss';

const useTopSamples = (filters, page = 1) =>
  useQuery([queryConstants.AUTOMATED_ANSWER_CHANGES, filters, page], {
    queryFn: async () => getAutomatedAnswersSamples(filters, page),
  });

const useExportTopSamples = (filters, enabled, withReviewFields) =>
  useQuery([queryConstants.AUTOMATED_ANSWER_CHANGES, filters, 'export'], {
    queryFn: async () => {
      const changes = await getAutomatedAnswersSamplesExport(filters);
      return changes.map((c) => [
        formatAnyDate(c.created_on, DATE_TIME_FORMAT),
        c.message,
        c.suggested_labels,
        c.suggested_response,
        c.used_labels,
        c.score__min,
        c.response?.text,
        c.response?.sender,
        new URL(getConversationUrl(c.lead_id), document.baseURI).href,
        ...(withReviewFields ? [c.review_suggested_labels, c.review_note, c.review_reviewer] : []),
      ]);
    },
    enabled,
  });

const Content = styled.div`
  padding: 1rem;
`;

const DateTimeCell = styled.div`
  white-space: nowrap;
`;

const SuggestedResponseCell = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  word-break: break-word;
`;

const LongTextCell = styled(Table.Cell)`
  word-break: break-word;
`;

const StyledRow = styled(Table.Row)`
  cursor: pointer;

  &:hover {
    background-color: var(--hover-white);
  }
`;

export const SamplesModal = ({ title, filters, onModalClose, withReviewFields }) => {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useTopSamples(filters, page);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const { data: exportData, isLoading: isLoadingExport } = useExportTopSamples(
    filters,
    exportModalOpen,
    withReviewFields
  );
  const totalPages = data ? Math.ceil(data.count / PAGE_SIZE) : 0;
  const headers = useMemo(() => (withReviewFields ? [...EXPORT_HEADERS, ...EXPORT_REVIEW_HEADERS] : EXPORT_HEADERS));
  return (
    <Modal open closeIcon onClose={onModalClose} size={withReviewFields ? 'fullscreen' : 'large'}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Content>
          <Dimmer active={isLoading} inverted>
            <Loader active size="large" transparent />
          </Dimmer>
          <Table compact>
            <Table.Header>
              <Table.HeaderCell collapsing>Time</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
              <If condition={!withReviewFields}>
                <Table.HeaderCell title="">
                  <SuggestedResponseCell>
                    Suggested Response
                    <Popup
                      wide
                      content="Answers displayed here might not be what was suggested to the agent in case the label condition, priority or the text was changed in the meantime."
                      trigger={<Icon name="info" size="15" />}
                    />
                  </SuggestedResponseCell>
                </Table.HeaderCell>
              </If>
              <Table.HeaderCell collapsing>Score</Table.HeaderCell>
              <Table.HeaderCell>Response</Table.HeaderCell>
              <If condition={!withReviewFields}>
                <Table.HeaderCell collapsing>Sender</Table.HeaderCell>
              </If>
              <If condition={withReviewFields}>
                <Table.HeaderCell>Reviewer Notes</Table.HeaderCell>
                <Table.HeaderCell collapsing>Reviewer Labels</Table.HeaderCell>
                <Table.HeaderCell collapsing>Reviewer</Table.HeaderCell>
              </If>
            </Table.Header>
            <Table.Body>
              {data?.results?.map((message, idx) => (
                <StyledRow key={idx} onClick={() => window.open(getConversationUrl(message.lead_id), '_blank')}>
                  <Table.Cell>
                    <DateTimeCell>{formatAnyDate(message.created_on, DATE_TIME_FORMAT)}</DateTimeCell>
                  </Table.Cell>
                  <LongTextCell>{message.message}</LongTextCell>
                  <If condition={!withReviewFields}>
                    <LongTextCell>{message.suggested_response}</LongTextCell>
                  </If>
                  <Table.Cell>{message.score__min}</Table.Cell>
                  <LongTextCell>{message.response?.text}</LongTextCell>
                  <If condition={!withReviewFields}>
                    <Table.Cell>{message.response?.sender}</Table.Cell>
                  </If>
                  <If condition={withReviewFields}>
                    <LongTextCell>{message.review_note}</LongTextCell>
                    <Table.Cell>{message.review_suggested_labels?.join(', ')}</Table.Cell>
                    <Table.Cell>{message.review_reviewer}</Table.Cell>
                  </If>
                </StyledRow>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan={withReviewFields ? 6 : 5}>
                  <Table.Pagination totalPages={totalPages} onPageChange={setPage} />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <CsvExport
                    data={exportData}
                    isLoading={isLoadingExport}
                    onModalClose={() => setExportModalOpen(false)}
                    onModalOpen={() => setExportModalOpen(true)}
                    headers={headers}
                    filename="label_export.csv"
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Content>
      </Modal.Content>
    </Modal>
  );
};

export const useSamplesModal = ({ getTitle, getFilters, groupBy, timeField, metric, withReviewFields }) => {
  const { automatedAnswerFilters: filters } = useSystemStatsFilters();
  const [selectedBar, setSelectedBar] = useState(null);
  const samplesFilters = {
    ...filters,
    ...getFilters(selectedBar),
    ...(timeField ? { time_field: timeField } : {}),
    exact_match_on: groupBy,
    metric,
  };
  const title = selectedBar ? getTitle(selectedBar) : null;
  const samplesModal = selectedBar ? (
    <SamplesModal
      onModalClose={() => setSelectedBar(null)}
      filters={samplesFilters}
      title={title}
      withReviewFields={withReviewFields}
    />
  ) : null;
  return { samplesModal, setSelectedBar };
};
