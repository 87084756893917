import React from 'react';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';
import { useParams, Link } from 'react-router-dom';

import { Sidebar } from 'components/common';
import { STATUSES } from '../../constants';
import { Grid } from '../Icons';
import { useOwnersDashboardContext } from './OwnersDashboardContext';
import { SidebarItem } from './SidebarItem';
import { ManagementCompanyLogo } from './ManagementCompanyLogo';

export const StyledSidebar = styled(Sidebar)`
  position: relative;
  overflow-y: auto;
  min-height: 100vh;
`;

export const Title = styled.div`
  color: #333333;
  font-size: 1.2rem;
`;
export const SidebarSectionTitle = styled.div`
  color: #979797;
  font-size: 0.9rem;
  padding: 2rem 1.5rem 1rem;
`;

export const IconWrapper = styled.div`
  margin-right: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  svg {
    stroke: #979797;

    #check-square-checkline {
      stroke: #00c213;
    }
  }
`;

export const PortfolioSidebar = () => {
  const { managementCompanyId, communityId } = useParams();
  const { communities, communitiesLoader } = useOwnersDashboardContext();

  const sidebarItems = Object.values(
    communities?.reduce((items, profile, index) => {
      if (!items[profile.management_company.id]) {
        items[profile.management_company.id] = {
          id: profile.management_company.id,
          name: profile.management_company.name,
          logo_url: profile.management_company.logo_url,
          communities: [],
        };
      }

      items[profile.management_company.id].communities.push(profile);

      return items;
    }, {})
  );

  const isManagementCompanyActive = (id) =>
    managementCompanyId === id?.toString() && typeof communityId === 'undefined';

  const isManagementCompanyExpanded = (id) => managementCompanyId === id?.toString();

  const isProviderProfileActive = (cid, pid) =>
    managementCompanyId === cid.toString() && communityId === pid.toString();

  return (
    <StyledSidebar>
      <SidebarSectionTitle>OVERALL STATISTICS</SidebarSectionTitle>
      <Link to="/owners-dashboard">
        <SidebarItem active={typeof managementCompanyId === 'undefined'}>
          <IconWrapper>
            <Grid />
          </IconWrapper>
          <div>
            <Title>Summary Overview</Title>
          </div>
        </SidebarItem>
      </Link>

      <SidebarSectionTitle>COMPANIES</SidebarSectionTitle>
      <Loader active={communitiesLoader === STATUSES.LOADING}>Loading</Loader>

      {sidebarItems.map((managementCompany, i) => (
        <React.Fragment key={i}>
          <Link to={`/owners-dashboard/${managementCompany.id}`}>
            <SidebarItem
              active={isManagementCompanyActive(managementCompany.id)}
              expanded={isManagementCompanyExpanded(managementCompany.id)}
            >
              <ManagementCompanyLogo company={managementCompany} />
              <Title>{managementCompany.name}</Title>
            </SidebarItem>
          </Link>

          {isManagementCompanyExpanded(managementCompany.id) &&
            managementCompany.communities?.map((p, j) => (
              <Link to={`/owners-dashboard/${managementCompany.id}/${p.id}`} key={j}>
                <SidebarItem.SubItem active={isProviderProfileActive(managementCompany.id, p.id)} key={j}>
                  {p.name}
                </SidebarItem.SubItem>
              </Link>
            ))}
        </React.Fragment>
      ))}
    </StyledSidebar>
  );
};
