export const USER_CIRCLE_COLORS = [
  {
    border: '#1f58b0', // Link
    background: '#f3f8ff',
  },
  {
    border: '#614ac8', // Neon Purple
    background: '#ded9f4',
  },
  {
    border: '#fa7550', // Orangeish
    background: '#ffe0d8',
  },
  {
    border: '#d9a707', // Yellowish
    background: '#fff5d6',
  },
  {
    border: '#0ab776', // Emerald Green
    background: '#e2f5ee',
  },
];
