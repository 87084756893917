import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Label, Menu, Sticky } from 'semantic-ui-react';
import { useFormikContext } from 'formik';

import { humanizeString } from 'components/utils';
import { STATUSES } from '../../../constants';
import { InstantAnswersContext } from './InstantAnswersContext';

const MenuContainer = styled(Menu)`
  height: 60vh;
  overflow-y: scroll;
  &.ui.vertical.menu {
    width: 100%;
  }
`;

export const InstantAnswersAttributesList = () => {
  const { dirty } = useFormikContext();
  const {
    wrapperRef,
    communityAttributes,
    activeMenuItem,
    setLoaderStatus,
    setActiveValue,
    loadAttributeAnswers,
    setShowConfirmUnsavedIA,
    setSelectedAttribute,
    onAttributeSelected,
  } = useContext(InstantAnswersContext);

  const onClick = (e, attribute) => {
    setSelectedAttribute(attribute.id);

    if (dirty) {
      setShowConfirmUnsavedIA(true);
      return;
    }

    onAttributeSelected(attribute.id);
  };

  return (
    <Sticky offset={80} context={wrapperRef}>
      <MenuContainer vertical>
        {communityAttributes.map((attribute) => (
          <Menu.Item
            key={attribute.id}
            id={attribute.id}
            name={attribute.name}
            active={activeMenuItem === attribute.id}
            onClick={onClick}
          >
            <Label color="blue">{attribute?.count}</Label>
            {humanizeString(attribute?.label)}
          </Menu.Item>
        ))}
      </MenuContainer>
    </Sticky>
  );
};
