import RuleConstant from './RuleConstant';
import RuleSlider from './RuleSlider';
import RuleDropdown from './RuleDropdown';
import RuleDropdownMultiple from './RuleDropdownMultiple';

export const CONNECTOR = {
  AND: 'and',
  OR: 'or',
};

export const FIELD_TYPE = {
  constant: {
    type: 'constant',
    icon: 'phone_disabled',
    Component: RuleConstant,
    width: '142px',
  },
  select: {
    type: 'select',
    icon: 'account_balance_wallet',
    Component: RuleDropdown,
    variant: 'outlined',
  },
  slider: {
    type: 'slider',
    icon: 'auto_awesome',
    Component: RuleSlider,
  },
  multi_select: {
    type: 'multi_select',
    icon: 'ads_click',
    Component: RuleDropdownMultiple,
  },
};
