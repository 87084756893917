import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { formatAnyDate } from 'components/utils';
import { exportLeadsConversations, getChannelSources, getCommunitiesGroups, getTrafficSources } from 'apis';
import { isInt, parseBool, parseNumber, useUrlSearchParams } from 'utils/hooks';
import { PAGINATION, STATUSES } from '../../constants';
import { downloadFile } from '../utils';
import { DEFAULT_FILTER } from './constants';

export const ConversationsContext = createContext();

const useConversationsFilter = () => {
  const { setUrlSearchParams } = useUrlSearchParams();
  const queryParams = new URLSearchParams(window.location.search);
  const startDate = formatAnyDate(queryParams.get('startDate'), 'yyyy-MM-dd') || DEFAULT_FILTER.startDate;
  const endDate = formatAnyDate(queryParams.get('endDate'), 'yyyy-MM-dd') || DEFAULT_FILTER.endDate;

  const [filters, setFilters] = useState({
    page: parseNumber(queryParams.get('page'), DEFAULT_FILTER.page),
    pageSize: parseNumber(queryParams.get('pageSize'), DEFAULT_FILTER.pageSize),
    startDate,
    endDate,
    diff: parseNumber(queryParams.get('diff'), DEFAULT_FILTER.diff),
    search: queryParams.get('search') || DEFAULT_FILTER.search,
    financiallyUnqualified: parseBool(queryParams.get('financiallyUnqualified'), DEFAULT_FILTER.financiallyUnqualified),
    moveInDate: parseBool(queryParams.get('moveInDate'), DEFAULT_FILTER.moveInDate),
    tourScheduled: parseBool(queryParams.get('tourScheduled'), DEFAULT_FILTER.tourScheduled),
    inboundCall: parseBool(queryParams.get('inboundCall'), DEFAULT_FILTER.inboundCall),
    outboundCall: parseBool(queryParams.get('outboundCall'), DEFAULT_FILTER.outboundCall),
    leadStatus: queryParams.get('leadStatus') || DEFAULT_FILTER.leadStatus,
    trafficSources: queryParams.get('trafficSources')?.split(',') || DEFAULT_FILTER.trafficSources,
    channelSources: queryParams.get('channelSources')?.split(',') || DEFAULT_FILTER.channelSources,
    ordering: queryParams.get('ordering') || DEFAULT_FILTER.ordering,
    isSurveySubmitted: parseBool(queryParams.get('is_survey_submitted'), DEFAULT_FILTER.isSurveySubmitted),
    isNoteAdded: parseBool(queryParams.get('is_note_added'), DEFAULT_FILTER.isNoteAdded),

    communities:
      queryParams
        .get('communities')
        ?.split(',')
        .filter((id) => isInt(id))
        .map((id) => parseInt(id)) || DEFAULT_FILTER.communities,
    groups:
      queryParams
        .get('groups')
        ?.split(',')
        .filter((id) => isInt(id))
        .map((id) => parseInt(id)) || DEFAULT_FILTER.groups,
  });

  useEffect(() => {
    setUrlSearchParams(filters, true);
  }, [filters]);

  return [filters, setFilters];
};

export const ConversationsContextProvider = (props) => {
  const { t } = useTranslation();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [filters, setFilters] = useConversationsFilter();
  const [leads, setLeads] = useState([]);
  const [channelSources, setChannelSources] = useState([]);
  const [trafficSources, setTrafficSources] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [groups, setCommunitiesGroups] = useState([]);
  const [csvExportStatus, setCsvExportStatus] = useState(STATUSES.IDLE);
  const [leadsCount, setLeadsCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const loadBrowsingFilters = async () => {
      try {
        const channelSources = await getChannelSources();
        setChannelSources(channelSources);
      } catch (e) {
        toast.error('We cannot load some filter at this time. Please try again later.');
        console.error(e);
      }
    };
    loadBrowsingFilters();
  }, []);

  useEffect(() => {
    const loadTrafficSources = async () => {
      try {
        const response = await getTrafficSources();
        setTrafficSources(response.traffic_source_names);
      } catch (e) {
        toast.error('We cannot load traffic source filter at this time. Please try again later.');
        console.error(e);
      }
    };
    loadTrafficSources();
  }, []);

  useEffect(() => {
    const loadCommunitiesGroups = async () => {
      try {
        const response = await getCommunitiesGroups();
        setCommunitiesGroups(response.results);
      } catch (e) {
        toast.error(`We cannot load ${t('community')} groups. Please try again later.`);
        console.error(e);
      }
    };
    loadCommunitiesGroups();
  }, []);

  const buildQueryParams = () => {
    const allCommunitiesSelected = filters.communities?.length === communities?.length;
    return {
      page: filters.page,
      page_size: filters.pageSize,
      start_date: filters.startDate,
      end_date: filters.endDate,
      diff: filters.diff,
      search: filters.search || undefined,
      ordering: filters.ordering,
      financially_unqualified: filters.financiallyUnqualified,
      move_in_date: filters.moveInDate,
      tour_scheduled: filters.tourScheduled,
      inbound_call: filters.inboundCall || undefined,
      outbound_call: filters.outboundCall || undefined,
      lead_status: filters.leadStatus,
      traffic_source: filters.trafficSources?.length ? filters.trafficSources.join(',') : undefined,
      channel_sources: filters.channelSources?.length ? filters.channelSources.join(',') : undefined,
      community_id: filters.communities?.length && !allCommunitiesSelected ? filters.communities.join(',') : undefined,
      group_id: filters.groups.join(','),
      is_survey_submitted: filters.isSurveySubmitted || null,
      is_note_added: filters.isNoteAdded || null,
    };
  };

  const handleFiltersChange = (props) => {
    setFilters({
      ...filters,
      ...props,
      page: PAGINATION.PAGE,
    });
  };

  const handlePageChange = (activePage) => {
    setFilters({
      ...filters,
      page: activePage,
    });
  };

  const handleFilterReset = () => {
    handleFiltersChange({
      ...DEFAULT_FILTER,
      search: '',
      communities: [],
      groups: [],
      diff: filters.diff,
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
  };

  const handleExportAsCSV = async (additionalColumns) => {
    setCsvExportStatus(STATUSES.LOADING);
    try {
      const queryParams = buildQueryParams();
      const additionalColumnsQueryParam = [...new Set(additionalColumns.map((c) => c.save_as))].join(',');
      const response = await exportLeadsConversations({
        ...queryParams,
        tz_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        include_extra: additionalColumnsQueryParam,
      });
      downloadFile({ data: response }, `conversations_${formatAnyDate(new Date(), 'yyyyMMdd_hhmmss')}.csv`);
      setCsvExportStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error('Unable to download CSV file.');
      setCsvExportStatus(STATUSES.FAILURE);
    }
  };

  const provide = {
    loaderStatus,
    filters,
    leads,
    channelSources,
    trafficSources,
    communities,
    leadsCount,
    csvExportStatus,
    totalPages,
    setLoaderStatus,
    setCommunities,
    setLeads,
    setCsvExportStatus,
    setTotalPages,
    setLeadsCount,
    handleFiltersChange,
    handlePageChange,
    handleFilterReset,
    handleExportAsCSV,
    DEFAULT_FILTER,
    buildQueryParams,
    groups,
  };

  return <ConversationsContext.Provider value={provide} {...props} />;
};
