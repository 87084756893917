import React, { useEffect, useState } from 'react';
import { Dimmer, Divider, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { getCampaignScheduledMessages } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PageHeader } from 'components/common';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import SearchInput from 'components/SearchInput';
import { useUrlSearchParams } from 'utils/hooks';
import { Dropdown } from 'components/lib';
import { PAGINATION, STATUSES } from '../../../constants';
import ScheduledMessageList from './ScheduledMessageList';
import { useScheduledMessages } from './ScheduledMessageContext';

const FilterSearch = styled.div`
  flex: 3;
`;

const FilterScheduledFor = styled.div`
  flex: 1;
`;

const FiltersBox = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const SCHEDULED_OPTION = {
  ALL: 'all',
  TODAY: 'today',
  WEEK: 'week',
};

const FILTER_OPTIONS = [
  { key: 'all', text: 'All', value: SCHEDULED_OPTION.ALL },
  { key: 'today', text: 'Today', value: SCHEDULED_OPTION.TODAY },
  { key: 'week', text: 'This Week', value: SCHEDULED_OPTION.WEEK },
];

const useFilters = () => {
  const { setUrlSearchParams } = useUrlSearchParams();
  const queryParams = new URLSearchParams(window.location.search);

  const [filters, setFilters] = useState({
    page: queryParams.get('page') || PAGINATION.PAGE,
    pageSize: queryParams.get('pageSize') || PAGINATION.PAGE_SIZE,
    search: queryParams.get('search') || '',
    scheduledDate: queryParams.get('scheduledDate') || SCHEDULED_OPTION.ALL,
  });

  useEffect(() => {
    setUrlSearchParams(filters, true);
  }, [filters]);

  return [filters, setFilters];
};

const buildQueryParams = (filters) => {
  const { page, pageSize, search, scheduledDate } = filters;
  return {
    page,
    page_size: pageSize,
    search: search || undefined,
    scheduled_date: scheduledDate,
  };
};

const ScheduledMessages = () => {
  const [loader, setLoader] = useState(STATUSES.IDLE);
  const [error, setError] = useState();
  const [filters, setFilters] = useFilters();
  const [count, setCount] = useState();
  const [totalPages, setTotalPages] = useState();
  const [{ messages }, dispatch] = useScheduledMessages();

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setLoader(STATUSES.LOADING);
        const queryParams = buildQueryParams(filters);
        const { results, count } = await getCampaignScheduledMessages(queryParams);

        dispatch({ type: 'SET_MESSAGES', payload: results });
        setCount(count);
        setTotalPages(Math.ceil(count / filters.pageSize));
        setLoader(STATUSES.LOADED);
      } catch (e) {
        dispatch({ type: 'SET_MESSAGES', payload: null });
        setCount(null);
        setTotalPages(null);
        setError(parseErrorResponse(e, 'Unable to fetch scheduled messages'));
        setLoader(STATUSES.FAILURE);
      }
    };

    fetchMessages();
  }, [filters]);

  const handleSearch = (value) => {
    setFilters((f) => ({ ...f, search: value, page: PAGINATION.PAGE }));
  };

  const handlePageChange = (page) => {
    setFilters((f) => ({ ...f, page }));
  };

  const handleScheduledChange = (e, { value }) => {
    setFilters((f) => ({ ...f, scheduledDate: value, page: PAGINATION.PAGE }));
  };

  return (
    <>
      <Dimmer active={loader === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <PageHeader
        as="h2"
        title="Scheduled Messages"
        subtitle="List of scheduled campaign messages"
        margin={PageHeader.MARGIN.SMALL}
      />
      <FiltersBox>
        <FilterSearch>
          <SearchInput value={filters.search} onSearch={handleSearch} placeholder="Search by lead, community" fluid />
        </FilterSearch>
        <FilterScheduledFor>
          <Dropdown
            options={FILTER_OPTIONS}
            selection
            fluid
            basic
            value={filters.scheduledDate}
            onChange={handleScheduledChange}
          />
        </FilterScheduledFor>
      </FiltersBox>
      <ScheduledMessageList
        messages={messages}
        count={count}
        page={filters.page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <Divider hidden />
      <If condition={loader === STATUSES.FAILURE}>
        <FailureMessage icon="search" content={error} />
      </If>
    </>
  );
};

export default ScheduledMessages;
