import React, { useContext, useEffect, useState } from 'react';
import { Form, Icon, Message, Popup, Table } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import styled from 'styled-components';

import { getChatModules } from '../../../api';
import { applyChatAnalyticsIntegrations } from '../../../apis';
import { STATUSES } from '../../../constants';
import { ANALYTICS_CHAT_MODULE_SUBMIT_TYPE } from './constants';
import { PageSection, PromptButton } from '../../common';
import { Checkbox } from '../../Checkbox';
import { ChatsContext } from '../ChatsContext';

const ModuleTextContainer = styled.div`
  max-width: 400px;
  pre {
    white-space: pre-wrap;
  }
`;

export const AnalyticsChatModulesForm = ({ integrations }) => {
  const { chatId } = useParams();
  const { chats, analytics } = useContext(ChatsContext);

  const [modules, setModules] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);

        const { modules } = await getChatModules(chatId);
        const submitModules = modules
          .filter((module) => module.module_type === ANALYTICS_CHAT_MODULE_SUBMIT_TYPE)
          .sort((a, b) => a.number - b.number);

        setModules(submitModules);
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        setLoaderStatus(STATUSES.FAILURE);
        console.error(e);
      }
    };

    fetchModules();
  }, [chatId]);

  const onSubmit = async (data) => {
    try {
      const isPopulated = Object.values(data.selection).some((module_selection) =>
        Object.values(module_selection).some((integration) => integration)
      );

      if (isPopulated) {
        await applyChatAnalyticsIntegrations(chatId, data);
        toast.success('Successfully updated chat modules tracking analytics!');
      } else {
        toast.warning('Please select at least one chat module analytic integration.');
      }
    } catch (e) {
      toast.error('Failed to update chat modules tracking analytics!');
      console.error(e);
    }
  };

  const chat = chats?.find((c) => c.id === parseInt(chatId));

  const initialValues = {
    selection: modules?.reduce(
      (acc, module) => ({
        ...acc,
        [module.id]: integrations?.reduce((acc_i, integration) => ({ ...acc_i, [integration.id]: false }), {}),
      }),
      {}
    ),
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({ handleSubmit }) => (
        <PageSection
          as="h3"
          title={`${chat?.name} Chat Submit Modules Analytics`}
          subtitle={"Manage analytics tracking codes for individual chat's submit modules"}
          loaderStatus={loaderStatus}
          actions={
            <PromptButton
              primary
              content="Apply"
              disabled={loaderStatus !== STATUSES.LOADED}
              loading={loaderStatus === STATUSES.LOADING}
              title="Are you sure you want to apply analytics codes?"
              description="This action will append codes to current content of submit modules! "
              onConfirm={handleSubmit}
              type="submit"
            />
          }
        >
          <Message info>
            On update selected individual tracking codes will append to the existing code contained in modules text
            field.
          </Message>

          <Form onSubmit={handleSubmit}>
            <Table fixed singleLine>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell width={2}>Module</Table.HeaderCell>
                  <Table.HeaderCell width={1} collapsing>
                    Text
                  </Table.HeaderCell>
                  {integrations?.map((integration) => (
                    <Table.HeaderCell key={integration.id}>
                      {analytics?.types[integration.type] || integration.type}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {modules?.map((module, i) => (
                  <Table.Row key={i} textAlign="center">
                    <Table.Cell width={4}>
                      <b>Module {module.number}</b>
                    </Table.Cell>
                    <Table.Cell collapsing>
                      <Popup basic trigger={<Icon name="info circle" />}>
                        <ModuleTextContainer>
                          <pre>{module.text || 'Not Set'}</pre>
                        </ModuleTextContainer>
                      </Popup>
                    </Table.Cell>
                    {integrations?.map((integration) => (
                      <Table.Cell key={integration.id}>
                        <Checkbox name={`selection[${module.id}][${integration.id}]`} />
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Form>
        </PageSection>
      )}
    </Formik>
  );
};
