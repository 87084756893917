export const DEFAULT_PARSE_TYPES = {
  full_name: 'lead_name',
  first_name: 'text',
  last_name: 'text',
  email: 'text',
  phone: 'phone_number',
  community: 'community_name',
  'tour_date.date': 'date',
  'tour_date.time': 'time',
  tour_datetime: 'datetime',
  who_looking_for: 'raw',
  care_required: 'raw',
  lead_additional_information: 'raw',
  extra_information: 'json',
  'extra_information.preferred_method': 'raw',
  'extra_information.middle_name': 'raw',
  'extra_information.checked_boxes': 'raw',
  'extra_information.subscribe_to_newsletter': 'raw',
  'extra_information.referral_type': 'raw',
  'extra_information.address.street': 'raw',
  'extra_information.address.city': 'raw',
  'extra_information.address.state_or_province': 'raw',
  'extra_information.address.zip_or_postal_code': 'raw',
};

const constructHasKeywords = (field) => (keywords) => {
  const fieldName = field.name?.toLowerCase() || '';
  const placeholder = field.placeholder?.toLowerCase() || '';
  const labels = Array.from(field.labels || []).map((l) => l.innerText?.toLowerCase() || '');

  return keywords.every(
    (kw) => fieldName.includes(kw) || placeholder.includes(kw) || labels.some((l) => l.includes(kw))
  );
};

export const guessMappingTarget = (field) => {
  const fieldType = field.type?.toLowerCase() || '';
  const hasKeywords = constructHasKeywords(field);

  if (hasKeywords(['first', 'name'])) {
    return 'first_name';
  }

  if (hasKeywords(['last', 'name'])) {
    return 'last_name';
  }

  if (hasKeywords(['preferred', 'method']) || hasKeywords(['prefer', 'contacted'])) {
    return 'extra_information.preferred_method';
  }

  if (hasKeywords(['email'])) {
    return 'email';
  }

  if (hasKeywords(['phone'])) {
    return 'phone';
  }

  if (hasKeywords(['community'])) {
    return 'community';
  }

  if (hasKeywords(['looking', 'for']) || hasKeywords(['relationship'])) {
    return 'who_looking_for';
  }

  if (hasKeywords(['street'])) {
    return 'extra_information.address.street';
  }

  if (hasKeywords(['city'])) {
    return 'extra_information.address.city';
  }

  if (hasKeywords(['state'] || hasKeywords(['province']))) {
    return 'extra_information.address.state_or_province';
  }

  if (hasKeywords(['zip'] || hasKeywords(['postal']))) {
    return 'extra_information.address.zip_or_postal_code';
  }

  if (hasKeywords(['how', 'can', 'help']) || hasKeywords(['message'])) {
    return 'lead_additional_information';
  }

  if (fieldType === 'textarea' && !hasKeywords(['captcha'])) {
    return 'lead_additional_information';
  }
};

export const guessParseType = (field, mappingTarget) => {
  if (mappingTarget) {
    return DEFAULT_PARSE_TYPES[mappingTarget];
  }

  const fieldType = field.type?.toLowerCase() || '';

  if (fieldType === 'radio') {
    return 'csv';
  }

  if (fieldType === 'text' || fieldType === 'textarea' || fieldType === 'email') {
    return 'text';
  }
};

export const guessIgnored = (field, mappingTarget, parseType) => {
  const hasKeywords = constructHasKeywords(field);

  if (hasKeywords(['captcha'])) {
    return true;
  }

  return !mappingTarget && !parseType && (field.type === 'hidden' || field.type === 'submit');
};

export const sorted = (mappings) => {
  return [...mappings].sort((a, b) => {
    if (a.ignored && !b.ignored) {
      return 1;
    }
    if (!a.ignored && b.ignored) {
      return -1;
    }
    return 0;
  });
};
