import React, { useMemo } from 'react';
import { Segment } from 'semantic-ui-react';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';
import { useMetrics } from './hooks';
import { PercentCircle } from '../../../common/analytics';

export const PieChart = ({ units, metrics, getCircleData, timeField, filters }) => {
  const { data, isLoading, loaderStatus } = useMetrics(metrics, filters, timeField);

  const circleData = useMemo(() => getCircleData(data), [data]);

  return (
    <Segment basic loading={isLoading}>
      <PercentCircle data={circleData} units={units} loaderStatus={loaderStatus} radius={150} circleWidth={15} />
    </Segment>
  );
};
