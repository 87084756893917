import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Divider, Dropdown, Form, Message, Popup } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { InputField, PageHeader, PAGE_HEADER_MARGIN, ToggleSwitch } from 'components/common';
import { parseErrorResponse } from 'apis/utils';
import { getProfileCommunities } from 'apis';
import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import { MEDIA_BREAK_POINTS, PAGINATION, TRAILING_30_DAYS_REPORT_FREQUENCY, USER_TYPES } from '../../../constants';

const CommunitiesInputField = styled(Form.Field)`
  width: 100%;
`;

const SelectAllCommunitiesButton = styled(Button)`
  && {
    background-color: white;
    min-width: fit-content;
    height: fit-content;
    margin-top: 25px;

    &:hover,
    &:focus {
      background-color: white;
    }

    @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      margin-top: 0;
      padding-left: 1em;
    }
  }
`;

const Trailing30DaysReport = styled.div`
  .public-report-toggle {
    padding-top: 1em;
  }
`;

const INITIAL_VALUES = {
  email: '',
  send_invite: true,
  invite_expires_in: 0,
  communities: [],
  trailing_30days_report_frequency: TRAILING_30_DAYS_REPORT_FREQUENCY.WEEKLY,
  trailing_30days_report_public_report: false,
  split_regional_report_per_owner: false,
};

const TRAILING_30_DAYS_REPORT_OPTIONS = Object.keys(TRAILING_30_DAYS_REPORT_FREQUENCY).map((f) => ({
  key: TRAILING_30_DAYS_REPORT_FREQUENCY[f],
  value: TRAILING_30_DAYS_REPORT_FREQUENCY[f],
  text: capitalize(f.toLowerCase()),
}));

const USER_TYPE_OPTIONS = [
  {
    key: USER_TYPES.REGIONAL,
    value: USER_TYPES.REGIONAL,
    text: 'Regional',
  },
  {
    key: USER_TYPES.MEMBER,
    value: USER_TYPES.MEMBER,
    text: 'Member',
  },
];

const UserAccountForm = ({ user, loading, onSubmit }) => {
  const { t } = useTranslation();
  const [communities, setCommunities] = useState([]);
  const [initialValues, setInitialValues] = useState();
  const validationSchema = Yup.object({
    type: Yup.string().required('User type is required'),
    email: Yup.string().required('Email is required'),
    send_invite: Yup.boolean(),
    trailing_30days_report_frequency: Yup.number(),
    trailing_30days_report_public_report: Yup.boolean(),
    split_regional_report_per_owner: Yup.boolean(),
    invite_expires_in: Yup.number().when('send_invite', {
      is: true,
      then: Yup.number()
        .required('Expiration days is required')
        .min(1, 'Expiration days must be greater than or equal to 1'),
    }),
    communities: Yup.array().required('Please select communities'),
  });
  const isEditMode = !!user;

  useEffect(() => {
    if (user) {
      const {
        email,
        type,
        communities,
        invitation,
        trailing_30days_report_frequency,
        trailing_30days_report_public_report,
        split_regional_report_per_owner,
      } = user;

      setCommunities(
        communities.map((pp) => ({
          key: pp.id,
          value: pp.id,
          text: pp.name,
        }))
      );
      setInitialValues({
        email,
        type,
        communities: communities.map((pp) => pp.id),
        send_invite: !!invitation,
        trailing_30days_report_frequency,
        trailing_30days_report_public_report,
        split_regional_report_per_owner,
        invite_expires_in: invitation?.expires_in || 0,
        activated: invitation?.activated || !!user.last_login,
      });
    } else {
      setInitialValues(INITIAL_VALUES);
    }

    fetchCommunities();
  }, [user]);

  const fetchCommunities = async () => {
    try {
      const { results } = await getProfileCommunities({ page_size: PAGINATION.MAX_LARGE_PAGE_SIZE });

      setCommunities((draft) => [
        ...draft,
        ...results
          .filter((r) => !draft.some((pp) => pp.key === r.id))
          .map((r) => ({
            key: r.id,
            value: r.id,
            text: r.name,
          })),
      ]);
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e), 'Unable to fetch communities');
    }
  };

  const handleSelectAllCommunities = (setFieldValue) => () => {
    setFieldValue(
      'communities',
      communities.map((p) => p.value)
    );
  };

  if (!initialValues) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => {
        const isRegional = values.type === USER_TYPES.REGIONAL;

        return (
          <>
            <PageHeader
              title={`${isEditMode ? 'Update' : 'Create'} user account`}
              subtitle={`Manage user account ${t('communities')}`}
              block
              margin={PAGE_HEADER_MARGIN.MEDIUM}
              actions={
                <>
                  <Link to="/settings/user-accounts">
                    <Button content={`${isEditMode ? 'Close' : 'Cancel'}`} loading={loading} disabled={loading} />
                  </Link>
                  <Button
                    primary
                    type="submit"
                    content={`${isEditMode ? 'Update' : 'Create'}`}
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={loading}
                  />
                </>
              }
            />
            <Form>
              <Form.Field>
                <InputField
                  type="text"
                  label="Type"
                  name="type"
                  placeholder="Type"
                  control={Dropdown}
                  options={USER_TYPE_OPTIONS}
                  fluid
                  selection
                />
              </Form.Field>
              <Form.Group>
                <InputField
                  width={isRegional ? '12' : '16'}
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="Email"
                  disabled={values.activated}
                />
                <If condition={isRegional}>
                  <Trailing30DaysReport>
                    <InputField
                      type="text"
                      label="Trailing 30 days analytics report frequency"
                      name="trailing_30days_report_frequency"
                      placeholder="Trailing 30 days analytics report frequency"
                      control={Dropdown}
                      options={TRAILING_30_DAYS_REPORT_OPTIONS}
                      fluid
                      selection
                    />
                    <Popup
                      inverted
                      content="Toggle this button to provide a link to the analytics, but does not give access to the dashboard. This may be useful if you just want to share the reporting data, without any access to the Further Platform. Users will be able to click the link and view all the statistics."
                      trigger={
                        <ToggleSwitch
                          toggle
                          name="trailing_30days_report_public_report"
                          label="Remove Dashboard Access From Report"
                          disabled={values.trailing_30days_report_frequency === TRAILING_30_DAYS_REPORT_FREQUENCY.NEVER}
                          className="public-report-toggle"
                        />
                      }
                    />
                    <Popup
                      inverted
                      content="Toggle this button to send a separate report per owner. This may be useful for regionals that manage communities owned by different users."
                      trigger={
                        <ToggleSwitch
                          toggle
                          name="split_regional_report_per_owner"
                          label="Separate Report per Owner"
                          disabled={values.trailing_30days_report_frequency === TRAILING_30_DAYS_REPORT_FREQUENCY.NEVER}
                          className="public-report-toggle"
                        />
                      }
                    />
                  </Trailing30DaysReport>
                </If>
              </Form.Group>
              <Form.Group>
                <CommunitiesInputField>
                  <InputField
                    type="text"
                    label={capitalize(t('communities'))}
                    name="communities"
                    placeholder={capitalize(t('communities'))}
                    control={Dropdown}
                    options={communities}
                    fluid
                    search
                    selection
                    multiple
                    clearable
                  />
                </CommunitiesInputField>
                <SelectAllCommunitiesButton
                  type="button"
                  icon="check"
                  content="Select All"
                  onClick={handleSelectAllCommunities(setFieldValue)}
                />
              </Form.Group>
              <Divider section horizontal>
                Invitation
              </Divider>
              <If condition={values.activated}>
                <Message
                  info
                  icon="info"
                  header="Invitation accepted!"
                  content="User accepted the invitation and registered successfully."
                />
              </If>
              <If condition={!values.activated}>
                <Form.Field>
                  <Popup
                    inverted
                    content="Send an invitation to the user"
                    trigger={<ToggleSwitch toggle name="send_invite" label="Send invite" />}
                  />
                </Form.Field>
                <Form.Field>
                  <InputField
                    type="number"
                    label="Invite expires in days"
                    name="invite_expires_in"
                    placeholder="Invite expires in"
                    disabled={!values.send_invite}
                  />
                </Form.Field>
              </If>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default UserAccountForm;
