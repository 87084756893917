import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Dimmer, Loader, Modal } from 'components/lib';

const ModalContent = styled(Modal.Content)`
  .title {
    font-family: var(--font-family-bold);
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
    margin-bottom: 12px;
  }

  .description {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #89919d;
    word-break: break-word;

    & b {
      color: #222;
    }
  }

  &&&& {
    padding: 30px;
  }
`;

const ManagementUserConfirm = ({ managedUser, loading, onConfirm, onCancel }) => {
  const [open, setOpen] = useState();

  useEffect(() => {
    setOpen(!!managedUser);
  }, [managedUser]);

  if (!managedUser) {
    return null;
  }

  return (
    <Modal size="tiny" closeIcon open={open} onClose={onCancel}>
      <Dimmer active={loading} inverted>
        <Loader active />
      </Dimmer>
      <ModalContent scrolling>
        <div className="title">Administrate user</div>
        <div className="description">
          Are you sure you want to administrate <b>{managedUser.user.email || managedUser.user.name}</b>?
          <div>You will get full access to this user.</div>
        </div>
      </ModalContent>
      <Modal.Actions>
        <Button primary color="blue" onClick={onConfirm}>
          Confirm
        </Button>
        <Button basic onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ManagementUserConfirm;
