import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import classNames from 'classnames';

import { getCampaignCommunityValidation } from 'apis';
import { If } from 'components/If';
import { EmailIntegrationTab } from './EmailIntegration';
import { SalesInfoForm } from './SalesInfo';
import { CampaignModal, CampaignModalActions } from '../../common';
import { CAMPAIGN_WARNINGS_TYPE, CAMPAIGN_VALIDATION_TYPE } from '../../../constants';
import { useCampaignDetails } from '../../CampaignDetailsContext';

const ContentSelectorWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

const TypeContainer = styled.div`
  height: 26px;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 13px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 1.5;
  font-weight: normal;
  background-color: var(--white);
  color: var(--text);
  text-transform: capitalize;
  cursor: pointer;

  &.active {
    background-color: var(--vivid-blue);
    color: var(--white);
  }
`;

export const WarningsModal = ({ isModalOpen, setModalOpen = () => {} }) => {
  const { state, updateCampaignValidations } = useCampaignDetails();
  const { campaignInvalidSections } = state;

  const [activeTab, setActiveTab] = useState(state?.campaignInvalidSections[0]);

  const [isSalesLoading, setIsSalesLoading] = useState(false);
  const [isEmailIntegrationLoading, setIsEmailIntegrationLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [communitySalesContacts, setCommunitySalesContacts] = useState([]);
  const [missingEmailIntegrations, setMissingEmailIntegrations] = useState([]);

  const initialFormValues = useMemo(() => {
    const salesContacts = communitySalesContacts.map((community) => ({
      communityId: community.id,
      name: community.name,
      salesFirstName: community.salesContact?.salesFirstName || '',
      primarySalesEmail: community.salesContact?.primarySalesEmail || '',
      primarySalesPhone: community.salesContact?.primarySalesPhone || '',
    }));

    return {
      salesContacts,
      useMessagingAssistant: false,
    };
  }, [communitySalesContacts]);

  useEffect(() => {
    const fetchSalesValidations = async () => {
      setIsSalesLoading(true);

      try {
        const response = await getCampaignCommunityValidation(
          state?.campaign?.id,
          CAMPAIGN_VALIDATION_TYPE.CONTACT_INFO
        );
        setCommunitySalesContacts(response.results);
      } catch (e) {
        console.error(e);
      }

      setIsSalesLoading(false);
    };

    if (!isModalOpen) return;

    if (state.campaignInvalidSections.length === 0) return;

    if (!state.campaignInvalidSections.includes(CAMPAIGN_WARNINGS_TYPE.CONTACT_INFO)) return;

    fetchSalesValidations();
  }, [isModalOpen]);

  useEffect(() => {
    const fetchMessagingValidations = async () => {
      setIsEmailIntegrationLoading(true);
      const response = await getCampaignCommunityValidation(
        state?.campaign?.id,
        CAMPAIGN_VALIDATION_TYPE.EMAIL_INTEGRATION
      );
      setMissingEmailIntegrations(response.results);
      setIsEmailIntegrationLoading(false);
    };

    if (!isModalOpen) return;

    if (state.campaignInvalidSections.length === 0) return;

    if (!state.campaignInvalidSections.includes(CAMPAIGN_WARNINGS_TYPE.EMAIL_INTEGRATION)) return;

    fetchMessagingValidations();
  }, [isModalOpen, state.campaignInvalidSections]);

  useEffect(() => {
    setActiveTab(state.campaignInvalidSections[0]);
  }, [state.campaignInvalidSections]);

  const handleSave = async (values, { resetForm }) => {
    setIsSaving(true);
    try {
      const data = {
        communitySalesContacts: values.salesContacts.map((contact) => {
          const salesData = { communityId: contact.communityId };

          if (contact.salesFirstName) {
            salesData.salesFirstName = contact.salesFirstName;
          }
          if (contact.primarySalesEmail) {
            salesData.primarySalesEmail = contact.primarySalesEmail;
          }
          if (contact.primarySalesPhone) {
            salesData.primarySalesPhone = contact.primarySalesPhone;
          }

          return salesData;
        }),
      };

      if (values.useMessagingAssistant) {
        data.communitySettings = {
          useNurtureConversationSupport: true,
        };
      }
      await updateCampaignValidations(data);
    } catch (e) {
      console.error(e);
    }

    setIsSaving(false);
    handleClose(resetForm);
  };

  const handleClose = (resetForm) => {
    resetForm();
    setModalOpen(false);
  };

  return (
    <Formik initialValues={initialFormValues} enableReinitialize onSubmit={handleSave} validateOnBlur={false}>
      {({ handleSubmit, resetForm, isValid, dirty }) => (
        <CampaignModal open={isModalOpen} onModalClose={() => handleClose(resetForm)} size="tiny" width={600}>
          <div type="header">
            Warnings
            {campaignInvalidSections?.length > 1 && (
              <ContentSelectorWrapper>
                {campaignInvalidSections.map((type) => (
                  <TypeContainer
                    key={type}
                    className={classNames({ active: activeTab === type })}
                    onClick={() => setActiveTab(type)}
                  >
                    {type}
                  </TypeContainer>
                ))}
              </ContentSelectorWrapper>
            )}
          </div>
          <div type="body">
            <If condition={activeTab === CAMPAIGN_WARNINGS_TYPE.CONTACT_INFO}>
              <SalesInfoForm isLoading={isSalesLoading} data={communitySalesContacts} />
            </If>
            <If condition={activeTab === CAMPAIGN_WARNINGS_TYPE.EMAIL_INTEGRATION}>
              <EmailIntegrationTab isLoading={isEmailIntegrationLoading} data={missingEmailIntegrations} />
            </If>
          </div>
          <CampaignModalActions
            loading={isSaving}
            disabled={!isValid || !dirty}
            onSave={handleSubmit}
            onCancel={() => handleClose(resetForm)}
            hideUpdate={
              campaignInvalidSections?.length === 1 &&
              campaignInvalidSections[0] === CAMPAIGN_WARNINGS_TYPE.EMAIL_INTEGRATION
            }
            cancelText="Skip"
            saveText="Update"
            saveType="submit"
            type="actions"
          />
        </CampaignModal>
      )}
    </Formik>
  );
};
