import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer).attrs({})`
  /* .toast-container */

  width: unset;

  /* .toast is passed to toastClassName */
  .Toastify__toast {
    background-color: whitesmoke;
    box-shadow: 0 0.2rem 0.25rem 0 rgba(0, 0, 0, 0.5);
    /* border: 1px solid #f0f0f0; */
    border-radius: 0.25rem;
    padding: 1rem 1rem 1rem 1.25rem;
    color: #444;
    font-size: 1.1rem;
    font-weight: 600;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: #999;
      top: 0;
      left: 0;
      height: 100%;
      width: 0.35rem;
    }
  }

  .Toastify__toast--error {
    &:before {
      background: #db2828;
    }
  }
  .Toastify__toast--warning {
    &:before {
      background: #fbbd08;
    }
  }
  .Toastify__toast--success {
    &:before {
      background: #21ba45;
    }
  }
`;
