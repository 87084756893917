import React from 'react';
import styled from 'styled-components';
import { STATUSES } from 'constants';
import { Dimmer, Loader } from 'components/lib';
import SeriesDescription from './SeriesDescription';

const Wrapper = styled.div`
  width: ${(p) => p.width};
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .chart {
    position: relative;
  }

  .legend {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .additional-metrics {
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

const DEFAULT_CIRCLE_RADIUS = 200;
const DEFAULT_CIRCLE_WIDTH = 16;
const DEFAULT_CIRCLE_COLOR = '#1f57bd';
const DEFAULT_CIRCLE_BACK_COLOR = 'var(--line-standard)';
const DEFAULT_COMPONENT_WIDTH = 'auto';

const CircleWrapper = styled.div`
  width: ${(p) => p.radius}px;
  height: ${(p) => p.radius}px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1;

  .value {
    font-size: 2rem;
  }

  .units {
    font-size: 1rem;
    margin: 0 ${(p) => p.circleWidth + 5}px;
    text-align: center;
  }

  .circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border: ${(p) => p.circleWidth}px ${(p) => p.backColor} solid;
    border-radius: 50%;
  }

  .active {
    border-color: ${(p) => p.color};
  }

  .filler {
    clip: rect(0, ${(p) => p.radius}px, ${(p) => p.radius}px, ${(p) => p.radius / 2}px);
  }

  .partial {
    clip: rect(0, ${(p) => p.radius}px, ${(p) => p.radius}px, ${(p) => p.radius / 2}px);
    transform: rotate(${(p) => p.rotation}deg);
  }
`;

const Circle = ({
  perc,
  radius = DEFAULT_CIRCLE_RADIUS,
  circleWidth = DEFAULT_CIRCLE_WIDTH,
  color = DEFAULT_CIRCLE_COLOR,
  backColor = DEFAULT_CIRCLE_BACK_COLOR,
  displayValue,
  units,
}) => {
  let rotation = perc * 360 - 180;
  let partialClassName = 'circle partial active';
  if (perc < 0.5) {
    partialClassName = 'circle partial';
    rotation -= 180;
  }
  return (
    <CircleWrapper
      perc={perc}
      rotation={rotation}
      radius={radius}
      circleWidth={circleWidth}
      color={color}
      backColor={backColor}
    >
      <div className="circle" />
      {perc > 0 && <div className="circle active filler" />}
      <div className={partialClassName} />
      {displayValue && <div className="value">{displayValue}</div>}
      {displayValue && units && <div className="units">{units}</div>}
    </CircleWrapper>
  );
};

const PercentCircle = ({
  data,
  units,
  radius = DEFAULT_CIRCLE_RADIUS,
  circleWidth = DEFAULT_CIRCLE_WIDTH,
  color = DEFAULT_CIRCLE_COLOR,
  backColor = DEFAULT_CIRCLE_BACK_COLOR,
  width = DEFAULT_COMPONENT_WIDTH,
  additionalMetrics,
  loaderStatus,
}) => {
  let first = '-';
  let second = '-';
  let firstName = '-';
  let secondName = '-';
  let total = null;
  let perc = 0;
  if (data) {
    first = Object.values(data)[0];
    second = Object.values(data)[1];
    firstName = Object.keys(data)[0];
    secondName = Object.keys(data)[1];
    total = first + second;
    perc = total > 0 ? (first * 1.0) / total : 0;
  }

  if (!Number.isFinite(total)) {
    total = null;
    perc = 0;
  }

  return (
    <Wrapper width={width}>
      <Dimmer active={!data || loaderStatus === STATUSES.LOADING} inverted>
        <Loader active size="large" />
      </Dimmer>
      <div className="chart">
        <Circle
          perc={perc}
          radius={radius}
          circleWidth={circleWidth}
          displayValue={total}
          units={total && units}
          color={color}
          backColor={backColor}
        />
      </div>
      <div className="legend">
        <div className="additional-metrics">{additionalMetrics}</div>
        <div>
          <SeriesDescription
            metricFontFamily="var(--font-family)"
            name={firstName}
            value={first}
            total={total}
            color={color}
          />
          <SeriesDescription
            metricFontFamily="var(--font-family)"
            name={secondName}
            value={second}
            total={total}
            color={backColor}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default PercentCircle;
