import { format, formatDistance, isDate, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { DEFAULT_DJANGO_TIMEZONE } from 'constants';

export const formatCurrency = (num) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  return formatter.format(num);
};

export const formatISODate = (dateStr) => {
  if (!dateStr) return format(new Date(), 'MM/dd/yyyy');
  return format(parseISO(dateStr), 'MM/dd/yyyy');
};

export const jumpToModule = (number) => {
  const m = document.getElementById(`module-${number}`);
  if (m) {
    m.scrollIntoView({ behavior: 'smooth' });
  }
};

export const hasDuplicates = (array) => new Set(array).size !== array.length;

export const replaceNullsWithEmptyStrings = (obj) => JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? '' : v)));

export const scrollIntoView = (id, property = 'name') => {
  const selector = `[${property}="${id}"]`;
  const element = document.querySelector(selector);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const formatCount = (count) => {
  if (count == null || isNaN(count)) return '-';
  return count.toLocaleString();
};
export const formatPositiveCount = (count) => {
  if (count == null || isNaN(count) || count < 0) return '-';
  return count.toLocaleString();
};

export const formatPercentNumber = (p) => `${p.toFixed(1)} %`;

export const formatPercent = (num, den) => {
  if (isNaN(num) || isNaN(den) || num == null || den == null || (num === 0 && den === 0)) return '--.- %';

  const _den = den || 1;
  const p = (num * 100) / _den;
  return formatPercentNumber(p);
};

export const percent = (num, den, { zeroBased = false } = {}) =>
  zeroBased ? (den ? num / den : 0) : (den && (num * 100) / den) || 0;

export const formatChangeInPercent = (num) => {
  if (isNaN(num) || num == null) return '--.- %';
  const fixed = num.toFixed(1);
  return fixed > 0 ? `+${fixed}%` : `${fixed}%`;
};

// To avoid uncontrolled input error message use this helper method to ensure no field value is null
export const initialValuesFormikParse = (object) =>
  Object.keys(object).reduce((values, item) => {
    values[item] = object[item] || undefined;
    return values;
  }, {});

export const humanizeString = (str) => {
  if (typeof str === 'string') {
    return str
      .split('_')
      .map((frag) => frag.charAt(0).toUpperCase() + frag.slice(1))
      .join(' ');
  }
  return str;
};

export const scrollTopView = () => {
  window.document.body.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const scrollMainContent = () => {
  const el = window.document.getElementById('WithTopBar');
  if (el) el.scrollTo(0, 0);
};

export const downloadFile = (response, filename) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const createContextMenuFromEvent = (e) => {
  const left = e.clientX;
  const top = e.clientY;
  const right = left + 1;
  const bottom = top + 1;

  return {
    getBoundingClientRect: () => ({
      left,
      top,
      right,
      bottom,
      height: 0,
      width: 0,
    }),
  };
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function (...args) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const roundTo = (value, precision = 1) => {
  if (!value) {
    return 0;
  }
  const factor = 10 ** precision;
  return Math.round(value * factor) / factor;
};

export const isValidHttpUrl = (string) => {
  try {
    return ['http:', 'https:'].includes(new URL(string).protocol);
  } catch {
    return false;
  }
};

export const formatAnyDate = (date, formatString) => {
  if (!date) return;

  if (isDate(date)) {
    return format(date, formatString);
  }

  const parsedDate = parseISO(date);
  if (isDate(parsedDate)) {
    return format(parsedDate, formatString);
  }

  return 'Invalid Date!';
};

export const formatAnyDateToTimeZone = (date, formatString, timeZone = DEFAULT_DJANGO_TIMEZONE) => {
  if (!date) return;

  const zonedDate = utcToZonedTime(date, timeZone);
  return formatAnyDate(zonedDate, formatString);
};

export const formatDuration = (durationInSeconds) => {
  if (durationInSeconds !== 0 && !durationInSeconds) {
    return '-';
  }
  return formatDistance(0, durationInSeconds * 1000, { includeSeconds: true });
};

export const formatDurationAsTime = (durationInSeconds) => {
  if (durationInSeconds !== 0 && !durationInSeconds) {
    return '-';
  }
  const hours = new Date(1000 * Math.round(durationInSeconds)).toISOString().substring(11, 19);
  if (durationInSeconds < 3600) {
    return hours.substring(3);
  }
  if (durationInSeconds > 86400) {
    return `${Math.floor(durationInSeconds / 86400)}d ${hours}`;
  }
};

export const formatPrices = (community, showStartingCostEmpty = false) => {
  if (!community) {
    return [];
  }

  let prices = [];

  if (community?.prices) {
    prices = community.prices.map((price) => ({
      name: price.name,
      cost: formatCurrency(price.cost),
    }));
  }

  prices.unshift({
    name: 'Ending cost',
    cost: community?.ending_cost ? formatCurrency(community.ending_cost) : '-',
  });

  if (community.starting_cost) {
    prices.unshift({
      name: 'Starting cost',
      cost: formatCurrency(community.starting_cost),
    });
  } else if (showStartingCostEmpty) {
    prices.unshift({
      name: 'Starting cost',
      cost: '-',
    });
  }

  return prices;
};

export const getObjectDiff = (obj1, obj2) => {
  const diff = {};

  const compareObjects = (innerObj1, innerObj2, path = '') => {
    for (const key in innerObj1) {
      if (Object.prototype.hasOwnProperty.call(innerObj1, key)) {
        const newPath = path ? `${path}.${key}` : key;

        if (!Object.prototype.hasOwnProperty.call(innerObj2, key)) {
          diff[newPath] = { oldValue: innerObj1[key], newValue: undefined };
        } else if (typeof innerObj1[key] === 'object' && typeof innerObj2[key] === 'object') {
          compareObjects(innerObj1[key], innerObj2[key], newPath);
        } else if (innerObj1[key] !== innerObj2[key]) {
          diff[newPath] = { oldValue: innerObj1[key], newValue: innerObj2[key] };
        }
      }
    }

    for (const key in innerObj2) {
      if (
        Object.prototype.hasOwnProperty.call(innerObj2, key) &&
        !Object.prototype.hasOwnProperty.call(innerObj1, key)
      ) {
        const newPath = path ? `${path}.${key}` : key;
        diff[newPath] = { oldValue: undefined, newValue: innerObj2[key] };
      }
    }
  };

  compareObjects(obj1, obj2);
  return diff;
};
