import React from 'react';
import styled from 'styled-components';

const Container = styled.span`
  line-break: anywhere;
`;

const Term = styled.span`
  font-family: var(--font-family-bold);
  font-size: 14px;
`;

const Operator = styled(Term)`
  color: var(--emerald-green);
`;

const OperatorRed = styled(Operator)`
  color: var(--salmon);
`;

const Field = styled(Term)``;

const Value = ({ children }) => {
  return <Term>{JSON.stringify(children)}</Term>;
};

const otherOperators = {
  equals: <Operator>is&nbsp;</Operator>,
  contains: <Operator>contains&nbsp;</Operator>,
  greater_than: <Operator>greater than&nbsp;</Operator>,
  less_than: <OperatorRed>less than&nbsp;</OperatorRed>,
  one_of: <OperatorRed>one of&nbsp;</OperatorRed>,
};

const Condition = ({ first, condition }) => {
  const showValue = !['exists', 'undefined'].includes(condition.type);
  return (
    <Container>
      {!first && <Operator>&nbsp;and&nbsp;</Operator>}
      {condition.type === 'exists' && <Operator>{first ? 'Has' : 'has'}&nbsp;</Operator>}
      {condition.type === 'undefined' && <OperatorRed>{first ? "Doesn't have" : "doesn't have"}&nbsp;</OperatorRed>}
      <Field>{condition.field}&nbsp;</Field>
      {otherOperators[condition.type]}
      {showValue && <Value>{condition.value}</Value>}
    </Container>
  );
};

export const Conditions = ({ value }) => {
  if (!value) {
    return <Term>No condition</Term>;
  }
  return (
    <>
      <Term>Condition:&nbsp;</Term>
      {value?.map((condition, idx) => (
        <Condition key={idx} first={idx === 0} condition={condition} />
      ))}
    </>
  );
};
