import React from 'react';

import { Route, Switch } from 'react-router-dom';

import MyProfile from '../advanced/MyProfile';

export const MyProfileRoutes = () => {
  return (
    <Switch>
      <Route exact path="/my-profile">
        <MyProfile />
      </Route>
    </Switch>
  );
};
