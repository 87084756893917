import React, { useCallback, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Dimmer, Loader, Message } from 'semantic-ui-react';

import { getLeadsConversations, getProfileCommunities } from 'apis';

import { ConversationsContext } from './ConversationsContext';
import { STATUSES, PAGINATION } from '../../constants';
import { ConversationsHeader } from './ConversationsHeader';
import { ConversationsControls } from './ConversationsControls';
import { ConversationsTable } from './ConversationsTable';
import FailureMessage from '../common/FailureMessage';

const ControlsWrapper = styled.div`
  padding: 40px 30px 27px 30px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ConversationsList = () => {
  const {
    communities,
    setCommunities,
    setLeads,
    filters,
    setTotalPages,
    setLeadsCount,
    loaderStatus,
    setLoaderStatus,
    buildQueryParams,
  } = useContext(ConversationsContext);

  const fetchLeads = useCallback(async () => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const queryParams = buildQueryParams();

      const { results, count } = await getLeadsConversations(queryParams);

      setLeads(results);
      setLeadsCount(count);
      setTotalPages(Math.ceil(count / PAGINATION.PAGE_SIZE));

      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error('We cannot load leads at this time. Please try again later.');
      setLoaderStatus(STATUSES.FAILURE);
    }
  }, [filters, setLeads, communities, setLoaderStatus]);

  useEffect(() => {
    const loadCommunities = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);

        const response = await getProfileCommunities({});
        setCommunities(response.results);

        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        toast.error('We cannot load communities at this time. Please try again later.');
        console.error(e);
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    if (communities.length === 0) {
      loadCommunities();
    }
  }, [filters, communities.length, setCommunities]);

  useEffect(() => {
    if (communities && communities.length > 0) {
      fetchLeads();
    }
  }, [fetchLeads, communities]);

  return (
    <Wrapper>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <ControlsWrapper>
        <ConversationsHeader />
        <ConversationsControls />
      </ControlsWrapper>
      <ConversationsTable />

      {loaderStatus === STATUSES.FAILURE && <FailureMessage icon="users" content="Conversations could not be loaded" />}
    </Wrapper>
  );
};
