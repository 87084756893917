import React, { useContext } from 'react';
import { ModuleForm } from './modules/ModuleForm/ModuleForm';
import { ModuleWrapper } from './ModuleWrapper';
import { ChatModulesContext } from './ChatModulesContext';

export const AddModule = ({ nextStep, onSubmit, onCancel }) => {
  const { moduleGraph } = useContext(ChatModulesContext);

  const emptyChildModule = {
    text: null,
    module_type: null,
    role: null,
    action: nextStep ? nextStep.number : null,
    number: moduleGraph.highestModuleNumber + 1,
    answers: [],
    module_traits: [],
    save_as: null,
    first_question: false,
  };

  return (
    <ModuleWrapper>
      <ModuleForm
        node={{ module: emptyChildModule }}
        onSubmit={({ module }) => {
          if (typeof onSubmit === 'function') {
            onSubmit(module);
          }
        }}
        onCancel={() => {
          if (typeof onCancel === 'function') {
            onCancel();
          }
        }}
      />
    </ModuleWrapper>
  );
};
