import React from 'react';
import { useField } from 'formik';
import { Form } from 'semantic-ui-react';

export const Checkbox = (props) => {
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <Form.Checkbox
      {...props}
      checked={!!value}
      onChange={() => {
        setValue(!value);
      }}
    />
  );
};
