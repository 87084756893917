import constants from './constants';

export const setReport = (data) => ({
  type: constants.SET_REPORT,
  payload: data,
});

export const setFilters = (filters) => ({
  type: constants.SET_FILTERS,
  payload: filters,
});

export const setActiveTab = (activeTab) => ({
  type: constants.SET_ACTIVE_TAB,
  payload: activeTab,
});

export const setTrafficSources = (trafficSources) => ({
  type: constants.SET_TRAFFIC_SOURCES,
  payload: trafficSources,
});

export const setChannels = (channels) => ({
  type: constants.SET_CHANNELS,
  payload: channels,
});

export const setCareTypes = (careTypes) => ({
  type: constants.SET_CARE_TYPES,
  payload: careTypes,
});
