import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { getStates, updateCommunityAssets, fetchCommunityScrapeResults } from 'apis';
import { capitalize } from 'utils/helpers';
import { STATUSES } from '../../constants';
import { ACTIONS, CommunitiesContext } from '../common/communities/CommunitiesContext';

export const CommunityAssetsContext = createContext();

export const CommunityAssetsContextProvider = (props) => {
  const { t } = useTranslation();
  const { communityId } = useParams();
  const { dispatch } = useContext(CommunitiesContext);

  const [states, setStates] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(STATUSES.IDLE);
  const [scrapeResults, setScrapeResults] = useState({});

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await getStates();
        setStates(response.results);
      } catch (e) {
        console.error(e);
        toast.error('Error loading states location information!');
      }
    };
    fetchStates();
  }, []);

  useEffect(() => {
    const onFetchScrape = async () => {
      try {
        const response = await fetchCommunityScrapeResults(communityId);
        setScrapeResults(response);
      } catch (error) {
        const msg = 'Trouble fetching scraped assets for requested URL';
        console.error(msg, error?.response);
        toast.error(msg);
      }
    };

    onFetchScrape();
  }, [communityId]);

  const onUpdateAssets = useCallback(
    async (data) => {
      try {
        setUpdateStatus(STATUSES.LOADING);
        const communityUpdates = await updateCommunityAssets(communityId, data);

        dispatch({
          type: ACTIONS.SET_COMMUNITY,
          payload: { communityId: parseInt(communityId), communityData: communityUpdates },
        });

        toast.success(`${capitalize(t('community'))} assets updated successfully`);
        setUpdateStatus(STATUSES.LOADED);
      } catch (error) {
        const msg = error?.response?.data?.reason
          ? error.response.data.reason
          : `Trouble updating assets for this ${t('community')}!`;
        console.error(msg, error?.response);
        toast.error(msg);
      } finally {
        setUpdateStatus < STATUSES.FAILURE;
      }
    },
    [communityId]
  );

  const provide = {
    states,
    scrapeResults,
    setScrapeResults,
    updateStatus,
    onUpdateAssets,
  };

  return <CommunityAssetsContext.Provider value={provide} {...props} />;
};
