import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { finishOnboarding } from 'apis/SelfServiceOnboardingAPI';
import { STATUSES } from 'constants';
import { Icon, Loader } from 'components/lib';
import { OnboardingContext } from '../OnboardingContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  height: fit-content;
  flex: 1 0 auto;
`;

const IconContainer = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 10px;
  background-color: #ebf5ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h3`
  font-family: var(--font-family-bold);
  font-size: 60px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.97;
  letter-spacing: normal;
  text-align: center;
  color: #222;
  margin: 30px 0;
`;

const Subtitle = styled.p`
  font-family: var(--font-family);
  font-size: 16px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #222;
  strong {
    font-family: var(--font-family-bold);
  }

  .error {
    color: var(--validation-red);
    color: red;
  }
`;

export const GenerateVSA = () => {
  const { onboarding, handleNextStep } = useContext(OnboardingContext);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [errorDetail, setErrorDetail] = useState(null);

  useEffect(() => {
    const finish = async () => {
      try {
        await finishOnboarding(onboarding.onboardingUuid);
        setLoaderStatus(STATUSES.LOADED);
        handleNextStep();
      } catch (e) {
        setLoaderStatus(STATUSES.FAILURE);
        toast.error('Error generating a VSA. Please contact support@talkfurther.com');
        if (e.response?.data?.detail) {
          setErrorDetail(e.response.data.detail);
        }
      }
    };

    if (onboarding) {
      setLoaderStatus(STATUSES.LOADING);
      setTimeout(finish, 3000);
    }
  }, [onboarding]);

  if (!onboarding) return null;

  return errorDetail ? (
    <Wrapper>
      <Title>Something went wrong</Title>
      <Subtitle>
        Error: {errorDetail}.
        <br /> Please contact our support team at{' '}
        <a className="mail" href="mailto:support@talkfurther.com">
          support@talkfurther.com
        </a>
        .
      </Subtitle>
    </Wrapper>
  ) : (
    <Wrapper>
      <IconContainer>
        <Icon name="build" variant="outlined" color="#275AFF" size="56" />
      </IconContainer>
      <Title>Generating your VSA!</Title>
      <Subtitle>Please wait while your custom VSA gets generated...</Subtitle>
      <Loader size="small" active={loaderStatus === STATUSES.LOADING} />
    </Wrapper>
  );
};
