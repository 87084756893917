import React, { useEffect, useState } from 'react';
import { format, startOfWeek, startOfMonth } from 'date-fns';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { useQuery } from 'react-query';

import { PageSection } from 'components/common';
import { getEngagementGraph } from 'apis';
import { toast } from 'react-toastify';
import { parseErrorResponse } from 'apis/utils';
import queryConstants from 'utils/queries/constants';
import { queryStatusToLoaderStatus } from 'utils/helpers';

import { useOwnersDashboardContext } from './OwnersDashboardContext';

const GraphMode = {
  days: 'days',
  weeks: 'weeks',
  months: 'months',
};

const Wrapper = styled.div`
  height: 25rem;
`;

const chartOptions = {
  maintainAspectRatio: false,
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          borderDash: [2, 10],
          drawBorder: false,
        },
        ticks: {
          padding: 16,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          borderDash: [2, 10],
          drawBorder: false,
        },
        ticks: {
          min: 0,
          padding: 16,
        },
      },
    ],
  },
};

export const OverviewGraph = ({ publicId }) => {
  const { filters } = useOwnersDashboardContext();

  const { data, error, status, isFetching } = useQuery(
    [queryConstants.ENGAGEMENT_GRAPH, { publicId, filters }],
    () => getEngagementGraph({ public_id: publicId, filters }),
    {
      placeholderData: [],
      select: (data) =>
        data.reduce(
          (accumulator, item) => {
            accumulator.labels.push(item.date);
            accumulator.visitors.push(item.visitors);
            accumulator.leads.push(item.leads);
            accumulator.tours.push(item.tours);
            accumulator.interactions.push(item.interactions);
            accumulator.move_ins.push(item.move_ins);
            return accumulator;
          },
          {
            labels: [],
            visitors: [],
            leads: [],
            tours: [],
            interactions: [],
            move_ins: [],
          }
        ),
    }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch engagement graph'));
  }

  const [leadsGraphData, setLeadsGraphData] = useState({});
  const [period, setPeriod] = useState(GraphMode.days);

  useEffect(() => {
    const prepareGraphData = (labels, visitors, leads, tours, interactions, moveIns) => {
      const leadsDataset = {
        labels: labels.slice(),
        datasets: [
          {
            label: 'Leads',
            backgroundColor: 'rgba(255, 127, 14, 0.2)',
            borderColor: 'rgba(255, 127, 14, 1)',
            borderWidth: 1,
            minBarLength: 5,
            hoverBackgroundColor: 'rgba(255, 127, 14, 0.4)',
            hoverBorderColor: 'rgba(255, 127, 14, 1)',
            data: leads.slice(),
          },
          {
            label: 'Requested Tours',
            backgroundColor: 'rgba(44, 160, 44, 0.2)',
            borderColor: 'rgba(44, 160, 44, 1)',
            borderWidth: 1,
            minBarLength: 5,
            hoverBackgroundColor: 'rgba(44, 160, 44, 0.4)',
            hoverBorderColor: 'rgba(44, 160, 44, 1)',
            data: tours.slice(),
          },
          {
            label: 'Move Ins',
            backgroundColor: 'rgba(242, 113, 115, 0.2)',
            borderColor: 'rgba(242, 113, 115, 1)',
            borderWidth: 1,
            minBarLength: 5,
            hoverBackgroundColor: 'rgba(242, 113, 115, 0.4)',
            hoverBorderColor: 'rgba(242, 113, 115, 1)',
            data: moveIns.slice(),
          },
        ],
      };

      setLeadsGraphData(leadsDataset);
    };

    const { labels = [], leads = [], tours = [], visitors = [], interactions = [], move_ins = [] } = data;

    if (period === GraphMode.days) {
      prepareGraphData(labels, visitors, leads, tours, interactions, move_ins);
      return;
    }

    const config =
      period === GraphMode.weeks
        ? {
            fn: startOfWeek,
            format: 'yyyy-LL-dd',
          }
        : {
            fn: startOfMonth,
            format: 'LL/yyyy',
          };

    const groupedData = {};
    const keys = new Set();
    labels.forEach((element, index) => {
      const groupLabel = format(config.fn(new Date(element)), config.format);

      if (!keys.has(groupLabel)) {
        keys.add(groupLabel);
        groupedData[groupLabel] = {
          visitors: 0,
          leads: 0,
          tours: 0,
          interactions: 0,
          moveIns: 0,
        };
      }

      groupedData[groupLabel].visitors += visitors[index];
      groupedData[groupLabel].leads += leads[index];
      groupedData[groupLabel].tours += tours[index];
      groupedData[groupLabel].interactions += interactions[index];
      groupedData[groupLabel].moveIns += move_ins[index];
    });

    const groupedLabels = [];
    const groupedVisitors = [];
    const groupedLeads = [];
    const groupedTours = [];
    const groupedInteractions = [];
    const groupedMoveIns = [];

    Object.keys(groupedData).forEach((key) => {
      groupedLabels.push(key);
      groupedVisitors.push(groupedData[key].visitors);
      groupedLeads.push(groupedData[key].leads);
      groupedTours.push(groupedData[key].tours);
      groupedInteractions.push(groupedData[key].interactions);
      groupedMoveIns.push(groupedData[key].moveIns);
    });

    prepareGraphData(groupedLabels, groupedVisitors, groupedLeads, groupedTours, groupedInteractions, groupedMoveIns);
  }, [data, period]);

  const switchTo = (period = GraphMode.days) => {
    setPeriod(period);
  };

  return (
    <PageSection
      loaderStatus={loaderStatus}
      actions={
        <Button.Group size="small" basic>
          <Button active={period === GraphMode.days} onClick={() => switchTo(GraphMode.days)}>
            Day
          </Button>
          <Button active={period === GraphMode.weeks} onClick={() => switchTo(GraphMode.weeks)}>
            Week
          </Button>
          <Button active={period === GraphMode.months} onClick={() => switchTo(GraphMode.months)}>
            Month
          </Button>
        </Button.Group>
      }
    >
      <Wrapper>
        <Line data={leadsGraphData} width={100} height={300} options={chartOptions} />
      </Wrapper>
    </PageSection>
  );
};
