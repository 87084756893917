import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

import { InputField } from 'components/common';
import { If } from 'components/If';
import { Radio } from 'components/lib/Radio';
import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import Dropdown from '../../../form/fields/Dropdown';
import { OPERATORS } from '../../constants';
import { MultiSelect } from './MultiSelect';
import Container from './Container';

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const RadioRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    margin-bottom: 11px;
  }

  &:last-child {
    margin-top: 11px;
  }
`;

const BaseOption = ({ name, criterion, options, canEditDelete, operators }) => {
  const operator = operators.find((o) => o.id === criterion.operator);
  const multiple = operator?.name === OPERATORS.IS_ANY_OF;
  const fullFieldName = `${name}.value`;
  const fieldName = `${fullFieldName}${multiple ? '' : '[0]'}`;
  const operatorFieldName = `${name}.operator`;
  const virtualFieldName = `${name}.virtual`;
  const [operatorField, _, operatorFieldHelpers] = useField(operatorFieldName);
  const [fullField, __, helpers] = useField(fullFieldName);
  const optionOptions = options.map((option) => ({
    key: option.id,
    text: option.displayName,
    value: option.id,
  }));

  const control = multiple ? MultiSelect : Dropdown;

  const operatorIs = operators.find((operator) => operator.name === OPERATORS.IS);
  const operatorIsNot = operators.find((operator) => operator.name === OPERATORS.IS_NOT);
  const operatorIsAnyOf = operators.find((operator) => operator.name === OPERATORS.IS_ANY_OF);
  const includes = [operatorIs?.id, operatorIsAnyOf?.id].includes(operatorField.value);
  const multipleAvailable = operatorIsAnyOf && includes;

  const onOperatorChange = (e, { value }) => {
    if (value !== operatorIsAnyOf?.id && fullField.value?.length > 1) {
      helpers.setValue(fullField.value[0]);
    }
    operatorFieldHelpers.setValue(value);
  };

  return (
    <Container header={criterion.displayName}>
      <If condition={operatorIs}>
        <RadioRow>
          <Radio
            name={operatorFieldName}
            value={operatorIs?.id}
            label={operatorIs?.displayName}
            checked={[operatorIs?.id, operatorIsAnyOf?.id].includes(operatorField.value)}
            onChange={onOperatorChange}
          />
          <If condition={multipleAvailable}>
            <RadioContainer>
              <Radio
                name={virtualFieldName}
                label="Single value"
                value={operatorIs?.id}
                checked={operatorField.value === operatorIs?.id}
                onChange={onOperatorChange}
              />
              <Radio
                name={virtualFieldName}
                label="Any of"
                value={operatorIsAnyOf?.id}
                checked={operatorField.value === operatorIsAnyOf?.id}
                onChange={onOperatorChange}
              />
            </RadioContainer>
          </If>
        </RadioRow>
      </If>

      <InputField
        name={fieldName}
        control={control}
        fluid
        selection
        options={optionOptions}
        placeholder="Select"
        className="option"
        disabled={!canEditDelete}
      />
      <If condition={operatorIsNot}>
        <RadioRow>
          <Radio
            name={operatorFieldName}
            value={operatorIsNot?.id}
            label={operatorIsNot?.displayName}
            checked={operatorField.value === operatorIsNot?.id}
            onChange={onOperatorChange}
          />
        </RadioRow>
      </If>
    </Container>
  );
};

export default BaseOption;
