import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { toast } from 'react-toastify';
import { subDays } from 'date-fns';

import { getCampaignsSearch, getCommunitiesGroups, getMessagingCommunities } from 'apis';
import { formatAnyDate } from 'components/utils';
import { parseIds } from 'utils/hooks/useSearchQuery';
import { PAGINATION } from '../../constants';

const NurtureFilterContext = createContext();

const today = new Date();
export const last30Days = formatAnyDate(subDays(today, 30), 'yyyy-MM-dd');

const actions = {
  SET_FILTERS: 'nurture_filter/SET_FILTERS',
  SET_COMMUNITIES: 'nurture_filter/SET_COMMUNITIES',
  SET_GROUPS: 'nurture_filter/SET_GROUPS',
  SET_CAMPAIGNS: 'nurture_filter/SET_CAMPAIGNS',
};

const searchParams = new URLSearchParams(window.location.search);

export const INITIAL_STATE = {
  communities: null,
  groups: null,
  campaigns: null,
  filters: {
    startDate: searchParams.get('startDate') || last30Days,
    endDate: searchParams.get('endDate') || null,
    communities: parseIds(searchParams.get('communities')),
    groups: parseIds(searchParams.get('groups')),
    campaigns: parseIds(searchParams.get('campaigns')),
  },
};

function nurtureFilterReducer(state, action) {
  switch (action.type) {
    case actions.SET_COMMUNITIES: {
      return { ...state, communities: action.data };
    }
    case actions.SET_GROUPS: {
      return { ...state, groups: action.data };
    }
    case actions.SET_CAMPAIGNS: {
      return { ...state, campaigns: action.data };
    }
    case actions.SET_FILTERS: {
      return { ...state, filters: action.data };
    }
    default: {
      return state;
    }
  }
}

function NurtureFilterProvider({ children }) {
  const [state, dispatch] = useReducer(nurtureFilterReducer, INITIAL_STATE);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const [{ results: communities }, { results: groups }, { results: campaigns }] = await Promise.all([
          getMessagingCommunities({ page_size: PAGINATION.MAX_PAGE_SIZE }),
          getCommunitiesGroups(),
          getCampaignsSearch({ pageSize: PAGINATION.MAX_PAGE_SIZE }),
        ]);
        dispatch({ type: actions.SET_COMMUNITIES, data: communities.sort((p1, p2) => p1.name.localeCompare(p2.name)) });
        dispatch({ type: actions.SET_GROUPS, data: groups });
        dispatch({ type: actions.SET_CAMPAIGNS, data: campaigns });
      } catch (e) {
        toast.error('Unable to fetch filters');
        console.error(e);
      }
    };

    fetchFilters();
  }, []);

  const updateFilters = (data) => {
    dispatch({ type: actions.SET_FILTERS, data: { ...state.filters, ...data } });
  };

  const buildQueryParams = (params) => {
    return Object.keys(params).reduce((acc, key) => {
      if (params[key] === null || params[key] === undefined || params[key] === '') {
        return acc;
      }
      if (Array.isArray(params[key])) {
        return { ...acc, [key]: params[key].join(',') };
      }

      return { ...acc, [key]: params[key] };
    }, {});
  };

  const value = { state, buildQueryParams, updateFilters };
  return <NurtureFilterContext.Provider value={value}>{children}</NurtureFilterContext.Provider>;
}

function useNurtureFilter() {
  const context = React.useContext(NurtureFilterContext);
  if (context === undefined) {
    throw new Error('useNurtureFilter must be used within a NurtureFilterProvider');
  }
  return context;
}

export { NurtureFilterProvider, useNurtureFilter };
