import React from 'react';

import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { STATUSES } from 'constants';
import { Table } from 'components/lib';
import { If } from 'components/If';
import FailureMessage from 'components/common/FailureMessage';

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmallText = styled.span`
  font-size: 12px;
`;

const UserNameCell = styled(Table.HeaderCell)`
  min-width: 200px;
`;

const DashboardTable = ({ dataRows, error, handlePageChange, loader, page, totalPages }) => {
  if (loader === STATUSES.FAILURE) {
    return <FailureMessage content={error} />;
  }

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <UserNameCell>Customer Name</UserNameCell>
          <Table.HeaderCell>Community Count</Table.HeaderCell>
          <Table.HeaderCell>Upcoming Move ins</Table.HeaderCell>
          <Table.HeaderCell>Move ins 30 Days</Table.HeaderCell>
          <Table.HeaderCell>Move ins 90 Days</Table.HeaderCell>
          <Table.HeaderCell>Move ins 365 Days</Table.HeaderCell>
          <UserNameCell>Account Manager</UserNameCell>
          <Table.HeaderCell>CRM Type</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {dataRows.map((dataRow) => (
          <Table.Row key={dataRow.customerEmail}>
            <Table.Cell>
              <VerticalContainer>
                <div>
                  <span>{dataRow.customerName} </span>
                  <If condition={dataRow.moveIns30 === 0}>
                    <Popup
                      trigger={<Icon name="exclamation circle" color="red" />}
                      content="This customer didn't have any move-ins in the past 30 days."
                      on="hover"
                      hoverable
                    />
                  </If>
                </div>
                <SmallText>{dataRow.customerEmail}</SmallText>
              </VerticalContainer>
            </Table.Cell>
            <Table.Cell>{dataRow.communitiesCount}</Table.Cell>
            <Table.Cell>{dataRow.moveInsUpcoming}</Table.Cell>
            <Table.Cell>{dataRow.moveIns30}</Table.Cell>
            <Table.Cell>{dataRow.moveIns90}</Table.Cell>
            <Table.Cell>{dataRow.moveIns365}</Table.Cell>
            <Table.Cell>{dataRow.accountManager?.name}</Table.Cell>
            <Table.Cell>
              {Array.from(new Set(dataRow.crmTypes.map((type) => type.crmTypeName)))
                .sort()
                .join(', ')}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan={6}>
            {totalPages > 1 && <Table.Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />}
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default DashboardTable;
