import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Segment } from 'semantic-ui-react';

import { Button, Dimmer, Form, Loader, TextArea } from 'components/lib';
import { CommunitiesContext } from 'components/chats/RelatedCommunities';
import { parseErrorResponse } from 'apis/utils';
import { Formik } from 'formik';
import { InputField } from 'components/common';
import { getSalesAssistantCommunityNotation, setSalesAssistantCommunityNotation } from 'apis';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import { STATUSES } from '../../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
`;

const INITIAL_VALUES = {
  notes: '',
};

const UserCommunityMessagingAssistantNotes = () => {
  const { state } = useContext(CommunitiesContext);
  const { activeCommunity } = state;
  const { id: communityId } = activeCommunity;
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [formLoading, setFormLoading] = useState(false);
  const loading = loaderStatus === STATUSES.LOADING || formLoading;

  const fetchNotes = useCallback(async () => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const { notes } = await getSalesAssistantCommunityNotation(communityId);
      setInitialValues({ notes });
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to fetch Messaging Assistant Notes.'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  }, [communityId]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  const handleSubmit = async (values) => {
    try {
      setFormLoading(true);
      await setSalesAssistantCommunityNotation(communityId, values);
      toast.success('Messaging Assistant Notes updated successfully');
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to update Messaging Assistant Notes.'));
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <Segment>
      <Container>
        <Dimmer active={loading} inverted>
          <Loader inverted active>
            Loading
          </Loader>
        </Dimmer>
        <If condition={loaderStatus === STATUSES.LOADED}>
          <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
              <Form>
                <Form.Field>
                  <InputField
                    type="text"
                    label="Notes"
                    name="notes"
                    placeholder="Enter Messaging Assistant Notes..."
                    control={TextArea}
                    rows={16}
                  />
                </Form.Field>
                <Button
                  primary
                  type="submit"
                  content="Update"
                  loading={loading}
                  disabled={loading}
                  onClick={handleSubmit}
                />
              </Form>
            )}
          </Formik>
        </If>
        <If condition={loaderStatus === STATUSES.FAILURE}>
          <FailureMessage icon="warning" content="Failed to fetch Messaging Assistant Notes." onTryAgain={fetchNotes} />
        </If>
      </Container>
    </Segment>
  );
};

export default UserCommunityMessagingAssistantNotes;
