import React from 'react';
import { useField } from 'formik';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledCheckbox = styled(Form.Checkbox)`
  &&& {
    display: flex;
    .ui.checkbox label:before {
      border: 2px solid #777777;
      width: 18px;
      height: 18px;
    }
    .ui.checkbox input:checked ~ label:before {
      background: var(--emerald-green);
      border-color: var(--emerald-green);
    }
    .ui.checkbox input:checked ~ label:after {
      width: 18px;
      height: 18px;
      color: #ffffff;
      content: '\\e5ca';
      font-family: 'Material Icons';
      padding-top: 1px;
    }
  }
`;

export const CheckboxField = ({ handleOnChange, ...props }) => {
  const [field, meta, { setValue }] = useField({ name: props.name, type: 'checkbox' });
  const { value } = meta;
  const { fieldProps } = field;

  const onChange = () => {
    handleOnChange && handleOnChange();
    setValue(!value);
  };

  return <StyledCheckbox {...props} {...fieldProps} checked={!!value} onChange={onChange} />;
};
