import { Table, Pagination } from 'semantic-ui-react';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Toggle } from 'components/lib';
import { InputField } from './fields/InputField';
import { scrollIntoView } from '../utils';
import { If } from '../If';
import { STICKY_OFFSETS } from '../../constants';

export const StickyTableHeader = styled(Table.Header)`
  tr:first-child > th {
    position: sticky !important;
    top: ${(props) => props.offset || STICKY_OFFSETS.NO_OFFSET}px;
    z-index: 2;
  }
`;

const TableWrapper = styled.div`
  .ui.table {
    tr {
      td,
      th {
        &:last-child {
          border-left: 1px solid rgba(34, 36, 38, 0.1);
        }

        &.hidden {
          display: none;
        }
      }
    }
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const COLUMNS_PER_PAGE = 5;

export const PricingTable = ({ values, headerOffset = STICKY_OFFSETS.NO_OFFSET, hasStartingEndingCost }) => {
  const [tablePage, setTablePage] = useState(1);
  const [viewStartingEndingColumns, setViewStartingEndingColumns] = useState();

  const { pricingColumns } = values;
  const pricingColumnsLength = pricingColumns.length;
  const showPagination = pricingColumnsLength > COLUMNS_PER_PAGE;

  const lowerLimit = (tablePage - 1) * COLUMNS_PER_PAGE;
  const upperLimit = tablePage * COLUMNS_PER_PAGE;

  const pagePricingColumns = values.pricingColumns.slice(lowerLimit, upperLimit);

  useEffect(() => {
    setViewStartingEndingColumns(hasStartingEndingCost);
  }, [hasStartingEndingCost]);

  const toggleViewStartingEndingColumns = () => setViewStartingEndingColumns(!viewStartingEndingColumns);

  return (
    <TableWrapper>
      <Box>
        <If condition={showPagination}>
          <Pagination
            activePage={tablePage}
            onPageChange={(e, { activePage }) => setTablePage(activePage)}
            size="mini"
            totalPages={Math.ceil(pricingColumnsLength / COLUMNS_PER_PAGE)}
            firstItem={null}
            lastItem={null}
          />
        </If>

        <If condition={!hasStartingEndingCost}>
          <Toggle
            label="View starting and ending cost"
            defaultChecked={false}
            onChange={toggleViewStartingEndingColumns}
          />
        </If>
      </Box>

      <Table fixed>
        <StickyTableHeader offset={headerOffset}>
          <Table.Row>
            <Table.HeaderCell>Location</Table.HeaderCell>

            {pagePricingColumns.map((c, i) => (
              <Table.HeaderCell key={c}>{c}</Table.HeaderCell>
            ))}
            {viewStartingEndingColumns && <Table.HeaderCell>Starting Cost</Table.HeaderCell>}
            {viewStartingEndingColumns && <Table.HeaderCell>Ending Cost</Table.HeaderCell>}
          </Table.Row>
        </StickyTableHeader>

        <Table.Body>
          {values.communities &&
            values.communities.map((community, i) => (
              <Table.Row key={i}>
                <Table.Cell>{community.name}</Table.Cell>

                {pagePricingColumns.map((c, j) => (
                  <Table.Cell key={c}>
                    <InputField
                      name={`prices[${i}][${c}].cost`}
                      disabled={!values.prices[i][c]}
                      type="number"
                      min="0"
                      icon="dollar"
                      iconPosition="left"
                      fluid
                      onError={scrollIntoView}
                    />
                  </Table.Cell>
                ))}
                {viewStartingEndingColumns && (
                  <Table.Cell>
                    <InputField
                      name={`communities[${i}].starting_cost`}
                      type="number"
                      min="0"
                      icon="dollar"
                      iconPosition="left"
                      fluid
                      onError={scrollIntoView}
                    />
                  </Table.Cell>
                )}
                {viewStartingEndingColumns && (
                  <Table.Cell>
                    <InputField
                      name={`communities[${i}].ending_cost`}
                      type="number"
                      min="0"
                      icon="dollar"
                      iconPosition="left"
                      fluid
                      onError={scrollIntoView}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </TableWrapper>
  );
};
