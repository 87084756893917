import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useFormikContext, FieldArray } from 'formik';

import { Icon, Button } from 'components/lib';
import { NextStep } from './NextStep';
import { Question } from './Question';
import { OnboardingContext } from '../OnboardingContext';

const Wrapper = styled.div`
  margin: 4.2857rem 0;
  width: 100%;
`;

const OptionsContainer = styled.div`
  margin: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 60px;
  gap: 30px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: ${({ numberOfColumns }) => (numberOfColumns === 1 ? '100%' : `calc(100% - 180px / ${numberOfColumns})`)};

  #addMore {
    min-height: 72px;
  }
`;

const Option = styled.div`
  position: relative;
  width: 100%;
  min-height: 72px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 30px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #fff;

  p {
    margin: 0;
    font-family: var(--font-family-bold);
    font-size: 14px;
    color: #222;
    text-align: left;
    white-space: pre-line;
  }

  &.active {
    box-shadow: 0 5px 10px 0 rgba(39, 90, 255, 0.1);
    border: solid 1px #275aff;
    background-color: #f2f5fb;
    p {
      color: #0ab776;
    }
  }

  &.alwaysActive {
    cursor: default;
    box-shadow: 0 5px 10px 0 rgba(39, 90, 255, 0.1);
    border: solid 1px #275aff;
    background-color: #f2f5fb;
    p {
      color: #0ab776;
    }

    filter: grayscale(100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::after {
      display: inline-block;
      content: 'Always asked';
      text-align: right;
      font-family: var(--font-family);
      font-size: 12px;
      color: #999;
      border-radius: 4px;
    }
  }
`;

const CloseContainer = styled.div`
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  box-shadow: 0 5px 10px 0 rgba(39, 90, 255, 0.1);
  border: solid 1px #275aff;
  background-color: #f2f5fb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FIXED_QUESTIONS = [
  {
    id: 1,
    question: 'What is your name?',
    alwaysActive: true,
    responses: [],
  },
  {
    id: 2,
    question: 'What is your email address?',
    alwaysActive: true,
    responses: [],
  },
  {
    id: 3,
    question: 'What is your phone number?',
    alwaysActive: true,
    responses: [],
  },
];

const splitIntoColumns = (arr, n) => Array.from({ length: n }, (_, i) => arr.filter((_, j) => j % n === i));

export const QualifyingQuestionsForm = ({ numberOfRows, numberOfColumns }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { values, setFieldValue, isValid } = useFormikContext();
  const { onboarding } = useContext(OnboardingContext);
  const [questionList, setQuestionList] = useState([]);
  const nextStepDisabled = !isValid;

  const editableQuestions = splitIntoColumns(questionList, numberOfColumns);

  const lastOption = values.qualifyingQuestions[values.qualifyingQuestions.length - 1];
  const showAddMore = questionList.length < 21 && selectedOptions.length < 5 && lastOption?.question !== '';

  let addMoreIndex = 0;
  for (let i = 0; i < editableQuestions.length; i++) {
    if (editableQuestions[i].length <= questionList.length / numberOfColumns) {
      addMoreIndex = i;
      break;
    }
  }

  useEffect(() => {
    const editableQuestions = onboarding?.qualifyingQuestions.map((item, index) => ({ ...item, id: index + 4 })) || [];
    setQuestionList([...FIXED_QUESTIONS, ...editableQuestions]);

    if (onboarding?.collectedData?.qualifyingQuestions?.length > 0) {
      const selectedOptions = onboarding.collectedData.qualifyingQuestions;
      setFieldValue('qualifyingQuestions', selectedOptions);
      setSelectedOptions(selectedOptions.map((item) => item.id));
    }
  }, [onboarding]);

  const handleOptionSelect = (option, push) => {
    if (option.alwaysActive) return;

    if (!selectedOptions.includes(option.id) && selectedOptions.length < 5) {
      setSelectedOptions((prev) => [...prev, option.id]);
      push(option);
    }
  };

  const handleOptionDeselect = (option, remove, values) => {
    if (option.alwaysActive) return;

    if (selectedOptions.includes(option.id)) {
      setSelectedOptions((prev) => prev.filter((item) => item !== option.id));
      const removeIndex = values.qualifyingQuestions.findIndex((item) => item.id === option.id);
      remove(removeIndex);
    }
    if (option.question === '') {
      setQuestionList((prev) => prev.filter((item) => item.id !== option.id));
    }
  };

  const handleAddMore = (push) => {
    const newOption = {
      id: questionList.length + 1,
      question: '',
      type: 'option',
      responses: [],
    };
    setQuestionList((prev) => {
      console.log('prev', prev);
      return [...prev, newOption];
    });
    handleOptionSelect(newOption, push);
  };

  return (
    <Wrapper>
      <OptionsContainer>
        <FieldArray name="qualifyingQuestions">
          {({ remove, push }) =>
            editableQuestions.map((colQuestions, colIndex) => (
              <Column key={colIndex} numberOfColumns={numberOfColumns}>
                {colQuestions.map((option, j) => {
                  const active = selectedOptions.includes(option.id);
                  const alwaysActive = option.alwaysActive;
                  const className = classNames({ active }, { alwaysActive });
                  return (
                    <Option key={j} onClick={() => handleOptionSelect(option, push)} className={className}>
                      {active && (
                        <CloseContainer onClick={() => handleOptionDeselect(option, remove, values)}>
                          <Icon name="close" color="#275aff" size="14" />
                        </CloseContainer>
                      )}
                      {active ? <Question initialValues={option} /> : <p>{option.question}</p>}
                    </Option>
                  );
                })}

                {showAddMore && colIndex === addMoreIndex && (
                  <Button
                    id="addMore"
                    className="link"
                    color="blue"
                    onClick={() => {
                      handleAddMore(push);
                    }}
                    type="button"
                  >
                    Add more <br />
                  </Button>
                )}
              </Column>
            ))
          }
        </FieldArray>
      </OptionsContainer>

      <NextStep disabled={nextStepDisabled} />
    </Wrapper>
  );
};
