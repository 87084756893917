import React, { useMemo } from 'react';

import { ReportComponent } from 'components/common/analytics';
import { formatPercentNumber, percent } from 'components/utils';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';
import { useMessagesTotals } from './MessagesTotals';
import PrevStats from '../common/PrevStats';

const getReplyableMessages = (data) =>
  (data['messages.lead']?.value || 0) -
  (data['messages.lead.stop']?.value || 0) -
  (data['messages.lead.sequential']?.value || 0) -
  (data['messages.community_manager']?.value || 0) +
  (data['messages.rereply']?.value || 0);

const MessagesTotalsPrevPeriod = () => {
  const { saleAssistantFilters: filters, getPreviousDateRange } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };

  const prevDateRange = getPreviousDateRange();
  const prevFilters = { ...maFilters, ...prevDateRange };

  const totals = useMessagesTotals(maFilters);
  const prevTotals = useMessagesTotals(prevFilters);

  const prevData = useMemo(() => {
    return [
      {
        label: 'Lead Messages',
        value: totals.data['messages.lead']?.value - totals.data['messages.lead.stop']?.value,
        prevValue: prevTotals.data['messages.lead']?.value - prevTotals.data['messages.lead.stop']?.value,
      },
      {
        label: 'MA Messages',
        value: totals.data['messages.sales_assistant']?.value,
        prevValue: prevTotals.data['messages.sales_assistant']?.value,
      },
      {
        label: 'MA Reply %',
        value: percent(totals.data['messages.sales_assistant']?.value, getReplyableMessages(totals.data)),
        prevValue: percent(prevTotals.data['messages.sales_assistant']?.value, getReplyableMessages(prevTotals.data)),
        formatter: formatPercentNumber,
      },
      {
        label: 'Stop Messages',
        value: totals.data['messages.lead.stop']?.value,
        prevValue: prevTotals.data['messages.lead.stop']?.value,
        lessIsBetter: true,
      },
      {
        label: 'Re-replied',
        value: totals.data['messages.rereply']?.value,
        prevValue: prevTotals.data['messages.rereply']?.value,
        lessIsBetter: true,
      },
    ];
  }, [totals, prevTotals]);

  return (
    <ReportComponent
      title={`Compared to previous ${prevDateRange.day_span} days`}
      tooltip={`Changes in messages metrics compared to previous time period, from ${prevDateRange.start_date} to ${prevDateRange.end_date}`}
      contentMargins="20px 0"
      contentHeight="50px"
    >
      <PrevStats data={prevData} />
    </ReportComponent>
  );
};

export default MessagesTotalsPrevPeriod;
