import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Dropdown, Modal } from 'components/lib';

const StyledModal = styled(Modal)`
  && {
    & > .close {
      padding-top: 6px;
    }
  }
`;

const ModalContent = styled(Modal.Content)`
  &&& {
    padding: 30px;
  }
`;

const ModalTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #89919d;

  & b {
    color: var(--text);
  }

  & a {
    color: var(--link);
  }
`;

const DropdownBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  & label {
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--text);
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 175px;
`;

const ARCHIVE_OPTIONS = [
  {
    text: 'All conversations',
    value: '0',
  },
  {
    text: 'Older than 7 days',
    value: '7',
  },
  {
    text: 'Older than 30 days',
    value: '30',
  },
  {
    text: 'Older than 60 days',
    value: '60',
  },
];

export const ARCHIVE_ALL_OPTION = ARCHIVE_OPTIONS[0].value;

const ArchiveConversationsModal = ({ open, loading, onConfirm, onClose }) => {
  const [value, setValue] = useState(ARCHIVE_ALL_OPTION);

  useEffect(() => {
    if (open) {
      setValue(ARCHIVE_ALL_OPTION);
    }
  }, [open]);

  const handleChange = (e, { value }) => {
    setValue(value);
  };

  const handleConfirm = () => {
    if (loading) {
      return;
    }
    onConfirm(value);
  };

  return (
    <StyledModal open={open} closeIcon size="tiny" onClose={onClose}>
      <ModalContent>
        <ModalTitle>Clean Up Conversations</ModalTitle>
        <Description>
          Select the range of messages to be <b>archived</b>. These can later be found in{' '}
          <a href="/conversations">All Conversations</a>, and will come back upon any reply or reminder.
        </Description>
        <DropdownBox>
          <label>Archive</label>
          <StyledDropdown
            basic
            selection
            options={ARCHIVE_OPTIONS}
            defaultValue={ARCHIVE_ALL_OPTION}
            onChange={handleChange}
          />
        </DropdownBox>
      </ModalContent>
      <Modal.Actions>
        <Button content="Archive" primary color="blue" onClick={handleConfirm} />
        <Button content="Cancel" primary onClick={onClose} />
      </Modal.Actions>
    </StyledModal>
  );
};

export default ArchiveConversationsModal;
