import React, { useEffect } from 'react';
import styled from 'styled-components';

import { InputField } from 'components/common';
import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import { Input } from 'components/lib';
import { useField } from 'formik';
import Display from './Display';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  .helper-text {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
    text-align: center;
  }
`;

const Box = styled.div`
  display: flex;
  gap: 10px;

  .display {
    width: 150px;
  }
`;

const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled.div`
  flex: 1;
  width: 50px;
`;

const Error = styled.div`
  padding-top: 3px;
  font-size: 0.8571rem;
  color: var(--validation-red);
  text-align: center;
`;

const RangeInteger = ({ name, criterion }) => {
  const { state, hasEditDeletePermission } = useCampaignDetails();
  const { criteria } = state;
  const { helpText } = criteria.find((c) => c.id === criterion.criterionId);
  const [, { error, touched }] = useField(`${name}.value`);
  const errorText = Array.isArray(error) ? error.find((e) => !!e) : error;

  return (
    <Container>
      <InputContainer>
        <Box>
          <Display className="display" size="sm">
            {criterion.displayName}
          </Display>
          <InputWrapper>
            <InputField
              name={`${name}.value[0]`}
              control={Input}
              className="input"
              disabled={!hasEditDeletePermission}
              hideError
            />
          </InputWrapper>
          <Divider>-</Divider>
          <InputWrapper>
            <InputField
              name={`${name}.value[1]`}
              control={Input}
              className="input"
              disabled={!hasEditDeletePermission}
              hideError
            />
          </InputWrapper>
        </Box>
        {helpText && <div className="helper-text">{helpText}</div>}
      </InputContainer>
      {touched && errorText && <Error>{errorText}</Error>}
    </Container>
  );
};

export default RangeInteger;
