import React from 'react';
import { PieChart } from './PieChart';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const metrics = ['answers.reviewed', 'answers.not_reviewed'];

export const ReviewedPieChart = () => {
  const { reviewFilters } = useSystemStatsFilters();
  return (
    <PieChart
      units="Automated Answers"
      metrics={metrics}
      filters={reviewFilters}
      getCircleData={(data) =>
        data
          ? {
              Reviewed: data['answers.reviewed']?.value,
              'Not Reviewed': data['answers.not_reviewed']?.value,
            }
          : {}
      }
    />
  );
};
