import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

import { Loader, Icon } from 'components/lib';
import { UserCircle } from 'components/UserCircle';
import { UserContext } from 'components/UserContext';
import { administrateUser, getManagementCurrentUser } from 'apis/ManagementAPI';
import { parseErrorResponse } from 'apis/utils';

const Container = styled.div`
  font-family: var(--font-family);
  padding: 13px 11px 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #071b45;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.27);
  border: solid 1px rgba(255, 255, 255, 0.16);
  border-radius: 4px;
`;

const LeftContainerBox = styled.div`
  display: flex;
  align-items: center;
`;

const LeftIcon = styled.div`
  margin-right: 10px;
`;

const TextBox = styled.div`
  line-height: 14px;
  width: 130px;
`;

const Description = styled.div`
  font-size: 9px;
  font-weight: 500;
  color: var(--white);
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ManagementUserActive = () => {
  const history = useHistory();
  const [loading, setLoading] = useState();
  const { user } = useContext(UserContext);
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const result = await getManagementCurrentUser();

        setCurrentUser(result);
      } catch (e) {
        console.error(e);
      }
    };

    if (user.impersonated_session) {
      fetchCurrentUser();
    }
  }, [user]);

  const handleRemove = async () => {
    try {
      setLoading(true);
      await administrateUser(currentUser.id, { administrate: false });

      history.push('/management/session');
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to remove management session'));
    } finally {
      setLoading(false);
      location.reload();
    }
  };

  if (!currentUser) {
    return null;
  }

  return (
    <Container>
      <LeftContainerBox>
        <LeftIcon>
          <UserCircle user={currentUser} size="20" />
        </LeftIcon>
        <TextBox>
          <Description>Now managing</Description>
          <Title>{currentUser.name || currentUser.email}</Title>
        </TextBox>
      </LeftContainerBox>
      {loading ? (
        <Loader active size="small" transparent />
      ) : (
        <Icon name="close" color="rgba(255, 255, 255, 0.6)" onClick={handleRemove} />
      )}
    </Container>
  );
};

export default ManagementUserActive;
