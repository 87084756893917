import React from 'react';
import { Link, Switch, Redirect, Route, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { Content, PageHeader } from 'components/common';
import AutomatedAnswersReport from './AutomatedAnswerReport';
import CommunitiesReport from './CommunitiesReport';
import MessagingAssistantReport from './MessagingAssistantReport';
import NurtureOnlyReport from './NurtureOnlyReport';
import OverviewReport from './OverviewReport';
import { SystemStatsFiltersProvider } from './SystemStatsFiltersContext';
import SystemStatsFilters from './SystemStatsFilters';
import SalesAssistantReport from './SalesAssistantReport';
import { AutomationReport } from './AutomationReport';

const STAT_REPORTS = [
  {
    key: 'overview',
    path: '/admin/stats/overview',
    exact: true,
    label: 'Overview',
    component: OverviewReport,
  },
  {
    key: 'sales-assistant',
    path: '/admin/stats/sales-assistant',
    exact: true,
    label: 'Sales Assistant - Old',
    component: SalesAssistantReport,
  },
  {
    key: 'messaging-assistant',
    path: '/admin/stats/messaging-assistant',
    exact: true,
    label: 'Messaging Assistant',
    component: MessagingAssistantReport,
  },
  {
    key: 'nurture-only',
    path: '/admin/stats/messaging-only',
    exact: true,
    label: 'Messaging Only',
    component: NurtureOnlyReport,
  },
  {
    key: 'community-metrics',
    path: '/admin/stats/community-metrics',
    exact: true,
    label: 'Community Metrics',
    component: CommunitiesReport,
  },
  {
    key: 'automated-answers',
    path: '/admin/stats/automated-answers',
    exact: true,
    label: 'Messaging Assistant Answers',
    component: AutomatedAnswersReport,
  },
  {
    key: 'automation',
    path: '/admin/stats/automation',
    exact: true,
    label: 'Automation',
    component: AutomationReport,
  },
];

const SystemStats = () => {
  const location = useLocation();
  return (
    <SystemStatsFiltersProvider>
      <Content>
        <PageHeader
          as="h2"
          title="System Stats"
          subtitle="System wide stats to help understand how system is used"
          padding={PageHeader.PADDING.VERTICAL}
          margin={PageHeader.MARGIN.NONE}
        />
        <SystemStatsFilters />
        <Menu size="large" secondary pointing>
          {STAT_REPORTS.map((item) => (
            <Menu.Item
              key={item.key}
              as={Link}
              to={`${item.path}${location.search}`}
              active={location.pathname === item.path}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
        <Switch>
          {STAT_REPORTS.map((item) => (
            <Route {...item} />
          ))}
          <Redirect to={STAT_REPORTS[0].path} />
        </Switch>
      </Content>
    </SystemStatsFiltersProvider>
  );
};

export default SystemStats;
