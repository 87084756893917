import React from 'react';

import { Button, Icon } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { STATUSES } from '../../constants';
import LeadUserReminderDropdown from './LeadUserReminderDropdown';

const LeadUserReminder = ({ open, leadUserReminder, loaderStatus, onSelect, onRemove, onOpen, onClose }) => {
  const loading = loaderStatus === STATUSES.LOADING;
  const { id: leadUserReminderId, scheduled_at } = leadUserReminder;

  const toggleDropdown = () => {
    if (open) {
      onClose();
    } else {
      onOpen();
    }
  };

  return (
    <LeadUserReminderDropdown
      open={open}
      leadUserReminder={leadUserReminder}
      loading={loaderStatus === STATUSES.LOADING}
      onOpen={onOpen}
      onClose={onClose}
      onSelect={onSelect}
      onRemove={onRemove}
    >
      <Button color="orange" onClick={toggleDropdown} disabled={loading} secondary>
        <Icon name="notifications_active" variant="outlined" size="22" />
        {leadUserReminderId && <span>{formatAnyDate(scheduled_at, 'MMMM do')}</span>}
        {!leadUserReminderId && <span>Remind</span>}
      </Button>
    </LeadUserReminderDropdown>
  );
};

export default LeadUserReminder;
