import React from 'react';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';

import { If } from 'components/If';

const LabelText = styled.span`
  & + i {
    margin-left: 0.5rem;
  }
`;

const Label = ({ name, defaultValue }) => (
  <label>
    <LabelText>{name}</LabelText>
    <If condition={defaultValue}>
      <Popup
        trigger={<Icon name="info" color="blue" size="small" link circular />}
        content={`If no value then set ${defaultValue}`}
        hoverable
        position="top center"
        style={{ zIndex: 10000 }}
      />
    </If>
  </label>
);

export default Label;
