import React from 'react';
import { Message } from 'semantic-ui-react';

import { DateRangePicker, PageHeader } from 'components/common';
import { If } from '../If';
import { STATUSES } from '../../constants';
import { useOwnersDashboardContext } from './OwnersDashboardContext';
import { Metrics } from './Metrics';
import { OverviewGraph } from './OverviewGraph';
import { MetricsBreakdown } from './MetricsBreakdown';

export const SummaryOverview = () => {
  const { filters, setFilters, communitiesLoader, communityId } = useOwnersDashboardContext();

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, start_date: startDate, end_date: endDate });
  };

  return (
    <>
      <PageHeader
        as="h2"
        title="Data Summary"
        subtitle="General statistics for all your companies"
        padding={PageHeader.PADDING.VERTICAL}
      />
      <DateRangePicker dateRange={filters} onFilterChange={onDateRangeChange} />
      <If condition={communitiesLoader === STATUSES.LOADED}>
        <Metrics />
        <OverviewGraph />
        <If condition={!communityId}>
          <MetricsBreakdown />
        </If>
      </If>

      <If condition={communitiesLoader === STATUSES.FAILURE}>
        <Message error>
          <Message.Header>Error Loading Communities</Message.Header>
          <Message.Content>
            Something went wrong and we couldn't fetch your communities. Please try again, or reach out over email for
            help.
          </Message.Content>
        </Message>
      </If>
    </>
  );
};
