import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const VARIANT_COLOR = {
  orange: '#fa7550',
  green: '#0ab776',
  blue: '#1f58b0',
  red: '#ff0000',
};

const Container = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const Title = styled.div`
  font-size: 14px;
  color: ${({ color }) => VARIANT_COLOR[color]};
  ${({ clickable }) => clickable && 'cursor: pointer;'}
`;

const ValueChange = ({ title, color, onTitleClick }) => {
  return (
    <Container>
      <Title color={color} onClick={onTitleClick} clickable={typeof onTitleClick === 'function'}>
        {title}
      </Title>
    </Container>
  );
};

ValueChange.propTypes = {
  color: PropTypes.oneOf(['orange', 'green', 'blue', 'red']),
};

export default ValueChange;
