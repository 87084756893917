import React, { createContext, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { fetchOnboardingData, createOnboarding, updateOnboarding, checkWebsite } from 'apis';
import { useOnboardingReducer, ONBOARDING_ACTIONS } from './onboardingStateReducer';

export const OnboardingContext = createContext();

export const OnboardingContextProvider = (props) => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const queryParams = new URLSearchParams(location.search);

  const { step, onboardingUuid } = params;
  const onboardingTemplateTag = queryParams.get('template');

  const [onboardingState, dispatchOnboardingAction] = useOnboardingReducer({
    step,
    onboardingTemplateTag,
  });

  const { onboarding, steps, currentStepNumber } = onboardingState;

  const [hidePreviousStep, setHidePreviousStep] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const onboarding = await fetchOnboardingData(onboardingUuid);
      dispatchOnboardingAction({ type: ONBOARDING_ACTIONS.SET_ONBOARDING, payload: { onboarding } });
    };

    if (onboardingUuid && !onboarding) {
      fetchData();
    }
  }, [onboardingUuid, onboarding]);

  const _pollScrapingStatus = (onboardingUuid) => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        const response = await fetchOnboardingData(onboardingUuid);
        if (response && ['completed', 'failed', 'timed_out'].includes(response.scrapingStatus)) {
          clearInterval(interval);
          resolve(response);
        }
      }, 3000);
    });
  };

  const setUrl = ({ onboardingUuid, stepNumber }) => {
    const urlPath = ['self-service-onboarding', onboardingUuid, 'steps', stepNumber].filter((p) => !!p).join('/');
    history.push(`/${urlPath}`);
  };

  const startOnboarding = async (values) => {
    const shouldPollScrapingStatus = !onboardingTemplateTag;

    await checkWebsite({ website: values.website });

    let onboarding = await createOnboarding({ ...values, template: onboardingTemplateTag });
    if (!onboarding.onboardingUuid) {
      throw new Error(
        'Something went wrong while trying to onboard your website. Please contact support@talkfurther.com for help!'
      );
    }

    if (shouldPollScrapingStatus) {
      onboarding = await _pollScrapingStatus(onboarding.onboardingUuid);
    }

    if (['failed', 'timed_out'].includes(onboarding.scrapingStatus)) {
      onboarding = await updateOnboarding(onboarding.onboardingUuid, { useScrapedData: false });
    }

    dispatchOnboardingAction({ type: ONBOARDING_ACTIONS.SET_ONBOARDING, payload: { onboarding } });
    _goToNextStep(onboarding);
  };

  const updateOnboardingData = async (collectedStepData) => {
    const response = await updateOnboarding(onboardingUuid, { collectedData: collectedStepData });
    dispatchOnboardingAction({ type: ONBOARDING_ACTIONS.SET_ONBOARDING, payload: { onboarding: response } });
  };

  const _goToPreviousStep = () => {
    if (currentStepNumber > 1) {
      const prevStepNumber = currentStepNumber - 1;
      setUrl({
        onboardingUuid: onboarding.onboardingUuid,
        stepNumber: prevStepNumber,
      });
      dispatchOnboardingAction({
        type: ONBOARDING_ACTIONS.SET_CURRENT_STEP,
        payload: { stepNumber: prevStepNumber },
      });
    }
  };

  const _goToNextStep = (onboarding) => {
    if (currentStepNumber < steps.length) {
      const nextStepNumber = currentStepNumber + 1;
      setUrl({
        onboardingUuid: onboarding.onboardingUuid,
        stepNumber: nextStepNumber,
      });
      dispatchOnboardingAction({
        type: ONBOARDING_ACTIONS.SET_CURRENT_STEP,
        payload: { stepNumber: nextStepNumber },
      });
    }
  };

  const handlePreviousStep = () => {
    _goToPreviousStep();
  };

  const handleNextStep = (collectedStepData) => {
    if (collectedStepData) {
      updateOnboardingData(collectedStepData);
    }
    _goToNextStep(onboarding);
  };

  const _pollSiteManagerInstalled = (onboardingUuid) => {
    return new Promise((resolve, reject) => {
      let count = 0;
      const interval = setInterval(async () => {
        count++;
        const response = await fetchOnboardingData(onboardingUuid);
        if (response && response.siteSetupRequested) {
          clearInterval(interval);
          resolve(response);
        }
        if (count > 5) {
          clearInterval(interval);
          reject(response);
        }
      }, 3000);
    });
  };

  const checkSiteManagerInstalled = async () => {
    try {
      const onboarding = await _pollSiteManagerInstalled(onboardingUuid);
      return { isInstalled: !!onboarding.siteSetupRequested };
    } catch (error) {
      return { isInstalled: false };
    }
  };

  const provide = {
    hidePreviousStep,
    handleNextStep,
    handlePreviousStep,
    startOnboarding,
    updateOnboardingData,
    checkSiteManagerInstalled,
    setHidePreviousStep,
    ...onboardingState,
  };

  return <OnboardingContext.Provider value={provide} {...props} />;
};
