import React from 'react';
import { useField } from 'formik';
import { Select } from 'semantic-ui-react';

export const SelectField = (props) => {
  const [, meta, helpers] = useField(props);
  const { value, touched, error } = meta;
  const { setValue } = helpers;
  const { onSelected } = props;

  const onChange = (e, d) => {
    setValue(d.value);

    if (typeof onSelected === 'function') {
      onSelected(d.value);
    }
  };

  return <Select {...props} value={value} onChange={onChange} error={touched && !!error} />;
};
