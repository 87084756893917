import React from 'react';
import styled from 'styled-components';

import { Button, Modal } from 'components/lib';

const ModalContent = styled(Modal.Content)`
  &&&& {
    padding: 30px;
  }
`;

const UserCommunityTestimonialsDeleteModal = ({ testimonial, open, loading, onDelete, onClose }) => {
  return (
    <Modal size="tiny" closeIcon open={open} onClose={onClose}>
      <Modal.Header>Delete Testimonial</Modal.Header>
      <ModalContent scrolling>
        <div>
          Would you like to delete the testimonial for{' '}
          <em>
            <q>
              {testimonial.first_name} {testimonial.last_name}
            </q>
          </em>
          ?
        </div>
        {testimonial.show_on_embedded_vsa && (
          <div>
            <strong>This testimonial will be removed from the VSA.</strong>
          </div>
        )}
      </ModalContent>
      <Modal.Actions>
        <Button primary color="orange" onClick={() => onDelete(testimonial.id)} loading={loading}>
          Delete
        </Button>
        <Button basic onClick={onClose} disabled={loading}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UserCommunityTestimonialsDeleteModal;
