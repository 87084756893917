import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { Checkbox, Dimmer, Loader } from 'components/lib';
import { SearchField } from './SearchField';
import { FilterDropdown } from './FilterDropdown';

const Wrapper = styled.div``;

const StyledCheckbox = styled(Checkbox)`
  && label {
    font-size: 18px;
    font-weight: 900;
    color: var(--text);
    text-transform: capitalize;

    :before {
      border: 2px solid #777777;
      width: 18px;
      height: 18px;
      top: -3px;
    }

    :after {
      top: -3px;
    }
  }
`;

const MainActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .row-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const CommunitiesContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const Error = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: var(--validation-red);
`;
const RowWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const FILTER_OPTIONS = [
  { key: 1, text: 'All', value: 'all' },
  { key: 2, text: 'Selected', value: true },
  { key: 3, text: 'Unselected', value: false },
];

export const CommunitiesMenu = ({ communities, loading, disabled }) => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
  const errorMessage = touched.relatedCommunities && errors.relatedCommunities;

  const initialFilterValue = 'all';
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState(initialFilterValue);
  const [selectAll, setSelectAll] = useState(false);
  const { t } = useTranslation();

  const selectedCommunities = useMemo(() => {
    if (!values?.relatedCommunities || values.relatedCommunities.length === 0) return {};

    return values.relatedCommunities.reduce((acc, communityId) => ({ ...acc, [communityId]: true }), {});
  }, [values.relatedCommunities]);

  const filteredCommunities = useMemo(() => {
    let newCommunities = communities;

    if (!newCommunities || newCommunities.length === 0) {
      return [];
    }

    if (searchValue) {
      newCommunities = communities.filter((community) =>
        community.name?.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    if (filterValue === true) {
      newCommunities = newCommunities.filter((community) => selectedCommunities[community.id]);
    }

    if (!filterValue) {
      newCommunities = newCommunities.filter((community) => !selectedCommunities[community.id]);
    }

    return newCommunities;
  }, [communities, selectedCommunities, searchValue, filterValue]);

  const changeSelectFilter = (e, d) => {
    setFilterValue(d.value);
  };

  const changeSearchValue = (value) => {
    setSearchValue(value);
    setFieldTouched('relatedCommunities', true);
  };

  const changeSelectAll = (e, d) => {
    setSelectAll(!selectAll);
    setFieldTouched('relatedCommunities', true);

    if (!d.checked) {
      setFieldValue('relatedCommunities', []);
      return;
    }

    const newCommunities = filteredCommunities
      .filter((community) => !selectedCommunities[community.id])
      .map((community) => community.id);

    setFieldValue('relatedCommunities', [...values.relatedCommunities, ...newCommunities]);
  };

  const selectCommunity = (community) => () => {
    setFieldTouched('relatedCommunities', true);
    const oldCommunities = values.relatedCommunities;
    if (oldCommunities.includes(community.id)) {
      const newCommunities = oldCommunities?.filter((i) => i !== community.id);
      setFieldValue('relatedCommunities', newCommunities);
      return;
    }

    setFieldValue('relatedCommunities', oldCommunities?.concat(community.id));
  };

  return (
    <Dimmer.Dimmable as={Wrapper} dimmed={loading}>
      <Dimmer active={loading} inverted>
        <Loader active inverted />
      </Dimmer>
      <MainActionsContainer>
        <div className="row-item">
          <RowWrapper>
            <StyledCheckbox
              disabled={disabled}
              label={<label>{t('communities')} </label>}
              onChange={changeSelectAll}
              checked={selectAll}
            />
            <If condition={errorMessage}>
              <Error>{errorMessage}</Error>
            </If>
          </RowWrapper>
          <FilterDropdown value={filterValue} options={FILTER_OPTIONS} onChange={changeSelectFilter} />
        </div>
        <div className="row-item">
          <SearchField placeholder="Search" value={searchValue} onSearch={changeSearchValue} />
        </div>
      </MainActionsContainer>
      <If condition={filteredCommunities && filteredCommunities.length > 0}>
        <CommunitiesContainer>
          {filteredCommunities.map((community) => (
            <Checkbox
              disabled={disabled}
              key={community.id}
              label={<label>{community.name}</label>}
              onChange={selectCommunity(community)}
              checked={!!selectedCommunities[community.id]}
            />
          ))}
        </CommunitiesContainer>
      </If>
    </Dimmer.Dimmable>
  );
};
