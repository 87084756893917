import React, { useContext } from 'react';
import styled from 'styled-components';

import { firstInArray, splitName } from 'utils/helpers';
import { UserCircle } from 'components/UserCircle';
import { If } from '../If';
import { CommunitiesContext } from '../common/communities/CommunitiesContext';
import { GroupTag } from './GroupTag';
import { UserContext, USER_TYPES } from '../UserContext';
import UserCommunityOptions from './UserCommunityOptions';

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 25px 30px;
  h2 {
    margin: 0;
    margin-left: 20px;
    font-family: var(--font-family-bold);
    font-size: 40px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222;
  }
`;

const GroupsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 30px;
  gap: 10px;
`;

export const UserCommunityHeader = () => {
  const { getSessionOriginalUser } = useContext(UserContext);
  const user = getSessionOriginalUser();
  const isStaffUser = user.type === USER_TYPES.STAFF;
  const { state } = useContext(CommunitiesContext);
  const { activeCommunity } = state;

  const { first_name, last_name } = splitName(activeCommunity?.name) || {};
  const communityCircleData = {
    id: activeCommunity?.id,
    first_name,
    last_name,
    email: activeCommunity?.email,
    profile_picture: firstInArray(activeCommunity?.media?.images),
  };

  return (
    <>
      <If condition={activeCommunity?.groups.length > 0}>
        <GroupsContainer>
          {activeCommunity?.groups
            .filter((group) => group?.user === user.id)
            .map((group) => (
              <GroupTag key={group.id} group={group} />
            ))}
        </GroupsContainer>
      </If>
      <HeaderContainer>
        <UserCircle user={communityCircleData} size="40" />
        <h2>{activeCommunity?.name}</h2>
        <If condition={isStaffUser}>
          <UserCommunityOptions />
        </If>
      </HeaderContainer>
    </>
  );
};
