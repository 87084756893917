import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import { UserContext } from 'components/UserContext';
import { getDefaultRouteForUser } from 'components/common/routes/common';
import { getLoggedInUser } from 'api';
import { parseErrorResponse } from 'apis/utils';
import FailureMessage from 'components/common/FailureMessage';

const ManagementSession = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    const getUser = async () => {
      try {
        setLoading(true);
        const user = await getLoggedInUser();
        const redirectRoute = getDefaultRouteForUser(user);

        setUser(user);
        history.push(redirectRoute);
      } catch (e) {
        setError(parseErrorResponse(e, 'Unable to manage user. Please try again'));
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, []);

  return (
    <Segment basic>
      <Dimmer active={loading} inverted>
        <Loader active />
      </Dimmer>
      {error && <FailureMessage icon="warning" content={error} />}
    </Segment>
  );
};

export default ManagementSession;
