import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { createSupportMode, getSupportModeCurrentUser } from 'apis/SupportModeAPI';
import { UserContext } from 'components/UserContext';
import { parseErrorResponse } from 'apis/utils';
import SupportModeDropdown from './SupportModeDropdown';
import SupportModeActive from './SupportModeActive';

const SupportModeSelect = () => {
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState();
  const { user } = useContext(UserContext);
  const [currentUser, setCurrentUser] = useState();

  const fetchCurrentUser = useCallback(async () => {
    try {
      const result = await getSupportModeCurrentUser();

      setCurrentUser(result);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const initialize = async () => {
      if (user.impersonated_session) {
        await fetchCurrentUser();
      }
      setInitialized(true);
    };

    initialize();
  }, [fetchCurrentUser]);

  const handleChange = async (userId = null, demoMode) => {
    try {
      setLoading(true);
      await createSupportMode({ id: userId, demo_mode: demoMode });

      if (!userId) {
        setCurrentUser(null);
      } else {
        await fetchCurrentUser();
      }
      history.push('/support-mode');
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to enter support mode'));
    } finally {
      setLoading(false);
      location.reload();
    }
  };

  if (!initialized) {
    return null;
  }

  if (currentUser) {
    return <SupportModeActive user={currentUser} onRemove={handleChange} loading={loading} />;
  }

  return <SupportModeDropdown onSelect={handleChange} loading={loading} />;
};

export default SupportModeSelect;
