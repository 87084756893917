import React, { useContext, useEffect, useState } from 'react';

import styled from 'styled-components';

import { formatPrices } from 'components/utils';
import { CommunitiesContext } from '../../common/communities/CommunitiesContext';

const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const PricingList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ order }) => (order % 2 === 0 ? 'var(--white)' : '#fcfcfc')};
  padding: 8px 12px;
  border: solid 1px var(--line-standard);
  &:not(:last-child) {
    border-bottom: none;
  }
  span,
  strong {
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--text);
  }
  &:first-child {
    span,
    strong {
      color: var(--text);
      font-family: var(--font-family-bold);
    }
  }
  strong {
    color: var(--emerald-green);
  }
`;

export const UserCommunityHomeSidebarPricing = () => {
  const { state } = useContext(CommunitiesContext);
  const { activeCommunity } = state;

  const [prices, setPrices] = useState([]);

  useEffect(() => {
    setPrices(formatPrices(activeCommunity?.cost, true));
  }, [activeCommunity]);

  return (
    <PricingContainer>
      <h3>Pricing</h3>
      <PricingList>
        {prices.map((price, i) => (
          <Price key={i} order={i}>
            <span>{price.name}</span>
            <strong>{price.cost}</strong>
          </Price>
        ))}
      </PricingList>
    </PricingContainer>
  );
};
