import { ExperimentsActive } from './ExperimentsActive';
import { ExperimentsArchived } from './ExperimentsArchived';

export const EXPERIMENTS_TABS = [
  {
    tabName: 'Active',
    path: '/experiments/active',
    Content: ExperimentsActive,
  },
  {
    tabName: 'Archived',
    path: '/experiments/archived',
    Content: ExperimentsArchived,
  },
];

export const EXPERIMENT_TYPES_OPTIONS = [
  { key: '1', text: 'Any type', value: '' },
  { key: '2', text: 'Teaser', value: 'TEASER' },
  { key: '3', text: 'Design', value: 'DESIGN' },
  { key: '4', text: 'Flow', value: 'FLOW' },
  { key: '5', text: 'Language', value: 'LANGUAGE' },
  { key: '6', text: 'Sequence', value: 'SEQUENCE' },
  { key: '7', text: 'Messaging', value: 'MESSAGING' },
];
export const ORDERING_VALUES = [
  { key: '1', text: 'Newest first', value: '-created_on' },
  { key: '2', text: 'Oldest first', value: 'created_on' },
  { key: '3', text: 'Last updated', value: '-updated_on' },
  { key: '4', text: 'Last created', value: 'updated_on' },
];

export const EXPERIMENT_TYPES = {
  TEASER: {
    text: 'Teaser',
    value: 'teaser',
    iconName: 'horizontal_split',
    color: 'white',
    badgeColor: '#2791ff',
  },
  DESIGN: {
    text: 'Design',
    value: 'design',
    iconName: 'auto_awesome',
    color: '#89919d',
    badgeColor: 'white',
  },
  FLOW: {
    text: 'Flow',
    value: 'flow',
    iconName: 'account_tree',
    color: 'white',
    badgeColor: '#5f5fff',
  },
  LANGUAGE: {
    text: 'Lang.',
    value: 'language',
    iconName: 'edit_note',
    color: '#89919d',
    badgeColor: 'white',
  },
  SEQUENCE: {
    text: 'Seq.',
    value: 'sequence',
    iconName: 'flip_to_front',
    color: '#89919d',
    badgeColor: 'white',
  },
  MESSAGING: {
    text: 'Messaging',
    value: 'messaging',
    iconName: 'campaign',
    color: 'white',
    badgeColor: '#ff5b98',
  },
};
