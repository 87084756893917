import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { AuthContextProvider } from './context';
import { LoginEmail } from './LoginEmail';
import AuthRoutes from './AuthRoutes';

export const Login = () => {
  const isLoginRoot = useRouteMatch('/login').isExact;

  return (
    <AuthContextProvider>
      {isLoginRoot && <LoginEmail />}
      <AuthRoutes />
    </AuthContextProvider>
  );
};
