import humps from 'humps';

import { API } from '../api';

export const attemptReassignCommunityProperty = async (propertyId) => {
  const response = await API.get(`/api/chat/properties/${propertyId}/reassign-profile`);
  return humps.camelizeKeys(response.data);
};

export const reassignCommunityProperty = async (propertyId, data) => {
  const response = await API.put(`/api/chat/properties/${propertyId}/reassign-profile`, data);
  return response.data;
};

export const getChatProperties = async (params) => {
  const response = await API.get(`/api/chat/properties`, { params });
  return response.data;
};

export const createChatProperty = async (data) => {
  const response = await API.post(`/api/chat/properties`, data);
  return response.data;
};

export const deleteChatProperty = async (propertyId) => {
  const response = await API.delete(`/api/chat/properties/${propertyId}`);
  return response.data;
};
