import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

const DeleteSubjectOverridesModal = ({ open, loading, onClose, onConfirm }) => (
  <Modal open={open} onClose={onClose}>
    <Modal.Header>Delete All Overrides?</Modal.Header>
    <Modal.Content>
      <p>
        All customization for this client will be <strong>deleted</strong>. They will use <strong>default</strong>{' '}
        system email subjects, and will be removed from this list.
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={onConfirm} disabled={loading} loading={loading}>
        Delete
      </Button>
      <Button onClick={onClose} disabled={loading}>
        Cancel
      </Button>
    </Modal.Actions>
  </Modal>
);

export default DeleteSubjectOverridesModal;
