import React from 'react';
import { Tab } from 'semantic-ui-react';

import { useAnalytics } from '../context/AnalyticsContext';
import DevicesBreakdown from './DevicesBreakdown';
import HourlyBreakdown from './HourlyBreakdown';
import MoveIns from './MoveIns';
import TrafficSources from './TrafficSources';

const InsightsTab = () => {
  const [{ filters, publicId, report }] = useAnalytics();

  return (
    <Tab.Pane>
      <TrafficSources filters={filters} publicId={publicId} />
      <HourlyBreakdown filters={filters} publicId={publicId} />
      <DevicesBreakdown filters={filters} publicId={publicId} />
      <MoveIns filters={filters} publicId={publicId} groups={report?.groups} />
    </Tab.Pane>
  );
};

export default InsightsTab;
