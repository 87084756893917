import constants from './constants';

const reducer = (draft, action) => {
  switch (action.type) {
    case constants.SET_FILTERS:
      draft.filters = { ...draft.filters, ...action.payload };
      draft.report = null;
      break;
    case constants.SET_ACTIVE_TAB:
      draft.activeTab = action.payload;
      break;
    case constants.SET_REPORT: {
      draft.report = action.payload;
      draft.pageTitle = {
        title:
          action.payload && !!action.payload.name
            ? `Chat Analytics Report: ${action.payload.name}`
            : 'Chat Analytics Report',
      };
      break;
    }
    case constants.SET_TRAFFIC_SOURCES:
      draft.trafficSources = action.payload;
      break;
    case constants.SET_CHANNELS:
      draft.channels = action.payload;
      break;
    case constants.SET_CARE_TYPES:
      draft.careTypes = action.payload;
      break;
    default:
      console.warn(`Unhandled action ${JSON.stringify(action)}`);
      break;
  }
  return draft;
};

export default reducer;
