import React, { useContext } from 'react';

import { Button, Table } from 'components/lib';
import { updateCommunityCareType } from 'apis';
import { STATUSES } from 'constants';
import { CareTypesContext } from './CareTypesContext';

export const EditRankCell = ({ careType }) => {
  const { communityCareTypes, setStatus, communityId, fetchCommunityCareTypes } = useContext(CareTypesContext);

  const updateRank = async (targetRank) => {
    const targetCareType = communityCareTypes.find((c) => c.rank === targetRank);

    setStatus(STATUSES.LOADING);

    try {
      await Promise.all([
        updateCommunityCareType(communityId, careType.id, { rank: targetRank }),
        updateCommunityCareType(communityId, targetCareType.id, { rank: careType.rank }),
      ]);

      await fetchCommunityCareTypes();
      setStatus(STATUSES.LOADED);
    } catch (e) {
      setStatus(STATUSES.FAILURE);
    }
  };

  const increaseRank = async () => {
    await updateRank(careType.rank - 1);
  };

  const decreaseRank = async () => {
    await updateRank(careType.rank + 1);
  };

  return (
    <Table.Cell width={2}>
      <Button.Group>
        <Button
          type="button"
          icon="chevron up"
          size="small"
          className="link"
          disabled={careType.rank === 0}
          onClick={increaseRank}
        />
        <Button
          type="button"
          icon="chevron down"
          size="small"
          className="link"
          disabled={careType.rank === communityCareTypes.length - 1}
          onClick={decreaseRank}
        />
      </Button.Group>
    </Table.Cell>
  );
};
