import React, { createContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { getLoggedInUser } from 'api';
import { setMarketLanguage } from 'utils/i18n';
import { STATUSES, USER_TYPES } from '../constants';
import { useBeamer } from '../utils/hooks/useBeamer';

export const UserContext = createContext();

export { USER_TYPES } from '../constants';

export const UserContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(STATUSES.IDLE);

  useBeamer(user);

  const fetchUser = async () => {
    try {
      setStatus(STATUSES.LOADING);
      const user = await getLoggedInUser();
      setUser(user);
      Sentry.setUser({
        username: user?.email || 'NO_USER',
      });
      // Identify the user with Segment
      // eslint-disable-next-line no-unused-expressions
      window?.analytics?.identify(user.id, user);
      // Set the market language for the user
      setMarketLanguage(user.market_type);

      setStatus(STATUSES.LOADED);
    } catch (e) {
      setUser(null);
      setStatus(STATUSES.FAILURE);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const isUserType = (userType) =>
    Array.isArray(userType) ? userType.some((t) => t === user.type) : user.type === userType;

  const getSessionOriginalUser = () => {
    if (!user) {
      return null;
    }

    return user.impersonated_session || user;
  };

  const hasPermission = (perm) => {
    if (!user) {
      return false;
    }

    return user.permissions.includes(perm);
  };

  const isImpersonatedSession = user && !!user.impersonated_session;

  const supportingRegularUser = isImpersonatedSession && isUserType(USER_TYPES.REGULAR);

  const provide = {
    user,
    setUser,
    fetchUser,
    status,
    isUserType,
    getSessionOriginalUser,
    isImpersonatedSession,
    hasPermission,
    supportingRegularUser,
  };

  return <UserContext.Provider value={provide} {...props} />;
};

export function useUserContext() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
}
