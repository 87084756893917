import React from 'react';
import { PieChart } from './PieChart';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const metrics = ['answers.reviewed_accepted', 'answers.reviewed_declined'];

export const AcceptedDeclinedPieChart = () => {
  const { reviewFilters } = useSystemStatsFilters();
  return (
    <PieChart
      units="Reviewed Answers"
      metrics={metrics}
      filters={reviewFilters}
      timeField="review__reviewed_on"
      getCircleData={(data) =>
        data
          ? {
              Accepted: data['answers.reviewed_accepted']?.value,
              Declined: data['answers.reviewed_declined']?.value,
            }
          : {}
      }
    />
  );
};
