import React, { useContext } from 'react';
import { Icon } from 'semantic-ui-react';

import { ConversationsContext } from './ConversationsContext';
import { Pagination } from '../lib';

export const ConversationsTablePagination = () => {
  const { filters, totalPages, handlePageChange } = useContext(ConversationsContext);
  const isFirstPage = filters.page === 1;
  const isLastPage = filters.page === totalPages;

  return (
    <Pagination
      activePage={filters.page}
      onPageChange={(e, { activePage }) => handlePageChange(activePage)}
      totalPages={totalPages}
      ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
      firstItem={{ content: <Icon name="angle double left" />, icon: true, disabled: isFirstPage }}
      lastItem={{ content: <Icon name="angle double right" />, icon: true, disabled: isLastPage }}
      prevItem={{ content: <Icon name="angle left" />, icon: true, disabled: isFirstPage }}
      nextItem={{ content: <Icon name="angle right" />, icon: true, disabled: isLastPage }}
    />
  );
};
