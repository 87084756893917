import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Input } from 'components/lib';
import { If } from 'components/If';
import { isArray, isObject } from 'utils/helpers';
import classNames from 'classnames';

const Wrapper = styled.div`
  .ui.dropdown {
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
    border: solid 1px var(--line-standard);
    border-radius: 0px;
    background-color: white;
    min-width: 20rem;
    font-weight: 500;
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--text-color);
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

    &.active {
      background-color: #f0f0f0;
    }

    .divider.text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .menu {
      max-height: 70vh;
      z-index: 10000; // prevents Dimer/Loader from obscuring the dropdown menu
      box-shadow: 0 8px 17px -4px rgba(0, 0, 0, 0.2);

      .item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
      }
    }
  }

  &&&& .ui.input {
    .close.icon {
      left: initial;
      right: 0px;
      pointer-events: auto;
    }
  }
`;

const ButtonsWrapper = styled.div`
  padding: 0.7rem 0.786rem 0 0.786rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .ui.buttons.select-buttons {
    margin-left: unset;
    .button {
      margin: 0;
      padding-left: 0;
    }
  }
`;

const ContentSelectorWrapper = styled.div`
  display: flex;
  padding: 20px;
`;

const TypeContainer = styled.div`
  height: 26px;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 13px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 1.5;
  background-color: var(--white);
  color: var(--text);
  text-transform: capitalize;
  &.active {
    background-color: var(--vivid-blue);
    color: var(--white);
  }
`;

export const MultiSelectFilter = ({
  name,
  placeholder,
  options,
  selectedValues,
  disabled,
  onChange,
  className,
  fullWidth,
  inputPlaceholder = '',
  closeOnApply,
  open = false,
  selectedOptionTypeDefault,
  hideEmptyOptionTypes,
}) => {
  const { t, i18n } = useTranslation();
  const searchInputRef = useRef();
  const [isOpen, setIsOpen] = useState(open);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [label, setLabel] = useState(placeholder);
  const [search, setSearch] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [optionTypes, setOptionTypes] = useState([]);
  const [selectedOptionType, setSelectedOptionType] = useState(selectedOptionTypeDefault);
  const [internalOptions, setInternalOptions] = useState([]);
  const _name = name || selectedOptionType || '';
  const matchesSearch = useCallback((item) => item.text?.toLowerCase().includes(search.toLowerCase()), [search]);

  const filteredUnselectedItems = useMemo(
    () => allItems.filter((item) => matchesSearch(item) && !selectedItems.some((i) => i.value === item.value)),
    [allItems, matchesSearch, selectedItems]
  );

  useEffect(() => {
    if (isObject(options)) {
      let keys = Object.keys(options);
      if (hideEmptyOptionTypes) {
        keys = keys.filter((k) => options[k]?.length > 0);
        if (!keys.length) {
          keys = [keys[0]];
        }
      }
      let selectedOptionType = selectedOptionTypeDefault;
      if (!selectedOptionType || keys.indexOf(selectedOptionType) < 0) {
        selectedOptionType = keys[0];
      }
      setOptionTypes(keys);
      setSelectedOptionType(selectedOptionType);
      setInternalOptions(options[selectedOptionType]);
    }

    if (isArray(options)) {
      setInternalOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (selectedOptionType) {
      setInternalOptions(options[selectedOptionType]);
    }
  }, [selectedOptionType]);

  useEffect(() => {
    if (internalOptions) {
      let items;

      if (internalOptions.some((o) => typeof o === 'string')) {
        // Assumes all internalOptions are strings
        items = internalOptions?.map((o, i) => ({
          key: i,
          text: o,
          value: o,
        }));
      } else {
        // Assumes all internalOptions are {id, name} objects
        items = internalOptions?.map((o) => ({
          ...o,
          key: o.id,
          text: o.name,
          value: o.id,
        }));
      }

      // always sort the items
      items.sort((a, b) => a.text?.trim().localeCompare(b.text?.trim()));

      setAllItems(items);
    }
  }, [internalOptions]);

  useEffect(() => {
    if (isArray(selectedValues)) {
      setSelectedItems(allItems.filter((item) => selectedValues.includes(item.value)));
    } else if (selectedValues == null) {
      setSelectedItems([]);
      setLabel(placeholder);
      setIsDirty(false);
    }
  }, [selectedValues, allItems, _name]);

  useEffect(() => {
    if (isArray(selectedValues)) {
      if (internalOptions?.length > 0) {
        setLabel(`${selectedValues?.length} out of ${internalOptions.length} ${_name} Selected`);
      } else {
        setLabel(`${selectedValues?.length} ${_name} Selected`);
      }
    } else if (selectedValues == null) {
      setLabel(placeholder);
    }
  }, [selectedValues]);

  const selectItem = (item) => {
    setSelectedItems([...selectedItems, item]);
    setIsDirty(true);
  };

  const unselectItem = (item) => {
    setSelectedItems(selectedItems.filter((i) => i !== item));
    setIsDirty(true);
  };

  const selectAll = () => {
    setSearch('');
    setSelectedItems(allItems);
    setIsDirty(true);
  };

  const selectNone = () => {
    setSearch('');
    setSelectedItems([]);
    setIsDirty(true);
  };

  const resetSelection = () => {
    setSearch('');
    setSelectedItems([]);
    setLabel(placeholder);
    if (typeof onChange === 'function') {
      onChange(null, selectedOptionType);
      setIsDirty(false);
    }
  };

  const applyChange = () => {
    if (isDirty && typeof onChange === 'function') {
      onChange(
        selectedItems.map((i) => i.value),
        selectedOptionType
      );
      setLabel(`${selectedItems.length} out of ${allItems.length} ${_name} Selected`);
      setIsDirty(false);
    }
    if (closeOnApply) {
      setIsOpen(false);
    }
  };

  const focusSearchInput = () => {
    // The timeout is a workaround for some focus-related weirdness built into the Dropdown component
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 100);
  };

  const selectFirstSearchResult = () => {
    if (search) {
      const first = allItems
        .filter((item) => matchesSearch(item) && !selectedItems.some((i) => i.value === item.value))
        .shift();

      if (first) {
        selectItem(first);
        setSearch('');
      }
    }
  };

  const onDropdownBlur = (e) => {
    // Auto-focusing on the search input field causes the dropdown to blur - but
    // that shouldn't close the dropdown.
    if (e.relatedTarget !== searchInputRef.current) {
      applyChange();
      setIsOpen(false);
    }
  };

  const onDropdownKeyUp = (e) => {
    if (['Enter', 'Escape'].includes(e.key) && search === '') {
      applyChange();
      setIsOpen(false);
    }
  };

  const onSearchInputKeyDown = (e) => {
    // Prevents the Dropdown from absorbing the typed space characters
    if (e.key === ' ') {
      e.stopPropagation();
    }
  };

  const onSearchInputKeyUp = (e) => {
    if (e.key === 'Escape') {
      setSearch('');
    }

    if (e.key === 'Enter') {
      if (search) {
        selectFirstSearchResult();
      }
    }
  };

  return (
    <Wrapper className={className} fullWidth={fullWidth}>
      <Dropdown
        className={className}
        scrolling
        pointing
        button
        text={label}
        open={isOpen}
        onOpen={focusSearchInput}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onBlur={onDropdownBlur}
        onKeyUp={onDropdownKeyUp}
        disabled={disabled}
      >
        <Dropdown.Menu
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <If condition={optionTypes.length > 1}>
            <ContentSelectorWrapper>
              {optionTypes.map((type) => (
                <TypeContainer
                  key={type}
                  className={classNames({ active: selectedOptionType === type })}
                  onClick={() => setSelectedOptionType(type)}
                >
                  {i18n.exists(type) ? t(type) : type}
                </TypeContainer>
              ))}
            </ContentSelectorWrapper>
          </If>
          <ButtonsWrapper>
            <Button.Group compact className="select-buttons" size="small">
              <Button className="light" onClick={selectAll}>
                Select All
              </Button>
              <Button className="light" onClick={selectNone}>
                Select None
              </Button>
              <Button className="light" onClick={resetSelection}>
                Reset
              </Button>
            </Button.Group>
            <Button color="blue" onClick={applyChange}>
              Apply
            </Button>
          </ButtonsWrapper>

          <Input
            icon
            iconPosition="left"
            name="search"
            value={search}
            placeholder={inputPlaceholder}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          >
            <Icon name="search" />
            <input
              ref={searchInputRef}
              onKeyDown={onSearchInputKeyDown}
              onKeyUp={onSearchInputKeyUp}
              autoComplete="off"
            />
            <Icon name="close" className="close" onClick={() => setSearch('')} />
          </Input>

          {selectedItems.length > 0 && (
            <>
              <Dropdown.Divider />
              <Dropdown.Header content="SELECTED" />

              {selectedItems.map((o, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() => {
                    unselectItem(o);
                  }}
                >
                  <Icon name="check" color="var(--emerald-green)" />
                  {o.Icon && <o.Icon />}
                  {o.text}
                </Dropdown.Item>
              ))}
            </>
          )}

          {label !== placeholder && (
            <>
              <Dropdown.Divider />
              <Dropdown.Header content="NOT SELECTED" />
            </>
          )}
          {filteredUnselectedItems.map((o, i) => (
            <Dropdown.Item
              key={i}
              onClick={() => {
                selectItem(o);
              }}
            >
              {o.Icon && <o.Icon />}
              {o.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};
