import React, { useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { getCampaignTemplates } from 'apis';
import { CAMPAIGN_TYPE, CAMPAIGN_TYPE_NAME } from 'components/nurture/constants';
import { Dimmer, Loader, Form, Icon } from 'components/lib';
import { If } from 'components/If';
import { StepTitle, StepSubtitle, WizardActions, WizardContent } from '../TemplateWizard';
import { useCampaignsTemplate } from '../CampaignsTemplateContext';
import { PAGINATION } from '../../../../constants';
import { CAMPAIGN_BUILD_TYPES } from '../constants';

const Wrapper = styled.div``;

const TemplatesContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
  flex-wrap: wrap;
`;

const TemplatesWrapper = styled.div`
  min-height: 411px;
  max-height: 411px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const TemplateBoxTitle = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
`;

const TemplateBoxStats = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
`;

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 64px;
  flex-shrink: 0;
`;

const TemplateBox = styled.div`
  width: 431px;
  height: 84px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #fff;
  margin-right: 20px;
  margin-bottom: 10px;
  flex-shrink: 0;
  cursor: pointer;
  padding: 12px 20px;
  display: flex;

  ${TemplateBoxTitle} {
    color: var(--text);
  }
  ${TemplateBoxStats} {
    color: #89919d;
  }
  ${IconWrapper} {
    span {
      color: var(--line-standard);
    }
  }
  &.selected- {
    &sales {
      box-shadow: 0 2px 4px 0 rgba(0, 122, 255, 0.2);
      border: solid 1px #73b8ff;
      background-image: linear-gradient(to bottom, #f7fbff, #ebf5ff);

      ${TemplateBoxTitle} {
        color: #007aff;
      }
      ${TemplateBoxStats} {
        color: #499ffc;
      }
      ${IconWrapper} {
        span {
          color: #007aff;
        }
      }
    }
    &follow-up {
      box-shadow: 0 2px 4px 0 rgba(255, 91, 152, 0.2);
      border: solid 1px #ff96bd;
      background-image: linear-gradient(to bottom, #fffafc, #ffe9f1);

      ${TemplateBoxTitle} {
        color: #ff5b98;
      }
      ${TemplateBoxStats} {
        color: #fe77a9;
      }
      ${IconWrapper} {
        span {
          color: #ff5b98;
        }
      }
    }
  }
`;

const TemplateBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const Templates = () => {
  const { state, dispatch, actions } = useCampaignsTemplate();
  const { templates, templatesLoading: loading, wizardValues } = state;
  const { t } = useTranslation();

  const initialFormValues = {
    campaignTemplates: wizardValues?.campaignTemplates || [],
  };

  const validationSchema = Yup.object({
    campaignTemplates: Yup.array().min(1, ''),
  });

  const fetchTemplates = async () => {
    const filters = {
      pageSize: PAGINATION.MAX_PAGE_SIZE,
    };
    try {
      dispatch({ type: actions.SET_TEMPLATES_LOADING, data: true });
      const { results } = await getCampaignTemplates(filters);
      dispatch({
        type: actions.SET_TEMPLATES,
        data: results.sort((a, b) => a.priority - b.priority),
      });
      dispatch({ type: actions.SET_TEMPLATES_LOADING, data: false });
    } catch (e) {
      console.error(e);
      toast.error('Error fetching templates');
      dispatch({ type: actions.SET_TEMPLATES_LOADING, data: false });
    }
  };

  useEffect(() => {
    if (templates && templates.length > 0) {
      return;
    }
    fetchTemplates();
  }, []);

  const setSelectedTemplates = (template, values, setFieldValue) => {
    const campaignTemplates = values.campaignTemplates || [];
    const isSelected = campaignTemplates.includes(template.id);
    if (isSelected) {
      setFieldValue(
        'campaignTemplates',
        campaignTemplates.filter((id) => id !== template.id)
      );
    } else {
      setFieldValue('campaignTemplates', [...campaignTemplates, template.id]);
    }
  };

  return (
    <Dimmer.Dimmable as={Wrapper} dimmed={loading}>
      <Dimmer active={loading} inverted>
        <Loader active inverted />
      </Dimmer>
      <Formik initialValues={initialFormValues} validationSchema={validationSchema} enableReinitialize>
        {({ setFieldValue, values }) => (
          <Form>
            <WizardContent>
              <StepTitle>What would you like to do?</StepTitle>
              <StepSubtitle>
                Select any of the campaign template below. You can select multiple templates to build multiple campaigns
                at once.
              </StepSubtitle>
              <TemplatesWrapper>
                <TemplatesContainer>
                  {templates.map((template) => {
                    const isSelected = values.campaignTemplates.includes(template.id);
                    const className =
                      template.campaignType === CAMPAIGN_TYPE.SALES ? 'selected-sales' : 'selected-follow-up';
                    return (
                      <TemplateBox
                        key={template.id}
                        className={isSelected && className}
                        onClick={() => setSelectedTemplates(template, values, setFieldValue)}
                      >
                        <IconWrapper>
                          <Icon name={template.icon} size="24" />
                        </IconWrapper>
                        <TemplateBoxContent>
                          <TemplateBoxTitle>{template.name}</TemplateBoxTitle>

                          <TemplateBoxStats>{CAMPAIGN_TYPE_NAME[template.campaignType]}</TemplateBoxStats>
                        </TemplateBoxContent>
                      </TemplateBox>
                    );
                  })}
                </TemplatesContainer>
              </TemplatesWrapper>
            </WizardContent>
            <WizardActions
              disabled={values.campaignTemplates.length === 0}
              values={values}
              type={CAMPAIGN_BUILD_TYPES.TEMPLATE}
            />
          </Form>
        )}
      </Formik>
    </Dimmer.Dimmable>
  );
};
