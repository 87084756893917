import React from 'react';
import styled from 'styled-components';

import { Modal as ComponentsModal, Dimmer, Loader } from 'components/lib';

const Modal = styled(ComponentsModal)`
  && {
    & > .header {
      border-bottom: none;
    }
    &.tiny,
    &.large {
      width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
      height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
    }
  }
`;

const Content = styled.div`
  padding: 0 30px;
`;

export const SettingsModal = ({ children, open, onModalClose, loading, size, width, height }) => {
  const findChildByType = (type) => {
    return React.Children.toArray(children).find((child) => {
      if (React.isValidElement(child) && child.props.type === type) {
        return child;
      }
      return null;
    });
  };

  const header = findChildByType('header');
  const body = findChildByType('body');
  const actions = findChildByType('actions');

  return (
    <Modal open={open} closeIcon onClose={onModalClose} size={size} width={width} height={height}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Content>
          <Dimmer active={loading} inverted>
            <Loader active size="large" transparent />
          </Dimmer>
          {body}
        </Content>
      </Modal.Content>
      <Modal.Actions>{actions}</Modal.Actions>
    </Modal>
  );
};
