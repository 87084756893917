import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { getCampaignsConversations, getCampaignsConversationsMetrics } from 'apis';

import useDownloadAsCSV from 'utils/hooks/useDownloadAsCSV';
import { useUrlSearchParams } from 'utils/hooks';
import { parseErrorResponse } from 'apis/utils';
import { PageHeader, PageSection } from 'components/common';
import { capitalize } from 'utils/helpers';
import { PAGINATION, STATUSES } from '../../../constants';
import { useNurtureFilter } from '../NurtureFilterContext';
import CampaignConversationList from './CampaignConversationList';
import { Metrics } from './Metrics';

export const Conversations = () => {
  const { t } = useTranslation();
  const [initialized, setInitialized] = useState(false);
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams();
  const { state, buildQueryParams } = useNurtureFilter();
  const { filters: globalFilters } = state;
  const [filters, setFilters] = useState(urlSearchParams);
  const [page, setPage] = useState(urlSearchParams.page || PAGINATION.PAGE);
  const [search, setSearch] = useState();
  const [items, setItems] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [metrics, setMetrics] = useState({});
  const { exportAsCSV } = useDownloadAsCSV();

  useEffect(() => {
    setFilters((draft) => ({
      pageSize: PAGINATION.PAGE_SIZE,
      repliedByLead: true,
      repliedBySales: false,
      ...draft,
      ...globalFilters,
    }));
    setPage(PAGINATION.PAGE);
    setInitialized(true);
  }, [globalFilters]);

  const fetchConversations = useCallback(async (q) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const combinedFilters = {
        ...q,
        repliedByLead: q.repliedByLead || undefined,
        repliedBySales: q.repliedBySales || undefined,
      };
      const params = buildQueryParams(combinedFilters);
      setUrlSearchParams(combinedFilters);
      const response = await getCampaignsConversations(params);
      setItems(response.results);
      setTotalPages(Math.ceil(response.count / PAGINATION.PAGE_SIZE));
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to fetch campaign conversations'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  }, []);

  const fetchMetrics = useCallback(async (q) => {
    try {
      const results = await getCampaignsConversationsMetrics(buildQueryParams(q));
      setMetrics(results);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to fetch metrics'));
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      fetchConversations({ page, ...filters, search });
    }
  }, [filters, page, search, fetchConversations]);

  useEffect(() => {
    if (initialized) {
      fetchMetrics({ ...filters, search });
    }
  }, [filters, search, fetchConversations]);

  const handleSearch = (value) => {
    setPage(PAGINATION.PAGE);
    setSearch(value);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const onToggleRepliedByLead = (e, { checked }) => {
    setFilters({ ...filters, repliedByLead: checked });
  };

  const onToggleRepliedBySales = (e, { checked }) => {
    setFilters({ ...filters, repliedBySales: checked });
  };

  const handleExportAsCSV = useCallback(async () => {
    await exportAsCSV({
      exportAction: () => getCampaignsConversations({ ...buildQueryParams({ ...filters, search }), export: true }),
      filename: 'conversation_statistics',
    });
  }, [filters, search, exportAsCSV]);

  if (!initialized) {
    return null;
  }

  return (
    <>
      <PageHeader
        as="h2"
        title="Conversations"
        subtitle={`See full conversations with your ${capitalize(t('leads'))}.`}
        margin={PageHeader.MARGIN.NONE}
        padding={PageHeader.PADDING.DEFAULT}
        actions={<Metrics metrics={metrics} />}
      />

      <PageSection>
        <CampaignConversationList
          conversations={items}
          loading={loaderStatus === STATUSES.LOADING}
          page={page}
          filters={filters}
          totalPages={totalPages}
          onSearch={handleSearch}
          onToggleRepliedByLead={onToggleRepliedByLead}
          onToggleRepliedBySales={onToggleRepliedBySales}
          onPageChange={handlePageChange}
          exportAsCSV={handleExportAsCSV}
        />
      </PageSection>
    </>
  );
};
