import React from 'react';
import styled from 'styled-components';

import { Divider as SemanticDivider } from 'semantic-ui-react';

const StyledDivider = styled(SemanticDivider)`
  && {
    cursor: pointer;
  }
`;

export const Divider = ({ ...props }) => <StyledDivider {...props} />;
