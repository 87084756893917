import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { useSettings } from '../context/SettingsContext';
import { SettingsModalSelectedCommunity } from './SettingsModalSelectedCommunity';

const Title = styled.div`
  font-family: var(--font-family-bold);
  font-size: 20px;
  color: var(--text);
  font-weight: 500;
  letter-spacing: normal;
  text-transform: ${({ noCapitalize }) => (noCapitalize ? 'none' : 'capitalize')};
`;

export const SettingsModalHeader = ({
  community,
  title,
  totalItemsCount,
  hideCommunites = false,
  noCapitalize = false,
}) => {
  const [{ allItemsSelected, excludedItems, selectedItems }] = useSettings();
  const count = allItemsSelected ? totalItemsCount - excludedItems.length : selectedItems.length;
  return (
    <>
      <Title noCapitalize={noCapitalize}>{title}</Title>
      <If condition={!hideCommunites}>
        <SettingsModalSelectedCommunity community={community} count={count} />
      </If>
    </>
  );
};
