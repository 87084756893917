import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { sendResetPasswordEmail } from 'apis';
import { Divider } from 'components/lib';
import { AuthLayout } from '../AuthLayout';
import { AuthContext } from '../context';
import { TALK_FURTHER } from '../../../constants';

const CheckTheEmailContainer = styled.div`
  font-size: 14px;
`;

const ContactSupport = styled.a`
  color: var(--link);
`;

export const ForgotPassword = () => {
  const history = useHistory();
  const { email } = useContext(AuthContext);

  useEffect(() => {
    if (!email) {
      history.replace('/login');
      return;
    }

    const handleSendResetPasswordEmail = async (email) => {
      try {
        await sendResetPasswordEmail({ email });
      } catch (e) {
        toast.error('Unable to send reset password email');
      }
    };

    handleSendResetPasswordEmail(email);
  }, []);

  return (
    <AuthLayout>
      <h1>Check your email</h1>
      <p>
        If this email address is associated with an account, it will receive a password reset link soon. Click on the
        link in the email and follow the instructions.
      </p>
      <Divider horizontal />
      <CheckTheEmailContainer>
        If it does not arrive in a few minutes, check the spam folder, or contact us at:&nbsp;
        <ContactSupport href={`mailto:${TALK_FURTHER.SUPPORT_EMAIL}`}>{TALK_FURTHER.SUPPORT_EMAIL}</ContactSupport>.
      </CheckTheEmailContainer>
    </AuthLayout>
  );
};
