import React from 'react';
import { Formik } from 'formik';
import { Divider, Form, Header } from 'semantic-ui-react';
import * as Yup from 'yup';

import AvailabilityStepTable from './AvailabilityStepTable';
import { useUnitLevelPricing, setUnits, setNextStep } from './context';
import UnitLevelPricingStepActions from './UnitLevelPricingStepActions';

const AvailabilityStep = () => {
  const [{ units }, dispatch] = useUnitLevelPricing();

  const initialValues = { units };

  const validationSchema = Yup.object({
    units: Yup.array().of(
      Yup.object({
        careTypes: Yup.array().of(
          Yup.object({
            minPrice: Yup.number().nullable(),
            maxPrice: Yup.number().nullable(),
            unitsAvailable: Yup.number().when('minPrice', {
              is: (v) => v > 0,
              then: Yup.number()
                .required('Availability is required.')
                .min(0, 'Availability must be grater than or equal to 0.'),
              otherwise: Yup.number().nullable(),
            }),
          })
        ),
      })
    ),
  });

  const handleSubmit = ({ units }) => {
    dispatch(setUnits(units));
    dispatch(setNextStep());
  };

  return (
    <>
      <Header as="h3">
        <Header.Content>
          How many units do you have?
          <Header.Subheader>Define number of available units per care type</Header.Subheader>
        </Header.Content>
      </Header>
      <Divider hidden />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <Form>
            <AvailabilityStepTable />
            <UnitLevelPricingStepActions onNextStep={handleSubmit} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AvailabilityStep;
