import React, { memo } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import styled from 'styled-components';

const Wrapper = styled(Label)`
  &.ui.label {
    display: flex;
    cursor: move;
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;

export const DraggableLabel = memo(({ item, onRemove }) => (
  <Wrapper>
    {item.value}
    <Icon name="delete" onClick={onRemove} />
  </Wrapper>
));
