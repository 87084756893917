import React from 'react';
import styled from 'styled-components';

import { ServiceBadge } from '../../common';
import { UserCommunityHomeHeaderContact } from './UserCommunityHomeHeaderContact';

const CommunityHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 30px;
`;

export const UserCommunityHomeHeader = () => (
  <CommunityHeader>
    <UserCommunityHomeHeaderContact />
    <ServiceBadge type="WEB_ASSISTENT" />
    <ServiceBadge type="MESSAGING_ASSISTANT" />
  </CommunityHeader>
);
