import React from 'react';
import styled from 'styled-components';

import { UserCircle } from 'components/UserCircle';

const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 148px;
  max-width: 148px;
  overflow-wrap: anywhere;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 9px;
`;

const Name = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #222222;
`;

const Email = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #89919d;
`;

export const UserWrapper = ({ user }) => {
  return (
    <Container>
      <UserCircle user={user} size="18" />
      <NameWrapper>
        <Name>{user?.name}</Name>
        <Email>{user?.email}</Email>
      </NameWrapper>
    </Container>
  );
};
