import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Dropdown, Form, Modal, Radio } from 'components/lib';
import { InputField } from 'components/common/fields/InputField';
import { STATUSES } from '../../../constants';
import { ChatModulesContext } from './ChatModulesContext';
import { useChatEditor } from '../ChatEditorContext';
import { ChatTemplateItem } from '../ChatTemplateItem';
import { chatTmplateOrderByType } from '../CreateChat';

const ModalContent = styled(Modal.Content)`
  &&&&& {
    padding: 20px 30px;
  }
`;

const initialFormValues = {
  name: '',
  existing_name: '',
};

const validationSchema = Yup.object()
  .shape({
    name: Yup.string(),
    existing_name: Yup.string(),
  })
  .test('at-least-one', null, function (values) {
    const { name, existing_name } = values;
    if (!name && !existing_name) {
      return this.createError({
        path: 'name',
        message: 'At least one is required',
      });
    }
    return true;
  });

const RADIO_NEW = 'new';
const RADIO_EXISTING = 'existing';

export const ChatTemplateSaveModal = ({ open }) => {
  const { chatTemplates, getChatTemplates, handleSaveTemplate, saveingStatus, toggleShowSaveTemplateModal } =
    useContext(ChatModulesContext);
  const [state] = useChatEditor();
  const { chat } = state;

  const [radioValue, setRadioValue] = useState();

  const handleRadioChange = (e, { value }) => {
    setRadioValue(value);
  };

  const handleClose = () => {
    toggleShowSaveTemplateModal();
  };

  const onFormSubmit = (values) => {
    if (values.name) {
      handleSaveTemplate(chat?.vsa_type, values.name);
    } else {
      const template = chatTemplates.find((template) => template.id === values.existing_name);
      handleSaveTemplate(chat?.vsa_type, template.name, template.id);
    }
  };

  useEffect(() => {
    getChatTemplates();
  }, []);

  const isLoading = saveingStatus === STATUSES.LOADING;

  const chatTemplatesList = useMemo(() => {
    return chatTemplates
      .sort((a, b) => {
        return chatTmplateOrderByType(a.vsa_type) - chatTmplateOrderByType(b.vsa_type);
      })
      .map(({ id, name, vsa_type }) => ({
        key: id,
        value: id,
        text: name,
        content: <ChatTemplateItem text={name} type={vsa_type} />,
      }));
  }, [chatTemplates]);

  return (
    <Modal onClose={handleClose} open={open} closeIcon size="small">
      <Formik initialValues={initialFormValues} onSubmit={onFormSubmit} validationSchema={validationSchema}>
        {({ handleSubmit, isValid, resetForm }) => (
          <>
            <Modal.Header>Save Chat Template</Modal.Header>
            <ModalContent>
              <Form>
                <Radio
                  onChange={handleRadioChange}
                  label="Create New Template"
                  checked={radioValue === RADIO_NEW}
                  value={RADIO_NEW}
                />
                <br />
                <InputField
                  name="name"
                  onFocus={() => setRadioValue(RADIO_NEW)}
                  disabled={radioValue === RADIO_EXISTING}
                  placeholder="Enter a name for your new template"
                />
                <Radio
                  onChange={handleRadioChange}
                  label="Overwrite Existing Template"
                  checked={radioValue === RADIO_EXISTING}
                  value={RADIO_EXISTING}
                />
                <br />
                <InputField
                  basic
                  clearable
                  fluid
                  search
                  selection
                  control={Dropdown}
                  onFocus={() => setRadioValue(RADIO_EXISTING)}
                  disabled={radioValue === RADIO_NEW}
                  name="existing_name"
                  placeholder="Select a template to overwrite"
                  options={chatTemplatesList}
                />
              </Form>
            </ModalContent>
            <Modal.Actions>
              <Button onClick={() => handleClose(resetForm)} primary disabled={isLoading}>
                Cancel
              </Button>
              <Button
                content="Save"
                primary
                type="submit"
                color="blue"
                disabled={!isValid || isLoading}
                loading={isLoading}
                onClick={handleSubmit}
              />
            </Modal.Actions>
          </>
        )}
      </Formik>
    </Modal>
  );
};
