import React from 'react';
import styled from 'styled-components';

import { Button as SemanticButton, ButtonGroup as SemanticButtonGroup } from 'semantic-ui-react';
import { MEDIA_BREAK_POINTS } from '../../constants';
import { FURHTER_ICON } from './Icon';

const StyledButton = styled(SemanticButton)`
  && {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    font-weight: normal;
    font-family: var(--font-family);
    & + & {
      margin-left: inherit;
      @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
        margin-left: 10px;
      }
    }
    &.pill {
      border-radius: 18px;
      padding: 6px 27px 6px 26px;
    }

    &.green {
      border: solid 1px #10a36c;
      background-color: var(--emerald-green);
      &:hover {
        background-color: var(--emerald-green);
      }
    }

    &.white {
      background-color: var(--white);
    }

    &.circular {
      justify-content: center;
      padding: 0;
      min-width: 40px;
      min-height: 40px;
    }

    && > .${FURHTER_ICON} {
      &:last-child:not(:only-child) {
        margin-left: 10px;
      }
      &:first-child:not(:only-child) {
        margin-right: 10px;
      }
    }

    &.link {
      background-color: transparent;
      border: none !important;
      box-shadow: none !important;
      margin: 0;
      padding: 0;

      &:hover,
      &:active {
        background-color: transparent;
        text-decoration: underline;
      }

      &.orange {
        color: var(--salmon) !important;
      }

      &.green {
        color: var(--emerald-green) !important;
      }

      &.blue {
        color: var(--vivid-blue) !important;
      }
      &.red {
        color: var(--danger) !important;
      }
    }

    &.primary {
      box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
      border: solid 1px var(--line-standard);
      background-color: var(--white);
      font-family: var(--font-family-bold);
      line-height: 1.43;
      color: var(--text-color);
      min-width: 104px;

      &:hover,
      &:focus,
      &:active {
        color: var(--text-color);
        background-color: var(--hover-white);
        border: solid 1px var(--line-standard);
      }
      &.big {
        font-size: 16px;
        height: 54px;
      }
      &.small {
        height: 40px;
      }

      &.orange,
      &.green,
      &.red,
      &.blue {
        color: var(--white);
      }

      &.orange {
        background-color: var(--salmon);
        border-color: var(--salmon);
      }

      &.green {
        background-color: var(--emerald-green);
        border-color: var(--emerald-green);
      }

      &.blue {
        background-color: var(--vivid-blue);
        border-color: var(--vivid-blue);
        &:hover {
          background-color: var(--hover-blue);
        }
      }

      &.red {
        background-color: var(--danger);
        border-color: var(--danger);
      }
    }

    &.secondary {
      min-width: 104px;
      border-radius: 18px;
      box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
      font-family: var(--font-family);
      line-height: 1.14;
      color: var(--text-color);

      &.orange {
        color: var(--text-color);
        border: solid 1px var(--salmon);
        background-color: #fff1ee;
        span[class*='material-icons'] {
          color: var(--salmon);
        }
      }
    }

    &.basic {
      font-family: var(--font-family);
      border: solid 1px var(--line-standard);
      box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
      line-height: 1.43;
      color: var(--text-color) !important;
      min-width: 104px;

      &.compact {
        min-width: auto;
      }

      &:hover,
      &:focus,
      &:active {
        border: solid 1px var(--line-standard);
        box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
        opacity: 0.8;
      }
    }

    &.raised {
      box-shadow: 0 2px 4px 0 rgb(19 33 90 / 15%);
      border: solid 1px var(--line-standard);
      background-color: var(--white);
    }

    &:hover {
      border-color: var(--light-text);
    }

    &.disabled {
      opacity: 0.2 !important;
    }

    &.inverted.button {
      border: solid 1px var(--line-standard);
      box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15) !important;

      &:hover,
      &:focus,
      &:active {
        opacity: 1;
        border: solid 1px #fff;
        box-shadow: none !important;
      }
    }
    &&&&.loading {
      pointer-events: none;
    }

    &.light {
      background-color: var(--white);
      border: none;
      opacity: 0.4;
      font-family: var(--font-family);
      font-size: 12px;
      color: var(--link);
      &:hover,
      &:focus,
      &:active {
        opacity: 1;
      }
    }
  }
`;

const StyledButtonGroup = styled(SemanticButtonGroup)`
  &&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;

    &.vertical {
      flex-direction: column;

      .button {
        margin: 0;
        border: solid 1px var(--line-standard);
        margin-top: -1px;

        &:hover,
        &:focus,
        &:active {
          border-color: var(--light-text);
          z-index: 1;
        }
      }
    }
  }
`;

export const Button = ({ ...props }) => <StyledButton {...props} />;

Button.propTypes = { ...StyledButton.propTypes };

Button.Group = ({ ...props }) => <StyledButtonGroup {...props} />;
