import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatCount, formatPercent } from 'components/utils';
import { capitalize } from 'utils/helpers';
import { Statistic } from '../../common/Statistic';

export const Metrics = ({ metrics }) => {
  const { t } = useTranslation();
  const { conversationsCount, repliedByLeadCount, repliedBySalesCount } = metrics;

  return (
    <Statistic.Group name="Response Metrics">
      <Statistic label="Conversations Count" value={formatCount(conversationsCount)} />
      <Statistic
        label={`Replied by ${capitalize(t('lead'))}`}
        value={formatCount(repliedByLeadCount)}
        secondaryValue={formatPercent(repliedByLeadCount, conversationsCount)}
      />
      <Statistic
        label="Replied by Sales"
        value={formatCount(repliedBySalesCount)}
        secondaryValue={formatPercent(repliedBySalesCount, conversationsCount)}
      />
    </Statistic.Group>
  );
};
