import React, { useMemo } from 'react';
import { Tab } from 'semantic-ui-react';

import { useAnalytics } from '../context/AnalyticsContext';
import { ChatFlows } from './ChatFlows';

const ChatFlowsTab = () => {
  const [{ filters, report, publicId }] = useAnalytics();
  const filteredChats = useMemo(() => {
    const communityNames = report.communities.reduce((acc, community) => {
      acc[community.id] = community.name;
      return acc;
    }, {});

    if (!report.chats?.length || (!filters.communities?.length && !filters.groups?.length)) {
      return report.chats
        .map((chat) => ({
          ...chat,
          communityNames: chat.communities.map((id) => communityNames[id]),
        }))
        .filter((chat) => chat.communities.length > 0);
    }

    const communityIds = filters.groups?.length
      ? report.groups
          .filter((g) => filters.groups.includes(g.id))
          .reduce((acc, group) => {
            group.community_ids?.forEach((communityId) => {
              if (!acc.includes(communityId)) {
                acc.push(communityId);
              }
            });
            return acc;
          }, [])
      : filters.communities;

    const visibleChats =
      report.chats?.map((chat) => {
        const filteredChat = { ...chat };
        filteredChat.communities = chat.communities?.filter((id) => communityIds.includes(id)) || [];
        filteredChat.communityNames = filteredChat.communities.map((id) => communityNames[id]);
        return filteredChat;
      }) || [];

    return visibleChats.filter((chat) => chat.communities.length > 0);
  }, [report.chats, report.communities, report.groups, filters.communities, filters.groups]);

  return (
    <Tab.Pane>
      <ChatFlows filters={filters} publicId={publicId} chats={filteredChats} />
    </Tab.Pane>
  );
};

export default ChatFlowsTab;
