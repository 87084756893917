import humps from 'humps';

import { API } from '../api';

export const createLead = async (data) => {
  const url = `/api/chat/leads/create`;
  const response = await API.post(url, data);
  return response.data;
};

export const getLeadUserReminder = async (leadId, reminderType) => {
  const response = await API.get(`api/chat/leads/${leadId}/user-reminders/${reminderType}`);
  return response.data;
};

export const createLeadUserReminder = async (leadId, reminderType, data) => {
  const response = await API.post(`api/chat/leads/${leadId}/user-reminders/${reminderType}`, data);
  return response.data;
};

export const updateLeadUserReminder = async (leadId, reminderType, leadUserReminderId, data) => {
  const response = await API.put(`api/chat/leads/${leadId}/user-reminders/${reminderType}/${leadUserReminderId}`, data);
  return response.data;
};

export const removeLeadUserReminder = async (leadId, reminderType, leadUserReminderId) => {
  const response = await API.put(
    `api/chat/leads/${leadId}/user-reminders/${reminderType}/${leadUserReminderId}/remove`
  );
  return response.data;
};

export const submitLeadCRM = async (leadId) => {
  const response = await API.put(`api/chat/leads/${leadId}/crm-submit`);
  return response.data;
};

export const submitLeadActionsToCRM = async (leadId) => {
  const response = await API.put(`api/chat/leads/${leadId}/crm-submit/lead-actions`);
  return response.data;
};

export const getChannelSources = async () => {
  const url = '/api/chat/leads/channel-sources';
  const response = await API.get(url);
  return response.data;
};

export const getTrafficSources = async () => {
  const url = 'api/chat/browserdata/eligible-traffic-sources';
  const response = await API.get(url);
  return response.data;
};

export const submitCallToCRM = async (leadId) => {
  const response = await API.put(`api/chat/leads/${leadId}/crm-submit/calls`);
  return response.data;
};

export const submitTourToCRM = async (leadId) => {
  const response = await API.put(`api/chat/leads/${leadId}/crm-submit/tours`);
  return response.data;
};

export const updateLeadMoveInDate = async (leadId, date) => {
  const response = await API.put(`api/leads/${leadId}/move-in-date`, { move_in_date: date });
  return response.data;
};

export const getLeadProcessing = async (leadId) => {
  const response = await API.get(`/api/chat/leads/${leadId}/processing`);
  return humps.camelizeKeys(response.data);
};

export const deleteLead = async (leadId) => {
  const response = await API.delete(`api/chat/leads/${leadId}`);
  return response.data;
};

export const leadFurtherArchive = async (leadId, data) => {
  const response = await API.put(`/api/leads/${leadId}/further-archive`, data);
  return response.data;
};

export const getLeadLocationMap = async (leadId) => {
  const response = await API.get(`/api/leads/${leadId}/geolocation-map`);
  return humps.camelizeKeys(response.data);
};
