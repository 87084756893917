import React, { createContext, useContext, useState } from 'react';
import { useImmerReducer } from 'use-immer';

import { STATUSES } from '../../constants';
import { getChat } from '../../api';

const INITIAL_STATE = {
  isPublished: undefined,
  startValidateChat: 0, // increment number to trigger modal for chat validation
  chat: undefined,
  shouldRefreshPage: false,
};

const ChatEditorContext = createContext();

export const actions = {
  SET_IS_PUBLISHED: 'SET_IS_PUBLISHED',
  SET_START_VALIDATE_CHAT: 'SET_START_VALIDATE_CHAT',
  SET_CHAT: 'SET_CHAT',
  SET_REFRESH_PAGE: 'SET_REFRESH_PAGE',
};

const reducer = (draft, action) => {
  switch (action.type) {
    case actions.SET_IS_PUBLISHED:
      draft.isPublished = action.payload;
      break;
    case actions.SET_START_VALIDATE_CHAT: {
      draft.startValidateChat = action.payload ? draft.startValidateChat + 1 : 0;
      draft.isPublished = action.payload;
      break;
    }
    case actions.SET_CHAT: {
      draft.chat = action.payload.chat;
      break;
    }
    case actions.SET_REFRESH_PAGE: {
      draft.shouldRefreshPage = action.payload;
      break;
    }
    default:
      console.warn(`Unhandled action ${JSON.stringify(action)}`);
      break;
  }

  return draft;
};

export const ChatEditorProvider = (props) => {
  const [state, dispatch] = useImmerReducer(reducer, INITIAL_STATE);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.LOADING);

  const loadChat = async (chatId) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const chat = await getChat(chatId);
      dispatch({ type: actions.SET_CHAT, payload: { chat } });
      setLoaderStatus(STATUSES.LOADED);
    } catch (error) {
      const msg = 'Could not load chat and relation models';
      console.error(msg, error?.response);
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const value = [state, dispatch, loadChat, loaderStatus];

  return <ChatEditorContext.Provider value={value}>{props.children}</ChatEditorContext.Provider>;
};

export function useChatEditor() {
  const context = useContext(ChatEditorContext);

  if (!context) {
    throw new Error('useChatEditor must be used within ChatEditorProvider');
  }

  return context;
}
