import { Dropdown } from 'components/lib';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    margin-left: 10px;
  }
`;

const TOUCH_MODE_OPTIONS = [
  { value: 'multi', text: 'Multi touch' },
  { value: 'first', text: 'First touch' },
  { value: 'significant', text: 'Lead creation touch' },
];

const TouchModeSelector = ({ value, onChange }) => {
  return (
    <Wrapper>
      Attribution:
      <Dropdown
        options={TOUCH_MODE_OPTIONS}
        value={value || 'multi'}
        onChange={onChange}
        placeholder="Touch Mode"
        selection
      />
    </Wrapper>
  );
};

export default TouchModeSelector;
