import React from 'react';
import styled from 'styled-components';

import { Icon, FURHTER_ICON } from 'components/lib';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 3px 10px;
  border-radius: 13px;
  line-height: 12px;
  background-color: #fff;
  transition: box-shadow 0.2s ease;
  .${FURHTER_ICON} {
    display: none;
  }
`;

export const NumberIndicator = ({ phoneNumbers = [], allowedSize, enabled, onClick }) => {
  return (
    <Container className="number-indicator" onClick={onClick}>
      {phoneNumbers.length}
      {allowedSize && `/${allowedSize}`}
      <Icon name="navigate_next" />
    </Container>
  );
};
