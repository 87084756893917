import React, { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { InputField } from 'components/common';
import { Form, Modal as ComponentsModal, Button, TextArea } from 'components/lib';
import { ConversationContext } from './ConversationContext';

const Modal = styled(ComponentsModal)`
  && {
    & > .header {
      border-bottom: none;
    }
    &.tiny,
    &.large {
      width: 540px;
    }
  }
`;

const HeaderTitle = styled.div`
  font-family: var(--font-family);
  font-weight: 900; // override semantic-ui inherited font-weight: 700;
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const HeaderSubtitle = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  margin-top: 11px;
`;

const Content = styled.div`
  padding: 0 30px;
  &&&& {
    .field {
      flex-grow: 1;
    }
  }
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  border-top: 1px solid var(--line-standard);
  padding: 0 30px;
  margin-top: 28px;
`;

const CustomTextArea = styled(InputField)`
  &&&& {
    textarea {
      width: 100%;
      height: 250px;
      border: solid 1px var(--line-standard);
      background-color: var(--input-field-fill);
      outline: none;
      font-family: var(--font-family);
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: normal;
      color: var(--text);
      padding: 12px;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &:focus {
        border: solid 1px var(--line-standard);
      }
    }
  }
`;

const TextAreaWrapper = styled.div`
  display: flex;
  max-height: 250px;
  label {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--text);
    margin-right: 10px;
    padding-top: 8px;
  }
`;

const validationSchema = Yup.object({
  content: Yup.string().required('Required'),
});

export const ConversationNoteModal = () => {
  const { currentNote, saveNote, isNoteModalOpen, setIsNoteModalOpen, isNoteLoading, setCurrentNote } =
    useContext(ConversationContext);
  const initialValues = {
    content: '',
    ...currentNote,
  };

  const onSubmit = async (values, { setErrors }) => {
    saveNote(values, setErrors);
  };

  const handleClose = () => {
    setCurrentNote(null);
    setIsNoteModalOpen(false);
  };

  const onCancel = (resetForm) => () => {
    resetForm();
    handleClose();
  };
  const headerTitle = currentNote ? 'Edit Note' : 'Add Note';
  const headerSubtitle = currentNote ? 'Edit the note you added earlier.' : 'Add a custom note to this conversation.';
  return (
    <Modal open={isNoteModalOpen} closeIcon size="large" onClose={handleClose}>
      <Modal.Header>
        <HeaderTitle>{headerTitle}</HeaderTitle>
        <HeaderSubtitle>
          {headerSubtitle} <br />
          Use the activity filter at the top to filter conversation by notes.
        </HeaderSubtitle>
      </Modal.Header>
      <Modal.Content>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ dirty, handleSubmit, touched, isValid, resetForm, values }) => (
            <Form onSubmit={handleSubmit}>
              <Content>
                <TextAreaWrapper>
                  <label>Note</label>
                  <CustomTextArea name="content" control={TextArea} placeholder="Note" value={values?.content} />
                </TextAreaWrapper>
              </Content>
              <Actions>
                <Button
                  primary
                  color="blue"
                  type="submit"
                  disabled={!dirty || !touched || !isValid}
                  loading={isNoteLoading}
                >
                  Save
                </Button>
                <Button primary type="button" onClick={onCancel(resetForm)}>
                  Cancel
                </Button>
              </Actions>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
