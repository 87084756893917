import { useReducer } from 'react';

import { ONBOARDING_STEPS } from './constants';

/**
 * @typedef {Object} OnboardingState
 */
const INITIAL_STATE = {
  onboarding: null,
  onboardingTemplateTag: null,
  currentStepNumber: 1,
  currentStep: null,
  steps: [],
  intents: [],
  qualifyingQuestions: [],
  collectedData: {
    name: null,
    website: null,
  },
  hasCreatedAccount: false,
  isPollingScrapingStatus: false,
};

const filterSteps = (steps, onboardingTemplateTag) => {
  if (onboardingTemplateTag && onboardingTemplateTag !== 'generic') {
    return steps.filter((s) => !['main-goal', 'intents', 'qualifying-questions'].includes(s.slug));
  }
  return steps;
};

const configureInitialState = (overrides) => {
  const initialState = { ...INITIAL_STATE };
  const { step, onboardingTemplateTag } = overrides;
  const steps = filterSteps(ONBOARDING_STEPS, onboardingTemplateTag);

  if (step) {
    let stepNumber = parseInt(step);
    if (Number.isNaN(stepNumber)) {
      stepNumber = steps.findIndex((s) => s.slug === step) + 1;
    }
    initialState.currentStepNumber = stepNumber;
  }

  if (onboardingTemplateTag) {
    initialState.onboardingTemplateTag = onboardingTemplateTag;
  }

  initialState.steps = steps;
  initialState.currentStep = initialState.steps[initialState.currentStepNumber - 1];

  return initialState;
};

// ACTIONS

const SET_ONBOARDING = 'SET_ONBOARDING';
const SET_CURRENT_STEP = 'SET_CURRENT_STEP';

export const ONBOARDING_ACTIONS = {
  SET_ONBOARDING,
  SET_CURRENT_STEP,
};

// REDUCERS

const ONBOARDING_REDUCERS = {
  [SET_ONBOARDING]: (state, { onboarding }) => {
    const steps = filterSteps(ONBOARDING_STEPS, onboarding?.collectedData?.template);

    const currentStepNumber = steps.findIndex((s) => s.slug === state.currentStep.slug) + 1;

    return {
      ...state,
      onboarding,
      steps,
      currentStepNumber,
    };
  },

  [SET_CURRENT_STEP]: (state, { stepNumber }) => {
    const currentStep = state.steps[stepNumber - 1];
    return {
      ...state,
      currentStepNumber: stepNumber,
      currentStep,
    };
  },
};

const reduce = (state, action) => {
  const { type, payload } = action;
  if (ONBOARDING_REDUCERS[type]) {
    return ONBOARDING_REDUCERS[type](state, payload);
  }
  return state;
};

export const useOnboardingReducer = (initialStateOverrides) => {
  return useReducer(reduce, initialStateOverrides, configureInitialState);
};
