import React, { useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, Confirm, Segment, Form, Grid, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { sendTestLead } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES, PHONE_REGEX } from '../../../constants';
import { InputField } from '../../common/fields/InputField';
import { useCRM } from './context';

const ModalText = styled.p`
  font-size: 1.1rem;
`;

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email('Must be a valid email address').required('Required'),
  phoneNumber: Yup.string().matches(PHONE_REGEX, 'Phone number is not valid').required('Required'),
});

const TestLeadModal = ({ open, onClose, community }) => {
  const [{ userId }] = useCRM();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [testLead, setTestLead] = useState({});
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [result, setResult] = useState(null);

  const handleSubmit = (data) => {
    setTestLead({
      community_id: community.id,
      crm_location_code: community.crm_location_code,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
    });
    setConfirmOpen(true);
  };

  const sendLead = async () => {
    setConfirmOpen(false);
    setLoaderStatus(STATUSES.LOADING);
    setResult(null);

    try {
      const { id } = community;
      const { crm_result } = await sendTestLead(userId, id, testLead);

      setResult(crm_result);
      toast.success('Test lead sent successfully');
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      toast.error('Error sending test lead');
      setResult(parseErrorResponse(e, 'Unable to parse error message. Please try again.'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const handleClose = (resetForm) => () => {
    onClose();
    setResult(null);
    resetForm();
  };

  if (!community) {
    return null;
  }

  const isSuccess = loaderStatus !== STATUSES.FAILURE;

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, resetForm }) => (
        <Modal open={open} closeOnDimmerClick={loaderStatus !== STATUSES.LOADING} onClose={handleClose(resetForm)}>
          <Modal.Header>Send Test Lead</Modal.Header>
          <Modal.Content>
            <ModalText>Please fill out the following fields and send a test lead</ModalText>
            <Form loading={loaderStatus === STATUSES.LOADING}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form.Field>
                      <label>CRM Type: {community.crm_settings_integration_name}</label>
                      <label>CRM Location Code: {community.crm_location_code}</label>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form.Field>
                      <label>First Name</label>
                      <InputField type="text" name="firstName" />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Field>
                      <label>Last Name</label>
                      <InputField type="text" name="lastName" />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form.Field>
                      <label>Email</label>
                      <InputField type="email" name="email" />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Field>
                      <label>Phone</label>
                      <InputField type="tel" name="phoneNumber" />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
            {result && (
              <Segment inverted color={isSuccess ? 'green' : 'red'}>
                {result}
              </Segment>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleClose(resetForm)} disabled={loaderStatus === STATUSES.LOADING}>
              Cancel
            </Button>
            <Button positive onClick={handleSubmit} type="submit" disabled={loaderStatus === STATUSES.LOADING}>
              Send
            </Button>
          </Modal.Actions>
          <Confirm
            cancelButton="No"
            confirmButton="Yes"
            content="This will send a test lead directly to CRM. Are you sure you want to continue?"
            open={confirmOpen}
            onCancel={() => setConfirmOpen(false)}
            onConfirm={sendLead}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default TestLeadModal;
