import React, { useContext } from 'react';

import { Table } from 'components/lib';

import { FeaturesContext } from './FeaturesContext';

const FeaturesOverviewListHeader = () => {
  const { FEATURE_TYPES } = useContext(FeaturesContext);

  return (
    <>
      {Object.values(FEATURE_TYPES).map((featureType) => (
        <Table.HeaderCell key={featureType} textAlign="center">
          {featureType}
        </Table.HeaderCell>
      ))}
    </>
  );
};

export default FeaturesOverviewListHeader;
