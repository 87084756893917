import React from 'react';
import styled from 'styled-components';

import { Logo as FurtherLogo } from 'components/Logo';

const StyledLogo = styled(FurtherLogo)`
  height: auto;
  g {
    > path:first-child {
      fill: #173b72;
    }
  }
`;

export const Logo = () => {
  return <StyledLogo width="113" />;
};
