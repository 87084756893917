import React, { useState } from 'react';
import { Button, Modal } from 'components/lib';
import styled from 'styled-components';

const ModalContent = styled(Modal.Content)`
  &&&& {
    padding: 30px;
  }
`;

const PromtTitle = styled.h3`
  font-family: var(--font-family-bold);
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const PromtDescription = styled.p`
  font-family: var(--font-family);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--light-text);
`;

export const PromptButton = ({
  title,
  description,
  actionLabel,
  onConfirm,
  confirmButtonProps,
  triggerClassName,
  ...props
}) => {
  const [confirmActivate, setConfirmActivate] = useState(false);
  if (!confirmButtonProps) {
    confirmButtonProps = {
      primary: true,
      color: 'blue',
    };
  }

  const handleOpen = () => setConfirmActivate(true);
  const handleClose = () => setConfirmActivate(false);

  const handleConfirm = () => {
    handleClose();
    onConfirm();
  };

  return (
    <>
      <Modal
        size="tiny"
        closeIcon
        open={confirmActivate}
        onClose={handleClose}
        onOpen={handleOpen}
        trigger={<Button {...props} className={triggerClassName} />}
      >
        <ModalContent>
          <PromtTitle>{title}</PromtTitle>
          <PromtDescription>{description}</PromtDescription>
        </ModalContent>
        <Modal.Actions>
          <Button {...confirmButtonProps} onClick={handleConfirm}>
            {actionLabel || 'Confirm'}
          </Button>
          <Button basic onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
