import React from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import { parseEmailMessage } from 'utils/helpers';
import { CONVERSATION_TYPE } from '../../../constants';

const MAX_MESSAGE_PREVIEW = 300;

const Dots = styled.span`
  color: #3e3e3e;
`;

const InstantResponseMessageText = ({ text, conversationType }) => {
  if (!text) {
    return null;
  }

  const parsedText = conversationType === CONVERSATION_TYPE.SMS ? text : parseEmailMessage(text);

  if (parsedText.length === 0) {
    return null;
  }

  if (conversationType === CONVERSATION_TYPE.EMAIL || parsedText.length >= MAX_MESSAGE_PREVIEW) {
    return (
      <Popup
        pinned
        content={conversationType === CONVERSATION_TYPE.SMS ? parsedText : <pre>{parsedText}</pre>}
        trigger={
          <p>
            {parsedText.slice(0, MAX_MESSAGE_PREVIEW)} {parsedText.length >= MAX_MESSAGE_PREVIEW && <Dots>...</Dots>}
          </p>
        }
      />
    );
  }

  return parsedText;
};

export default InstantResponseMessageText;
