import React, { useContext, useEffect } from 'react';

import { Redirect, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginPathWithNext } from 'utils/helpers';
import { UserContext } from '../../UserContext';
import { USER_TYPES } from '../../../constants';
import { getDefaultRouteForUser } from './common';
import { useNavigationContext } from '../../navigation';

export const UserRoute = ({ allowedTypes = [], hideNavigation = false, ...props }) => {
  const { user } = useContext(UserContext);
  const { setShowNavigation } = useNavigationContext();

  useEffect(() => {
    setShowNavigation(!hideNavigation);
  }, [hideNavigation]);

  if (!user) {
    const next = loginPathWithNext();
    return <Redirect to={next} />;
  }

  if (![USER_TYPES.STAFF, ...allowedTypes].includes(user.type)) {
    const redirectRoute = getDefaultRouteForUser(user);

    toast.warning(`Insufficient user privileges to access ${props.location.pathname} page!`);
    return <Redirect to={redirectRoute} />;
  }

  return <Route {...props} />;
};
