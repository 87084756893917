import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { getReportMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import queryConstants from 'utils/queries/constants';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import { TimeSeriesChart } from 'components/common/analytics';

const EMPTY_CALL_DATA = {
  labels: [],
  datasets: [
    {
      label: 'CALLS',
      borderColor: '#275aff',
      backgroundColor: '#275aff',
      pointBackgroundColor: '#ffffff',
      borderWidth: 1,
      minBarLength: 5,
      data: [],
    },
  ],
};

function createGradient(context, color1, color2) {
  const gradient = context.createLinearGradient(0, 0, 0, context.canvas.height);
  gradient.addColorStop(0, color1);
  gradient.addColorStop(1, color2);
  return gradient;
}

const metrics = ['calls.total'];
const groupBy = 'time';

const CallsGraph = ({ filters, publicId }) => {
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.METRICS, { publicId, filters, metrics, groupBy }],
    () => getReportMetrics({ public_id: publicId, filters: { ...filters, metrics, group_by: groupBy } }),
    { placeholderData: {} }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  const parsedData = useMemo(() => {
    if (!data || !data['calls.total']) {
      return EMPTY_CALL_DATA;
    }
    const totals = data['calls.total'].groups;
    const ctx = document.querySelector('canvas')?.getContext('2d');

    return {
      labels: totals.map((b) => b.time),
      datasets: [
        {
          ...EMPTY_CALL_DATA.datasets[0],
          data: totals.map((b) => b.value),
          backgroundColor: ctx && createGradient(ctx, 'rgba(39, 90, 255,0.1)', 'rgba(255,255,255,0.1)'), // Using a custom gradient function
        },
      ],
    };
  }, [data]);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch calls data'));
  }

  return <TimeSeriesChart loaderStatus={loaderStatus} data={parsedData} title="NO. OF CALLS" />;
};

export default CallsGraph;
