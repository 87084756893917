import React from 'react';
import { Route } from 'react-router';

import { getCommunitiesByUserId } from 'apis';
import { PageHeader } from '../../common';
import { PAGE_HEADER_MARGIN } from '../../common/PageHeader';
import SEOAnalysis from './SEOAnalysis';
import { UserCommunities } from '../../common/communities/UserCommunities';
import { UserCommunitiesNoneSelected } from '../../common/communities/UserCommunitiesNoneSelected';

const SEOAnalyses = () => (
  <>
    <PageHeader
      title="Search Engine Optimization Analyses"
      subtitle="Select community and preview currently available analyses. Initiate independent analysis. "
      margin={PAGE_HEADER_MARGIN.SMALL}
    />
    <Route path="/admin/seo-analyses/:communityId?">
      <UserCommunities linkPrefix="/admin/seo-analyses/" communitiesCallback={getCommunitiesByUserId}>
        <Route exact path="/admin/seo-analyses/:communityId">
          <SEOAnalysis />
        </Route>
        <Route exact path="/admin/seo-analyses/">
          <UserCommunitiesNoneSelected />
        </Route>
      </UserCommunities>
    </Route>
  </>
);

export default SEOAnalyses;
