import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const NurtureTab = ({ item }) => {
  const isTabActive = !!useRouteMatch({ path: item.to, exact: item.exact });

  return (
    <Menu.Item key={item.key} as={Link} to={item.to} active={isTabActive} style={{ fontWeight: 'normal' }}>
      {item.label}
    </Menu.Item>
  );
};

export default NurtureTab;
