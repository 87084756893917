import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { formatCount, formatPercent } from 'components/utils';
import { capitalize } from 'utils/helpers';
import { Statistic } from '../../common/Statistic';

const Wrapper = styled.div`
  padding: 1rem 1rem 2rem;
`;

export const Metrics = ({ metrics }) => {
  const { t } = useTranslation();

  if (!metrics) {
    return null;
  }

  const { leads, scheduledTours, moveIns, messagesSent, messagesDelivered, messagesClicked, messageReplies } = metrics;

  return (
    <Wrapper>
      <Grid stackable>
        <Grid.Column width={6}>
          <Statistic.Group name="Primary Metrics">
            <Statistic label={capitalize(t('leads'))} value={formatCount(leads)} />
            <Statistic
              label={`Scheduled ${capitalize(t('tours'))}`}
              value={formatCount(scheduledTours)}
              secondaryValue={formatPercent(scheduledTours, leads)}
            />
            <Statistic
              label={capitalize(t('move_ins'))}
              value={formatCount(moveIns)}
              secondaryValue={formatPercent(moveIns, leads)}
            />
          </Statistic.Group>
        </Grid.Column>
        <Grid.Column width={10}>
          <Statistic.Group name="Messages" justifyContent="space-between">
            <Statistic label="Messages Sent" value={formatCount(messagesSent)} />
            <Statistic
              label="Messages Delivered"
              value={formatCount(messagesDelivered)}
              secondaryValue={formatPercent(messagesDelivered, messagesSent)}
            />
            <Statistic
              label="Messages Clicked"
              value={formatCount(messagesClicked)}
              secondaryValue={formatPercent(messagesClicked, messagesSent)}
            />
            <Statistic
              label="Messages Replied"
              value={formatCount(messageReplies)}
              secondaryValue={formatPercent(messageReplies, messagesSent)}
            />
          </Statistic.Group>
        </Grid.Column>
      </Grid>
    </Wrapper>
  );
};
