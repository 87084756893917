import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'components/lib';
import { ReactComponent as ConversionEngineImage } from 'assets/images/conversion-engine-with-connections.svg';

const ModalContent = styled(Modal.Content)`
  &&&& {
    padding: 30px;
  }
`;

const Title = styled.h3`
  font-family: var(--font-family-bold);
  font-size: 20px;
  font-weight: 900;
  color: var(--text);
`;

const Description = styled.p`
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 1.43;
  color: #89919d;

  & > span {
    color: var(--text);
  }
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  p {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: var(--text);
    width: 180px;
  }
`;

const ModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--line-standard);
  span {
    font-family: var(--font-family);
    font-size: 12px;
    color: #89919d;
    strong {
      color: var(--text);
    }
  }
`;

export const ConversionEngineModal = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalContent>
        <Title>How does this work?</Title>
        <Description>
          We track all digital behavior, feed that information into machine learning models, and then track success data
          so we can predict with a high level of confidence who would actually {t('move_in')}
          <span>*</span>.
        </Description>
        <ConversionEngineImage width={660} />
        <StepsContainer>
          <p>1. We track all digital behavior</p>
          <p>2. We feed this info into machine learning models</p>
          <p>3. We track success data for high quality predictions</p>
        </StepsContainer>
      </ModalContent>
      <ModalActions>
        <span>
          <strong>*</strong> Predictions are made with AI processing, and may not reflect actual results.
        </span>
        <Button primary onClick={onClose}>
          Close
        </Button>
      </ModalActions>
    </>
  );
};
