import React from 'react';
import { useHistory } from 'react-router-dom';
import { ConversationContextProvider } from './ConversationContext';
import { ConversationModes } from './ConversationModes';

export const ConversationMain = (props) => {
  const history = useHistory();
  const onModalClose = () => {
    if (!!history.location.state?.fromConversations) {
      history.goBack();
    } else {
      history.push('/conversations');
    }
  };
  return (
    <ConversationContextProvider onModalClose={onModalClose} {...props}>
      <ConversationModes />
    </ConversationContextProvider>
  );
};
