import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';

import { If } from '../If';
import { Logo } from '../Logo';
import { MEDIA_BREAK_POINTS } from '../../constants';
import { UserInfo, UserInfoWrapper } from './UserInfo';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  height: 4.25rem;
  padding: 0 1rem;
  background-color: var(--menu-navy);
  z-index: 2002;
`;

const MobileMenuTrigger = styled.div`
  width: 35px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  z-index: 1002;

  span {
    transition: transform 0.3s ease-in-out;
    width: 100%;
    height: 5px;
    background-color: white;
    border-radius: 5px;
  }

  span:nth-child(2) {
    transform: ${({ open }) => (open ? 'translateX(100%)' : '')};
  }

  span:first-child {
    transform: ${({ open }) => (open ? 'rotate(45deg) translateX(10px) translateY(9px)' : '')};
  }

  span:last-child {
    transform: ${({ open }) => (open ? 'rotate(-45deg) translateX(8px) translateY(-8px)' : '')};
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: calc(100% - 4.25rem);
  width: 100vw;
  position: fixed;
  top: 4.25rem;
  left: 0;
  background-color: var(--menu-navy);
  transform: translateY(-120%);
  transform: ${({ open }) => (open ? 'translateY(0)' : '')};
  transition: transform 0.3s ease-in-out;
  z-index: 2001;
  overflow: ${({ open }) => (open ? 'auto' : 'hidden')};

  &:-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar {
    width: 0;
  }

  ${UserInfoWrapper} {
    display: flex;
    width: 100%;
    margin-top: auto;
    align-self: center;
    padding: 10px;
  }
`;

export const MobileNavigation = ({ isWhitelabeled, children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  useEffect(() => {
    document.body.style.paddingTop = '50px';
    return () => (document.body.style.paddingTop = '0');
  }, []);

  const onToggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    document.body.style.position = mobileMenuOpen ? 'relative' : 'fixed';
  };

  return (
    <>
      <MobileMenu open={mobileMenuOpen}>
        {children}
        <If condition={!isWhitelabeled}>
          <UserInfo />
        </If>
      </MobileMenu>

      <Container mobileMenuOpen={mobileMenuOpen}>
        <If condition={!isWhitelabeled}>
          <NavLink to="/" className="logo">
            <Logo />
          </NavLink>
        </If>

        <MobileMenuTrigger onClick={onToggleMobileMenu} open={mobileMenuOpen}>
          <span />
          <span />
          <span />
        </MobileMenuTrigger>
      </Container>
    </>
  );
};
