import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { Button } from 'semantic-ui-react';

import { ChatModulesContext } from './ChatModulesContext';
import { Node } from './Node';
import { ModuleForm } from './modules/ModuleForm/ModuleForm';
import { ModuleHeader } from './ModuleHeader';
import { ModuleWrapper } from './ModuleWrapper';
import { NodeWrapper } from './NodeWrapper';
import { AddModule } from './AddModule';
import { jumpToModule } from '../../utils';

const InsertStepButton = styled(Button)`
  max-width: 25rem;
`;

export const Module = ({ node, children }) => {
  const { module, reachable } = node;
  const [editing, setEditing] = useState(false);
  const [addNextStepFormVisible, setAddNextStepFormVisible] = useState(false);
  const [insertStepFormVisible, setInsertStepFormVisible] = useState(false);
  const { addChildModule, updateModule, deleteModule, previewMode } = useContext(ChatModulesContext);

  const nextNode = node.getNext();
  const nextJump = node.getJumpNumber();

  const onAddNextStep = (newModule) => {
    setAddNextStepFormVisible(false);
    addChildModule(newModule, module);
  };

  const onInsertStep = (newModule) => {
    setInsertStepFormVisible(false);
    addChildModule(newModule, node.parentNode?.module, node.parentNodeAnswer);
  };

  const insertStepAvailable = !previewMode && reachable && !node.isRootNode();
  const addNextstepAvailable =
    !previewMode &&
    (node.isLastStep() || node.isOnlyStep()) &&
    (!nextJump || nextJump === module.number) &&
    !node.isRootNode();

  return (
    <>
      {insertStepAvailable && (
        <InsertStepButton
          basic
          fluid
          type="button"
          size="tiny"
          icon="add"
          content="Insert Step"
          onClick={() => {
            setInsertStepFormVisible(true);
          }}
        />
      )}

      {insertStepFormVisible && (
        <AddModule
          nextStep={node}
          onSubmit={onInsertStep}
          onCancel={() => {
            setInsertStepFormVisible(false);
          }}
        />
      )}

      <NodeWrapper key={node.number}>
        <ModuleWrapper>
          {!editing && (
            <>
              <ModuleHeader
                node={node}
                nextNode={nextNode}
                onEdit={() => {
                  setEditing(true);
                }}
                onDelete={(deleteRelated) => {
                  deleteModule(module, deleteRelated);
                }}
              />

              {children}
            </>
          )}

          {editing && (
            <ModuleForm
              node={node}
              onSubmit={(node) => {
                setEditing(false);
                updateModule(node.module);
              }}
              onCancel={() => {
                setEditing(false);
              }}
            />
          )}
        </ModuleWrapper>
        {reachable && (
          <>
            {nextJump && nextJump !== module.number && (
              <ModuleWrapper>
                <Button
                  icon="reply all"
                  size="tiny"
                  color="orange"
                  content={`Jump to module ${nextJump}`}
                  onClick={() => {
                    jumpToModule(nextJump);
                  }}
                />
                <Button
                  type="button"
                  size="tiny"
                  icon="edit"
                  onClick={() => {
                    if (!nextNode) {
                      addChildModule(module);
                    }
                  }}
                />
              </ModuleWrapper>
            )}

            {addNextstepAvailable && (
              <Button
                basic
                type="button"
                size="tiny"
                icon="add"
                content="Add next step"
                onClick={() => {
                  setAddNextStepFormVisible(true);
                }}
              />
            )}
          </>
        )}
      </NodeWrapper>

      {reachable && nextNode && <Node node={nextNode} />}
      {addNextStepFormVisible && (
        <AddModule
          onSubmit={onAddNextStep}
          onCancel={() => {
            setAddNextStepFormVisible(false);
          }}
        />
      )}
    </>
  );
};
