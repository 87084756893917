import { intervalToDuration } from 'date-fns';
/* eslint-disable no-bitwise */
import { STATUSES } from '../constants';

const Integrations = {
  SHERPA_CRM: 'sherpacrm',
};

export const queryStatusToLoaderStatus = (status, isFetching = false) => {
  if (isFetching) {
    return STATUSES.LOADING;
  }

  switch (status) {
    case 'idle':
      return STATUSES.IDLE;
    case 'loading':
      return STATUSES.LOADING;
    case 'error':
      return STATUSES.FAILURE;
    case 'success':
      return STATUSES.LOADED;
    default:
      console.error(`Unhandled status ${status}`);
  }
};

export const capitalize = (value, full = false) => {
  if (!value) {
    return value;
  }
  if (full) {
    return value
      .split(' ')
      .map((word) => {
        const firstLetter = word.charAt(0).toUpperCase();
        const rest = word.slice(1).toLowerCase();

        return firstLetter + rest;
      })
      .join(' ');
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const titleCase = (content, separator = ' ') =>
  content
    .toLowerCase()
    .split(separator)
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');

export const parseEmailMessage = (content) => content?.replace(/<br\s*\/?>/gi, '\n');

export const shouldRedirectSsoUnauthorized = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return params.integration === Integrations.SHERPA_CRM;
};

export const pluralize = (count, plural, singular) => `${count} ${parseInt(count) === 1 ? singular : plural}`;

export const getArrayDifference = (originalArray, changedArray) =>
  changedArray.filter((changedValue) => {
    const changedValueStr = JSON.stringify(changedValue);
    return !originalArray.some((originalValue) => changedValueStr === JSON.stringify(originalValue));
  });

export const loginPathWithNext = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const next = window.location.pathname;
  if (next !== '/') {
    queryParams.append('next', next);
  }

  return `/login?${queryParams}`;
};

export const splitName = (name = '') => {
  const [first_name, ...last_name] = name.split(' ').filter(Boolean);
  return {
    first_name,
    last_name: last_name.join(' '),
  };
};

export const firstInArray = (array) => [].concat(array).shift();

export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) {
    phoneNumberString = '';
  }

  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]} - ${match[2]} - ${match[3]}`;
  }
  return 'Invalid phone number';
};

export const getGoogleMapsUrl = (...params) => {
  const query = params.map((p) => encodeURIComponent(p)).join(',');
  return `https://www.google.com/maps/search/?api=1&query=${query}`;
};

export const scrollToTopOf = ({ element, xCoord = 0, yCoord = 0 }) =>
  document.querySelector(element).scrollTo(xCoord, yCoord);

export const formatSecondsToTime = (seconds) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  const zeroPad = (num) => String(num).padStart(2, '0');

  const formatted = `${duration.minutes}:${zeroPad(duration.seconds)}`;
  return formatted;
};

export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const isArray = (item) => item && Array.isArray(item);
export const isObject = (item) => item && typeof item === 'object' && !isArray(item);

export const numberFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const addProtocolToUrl = (url) => {
  if (!url) {
    return url;
  }

  if (!url.startsWith('http')) {
    return `https://${url}`;
  }

  return url;
};

export const divMod = (a, b) => {
  return [Math.floor(a / b), a % b];
};

export const getConversationUrl = (leadId) => `/admin/instant-responses/${leadId}/sms`;

export const getTomorrow = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
};
