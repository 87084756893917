import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { HorizontalBar } from 'react-chartjs-2';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { ReportComponent } from 'components/common/analytics';
import { STATUSES } from 'constants';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';
import { TopNGraphTitle } from './TopNGraphTitle';
import { useSamplesModal } from './SamplesModal';

const datasetDefaults = {
  label: 'Count',
  borderColor: 'rgb(200, 200, 200)',
  backgroundColor: 'rgba(200, 200, 200, 0.5)',
};

const chartOptions = {
  maintainAspectRatio: false,
  legend: false,
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const defaultLabeler = (b, groupBy) => {
  const v = b[groupBy];
  if (Array.isArray(v)) {
    return v.join(', ');
  }
  return v;
};

const TopNGraph = ({
  metric,
  groupBy,
  title,
  tooltip,
  timeField,
  toLabel = defaultLabeler,
  defaultLimit = 10,
  hideLimitSelect,
  withReviewFields,
  filters,
  ...datasetOverrides
}) => {
  const { automatedAnswerFilters } = useSystemStatsFilters();
  const finalFilters = filters || automatedAnswerFilters;
  const [limit, setLimit] = useState(defaultLimit);
  const {
    data = {},
    error,
    status,
    isFetching,
  } = useQuery(
    [queryConstants.ADMIN_METRICS, { ...finalFilters, metric, groupBy, limit, timeField }],
    () => getMetrics([metric], finalFilters, groupBy, limit, timeField),
    { placeholderData: {} }
  );

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);
  const loading = loaderStatus === STATUSES.LOADING;

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, `Unable to fetch calls top N metric ${metric}`));
  }

  const metrics = useMemo(() => {
    const res = data[metric]?.groups.filter((b) => b.value > 0) || [];
    const labels = res.map((b) => toLabel(b, groupBy));
    const datasets = [
      {
        ...datasetDefaults,
        data: res.map((b) => b.value),
        ...datasetOverrides,
      },
    ];
    return { labels, datasets };
  }, [data, metric, groupBy, datasetOverrides]);

  const getAdditionalFilterForSamples = (selectedBar) => {
    // filter samples by the currently selected value (bar)
    // some charts have multiple group by keys, so we have to filter by multiple fields as well
    if (!selectedBar) return null;
    const groupByKeys = groupBy.split(',');
    const groupByFilters = groupByKeys.reduce((sampleFilters, key) => {
      sampleFilters[key] = selectedBar[key];
      return sampleFilters;
    }, {});
    return { ...finalFilters, ...groupByFilters };
  };

  const { samplesModal, setSelectedBar } = useSamplesModal({
    getTitle: (bar) => toLabel(bar, groupBy),
    getFilters: getAdditionalFilterForSamples,
    groupBy,
    timeField,
    metric,
    withReviewFields,
  });

  const handleElementsClick = useCallback(
    (elements) => {
      const res = data[metric]?.groups.filter((b) => b.value > 0) || [];
      if (elements.length > 0 && res && res.length > 0) {
        const { _index } = elements[0];
        setSelectedBar(res[_index]);
      }
    },
    [data]
  );

  return (
    <>
      {samplesModal}
      <ReportComponent
        title={
          <TopNGraphTitle
            title={title}
            onChangeLimit={setLimit}
            defaultLimit={defaultLimit}
            hideLimitSelect={hideLimitSelect}
          />
        }
        tooltip={tooltip}
        loading={loading}
        contentHeight="300px"
      >
        <HorizontalBar data={metrics} height={300} options={chartOptions} onElementsClick={handleElementsClick} />
      </ReportComponent>
    </>
  );
};

export default TopNGraph;
