import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { MultiSelectFilter } from 'components/common';
import queryConstants from 'utils/queries/constants';
import { getMetrics } from 'apis';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const metric = 'messages.sales_assistant';
const groupBy = 'sender_id';
const limit = 100;

const SendersFilter = ({
  selectedValues,
  onChange,
  allItemsSelectedText = 'All Senders',
  name = 'Senders',
  ...other
}) => {
  const { getExtendedDateRange } = useSystemStatsFilters();
  const { start_date: start, end_date: end } = getExtendedDateRange();

  const {
    data = {},
    error,
    status,
    isFetching,
  } = useQuery(
    [queryConstants.METRICS, { metric, groupBy, limit, start, end }],
    () => getMetrics([metric], { start, end }, groupBy, limit),
    { placeholderData: {} }
  );

  const senderOptions = useMemo(() => {
    const options =
      data[metric]?.groups
        ?.filter(({ value }) => value > 0)
        ?.map(({ sender_id: id, user_email: name }) => ({ id, name })) || [];
    if (selectedValues && !isFetching) {
      selectedValues.forEach((id) => {
        if (options.findIndex(({ id: optionId }) => optionId === id) === -1) {
          options.push({ id, name: `Unknown Sender (${id})` });
        }
      });
    }
    return options;
  }, [data, isFetching, selectedValues]);

  return (
    <MultiSelectFilter
      disabled={isFetching}
      name={name}
      allItemsSelectedText={allItemsSelectedText}
      options={senderOptions}
      selectedValues={selectedValues}
      onChange={onChange}
      {...other}
    />
  );
};

export default SendersFilter;
