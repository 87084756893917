import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { Button, Icon } from 'components/lib';
import { pluralize } from 'utils/helpers';
import { MEDIA_BREAK_POINTS } from '../../../constants';
import { useSettings } from '../context/SettingsContext';
import {
  setSelectAllItems,
  setResetIsMultipleSelection,
  setResetSelectedPages,
  setResetSelectedItems,
  setResetExcludedItems,
  setResetAllItemsSelected,
} from '../context/actions';

const Container = styled.div`
  min-width: 373px;
  padding: 10px 10px 10px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 50%;
  transform: translateX(calc(-50% + 115px));
  z-index: 2003;
  top: 20px;
  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    transform: translateX(-50%);
  }
`;

const ButtonWrapper = styled.div`
  width: 145px;
  margin-left: auto;
  margin-right: 10px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const SelectAll = styled.div`
  color: var(--link);
  cursor: pointer;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

export const SettingsSelectedCountModal = ({ onClick, totalItemsCount, items, filters, totalPages }) => {
  const { t } = useTranslation();
  const [{ allItemsSelected, selectedItems, excludedItems, selectedPages }, dispatch] = useSettings();
  const count = allItemsSelected ? totalItemsCount - excludedItems.length : selectedItems.length;
  const allItemsSelectedCount = totalItemsCount - excludedItems.length;
  const showSelectedCount = allItemsSelected ? allItemsSelectedCount > 0 : selectedItems.length > 0;
  const countOfItemsOnPage = selectedItems.filter((itemId) => items?.find((item) => item.id === itemId))?.length;
  const isPageSelected = selectedPages.includes(filters.page);

  const handleSelectAllClick = () => {
    dispatch(setSelectAllItems(totalPages));
  };

  const handleOnClose = () => {
    dispatch(setResetIsMultipleSelection());
    dispatch(setResetSelectedItems());
    dispatch(setResetExcludedItems());
    dispatch(setResetAllItemsSelected());
    dispatch(setResetSelectedPages());
  };

  return (
    <If condition={showSelectedCount}>
      <Container>
        <TextWrapper>
          <If condition={!isPageSelected && !allItemsSelected}>
            {pluralize(count, `${t('communities')}`, `${t('community')}`)} selected.
          </If>
          <If condition={isPageSelected && !allItemsSelected}>
            All {pluralize(countOfItemsOnPage, `${t('communities')}`, `${t('community')}`)} on page selected.{' '}
            <If condition={totalItemsCount !== count}>
              <SelectAll onClick={handleSelectAllClick}>
                Select all {pluralize(totalItemsCount, `${t('communities')}`, `${t('community')}`)}
              </SelectAll>
            </If>
          </If>
          <If condition={allItemsSelected}>
            All {pluralize(allItemsSelectedCount, `${t('communities')}`, `${t('community')}`)} selected.
          </If>
        </TextWrapper>
        <ButtonWrapper>
          <Button primary color="blue" onClick={onClick} fluid>
            Edit Multiple
          </Button>
        </ButtonWrapper>
        <IconWrapper onClick={handleOnClose}>
          <Icon name="close" color="#89919d" size={16} />
        </IconWrapper>
      </Container>
    </If>
  );
};
