import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Modal, Button, Dimmer, Loader, Icon } from 'components/lib';
import PromptModal from 'components/common/PromptModal';
import {
  useAnswerOverrides,
  useGroupedAnswers,
  useResetOverrides,
  IntentsList,
  PlaceholderList,
} from 'components/common/automated_answers';
import { SettingsModalHeader, SettingsModalSelectedCommunity } from 'components/advanced/common';
import { EditCommunityIntent } from './EditCommunityIntent';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 70vh;
  max-height: 70vh;
  overflow: hidden;
`;

const DetailView = styled.div`
  flex: 1;
  padding: 20px 10px 0 30px;
  overflow: auto;
`;

const RightPanel = styled.div`
  max-width: 20rem;
  overflow: auto;
  padding: 20px 15px;

  .header {
    font-size: 14px;
    font-weight: 900;
    color: #222;
    margin-bottom: 10px;
  }
`;

const Buttons = styled.div`
  display: flex;

  div {
    flex: 1;
  }

  .reset-all {
    & > div {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;
      font-family: var(--font-family-bold);
      font-weight: 500;
    }
  }
`;

const StyledModal = styled(Modal)`
  @media only screen and (min-width: 1200px) {
    &.ui.medium.modal {
      width: 970px;
    }
  }

  &.ui.modal > .header {
    padding-bottom: 10px;
  }
`;

const StyledIntents = styled(IntentsList)`
  overflow-y: auto;
  margin-bottom: 0 !important;
  border-bottom: none !important;
  border-top: none !important;
`;

const Content = styled.div``;

const resetMessageSingle = (t) =>
  `This will revert all automated answers for this ${t(
    'community'
  )} to the default system answers. Are you sure you want to do this?`;

const resetMessagePlural = (t) =>
  `This will revert all automated answers for all selected ${t(
    'communities'
  )} to the default system answers. Are you sure you want to do this?`;

const ResetAllConfirm = ({ open, onCancel, onConfirm, communities, selectedCommunity, t }) => (
  <PromptModal
    description={communities?.length === 1 ? resetMessageSingle(t) : resetMessagePlural(t)}
    title="Restore all answers to default values?"
    subtitle={<SettingsModalSelectedCommunity count={communities?.length} community={selectedCommunity} />}
    actionLabel="Restore"
    actionColor="orange"
    open={open}
    onClose={onCancel}
    onConfirm={onConfirm}
  />
);

const ConfirmChangeIntent = ({ open, onCancel, onConfirm }) => (
  <PromptModal
    title="Are you sure?"
    description="You have unsaved changes. Are you sure you want to navigate away?"
    open={open}
    onClose={onCancel}
    onConfirm={onConfirm}
  />
);

export const AutomatedAnswersModal = ({
  selectedItemsCount,
  selectedItems,
  selectedCommunity,
  open,
  onClose,
  onAfterSaveAndClose,
  refetchCommunities,
  answerTypes,
}) => {
  const queryClient = useQueryClient();
  const { isLoading, groups, intents } = useGroupedAnswers({ type__in: answerTypes.join(',') });
  const { data: overrides, isLoading: isLoadingOverrides } = useAnswerOverrides({
    community: selectedItems?.[0],
    enabled: !!selectedItems?.[0],
    answer__type__in: answerTypes.join(','),
  });
  const [intent, setIntent] = useState(null);
  const formikRef = useRef(null);
  const resetMutation = useResetOverrides(queryClient);
  const [showReset, setShowReset] = useState(false);
  const [pendingIntent, setPendingIntent] = useState(null);
  const buttonsRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!intent && intents) {
      setIntent(intents[0]);
    }
  }, [intent, intents]);

  const handleSetIntent = useCallback((intent) => {
    if (formikRef.current?.dirty) {
      setPendingIntent(intent);
    } else {
      setIntent(intent);
    }
  }, []);

  const handleResetAllConfirm = useCallback(async () => {
    try {
      await resetMutation.mutateAsync({ filter: { communities: selectedItems }, answer_types: answerTypes });
      refetchCommunities();
      toast.success('All answers were successfully reset!');
    } catch (e) {
      toast.error('Unable to reset answers due to unknown server error');
      throw e;
    } finally {
      setShowReset(false);
    }
  }, [selectedItems, refetchCommunities]);

  if (isLoading) {
    return null;
  }
  return (
    <StyledModal open={open} closeIcon onClose={onClose} size="medium">
      <ConfirmChangeIntent
        open={!!pendingIntent}
        onCancel={() => setPendingIntent(null)}
        onConfirm={() => {
          setIntent(pendingIntent);
          setPendingIntent(null);
        }}
      />
      <ResetAllConfirm
        communities={selectedItems}
        selectedCommunity={selectedCommunity}
        open={showReset}
        onCancel={() => setShowReset(false)}
        onConfirm={handleResetAllConfirm}
        t={t}
      />
      <Modal.Header>
        <SettingsModalHeader community={selectedCommunity} count={selectedItemsCount} title="Automated Answers" />
      </Modal.Header>
      <Modal.Content>
        <Content>
          <Dimmer active={isLoading} inverted>
            <Loader active size="large" transparent />
          </Dimmer>
          <Container>
            <StyledIntents
              groups={groups}
              intents={intents}
              overrides={selectedItemsCount === 1 ? overrides : undefined}
              selectedIntent={intent}
              onSelectIntent={handleSetIntent}
            />
            <DetailView>
              {intent ? (
                <EditCommunityIntent
                  communities={selectedItems}
                  intent={intent}
                  communityOverrides={overrides}
                  group={groups[intent]}
                  onCancel={onClose}
                  key={intent}
                  formikRef={formikRef}
                  buttonsRef={buttonsRef}
                  refetchCommunities={refetchCommunities}
                  onSaveAndClose={() => {
                    onClose();
                    onAfterSaveAndClose();
                  }}
                />
              ) : (
                <p>Select label on the left to get started.</p>
              )}
            </DetailView>
            <RightPanel>
              <div className="header">Insert Placeholder</div>
              <PlaceholderList showSafePlaceholdersOnly />
            </RightPanel>
          </Container>
        </Content>
      </Modal.Content>
      <Modal.Actions>
        <Buttons>
          <Button
            basic
            icon
            disabled={resetMutation.isLoading}
            onClick={() => setShowReset(true)}
            style={{ width: '237px', height: '42px' }}
            className="reset-all"
          >
            <div>
              <Icon size={24} name="error_outline" color="#fa7550" /> Restore Default Answers
            </div>
          </Button>
          <div ref={buttonsRef}></div>
        </Buttons>
      </Modal.Actions>
    </StyledModal>
  );
};
