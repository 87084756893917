import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { formatAnyDate } from 'components/utils';
import { Icon } from 'components/lib';
import { OPT_OUT_CONVERSATION_TYPE_NAME } from '../constants';

const Container = styled.div`
  padding: 8px 20px 7px 18px;
  border-radius: 17px;
  box-shadow: 0 3px 5px 0 rgba(216, 216, 216, 0.85);
  background-color: var(--white);
  min-width: 402px;
  max-width: 420px;
  height: 35px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;

  .text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .text {
    font-family: var(--font-family);
    font-weight: 500;
    color: #89919d;
    font-size: 13px;
  }

  .time {
    font-size: 11px;
  }
`;

export const ConversationStopped = ({ data }) => {
  const { t } = useTranslation();
  const { created_on, conversation_type } = data;
  const conversationTypeName = OPT_OUT_CONVERSATION_TYPE_NAME[conversation_type];

  return (
    <Container>
      <div className="text-container">
        <Icon name="comments_disabled" color="#fa7550" size="14" />
        <div className="text">
          The {t('lead')} has opted out of {conversationTypeName} messaging.
        </div>
      </div>
      <div className="text time">{formatAnyDate(created_on, 'hh:mm a')}</div>
    </Container>
  );
};
