import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

import { Icon, Menu, Dropdown } from 'components/lib';
import { If } from 'components/If';
import { ConversationContext } from '../ConversationContext';
import { useOutsideClick } from '../../../utils/hooks';
import { formatAnyDate } from '../../utils';
import { CONVERSATION_ACTIONS, CONVERSATION_EVENT, CONVERSATION_EVENT_NAME, SCHEDULE_TYPES } from './constants';

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  padding-right: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--font-family-bold);
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-color);
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  padding: 0;
  color: var(--text-color);
  cursor: pointer;
  overflow: visible;

  &.disabled {
    pointer-events: none;
  }

  ${IconContainer} {
    color: ${({ $color }) => $color};
    span {
      color: ${({ $color }) => $color};
    }
  }
`;

const Title = styled.span`
  font-family: var(--font-family);
`;

export const SubTitle = styled.span`
  font-family: var(--font-family);
  font-size: 10px;
`;

const MenuItem = styled(Menu.Item)`
  &&& {
    text-transform: capitalize;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  .ui.vertical.menu {
    width: 100%;
  }
`;

const ScheduleButton = ({ type, classes, disabled, ...props }) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const typeName = CONVERSATION_EVENT_NAME(t)[type];

  useOutsideClick(ref, () => setMenuVisible(false));

  const { leadUserReminder, setEventType, selectedConversation, showModal } = useContext(ConversationContext);

  const MENU_ITEMS = {
    alternativeDates: {
      label: 'Offer Alternative Dates',
      action: CONVERSATION_ACTIONS.ALTERNATIVE_DATES,
    },
    confirm: {
      label: `Confirm ${typeName}`,
      action: CONVERSATION_ACTIONS.CONFIRM,
    },
    moreOptions: {
      label: 'More Options',
      action: CONVERSATION_ACTIONS.MORE_OPTIONS,
    },
    reschedule: {
      label: 'Reschedule',
      action: CONVERSATION_ACTIONS.RESCHEDULE,
    },
    cancel: {
      label: `Cancel ${typeName}`,
      action: CONVERSATION_ACTIONS.CANCEL,
    },
  };

  const event = selectedConversation?.[type];
  const initialState = !event || event?.cancelled;

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuItem1, setMenuItem1] = useState(MENU_ITEMS.confirm);
  const [menuItem2, setMenuItem2] = useState(MENU_ITEMS.moreOptions);

  useEffect(() => {
    if (event?.reschedule_id) {
      setMenuItem1(MENU_ITEMS.alternativeDates);
      setMenuItem2(MENU_ITEMS.cancel);
    }
  }, [event]);

  // Prevent display of buttons for regional and regular users if there is no active event
  if (disabled && initialState) {
    return null;
  }

  const actionType = SCHEDULE_TYPES(t)[type];
  const containerClasses = classNames(classes, { disabled, initialState });

  const displayDate = type === CONVERSATION_EVENT.REMINDER ? leadUserReminder.scheduled_at : event?.start;
  const displayDateValid = !!displayDate;
  const displayDateZoned = utcToZonedTime(displayDate, selectedConversation?.community.timezone);
  const currentDateTimezoned = utcToZonedTime(new Date(), selectedConversation?.community.timezone);
  const confirmItemDisabled = isBefore(displayDateZoned, currentDateTimezoned) || !displayDateValid;
  const date = displayDateValid && formatAnyDate(displayDateZoned, "MMM d yyyy 'at' h:mm a");

  const handleClick = () => {
    setEventType(type);

    if (type === CONVERSATION_EVENT.REMINDER) {
      showModal(CONVERSATION_ACTIONS.REMINDER);
      return;
    }

    if (event && event.confirmed) {
      return;
    }

    if (event && !event.cancelled) {
      setMenuVisible((prev) => !prev);
      return;
    }

    showModal(CONVERSATION_ACTIONS.SCHEDULE);
  };

  const handleMenuClick = (item) => {
    showModal(item.action);
  };

  const alternativeDatesSent = event?.reschedule_id && !(event?.confirmed || event?.cancelled);
  const title = initialState
    ? actionType.label
    : event?.confirmed
    ? actionType.confirmedText
    : actionType.requestedText;

  return (
    <Dropdown.Item className={classNames({ 'item-disabled': disabled })}>
      <Container
        className={containerClasses}
        {...props}
        $color={actionType.color}
        $fadedColor={actionType.fadedColor}
        onClick={handleClick}
        ref={ref}
      >
        <IconContainer>
          <Icon name={actionType.icon} variant="outlined" size="22" />
        </IconContainer>
        <Content>
          <If condition={initialState || event?.cancelled}>
            <SubTitle>{actionType.buttonSubTitle}</SubTitle>
          </If>
          <Title>{alternativeDatesSent ? actionType.offeredText : title}</Title>
          <If condition={(!initialState || event) && !event?.cancelled}>
            <SubTitle>{alternativeDatesSent ? 'Awaiting response' : date}</SubTitle>
          </If>
        </Content>

        <If condition={menuVisible}>
          <MenuContainer>
            <Menu vertical>
              <MenuItem disabled={confirmItemDisabled} onClick={() => handleMenuClick(menuItem1)}>
                {menuItem1.label}
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick(menuItem2)}>{menuItem2.label}</MenuItem>
            </Menu>
          </MenuContainer>
        </If>
      </Container>
    </Dropdown.Item>
  );
};

ScheduleButton.propTypes = {
  type: PropTypes.oneOf(Object.keys(CONVERSATION_EVENT).map((key) => key.toLocaleLowerCase())),
  disabled: PropTypes.bool,
};

export default ScheduleButton;
