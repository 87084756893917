import React from 'react';
import { Tab } from 'semantic-ui-react';

import { useAnalytics } from '../context/AnalyticsContext';
import Benchmarks from './Benchmarks';

const BenchmarksTab = () => {
  const [{ filters, publicId }] = useAnalytics();

  return (
    <Tab.Pane>
      <Benchmarks filters={filters} publicId={publicId} />
    </Tab.Pane>
  );
};

export default BenchmarksTab;
