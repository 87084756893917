import React from 'react';
import styled from 'styled-components';
import { formatAnyDate } from 'components/utils';
import { If } from 'components/If';

const BoldTourDate = styled.span`
  font-family: var(--font-family-bold);
`;

const CRMTourRequestedContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ConversationCRMTourRequested = ({ lead }) => {
  if (!lead) {
    return null;
  }

  return (
    <CRMTourRequestedContainer>
      <If condition={lead.tour_date}>
        <BoldTourDate>{formatAnyDate(lead.tour_date, 'MMM d, yyyy')}</BoldTourDate>
      </If>
      <If condition={!lead.tour_date}>-</If>
    </CRMTourRequestedContainer>
  );
};

export default ConversationCRMTourRequested;
