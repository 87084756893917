import React from 'react';
import styled from 'styled-components';

const NoCampaignsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const NoCampaignsTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
`;

const NoCampaignsSubtitle = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #89919d;
`;

export const EmptyCampaigns = ({ title, subtitle, children }) => {
  return (
    <NoCampaignsWrapper>
      <NoCampaignsTitle>{title}</NoCampaignsTitle>
      <NoCampaignsSubtitle>{subtitle}</NoCampaignsSubtitle>
      {children}
    </NoCampaignsWrapper>
  );
};
