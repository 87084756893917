import React from 'react';
import { Tab } from 'semantic-ui-react';

import { useAnalytics } from '../context/AnalyticsContext';
import AverageLeadValueScoreGraph from './AverageLeadValueScoreGraph';
import LeadValueScores from './LeadValueScores';

const LeadsTab = () => {
  const [{ filters, publicId }] = useAnalytics();

  return (
    <Tab.Pane>
      <AverageLeadValueScoreGraph filters={filters} publicId={publicId} />
      <LeadValueScores filters={filters} publicId={publicId} />
    </Tab.Pane>
  );
};

export default LeadsTab;
