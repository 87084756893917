import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

const DatePickerWrapper = styled.div`
  display: flex;
`;

const DatePickerError = styled.div`
  display: flex;
  color: #9f3a38;
  border: 1px solid #e0b4b4;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 12px;
  margin-left: 0.5rem;
`;

export const DatePickerField = ({ onChange, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const { touched, error } = meta;

  return (
    <DatePickerWrapper>
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
          onChange && onChange(val);
        }}
      />
      {touched && error && <DatePickerError>{error}</DatePickerError>}
    </DatePickerWrapper>
  );
};
