import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConversationContext } from '../ConversationContext';
import { ProfileCircle } from '../../common/profile/ProfileCircle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;
`;

const CommunityInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`;

const CommunityName = styled.div`
  font-weight: 900;
  color: var(--text-color);
`;

const CommunityContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  a {
    color: var(--text-color);
    &.email {
      color: var(--link);
    }
  }
`;

export const CommunityInfoPanel = () => {
  const { selectedConversation } = useContext(ConversationContext);

  return (
    <Wrapper>
      <ProfileCircle name={selectedConversation?.community?.name} parts={1} />
      <CommunityInfo>
        <CommunityName>{selectedConversation?.community?.name}</CommunityName>
        <CommunityContactInfo>
          <a className="email" href={`mailto:${selectedConversation?.community?.email}`}>
            {selectedConversation?.community?.email}
          </a>
          <a href={`tel:${selectedConversation?.community.phone}`}>{selectedConversation?.community?.phone}</a>
        </CommunityContactInfo>
      </CommunityInfo>
    </Wrapper>
  );
};
