import React from 'react';
import { Button } from 'semantic-ui-react';

import { jumpToModule } from '../../utils';
import { OptionTitle } from './OptionTitle';
import { ACTION_TYPES } from './ModuleGraph';

export const OptionButton = ({ answer }) => {
  const { text, action, meta } = answer;

  return (
    <OptionTitle>
      <Button
        fluid
        color={meta?.actionType === ACTION_TYPES.CLOSE ? 'grey' : 'blue'}
        size="small"
        onClick={() => {
          jumpToModule(action);
        }}
      >
        {text}
      </Button>
    </OptionTitle>
  );
};
