import React from 'react';
import { Button } from 'components/lib';
import { PAGE_HEADER_PADDING, PageHeader } from '../../common';

export const UserCommunityPanelHeader = ({ title, subtitle, disabled, loading, onUpdate, content, type, actions }) => (
  <PageHeader
    title={title}
    subtitle={subtitle}
    padding={PAGE_HEADER_PADDING.VERTICAL}
    actions={
      actions || (
        <Button
          primary
          type={type || 'submit'}
          content={content || 'Update'}
          disabled={disabled}
          loading={loading}
          onClick={onUpdate}
        />
      )
    }
  />
);
