import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, useField } from 'formik';
import { Button, Table, Dropdown, Form, Header, Input } from 'semantic-ui-react';

import { MODULE_TYPES } from 'components/chats/ChatModules/constants';

const Wrapper = styled.div`
  .ui.table {
    tfoot {
      .field {
        margin-bottom: 0;
      }
    }

    .trait-value {
      overflow-wrap: anywhere;
    }
  }
`;

const InvalidModuleText = styled.div`
  color: var(--warning);

  .delete {
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 700;
  }
`;

const ModuleWrapper = styled(Form.Field)`
  &&&& {
    margin: auto;
  }
`;

const MIN_TIMEOUT_VALUE = 1;
const MAX_TIMEOUT_VALUE = 60;

const getModuleList = (modules) =>
  modules
    .filter((m) => m.module_type === MODULE_TYPES.DIAL_COMMUNITY)
    .map((m) => ({
      key: m.id,
      value: m.id,
      text: `Module ${m?.number} [${m?.module_type}]`,
      search: `Module ${m?.number} [${m?.module_type} ${m?.text}]`,
      content: <Header as="h5" content={`Module ${m?.number} [${m?.module_type}]`} subheader={m?.text} />,
    }));

const ModuleDisplay = ({ moduleId, modules }) => {
  if (!moduleId || !modules) {
    return '-';
  }

  const module = modules.find((m) => m.id === moduleId);

  if (!module) {
    return (
      <InvalidModuleText>
        <div>Invalid module</div>
        <div className="delete">Safe to delete</div>
      </InvalidModuleText>
    );
  }

  return (
    <div>
      Module {module.number} [{module.module_type}]
    </div>
  );
};

// Not a real form because nesting <form /> within another <form />
// is not allowed.
const AddModuleCallForwardingTimeout = ({ modules, onSubmit }) => {
  const [module, setModule] = useState('');
  const [ringingTimeout, setRingingTimeout] = useState('');
  const [moduleError, setModuleError] = useState(false);
  const [ringingTimeoutError, setRingingTimeoutError] = useState(false);
  const moduleList = getModuleList(modules);

  const reset = () => {
    setModuleError(false);
    setRingingTimeoutError(false);
    setModule('');
    setRingingTimeout('');
  };

  const submit = () => {
    let valid = true;

    if (!module || module === '') {
      valid = false;
      setModuleError(true);
    }

    if (ringingTimeout < MIN_TIMEOUT_VALUE || ringingTimeout > MAX_TIMEOUT_VALUE) {
      valid = false;
      setRingingTimeoutError({
        content: `Allowed values between ${MIN_TIMEOUT_VALUE} and ${MAX_TIMEOUT_VALUE}.`,
      });
    }

    if (valid && typeof onSubmit === 'function') {
      onSubmit({
        ringing_timeout: ringingTimeout,
        module_id: module,
      });

      reset();
    }
  };

  return (
    <Table.Row>
      <Table.HeaderCell verticalAlign="top" textAlign="center">
        <ModuleWrapper
          width="8"
          error={moduleError}
          control={Dropdown}
          fluid
          placeholder="Select module"
          selection
          options={moduleList}
          search={(options, query) => options.filter((o) => o.search.includes(query.toLowerCase()))}
          value={module}
          onChange={(_, d) => {
            setModule(d.value);
            setModuleError(false);
          }}
        />
      </Table.HeaderCell>
      <Table.HeaderCell verticalAlign="top">
        <Form.Field
          control={Input}
          error={ringingTimeoutError}
          type="number"
          min={MIN_TIMEOUT_VALUE}
          max={MAX_TIMEOUT_VALUE}
          defaultValue=""
          value={ringingTimeout}
          onChange={(_, d) => {
            setRingingTimeout(d.value);
            setRingingTimeoutError(false);
          }}
        />
      </Table.HeaderCell>

      <Table.HeaderCell verticalAlign="top">
        <Button.Group floated="right">
          <Button type="button" icon="add" content="Add Timeout" size="small" basic onClick={submit} />
        </Button.Group>
      </Table.HeaderCell>
    </Table.Row>
  );
};

export const ModuleCallForwardingTimeouts = ({ modules, ...props }) => {
  const [, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const moduleCallForwardingTimeouts = [...meta.value];

  const addModuleCallForwardingTimeout = (timeout) => {
    const { id, module_id, ringing_timeout } = timeout;
    setValue([...moduleCallForwardingTimeouts, { id, module_id, ringing_timeout }]);
  };

  const removeModuleCallForwardingTimeout = (index) => () => {
    moduleCallForwardingTimeouts.splice(index, 1);
    setValue(moduleCallForwardingTimeouts);
  };

  const filteredModules = modules.filter((m) => !moduleCallForwardingTimeouts.some((t) => t.module_id === m.id));

  return (
    <Wrapper>
      <Table>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={5}>Module</Table.HeaderCell>
            <Table.HeaderCell width={1}>Call Dialing Timeout</Table.HeaderCell>
            <Table.HeaderCell width={3}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {moduleCallForwardingTimeouts?.map((mc, index) => (
            <Table.Row key={index} textAlign="center">
              <Table.Cell>
                <ModuleDisplay moduleId={mc.module_id} modules={modules} />
              </Table.Cell>
              <Table.Cell>{mc.ringing_timeout}</Table.Cell>
              <Table.Cell textAlign="right">
                <Button.Group floated="right">
                  <Button
                    type="button"
                    icon="times"
                    size="small"
                    basic
                    onClick={removeModuleCallForwardingTimeout(index)}
                  />
                </Button.Group>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer fullWidth>
          {filteredModules.length > 0 && (
            <AddModuleCallForwardingTimeout modules={filteredModules} onSubmit={addModuleCallForwardingTimeout} />
          )}
        </Table.Footer>
      </Table>
    </Wrapper>
  );
};
