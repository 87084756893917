import humps from 'humps';

import { API } from '../api';

export const checkInstallationScript = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/chat/check-installation-script', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const createOnboarding = async (params) => {
  const payload = humps.decamelizeKeys(params);
  const response = await API.post('/api/accounts/onboarding', {
    collected_data: payload,
  });
  return humps.camelizeKeys(response.data);
};

export const updateOnboarding = async (onboardingUuid, params) => {
  const payload = humps.decamelizeKeys(params);
  const response = await API.patch(`/api/accounts/onboarding/${onboardingUuid}`, payload);
  return humps.camelizeKeys(response.data);
};

export const finishOnboarding = async (onboardingUuid) => {
  const params = {
    readyToOnboard: true,
  };
  return updateOnboarding(onboardingUuid, params);
};

export const fetchOnboardingData = async (onboardingUuid, template) => {
  const response = await API.get(`/api/accounts/onboarding/${onboardingUuid}`, {
    params: { template },
  });
  return humps.camelizeKeys(response.data);
};

export const createAccount = async (params) => {
  const payload = humps.decamelizeKeys(params);
  const response = await API.post('/api/accounts/signup', payload);
  return humps.camelizeKeys(response.data);
};

export const checkWebsite = async (params) => {
  const payload = humps.decamelizeKeys(params);
  const response = await API.post('/api/accounts/check-website', payload);
  return humps.camelizeKeys(response.data);
};

export const resendEmailVerificationCode = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/accounts/resend-email-verification-code', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const recaptcha = async (params) => {
  const payload = humps.decamelizeKeys(params);
  const response = await API.post('/api/accounts/recaptcha', payload);
  return humps.camelizeKeys(response.data);
};
