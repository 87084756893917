import React from 'react';

import { Module } from './Module';
import { Calendar } from './Calendar';
import { MessageInputField } from './MessageInputField';
import { Message } from './Message';
import { SendIcon } from './icons';
import { Answers } from './Answers';
import { jumpToModule } from '../../utils';

export const UserInput = ({ node }) => {
  const { module } = node;
  const { text } = module;

  return (
    <Module node={node}>
      {text && <Message>{text}</Message>}
      {module.module_type === 'date_picker' ? (
        <Calendar />
      ) : (
        <>
          <Answers node={node} />
          <MessageInputField
            onClick={() => {
              jumpToModule(module.action);
            }}
          >
            <span>Type here...</span>
            <SendIcon />
          </MessageInputField>
        </>
      )}
    </Module>
  );
};
