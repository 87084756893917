import React, { useContext } from 'react';
import styled from 'styled-components';
import { Dropdown, Icon, Divider } from 'components/lib';
import { ACTIONS, CommunitiesContext } from '../common/communities/CommunitiesContext';
import { USER_COMMUNITY_ACTION_MODALS } from './constants';

const OptionsContainer = styled.div`
  margin-left: auto;
  align-self: center;
  margin-top: 5px;
`;

const CustomDivider = styled(Divider)`
  &&&& {
    margin: 0;
  }
`;

const UserCommunityOptions = () => {
  const { state, dispatch } = useContext(CommunitiesContext);
  const handleOnClick = (activeModal) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_MODAL,
      payload: { activeModal },
    });
  };
  return (
    <OptionsContainer>
      <Dropdown icon={null} trigger={<Icon name="more_horiz" size="22" />}>
        <Dropdown.Menu direction="left">
          <Dropdown.Item onClick={() => handleOnClick(USER_COMMUNITY_ACTION_MODALS.ASSIGN_COMMUNITY)}>
            Assign to a Different Client
          </Dropdown.Item>
          <CustomDivider />
          <Dropdown.Item onClick={() => handleOnClick(USER_COMMUNITY_ACTION_MODALS.REMOVE_COMMUNITY)}>
            Remove Community
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </OptionsContainer>
  );
};

export default UserCommunityOptions;
