import React from 'react';

import Timer from './Timer';
import { CALL_STATUS, CALL_STATUS_LABELS } from './constants';

const CallStatusLabel = ({ communityName, status }) => {
  if (!status.value) {
    return null;
  }

  if (status.value === CALL_STATUS.OPEN) {
    return <Timer startedAt={status.updatedAt} />;
  }

  if (status.value === CALL_STATUS.PENDING && communityName) {
    return `Incoming call for ${communityName}`;
  }

  return CALL_STATUS_LABELS[status.value];
};

export default CallStatusLabel;
