import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Table } from 'components/lib';
import FeaturesOverviewCommunitiesStatuses from './FeaturesOverviewCommunitiesStatuses';

const FeaturesOverviewCommunitiesList = ({ communities }) => {
  const history = useHistory();
  const { userId } = useParams();

  const onRowClick = (communityId) => {
    history.push(`/admin/features/${userId}/${communityId}`);
  };

  return (
    <>
      {communities.map((community) => (
        <Table.Row key={community.id} onClick={() => onRowClick(community.id)}>
          <Table.Cell>{community.name}</Table.Cell>
          <FeaturesOverviewCommunitiesStatuses community={community} />
        </Table.Row>
      ))}
    </>
  );
};

export default FeaturesOverviewCommunitiesList;
