import React from 'react';
import styled from 'styled-components';

import { Divider, Dropdown } from 'components/lib';

const CustomDropdown = styled(Dropdown)`
  &&&&&& {
    .dropdown.icon {
      font-family: 'Material Icons';
      font-size: 12px;
      ::before {
        content: '\\e5cf'; // Down caret
        font-size: 18px;
      }
      margin: 0 0 0 2px;
      position: relative;
      top: 3px;
    }
    .text {
      font-family: var(--font-family);
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      color: #222;
      letter-spacing: unset;
    }
    .menu {
      .disabled {
        opacity: 1 !important;
      }
    }

    &.button {
      border-radius: 18px;
      box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
      border: solid 1px var(--line-standard);
      background-color: var(--white);
      padding: 4px 8px;
      &:hover {
        background-color: var(--white);
      }
    }
`;

const CustomDropdownMenu = styled(Dropdown.Menu)`
  width: 190px;
`;

const NumbersDropdownItem = styled(Dropdown.Item)`
  &&&&& {
    display: flex !important;
    flex-wrap: wrap !important;
    padding: 10px !important;
    cursor: default !important;
  }
`;

export const PhoneNumbersDropdown = ({ text, disabled, onEdit, children }) => (
  <CustomDropdown text={text} button disabled={disabled}>
    <CustomDropdownMenu>
      {children && (
        <>
          <NumbersDropdownItem disabled>{children}</NumbersDropdownItem>
          <Divider />
        </>
      )}
      <Dropdown.Item onClick={onEdit}>
        <div>Edit</div>
      </Dropdown.Item>
    </CustomDropdownMenu>
  </CustomDropdown>
);
