import React, { useContext } from 'react';

import { STATUSES } from '../../constants';
import { PAGE_HEADER_MARGIN, PageSection } from '../common';
import { If } from '../If';
import { ToursContext } from './ToursContext';
import { TourNotFound } from './TourNotFound';
import { TourPreview } from './TourPreview';

export const Tour = () => {
  const { tourStatus } = useContext(ToursContext);

  return (
    <PageSection as="h2" margin={PAGE_HEADER_MARGIN.SMALL} loaderStatus={tourStatus}>
      <If condition={tourStatus === STATUSES.LOADED}>
        <TourPreview />
      </If>

      <If condition={tourStatus === STATUSES.FAILURE}>
        <TourNotFound />
      </If>
    </PageSection>
  );
};
