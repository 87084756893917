import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { getEngagementGraph } from 'apis';
import { toast } from 'react-toastify';
import { parseErrorResponse } from 'apis/utils';
import queryConstants from 'utils/queries/constants';
import { queryStatusToLoaderStatus, capitalize } from 'utils/helpers';
import { TimeSeriesChart } from 'components/common/analytics';

const EMPTY_USERS = {
  labels: [],
  datasets: [
    {
      label: 'Visitors',
      backgroundColor: 'rgba(31, 87, 189, 0.1)',
      borderColor: 'rgba(31, 87, 189, 1)',
      borderWidth: 1,
      minBarLength: 5,
      hoverBackgroundColor: 'rgba(31, 119, 180, 0.4)',
      hoverBorderColor: 'rgba(31, 119, 180, 1)',
      data: [],
    },
    {
      label: 'VSA Users',
      backgroundColor: 'rgba(255, 127, 14, 0.2)',
      borderColor: 'rgba(255, 127, 14, 1)',
      borderWidth: 1,
      minBarLength: 5,
      hoverBackgroundColor: 'rgba(255, 127, 14, 0.4)',
      hoverBorderColor: 'rgba(255, 127, 14, 1)',
      data: [],
    },
  ],
};

const EMPTY_LEADS = {
  labels: [],
  datasets: [
    {
      label: 'Leads',
      backgroundColor: 'rgba(255, 127, 14, 0.2)',
      borderColor: 'rgba(255, 127, 14, 1)',
      borderWidth: 1,
      minBarLength: 5,
      hoverBackgroundColor: 'rgba(255, 127, 14, 0.4)',
      hoverBorderColor: 'rgba(255, 127, 14, 1)',
      data: [],
    },
    {
      label: 'Requested Tours',
      backgroundColor: 'rgba(44, 160, 44, 0.2)',
      borderColor: 'rgba(44, 160, 44, 1)',
      borderWidth: 1,
      minBarLength: 5,
      hoverBackgroundColor: 'rgba(44, 160, 44, 0.4)',
      hoverBorderColor: 'rgba(44, 160, 44, 1)',
      data: [],
    },
  ],
};

const OverviewGraph = ({ filters, publicId }) => {
  const { t } = useTranslation();
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.ENGAGEMENT_GRAPH, { publicId, filters }],
    () => getEngagementGraph({ public_id: publicId, filters }),
    {
      placeholderData: [],
      select: (data) =>
        data.reduce(
          (accumulator, item) => {
            accumulator.labels.push(item.date);
            accumulator.visitors.push(item.visitors);
            accumulator.leads.push(item.leads);
            accumulator.tours.push(item.tours);
            accumulator.interactions.push(item.interactions);
            accumulator.move_ins.push(item.move_ins);
            return accumulator;
          },
          {
            labels: [],
            visitors: [],
            leads: [],
            tours: [],
            interactions: [],
            move_ins: [],
          }
        ),
    }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch engagement graph'));
  }

  const [graphs, setGraphs] = useState([EMPTY_USERS, EMPTY_LEADS]);

  useEffect(() => {
    const prepareGraphData = (labels, visitors, leads, tours, interactions, moveIns) => {
      const userDataset = {
        labels,
        datasets: [
          {
            ...EMPTY_USERS.datasets[0],
            data: visitors,
          },
          {
            ...EMPTY_USERS.datasets[1],
            data: interactions,
          },
        ],
      };

      const leadsDataset = {
        labels,
        datasets: [
          {
            ...EMPTY_LEADS.datasets[0],
            data: leads,
          },
          {
            ...EMPTY_LEADS.datasets[1],
            data: tours,
          },
        ],
      };

      const moveInsExist = moveIns?.some((x) => x > 0);
      if (moveInsExist) {
        leadsDataset.datasets.push({
          label: capitalize(t('move_ins')),
          backgroundColor: 'rgba(242, 113, 115, 0.2)',
          borderColor: 'rgba(242, 113, 115, 1)',
          borderWidth: 1,
          minBarLength: 5,
          hoverBackgroundColor: 'rgba(242, 113, 115, 0.4)',
          hoverBorderColor: 'rgba(242, 113, 115, 1)',
          data: moveIns,
        });
      }

      setGraphs([userDataset, leadsDataset]);
    };

    const { labels = [], leads = [], tours = [], visitors = [], interactions = [], move_ins = [] } = data || {};
    prepareGraphData(labels, visitors, leads, tours, interactions, move_ins);
  }, [data]);

  return (
    <TimeSeriesChart
      loaderStatus={loaderStatus}
      data={graphs}
      title={`Visitors, ${capitalize(t('leads'))} and Requested ${capitalize(t('tours'))} Graph`}
    />
  );
};

export default OverviewGraph;
