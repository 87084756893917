import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUserContext } from 'components/UserContext';
import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import permissions from 'utils/permissions';
import { NavigationSection } from './NavigationSection';
import { NavigationItem } from './NavigationItem';

export const UserNavigationRegional = () => {
  const { t } = useTranslation();
  const { hasPermission } = useUserContext();

  return (
    <NavigationSection>
      <NavigationItem to="/stats" label="Stats" icon="auto_graph" />
      <NavigationItem to="/conversations" label="All Conversations" icon="textsms-outlined" />
      <NavigationItem to="/messaging" label="Messaging" icon="favorite_border" />
      <NavigationItem to="/communities" label={capitalize(t('communities'))} icon="holiday_village" />
      <If condition={hasPermission(permissions.MANAGEMENT_USER.FULL)}>
        <NavigationItem to="/management" label="Management" icon="key" />
      </If>
      <NavigationItem to="/advanced" label="Settings" icon="tune" />
    </NavigationSection>
  );
};
