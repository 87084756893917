import React, { useContext } from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Icon } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { ReactComponent as LogoType } from 'assets/images/new-logotype.svg';
import { ConversationContext } from '../ConversationContext';
import { UserContext } from '../../UserContext';

const Container = styled.div`
  width: fit-content;
  max-width: 700px;
  padding: ${(props) => (props.eventNote ? '20px 20px 8px' : '7px 20px 8px')};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: ${(props) => props.borderRadius || '16px'};
  margin: ${(props) => (props.eventNote ? '-10px auto 0' : '0 auto')};
  background-color: #ffffff;
  position: relative;
  z-index: ${(props) => (props.eventNote ? '0' : '1')};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Author = styled.div`
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(-text);
  margin-left: 10px;
`;

const EditNote = styled.div`
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--link);
  margin-left: 5px;
  cursor: pointer;
`;

const Time = styled.div`
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 10px;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #89919d;
  margin-left: auto;
  display: flex;
  align-items: center;
  > svg {
    top: 0;
    height: auto;
  }
`;

const Content = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  font-weight: 500;
  padding-top: 5px;
  padding-left: 25px;
`;

const FurtherLogoWrapper = styled.div`
  margin-right: 5px;
  margin-left: 5px;
  padding-top: 2px;
`;

const AddedNoteWrapper = styled.div`
  margin-left: 5px;
`;

export const LeadNote = ({ text, children }) => {
  const { setCurrentNote, setIsNoteModalOpen } = useContext(ConversationContext);
  const {
    content,
    created_on,
    author_name,
    is_further,
    title,
    icon,
    eventNote,
    borderRadius,
    author_id,
    hideAddedNote,
  } = text;
  const formattedTime = formatAnyDate(created_on, 'h:mm a');
  const author = !is_further ? author_name : 'Further’s Messaging Assistant';
  const { user } = useContext(UserContext);

  const handleEditNote = () => {
    setIsNoteModalOpen(true);
    setCurrentNote(text);
  };
  const authorText = title || author;
  return (
    <Container eventNote={eventNote} borderRadius={borderRadius}>
      <Header>
        <Icon name={icon || 'edit_note'} size="14" color="var(--vivid-blue)" />
        <Author>{authorText}</Author>
        <If condition={!hideAddedNote}>
          <AddedNoteWrapper>added a note</AddedNoteWrapper>
        </If>

        <If condition={!is_further && user?.id === author_id}>
          <EditNote onClick={handleEditNote}>Edit Note</EditNote>
        </If>
        <Time>
          <If condition={is_further}>
            <FurtherLogoWrapper>
              <LogoType width={10} height={10} />
            </FurtherLogoWrapper>
          </If>
          {formattedTime}
        </Time>
      </Header>
      <If condition={content}>
        <Content>{content}</Content>
      </If>
      {children}
    </Container>
  );
};
