import React, { useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { InputField } from 'components/common/fields/InputField';
import { useCRM } from './context';
import { OperationBox, OperationSign } from '../MappingRules';

const CRMSourceMappingLeadChannelSourceRule = ({ baseFieldName }) => {
  const [{ eligibleLeadSources }] = useCRM();

  const leadSourceOptions = useMemo(
    () =>
      Object.entries(eligibleLeadSources).map(([key, value]) => ({
        key,
        value,
        text: key,
      })),
    [eligibleLeadSources]
  );

  return (
    <>
      <OperationBox>
        <OperationSign>equals</OperationSign>
        <div>
          <InputField
            control={Dropdown}
            name={`${baseFieldName}.value`}
            placeholder="Channel Source Value"
            type="text"
            selection
            options={leadSourceOptions}
          />
        </div>
      </OperationBox>
      <OperationBox>
        <OperationSign>then</OperationSign>
        <div>
          <InputField placeholder="Result Value" name={`${baseFieldName}.result_value`} type="text" />
        </div>
      </OperationBox>
    </>
  );
};

export default CRMSourceMappingLeadChannelSourceRule;
