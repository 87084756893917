import { SelectDomainStep } from './SelectDomainStep';
import { DnsSetupStep } from './DnsSetupStep';
import { AddEmailAddressStep } from './AddEmailAddressStep';

export const STEPS = [
  {
    component: SelectDomainStep,
  },
  /*   {
    component: DnsSetupStep,
  }, */
  {
    component: AddEmailAddressStep,
    hasSave: true,
  },
];

export const MICROSOFT = 'microsoft';
export const GOOGLE = 'google';
export const FURTHER = 'further';
export const INVITABLE_PROVIDERS = [MICROSOFT, GOOGLE];

export const EMAIL_INTEGRATION_STATUS = {
  active: 'active',
  refresh_token_invalid: 'refresh_token_invalid',
  failure: 'failure',
  disabled: 'disabled',
  invited: 'invited',
  pending: 'pending',
  unassigned: 'unassigned',
};

export const EMAIl_INTEGRATION_VALUES = {
  active: {
    value: EMAIL_INTEGRATION_STATUS.active,
    text: 'Active',
    color: '#0ab776',
  },
  refresh_token_invalid: {
    value: EMAIL_INTEGRATION_STATUS.refresh_token_invalid,
    text: 'Refresh Token Invalid',
    color: '#89919d',
  },
  failure: {
    value: EMAIL_INTEGRATION_STATUS.failure,
    text: 'Failure',
    color: '#f44336',
  },
  disabled: {
    value: EMAIL_INTEGRATION_STATUS.disabled,
    text: 'Disabled',
    color: '#89919d',
  },
  invited: {
    value: EMAIL_INTEGRATION_STATUS.invited,
    text: 'Invited',
    color: '#89919d',
  },
  pending: {
    value: EMAIL_INTEGRATION_STATUS.pending,
    text: 'Pending',
    color: '#89919d',
  },
  unassigned: {
    value: EMAIL_INTEGRATION_STATUS.unassigned,
    text: 'Unassigned',
    color: '#f44336',
  },
};
