import React from 'react';
import { Field as FormikField, useFormikContext } from 'formik';
import { Dropdown, Form, Grid, Image, Input } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { uploadIcon } from 'api';
import { ColorPicker } from '../ColorPicker';
import { ImagePicker } from '../ImagePicker';
import { InputField } from '../../common/fields/InputField';
import { WrappedFontPicker } from '../../WrappedFontPicker';
import { previews } from '../../../assets/teaser-preview';
import { ToggleSwitch } from '../../common';
import { If } from '../../If';

const TEASER_VERSIONS = [
  {
    key: 1,
    text: 'Teaser 1',
    value: 1,
  },
  {
    key: 2,
    text: 'Teaser 2',
    value: 2,
  },
  {
    key: 3,
    text: 'Teaser 3',
    value: 3,
  },
  {
    key: 4,
    text: 'Teaser 4',
    value: 4,
  },
  {
    key: 5,
    text: 'Teaser 5',
    value: 5,
  },
];

const LabelContainerWithIcon = styled.label`
  &&&& {
    display: flex;
    span {
      margin-left: 5px;
    }
  }
`;

export const BrandingSection = () => {
  const { values, setFieldValue, errors } = useFormikContext();

  const handleTeaserVersionChange = (e, { value }) => {
    setFieldValue('teaser_version', value);
  };
  const onImageSelected = async (image) => {
    try {
      const resp = await uploadIcon(image);
      return resp.url;
    } catch (e) {
      console.error(e);
      toast.error('Error uploading image');
    }
  };

  const teaserPreview = previews[values?.teaser_version - 1];

  return (
    <Grid>
      <Grid.Row columns={4}>
        <Grid.Column>
          <ColorPicker name="brand_color" label="Brand color" />
        </Grid.Column>
        <Grid.Column>
          <ColorPicker name="background_color" label="Chat background" />
        </Grid.Column>
        <Grid.Column>
          <ColorPicker name="button_color" label="Button color" />
        </Grid.Column>
        <Grid.Column>
          <ColorPicker name="color" label="Chat text" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={3}>
        <Grid.Column width={4}>
          <InputField name="font_family" label="Enter font family" />
        </Grid.Column>
        <Grid.Column width={4}>
          <WrappedFontPicker name="font_family" label="Or choose one" className="six wide field" />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Field>
            <label>Font size</label>
            <FormikField
              as={Input}
              name="font_size"
              type="number"
              min="8"
              max="24"
              label={{ content: 'px' }}
              labelPosition="right"
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column mobile={16} tablet={6} largeScreen={6} widescreen={4}>
          <Form.Field>
            <label>Chat icon</label>
            <ImagePicker name="icon_logo" onImageSelected={onImageSelected} />
          </Form.Field>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={6} largeScreen={6} widescreen={4}>
          <Form.Field>
            <label>Backdrop image</label>
            <ImagePicker name="backdrop_image" onImageSelected={onImageSelected} onImageDelete={() => null} />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <Form.Field>
            <label>Teaser version</label>
            <Dropdown
              value={values.teaser_version}
              options={TEASER_VERSIONS}
              fluid
              search
              selection
              onChange={handleTeaserVersionChange}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Field>
            <label>Teaser version preview</label>
            <Image src={teaserPreview} alt="Teaser Preview" size="medium" bordered />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <Form.Field>
            <ToggleSwitch name="show_further_logo" label="Show Further logo" />
          </Form.Field>
        </Grid.Column>

        <Form.Field width={3}>
          <label>Buttons border radius</label>
          <FormikField
            as={Input}
            name="buttons_border_radius"
            type="number"
            min="0"
            max="20"
            label={{ content: 'px' }}
            labelPosition="right"
          />
          <If condition={errors.buttons_border_radius}>
            <div className="ui pointing above prompt label" role="alert" aria-atomic="true">
              Required
            </div>
          </If>
        </Form.Field>
      </Grid.Row>
    </Grid>
  );
};
