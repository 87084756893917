import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Accordion, Icon, Menu, Popup } from 'semantic-ui-react';

import { If } from '../../If';
import MappingRules from '../MappingRules';
import { SOURCE_MAPPING_RULES } from './constants';

const AccordionBox = styled.div`
  padding: 0 1rem;
`;

const SectionTitle = styled.span`
  padding-right: 5px;
  color: ${(props) => (props?.hasSourceMappingRulesSupport ? '#222' : '#a6a6a6')};
`;

const CRMSourceMappingRules = ({ hasSourceMappingRulesSupport, sourceMappingRules, setFieldValue }) => {
  const [openRulesSection, setOpenRulesSection] = useState(false);

  useEffect(() => {
    if (!hasSourceMappingRulesSupport) {
      setOpenRulesSection(false);
    }
  }, [hasSourceMappingRulesSupport]);

  const toggleRulesSection = () => {
    if (!hasSourceMappingRulesSupport) return;
    setOpenRulesSection(!openRulesSection);
  };

  return (
    <>
      <Accordion fluid as={Menu} vertical>
        <Accordion.Title active={openRulesSection} onClick={toggleRulesSection}>
          <AccordionBox>
            <Icon name="dropdown" />
            <SectionTitle hasSourceMappingRulesSupport={hasSourceMappingRulesSupport}>
              CRM Source Mapping Rules
            </SectionTitle>
            <If condition={!hasSourceMappingRulesSupport}>
              <Popup
                hoverable
                trigger={<Icon name="info" color="blue" size="small" link circular />}
                content="Custom source mapping rules are not supported."
                position="bottom center"
                style={{ zIndex: 10000 }}
              />
            </If>
          </AccordionBox>
        </Accordion.Title>
        <Accordion.Content active={openRulesSection}>
          <AccordionBox>
            <MappingRules fieldName="source_mapping_rules" ruleTypes={SOURCE_MAPPING_RULES} />
          </AccordionBox>
        </Accordion.Content>
      </Accordion>
    </>
  );
};

export default CRMSourceMappingRules;
