import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { parseErrorResponse } from 'apis/utils';
import { getLeadProcessing } from 'apis';
import { formatAnyDate } from 'components/utils';
import { Loader } from 'components/lib';
import {
  LEAD_PROCESSING_ACTION,
  LEAD_PROCESSING_ACTION_STATUS,
  LEAD_PROCESSING_ACTION_DISPLAY,
} from '../../../constants';

const STATUS_COLOR = {
  [LEAD_PROCESSING_ACTION_STATUS.PENDING]: 'var(--link)',
  [LEAD_PROCESSING_ACTION_STATUS.COMPLETED]: 'var(--emerald-green)',
  [LEAD_PROCESSING_ACTION_STATUS.FAILED]: 'var(--warning)',
};

const ActionRow = styled.div`
  display: flex;
  padding: 8px 30px;
  border-bottom: solid 1px #eaeaeb;
  font-family: var(--font-family);
  flex-direction: column;

  .row-1 {
    display: flex;
    justify-content: space-between;
  }

  .row-2 {
    font-size: 12px;
  }

  &:nth-child(odd) {
    background-color: #fcfcfc;
  }
`;

const ActionName = styled.div`
  color: ${(props) => STATUS_COLOR[props.status]};
`;

const ExecutedAt = styled.div`
  font-family: var(--font-family);
  font-size: 10px;
  flex: 0 0 auto;
`;

const NoResults = styled.div`
  padding: 8px 30px;
  color: var(--text-color);
`;

const Processing = styled.div`
  text-align: center;
  padding: 10px 0;
`;

const LeadProcessing = ({ leadId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState();

  useEffect(() => {
    const fetchProcessing = async () => {
      try {
        setLoading(true);
        const { results } = await getLeadProcessing(leadId);
        setProcessing(results);
      } catch (e) {
        toast.error(parseErrorResponse(e, 'Error fetching processing events'));
      } finally {
        setLoading(false);
      }
    };

    fetchProcessing();
  }, [leadId]);

  if (loading) {
    return <Loader active />;
  }

  if (!processing) {
    return null;
  }

  if (processing.length === 0) {
    return <NoResults>No processing events</NoResults>;
  }

  return (
    <div>
      {processing.map((p) => (
        <div key={p.id}>
          <Processing>{formatAnyDate(p.createdOn, 'MMMM d, yyyy')}</Processing>
          <div>
            {p.actions
              .filter((a) => a.actionType !== LEAD_PROCESSING_ACTION.PREPROCESS)
              .map((a) => (
                <ActionRow key={a.id}>
                  <div className="row-1">
                    <ActionName status={a.status}>{LEAD_PROCESSING_ACTION_DISPLAY(t)[a.actionType]}</ActionName>
                    <ExecutedAt>{formatAnyDate(a.executedAt, 'MMMM d hh:mm a')}</ExecutedAt>
                  </div>
                  {a.message && <div className="row-2">{a.message}</div>}
                </ActionRow>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LeadProcessing;
