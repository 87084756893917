import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Sticky, Checkbox } from 'semantic-ui-react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { InstantAnswersContext } from './InstantAnswersContext';
import { InstantAnswersHeaderControls } from './InstantAnswersHeaderControls';
import { toggleIA } from '../../../apis';
import { STATUSES } from '../../../constants';

const StyledHeader = styled.div`
  && {
    > .ui.header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
    }
  }
`;

const HeaderControls = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const InstantAnswersHeader = () => {
  const { community_id } = useParams();
  const { dirty } = useFormikContext();
  const { wrapperRef, community, setLoaderStatus } = useContext(InstantAnswersContext);
  const [useInstantAnswers, setUseInstantAnswers] = useState(false);

  useEffect(() => {
    setUseInstantAnswers(community?.use_instant_answers);
  }, [community]);

  const onInstantAnswersToggle = async (e, { checked }) => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      const response = await toggleIA(community_id, { use_instant_answers: checked });
      setUseInstantAnswers(response.use_instant_answers);

      toast.success('Updating Instant Answers successful.');
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      setLoaderStatus(STATUSES.FAILURE);
      toast.error('Error updating Instant Answers settings. Please try again later.');
    }
  };

  return (
    <>
      <Sticky context={wrapperRef}>
        <StyledHeader>
          <Header as="h4" block>
            <Link to="/settings/ia">
              <Icon name="arrow left" color="blue" />
              Back to communities
            </Link>
            <HeaderControls>
              <Checkbox
                toggle
                label="Enable Instant Answers"
                checked={useInstantAnswers}
                onChange={onInstantAnswersToggle}
              />
              <Button floated="right" primary type="submit" content="Save" disabled={!dirty} />
            </HeaderControls>
          </Header>
        </StyledHeader>
      </Sticky>

      <InstantAnswersHeaderControls />
    </>
  );
};
