import React from 'react';
import styled from 'styled-components';

import { useFormikContext } from 'formik';

import { InputField } from 'components/common';
import { Dropdown, Input } from 'components/lib';
import { QUERY_SELECTOR_OPTIONS } from '../constants';

const WebSelectorContainer = styled.div`
  display: flex;
  padding: 30px 26px 0px;
  align-items: center;
  gap: 10px;

  &.disabled {
    opacity: 0.3;
  }

  > .label {
    font-size: 14px;
    font-weight: 900;
    color: var(--text);
  }
  > .commands {
    display: flex;
    align-items: center;

    > div:first-child {
      > div {
        min-width: 100px !important;
      }
    }

    > div:last-child {
      > div {
        min-width: 280px;
      }
    }
  }
`;

export const WebSelectorField = ({ disabled, inputFieldName, dropdownFieldName, globalQuerySelector }) => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <WebSelectorContainer className={disabled ? 'disabled' : ''}>
      <div className="label">Web selector</div>
      <div className="commands">
        <InputField
          control={Dropdown}
          basic
          fluid
          selection
          name={dropdownFieldName}
          options={QUERY_SELECTOR_OPTIONS}
          size="small"
          onChanged={(value) => {
            setFieldValue('querySelector', (value ? globalQuerySelector : '') || '');
          }}
          disabled={disabled}
        />
        <InputField control={Input} name={inputFieldName} disabled={values[dropdownFieldName] || disabled} />
      </div>
    </WebSelectorContainer>
  );
};
