import React, { useContext } from 'react';
import { Conversation } from './Conversation';
import { ModalFullscreen } from '../lib';
import { useCheckMobileScreen } from '../../utils/hooks';
import { ConversationContext } from './ConversationContext';

export const ConversationModes = () => {
  const { selectedConversation, onModalClose } = useContext(ConversationContext);
  const isMobile = useCheckMobileScreen();

  if (!isMobile) return <Conversation />;

  return (
    <ModalFullscreen open={selectedConversation && isMobile} onClose={onModalClose}>
      <Conversation />
    </ModalFullscreen>
  );
};
