import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Dimmer, Loader } from 'semantic-ui-react';
import { getInstantResponsesMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PageSection } from 'components/common';
import { If } from 'components/If';
import { useSystemStatsFilters } from './SystemStatsFiltersContext';
import SalesAssistantBasicStats from './SalesAssistantBasicStats';

const NurtureOnlyReport = () => {
  const [metrics, setMetrics] = useState();
  const [prevMetrics, setPrevMetrics] = useState();
  const [loading, setLoading] = useState(false);
  const { dateRange, getPreviousDateRange, selectedUsers, selectedCommunities } = useSystemStatsFilters();

  useEffect(() => {
    const fetchMetrics = async (dateRange, onMetricsLoaded) => {
      try {
        setLoading(true);
        const params = {
          ...dateRange,
          messaging_assistant_enabled: false,
        };
        if (selectedUsers && selectedUsers.length) {
          params.sa_users = selectedUsers.join(',');
        }
        if (selectedCommunities && selectedCommunities.length) {
          params.sa_communities = selectedCommunities.join(',');
        }
        const _metrics = await getInstantResponsesMetrics(params);
        onMetricsLoaded(_metrics);
      } catch (e) {
        toast.error(parseErrorResponse(e, 'Error fetching messaging campaign metrics'));
      }
      setLoading(false);
    };

    fetchMetrics(dateRange, setMetrics);
    // fetch stats for previous period
    fetchMetrics(getPreviousDateRange(), setPrevMetrics);
  }, [dateRange, selectedUsers, selectedCommunities]);

  return (
    <div>
      <Dimmer active={!metrics || loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <If condition={metrics}>
        <PageSection>
          <SalesAssistantBasicStats metrics={metrics} prevMetrics={prevMetrics} />
        </PageSection>
      </If>
    </div>
  );
};

export default NurtureOnlyReport;
