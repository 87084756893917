import React, { useRef, useEffect, useState } from 'react';

import { getLeadLocationMap } from 'apis';
import { If } from 'components/If';
import { DetailsRowValue, DetailsRow } from './helpers';
import { roundTo } from '../../utils';
import { useIntersection } from '../../../utils/hooks/useIntersection';

const roundToTwo = (num) => roundTo(num, 2);

export const Location = ({ leadId, data = {} }) => {
  const { location, map_img, distance } = data;
  const [mapUrl, setMapUrl] = useState(map_img);
  const wrapperRef = useRef();
  const inViewport = useIntersection(wrapperRef, '0px');
  useEffect(() => {
    if (!map_img && inViewport) {
      getLeadLocationMap(leadId).then(({ mapImg }) => setMapUrl(mapImg));
    }
  }, [inViewport, map_img, setMapUrl, leadId]);

  return (
    <>
      <div ref={wrapperRef}>
        <DetailsRow>
          <span>City</span>
          <DetailsRowValue>{location?.city || '-'}</DetailsRowValue>
        </DetailsRow>
        <DetailsRow className="dense">
          <span>Zip</span>
          <DetailsRowValue>{location?.postal_code || '-'}</DetailsRowValue>
        </DetailsRow>
        <DetailsRow className="dense">
          <span>Country</span>
          <DetailsRowValue>{location?.country_name || '-'}</DetailsRowValue>
        </DetailsRow>
        <If condition={distance}>
          <DetailsRow className="dense">
            <span>Distance from community</span>
            <DetailsRowValue>
              {roundToTwo(distance?.miles)}mi / {roundToTwo(distance?.km)}km
            </DetailsRowValue>
          </DetailsRow>
        </If>

        <If condition={mapUrl}>
          <DetailsRow className="dense">
            <img src={mapUrl} alt="Map" />
          </DetailsRow>
        </If>
      </div>
    </>
  );
};
