import React from 'react';
import { Link } from 'react-router-dom';

import { getConversationUrl } from 'utils/helpers';
import { formatAnyDate } from 'components/utils';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { Button } from 'components/lib';

const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';

const DateTimeCell = styled.div`
  white-space: nowrap;
`;

const LongTextCell = styled(Table.Cell)`
  word-break: break-word;
`;

const ActionsCell = styled(Table.Cell)`
  div {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
  }
`;

const ReviewRow = styled(Table.Row)`
  cursor: pointer;
`;

export const AutomationReviewList = ({ reviews, onAccept, onDecline }) => {
  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Message Date</Table.HeaderCell>
        <Table.HeaderCell>Community</Table.HeaderCell>
        <Table.HeaderCell>Message</Table.HeaderCell>
        <Table.HeaderCell singleLine>Used Labels</Table.HeaderCell>
        <Table.HeaderCell>Response</Table.HeaderCell>
        <Table.HeaderCell>Actions</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {reviews?.map((review, idx) => (
          <ReviewRow key={idx} onClick={() => window.open(getConversationUrl(review.lead_id), '_blank')}>
            <Table.Cell collapsing>
              <DateTimeCell>{formatAnyDate(review.created_on, DATE_TIME_FORMAT)}</DateTimeCell>
            </Table.Cell>
            <Table.Cell collapsing singleLine>
              {review.community_name}
            </Table.Cell>
            <LongTextCell>{review.message}</LongTextCell>
            <Table.Cell collapsing singleLine>
              {review.used_labels.join(', ')}
            </Table.Cell>
            <LongTextCell>{review.response?.text}</LongTextCell>
            <ActionsCell collapsing>
              <div>
                <Button
                  className="link"
                  color="red"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDecline?.(review);
                  }}
                >
                  Decline
                </Button>
                <Button
                  className="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAccept?.(review);
                  }}
                >
                  Accept
                </Button>
              </div>
            </ActionsCell>
          </ReviewRow>
        ))}
      </Table.Body>
    </Table>
  );
};
