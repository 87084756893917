import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { getPhoneCallDetails } from 'apis';
import { AudioPlayer, Icon, Loader } from 'components/lib';

const Container = styled.div.attrs(({ playerOffset }) => ({
  style: {
    left: `calc(50% - ${playerOffset}px)`,
  },
}))`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 18px;
  border: solid 1px var(--emerald-green);
  background-color: #eefff9;
  padding: 0 3px 0 4px;
`;
const PlayerContainer = styled.div`
  display: flex;
  position: fixed;
  padding: 6px 10px;
  height: 36px;
  background: #fff;
  border-radius: 18px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.12);
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5000;
  align-items: center;
`;

const CloseIcon = styled(Icon)`
  margin-left: 10px;
`;

export const AudioPlayPauseWidget = ({
  url,
  duration,
  color = 'var(--emerald-green)',
  text,
  callId,
  playerOffset = 0,
  forceOpen = false,
}) => {
  const [playerVisible, setPlayerVisible] = useState(false);
  const [playing, setPlaying] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [localUrl, setLocalUrl] = useState(url);
  const [localDuration, setLocalDuration] = useState(duration);

  const handlePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleClose = (e) => {
    handlePropagation(e);

    setPlaying(false);
    setTimeout(() => {
      setPlayerVisible(false);
    }, 0);
  };

  const handlePlayerOpen = async (e) => {
    setLoading(true);
    setPlayerVisible(true);
    e.preventDefault();
    try {
      const response = await getPhoneCallDetails(callId);
      const { callDurationSeconds, recordingUrl } = response;

      if (!recordingUrl) {
        toast.error('Recording is not playable in the dashboard');
        setLoading(false);
        setPlayerVisible(false);
        setPlaying(false);
        return;
      }

      if (forceOpen) {
        setLoading(false);
        setPlayerVisible(false);
        setPlaying(false);
        window.open(recordingUrl, '_blank');
        return;
      }

      if (callDurationSeconds) {
        setLocalUrl(recordingUrl);
        setLocalDuration(callDurationSeconds);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error while fetching call details');
      setLoading(false);
    }
  };

  const togglePlay = (e) => {
    handlePropagation(e);
    if (!playerVisible) {
      handlePlayerOpen(e);
    }
    if (playing) {
      setPlaying(false);
    } else {
      setPlaying(true);
    }
  };

  const statusIcon = playing ? 'pause' : 'play_arrow';
  const backgroundColor = playing ? color : '#eefff9';
  const iconColor = playing ? '#fff' : color;

  return (
    <>
      <Container onClick={togglePlay} style={{ backgroundColor }}>
        <Icon name="phone" color={iconColor} size="14" />
        <Icon name={statusIcon} color={iconColor} size="14" />
      </Container>

      {playerVisible && (
        <PlayerContainer onClick={handlePropagation} playerOffset={playerOffset}>
          {!loading ? (
            <AudioPlayer
              url={localUrl}
              duration={localDuration}
              text={text}
              color={color}
              overRidePlayPause={playing}
              outSideState={setPlaying}
            />
          ) : (
            <Loader size="20" active />
          )}
          <CloseIcon name="close" size="24px" color="#89919d" onClick={handleClose} />
        </PlayerContainer>
      )}
    </>
  );
};
