import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Statistic, Table, Message } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { getInstantAnswersAnalytics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { capitalize, queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { PageSection, CsvExport } from '../../common';

const InstantAnswersAnalytics = ({ filters, publicId }) => {
  const { t } = useTranslation();
  const [iaAnalytics, setIaAnalytics] = useState();
  const { data, status, error, isFetching } = useQuery(
    [queryConstants.INSTANT_ANSWERS_ANALYTICS, { publicId, filters }],
    () => getInstantAnswersAnalytics({ public_id: publicId, filters })
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch instant answers data'));
  }

  useEffect(() => {
    if (data) {
      setIaAnalytics(data.ia_analytics);
    }
  }, [data]);

  return (
    <PageSection
      title="Instant Answers Analytics"
      loaderStatus={loaderStatus}
      actions={
        iaAnalytics && (
          <div>
            <Statistic label="Answered Questions" value={`${iaAnalytics.answer_percentage?.toFixed(1)}%`} size="mini" />
            <Statistic
              label="User Engagement"
              value={`${iaAnalytics.ia_engagement_percentage?.toFixed(1)}%`}
              size="mini"
            />
            <Statistic label={`Requested ${capitalize(t('tours'))}`} value={iaAnalytics.tours_booked} size="mini" />
          </div>
        )
      }
    >
      {iaAnalytics && (
        <Table className="fixed-size">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Label</Table.HeaderCell>
              <Table.HeaderCell>Count</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {Object.entries(iaAnalytics?.most_used_labels || {}).map(([label, count], i) => (
              <Table.Row key={i}>
                <Table.Cell>{label}</Table.Cell>
                <Table.Cell>{count}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={8} textAlign="right">
                <CsvExport
                  data={Object.entries(iaAnalytics?.most_used_labels || {})}
                  headers={['Label', 'Count']}
                  filename="instant_answers_analytics.csv"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}

      {!iaAnalytics && (
        <Message>
          <Message.Header>Instant Analytics not Enabled</Message.Header>
          <p>
            No analytics available for Instant Answers because this feature is not enabled in any of the existing chats.
          </p>
        </Message>
      )}
    </PageSection>
  );
};

export default InstantAnswersAnalytics;
