import React from 'react';
import styled from 'styled-components';

import { ACTION_TYPE, ACTION_TYPE_NAME } from './constants';

const Container = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const Title = styled.div`
  font-size: 14px;
`;

const SubTitle = styled.div`
  font-size: 12px;
`;

export const BusinessActivityAction = ({ actionType, contentObjectName }) => {
  const title = ACTION_TYPE_NAME[actionType] || '-';
  const subtitle = contentObjectName;
  const isNotSupportMode = ACTION_TYPE.SUPPORT_MODE !== actionType;

  return (
    <Container>
      <Title>{title}</Title>
      {isNotSupportMode && <SubTitle>{subtitle}</SubTitle>}
    </Container>
  );
};
