import React, { useMemo } from 'react';
import styled from 'styled-components';

import { If } from 'components/If';

export const RangeInputContainer = styled.div`
  position: relative;
  margin-bottom: 62px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  input[type='range'] {
    background: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    width: 100%;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -11px;
    height: 23px;
    width: 23px;
    border-radius: 50%;
    background-color: var(--vivid-blue);
    outline: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px #fff;
  }

  input[type='range']::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--vivid-blue);
    outline: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px #fff;
    margin-top: -1px;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 4px;
    border-radius: 2px;
    background: linear-gradient(
      90deg,
      var(--vivid-blue) 0%,
      var(--vivid-blue) var(--value-progress),
      #f7f7f7 var(--value-progress)
    );
  }

  input[type='range']::-moz-range-progress {
    height: 4px;
    background-color: var(--vivid-blue);
    border-radius: 2px;
  }

  input[type='range']::-moz-range-track {
    height: 4px;
    background-color: #f7f7f7;
    border-radius: 2px;
  }
`;

const FloatingValue = styled.div`
  position: absolute;
  left: var(--value-progress);
  transform: translateX(-50%);
  margin-top: 14px;
  padding: 7px 12.5px;

  display: flex;
  flex-direction: column;
  text-align: center;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #fff;

  .value {
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: 16px;
  }

  .label {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
`;

export const RangeInput = ({ className, hideFloatingValue = false, disabled, ...inputProps }) => {
  const variables = useMemo(
    () => ({
      '--value-progress': inputProps.value ? `${(inputProps.value / inputProps.max) * 100}%` : 0,
    }),
    [inputProps.value]
  );
  return (
    <RangeInputContainer className={className} style={variables} disabled={disabled}>
      <input type="range" {...inputProps} />
      <If condition={!hideFloatingValue}>
        <FloatingValue>
          <span className="value">{inputProps.value}%</span>
          <span className="label">of price</span>
        </FloatingValue>
      </If>
    </RangeInputContainer>
  );
};
