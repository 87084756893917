import React from 'react';

import { ACTION_TYPE } from './constants';
import { CampaignCommunityChanged } from './change-modal-content/CampaignCommunityChanged';
import { ChatPropertyAssigmentChanged } from './change-modal-content/ChatPropertyAssigmentChanged';
import { LeadSubmitToCRMManual } from './change-modal-content/LeadSubmitToCRMManual';

const BusinessActivityChangeModalContent = ({ businessActivity }) => {
  const { actionType } = businessActivity;

  switch (actionType) {
    case ACTION_TYPE.CAMPAIGN_COMMUNITIES_ADD:
    case ACTION_TYPE.CAMPAIGN_COMMUNITIES_REMOVE:
      return <CampaignCommunityChanged businessActivity={businessActivity} />;
    case ACTION_TYPE.CHAT_COMMUNITIES_ADD:
      return <ChatPropertyAssigmentChanged businessActivity={businessActivity} />;
    case ACTION_TYPE.LEAD_SUBMIT_TO_CRM_MANUAL:
      return <LeadSubmitToCRMManual businessActivity={businessActivity} />;
    default:
      return null;
  }
};

export default BusinessActivityChangeModalContent;
