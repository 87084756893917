import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

export const TourCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px #1f57bd;
  background-color: rgba(31, 87, 189, 0.02);
  font-size: 18px;
  margin-bottom: 100px;
  text-align: center;
  padding: 40px;
`;

const LabelWrapper = styled.div`
  display: flex;
  color: rgba(25, 25, 25, 0.86);
`;

export const TourNotFound = () => (
  <TourCard>
    <LabelWrapper>
      <Icon name="info circle" size="large" color="blue" />
      <p>Calendar request not found</p>
    </LabelWrapper>
  </TourCard>
);
