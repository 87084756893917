import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

const Label = styled.div`
  font-family: var(--font-family);
  font-size: 16px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
`;

const Text = styled.div`
  font-family: var(--font-family);
  font-size: 21px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  max-width: 605px;
  word-wrap: break-word;
`;

export const MyProfileField = ({ label, text }) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Text>{text}</Text>
    </Container>
  );
};
