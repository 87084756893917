import React from 'react';
import { Tab } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { capitalize } from 'utils/helpers';
import CRMSettings from './CRMSettings';
import CRMCommunity from './CRMCommunity';
import { useCRM, setActiveTab } from './context';

const CRMTabs = () => {
  const { t } = useTranslation();
  const [{ activeTab }, dispatch] = useCRM();

  const handleTabChange = (e, { activeIndex }) => {
    dispatch(setActiveTab(activeIndex));
  };

  const panes = [
    {
      menuItem: 'CRM Settings',
      render: () => (
        <Tab.Pane as="div">
          <CRMSettings />
        </Tab.Pane>
      ),
    },
    {
      menuItem: capitalize(t('communities')),
      render: () => (
        <Tab.Pane as="div">
          <CRMCommunity />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      activeIndex={activeTab}
      panes={panes}
      onTabChange={handleTabChange}
    />
  );
};

export default CRMTabs;
