import React, { useContext } from 'react';
import styled from 'styled-components';

import { Modal, Button } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { ConversationContext } from '../ConversationContext';
import { CONVERSATION_ACTIONS } from './constants';
import { ForwardedCallTranscript } from '../chat/ForwardedCallTranscript';
import { ConversationModal, ConversationModalHeader } from './ConversationModal';

const StyledModal = styled(ConversationModal)`
  max-height: 500px;
`;

const ModalHeader = styled(ConversationModalHeader)`
  &&&& {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: 900;
    color: var(--text);
    border: none;
  }
`;

const ModalSubHeader = styled.div`
  &&&& {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.43;
  }
`;

export const CallInfoModal = () => {
  const { activeModal, selectedForwardedCall, selectedConversation, showModal } = useContext(ConversationContext);

  if (!selectedConversation) return null;

  if (!selectedForwardedCall) return null;

  return (
    <StyledModal
      open={activeModal === CONVERSATION_ACTIONS.CALL_INFO}
      size="small"
      closeIcon
      onClose={() => showModal(null)}
    >
      <Modal.Header>
        <ModalHeader>Call Info</ModalHeader>
        <ModalSubHeader>
          <p>
            {selectedConversation.name}, {formatAnyDate(selectedForwardedCall.start_time, 'MMM d, yyyy h:mm a')}
          </p>
        </ModalSubHeader>
      </Modal.Header>

      <Modal.Content>
        <ForwardedCallTranscript
          transcript={selectedForwardedCall.transcript}
          summary={selectedForwardedCall.transcript_summary}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button basic onClick={() => showModal(null)}>
          Close
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
};
