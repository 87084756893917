import React, { useState } from 'react';
import styled from 'styled-components';

import { Modal as ComponentsModal } from 'components/lib';
import { STEPS } from './constants';

const Modal = styled(ComponentsModal)`
  && {
    & > .header {
      border-bottom: none;
    }
    &.tiny,
    &.large {
      width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
      height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
    }
  }
`;

export const AddEmailAddressModal = ({ isModalOpen, onClose, fetchEmails, filters, emailDomains }) => {
  const [state, setState] = useState({
    currentStep: 1,
    wizardValues: {},
  });

  const handleClose = () => {
    onClose();
    setState({
      currentStep: 1,
      wizardValues: {},
    });
  };

  const onNextClick = (values) => {
    setState((prevState) => ({
      ...prevState,
      currentStep: prevState.currentStep + 1,
      wizardValues: {
        ...prevState.wizardValues,
        ...values,
      },
    }));
  };

  const Step = STEPS[state.currentStep - 1]?.component || null;
  return (
    <Modal open={isModalOpen} closeIcon onClose={handleClose} size="tiny" width={540} height={502}>
      <Step
        state={state}
        onNextClick={onNextClick}
        handleClose={handleClose}
        fetchEmails={fetchEmails}
        filters={filters}
        emailDomains={emailDomains}
      />
    </Modal>
  );
};
