import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import humps from 'humps';

import { addManagementUser } from 'apis/ManagementAPI';
import { parseErrorResponse, parseFieldErrors } from 'apis/utils';
import { Form, Modal as ComponentsModal, Button, Dropdown, Checkbox } from 'components/lib';
import { MARKET_TYPE, MARKET_TYPE_LABEL } from '../../constants';
import { ManagementInputField } from './ManagementInputField';

const Modal = styled(ComponentsModal)`
  && {
    & > .header {
      border-bottom: none;
    }
    &.tiny,
    &.large {
      width: 541px;
      height: 421px;
    }
  }
`;

const HeaderTitle = styled.div`
  font-family: var(--font-family);
  font-weight: 900; // override semantic-ui inherited font-weight: 700;
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const HeaderSubtitle = styled.div`
  font-family: var(--font-family);
  font-weight: 500; // override semantic-ui inherited font-weight: 700;
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  margin-top: 11px;
`;

const Content = styled.div`
  padding: 0 30px 30px 30px;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  border-top: 1px solid var(--line-standard);
  padding: 0 30px;
  margin-top: 28px;
`;

const StyledDropdown = styled(Dropdown)`
  &&&& {
    min-width: auto;
    width: 398px !important;
  }
`;

const validationSchema = Yup.object({
  user: Yup.object({
    email: Yup.string()
      .email('Must be a valid email address')
      .max(150, `Can't be longer than 150 characters`)
      .required('Required'),
    firstName: Yup.string().max(30, `Can't be longer than 30 characters`).required('Required'),
    lastName: Yup.string().max(150, `Can't be longer than 150 characters`).required('Required'),
    phone: Yup.string().max(20, `Can't be longer than 20 characters`).nullable(),
    marketType: Yup.number().required('Required'),
  }),
  sendInvite: Yup.boolean(),
});

const TEMP_MARKET_TYPES_OPTIONS = Object.keys(MARKET_TYPE).map((key) => {
  return {
    key,
    text: MARKET_TYPE_LABEL[MARKET_TYPE[key]],
    value: MARKET_TYPE[key],
  };
});

export const ManagementAddUserModal = ({ open, onClose, updateManagementUsersList }) => {
  const [isLoading, setIsLoading] = useState();

  const update = async (values, setErrors) => {
    try {
      setIsLoading(true);
      const response = await addManagementUser(values);
      setIsLoading(false);
      onClose();
      toast.success('User added successfully!');
      updateManagementUsersList(response);
    } catch (e) {
      setErrors(humps.camelizeKeys(parseFieldErrors(e)));
      toast.error(parseErrorResponse(e, 'Unable to add user!'));
    } finally {
      setIsLoading(false);
    }
  };
  const initialValues = {
    user: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      marketType: 2,
    },
    sendInvite: true,
  };
  const onSubmit = async (values, { setErrors, resetForm }) => {
    update(values, setErrors, resetForm);
  };

  const onCancel = (resetForm) => () => {
    resetForm();
    onClose();
  };
  return (
    <Modal open={open} closeIcon size="large" onClose={onClose}>
      <Modal.Header>
        <HeaderTitle>Add User</HeaderTitle>
        <HeaderSubtitle>Enter user information below.</HeaderSubtitle>
      </Modal.Header>
      <Modal.Content>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ dirty, handleSubmit, touched, isValid, resetForm, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Content>
                <ManagementInputField
                  label="First Name"
                  name="user.firstName"
                  placeholder="First Name"
                  value={values.user.firstName}
                  validateOnKeyUp
                />
                <ManagementInputField
                  label="Last Name"
                  name="user.lastName"
                  placeholder="Last Name"
                  value={values.user.lastName}
                  validateOnKeyUp
                />
                <ManagementInputField
                  label="Email"
                  name="user.email"
                  placeholder="Email"
                  value={values.user.email}
                  validateOnKeyUp
                />
                <ManagementInputField
                  label="Phone"
                  name="user.phone"
                  placeholder="Add phone number"
                  value={values.user.phone}
                  validateOnKeyUp
                />
                <ManagementInputField
                  label="Market Type"
                  control={StyledDropdown}
                  name="user.marketType"
                  selection
                  options={TEMP_MARKET_TYPES_OPTIONS}
                  value={values.user.marketType}
                />
                <ManagementInputField
                  label="Send invite"
                  control={Checkbox}
                  name="sendInvite"
                  checked={!!values.sendInvite}
                />
              </Content>
              <Actions>
                <Button
                  primary
                  color="blue"
                  type="submit"
                  disabled={!dirty || !touched || !isValid}
                  loading={isLoading}
                >
                  Save
                </Button>
                <Button primary type="button" onClick={onCancel(resetForm)}>
                  Cancel
                </Button>
              </Actions>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
