import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { InformantBox } from '../common';

export const TableHeaderRow = () => {
  const { t } = useTranslation();
  return (
    <>
      <Grid.Row className="header no-padding">
        <Grid.Column width={7}>{capitalize(t('communities'))}</Grid.Column>
        <Grid.Column width={3}>Sales Name</Grid.Column>
        <Grid.Column width={3}>Sales Email</Grid.Column>
        <Grid.Column width={3}>Sales Phone</Grid.Column>
      </Grid.Row>
      <Grid.Row className="no-padding info-box">
        <Grid.Column width={16} className="no-padding">
          <InformantBox text="Contact info is missing for:" />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
