import React, { useContext } from 'react';
import styled from 'styled-components';

import { Accordion } from '../../lib/Accordion';
import { ConversationContext } from '../ConversationContext';
import { CRMResult } from './CRMResult';
import { TechnicalDetails } from './TechnicalDetails';
import { Location } from './Location';
import { GeneralDetails } from './GeneralDetails';

const StyledAccordion = styled(Accordion)`
  &&&&& {
    border-top: none;

    .title {
      margin-top: 30px;
    }

    .title:first-child {
      margin-top: 0;
    }
  }
`;

export const LeadPanels = () => {
  const { selectedConversation } = useContext(ConversationContext);

  const { details } = selectedConversation || {};
  const { crm_result } = details || {};

  const panels = [
    {
      key: 'general',
      title: 'General',
      content: {
        content: <GeneralDetails />,
      },
    },
    {
      key: 'technical_details',
      title: 'Technical Details',
      content: {
        content: <TechnicalDetails data={details} />,
      },
    },
    {
      key: 'location',
      title: 'Location (geoip)',
      content: {
        content: <Location data={details} leadId={selectedConversation.id} />,
      },
    },
    {
      key: 'crm_result',
      title: 'CRM Result',
      content: {
        content: <CRMResult data={crm_result} />,
      },
    },
  ];

  return <StyledAccordion fluid defaultActiveIndex={[0, 1]} panels={panels} exclusive={false} />;
};
