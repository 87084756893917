import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { CommunityNotes } from './CommunityNotes';
import { CommunityPricing } from './CommunityPricing';
import { CommunityStatistics } from './CommunityStatistics';
import { Accordion } from '../../lib/Accordion';
import { CommunityPanelTitle } from './CommunityPanelTitle';
import { UserContext } from '../../UserContext';
import { USER_TYPES } from '../../../constants';

export const PANEL_MODE = {
  PREVIEW: 'preview',
  EDIT: 'edit',
};

const EditAction = styled.div`
  font-family: var(--font-family);
  color: var(--link);
`;

const StyledAccordion = styled(Accordion)`
  .title {
    &:not(.active) {
      ${EditAction} {
        display: none;
      }
    ]
  }
`;

export const CommunityPanels = () => {
  const { user } = useContext(UserContext);
  const [notesMode, setNotesMode] = useState(PANEL_MODE.PREVIEW);

  const onEditClick = (e) => {
    e.stopPropagation();
    setNotesMode(PANEL_MODE.EDIT);
  };

  const onAction = () => {
    setNotesMode(PANEL_MODE.PREVIEW);
  };

  const notesAction = notesMode === PANEL_MODE.PREVIEW ? <EditAction onClick={onEditClick}>Edit</EditAction> : null;
  const panels = [
    {
      key: 'pricing',
      title: 'Pricing',
      content: {
        content: <CommunityPricing />,
      },
    },
  ];

  if (user.type === USER_TYPES.STAFF) {
    panels.unshift({
      key: 'notes',
      title: {
        content: <CommunityPanelTitle title="Notes" action={notesAction} />,
      },
      content: {
        content: <CommunityNotes mode={notesMode} onAction={onAction} />,
      },
    });
    panels.push({
      key: 'stats',
      title: 'Stats (30 days)',
      content: {
        content: <CommunityStatistics />,
      },
    });
  }

  if (user.type === USER_TYPES.STAFF) {
  }

  return <StyledAccordion fluid defaultActiveIndex={[0]} panels={panels} exclusive={false} />;
};
