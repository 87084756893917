import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchChatTemplates, updateChatTemplate, createChatTemplate } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { getChatModules, updateChatModules } from '../../../api';
import { STATUSES } from '../../../constants';
import { ModuleGraph } from './ModuleGraph';
import { usePreventUnload } from '../usePreventUnload';

export const ChatModulesContext = createContext();

/** * Properties description:
  preview             {bool}      Disables all user editing interaction
  disabled            {bool}      Disables all user interaction and freezes current state with added opacity
  hideHeader          {bool}      Prevent display of header with editing controls
  hideUnreachable     {bool}      Prevents display of side panel containing information about unlinked modules
  customModuleGraph   {object}    Display custom module graph, prevent default initial loading of modules
  notification        {bool}      Node reserved for displaying notification positioned below header
  onModulesLoad       {function}  Callback after loading modules
*/

export const ChatModulesContextProvider = (props) => {
  const { chatId } = useParams();

  const { preview, disabled, hideHeader, hideUnreachable, customModuleGraph, notification, onModulesLoad } = props;
  const [loaderStatus, setLoaderStatus] = useState(customModuleGraph ? STATUSES.LOADED : STATUSES.IDLE);
  const [saveingStatus, setSaveingStatus] = useState(STATUSES.IDLE);
  const [graphWrapperRef, setGraphWrapperRef] = useState({
    moduleGraph: customModuleGraph || new ModuleGraph([]),
  });
  const [chatTemplateModalOpen, setChatTemplateModalOpen] = useState(false);
  const [chatTemplates, setChatTemplates] = useState([]);
  const { moduleGraph } = graphWrapperRef;
  const [dirty, setDirty] = usePreventUnload();

  useEffect(() => {
    const fetchModules = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);

        const { modules } = await getChatModules(chatId);
        const moduleGraph = new ModuleGraph(modules);

        setGraphWrapperRef({ moduleGraph });

        if (typeof onModulesLoad === 'function') {
          onModulesLoad(moduleGraph);
        }

        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        setLoaderStatus(STATUSES.FAILURE);
        console.error(e);
      }
    };

    // Skip fetching modules if custom modules graph is provided
    if (customModuleGraph) {
      setLoaderStatus(STATUSES.LOADED);
    } else {
      fetchModules();
    }
  }, [chatId, customModuleGraph, preview, onModulesLoad]);

  const addRootModule = (rootModule) => {
    setDirty(true);
    const moduleGraph = new ModuleGraph([rootModule]);
    setGraphWrapperRef({ moduleGraph });
  };

  const addChildModule = (child, parent, parentNodeAnswer) => {
    setDirty(true);
    moduleGraph.addModule(child, parent, parentNodeAnswer);
    setGraphWrapperRef({ moduleGraph });
  };

  const updateModule = (module) => {
    setDirty(true);
    moduleGraph.updateModule(module);
    setGraphWrapperRef({ moduleGraph });
  };

  const deleteModule = (module, deleteRelated) => {
    setDirty(true);
    moduleGraph.deleteModule(module.number, deleteRelated);
    setGraphWrapperRef({ moduleGraph });
  };

  const moveUp = (node) => {
    setDirty(true);
    moduleGraph.moveNodeUp(node);
    setGraphWrapperRef({ moduleGraph });
  };

  const moveDown = (node) => {
    setDirty(true);
    moduleGraph.moveNodeDown(node);
    setGraphWrapperRef({ moduleGraph });
  };

  const saveChanges = async () => updateChatModules(chatId, moduleGraph.modules);

  const toggleShowSaveTemplateModal = () => {
    setChatTemplateModalOpen((prev) => !prev);
  };

  const getChatTemplates = async () => {
    const templates = await fetchChatTemplates();
    setChatTemplates(templates.results);
  };

  const handleSaveTemplate = async (vsa_type, name, id) => {
    setSaveingStatus(STATUSES.LOADING);
    try {
      if (id) {
        await updateChatTemplate({ vsa_type, name, id, chat_id: chatId });
        setSaveingStatus(STATUSES.LOADED);
        toast.success(`Successfully updated template ${name}`);
        toggleShowSaveTemplateModal();
        getChatTemplates();
        return;
      }
      const response = await createChatTemplate({ vsa_type, name, chat_id: chatId });
      setSaveingStatus(STATUSES.LOADED);
      toast.success(`Successfully created template ${response.name}`);
      toggleShowSaveTemplateModal();
      getChatTemplates();
    } catch (e) {
      setSaveingStatus(STATUSES.FAILURE);
      const message = parseErrorResponse(e, 'Unable to save template. Please try again.');
      toast.error(message);
      console.error(e);
    }
  };

  return (
    <ChatModulesContext.Provider
      {...props}
      value={{
        chatId,
        moduleGraph,
        loaderStatus,
        addRootModule,
        addChildModule,
        updateModule,
        deleteModule,
        saveChanges,
        moveUp,
        moveDown,
        dirty,
        setDirty,
        notification,
        previewMode: preview,
        disabledMode: disabled,
        hideHeader,
        hideUnreachable,
        chatTemplateModalOpen,
        toggleShowSaveTemplateModal,
        chatTemplates,
        getChatTemplates,
        handleSaveTemplate,
        saveingStatus,
      }}
    />
  );
};
