import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledPill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 16px;
  margin: 2px 0 2px 8px;
  padding: 0 3px;
  border-radius: 9px;
  background-color: var(--emerald-green);

  span {
    font-family: Avenir;
    font-size: 9px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
  }
`;

export const Pill = ({ label, backgroundColor, ...otherProps }) => {
  const classes = classNames('pill', otherProps.className);
  return (
    <StyledPill className={classes} style={{ backgroundColor }}>
      <span>{label}</span>
    </StyledPill>
  );
};

Pill.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
};
