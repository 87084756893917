import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import humps from 'humps';

import { updateUserProfile } from 'apis';
import { parseErrorResponse, parseFieldErrors } from 'apis/utils';
import { Form, Modal as ComponentsModal, Button } from 'components/lib';
import { MyProfileInputField } from './MyProfileInputField';

const Modal = styled(ComponentsModal)`
  && {
    & > .header {
      border-bottom: none;
    }
    &.tiny,
    &.large {
      width: 541px;
      height: 421px;
    }
  }
`;

const HeaderTitle = styled.div`
  font-family: var(--font-family);
  font-weight: 900; // override semantic-ui inherited font-weight: 700;
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const HeaderSubtitle = styled.div`
  font-family: var(--font-family);
  font-weight: 500; // override semantic-ui inherited font-weight: 700;
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  margin-top: 11px;
`;

const Content = styled.div`
  padding: 0 30px 30px 30px;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  border-top: 1px solid var(--line-standard);
  padding: 0 30px;
  margin-top: 28px;
`;

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Must be a valid email address')
    .max(150, `Can't be longer than 150 characters`)
    .required('Required'),
  firstName: Yup.string().max(30, `Can't be longer than 30 characters`).required('Required'),
  lastName: Yup.string().max(150, `Can't be longer than 150 characters`).required('Required'),
  phone: Yup.string().max(20, `Can't be longer than 20 characters`).required('Required').nullable(),
});

export const MyProfileInfoModal = ({ open, onClose, data = {}, setProfileData }) => {
  const [isLoading, setIsLoading] = useState();

  const update = async (values, setErrors) => {
    try {
      setIsLoading(true);
      const response = await updateUserProfile(values);
      setIsLoading(false);
      onClose();
      setProfileData(response);
      toast.success('Profile data updated successfully!');
    } catch (e) {
      setErrors(humps.camelizeKeys(parseFieldErrors(e)));
      toast.error(parseErrorResponse(e, 'Unable to update profile data!'));
    } finally {
      setIsLoading(false);
    }
  };
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    ...data,
  };
  const onSubmit = async (values, { setErrors }) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
    };
    update(data, setErrors);
  };

  const onCancel = (resetForm) => () => {
    resetForm();
    onClose();
  };
  return (
    <Modal open={open} closeIcon size="large" onClose={onClose}>
      <Modal.Header>
        <HeaderTitle>Edit Personal Info</HeaderTitle>
        <HeaderSubtitle>Enter your personal information below.</HeaderSubtitle>
      </Modal.Header>
      <Modal.Content>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ dirty, handleSubmit, touched, isValid, resetForm, values }) => (
            <Form onSubmit={handleSubmit}>
              <Content>
                <MyProfileInputField
                  label="First Name"
                  name="firstName"
                  placeholder="First Name"
                  value={values.firstName}
                  validateOnKeyUp
                />
                <MyProfileInputField
                  label="Last Name"
                  name="lastName"
                  placeholder="Last Name"
                  value={values.lastName}
                  validateOnKeyUp
                />
                <MyProfileInputField
                  label="Email"
                  name="email"
                  placeholder="Email"
                  value={values.email}
                  validateOnKeyUp
                />
                <MyProfileInputField
                  label="Phone"
                  name="phone"
                  placeholder="Add phone number"
                  value={values.phone}
                  validateOnKeyUp
                />
              </Content>
              <Actions>
                <Button
                  primary
                  color="blue"
                  type="submit"
                  disabled={!dirty || !touched || !isValid}
                  loading={isLoading}
                >
                  Save
                </Button>
                <Button primary type="button" onClick={onCancel(resetForm)}>
                  Cancel
                </Button>
              </Actions>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};
