import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const Value = styled.div`
  font-size: 2rem;
  height: 2rem;
  line-height: 1;
  white-space: nowrap;
  color: #3d3d3d;
  display: flex;
  align-items: flex-end;
`;

const SecondaryValue = styled.span`
  font-size: 1.2rem;
  color: #666;
`;

const Label = styled.div`
  font-size: 0.9rem;
  text-transform: uppercase;
  line-height: 1.5;
  color: #666;
`;

const ChangeIcon = styled.div`
  display: flex;
  font-size: 1rem;
  flex-direction: row;

  .red {
    color: red;
  }

  .green {
    color: green;
  }

  i {
    font-size: 70%;
  }
`;

const Wrapper = styled.div``;

const identity = (v) => v;
export const getChangeIcon = (value, prevValue, lessIsBetter, formatter = identity, className, noChange = null) => {
  if (!Number.isFinite(value) || !Number.isFinite(prevValue)) {
    return null;
  }
  if (value === prevValue) {
    return noChange;
  }
  const diff = value - prevValue;
  const color = (lessIsBetter && diff > 0) || (!lessIsBetter && diff < 0) ? 'red' : 'green';
  const icon = diff < 0 ? 'arrow down' : 'arrow up';
  const perc = value && Math.abs(diff) > 999 ? `${((diff / value) * 100).toFixed(1)}%` : formatter(diff);
  return (
    <ChangeIcon className={className}>
      <div className={color}>
        {diff > 0 ? '+' : ''}
        {perc}
      </div>
      <Icon name={icon} color={color} />
    </ChangeIcon>
  );
};

export const Statistic = ({ label, value, secondaryValue, tooltip, changeIcon }) => {
  const stat = (
    <Wrapper>
      <Value>
        {value}&nbsp;
        {secondaryValue && <SecondaryValue>({secondaryValue})</SecondaryValue>}
        {changeIcon}
      </Value>
      <Label>{label}</Label>
    </Wrapper>
  );
  if (tooltip) {
    return <Popup content={tooltip} trigger={stat} position="top center" />;
  }
  return stat;
};

Statistic.Group = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};

  padding-top: 2rem;
  margin-top: ${(props) => props.marginTop || 'auto'};
  margin-bottom: ${(props) => props.marginBottom || 'auto'};

  ${Wrapper} {
    margin-right: ${(props) => (props.justifyContent ? 0 : '3rem')};
    /* margin-bottom: 2rem; */
  }

  &::before {
    display: block;
    position: absolute;
    content: '${(props) => props.name}';
    top: -1rem;
    left: -1rem;
    line-height: 0;
    background-color: white;
    padding: 1rem;
    color: #666;
  }
`;
