import constants from './constants';

const reducer = (draft, action) => {
  switch (action.type) {
    case constants.SET_IS_MULTIPLE_SELECTION:
      draft.isMultipleSelection = !draft.isMultipleSelection;
      draft.selectedItems = [];
      draft.selectedPages = [];
      draft.allItemsSelected = false;
      draft.excludedItems = [];
      break;
    case constants.SET_SELECTED_ITEM: {
      const itemId = action.payload.id;
      const items = action.payload.items;
      const currentPage = action.payload.currentPage;
      let tempSelectedItems = [];
      if (!draft.allItemsSelected) {
        if (draft.selectedItems.includes(itemId)) {
          tempSelectedItems = draft.selectedItems.filter((id) => id !== itemId);
        } else {
          tempSelectedItems = [...draft.selectedItems, itemId];
        }
        draft.selectedItems = tempSelectedItems;
        const countOfItemsOnPage = tempSelectedItems.filter((itemId) =>
          items.find((item) => item.id === itemId)
        )?.length;
        const areAllItemsOnPageSelected = countOfItemsOnPage === items?.length;
        if (draft.selectedPages.includes(currentPage) && !areAllItemsOnPageSelected) {
          draft.selectedPages = draft.selectedPages.filter((page) => page !== currentPage);
        }
      } else {
        draft.excludedItems = draft.excludedItems.includes(itemId)
          ? draft.excludedItems.filter((id) => id !== itemId)
          : [...draft.excludedItems, itemId];
      }

      break;
    }
    case constants.SET_SELECTED_PAGES: {
      const page = action.payload.currentPage;
      const items = action.payload.items;

      if (!draft.allItemsSelected) {
        if (draft.selectedPages.includes(page)) {
          const idsToRemove = items.map((item) => item.id);
          draft.selectedItems = draft.selectedItems.filter((id) => !idsToRemove.includes(id));
        } else {
          const idsToRemove = items.map((item) => item.id);
          draft.selectedItems = draft.selectedItems.filter((id) => !idsToRemove.includes(id));
          draft.selectedItems = [...draft.selectedItems, ...items.map((item) => item.id)];
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (draft.selectedPages.includes(page)) {
          const idsToRemove = items.map((item) => item.id);
          draft.excludedItems = draft.excludedItems.filter((id) => !idsToRemove.includes(id));
          draft.excludedItems = [...draft.excludedItems, ...items.map((item) => item.id)];
        } else {
          draft.excludedItems = draft.excludedItems.filter((id) => !draft.excludedItems.includes(id));
        }
      }

      draft.selectedPages = draft.selectedPages.includes(page)
        ? draft.selectedPages.filter((p) => p !== page)
        : [...draft.selectedPages, page];

      break;
    }
    case constants.SET_SELECT_ALL_ITEMS:
      draft.allItemsSelected = true;
      draft.selectedItems = [];
      draft.selectedPages = Array.from(Array(action.payload), (_, index) => index + 1);
      break;
    case constants.SET_RESET_IS_MULTIPLE_SELECTION:
      draft.isMultipleSelection = false;
      break;
    case constants.SET_RESET_SELECTED_PAGES:
      draft.selectedPages = [];
      break;
    case constants.SET_RESET_SELECTED_ITEMS:
      draft.selectedItems = [];
      break;
    case constants.SET_RESET_EXCLUDED_ITEMS:
      draft.excludedItems = [];
      break;
    case constants.SET_RESET_ALL_ITEMS_SELECTED:
      draft.allItemsSelected = false;
      break;
    case constants.RESET_TABLE_SELECTION:
      draft.selectedPages = [];
      draft.selectedItems = [];
      draft.excludedItems = [];
      draft.allItemsSelected = false;
      break;
    case constants.SET_RESET:
      draft.selectedItems = [];
      draft.selectedPages = [];
      draft.allItemsSelected = false;
      draft.excludedItems = [];
      draft.isMultipleSelection = false;
      draft.editModalOpen = false;
      draft.singleSelection = false;
      break;
    default:
      console.warn(`Unhandled action ${JSON.stringify(action)}`);
      break;
  }
  return draft;
};

export default reducer;
