import React, { useState, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { If } from 'components/If';
import { InputField } from 'components/common';
import { Form, Radio, Input, Loader } from 'components/lib';
import { parseErrorResponse, parseFieldErrors } from 'apis/utils';
import { addDomain, getDomains } from 'apis';
import { ReactComponent as LogoType } from 'assets/images/new-logotype.svg';
import StepActions from './StepActions';
import { StepsHeader } from './StepsHeader';
import { StepsContent } from './StepsContent';
import { STATUSES } from '../../../constants';
import { MICROSOFT, FURTHER } from './constants';

const CustomRadio = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  > span {
    margin-left: 10px;
    margin-right: 10px;
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
    display: flex;
    flex-direction: column;
    > span {
      font-family: var(--font-family);
      font-size: 12px;
      line-height: normal;
      letter-spacing: normal;
      color: #89919d;
    }
  }
  && {
    .field {
      margin: 0;
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  padding-left: 28px;
  .field {
    width: 330px;
  }
`;

const InputPrefix = styled.div`
  width: 36px;
  height: 36px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #fff;
  font-family: var(--font-family);
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.span`
  display: inline-block;
  margin-left: 3px;
`;

const NEW_DOMAIN = 'newDomain';

const initialFormValues = {
  domain: '',
  customDomain: '',
};

const validationSchema = Yup.object({
  domain: Yup.string().required('Domain is required'),
  customDomain: Yup.string().when('domain', {
    is: NEW_DOMAIN,
    then: Yup.string().required('Custom domain is required'),
  }),
});

export const SelectDomainStep = (props) => {
  const { onNextClick, emailDomains } = props;
  const defaultStatus = emailDomains.length > 0 ? STATUSES.LOADED : STATUSES.IDLE;
  const [domainsLoading, setDomainsLoading] = useState(defaultStatus);
  const [isDomainAdding, setIsDomainAdding] = useState(defaultStatus);
  const [domains, setDomains] = useState(emailDomains);

  const fetchDomains = useCallback(async () => {
    try {
      if (domainsLoading === STATUSES.IDLE) {
        setDomainsLoading(STATUSES.LOADING);
      }
      const { results } = await getDomains();
      setDomains(results);
      setDomainsLoading(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e), 'Unable to fetch data!');
      setDomainsLoading(STATUSES.FAILURE);
    }
  }, [setDomains, setDomainsLoading]);

  const createDomain = async (data, setFieldError, values) => {
    try {
      setIsDomainAdding(STATUSES.LOADING);
      const response = await addDomain(data);
      setIsDomainAdding(STATUSES.LOADED);
      if ([MICROSOFT, FURTHER].includes(response?.provider?.toLowerCase())) {
        onNextClick({ ...values, domain: response });
      } else {
        toast.error('Only Microsoft domains are supported', 'Unable to add domain!');
        setFieldError('customDomain', 'Only Microsoft domains are supported');
      }
    } catch (e) {
      console.error(e);
      const error = parseFieldErrors(e);
      setFieldError('customDomain', error?.domain);
      toast.error(parseErrorResponse(e), 'Unable to add domain!');
      setIsDomainAdding(STATUSES.FAILURE);
    }
  };

  useEffect(() => {
    fetchDomains();
  }, [emailDomains]);

  const onSubmit = async (values, { setFieldError }) => {
    const { onNextClick } = props;
    if (values.domain === NEW_DOMAIN) {
      await createDomain({ domain: values.customDomain }, setFieldError, values);
    } else {
      onNextClick(values);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values, isValid, handleSubmit }) => (
        <Form>
          <StepsHeader title="Add Email Address" subtitle="Please select a domain." />
          <StepsContent>
            <If condition={domainsLoading === STATUSES.LOADING}>
              <Loader active />
            </If>
            {domains.map((domain) => (
              <CustomRadio key={domain.id}>
                <Form.Field
                  control={Radio}
                  name="domain"
                  value={domain.id}
                  checked={values.domain.id === domain.id}
                  onChange={() => {
                    setFieldValue('domain', domain);
                  }}
                />
                <span>
                  @{domain.domain}
                  <If condition={domain.provider === 'further'}>
                    <span>
                      Automated by Further{' '}
                      <LogoWrapper>
                        <LogoType width={12} height={12} />
                      </LogoWrapper>
                    </span>
                  </If>
                  <If condition={domain.provider !== 'further'}>
                    <span>{domain.provider}</span>
                  </If>
                </span>
              </CustomRadio>
            ))}
            <If condition={domainsLoading === STATUSES.LOADED}>
              <CustomRadio>
                <Form.Field
                  control={Radio}
                  name="domain"
                  value={NEW_DOMAIN}
                  checked={values.domain === NEW_DOMAIN}
                  onChange={() => {
                    setFieldValue('domain', NEW_DOMAIN);
                  }}
                />
                <span>
                  New Domain
                  <span>Add any other domain you own. May require additional DNS setup.</span>
                </span>
              </CustomRadio>
            </If>
            <If condition={values.domain === NEW_DOMAIN}>
              <InputWrapper>
                <InputPrefix>@</InputPrefix>
                <InputField control={Input} name="customDomain" />
              </InputWrapper>
            </If>
          </StepsContent>
          <StepActions
            {...props}
            disabled={!isValid}
            onNext={handleSubmit}
            loading={isDomainAdding === STATUSES.LOADING}
          />
        </Form>
      )}
    </Formik>
  );
};
