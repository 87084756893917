import React from 'react';
import { format, parseISO } from 'date-fns';
import { Message, Pagination, Table } from 'semantic-ui-react';

import ProfilesMetricIndicator from './ProfilesMetricIndicator';

const OnboardingMetricsTable = ({ clients, activePage, totalPages, onPageChange }) => {
  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  if (!clients) {
    return null;
  }

  if (clients.length === 0) {
    return <Message warning icon="users" content="Users not found" />;
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>User ID</Table.HeaderCell>
          <Table.HeaderCell>User</Table.HeaderCell>
          <Table.HeaderCell>Chat live from</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">No. of Communities</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Contact Info Added</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Pricing Provided</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Showing Regular VSA</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Showing Embedded VSA</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Image Uploaded</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {clients.map((c) => (
          <Table.Row key={c.id}>
            <Table.Cell>{c.id}</Table.Cell>
            <Table.Cell>{c.email}</Table.Cell>
            <Table.Cell>{c.payingCustomerFrom && format(parseISO(c.payingCustomerFrom), 'yyyy-MM-dd')}</Table.Cell>
            <Table.Cell textAlign="center">{c.providerProfilesCount}</Table.Cell>
            <Table.Cell textAlign="center">
              <ProfilesMetricIndicator
                name="contact-info"
                completedProfiles={c.contactInfoAdded}
                incompleteProfiles={c.contactInfoMissing}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              <ProfilesMetricIndicator
                name="pricing-added"
                completedProfiles={c.pricingAdded}
                incompleteProfiles={c.pricingMissing}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              <ProfilesMetricIndicator
                name="chat-live"
                completedProfiles={c.regularVsaLive}
                incompleteProfiles={c.regularVsaNotLive}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              <ProfilesMetricIndicator
                name="chat-live"
                completedProfiles={c.embeddedVsaLive}
                incompleteProfiles={c.embeddedVsaNotLive}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              <ProfilesMetricIndicator
                name="images-uploaded"
                completedProfiles={c.imagesUploaded}
                incompleteProfiles={c.imagesNotUploaded}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="9" textAlign="left">
            {totalPages > 1 && (
              <Pagination activePage={activePage} onPageChange={handlePageChange} totalPages={totalPages} />
            )}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default OnboardingMetricsTable;
