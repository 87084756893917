import React from 'react';
import styled from 'styled-components';

import { TableHeaderCell } from 'semantic-ui-react';
import { Message, Pagination, Table, StyledTable } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { MARKET_TYPE_LABEL } from 'constants';
import UserStatus from './UserStatus';

const LeftPaddedContent = styled.div`
  padding-left: 35px;
`;

const RightPaddedContent = styled.div`
  padding-right: 35px;
`;

const SmallText = styled.span`
  font-size: 12px;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-right: 20px;
`;

const TableWrapper = styled.div`
  cursor: pointer;
`;

const UserManagementTable = ({ users, activePage, totalPages, onPageChange }) => {
  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  if (!users) {
    return null;
  }

  if (users.length === 0) {
    return <Message warning icon="users" content="Users not found" />;
  }

  const getMarketTypeText = (marketType) => MARKET_TYPE_LABEL[marketType] || 'Unknown';

  function getUserType(user) {
    const types = [];
    if (user.payingCustomer) types.push('Paying Customer');
    if (user.demoAccount) types.push('Demo');
    if (user.inPilot) types.push('Pilot');
    if (types.length === 1) {
      return types[0];
    }
    return types.join(', ');
  }

  const handleRowClick = (userId) => {
    window.location.href = `/admin/user-management/user/${userId}`;
  };

  return (
    <>
      <TableWrapper>
        <StyledTable basic selectable striped>
          <Table.Header>
            <Table.Row>
              <TableHeaderCell>
                <LeftPaddedContent>USER</LeftPaddedContent>
              </TableHeaderCell>
              <TableHeaderCell>CONTACT</TableHeaderCell>
              <TableHeaderCell>COMPANY</TableHeaderCell>
              <TableHeaderCell>MARKET</TableHeaderCell>
              <TableHeaderCell>ROLE</TableHeaderCell>
              <TableHeaderCell>TYPE</TableHeaderCell>
              <TableHeaderCell textAlign="right">
                <RightPaddedContent>STATUS</RightPaddedContent>
              </TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {users.map((u) => (
              <Table.Row key={u.id} onClick={() => handleRowClick(u.id)}>
                <Table.Cell>
                  <LeftPaddedContent>{u.name}</LeftPaddedContent>
                </Table.Cell>
                <Table.Cell>
                  <VerticalContainer>
                    <span>{u.phone || '-'}</span>
                    <SmallText>{u.email || '-'}</SmallText>
                  </VerticalContainer>
                </Table.Cell>
                <Table.Cell>
                  <VerticalContainer>
                    <span>{u.companyName || '-'}</span>
                    <SmallText>
                      {u.accountManager && u.accountManager.displayName ? u.accountManager.displayName : '-'}
                    </SmallText>
                  </VerticalContainer>
                </Table.Cell>
                <Table.Cell>{getMarketTypeText(u.marketType)}</Table.Cell>
                <Table.Cell>{capitalize(u.role)}</Table.Cell>
                <Table.Cell>{getUserType(u) ? capitalize(getUserType(u)) : '-'}</Table.Cell>
                <Table.Cell>
                  <RightPaddedContent>
                    <UserStatus lastLogin={u.lastLogin} invite={u.invite} />
                  </RightPaddedContent>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </StyledTable>
      </TableWrapper>
      {totalPages > 1 && (
        <Footer>
          <Pagination activePage={activePage} onPageChange={handlePageChange} totalPages={totalPages} />
        </Footer>
      )}
    </>
  );
};

export default UserManagementTable;
