import React, { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';

const ScheduledMessageContext = createContext();

const INITIAL_STATE = {
  messages: undefined,
};

const reducer = (draft, action) => {
  switch (action.type) {
    case 'SET_MESSAGES':
      draft.messages = action.payload;
      break;
    case 'UPDATE_MESSAGE': {
      const { leadId, data } = action.payload;
      const { useFirstNameInMessaging, messagePreview } = data;
      draft.messages = draft.messages.map((m) => {
        if (m.lead.id === leadId) {
          const lead = { ...m.lead, useFirstNameInMessaging };
          return { ...m, lead, messagePreview };
        }
        return m;
      });
      break;
    }
    default:
      console.warn(`Unhandled action ${JSON.stringify(action)}`);
      break;
  }
  return draft;
};

const ScheduledMessageProvider = (props) => {
  const [state, dispatch] = useImmerReducer(reducer, INITIAL_STATE);

  const value = [state, dispatch];

  return <ScheduledMessageContext.Provider value={value}>{props.children}</ScheduledMessageContext.Provider>;
};

export function useScheduledMessages() {
  const context = useContext(ScheduledMessageContext);

  if (!context) {
    throw new Error(`useScheduledMessages must be used within ScheduledMessageProvider`);
  }

  return context;
}

export default ScheduledMessageProvider;
