import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Icon, FURHTER_ICON } from 'components/lib';
import { CsvDownloadModal } from './CsvDownloadModal';

const StyledButton = styled(Button)`
  &&& {
    display: inline-flex;
    align-items: center;
    min-width: 62px;
    height: 26px;
    border-radius: 4px;
    background-color: rgba(39, 90, 255, 0.1);
    p {
      margin: 0;
      font-family: var(--font-family-bold);
      font-size: 12px;
      line-height: 12px;
      color: var(--vivid-blue);
      padding-top: 3px;
    }
    .${FURHTER_ICON} {
      height: auto;
    }
  }
`;

export const CsvExport = ({ data, isLoading, onModalOpen, onModalClose, content = 'CSV', ...props }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handelButtonClick = () => {
    setModalOpen(true);
    onModalOpen?.();
  };
  const onCloseModal = () => {
    setModalOpen(false);
    onModalClose?.();
  };

  return (
    <>
      <CsvDownloadModal open={modalOpen} data={data} isLoading={isLoading} onCloseModal={onCloseModal} {...props} />
      <StyledButton size="tiny" icon onClick={handelButtonClick} type="button">
        <Icon name="file_download" variant="outlined" color="var(--vivid-blue)" size="20px" />
        <p>{content}</p>
      </StyledButton>
    </>
  );
};

CsvExport.propTypes = {
  data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.array), PropTypes.arrayOf(PropTypes.object)]),
  headers: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.object)]),
  filename: PropTypes.string,
};
