import React, { useContext, useEffect, useState } from 'react';
import { Form, Message } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Divider, Tab } from 'components/lib';
import { If } from '../../If';
import { UserCommunityImagesUpload } from './UserCommunityImagesUpload';
import { InputField, PAGE_HEADER_PADDING, PageHeader } from '../../common';
import { SortableGrid, DraggableImage } from '../../common/sortableGrid';
import { UserCommunityImagesScraped } from './UserCommunityImagesScraped';
import { ImageBox } from '../ImageBox';
import { CommunitiesContext } from '../../common/communities/CommunitiesContext';
import { CommunityAssetsContext } from '../CommunityAssetsContext';
import { STATUSES } from '../../../constants';

export const UserCommunityImages = () => {
  const { state } = useContext(CommunitiesContext);
  const { scrapeResults, updateStatus, onUpdateAssets } = useContext(CommunityAssetsContext);
  const { activeCommunity } = state;

  const [images, setImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [updateDisabled, setUpdateDisabled] = useState(true);

  useEffect(() => {
    setImages(activeCommunity?.media?.images?.map((img, id) => ({ id, img })) || []);
    setDeletedImages([]);
    setUpdateDisabled(true);
  }, [activeCommunity]);

  const onOrderImages = (images) => {
    setImages(images);
    setUpdateDisabled(false);
  };

  const onRemoveImage = (item) => {
    setDeletedImages([...deletedImages, item.img]);
  };

  const onSubmit = (values) => {
    onUpdateAssets({ images: images.map((i) => i.img), images_url: values.images_url });
  };

  const onReset = () => {
    setImages(activeCommunity?.media?.images?.map((img, id) => ({ id, img })) || []);
    setDeletedImages([]);
    setUpdateDisabled(true);
  };

  const hasImages = images?.length;
  const updateLoading = updateStatus === STATUSES.LOADING;
  const initialValues = { images_url: activeCommunity?.media?.images_url || '' };

  const VALIDATION_SCHEMA = Yup.object({
    images_url: Yup.string().url().nullable(),
  });

  return (
    <Tab.Pane padding="30px">
      <Formik initialValues={initialValues} validationSchema={VALIDATION_SCHEMA} enableReinitialize onSubmit={onSubmit}>
        {({ dirty, handleSubmit }) => (
          <Form>
            <PageHeader
              title="Edit Current Images"
              subtitle="Sort images by drag and drop, or remove them, and apply update"
              padding={PAGE_HEADER_PADDING.VERTICAL}
              actions={
                <>
                  <If condition={!updateDisabled}>
                    <Button icon="redo" disabled={updateDisabled} content="Reset" onClick={onReset} />
                  </If>
                  <UserCommunityImagesUpload disabled={!updateDisabled} />
                  <Button
                    primary
                    disabled={(updateDisabled && !dirty) || updateLoading}
                    loading={updateLoading}
                    content="Update"
                    onClick={handleSubmit}
                  />
                </>
              }
            />
            <InputField name="images_url" label="Images Fallback URL" placeholder="Webpage URL" />
            <Divider section horizontal>
              Current Images
            </Divider>
            <If condition={!hasImages}>
              <Message info>No image assets</Message>
            </If>

            <If condition={hasImages}>
              <SortableGrid
                items={images}
                onUpdate={onOrderImages}
                onRemove={onRemoveImage}
                contentComponent={DraggableImage}
              />
            </If>

            <If condition={deletedImages.length}>
              <Divider section horizontal>
                Deleted images
              </Divider>
              <ImageBox size="tiny" images={deletedImages} />
            </If>

            <If condition={scrapeResults?.images?.length}>
              <Divider section horizontal>
                Scraped images
              </Divider>
              <UserCommunityImagesScraped disabled={!updateDisabled || updateLoading} images={scrapeResults?.images} />
            </If>
          </Form>
        )}
      </Formik>
    </Tab.Pane>
  );
};
