import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Dimmer, Loader } from 'components/lib';
import { useSettings } from '../context/SettingsContext';
import { setResetAllItemsSelected, setResetExcludedItems } from '../context/actions';

const Wrapper = styled.div`
  position: relative;
  .list-item {
    border-top: none;
    padding: 0;
    &:last-of-type {
      border-bottom: none;
    }
    cursor: ${(props) => (props.isMultipleSelection ? 'default' : 'pointer')};
  }
`;

export const SettingsContainer = ({ children, loading, totalItemsCount }) => {
  const [{ isMultipleSelection, excludedItems, allItemsSelected }, dispatch] = useSettings();
  useEffect(() => {
    if (excludedItems.length === totalItemsCount && allItemsSelected) {
      dispatch(setResetExcludedItems());
      dispatch(setResetAllItemsSelected());
    }
  }, [excludedItems.length]);
  return (
    <Wrapper isMultipleSelection={isMultipleSelection}>
      <Dimmer active={loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {children}
    </Wrapper>
  );
};
