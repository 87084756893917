import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TabsWithNavigation } from 'components/lib';
import ManageAnswersTab from './ManageAnswersTab';

const MANAGE_ANSWERS_TABS = [
  {
    tabName: 'Messaging Assistant',
    path: '/admin/answers/messaging-assistant',
    Content: () => <ManageAnswersTab />,
  },
  {
    tabName: 'Instant Answers',
    path: '/admin/answers/instant-answers',
    Content: () => <ManageAnswersTab answerTypes="INSTANT_ANSWER" />,
  },
];

const INITIAL_TAB = MANAGE_ANSWERS_TABS[0].path;

export const ManageAnswers = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/admin/answers') {
      history.replace(INITIAL_TAB);
    }
  }, []);

  return (
    <div>
      <TabsWithNavigation tabs={MANAGE_ANSWERS_TABS} />
    </div>
  );
};
