import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { Segment } from 'semantic-ui-react';
import { add, format, differenceInDays, parseISO } from 'date-fns';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { ReportComponent, TimeSeriesChart } from 'components/common/analytics';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { STATUSES } from 'constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const METRIC_DATASET = {
  manually_accepted: {
    label: 'Manually Accepted',
    borderColor: '#17bd2a',
    backgroundColor: '#bae8bf',
  },
  partially_accepted: {
    label: 'Partially Accepted',
    borderColor: '#289eb5',
    backgroundColor: '#a7d2db',
  },
  relabeled: {
    label: 'Changed with Labels',
    borderColor: '#2b43a1',
    backgroundColor: '#9eaad9',
  },
  retexted: {
    label: 'Changed with Text',
    borderColor: '#7d2394',
    backgroundColor: '#c49bcf',
  },
  labeled: {
    label: 'Replied with Labels',
    borderColor: '#c4931f',
    backgroundColor: '#d6c49a',
  },
  texted: {
    label: 'Replied with Text',
    borderColor: '#b33024',
    backgroundColor: '#cf9a95',
  },
  unsent: {
    label: 'Not Sent',
    borderColor: '#cfcf1f',
    backgroundColor: '#d4d496',
  },
  automated: {
    label: 'Automated',
    borderColor: '#222',
    backgroundColor: '#102a61',
  },
};

const tooltip = (
  <ul style={{ width: '400px' }}>
    <li>Manually Accepted: Agent has manually sent an answer that is the same as suggested.</li>
    <li>Partially Accepted: Sent answer has at least one suggested label.</li>
    <li>Changed with Labels: Sent answer does not include suggested labels but it is formed using other labels</li>
    <li>Changed with Text: Sent answer does not include suggested labels and free text is included in the answer.</li>
    <li>Replied with Labels: Answer was not suggested and sent answer is formed using only labels.</li>
    <li>Replied with Text: Answer was not suggested and sent answer has free text included in the answer.</li>
    <li>Not Sent: The answer is not sent.</li>
    <li>Automated: The answer was automatically sent.</li>
  </ul>
);

const chartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          borderDash: [2, 10],
          drawBorder: false,
        },
        ticks: {
          padding: 16,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          borderDash: [2, 10],
          drawBorder: false,
        },
        ticks: {
          min: 0,
          padding: 16,
        },
      },
    ],
  },
};

const groupBy = 'time';
const metrics = [
  'answers.manually_accepted',
  'answers.partially_accepted',
  'answers.relabeled',
  'answers.retexted',
  'answers.labeled',
  'answers.texted',
  'answers.unsent',
  'answers.automated',
];

const TrendsGraph = () => {
  const { dateRange, automatedAnswerFilters: filters } = useSystemStatsFilters();
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.ADMIN_METRICS, { ...filters, metrics }],
    () => getMetrics(metrics, filters, groupBy),
    { placeholderData: {} }
  );

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch calls metrics'));
  }

  const chartData = useMemo(() => {
    const start = parseISO(dateRange.start_date);
    const end = parseISO(dateRange.end_date);
    const diff = differenceInDays(end, start);
    const labels = [];
    for (let i = 0; i <= diff; i += 1) {
      const day = format(add(start, { days: i }), 'yyyy-MM-dd');
      labels.push(day);
    }
    if (!data || !data[metrics[0]]) {
      return { labels, datasets: [] };
    }
    const dataLookup = metrics.reduce((lookup, m) => {
      lookup[m] = data[m].groups.reduce((r, obj) => {
        r[obj.time] = obj.value;
        return r;
      }, {});
      return lookup;
    }, {});

    const datasets = metrics.map((m) => ({
      ...METRIC_DATASET[m.substring(8)],
      data: labels.map((l) => dataLookup[m][l] || 0),
    }));
    return { labels, datasets };
  }, [data, dateRange]);

  return (
    <ReportComponent title="Answers Breakdown" tooltip={tooltip} loading={loaderStatus === STATUSES.LOADING}>
      <TimeSeriesChart data={chartData} height={300} options={chartOptions} />
    </ReportComponent>
  );
};

export default TrendsGraph;
