import React from 'react';
import { Link } from 'react-router-dom';

const ConversationsLink = ({
  value,
  startDate,
  endDate,
  leadStatus,
  channelSources,
  communities,
  groups,
  trafficSources,
  moveInDate,
  tourScheduled,
  callReceived,
  financiallyUnqualified,
  isSurveySubmitted,
  ...other
}) => {
  const filters = {
    startDate,
    endDate,
    leadStatus,
    channelSources,
    communities,
    groups,
    trafficSources,
    moveInDate,
    tourScheduled,
    callReceived,
    financiallyUnqualified,
    isSurveySubmitted,
  };
  Object.keys(filters).forEach((key) => {
    if (filters[key] === undefined || filters[key] === null || filters[key] === '' || filters[key].length === 0) {
      delete filters[key];
    }
  });
  const query = new URLSearchParams(filters).toString();
  return (
    <Link to={`/conversations?${query}`} target="_blank" {...other}>
      {value}
    </Link>
  );
};

export default ConversationsLink;
