import React from 'react';
import styled from 'styled-components';

import { MEDIA_BREAK_POINTS } from '../../constants';
import { ConversationHeaderTop } from './ConversationHeaderTop';
import { ConversationHeaderBottom } from './ConversationHeaderBottom';

const Container = styled.div`
  width: 100%;
  display: block;
  padding: 30px;
  padding-bottom: 0px;
  border-bottom: 1px solid var(--line-standard);
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--white);

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
  }
`;

export const ConversationHeader = () => (
  <Container>
    <ConversationHeaderTop />
    <ConversationHeaderBottom />
  </Container>
);
