import React from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { formatCount } from 'components/utils';
import { PageSection } from 'components/common';
import { capitalize } from 'utils/helpers';
import ComparisonCell from '../ComparisonCell';

const BenchmarkOverview = ({ data }) => {
  const { t } = useTranslation();

  return (
    <PageSection
      title="Overview"
      subtitle={`Overview data of average values for selected ${t('communities')} compared to others`}
    >
      {data && (
        <Table className="fixed-size">
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Website Visitors</Table.HeaderCell>
              <Table.HeaderCell>VSA Users</Table.HeaderCell>
              <Table.HeaderCell>{capitalize(t('leads'))}</Table.HeaderCell>
              <Table.HeaderCell>VSA User Conversion Rate</Table.HeaderCell>
              <Table.HeaderCell>Financially Unqualified</Table.HeaderCell>
              <Table.HeaderCell>Avg. {capitalize(t('move_in'))} Likelihood</Table.HeaderCell>
              <Table.HeaderCell>Requested {capitalize(t('tours'))}</Table.HeaderCell>
              <Table.HeaderCell>{capitalize(t('move_ins'))}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row textAlign="center">
              <ComparisonCell
                valueA={formatCount(data.websiteVisitors.internal)}
                valueB={formatCount(data.websiteVisitors.industry)}
                trend={data.websiteVisitors.trend}
              />
              <ComparisonCell
                valueA={formatCount(data.vsaUsers.internal)}
                valueB={formatCount(data.vsaUsers.industry)}
                trend={data.vsaUsers.trend}
              />
              <ComparisonCell
                valueA={formatCount(data.leads.internal)}
                valueB={formatCount(data.leads.industry)}
                trend={data.leads.trend}
              />
              <ComparisonCell
                valueA={data.vsaConversion.internal}
                valueB={data.vsaConversion.industry}
                trend={data.vsaConversion.trend}
              />

              <ComparisonCell
                valueA={data.financiallyUnqualified.internal}
                valueB={data.financiallyUnqualified.industry}
                trend={data.financiallyUnqualified.trend}
                ascColor="red"
                descColor="green"
              />
              <ComparisonCell
                valueA={formatCount(data.avgLeadScore.internal)}
                valueB={formatCount(data.avgLeadScore.industry)}
                trend={data.avgLeadScore.trend}
              />
              <ComparisonCell
                valueA={formatCount(data.tours.internal)}
                valueB={formatCount(data.tours.industry)}
                trend={data.tours.trend}
              />
              <ComparisonCell
                valueA={formatCount(data.moveIns.internal)}
                valueB={formatCount(data.moveIns.industry)}
                trend={data.moveIns.trend}
              />
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </PageSection>
  );
};

export default BenchmarkOverview;
