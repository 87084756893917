import React, { useContext } from 'react';
import styled from 'styled-components';
import { Dimmer, Loader } from 'semantic-ui-react';

import { STATUSES } from '../../../constants';
import { InstantAnswersContext } from './InstantAnswersContext';
import { InstantAnswersForm } from './InstantAnswersForm';

const InstantAnswersWrapper = styled.div`
  padding: 20px;
`;

export const InstantAnswersCommunity = () => {
  const { wrapperRef, exporting } = useContext(InstantAnswersContext);

  return (
    <InstantAnswersWrapper ref={wrapperRef}>
      <Dimmer active={exporting === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <InstantAnswersForm />
    </InstantAnswersWrapper>
  );
};
