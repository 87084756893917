import React, { useEffect, useRef, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import styled from 'styled-components';

import { Dropdown } from 'components/lib';
import { If } from 'components/If';
import { InputField } from 'components/common';
import { QuestionResponse } from './QuestionResponse';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;
  gap: 10px;
  padding: 25px 0;
  textarea {
    background-color: transparent;
    border: none;
    outline: none;
    font-family: var(--font-family-bold);
    font-size: 14px;
    color: #275aff;
    width: 100%;
    resize: none;
  }
  .dropdown {
    width: 210px;
    margin-top: 20px;
  }
`;

const TYPE_OF_ANSWER_OPTIONS = [
  { key: 'option', value: 'option', text: 'Customer selects one' },
  { key: 'multi_option', value: 'multi_option', text: 'Customer selects multiple' },
];

export const Question = ({ initialValues }) => {
  const [value, setValue] = useState(initialValues.question);
  const { values, setFieldValue } = useFormikContext();
  const textAreaRef = useRef(null);

  const handleTextAreaChange = (event) => {
    handleTextAreaSize();
    if (event?.target) {
      setValue(event.target.value);
      setFieldValue(`qualifyingQuestions[${index}].question`, event.target.value);
    }
  };

  const handleTextAreaSize = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0';
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  };

  useEffect(() => {
    handleTextAreaSize();
  }, []);

  const data = values?.qualifyingQuestions?.find((question) => question.id === initialValues.id);
  const index = values?.qualifyingQuestions?.findIndex((question) => question.id === initialValues.id);

  if (!data) return null;

  return (
    <Container onClick={handleTextAreaSize}>
      <textarea
        ref={textAreaRef}
        placeholder="Type your question here"
        value={value}
        autoFocus
        onChange={handleTextAreaChange}
      />
      <If condition={data.type !== 'text'}>
        <InputField
          control={Dropdown}
          name={`qualifyingQuestions[${index}].type`}
          placeholder="Select type of answer"
          selection
          basic
          options={TYPE_OF_ANSWER_OPTIONS}
        />
        <FieldArray name={`qualifyingQuestions[${index}].responses`}>
          {({ remove, push }) => (
            <QuestionResponse remove={remove} push={push} id={index} type={data.type} responses={data.responses} />
          )}
        </FieldArray>
      </If>
    </Container>
  );
};
