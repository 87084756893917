import React, { useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { Calendar } from './Calendar';

export const CalendarAvailabilityContext = React.createContext();

export const CalendarAvailability = ({ type }) => {
  const [changesKey, setChangesKey] = useState(null);

  const onCalendarChanges = () => {
    setChangesKey(Math.random());
  };

  return (
    <CalendarAvailabilityContext.Provider value={{ onCalendarChanges }}>
      <Segment.Group key={changesKey}>
        <Calendar type={type} />
      </Segment.Group>
    </CalendarAvailabilityContext.Provider>
  );
};
