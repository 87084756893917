import humps from 'humps';
import { API } from '../api';

export const getTrackingAnalytics = async () => {
  const response = await API.get('/api/chat/tracking-analytics');
  return response.data;
};

export const updateTrackingAnalytics = async (data) => {
  const response = await API.post('/api/chat/tracking-analytics', data);
  return response.data;
};

export const getChatAnalyticsIntegrations = async (chat_id) => {
  const response = await API.get(`/api/chat/tracking-analytics/${chat_id}`);
  return response.data;
};

export const applyChatAnalyticsIntegrations = async (chat_id, data) => {
  const response = await API.post(`/api/chat/tracking-analytics/${chat_id}`, data);
  return response.data;
};

const addFilter = (params, filterName, filterValue) => ({
  ...params,
  filters: { ...params.filters, [filterName]: filterValue },
});

const constructCsvParam = (filters, paramName) =>
  filters &&
  Array.isArray(filters[paramName]) && {
    [paramName]: filters[paramName].join(','),
  };

const percentage = (specific, total) => {
  if (total <= 0 || specific <= 0) {
    return 0;
  }
  return (specific / total) * 100;
};

export const getTrafficSourceFilterValues = async () => {
  const url = '/api/analytics/reports/filters/traffic-sources';
  const response = await API.get(url);
  return response.data;
};

export const getAnalytics = async (type, { public_id, filters }, cancelToken) => {
  const report = public_id || 'current';

  const params = {
    ...filters,
    ...constructCsvParam(filters, 'communities'),
    ...constructCsvParam(filters, 'groups'),
    ...constructCsvParam(filters, 'traffic_sources'),
    ...constructCsvParam(filters, 'channels'),
    ...constructCsvParam(filters, 'care_types'),
    ...constructCsvParam(filters, 'metrics'),
  };

  const response = await API.get(`/api/analytics/reports/${report}/${type}`, {
    params,
    cancelToken,
  });
  return response.data;
};

export const getReportMetrics = async (params) => getAnalytics('metrics', params);
export const getAnalyticsReport = async (params) => getAnalytics('details', params);
export const getEngagement = async (params) => getAnalytics('engagement', params);
export const getEngagementGraph = async (params) => getAnalytics('engagement-graph', params);
export const getLeadValueScores = async (params) => getAnalytics('lead-value-scores', params);
export const getLeadValueScoresGraph = async (params) => getAnalytics('lead-value-scores-graph', params);
export const getOtherStatistics = async (params) => getAnalytics('other-statistics', params);
export const getDevicesBreakdown = async (params) => getAnalytics('devices-breakdown', params);
export const getTrafficSourcesBreakdown = async (params) => getAnalytics('traffic-sources-breakdown', params);
export const getKeywordBreakdown = async (params) => getAnalytics('keyword-breakdown', params);
export const getMoveIns = async (params) => getAnalytics('move-ins', params);
export const getChatFlow = async (params, token) => getAnalytics('chat-flow', params, token);
export const getHourlyBreakdown = async (params) => getAnalytics('hourly-breakdown', params);
export const getBenchmarks = async (params) => getAnalytics('benchmarks', params);
export const getInstantAnswersAnalytics = async (params) =>
  Promise.all([
    getInstantAnswerAnalyticsMetrics(params, 'most_used_labels'),
    getInstantAnswerAnalyticsMetrics(params, 'ia_stats'),
    getInstantAnswerAnalyticsMetrics(params, 'total_vsa_interactions'),
  ]).then((responses) => {
    const labels = responses[0].most_used_labels;
    const iaStats = responses[1].ia_stats;
    const totalVsaInteractions = responses[2].total_vsa_interactions;

    let answers = 0;
    for (const l in labels) {
      if (labels[l]) {
        answers += labels[l];
      }
    }
    return {
      ia_analytics: {
        most_used_labels: labels,
        tours_booked: iaStats.tours_with_questions,
        answer_percentage: percentage(iaStats.answers, iaStats.questions),
        ia_engagement_percentage: percentage(iaStats.visitors, totalVsaInteractions),
      },
    };
  });

export const getInstantAnswerAnalyticsMetrics = async (params, metrics) =>
  getAnalytics('instant-answers', addFilter(params, 'metrics', metrics));

export const getOwnersDashboardAnalytics = async (type, { filters }) => {
  const params = {
    ...filters,
    communities: filters?.communities?.join(','),
  };

  const response = await API.get(`/api/analytics/owners/${type}`, { params });
  return response.data;
};

export const getOwnersSummary = async (params) => getOwnersDashboardAnalytics('summary', params);
export const getOwnersBreakdown = async (params) => getOwnersDashboardAnalytics('breakdown', params);

export const getSavedReports = async () => {
  const url = `api/analytics/reports`;

  const response = await API.get(url);
  return response.data;
};

export const saveAnalyticsReport = async (params) => {
  const response = await API.post(`api/analytics/reports`, params);
  return response.data;
};

export const sendReportAsPDF = async (params) => {
  const response = await API.post(`api/analytics/reports/export-pdf`, params);
  return response.data;
};

export const sendPublicReportAsPDF = async (reportId, params) => {
  const response = await API.post(`api/analytics/reports/${reportId}/export-pdf`, params);
  return response.data;
};

export const deleteReport = async (reportId) => {
  const url = `api/analytics/reports/${reportId}/details`;

  const response = await API.delete(url);
  return response.data;
};

export const saveCustomKeyword = async (keyword) => {
  const url = `api/analytics/reports/current/keyword-breakdown/save-custom-keyword`;
  const data = { keyword };

  const response = await API.post(url, data);
  return response.data;
};

export const getMoveInKeywords = async (params) =>
  Promise.all([
    getMoveInKeywordsMetrics(params, 'performing'),
    getMoveInKeywordsMetrics(params, 'no_move_ins'),
    getMoveInKeywordsMetrics(params, 'provider_profiles_count'),
  ]).then(([performing, noMoveins, counts]) => ({
    internal: {
      ...performing.internal,
      ...noMoveins.internal,
      ...counts.internal,
    },
    industry: {
      ...performing.industry,
      ...noMoveins.industry,
      ...counts.industry,
    },
  }));

export const getMoveInKeywordsMetrics = async (params, metrics) =>
  getAnalytics('move-in-keywords', addFilter(params, 'metrics', metrics));

export const getAnalyticsSettings = async () => API.get('api/analytics/settings');
export const saveAnalyticsSettings = async (data) => API.put('api/analytics/settings', data);

export const getDashboardStats = async (filters) => {
  const params = humps.decamelizeKeys(filters);

  const query = new URLSearchParams(params).toString();

  const response = await API.get(`api/analytics/move-in-stats?${query}`);
  return humps.camelizeKeys(response.data);
};

export const getDashboardStatsAccountManagers = async () => {
  const response = await API.get('api/analytics/move-in-stats/managers');
  return humps.camelizeKeys(response.data);
};

export const getDashboardStatsCustomers = async () => {
  const response = await API.get('api/analytics/move-in-stats/customers');
  return humps.camelizeKeys(response.data);
};

export const getAIOverviewMetrics = async () => {
  const response = await API.get('api/analytics/ai-overview');
  return humps.camelizeKeys(response.data);
};

export const doGetMetrics = async (endpoint, metrics, filters, groupBy, limit, timeField) => {
  const params = {
    metrics: metrics.join(','),
    ...filters,
  };
  if (groupBy) {
    params.group_by = groupBy;
  }
  if (limit) {
    params.limit = limit;
  }
  if (timeField) {
    params.time_field = timeField;
  }
  const query = new URLSearchParams(params).toString();
  const response = await API.get(`api/analytics/${endpoint}?${query}`);
  return response.data;
};

export const getMetrics = async (metrics, filters, groupBy, limit, timeField) =>
  doGetMetrics('metrics', metrics, filters, groupBy, limit, timeField);

export const getAutomatedAnswersSamples = async (filters, page = 1, pageSize = 10) => {
  const query = new URLSearchParams({ ...filters, page, page_size: pageSize }).toString();
  const response = await API.get(`api/analytics/automated-answers/samples?${query}`);
  return response.data;
};

export const getAutomatedAnswersSamplesExport = async (filters) => {
  const query = new URLSearchParams({ ...filters, export: true }).toString();
  const response = await API.get(`api/analytics/automated-answers/samples?${query}`);
  return response.data;
};
