import styled from 'styled-components';
import { ROW_TYPE_CLASS_NAME } from '../constants';

const ChatRow = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;

  &.center {
    align-items: center;
    text-align: center;

    > div {
      background-color: #ebebeb;
      color: #81888e;
    }
  }

  h6 {
    margin: 0;

    &:empty {
      display: none;
    }
  }

  &.${ROW_TYPE_CLASS_NAME.USER_EVENT} {
    margin: 10px 0;

    & + .${ROW_TYPE_CLASS_NAME.OTHER_TYPES} {
      margin-top: 20px;
    }
  }

  &.${ROW_TYPE_CLASS_NAME.OTHER_TYPES} {
    & + .${ROW_TYPE_CLASS_NAME.USER_EVENT} {
      margin-top: 20px;
    }
  }
`;

export default ChatRow;
