import React, { useState, useContext, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Divider, Button, Grid, Sticky } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { actions, useChatEditor } from 'components/chats/ChatEditorContext';
import { formatAnyDateToTimeZone } from 'components/utils';
import { updateChatById } from '../../../api';
import { UnloadPrompt } from '../UnloadPrompt';
import { ChatSettingsContext, ACTIONS } from './ChatSettingsContext';
import { BehaviorSection } from './BehaviorSection';
import { BrandingSection } from './BrandingSection';
import { GeneralSection } from './GeneralSection';
import { InstantAnswersSection } from './InstantAnswersSection';
import { FormWrapper } from '../FormWrapper';
import { ChatVisibility } from './ChatVisibility';
import { ActivateAnalytics } from './ActivateAnalytics';
import { PageHeader } from '../../common';
import ChatSaveModal from '../ChatSaveModal';

export const ChatForm = ({ chat }) => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(ChatSettingsContext);
  const [, chatEditorDispatch] = useChatEditor();
  const wrapperRef = useRef();
  const [values, setValues] = useState();
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const initialValues = {
    out_of_my_budget_alert_text: '',
    tour_slide_button_text: '',
    ...chat,
    vsa_type: chat?.vsa_type?.toUpperCase(),
    ia_proceed_message: chat.ia_proceed_message || '',
    ia_schedule_tour_button_text: chat.ia_schedule_tour_button_text || '',
    font_size: chat.font_size.replace('px', ''),
    teaser_scroll_delay: chat.teaser_scroll_delay.replace('px', ''),
    max_phone_attempts: chat.max_phone_attempts || undefined,
  };

  const validationSchema = Yup.object({
    name: Yup.string().max(100, `Can't be longer than 100 characters`).required('Required'),
    advisor_name: Yup.string().max(100, `Can't be longer than 100 characters`).required('Required'),
    intro_text: Yup.string().max(100, `Can't be longer than 100 characters`).required('Required'),
    font_family: Yup.string().max(300, `Can't be longer than 300 characters`).required('Required'),
    teaser: Yup.string().max(300, `Can't be longer than 300 characters`).required('Required'),
    pricing_gallery_text: Yup.string().max(30, `Can't be longer than 30 characters`).required('Required'),
    pricing_services_text: Yup.string().max(100, `Can't be longer than 100 characters`).required('Required'),
    pricing_type: Yup.string().max(20, `Can't be longer than 20 characters`).required('Required'),
    buttons_border_radius: Yup.number().required('Required'),
    pause: Yup.number().required('Required'),
  });

  const handleSubmit = async ({ shouldPublish }) => {
    try {
      const _chat = {
        ...values,
        font_size: `${values.font_size}px`,
        teaser_scroll_delay: `${values.teaser_scroll_delay}px`,
      };

      setLoading(true);
      const response = await updateChatById(chat.id, _chat);
      dispatch({ type: ACTIONS.SET_CHAT, payload: { chat: response } });
      setOpenSaveModal(false);
      setValues(null);
      setLoading(false);
      chatEditorDispatch({ type: actions.SET_START_VALIDATE_CHAT, payload: shouldPublish });
      toast.success('Chat settings saved');
    } catch (e) {
      toast.error('Error: something went wrong and chat settings were not saved');
      console.error(e);
      setLoading(false);
    }
  };

  const handleOpenSaveModal = (values) => {
    setOpenSaveModal(true);
    setValues(values);
  };

  const handleCancel = () => {
    setOpenSaveModal(false);
    setValues(null);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOpenSaveModal}
      enableReinitialize
    >
      {(formik) => (
        <FormWrapper ref={wrapperRef}>
          <UnloadPrompt>
            <Form onSubmit={formik.handleSubmit}>
              <Sticky context={wrapperRef}>
                <PageHeader
                  title="Chat Settings"
                  block
                  subtitle="Define how your chat looks and behaves"
                  actions={
                    <>
                      <ChatSaveModal
                        open={openSaveModal}
                        loading={loading}
                        onConfirm={handleSubmit}
                        onCancel={handleCancel}
                      />
                      <Button
                        type="submit"
                        primary
                        floated="right"
                        content="Save"
                        disabled={loading || !formik.dirty || !formik.isValid}
                      />
                      <Button as={Link} floated="right" content="Set Live" to={`/chats/${chat.id}/set-live`} />
                      <ActivateAnalytics />
                      <Button as={Link} floated="right" content="Clone" to={`/chats/${chat.id}/clone`} />
                    </>
                  }
                />
              </Sticky>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={11}>
                    <GeneralSection formik={formik} />

                    <Divider section horizontal>
                      Branding
                    </Divider>
                    <BrandingSection chat={chat} />

                    <Divider section horizontal>
                      Behavior
                    </Divider>
                    <BehaviorSection chat={chat} />
                    <Divider section horizontal>
                      Instant Answers
                    </Divider>
                    <InstantAnswersSection chat={chat} />
                  </Grid.Column>
                  <Grid.Column width={1} />
                  <Grid.Column width={4}>
                    <ChatVisibility chat={chat} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </UnloadPrompt>
        </FormWrapper>
      )}
    </Formik>
  );
};
