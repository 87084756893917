import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import humps from 'humps';

import { If } from 'components/If';
import { settingsGetCommunities, settingsUpdateCommunities } from 'apis';
import useDataList from 'utils/hooks/useDataList';
import { parseErrorResponse } from 'apis/utils';
import { formatAnyDate } from 'components/utils';
import { useLocation, useHistory } from 'react-router-dom';
import { STATUSES, PAGINATION } from '../../../constants';
import {
  CommunityRow,
  SettingsBooleanContainer,
  SettingsContainer,
  SettingsDescription,
  SettingsDropdown,
  SettingsFiltersContainer,
  SettingsHeader,
  SettingsPagination,
  SettingsSearch,
  SettingsSelectedCountModal,
  SettingsTable,
  SettingsTableBody,
  SettingsTableRow,
  SettingsTableRowContainer,
  SettingsTableToggle,
  SettingsOff,
  SettingsOn,
} from '../common';
import { useSettings } from '../context/SettingsContext';
import {
  setResetAllItemsSelected,
  setResetExcludedItems,
  setResetIsMultipleSelection,
  setResetSelectedPages,
  setSelectedItem,
  resetTableSelection,
} from '../context/actions';
import { VSABannerModal } from './VSABannerModal';

const dropdownOptions = [
  { key: '1', text: 'Any status', value: null },
  { key: '2', text: 'On', value: 'on' },
  { key: '3', text: 'Off with content', value: 'off_with_content' },
  { key: '4', text: 'On with end date', value: 'on_with_end_date' },
  { key: '5', text: 'Off', value: 'off' },
];

const flagName = 'banner_enabled';

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;
const BottomTextContainer = styled.div`
  color: #89919d;
  font-size: 12px;
  white-space: nowrap;
`;

const RightAlignedText = styled.div`
  text-align: right;
`;

export const VSABanner = () => {
  const location = useLocation();
  const history = useHistory();

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      page: searchParams.get('page') || PAGINATION.PAGE,
      pageSize: searchParams.get('pageSize') || PAGINATION.PAGE_SIZE,
      search: searchParams.get('search') || '',
      [flagName]: searchParams.get(flagName) !== null ? searchParams.get(flagName) : null,
    };
  };

  const initialFilters = getQueryParams();

  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters, totalItemsCount } = useDataList({
    initialState: {
      filters: initialFilters,
      setUrlParams: true,
    },
  });

  const [{ isMultipleSelection, selectedItems, excludedItems }, dispatch] = useSettings();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const fetchCommunities = useCallback(
    async ({ page, pageSize, search, [flagName]: flag }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const params = {
          page,
          page_size: pageSize,
          search,
        };

        if (flag !== null) {
          if (flag === 'on') {
            params[flagName] = true;
            params.banner_end_date = false;
          } else if (flag === 'on_with_end_date') {
            params[flagName] = true;
            params.banner_end_date = true;
          } else if (flag === 'off_with_content') {
            params[flagName] = false;
            params.banner_content = true;
          } else if (flag === 'off') {
            params[flagName] = false;
            params.banner_content = false;
          } else {
            params[flagName] = flag;
          }
        }

        const { results, count } = await settingsGetCommunities(params);
        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch data');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchCommunities(filters);
  }, [fetchCommunities, filters]);

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', activePage);
    history.push({ search: searchParams.toString() });
  };

  const handleDropdownChange = (e, { value }) => {
    const newFilters = { ...filters, [flagName]: value };
    setFilters(newFilters);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(flagName, value);
    history.push({ search: searchParams.toString() });
    dispatch(resetTableSelection());
  };

  const handleOnSearch = (value) => {
    setSearch(value);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('search', value);
    history.push({ search: searchParams.toString() });
    dispatch(resetTableSelection());
  };

  const handleOnRowClick = (item) => {
    if (!isMultipleSelection) {
      setIsModalOpen(true);
      dispatch(setSelectedItem({ id: item.id, items, currentPage: filters.page }));
    }
  };

  const handleOnModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetTableSelection());
  };

  const handleOnSelectedCountModalClick = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (values) => {
    setLoaderStatus(STATUSES.LOADING);
    const data = {
      include_communities: selectedItems,
      exclude_communities: excludedItems,
      ...values,
    };

    try {
      await settingsUpdateCommunities(data);
      toast.success('Settings updated successfully!');
    } catch (error) {
      const errorText = 'Trouble updating settings!';
      const msg = parseErrorResponse(error, errorText);
      console.error(msg, error?.response);
      toast.error(msg);
      setLoaderStatus(STATUSES.FAILURE);
    } finally {
      handleOnModalClose();
      dispatch(setResetExcludedItems());
      dispatch(setResetAllItemsSelected());
      dispatch(setResetSelectedPages());
      dispatch(setResetIsMultipleSelection());
      setLoaderStatus(STATUSES.LOADED);
      const newFilters = {
        page: PAGINATION.PAGE,
        page_size: PAGINATION.PAGE_SIZE,
        search: null,
        [flagName]: null,
      };
      setFilters(newFilters);
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('search');
      searchParams.delete(flagName);
      history.push({ search: searchParams.toString() });
    }
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const selectedItem = selectedItems.length === 1 ? items.find((item) => item.id === selectedItems[0]) : null;

  const getStatusText = (item) => {
    if (item.banner && item.banner?.is_enabled && !item.banner?.expires_at) {
      return (
        <StatusContainer>
          <SettingsOn />
          <BottomTextContainer>{item.banner?.content.substring(0, 50)}...</BottomTextContainer>
        </StatusContainer>
      );
    }
    if (item.banner && item.banner?.is_enabled && item.banner?.expires_at) {
      const formattedDate = formatAnyDate(item.banner?.expires_at, "MMM d yyyy 'at' hh:mm a");
      return (
        <StatusContainer>
          <SettingsOn text={`On · Ends ${formattedDate}`} />
          <If condition={item.banner.content}>
            <BottomTextContainer>{item.banner?.content.substring(0, 50)}...</BottomTextContainer>
          </If>
        </StatusContainer>
      );
    }
    if (item.banner && !item.banner?.is_enabled && item.banner?.content) {
      return (
        <StatusContainer>
          <SettingsOff />
          <BottomTextContainer>{item.banner?.content.substring(0, 50)}...</BottomTextContainer>
        </StatusContainer>
      );
    }
    return <SettingsOff />;
  };

  return (
    <SettingsContainer loading={loading} totalItemsCount={totalItemsCount}>
      <If condition={!isModalOpen}>
        <SettingsSelectedCountModal
          totalItemsCount={totalItemsCount}
          items={items}
          filters={filters}
          totalPages={totalPages}
          onClick={handleOnSelectedCountModalClick}
        />
      </If>
      <VSABannerModal
        isModalOpen={isModalOpen}
        selectedItem={selectedItem}
        totalItemsCount={totalItemsCount}
        onSave={handleSubmit}
        flagName={flagName}
        onClose={handleOnModalClose}
        isMultipleSelection={isMultipleSelection}
      />

      <SettingsDescription>
        Add a banner to show above the Virtual Sales Assistant and specify its content and duration.
      </SettingsDescription>
      <SettingsHeader>
        <SettingsTableToggle />
        <SettingsFiltersContainer>
          <SettingsDropdown
            options={dropdownOptions}
            value={filters[flagName]}
            onChange={handleDropdownChange}
            placeholder="Any status"
          />
          <SettingsSearch
            onSearch={handleOnSearch}
            value={filters.search}
            placeholder={`Search ${t('communities')}`}
            loading={loading}
          />
        </SettingsFiltersContainer>
      </SettingsHeader>
      <SettingsTable tableName={`${t('community')}`} currentPage={filters.page} items={items}>
        <SettingsTableBody>
          {items?.map((item, i) => (
            <SettingsTableRow item={item} key={i} onClick={() => handleOnRowClick(item)} items={items}>
              <SettingsTableRowContainer>
                <CommunityRow name={item.name} picture={item.picture_url} clickable={!isMultipleSelection} />
                <If condition={!isMultipleSelection}>
                  <RightAlignedText>
                    <SettingsBooleanContainer fullWidth>{getStatusText(item)}</SettingsBooleanContainer>
                  </RightAlignedText>
                </If>
              </SettingsTableRowContainer>
            </SettingsTableRow>
          ))}
        </SettingsTableBody>
      </SettingsTable>
      <SettingsPagination filters={filters} totalPages={totalPages} onPageChange={handlePageChange} />
    </SettingsContainer>
  );
};
