import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { If } from 'components/If';
import {
  getSettingsEmailIntegrations,
  updateEmailIntegrationCommunities,
  getDomains,
  createDefaultEmailSubdomain,
} from 'apis';
import useDataList from 'utils/hooks/useDataList';
import { parseErrorResponse } from 'apis/utils';
import { Button, Icon, Popup } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { STATUSES, PAGINATION } from '../../../constants';
import {
  SettingsTable,
  SettingsContainer,
  SettingsDropdown,
  SettingsFiltersContainer,
  SettingsHeader,
  SettingsSearch,
  SettingsTableBody,
  SettingsTableRow,
  SettingsPagination,
  SettingsValueText,
  SettingsTableRowContainer,
  SettingsBooleanContainer,
  SettingsSelectedCountModal,
  CommunityRow,
  SettingsDescription,
} from '../common';
import { useSettings } from '../context/SettingsContext';
import { setSelectedItem, resetTableSelection, resetState } from '../context/actions';
import { getUrlFilterAndSearch } from '../common/constants';
import { EmailAddressesModal } from './EmailAddressesModal';
import { AddEmailAddressModal } from './AddEmailAddressModal';
import { EMAIL_INTEGRATION_STATUS, EMAIl_INTEGRATION_VALUES, FURTHER } from './constants';
import { ResendInvitesModal } from './ResendInvitesModal';

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  &&& {
    min-width: auto;
    width: 92px;
    height: 40px;
    > span {
      &:last-child {
        padding-top: 5px;
      }
      &.icon {
        opacity: 1;
      }
    }
    margin-right: 10px;
  }
`;

const Options = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  width: 126px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #ffffff;
`;

const Option = styled.div`
  height: 36px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid var(--line-standard);
  }
`;

const Trigger = styled.div`
  width: 102px;
  height: 40px;
  padding: 8px 10px 8px 14.5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #fff;
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PopupStyle = {
  borderRadius: 0,
  backgroundColor: 'transparent',
  padding: 0,
  border: 'none',
  boxShadow: 'none',
};

const dropdownOptions = [
  { key: '0', text: 'Any status', value: null },
  ...Object.keys(EMAIl_INTEGRATION_VALUES).map((key, i) => ({
    key: i + 1,
    text: EMAIl_INTEGRATION_VALUES[key].text,
    value: EMAIl_INTEGRATION_VALUES[key].value,
  })),
];
const flagName = 'status';

export const EmailAddresses = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuClick = () => setMenuOpen(!menuOpen);
  const { urlFilterValue, urlSearchValue } = getUrlFilterAndSearch({ flagName });
  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters, totalItemsCount } = useDataList({
    initialState: {
      filters: {
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.PAGE_SIZE,
        search: urlSearchValue,
        [flagName]: urlFilterValue,
      },
      setUrlParams: true,
    },
  });
  const [{ isMultipleSelection, selectedItems, excludedItems }, dispatch] = useSettings();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddEmailAddressModalOpen, setIsAddEmailAddressModalOpen] = useState(false);
  const [isResendInvitesModalOpen, setIsResendInvitesModalOpen] = useState(false);
  const [emailDomains, setEmailDomains] = useState([]);
  const { t } = useTranslation();

  const fetchEmailDomains = useCallback(async () => {
    const response = await getDomains();
    const domains = response.results;
    setEmailDomains(domains);
    const hasFurtherDomain = Boolean(domains.find((domain) => domain.provider === FURTHER));
    if (!hasFurtherDomain) {
      await createDefaultEmailSubdomain();
      return fetchEmailDomains();
    }
  }, [setEmailDomains]);

  const fetchEmails = useCallback(
    async ({ page, pageSize, search, [flagName]: flag }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await getSettingsEmailIntegrations({
          page,
          page_size: pageSize,
          search,
          [flagName]: flag,
        });
        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch data');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchEmails(filters);
    fetchEmailDomains();
  }, [fetchEmails, filters, fetchEmailDomains]);

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
  };

  const handleDropdownChange = (e, { value }) => {
    setFilters({ [flagName]: value });
    dispatch(resetTableSelection());
  };

  const handleOnSearch = (value) => {
    setSearch(value);
    dispatch(resetTableSelection());
  };

  const handleOnRowClick = (data) => {
    if (!isMultipleSelection) {
      setIsModalOpen(true);
      dispatch(setSelectedItem(data));
    }
  };

  const handleOnModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetTableSelection());
  };

  const handleOnSelectedCountModalClick = () => {
    setIsModalOpen(true);
  };

  const handleOnAddEmailAddressModalClose = () => {
    setIsAddEmailAddressModalOpen(false);
  };

  const handleOnAddEmailAddressModalOpen = () => {
    setIsAddEmailAddressModalOpen(true);
  };

  const handleOnResendInvitesModalOpen = () => {
    setIsResendInvitesModalOpen(true);
  };

  const handleOnResendInvitesModalClose = () => {
    setIsResendInvitesModalOpen(false);
  };

  const handleSubmit = async (values) => {
    setLoaderStatus(STATUSES.LOADING);

    const data = {
      include_communities: selectedItems,
      exclude_communities: excludedItems,
      ...values,
    };

    try {
      await updateEmailIntegrationCommunities(data);
      toast.success('Settings updated successfully!');
    } catch (error) {
      const errorText = 'Trouble updating settings!';
      const msg = parseErrorResponse(error, errorText);
      console.error(msg, error?.response);
      toast.error(msg);
      setLoaderStatus(STATUSES.FAILURE);
    } finally {
      handleOnModalClose();
      dispatch(resetState());
      setLoaderStatus(STATUSES.LOADED);
      setFilters({
        page: PAGINATION.PAGE,
        page_size: PAGINATION.PAGE_SIZE,
        search: null,
        [flagName]: null,
      });
    }
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const selectedItem = selectedItems.length === 1 ? items?.find((item) => item.id === selectedItems[0]) : null;
  return (
    <SettingsContainer loading={loading} totalItemsCount={totalItemsCount}>
      <If condition={!isModalOpen}>
        <SettingsSelectedCountModal
          totalItemsCount={totalItemsCount}
          items={items}
          filters={filters}
          totalPages={totalPages}
          onClick={handleOnSelectedCountModalClick}
        />
      </If>
      <SettingsDescription>
        <strong> Add and manage email addresses</strong> to send campaign messages from.
      </SettingsDescription>
      <EmailAddressesModal
        isModalOpen={isModalOpen}
        selectedItem={selectedItem}
        totalItemsCount={totalItemsCount}
        onSave={handleSubmit}
        flagName={flagName}
        onClose={handleOnModalClose}
        handleOnResendInvitesModalOpen={handleOnResendInvitesModalOpen}
      />
      <AddEmailAddressModal
        isModalOpen={isAddEmailAddressModalOpen}
        onClose={handleOnAddEmailAddressModalClose}
        fetchEmails={fetchEmails}
        filters={filters}
        emailDomains={emailDomains}
      />
      <If condition={isResendInvitesModalOpen}>
        <ResendInvitesModal
          isModalOpen={isResendInvitesModalOpen}
          onClose={handleOnResendInvitesModalClose}
          items={items}
          selectedItem={selectedItem}
        />
      </If>
      <SettingsHeader>
        <ActionsWrapper>
          <StyledButton primary color="blue" onClick={handleOnAddEmailAddressModalOpen}>
            <Icon name="add" color="#ffffff" size="24" />
            <span>Add</span>
          </StyledButton>
          <If condition={items?.some((item) => item.status !== EMAIL_INTEGRATION_STATUS.active)}>
            <Popup
              trigger={
                <Trigger onClick={handleMenuClick}>
                  Actions
                  <Icon name="expand_more" color="#000000" size="24" />
                </Trigger>
              }
              position="bottom center"
              style={PopupStyle}
              basic
              offset={[12, -10]}
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
              hoverable
            >
              <Options>
                <Option onClick={handleOnResendInvitesModalOpen}>Resend Invites...</Option>
              </Options>
            </Popup>
          </If>
        </ActionsWrapper>
        <SettingsFiltersContainer>
          <SettingsDropdown
            options={dropdownOptions}
            value={filters[flagName]}
            onChange={handleDropdownChange}
            placeholder="Any status"
          />
          <SettingsSearch
            onSearch={handleOnSearch}
            value={filters.search}
            placeholder={`Search email addresses and ${t('communities')}`}
            loading={loading}
          />
        </SettingsFiltersContainer>
      </SettingsHeader>
      <SettingsTable currentPage={filters.page} items={items}>
        <SettingsTableBody>
          {items?.map((item, i) => (
            <SettingsTableRow
              item={item}
              key={i}
              onClick={() => handleOnRowClick({ id: item.id, items, currentPage: filters.page })}
              items={items}
              currentPage={filters.page}
            >
              <SettingsTableRowContainer>
                <CommunityRow key={i} name={item.email} clickable={!isMultipleSelection} hideProfilePicture />
                <If condition={!isMultipleSelection}>
                  <SettingsBooleanContainer>
                    <SettingsValueText
                      text={capitalize(EMAIl_INTEGRATION_VALUES[item.status]?.text)}
                      color={EMAIl_INTEGRATION_VALUES[item.status]?.color}
                    />
                  </SettingsBooleanContainer>
                </If>
              </SettingsTableRowContainer>
            </SettingsTableRow>
          ))}
        </SettingsTableBody>
      </SettingsTable>
      <SettingsPagination filters={filters} totalPages={totalPages} onPageChange={handlePageChange} />
    </SettingsContainer>
  );
};
