import React from 'react';
import styled from 'styled-components';

import { Button, Icon, Input } from 'components/lib';
import { InputField } from 'components/common';

const Wrapper = styled.div`
  margin-top: 20px;
`;

const RemoveresponseContainer = styled.div`
  position: absolute;
  top: calc(50% - 13px);
  right: -13px;
  height: 26px;
  width: 26px;
  display: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 10px 0 rgba(39, 90, 255, 0.1);
  background-color: #fff;
  border-radius: 50%;
`;

const QuestionResponseContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  width: ${({ width }) => width};
  &:hover ${RemoveresponseContainer} {
    display: flex;
  }
`;

const Responses = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const AddResponseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  &.multipleTypeOfAnswer {
    width: calc(50% - 10px);
  }
`;

const StyledInput = styled(Input)`
  &&&& {
    input {
      background-color: var(--white);
    }
  }
`;

export const QuestionResponse = ({ push, remove, responses, id }) => {
  const handleAddResponse = () => {
    push('');
  };

  return (
    <Wrapper>
      <Responses>
        {responses.map((_, index) => (
          <QuestionResponseContainer key={index}>
            <InputField
              control={StyledInput}
              key={index}
              name={`qualifyingQuestions[${id}].responses[${index}]`}
              fluid
            />
            <RemoveresponseContainer onClick={() => remove(index)}>
              <Icon name="close" size="14" color="#89919d" />
            </RemoveresponseContainer>
          </QuestionResponseContainer>
        ))}
      </Responses>
      <AddResponseContainer>
        <Button className="link" color="blue" onClick={handleAddResponse} type="button">
          Add option
        </Button>
      </AddResponseContainer>
    </Wrapper>
  );
};
