import React, { useContext, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import { Button, Icon } from 'components/lib';
import { If } from 'components/If';
import { useKeyPress } from 'utils/hooks';
import { ChatModulesContext } from './ChatModulesContext';
import { UserContext } from '../../UserContext';
import { VSA_TYPE } from '../constants';
import { PAGE_HEADER_MARGIN, PageHeader } from '../../common';
import ChatSaveModal from '../ChatSaveModal';
import { ChatTemplateSaveModal } from './ChatTemplateSaveModal';
import { actions, useChatEditor } from '../ChatEditorContext';
import { USER_TYPES } from '../../../constants';

const Subtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .strong {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }

  .icon {
    cursor: default;
  }
`;

export const ChatModulesHeader = () => {
  const { getSessionOriginalUser } = useContext(UserContext);
  const { moduleGraph, saveChanges, dirty, setDirty, chatTemplateModalOpen, toggleShowSaveTemplateModal } =
    useContext(ChatModulesContext);
  const [state, chatEditorDispatch] = useChatEditor();
  const { chat } = state;
  const [saving, setSaving] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const originalUser = getSessionOriginalUser();
  const isStaffUser = originalUser.type === USER_TYPES.STAFF;

  const handleSubmit = async ({ shouldPublish }) => {
    try {
      setSaving(true);
      await saveChanges();
      setDirty(false);
      setOpenSaveModal(false);
      chatEditorDispatch({ type: actions.SET_START_VALIDATE_CHAT, payload: shouldPublish });
      toast.success('Dialog flows updated');
    } catch (e) {
      console.error(e);
      toast.error('Error: something went wrong. No changes were made to dialog flows.');
    }
    setSaving(false);
  };

  const handleSave = () => {
    setOpenSaveModal(true);
  };

  const handleSaveTemplate = () => {
    toggleShowSaveTemplateModal();
  };

  const handleCancel = () => {
    setOpenSaveModal(false);
  };

  useKeyPress('ctrls', () => {
    dirty && handleSave();
  });

  const hasModules = moduleGraph?.modules?.length > 0;
  const hasAnyModuleRecordings =
    hasModules &&
    chat?.vsa_type === VSA_TYPE.PHONE &&
    moduleGraph.modules.some((module) => module.has_module_recordings);

  const subtitle = useMemo(() => {
    if (chat?.vsa_type !== VSA_TYPE.PHONE || !hasAnyModuleRecordings)
      return <Subtitle>Define how your chat interacts with visitors</Subtitle>;

    return (
      <Subtitle>
        <div>Define how your chat interacts with visitors</div>
        <div className="strong">
          <Icon name="info" size={16} variant="outlined" color="var(--salmon)" />
          <span>
            Changing flow settings deactivates&nbsp;
            <Link to={`/chats/${chat.id}/module-recordings`}>custom module recordings</Link>&nbsp;and demands your
            attention.
          </span>
        </div>
      </Subtitle>
    );
  }, [chat, hasAnyModuleRecordings]);

  return (
    <PageHeader
      as="h3"
      block
      title="Dialog Flows"
      subtitle={subtitle}
      margin={PAGE_HEADER_MARGIN.SMALL}
      actions={
        hasModules && (
          <>
            <ChatSaveModal open={openSaveModal} loading={saving} onConfirm={handleSubmit} onCancel={handleCancel} />
            <Button
              floated="right"
              primary
              color="blue"
              content="Save"
              onClick={handleSave}
              disabled={saving || !dirty}
            />
            <If condition={isStaffUser}>
              <Button
                floated="right"
                primary
                content="Save Chat Template"
                onClick={handleSaveTemplate}
                disabled={saving || dirty}
              />
              <ChatTemplateSaveModal open={chatTemplateModalOpen} />
            </If>
          </>
        )
      }
    />
  );
};
