import React, { createContext, useEffect, useReducer, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';

import {
  getMessagingCampaignById,
  getMessagingCampaignStatistics,
  getMessagingCommunities,
  createMessagingCampaign,
  updateMessagingCampaign,
  deleteMessagingCampaign,
  validateCampaignDetails,
  updateCampaignValidationDetails,
  createCampaignMessage,
  editCampaignMessage,
  deleteCampaignMessage,
  getCampaignCriteria,
  getAvailableSurveyVSAa,
  getEmailTemplates,
} from 'apis';
import { USER_TYPES, useUserContext } from 'components/UserContext';
import { CAMPAIGN_WARNINGS_TYPE, NURTURE_CAMPAIGNS_ROUTE } from '../constants';
import { PAGINATION } from '../../../constants';
import { sortCampaignMessages } from './common';

const CampaignDetailsContext = createContext();

const actions = {
  SET_CAMPAIGN: 'nurture/SET_CAMPAIGN',
  SET_MESSAGES: 'nurture/SET_MESSAGES',
  SET_CAMPAIGN_LOADING: 'nurture/SET_CAMPAIGN_LOADING',
  SET_CAMPAIGN_SAVING: 'nurture/SET_CAMPAIGN_SAVING',
  SET_CAMPAIGN_INVALID_SECTIONS: 'nurture/SET_CAMPAIGN_INVALID_SECTIONS',
  SET_STATISTICS: 'nurture/SET_STATISTICS',
  SET_AVAILABLE_SURVEYS: 'nurture/SET_AVAILABLE_SURVEYS',
  SET_SURVEY_MESSAGE_WARNINGS: 'nurture/SET_SURVEY_MESSAGE_WARNINGS',
  SET_ELIGIBLE_COMMUNITIES: 'nurture/SET_ELIGIBLE_COMMUNITIES',
  SET_ELIGIBLE_COMMUNITIES_LOADING: 'nurture/SET_ELIGIBLE_COMMUNITIES_LOADING',
  SET_CURRENT_MESSAGE: 'nurture/SET_CURRENT_MESSAGE',
  SET_MESSAGE_PLACEHOLDERS: 'nurture/SET_MESSAGE_PLACEHOLDERS',
  SET_MESSAGE_PLACEHOLDERS_LOADING: 'nurture/SET_MESSAGE_PLACEHOLDERS_LOADING',
  SET_PERMISSION: 'nurture/SET_PERMISSION',
  SET_MESSAGE_CRUD: 'nurture/SET_MESSAGE_CRUD',
  SET_IS_EDIT_MODAL_OPEN: 'nurture/SET_IS_EDIT_MODAL_OPEN',
  SET_ADD_NEW_MESSAGE: 'nurture/SET_ADD_NEW_MESSAGE',
  SET_UPDATE_MESSAGE: 'nurture/SET_UPDATE_MESSAGE',
  SET_DELETE_MESSAGE: 'nurture/SET_DELETE_MESSAGE',
  SET_CRITERIA: 'nurture/SET_CRITERIA',
  SET_IS_SELECT_TEMPLATE_MODAL_OPEN: 'nurture/SET_SELECT_TEMPLATE_MODAL_OPEN',
  SET_TEMPLATES: 'nurture/SET_TEMPLATES',
  SET_IS_PLACEHOLDERS_MODAL_OPEN: 'nurture/SET_IS_PLACEHOLDERS_MODAL_OPEN',
  SET_CURRENT_PLACEHOLDER: 'nurture/SET_CURRENT_PLACEHOLDER',
};

export const INITIAL_STATE = {
  campaign: null,
  triggerCriterion: null,
  messages: [],
  campaignLoading: false,
  campaignSaving: false,
  campaignInvalidSections: [],
  statistics: null,
  statisticsLoading: null,
  eligibleCommunities: null,
  eligibleCommunitiesLoading: false,
  availableSurveyVSAs: [],
  surveyMessageWarnings: null,
  currentMessage: null,
  placeholders: [],
  placeholdersLoading: false,
  isMesssageCrudLoading: false,
  isEditModalOpen: false,
  criteria: [],
  isSelectTemplateModalOpen: false,
  templates: {},
  isPlaceholdersModalOpen: false,
  currentPlaceholder: null,
};

function campaignDetailsReducer(state, action) {
  switch (action.type) {
    case actions.SET_CAMPAIGN: {
      const entryCriteriaRootGroup = action.data?.entryCriteriaRootGroup;
      const triggerCriterion = entryCriteriaRootGroup?.criteria.find((c) => c.isTrigger);

      return { ...state, campaign: action.data, triggerCriterion };
    }
    case actions.SET_MESSAGES: {
      return { ...state, messages: action.data };
    }
    case actions.SET_CAMPAIGN_LOADING: {
      return { ...state, campaignLoading: action.data };
    }
    case actions.SET_CAMPAIGN_SAVING: {
      return { ...state, campaignSaving: action.data };
    }
    case actions.SET_CAMPAIGN_INVALID_SECTIONS: {
      return { ...state, campaignInvalidSections: action.data };
    }
    case actions.SET_ELIGIBLE_COMMUNITIES: {
      return { ...state, eligibleCommunities: action.data };
    }
    case actions.SET_AVAILABLE_SURVEYS: {
      return { ...state, availableSurveyVSAs: action.data };
    }
    case actions.SET_SURVEY_MESSAGE_WARNINGS: {
      return { ...state, surveyMessageWarnings: action.data };
    }
    case actions.SET_ELIGIBLE_COMMUNITIES_LOADING: {
      return { ...state, eligibleCommunitiesLoading: action.data };
    }
    case actions.SET_STATISTICS: {
      return { ...state, statistics: action.data };
    }
    case actions.SET_STATISTICS_LOADING: {
      return { ...state, statisticsLoading: action.data };
    }
    case actions.SET_CURRENT_MESSAGE: {
      return { ...state, currentMessage: action.data };
    }
    case actions.SET_MESSAGE_PLACEHOLDERS: {
      return { ...state, placeholders: action.data };
    }
    case actions.SET_MESSAGE_PLACEHOLDERS_LOADING: {
      return { ...state, placeholdersLoading: action.data };
    }
    case actions.SET_MESSAGE_CRUD: {
      return { ...state, isMesssageCrudLoading: action.data };
    }
    case actions.SET_IS_EDIT_MODAL_OPEN: {
      return { ...state, isEditModalOpen: action.data };
    }
    case actions.SET_IS_SELECT_TEMPLATE_MODAL_OPEN: {
      return { ...state, isSelectTemplateModalOpen: action.data };
    }
    case actions.SET_TEMPLATES: {
      return {
        ...state,
        templates: action.data.reduce((acc, template) => {
          acc[template.id] = template;
          return acc;
        }, {}),
      };
    }
    case actions.SET_ADD_NEW_MESSAGE: {
      // we need to sort the messages by combining days, hours, minutes properties
      // if message.isNegativeTime = true we need to multiply the time by -1
      const messages = sortCampaignMessages([...state.messages, action.data]);
      return { ...state, messages };
    }
    case actions.SET_UPDATE_MESSAGE: {
      // need to update the message in the campaign messages array
      // and sort it the same way as new messages array
      const messages = sortCampaignMessages(
        state.messages.map((message) => (message.id === action.data.id ? action.data : message))
      );
      return { ...state, messages };
    }
    case actions.SET_DELETE_MESSAGE: {
      // need to delete the message in the campaign messages array
      const messages = state.messages.filter((message) => message.id !== action.data);
      return { ...state, messages };
    }
    case actions.SET_CRITERIA: {
      return { ...state, criteria: action.data };
    }
    case actions.SET_IS_PLACEHOLDERS_MODAL_OPEN: {
      return { ...state, isPlaceholdersModalOpen: action.data };
    }
    case actions.SET_CURRENT_PLACEHOLDER: {
      return { ...state, currentPlaceholder: action.data };
    }
    default:
      console.error('Unrecognized action type');
  }
}

function CampaignDetailsProvider({ children }) {
  const history = useHistory();
  const { campaignId } = useParams();
  const [state, dispatch] = useReducer(campaignDetailsReducer, INITIAL_STATE);

  const { isUserType } = useUserContext();
  const hasEditDeletePermission = isUserType([USER_TYPES.REGULAR, USER_TYPES.STAFF]);

  const fetchEligibleCommunities = async () => {
    try {
      dispatch({ type: actions.SET_ELIGIBLE_COMMUNITIES_LOADING, data: true });
      const response = await getMessagingCommunities({ pageSize: PAGINATION.MAX_PAGE_SIZE });
      const communities = response?.results?.sort((p1, p2) => p1.name.localeCompare(p2.name));
      dispatch({ type: actions.SET_ELIGIBLE_COMMUNITIES, data: communities });
      dispatch({ type: actions.SET_ELIGIBLE_COMMUNITIES_LOADING, data: false });
    } catch (e) {
      console.error(e);
      toast.error('Error fetching eligible communities');
      dispatch({ type: actions.SET_ELIGIBLE_COMMUNITIES_LOADING, data: false });
    }
  };

  const fetchAvailableSurveyVSAs = async () => {
    try {
      const response = await getAvailableSurveyVSAa();
      dispatch({ type: actions.SET_AVAILABLE_SURVEYS, data: response.results });
    } catch (e) {
      console.error(e);
      toast.error('Error fetching available survey VSAs');
    }
  };

  const fetchCriteria = async () => {
    try {
      const data = await getCampaignCriteria();
      dispatch({ type: actions.SET_CRITERIA, data });
    } catch (e) {
      console.error(e);
      toast.error('Error fetching criteria');
    }
  };

  const fetchEmailTemplates = useCallback(async () => {
    const templates = await getEmailTemplates();
    dispatch({ type: actions.SET_TEMPLATES, data: templates });
  }, []);

  const fetchCampaignDetails = async () => {
    if (!campaignId) return;
    try {
      dispatch({ type: actions.SET_CAMPAIGN_LOADING, data: true });
      const campaign = await getMessagingCampaignById(campaignId);
      dispatch({ type: actions.SET_CAMPAIGN, data: campaign });
      dispatch({ type: actions.SET_MESSAGES, data: campaign.messages || [] });
      dispatch({ type: actions.SET_CAMPAIGN_LOADING, data: false });
      await fetchEmailTemplates();
    } catch (e) {
      console.error(e);
      toast.error('Error fetching campaign details');
      dispatch({ type: actions.SET_CAMPAIGN_LOADING, data: false });
    }
  };

  const fetchCampaignStatistics = async () => {
    if (!campaignId) return;
    try {
      dispatch({ type: actions.SET_STATISTICS_LOADING, data: true });
      const campaignStatistics = await getMessagingCampaignStatistics(campaignId);
      dispatch({ type: actions.SET_STATISTICS, data: campaignStatistics });
      dispatch({ type: actions.SET_STATISTICS_LOADING, data: false });
    } catch (e) {
      console.error(e);
      toast.error('Error fetching campaign statistics');
      dispatch({ type: actions.SET_STATISTICS_LOADING, data: false });
    }
  };

  const createCampaign = async (data) => {
    if (campaignId || state?.campaign?.id) return;

    try {
      dispatch({ type: actions.SET_CAMPAIGN_SAVING, data: true });
      const campaign = await createMessagingCampaign(data);
      dispatch({ type: actions.SET_CAMPAIGN, data: campaign });
      dispatch({ type: actions.SET_CAMPAIGN_SAVING, data: false });
      toast.success('Campaign successfully saved!');

      history.replace(`${NURTURE_CAMPAIGNS_ROUTE}/${campaign.campaignType}/details/${campaign.id}`, { shallow: true });

      await validateCampaign(campaign);
    } catch (e) {
      console.error(e);
      toast.error('Error creating campaign');
      dispatch({ type: actions.SET_CAMPAIGN_SAVING, data: false });
    }
  };

  const updateCampaign = async (data, resetForm) => {
    const itemId = campaignId || state?.campaign?.id;

    if (!itemId) return;

    try {
      dispatch({ type: actions.SET_CAMPAIGN_SAVING, data: true });
      const campaign = await updateMessagingCampaign(itemId, data);
      dispatch({ type: actions.SET_CAMPAIGN, data: campaign });
      dispatch({ type: actions.SET_CAMPAIGN_SAVING, data: false });
      dispatch({ type: actions.SET_MESSAGE_PLACEHOLDERS, data: [] });
      toast.success('Campaign successfully saved!');
      resetForm();
      await validateCampaign(campaign);
    } catch (e) {
      console.error(e);
      toast.error('Error updating campaign');
      dispatch({ type: actions.SET_CAMPAIGN_SAVING, data: false });
    }
  };

  const deleteCampaign = async () => {
    const itemId = campaignId || state?.campaign?.id;

    if (!itemId) return;

    try {
      dispatch({ type: actions.SET_CAMPAIGN_SAVING, data: true });
      await deleteMessagingCampaign(itemId);

      dispatch({ type: actions.SET_CAMPAIGN, data: null });
      dispatch({ type: actions.SET_CAMPAIGN_SAVING, data: false });
      toast.success('Campaign successfully deleted!');
    } catch (e) {
      console.error(e);
      toast.error('Error deleting campaign');
      dispatch({ type: actions.SET_CAMPAIGN_SAVING, data: false });
    }
  };

  const validateCampaign = async (campaign) => {
    const itemId = campaignId || campaign?.id;

    if (!itemId) return null;

    let validationDetails = {};
    try {
      validationDetails = await validateCampaignDetails(itemId);
    } catch (e) {
      console.error(e);
      return;
    }
    // Returns the list of invalid sections
    const invalidSections = [];
    if (validationDetails.hasMissingSalesContact) {
      invalidSections.push(CAMPAIGN_WARNINGS_TYPE.CONTACT_INFO);
    }

    if (validationDetails.hasMissingEmailIntegration) {
      invalidSections.push(CAMPAIGN_WARNINGS_TYPE.EMAIL_INTEGRATION);
    }

    dispatch({ type: actions.SET_CAMPAIGN_INVALID_SECTIONS, data: invalidSections });
  };

  const updateCampaignValidations = async (data = null) => {
    const itemId = campaignId || state?.campaign?.id;
    if (!itemId) return null;

    if (!data) {
      dispatch({ type: actions.SET_CAMPAIGN_INVALID_SECTIONS, data: [] });
      return;
    }

    try {
      await updateCampaignValidationDetails(itemId, data);
      dispatch({ type: actions.SET_CAMPAIGN_INVALID_SECTIONS, data: [] });
      validateCampaign(state?.campaign);
      toast.success('Data successfully updated!');
    } catch (e) {
      console.error(e);
      toast.error('Error while saving community data');
    }
  };

  const createMessage = async (data) => {
    try {
      dispatch({ type: actions.SET_MESSAGE_CRUD, data: true });
      const message = await createCampaignMessage(campaignId, data);
      dispatch({ type: actions.SET_MESSAGE_CRUD, data: false });
      toast.success('Message successfully saved!');
      dispatch({ type: actions.SET_ADD_NEW_MESSAGE, data: message });
      dispatch({ type: actions.SET_CURRENT_MESSAGE, data: null });
      dispatch({ type: actions.SET_IS_EDIT_MODAL_OPEN, data: false });
      if (message.warnings) {
        dispatch({ type: actions.SET_SURVEY_MESSAGE_WARNINGS, data: message.warnings });
      }
    } catch (e) {
      console.error(e);
      toast.error('Error creating message!');
      dispatch({ type: actions.SET_MESSAGE_CRUD, data: false });
    }
  };

  const updateMessage = async (data) => {
    try {
      const messageId = data?.id;
      dispatch({ type: actions.SET_MESSAGE_CRUD, data: true });
      const message = await editCampaignMessage(campaignId, messageId, data);
      dispatch({ type: actions.SET_MESSAGE_CRUD, data: false });
      toast.success('Message successfully updated!');
      dispatch({ type: actions.SET_UPDATE_MESSAGE, data: message });
      dispatch({ type: actions.SET_CURRENT_MESSAGE, data: null });
      dispatch({ type: actions.SET_IS_EDIT_MODAL_OPEN, data: false });
      if (message.warnings) {
        dispatch({ type: actions.SET_SURVEY_MESSAGE_WARNINGS, data: message.warnings });
      }
    } catch (e) {
      console.error(e);
      toast.error('Error updating message!');
      dispatch({ type: actions.SET_MESSAGE_CRUD, data: false });
    }
  };

  const deleteMessage = async (data) => {
    try {
      const messageId = data?.id;
      dispatch({ type: actions.SET_MESSAGE_CRUD, data: true });
      await deleteCampaignMessage(campaignId, messageId, data);
      dispatch({ type: actions.SET_MESSAGE_CRUD, data: false });
      toast.success('Message successfully deleted!');
      dispatch({ type: actions.SET_DELETE_MESSAGE, data: messageId });
      dispatch({ type: actions.SET_CURRENT_MESSAGE, data: null });
      dispatch({ type: actions.SET_IS_EDIT_MODAL_OPEN, data: false });
    } catch (e) {
      console.error(e);
      toast.error('Error deleting message!');
      dispatch({ type: actions.SET_MESSAGE_CRUD, data: false });
    }
  };

  useEffect(() => {
    fetchCampaignDetails();
    fetchAvailableSurveyVSAs();
    fetchEligibleCommunities();
    fetchCampaignStatistics();
    fetchCriteria();
  }, []);

  const value = {
    state,
    dispatch,
    actions,
    createCampaign,
    updateCampaign,
    deleteCampaign,
    updateCampaignValidations,
    hasEditDeletePermission,
    createMessage,
    updateMessage,
    deleteMessage,
    validateCampaign,
  };
  return <CampaignDetailsContext.Provider value={value}>{children}</CampaignDetailsContext.Provider>;
}

function useCampaignDetails() {
  const context = React.useContext(CampaignDetailsContext);
  if (context === undefined) {
    throw new Error('useCampaigns must be used within a CampaignDetailsProvider');
  }
  return context;
}

export { CampaignDetailsProvider, useCampaignDetails };
