import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

const DeleteCommunitySubjectOverrideModal = ({ open, loading, onClose, onConfirm }) => (
  <Modal open={open} onClose={onClose}>
    <Modal.Header>Delete Customizations?</Modal.Header>
    <Modal.Content>
      <p>The related communities will go back using the global email subject for this email type.</p>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={onConfirm} disabled={loading} loading={loading}>
        Delete
      </Button>
      <Button onClick={onClose} disabled={loading}>
        Cancel
      </Button>
    </Modal.Actions>
  </Modal>
);

export default DeleteCommunitySubjectOverrideModal;
