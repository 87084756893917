import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import {
  ConversationFloatingReminderAlternativeDatesSent,
  ConversationFloatingReminderConfirmed,
  ConversationFloatingReminderCanceled,
} from '../actions';
import { REQUEST_EVENT_NAMES } from '../actions/constants';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const CalendarMessage = ({ data, user }) => {
  const { request_type, event_type_name } = data;
  return (
    <Container>
      <If condition={event_type_name === REQUEST_EVENT_NAMES.CONFIRMED}>
        <ConversationFloatingReminderConfirmed type={request_type} user={user} data={data} />
      </If>
      <If condition={event_type_name === REQUEST_EVENT_NAMES.RESCHEDULED}>
        <ConversationFloatingReminderAlternativeDatesSent type={request_type} user={user} data={data} />
      </If>
      <If condition={event_type_name === REQUEST_EVENT_NAMES.CANCELLED}>
        <ConversationFloatingReminderCanceled type={request_type} user={user} data={data} />
      </If>
    </Container>
  );
};
