import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { Form, Radio, Input } from 'components/lib';
import { If } from 'components/If';
import { STATUSES, MOBILE_DISPLAY_STATES } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';

const ModalBody = styled.div``;

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 10px;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 30px;
  gap: 10px;
`;

const RadioWrapperItem = styled.div`
  margin-left: 10px;
  width: 100%;
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  width: 100%;
  > span {
    margin-left: 10px;
    padding-top: 6px;
  }
`;

export const MobileDisplayModal = ({
  isModalOpen,
  selectedItem,
  flagName,
  totalItemsCount,
  onSave,
  onClose,
  isMultipleSelection,
}) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    const data = {
      [flagName]: values.mobile_display_text === String(MOBILE_DISPLAY_STATES.STRING.value) ? values.string : null,
    };

    await onSave(data);

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const initialValues = {
    mobile_display_text: Boolean(selectedItem?.[flagName])
      ? String(MOBILE_DISPLAY_STATES.STRING.value)
      : String(MOBILE_DISPLAY_STATES.PHONE_NUMBER.value),
    string: selectedItem?.[flagName] || '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue }) => {
        return (
          <SettingsModal
            loading={loading}
            open={isModalOpen}
            onModalClose={() => handleClose(resetForm)}
            size="tiny"
            width={540}
          >
            <SettingsModalHeader
              title="Mobile Display"
              community={selectedItem || null}
              totalItemsCount={totalItemsCount}
              type="header"
            />
            <ModalBody type="body">
              <BodyText>Choose how the phone number link displays on mobile versions of websites.</BodyText>
              <RadioWrapper>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name="mobile_display_text"
                    value={String(MOBILE_DISPLAY_STATES.PHONE_NUMBER.value)}
                    onChange={(e, { value }) => {
                      setFieldValue('mobile_display_text', value);
                    }}
                    checked={values.mobile_display_text === 'null'}
                  />
                  <span>{MOBILE_DISPLAY_STATES.PHONE_NUMBER.text}</span>
                </CustomRadio>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name="mobile_display_text"
                    value={String(MOBILE_DISPLAY_STATES.STRING.value)}
                    onChange={(e, { value }) => {
                      setFieldValue('mobile_display_text', value);
                    }}
                    checked={values.mobile_display_text === 'true'}
                  />
                  <span>{MOBILE_DISPLAY_STATES.STRING.text}</span>
                  <If condition={values.mobile_display_text === 'true'}>
                    <RadioWrapperItem>
                      <Form.Input
                        fluid
                        autoFocus
                        name="string"
                        control={Input}
                        value={values.string}
                        placeholder="Enter text"
                        onChange={(e, { value }) => {
                          setFieldValue('string', value);
                        }}
                      />
                    </RadioWrapperItem>
                  </If>
                </CustomRadio>
              </RadioWrapper>
            </ModalBody>
            <SettingsModalActions
              onSave={handleSubmit}
              onCancel={() => handleClose(resetForm)}
              type="actions"
              disabled={!isMultipleSelection && (!dirty || !touched)}
              saveText="Save"
            />
          </SettingsModal>
        );
      }}
    </Formik>
  );
};
