import React from 'react';
import styled from 'styled-components';

const StatisticContainer = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin: 0;
    font-family: var(--font-family);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-transform: uppercase;
  }
`;

const StatisticTitle = styled.h2`
  font-family: var(--font-family-bold);
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.43px;
  color: var(--text);
`;

const StatisticSubTitle = styled.h2`
  font-family: var(--font-family-bold);
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.43px;
  color: var(--brown-grey);
`;

const StatisticValue = styled.h2`
  color: var(--text);
  font-family: var(--font-family);
  font-size: 42px;
  line-height: 1.38;
`;

export const Statistic = ({ value = '-', title, subtitle }) => {
  return (
    <StatisticContainer>
      <StatisticTitle>{title}</StatisticTitle>
      <StatisticSubTitle>{subtitle}</StatisticSubTitle>
      <StatisticValue>{value}</StatisticValue>
    </StatisticContainer>
  );
};
