import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Dimmer, Loader } from 'components/lib';

import { getAdminUserDetails, updateAdminUser } from 'apis';
import { parseErrorResponse, parseFieldErrors } from 'apis/utils';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import { STATUSES } from '../../constants';
import UserManagementForm from './UserManagementForm';

const UserManagementEdit = () => {
  const { userId } = useParams();
  const history = useHistory();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const response = await getAdminUserDetails(userId);

        setUser(response);
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        toast.error(parseErrorResponse(e), 'Unable to fetch user details');
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    fetchUser();
  }, [userId]);

  const handleUpdateUser = async (values, { setErrors }) => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      if (values.type === 'null') {
        values.type = null;
      }

      await updateAdminUser(userId, values);

      toast.success('User updated successfully');
      setLoaderStatus(STATUSES.LOADED);

      history.push(`/admin/user-management/user/${userId}`);
    } catch (e) {
      setErrors(parseFieldErrors(e));
      toast.error(parseErrorResponse(e, 'Unable to update user.'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <If condition={loaderStatus === STATUSES.FAILURE}>
        <FailureMessage icon="search" content="Unable to fetch user details." />
      </If>

      <UserManagementForm user={user} loading={loaderStatus === STATUSES.LOADING} onSubmit={handleUpdateUser} />
    </>
  );
};

export default UserManagementEdit;
