import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header, Table, Button, Checkbox, Dimmer, Loader, Message } from 'semantic-ui-react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { getCommunitiesForLoggedUser } from 'apis';
import { capitalize } from 'utils/helpers';
import { cloneChat } from '../../api';
import { STATUSES } from '../../constants';
import { VSA_TYPE } from './constants';

const Wrapper = styled.div`
  position: relative;
  min-height: 35rem;
  .ui.header {
    margin-bottom: 4rem;
  }
`;

export const CloneChat = () => {
  const { t } = useTranslation();
  const { chatId } = useParams();
  const [communities, setCommunities] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const history = useHistory();
  const [loadingClone, setLoadingClone] = useState(false);

  useEffect(() => {
    const loadCommunities = async () => {
      setLoaderStatus(STATUSES.LOADING);
      try {
        const response = await getCommunitiesForLoggedUser({});
        if (response?.results) {
          const communities = response.results.map((p) => ({ ...p, linkToClonedChat: false }));
          setCommunities(communities);
        }
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        setLoaderStatus(STATUSES.FAILURE);
      }
    };
    loadCommunities();
  }, [chatId]);

  const submit = async () => {
    const relatedCommunities = communities.filter((p) => p.linkToClonedChat).map((p) => p.id);
    if (relatedCommunities.length === 0) {
      toast.error(`Please choose at least one ${t('community')} to link to the cloned chat.`);
    } else {
      try {
        setLoadingClone(true);
        const { id } = await cloneChat(chatId, {
          related_communities: relatedCommunities,
        });
        toast.success('Chat successfully cloned');
        history.push(`/chats/${id}`);
      } catch (e) {
        toast.error('Error: something went wrong and the chat could not be cloned.');
        setLoadingClone(false);
      }
    }
  };

  const getChatLinks = (community, vsaType) => {
    if (!community?.chat_properties) return 'NONE';

    const chatProperties = community.chat_properties.filter((property) => property.vsa_type === vsaType);

    if (chatProperties.length === 0) return 'NONE';

    return chatProperties.map((property, index) => (
      <Link key={index} to={`/chats/${property.chat}`}>
        {property.chat}&nbsp;
      </Link>
    ));
  };

  return (
    <Wrapper>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      {loaderStatus === STATUSES.LOADED && (
        <>
          <Header as="h2">
            <Header.Content>
              Clone Chat
              <Header.Subheader>
                Make a new chat based on the chat {chatId} settings. This will clone all chat settings and all dialog
                flows.
              </Header.Subheader>
            </Header.Content>
            <Button
              floated="right"
              primary
              loading={loadingClone}
              disabled={loadingClone}
              content="Clone"
              onClick={submit}
            />
          </Header>

          <Message
            info
            attached="top"
            header={`Choose Related ${capitalize(t('communities'))}`}
            content={`Choose which ${capitalize(
              t('communities')
            )} will be linked to the cloned chat. You won't be able to clone the chat unless at least one ${t(
              'community'
            )} is selected.`}
          />

          <Table singleLine attached="bottom">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>{capitalize(t('community'))} name</Table.HeaderCell>
                <Table.HeaderCell>Currently linked chat</Table.HeaderCell>
                <Table.HeaderCell>Currently linked Embedded VSAs</Table.HeaderCell>
                <Table.HeaderCell>Currently linked Voice VSAs</Table.HeaderCell>
                <Table.HeaderCell>Currently linked Survey VSAs</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {communities &&
                communities.map((community, i) => (
                  <Table.Row key={i}>
                    <Table.Cell collapsing textAlign="right">
                      <Checkbox
                        onChange={(e, d) => {
                          community.linkToClonedChat = d.checked;
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>{community?.name}</Table.Cell>
                    <Table.Cell>{getChatLinks(community, VSA_TYPE.REGULAR)}</Table.Cell>
                    <Table.Cell>{getChatLinks(community, VSA_TYPE.EMBEDDED)}</Table.Cell>
                    <Table.Cell>{getChatLinks(community, VSA_TYPE.PHONE)}</Table.Cell>
                    <Table.Cell>{getChatLinks(community, VSA_TYPE.SURVEY)}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </>
      )}

      {loaderStatus === STATUSES.FAILURE && (
        <Message negative>
          <Message.Header>Couldn't load {t('communities')}</Message.Header>
          <p>
            Something went wrong and we couldn't load available {capitalize(t('communities'))}. Please try again later.
          </p>
        </Message>
      )}
    </Wrapper>
  );
};
