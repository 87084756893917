import React from 'react';

import { MessagesContextProvider } from './MessagesContext';
import MessagesContainer from './MessagesContainer';

export const MessagesRoot = ({ conversationType }) => {
  return (
    <MessagesContextProvider key={conversationType} conversationType={conversationType}>
      <MessagesContainer />
    </MessagesContextProvider>
  );
};
