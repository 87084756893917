import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

import { Form, Input } from 'components/lib';
import { If } from 'components/If';
import { FieldLabel } from './AuthLayout';

const Container = styled(Form.Field)`
  &&&:last-of-type {
    margin-bottom: 35px;
  }
`;

const LoginStyleInput = styled(Input)`
  &&&& {
    margin-top: 10px;
    input {
      border-radius: 0;
      border: 1px solid var(--line-standard);
      background-color: var(--input-field-fill);
      padding: 6px 20px 6px 10px;
      font-size: 14px;
      height: 100%;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--input-field-fill) inset !important;
        border-color: #777 !important;
      }

      &:focus {
        border-color: #777;
      }
    }
  }
`;

const ErrorMsg = styled.div`
  color: var(--salmon);
  margin-top: 15px;
  line-height: 1;
`;

export const LoginInputField = forwardRef(({ label, onError, onChanged, ...props }, ref) => {
  const [field, meta, { setValue }] = useField(props);
  const { value, onChange, ...fieldProps } = field;

  const { touched, error } = meta;

  const handleChange = (e, d) => {
    const value = d ? d.value : e.target.value;
    setValue(value);

    if (typeof onChanged === 'function') {
      onChanged(value);
    }
  };

  const isLabelComponent = React.isValidElement(label);

  return (
    <Container>
      <If condition={isLabelComponent}>{label}</If>
      <If condition={typeof label === 'string'}>
        <FieldLabel>{label}</FieldLabel>
      </If>
      <LoginStyleInput ref={ref} {...fieldProps} {...props} error={!!(touched && error)} onChange={handleChange} />
      {touched && error && <ErrorMsg>{error}</ErrorMsg>}
    </Container>
  );
});
