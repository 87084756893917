import React, { useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { ModalFullscreen } from 'components/lib';
import { ConversationContext } from '../ConversationContext';
import { ConversationSidebarCommunityTab } from './ConversationSidebarCommunityTab';
import { SIDEBAR_TAB } from '../constants';
import { ConversationSidebarLeadTab } from './ConversationSidebarLeadTab';
import { ConversationSidebarCallsTab } from './ConversationSidebarCallsTab';
import { ConversationSidebarNavigationItems } from './ConversationSidebarNavigationItems';
import { ConversationSidebarProcessingTab } from './ConversationSidebarProcessingTab';

const Wrapper = styled.div`
  display: none;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  max-width: 370px;
  border-left: 1px solid var(--line-standard);

  &.expanded {
    display: flex;
    width: 100%;
  }
`;

const TABS = {
  [SIDEBAR_TAB.COMMUNITY]: <ConversationSidebarCommunityTab />,
  [SIDEBAR_TAB.LEAD]: <ConversationSidebarLeadTab />,
  [SIDEBAR_TAB.CALLS]: <ConversationSidebarCallsTab />,
  [SIDEBAR_TAB.PROCESSING]: <ConversationSidebarProcessingTab />,
};

export const ConversationSidebar = () => {
  const { isViewBreakpoint, sidebarExpanded, setSidebarExpanded, sidebarActiveTab } = useContext(ConversationContext);

  const drawerClasses = classNames({ expanded: sidebarExpanded && !isViewBreakpoint });

  const activeTab = TABS[sidebarActiveTab];

  if (isViewBreakpoint) {
    const onModalClose = () => {
      setSidebarExpanded(false);
    };

    return (
      <ModalFullscreen open={isViewBreakpoint && sidebarExpanded} fullscreen onClose={onModalClose}>
        <ConversationSidebarNavigationItems />
        {activeTab}
      </ModalFullscreen>
    );
  }

  return <Wrapper className={drawerClasses}>{activeTab}</Wrapper>;
};
