import React, { useState } from 'react';

import { CampaignModal, CampaignModalActions } from '../nurture/Campaigns/common';

export default function DeleteModal({ isOpen, onSave, onClose }) {
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    await onSave();
    setLoading(false);
  };

  return (
    <CampaignModal loading={loading} open={isOpen} onModalClose={onClose} size="tiny" width={540}>
      <div type="header">Delete Email Template?</div>
      <div type="body">The template will be deleted. This can not be undone.</div>
      <CampaignModalActions onSave={handleSave} onCancel={onClose} type="actions" saveText="Delete" />
    </CampaignModal>
  );
}
