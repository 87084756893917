import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Button } from 'components/lib';
import { useCampaignsTemplate } from '../CampaignsTemplateContext';
import { CAMPAIGN_BUILD_TYPES } from '../constants';
import { TEMPLATE_STEPS, AI_STEPS } from './constants';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 81px;
  border-top: 1px solid var(--line-standard);
  > button {
    &:last-child {
      margin-left: auto;
    }
  }
`;

export const WizardActions = ({ disabled, values, type }) => {
  const { state, handleNextStep, handlePreviousStep } = useCampaignsTemplate();
  const { currentStep } = state;
  const STEPS = CAMPAIGN_BUILD_TYPES.TEMPLATE === type ? TEMPLATE_STEPS : AI_STEPS;
  const step = STEPS[currentStep - 1];
  const onNextClick = () => {
    handleNextStep(values, type);
  };
  return (
    <Container>
      <If condition={step?.hasPreviousStep}>
        <Button primary onClick={handlePreviousStep}>
          Back
        </Button>
      </If>
      <Button primary color="blue" disabled={disabled} onClick={onNextClick}>
        Next
      </Button>
    </Container>
  );
};
