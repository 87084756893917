import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Divider } from 'components/lib';
import { AuthLayout } from '../AuthLayout';
import { AuthContext } from '../context';
import LoginEmailForm from './LoginEmailForm';

export const LoginEmail = () => {
  const history = useHistory();
  const { setEmail, loginQueryParams, loginLoading } = useContext(AuthContext);

  const proceedFromEmailStep = async (email) => {
    setEmail(email);
    history.push(`/login/pwd?${loginQueryParams}`);
  };

  return (
    <AuthLayout>
      <h1>Log in</h1>
      <p>Enter your email address below.</p>
      <Divider horizontal />
      <LoginEmailForm loading={loginLoading} onSubmit={proceedFromEmailStep} />
    </AuthLayout>
  );
};
