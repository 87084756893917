import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelectFilter } from 'components/common';
import { ConversationsContext } from './ConversationsContext';

export const ConversationsDropdownSources = () => {
  const { t } = useTranslation();
  const { filters, handleFiltersChange, trafficSources } = useContext(ConversationsContext);
  const [selectedTrafficSources, setSelectedTrafficSources] = useState([]);

  useEffect(() => {
    setSelectedTrafficSources(filters.trafficSources?.length > 0 ? filters.trafficSources : null);
  }, [filters.trafficSources]);

  const onTrafficSourceChange = (selectedNames) => {
    handleFiltersChange({
      trafficSources: selectedNames,
    });
  };

  const options = trafficSources ? trafficSources.map((source) => source) : [];

  return (
    <MultiSelectFilter
      name="Traffic Sources"
      placeholder="Any traffic source"
      options={options}
      selectedValues={selectedTrafficSources}
      onChange={onTrafficSourceChange}
    />
  );
};
