import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigationContext } from './NavigationContext';

export const SubNavigationPortal = ({ children }) => {
  const { subNavigationPortalRef } = useNavigationContext();
  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    setRenderCount(renderCount + 1);
  }, [subNavigationPortalRef]);

  if (!subNavigationPortalRef.current) {
    return null;
  }

  return createPortal(children, subNavigationPortalRef.current);
};
