import { API } from '../api';

export const exportPricing = async () => {
  const response = await API.get('/api/chat/pricing/import-export');
  return response.data;
};

export const importPricing = async (data) => {
  const response = await API.post('/api/chat/pricing/import-export', data);
  return response.data;
};
