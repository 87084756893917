import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { ReactComponent as CSVDownload } from 'assets/images/csv-file.svg';
import { Button, Icon, Modal } from 'components/lib';
import { parseErrorResponse } from 'apis/utils';
import { ReactComponent as FileDownload } from 'assets/images//file-download-black.svg';
import { usePhoneSystem } from './PhoneSystemContext';

const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 36px;
  box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
  border: solid 1px var(--line-standard);
  background-color: var(--white);
`;

const StyledModelHeader = styled(Modal.Header)`
  &&& {
    display: flex;
    justify-content: space-between;
    border: none;
    font-family: var(--font-family-bold);
    padding: 25px 25px 10px 30px;

    .icon {
      color: var(--light-text);
      cursor: pointer;
    }
  }
`;

const StyledModelContent = styled(Modal.Content)`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--light-text);
    font-family: var(--font-family);
    font-weight: 500;

    padding: 0px 30px 35px 30px;

    div {
      margin-bottom: 16px;
    }

    .highlight {
      color: var(--text-color);
    }

    .fake-link {
      color: var(--link);
      cursor: pointer;
      user-select: none;
    }
  }
`;

const StyledModelActions = styled(Modal.Actions)`
  &&& {
    display: flex;
    justify-content: flex-end;
    background-color: white;
    border-top: 1px solid var(--line-standard);
    padding: 20px 30px;

    .ui.button {
      height: 40px;

      &.confirm {
        width: 160px;
      }

      &.cancel {
        color: var(--text-color);
        width: 120px;
      }
    }
  }
`;

export default function CallTrackingExportModal() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { exportPhoneNumbers } = usePhoneSystem();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!loading) {
      setOpen(false);
    } else {
      toast.warning('Please wait while the file is being downloaded');
    }
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      await exportPhoneNumbers();
      handleClose();
      toast.success('Spreadsheet downloaded successfully');
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to download spreadsheet'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IconWrapper onClick={handleOpen}>
        <FileDownload />
      </IconWrapper>
      <Modal open={open} onClose={handleClose} size="tiny">
        <StyledModelHeader>
          <span>Download Spreadsheet?</span>
          <Icon name="close" onClick={handleClose} />
        </StyledModelHeader>

        <StyledModelContent>
          <div>
            The data specified with your selected filters will be downloaded as a{' '}
            <span className="highlight">.csv</span> spreadsheet file to your computer.
          </div>
          <CSVDownload />
        </StyledModelContent>

        <StyledModelActions>
          <Button className="confirm primary blue" loading={loading} disabled={loading} onClick={handleDownload}>
            Download .csv
          </Button>
          <Button className="cancel raised" disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
        </StyledModelActions>
      </Modal>
    </>
  );
}
