import React from 'react';
import styled from 'styled-components';

import { Button, Modal } from 'components/lib';

const ModalContent = styled(Modal.Content)`
  &&&& {
    padding: 30px;
  }
`;

const PromptTitle = styled.h3`
  font-family: var(--font-family-bold);
  font-size: 20px;
  padding-right: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 24px;
`;

const PromptDescription = styled.p`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--text);

  .bold {
    color: var(--text);
  }
`;

const PromptModal = ({
  open,
  subtitle,
  title,
  description,
  actionLabel,
  loading,
  disabled,
  onConfirm,
  onClose,
  actionColor = 'blue',
}) => {
  return (
    <>
      <Modal size="tiny" closeIcon open={open} onClose={onClose}>
        <ModalContent>
          <PromptTitle>
            <div>{title}</div>
            {subtitle}
          </PromptTitle>
          <PromptDescription>{description}</PromptDescription>
        </ModalContent>
        <Modal.Actions>
          <Button primary color={actionColor} onClick={onConfirm} loading={loading} disabled={loading || disabled}>
            {actionLabel || 'Confirm'}
          </Button>
          <Button basic onClick={onClose} disabled={loading}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default PromptModal;
