import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import { getCommunityMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PageSection } from 'components/common';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import useDataList from 'utils/hooks/useDataList';

import { STATUSES, PAGINATION } from '../../../constants';
import { useSystemStatsFilters } from './SystemStatsFiltersContext';
import CommunitiesTable from './CommunitiesTable';

const CommunitiesReport = () => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { items, totalPages, filters, ordering, setItems, setPage, setOrdering } = useDataList({
    initialState: { ordering: 'name' },
  });
  const { dateRange, selectedUsers, selectedCommunities } = useSystemStatsFilters();

  const fetchCommunities = async (page = 1, pageSize = PAGINATION.PAGE_SIZE) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const params = {
        ...dateRange,
        page,
        page_size: pageSize,
        ordering,
      };
      if (selectedUsers && selectedUsers.length) {
        params.sa_users = selectedUsers.join(',');
      }
      if (selectedCommunities && selectedCommunities.length) {
        params.sa_communities = selectedCommunities.join(',');
      }
      const { results, count } = await getCommunityMetrics(params);
      setItems({ results, count });
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Error fetching community metrics'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  useEffect(() => {
    if (filters.page !== 1) {
      setPage(1);
    } else {
      fetchCommunities();
    }
  }, [dateRange, selectedUsers, selectedCommunities, ordering]);

  useEffect(() => {
    fetchCommunities(filters.page);
  }, [filters]);

  return (
    <Segment basic>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <If condition={loaderStatus !== STATUSES.FAILURE}>
        <PageSection>
          <CommunitiesTable
            communities={items}
            activePage={filters.page}
            totalPages={totalPages}
            ordering={ordering}
            onPageChange={setPage}
            onOrderingChange={setOrdering}
          />
        </PageSection>
      </If>
      <If condition={loaderStatus === STATUSES.FAILURE}>
        <FailureMessage icon="search" content="Community metrics could not be loaded" />
      </If>
    </Segment>
  );
};

export default CommunitiesReport;
