import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { Button, Modal } from 'components/lib';
import conversationalIVRSetup from 'assets/images/IVRSetupImages/conversationalIVRSetup.webp';

const StyledHeader = styled(Modal.Header)`
  &&& {
    font-family: var(--font-family);
    border: none;
  }
`;

const SubHeader = styled.h6`
  margin: 0;
  font-family: var(--font-family);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #89919d;
`;

const Content = styled.div`
  padding: 0 30px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-column-gap: 25px;
`;

const Side = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ListHeading = styled.h6`
  margin: 0;
  font-family: var(--font-family-bold);
  font-size: 14px;
  color: var(--text);
`;

const List = styled.ol`
  margin: 0;
  padding: 15px;
  li {
    margin-bottom: 5px;
    padding-left: 10px;
    font-family: var(--font-family);
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #89919d;
    span {
      color: var(--link);
    }
  }
`;

const ProductImage = styled.img`
  height: 141px;
  width: 195px;
`;

export const LinkWebAssistentModal = ({ open, onClose }) => {
  const history = useHistory();
  const handleOpenWebAssistants = () => {
    history.push('/chats');
  };

  return (
    <Modal open={open} closeIcon onClose={onClose} size="tiny">
      <StyledHeader>
        How to link to a web assistant
        <SubHeader>Call tracking requires linking to a web assistant to work properly.</SubHeader>
      </StyledHeader>
      <Content>
        <Side>
          <ListHeading>For call tracking only</ListHeading>
          <List>
            <li>
              Create a web assistant with the <span>Call Tracking template</span>
            </li>
            <li> Assign the community to it</li>
          </List>
          <ListHeading>For added Conversational IVR</ListHeading>
          <List>
            <li>
              Create a web assistant with the <span>Conversational IVR template</span>
            </li>
            <li> Assign the community to it</li>
          </List>
        </Side>
        <Side>
          <ProductImage src={conversationalIVRSetup} alt="ivr-setup"></ProductImage>
        </Side>
      </Content>
      <Modal.Actions>
        <Button primary color="blue" onClick={handleOpenWebAssistants}>
          Open Web Assistants
        </Button>
        <Button basic onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
