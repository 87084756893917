import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';

const Setting = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
  color: #89919d;
`;

export const SettingsStringContainer = ({ children }) => {
  return (
    <Setting>
      {children}
      <Icon name="chevron_right" size="24" color="#89919d" />
    </Setting>
  );
};
