import React from 'react';

import { BooleanSettingsPage } from '../common';

export default function ConversionInsights() {
  return (
    <BooleanSettingsPage
      flagName="show_conversation_insights"
      modalText="Choose whether to show data such as engagement, time spent and more in emails."
      modalTitle="Conversation insights in emails"
    />
  );
}
