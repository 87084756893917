import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { DateRangePicker, MultiSelectFilter } from 'components/common';
import { Icon } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { useNurtureFilter, last30Days } from './NurtureFilterContext';

import { MEDIA_BREAK_POINTS, PAGINATION } from '../../constants';

const GroupIcon = (props) => <Icon name="local_offer" variant="outlined" {...props} />;
const COMMUNITIES = 'communities';
const GROUPS = 'groups';

export const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-flow: wrap;
  padding: 1rem;
  justify-content: space-between;
`;

const SubFilters = styled.div`
  display: flex;
  gap: 5px;
  flex-flow: nowrap;
  flex-direction: column;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
  }

  .ui.button.reset-filters {
    height: 2.53rem;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.92857143rem;
    background-color: #f9f9f9;
    border: 1px solid rgba(34, 36, 38, 0.05);

    &:hover {
      color: rgba(0, 0, 0, 0.9);
    }
  }
`;

export const NurtureFilters = ({ hideDateFilter }) => {
  const { t } = useTranslation();
  const { state, updateFilters } = useNurtureFilter();
  const { filters, communities, groups, campaigns } = state;
  const [selectedType, setSelectedType] = useState(filters.groups?.length > 0 ? GROUPS : COMMUNITIES);

  const onDateRangeChange = ({ startDate, endDate }) => {
    updateFilters({ startDate, endDate });
  };

  const onSelectedCommunitiesChange = (selectedCommunities) => {
    updateFilters({ communities: selectedCommunities, groups: [] });
  };

  const onSelectedGroupsChange = (selectedGroups) => {
    updateFilters({ groups: selectedGroups, communities: [] });
  };

  const onSelectedChange = (selected, st) => {
    setSelectedType(st);
    switch (st) {
      case GROUPS:
        onSelectedGroupsChange(selected);
        break;
      case COMMUNITIES:
        onSelectedCommunitiesChange(selected);
        break;
      default:
        break;
    }
  };

  const onSelectedCampaignsChange = (campaigns) => {
    updateFilters({ campaigns });
  };

  const resetFilters = () => {
    updateFilters({
      startDate: last30Days,
      endDate: null,
      communities: null,
      campaigns: null,
      groups: null,
    });
  };

  const options = {
    communities,
    groups: groups && groups.map((g) => ({ ...g, Icon: GroupIcon.bind(null, { color: g.color }) })),
  };

  return (
    <FilterContainer>
      {!hideDateFilter && (
        <div>
          <DateRangePicker
            dateRange={{ start_date: filters.startDate, end_date: filters.endDate }}
            onFilterChange={onDateRangeChange}
          />
        </div>
      )}
      <SubFilters>
        <MultiSelectFilter
          closeOnApply
          name={capitalize(t('communities'))}
          placeholder={`Filter ${capitalize(t('communities'))} or Groups`}
          options={options}
          selectedValues={filters && filters[selectedType]}
          onChange={onSelectedChange}
          selectedOptionTypeDefault={selectedType}
        />

        <MultiSelectFilter
          name="Campaigns"
          placeholder="Filter Campaigns"
          options={campaigns}
          selectedValues={filters && filters.campaigns}
          onChange={onSelectedCampaignsChange}
        />

        <Button icon="close" className="reset-filters" onClick={resetFilters} />
      </SubFilters>
    </FilterContainer>
  );
};
