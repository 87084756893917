import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Dimmer, Icon, Loader, Message, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { importIA } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { UploadDropzone } from 'components/common/upload/UploadDropzone';
import { STATUSES } from '../../../constants';

import { InstantAnswersContext } from './InstantAnswersContext';

export const InstantAnswersImportDialog = () => {
  const { community_id } = useParams();
  const { showImportDialog, loadCommunityDetails, setShowImportDialog, setImportErrors } =
    useContext(InstantAnswersContext);

  const [csvFile, setCsvFile] = useState(null);
  const [importingStatus, setImportingStatus] = useState(STATUSES.IDLE);

  const onImportFiles = async () => {
    try {
      setImportingStatus(STATUSES.LOADING);

      const data = new FormData();
      data.append('file', csvFile);
      const response = await importIA(community_id, data);

      setImportErrors(response?.errors || []);

      loadCommunityDetails();
      setImportingStatus(STATUSES.LOADED);
      setShowImportDialog(false);
      toast.success(`Successfully imported ${response?.imported_rows_count || ''} Instant Answers.`);
    } catch (error) {
      setImportingStatus(STATUSES.FAILURE);
      const msg = 'Trouble uploading instant answers file!';
      console.error(msg, error?.response);
      toast.error(parseErrorResponse(error, msg));
    }
  };

  return (
    <Modal
      open={showImportDialog}
      onClose={() => setShowImportDialog(false)}
      closeOnDimmerClick={importingStatus !== STATUSES.LOADING}
    >
      <Modal.Header>Import Instant Answers</Modal.Header>
      <Dimmer active={importingStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Importing...</Loader>
      </Dimmer>
      <Modal.Content>
        <Message icon warning>
          <Icon name="warning circle" />
          <Message.Content>
            Please note that that the current answers will be delete when importing new ones.
          </Message.Content>
        </Message>
        <UploadDropzone onSelected={(files) => setCsvFile(files[0])} buttonLabel="Select CSV File" />
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => setShowImportDialog(false)}>
          Cancel
        </Button>
        <Button
          content="Import"
          labelPosition="right"
          icon="cloud upload"
          onClick={onImportFiles}
          positive
          disabled={!csvFile}
        />
      </Modal.Actions>
    </Modal>
  );
};
