import { API } from '../api';

export const getWebForms = async (params) => {
  const response = await API.get('/api/web-forms', { params });
  return response.data;
};

export const getWebFormInstances = async (communityId, params) => {
  const response = await API.get(`/api/web-forms/instances/${communityId}`, { params });
  return response.data;
};

export const getWebFormMappingCandidates = async (params) => {
  const response = await API.get('/api/web-forms/mapping-candidates/', { params });
  return response.data;
};

export const getWebFormMappingCandidate = async (id) => {
  const response = await API.get(`/api/web-forms/mapping-candidates/${id}`);
  return response.data;
};

export const updateWebFormMappingCandidate = async (id, data) => {
  const response = await API.patch(`/api/web-forms/mapping-candidates/${id}`, data);
  return response.data;
};

export const validateMappings = async (mappingCandidateId, mappings) => {
  const response = await API.post(`/api/web-forms/mapping-candidates/${mappingCandidateId}/validate`, mappings);
  return response.data;
};

export const createWebForm = async (data) => {
  const response = await API.post(`/api/web-forms/`, data);
  return response.data;
};

export const captureWebFormLead = async (data) => {
  const response = await API.post(`/api/web-forms/leads`, data);
  return response.data;
};

export const getMappingTargets = async () => {
  const response = await API.get(`/api/web-forms/mapping-targets`);
  return response.data;
};

export const createMappingTarget = async (data) => {
  const response = await API.post(`/api/web-forms/mapping-targets`, data);
  return response.data;
};
