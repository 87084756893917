export const SETTINGS = {
  ON: 'on',
  ON_INCLUDING_SUBPAGES: 'on_including_subpages',
  OFF: 'off',
};

export const FILTER_OPTIONS = [
  { key: '1', text: 'Any status', value: null },
  { key: '2', text: 'On', value: SETTINGS.ON },
  { key: '3', text: 'On (including subpages)', value: SETTINGS.ON_INCLUDING_SUBPAGES },
  { key: '4', text: 'Off', value: SETTINGS.OFF },
];

export const FILTER_QUERY = {
  [SETTINGS.ON]: { strict_chat_url: true, is_fallback_chat_config: false },
  [SETTINGS.ON_INCLUDING_SUBPAGES]: { strict_chat_url: true, is_fallback_chat_config: true },
  [SETTINGS.OFF]: { strict_chat_url: false, is_fallback_chat_config: false },
};

export const FORM_OPTIONS = {
  ON: {
    value: SETTINGS.ON,
    text: 'On',
  },
  ON_INCLUDING_SUBPAGES: {
    value: SETTINGS.ON_INCLUDING_SUBPAGES,
    text: 'On (Including subpages)',
  },
  OFF: {
    value: SETTINGS.OFF,
    text: 'Off',
  },
};
