import styled from 'styled-components';
import { MEDIA_BREAK_POINTS } from '../../constants';

export const Content = styled.div`
  position: relative;
  /* max-width: 120rem; */
  width: 100%;
  min-height: 70vh;
  /* margin: 3rem auto 5rem; */
  padding: ${({ noPadding }) => (noPadding ? '0' : '1.25rem 0.5rem 3rem')};
  padding-bottom: ${({ noPadding, noBottomPadding }) => (noPadding || noBottomPadding ? '0' : '3rem')};
  background-color: #fff;

  /* border: 1px solid #e4e4e4;
  border-radius: 0.5rem;
  box-shadow: 0 5px 5px -2px rgb(190 206 234); */

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    padding: ${({ noPadding }) => (noPadding ? '0' : '1.25rem 2.5rem 3rem')};
    padding-bottom: ${({ noPadding, noBottomPadding }) => (noPadding || noBottomPadding ? '0' : '3rem')};
  }
`;
