import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { InputField } from 'components/common';
import { CRM_LOCATION_CODE_RULE_INPUT_TYPE } from '../../../constants';

const CRMLocationCodeOverrideInput = ({ name, crmLocationCodeRule }) => {
  if (crmLocationCodeRule?.input_type === CRM_LOCATION_CODE_RULE_INPUT_TYPE.OPTIONS) {
    return (
      <InputField
        control={Dropdown}
        name={name}
        selection
        options={crmLocationCodeRule.input_options.map((c) => ({
          key: c.id,
          value: c.id,
          text: c.name,
        }))}
        placeholder="Value"
      />
    );
  }

  return <InputField name={name} placeholder="Value" />;
};

export default CRMLocationCodeOverrideInput;
