import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { getMapImage } from 'apis';
import { Icon } from 'components/lib';
import { formatPhoneNumber, getGoogleMapsUrl, addProtocolToUrl } from 'utils/helpers';
import { CommunitiesContext, ACTIONS } from '../../common/communities/CommunitiesContext';
import { If } from '../../If';

const ContactContainer = styled.div`
  display: flex;
  margin-right: auto;
`;

const MapContainer = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  display: flex;
  width: 62px;
  height: 62px;
  box-shadow: 0 3px 5px 0 rgba(216, 216, 216, 0.6);
  border: solid 1px var(--line-standard);
  margin-right: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.43;
  color: #222;
  a {
    color: var(--link);
  }
`;

const AddressBox = styled.div`
  display: flex;
  align-items: center;
`;

const LocationIcon = styled(Icon)`
  margin-left: 0.3rem;
`;

export const UserCommunityHomeHeaderContact = () => {
  const { communityId } = useParams();
  const { dispatch, state } = useContext(CommunitiesContext);
  const { activeCommunity } = state;

  const [hasCoordinates, setHasCoordinates] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [website, setWebsite] = useState(undefined);
  const [googleMapLink, setGoogleMapLink] = useState(undefined);

  useEffect(() => {
    const fetchMapImage = async () => {
      try {
        const data = await getMapImage(communityId);
        dispatch({ type: ACTIONS.EDIT_COMMUNITY, payload: data });
      } catch (e) {
        console.error(e);
      }
    };

    const { address, lat, lng, city, map_img } = activeCommunity?.location || {};

    const existingCoordinates = !!lat && !!lng;
    const formattedPhoneNumber = phone && `${formatPhoneNumber(phone)} • `;
    const formattedWebsite = activeCommunity?.website?.replace(/^https?:\/\//, '');

    setHasCoordinates(existingCoordinates);
    setPhoneNumber(formattedPhoneNumber);
    setWebsite(formattedWebsite);

    if (existingCoordinates) {
      const googleMapLink = getGoogleMapsUrl(address, city?.name, city?.state.code);
      setGoogleMapLink(googleMapLink);
    }

    const canGetMapImage = map_img && (existingCoordinates || (!!address && !!city?.name && !!city?.state?.code));
    if (canGetMapImage) {
      fetchMapImage();
    }
  }, [activeCommunity]);

  const openGoogleMapLink = () => {
    window.open(googleMapLink, '_blank');
  };

  const { zip, address, city, map_img, phone } = activeCommunity?.location || {};

  return (
    <ContactContainer>
      <If condition={map_img}>
        <a href={googleMapLink} target="_blank" rel="noreferrer">
          <MapContainer img={map_img} />
        </a>
      </If>

      <ContactInfo>
        <AddressBox>
          {address}
          <If condition={hasCoordinates}>
            <LocationIcon name="my_location" size="16" variant="outlined" onClick={openGoogleMapLink} />
          </If>
        </AddressBox>
        <span>
          {zip} {city?.state?.name}
        </span>
        <span>
          {phoneNumber}
          <a href={addProtocolToUrl(activeCommunity?.website)} target="_blank" rel="noreferrer">
            {website}
          </a>
        </span>
      </ContactInfo>
    </ContactContainer>
  );
};
