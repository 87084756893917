import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { CloudSync, Save } from 'components/Icons';
import { OptionsList } from 'components/lib';

const Description = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #89919d;
`;

const SAVE_OPTIONS = [
  {
    id: 'save_and_publish',
    title: 'Save and publish',
    description: 'Changes will be saved and published immediately.',
    value: true,
    Icon: CloudSync,
  },
  {
    id: 'save',
    title: 'Save without publishing',
    description: 'Changes will be saved, but not published right now.',
    value: false,
    Icon: Save,
  },
];

const ChatSaveModal = ({ open, loading, onConfirm, onCancel }) => {
  const [isPublish, setIsPublish] = useState(true);

  useEffect(() => {
    if (open) {
      setIsPublish(true); // Set first option as selected
    }
  }, [open]);

  const handleSetOption = (value) => {
    setIsPublish(value);
  };

  const handleConfirm = () => {
    onConfirm({ shouldPublish: isPublish });
  };

  return (
    <Modal open={open} size="small">
      <Modal.Header>Publish your changes?</Modal.Header>
      <Modal.Content>
        <Description>
          You can choose to publish your changes immediately, or just save them without publishing for now.
        </Description>
        <OptionsList options={SAVE_OPTIONS} selectedOnMount={isPublish} onChange={handleSetOption} />
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleConfirm} disabled={loading} loading={loading}>
          Confirm
        </Button>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ChatSaveModal;
