import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Redirect, Route } from 'react-router-dom';
import { loginPathWithNext } from 'utils/helpers';
import { UserContext } from '../../UserContext';
import { USER_TYPES } from '../../../constants';
import { getDefaultRouteForUser } from './common';
import { useNavigationContext } from '../../navigation';

export const StaffRoute = ({ hideNavigation = false, ...props }) => {
  const { user, isImpersonatedSession, getSessionOriginalUser } = useContext(UserContext);
  const { setShowNavigation } = useNavigationContext();
  const originalUser = getSessionOriginalUser();
  const isStaffUser = originalUser?.type === USER_TYPES.STAFF;

  useEffect(() => {
    setShowNavigation(!hideNavigation);
  }, [hideNavigation]);

  if (!user) {
    toast.warning('Please log in to access the page.');
    const next = loginPathWithNext();
    return <Redirect to={next} />;
  }

  if (!isImpersonatedSession && !isStaffUser) {
    const redirectRoute = getDefaultRouteForUser(user);

    toast.warning(`Insufficient admin privileges to access ${props.location.pathname} page!`);
    return <Redirect to={redirectRoute} />;
  }

  return <Route {...props} />;
};
