import React, { useContext, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Dimmer, Loader, Message } from 'semantic-ui-react';

import { Button, Dropdown } from 'components/lib';
import SearchInput from 'components/SearchInput';
import { STATUSES, MEDIA_BREAK_POINTS } from '../../constants';
import { ChatsContext } from './ChatsContext';
import { ChatBox } from './ChatBox';
import { VSA_TYPE_LABEL } from './constants';

const Wrapper = styled.div`
  position: relative;
  min-height: 70vh;

  ul {
    margin: 0;
    padding-left: 1rem;
  }
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    min-width: 100%;
    @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      min-width: 215px;
    }
  }
`;

const StyledSearchInput = styled(SearchInput)`
  &&& {
    min-width: 100%;
    @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      min-width: 400px;
    }
  }
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-family: var(--font-family-bold);
    font-size: 38px;
    line-height: 0.97;
    color: var(--text);
    @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      font-size: 60px;
    }
  }
`;

const PageFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
  }
`;

const ChatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
  padding-bottom: 10rem;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
  }
`;

export const Chats = () => {
  const { chats, chatTypes, loadChats, fetchChatTypes, loaderStatus } = useContext(ChatsContext);
  const [selectedChatTypes, setSelectedChatTypes] = useState([]);
  const [search, setSearch] = useState('');
  const history = useHistory();

  useEffect(() => {
    loadChats();
    fetchChatTypes();
  }, []);

  useEffect(() => {
    loadChats({ vsa_type: selectedChatTypes, search });
  }, [selectedChatTypes, search]);

  const handleChatTypeChange = (e, { value }) => {
    setSelectedChatTypes(value);
  };

  const chatTypeOptions = useMemo(() => {
    if (!chatTypes) return [];

    return chatTypes.map((type) => ({
      key: type.value,
      text: VSA_TYPE_LABEL[type.value],
      value: type.value,
    }));
  }, [chatTypes]);

  const handleAdd = () => {
    history.push('/chats/create');
  };

  return (
    <Wrapper>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <PageHeader>
        <h2>Web Assistant</h2>
        <Button onClick={handleAdd} primary color="blue" content="Add" />
      </PageHeader>

      <PageFilter>
        <StyledDropdown
          value={selectedChatTypes}
          options={chatTypeOptions}
          multiple
          search
          selection
          onChange={handleChatTypeChange}
          disabled={!chatTypeOptions}
          placeholder="Any type"
        />
        <StyledSearchInput value={search} onSearch={setSearch} loading={loaderStatus === STATUSES.LOADING} />
      </PageFilter>
      {loaderStatus === STATUSES.LOADED && (
        <ChatsContainer>{chats && chats.map((chat, i) => <ChatBox key={i} chat={chat} />)}</ChatsContainer>
      )}

      {loaderStatus === STATUSES.FAILURE && (
        <Message negative>
          <Message.Header>Couldn't load Chats</Message.Header>
          <p>Something went wrong and we couldn't load your active chats. Please try again later.</p>
        </Message>
      )}
    </Wrapper>
  );
};
