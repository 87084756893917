import React, { useContext } from 'react';
import styled from 'styled-components';

import { TourDateTimeContainer } from './TourDateTimeContainer';
import { CALENDAR_REQUEST_TYPE } from './constants';
import { If } from '../../../If';
import { CheckboxField } from '../../../common';

const TourInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
`;

const TourAdditionalActions = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0 0 0;
  text-transform: none;
`;

const CalendarCreateTourInfo = ({ type, ...other }) => {
  const label = `${type} TIME & DATE`;

  return (
    <TourInfoWrapper>
      <TourDateTimeContainer label={label} {...other}>
        <TourAdditionalActions>
          <If condition={type === CALENDAR_REQUEST_TYPE.TOUR}>
            <CheckboxField name="prompt_confirmation" checked label="Ask for confirmation" />
          </If>
          <If condition={type === CALENDAR_REQUEST_TYPE.CALL}>
            <CheckboxField name="urgent" label="Call as soon as possible" />
          </If>
        </TourAdditionalActions>
      </TourDateTimeContainer>
    </TourInfoWrapper>
  );
};
export default CalendarCreateTourInfo;
