import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import { downloadFile } from 'components/utils';

const useDownloadAsCSV = () => {
  const [exportProgressStatus, setExportProgressStatus] = useState(false);

  const preparedData = (data, headers) => {
    const temp = headers ? [headers, ...data] : data;
    return temp
      .map(
        (row) =>
          row
            .map(String) // convert every value to String
            .map((v) => v.replaceAll('"', '""')) // escape double quotes
            .map((v) => `"${v}"`) // quote it
            .join(',') // comma-separated
      )
      .join('\r\n'); // rows starting on new lines
  };

  const exportAsCSV = useCallback(
    async ({ exportAction, filename, appendDateSuffix = true, data = [], headers, skipDownload = false }) => {
      setExportProgressStatus(true);

      const dateSuffix = `${format(new Date(), 'yyyymmdd_hhmmss')}`;
      const csvFilename = appendDateSuffix ? `${filename}_${dateSuffix}.csv` : `${filename}.csv`;

      try {
        const response = exportAction ? await exportAction() : preparedData(data, headers);
        if (!skipDownload) {
          downloadFile({ data: response }, csvFilename);
          toast.success('Succesfully downloaded file.');
        }
      } catch (e) {
        console.error(e);
        toast.error('Unable to download file.');
      }

      setExportProgressStatus(false);
    },
    []
  );

  return {
    exportProgressStatus,
    exportAsCSV,
  };
};

export default useDownloadAsCSV;
