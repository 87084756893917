import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Button, Dropdown, Form, Modal } from 'components/lib';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { InputField } from 'components/common';
import { LABEL_OPTIONS } from 'components/common/automated_answers/constants';
import { useAssignedReviews } from '../../common/automated_answers';

const ModalContent = styled(Modal.Content)`
  &&&& {
    font-family: var(--font-family);
    padding: 30px;
  }
`;

const PromptTitle = styled.h3`
  font-family: var(--font-family-bold);
  font-size: 20px;
  padding-right: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 24px;
`;

const ReadOnlyDropdown = styled(Dropdown)`
  pointer-events: none;
  cursor: not-allowed;
`;

const VALIDATION_SCHEMA = Yup.object({
  note: Yup.string(),
  suggested_labels: Yup.array(Yup.string()).required('Please suggest a better answer'),
});

const INITIAL_VALUES = {
  suggested_labels: [],
};

const StyledForm = styled(Form)`
  &.ui.form textarea {
    font-family: var(--font-family);
    background-color: var(--input-field-fill);
    border-radius: 0;
    resize: none;
  }
`;

const DROPDOWN_LABEL_OPTIONS = LABEL_OPTIONS.map((o) => ({ key: o.id, value: o.id, text: o.name }));

export const AutomationReviewDeclineModal = ({ open, review, onClose }) => {
  const { handleDecline } = useAssignedReviews();

  const onSubmit = async (values) => {
    await handleDecline({ review, values });
    toast.success('Your review is submitted successfully');
    onClose();
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={VALIDATION_SCHEMA}
      isInitialValid={false}
    >
      {({ isValid, values, handleBlur, handleChange, isSubmitting, handleSubmit }) => (
        <Modal size="tiny" closeIcon open={open} onClose={onClose}>
          <ModalContent>
            <PromptTitle>
              <div>Decline this automated reply?</div>
            </PromptTitle>
            <StyledForm onSubmit={handleSubmit}>
              <Form.Field>
                <label>Message</label>
                <InputField control={Form.TextArea} name="message" value={review.message} readOnly />
              </Form.Field>
              <Form.Field>
                <label>Response</label>
                <InputField control={Form.TextArea} name="message" value={review.response?.text} readOnly />
              </Form.Field>
              <InputField
                name="used_labels"
                label="Used Labels"
                value={review.used_labels}
                control={ReadOnlyDropdown}
                search
                selection
                multiple
                options={DROPDOWN_LABEL_OPTIONS}
              />
              <InputField
                name="note"
                label="Reviewer Note"
                value={values.note}
                control={Form.TextArea}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                placeholder="Tell us more about why you think this is not an acceptable reply"
              />
              <InputField
                name="suggested_labels"
                label="Suggest Labels"
                value={values.suggested_labels}
                control={Dropdown}
                search
                selection
                multiple
                options={DROPDOWN_LABEL_OPTIONS}
              />
            </StyledForm>
          </ModalContent>
          <Modal.Actions>
            <Button
              type="submit"
              primary
              color="red"
              loading={isSubmitting}
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
            >
              Decline
            </Button>
            <Button basic onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};
