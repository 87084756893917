import React, { useMemo } from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { useMutation, useQueryClient } from 'react-query';
import { ErrorMessage, FieldArray, Formik } from 'formik';
import { toast } from 'react-toastify';

import { Button, Form } from 'components/lib';
import { updateAnswer } from 'apis';
import queryConstants from 'utils/queries/constants';
import { PageHeader, PromptButton } from 'components/common';
import { If } from 'components/If';
import { UnloadPrompt } from '../../chats/UnloadPrompt';
import { Conditions } from '../../common/automated_answers';
import { Intent } from './Intent';

const useUpdateAnswers = (queryClient) =>
  useMutation({
    mutationFn: (answers) => {
      return Promise.all(answers.map(updateAnswer));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryConstants.INSTANT_RESPONSES_ANSWERS]);
    },
  });

const sortAnswers = (answers) => {
  return [...answers].sort((a, b) => (a.priority > b.priority ? -1 : 1));
};

const VALIDATION_SCHEMA = Yup.object({
  answers: Yup.array(
    Yup.object({
      text: Yup.string().required('Text is required'),
      conditions: Yup.array(Yup.object()).nullable(),
    })
  ),
});

const AnswerRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 2rem;

  .buttons {
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: 0.5rem;
    }
  }
  .answer-form {
    flex: 1;
  }
`;

export const EditIntent = ({ intent, group, showButtons = false, onCancel }) => {
  const queryClient = useQueryClient();
  const mutation = useUpdateAnswers(queryClient);
  const initialValues = useMemo(
    () => ({
      answers: group,
    }),
    [group]
  );

  const onSubmit = async (values, { resetForm }) => {
    const { answers } = values;
    // avoid updating answers that have not been changed because we keep an audit log and
    // send Slack notifications on every update
    const dirtyAnswers = answers.filter((newAnswer) => {
      const oldAnswer = group.find((o) => o.id === newAnswer.id);
      return JSON.stringify(oldAnswer) !== JSON.stringify(newAnswer);
    });
    await mutation.mutateAsync(dirtyAnswers);
    toast.success('Answer(s) updated successfully');
    resetForm({ values });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={VALIDATION_SCHEMA}>
      {({ values, handleSubmit, handleChange, handleBlur, isValid, isSubmitting }) => (
        <UnloadPrompt>
          <Form onSubmit={handleSubmit}>
            <PageHeader
              as="h2"
              title={
                <span>
                  Edit <Intent>{intent}</Intent> ({group.length})
                </span>
              }
              padding={PageHeader.PADDING.NONE}
              margin={PageHeader.MARGIN.MEDIUM}
              actions={
                <>
                  <PromptButton
                    title="Are you sure?"
                    description="Are you sure you want to update the answers?"
                    onConfirm={handleSubmit}
                    disabled={!isValid || isSubmitting}
                    triggerClassName="blue"
                    type="button"
                  >
                    Save
                  </PromptButton>
                  <Button type="button" onClick={onCancel}>
                    Cancel
                  </Button>
                </>
              }
            />
            <FieldArray name="answers">
              {({ remove, swap }) => (
                <div>
                  {sortAnswers(values.answers).map((answer, index) => (
                    <AnswerRow key={index}>
                      <If condition={showButtons}>
                        <div className="buttons">
                          <Button.Group basic compact vertical size="mini">
                            <Button icon="caret up" onClick={() => swap(index, index - 1)}></Button>
                            <Button icon="caret down" onClick={() => swap(index, index + 1)}></Button>
                          </Button.Group>
                          <Button.Group negative compact vertical size="mini">
                            <Button
                              icon="trash alternate outline"
                              negative
                              compact
                              size="mini"
                              onClick={() => remove(index)}
                            />
                          </Button.Group>
                        </div>
                      </If>
                      <div className="answer-form">
                        <Form.Field>
                          <Conditions value={values.answers[index].conditions} />
                        </Form.Field>
                        {values.answers[index].community ? (
                          <Form.Field>
                            <label>Community</label>
                            {values.answers[index].community?.id} ({values.answers[index].community?.name})
                          </Form.Field>
                        ) : null}
                        {values.answers[index].user ? (
                          <Form.Field>
                            <label>User</label>
                            {values.answers[index].user?.id} ({values.answers[index].user?.email})
                          </Form.Field>
                        ) : null}
                        <Form.TextArea
                          label="Text"
                          name={`answers.${index}.text`}
                          value={values.answers[index].text}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage name={`answers.${index}.text`} component="div" className="field-error" />
                      </div>
                    </AnswerRow>
                  ))}
                </div>
              )}
            </FieldArray>
          </Form>
        </UnloadPrompt>
      )}
    </Formik>
  );
};
