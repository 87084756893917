import React from 'react';

import ScheduledMessageProvider from './ScheduledMessageContext';
import ScheduledMessages from './ScheduledMessages';

const ScheduledMessageRoot = () => (
  <ScheduledMessageProvider>
    <ScheduledMessages />
  </ScheduledMessageProvider>
);

export default ScheduledMessageRoot;
