import React from 'react';

import CRMContextProvider from './context/CRMContext';
import CRMRoot from './CRMRoot';

export const CRM = () => (
  <CRMContextProvider>
    <CRMRoot />
  </CRMContextProvider>
);
