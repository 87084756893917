import { If } from 'components/If';
import { Icon, Loader, Popup } from 'components/lib';
import React from 'react';
import styled from 'styled-components';

const ComponentWrapper = styled.div`
  width: 100%;
  .title {
    font-size: 1.1rem;
    margin-right: 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0.5rem;

    span.icon {
      margin-right: 10px;
    }
  }

  .content {
    height: ${(p) => p.contentHeight};
    margin: ${(p) => p.contentMargins};
    overflow: hidden;
  }
`;

const ReportComponent = ({
  title,
  children,
  loading = false,
  tooltip,
  tooltipPosition = 'bottom left',
  contentHeight = 'auto',
  contentMargins = '0',
}) => {
  return (
    <ComponentWrapper contentHeight={contentHeight} contentMargins={contentMargins}>
      <div className="title">
        {tooltip && (
          <Popup wide content={tooltip} position={tooltipPosition} trigger={<Icon name="info" size="15" />} />
        )}
        {title}
      </div>
      <div className="content">
        <Loader active={loading} size="large" overlay inverted />
        {children}
      </div>
    </ComponentWrapper>
  );
};

export default ReportComponent;
