import { API } from '../api';
import { getChecksum } from './utils';

export const getCalendars = async (communityId) => {
  const response = await API.get(`/api/tours/calendars/${communityId}`);
  return response.data;
};

export const getTourByHash = async (tourHash) => {
  const response = await API.get(`/api/tours/${tourHash}`);
  return response.data;
};

export const getAuthentication = async (communityId, redirectUrl) => {
  const response = await API.get(`/api/tours/authenticate/${communityId}?redirect=${redirectUrl}`);
  return response.data;
};

export const createTour = async (leadId, data) => {
  const response = await API.post(`/api/tours/create/${leadId}`, data);
  return response.data;
};

export const confirmTour = async (tourHash, data) => {
  const response = await API.put(`/api/tours/confirm/${tourHash}`, data);
  return response.data;
};

export const updateTour = async (tourHash, data) => {
  const response = await API.put(`/api/tours/${tourHash}`, data);
  return response.data;
};

export const rescheduleTour = async (tourHash, data) => {
  const response = await API.put(`/api/tours/reschedule/${tourHash}`, data);
  return response.data;
};

export const sendReminder = async (tourHash, data) => {
  const response = await API.post(`/api/tours/reminder/${tourHash}`, data);
  return response.data;
};

/**
 * This particular API route is protected via checksum header.
 */
export const calendarAvailability = async (communityId, data = {}) => {
  const response = await API.post(`/api/tours/calendar-availability/${communityId}`, data, {
    headers: { 'X-CHECKSUM': getChecksum(data) },
  });
  return response.data;
};
