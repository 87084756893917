import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { If } from 'components/If';
import { Button, Icon, Dimmer, Loader } from 'components/lib';
import { parseErrorResponse } from 'apis/utils';
import { getIntegrationCheck } from 'apis';
import { STATUSES, TALK_FURTHER } from '../../../constants';

const Page = styled.div`
  display: flex;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Heading = styled.h1`
  font-family: Avenir;
  font-size: 40px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  margin: 20px 0 0 0;
`;

const Description = styled.p`
  max-width: 490px;
  margin: 20px auto;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
`;

const Link = styled.a`
  color: var(--link);
`;

const StyledButton = styled(Button)`
  width: 300px;
  height: 54px;
  margin: 40px 95px 0;
  font-size: 16px;
`;

export const EmailAuthentication = () => {
  const [isSuccesful, setIsSuccesful] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const history = useHistory();
  const location = useLocation();
  const { provider } = useParams();

  const query = useMemo(() => new URLSearchParams(location.search), [location]);
  const inviteCode = query.get('invite_code');
  const code = query.get('code');

  const fetchRedirectUrl = async (inviteCode) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const response = await getIntegrationCheck({ inviteCode, provider });
      const { redirectUrl } = response;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      setIsSuccesful(false);
      console.error(e);
      toast.error(parseErrorResponse(e), 'Unable to fetch redirect URL!');
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const checkIntegration = async (inviteCode, code) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const response = await getIntegrationCheck({ provider, code });
      const { emailIntegration } = response;
      if (emailIntegration) {
        setIsSuccesful(true);
      } else {
        setIsSuccesful(false);
      }
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      setIsSuccesful(false);
      console.error(e);
      toast.error(parseErrorResponse(e), 'Unable to check integration!');
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  useEffect(() => {
    if (code) {
      checkIntegration(inviteCode, code);
    } else {
      fetchRedirectUrl(inviteCode);
    }
  }, [inviteCode, code]);

  const headingText = isSuccesful ? 'Email Authentication Successful' : 'Email Authentication Failed';
  const descriptionText = isSuccesful ? (
    'Use the Marketing Email page on Settings to assign this email address to your communities. You can create email campaigns on the Messaging page.'
  ) : (
    <span>
      Something went wrong, and we could not authenticate your email address. Please try again, and if the problem
      persists, <Link href={`mailto:${TALK_FURTHER.SUPPORT_EMAIL}`}>contact support</Link>.
    </span>
  );
  const buttonText = 'Go to the Further Dashboard';
  const icon = {
    name: isSuccesful ? 'check_circle' : 'error_outline',
    color: isSuccesful ? '#0ab776' : '#fa7550',
  };

  const handleClick = () => {
    history.push('/login');
  };
  const loading = loaderStatus === STATUSES.LOADING;
  return (
    <Page>
      <Dimmer active={loading} inverted>
        <Loader active inverted />
      </Dimmer>
      <If condition={isSuccesful !== null}>
        <Icon name={icon.name} size={40} color={icon.color} variant="outlined" />
        <Heading>{headingText}</Heading>
        <Description>{descriptionText}</Description>
        <StyledButton primary color="blue" onClick={handleClick}>
          {buttonText}
        </StyledButton>
      </If>
    </Page>
  );
};
