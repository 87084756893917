import React, { useContext, useEffect, useRef, useState } from 'react';

import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Form, Sticky } from 'semantic-ui-react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { getAnalyticsSettings, saveAnalyticsSettings } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PageHeader } from 'components/common';
import { Button, Message } from 'components/lib';
import { UserContext } from 'components/UserContext';
import { STATUSES, USER_TYPES } from 'constants';
import MappingRules, { URL_MAPPING_TYPES } from '../MappingRules';

const Wrapper = styled.div`
  position: relative;
  min-height: 60vh;
  padding: 17px 30px 0 30px;

  h4.ui.header {
    color: #666;
  }
`;

const VALIDATION_SCHEMA = Yup.object({
  source_mappings: Yup.array()
    .notRequired()
    .of(
      Yup.object({
        mapping_type: Yup.string().required('Value is required'),
        result_value: Yup.string().when('mapping_type', {
          is: (mapping_type) =>
            [URL_MAPPING_TYPES.URL_PARAMETERS, URL_MAPPING_TYPES.URL_LOCATION].includes(mapping_type),
          then: Yup.string().required('Values are required'),
        }),
        keyword: Yup.string().when('mapping_type', {
          is: (mapping_type) =>
            [URL_MAPPING_TYPES.URL_PARAMETERS_MATCH, URL_MAPPING_TYPES.URL_LOCATION].includes(mapping_type),
          then: Yup.string().required('Values are required'),
        }),
        clauses: Yup.array().when('mapping_type', {
          is: URL_MAPPING_TYPES.URL_PARAMETERS,
          then: Yup.array()
            .of(
              Yup.object({
                key_value: Yup.string().required('Key value is required'),
                keyword: Yup.string().required('Keyword value is required'),
              })
            )
            .required(),
        }),
      })
    ),
});

export const AnalyticsSettings = () => {
  const wrapperRef = useRef();
  const { user, getSessionOriginalUser } = useContext(UserContext);
  const originalUser = getSessionOriginalUser();
  const disableMappingChanges = !(originalUser && originalUser.type === USER_TYPES.STAFF);
  const [initialValues, setInitialValues] = useState();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  useEffect(() => {
    const fetchAnalyticsSettings = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const response = await getAnalyticsSettings();
        setInitialValues(response.data);
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e, 'Unable to load analytics settings.'));
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    if (user) {
      fetchAnalyticsSettings();
    }
  }, [user]);

  const loading = loaderStatus === STATUSES.LOADING;
  const onSubmit = async (settings) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const res = await saveAnalyticsSettings(settings);
      toast.success('Analytics settings are applied. It might take up to 24h to make those changes visible in reports');
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e, 'Unable to save analytics settings.'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={VALIDATION_SCHEMA} enableReinitialize>
      {({ dirty, handleSubmit }) => (
        <Form loading={loading} onSubmit={handleSubmit}>
          <Wrapper ref={wrapperRef}>
            <Sticky context={wrapperRef}>
              <PageHeader
                title="Analytics Options"
                subtitle="Define custom mappings for traffic sources analytics"
                block
                actions={<Button primary type="submit" content="Save" loading={loading} disabled={!dirty} />}
              />
            </Sticky>
            <Message
              warning
              visible={disableMappingChanges}
              size="tiny"
              header="Changing mapping rules is not allowed"
              content={
                <>
                  If you notice something is wrong with your rules or you want to set up rules, please contanct us at
                  <a href="mailto:support@talkfurther.com"> support@talkfurther.com</a>
                </>
              }
            />
            <MappingRules disabled={disableMappingChanges} fieldName="traffic_source_mappings" />
          </Wrapper>
        </Form>
      )}
    </Formik>
  );
};
