import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

import { If } from 'components/If';
import { Icon, Dropdown } from 'components/lib';

const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown {
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
    border: solid 1px var(--line-standard);
    border-radius: 0px;
    background-color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
    padding: 5px;
    margin-right: 3px;
    min-width: 120px;
    width: 120px;
    min-height: 36px;
    height: 36px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      top: -2px;
      right: 2px;
    }

    .default {
      color: var(--text-color);
    }

    .menu {
      max-height: 70vh;
      z-index: 10000; // prevents Dimer/Loader from obscuring the dropdown menu
      box-shadow: 0 8px 17px -4px rgba(0, 0, 0, 0.2);
      min-width: 120px;
      width: 120px;

      .item {
        padding: 5px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        border: solid 1px var(--line-standard);
      }
    }
  }
`;

const DropdownItemOption = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  .text {
    position: relative;
    top: 2px;
  }
`;

const ActiveStatusDropdownOption = () => (
  <DropdownItemOption>
    <Icon name="play_arrow" size="24" color="var(--emerald-green)" />
    <div className="text">Active</div>
  </DropdownItemOption>
);

const InactiveStatusDropdownOption = () => (
  <DropdownItemOption>
    <Icon name="pause" size="24" color="var(--light-text)" />
    <div className="text">Inactive</div>
  </DropdownItemOption>
);

export const StatusDropdownField = (props) => {
  const { campaignId, onDeleteCampaign, hasDeletePermission, disabled, hasErrors, updateCampaignStatus, ...rest } =
    props;
  const [, meta] = useField(rest);
  const { value } = meta;

  const onSelect = async (_, d) => {
    const value = d.value;
    if ((value && !hasErrors) || !value) {
      await updateCampaignStatus(value);
    }
  };

  const getDropdownText = () => {
    switch (value) {
      case true:
        return <ActiveStatusDropdownOption />;
      case false:
        return <InactiveStatusDropdownOption />;
      default:
        if (!campaignId) return <InactiveStatusDropdownOption />;
        return '';
    }
  };

  return (
    <StyledDropdown disabled={disabled} {...rest} text={getDropdownText()}>
      <StyledDropdown.Menu>
        <If condition={value !== true}>
          <StyledDropdown.Item onClick={onSelect} value={true} disabled={hasErrors}>
            <Icon name="play_arrow" size="24" color="#0ab776" /> <span>Active</span>
          </StyledDropdown.Item>
        </If>
        <If condition={value !== false}>
          <StyledDropdown.Item active={value === false} onClick={onSelect} value={false}>
            <Icon name="pause" size="24" color="#89919d" /> <span>Inactive</span>
          </StyledDropdown.Item>
        </If>
        <If condition={!!campaignId && hasDeletePermission}>
          <StyledDropdown.Item onClick={onDeleteCampaign}>
            <Icon name="delete" size="24" color="#fa7550" /> <span>Delete</span>
          </StyledDropdown.Item>
        </If>
      </StyledDropdown.Menu>
    </StyledDropdown>
  );
};
