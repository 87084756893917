import React, { createContext, useEffect, useState } from 'react';
import { STATUSES } from '../../constants';
import { getAllChats, getChatTypes } from '../../api';

export const ChatsContext = createContext();

export const ChatsContextProvider = (props) => {
  const [chats, setChats] = useState(null);
  const [chatTypes, setChatTypes] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const [analytics, setAnalytics] = useState(null);
  const [chatIntegration, setChatIntegrations] = useState(null);

  const fetchChatTypes = async () => {
    setLoaderStatus(STATUSES.LOADING);
    try {
      const response = await getChatTypes();
      setChatTypes(response);
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const loadChats = async (params) => {
    setLoaderStatus(STATUSES.LOADING);
    try {
      const response = await getAllChats(params);
      if (response?.results) {
        const chats = response.results;
        setChats(chats);
      }
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const addAnalytics = (data) => {
    try {
      setChats((prevChats) => {
        const newChats = prevChats.map((chat) => {
          if (chat.id === data.id) {
            chat.analytics = data.chat_engagement;
          }
          return chat;
        });
        return newChats;
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ChatsContext.Provider
      {...props}
      value={{
        chats,
        chatTypes,
        loaderStatus,
        analytics,
        chatIntegration,
        fetchChatTypes,
        setChats,
        loadChats,
        setLoaderStatus,
        setAnalytics,
        setChatIntegrations,
        addAnalytics,
      }}
    />
  );
};
