import React from 'react';
import styled from 'styled-components';

import { Dropdown } from 'components/lib';

const options = [10, 15, 20, 50].map((limit) => ({
  key: limit.toString(),
  text: `Show Top ${limit}`,
  value: limit,
  content: limit.toString(),
}));

const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const TopNGraphTitle = ({ title, onChangeLimit, defaultLimit, hideLimitSelect }) => {
  return (
    <Container>
      <span>{title}</span>
      {!hideLimitSelect ? (
        <Dropdown
          basic
          selection
          options={options}
          defaultValue={defaultLimit}
          onChange={(e, { value }) => onChangeLimit(value)}
        />
      ) : null}
    </Container>
  );
};
