import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';

const Wrapper = styled.div`
  width: fit-content;
  padding: 5px 5px 3px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: ${({ $backgroundColor }) => ($backgroundColor === 'white' ? 'solid 1px #d7d7d7' : 'none')};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  display: flex;
  align-items: center;
  gap: 5px;

  .text {
    text-transform: uppercase;
    font-family: var(--font-family-bold);
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.42px;
    color: ${({ $color }) => $color};
  }
`;
export const ExperimentTypeBadge = ({ experimentType }) => {
  const { iconName, color, badgeColor, text } = experimentType;
  return (
    <Wrapper $color={color} $backgroundColor={badgeColor}>
      <Icon name={iconName} size={12} color={color} variant="outlined" />
      <span className="text">{text}</span>
    </Wrapper>
  );
};
