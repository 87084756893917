import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormikContext, getIn } from 'formik';
import * as Yup from 'yup';

import { Input, Grid } from 'components/lib';
import { InputField } from 'components/common';
import { CommunityNameBox } from '../common';

const StyledInputField = styled(InputField)`
  &&&&& {
    & .input {
      height: 100%;
      ${({ outlineError }) => outlineError && `border: 1px solid var(--warning);`}
    }
    input {
      width: 100%;
      height: 100%;
      border-radius: 0;
      border: 1px solid var(--line-standard);
      background-color: var(--input-field-fill);
      padding: 6px 6px 6px 10px;
      font-size: 12px;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--input-field-fill) inset !important;
        border-color: #777 !important;
      }

      &:focus {
        border-color: #777;
      }
    }
  }
`;

const BASE_FIRST_NAME_FIELD = 'salesFirstName';
const BASE_SALES_EMAIL_FIELD = 'primarySalesEmail';
const BASE_SALES_PHONE_FIELD = 'primarySalesPhone';

export const TableContentRow = ({ community, index }) => {
  const { initialValues } = useFormikContext();
  const [hasFirstNameError, setHasFirstNameError] = useState(false);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [hasPhoneError, setHasPhoneError] = useState(false);

  const baseFieldName = `salesContacts[${index}]`;

  const validateField = async (name, value, validatorObj) => {
    const innerValue = value || '';
    const initialValue = getIn(initialValues, `${baseFieldName}.${name}`) || '';
    if (innerValue === initialValue) return undefined;

    try {
      await validatorObj.validate(value);
      return undefined;
    } catch (err) {
      return err.message;
    }
  };

  const validateNameField = (name) => async (value) => {
    const errorMsg = 'Name is required';
    const yupObj = Yup.string().required(errorMsg);

    const val = await validateField(name, value, yupObj);
    setHasFirstNameError(!!val);

    return val;
  };

  const validateEmailField = (name) => async (value) => {
    const errorMsg = 'Must be a valid email address';
    const yupObj = Yup.string().email(errorMsg).required(errorMsg);

    const val = await validateField(name, value, yupObj);
    setHasEmailError(!!val);

    return val;
  };

  const validatePhoneField = (name) => async (value) => {
    const errorMsg = 'Individual phone numbers cannot be longer than 20 characters';
    const yupObj = Yup.string().max(20, errorMsg).required(errorMsg);

    const val = await validateField(name, value, yupObj);
    setHasPhoneError(!!val);

    return val;
  };

  return (
    <Grid.Row key={community.id} className="no-padding">
      <Grid.Column width={7}>
        <CommunityNameBox name={community.name} />
      </Grid.Column>
      <Grid.Column width={3}>
        <StyledInputField
          control={Input}
          name={`${baseFieldName}.${BASE_FIRST_NAME_FIELD}`}
          placeholder="Enter Name"
          type="text"
          hideError
          outlineError={hasFirstNameError}
          validate={validateNameField(BASE_FIRST_NAME_FIELD)}
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <StyledInputField
          control={Input}
          name={`${baseFieldName}.${BASE_SALES_EMAIL_FIELD}`}
          placeholder="Enter Email"
          type="text"
          hideError
          outlineError={hasEmailError}
          validate={validateEmailField(BASE_SALES_EMAIL_FIELD)}
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <StyledInputField
          control={Input}
          name={`${baseFieldName}.${BASE_SALES_PHONE_FIELD}`}
          placeholder="Enter Phone"
          type="text"
          hideError
          outlineError={hasPhoneError}
          validate={validatePhoneField(BASE_SALES_PHONE_FIELD)}
        />
      </Grid.Column>
    </Grid.Row>
  );
};
