import React, { useContext } from 'react';

import { Dropdown } from 'components/lib';
import ScheduleButton from './ScheduleButton';
import { USER_TYPES, UserContext } from '../../UserContext';
import { CONVERSATION_EVENT } from './constants';

const ConversationActions = () => {
  const { user } = useContext(UserContext);
  const actionsPreviewMode = [USER_TYPES.REGIONAL, USER_TYPES.REGULAR, USER_TYPES.LABELING].includes(user.type);

  // For regular and 'regional' type users disable all actions related to tours and calls
  return (
    <>
      <Dropdown.Header content="Scheduling" />
      <ScheduleButton disabled={actionsPreviewMode} type={CONVERSATION_EVENT.TOUR} />
      <ScheduleButton disabled={actionsPreviewMode} type={CONVERSATION_EVENT.CALL} />
      <ScheduleButton type={CONVERSATION_EVENT.REMINDER} />
    </>
  );
};

export default ConversationActions;
