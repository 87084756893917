import MD5 from 'md5';
import stringify from 'json-stable-stringify';
import humps from 'humps';

import { capitalize } from 'utils/helpers';
import { SORT_ORDER } from '../constants';

const NON_FIELD_ERROR_NAME = 'non_field_errors';
const MESSAGE_FIELD_NAME = 'detail';

const isResponseDataInvalid = (data) => !data || !(typeof data === 'object' && data !== null);

export const parseErrorResponse = (error, defaultMessage = 'Something went wrong!') => {
  const data = error?.response?.data;

  if (isResponseDataInvalid(data)) {
    return defaultMessage;
  }

  if (MESSAGE_FIELD_NAME in data) {
    return data[MESSAGE_FIELD_NAME];
  }

  if (NON_FIELD_ERROR_NAME in data) {
    const messages = data[NON_FIELD_ERROR_NAME];

    if (Array.isArray(messages)) {
      return messages[0]; // Get first message
    }

    return messages;
  }

  if (Array.isArray(data)) {
    return data[0]; // Get first message
  }

  return defaultMessage;
};

const buildFieldErrors = (data) => {
  const fieldErrors = {};
  if (isResponseDataInvalid(data)) {
    return null;
  }

  const dataKeys = Object.keys(data).filter((key) => !(key in [MESSAGE_FIELD_NAME, NON_FIELD_ERROR_NAME]));

  if (dataKeys.length === 0) {
    return null;
  }

  dataKeys.forEach((key) => {
    if (Array.isArray(data[key])) {
      fieldErrors[key] = capitalize(data[key].join(' '));
    } else if (typeof data[key] === 'object') {
      fieldErrors[key] = buildFieldErrors(data[key]);
    } else {
      fieldErrors[key] = capitalize(data[key]);
    }
  });

  return fieldErrors;
};

export const parseFieldErrors = (error) => {
  const data = error?.response?.data || error;
  return buildFieldErrors(data);
};

export const buildOrdering = ({ orderBy, orderAs }) => {
  if (!orderBy) {
    return null;
  }

  return `${orderAs === SORT_ORDER.ASC ? '' : '-'}${orderBy}`;
};

export const getChecksum = (data) => {
  const secretParts = ['6vC24bTu4L', 'la05L2pS3V', '0PGsx1waa', '9QMt4', 'R2O', 'R8JD4', 'PSCXSzK9', 'zWkJ'];

  const prepareSecret = () => {
    const secretPartsCopy = [...secretParts];
    const partsToReverse = [1, 2, 5, 7];
    partsToReverse.forEach((i) => (secretPartsCopy[i] = secretPartsCopy[i].split('').reverse().join('')));
    return secretPartsCopy.join('');
  };

  const dataStr = (typeof data === 'string' ? data : stringify(data)) || '';

  return MD5(dataStr + prepareSecret());
};

export const objectToFormData = (obj, rootName, ignoreList) => {
  const formData = new FormData();

  const appendFormData = (data, root) => {
    if (!ignore(root)) {
      root = root || '';
      if (data instanceof File) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        if (data.length === 0) {
          // eslint-disable-next-line prefer-template
          appendFormData(data[0], root + '[]');
        } else {
          for (let i = 0; i < data.length; i++) {
            // eslint-disable-next-line prefer-template
            appendFormData(data[i], root + '[' + i + ']');
          }
        }
      } else if (typeof data === 'object' && data) {
        for (const key in data) {
          // eslint-disable-next-line no-prototype-builtins
          if (data.hasOwnProperty(key)) {
            if (root === '') {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], root + key);
            }
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data);
        }
      }
    }
  };

  const ignore = (root) => {
    return (
      Array.isArray(ignoreList) &&
      // eslint-disable-next-line prefer-arrow-callback
      ignoreList.some(function (x) {
        return x === root;
      })
    );
  };

  appendFormData(obj, rootName);

  return formData;
};

/**
 * Build query params from object
 * Parse object to query params format. Arrays are converted to comma separated strings.
 * If value is undefined or null, it is not included in the query params.
 * @param {object} params - The object to be converted to query params.
 * @returns {object} - The query params object.
 */
export const buildQueryParams = (params) => {
  const queryParams = {};
  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      queryParams[key] = params[key].join(',');
    } else if (params[key] !== undefined && params[key] !== null) {
      queryParams[key] = params[key];
    }
  });

  return humps.decamelizeKeys(queryParams);
};
