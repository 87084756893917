import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import { Form, Input, Dimmer, Loader } from 'components/lib';
import { InputField, PromptButton } from 'components/common';
import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import { addCustomPlaceholder, deleteCustomPlaceholder, getCustomPlaceholder, updateCustomPlaceholder } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { CampaignModal, CampaignModalActions } from '../common';
import { CommunityNameBox } from '../EditCampaigns/warnings/common';
import { STATUSES } from '../../../../constants';

const Subtitle = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  margin-top: 3px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 36px;
  .field {
    width: 295px;
    .label {
      position: relative;
      z-index: 1;
    }
    .input {
      width: 100%;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 20px;
  padding-right: 15px;
  position: relative;
  padding-top: 20px;
`;

const FieldArrayWrapper = styled.div`
  height: 269px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const DisplayNameWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-right: 10px;
  height: 36px;
  align-items: flex-start;
  label {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
    display: flex;
    align-items: center;
    height: 100%;
  }
  .field {
    width: 430px;
    .label {
      position: relative;
      z-index: 1;
    }
    .input {
      width: 100%;
    }
  }
`;

const ModalHeader = styled.div`
  position: relative;
`;

const PromptButtonWrapper = styled.div`
  .prompt {
    border: solid 1px var(--salmon);
    background-color: rgba(250, 117, 80, 0.09);
    color: var(--salmon);
    font-family: var(--font-family-bold);
    &:hover,
    &:focus,
    &:active {
      border: solid 1px var(--salmon);
      background-color: #fde8e2;
      color: var(--salmon);
    }
  }
`;

const MODAL_WIDTH = 650;

const Divider = styled.div`
  position: absolute;
  top: 0;
  left: -30px;
  width: ${MODAL_WIDTH}px;
  height: 1px;
  background-color: var(--line-standard);
`;

export const Modal = ({
  handleSubmit,
  relatedCommunities,
  handeOnClose,
  loading,
  disabled,
  deletePlaceholder,
  currentPlaceholder,
}) => {
  const { t } = useTranslation();
  const headerText = currentPlaceholder ? 'Edit' : 'Add';

  return (
    <CampaignModal open onModalClose={handeOnClose} size="tiny" width={MODAL_WIDTH} height={505}>
      <ModalHeader type="header">
        {headerText} {capitalize(t('community'))}-Specific Placeholder
        <Subtitle>
          Please add a name and specify a value or URL for each of the {t('communities')} selected in this campaign.
        </Subtitle>
      </ModalHeader>
      <div type="body">
        <Dimmer active={loading} inverted>
          <Loader active />
        </Dimmer>
        <DisplayNameWrapper className="ui form">
          <label>Placeholder Name</label>
          <InputField control={Input} name="displayName" placeholder="Enter a name" />
        </DisplayNameWrapper>
        <Header>
          <Divider />
          <div>{capitalize(t('community'))}</div>
          <div>{capitalize(t('community'))}-specific value or URL</div>
        </Header>
        <FieldArrayWrapper className="ui form">
          <FieldArray name="placeholders">
            {() => (
              <>
                {relatedCommunities.map((community, i) => (
                  <InputWrapper key={i}>
                    <CommunityNameBox name={community.name} width="50%" />
                    <InputField control={Input} name={`placeholders.${i}`} placeholder="Enter value or URL" />
                  </InputWrapper>
                ))}
              </>
            )}
          </FieldArray>
        </FieldArrayWrapper>
      </div>
      <CampaignModalActions
        onSave={handleSubmit}
        onCancel={handeOnClose}
        type="actions"
        loading={loading}
        disabled={disabled}
      >
        <If condition={currentPlaceholder}>
          <PromptButtonWrapper>
            <PromptButton
              floated="left"
              content="Delete Placeholder"
              title="Delete Placeholder"
              description="Are you sure you want to delete this placeholder? This action cannot be undone."
              onConfirm={deletePlaceholder}
              loading={loading}
              actionLabel="Delete"
              triggerClassName="prompt"
            />
          </PromptButtonWrapper>
        </If>
      </CampaignModalActions>
    </CampaignModal>
  );
};

export const CustomPlaceholdersModal = ({ campaign = {}, fetchPlaceholders }) => {
  const { state, actions, dispatch } = useCampaignDetails();
  const { isPlaceholdersModalOpen, currentPlaceholder } = state;
  const [loader, setLoader] = useState(STATUSES.IDLE);
  const [placeholder, setPlaceholder] = useState(null);
  const relatedCommunities = campaign?.relatedCommunities || [];
  const { t } = useTranslation();

  const initialFormValues = {
    id: placeholder?.id || null,
    placeholders: placeholder?.communityPlaceholders.map((tag) => tag.value) || relatedCommunities.map(() => ''),
    displayName: placeholder?.displayName || '',
  };

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().trim().required('Placeholder Name is required'),
    placeholders: Yup.array()
      .of(Yup.string().trim().required('Value is required'))
      .min(relatedCommunities.length, `Please enter a value for each related ${t('community')}`)
      .required('Values are required'),
  });

  const handeOnClose = () => {
    dispatch({ type: actions.SET_IS_PLACEHOLDERS_MODAL_OPEN, data: false });
    dispatch({ type: actions.SET_CURRENT_PLACEHOLDER, data: null });
  };

  const onSubmit = async (values) => {
    const formattedPlaceholders = relatedCommunities
      .map((community, i) => ({
        communityId: community.id,
        value: values.placeholders[i] || '',
      }))
      .filter((placeholder) => placeholder.value);
    const data = {
      displayName: values.displayName,
      communityPlaceholders: formattedPlaceholders,
    };
    try {
      setLoader(STATUSES.LOADING);
      if (values.id) {
        await updateCustomPlaceholder(campaign.id, placeholder.id, data);
        toast.success('Placeholder successfully updated!');
      } else {
        await addCustomPlaceholder(campaign.id, data);
        toast.success('Placeholder successfully added!');
      }
      setLoader(STATUSES.LOADED);
      fetchPlaceholders();
      handeOnClose();
    } catch (error) {
      toast.error(
        parseErrorResponse(error, 'Error: something went wrong and the changes were not saved. Please try again.')
      );
      console.error(error.message);
      setLoader(STATUSES.FAILURE);
    }
  };

  const deletePlaceholder = async () => {
    try {
      setLoader(STATUSES.LOADING);
      await deleteCustomPlaceholder(campaign.id, currentPlaceholder.id);
      toast.success('Placeholder successfully deleted!');
      setLoader(STATUSES.LOADED);
      fetchPlaceholders();
      handeOnClose();
    } catch (error) {
      toast.error('Error: something went wrong and the changes were not saved. Please try again.');
      console.error(error.message);
      setLoader(STATUSES.FAILURE);
    }
  };

  useEffect(() => {
    const fetchPlaceholder = async () => {
      try {
        setLoader(STATUSES.LOADING);
        const response = await getCustomPlaceholder(campaign.id, currentPlaceholder.id);
        setPlaceholder(response);
        setLoader(STATUSES.LOADED);
      } catch (error) {
        toast.error('Trouble with loading placeholder!');
        console.error(error.message);
        setLoader(STATUSES.FAILURE);
      }
    };
    if (currentPlaceholder?.id) {
      fetchPlaceholder();
    } else {
      setPlaceholder(null);
    }
  }, [currentPlaceholder?.id]);

  const loading = loader === STATUSES.LOADING;
  return (
    <If condition={isPlaceholdersModalOpen}>
      <Formik
        initialValues={initialFormValues}
        validateOnMount
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isValid, touched, dirty, handleSubmit, values }) => (
          <Form>
            <Modal
              handleSubmit={handleSubmit}
              handeOnClose={handeOnClose}
              relatedCommunities={relatedCommunities}
              loading={loading}
              disabled={!dirty || !touched || !isValid}
              deletePlaceholder={deletePlaceholder}
              currentPlaceholder={currentPlaceholder}
            />
          </Form>
        )}
      </Formik>
    </If>
  );
};
