import React from 'react';
import styled from 'styled-components';

import { useCheckMobileScreen } from 'utils/hooks';
import { Logo } from '../Logo';
import Sidebar from './Sidebar';
import { MEDIA_BREAK_POINTS } from '../../constants';

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4rem;
  padding: 2rem;
  overflow: auto;
`;

const GrayscaleLogo = styled(Logo)`
  width: 9rem;
  path,
  circle {
    fill: #aaa;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  max-width: 38rem;
  margin: auto;

  h1 {
    font-size: 60px;
    font-family: AvenirLTPro-Heavy, sans-serif;
    @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      font-size: 34px;
    }
  }

  p {
    font-size: 18px;
    margin: 0;
    @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      font-size: 16px;
    }
  }

  .ui {
    &.form {
      & > p {
        margin-bottom: 0;
      }

      .field {
        margin-bottom: 1.5rem;
        flex: 1;

        &.login-code {
          input {
            font-family: monospace;
            font-weight: bolder;
          }
        }
      }
    }

    &.input input {
      background-color: var(--input-field-fill);
      padding: 1rem;
      border: solid 1px var(--line-standard);
      border-radius: 0;
    }

    &.button {
      font-size: 1rem;
      padding: 1rem;
    }
  }
`;

export const FormFooter = styled.div`
  margin: 2rem 0 2rem 0;
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  span {
    font-weight: bold;
    color: var(--link);
    cursor: pointer;
  }
`;

export const Message = styled.div`
  display: flex;
  margin: 40px 0 20px 0;
  justify-content: center;
  width: 100%;
  font-size: 18px;
`;

export const FieldLabel = styled.label`
  &&&& {
    display: inline;
    font-family: var(--font-family-bold);
    font-weight: normal;
    font-size: 14px;
  }
`;

export const FieldLinkLabel = styled.label`
  &&&& {
    display: inline;
    font-weight: normal;
    font-family: var(--font-family);
    font-size: 14px;
    cursor: pointer;
    color: var(--link);

    &:active,
    &:focus {
      color: var(--white);
    }
  }
`;

export const FieldLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AuthLayout = ({ children }) => {
  const isMobile = useCheckMobileScreen();

  return (
    <Container>
      {!isMobile && <Sidebar />}
      <Main>
        <FormWrapper>{children}</FormWrapper>
        {isMobile && <GrayscaleLogo />}
      </Main>
    </Container>
  );
};
