import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { settingsGetCommunities, settingsUpdateCommunities } from 'apis';
import useDataList from 'utils/hooks/useDataList';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES, PAGINATION } from '../../../../constants';

import {
  SettingsTable,
  SettingsContainer,
  SettingsDropdown,
  SettingsFiltersContainer,
  SettingsHeader,
  SettingsSearch,
  SettingsTableBody,
  SettingsTableToggle,
  SettingsTableRow,
  SettingsPagination,
  SettingsTableRowContainer,
  SettingsSelectedCountModal,
  CommunityRow,
  SettingsBooleanContainer,
  SettingsOff,
  SettingsOn,
} from '../../common';
import { useSettings } from '../../context/SettingsContext';
import { resetState, setSelectedItem } from '../../context/actions';
import { AutomatedAnswersModal } from './AutomatedAnswersModal';
import { getUrlFilterAndSearch } from '../../common/constants';

export const AutomatedAnswers = ({ answerTypes = ['ANSWER'] }) => {
  const { urlSearchValue } = getUrlFilterAndSearch({});
  const queryParams = new URLSearchParams(window.location.search);
  const intialFilters = {};
  if (queryParams.get('num_overrides') === '0') {
    intialFilters.num_overrides = parseInt(queryParams.get('num_overrides'));
  }
  if (queryParams.get('num_overrides_gt') === '0') {
    intialFilters.num_overrides_gt = parseInt(queryParams.get('num_overrides_gt'));
  }
  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters, totalItemsCount } = useDataList({
    initialState: {
      filters: {
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.PAGE_SIZE,
        search: urlSearchValue,
        overridesFilter: {
          ...intialFilters,
        },
      },
      setUrlParams: true,
    },
  });
  const [{ isMultipleSelection, selectedItems, excludedItems }, dispatch] = useSettings();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const fetchCommunities = useCallback(
    async ({ page, pageSize, search, overridesFilter }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await settingsGetCommunities({
          page,
          page_size: pageSize,
          search,
          overrides_types: answerTypes.join(','),
          ...overridesFilter,
        });

        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch data');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchCommunities(filters);
  }, [fetchCommunities, filters]);

  const refetchCommunities = useCallback(() => fetchCommunities(filters), [fetchCommunities, filters]);

  const dropdownOptions = [
    { key: '1', text: 'Any status', value: {} },
    { key: '2', text: 'Default answers', value: { num_overrides: 0 } },
    { key: '3', text: 'Has custom answers', value: { num_overrides_gt: 0 } },
  ];

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
  };

  const handleDropdownChange = (e, { value: overridesFilter }) => {
    const urlFilters = {
      num_overrides: overridesFilter?.num_overrides,
      num_overrides_gt: overridesFilter?.num_overrides_gt,
    };
    setFilters({ overridesFilter }, { urlFilters: { ...urlFilters } });
    dispatch(resetState());
  };

  const handleOnSearch = (value) => {
    setSearch(value);
    dispatch(resetState());
  };

  const handleOnRowClick = (data) => {
    if (!isMultipleSelection) {
      setIsModalOpen(true);
      dispatch(setSelectedItem(data));
    }
  };

  const handleOnModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetState());
  };

  const handleOnSelectedCountModalClick = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async ({ overridesFilter }) => {
    setLoaderStatus(STATUSES.LOADING);
    const data = {
      include_communities: selectedItems,
      exclude_communities: excludedItems,
      overridesFilter,
    };

    try {
      await settingsUpdateCommunities(data);
      toast.success('Settings updated successfully!');
    } catch (error) {
      const errorText = 'Trouble updating settings!';
      const msg = parseErrorResponse(error, errorText);
      console.error(msg, error?.response);
      toast.error(msg);
      setLoaderStatus(STATUSES.FAILURE);
    } finally {
      dispatch(resetState());
      setLoaderStatus(STATUSES.LOADED);
      setFilters({
        page: PAGINATION.PAGE,
        page_size: PAGINATION.PAGE_SIZE,
        search: null,
        overridesFilter: null,
      });
    }
  };

  const loading = loaderStatus === STATUSES.LOADING;
  return (
    <SettingsContainer loading={loading} totalItemsCount={totalItemsCount}>
      <If condition={!isModalOpen}>
        <SettingsSelectedCountModal
          totalItemsCount={totalItemsCount}
          items={items}
          filters={filters}
          totalPages={totalPages}
          onClick={handleOnSelectedCountModalClick}
        />
      </If>
      <AutomatedAnswersModal
        open={isModalOpen}
        answerTypes={answerTypes}
        totalItemsCount={totalItemsCount}
        onSave={handleSubmit}
        onClose={handleOnModalClose}
        selectedItemsCount={selectedItems.length}
        selectedCommunity={selectedItems.length === 1 ? items?.find((item) => item.id === selectedItems[0]) : null}
        selectedItems={selectedItems}
        refetchCommunities={refetchCommunities}
        onAfterSaveAndClose={handleOnModalClose}
      />
      <SettingsHeader>
        <SettingsTableToggle />
        <SettingsFiltersContainer>
          <SettingsDropdown
            options={dropdownOptions}
            value={filters.overridesFilter}
            onChange={handleDropdownChange}
            placeholder="Any status"
          />
          <SettingsSearch
            onSearch={handleOnSearch}
            value={filters.search}
            placeholder={`Search ${t('communities')}`}
            loading={loading}
          />
        </SettingsFiltersContainer>
      </SettingsHeader>
      <SettingsTable tableName={`${t('community')}`} currentPage={filters.page} items={items}>
        <SettingsTableBody>
          {items?.map((item, i) => (
            <SettingsTableRow
              item={item}
              key={i}
              onClick={() => handleOnRowClick({ id: item.id, items, currentPage: filters.page })}
              items={items}
              currentPage={filters.page}
            >
              <SettingsTableRowContainer>
                <CommunityRow name={item.name} picture={item.picture_url} clickable={!isMultipleSelection} />
                <If condition={!isMultipleSelection}>
                  <SettingsBooleanContainer>
                    <If condition={item?.num_overrides > 0}>
                      <SettingsOn text="Has custom answers" color="#89919d" bold={false} />
                    </If>
                    <If condition={item?.num_overrides <= 0}>
                      <SettingsOff text="Default answers" />
                    </If>
                  </SettingsBooleanContainer>
                </If>
              </SettingsTableRowContainer>
            </SettingsTableRow>
          ))}
        </SettingsTableBody>
      </SettingsTable>
      <SettingsPagination filters={filters} totalPages={totalPages} onPageChange={handlePageChange} />
    </SettingsContainer>
  );
};
