import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { OnboardingContext } from '../OnboardingContext';
import { Container } from './common';

import { MainGoalForm } from '.';

const initialFormValues = {
  mainGoal: '',
};

const VALIDATION_SCHEMA = Yup.object({
  mainGoal: Yup.string().required('Please select your main goal.'),
});

export const MainGoal = () => {
  const { handleNextStep, onBoardingData } = useContext(OnboardingContext);
  const [initialValues, setInitialValues] = useState(initialFormValues);

  useEffect(() => {
    if (onBoardingData?.mainGoal) {
      setInitialValues({
        mainGoal: onBoardingData?.mainGoal,
      });
    }
  }, [onBoardingData]);

  const onFormSubmit = (values) => {
    handleNextStep(values);
  };

  return (
    <Container maxWidth="67.1429rem">
      <h2>What is your main goal?</h2>
      <p>
        Tell us what your main objective is when it comes to engaging leads who visit your website.
        <br />
        If you have multiple goals, we can build other assistants later.
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={VALIDATION_SCHEMA}
        enableReinitialize
      >
        <MainGoalForm />
      </Formik>
    </Container>
  );
};
