import React, { useState } from 'react';
import { Icon, Input } from 'semantic-ui-react';

export const FilterInput = ({ items, placeholder, filterBy = [], onFilter }) => {
  const [filterValue, setFilterValue] = useState('');

  const onChangeFilter = (e) => {
    if (e?.target) {
      const term = e.target.value?.toLowerCase();

      setFilterValue(term);
      onFilter(items.filter((p) => filterMultiple(p, term)));
    }
  };

  const filterMultiple = (item, term) =>
    filterBy
      .map((attribute) => {
        const value = attribute.split('.').reduce((previous, current) => previous[current], item);
        return value.toString().toLowerCase().includes(term);
      })
      .some((e) => e);

  const clearFilter = () => {
    setFilterValue('');
    onFilter(items);
  };

  return (
    <Input
      fluid
      icon={<Icon name="delete" link onClick={clearFilter} />}
      onChange={onChangeFilter}
      placeholder={placeholder}
      value={filterValue}
    />
  );
};
