import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const Info = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 34px;
`;

const Drawer = styled.div`
  padding: 20px;
  display: ${(props) => (props.expaned ? 'flex' : 'none')};
`;

export const SimpleLink = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #1f57bd;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
`;

export const Instructions = ({ title, children }) => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <Container>
      <Info>
        <Icon name="info circle" size="large" color="blue" />
        <span>{title}</span>
      </Info>
      <Content>
        <Drawer expaned={expanded}>{children}</Drawer>
        <SimpleLink onClick={toggleExpanded}>{expanded ? 'Show Less' : 'See full instructions'}</SimpleLink>
      </Content>
    </Container>
  );
};
