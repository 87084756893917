import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { uploadCommunityAssets } from 'apis';
import { STATUSES } from '../../../constants';
import { ACTIONS, CommunitiesContext } from '../../common/communities/CommunitiesContext';

import { PAGE_HEADER_PADDING, PageHeader } from '../../common';
import { UserCommunityImagesSelection } from './UserCommunityImagesSelection';

export const UserCommunityImagesScraped = ({ images, disabled }) => {
  const { communityId } = useParams();
  const { dispatch } = useContext(CommunitiesContext);

  const [uploadingStatus, setUploadingStatus] = useState(STATUSES.IDLE);
  const [selection, setSelection] = useState([]);

  const onConfirmSelection = async () => {
    try {
      setUploadingStatus(STATUSES.LOADING);
      const community = await uploadCommunityAssets(communityId, { images: selection });
      dispatch({ type: ACTIONS.SET_COMMUNITY, payload: { communityId: community.id, communityData: community } });

      setSelection([]);
      setUploadingStatus(STATUSES.LOADED);
      toast.success('Scraped images uploaded successfully!');
    } catch (e) {
      toast.error('Trouble uploading scraped images!');
      setUploadingStatus(STATUSES.FAILURE);
    }
  };

  const isUploading = uploadingStatus === STATUSES.LOADING;

  return (
    <>
      <PageHeader
        title="Select Scraped Images"
        subtitle="Please make selection of scraped image assets and apply upload"
        padding={PAGE_HEADER_PADDING.VERTICAL}
        actions={
          <Button
            primary
            disabled={!selection?.length || isUploading || disabled}
            loading={isUploading}
            content="Upload"
            icon="upload"
            onClick={onConfirmSelection}
          />
        }
      />
      <UserCommunityImagesSelection
        images={images}
        selection={selection}
        onChange={(selection) => setSelection(selection)}
      />
    </>
  );
};
