import React from 'react';
import RuleDropdown from './RuleDropdown';

const RuleDropdownMultiple = ({ rule, onChange, onRemove, preview, selectedRule }) => {
  return (
    <RuleDropdown
      rule={rule}
      onChange={onChange}
      onRemove={onRemove}
      multiple
      preview={preview}
      selectedRule={selectedRule}
    />
  );
};

export default RuleDropdownMultiple;
