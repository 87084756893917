import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';

export const StyledDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
    border: solid 1px var(--line-standard);
    border-radius: 0px;
    background-color: white;
    min-width: 20rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
    padding: 11px 21px;
    margin-right: 3px;
    min-height: 36px;
    height: 36px;

    .icon {
      right: 18px;
    }

    .default {
      color: var(--text-color);
    }
  }
`;

export const FilterDropdown = ({ ...props }) => <StyledDropdown {...props} />;
