import React, { lazy, Suspense } from 'react';

const LazyEditor = lazy(() => import('./TinyMCE'));

export function Editor(props) {
  return (
    <Suspense fallback={false}>
      <LazyEditor {...props} />
    </Suspense>
  );
}
