import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { List, ListItem, Divider } from 'semantic-ui-react';
import { If } from '../../If';
import Button from '../../Button';

const DEFAULT_LABEL = 'Drag and drop files here, or click to select files';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 200px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const Preview = styled.div`
  width: 100%;
`;

export const UploadDropzone = ({ label, formats, buttonLabel, onSelected, maxFiles = 1 }) => {
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: formats,
    maxFiles,
    onDrop: (acceptedFiles) => onSelected(acceptedFiles),
  });

  const mainLabel = label || DEFAULT_LABEL;
  const files = acceptedFiles.map((file) => <ListItem key={file.path} icon="file" header={file.path} />);

  return (
    <div className="container">
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <h4>{mainLabel}</h4>
        <p>Maximum number of files: {maxFiles}</p>
        <If condition={buttonLabel}>
          <Button primary>{buttonLabel}</Button>
        </If>
        <input {...getInputProps()} />

        <If condition={files.length}>
          <Preview>
            <Divider horizontal section>
              Files
            </Divider>
            <List>{files}</List>
          </Preview>
        </If>
      </Container>
    </div>
  );
};
