import React from 'react';
import styled from 'styled-components';

import { Modal as SemanticModal } from 'semantic-ui-react';

const StyledModal = styled(SemanticModal)`
  && {
    border-radius: 0;
    box-shadow: none;
    &.centered {
      top: 50%;
      transform: translateY(-50%);
    }
    && > .actions {
      background-color: var(--white);
      padding: 20px 30px;
      display: flex;
      justify-content: flex-end;
    }
    & > .content {
      padding: 0;
    }
    & > .header {
      padding: 20px 30px;
    }
    & > .close {
      top: 22px;
      right: 25px;
      padding: 0;
      &:before {
        font-family: 'Material Icons Outlined';
        font-size: 24px;
        color: var(--light-text);
        content: '\\e5cd';
      }
    }
    .ui.basic.button {
      font-weight: 900;
    }

    @media only screen and (min-width: 1200px) {
      .ui.medium.modal {
        width: 900px;
        margin: 0;
      }
    }

    @media only screen and (min-width: 992px) {
      .ui.medium.modal {
        width: 780px;
        margin: 0;
      }
    }

    @media only screen and (min-width: 768px) {
      .ui.medium.modal {
        width: 80.4%;
        margin: 0;
      }
    }
    @media only screen and (min-width: 768px) {
      &.ui.small.modal {
        width: 70.4%;
        margin: 0;
      }
    }

    @media only screen and (min-width: 992px) {
      &.ui.small.modal {
        width: 680px;
        margin: 0;
      }
    }

    @media only screen and (min-width: 1200px) {
      &.ui.small.modal {
        width: 700px;
        margin: 0;
      }
    }
  }
`;

export const Modal = ({ ...props }) => <StyledModal {...props} />;

Modal.Actions = SemanticModal.Actions;
Modal.Content = SemanticModal.Content;
Modal.Description = SemanticModal.Description;
Modal.Dimmer = SemanticModal.Dimmer;
Modal.Header = SemanticModal.Header;
