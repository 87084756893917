export const validateLoginField = async (validationObject, validationSchema, onInvalidEvent) => {
  try {
    await validationSchema.validate(validationObject, { strict: true });
  } catch (e) {
    if (e?.message && e?.path && typeof onInvalidEvent === 'function') {
      onInvalidEvent(e.path, e.message);
    }
    return false;
  }
  return true;
};
