import React from 'react';
import { Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { DiscountsTable } from './DiscountsTable';

export const DiscountsForm = ({ communities, formRef, onSubmit }) => {
  const initialValues = {
    prices: communities.flatMap((community) => {
      const pricesArray = [];
      if (community.prices?.length) {
        community.prices.forEach((price) => {
          const { id, name, cost, discount_amount, discount_text } = price;

          pricesArray.push({
            id,
            cost,
            name,
            discount_amount: discount_amount || '',
            discount_text: discount_text || '',
            locationName: community.name,
          });
        });
      }
      return pricesArray;
    }),
  };

  const validationSchema = Yup.object({
    prices: Yup.array().of(
      Yup.object({
        discount_amount: Yup.number().min(0, 'Must be a positive number or zero').nullable(),
      })
    ),
  });

  const onFormSubmit = (values) => {
    if (typeof onSubmit === 'function') {
      onSubmit(values);
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
      enableReinitialize
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <DiscountsTable values={values} />
        </Form>
      )}
    </Formik>
  );
};
