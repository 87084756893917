import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { WithTopBar } from 'components/WithTopBar';
import { getUserCommunityAssets } from 'apis';
import { UserCommunityDetailsOverview } from './UserCommunityDetailsOverview';
import { UserCommunitiesNoneSelected } from '../common/communities/UserCommunitiesNoneSelected';
import { UserCommunities } from '../common/communities/UserCommunities';

export const CommunitiesDashboard = () => (
  <UserCommunities linkPrefix="/communities/" communitiesCallback={getUserCommunityAssets}>
    <WithTopBar>
      <Switch>
        <Route exact path="/communities/:communityId">
          <UserCommunityDetailsOverview />
        </Route>
        <Route exact path="/communities">
          <UserCommunitiesNoneSelected />
        </Route>
      </Switch>
    </WithTopBar>
  </UserCommunities>
);
