import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';

import { debounce } from 'components/utils';
import { ChatMessage } from 'components/common/chat';
import { VSA_LINE_TYPE, VSA_LINE_TYPE_NAME, MEDIA_BREAK_POINTS } from '../../constants';

const Container = styled.div`
  height: 100%;
  padding: 0 1rem;
  margin-top: auto;
  overflow-y: auto;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    margin-top: 0;
    padding: 0 1rem 0 0;
    &.viewPortContained {
      max-height: 40vh;
    }
  }
`;

export const ApplicantConversation = ({ conversation, viewPortContained }) => {
  const containerRef = useRef();
  const [scroll, setScroll] = useState(0);
  const withConfig = conversation
    ?.filter((message) => message.text)
    .map((message) => {
      const config = {};

      switch (message.type) {
        case VSA_LINE_TYPE.EVENT:
          config.hideType = true;
          config.float = 'center';
          break;
        case VSA_LINE_TYPE.VSA:
        case VSA_LINE_TYPE.EVSA:
          config.float = 'right';
          break;
        case VSA_LINE_TYPE.PROSPECT:
          config.float = 'left';
          break;
        default:
          config.hideType = true;
          config.float = 'left';
          break;
      }
      return {
        ...message,
        config,
      };
    });

  useEffect(() => {
    new ResizeObserver(
      debounce(() => {
        setScroll(containerRef?.current?.scrollHeight);
      }),
      20
    ).observe(containerRef?.current);
  }, []);

  if (viewPortContained && containerRef?.current?.scrollHeight && scroll !== containerRef?.current?.scrollHeight) {
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }

  const className = classNames({ viewPortContained });

  return (
    <Container className={className} ref={containerRef}>
      {withConfig?.map((line, i) => (
        <ChatMessage
          key={i}
          className={line.config.float}
          text={line.text}
          type={line.config.hideType ? null : VSA_LINE_TYPE_NAME[line.type]}
          timestamp={line.config.hideType ? null : line.timestamp}
        />
      ))}
    </Container>
  );
};
