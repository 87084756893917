import React from 'react';

import { Overview, OverviewContextProvider } from '.';

import { WithTopBar } from '../WithTopBar';

export const OverviewRoot = () => {
  return (
    <OverviewContextProvider>
      <WithTopBar>
        <Overview />
      </WithTopBar>
    </OverviewContextProvider>
  );
};
