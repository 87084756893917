import styled, { css } from 'styled-components';

export const NavigationSection = styled.div`
  padding: 1rem 0;
`;

NavigationSection.Title = styled.div`
  display: block;
  padding: 0 2rem;
  font-family: AvenirLTPro-Heavy, sans-serif;
  font-size: 0.85rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 1.2rem;
  text-transform: uppercase;
`;

NavigationSection.Divider = styled.div`
  height: 4px;
  border-top: 2px solid #071d4c;
  border-bottom: 2px solid #294276;
  margin: 1rem 0;
`;
