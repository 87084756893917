import constants from './constants';

export const setLoaderStatus = (data) => ({
  type: constants.SET_LOADER_STATUS,
  payload: data,
});

export const setActiveTab = (activeTab) => ({
  type: constants.SET_ACTIVE_TAB,
  payload: activeTab,
});

export const setUserId = (userId) => ({
  type: constants.SET_USER_ID,
  payload: userId,
});

export const setCRMGlobalSettings = (crmGlobalSettings) => ({
  type: constants.SET_CRM_GLOBAL_SETTINGS,
  payload: crmGlobalSettings,
});

export const setCRMSettings = (crmSettings) => ({
  type: constants.SET_CRM_SETTINGS,
  payload: crmSettings,
});

export const setCommunities = (communities) => ({
  type: constants.SET_COMMUNITIES,
  payload: communities,
});

export const updateCommunity = (
  id,
  crm_location_code,
  crm_settings_id,
  crm_settings_integration_name,
  crm_settings_name,
  crm_location_code_overrides
) => ({
  type: constants.UPDATE_COMMUNITY,
  payload: {
    id,
    crm_location_code,
    crm_settings_id,
    crm_settings_integration_name,
    crm_settings_name,
    crm_location_code_overrides,
  },
});

export const removeCommunityCRMSettings = (id) => ({
  type: constants.REMOVE_COMMUNITY_CRM_SETTINGS,
  payload: { id },
});

export const setCRMLocationCodeRules = (rules) => ({
  type: constants.SET_CRM_LOCATION_CODE_RULES,
  payload: rules,
});

export const setCRMCommunityInvalidSettings = (data) => ({
  type: constants.SET_CRM_COMMUNITY_INVALID_SETTINGS,
  payload: data,
});

export const setCRMSourceMappingTrafficSources = (data) => ({
  type: constants.SET_CRM_SOURCE_MAPPING_TRAFFIC_SOURCES,
  payload: data,
});

export const setCRMSourceMappingLeadSources = (data) => ({
  type: constants.SET_CRM_SOURCE_MAPPING_LEAD_SOURCES,
  payload: data,
});

export const setCRMSourceMappingUrlParameters = (data) => ({
  type: constants.SET_CRM_SOURCE_MAPPING_URL_PARAMETERS,
  payload: data,
});
