import React from 'react';
import styled from 'styled-components';
import { Button, Message } from 'semantic-ui-react';

const FailureMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 70vh;
`;

const FailureMessage = ({ icon, content, onTryAgain }) => {
  const handleTryAgain = () => {
    if (onTryAgain) {
      onTryAgain();
    } else {
      window.location.reload();
    }
  };

  return (
    <FailureMessageContainer>
      <Message error icon={icon} content={content} />
      <Button onClick={handleTryAgain}>Try again?</Button>
    </FailureMessageContainer>
  );
};

export default FailureMessage;
