import React, { useContext } from 'react';
import styled from 'styled-components';
import { Form, Button } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { setHours, setMinutes } from 'date-fns';

import { formatAnyDate } from 'components/utils';
import { DatePickerField } from 'components/form/DatePickerField';
import { ToursContext } from './ToursContext';
import { STATUSES } from '../../constants';

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 10rem;
  }
`;

const VALIDATION_SCHEMA = Yup.object({
  start: Yup.date().required('Visit date is required'),
});

const TourForm = () => {
  const { tour, updateStatus, handleTourSubmit } = useContext(ToursContext);

  const onSubmit = (values) => {
    const data = {
      ...values,
      start: formatAnyDate(values.start, "yyy-MM-dd'T'HH:mm:ss"), // Avoid timezone cast
    };

    handleTourSubmit(data);
  };

  const loading = updateStatus === STATUSES.LOADING;

  return (
    <Formik initialValues={tour} validationSchema={VALIDATION_SCHEMA} onSubmit={onSubmit} enableReinitialize>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <DatePickerField
              name="start"
              dateFormat="yyyy-mm-ddThh:mm:ss"
              minDate={new Date()}
              minTime={setHours(setMinutes(new Date(), 0), 9)}
              maxTime={setHours(setMinutes(new Date(), 0), 17)}
              placeholderText="Visit date"
              inline
              showTimeSelect
            />
          </Form.Field>
          <Actions>
            <Button primary content="Save" loading={loading} disabled={loading || tour.cancelled} />
          </Actions>
        </Form>
      )}
    </Formik>
  );
};

export default TourForm;
