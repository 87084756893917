import React from 'react';
import styled from 'styled-components';

import { Button } from 'components/lib';
import { If } from 'components/If';

const StyledButton = styled(Button)`
  &&&&& {
    width: 150px;
    background-color: var(--white);
    border: none;
    opacity: 1;
    font-family: var(--font-family);
    font-size: 12px;
    color: var(--link);
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const AnotherPurposeButton = ({ onClick, disabled, display }) => (
  <If condition={display}>
    <StyledButton disabled={disabled} onClick={onClick}>
      Add another purpose
    </StyledButton>
  </If>
);
