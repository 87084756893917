import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { getSupportModeUsers } from 'apis/SupportModeAPI';
import { Icon, Loader } from 'components/lib';
import { UserCircle } from 'components/UserCircle';
import SearchInput from 'components/SearchInput';
import { PAGINATION } from '../../constants';
import SupportModeBox from './SupportModeBox';

const DropdownIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  text-align: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

const DropdownContainer = styled.div`
  font-family: var(--font-family);
  position: relative;
`;

const DropdownBox = styled.div`
  position: absolute;
  top: 1px;
  left: 0;
  width: 300px;
  background: white;
  z-index: 1101;
  box-shadow: 0 3px 12px 0 rgba(21, 24, 35, 0.35);
`;

const InputBox = styled.div`
  padding: 10px;
`;

const DropdownList = styled.div`
  overflow-y: auto;
  max-height: 235px;
`;

const DropdownListItem = styled.div`
  margin: 0;
  padding: 10px 10px 13px 10px;
  border-top: solid 1px var(--line-standard);
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const AvatarBox = styled.div`
  margin-right: 10px;
`;

const UserBox = styled.div`
  line-height: 18px;
`;

const UserName = styled.div`
  color: var(--text-color);
  font-size: 14px;
  font-weight: 900;
  word-break: break-all;
`;

const UserEmail = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: var(--link);
  word-break: break-all;
`;

const UserType = styled.div`
  text-transform: uppercase;
  color: var(--light-text);
  font-size: 10px;
  font-weight: 500;
`;

const SCROLL_DIFF_TRIGGER = 50;

const SupportModeDropdown = ({ loading, onSelect }) => {
  const [loadingUsers, setLoadingUsers] = useState();
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState();
  const [page, setPage] = useState(PAGINATION.PAGE);
  const [search, setSearch] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [demoMode, setDemoMode] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && hovering) {
        setDemoMode(!demoMode);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [hovering, demoMode]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoadingUsers(true);
        const { results, next } = await getSupportModeUsers({
          search: search || undefined,
          page_size: PAGINATION.PAGE_SIZE,
        });

        setPage(PAGINATION.PAGE);
        setUsers(results);
        setHasMore(!!next);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchUsers();
  }, [search]);

  const handleOpenDropdown = () => {
    setOpen(!open);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleSelect = (userId, demoMode) => () => {
    setOpen(false);
    onSelect(userId, demoMode);
  };

  const handleScroll = async (e) => {
    const isBottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < SCROLL_DIFF_TRIGGER;
    if (!isBottom || !hasMore || loadingUsers) {
      return;
    }

    try {
      setLoadingUsers(true);
      const activePage = page + 1;

      const { results, next } = await getSupportModeUsers({
        search: search || undefined,
        page: activePage,
        page_size: PAGINATION.PAGE_SIZE,
      });

      setUsers([...users, ...results]);
      setHasMore(!!next);
      setPage(activePage);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingUsers(false);
    }
  };

  return (
    <div ref={dropdownRef} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <SupportModeBox
        leftIcon={
          <DropdownIcon backgroundColor={demoMode ? 'var(--warning)' : 'var(--salmon)'}>
            <Icon
              name={demoMode ? 'play_arrow' : 'auto_awesome'}
              color="var(--text-color)"
              variant="outlined"
              size="12"
            />
          </DropdownIcon>
        }
        description={demoMode ? 'Demo mode' : 'Support mode'}
        title="Select a client"
        rightIcon={<Icon name="expand_more" color="rgba(255, 255, 255, 0.6)" />}
        onClick={handleOpenDropdown}
        loading={loading}
      />
      {open && users && (
        <DropdownContainer>
          <DropdownBox>
            <InputBox>
              <SearchInput
                autoFocus
                value={search}
                loading={loadingUsers}
                fluid
                iconPosition="left"
                icon={<Icon name="search" size="22" color="var(--light-text)" />}
                onSearch={handleSearch}
              />
            </InputBox>
            <DropdownList onScroll={handleScroll}>
              {users.map((u) => (
                <DropdownListItem key={u.id} onClick={handleSelect(u.id, demoMode)}>
                  <AvatarBox>
                    <UserCircle user={u} />
                  </AvatarBox>
                  <UserBox>
                    <UserName>{u.name}</UserName>
                    <UserEmail>{u.email}</UserEmail>
                    <UserType>{u.type}</UserType>
                  </UserBox>
                </DropdownListItem>
              ))}
              <Loader active={loadingUsers} />
            </DropdownList>
          </DropdownBox>
        </DropdownContainer>
      )}
    </div>
  );
};

export default SupportModeDropdown;
