import constants from './constants';

export const setLoaderStatus = (data) => ({
  type: constants.SET_LOADER_STATUS,
  payload: data,
});

export const setInvitation = ({ invitation, token }) => ({
  type: constants.SET_INVITATION,
  payload: { invitation, token },
});

export const setTokenInvalid = () => ({
  type: constants.SET_TOKEN_INVALID,
});

export const setNextStep = () => ({
  type: constants.SET_NEXT_STEP,
});

export const setPrevStep = () => ({
  type: constants.SET_PREV_STEP,
});

export const setCareTypes = (careTypes) => ({
  type: constants.SET_CARE_TYPES,
  payload: careTypes,
});

export const setUnits = (units) => ({
  type: constants.SET_UNITS,
  payload: units,
});
