import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { createMappingTarget } from 'apis';
import { Button, Modal, Form } from 'components/lib';
import { InputField } from 'components/common';

const FormWrapper = styled.div`
  padding: 2rem;

  var {
    color: #fa7550;
    font-family: monospace;
  }
`;

export const LeadPropModal = ({ isOpen, mappingTargets, onClose }) => {
  const initialValues = {
    path: '',
    label: '',
  };

  const validationSchema = Yup.object().shape({
    path: Yup.string()
      .required('Required')
      .test('is-unique-path', 'Mapping target with this path already exists', (value) => {
        const fullPath = `extra_information.${value}`;
        return !mappingTargets.find((mt) => mt.path === fullPath);
      }),
    label: Yup.string()
      .required('Required')
      .test('is-unique-label', 'Mapping target with this label already exists', (value) => {
        return !mappingTargets.find((mt) => mt.label === value);
      }),
  });

  const handleSubmit = async (values) => {
    try {
      await createMappingTarget({
        label: values.label,
        path: `extra_information.${values.path}`,
      });
      if (onClose) {
        onClose(values);
      }
    } catch (error) {
      console.log(error);
      toast.error('Failed to create mapping target');
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <Modal open={isOpen} closeIcon onClose={handleClose} size="small">
      <Modal.Header>Create a new Mapping Target</Modal.Header>
      <Modal.Content>
        <FormWrapper>
          <p>
            Arbitrary form data can be saved (as JSON) in the <var>Lead.extra_information</var> attribute.
            <br />
            <br />
            This form lets you define a new mapping target within <var>Lead.extra_information</var>. Once saved, the new
            target will become available in the "Map to" dropdown.
          </p>

          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form id="mapping-target-form" onSubmit={handleSubmit}>
                <InputField name="label" label="Label" />
                <InputField name="path" label="JSON Path (within Lead.extra_information)" />
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button primary color="blue" type="submit" form="mapping-target-form">
          Save
        </Button>
        <Button basic onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
