import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

import { Module } from './Module';
import { Answers } from './Answers';
import { Message } from './Message';

export const OutOfBudget = ({ node }) => {
  const text = node.module?.text;
  return (
    <Module node={node}>
      <Message>{text}</Message>
      <Label color="black">
        <Icon name="sync alternate" /> Update Lead Info
      </Label>
      <Answers node={node} />
    </Module>
  );
};
