import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { getWebFormMappingCandidates } from 'apis';
import { PageHeader } from 'components/common';
import useDataList from 'utils/hooks/useDataList';
import { Table, Icon, Toggle, Input } from 'components/lib';
import SearchInput from 'components/SearchInput';
import { PAGINATION } from '../../../constants';

const Filters = styled.div`
  display: flex;
  gap: 2rem;
  padding: 1rem 0;

  .ui.input {
    flex: 1;
  }
`;

const SelectableCell = styled(Table.Cell)`
  cursor: pointer;
`;

export const MappingCandidates = () => {
  const history = useHistory();
  const { items, setItems, totalPages, filters, setFilters, setPage, setPageSize } = useDataList({
    initialState: {
      filters: {
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.MEDIUM_PAGE_SIZE,
        search: '',
        includeIgnored: false,
        includeMapped: false,
      },
    },
  });

  useEffect(() => {
    const fetchMappingCandidates = async () => {
      const response = await getWebFormMappingCandidates({
        page: filters.page,
        include_ignored: filters.includeIgnored,
        include_mapped: filters.includeMapped,
        search: filters.search,
      });
      setItems({ results: response?.results, count: response?.count });
    };

    fetchMappingCandidates();
  }, [filters]);

  const editMappingCandidate = (id) => {
    history.push(`/admin/web-forms/mapping-candidates/${id}`);
  };

  return (
    <div>
      <PageHeader
        as="h2"
        title="WebForm Mapping Candidates"
        subtitle="List of web forms that have been found on the site and are candidates for mapping to a WebForm"
        padding={PageHeader.PADDING.NONE}
        margin={PageHeader.MARGIN.NONE}
      />
      <Filters>
        <SearchInput
          value={filters.search}
          onSearch={(search) => {
            setFilters({ ...filters, search });
          }}
        />
        <Toggle
          label="Include mapped"
          checked={filters.includeMapped}
          onChange={(_, t) => {
            setFilters({ ...filters, includeMapped: t.checked });
          }}
        />
        <Toggle
          label="Include ignored"
          onChange={(_, t) => {
            setFilters({ ...filters, includeIgnored: t.checked });
          }}
        />
      </Filters>
      <Table selectable striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={8}>URL Location</Table.HeaderCell>
            <Table.HeaderCell width={4}>Query selector</Table.HeaderCell>
            <Table.HeaderCell width={3}>Mapped to WebForm</Table.HeaderCell>
            <Table.HeaderCell>Ignored</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items?.map((item, i) => (
            <Table.Row
              key={i}
              onClick={() => {
                editMappingCandidate(item.id);
              }}
            >
              <SelectableCell>{item.form.url_location}</SelectableCell>
              <SelectableCell>{item.form.query_selector}</SelectableCell>
              <SelectableCell>{item.mapped_to_hash}</SelectableCell>
              <SelectableCell>
                {item.ignored ? <Icon name="do_not_disturb_on" variant="outlined" /> : null}
              </SelectableCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {totalPages > 1 && <Table.Pagination page={filters.page} totalPages={totalPages} onPageChange={setPage} />}
    </div>
  );
};
