import React, { useContext, useState } from 'react';
import { Button, Header, Icon, Popup, Message } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';

import { exportIA } from 'apis';
import { CsvExport } from 'components/common';
import { If } from '../../If';
import { STATUSES, USER_TYPES } from '../../../constants';
import { UserContext } from '../../UserContext';
import { InstantAnswersContext } from './InstantAnswersContext';
import { downloadFile, formatAnyDate } from '../../utils';

const HeaderControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  align-items: center;
  h2,
  button {
    margin: 0;
  }
`;

const CommunityName = styled(Header)`
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const IAManagment = styled.div`
  margin-left: auto;
  > button.button {
    margin-right: 15px;
  }
`;

const DisabledButtonContainer = styled.div`
  display: inline-block;
  margin-right: 15px;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    button {
      pointer-events: none;
    }
  }
`;

export const InstantAnswersHeaderControls = () => {
  const { community_id } = useParams();
  const { dirty } = useFormikContext();

  const { user } = useContext(UserContext);
  const { community, communityAttributes, importErrors, setShowConfirmDeleteIA, setShowImportDialog } =
    useContext(InstantAnswersContext);

  const [exportingStatus, setExportingStatus] = useState(STATUSES.IDLE);

  const handleExport = async () => {
    try {
      setExportingStatus(STATUSES.LOADING);
      const response = await exportIA(community_id);

      const communityName = community?.name.split(' ').join('').trim();
      const filename = `${communityName}_IA_${formatAnyDate(new Date(), 'yyyyMMdd_hhmmss')}.csv`;
      downloadFile({ data: response }, filename);

      toast.success('Answers exported successfully');
      setExportingStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error('Error exporting answers. Please try again later, or contact us for help.');
      setExportingStatus(STATUSES.FAILURE);
    }
  };

  const onDeleteIAOpen = () => {
    setShowConfirmDeleteIA(true);
  };

  const isStaff = user.type === USER_TYPES.STAFF;
  const hasIA = communityAttributes?.some((attribute) => attribute.count !== 0);
  const importErrorsMsg = importErrors.map((error) => `On row ${error.row_id}: ${error.message}`);

  return (
    <>
      <Message
        color="red"
        hidden={importErrors.length === 0}
        header="The following errors occurred while importing IA and these rows were not imported."
        list={importErrorsMsg}
      />

      <HeaderControlsContainer>
        <CommunityName as="h2">{community?.community_name}</CommunityName>
        <IAManagment>
          <If condition={isStaff}>
            <Button
              basic
              color="blue"
              type="button"
              content="Import IA"
              disabled={exportingStatus === STATUSES.LOADING}
              onClick={() => setShowImportDialog(true)}
              icon="cloud upload"
              labelPosition="right"
            />
          </If>
          <If condition={hasIA}>
            <Popup
              disabled={!dirty}
              content="Please save your changes before exporting."
              trigger={
                <DisabledButtonContainer disabled={dirty || exportingStatus === STATUSES.LOADING}>
                  <CsvExport content="Export IA" exportAction={handleExport} skipDownload />
                </DisabledButtonContainer>
              }
            />
          </If>
          <If condition={isStaff && hasIA}>
            <Button onClick={onDeleteIAOpen} type="button" icon labelPosition="right" negative>
              Remove IA
              <Icon name="trash alternate" />
            </Button>
          </If>
        </IAManagment>
      </HeaderControlsContainer>
    </>
  );
};
