import React from 'react';
import { Switch } from 'react-router-dom';
import styled from 'styled-components';

import { PublicRoute } from '../routes';
import { AuthenticationSuccess } from './AuthenticationSuccess';
import NavBar from '../../navigation/NavBar';
import { Content } from '../Content';

const StatusWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

export const StatusRoutes = () => (
  <StatusWrapper>
    <NavBar />
    <Switch>
      <PublicRoute path="/status/authentication" component={AuthenticationSuccess} />
    </Switch>
  </StatusWrapper>
);
