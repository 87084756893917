import React from 'react';
import styled from 'styled-components';

import { Popup, Icon } from 'components/lib';

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  padding: 2px;
  border: solid 1px var(--text);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  left: 18px;
  top: 20px;
`;

const NoteText = styled.div`
  max-height: 124px;
  overflow: auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }
`;

const NoteContent = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  padding: 0px 14px;
`;

const NoteTitle = styled.div`
  font-family: var(--font-family-bold);
`;

const popStyle = {
  width: '200px',
  borderRadius: '4px',
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
  border: 'solid 1px var(--line-standard)',
  padding: '14px 0px',
};

export const ConversationsTableLeadPopup = ({ note }) => {
  return (
    <Popup
      hoverable
      position="bottom center"
      trigger={
        <IconWrapper>
          <Icon name="edit_note" size="12" color="#222222" />
        </IconWrapper>
      }
      content={
        <NoteContent>
          <NoteTitle>Latest Note</NoteTitle>
          <NoteText>{note}</NoteText>
        </NoteContent>
      }
      style={popStyle}
    />
  );
};
