import React from 'react';
import { Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Module } from './Module';
import { Answers } from './Answers';

export const Links = ({ node }) => {
  const { t } = useTranslation();

  return (
    <Module node={node}>
      <Label horizontal color="black">
        Open Link ({t('community')}-specific)
      </Label>
      <Answers node={node} />
    </Module>
  );
};
