import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Icon } from 'semantic-ui-react';

import { sendReportAsPDF, sendPublicReportAsPDF } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import SendAsPDFModal from './SendAsPDFModal';
import { STATUSES } from '../../../constants';

const SendAsPDFBox = ({ report, filters, user }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const closeSendAsPDFModal = () => {
    setIsModalOpened(false);
  };

  const openSendAsPDFModal = () => {
    setIsModalOpened(true);
  };

  const handleSendReport = async (values) => {
    setLoaderStatus(STATUSES.LOADING);

    try {
      const payload = {
        tabs_to_export: Object.keys(values).filter((tab) => values[tab] === true),
        recipient_email: values.recipient_email,
        ...filters,
      };

      if (report.public_id) {
        payload.communities = payload.communities || report.communities.map((p) => p.id);
        payload.traffic_sources = payload.traffic_sources || report.traffic_sources;
        payload.care_types = payload.care_types || report.care_types;
        payload.start_date = payload.start_date || report.start_date;
        payload.end_date = payload.end_date || report.end_date;

        await sendPublicReportAsPDF(report.public_id, payload);
      } else {
        await sendReportAsPDF(payload);
      }

      setLoaderStatus(STATUSES.LOADED);
      toast.success('Exporting report in progress, an email will be sent after completion');
    } catch (error) {
      setLoaderStatus(STATUSES.FAILURE);
      const msg = parseErrorResponse(error, 'There was an error exporting your report.');
      console.error(msg, error?.response);
      toast.error(msg);
    }
    closeSendAsPDFModal();
  };

  return (
    <>
      <Button basic color="blue" onClick={openSendAsPDFModal}>
        <Icon color="red" name="file pdf outline" /> Send As PDF
      </Button>
      <SendAsPDFModal
        loading={loaderStatus}
        open={isModalOpened}
        user={user}
        onClose={closeSendAsPDFModal}
        onSendAsPDF={handleSendReport}
      />
    </>
  );
};

export default SendAsPDFBox;
