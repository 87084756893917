import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { CsvField } from 'components/CsvField';
import { STATUSES } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';

const ModalBody = styled.div`
  .field {
    > div {
      flex-direction: column-reverse;
      padding-bottom: 30px;
    }
    input {
      width: 100%;
      border: solid 1px var(--line-standard);
      background-color: var(--input-field-fill);
      padding: 8px 10px;
      margin: 10px 0 20px;
      &:focus,
      &:active {
        border: 1px solid var(--line-standard);
        outline: none;
      }
    }
    .ui.label {
      border-radius: 4px;
      background-color: #e1f0ff;
      font-family: var(--font-family);
      font-size: 10px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: normal;
      color: #2791ff;
      .delete.icon {
        padding-top: 2px;
      }
    }
  }
`;

const AddAmenity = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  span {
    text-align: center;
    font-family: var(--font-family);
    max-width: 318px;
    :first-child {
      font-size: 14px;
      font-weight: 900;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--text);
    }
    :last-child {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: #89919d;
    }
  }
`;

export default function AmenitiesModal({ isModalOpen, selectedItem, totalItemsCount, onSave, onClose }) {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { t } = useTranslation();

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    await onSave(values);

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const initialValues = { amenities: selectedItem?.amenities };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {({ dirty, touched, handleSubmit, resetForm, values }) => (
        <SettingsModal
          loading={loading}
          open={isModalOpen}
          onModalClose={() => handleClose(resetForm)}
          size="tiny"
          width={540}
        >
          <SettingsModalHeader
            title="Amenities"
            community={selectedItem || null}
            totalItemsCount={totalItemsCount}
            type="header"
          />
          <ModalBody type="body">
            <AddAmenity>Add amenity</AddAmenity>
            <CsvField sortable name="amenities" placeholder="Type in an amenity here, then press Tab or Return." />
            <If condition={!values.amenities?.length}>
              <If condition={selectedItem}>
                <EmptyState>
                  <span>There is nothing here.</span>
                  <span>Use the input above to add amenities for this {t('community')}.</span>
                </EmptyState>
              </If>
              <If condition={!selectedItem}>
                <EmptyState>
                  <span>Adding for multiple {t('communities')}</span>
                  <span>
                    The amenities you add will replace any existing list of amenities for each of the selected{' '}
                    {t('communities')}.
                  </span>
                </EmptyState>
              </If>
            </If>
          </ModalBody>
          <SettingsModalActions
            onSave={handleSubmit}
            onCancel={() => handleClose(resetForm)}
            type="actions"
            disabled={!dirty || !touched}
            saveText="Save"
          />
        </SettingsModal>
      )}
    </Formik>
  );
}
