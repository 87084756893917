import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Formik, Field as FormikField } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form, Button, Grid, Sticky, Input, Confirm, Message, Segment, Popup, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { createTeaser, updateTeaser, deleteTeaser } from 'apis';
import { PageHeader, InputField, ToggleSwitch, UnitInputField } from 'components/common';
import { capitalize } from 'utils/helpers';
import { EmbeddedVSAPreview } from '../TeaserPreviewEVSA';
import { UnloadPrompt } from '../UnloadPrompt';
import { FormWrapper } from '../FormWrapper';
import { ColorPicker } from '../ColorPicker';
import { OptionalField } from '../OptionalField';
import { CodeArea } from '../CodeArea';
import { TeaserCommunitiesField } from './TeaserCommunitiesField';
import { ElementDisplayField } from './ElementDisplayField';

const BLANK_THEME = {
  fontFamily: null,
  fontSize: null,
  color: null,
  fontWeight: null,
  lineHeight: null,
  brandColor: null,

  buttonHeight: null,
  buttonColor: null,
  buttonShadow: null,
  buttonLabelFontSize: null,
  buttonSublabelFontSize: null,
  buttonsBorderRadius: null,

  teaserBackground: null,
  teaserBoxShadow: null,
  teaserBorderRadius: null,
  teaserMinWidth: null,
  teaserMinHeight: null,
  teaserMaxWidth: null,
  teaserMaxHeight: null,
  teaserPadding: null,
  teaserMargin: null,
  teaserMaxOptions: null,
  teaserResizeParentContainer: null,

  headerTitleDisplay: null,
  headerTitleFontFamily: null,
  headerTitleFontSize: null,
  headerTitleColor: null,
  headerTitleFontWeight: null,
  headerTitleLineHeight: null,

  headerSubtitleDisplay: null,
  headerSubtitleFontFamily: null,
  headerSubtitleFontSize: null,
  headerSubtitleColor: null,
  headerSubtitleFontWeight: null,
  headerSubtitleLineHeight: null,
};

const DEFAULT_STYLING_FOR_NEW_INSTANCES = {
  teaserBackground: '#ffffff',
  teaserPadding: '16px 30px',
  teaserMargin: '0 auto',
  teaserBoxShadow: '0 15px 40px 0 rgba(51, 51, 51, 0.15)',
  teaserBorderRadius: '10px',
  headerTitleDisplay: 'block',
  headerSubtitleDisplay: 'block',
  buttonShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.12)',
  buttonsBorderRadius: '6px',
};

const Wrapper = styled.div``;

const SectionHeader = styled.h3`
  margin-top: 4rem;
  width: 100%;

  span {
    margin-right: 2ch;
  }
`;

export const TeaserSettingsForm = ({ chat, teasers, chatProperties, onSubmitted }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { chatId, teaserId } = useParams();
  const wrapperRef = useRef();
  const [showConfirmSubmitPrompt, setShowConfirmSubmitPrompt] = useState(false);
  const [showConfirmDeletePrompt, setShowConfirmDeletePrompt] = useState(false);
  const [previewKey, setPreviewKey] = useState(0);

  const teaser = teaserId === 'new' ? {} : teasers?.filter((t) => t.id === parseInt(teaserId)).pop();

  if (!teaser || !chat) return null;

  const initialValues = {
    query_selector: '',
    placement_method: 'append',
    custom_css: null,
    chat_properties: [],
    ...teaser,
    css_theme: teaser.id
      ? {
          ...BLANK_THEME,
          ...teaser.css_theme,
        }
      : {
          ...BLANK_THEME,
          ...DEFAULT_STYLING_FOR_NEW_INSTANCES,
        },
  };

  const validationSchema = Yup.object({
    query_selector: Yup.string().max(200, "Can't be longer than 200 characters").required('Required'),
    chat_properties: Yup.array()
      .of(
        Yup.object({
          id: Yup.number(),
        })
      )
      .min(1, `Teaser must be enabled on at least one ${t('community')}`)
      .required('Required'),
  });

  const cleanupThemeValues = (values) => {
    return Object.fromEntries(Object.entries(values).filter(([k, v]) => !!v));
  };

  const onSubmit = (values, errors) => {
    setShowConfirmSubmitPrompt(true);
  };

  const onDelete = () => {
    setShowConfirmDeletePrompt(true);
  };

  const onConfirmSubmit = async (rawValues) => {
    try {
      const values = { ...rawValues, css_theme: cleanupThemeValues(rawValues.css_theme) };

      if (!values.id) {
        const response = await createTeaser(chatId, values);
        toast.success('Teaser created and published');
        history.push(`/chats/${chatId}/teasers/${response.id}`);
      } else {
        await updateTeaser(chatId, values.id, values);
        toast.success('Teaser updates published');
      }

      onSubmitted();
    } catch (e) {
      console.error(e);
      toast.error('Error: something went wrong and the changes were not saved. Please try again.');
    }
  };

  const onConfirmDelete = async () => {
    try {
      await deleteTeaser(chatId, teaserId);
      toast.success('Teaser deleted');
      onSubmitted();
      history.push(`/chats/${chatId}/teasers`);
    } catch (e) {
      console.error(e);
      toast.error('Error: something went wrong and the changes were not saved. Please try again.');
    }
  };

  const reloadPreview = () => {
    setPreviewKey((pk) => pk + 1);
  };

  const openQSPicker = (selectedChatProperties) => {
    if (selectedChatProperties.length === 0) {
      toast.error(
        `Teaser is not enabled on any ${t('community')}. Please enable it on at least one, in the ${capitalize(
          t('communities')
        )} table below.`
      );
      return;
    }

    const previewPropId = selectedChatProperties[0].id;
    const communityUrl = chat.chat_properties.find((cp) => cp.id === previewPropId).community.url;
    window.open(`${communityUrl}?FurtherShowElementPickerFor=${previewPropId}`, '_blank');
  };

  return (
    <Wrapper>
      <Formik
        key={teaserId}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, touched, errors, handleSubmit }) => (
          <>
            <FormWrapper ref={wrapperRef}>
              <UnloadPrompt>
                <Form onSubmit={handleSubmit}>
                  <Sticky context={wrapperRef}>
                    <PageHeader
                      title="Embedded VSA Teaser Settings"
                      subtitle="Control how the VSA gets inserted into the client websites"
                      block
                      actions={
                        <>
                          <Button type="submit" primary>
                            {teaserId === 'new' ? 'Create' : 'Update'}
                          </Button>
                          {teaserId !== 'new' && (
                            <Button type="button" onClick={onDelete}>
                              Delete
                            </Button>
                          )}
                          <Button type="button" onClick={reloadPreview}>
                            Reload Preview
                          </Button>
                        </>
                      }
                    />
                  </Sticky>

                  {touched.chat_properties && errors.chat_properties && (
                    <Message
                      attached="bottom"
                      color="red"
                      header="Unable to submit"
                      content="Please fix all errors below & try again"
                    />
                  )}

                  <SectionHeader>Live Preview</SectionHeader>

                  <EmbeddedVSAPreview
                    key={previewKey}
                    chat={chat}
                    teaser={teaser}
                    theme={cleanupThemeValues(values.css_theme)}
                    customCss={values.custom_css}
                  />

                  <Grid verticalAlign="middle">
                    <Grid.Row>
                      <Grid.Column width={10}>
                        <Form.Field>
                          <InputField
                            name="query_selector"
                            label="Query Selector for the HTML Container Element"
                            action={
                              <Popup
                                content={`Opens the first enabled ${capitalize(
                                  t('community')
                                )} in a new tab & shows an Element Picker panel, which can generate Query Selectors.`}
                                trigger={
                                  <Button
                                    type="button"
                                    color="orange"
                                    content="Pick"
                                    icon="crosshairs"
                                    labelPosition="left"
                                    onClick={() => {
                                      openQSPicker(values.chat_properties);
                                    }}
                                  />
                                }
                              />
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid stackable>
                    <SectionHeader>Layout</SectionHeader>
                    <Grid.Row columns="3">
                      <Grid.Column>
                        <label>Placement method</label>
                        <InputField
                          control={Dropdown}
                          name="placement_method"
                          options={[
                            { key: 'replace', value: 'replace', text: 'Replace container content' },
                            { key: 'before', value: 'before', text: 'Insert before container' },
                            { key: 'prepend', value: 'prepend', text: 'Insert before container content' },
                            { key: 'append', value: 'append', text: 'Insert after container content' },
                            { key: 'after', value: 'after', text: 'Insert after container' },
                          ]}
                          fluid
                          selection
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <label>Container Size</label>
                          <Popup
                            content={
                              'Forces the container height to match the height of the Teaser content.' +
                              " Enable this if the container element has fixed height that's smaller than what's needed for the Teaser."
                            }
                            trigger={
                              <ToggleSwitch
                                name="css_theme.teaserResizeParentContainer"
                                label="Resize Container Element to match Teaser size"
                              />
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns="4">
                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={UnitInputField}
                            name="css_theme.teaserMinWidth"
                            fieldLabel="Min width"
                            enabled={!!values.css_theme.teaserMinWidth}
                            defaultValue="300px"
                            unit="px"
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={UnitInputField}
                            name="css_theme.teaserMinHeight"
                            fieldLabel="Min height"
                            enabled={!!values.css_theme.teaserMinHeight}
                            defaultValue="60px"
                            unit="px"
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={UnitInputField}
                            name="css_theme.teaserMaxWidth"
                            fieldLabel="Max width"
                            enabled={!!values.css_theme.teaserMaxWidth}
                            defaultValue="800px"
                            min="300"
                            unit="px"
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={UnitInputField}
                            name="css_theme.teaserMaxHeight"
                            fieldLabel="Max height"
                            enabled={!!values.css_theme.teaserMaxHeight}
                            defaultValue="210px"
                            min="130"
                            unit="px"
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={FormikField}
                            name="css_theme.teaserMargin"
                            fieldLabel="Margin"
                            enabled={!!values.css_theme.teaserMargin}
                            defaultValue="0 auto"
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={FormikField}
                            name="css_theme.teaserPadding"
                            fieldLabel="Padding"
                            enabled={!!values.css_theme.teaserPadding}
                            defaultValue="16px 30px"
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid stackable>
                    <SectionHeader>Style</SectionHeader>
                    <Grid.Row columns="5">
                      <Grid.Column>
                        <OptionalField
                          as={ColorPicker}
                          name="css_theme.brandColor"
                          fieldLabel="Primary (brand) color"
                          enabled={!!values.css_theme.brandColor}
                          defaultValue="#000000"
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <OptionalField
                          as={ColorPicker}
                          name="css_theme.color"
                          fieldLabel="Text color"
                          enabled={!!values.css_theme.color}
                          defaultValue="#222222"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <OptionalField
                          as={ColorPicker}
                          name="css_theme.teaserBackground"
                          fieldLabel="Background color"
                          enabled={!!values.css_theme.teaserBackground}
                          defaultValue="#ffffff"
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns="4">
                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={FormikField}
                            name="css_theme.teaserBoxShadow"
                            fieldLabel="Box Shadow"
                            enabled={!!values.css_theme.teaserBoxShadow}
                            defaultValue="0 15px 40px 0 rgba(51, 51, 51, 0.15)"
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={UnitInputField}
                            name="css_theme.teaserBorderRadius"
                            fieldLabel="Border radius"
                            enabled={!!values.css_theme.teaserBorderRadius}
                            defaultValue="10px"
                            unit="px"
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns="4">
                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={FormikField}
                            name="css_theme.fontFamily"
                            fieldLabel="Font family"
                            enabled={!!values.css_theme.fontFamily}
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={UnitInputField}
                            name="css_theme.fontSize"
                            unit="px"
                            min="8"
                            max="32"
                            fieldLabel="Font size"
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={FormikField}
                            name="css_theme.fontWeight"
                            fieldLabel="Font weight"
                            type="number"
                            min="100"
                            max="1000"
                            step="100"
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <OptionalField as={FormikField} name="css_theme.lineHeight" fieldLabel="Line height" />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid stackable>
                    <SectionHeader>Buttons</SectionHeader>
                    <Grid.Row columns="4">
                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={FormikField}
                            name="css_theme.teaserMaxOptions"
                            fieldLabel="Max visible buttons"
                            enabled={!!values.css_theme.teaserMaxOptions}
                            type="number"
                            defaultValue="3"
                            min="1"
                            max="10"
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={UnitInputField}
                            name="css_theme.buttonHeight"
                            fieldLabel="Button height"
                            enabled={!!values.css_theme.buttonHeight}
                            defaultValue="160px"
                            unit="px"
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={FormikField}
                            name="css_theme.buttonShadow"
                            fieldLabel="Button Shadow"
                            enabled={!!values.css_theme.buttonShadow}
                            defaultValue="0 2px 6px 0 rgba(0, 0, 0, 0.12)"
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={UnitInputField}
                            name="css_theme.buttonsBorderRadius"
                            fieldLabel="Button border radius"
                            enabled={!!values.css_theme.buttonsBorderRadius}
                            defaultValue="6px"
                            unit="px"
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns="3">
                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={UnitInputField}
                            name="css_theme.buttonLabelFontSize"
                            unit="px"
                            min="8"
                            max="32"
                            fieldLabel="Font size"
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <OptionalField
                          as={ColorPicker}
                          name="css_theme.buttonColor"
                          fieldLabel="Primary button text color"
                          enabled={!!values.css_theme.buttonColor}
                          defaultValue="#ffffff"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid stackable>
                    <SectionHeader>
                      <span>Title</span>
                      <ElementDisplayField name="css_theme.headerTitleDisplay" />
                    </SectionHeader>
                    <Grid.Row columns="3">
                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={FormikField}
                            name="css_theme.headerTitleFontFamily"
                            fieldLabel="Font family"
                            enabled={!!values.css_theme.headerTitleFontFamily}
                            defaultValue={values.css_theme.fontFamily}
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={UnitInputField}
                            name="css_theme.headerTitleFontSize"
                            fieldLabel="Font size"
                            unit="px"
                            min="8"
                            max="80"
                            defaultValue="26px"
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <OptionalField
                          as={ColorPicker}
                          name="css_theme.headerTitleColor"
                          fieldLabel="Color"
                          enabled={!!values.css_theme.headerTitleColor}
                          defaultValue="#222222"
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={Input}
                            name="css_theme.headerTitleFontWeight"
                            fieldLabel="Font weight"
                            type="number"
                            min="100"
                            max="1000"
                            step="100"
                            defaultValue="400"
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={Input}
                            name="css_theme.headerTitleLineHeight"
                            fieldLabel="Line Height"
                            defaultValue="1.2"
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid stackable>
                    <SectionHeader>
                      <span>Subtitle</span>
                      <ElementDisplayField name="css_theme.headerSubtitleDisplay" />
                    </SectionHeader>
                    <Grid.Row columns="3">
                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={FormikField}
                            name="css_theme.headerSubtitleFontFamily"
                            fieldLabel="Font family"
                            enabled={!!values.css_theme.headerTitleFontFamily}
                            defaultValue={values.css_theme.fontFamily}
                          />
                        </Form.Field>
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={UnitInputField}
                            name="css_theme.headerSubtitleFontSize"
                            fieldLabel="Font size"
                            unit="px"
                            min="8"
                            max="80"
                            defaultValue="16px"
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <OptionalField
                          as={ColorPicker}
                          name="css_theme.headerSubtitleColor"
                          fieldLabel="Color"
                          enabled={!!values.css_theme.headerTitleColor}
                          defaultValue="#222222"
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={Input}
                            name="css_theme.headerSubtitleFontWeight"
                            fieldLabel="Font weight"
                            type="number"
                            min="100"
                            max="1000"
                            step="100"
                            defaultValue="400"
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <OptionalField
                            as={Input}
                            name="css_theme.headerSubtitleLineHeight"
                            fieldLabel="Line Height"
                            defaultValue="1.2"
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid verticalAlign="middle">
                    <SectionHeader>Additional Customizations</SectionHeader>
                    <Grid.Row>
                      <Grid.Column width={13}>
                        <Form.Field>
                          <OptionalField
                            as={CodeArea}
                            name="custom_css"
                            fieldLabel="Custom CSS (no live preview)"
                            enabled={values.custom_css !== null}
                            placeholder="Paste CSS snippet here..."
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <Segment vertical>
                          Live previews are not available for Custom CSS. Click the "Reload Preview" button if you wish
                          to preview changes you make here.
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <SectionHeader>Related {capitalize(t('communities'))}</SectionHeader>
                  <TeaserCommunitiesField name="chat_properties" chatId={chatId} chatProperties={chatProperties} />
                </Form>
              </UnloadPrompt>
            </FormWrapper>

            <Confirm
              header="Are you sure?"
              content="The changes made here are published immediately, and will be set live when you hit OK!"
              open={showConfirmSubmitPrompt}
              onConfirm={() => {
                setShowConfirmSubmitPrompt(false);
                onConfirmSubmit(values);
              }}
              onCancel={() => {
                setShowConfirmSubmitPrompt(false);
              }}
              size="tiny"
            />

            <Confirm
              header="Are you sure?"
              content="Deleting the teaser will immediatelly affect any live VSAs, without wating on a Publish step!"
              open={showConfirmDeletePrompt}
              confirmButton={<Button negative>Delete</Button>}
              onConfirm={() => {
                setShowConfirmDeletePrompt(false);
                onConfirmDelete(values);
              }}
              onCancel={() => {
                setShowConfirmDeletePrompt(false);
              }}
              size="tiny"
            />
          </>
        )}
      </Formik>
    </Wrapper>
  );
};
