import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';

import { If } from 'components/If';
import { debounce } from 'components/utils';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: var(--text);
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--light-text);
  &.showAll {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }
  a {
    color: var(--light-text);
    &:hover {
      color: var(--link);
    }
  }
  .view-all {
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 1) 100%
    );
    padding-left: 30px;
    color: var(--link);
    cursor: pointer;
  }
`;

export const CommunitiesList = ({ communities }) => {
  const [showAll, setShowAll] = useState(false);
  const [container, setContainer] = useState();
  const [scroll, setScroll] = useState(0);
  const [more, setMore] = useState(0);
  const containerRef = useRef();

  const resizeObserver = new ResizeObserver(
    debounce(() => {
      setScroll(containerRef?.current?.scrollHeight);
    }),
    20
  );

  useEffect(() => {
    resizeObserver.observe(containerRef?.current);
    return () => resizeObserver.unobserve(containerRef?.current);
  }, []);

  useEffect(() => {
    if (containerRef?.current) {
      setContainer(containerRef?.current);
    }
  }, [containerRef]);

  useEffect(() => {
    let containerHeight = 0;
    if (container) {
      containerHeight = containerRef.current.clientHeight;

      if (container.children.length > 0) {
        setMore(
          Array.from(container.children)
            .filter((child) => child.className === 'community')
            .filter((child) => child.offsetTop >= containerHeight).length
        );
      }
    }
  }, [container, scroll]);

  const noOfCommunitiesShown = communities.length - more;
  const handleViewAllClick = () => {
    setShowAll(true);
  };

  return (
    <Container ref={containerRef} className={classNames({ showAll })}>
      {communities.map((community, i) => (
        <React.Fragment key={i}>
          <a
            href={`/communities/${community.id}`}
            target="_blank"
            rel="noreferrer"
            className="community"
            title={community.name}
          >
            {community.name}
          </a>
          <If condition={noOfCommunitiesShown - 1 > i && !showAll}>,&nbsp;</If>
        </React.Fragment>
      ))}
      <If condition={more > 0 && !showAll}>
        <span className="view-all" onClick={handleViewAllClick}>
          and {more} more
        </span>
      </If>
    </Container>
  );
};
