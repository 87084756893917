import React from 'react';
import { formatCount, formatPercent } from 'components/utils';
import styled from 'styled-components';
import { If } from 'components/If';

const OutOf = styled.span`
  color: #666;
`;

export const TableCellStat = ({ count, outOf, asPercent = true }) => (
  <>
    <strong>{formatCount(count)}</strong>
    &nbsp;
    <If condition={!!outOf}>
      <OutOf>({asPercent ? formatPercent(count, outOf) : outOf})</OutOf>
    </If>
  </>
);
