import React, { useCallback, useEffect, useState } from 'react';
import { Dimmer, Dropdown, Grid, Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import SearchInput from 'components/SearchInput';
import { getCRMLocationCodeRules, getCRMCommunities } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PAGINATION, STATUSES } from '../../../constants';
import CRMCommunityList from './CRMCommunityList';
import TestLeadModal from './TestLeadModal';
import CRMCommunityManageModal from './CRMCommunityManageModal';
import { useCRM, setLoaderStatus, setCommunities, setCRMLocationCodeRules } from './context';
import CRMCommunityRemoveModal from './CRMCommunityRemoveModal';
import CRMCommunityInvalidSettings from './CRMCommunityInvalidSettings';

const CRMCommunity = () => {
  const { t } = useTranslation();
  const [{ userId, loaderStatus, communities, crmSettingsOptions }, dispatch] = useCRM();
  const [totalPages, setTotalPages] = useState();
  const [filters, setFilters] = useState({
    search: '',
    crmSettings: [],
    page: PAGINATION.PAGE,
  });
  const [selectedCommunity, setSelectedCommunity] = useState();
  const [openSendTestLead, setOpenSendTestLead] = useState(false);
  const [openManageCommunity, setOpenManageCommunity] = useState(false);
  const [openDeleteCommunity, setOpenDeleteCommunity] = useState(false);

  const fetchCommunities = useCallback(
    async (userId, page, pageSize, search, crm_settings_ids) => {
      try {
        dispatch(setLoaderStatus(STATUSES.LOADING));
        const { results, count } = await getCRMCommunities(userId, {
          page,
          page_size: pageSize,
          cs_id: crm_settings_ids,
          search,
        });

        dispatch(setCommunities(results));
        setTotalPages(Math.ceil(count / pageSize));
        dispatch(setLoaderStatus(STATUSES.LOADED));
      } catch (e) {
        toast.error(parseErrorResponse(e, 'Unable to fetch communities.'));
        dispatch(setLoaderStatus(STATUSES.FAILURE));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (userId) {
      const { page, search, crmSettings } = filters;

      fetchCommunities(userId, page, PAGINATION.PAGE_SIZE, search, crmSettings);
    }
  }, [dispatch, fetchCommunities, filters, userId]);

  useEffect(() => {
    const fetchCRMLocationCodeRules = async () => {
      try {
        dispatch(setLoaderStatus(STATUSES.LOADING));
        const results = await getCRMLocationCodeRules();

        dispatch(setCRMLocationCodeRules(results));
        dispatch(setLoaderStatus(STATUSES.LOADED));
      } catch (e) {
        toast.error(parseErrorResponse(e, 'Unable to fetch CRM location rules.'));
        dispatch(setLoaderStatus(STATUSES.FAILURE));
      }
    };

    fetchCRMLocationCodeRules();
  }, [dispatch]);

  const handleSearch = (value) => {
    setFilters((draft) => ({
      ...draft,
      page: PAGINATION.PAGE,
      search: value,
    }));
  };

  const handleFilter = (e, { value }) => {
    setFilters((draft) => ({
      ...draft,
      page: PAGINATION.PAGE,
      crmSettings: value,
    }));
  };

  const handlePageChange = (activePage) => {
    setFilters((draft) => ({
      ...draft,
      page: activePage,
    }));
  };

  const handleOpenSendTestLead = (community) => {
    setSelectedCommunity(community);
    setOpenSendTestLead(true);
  };

  const handleCloseSendTestLead = () => {
    setSelectedCommunity(null);
    setOpenSendTestLead(false);
  };

  const handleOpenManageCommunity = (community) => {
    setSelectedCommunity(community);
    setOpenManageCommunity(true);
  };

  const handleCloseManageCommunity = () => {
    setSelectedCommunity(null);
    setOpenManageCommunity(false);
  };

  const handleRemoveCommunity = () => {
    setOpenDeleteCommunity(true);
  };

  const handleCloseDeleteCommunity = () => {
    setOpenDeleteCommunity(false);
  };

  const handleConfirmDeleteCommunity = () => {
    setOpenDeleteCommunity(false);
    setOpenManageCommunity(false);
    setSelectedCommunity(null);
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <Grid columns={2}>
        <Grid.Column>
          <SearchInput
            value={filters.search}
            placeholder={`Search ${t('communities')}`}
            fluid
            onSearch={handleSearch}
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            placeholder="Filter by CRM settings"
            fluid
            search
            selectOnBlur={false}
            selectOnNavigation={false}
            selection
            options={crmSettingsOptions}
            onChange={handleFilter}
            value={filters.crmSettings}
            multiple
          />
        </Grid.Column>
      </Grid>

      <CRMCommunityInvalidSettings />

      <CRMCommunityList
        communities={communities}
        totalPages={totalPages}
        activePage={filters.page}
        onPageChange={handlePageChange}
        onSendTestLead={handleOpenSendTestLead}
        onEdit={handleOpenManageCommunity}
      />
      <TestLeadModal open={openSendTestLead} onClose={handleCloseSendTestLead} community={selectedCommunity} />
      <CRMCommunityManageModal
        open={openManageCommunity}
        onClose={handleCloseManageCommunity}
        community={selectedCommunity}
        onRemove={handleRemoveCommunity}
      />
      <CRMCommunityRemoveModal
        open={openDeleteCommunity}
        onConfirm={handleConfirmDeleteCommunity}
        onClose={handleCloseDeleteCommunity}
        community={selectedCommunity}
      />
    </>
  );
};

export default CRMCommunity;
