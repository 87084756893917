import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'semantic-ui-react';

import { useUrlSearchParams } from 'utils/hooks';
import { Checkbox, Menu, Popup } from 'components/lib';

import ChatFlow from './ChatFlow';
import { PageSection } from '../../common';
import ChatFlowCommunities from './ChatFlowCommunites';

const Wrapper = styled.div`
  padding-left: 1rem;
  padding-top: 2rem;

  .ui.attached.segment {
    border: none;
    padding: 0 2rem;
    margin-top: -2rem;
  }

  .ui.grid > .column:not(.row) {
    padding: 0;
  }
`;

const ChatName = styled.div`
  font-size: 1rem;
  font-family: var(--font-family-bold);
  margin-bottom: 0.2rem;
`;

export const ChatFlows = ({ filters, publicId, chats, dateRange }) => {
  const {
    urlSearchParams: { latestChatVersion = true },
    setUrlSearchParams,
  } = useUrlSearchParams();
  const [latestOnly, setLatestOnly] = useState(latestChatVersion);

  const handleToggleLatestOnly = () => {
    setLatestOnly(!latestOnly);
    setUrlSearchParams({ latestChatVersion: !latestOnly && null });
  };

  const tabs = chats.map((chat, i) => ({
    menuItem: (
      <Menu.Item key={i}>
        <ChatName>{chat.name}</ChatName>
        <ChatFlowCommunities chat={chat} />
      </Menu.Item>
    ),
    render: () => (
      <Tab.Pane>
        <ChatFlow
          publicId={publicId}
          filters={filters}
          dateRange={dateRange}
          chat={chat}
          latestChatVersion={latestOnly}
        />
      </Tab.Pane>
    ),
  }));

  return (
    <PageSection
      title="Chat Flows"
      actions={
        <Popup
          content={
            <>
              Use this switch to toggle between displaying chat data for only the latest version of the chat or to
              include all versions. Note that chat flow changes can be incompatible and displaying all versions might
              result in unexpected numbers. If you have any questions or concerns about this feature, please contact
              <a href="mailto:support@talkfurther.com"> support@talkfurther.com</a>.
            </>
          }
          trigger={
            <Checkbox slider checked={latestOnly} onChange={handleToggleLatestOnly} label="Latest Chat Version Only" />
          }
        />
      }
    >
      <Wrapper>
        <Tab menu={{ fluid: true, vertical: true, secondary: true }} panes={tabs} />
      </Wrapper>
    </PageSection>
  );
};
