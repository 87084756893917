import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Module } from './Module';
import { Message } from './Message';
import { jumpToModule } from '../../utils';

const LinkBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LinkItem = styled.div`
  color: #1f57bd;
  font-size: 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
`;

export const Voicemail = ({ node }) => {
  const { module } = node;
  const { text } = module;

  const nextModule = () => {
    jumpToModule(module.action);
  };

  return (
    <Module node={node}>
      {text && <Message>{text}</Message>}
      <If condition={!!module.action}>
        <LinkBox>
          <LinkItem onClick={nextModule}>Go to next module</LinkItem>
        </LinkBox>
      </If>
    </Module>
  );
};
