import React from 'react';
import { UserCommunityModal } from './UserCommunityModal';
import { USER_COMMUNITY_FLOWS } from './constants';

export const UserCommunityAssignModal = () => {
  return (
    <UserCommunityModal
      modalHeader="Assign Community to a Different Client"
      flow={USER_COMMUNITY_FLOWS.ASSIGN}
      modalSubTitle="Reassigning the community will result in the following changes:"
    />
  );
};
