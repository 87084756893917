import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

import { Icon } from 'components/lib';
import { If } from 'components/If';
import { LoginInputField } from './LoginInputField';

const Container = styled.div`
  position: relative;
`;

const EyeIcon = styled(Icon)`
  position: absolute;
  top: 37px;
  right: 20px;
  cursor: pointer;
`;

const StyledInput = styled(LoginInputField)`
  &&&& input {
    padding-right: 50px;
  }
`;

export const PasswordInput = (props) => {
  const [, meta] = useField('password');
  const { value } = meta;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [eyeVisible, setEyeVisible] = useState(false);

  useEffect(() => {
    setEyeVisible(value?.length > 0);
  }, [value]);

  const togglePasswordVisibility = (e) => {
    e.stopPropagation();
    setPasswordVisible((prev) => !prev);
  };

  const passwordVisibleIconName = passwordVisible ? 'visibility' : 'visibility_off';
  const passwordVisibleIconColor = passwordVisible ? 'var(--light-text)' : 'var(--line-standard)';
  const passwordInputType = passwordVisible ? 'text' : 'password';

  return (
    <Container>
      <StyledInput label="Password" name="password" {...props} type={passwordInputType} />
      <If condition={eyeVisible}>
        <EyeIcon
          size="22"
          name={passwordVisibleIconName}
          color={passwordVisibleIconColor}
          onClick={togglePasswordVisibility}
        />
      </If>
    </Container>
  );
};
