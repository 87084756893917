import React from 'react';
import styled from 'styled-components';

import { InputField } from 'components/common';

const Field = styled(InputField)`
  &&&&& {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .input {
      width: ${(props) => (props.width ? `${props.width}px` : '398px')};
      flex-shrink: 0;
    }
    .ui.pointing.label {
      position: absolute;
      right: ${(props) => (props.labelPosition ? `${props.labelPosition}px` : '327px')};
      top: 100%;
      z-index: 1;
    }
  }
`;

export const MyProfileInputField = (props) => {
  return <Field {...props} />;
};
