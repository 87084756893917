import React, { useEffect, useCallback, useContext, useState } from 'react';
import { Dimmer, Loader } from 'components/lib';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { getAdminUsers } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import SearchInput from 'components/SearchInput';
import { MultiSelectFilter } from 'components/common';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import useDataList from 'utils/hooks/useDataList';
import UserManagementHeader from './UserManagementHeader';
import { MEDIA_BREAK_POINTS, STATUSES } from '../../constants';
import UserManagementTable from './UserManagementTable';
import UserManagementDropdownRole from './UserManagementDropdownRole';
import UserManagementDropdownMarket from './UserManagementDropdownMarket';
import { USER_TYPES } from './constants';
import { Button } from '../lib';

const PageContainer = styled.div`
  width: 100%;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  & > .input {
    flex: 1 0 auto;
    margin-bottom: 10px;
  }
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
  }
  padding: 0 35px 0 35px;
`;

const StyledButton = styled(Button)`
  &&&& {
    color: var(--link);
    display: ${(props) => (props.show ? 'block' : 'none')};
  }
`;

const StyledMultiSelectFilter = styled(MultiSelectFilter)`
  .ui.dropdown {
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
    border: solid 1px var(--line-standard);
    border-radius: 0px;
    background-color: white;
    min-width: 17rem;
    font-weight: 500;
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--text-color);
    height: 36px;
  }
`;

const UserManagementList = () => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters } = useDataList();
  const [showClearFilters, setShowClearFilters] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const fetchUsers = useCallback(
    async ({ page, page_size, search, role, ...boolTypeFilters }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await getAdminUsers({
          page,
          pageSize: page_size,
          search,
          role,
          ...boolTypeFilters,
        });
        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (error) {
        toast.error(parseErrorResponse(error), 'Unable to fetch user accounts');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchUsers(filters);
  }, [fetchUsers, filters]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSearch = (value) => {
    setSearch(value);
    setFilters({ ...filters, search: value });
  };

  const onSelectedRoleChange = (roles) => {
    setFilters({ ...filters, role: roles });
  };
  const onSelectedMarketTypeChange = (marketTypes) => {
    setFilters({ ...filters, marketType: marketTypes });
  };
  const onSelectedTypeChange = (types) => {
    if (!types || types.length === 0) {
      setFilters({ payingCustomer: null, inPilot: null, demoAccount: null, type: null });
      setSelectedTypes([]);
      return;
    }

    const boolTypeFilters = {
      payingCustomer: types.includes('Paying Customer'),
      inPilot: types.includes('Pilot'),
      demoAccount: types.includes('Demo'),
    };

    const filteredBoolTypeFilters = Object.fromEntries(
      Object.entries(boolTypeFilters).filter(([_, value]) => value !== false)
    );

    const newFilters = {
      ...filters,
      payingCustomer: filteredBoolTypeFilters.payingCustomer || null,
      inPilot: filteredBoolTypeFilters.inPilot || null,
      demoAccount: filteredBoolTypeFilters.demoAccount || null,
      type: types,
    };

    setFilters(newFilters);
    setSelectedTypes(types);
  };

  useEffect(() => {
    const { page, page_size, ...filterValues } = filters;
    const filteredValues = Object.keys(filterValues)
      .filter((key) => key !== 'pageSize')
      .reduce((obj, key) => {
        obj[key] = filterValues[key];
        return obj;
      }, {});
    const areFiltersApplied = Object.values(filteredValues).some(
      (value) => value !== '' && value !== undefined && value !== null
    );

    setShowClearFilters(areFiltersApplied);
  }, [filters]);

  useEffect(() => {
    setSelectedTypes(filters.type?.length > 0 ? filters.type : null);
  }, [filters.type]);

  const handleFilterReset = () => {
    setFilters({
      search: '',
      role: '',
      marketType: '',
      payingCustomer: '',
      inPilot: '',
      demoAccount: '',
      type: undefined,
    });
    setSelectedTypes(null);
  };

  return (
    <PageContainer>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {loaderStatus !== STATUSES.FAILURE && (
        <>
          <UserManagementHeader />
          <FilterContainer>
            <UserManagementDropdownRole value={filters.role} onChange={onSelectedRoleChange} />
            <UserManagementDropdownMarket value={filters.marketType} onChange={onSelectedMarketTypeChange} />
            <StyledMultiSelectFilter
              name="Type"
              placeholder="Any type"
              selection
              selectedValues={selectedTypes}
              options={USER_TYPES}
              onChange={onSelectedTypeChange}
            />
            <SearchInput value={filters.search} onSearch={handleSearch} placeholder="Search users by email, name" />
            <StyledButton className="link" show={showClearFilters} onClick={handleFilterReset}>
              Clear Filters
            </StyledButton>
          </FilterContainer>
          <UserManagementTable
            users={items}
            activePage={filters.page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
      <If condition={loaderStatus === STATUSES.FAILURE}>
        <FailureMessage icon="search" content="Users could not be loaded" />
      </If>
    </PageContainer>
  );
};

export default UserManagementList;
