import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Button, Image } from 'components/lib';
import { If } from 'components/If';
import { postUnsubscribe } from 'api';
import { ColorLogo } from '../../assets/images';

const Page = styled.div`
  display: flex;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Heading = styled.h1`
  font-family: Avenir;
  font-size: 40px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  margin: 20px 0 0 0;
`;

const Description = styled.p`
  max-width: 490px;
  margin: 20px auto;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
`;

const StyledButton = styled(Button)`
  width: 300px;
  height: 54px;
  margin: 40px 95px 0;
  font-size: 16px;
`;

export default function Unsubscribe() {
  const { token } = useParams();
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [isWrongLink, setIsWrongLink] = useState(false);

  const handleUnsubscribe = useCallback(async () => {
    setDisabledButton(true);
    try {
      await postUnsubscribe(token);
      setIsUnsubscribed(true);
    } catch {
      setIsWrongLink(true);
    }
  }, []);

  const headingText = isUnsubscribed ? 'You have unsubscribed.' : 'Unsubscribe?';
  const descriptionText = isUnsubscribed
    ? 'We will no longer send you emails.'
    : 'Are you sure you want to be removed from email communication? If you change your mind, simply close this page.';
  const buttonText = isWrongLink ? 'Wrong unsubscribe link' : 'Yes, Unsubscribe';

  return (
    <Page>
      <Image src={ColorLogo} width="24px" height="24px" inline />
      <Heading>{headingText}</Heading>
      <Description>{descriptionText}</Description>
      <If condition={!isUnsubscribed}>
        <StyledButton primary color="blue" onClick={handleUnsubscribe} disabled={disabledButton}>
          {buttonText}
        </StyledButton>
      </If>
    </Page>
  );
}
