import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { If } from 'components/If';
import { Icon } from 'components/lib';

const ICON_WIDTH = 24;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active {
    background-color: #fff;
  }
  .icon {
    width: ${ICON_WIDTH}px;
  }
`;

const Text = styled.span`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--60-white);
  margin-left: 10px;
`;

export const ActionButton = ({ onClick, iconName, iconActiveColor, text, active, className }) => {
  const classes = classNames({
    active,
    [className]: className,
  });
  return (
    <Wrapper>
      <IconContainer onClick={onClick} className={classes}>
        <Icon name={iconName} color={active ? iconActiveColor || '#2f2c53' : '#fff'} size={ICON_WIDTH} />
      </IconContainer>
      <If condition={text}>
        <Text>{text}</Text>
      </If>
    </Wrapper>
  );
};
