import React from 'react';
import { Switch } from 'react-router-dom';

import { UserRoute } from 'components/common/routes';
import { USER_TYPES } from '../../constants';
import { CampaignsList } from './Campaigns';
import { Conversations } from './Conversations';
import { ScheduledMessages } from './ScheduledMessages';
import { CampaignStatistics } from './CampaignStatistics';
import { TeamResponseStatistics } from './TeamResponseStatistics';
import { NURTURE_CAMPAIGNS_ROUTE } from './constants';
import { CampaignsProvider } from './Campaigns/CampaignsContext';

const NurtureRoutes = () => (
  <Switch>
    <UserRoute path={NURTURE_CAMPAIGNS_ROUTE} allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.REGIONAL]} exact>
      <CampaignsProvider>
        <CampaignsList />
      </CampaignsProvider>
    </UserRoute>
    <UserRoute
      path="/messaging/campaign-scheduled-messages"
      component={ScheduledMessages}
      allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.MEMBER, USER_TYPES.REGIONAL]}
      exact
    />
    <UserRoute
      path="/messaging/conversations"
      component={Conversations}
      allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.REGIONAL]}
      exact
    />
    <UserRoute
      path="/messaging/campaign-statistics"
      component={CampaignStatistics}
      allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.REGIONAL]}
      exact
    />
    <UserRoute
      path="/messaging/team-response-statistics"
      component={TeamResponseStatistics}
      allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.REGIONAL]}
      exact
    />
  </Switch>
);

export default NurtureRoutes;
