import humps from 'humps';

import { API } from '../api';
import { buildQueryParams, objectToFormData } from './utils';

export const getMessagingCampaigns = async (filters) => {
  const params = buildQueryParams(filters);
  const response = await API.get('/api/campaigns/', { params });
  return humps.camelizeKeys(response.data);
};

export const getCampaignsSearch = async (q) => {
  const params = humps.decamelizeKeys(q);
  const response = await API.get('/api/campaigns/search', { params });
  return humps.camelizeKeys(response.data);
};

export const getMessagingCampaignById = async (campaignId) => {
  const response = await API.get(`/api/campaigns/${campaignId}`);
  return humps.camelizeKeys(response.data);
};

export const createMessagingCampaign = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.post('/api/campaigns/', requestData);
  return humps.camelizeKeys(response.data);
};

export const updateMessagingCampaign = async (campaignId, data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.patch(`/api/campaigns/${campaignId}`, requestData);
  return humps.camelizeKeys(response.data);
};

export const deleteMessagingCampaign = async (campaignId) => {
  const response = await API.delete(`/api/campaigns/${campaignId}`);
  return humps.camelizeKeys(response.data);
};

export const getMessagingStatistics = async (filters) => {
  const params = buildQueryParams(filters);
  const response = await API.get('/api/campaigns/statistics', { params });
  return humps.camelizeKeys(response.data);
};

export const getMessagingCampaignStatistics = async (campaignId) => {
  const response = await API.get(`/api/campaigns/${campaignId}/statistics`);
  return humps.camelizeKeys(response.data);
};

export const getMessagingCommunities = async (filters) => {
  const queryParams = humps.decamelizeKeys(filters);
  const response = await API.get('/api/communities/search', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const updateMessagingCampaignsPriority = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.put(`/api/campaigns/${queryParams?.id}/priority`, { action: queryParams?.action });
  return humps.camelizeKeys(response.data);
};

export const getMessagingRelatedCommunities = async (campaignId) => {
  const response = await API.get(`/api/campaigns/${campaignId}/communities`);
  return humps.camelizeKeys(response.data);
};

export const getCampaignCriteria = async () => {
  const response = await API.get('/api/campaigns/criteria');
  return humps.camelizeKeys(response.data);
};

export const validateCampaignDetails = async (campaignId) => {
  const response = await API.get(`/api/campaigns/${campaignId}/validations`);
  return humps.camelizeKeys(response.data);
};

export const updateCampaignValidationDetails = async (campaignId, data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.patch(`/api/campaigns/${campaignId}/validations`, requestData);

  return humps.camelizeKeys(response.data);
};

export const getCampaignCommunityValidation = async (campaignId, type) => {
  const response = await API.get(`/api/campaigns/${campaignId}/validations/communities/${type}`);
  return humps.camelizeKeys(response.data);
};

export const getCampaignMessagePlaceholders = async (campaignId) => {
  const response = await API.get(`/api/campaigns/${campaignId}/messages/placeholders`);
  return humps.camelizeKeys(response.data);
};

export const createCampaignMessage = async (campaignId, data) => {
  const requestData = objectToFormData(data);
  const response = await API.post(`/api/campaigns/${campaignId}/messages`, requestData);
  return humps.camelizeKeys(response.data);
};

export const editCampaignMessage = async (campaignId, messageId, data) => {
  const requestData = objectToFormData(data);
  const response = await API.patch(`/api/campaigns/${campaignId}/messages/${messageId}`, requestData);
  return humps.camelizeKeys(response.data);
};

export const deleteCampaignMessage = async (campaignId, messageId) => {
  const response = await API.delete(`/api/campaigns/${campaignId}/messages/${messageId}`);
  return humps.camelizeKeys(response.data);
};

export const getCampaignTemplates = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/campaigns/templates', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const createAiCampaign = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.post('/api/campaigns/ai-setup', requestData);
  return humps.camelizeKeys(response.data);
};

export const createCampaignFromTemplate = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.post('/api/campaigns/from-templates', requestData);
  return humps.camelizeKeys(response.data);
};

export const getCampaignBuildRequests = async (params) => {
  const response = await API.get('/api/campaigns/builds', { params });
  return humps.camelizeKeys(response.data);
};

export const getCampaignProgress = async (id) => {
  const response = await API.get(`/api/campaigns/builds/${id}/status`);
  return humps.camelizeKeys(response.data);
};

export const getEmailTemplates = async () => {
  const response = await API.get(`/api/campaigns/email-templates`);
  return humps.camelizeKeys(response.data.results);
};

export const getDefaultStyles = async () => {
  const response = await API.get(`/api/campaigns/email-templates/defaults`);
  return humps.camelizeKeys(response.data);
};

export const getEmailTemplate = async (templateId) => {
  const response = await API.get(`/api/campaigns/email-templates/${templateId}`);
  return humps.camelizeKeys(response.data);
};

export const createEmailTemplate = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.post(`/api/campaigns/email-templates`, requestData);
  return humps.camelizeKeys(response.data);
};

export const updateEmailTemplate = async (templateId, data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.patch(`/api/campaigns/email-templates/${templateId}`, requestData);
  return humps.camelizeKeys(response.data);
};

export const deleteEmailTemplate = async (templateId) => {
  const response = await API.delete(`/api/campaigns/email-templates/${templateId}`);
  return humps.camelizeKeys(response.data);
};

export const getAllPlaceholders = async () => {
  const response = await API.get('/api/campaigns/placeholders');
  return humps.camelizeKeys(response.data);
};

export const getCampaignsConversations = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/campaigns/conversations', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const getCampaignsConversationsMetrics = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/campaigns/conversations/metrics', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const getCampaignScheduledMessages = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/campaigns/scheduled-messages', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const getCampaignScheduledMessagesMetrics = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/campaigns/scheduled-messages/metrics', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const cancelCampaignScheduledLeadMessages = async (campaignId, leadId) => {
  const response = await API.put(`/api/campaigns/${campaignId}/leads/${leadId}/cancel-scheduled-messages`);
  return humps.camelizeKeys(response.data);
};

export const getCampaignStatisticsOverview = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/campaigns/statistics/overview', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const getCampaignStatisticsTeamResponse = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/campaigns/statistics/team-response', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const getAvailableSurveyVSAa = async () => {
  const response = await API.get('/api/campaigns/survey-vsas');
  return response.data;
};

export const updateScheduledMessage = async (scheduledMessageId, data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.put(`/api/campaigns/scheduled-messages/${scheduledMessageId}`, body);
  return humps.camelizeKeys(response.data);
};

export const getUploadedImages = async () => {
  const response = await API.get('/api/campaigns/user-files');
  return response.data;
};

export const getInvalidCampaigns = async () => {
  const response = await API.get('/api/campaigns/invalid');
  return humps.camelizeKeys(response.data);
};

export const addCustomPlaceholder = async (campaignId, data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.post(`/api/campaigns/${campaignId}/placeholders`, body);
  return humps.camelizeKeys(response.data);
};

export const getCustomPlaceholder = async (campaignId, placeholderId) => {
  const response = await API.get(`/api/campaigns/${campaignId}/placeholders/${placeholderId}`);
  return humps.camelizeKeys(response.data);
};

export const updateCustomPlaceholder = async (campaignId, placeholderId, data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.put(`/api/campaigns/${campaignId}/placeholders/${placeholderId}`, body);
  return humps.camelizeKeys(response.data);
};

export const deleteCustomPlaceholder = async (campaignId, placeholderId) => {
  const response = await API.delete(`/api/campaigns/${campaignId}/placeholders/${placeholderId}`);
  return humps.camelizeKeys(response.data);
};
