import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const HeaderIconWrapperContainer = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 10px;
  background-color: #ebf5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: ${({ bgColor }) => bgColor};
`;

const HeaderSubtitleContainer = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const HeaderTitleContainer = styled.div`
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  display: flex;
  align-items: center;
`;

const PageHeaderTitleContainer = styled.div`
  font-family: var(--font-family-bold);
  font-size: 40px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  display: flex;
  align-items: center;
`;

export const Header = ({ children }) => {
  return <Container>{children}</Container>;
};

export const HeaderIconWrapper = ({ children, bgColor, className }) => {
  return (
    <HeaderIconWrapperContainer bgColor={bgColor} className={className}>
      {children}
    </HeaderIconWrapperContainer>
  );
};

export const HeaderSubtitle = ({ children }) => {
  return <HeaderSubtitleContainer>{children}</HeaderSubtitleContainer>;
};

export const HeaderTitle = ({ children }) => {
  return <HeaderTitleContainer>{children}</HeaderTitleContainer>;
};

export const PageHeaderTitle = ({ children }) => {
  return <PageHeaderTitleContainer>{children}</PageHeaderTitleContainer>;
};
