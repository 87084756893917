import React from 'react';
import styled from 'styled-components';

import { If } from '../../If';
import UrlParameterRuleClause from './UrlParameterRuleClause';

const ClauseConnector = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  padding: 12px;
`;

const CRMSourceMappingUrlParameterRuleTable = ({
  name,
  ruleId,
  clauses,
  urlParameterOptions,
  onPush,
  onRemove,
  onReplace,
  disabled,
}) => {
  const handleAdd = () => {
    onPush({ keyword: '', key_value: '' });
  };

  const handleRemove = (rowId, rowsCount) => () => {
    if (rowsCount === 1) {
      onReplace(0, { keyword: '', key_value: '' });
      return;
    }
    onRemove(rowId);
  };

  if (!ruleId && (!clauses || clauses.length === 0)) {
    onPush({ keyword: '', key_value: '' });
  }

  return (
    <div>
      {clauses &&
        clauses.map((clause, index) => (
          <div key={index}>
            <UrlParameterRuleClause
              key={index}
              rowId={index}
              rowsCount={clauses.length}
              baseFieldName={`${name}[${index}]`}
              clause={clause}
              urlParameterOptions={urlParameterOptions}
              handleAdd={handleAdd}
              handleRemove={handleRemove(index, clauses.length)}
              disabled={disabled}
            />
            <If condition={index !== clauses.length - 1}>
              <ClauseConnector>AND</ClauseConnector>
            </If>
          </div>
        ))}
    </div>
  );
};

export default CRMSourceMappingUrlParameterRuleTable;
