import React from 'react';
import styled from 'styled-components';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Modal } from 'components/lib';
import { usePhoneSystem } from './PhoneSystemContext';
import { ActivatePhoneSystemForm } from './fields';

const Header = styled.div`
  margin: 29px 0 10px 30px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  color: var(--text);
  font-family: var(--font-family-bold);
`;

const Content = styled.div`
  margin: 10px 0px;
`;

const SubHeader = styled.div`
  font-family: var(--font-family);
  color: var(--light-text);
  font-weight: 500;
  font-size: 14px !important;
  line-height: 1.43;
`;

const CommunityLink = styled.a`
  color: var(--link);
`;

const validationSchema = Yup.object({
  areaCode: Yup.number().nullable().notRequired().min(0).max(999),
  inheritQuerySelector: Yup.bool(),
  querySelector: Yup.string(),
  poolEnabled: Yup.bool(),
  dedicatedNumbersEnabled: Yup.bool().when('poolEnabled', {
    is: false,
    then: Yup.bool().oneOf([true], 'At least one item should be selected'),
  }),
  dedicatedNumbers: Yup.array().when('dedicatedNumbersEnabled', {
    is: true,
    then: Yup.array()
      .of(
        Yup.object({
          referralOriginType: Yup.string().required('Campaign must be defined'),
          referralOrigin: Yup.string().required('Campaign must be defined'),
          quantity: Yup.number().required('Quantity must be positive integer'),
        })
      )
      .required(),
    otherwise: Yup.array(),
  }),
});

export const ActivatePhoneSystemModal = ({ open, onClose = () => false }) => {
  const { selectedCommunity, onPhoneSystemActivate, isSaving, globalQuerySelector } = usePhoneSystem();

  const initialFormValues = {
    areaCode: '',
    poolEnabled: true,
    dedicatedNumbersEnabled: false,
    inheritQuerySelector: true,
    querySelector: globalQuerySelector || '',
    dedicatedNumbers: [],
  };

  const handleClose = () => {
    if (!onClose) return;

    if (isSaving) return;

    onClose();
  };

  const handleConfirm = async (values) => {
    const requestData = {
      areaCode: values.areaCode || null,
      querySelector: values.querySelector || null,
      inheritQuerySelector: values.inheritQuerySelector,
      poolEnabled: values.poolEnabled,
      dedicatedPhoneNumbers: values.dedicatedNumbersEnabled
        ? values.dedicatedNumbers.map((number) => ({
            quantity: number.quantity,
            referralOrigin: number.referralOrigin,
          }))
        : [],
    };
    await onPhoneSystemActivate(selectedCommunity, requestData);
    onClose();
  };

  if (!selectedCommunity) return null;

  return (
    <Modal open={open} closeIcon onClose={handleClose} size="small">
      <Header>
        Activate Call Tracking
        <SubHeader>
          for <CommunityLink href={`/communities/${selectedCommunity.id}`}>{selectedCommunity.name}</CommunityLink>
        </SubHeader>
      </Header>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={handleConfirm}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {({ handleSubmit, isValid }) => (
          <>
            <Content>
              <ActivatePhoneSystemForm />
            </Content>
            <Modal.Actions>
              <Button primary color="blue" disabled={!isValid || isSaving} loading={isSaving} onClick={handleSubmit}>
                Activate
              </Button>
              <Button basic onClick={handleClose}>
                Close
              </Button>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </Modal>
  );
};
