import React, { useState } from 'react';
import styled from 'styled-components';

import ArchiveInboxImage from 'assets/images/archive-inbox.png';
import { Button, Loader } from 'components/lib';
import { archiveConversations } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { toast } from 'react-toastify';
import ArchiveConversationsModal, { ARCHIVE_ALL_OPTION } from './ArchiveConversationsModal';
import { useMessagesContext } from './MessagesContext';

const Container = styled.div`
  margin-top: 47px;
  padding: 21px 30px 25px 34px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #dbdbdb;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 35px;
`;

const Content = styled.div`
  max-width: 280px;

  & .title {
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
  }

  & .description {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #89919d;
  }
`;

const CleanupConversations = () => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { refreshConversation } = useMessagesContext();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleArchiveConversations = async (value) => {
    try {
      setLoading(true);

      const { totalArchived } = await archiveConversations({ days: value, archiveAll: value === ARCHIVE_ALL_OPTION });

      setLoading(false);
      setOpenModal(false);

      if (totalArchived === 0) {
        toast.info('No conversations to archive');
      } else {
        toast.success(`Total ${totalArchived} conversations archived successfully`);
        refreshConversation();
      }
    } catch (e) {
      setLoading(false);
      toast.error(parseErrorResponse(e, 'Unable to archive conversations'));
    }
  };

  return (
    <Container>
      <Loader active={loading} overlay transparent fullwidth />
      <ImageBox>
        <img src={ArchiveInboxImage} alt="archive_inbox_image" width="75px" height="75px" />
      </ImageBox>
      <Content>
        <div className="title">Let&#39;s clean up a bit.</div>
        <div className="description">We can help archive some of your old conversations. Click here to begin.</div>
      </Content>
      <div>
        <Button primary color="blue" content="Clean Up" onClick={handleOpenModal} />
      </div>

      <ArchiveConversationsModal
        open={openModal}
        loading={loading}
        onConfirm={handleArchiveConversations}
        onClose={handleCloseModal}
      />
    </Container>
  );
};

export default CleanupConversations;
