import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../context/SettingsContext';
import { resetState } from '../context/actions';
import { advancedPages } from './constants';

const ItemContext = createContext();

export const useItemContext = () => useContext(ItemContext);

export const ItemProvider = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [, dispatch] = useSettings();
  const { t } = useTranslation();
  const pages = advancedPages(t);

  const currentPath = window.location.pathname;
  const mainPagePath = '/advanced';

  useEffect(() => {
    for (const section of pages) {
      const foundItem = section.items.find((item) => {
        if (item.subPaths) {
          return item.subPaths.some((subPath) => item.path + subPath === currentPath);
        }
        return item.path === currentPath;
      });
      if (foundItem) {
        setSelectedItem({ ...foundItem, sectionTitle: section.title });
        break;
      }
    }
    if (mainPagePath === currentPath) {
      setSelectedItem(null);
      dispatch(resetState());
    }
  }, [currentPath]);

  const provide = {
    selectedItem,
  };

  return <ItemContext.Provider value={provide} {...props} />;
};
