import React from 'react';
import { useHistory } from 'react-router-dom';
import { Header, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const Content = styled.span`
  cursor: pointer;
`;

const GoBackLink = ({ as, to, goBack, children }) => {
  const history = useHistory();

  const handleBack = () => {
    if (goBack) {
      history.goBack();
    } else {
      history.push(to || '/');
    }
  };

  return (
    <Header as={as} color="blue">
      <Content onClick={handleBack}>
        <Icon name="arrow left" color="blue" />
        {children}
      </Content>
    </Header>
  );
};

GoBackLink.defaultProps = {
  as: 'h4',
  to: undefined,
  goBack: true,
};

export default GoBackLink;
