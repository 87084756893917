import { capitalize } from 'utils/helpers';

export const CONVERSATION_EVENT = {
  TOUR: 'tour',
  CALL: 'call',
  REMINDER: 'reminder',
};

export const CONVERSATION_EVENT_NAME = (t) => ({
  [CONVERSATION_EVENT.TOUR]: t('tour'),
  [CONVERSATION_EVENT.CALL]: t('call'),
  [CONVERSATION_EVENT.REMINDER]: 'reminder',
});

export const SCHEDULE_TYPES = (t) => ({
  [CONVERSATION_EVENT.REMINDER]: {
    class: 'orange',
    color: '#fa7550',
    fadedColor: '#fff1ee',
    icon: 'notifications_active',
    label: 'Reminder',
    buttonSubTitle: 'Set up a',
    requestedText: 'Reminder Set',
    modalHeader: 'Set up a Reminder',
    subtitle:
      'A reminder will bump up this conversation to the top of the list of messages after a set period of time, so you can remember to follow up.',
  },
  [CONVERSATION_EVENT.TOUR]: {
    class: 'blue',
    color: '#275aff',
    fadedColor: '#eff3ff',
    icon: 'tour',
    label: capitalize(t('tour')),
    buttonSubTitle: 'Schedule a',
    confirmedText: `${capitalize(t('tour'))} Confirmed`,
    offeredText: `${capitalize(t('tour'))} Offered`,
    requestedText: `${capitalize(t('tour'))} Requested`,
    modalHeader: `Schedule a ${capitalize(t('tour'))}`,
    modalHeaderReschedule: `Reschedule ${capitalize(t('tour'))}`,
    modalHeaderConfirm: `Confirm ${capitalize(t('tour'))}`,
    subtitle: `Schedule a ${capitalize(t('tour'))} using the calendar below. \n The ${t(
      'lead'
    )} will be notified via email.`,
    subtitleReschedule: `Use this if you have been in touch with the ${t(
      'lead'
    )} and you have agreed on a different date. The ${capitalize(t('tour'))} will be confirmed immediately.`,
  },
  [CONVERSATION_EVENT.CALL]: {
    class: 'green',
    color: '#0ab776',
    fadedColor: '#effaf6',
    icon: 'phone_in_talk',
    label: 'Call',
    buttonSubTitle: 'Schedule a',
    confirmedText: 'Call Confirmed',
    offeredText: 'Call Offered',
    requestedText: 'Call Requested',
    modalHeader: 'Schedule a Call',
    modalHeaderReschedule: 'Reschedule Call',
    modalHeaderConfirm: 'Confirm Call',
    subtitle: `Schedule a call using the calendar below.\n The ${t('lead')} will be notified via email.`,
    subtitleReschedule: `Use this if you have been in touch with the ${t(
      'lead'
    )} and you have agreed on a different date. The call will be confirmed immediately.`,
  },
});

export const TIME_SLOTS = [
  { key: 9, value: 9, text: '9:00 AM' },
  { key: 10, value: 10, text: '10:00 AM' },
  { key: 11, value: 11, text: '11:00 AM' },
  { key: 12, value: 12, text: '12:00 PM' },
  { key: 13, value: 13, text: '1:00 PM' },
  { key: 14, value: 14, text: '2:00 PM' },
  { key: 15, value: 15, text: '3:00 PM' },
  { key: 16, value: 16, text: '4:00 PM' },
];

export const DEFAULT_EVENT_TYPE = 'tour';

export const REQUEST_EVENT_NAMES = {
  CREATED: 'Created',
  UPDATED: 'Updated',
  REMINDER: 'Reminder',
  CONFIRMED: 'Confirmed',
  RESCHEDULED: 'Rescheduled',
  RESCHEDULE_CONFIRMED: 'Reschedule Confirmed',
  RESCHEDULE_CANCELLED: 'Reschedule Cancelled',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
};

export const CONVERSATION_ACTION_QUERY_PARAMETER = 'action';
export const CONVERSATION_ACTIONS = {
  NONE: 'none',
  LINK: 'link',
  SCHEDULE: 'schedule',
  CONFIRM: 'confirm',
  RESCHEDULE: 'reschedule',
  ALTERNATIVE_DATES: 'alternative_date',
  CANCEL: 'cancel',
  MORE_OPTIONS: 'more_options',
  REMINDER: 'reminder',
  CALL_INFO: 'call_info',
  NEW_CALENDAR: 'new_calendar',
};

export const USER_CALENDAR_PROMPT_ACTIONS = [
  CONVERSATION_ACTIONS.CONFIRM,
  CONVERSATION_ACTIONS.RESCHEDULE,
  CONVERSATION_ACTIONS.ALTERNATIVE_DATES,
];

export const REASON_FOR_CANCELLATION = {
  MAX_LENGTH: 500,
  PLACEHOLDER: 'N/A',
};

export const CALL_DIRECTION_TYPES = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
};

export const NEW_CALENDAR = {
  value: 'new_calendar',
  text: 'New Calendar',
};
