import React from 'react';
import styled from 'styled-components';

const On = styled.div`
  font-size: 14px;
  text-align: right;
  padding-top: 2px;
  color: ${(props) => props.color || '#0ab776'};
  white-space: nowrap;

  .text {
    font-family: ${(props) => (!props.bold ? 'var(--font-family)' : 'var(--font-family-bold)')};
    text-transform: capitalize;
  }

  .description {
    font-family: var(--font-family);
    font-size: 12px;
  }
`;

export const SettingsOn = ({ text = 'On', bold = true, description, ...otherProps }) => {
  return (
    <On bold={bold} {...otherProps}>
      <div className="text">{text}</div>
      {description ? <div className="description">{description}</div> : ''}
    </On>
  );
};
