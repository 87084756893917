import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: var(--font-family-bold);
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

export const StepTitle = ({ children }) => {
  return <Container>{children}</Container>;
};
