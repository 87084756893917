import { useEffect } from 'react';

const SMARTLOOK_SCRIPT = `window.smartlook||(function(d) {
  var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
  var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
  c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
  })(document);
  smartlook('init', '${process.env.REACT_APP_SMARTLOOK_KEY}', { region: 'eu' });`;

export const Smartlook = () => {
  // add the smartlook script to the application
  useEffect(() => {
    if (process.env.REACT_APP_SMARTLOOK_KEY) {
      const script = document.createElement('script');
      const smartlookScript = document.createTextNode(SMARTLOOK_SCRIPT);
      script.appendChild(smartlookScript);
      document.head.appendChild(script);
    }
  }, []);

  return null;
};
