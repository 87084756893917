import React from 'react';
import styled from 'styled-components';

const CountDisplay = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
`;

const TableCount = ({ current, total }) => (
  <CountDisplay>
    Showing {current} out of {total}
  </CountDisplay>
);

export default TableCount;
