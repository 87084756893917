import React from 'react';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import { Modal, Icon } from 'components/lib';

const StyledModal = styled(Modal)`
  && {
    border-radius: 0;
    height: 100%;
    width: 100%;
    margin: 0 !important;
    background-color: white;
    top: 0;
    left: 0;
    overflow: hidden;
  }
`;

const CloseModal = styled(Icon)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const ModalFullscreen = ({ fullscreen, children, onClose, ...props }) => {
  return (
    <StyledModal {...props}>
      <Div100vh>
        <CloseModal name="close" onClick={onClose} />
        {children}
      </Div100vh>
    </StyledModal>
  );
};

ModalFullscreen.Actions = Modal.Actions;
ModalFullscreen.Content = Modal.Content;
ModalFullscreen.Description = Modal.Description;
ModalFullscreen.Dimmer = Modal.Dimmer;
ModalFullscreen.Header = Modal.Header;
