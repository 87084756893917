import React, { useContext, useEffect, useMemo } from 'react';

import { toast } from 'react-toastify';
import { Tab } from 'semantic-ui-react';

import SearchInput from 'components/SearchInput';
import { clearQuestionReviews } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { UserContext } from 'components/UserContext';
import { CsvExport, PromptButton } from 'components/common';
import {
  fetchQuestions,
  useQuestionReviews,
  setFilters,
  editQuestionReview,
  setLoaderStatus,
  updateQuestionsCount,
  refreshQuestionsList,
  exportQuestions,
  resetIgnoredQuestionsCount,
  changeSort,
} from './context';
import QuestionReviewList from './QuestionReviewList';
import { PAGINATION, STATUSES, USER_TYPES } from '../../../constants';
import TabHeader, { IncludeExportedQuestionsCheckbox, TabActionsBox, TabFiltersBox } from './TabHeader';

const IgnoredQuestionsTab = () => {
  const { isUserType } = useContext(UserContext);
  const [state, dispatch] = useQuestionReviews();
  const { questions, totalPages, loaderStatus, filters } = state;
  const ignoredQuestionsFilter = useMemo(() => ({ ...filters, isLabeled: false, isIgnored: true }), [filters]);

  useEffect(() => {
    fetchQuestions(dispatch, ignoredQuestionsFilter);
  }, [dispatch, ignoredQuestionsFilter]);

  const handlePageChange = (page) => {
    dispatch(setFilters({ page }));
  };

  const handleSearch = (search) => {
    dispatch(setFilters({ page: PAGINATION.PAGE, search }));
  };

  const handleOrdering = (orderBy) => {
    dispatch(changeSort(orderBy));
  };

  const handleQuestionUpdate = async (questionReviewId, attributes, isIgnored) => {
    const questionReview = questions.find((q) => q.id === questionReviewId);
    const response = await editQuestionReview(dispatch, questionReviewId, attributes, isIgnored);

    if (response && !!attributes) {
      if (!questionReview.processed_at) {
        dispatch(
          updateQuestionsCount({
            labeledQuestionCount: 1,
            ignoredQuestionsCount: -1,
          })
        );
      }

      refreshQuestionsList(state, dispatch, questionReviewId);
    }
  };

  const handleIncludeExported = (e, { checked }) => {
    dispatch(setFilters({ includeExported: checked }));
  };

  const handleArchiveQuestions = async () => {
    try {
      dispatch(setLoaderStatus(STATUSES.LOADING));
      await clearQuestionReviews();
      await fetchQuestions(dispatch, ignoredQuestionsFilter);
      dispatch(resetIgnoredQuestionsCount());

      toast.success('Questions removed successfully');
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e, 'Unable to remove questions.'));
    } finally {
      dispatch(setLoaderStatus(STATUSES.LOADED));
    }
  };

  const handleExportAsCSV = async () => {
    await exportQuestions(dispatch, { ...filters, isLabeled: false, isIgnored: true });
    await fetchQuestions(dispatch, ignoredQuestionsFilter);
    dispatch(resetIgnoredQuestionsCount());
  };

  return (
    <Tab.Pane>
      <TabHeader>
        <TabFiltersBox>
          <SearchInput
            value={filters.search}
            onSearch={handleSearch}
            loading={loaderStatus === STATUSES.LOADING}
            placeholder="Search question..."
          />
          <IncludeExportedQuestionsCheckbox
            label="Include exported questions"
            toggle
            checked={filters.includeExported}
            onChange={handleIncludeExported}
          />
        </TabFiltersBox>
        {isUserType(USER_TYPES.STAFF) && (
          <TabActionsBox>
            <CsvExport content="Export as CSV" exportAction={handleExportAsCSV} skipDownload />
            <PromptButton
              content="Archive"
              basic
              color="blue"
              type="button"
              title="Are you sure you want to archive all ignored questions?"
              actionLabel="Archive"
              confirmButtonProps={{ primary: true }}
              onConfirm={handleArchiveQuestions}
            />
          </TabActionsBox>
        )}
      </TabHeader>
      <QuestionReviewList
        loaderStatus={loaderStatus}
        questions={questions}
        activePage={filters.page}
        ordering={filters.ordering}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        onQuestionUpdate={handleQuestionUpdate}
        onOrdering={handleOrdering}
      />
    </Tab.Pane>
  );
};

export default IgnoredQuestionsTab;
