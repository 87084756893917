import React, { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';

import reducer from './reducer';
import { PAGINATION, STATUSES, SORT_ORDER } from '../../../../constants';

export const COLUMN_SORT_NAMES = {
  UPDATED_ON: 'updated_on',
};

export const DEFAULT_FILTERS = {
  page: PAGINATION.PAGE,
  pageSize: PAGINATION.PAGE_SIZE,
  search: '',
  includeExported: false,
  ordering: {
    orderBy: COLUMN_SORT_NAMES.UPDATED_ON,
    orderAs: SORT_ORDER.DESC,
  },
};

const INITIAL_STATE = {
  loaderStatus: STATUSES.IDLE,
  updateLoaderStatus: STATUSES.IDLE,
  questions: undefined,
  questionsCount: 0,
  totalPages: 0,
  attributes: [],
  filters: DEFAULT_FILTERS,
  newQuestionsCount: undefined,
  labeledQuestionsCount: undefined,
  ignoredQuestionsCount: undefined,
  currentQuestionId: undefined,
};

const QuestionReviewsContext = createContext();

export const QuestionReviewsProvider = (props) => {
  const [state, dispatch] = useImmerReducer(reducer, INITIAL_STATE);

  const value = [state, dispatch];

  return <QuestionReviewsContext.Provider value={value}>{props.children}</QuestionReviewsContext.Provider>;
};

export function useQuestionReviews() {
  const context = useContext(QuestionReviewsContext);

  if (!context) {
    throw new Error(`useQuestionReviews must be used within QuestionReviewsProvider`);
  }

  return context;
}
