import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PercentCircle, ReportComponent, SimpleMetric } from 'components/common/analytics';
import { Toggle } from 'components/lib';
import { formatCount } from 'components/utils';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const ToggleWrapper = styled.div`
  margin-top: 20px;

  > div {
    margin: 5px;
  }
`;

const metrics = [
  'messages.lead',
  'messages.lead.stop',
  'messages.lead.sequential',
  'messages.rereply',
  'messages.sales_assistant',
  'messages.community_manager',
];

const useMessagesTotals = (filters) =>
  useQuery(
    [queryConstants.ADMIN_METRICS, { ...filters, metrics }],
    () => {
      return getMetrics(metrics, filters);
    },
    { placeholderData: {} }
  );

const MessagesTotals = () => {
  const [ignoreBundled, setIgnoreBundled] = useState(true);
  const [ignoreCommunityReplied, setCommunityReplied] = useState(true);

  const { saleAssistantFilters: filters } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };

  const { data, error, status, isFetching } = useMessagesTotals(maFilters);

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch messages metrics'));
  }

  const repliedJustByCommunity =
    (data['messages.community_manager']?.value || 0) - (data['messages.rereply']?.value || 0);

  const messagesData = useMemo(
    () => ({
      'MA Replies': data['messages.sales_assistant']?.value || 0,
      'Not Replied':
        (data['messages.lead']?.value || 0) -
        (data['messages.lead.stop']?.value || 0) -
        (data['messages.sales_assistant']?.value || 0) -
        (ignoreBundled ? data['messages.lead.sequential']?.value || 0 : 0) -
        (ignoreCommunityReplied ? repliedJustByCommunity : 0),
    }),
    [data, ignoreBundled, ignoreCommunityReplied]
  );

  const toggleBundledLeadMessages = () => {
    setIgnoreBundled(!ignoreBundled);
  };

  const toggleCommunityRepliedMessages = () => {
    setCommunityReplied(!ignoreCommunityReplied);
  };

  return (
    <ReportComponent
      title="Messages Totals"
      tooltip="Total number of lead messages and MA replies, excluding STOP messages."
      contentMargins="20px 0"
      contentHeight="300px"
    >
      <PercentCircle
        data={messagesData}
        units="Lead Messages"
        loaderStatus={loaderStatus}
        radius={150}
        circleWidth={15}
        additionalMetrics={
          <>
            <SimpleMetric
              metric={formatCount(data['messages.rereply']?.value)}
              unit="re-replies by community"
              fontSize="24px"
              unitFontSize="16px"
            />
            <SimpleMetric
              metric={formatCount(data['messages.lead.stop']?.value)}
              unit="stop messages"
              fontSize="16px"
              unitFontSize="16px"
            />
          </>
        }
      />
      <ToggleWrapper>
        <Toggle
          checked={ignoreBundled}
          onClick={toggleBundledLeadMessages}
          label={`Exclude ${formatCount(data['messages.lead.sequential']?.value)} bundled lead messages`}
        />
        <Toggle
          checked={ignoreCommunityReplied}
          onClick={toggleCommunityRepliedMessages}
          label={`Exclude ${formatCount(repliedJustByCommunity)} lead messages replied by community`}
        />
      </ToggleWrapper>
    </ReportComponent>
  );
};

export { useMessagesTotals };
export default MessagesTotals;
