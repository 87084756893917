import React, { useContext, useState } from 'react';
import { Button, Modal, Message, Form, TextArea } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { createTour, sendReminder } from 'apis';
import { formatAnyDate } from 'components/utils';
import { parseErrorResponse } from 'apis/utils';
import { MessageContext } from '../MessageContext';
import { STATUSES } from '../../../../constants';
import CalendarCreateTourInfo from './CalendarCreateTourInfo';
import { CheckboxField, InputField } from '../../../common';
import { CALENDAR_REQUEST_SOURCE_TYPE } from '../../../conversation/constants';
import { CALENDAR_MAX_NOTE_LENGTH } from './constants';

const StyledForm = styled(Form)`
  & {
    margin: 0 15px 30px 15px;
  }
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    resize: none;
  }
`;

export const CalendarCreateTour = ({ type, slot, onCloseModal }) => {
  const { leadId } = useParams();
  const { lead, getLead } = useContext(MessageContext);
  const [creatingTour, setCreatingTour] = useState(STATUSES.IDLE);

  if (!slot) return null;

  const start = slot.start;

  const onModalClosed = () => {
    onCloseModal();
  };

  const actionsLoading = creatingTour === STATUSES.LOADING;
  const showTourCreationError = creatingTour === STATUSES.FAILURE;

  const initialValues = {
    date: `date-${formatAnyDate(start, 'yyyy-MM-dd')}`,
    hour: formatAnyDate(start, 'h'),
    minute: formatAnyDate(start, 'mm'),
    am_pm: formatAnyDate(start, 'a'),
    note: '',
    source: CALENDAR_REQUEST_SOURCE_TYPE.MESSAGING_ASSISTANT,
    request_type: type,
    prompt_confirmation: true,
    urgent: false,
    send_reminder: lead?.lead_submitted,
  };

  const validationSchema = Yup.object({
    note: Yup.string().max(CALENDAR_MAX_NOTE_LENGTH, `Can't be longer than 1000 characters`),
  });

  const onSubmit = async ({ send_reminder, ...data }) => {
    try {
      setCreatingTour(STATUSES.LOADING);

      const tour = await createTour(lead.id, data);
      if (send_reminder) {
        await sendReminder(tour.hash, { action_type: type, note: data.note });
      }
      await getLead(leadId);

      setCreatingTour(STATUSES.LOADED);
      toast.success(`Successfully requested ${type}.`);
      onModalClosed();
    } catch (e) {
      console.error(e);
      const message = parseErrorResponse(e, 'Please try again latter.');
      toast.error(`Unable to request ${type}. ${message}`);
      setCreatingTour(STATUSES.FAILURE);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
      {({ values, handleSubmit }) => (
        <Modal dimmer="blurring" open={!!start} closeOnDimmerClick={!actionsLoading} onClose={onModalClosed}>
          <Modal.Header>Request {type}?</Modal.Header>

          <Modal.Content>
            <StyledForm onSubmit={handleSubmit}>
              <InputField control={StyledTextArea} name="note" label="Add note for the community (optional)" rows={8} />
              <CheckboxField name="send_reminder" label="Send reminder to community" disabled={!lead?.lead_submitted} />
            </StyledForm>

            <CalendarCreateTourInfo type={type} date={start} />
            <Message
              error
              hidden={!showTourCreationError}
              content={`Trouble requesting ${type}. Please try again later.`}
            />
          </Modal.Content>

          <Modal.Actions>
            <Button disabled={creatingTour === STATUSES.LOADING} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button
              disabled={(values.confirmed && !values.calendar) || actionsLoading}
              type="submit"
              loading={actionsLoading}
              primary
              onClick={handleSubmit}
            >
              Create
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};
