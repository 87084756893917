import humps from 'humps';
import { PAGINATION } from '../constants';

import { API } from '../api';

export const getAsUsers = async () => {
  const response = await API.get(`/api/chat/users/as-user?page_size=${PAGINATION.MAX_LARGE_PAGE_SIZE}`);
  return response.data;
};

export const getManagedUsers = async (params) => {
  const response = await API.get('/api/chat/managed-users', { params });
  return response.data;
};

export const getAdminUsers = async (params) => {
  const requestParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/users', { params: requestParams });
  return humps.camelizeKeys(response.data);
};
export const getAdminUsersSearch = async (params) => {
  const requestParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/users/search', { params: requestParams });
  return humps.camelizeKeys(response.data);
};

export const getAdminUserDetails = async (userId) => {
  const response = await API.get(`/api/users/${userId}`);
  return humps.camelizeKeys(response.data);
};

export const updateAdminUser = async (userId, data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.put(`/api/users/${userId}`, requestData);
  return humps.camelizeKeys(response.data);
};

export const createAdminUser = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.post(`/api/users`, requestData);
  return humps.camelizeKeys(response.data);
};

export const createManagedUser = async (data) => {
  const response = await API.post('/api/chat/managed-users', data);
  return response.data;
};

export const getManagedUserDetails = async (userId) => {
  const response = await API.get(`/api/chat/managed-users/${userId}`);
  return response.data;
};

export const updateManagedUser = async (userId, data) => {
  const response = await API.put(`/api/chat/managed-users/${userId}`, data);
  return response.data;
};

export const deleteManagedUser = async (userId) => {
  const response = await API.delete(`/api/chat/managed-users/${userId}`);
  return response.data;
};

export const sendResetPasswordEmail = async (data) => {
  const response = await API.post('/api/chat/users/forgot-password', data);
  return response.data;
};

export const resetPassword = async (data) => {
  const response = await API.post(`/api/chat/users/reset-password`, data);
  return response.data;
};

export const getUserCommunities = async (userId) => {
  const response = await API.get(`/api/users/${userId}/communities`);
  return response.data;
};

export const uploadProfilePicture = async (image) => {
  const response = await API.put('/api/users/me/profile/picture', image);
  return humps.camelizeKeys(response.data);
};

export const deleteProfilePicture = async () => {
  const response = await API.delete('/api/users/me/profile/picture');
  return response.data;
};

export const getUserProfile = async () => {
  const response = await API.get(`/api/users/me/profile`);
  return humps.camelizeKeys(response.data);
};

export const updateUserProfile = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.patch(`/api/users/me/profile`, requestData);
  return humps.camelizeKeys(response.data);
};
