import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { Icon, Dimmer, Loader, Popup } from 'components/lib';
import { useField } from 'formik';

import { If } from '../If';

const Wrapper = styled.div`
  position: relative;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'grab')};
  width: ${(p) => (p.value ? '90px' : 'auto')}; /* Updated width */
  opacity: ${(p) => (p.disabled ? 0.6 : 1)};
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  & {
    .edit {
      opacity: 0;
    }
  }

  &:hover {
    .edit {
      opacity: 1;
    }
  }

  div {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    height: 6rem;
    max-width: 100%;
  }
  &.full {
    width: 100%;
    height: 100%;
    max-width: 215px;
    img {
      max-height: 215px;
      height: 80%;
      vertical-align: top;
    }
  }
`;

const OptionalLink = styled.div`
  width: 56px;
  height: 56px !important;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const IconWrapper = styled.div`
  width: 56px;
  height: 56px !important;
  border: 1px dashed #979797;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  i {
    margin: 0 !important;
  }
`;

const PopupStyle = {
  borderRadius: 0,
  backgroundColor: 'transparent',
  padding: 0,
  border: 'none',
  boxShadow: 'none',
};

const Options = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  width: 115px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #ffffff;
`;

const Option = styled.div`
  height: 36px;
  display: flex;
  padding: 0 8px;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid var(--line-standard);
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: rgba(34, 34, 34, 0.6);
`;

export const BrandNewImagePicker = ({ onImageSelected, onImageDelete, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;
  const { disabled, className, openOnClick } = props;
  const [uploading, setUploading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuClick = () => setMenuOpen(!menuOpen);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles?.length === 1) {
        const file = acceptedFiles[0];
        if (typeof onImageSelected === 'function') {
          try {
            setUploading(true);
            const resp = await onImageSelected(file);
            setUploading(false);
            setValue(resp);
          } catch (e) {
            console.error(e);
          }
        }
      }
    },
    [onImageSelected, setValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: ['.png', '.jpg', '.jpeg'],
  });

  const onTrashClick = async (e) => {
    e.stopPropagation();
    if (typeof onImageDelete === 'function') {
      try {
        setUploading(true);
        const resp = await onImageDelete();
        setUploading(false);
        setValue(resp);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const rootProps = { ...getRootProps(), disabled };
  const inputProps = { ...getInputProps(), disabled };
  const hasDelete = typeof onImageDelete === 'function';
  const acceptNewImageOnClick = !value || (value && !openOnClick);

  const trigger = (
    <div>
      <Dimmer active={uploading} inverted>
        <Loader active />
      </Dimmer>
      {value ? (
        <OptionalLink
          style={{
            backgroundImage: `url(${value})`,
          }}
        >
          <Overlay className="edit">
            <Icon name="edit" size={25} color="white" />
          </Overlay>
        </OptionalLink>
      ) : (
        <IconWrapper>
          <Icon name="add" size={15} color="grey" />
        </IconWrapper>
      )}
    </div>
  );

  return (
    <Wrapper className={className}>
      <input {...inputProps} disabled={disabled || !acceptNewImageOnClick} />
      <div>
        <Popup
          position="bottom center"
          on={['click', 'focus']}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          trigger={<div onClick={handleMenuClick}>{trigger}</div>}
          style={PopupStyle}
          basic
          offset={[30, -8]}
        >
          <Options>
            <If condition={!value}>
              <Option {...rootProps} value={value}>
                Upload Picture
              </Option>
            </If>

            <If condition={value}>
              <Option onClick={(e) => onTrashClick(e)}>Remove</Option>
            </If>
          </Options>
        </Popup>
      </div>
    </Wrapper>
  );
};
