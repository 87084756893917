import React, { useMemo } from 'react';

import { ReportComponent } from 'components/common/analytics';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';
import PrevStats from '../common/PrevStats';
import { useLeadTotals } from './LeadTotals';

const LeadTotalsPrevPeriod = () => {
  const { saleAssistantFilters: filters, getPreviousDateRange } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };

  const prevDateRange = getPreviousDateRange();
  const prevFilters = { ...maFilters, ...prevDateRange };

  const totals = useLeadTotals(maFilters);
  const prevTotals = useLeadTotals(prevFilters);

  const prevData = useMemo(() => {
    return [
      {
        label: 'Communities Active',
        value: totals.data['leads.reached.communities']?.value,
        prevValue: prevTotals.data['leads.reached.communities']?.value,
      },
      {
        label: 'Reached Leads',
        value: totals.data['leads.reached']?.value,
        prevValue: prevTotals.data['leads.reached']?.value,
      },
      {
        label: 'Active Leads',
        value: totals.data['leads.replied']?.value,
        prevValue: prevTotals.data['leads.replied']?.value,
      },
      {
        label: 'Opted Out',
        value: totals.data['leads.opted_out']?.value,
        prevValue: prevTotals.data['leads.opted_out']?.value,
        lessIsBetter: true,
      },
      {
        label: 'MA Involved',
        value: totals.data['leads.ma_active']?.value,
        prevValue: prevTotals.data['leads.ma_active']?.value,
      },
      {
        label: 'Qualified Leads',
        value: totals.data['leads.ma_qualified']?.value,
        prevValue: prevTotals.data['leads.ma_qualified']?.value,
      },
      {
        label: 'Disqualified Leads',
        value: totals.data['leads.ma_disqualified']?.value,
        prevValue: prevTotals.data['leads.ma_disqualified']?.value,
      },
    ];
  }, [totals, prevTotals]);

  return (
    <ReportComponent
      title={`Compared to previous ${prevDateRange.day_span} days`}
      tooltip={`Changes in lead metrics compared to previous time period, from ${prevDateRange.start_date} to ${prevDateRange.end_date}.`}
      contentMargins="20px 10px"
      contentHeight="50px"
    >
      <PrevStats data={prevData} />
    </ReportComponent>
  );
};

export default LeadTotalsPrevPeriod;
