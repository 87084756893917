import React from 'react';
import { Icon, Message, Pagination, Table } from 'semantic-ui-react';

import { formatCount, formatDuration, formatPercent } from 'components/utils';
import StickyHeader from 'components/StickyHeader';
import { If } from 'components/If';

const MetricHeader = ({ title }) => (
  <StickyHeader textAlign="center" className="unsortable wrappable">
    {title}
  </StickyHeader>
);

const PercentCell = ({ num, den, children }) => {
  return (
    <Table.Cell textAlign="center">
      {formatCount(num)}
      <If condition={den > 0}>
        <br />
        <small>({formatPercent(num, den)})</small>
      </If>
      {children}
    </Table.Cell>
  );
};

const ResponseCell = ({ active, responses, responseTime }) => {
  const respTime =
    responses > 0 ? (
      <>
        <br />
        <small>~ {formatDuration(responseTime)}</small>
      </>
    ) : null;

  return (
    <PercentCell num={responses} den={active}>
      {respTime}
    </PercentCell>
  );
};

const CommunitiesTable = ({ communities, activePage, totalPages, ordering, onPageChange, onOrderingChange }) => {
  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  const handleOrdering = (column) => () => {
    onOrderingChange(column);
  };

  if (!communities) {
    return null;
  }

  if (communities.length === 0) {
    return <Message warning icon="users" content="Communities not found" />;
  }

  const descending = ordering && ordering[0] === '-';
  const orderAs = ordering && (descending ? 'descending' : 'ascending');
  const orderingColumn = (descending && ordering.substring(1)) || ordering;

  const isSortedBy = (column) => (orderingColumn === column && orderAs) || undefined;

  return (
    <Table sortable striped>
      <Table.Header>
        <Table.Row>
          <StickyHeader sorted={isSortedBy('name')} onClick={handleOrdering('name')}>
            Community Name
          </StickyHeader>
          <StickyHeader sorted={isSortedBy('user__name')} onClick={handleOrdering('user__name')}>
            User
          </StickyHeader>
          <MetricHeader title="Has Messaging Assistant" />
          <MetricHeader title="Leads Reached" />
          <MetricHeader title="Leads Responded" />
          <MetricHeader title="Active Leads" />
          <MetricHeader title="Further Replied" />
          <MetricHeader title="Community Replied" />
          <MetricHeader title="Unqualified by MA" />
          <MetricHeader title="Tours Scheduled by MA" />
          <MetricHeader title="Calls Scheduled by MA" />
          <MetricHeader title="Unreplied without Status" />
          <MetricHeader title="Removed from Outreach" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {communities.map((c) => (
          <Table.Row key={c.id}>
            <Table.Cell>{c.name}</Table.Cell>
            <Table.Cell>{c.user_email}</Table.Cell>
            <Table.Cell textAlign="center">
              {c.has_nurture_assistant && <Icon name="check circle" color="green" />}
            </Table.Cell>
            <Table.Cell textAlign="center">{formatCount(c.reached)}</Table.Cell>
            <PercentCell num={c.responded} den={c.reached} />
            <Table.Cell textAlign="center">{formatCount(c.active_leads)}</Table.Cell>
            <ResponseCell
              responses={c.further_replied_leads}
              active={c.active_leads}
              responseTime={c.further_response_time}
            />
            <ResponseCell
              responses={c.community_replied_leads}
              active={c.active_leads}
              responseTime={c.community_response_time}
            />
            <Table.Cell textAlign="center">{formatCount(c.ma_disqualified)}</Table.Cell>
            <Table.Cell textAlign="center">{formatCount(c.ma_tour_scheduled)}</Table.Cell>
            <Table.Cell textAlign="center">{formatCount(c.ma_call_scheduled)}</Table.Cell>
            <Table.Cell textAlign="center">{formatCount(c.unreplied_without_status)}</Table.Cell>
            <Table.Cell textAlign="center">{formatCount(c.canceled_leads)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      {totalPages > 1 && (
        <Table.Footer>
          <Table.Row>
            <StickyHeader colSpan="8" textAlign="left">
              <Pagination activePage={activePage} onPageChange={handlePageChange} totalPages={totalPages} />
            </StickyHeader>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
};

export default CommunitiesTable;
