import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link, Switch, Route, Redirect } from 'react-router-dom';
import { Dimmer, Grid, Loader, Message, Button } from 'semantic-ui-react';

import { getChat } from 'api';
import { getTeasers, getChatProperties } from 'apis';
import { PageHeader } from 'components/common';
import { STATUSES } from 'constants';
import { VSA_TYPE } from '../constants';
import { TeaserSettingsForm } from './TeaserSettingsForm';
import { TeasersMenu } from './TeasersMenu';

export const Teasers = () => {
  const { chatId } = useParams();
  const [chat, setChat] = useState();
  const [teasers, setTeasers] = useState();
  const [chatProperties, setChatProperties] = useState();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const loadChatAndCommunities = useCallback(async (chatId) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const chat = await getChat(chatId);
      const teasers = await getTeasers(chatId);
      const chatProperties = await getChatProperties({ chat: chatId });
      setChat(chat);
      setTeasers(teasers.results);
      setChatProperties(chatProperties.results);
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      setLoaderStatus(STATUSES.FAILURE);
    }
  }, []);

  useEffect(() => {
    loadChatAndCommunities(chatId);
  }, [chatId]);

  if (loaderStatus === STATUSES.FAILURE) {
    return <div>Error loading chat info...</div>;
  }

  if (loaderStatus === STATUSES.LOADED && chat?.vsa_type !== VSA_TYPE.EMBEDDED) {
    return <div>Teasers can be configured only for the embedded VSA</div>;
  }

  if (loaderStatus === STATUSES.LOADING || !teasers) {
    return (
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <Grid>
      <Grid.Row>
        <Switch>
          <Route exact path="/chats/:chatId/teasers">
            {teasers.length > 0 && (
              <Grid.Column width={3}>
                <TeasersMenu teasers={teasers} />
              </Grid.Column>
            )}
            <Grid.Column>
              <PageHeader
                title="Embedded VSA Teaser Settings"
                subtitle="Control how the VSA gets inserted into the client websites"
                block
              />
              {teasers?.length === 0 && (
                <Message info>
                  <h4>This chat doesn't have any teasers yet.</h4>
                  <Button as={Link} to={`/chats/${chatId}/teasers/new`} content="Add new teaser" icon="plus" basic />
                </Message>
              )}
              {teasers?.length > 0 && <Redirect to={`/chats/${chatId}/teasers/${teasers[0].id}`} />}
            </Grid.Column>
          </Route>

          <Route exact path="/chats/:chatId/teasers/:teaserId">
            <Grid.Column width={3}>
              <TeasersMenu teasers={teasers} />
            </Grid.Column>

            <Grid.Column width={13}>
              <TeaserSettingsForm
                teasers={teasers}
                chat={chat}
                chatProperties={chatProperties}
                onSubmitted={() => {
                  loadChatAndCommunities(chatId);
                }}
              />
            </Grid.Column>
          </Route>
        </Switch>
      </Grid.Row>
    </Grid>
  );
};
