export const CALL_STATUS = {
  PENDING: 'pending', // The call is incoming and hasn't yet been accepted.
  RINGING: 'ringing', // The callee has been notified of the call but has not yet responded
  CONNECTING: 'connecting', // The call was accepted by or initiated by the local Device instance and the media session is being set up.
  OPEN: 'open', // The media session associated with the call has been established.
  CLOSED: 'closed', // The media session associated with the call has been disconnected.
};

export const CALL_STATUS_LABELS = {
  [CALL_STATUS.PENDING]: 'Incoming call',
  [CALL_STATUS.RINGING]: 'Ringing',
  [CALL_STATUS.CONNECTING]: 'Connecting',
  [CALL_STATUS.OPEN]: 'Call in progress',
  [CALL_STATUS.CLOSED]: 'Call completed',
};

export const CALL_DIRECTION = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
};
