import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { getWebFormMappingCandidate } from 'apis';
import { PageHeader } from 'components/common';
import { MappingForm } from './MappingForm';
import { CandidateInfo } from './CandidateInfo';

export const Mapper = () => {
  const history = useHistory();
  const { candidateId } = useParams();
  const [mappingCandidate, setMappingCandidate] = useState();

  const fetchMappingCandidate = useCallback(async () => {
    try {
      const mappingCandidate = await getWebFormMappingCandidate(candidateId);
      setMappingCandidate(mappingCandidate);
      return mappingCandidate;
    } catch (e) {
      console.error(e);
      toast.error('Failed to fetch mapping candidate');
      history.push('/admin/web-forms/mapping-candidates/');
    }
  }, [candidateId]);

  useEffect(() => {
    fetchMappingCandidate();
  }, [fetchMappingCandidate]);

  return (
    <>
      <PageHeader
        as="h2"
        title={<span>Mapping candidate {candidateId}</span>}
        subtitle={<CandidateInfo mappingCandidate={mappingCandidate} />}
        padding={PageHeader.PADDING.NONE}
        margin={PageHeader.MARGIN.NONE}
      />
      {mappingCandidate && (
        <MappingForm mappingCandidate={mappingCandidate} fetchMappingCandidate={fetchMappingCandidate} />
      )}
    </>
  );
};
