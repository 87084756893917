import React, { useContext } from 'react';
import styled from 'styled-components';

import { Statistic } from 'components/common';

import { FeaturesContext } from './FeaturesContext';
import { formatCount } from '../../utils';
import { FEATURE_ACTIVE_STATE } from './constants';

const StyledStatistic = styled(Statistic)`
  &&&&&&& {
    color: red;
  }
`;

export const FeaturesOverviewGlobal = () => {
  const { FEATURE_TYPES, featuresCounts } = useContext(FeaturesContext);

  return (
    <Statistic.Group name="Global Features Overview" marginTop="2rem" marginBottom="2rem">
      {Object.entries(FEATURE_TYPES).map(([featureKey, featureType]) => {
        const featureCount = featuresCounts.filter((feature) => feature.type === parseInt(featureKey));
        const featureActive = featureCount.find((feature) => feature.status === FEATURE_ACTIVE_STATE);
        const featureInactive = featureCount.find((feature) => feature.status !== FEATURE_ACTIVE_STATE);

        const featureActiveCount = featureActive ? featureActive.total : 0;
        const featureInactiveCount = featureInactive ? featureInactive.total : 0;

        return (
          <StyledStatistic
            key={featureKey}
            label={featureType}
            value={formatCount(featureActiveCount)}
            secondaryValue={formatCount(featureInactiveCount)}
            tooltip={`Number of communities with ${featureType} active versus inactive`}
          />
        );
      })}
    </Statistic.Group>
  );
};
