import React, { useEffect, useState, useCallback } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import {
  cancelCampaignScheduledLeadMessages,
  getCampaignScheduledMessages,
  getCampaignScheduledMessagesMetrics,
} from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { useUrlSearchParams } from 'utils/hooks';
import { PAGINATION, STATUSES } from '../../../constants';
import ScheduledMessagesList from './ScheduledMessagesList';
import { useNurtureFilter } from '../NurtureFilterContext';
import { CAMPAIGN_TYPE_NAME } from '../constants';

export const ScheduledMessages = () => {
  const {
    state: { filters },
    buildQueryParams,
  } = useNurtureFilter();
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.LOADED);
  const [scheduledMessages, setScheduledMessages] = useState();
  const [metrics, setMetrics] = useState({});
  const [search, setSearch] = useState(urlSearchParams.search || '');
  const [scheduledDate, setScheduledDate] = useState(urlSearchParams.scheduled_date);

  setUrlSearchParams({
    communities: filters.communities,
    groups: filters.groups,
    campaigns: filters.campaigns,
    search,
    scheduled_date: scheduledDate,
  });

  const fetchScheduledMessages = useCallback(async (filters, search, scheduledDate) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const params = buildQueryParams({
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.MEDIUM_PAGE_SIZE,
        communities: filters.communities,
        groups: filters.groups,
        campaigns: filters.campaigns,
        search,
        scheduledDate,
      });
      const { results } = await getCampaignScheduledMessages(params);

      setScheduledMessages(results);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to fetch campaign scheduled messages'));
    } finally {
      setLoaderStatus(STATUSES.LOADED);
    }
  }, []);

  const fetchScheduledMessagesMetrics = useCallback(async (filters, scheduledDate) => {
    try {
      const params = buildQueryParams({
        communities: filters.communities,
        groups: filters.groups,
        campaigns: filters.campaigns,
        scheduledDate,
      });
      const response = await getCampaignScheduledMessagesMetrics(params);
      setMetrics(response);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to fetch campaign scheduled messages metrics'));
    }
  }, []);

  useEffect(() => {
    fetchScheduledMessages(filters, search, scheduledDate);
  }, [filters, search, scheduledDate, fetchScheduledMessages]);

  useEffect(() => {
    fetchScheduledMessagesMetrics(filters, scheduledDate);
  }, [filters, scheduledDate]);

  const handleRemove = async (scheduledMessage) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const { campaign, lead } = scheduledMessage;

      await cancelCampaignScheduledLeadMessages(campaign.id, lead.id);
      await fetchScheduledMessages(filters, search, scheduledDate);
      await fetchScheduledMessagesMetrics(filters, scheduledDate);

      const leadName = [lead.firstName, lead.lastName].filter((np) => !!np).join(' ');

      toast.success(
        `${CAMPAIGN_TYPE_NAME[campaign.campaignType]} campaign scheduled messages for ${leadName} have been cancelled.`
      );
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to remove lead from campaign.'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <ScheduledMessagesList
        scheduledMessages={scheduledMessages}
        search={search}
        metrics={metrics}
        scheduledDate={scheduledDate}
        onRemove={handleRemove}
        onSearch={setSearch}
        onToggleScheduledDate={setScheduledDate}
      />
    </>
  );
};
