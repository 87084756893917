import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  min-height: 60px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 18px;
  cursor: pointer;
  .ordering-indicator {
    display: none;
  }
  &:hover {
    .ordering-indicator {
      display: block;
    }
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--line-standard);
  }
`;

export const ListRow = ({ children, className, onClick }) => {
  return (
    <Container className={className} onClick={onClick}>
      {children}
    </Container>
  );
};
