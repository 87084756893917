import React from 'react';
import styled from 'styled-components';

import { InputField } from 'components/common';

const NameInputField = styled(InputField)`
  &&&&& {
    input {
      font-size: 14px;
      width: ${({ width }) => width || '600px'};
      height: 36px;
      border-radius: 0;
      border: 1px solid var(--line-standard);
      background-color: var(--input-field-fill);
      padding: 6px 20px 6px 10px;
      color: var(--text);
      font-weight: 500;
      font-family: var(--font-family);

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--input-field-fill) inset;
        border-color: #777;
      }

      &:focus {
        border-color: #777;
      }
    }

    &&&&& .field {
      margin: 0;
      width: 100%;
    }

    &&&&& .input {
      width: 100%;
    }
  }
`;

export const NameField = ({ width, disabled = false }) => (
  <NameInputField
    width={width}
    disabled={disabled}
    name="name"
    placeholder="Type a name for your campaign here"
    newError
  />
);
