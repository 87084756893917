import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Popup } from 'semantic-ui-react';

const MoreOptionsWrapper = styled.div`
  width: 40px;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  &:hover {
    padding: 6px 12px;
    border-radius: 8px;
    background-color: #e8e8e8;
  }
`;

const DotsActionButton = ({ onClick, title }) => (
  <Popup
    trigger={
      <MoreOptionsWrapper>
        <Icon fitted size="small" name="ellipsis horizontal" />
      </MoreOptionsWrapper>
    }
    on="click"
    basic
  >
    <Button onClick={onClick}>{title}</Button>
  </Popup>
);

export default DotsActionButton;
