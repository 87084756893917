import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Icon, FURHTER_ICON, Modal } from 'components/lib';
import { numberFormatter, pluralize } from 'utils/helpers';
import { ProductModal } from '.';

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  padding-left: 20px;
  border-bottom: solid 1px var(--line-standard);
  background-color: var(--white);
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  padding: 9px;
  border-radius: 10px;
  background-color: #defff3;
  background-color: ${({ backgoundColor }) => backgoundColor};
  & > .flipped {
    transform: rotate(270deg) scaleX(-1);
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-right: auto;
  h6,
  p,
  span:not(.${FURHTER_ICON}) {
    margin: 0;
    font-family: var(--font-family);
    font-size: 12px;
    color: #89919d;
  }
  h6 {
    font-size: 16px;
    font-family: var(--font-family-bold);
    color: var(--text);
  }
`;

const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  p {
    margin-right: 5px;
  }
  && > span {
    color: ${(p) => (p.$active ? 'var(--emerald-green)' : 'var(--salmon)')};
  }
  && > .notUsed {
    margin-left: 5px;
    color: #89919d;
  }
`;

const ActivateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  p {
    margin: 0;
    font-family: var(--font-family);
    font-size: 12px;
    text-align: right;
    color: var(--link);
  }
`;

const BenefitsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  flex-direction: column;
  background-color: #5f5fff;
  height: 100%;
  width: 180px;
  position: relative;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 11px;
    background-color: #5f5fff;
  }
  &::after {
    top: auto;
    bottom: -10px;
  }
`;

const BenefitValue = styled.h5`
  font-family: var(--font-family);
  font-size: 24px;
  color: var(--white);
  margin: 0;
`;

const Benefit = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--white);
  display: flex;
`;

const BenefitDisclaimer = styled.span`
  font-family: var(--font-family);
  font-size: 11px;
  text-align: center;
  color: var(--60-white);
`;

const AIpowered = styled.div`
  width: 24px;
  height: 16px;
  padding: 0 6px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.6);
  content: 'IA';
  margin-right: 6px;
  font-family: var(--font-family-bold);
  font-size: 12px;
  text-align: center;
  color: #5f5fff;
`;

export const Product = ({ product }) => {
  const {
    ProductIcon,
    iconBancground,
    name,
    subtitle,
    active,
    benefitValue,
    benefit,
    isAIpowered,
    numberOfCommunitiesThatDontUseFeature,
    isActivatedRecently,
  } = product;
  const lineRef = useRef(null);

  // detect mouse hover on the line
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  useEffect(() => {
    lineRef.current.addEventListener('mouseenter', handleMouseEnter);
    lineRef.current.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      lineRef.current.removeEventListener('mouseenter', handleMouseEnter);
      lineRef.current.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [lineRef.current]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      className="medium"
      content={<ProductModal product={product} />}
      trigger={
        <LineContainer ref={lineRef}>
          <IconContainer backgoundColor={iconBancground}>
            <ProductIcon />
          </IconContainer>
          <HeadingContainer>
            <h6>{name}</h6>
            <Subtitle $active={active}>
              <p>{subtitle} ·</p>
              <span>{active ? 'Active ' : 'Not active'}</span>{' '}
              {active && numberOfCommunitiesThatDontUseFeature > 0 && (
                <span className="notUsed">
                  · Not used on {`${pluralize(numberOfCommunitiesThatDontUseFeature, 'communities', 'community')}`}
                </span>
              )}
            </Subtitle>
          </HeadingContainer>
          <ActivateContainer>
            <If condition={!active}>
              <p>Activate</p>
            </If>
            <If condition={!active || hover}>
              <Icon name="chevron_right" color="#222" size={24} />
            </If>
          </ActivateContainer>
          <BenefitsContainer>
            <BenefitValue>+{numberFormatter(benefitValue, 1)}</BenefitValue>
            <Benefit>
              <If condition={isAIpowered}>
                <AIpowered>AI</AIpowered>
              </If>
              {benefit}
            </Benefit>
            <BenefitDisclaimer>
              <If condition={isActivatedRecently}>
                <p>Activated recently</p>
              </If>
            </BenefitDisclaimer>
          </BenefitsContainer>
        </LineContainer>
      }
    />
  );
};
