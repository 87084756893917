import React from 'react';
import { Link, Switch, Redirect, Route, useParams } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { Content, PageHeader } from 'components/common';
import UserCommunitySurveyData from 'components/communities/panels/UserCommunitySurveyData';

const COMMUNITY_TABS = [
  {
    key: 'survey',
    path: '/admin/communities/:communityId/survey',
    exact: true,
    label: 'Survey Data',
    component: UserCommunitySurveyData,
  },
];

const CommunityData = () => {
  const { communityId } = useParams();
  return (
    <Content>
      <PageHeader
        as="h2"
        title="Community Data"
        subtitle="Admin page for editing client's community data."
        padding={PageHeader.PADDING.VERTICAL}
        margin={PageHeader.MARGIN.NONE}
      />
      <Menu size="large" secondary pointing>
        {COMMUNITY_TABS.map((item) => (
          <Menu.Item
            key={item.key}
            as={Link}
            to={`${item.path}${location.search}`}
            active={location.pathname === item.path}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
      <Switch>
        {COMMUNITY_TABS.map((item) => (
          <Route {...item} component={() => item.component({ communityId })} />
        ))}
        <Redirect to={COMMUNITY_TABS[0].path} />
      </Switch>
    </Content>
  );
};

export default CommunityData;
