import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from 'components/UserContext';
import { WithTopBar } from 'components/WithTopBar';
import { BASE_NURTURE_PATHNAME, DEFAULT_NURTURE_USER_ROUTE } from './constants';
import Routes from './NurtureRoutes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Nurture = () => {
  const [isRouted, setIsRouted] = useState(false);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === BASE_NURTURE_PATHNAME) {
      history.replace(DEFAULT_NURTURE_USER_ROUTE[user.type]);
    }

    setIsRouted(true);
  }, [history, location, user]);

  // To keep backward compatibility with old URLs
  if (location.pathname.startsWith('/nurture')) {
    return <Redirect to={location.pathname.replace('/nurture', '/messaging') + location.search} />;
  }

  if (!isRouted) {
    return null;
  }

  return (
    <WithTopBar>
      <Container>
        <Routes />
      </Container>
    </WithTopBar>
  );
};
