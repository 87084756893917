import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 12px;
  height: 12px;
  padding: 2px 2px 5px;
  border-radius: 1px;
  background-color: #ffd1d1;
`;

const LongLine = styled.div`
  width: 8px;
  height: 2px;
  margin: 0 0 1px;
  border-radius: 1px;
  background-color: #ff5f5f;
`;
const ShortLine = styled.div`
  width: 4px;
  height: 2px;
  margin: 1px 4px 0 0;
  border-radius: 1px;
  background-color: #ff5f5f;
`;

export const Dynamic = () => (
  <Wrapper>
    <LongLine />
    <ShortLine />
  </Wrapper>
);
