import React from 'react';
import { Image } from 'semantic-ui-react';

export const ImageBox = ({ size, images }) => (
  <Image.Group size={size}>
    {images.map((image, index) => (
      <Image key={index} src={image} rounded fluid verticalAlign="top" />
    ))}
  </Image.Group>
);
