import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { useQueryClient } from 'react-query';

import { logOut } from '../../api';
import { MEDIA_BREAK_POINTS } from '../../constants';
import { If } from '../If';

import { UserContext } from '../UserContext';

const ProfilePicture = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 12px;
  transition: opacity 0.3s ease;
  background-image: ${(props) => `url('${props.src}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const UserInfoWrapper = styled.div`
  white-space: nowrap;
  color: white;
  display: none;
  align-items: center;
  justify-content: ${(props) => (props.right ? 'flex-end' : 'space-between')};
  width: 100%;

  ${ProfilePicture} {
    margin-right: 1rem;
    padding: 1.1rem;
    display: inline-block;

    line-height: 0;
  }

  .ui.button {
    margin: 0 12px;
    flex: 0;
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    display: flex;
  }
`;

const UserInfoDisplay = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const UserEmail = styled.span`
  width: ${(props) => (props.hasProfilePic ? '175px' : '215px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserInfo = ({ right }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { user, setUser } = useContext(UserContext);
  const logout = async () => {
    try {
      await logOut();
    } catch (e) {
      console.error(e);
    } finally {
      queryClient.clear();
      setUser(null);
      history.push('/login');
    }
  };

  return (
    <UserInfoWrapper right={right}>
      <If condition={user}>
        <If condition={user.profile_picture}>
          <ProfilePicture src={user.profile_picture} alt={user.first_name} />
        </If>
        <UserInfoDisplay>
          <span>
            {user.first_name} {user.last_name}
          </span>
          <UserEmail title={user.email} hasProfilePic={!!user.profile_picture}>
            ({user.email})
          </UserEmail>
        </UserInfoDisplay>
      </If>
      <Button
        basic
        fluid
        inverted
        circular
        size="tiny"
        icon="log out"
        title="Log Out"
        onClick={() => {
          logout();
        }}
      />
    </UserInfoWrapper>
  );
};
