import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal, Button } from 'components/lib';

const StyledModal = styled(Modal)`
  && {
    .close {
      top: 10px;
      right: 25px;
    }
  }
`;

const ModalHeader = styled(StyledModal.Header)`
  &&&& {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: 900;
    color: var(--text);
    border: none;
  }
`;

const ModalContent = styled(StyledModal.Content)`
  &&&& {
    padding: 0 30px;
    min-height: 80px;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--light-text);
  }
`;

export const ResendMessageModal = ({ open, loading, onSend, onCancel }) => {
  const { t } = useTranslation();

  return (
    <StyledModal open={open} size="tiny" closeIcon onClose={onCancel}>
      <ModalHeader>Try sending the message again?</ModalHeader>
      <ModalContent>
        Looks like the message was not delivered to the {t('lead')}. Would you like to try sending it again?
      </ModalContent>
      <Modal.Actions>
        <Button primary color="blue" onClick={onSend} loading={loading} disabled={loading}>
          Send
        </Button>
        <Button basic onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
};
