import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { MessagesPreview } from './MessagesPreview';
import { MessageContextProvider } from './MessageContext';
import InstantResponses from './InstantResponses';
import ManageAnswersTab from '../ManageAnswers/ManageAnswersTab';

const InstantResponsesRoutes = () => (
  <Switch>
    <MessageContextProvider>
      <Route exact path="/admin/instant-responses" component={InstantResponses} />
      <Route path="/admin/instant-responses/:leadId/:type/:messageId?" component={MessagesPreview} />
    </MessageContextProvider>
  </Switch>
);

export default InstantResponsesRoutes;
