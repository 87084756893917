import React from 'react';
import styled from 'styled-components';
import { isValid } from 'date-fns';

import { formatAnyDate } from 'components/utils';
import { If } from '../../If';

export const Time = styled.span`
  font-family: var(--font-family);
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: right;
  color: #89919d;
  flex: 1 0 auto;
`;

export const ReminderTime = ({ date }) => {
  const isDateValid = isValid(date);

  return (
    <Time>
      <If condition={isDateValid}>{formatAnyDate(date, 'h:mm a')}</If>
      <If condition={!isDateValid}>
        <span>Date and time not specified</span>
      </If>
    </Time>
  );
};
