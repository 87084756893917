import React from 'react';
import { Form, Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { confirmPhoneNumbersImport } from 'api';

const ERROR_COLUMN = 'Errors';

export const PhoneNumbersPreview = ({ formRef, data, setLoading, onUpdateComplete }) => {
  const { filename, records } = data;
  const columns = Object.keys(records[0]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await confirmPhoneNumbersImport({ filename });
      toast.success(`Successfully updated ${response?.updated} numbers`);
      onUpdateComplete(response?.updated);
    } catch (error) {
      const msg = `Trouble importing phone numbers!`;
      console.error(msg, error?.response);
      toast.error(msg);
    }

    setLoading(false);
  };

  const initialValues = {
    records,
  };

  const validationSchema = Yup.object().shape({
    records: Yup.array()
      .compact((v) => !v)
      .required(),
  });

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Table>
            <Table.Header>
              <Table.Row>
                {columns.map((name) => (
                  <Table.HeaderCell key={name}>{name}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {records.map((row, i) => (
                <Table.Row key={i} negative={!!row[ERROR_COLUMN]}>
                  {columns.map((columnName, j) => {
                    return (
                      <Table.Cell key={j}>
                        <span>{row[columnName]}</span>
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Form>
      )}
    </Formik>
  );
};
