import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { setIsMultipleSelection, setResetSelectedItems } from '../context/actions';
import { useSettings } from '../context/SettingsContext';

const Toggle = styled.div`
  width: 36px;
  height: 36px;
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #ffffff;
  cursor: pointer;
  user-select: none;

  &.active {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #275aff;
    background-color: #f5f8ff;

    > span {
      color: #275aff;
    }
  }
`;

export const SettingsTableToggle = () => {
  const [{ isMultipleSelection }, dispatch] = useSettings();
  const handleOnClick = () => {
    dispatch(setIsMultipleSelection());
    dispatch(setResetSelectedItems());
  };
  return (
    <Toggle className={isMultipleSelection ? 'active' : ''} onClick={handleOnClick}>
      <Icon name="checklist" size={24} />
    </Toggle>
  );
};
