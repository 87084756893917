import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Dropdown, Icon, Table } from 'semantic-ui-react';
import { If } from 'components/If';
import { Checkbox } from 'components/lib';
import styled from 'styled-components';

import InstantResponseMessageText from './InstantResponseMessageText';

const DateTimeValue = styled.p`
  white-space: nowrap;
`;

const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown {
    display: none;
  }
`;

const StyledTableRow = styled(Table.Row)`
  &:hover {
    ${StyledDropdown} {
      display: inline;
    }
  }
`;

const SubmissionStatus = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => (props.submitted ? 'initial' : 'red')};

  .info {
    font-size: 12px;
    display: ${(props) => (props.submitted ? 'none' : 'flex')};
  }
`;

const MessageTextCell = styled(Table.Cell)`
  overflow: hidden;
  /* this fixes very long strings in message like URLs pushing the width of the table beyond available screen size */
  word-break: break-word;
`;

const InstantResponsesTableRow = ({ message, selectable, selected, onToggleArchive, onToggleSelected }) => {
  return (
    <StyledTableRow key={message.lead_id}>
      <If condition={selectable}>
        <Table.Cell>
          <Checkbox checked={selected} onChange={onToggleSelected(message)} />
        </Table.Cell>
      </If>
      <Table.Cell>
        <Link to={`/admin/instant-responses/${message.lead_id}/${message.last_message_type}`}>{message.lead_name}</Link>
      </Table.Cell>
      <MessageTextCell width={6}>
        <InstantResponseMessageText text={message.last_message} conversationType={message.last_message_type} />
      </MessageTextCell>
      <Table.Cell>
        <DateTimeValue>{format(parseISO(message.last_message_at), 'MM/dd/yyyy hh:mm a')}</DateTimeValue>
      </Table.Cell>
      <Table.Cell textAlign="center">
        {(message.replied_by_community || message.replied_by_further) && <Icon name="check circle" color="green" />}
      </Table.Cell>
      <Table.Cell>{message.community_name}</Table.Cell>
      <Table.Cell>
        <SubmissionStatus submitted={message.lead_submitted}>
          <span>{message.lead_submitted ? 'Submitted' : 'Not Submitted'}</span>
          <div className="info">Not forwarded to community</div>
        </SubmissionStatus>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <StyledDropdown icon="ellipsis horizontal">
          <Dropdown.Menu>
            <Dropdown.Item
              text={message.is_further_archived ? 'Restore' : 'Archive'}
              onClick={onToggleArchive(message)}
            />
          </Dropdown.Menu>
        </StyledDropdown>
      </Table.Cell>
    </StyledTableRow>
  );
};

export default InstantResponsesTableRow;
