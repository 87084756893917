import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { Form, Icon } from 'components/lib';
import { useCampaigns } from 'components/nurture/Campaigns/CampaignsContext';
import { CAMPAIGN_TYPE } from 'components/nurture/constants';
import { InputField } from 'components/common';
import { getTomorrow } from 'utils/helpers';
import { useCampaignDetails } from '../CampaignDetailsContext';
import { CampaignModal, CampaignModalActions } from './index';
import { NameField } from '../EditCampaigns/form/fields';
import Dropdown from '../EditCampaigns/form/fields/Dropdown';
import { CommunitiesMenu } from '../EditCampaigns/sidebar/Communities/CommunitiesMenu';
import { HeaderIconWrapper } from './Header';
import Field from '../EditCampaigns/sidebar/Criteria/Fields';
import StartDateField from '../EditCampaigns/form/fields/StartDateField';

const BodyWrapper = styled.div`
  width: 100%;
  height: 360px;
  display: grid;
  grid-template-columns: 510px 400px;
`;

const Label = styled.label`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-right: 10px;
`;

const FormContainer = styled.div`
  margin-top: 30px;
  margin-right: 30px;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 15px 0;
  align-items: center;

  label {
    text-align: right;
    max-width: 80px;
  }

  span {
    position: relative;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  line-height: 28px;
`;

const ModalHeaderIconWrapper = styled(HeaderIconWrapper)`
  width: 28px;
  height: 28px;
  border-radius: 4px;
`;

const HEADER_PARTS = {
  [CAMPAIGN_TYPE.SALES]: <Icon name="campaign" size="22" color="#2791ff" />,
  [CAMPAIGN_TYPE.FOLLOW_UP]: <Icon name="emoji_events" size="22" color="#ff5b98" />,
  [CAMPAIGN_TYPE.ONE_TIME]: <Icon name="event_available" size="22" color="#0ab776" />,
};

const descriptionText = {
  [CAMPAIGN_TYPE.SALES]: 'Enter a name and associated communities. This can be edited later.',
  [CAMPAIGN_TYPE.FOLLOW_UP]: 'Enter some basic information for your follow-up campaign.',
  [CAMPAIGN_TYPE.ONE]: 'Enter some basic information for your one-time campaign.',
};

export const AddCampaignModal = () => {
  const { state, actions, dispatch } = useCampaigns();
  const { state: campaignDetailsState, createCampaign } = useCampaignDetails();
  const { addCampaignModal } = state;
  const { eligibleCommunities, eligibleCommunitiesLoading } = campaignDetailsState;

  const validationSchema = Yup.object({
    name: Yup.string().required('Campaign name is required.'),
    campaignType: Yup.string(),
    entryCriterionId: Yup.number().when('campaignType', (campaignType, schema) => {
      if (campaignType === CAMPAIGN_TYPE.FOLLOW_UP) {
        return schema.required('Entry criteria is required.');
      }
      return schema;
    }),
    relatedCommunities: Yup.array().required('Please select below'),
    triggerValue: Yup.string().when('campaignType', (campaignType, schema) => {
      if (campaignType === CAMPAIGN_TYPE.ONE_TIME) {
        return schema.required('Start date and time is required.');
      }
      return schema;
    }),
  });

  const initialFormValues = {
    name: '',
    campaignType: state?.addCampaignModal?.campaignType,
    entryCriterionId: '',
    relatedCommunities: [],
    triggerValue: state?.addCampaignModal?.campaignType === CAMPAIGN_TYPE.ONE_TIME ? getTomorrow() : '',
  };

  const onSubmit = async (values, { resetForm }) => {
    let entryCriterionId = values.entryCriterionId;
    if (values.campaignType === CAMPAIGN_TYPE.SALES) {
      const salesTriggerCriterion = campaignDetailsState.criteria.find(
        (c) => c.canBeTrigger && c.triggerCampaignType === CAMPAIGN_TYPE.SALES
      );
      entryCriterionId = salesTriggerCriterion.id;
    } else if (values.campaignType === CAMPAIGN_TYPE.ONE_TIME) {
      const oneTimeTriggerCriterion = campaignDetailsState.criteria.find(
        (c) => c.canBeTrigger && c.triggerCampaignType === CAMPAIGN_TYPE.ONE_TIME
      );
      entryCriterionId = oneTimeTriggerCriterion.id;
    }

    const requestData = {
      name: values.name,
      campaignType: values.campaignType,
      entryCriterionId,
      relatedCommunitiesIds: values.relatedCommunities,
      triggerValue: values.triggerValue || null,
    };
    await createCampaign(requestData, resetForm);
  };

  const handleOnClose = (resetForm) => () => {
    dispatch({ type: actions.SET_ADD_CAMPAIGN_MODAL_OPEN, data: { isOpen: false, campaignType: null } });
    resetForm();
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount={true}
    >
      {({ handleSubmit, isValid, resetForm, dirty }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <CampaignModal
              loading={campaignDetailsState?.campaignSaving}
              open={addCampaignModal?.isOpen}
              onModalClose={handleOnClose(resetForm)}
              size="tiny"
              width={970}
            >
              <ModalHeader type="header">
                <ModalHeaderIconWrapper>{HEADER_PARTS[state?.addCampaignModal?.campaignType]}</ModalHeaderIconWrapper>
                <span>Add {addCampaignModal?.label} Campaign</span>
              </ModalHeader>
              <BodyWrapper type="body">
                <div>
                  {descriptionText[state?.addCampaignModal?.campaignType]}
                  <FormContainer>
                    <Label>Name</Label>
                    <NameField width="381px" />
                    {state?.addCampaignModal?.campaignType === CAMPAIGN_TYPE.FOLLOW_UP && (
                      <>
                        <Label>Entry Criteria</Label>
                        <InputField
                          name="entryCriterionId"
                          control={Dropdown}
                          fluid
                          selection
                          options={campaignDetailsState.criteria
                            .filter(
                              (c) => c.canBeTrigger && c.triggerCampaignType === state?.addCampaignModal?.campaignType
                            )
                            .map((c) => ({
                              key: c.id,
                              text: c.displayName,
                              value: c.id,
                            }))}
                          placeholder="Select"
                        />
                      </>
                    )}
                    {state?.addCampaignModal?.campaignType === CAMPAIGN_TYPE.ONE_TIME && (
                      <>
                        <Label>Start date</Label>
                        <StartDateField name="triggerValue" />
                      </>
                    )}
                  </FormContainer>
                </div>
                <CommunitiesMenu communities={eligibleCommunities} loading={eligibleCommunitiesLoading} />
              </BodyWrapper>
              <CampaignModalActions
                onSave={handleSubmit}
                onCancel={handleOnClose(resetForm)}
                type="actions"
                saveText="Save"
                disabled={!dirty || !isValid}
              />
            </CampaignModal>
          </Form>
        );
      }}
    </Formik>
  );
};
