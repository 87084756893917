import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';

import { NavBar } from 'components/navigation';
import { Content } from '../common';
import { ToursContextProvider } from './ToursContext';
import { Tour } from './Tour';
import { TourLeadCancelled } from './TourLeadCancelled';

const FullWidth = styled.div`
  width: 100%;
`;

export const TourRoutes = () => (
  <FullWidth>
    <NavBar />
    <Content>
      <Switch>
        <Route exact path="/tours/cancelled">
          <TourLeadCancelled />
        </Route>
        <Route exact path="/tours/:tourHash">
          <ToursContextProvider>
            <Tour />
          </ToursContextProvider>
        </Route>
      </Switch>
    </Content>
  </FullWidth>
);
