import React from 'react';
import styled from 'styled-components';

import { Dropdown, Icon, Popup, Input } from 'semantic-ui-react';

import { InputField } from 'components/common/fields/InputField';
import { If } from '../../If';
import { OperationSign, OperationBox } from './wrappers';

const ClauseBox = styled.div`
  display: flex;
`;

const ClicableIcon = styled(Icon)`
  cursor: pointer;
`;

const CommandsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const UrlParameterRuleClause = ({
  rowId,
  rowsCount,
  baseFieldName,
  urlParameterOptions,
  handleRemove,
  handleAdd,
  disabled,
}) => {
  return (
    <ClauseBox>
      <div>
        <OperationBox>
          <OperationSign>if</OperationSign>
          <div>
            <InputField
              control={urlParameterOptions ? Dropdown : Input}
              name={`${baseFieldName}.keyword`}
              placeholder="Keyword Value"
              type="text"
              selection
              options={urlParameterOptions}
              disabled={disabled}
            />
          </div>
        </OperationBox>
        <OperationBox>
          <OperationSign>=</OperationSign>
          <div>
            <InputField placeholder="Key Value" name={`${baseFieldName}.key_value`} disabled={disabled} />
          </div>
        </OperationBox>
      </div>
      <CommandsBox>
        <Popup
          hoverable
          trigger={
            <div>
              <ClicableIcon color="red" name="times" onClick={handleRemove} disabled={disabled} />
            </div>
          }
          content="Remove condition"
          position="top center"
          style={{ zIndex: 10000 }}
        />
        <If condition={rowId === rowsCount - 1}>
          <Popup
            hoverable
            trigger={
              <div>
                <ClicableIcon name="plus" onClick={handleAdd} disabled={disabled} />
              </div>
            }
            position="top center"
            content="Add new condition"
            style={{ zIndex: 10000 }}
          />
        </If>
      </CommandsBox>
    </ClauseBox>
  );
};

export default UrlParameterRuleClause;
