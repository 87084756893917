import humps from 'humps';

import { API } from '../api';
import { PAGINATION } from '../constants';

export const getCommunitiesByUserId = async (params) => {
  const query = new URLSearchParams(params).toString();
  const url = `/api/chat/profiles?${query}`;
  const response = await API.get(url);
  return response.data;
};

export const getCommunitiesById = async (data) => {
  const response = await API.post('/api/chat/profiles/fetch-profiles', data);
  return response.data;
};

export const getCommunitiesForLoggedUser = async (params) => {
  const query = new URLSearchParams(params).toString();
  const url = `/api/communities/?${query}`;
  const response = await API.get(url);
  return response.data;
};

export const updateChatCommunity = async (communityId, data) => {
  const response = await API.patch(`/api/communities/${communityId}/settings`, data);
  return response.data;
};

export const updateCommunitiesContactInfo = async (data) => {
  const response = await API.put(`/api/chat/profiles/lead-emails`, data);
  return response.data;
};

export const createCommunitiesFromInviteLocations = async ({ locations }) => {
  const response = await API.post(`/api/chat/profiles/from-invite-locations`, locations);
  return response.data;
};

export const getUserCommunityAssets = async () => {
  const response = await API.get(`/api/communities/user/communities-assets`);
  return response.data;
};

export const updateCommunityAssets = async (communityId, data) => {
  const response = await API.post(`/api/communities/${communityId}/assets`, data);
  return response.data;
};

export const uploadCommunityAssets = async (communityId, data) => {
  const response = await API.post(`/api/communities/${communityId}/upload`, data);
  return response.data;
};

export const fetchCommunityScrapeResults = async (communityId, url = undefined) => {
  const query = url ? new URLSearchParams({ url }).toString() : '';
  const response = await API.get(`/api/communities/${communityId}/scrape?${query}`);
  return response.data;
};

export const getProfileCommunities = async ({
  page = PAGINATION.PAGE,
  page_size = PAGINATION.MAX_LARGE_PAGE_SIZE,
  search = '',
} = {}) => {
  const query = new URLSearchParams({ page, page_size, search }).toString();
  const url = `/api/chat/profiles/communities?${query}`;
  const response = await API.get(url);
  return response.data;
};

export const getUserCommunityCareTypes = async () => {
  const url = `/api/chat/profiles/care-types`;
  const response = await API.get(url);
  return response.data;
};

export const getMapImage = async (communityId) => {
  const url = `/api/communities/${communityId}/map-image`;
  const response = await API.get(url);
  return response.data;
};

export const getFloorPlanAssets = async () => {
  const url = '/api/floorplans/assets';
  const response = await API.get(url);
  return response.data;
};

export const createNewFloorPlan = async (communityId, data) => {
  const response = await API.post(`/api/communities/${communityId}/floorplans`, data);
  return response.data;
};

export const getFloorPlans = async (communityId, params) => {
  const query = new URLSearchParams(params).toString();
  const response = await API.get(`/api/communities/${communityId}/floorplans?${query}`);
  return response.data;
};

export const deleteFloorPlan = async (communityId, floorPlanId) => {
  const response = await API.delete(`/api/communities/${communityId}/floorplans/${floorPlanId}`);
  return response.data;
};

export const updateFloorPlan = async (communityId, floorPlanId, data) => {
  const response = await API.put(`/api/communities/${communityId}/floorplans/${floorPlanId}`, data);
  return response.data;
};

export const getCommunityTestimonials = async (communityId, params) => {
  const response = await API.get(`/api/communities/${communityId}/testimonials`, { params });
  return response.data;
};

export const createCommunityTestimonial = async (communityId, data) => {
  const response = await API.post(`/api/communities/${communityId}/testimonials`, data);
  return response.data;
};

export const updateCommunityTestimonial = async (communityId, testimonialId, data) => {
  const response = await API.put(`/api/communities/${communityId}/testimonials/${testimonialId}`, data);
  return response.data;
};

export const deleteCommunityTestimonial = async (communityId, testimonialId) => {
  await API.delete(`/api/communities/${communityId}/testimonials/${testimonialId}`);
};

export const getJobApplicantEmails = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/communities/job-applicant-emails', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const createJobApplicantEmails = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.post('/api/communities/job-applicant-emails', requestData);
  return humps.camelizeKeys(response.data);
};

export const getCommunityTraits = async (communityId, pageSize) => {
  const params = humps.decamelizeKeys({ pageSize });
  const response = await API.get(`/api/communities/${communityId}/traits`, params);
  return humps.camelizeKeys(response.data);
};

export const getCommunityContactPhoneNumbers = async (communityId) => {
  const response = await API.get(`/api/communities/${communityId}/contact-phone-numbers`);
  return humps.camelizeKeys(response.data);
};

export const createCommunitiesGroup = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.post('/api/communities/groups', requestData);
  return humps.camelizeKeys(response.data);
};

export const updateCommunitiesGroup = async (id, data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.patch(`/api/communities/groups/${id}`, requestData);
  return humps.camelizeKeys(response.data);
};

export const deleteCommunitiesGroup = async (id) => {
  const response = await API.delete(`/api/communities/groups/${id}`);
  return humps.camelizeKeys(response.data);
};

export const getCommunitiesGroups = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/communities/groups', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const getCareTypes = async (params) => {
  const response = await API.get(`/api/communities/care-types`, { params });
  return response.data;
};

export const createCareTypes = async (data) => {
  const response = await API.post(`/api/communities/care-types`, data);
  return response.data;
};

export const getCommunityCareTypes = async (communityId) => {
  const response = await API.get(`/api/communities/${communityId}/care-types`);
  return response.data;
};

export const assignCommunityCareType = async (communityId, data) => {
  const response = await API.post(`/api/communities/${communityId}/care-types`, data);
  return response.data;
};

export const updateCommunityCareType = async (communityId, careTypeId, data) => {
  const response = await API.patch(`/api/communities/${communityId}/care-types/${careTypeId}`, data);
  return response.data;
};

export const deleteCommunityCareType = async (communityId, careTypeId) => {
  const response = await API.delete(`/api/communities/${communityId}/care-types/${careTypeId}`);
  return response.data;
};

export const getOffboardingData = async (communityId) => {
  const response = await API.get(`/api/communities/${communityId}/off-boarding`);
  return response.data;
};

export const getOffboardingUsers = async (communityId, params) => {
  const response = await API.get(`/api/communities/${communityId}/off-boarding/users`, { params });
  return response.data;
};

export const reassignCommunity = async (communityId, params) => {
  const response = await API.put(`/api/communities/${communityId}/off-boarding`, params);
  return response.data;
};

export const getStaff = async (communityId, params) => {
  const response = await API.get(`/api/communities/${communityId}/staff`, { params });
  return response.data;
};

export const createStaff = async (communityId, data) => {
  const response = await API.post(`/api/communities/${communityId}/staff`, data);
  return response.data;
};

export const updateStaff = async (communityId, staffId, data) => {
  const response = await API.patch(`/api/communities/${communityId}/staff/${staffId}`, data);
  return response.data;
};

export const deleteStaff = async (communityId, staffId) => {
  const response = await API.delete(`/api/communities/${communityId}/staff/${staffId}`);
  return response.data;
};

export const getContactRules = async (query) => {
  const params = humps.decamelizeKeys(query);
  const response = await API.get(`/api/communities/contact-rules`, { params });
  return humps.camelizeKeys(response.data);
};

export const getContactRuleOptions = async () => {
  const response = await API.get(`/api/communities/contact-rules/options`);
  return humps.camelizeKeys(response.data);
};

export const createContactRule = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.post('/api/communities/contact-rules', requestData);
  return humps.camelizeKeys(response.data);
};

export const getContactRuleById = async (id) => {
  const response = await API.get(`/api/communities/contact-rules/${id}`);
  return humps.camelizeKeys(response.data);
};

export const updateContactRule = async (id, data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.patch(`/api/communities/contact-rules/${id}`, requestData);
  return humps.camelizeKeys(response.data);
};

export const deleteContactRule = async (id) => {
  const response = await API.delete(`/api/communities/contact-rules/${id}`);
  return humps.camelizeKeys(response.data);
};

export const settingsGetCommunities = async (params) => {
  const response = await API.get(`/api/communities/settings`, { params });
  return response.data;
};

export const settingsUpdateCommunities = async (data) => {
  const response = await API.post(`/api/communities/settings`, data);
  return response.data;
};

export const getFeaturesInfo = async (params) => {
  const response = await API.get(`/api/communities/features-overview/info`);
  return response.data;
};

export const getFeaturesOverviewUsers = async (params) => {
  const query = new URLSearchParams(params).toString();
  const response = await API.get(`/api/communities/features-overview?${query}`);
  return response.data;
};

export const getFeaturesOverviewUserCommunities = async (userId, params) => {
  const query = new URLSearchParams(params).toString();
  const response = await API.get(`/api/communities/features-overview/${userId}?${query}`);
  return response.data;
};

export const getCommunityFeaturesOverview = async (userId, communityId, params) => {
  const query = new URLSearchParams(humps.decamelizeKeys(params)).toString();
  const response = await API.get(`/api/communities/features-overview/${userId}/${communityId}?${query}`);
  return response.data;
};

export const getSurveyData = async (communityId) => {
  const response = await API.get(`/api/communities/${communityId}/survey-data`);
  return response.data;
};

export const setSurveyData = async (communityId, surveyData) => {
  const response = await API.put(`/api/communities/${communityId}/survey-data`, surveyData);
  return response.data;
};

export const getConnectedCalendarsSettings = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get(`/api/communities/settings/connected-calendars`, { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const getConnectedCalendarsUsers = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get(`/api/communities/settings/connected-calendars/users`, { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const updateConnectedCalendarsSettings = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.post('/api/communities/settings/connected-calendars', requestData);
  return humps.camelizeKeys(response.data);
};

export const getUnqualifiedLeadFilters = async () => {
  const response = await API.get('/api/communities/user/unqualified-lead-validators');
  return humps.camelizeKeys(response.data);
};

export const settingsGetLeadQualificationsCommunities = async (params) => {
  const response = await API.get(`/api/communities/settings/lead-qualifications`, { params });
  return humps.camelizeKeys(response.data);
};

export const settingsGetLeadQualificationsRules = async () => {
  const response = await API.get(`/api/leads/qualification-rules`);
  return humps.camelizeKeys(response.data);
};

export const settingsUpdateLeadQualificationsRules = async (data) => {
  const requestData = humps.decamelizeKeys(data);
  const response = await API.post(`/api/communities/settings/lead-qualifications`, requestData);
  return humps.camelizeKeys(response.data);
};
