import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Logo } from 'components/Logo';

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 0 12px 0;
`;

const StyledLogo = styled(Logo)`
  height: auto;
`;

export const NavigationLogo = () => {
  return (
    <LogoContainer>
      <NavLink to="/" className="logo">
        <StyledLogo width="120" />
      </NavLink>
    </LogoContainer>
  );
};
