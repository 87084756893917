import React, { useMemo } from 'react';

import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PercentCircle, ReportComponent, SimpleMetric } from 'components/common/analytics';
import { Grid } from 'components/lib';
import { formatCount } from 'components/utils';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const metrics = [
  'leads.total',
  'leads.reached',
  'leads.responded',
  'leads.replied',
  'leads.opted_out',
  'leads.communities',
  'leads.reached.communities',
  'leads.ma_active',
  'leads.ma_qualified',
  'leads.ma_disqualified',
];

const useLeadTotals = (filters) =>
  useQuery(
    [queryConstants.ADMIN_METRICS, { ...filters, metrics }],
    () => {
      return getMetrics(metrics, filters);
    },
    { placeholderData: {} }
  );

const LeadTotals = () => {
  const { saleAssistantFilters: filters, getPreviousDateRange } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };

  const { data, error, status, isFetching } = useLeadTotals(maFilters);

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch lead metrics'));
  }

  const maLeadsData = useMemo(
    () => ({
      Reached: data['leads.reached']?.value,
      'Not Reached': data['leads.total']?.value - data['leads.reached']?.value,
    }),
    [data]
  );

  const reachedData = useMemo(
    () => ({
      Responded: data['leads.responded']?.value,
      Silent: data['leads.reached']?.value - data['leads.responded']?.value,
    }),
    [data]
  );

  const maActiveData = useMemo(
    () => ({
      'MA Involved': data['leads.ma_active']?.value,
      'No Involvement Yet': data['leads.replied']?.value - data['leads.ma_active']?.value,
    }),
    [data]
  );

  return (
    <ReportComponent
      title="New Leads Summary"
      tooltip="Statistics for leads that are created within the selected time period and where Messaging Assistant is enabled."
      contentMargins="20px 0"
      contentHeight="170px"
    >
      <Grid columns={3} stackable>
        <Grid.Row>
          <Grid.Column>
            <PercentCircle
              data={maLeadsData}
              units="Leads with MA"
              loaderStatus={loaderStatus}
              radius={150}
              circleWidth={15}
              additionalMetrics={
                <>
                  <SimpleMetric
                    metric={formatCount(data['leads.reached.communities']?.value)}
                    unit="communities with reached leads"
                    fontSize="24px"
                    unitFontSize="16px"
                  />
                  <SimpleMetric
                    metric={formatCount(data['leads.communities']?.value - data['leads.reached.communities']?.value)}
                    unit="communities without reached leads"
                    fontSize="16px"
                    unitFontSize="16px"
                  />
                </>
              }
            />
          </Grid.Column>
          <Grid.Column>
            <PercentCircle
              data={reachedData}
              units="Reached Leads"
              loaderStatus={loaderStatus}
              radius={150}
              circleWidth={15}
              additionalMetrics={
                <>
                  <SimpleMetric
                    metric={formatCount(data['leads.replied']?.value)}
                    unit="active leads"
                    fontSize="24px"
                    unitFontSize="16px"
                  />
                  <SimpleMetric
                    metric={formatCount(data['leads.opted_out']?.value)}
                    unit="opted out"
                    fontSize="16px"
                    unitFontSize="16px"
                  />
                </>
              }
            />
          </Grid.Column>
          <Grid.Column>
            <PercentCircle
              data={maActiveData}
              units="Active Leads"
              loaderStatus={loaderStatus}
              radius={150}
              circleWidth={15}
              additionalMetrics={
                <>
                  <SimpleMetric
                    metric={formatCount(data['leads.ma_qualified']?.value)}
                    unit="qualified leads"
                    fontSize="24px"
                    unitFontSize="16px"
                  />
                  <SimpleMetric
                    metric={formatCount(data['leads.ma_disqualified']?.value)}
                    unit="disqualified leads"
                    fontSize="16px"
                    unitFontSize="16px"
                  />
                </>
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ReportComponent>
  );
};

export { useLeadTotals };
export default LeadTotals;
