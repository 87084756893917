import React from 'react';
import styled from 'styled-components';

import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import { MEDIA_BREAK_POINTS } from '../../constants';
import { FURHTER_ICON } from './Icon';

const StyledDropdown = styled(SemanticDropdown)`
  &&&& {
    .dropdown.icon {
      font-family: 'Material Icons';
      font-size: 12px;
      ::before {
        content: '\\e5cf'; // Down caret
        font-size: 18px;
      }
      &.clear {
        ::before {
          content: '\\e5cd'; // Close
        }
      }
    }
    && > .${FURHTER_ICON} {
      margin: 0;
    }
    .text {
      font-family: var(--font-family);
      font-size: 14px;
      line-height: 1;
      color: #222;
      letter-spacing: unset;
    }
    &.button {
      border-radius: 18px;
      box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
      border: solid 1px var(--line-standard);
      background-color: var(--white);
      padding: 6px 10px;
      &:hover {
        background-color: var(--white);
      }
    }
    &.basic {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0;
      padding: 8px 10px 7px;
      box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
      border: solid 1px var(--line-standard);
      background-color: var(--white);
      height: 36px;
      min-height: 36px;
      color: rgba(0, 0, 0, 0.6);
      @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
        min-width: 120px;
      }
      .item:first-child {
        border-top: 1px solid var(--line-standard);
      }
      .menu {
        border-radius: 0;
        border: solid 1px var(--line-standard);
      }
      .dropdown.icon {
        font-family: 'Material Icons';
        font-size: 18px;
        padding: 0;
        float: none;
        position: relative;
        right: auto;
        left: auto;
        top: auto;
        margin: 0;
        margin-left: auto;
      }
    }
    &:hover {
      border-color: #89919d;
    }
  }
`;

export const Dropdown = ({ ...props }) => <StyledDropdown {...props} />;

Dropdown.Header = SemanticDropdown.Header;
Dropdown.Menu = SemanticDropdown.Menu;
Dropdown.Item = SemanticDropdown.Item;
Dropdown.Divider = SemanticDropdown.Divider;
Dropdown.Text = SemanticDropdown.Text;
