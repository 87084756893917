import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dropdown, Label, Tab, Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import { If } from 'components/If';
import { markLeadMessageUnread, getLeadUserReminder, removeLeadUserReminder, leadFurtherArchive } from 'apis';

import LeadUserReminder from 'components/common/LeadUserReminder';
import { parseErrorResponse } from 'apis/utils';
import BackLink from 'components/common/BackLink';
import {
  LEAD_USER_REMINDER_SCHEDULE_TYPE,
  LEAD_USER_REMINDER_TYPE,
  MEDIA_BREAK_POINTS,
  STATUSES,
} from '../../../constants';
import { Labels } from './Labels';
import { LeadInformation } from './LeadInformation';
import { MessageContext } from './MessageContext';
import { Conversation } from './Conversation';
import { MessageInformation } from './MessageInformation';
import { CommunityInformation } from './CommunityInformation';
import { CommunityStats } from './CommunityStats';
import { CommunityNotes } from './CommunityNotes';
import { DEFAULT_LEAD_USER_REMINDER } from './constants';
import { CalendarRequests } from './calendar/CalendarRequests';

const Wrapper = styled.div`
  height: calc(100vh - 70px);
  margin: -1.25rem -2.5rem 0;
  display: flex;
  flex-direction: row;
  overflow: auto;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    height: calc(100vh - 70px);
    margin: 0;
  }

  .ma-column {
    display: flex;
    flex-direction: column;

    .column-header {
      padding: 0 1rem 1rem;
    }

    .column-content {
      flex: 1;
      overflow: auto;
    }
  }

  .left-column,
  .right-column {
    width: 300px;
    overflow: hidden;

    @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      width: 235px;
    }

    @media only screen and (max-width: 700px) {
      min-width: calc(100vw - 10px);
    }
  }

  .left-column {
    padding-top: 18px;
    padding-right: 10px;
  }

  .right-column {
    padding-left: 10px;
  }

  .main-column {
    flex: 1;
    padding: 0 1rem;
    overflow: auto;

    @media only screen and (max-width: 700px) {
      min-width: calc(100vw - 10px);
    }
  }

  .column-tabs {
    height: 100%;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;

    > div:last-child {
      flex: 1;
      overflow: auto;
    }
  }
`;

export const MessagesPreview = () => {
  const { leadId, messageId, type: messageType } = useParams();
  const {
    lead,
    leadReminder,
    getLead,
    setLeadReminder,
    reminderStatus,
    setReminderStatus,
    createOrUpdateReminder,
    leadStatus,
    isFurtherArchived,
    setIsFurtherArchived,
    loadAnswerLabels,
    labels,
    clearLabels,
  } = useContext(MessageContext);

  const [dropdownStatus, setDropdownStatus] = useState(STATUSES.IDLE);
  const [openReminder, setOpenReminder] = useState(false);

  const handleOpen = () => {
    setOpenReminder(true);
  };

  const handleClose = () => {
    setOpenReminder(false);
  };

  useEffect(() => {
    const loadLeadReminder = async () => {
      try {
        setReminderStatus(STATUSES.LOADING);
        const response = await getLeadUserReminder(leadId, LEAD_USER_REMINDER_TYPE.INSTANT_RESPONSES);

        setLeadReminder({ ...DEFAULT_LEAD_USER_REMINDER, ...response });

        setReminderStatus(STATUSES.LOADED);
      } catch (e) {
        setReminderStatus(STATUSES.LOADING);
        toast('Failure to load lead reminder details!');
        console.error(e);
      }
    };

    getLead(leadId);
    loadLeadReminder();
    loadAnswerLabels(leadId);
  }, [leadId, setReminderStatus, setLeadReminder]);

  const onMarkAsUnread = async () => {
    try {
      setDropdownStatus(STATUSES.LOADING);
      await markLeadMessageUnread(leadId);
      setDropdownStatus(STATUSES.LOADED);
      toast.success('Messages marked as unread');
    } catch (e) {
      setDropdownStatus(STATUSES.FAILURE);
      toast.warning('Failed to mark messages as unread!');
      console.error(e);
    }
  };

  const onToggleArchive = async () => {
    try {
      setDropdownStatus(STATUSES.LOADING);

      const response = await leadFurtherArchive(leadId, {
        is_further_archived: !isFurtherArchived,
      });

      setIsFurtherArchived(response.is_further_archived);
      setDropdownStatus(STATUSES.LOADED);
      toast.success(`Message ${response.is_further_archived ? 'archived' : 'restored'} successfully`);
    } catch (e) {
      setDropdownStatus(STATUSES.FAILURE);
      toast.error(`Trouble with archiving or restoring messages`);
      console.error(e);
    }
  };

  const handleSelectReminder = async ({ scheduleType, reminderDate = null }) => {
    if (scheduleType !== LEAD_USER_REMINDER_SCHEDULE_TYPE.NO_REMINDER) {
      createOrUpdateReminder(scheduleType, reminderDate);
      setOpenReminder(false);
    }
  };

  const handleRemoveReminder = async () => {
    try {
      setReminderStatus(STATUSES.LOADING);
      const { id } = leadReminder;
      await removeLeadUserReminder(leadId, LEAD_USER_REMINDER_TYPE.INSTANT_RESPONSES, id);

      setReminderStatus(STATUSES.LOADED);
      setLeadReminder(DEFAULT_LEAD_USER_REMINDER);
      setOpenReminder(false);
      toast.success('Lead reminder removed successfully');
    } catch (e) {
      setReminderStatus(STATUSES.FAILURE);
      toast.error(parseErrorResponse(e));
    }
  };

  const manageDropdownLoading = dropdownStatus === STATUSES.LOADING || leadStatus === STATUSES.LOADING;
  const toggleArchivedLabel = isFurtherArchived ? 'Restore' : 'Archive';
  const showLeadUserReminder = leadReminder?.schedule_type !== LEAD_USER_REMINDER_SCHEDULE_TYPE.NO_REMINDER;

  const labelsItem = (
    <Menu.Item key="labels">
      Labels
      {labels?.length ? (
        <Label onRemove={clearLabels} content={labels.length} size="small" circular color="teal" />
      ) : null}
    </Menu.Item>
  );

  const panes = [
    {
      menuItem: labelsItem,
      render: () => (
        <Tab.Pane>
          <Labels />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Notes',
      render: () => (
        <Tab.Pane>
          <CommunityNotes />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Wrapper>
      <div className="ma-column left-column">
        <div className="column-header">
          <BackLink to="/admin/instant-responses" label="Back to Messages" />
        </div>
        <div className="column-content">
          <If condition={leadStatus === STATUSES.LOADED}>
            <LeadInformation />
            <CommunityInformation />
            <CommunityStats />
            <CalendarRequests />
          </If>
          <If condition={messageId}>
            <MessageInformation />
          </If>
        </div>
      </div>
      <div className="ma-column main-column">
        <Conversation leadId={leadId} messageType={messageType} />
      </div>
      <div className="ma-column right-column">
        <div className="column-header">
          <If condition={showLeadUserReminder}>
            <LeadUserReminder
              open={openReminder}
              leadUserReminder={leadReminder}
              loaderStatus={reminderStatus}
              onSelect={handleSelectReminder}
              onRemove={handleRemoveReminder}
              onOpen={handleOpen}
              onClose={handleClose}
            />
          </If>
          <Dropdown button text="Manage Conversation" loading={manageDropdownLoading}>
            <Dropdown.Menu>
              <Dropdown.Item text="Mark as Unread" onClick={onMarkAsUnread} />
              <Dropdown.Item text={toggleArchivedLabel} onClick={onToggleArchive} />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="column-content">
          <Tab className="column-tabs" panes={panes} />
        </div>
      </div>
    </Wrapper>
  );
};
