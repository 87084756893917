import React, { createContext, useContext, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  getEmailSubjectDefaultOverrides,
  getEmailSubjectOverrides,
  getEmailSubjectTypes,
  getEmailSubjectPlaceholders,
} from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES } from '../../../constants';

export const EmailSubjectsContext = createContext();

const EmailSubjectsContextProvider = (props) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [emailTypes, setEmailTypes] = useState({});
  const [placeholders, setPlaceholders] = useState([]);
  const [emailSubjectOverrides, setEmailSubjectOverrides] = useState([]);
  const [defaultEmailSubjects, setDefaultEmailSubjects] = useState([]);

  useEffect(() => {
    const fetchEmailAssets = async () => {
      try {
        const [emailTypes, templatePlaceholders, defaultSubjects] = await Promise.all([
          getEmailSubjectTypes(),
          getEmailSubjectPlaceholders(),
          getEmailSubjectDefaultOverrides(),
        ]);
        if (emailTypes) {
          const emailTypeOptions = emailTypes.reduce(
            (acc, emailType) => ({
              ...acc,
              [emailType.key]: emailType.value,
            }),
            {}
          );
          setEmailTypes(emailTypeOptions);
        }
        setPlaceholders(templatePlaceholders);
        setDefaultEmailSubjects(defaultSubjects);
      } catch (error) {
        toast.error(parseErrorResponse(error));
      }
    };

    fetchEmailAssets();
  }, []);

  const fetchEmailSubjectOverrides = useCallback(async (search) => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      const results = await getEmailSubjectOverrides(search);

      setEmailSubjectOverrides(results);
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      toast.error(parseErrorResponse(e));
      setLoaderStatus(STATUSES.FAILURE);
    }
  }, []);

  const provide = {
    loaderStatus,
    emailTypes,
    placeholders,
    emailSubjectOverrides,
    defaultEmailSubjects,
    fetchEmailSubjectOverrides,
    setEmailSubjectOverrides,
  };

  return <EmailSubjectsContext.Provider value={provide}>{props.children}</EmailSubjectsContext.Provider>;
};

export function useEmailSubjects() {
  const context = useContext(EmailSubjectsContext);

  if (!context) {
    throw new Error(`useEmailSubjects must be used within EmailSubjectsContextProvider`);
  }

  return context;
}

export default EmailSubjectsContextProvider;
