import React, { useState } from 'react';
import styled from 'styled-components';

import { Node } from './Node';
import { AnswersTable } from './AnswersTable';

const BranchWrapper = styled.div`
  position: relative;

  .child-node {
    margin-top: -1px;
    margin-bottom: -1px;

    border: 1px solid rgba(34, 36, 38, 0.1);

    &.indent {
      margin-left: 44px;
    }
  }
`;

export const Branch = ({
  answers,
  totalCount,
  parentCount,
  branchCount,
  indent,
  showTableHeader,
  childNode,
  ...childNodeProps
}) => {
  const [collapsed, setCollapsed] = useState(indent);

  const showTable = answers.length >= 1 && ['option', 'multi_option'].includes(answers[0].source_type);

  return (
    <BranchWrapper collapsed={collapsed}>
      {showTable && (
        <div
          style={{ position: 'relative' }}
          onClick={(e) => {
            e.stopPropagation();
            if (indent) {
              setCollapsed(!collapsed);
            }
          }}
        >
          <AnswersTable
            answers={answers}
            totalCount={totalCount}
            branchCount={branchCount}
            parentCount={parentCount}
            showHeader={showTableHeader}
            collapsed={collapsed}
            expandable={indent}
          />
        </div>
      )}

      {childNode && !collapsed && (
        <div className={`child-node ${indent ? 'indent' : ''}`}>
          <Node node={childNode} parentCount={branchCount} {...childNodeProps} />
        </div>
      )}
    </BranchWrapper>
  );
};
