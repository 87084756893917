import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { Form, Radio } from 'components/lib';

import { If } from 'components/If';
import { STATUSES } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from './index';

const ModalBody = styled.div``;

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 10px;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:first-child) {
    margin-left: 30px;
  }
  > span {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 6px;
  }
`;
const Description = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  color: #89919d;
`;

export const SettingsBooleanModal = ({
  isModalOpen,
  selectedItem,
  flagName,
  totalItemsCount,
  onSave,
  onClose,
  modalTitle,
  modalText,
  modalDescription,
  modalSaveText = 'Save',
}) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    const value = values[flagName];

    await onSave({ [flagName]: value });

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const initialValues = { [flagName]: selectedItem?.[flagName] };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue }) => (
        <SettingsModal
          loading={loading}
          open={isModalOpen}
          onModalClose={() => handleClose(resetForm)}
          size="tiny"
          width={540}
        >
          <SettingsModalHeader
            title={modalTitle}
            community={selectedItem || null}
            totalItemsCount={totalItemsCount}
            type="header"
          />
          <ModalBody type="body">
            <BodyText>{modalText}</BodyText>
            <If condition={modalDescription}>
              <Description>{modalDescription}</Description>
            </If>
            <RadioWrapper>
              <CustomRadio>
                <Form.Field
                  control={Radio}
                  name={flagName}
                  value={true}
                  checked={Boolean(values[flagName])}
                  onChange={() => {
                    setFieldValue(flagName, true);
                  }}
                />
                <span>On</span>
              </CustomRadio>
              <CustomRadio>
                <Form.Field
                  control={Radio}
                  name={flagName}
                  value={false}
                  checked={String(values[flagName]) === String(false)}
                  onChange={() => {
                    setFieldValue(flagName, false);
                  }}
                />
                <span>Off</span>
              </CustomRadio>
            </RadioWrapper>
          </ModalBody>
          <SettingsModalActions
            onSave={handleSubmit}
            onCancel={() => handleClose(resetForm)}
            type="actions"
            disabled={!dirty || !touched}
            saveText={modalSaveText}
          />
        </SettingsModal>
      )}
    </Formik>
  );
};
