import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { ReactComponent as CodeChecking } from 'assets/images/code-checking.svg';

import { Button, Loader } from 'components/lib';
import { If } from 'components/If';
import classNames from 'classnames';
import { STATUSES } from 'constants';
import { NextStep } from './NextStep';
import { OnboardingContext } from '../OnboardingContext';
import { Container } from './common';
import { getScriptCode } from '../constants';

const Header = styled.h2`
  && {
    margin: 0;
  }
`;

const WebsiteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: 14px;
  text-align: center;
  color: #222;
  margin-bottom: 45px;
`;

const InstructionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  gap: 30px;
  width: 100%;
  p {
    text-align: left;
    font-size: 14px;
    white-space: pre-line;
  }
  && > button {
    width: fit-content;
    margin: 0;
  }
`;

const NoCode = styled.span`
  display: block;
  && {
    color: #fa7550;
    font-family: var(--font-family-bold);
  }
`;

const CodeBox = styled.div`
  width: 100%;
  padding: 20px;
  border: solid 1px #d7d7d7;
  background-color: #f8f8f8;
  margin-bottom: 30px;
  pre {
    font-family: var(--font-family);
    font-size: 14px;
    color: #89919d;
    margin: 0;
    white-space: break-spaces;
  }
`;

const AnimatedSection = styled(CodeChecking)`
  @keyframes scale-in-center {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  p {
    margin: 5px 0 0 10px;
    font-family: var(--font-family);
    font-size: 16px;
    color: #222;
  }
`;

export const InstallCode = () => {
  const { onboarding, handleNextStep, checkSiteManagerInstalled } = useContext(OnboardingContext);
  const [loading, setLoading] = useState(STATUSES.IDLE);
  const [showCodeNotInstalled, setShowCodeNotInstalled] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(getScriptCode(onboarding.onboardingUuid, onboarding.collectedData?.createdOn));
    toast.success('Copied to clipboard');
  };

  const checkInstallationScriptStatus = async () => {
    setLoading(STATUSES.LOADING);
    // wait for 5 seconds and then continue
    setTimeout(async () => {
      try {
        const { isInstalled } = await checkSiteManagerInstalled({ onboardingUuid: onboarding.onboardingUuid });

        setShowCodeNotInstalled(!isInstalled);
        setLoading(STATUSES.LOADED);
        if (isInstalled) {
          toast.success('Code installed successfully');
          handleNextStep();
        }
      } catch (error) {
        console.log('error: ', error);
        setShowCodeNotInstalled(true);
        setLoading(STATUSES.FAILURE);
      }
    }, 5000);
  };

  const isLoading = loading === STATUSES.LOADING;
  const className = classNames({ centered: isLoading });

  const headerText = isLoading ? 'Checking Code' : 'Install Code';

  if (!onboarding) return null;

  return (
    <Container className={className} maxWidth="35.7143rem">
      <Header>{headerText}</Header>
      <WebsiteContainer>{onboarding.collectedData?.website}</WebsiteContainer>
      <If condition={isLoading}>
        <AnimatedSection />
        <LoaderContainer>
          <Loader size="big" active />
          <p>Checking code on your website, please wait…</p>
        </LoaderContainer>
      </If>
      <If condition={!isLoading}>
        <InstructionsContainer>
          <p>
            <If condition={showCodeNotInstalled}>
              <NoCode>Could not detect code.</NoCode>
            </If>
            <b>Paste this code</b> before the &lt;/head&gt; tag on <br /> your website, then press Next Step.
          </p>
          <Button basic onClick={handleCopy}>
            Copy to Clipboard
          </Button>
        </InstructionsContainer>
        <CodeBox>
          <pre>{getScriptCode(onboarding.onboardingUuid, onboarding.createdOn)}</pre>
        </CodeBox>
        <Button size="big" fluid primary color="blue" onClick={checkInstallationScriptStatus}>
          Next Step
        </Button>
        <NextStep onNextStep={checkInstallationScriptStatus} />
      </If>
    </Container>
  );
};
