import React from 'react';

import { PageSection } from 'components/common';
import { Grid } from 'components/lib';

import TopNGraph from '../common/TopNGraph';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';
import LeadMoveins from './LeadMoveins';
import LeadTotals from './LeadTotals';
import LeadTotalsPrevPeriod from './LeadTotalsPrevPeriod';
import LeadTrendsGraph from './LeadTrendsGraph';
import MessagesTotals from './MessagesTotals';
import MessagesTotalsPrevPeriod from './MessagesTotalsPrevPeriod';
import MessagesTrendsGraph from './MessagesTrendsGraph';
import MessagesResponseTimes from './MessagesResponseTimes';
import MessagesResponseTimesPrevPeriod from './MessagesResponseTimesPrevPeriod';
import MessagesResponseTimesTrendsGraph from './MessagesResponseTimesTrendsGraph';
import LeadMoveinsOther from './LeadMoveinsOther';
import LeadMoveinsPrevPeriod from './LeadMoveinsPrevPeriod';

const MessagingAssistantReport = () => {
  const { saleAssistantFilters: filters } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };
  return (
    <PageSection>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <LeadTotals />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <LeadTotalsPrevPeriod />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <LeadTrendsGraph />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10}>
            <LeadMoveins />
          </Grid.Column>
          <Grid.Column width={6}>
            <LeadMoveinsOther />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <LeadMoveinsPrevPeriod />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <TopNGraph
              metric="leads.total"
              groupBy="sales_assistant_status"
              filters={maFilters}
              backgroundColor="#17bd2abb"
              title="Leads by MA Statuses"
              tooltip="Statuses that were set by MA team."
              horizontal
            />
          </Grid.Column>
          <Grid.Column>
            <TopNGraph
              metric="leads.total"
              groupBy="sales_assistant_messages"
              filters={maFilters}
              backgroundColor="#17bd2abb"
              title="Leads by MA Messages Sent"
              tooltip="Number of leads grouped by number of messages sent by MA team."
            />
          </Grid.Column>
          <Grid.Column>
            <TopNGraph
              metric="leads.move_ins"
              groupBy="sales_assistant_messages"
              filters={maFilters}
              backgroundColor="#17bd2abb"
              title="Move-ins by MA Messages Sent"
              tooltip="Number of move-ins grouped by number of messages sent by MA team."
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column width={6}>
            <MessagesTotals />
          </Grid.Column>
          <Grid.Column width={10}>
            <MessagesTrendsGraph />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <MessagesTotalsPrevPeriod />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <TopNGraph
              metric="messages.sales_assistant"
              groupBy="sender_id"
              filters={maFilters}
              backgroundColor="#17bd2abb"
              title="MA Messages by Sender"
              tooltip="Number of messages sent by MA team members."
              toLabel={({ user_email }) => user_email}
              horizontal
            />
          </Grid.Column>
          <Grid.Column>
            <TopNGraph
              metric="messages.lead"
              groupBy="local_hour"
              filters={maFilters}
              backgroundColor="#17bd2abb"
              title="Lead Messages by Local Hour"
              tooltip="Number of messages received from leads by local hour."
            />
          </Grid.Column>
          <Grid.Column>
            <TopNGraph
              metric="messages.lead"
              groupBy="utc_hour"
              filters={maFilters}
              backgroundColor="#17bd2abb"
              title="Lead Messages by Further Hour"
              tooltip="Number of messages received from leads by Further hour (UTC-4)."
              toLabel={({ utc_hour }) => utc_hour - 4}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <MessagesResponseTimes />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <MessagesResponseTimesPrevPeriod />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column width={6}>
            <TopNGraph
              metric="messages.sales_assistant.avg_response_time"
              groupBy="sender_id"
              filters={maFilters}
              backgroundColor="#17bd2abb"
              title="Avg. Response Time by Sender"
              tooltip="Avg. response time of messages sent by MA team members."
              toLabel={({ user_email }) => user_email}
              toValue={({ value }) => Number.parseInt(value)}
              horizontal
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <MessagesResponseTimesTrendsGraph />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PageSection>
  );
};

export default MessagingAssistantReport;
