import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Button, Dropdown, Icon } from 'semantic-ui-react';

import { InputField } from 'components/common/fields/InputField';
import CRMLocationCodeOverrideInput from './CRMLocationCodeOverrideInput';
import { MEDIA_BREAK_POINTS } from '../../../constants';

const RuleInputsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 0.5rem;
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
  }
`;

const CRMLocationCodeOverrideRow = ({
  baseFieldName,
  rowId,
  rowsCount,
  ruleId,
  crmLocationCodeRules,
  crmLocationCodeRuleOptions,
  onCRMLocationRuleChange,
  onSwap,
  onRemove,
}) => {
  const [crmLocationCodeRule, setCRMLocationCodeRule] = useState();
  const [ruleOperationsOptions, setRuleOperationOptions] = useState([]);

  useEffect(() => {
    if (crmLocationCodeRules) {
      const rule = crmLocationCodeRules.find((c) => c.id === ruleId);

      if (rule) {
        setCRMLocationCodeRule(rule);
        setRuleOperationOptions(rule.operations.map((o) => ({ key: o, value: o, text: o })));
      }
    }
  }, [crmLocationCodeRules, ruleId]);

  const handleMoveUp = () => {
    onSwap(rowId, rowId - 1);
  };

  const handleMoveDown = () => {
    onSwap(rowId, rowId + 1);
  };

  const handleRemove = () => {
    onRemove(rowId);
  };

  return (
    <Table.Row verticalAlign="top">
      <Table.Cell textAlign="left">
        <Button.Group basic size="mini">
          <Button disabled={rowId === rowsCount - 1} icon="chevron down" onClick={handleMoveDown} />
          <Button disabled={rowId === 0} icon="chevron up" onClick={handleMoveUp} />
        </Button.Group>
      </Table.Cell>

      <Table.Cell>
        <RuleInputsBox>
          <div>
            <InputField
              control={Dropdown}
              name={`${baseFieldName}.rule`}
              placeholder="Rule"
              selection
              options={crmLocationCodeRuleOptions}
              onChanged={onCRMLocationRuleChange}
            />
          </div>
          <div>
            <InputField
              control={Dropdown}
              name={`${baseFieldName}.operation`}
              placeholder="Operation"
              selection
              options={ruleOperationsOptions}
            />
          </div>
          <div>
            <CRMLocationCodeOverrideInput name={`${baseFieldName}.value`} crmLocationCodeRule={crmLocationCodeRule} />
          </div>
        </RuleInputsBox>
      </Table.Cell>
      <Table.Cell>
        <InputField placeholder="CRM Location Code" name={`${baseFieldName}.crmLocationCode`} />
      </Table.Cell>
      <Table.Cell>
        <Button icon negative size="tiny" onClick={handleRemove}>
          <Icon name="trash" />
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default CRMLocationCodeOverrideRow;
