import React, { useEffect, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { subDays } from 'date-fns';
import humps from 'humps';

import { Dimmer, Loader, Dropdown, Button, Message, Icon } from 'components/lib';
import useDataList from 'utils/hooks/useDataList';
import { formatAnyDate } from 'components/utils';
import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import { getCRMLogsLeadsStatistics, getCRMLogsToursStatistics, getCRMTypesSearch, getAdminUsersSearch } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import FailureMessage from 'components/common/FailureMessage';
import { DateRangePicker as BaseDateRangePicker, MultiSelectFilter } from 'components/common';
import { MEDIA_BREAK_POINTS, STATUSES } from '../../constants';
import { ENTITY_TYPE, ENTITY_TYPE_LABEL } from './constants';
import ChartComponent from './CRMAdminLogsPercentCircle';

const DateRangePicker = styled(BaseDateRangePicker)`
  .ui.selection.dropdown {
    width: 240px;
  }
`;

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const CustomMultiSelectFilter = styled(MultiSelectFilter)`
  .ui.dropdown {
    min-width: unset;
    width: 240px;
    @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      width: 100%;
    }
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 30px;
  background-color: #f9f9f9;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    gap: 10px;
  }
`;

const GrayedText = styled.span`
  color: #6d6d6d;
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
  }
`;

const RefreshIcon = styled(Icon)`
  cursor: pointer;
  font-size: 30px !important;
  color: #727272;

  &:hover {
    color: #0056b3;
  }
`;

const SecondRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    gap: 1rem;
  }
`;

const DropdownBox = styled.div`
  width: 240px;
  .ui.dropdown {
    .text {
      padding-left: 6px;
    }
  }

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    color: var(--link);
    display: ${(props) => (props.show ? 'block' : 'none')};
  }
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 10px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`;

const CRMAdminLogsStatistics = () => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [userOptions, setUserOptions] = useState([]);
  const startDateCalculated = formatAnyDate(subDays(new Date(), 30), 'yyyy-MM-dd');
  const endDateCalculated = formatAnyDate(new Date(), 'yyyy-MM-dd');
  const { filters, setFilters } = useDataList({
    initialState: {
      filters: {
        startDate: startDateCalculated,
        endDate: endDateCalculated,
        entityType: ENTITY_TYPE.LEADS,
      },
    },
  });
  const [crmStatistics, setCrmStatistics] = useState([]);
  const [crmTypes, setCrmTypes] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const [subtitle, setSubtitle] = useState(ENTITY_TYPE_LABEL[ENTITY_TYPE.LEADS]);

  const fetchCrmData = useCallback(
    async (entityType) => {
      try {
        setLoaderStatus(STATUSES.LOADING);

        const statistics =
          entityType === ENTITY_TYPE.TOURS
            ? await getCRMLogsToursStatistics({ ...filters })
            : await getCRMLogsLeadsStatistics({ ...filters });

        const types = await getCRMTypesSearch();
        setCrmStatistics(statistics);
        setCrmTypes(types);
        setLoaderStatus(STATUSES.LOADED);
      } catch (error) {
        toast.error(parseErrorResponse(error, 'Unable to fetch CRM data'));
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [filters]
  );

  useEffect(() => {
    fetchCrmData(filters.entityType);
    console.log(('Entity Type:', filters.entityType));

    console.log(('Entity Type:', filters.entityType));
    setSubtitle(ENTITY_TYPE_LABEL[filters.entityType]);
  }, [fetchCrmData, filters.entityType]);

  const fetchAdminUsers = useCallback(async (searchQuery = '') => {
    try {
      const { results } = await getAdminUsersSearch({ search: searchQuery });
      setUserOptions([
        { key: 'all', value: null, text: 'Any user' },
        ...results.map((user) => ({
          key: user.id,
          value: user.id,
          text: user.email,
        })),
      ]);
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to fetch users'));
    }
  }, []);

  useEffect(() => {
    fetchAdminUsers();
  }, [fetchAdminUsers]);

  const handleDateRangeChange = ({ startDate, endDate }) => {
    setFilters({
      ...filters,
      startDate,
      endDate,
    });
  };

  const handleClearFilters = () => {
    setFilters({
      ...filters,
      startDate: startDateCalculated,
      endDate: endDateCalculated,
    });
  };

  const handleFilterChange =
    (key) =>
    (_, { value }) => {
      const newValue = key === 'crmType' ? value?.join(',') : value;
      if (key === 'user') {
        const selectedUser = userOptions.find((user) => user.value === value);
        setUserEmail(selectedUser ? selectedUser.text : '');
      }
      setFilters({ ...filters, [key]: newValue });
    };

  const getTitleById = (id) => {
    const ids = id?.split(',').map(Number);

    if (ids?.length === 1 && ids[0] === 0) {
      return crmTypes?.map((type) => type.name).join(', ');
    }

    const names = ids?.map((id) => {
      const type = crmTypes?.find((type) => type.id === id);
      return type ? type.name : 'Unknown';
    });
    return names?.join(', ');
  };

  const filteredStatistics = crmStatistics.filter((statistic) => {
    const crmNames = getTitleById(filters.crmType);
    const isMatch =
      filters.crmType === 'all' || filters.crmType === undefined || crmNames.includes(statistic.crmTypeName);

    return isMatch;
  });

  return (
    <PageContainer>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {loaderStatus !== STATUSES.FAILURE && (
        <>
          <Container>
            <FirstRow>
              <DateRangePicker
                dateRange={{ start_date: filters.startDate, end_date: filters.endDate }}
                onFilterChange={handleDateRangeChange}
                resetFilterChange={handleDateRangeChange}
              />
              <RefreshIcon name="refresh" onClick={() => fetchCrmData(filters.entityType)} />
            </FirstRow>
            <SecondRow>
              <DropdownBox>
                <Dropdown
                  basic
                  fluid
                  selection
                  options={[
                    { key: ENTITY_TYPE.LEADS, text: ENTITY_TYPE_LABEL[ENTITY_TYPE.LEADS], value: ENTITY_TYPE.LEADS },
                    { key: ENTITY_TYPE.TOURS, text: ENTITY_TYPE_LABEL[ENTITY_TYPE.TOURS], value: ENTITY_TYPE.TOURS },
                  ]}
                  value={filters.entityType}
                  onChange={handleFilterChange('entityType')}
                  placeholder="Select Entity Type"
                  defaultValue={ENTITY_TYPE.LEADS}
                />
              </DropdownBox>
              <DropdownBox>
                <Dropdown
                  basic
                  fluid
                  search
                  selection
                  options={userOptions}
                  value={filters.user}
                  onSearchChange={(e, { searchQuery }) => fetchAdminUsers(searchQuery)}
                  onChange={(e, { value }) => {
                    handleFilterChange('user')(e, { value });
                    if (value === null) {
                      fetchAdminUsers('');
                    }
                  }}
                  placeholder="Select user"
                />
              </DropdownBox>
              <CustomMultiSelectFilter
                name="Types"
                placeholder="Select CRM type"
                options={crmTypes}
                selectedValues={filters.crmType}
                onChange={(value) => handleFilterChange('crmType')(null, { value })}
                closeOnApply={true}
              />
              <StyledButton className="link" onClick={handleClearFilters}>
                Clear Filters
              </StyledButton>
            </SecondRow>
            {filteredStatistics.length > 0 ? (
              filteredStatistics.map((statistic, index) => (
                <CardRow key={index}>
                  <CardContainer>
                    <div>
                      <ChartComponent
                        title={
                          <span>
                            {capitalize(humps.camelize(statistic.crmTypeName || 'Other'))}
                            <GrayedText> · Parsable</GrayedText>
                          </span>
                        }
                        subtitle={subtitle}
                        subsubtitle={userEmail !== 'Any user' ? userEmail : ''}
                        data={[statistic.parsableCount, statistic.nonParsableCount]}
                        labels={['True', 'False']}
                        onReload={() => console.log('Reload clicked')}
                      />
                    </div>

                    <div>
                      <ChartComponent
                        title={
                          <span>
                            {capitalize(humps.camelize(statistic.crmTypeName || 'Other'))}
                            <span style={{ color: '#6d6d6d' }}> · Valid</span>
                          </span>
                        }
                        subtitle={subtitle}
                        subsubtitle={userEmail !== 'Any user' ? userEmail : ''}
                        data={[statistic.validCount, statistic.nonValidCount, statistic.undefinedValidCount]}
                        labels={['True', 'False', 'Undefined']}
                        onReload={() => console.log('Reload clicked')}
                      />
                    </div>
                  </CardContainer>
                </CardRow>
              ))
            ) : (
              <Message warning icon="users" content="No CRM logs matching selected filters. Please change filters." />
            )}
          </Container>
        </>
      )}
      <If condition={loaderStatus === STATUSES.FAILURE}>
        <FailureMessage icon="search" content="Statistics could not be loaded" />
      </If>
    </PageContainer>
  );
};

export default CRMAdminLogsStatistics;
