import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Divider } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { getBenchmarks, getMoveInKeywords } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PageSection } from 'components/common';
import { capitalize, queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';

import { UserContext } from '../../UserContext';
import BenchmarkOverview from './BenchmarkOverview';
import BenchmarkTraffic from './BenchmarkTraffic';
import useBenchmarks from './useBenchmarks';
import useMoveInKeywords from './useMoveInKeywords';

const Benchmarks = ({ filters, publicId }) => {
  const { t } = useTranslation();
  const {
    state: { overview, organicTraffic, advertising },
    setBenchmarks,
  } = useBenchmarks();
  const { user } = useContext(UserContext);
  const {
    state: { performing, no_move_ins },
    setMoveInKeywords,
  } = useMoveInKeywords();

  const benchmarkQuery = useQuery([queryConstants.BENCHMARKS, { publicId, filters }], () =>
    getBenchmarks({ public_id: publicId, filters })
  );
  const banchmarkLoaderStatus = queryStatusToLoaderStatus(benchmarkQuery.status, benchmarkQuery.isFetching);

  if (benchmarkQuery.error) {
    console.error(benchmarkQuery.error);
    toast.error(parseErrorResponse(benchmarkQuery.error, 'Unable to fetch benchmarks data'));
  }

  useEffect(() => {
    if (benchmarkQuery.data) {
      setBenchmarks(benchmarkQuery.data);
    }
  }, [benchmarkQuery.data, setBenchmarks]);

  const keywordsQuery = useQuery([queryConstants.MOVE_IN_KEYWORDS, { publicId, filters }], () =>
    getMoveInKeywords({ public_id: publicId, filters })
  );

  const keywordsLoaderStatus = queryStatusToLoaderStatus(keywordsQuery.status, keywordsQuery.isFetching);

  if (keywordsQuery.error) {
    console.error(keywordsQuery.error);
    toast.error(parseErrorResponse(keywordsQuery.error, `Unable to fetch ${t('move_in')} keywords`));
  }

  useEffect(() => {
    if (keywordsQuery.data) {
      setMoveInKeywords(keywordsQuery.data);
    }
  }, [keywordsQuery.data, setMoveInKeywords]);

  // test tracking with Segment
  useEffect(() => {
    const segmentTrackBenchmarks = () =>
      window?.analytics?.track('Viewed Benchmarks', {
        ...user,
      });
    segmentTrackBenchmarks();
  }, [user]);

  return (
    <PageSection loaderStatus={banchmarkLoaderStatus}>
      <BenchmarkOverview data={overview} />
      <Divider horizontal />
      <BenchmarkTraffic
        data={organicTraffic}
        title="Organic traffic"
        subtitle={`Average values for selected ${t('communities')} of organic traffic sources compared to others`}
      />
      <BenchmarkTraffic
        data={advertising}
        title="Advertising"
        subtitle={`Average values for selected ${t('communities')} of advertising traffic sources compared to others`}
      />
      <BenchmarkTraffic
        data={performing}
        title="Top Performing Keywords"
        subtitle={`Keywords that result in the most ${t('move_ins')}}`}
        sourceTitle="Keyword"
        loaderStatus={keywordsLoaderStatus}
      />
      <BenchmarkTraffic
        data={no_move_ins}
        title={`Keywords without ${capitalize(t('move_ins'))}`}
        subtitle={`Keywords that did not result in any ${t('move_ins')} yet`}
        sourceTitle="Keyword"
        loaderStatus={keywordsLoaderStatus}
      />
    </PageSection>
  );
};

export default Benchmarks;
