import React, { useState } from 'react';
import styled from 'styled-components';
import { Accordion, Icon, Menu, Popup } from 'semantic-ui-react';
import { FieldArray, useFormikContext } from 'formik';

import { If } from '../../If';
import CRMLeadSourceTable from './CRMLeadSourceTable';

const AccordionBox = styled.div`
  padding: 0 1rem;
`;

const SectionTitle = styled.span`
  padding-right: 5px;
  color: ${({ enabled }) => (enabled ? '#222' : '#a6a6a6')};

  .count {
    color: #a6a6a6;
  }
`;

const CRMLeadSources = ({ fieldName, enabled }) => {
  const [open, setOpen] = useState(false);
  const { values } = useFormikContext();
  const leadSources = values && values[fieldName];

  const toggleOpen = () => {
    if (!enabled) {
      return;
    }

    setOpen(!open);
  };

  const handleRemove = (remove) => (index) => () => {
    remove(index);
  };

  return (
    <Accordion fluid as={Menu} vertical>
      <Accordion.Title active={open} onClick={toggleOpen}>
        <AccordionBox>
          <Icon name="dropdown" />
          <SectionTitle enabled={enabled}>
            Lead Sources <span className="count">{enabled ? `(${leadSources?.length})` : null}</span>
          </SectionTitle>
          <If condition={!enabled}>
            <Popup
              hoverable
              trigger={<Icon name="info" color="blue" size="small" link circular />}
              content="Lead sources are not supported for this CRM type."
              position="bottom center"
              style={{ zIndex: 10000 }}
            />
          </If>
        </AccordionBox>
      </Accordion.Title>
      <Accordion.Content active={open}>
        <AccordionBox>
          <FieldArray name={fieldName}>
            {({ push, remove }) => (
              <CRMLeadSourceTable
                fieldName={fieldName}
                leadSources={leadSources}
                onPush={push}
                onRemove={handleRemove(remove)}
              />
            )}
          </FieldArray>
        </AccordionBox>
      </Accordion.Content>
    </Accordion>
  );
};

export default CRMLeadSources;
