import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon, FURHTER_ICON } from 'components/lib';
import { numberFormatter } from 'utils/helpers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  max-width: fit-content;
  h3 {
    font-family: var(--font-family-bold);
    font-size: 16px;
    text-align: center;
    color: var(--text);
  }
`;

const FancyBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 20px 16px 20px;
  border-radius: 10px;
  border: solid 1px #5f5fff;
  background-image: linear-gradient(to bottom, #9898ff, #4444e9);
`;

const Heading = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  h3 {
    margin: 0;
    font-family: var(--font-family-bold);
    font-size: 12px;
    letter-spacing: 0.5px;
    color: var(--white);
    margin-left: 5px;
    text-transform: uppercase;
  }
  .${FURHTER_ICON} {
    margin-bottom: 5px;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  h3,
  h6 {
    font-family: var(--font-family);
    margin: 0;
  }
  h3 {
    font-size: 42px;
    color: var(--white);
    line-height: 42px;
    margin-right: 5px;
  }
  h6 {
    font-size: 12px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const DigitalTourConversion = ({ tours = 0, visitors = 0 }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <h3>Digital tour conversion</h3>
      <FancyBox>
        <Heading>
          <Icon name="tour" color="rgba(255, 255, 255, 0.6)" size={16} variant="outlined" />
          <h3>{t('tours')} SCHEDULED</h3>
        </Heading>
        <StatsContainer>
          <h3>{numberFormatter(tours, 1)}</h3>
          <h6>/ {numberFormatter(visitors, 1)} visitors</h6>
        </StatsContainer>
      </FancyBox>
    </Container>
  );
};
