import React from 'react';
import styled from 'styled-components';
import { Sticky } from 'semantic-ui-react';

import { Button, Divider } from 'components/lib';

const HeaderContainer = styled.div`
  padding: 20px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-family: var(--font-family-bold);
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.97;
    letter-spacing: normal;
    color: var(--text);
    margin: 0;
  }
`;

const JobApplicantEmailsHeader = ({ context, loading, disabled }) => {
  return (
    <Sticky context={context} className="sticky">
      <HeaderContainer>
        <h3>Job Applicant Emails</h3>
        <Button primary type="submit" color="blue" disabled={loading || disabled} loading={loading}>
          Save
        </Button>
      </HeaderContainer>
      <Divider />
    </Sticky>
  );
};

export default JobApplicantEmailsHeader;
