import React from 'react';
import { Label } from 'semantic-ui-react';

import { Pricing } from './Pricing';
import { Floorplans } from './Floorplans';
import { Gallery } from './Gallery';

const SAMPLE_PRICING = [
  {
    name: 'Example Price',
    cost: 1234.56,
  },
  {
    name: 'Example Price',
    cost: 1234.56,
  },
  {
    name: 'Example Price',
    cost: 1234.56,
  },
];

export const Role = ({ module }) => {
  const { role, text } = module;

  return (
    <>
      {role === 'pricing' && <Pricing startingCost={1234.56} detailedPricing={SAMPLE_PRICING} text={text} />}

      {role === 'floorplan' && <Floorplans />}

      {(role === 'gallery' || role === 'videos') && <Gallery module={module} />}

      {role === 'youtube' && <Label icon="youtube" content="YouTube" />}
    </>
  );
};
