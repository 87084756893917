import React from 'react';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';

const Container = styled.div`
  .date-picker {
    border: solid 1px var(--line-standard) !important;
    background-color: var(--input-field-fill) !important;
    border-radius: 0 !important;
    height: 36px !important;
    padding: 6px 20px 6px 10px !important;
  }
`;

export default ({ ...props }) => {
  return (
    <Container>
      <ReactDatePicker {...props} className="date-picker" />
    </Container>
  );
};
