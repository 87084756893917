import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { ProfileCircle } from '../profile/ProfileCircle';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: solid 1px var(--line-standard);
  background-color: var(--white);
  padding: 22px 20px 22px 34px;
  position: relative;
  cursor: pointer;
  width: 100%;
  &.active {
    box-shadow: 0 3px 5px 0 #d8d8d8;
    z-index: 1;
    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      background-color: #fa7550;
    }
  }

  &:last-of-type {
    border-bottom: solid 1px var(--line-standard);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const Name = styled.h4`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: 1.43;
  color: var(--text-color);
  margin: 0;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Subtitle = styled.h5`
  font-family: var(--font-family);
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
  margin: 0;
`;

const CommunityRow = ({ community, active, onItemSelect, subtitle, additionalElement, className }) => {
  if (!community) {
    return null;
  }

  const { id, name, picture_url } = community;
  const classes = classNames({ active }, className);

  const handleClick = () => {
    if (typeof onItemSelect === 'function') {
      onItemSelect(id);
    }
  };

  return (
    <Container onClick={handleClick} className={classes}>
      <ProfileCircle name={name} picture={picture_url} />
      <Content>
        <SpaceBetween>
          <Name>{name}</Name>
        </SpaceBetween>
        <Subtitle>{subtitle}</Subtitle>
        {additionalElement && additionalElement}
      </Content>
    </Container>
  );
};

export default CommunityRow;
