import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { OnboardingContextProvider, OnboardingHeader, Steps } from '.';

export const OnboardingRoutes = () => {
  const { search } = useLocation();

  return (
    <Switch>
      <Route path="/self-service-onboarding/:onboardingUuid?/steps/:step">
        <OnboardingContextProvider>
          <OnboardingHeader />
          <Steps />
        </OnboardingContextProvider>
      </Route>
      <Redirect to={`/self-service-onboarding/steps/1${search}`} />
    </Switch>
  );
};
