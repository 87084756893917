import React, { useRef } from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { MappingCandidates } from './MappingCandidates';
import { Mapper } from './Mapper';

export const WebFormsAdmin = () => {
  return (
    <Switch>
      <Route path="/admin/web-forms/mapping-candidates/:candidateId" component={Mapper} />
      <Route path="/admin/web-forms/mapping-candidates" component={MappingCandidates} />

      <Redirect to="/admin/web-forms/mapping-candidates" />
    </Switch>
  );
};
