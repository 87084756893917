import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, subDays } from 'date-fns';
import { Button, Dimmer, Loader, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getOverviewMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { formatCount, formatPercent, formatDuration } from 'components/utils';
import { If } from 'components/If';
import Table from 'components/common/Table';

import { capitalize } from 'utils/helpers';
import { MessageContext } from './MessageContext';

const HeaderSegment = styled(Segment)`
  display: flex;
  justify-content: space-between;
`;

const ContentSegment = styled(Segment)`
  padding: 0 !important;

  table {
    border: none;

    td.metric {
      font-weight: bold;
      white-space: nowrap;
      text-align: right;
    }
  }

  .footer-link {
    text-align: center;
    margin: 10px;
  }
`;

const getStartDate = () => format(subDays(new Date(), 30), 'yyyy-MM-dd');
const getEndDate = () => format(new Date(), 'yyyy-MM-dd');

export const CommunityStats = () => {
  const { t } = useTranslation();
  const { lead } = useContext(MessageContext);
  const [loading, setLoading] = useState();
  const [metrics, setMetrics] = useState();

  const fetchMetrics = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      setMetrics({});
      const params = {
        start_date: getStartDate(),
        end_date: getEndDate(),
        sa_communities: lead.community.id,
      };
      const _metrics = await getOverviewMetrics(params);
      setMetrics(_metrics);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Error fetching overview metrics'));
    }
    setLoading(false);
  };

  return (
    <Segment.Group>
      <HeaderSegment>
        Community Stats (30 days)
        <If condition={!metrics}>
          <Button size="mini" className="ui button" onClick={fetchMetrics} content="Show" disabled={loading} />
        </If>
      </HeaderSegment>
      <If condition={metrics}>
        <ContentSegment>
          <Dimmer active={loading} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <Table striped>
            <tr className="odd">
              <td>Emails sent to community</td>
              <td className="metric">{formatCount(metrics?.emails_sent_to_communities)}</td>
            </tr>
            <tr>
              <td>Emails opened by community</td>
              <td className="metric">
                {formatPercent(metrics?.emails_opened_by_communities, metrics?.emails_sent_to_communities)}
              </td>
            </tr>
            <tr className="odd">
              <td>Leads sent to community</td>
              <td className="metric">{formatCount(metrics?.lead_created_emails)}</td>
            </tr>
            <tr>
              <td>Leads who replied to a message</td>
              <td className="metric">{formatCount(metrics?.active_leads)}</td>
            </tr>
            <tr className="odd">
              <td>Leads who received a sales message</td>
              <td className="metric">{formatCount(metrics?.community_replied_leads)}</td>
            </tr>
            <tr>
              <td>Leads who replied to a sales message</td>
              <td className="metric">
                {formatPercent(metrics?.community_active_leads, metrics?.community_replied_leads)}
              </td>
            </tr>
            <tr className="odd">
              <td>{capitalize(t('tours'))} scheduled by Further</td>
              <td className="metric">{formatCount(metrics?.scheduled_tours)}</td>
            </tr>
            <tr>
              <td>Calls scheduled by Further</td>
              <td className="metric">{formatCount(metrics?.scheduled_calls)}</td>
            </tr>
          </Table>
          <div className="footer-link">
            <Link
              to={`/admin/stats/overview?sa_communities=${
                lead?.community.id
              }&start_date=${getStartDate()}&end_date=${getEndDate()}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Full Report
            </Link>
          </div>
        </ContentSegment>
      </If>
    </Segment.Group>
  );
};
