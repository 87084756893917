import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { Icon } from 'components/lib';
import { UserContext, USER_TYPES } from 'components/UserContext';

const ShortcutsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
`;

const Shortcut = styled(Link)`
  width: 310px;
  height: 36px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  border: solid 1px var(--line-standard);
  border-bottom: none;
  padding: 10px;
  cursor: pointer;
  &:last-child {
    border-bottom: solid 1px var(--line-standard);
  }
`;

const Text = styled.span`
  margin-left: 10px;
  margin-right: auto;
  font-family: var(--font-family);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--text);
`;

export const UserCommunityHomeSidebarShortcuts = () => {
  const { user } = useContext(UserContext);
  const { communityId } = useParams();

  const shortcuts = [
    {
      name: 'Stats',
      icon: 'auto_graph',
      iconColor: 'var(--link)',
      path: `/stats?communities=${communityId}`,
      userType: [USER_TYPES.MEMBER, USER_TYPES.STAFF, USER_TYPES.OWNER, USER_TYPES.REGULAR],
    },
    {
      name: 'Leads',
      icon: 'sentiment_satisfied',
      iconColor: 'var(--emerald-green)',
      path: `/conversations?communities=${communityId}`,
      userType: [USER_TYPES.MEMBER, USER_TYPES.STAFF, USER_TYPES.OWNER, USER_TYPES.REGULAR],
    },
    {
      name: 'Messages',
      icon: 'sms',
      iconColor: 'var(--salmon)',
      path: '/leads',
      userType: [USER_TYPES.MEMBER],
    },
  ];

  return (
    <ShortcutsContainer>
      {shortcuts
        ?.filter((shortcut) => shortcut.userType.includes(user.type))
        ?.map((shortcut) => (
          <Shortcut key={shortcut.name} to={shortcut.path}>
            <Icon name={shortcut.icon} color={shortcut.iconColor} variant="outlined" />
            <Text>{shortcut.name}</Text>
            <Icon name="chevron_right" />
          </Shortcut>
        ))}
    </ShortcutsContainer>
  );
};
