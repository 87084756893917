export const DEFAULT_RATING_ORDER_OPTION = '-created_on';

export const DEFAULT_SHOW_VSA_STATUS = 'any';

export const FILE_SIZE_FACTOR = 1024 * 1024; // Size in MB
export const MAX_STAFF_IMAGE_SIZE = 2.5;
export const STAFF_EXCERPT_WORD_LIMIT = 24;

export const RATING_OPTIONS = [
  { key: 'highest', value: '-rating', text: 'Highest Rated' },
  { key: 'oldest', value: 'rating', text: 'Lowest Rated' },
  { key: 'newest', value: '-created_on', text: 'Newest' },
  { key: 'lowest', value: 'created_on', text: 'Oldest' },
];

export const SHOW_ON_VSA_STATUS_OPTIONS = [
  { key: DEFAULT_SHOW_VSA_STATUS, value: DEFAULT_SHOW_VSA_STATUS, text: 'Any Status' },
  { key: 'show_on_vsa', value: true, text: 'Shown on VSA' },
  { key: 'hide_on_vsa', value: false, text: 'Hidden on VSA' },
];

export const USER_COMMUNITY_ACTION_MODALS = {
  ASSIGN_COMMUNITY: 'ASSIGN_COMMUNITY',
  REMOVE_COMMUNITY: 'REMOVE_COMMUNITY',
};

export const USER_COMMUNITY_FLOWS = {
  ASSIGN: 'ASSIGN',
  REMOVE: 'REMOVE',
};
