import React from 'react';

/**
 * Send icon for the text input field.
 */
export const SendIcon = (props) => {
  const fillColor = props.fillColor || '#C2D6F1';
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1782 8.66861L2.15165 0.156906C1.89354 0.0278903 1.60346 -0.0232397 1.31678 0.00975165C1.03011 0.042743 0.75923 0.15843 0.537189 0.342703C0.315148 0.526975 0.151539 0.771869 0.0663171 1.04751C-0.0189048 1.32316 -0.0220563 1.61765 0.0572471 1.89505L2.23119 9.50284H9.99895C10.1304 9.50284 10.2564 9.55505 10.3494 9.64798C10.4423 9.7409 10.4945 9.86694 10.4945 9.99835C10.4945 10.1298 10.4423 10.2558 10.3494 10.3487C10.2564 10.4417 10.1304 10.4939 9.99895 10.4939H2.23119L0.0572471 18.1017C-0.00536457 18.3227 -0.0160833 18.5552 0.0259265 18.781C0.0679362 19.0068 0.16154 19.2199 0.299441 19.4036C0.437342 19.5874 0.615817 19.7368 0.820954 19.8402C1.02609 19.9437 1.25235 19.9983 1.48209 20C1.71479 19.9995 1.94414 19.9446 2.15189 19.8398L19.1782 11.3281C19.4251 11.2046 19.6328 11.0148 19.778 10.7799C19.9231 10.5451 20 10.2744 20 9.99835C20 9.72227 19.9231 9.45165 19.778 9.21679C19.6328 8.98193 19.4251 8.79211 19.1782 8.66861Z"
        fill={fillColor}
      />
    </svg>
  );
};

/**
 * Day switch arrow for the calendar.
 * @param props
 * @returns {*}
 * @constructor
 */
export const Arrow = (props) => {
  const { className, onClick, fillColor = '#387BFF' } = props;
  // Unusually wrapped with a div to make the hover state with the background work.
  // There are other approaches as well.
  return (
    <div {...{ className, onClick }}>
      <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1 2.1L9 0L0 9L9 18L11.1 15.9L4.2 9L11.1 2.1Z"
          fill={fillColor}
        />
      </svg>
    </div>
  );
};

export const NextArrow = (props) => (
  <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.36865 1.47363L3.89497 3.68416L1.36865 5.89469"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FloorPlanIcon = (props) => {
  const { fillColor = '#387BFF', width = 50, height = 50 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50 50"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill={fillColor} stroke="none">
        <path d="M40 250 l0 -210 210 0 210 0 0 210 0 210 -75 0 c-43 0 -75 -4 -75 -10 0 -6 28 -10 65 -10 l65 0 0 -100 0 -100 -35 0 c-19 0 -35 -4 -35 -10 0 -5 16 -10 35 -10 l35 0 0 -80 0 -80 -100 0 -100 0 0 80 0 80 35 0 c19 0 35 5 35 10 0 6 -33 10 -80 10 -47 0 -80 -4 -80 -10 0 -5 16 -10 35 -10 l35 0 0 -80 0 -80 -80 0 -80 0 0 190 0 190 63 0 c55 0 69 -4 105 -31 26 -19 42 -26 42 -17 0 7 -18 25 -39 41 -34 24 -48 27 -115 27 l-76 0 0 -210z" />
      </g>
    </svg>
  );
};

export const VideosIcon = (props) => (
  <svg
    width="55"
    height="25"
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="photo-video"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
    className="svg-inline--fa fa-photo-video fa-w-20 fa-3x"
  >
    <path
      fill="rgb(173, 181, 189)"
      d="M608 0H160c-17.67 0-32 13.13-32 29.33V128h128V32h256v288h-32v32h128c17.67 0 32-13.13 32-29.33V29.33C640 13.13 625.67 0 608 0zM224 96h-64V32h64zm384 224h-64v-64h64zm0-96h-64v-96h64zm0-128h-64V32h64zm-192 64H32a32 32 0 0 0-32 32v288a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32zm0 320H32v-24l81.69-61.26 80 40 126.84-95.14L416 403.23zm0-115.23l-96.53-64.36-129.16 96.86-80-40L32 416V192h384zM112 320a48 48 0 1 0-48-48 48 48 0 0 0 48 48zm0-64a16 16 0 1 1-16 16 16 16 0 0 1 16-16z"
    />
  </svg>
);
