import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { addMonths } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getChatFlow } from 'apis';
import { pluralize } from 'utils/helpers';
import { useIntersection } from 'utils/hooks';
import { formatAnyDate, percent, roundTo } from 'components/utils';
import { Icon, Loader, Popup } from 'components/lib';
import { ChatsContext } from './ChatsContext';
import { MEDIA_BREAK_POINTS } from '../../constants';
import { Embedded, Regular, Dynamic } from './ChatTypeIcons';
import {
  EMBEDDED_VSA_LABEL,
  SURVEY_LABEL,
  REGULAR_VSA_LABEL,
  VOICE_BASED_VSA_LABEL,
  VSA_TYPE,
  DYNAMIC_VSA_LABEL,
} from './constants';

const Chat = styled.div`
  width: 100%;
  min-height: 200px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #fff;
  cursor: pointer;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    width: calc(50% - 15px);
  }
`;

const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 62px);
  width: 100%;
  padding: 0 1.5rem;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    display: flex;
  }
`;

const ChatTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem;
`;

const ChatName = styled.span`
  font-family: var(--font-family-bold);
  font-size: 1.2rem;
  color: var(--link);
  margin-bottom: 0.5rem;
  line-height: 1.4;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text);
`;

const ChatType = styled.div.attrs(({ color, backgroundColor }) => ({
  style: {
    color,
  },
}))`
  display: flex;
  border-radius: 11px;
  white-space: nowrap;
  align-items: center;

  span:not(.material-icons) {
    margin-top: 2px;
    font-family: var(--font-family);
    font-size: 11px;
    line-height: 11px;
    margin-left: 10px;
    margin-right: 20px;
  }
`;

const NoOfCommunities = styled.span`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
`;

const CommunitiesContainer = styled.p`
  font-family: var(--font-family);
  font-size: 12px;
  color: #89919d;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 auto 0;
`;

const AnalyticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2ch;
  padding: 1.5rem 0;
  min-height: 57px;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    justify-content: flex-start;
  }
`;
const AnaliticsBox = styled.div`
  margin-right: 10px;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    margin-right: 30px;
  }
  &:last-child {
    margin-right: 0;
  }
`;
const AnaliticsBoxHeading = styled.h5`
  margin: 0;
  font-family: var(--font-family-bold);
  font-size: 10px;
  letter-spacing: 0.5px;
  color: var(--light-text);
`;
const AnaliticsBoxValue = styled.h4`
  margin: 0;
  font-family: var(--font-family);
  font-size: 22px;
  color: var(--light-text);
  span {
    font-size: 14px;
  }
  &:empty {
    content: '-';
  }
`;

const MoreContainer = styled.div`
  display: flex;
`;

const MoreMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 9999;
`;

const MenuLink = styled.span`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  padding: 10px 20px;
  text-decoration: none;
  width: 120px;
  height: 36px;
  cursor: pointer;
  &:first-child {
    border-bottom: 1px solid #d7d7d7;
  }
  &:hover {
    background-color: #f2f2f2;
  }
`;

const MoreMenu = ({ id }) => {
  const history = useHistory();
  const handleMenuClick = (e, to) => {
    e.stopPropagation();
    history.push(to);
  };

  return (
    <MoreMenuContainer>
      <MenuLink onClick={(e) => handleMenuClick(e, `/chats/${id}`)}>Edit</MenuLink>
      <MenuLink onClick={(e) => handleMenuClick(e, `/chats/${id}/clone`)}>Clone</MenuLink>
    </MoreMenuContainer>
  );
};

export const ChatBox = ({ chat }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const ref = useRef();
  const inViewport = useIntersection(ref, '0px');
  const { addAnalytics } = useContext(ChatsContext);

  const [analytics, setAnalytics] = useState(chat.analytics);
  const [loading, setLoading] = useState(false);
  const [moreMenuVisible, setMoreMenuVisible] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const today = new Date();
    const range = {
      start_date: formatAnyDate(addMonths(today, -12), 'yyyy-MM-dd'),
      end_date: formatAnyDate(today, 'yyyy-MM-dd'),
    };
    const fetchAnalytics = async () => {
      setLoading(true);
      try {
        const { chat_engagement } = await getChatFlow(
          {
            filters: { chat_id: chat.id, ...range },
          },
          source.token
        );
        addAnalytics({ id: chat.id, chat_engagement });
        setAnalytics(chat_engagement);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    if (inViewport && !analytics) {
      fetchAnalytics();
    }
    return () => {
      source.cancel();
    };
  }, [inViewport]);

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setMoreMenuVisible((prevState) => !prevState);
  };

  const handleClick = () => {
    history.push(`/chats/${chat.id}`);
  };

  if (!chat) {
    return null;
  }

  const chatTypeLabelMapper = {
    [VSA_TYPE.EMBEDDED]: {
      title: EMBEDDED_VSA_LABEL,
      backgroundColor: '#f0f0ff',
      color: '#5f5fff',
      Icon: <Embedded />,
      bigIcon: 'wysiwyg',
      bigIconVariant: 'outlined',
    },
    [VSA_TYPE.SURVEY]: {
      title: SURVEY_LABEL,
      backgroundColor: '#f0f0ff',
      color: '#5f5fff',
      Icon: <Embedded />,
      bigIcon: 'wysiwyg',
      bigIconVariant: 'outlined',
    },
    [VSA_TYPE.REGULAR]: {
      title: REGULAR_VSA_LABEL,
      backgroundColor: '#ebf5ff',
      color: '#2791ff',
      Icon: <Regular />,
      bigIcon: 'question_answer',
      bigIconVariant: 'outlined',
    },
    [VSA_TYPE.PHONE]: {
      title: VOICE_BASED_VSA_LABEL,
      backgroundColor: '#defff3',
      color: '#0ab776',
      Icon: <Icon name="phone" size={14} />,
      bigIcon: 'phone',
      bigIconVariant: '',
    },
    [VSA_TYPE.DYNAMIC]: {
      title: DYNAMIC_VSA_LABEL,
      backgroundColor: '#fff2f0',
      color: '#ff5f5f',
      Icon: <Dynamic />,
      bigIcon: 'wysiwyg',
      bigIconVariant: 'outlined',
    },
  };

  const chatType = chatTypeLabelMapper[chat.vsa_type];
  const numberOfCommunities = chat.chat_properties.length;
  const communities = chat?.chat_properties
    .map((cp) => cp.community)
    .map((community, i) => `${community?.name}${numberOfCommunities - 1 === i ? '' : ', '}`);
  const visitesToLeads = roundTo(percent(analytics?.leads, analytics?.visitors), 2);

  return (
    <Chat onClick={handleClick} ref={ref}>
      <ChatTop>
        <ChatType color={chatType.color} backgroundColor={chatType.backgroundColor}>
          {chatType.Icon}
          <span>{chatType.title}</span>
        </ChatType>
        <Popup
          basic
          className="menu"
          position="bottom right"
          on={['click', 'focus']}
          offset={[0, -10]}
          open={moreMenuVisible}
          onClose={() => setMoreMenuVisible(false)}
          trigger={
            <MoreContainer onClick={handleMenuClick}>
              <Icon name="more_horiz" />
            </MoreContainer>
          }
        >
          <MoreMenu id={chat.id} />
        </Popup>
      </ChatTop>

      <ChatContent>
        <div>
          <ChatName>{chat?.name}</ChatName>

          <CommunitiesContainer title={communities}>
            <NoOfCommunities>{pluralize(numberOfCommunities, t('communities'), t('community'))}:</NoOfCommunities>{' '}
            {communities}
          </CommunitiesContainer>
        </div>

        <AnalyticsContainer>
          {loading ? (
            <Loader active={loading} fullwidth />
          ) : (
            <>
              <AnaliticsBox>
                <AnaliticsBoxHeading>LEADS</AnaliticsBoxHeading>
                <AnaliticsBoxValue>{analytics?.leads || '-'}</AnaliticsBoxValue>
              </AnaliticsBox>
              <AnaliticsBox>
                <AnaliticsBoxHeading>TOURS</AnaliticsBoxHeading>
                <AnaliticsBoxValue>{analytics?.tours || '-'}</AnaliticsBoxValue>
              </AnaliticsBox>
              <AnaliticsBox>
                <AnaliticsBoxHeading>MOVE-INS</AnaliticsBoxHeading>
                <AnaliticsBoxValue>{analytics?.move_ins || '-'}</AnaliticsBoxValue>
              </AnaliticsBox>
              <AnaliticsBox>
                <AnaliticsBoxHeading>VISITS TO LEADS</AnaliticsBoxHeading>
                <AnaliticsBoxValue>
                  {visitesToLeads ? (
                    <>
                      {visitesToLeads}
                      <span>%</span>
                    </>
                  ) : (
                    '-'
                  )}
                </AnaliticsBoxValue>
              </AnaliticsBox>
            </>
          )}
        </AnalyticsContainer>
      </ChatContent>
    </Chat>
  );
};
