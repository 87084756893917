import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { If } from 'components/If';
import { Button, Form, Icon } from 'components/lib';
import {
  RecordingsModal,
  RecordingsModalHeader,
  RecordingsModalContent,
  RecordingsModalActions,
} from './RecordingsModal';

const InvisibleInput = styled.input`
  display: none;
`;

const AttachmentContainer = styled.div`
  margin-top: 25px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UploadContainer = styled.div`
  margin-top: 25px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  .label {
    font-weight: 900;
  }
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  position: relative;
  top: -2px;
`;

const FileName = styled.div`
  display: flex;
  alignitems: center;
  gap: 12px;
`;

export const EditModuleRecordingsModal = ({ isOpen, module, loading, handleConfirm, handleCancel }) => {
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState(null);
  const [resourceKey, setRecourceKey] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const initialFormValues = {
    audio: null,
    moduleId: module?.id,
  };

  useEffect(() => {
    setRecourceKey(module?.recording?.resourceKey);
    setIsEdit(!!module?.recording?.resourceKey);
  }, [module]);

  const onFormSubmit = async (resetForm) => {
    await handleConfirm({ audio: file });
    setFile(null);
    setRecourceKey(null);
    resetForm();
    hiddenFileInput.current.value = null;
  };

  const handleUploadClick = (event) => {
    event.stopPropagation();
    hiddenFileInput.current.click();
  };

  const handleAddRecordingAudio = (event, setFieldValue) => {
    const fileUploaded = event.target.files[0];
    setFile(fileUploaded);
    setRecourceKey(fileUploaded?.name);
    setFieldValue('audio', fileUploaded?.name);
  };

  const removeRecordingAudio = (setFieldValue) => {
    setRecourceKey(null);
    setFile(null);
    setFieldValue('audio', '');
    hiddenFileInput.current.value = null;
  };

  const onCancel = (resetForm) => {
    handleCancel();
    setFile(null);
    setRecourceKey(null);
    resetForm();
  };

  return (
    <Formik initialValues={initialFormValues} onSubmit={onFormSubmit} enableReinitialize>
      {({ dirty, touched, handleSubmit, setFieldValue, resetForm, isValid }) => (
        <Form>
          <RecordingsModal open={isOpen} closeIcon onClose={() => onCancel(resetForm)} size="tiny">
            <RecordingsModalHeader>{isEdit ? 'Re-upload ' : 'Upload '}Custom Module Recording</RecordingsModalHeader>
            <RecordingsModalContent>
              Set a module recording to be used during the phone calls.
              <div>
                <If condition={!resourceKey}>
                  <UploadContainer onClick={handleUploadClick}>
                    <Button
                      icon
                      type="button"
                      onClick={handleUploadClick}
                      circular
                      className="raised"
                      disabled={loading}
                    >
                      <StyledIcon size="22" name="upload" variant="outlined" color="var(--light-text)" />
                    </Button>
                    <span>Press button to upload the new file.</span>
                  </UploadContainer>
                </If>

                {resourceKey && (
                  <AttachmentContainer>
                    <FileName>
                      <Icon name="record_voice_over" variant="outlined" size="24" />
                      <span>{resourceKey}</span>
                    </FileName>
                    <div>
                      <Button
                        icon
                        type="button"
                        onClick={() => removeRecordingAudio(setFieldValue)}
                        circular
                        color="white"
                        disabled={loading}
                      >
                        <StyledIcon name="delete" variant="outlined" color="red" size="24" />
                      </Button>
                    </div>
                  </AttachmentContainer>
                )}
              </div>
              <InvisibleInput
                name="file"
                type="file"
                ref={hiddenFileInput}
                onChange={(e) => handleAddRecordingAudio(e, setFieldValue)}
                accept=".mp3"
              />
            </RecordingsModalContent>
            <RecordingsModalActions>
              <Button
                primary
                color="blue"
                disabled={!dirty || !touched || !isValid || loading || (!file && !isEdit)}
                loading={loading}
                onClick={() => handleSubmit(resetForm)}
              >
                Save
              </Button>
              <Button basic disabled={loading} onClick={() => onCancel(resetForm)}>
                Cancel
              </Button>
            </RecordingsModalActions>
          </RecordingsModal>
        </Form>
      )}
    </Formik>
  );
};
