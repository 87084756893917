import React from 'react';
import styled from 'styled-components';

const Element = styled.h3`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px;
`;

const SectionTitle = ({ children, ...rest }) => <Element {...rest}>{children}</Element>;

export default SectionTitle;
