import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { Button } from 'components/lib';

const Container = styled.div`
  display: flex;
  gap: 0;
  flex-direction: column;
`;

const NoteArea = styled.textarea`
  width: 100%;
  border: 0;
  padding: 10px;
  color: var(--60-white);
  background-color: #2f2c53;
  height: 160px;
  outline: none;
  resize: none;
`;

const NoteControls = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  gap: 10px;
`;

export const NoteBox = ({ note, onSave, onCancel }) => {
  const [value, setValue] = useState(note);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleSave = useCallback(() => {
    onSave(value);
  }, [onSave, value]);

  return (
    <Container>
      <NoteArea value={value} onChange={onChange} />
      <NoteControls>
        <Button primary color="blue" onClick={handleSave} size="mini">
          Save Note
        </Button>
        <Button primary onClick={onCancel} size="mini">
          Cancel
        </Button>
      </NoteControls>
    </Container>
  );
};
