import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import CleanupConversations from './CleanupConversations';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 590px;
`;

const StyledIcon = styled(Icon)`
  transform: scale(-1, 1);
  background-image: linear-gradient(to top, #de75ff, var(--vivid-blue));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  margin-top: 10px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 5px;

  & div {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #89919d;

    & span {
      color: var(--text);
    }
  }
`;

const OngoingConversationNotSelected = () => {
  return (
    <Container>
      <Content>
        <div>
          <StyledIcon name="forward_to_inbox" variant="outlined" size="45" />
        </div>
        <Header>Select a conversation.</Header>
        <Description>
          <div>
            <span>Answer</span> messages that need responding
          </div>
          <div>
            <span>Set up reminders</span> to follow up on the conversation later
          </div>
          <div>
            <span>Archive</span> any finished, left for later, or unqualified conversations.
          </div>
        </Description>
        <CleanupConversations />
      </Content>
    </Container>
  );
};

export default OngoingConversationNotSelected;
