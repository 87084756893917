import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Button } from 'components/lib';
import { MEDIA_BREAK_POINTS } from '../../../../constants';
import { WizardModal } from './WizardModal';
import { CampaignsTemplateProvider, useCampaignsTemplate } from '../CampaignsTemplateContext';
import { CreatingCampaignsModal } from '../TemplateWizard/CreatingCampaignsModal';
import { CAMPAIGN_BUILD_TYPES } from '../constants';

const StyledButton = styled(Button)`
  &&& {
    width: 180px;
    margin-left: auto;
    margin-right: 10px;
    @media only screen and (max-width: ${MEDIA_BREAK_POINTS.WEB}px) {
      margin-top: 30px;
    }
  }
`;

const Component = () => {
  const { state, dispatch, actions } = useCampaignsTemplate();
  const { isWizardActive, isCreatingCampaignsModalOpen, initialized } = state;

  const handleOnClick = () => {
    dispatch({ type: actions.SET_WIZARD_ACTIVE, data: true });
  };

  return (
    <>
      <StyledButton primary onClick={handleOnClick} disabled={!initialized}>
        Create from Template
      </StyledButton>
      <If condition={isWizardActive}>
        <WizardModal open={isWizardActive} />
      </If>
      <If condition={isCreatingCampaignsModalOpen}>
        <CreatingCampaignsModal open={isCreatingCampaignsModalOpen} source={CAMPAIGN_BUILD_TYPES.TEMPLATE} />
      </If>
    </>
  );
};

export const CreateFromTemplate = () => {
  return (
    <CampaignsTemplateProvider>
      <Component />
    </CampaignsTemplateProvider>
  );
};
