import constants from './constants';

export const setIsMultipleSelection = () => ({
  type: constants.SET_IS_MULTIPLE_SELECTION,
});

export const setSelectedItem = (data) => ({
  type: constants.SET_SELECTED_ITEM,
  payload: data,
});

export const setSelectedPages = (data) => ({
  type: constants.SET_SELECTED_PAGES,
  payload: data,
});

export const setSelectAllItems = (data) => ({
  type: constants.SET_SELECT_ALL_ITEMS,
  payload: data,
});

export const setResetIsMultipleSelection = () => ({
  type: constants.SET_RESET_IS_MULTIPLE_SELECTION,
});

export const setResetSelectedPages = () => ({
  type: constants.SET_RESET_SELECTED_PAGES,
});

export const setResetSelectedItems = () => ({
  type: constants.SET_RESET_SELECTED_ITEMS,
});

export const setResetExcludedItems = () => ({
  type: constants.SET_RESET_EXCLUDED_ITEMS,
});

export const setResetAllItemsSelected = () => ({
  type: constants.SET_RESET_ALL_ITEMS_SELECTED,
});

export const resetTableSelection = () => ({
  type: constants.RESET_TABLE_SELECTION,
});

export const resetState = () => ({
  type: constants.SET_RESET,
});
