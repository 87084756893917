import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, Dropdown } from 'semantic-ui-react';

import { If } from 'components/If';
import { UserContext } from 'components/UserContext';

const DropdownContainer = styled.div`
  margin-top: 1rem;
`;

const CRMSettingsUserSelector = ({ users, selectedUser, onSelectUser }) => {
  const { user } = useContext(UserContext);
  const [showChangeUser, setShowChangeUser] = useState(false);

  const handleShowChangeUser = () => {
    const newShowChangeUser = !showChangeUser;

    setShowChangeUser(newShowChangeUser);

    if (!newShowChangeUser) {
      const { id } = user;

      onSelectUser(id);
    }
  };

  const handleChangeUser = (e, { value }) => {
    onSelectUser(value);
  };

  if (!users) {
    return null;
  }

  return (
    <>
      <Checkbox label="Change for other user" onChange={handleShowChangeUser} checked={showChangeUser} toggle />
      <If condition={showChangeUser}>
        <DropdownContainer>
          <Dropdown
            onChange={handleChangeUser}
            options={users}
            fluid
            search
            selection
            placeholder="Select User"
            value={selectedUser}
          />
        </DropdownContainer>
      </If>
    </>
  );
};

export default CRMSettingsUserSelector;
