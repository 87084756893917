import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Divider } from 'components/lib';
import { AuthContext } from '../context';
import { AuthLayout, Message } from '../AuthLayout';
import LoginPasswordFieldForm from './LoginPasswordForm';
import EmailMagicLinkButton from '../EmailMagicLinkButton';

export const LoginPassword = () => {
  const history = useHistory();
  const { email, login, loginQueryParams, requestMagicLink, loginLoading, magicLinkLoading } = useContext(AuthContext);

  useEffect(() => {
    if (!email) {
      history.replace('/login');
    }
  });

  const handleEmailPasswordLogin = async (password) => {
    await login({ email, password });
  };

  const onSendMagicLink = async () => {
    history.replace(`/login/otp?${loginQueryParams}`);
  };

  return (
    <AuthLayout>
      <h1>Enter password</h1>
      <p>Enter your password.</p>
      <Divider horizontal />
      <LoginPasswordFieldForm
        loading={loginLoading}
        disabled={magicLinkLoading}
        requestMagicLink={requestMagicLink}
        onSubmit={handleEmailPasswordLogin}
      />
      <Message>Don't like using passwords?</Message>
      <EmailMagicLinkButton loading={magicLinkLoading} disabled={magicLinkLoading} onClick={onSendMagicLink} />
    </AuthLayout>
  );
};
