import { formatPercent } from 'components/utils';
import React from 'react';
import styled from 'styled-components';
import SimpleMetric from './SimpleMetric';

const SeriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${(p) => p.fontSize};
  line-height: 14px;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
  > div {
    margin-left: 5px;
    white-space: nowrap;
  }
  .name {
    font-family: ${(p) => p.nameFontFamily};
    color: var(--text);
  }
  .dot {
    width: 10px;
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(p) => p.color};
  }
`;

const SeriesDescription = ({
  name,
  value,
  total,
  color,
  fontSize = '12px',
  unitFontSize = '12px',
  nameFontFamily = 'var(--font-family-bold)',
  metricFontFamily = 'var(--font-family-bold)',
}) => (
  <SeriesWrapper color={color} fontSize={fontSize} nameFontFamily={nameFontFamily}>
    <div className="dot" />
    <div className="name">{name}</div>
    <SimpleMetric
      metric={value}
      metricFontFamily={metricFontFamily}
      unit={total && formatPercent(value, total)}
      fontSize={fontSize}
      unitFontSize={unitFontSize}
    />
  </SeriesWrapper>
);

export default SeriesDescription;
