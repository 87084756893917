import { Healthcare, Nurse, Thinking, LivingRoom, HearthBeat, TwinBed, KingBed } from 'components/Icons';

export default {
  SET_LOADER_STATUS: 'SET_LOADER_STATUS',
  SET_INVITATION: 'SET_INVITATION',
  SET_TOKEN_INVALID: 'SET_TOKEN_INVALID',
  SET_NEXT_STEP: 'SET_NEXT_STEP',
  SET_PREV_STEP: 'SET_PREV_STEP',
  SET_CARE_TYPES: 'SET_CARE_TYPES',
  SET_UNITS: 'SET_UNITS',
};

export const UNIT_LEVEL_PRICING_STEPS = {
  GETTING_STARTED: 1,
  CARE_TYPES: 2,
  LIVING_ACCOMMODATION: 3,
  PRICE: 4,
  AVAILABILITY: 5,
  SUMMARY: 6,
  COMPLETED: 7,
};

const UNIT_LEVEL_PRICING_STEP_IDS = Object.values(UNIT_LEVEL_PRICING_STEPS);
export const UNIT_LEVEL_PRICING_FIRST_STEP = Math.min(...UNIT_LEVEL_PRICING_STEP_IDS);
export const UNIT_LEVEL_PRICING_LAST_STEP = Math.max(...UNIT_LEVEL_PRICING_STEP_IDS);

export const CARE_TYPES = [
  {
    id: 'skilled_nursing',
    title: 'Skilled Nursing',
    icon: Nurse,
  },
  {
    id: 'memory_care',
    title: 'Memory Care',
    icon: Thinking,
  },
  {
    id: 'assisted_living',
    title: 'Assisted Living',
    icon: Healthcare,
  },
  {
    id: 'independent_living',
    title: 'Independent Living',
    icon: LivingRoom,
  },
  {
    id: 'respite_care',
    title: 'Respite Care',
    icon: HearthBeat,
  },
];

export const UNIT_TYPES = [
  { id: 'apartment', title: 'Apartment', icon: LivingRoom },
  { id: 'room', title: 'Studio', icon: KingBed },
  { id: 'studio', title: 'Room', icon: TwinBed },
];
