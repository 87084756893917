import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import { Card } from './Card';

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SortableGrid = ({ items, key = 'id', contentComponent, onUpdate, onRemove }) => {
  const [cardsById, setCardsById] = useState();
  const [cardsByIndex, setCardsByIndex] = useState([]);
  const Component = contentComponent;

  let pendingUpdateFn;
  let requestedFrame;

  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      return arr;
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  useEffect(() => {
    if (!items) {
      return;
    }
    const byId = {};
    const byIndex = [];
    items.forEach((card, i) => {
      byId[card[key]] = card;
      byIndex[i] = card;
    });

    setCardsById(byId);
    setCardsByIndex(byIndex);
  }, [items, key]);

  const moveCard = (id, afterId) => {
    const card = cardsById[id];
    const afterCard = cardsById[afterId];
    const cardIndex = cardsByIndex.indexOf(card);
    const afterIndex = cardsByIndex.indexOf(afterCard);
    scheduleUpdate({
      cardIndex,
      afterIndex,
    });
  };
  const removeCard = (item) => () => {
    const newState = cardsByIndex.filter((c) => c[key] !== item[key]);
    setCardsByIndex([...newState]);
    if (onRemove) {
      onRemove(item);
    }
    if (onUpdate) {
      onUpdate(newState);
    }
  };

  const drawFrame = () => {
    if (!pendingUpdateFn) {
      return;
    }

    const newState = array_move(cardsByIndex, pendingUpdateFn.cardIndex, pendingUpdateFn.afterIndex);
    setCardsByIndex([...newState]);
    if (onUpdate) {
      onUpdate(newState);
    }
    pendingUpdateFn = undefined;
    requestedFrame = undefined;
  };

  const scheduleUpdate = (updateFn) => {
    pendingUpdateFn = updateFn;
    if (!requestedFrame) {
      requestedFrame = requestAnimationFrame(drawFrame);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid>
        {cardsByIndex?.map((card) => (
          <Card
            key={card[key]}
            id={card[key]}
            content={<Component item={card} onRemove={removeCard(card)} />}
            moveCard={moveCard}
          />
        ))}
      </Grid>
    </DndProvider>
  );
};
