import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { Button, Dropdown, Header, Icon, Input } from 'semantic-ui-react';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';
import { ChatModulesContext } from '../../ChatModulesContext';
import { ACTION_TYPES } from '../../ModuleGraph';
import { GrabHandleWrapper } from '../../GrabHandleWrapper';
import { MODULE_TYPES } from '../../constants';
import { CARE_TYPES_ANSWERS_PLACEHOLDER } from './AnswersField';
import { If } from '../../../../If';

const AnswerWrapper = styled.div`
  margin-bottom: 1rem;
`;

const OptionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p) => (p.error ? '#db2828' : '#2185d0')};
  color: #fff;
  border-top-left-radius: 0.28571429rem;
  border-top-right-radius: 0.28571429rem;

  .ui.buttons {
    height: 100%;
  }

  .ui.input input {
    border: none;
    background: transparent;
    color: #fff;
    text-align: center;

    &:focus {
      background: #0000002b;
      color: #fff;
    }
  }

  &:hover {
    .ui.buttons,
    i.icon {
      opacity: 0.5;
    }

    .ui.input input {
      background: #0000002b;
      color: #fff;
    }
  }
`;

const DeleteHandle = styled.div`
  .ui.buttons {
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
`;

const Actions = styled.div`
  padding: 0.5rem;
  /* background: #eaeff7; */
  border: 1px solid;
  border-color: ${(p) => (p.error ? '#db2828' : '#2185d0')};
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: ${(p) => (p.active ? '#eaeff7' : 'white')};
  border-radius: 0.28571429rem;
  color: #333;
  white-space: nowrap;
  min-height: 3rem;
  cursor: pointer;

  .ui.dropdown.button {
    flex: 0 0 6rem;
    text-align: center;
    min-width: 6rem;
    min-height: 2rem;
    padding: 0.5rem;
    padding-left: 2.5rem !important;
    padding-right: 0.5rem !important;

    input.search {
      text-align: center;
    }

    .menu {
      width: 20rem;
    }
  }
`;

const StyledInput = styled(Input)`
  &.ui.disabled.input {
    input {
      font-family: var(--font-family-bold);
      opacity: 0.75;
    }
  }
`;

const Answer = ({ answer, index, error, initialValue, onChange, onDelete }) => {
  const { values: node } = useFormikContext();
  const { module } = node;
  const text = answer.text || '';
  const { meta } = answer;
  const type = meta?.actionType;
  const { moduleGraph } = useContext(ChatModulesContext);

  const moduleList = moduleGraph.modules.map((m) => ({
    key: m.number,
    value: m.number,
    text: `${m?.number}`,
    search: `Module ${m?.number} [${m?.module_type} ${m?.text}]`,
    content: <Header as="h5" content={`Module ${m?.number} [${m?.module_type}]`} subheader={m?.text} />,
  }));

  const [, drag, preview] = useDrag({
    item: { type: `options_in_module_${module?.number}`, value: { answer, index } },
  });

  const onTextChange = (e) => {
    if (typeof onChange === 'function') {
      onChange({
        ...answer,
        text: e.target.value,
      });
    }
  };

  const onActionTypeChange = (type) => {
    if (typeof onChange === 'function') {
      if (initialValue && type === initialValue.meta?.actionType) {
        onChange(initialValue);
      } else {
        const newVal = {
          ...answer,
          meta: {
            ...answer.meta,
            actionType: type,
          },
        };
        if (type === ACTION_TYPES.CLOSE) {
          newVal.action = null;
        }
        if (type === ACTION_TYPES.BRANCH) {
          newVal.action = module.number;
        }
        if (type === ACTION_TYPES.STEP) {
          const existingModule = moduleGraph.getNode(module?.number);
          const nextStepNumber = existingModule ? existingModule.getNext()?.number : module?.action;

          newVal.action = nextStepNumber || 0;
        }

        onChange(newVal);
      }
    }
  };

  const setAction = (action) => {
    onChange({
      ...answer,
      action,
    });
  };

  const is_care_type_answer =
    module.module_type === MODULE_TYPES.CARE_OPTION && text === CARE_TYPES_ANSWERS_PLACEHOLDER;

  return (
    <AnswerWrapper>
      <OptionButton ref={preview} error={error}>
        <GrabHandleWrapper ref={drag}>
          <Icon name="ellipsis vertical" />
          <Icon name="ellipsis vertical" />
        </GrabHandleWrapper>

        <StyledInput value={text} autoFocus onChange={onTextChange} disabled={is_care_type_answer} />

        <If condition={!is_care_type_answer}>
          <DeleteHandle>
            <Button.Group>
              <Button
                tabIndex={-1}
                type="button"
                color="blue"
                icon="trash alternate outline"
                onClick={() => {
                  if (typeof onDelete === 'function') {
                    onDelete();
                  }
                }}
              />
            </Button.Group>
          </DeleteHandle>
        </If>
      </OptionButton>
      <Actions error={error}>
        <Action
          active={type === ACTION_TYPES.STEP}
          onClick={() => {
            onActionTypeChange(ACTION_TYPES.STEP);
          }}
        >
          Go to next step
        </Action>

        <Action
          active={type === ACTION_TYPES.BRANCH}
          onClick={() => {
            onActionTypeChange(ACTION_TYPES.BRANCH);
          }}
        >
          Branch into a separate dialog flow
        </Action>

        <Action
          active={type === ACTION_TYPES.BRANCH_JUMP}
          onClick={() => {
            onActionTypeChange(ACTION_TYPES.BRANCH_JUMP);
          }}
        >
          Jump to module
          {type === ACTION_TYPES.BRANCH_JUMP && (
            <Dropdown
              floating
              labeled
              button
              className="icon"
              options={moduleList}
              search={(options, query) => options.filter((o) => o.search.includes(query.toLowerCase()))}
              value={answer.action}
              onChange={(e, d) => {
                setAction(d.value);
              }}
            />
          )}
        </Action>

        <Action
          active={type === ACTION_TYPES.CLOSE}
          onClick={() => {
            onActionTypeChange(ACTION_TYPES.CLOSE);
          }}
        >
          Close chat
        </Action>
      </Actions>
    </AnswerWrapper>
  );
};

export default Answer;
