import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Menu } from 'semantic-ui-react';

import { useMessagesContext } from './MessagesContext';
import { CONVERSATION_TYPES } from './constants';

const Container = styled.div`
  padding-top: 15px;
  padding-left: ${({ leftpadding }) => (leftpadding ? '30px' : 0)};
`;

const StyledMenu = styled(Menu)`
  padding-left: ${({ leftpadding }) => (leftpadding ? '30px' : 0)};

  &&& {
    border: none;
  }

  &&&& {
    padding-bottom: 1px;
    border-bottom: ${({ borderless }) => (borderless ? 'none' : '1px solid var(--line-standard);')};
  }

  &&&& .item {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    color: ${({ inverted }) => (inverted ? 'var(--60-white)' : ' #89919d')} !important;
    padding: 14px 0;
    margin-left: 10px;
    margin-right: 10px;
  }

  &&&& .item.active {
    font-weight: 900;
    color: ${({ inverted }) => (inverted ? 'var(--white)' : 'var(--text)')} !important;
  }
`;

const MessagesNavigation = ({ variant = 'light', padding = 'container', borderless = false }) => {
  const history = useHistory();
  const { conversationType } = useMessagesContext();
  const isDarkMode = variant === 'light';

  const handleChangeConversationType = (conversationType) => () => {
    if (conversationType === CONVERSATION_TYPES.ONGOING) {
      history.push('/messages');
    } else {
      history.push('/messages/archived');
    }
  };

  return (
    <Container leftpadding={padding === 'container' ? 1 : 0}>
      <StyledMenu
        pointing
        secondary
        inverted={isDarkMode}
        leftpadding={padding === 'menu' ? 1 : 0}
        borderless={borderless}
      >
        <Menu.Item
          name="Ongoing"
          active={conversationType === CONVERSATION_TYPES.ONGOING}
          onClick={handleChangeConversationType(CONVERSATION_TYPES.ONGOING)}
        />
        <Menu.Item
          name="archived"
          active={conversationType === CONVERSATION_TYPES.ARCHIVED}
          onClick={handleChangeConversationType(CONVERSATION_TYPES.ARCHIVED)}
        />
      </StyledMenu>
    </Container>
  );
};

export default MessagesNavigation;
