import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TabsWithNavigation } from 'components/lib';

import { AutomatedAnswers } from '../MessagingAssistant/AutomatedAnswers';
import { Availability } from './Availability';

const INSTANT_ANSWERS_TABS = [
  {
    tabName: 'Availability',
    path: '/advanced/instant-answers/availability',
    Content: Availability,
  },
  {
    tabName: 'Automated Answers',
    path: '/advanced/instant-answers/automated-answers',
    Content: () => <AutomatedAnswers answerTypes={['INSTANT_ANSWER']} />,
  },
];

const INITIAL_TAB = INSTANT_ANSWERS_TABS[0].path;

export const InstantAnswers = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/advanced/instant-answers') {
      history.replace(INITIAL_TAB);
    }
  }, []);

  return (
    <div>
      <TabsWithNavigation tabs={INSTANT_ANSWERS_TABS} />
    </div>
  );
};
