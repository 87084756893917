import React, { useContext } from 'react';
import styled from 'styled-components';

import { ReactComponent as LinkedInIcon } from 'assets/images/linkedin.svg';
import { Dropdown } from 'components/lib';
import { OverviewContext } from '.';
import { OVERVIEW_RANGE_OPTIONS } from './constants';
import { MEDIA_BREAK_POINTS } from '../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
  }
  h2 {
    font-family: var(--font-family-bold);
    font-size: 60px;
    line-height: 0.97;
    color: var(--text);
    display: flex;
    margin-right: auto;
  }
`;

const Beta = styled.div`
  display: flex;
  width: 50px;
  height: 26px;
  padding: 5px 10px 3px;
  border-radius: 4px;
  background-color: #f0f0ff;
  font-family: var(--font-family);
  font-size: 12px;
  text-align: center;
  color: #5f5fff;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 12px;
`;

const LinkedInShare = styled.div`
  width: 99px;
  height: 36px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: var(--white);
  padding: 4px 3px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  span {
    margin-left: 14px;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: var(--text);
  }
`;

export const OverviewHeader = () => {
  const { handleRangeChange, range } = useContext(OverviewContext);

  return (
    <Container>
      <h2>
        Overview
        <Beta>BETA</Beta>
      </h2>
      <Dropdown options={OVERVIEW_RANGE_OPTIONS} selection value={range} onChange={handleRangeChange} disabled />
      {/* <LinkedInShare>
        <LinkedInIcon width={28} height={28} />
        <span>Share</span>
      </LinkedInShare> */}
    </Container>
  );
};
