export const SIMPLE_US_PHONE_REGEX = /^\+1\d{10}/;

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';

  if (!phoneNumber.match(SIMPLE_US_PHONE_REGEX)) return phoneNumber;

  return `${phoneNumber.substr(2, 3)}-${phoneNumber.substr(5, 3)}-${phoneNumber.substr(8, 4)}`;
};

export const groupCampaignNumbersByReferral = (phoneNumbers) => {
  const phoneNumbersList = [];

  if (!phoneNumbers) return phoneNumbersList;

  phoneNumbers.forEach((campaignPhoneNumber) => {
    const { referralOrigin, phoneNumber, isCustom } = campaignPhoneNumber;

    const found = phoneNumbersList.find((obj, i) => {
      if (obj.referralOrigin === referralOrigin) {
        phoneNumbersList[i].phoneNumbers.push(phoneNumber);
        return true;
      }
      return false;
    });

    if (!found) {
      phoneNumbersList.push({
        referralOrigin,
        isCustom,
        phoneNumbers: [phoneNumber],
      });
    }
  });

  return phoneNumbersList;
};
