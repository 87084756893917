import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Statistic, Input, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { getLeadValueScores } from 'apis';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { parseErrorResponse } from 'apis/utils';
import { capitalize, queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { CsvExport } from '../../common/CsvExport';
import { PageSection } from '../../common';
import { formatCount } from '../../utils';

const Wrapper = styled.div`
  .chevron.up.icon {
    color: #21ba45;
  }
  .chevron.down.icon {
    color: #db2828;
  }
  .minus.icon {
    color: #999;
  }
`;

const prepareCsvContent = (leadValueScores, globalAvgLvs, t) => {
  const headers = [
    `${capitalize(t('community'))} Name`,
    `${capitalize(t('community'))} Avg. ${capitalize(t('move_ins'))} Likelihood`,
    `Avg. ${capitalize(t('move_in'))} Likelihood`,
    `Requested ${capitalize(t('tours'))}`,
    `Expected ${capitalize(t('move_ins'))}`,
    `Actual ${capitalize(t('move_ins'))}`,
    `${capitalize(t('move_in'))} Trend`,
  ];
  const content = leadValueScores.map((l) => [
    l.community_name,
    l.avg_lvs?.toFixed(1),
    globalAvgLvs?.toFixed(1),
    l.scheduled_tours,
    l.expected_moveins,
    l.moveins,
    l.movein_trend,
  ]);

  return [headers, content];
};

const LeadValueScores = ({ filters, publicId }) => {
  const { t } = useTranslation();
  const [leadValueScores, setLeadValueScores] = useState([]);
  const [globalAvgLvs, setGlobalAvgLvs] = useState();
  const [communityNameFilter, setCommunityNameFilter] = useState('');

  const { data, status, error, isFetching } = useQuery([queryConstants.LEAD_VALUE_SCORES, { publicId, filters }], () =>
    getLeadValueScores({ public_id: publicId, filters })
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, `Unable to fetch ${t('move_in')} likelihood data`));
  }

  useEffect(() => {
    if (data) {
      setLeadValueScores(data?.per_community_lvs);
      setGlobalAvgLvs(data?.global_avg_lvs);
    }
  }, [data]);

  const [csvHeaders, csvContent] = prepareCsvContent(leadValueScores, globalAvgLvs, t);

  return (
    <PageSection
      title={`${capitalize(t('community'))} Breakdown`}
      loaderStatus={loaderStatus}
      actions={
        globalAvgLvs && (
          <div>
            <Statistic
              label={`Average ${capitalize(t('move_in'))} Likelihood`}
              value={globalAvgLvs.toFixed(1)}
              size="mini"
            />
          </div>
        )
      }
    >
      <Wrapper>
        <Table className="fixed-size">
          <Table.Header>
            <Table.Row textAlign="right">
              <Table.HeaderCell textAlign="left">
                <Input
                  size="small"
                  placeholder={`Find ${capitalize(t('community'))}`}
                  value={communityNameFilter}
                  onChange={(e) => {
                    setCommunityNameFilter(e.target.value);
                  }}
                  style={{ width: '15rem' }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>Avg. {capitalize(t('move_in'))} Likelihood</Table.HeaderCell>
              <Table.HeaderCell>Requested {capitalize(t('tours'))}</Table.HeaderCell>
              <Table.HeaderCell>Expected {capitalize(t('move_ins'))}</Table.HeaderCell>
              <Table.HeaderCell>Actual {capitalize(t('move_ins'))}</Table.HeaderCell>
              <Table.HeaderCell>{capitalize(t('move_in'))} Trend</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {leadValueScores
              .filter((l) => l.community_name.toLowerCase().includes(communityNameFilter.toLowerCase().trim()))
              .map((l, i) => (
                <Table.Row textAlign="right" key={i}>
                  <Table.Cell textAlign="left">{l.community_name}</Table.Cell>
                  <Table.Cell width={3}>{l.avg_lvs?.toFixed(1)}</Table.Cell>
                  <Table.Cell>{formatCount(l.scheduled_tours)}</Table.Cell>
                  <Table.Cell>{l.expected_moveins?.toFixed(1)}</Table.Cell>
                  <Table.Cell>{formatCount(l.moveins)}</Table.Cell>
                  <Table.Cell>
                    <Icon name={l.movein_trend === 'flat' ? 'minus' : `chevron ${l.movein_trend}`} />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={8} textAlign="right">
                <CsvExport data={csvContent} headers={csvHeaders} filename="move_in_predictions.csv" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Wrapper>
    </PageSection>
  );
};

export default LeadValueScores;
