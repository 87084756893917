import React from 'react';
import { Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { ContactInfoTable } from './ContactInfoTable';
import { getArrayDifference } from '../../../utils/helpers';

export const ContactInfoForm = ({ communities, formRef, onSubmit, isTableFooter, tableHeaderOffset }) => {
  const initialValues = {
    communities: communities?.map((p) => ({
      id: p.id,
      name: p.name,
      sales_first_name: p.sales_first_name || '',
      lead_email: p.lead_email || '',
      other_lead_emails: p.other_lead_emails || [],
      sales_phones: p.sales_phones || [],
    })),
  };

  const validationSchema = Yup.object({
    communities: Yup.array().of(
      Yup.object({
        lead_email: Yup.string().email('Must be a valid email address'),
        other_lead_emails: Yup.array().of(Yup.string().email('Must contain valid email addresses')),
        sales_phones: Yup.array().of(Yup.string().max(20)),
        sales_first_name: Yup.string().nullable(),
      })
    ),
  });

  const onFormSubmit = ({ communities }) => {
    if (typeof onSubmit === 'function') {
      const changedCommunities = getArrayDifference(initialValues.communities, communities);
      onSubmit(
        changedCommunities.map((community) => ({
          id: community.id,
          lead_email: community.lead_email,
          other_lead_emails: community.other_lead_emails,
          sales_phones: community.sales_phones,
          sales_first_name: community.sales_first_name,
        }))
      );
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
      enableReinitialize
      validateOnChange={false}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <ContactInfoTable values={values} isTableFooter={isTableFooter} headerOffset={tableHeaderOffset} />
        </Form>
      )}
    </Formik>
  );
};
