import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';

import { Icon } from 'components/lib';
import { useCheckMobileScreen } from 'utils/hooks';
import { useNavigationContext } from './NavigationContext';
import { SubNavigationPortal } from './SubNavigationPortal';

export const NavigationItemText = styled.span`
  margin: 0 0 0 12px;
  font-family: var(--font-family);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  white-space: nowrap;
`;

const NavigationItemIcon = ({ name, variant }) => <Icon name={name} variant={variant} className="btn-icon" size="18" />;

export const NavigationItemLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 15px 12px 20px;
  position: relative;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  margin-top: -1px;
  height: 45px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
  text-transform: capitalize;
  transition: all 0.1s;

  .btn-icon {
    color: rgba(255, 255, 255, 0.6);
  }

  &:hover,
  &.active {
    background-color: rgba(255, 255, 255, 0.16);

    .btn-icon {
      color: rgba(255, 255, 255, 1);
    }
  }

  .chevron {
    flex: 1;
    text-align: right;
  }

  &.expandable.active {
    background-color: #0c214e;
  }
`;

const SubNavigationFold = styled.div`
  max-height: ${({ expanded }) => (expanded ? '1000px' : 0)};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
`;

const MobileNavigationItem = ({ to, iconName, iconVariant, label, subnavigation, children }) => {
  const history = useHistory();
  const match = useRouteMatch(to);
  const [expandSubnavigation, setExpandSubnavigation] = useState(!!match);
  const classNames = ['navlink'];
  if (match) {
    classNames.push('active');
  }
  if (subnavigation) {
    classNames.push('expandable');
  }

  useEffect(() => {
    if (!match) {
      setExpandSubnavigation(false);
    }
  }, [match]);

  const onClick = (e) => {
    e.stopPropagation();
    if (subnavigation) {
      setExpandSubnavigation(!expandSubnavigation);
    } else {
      history.push(to);
      document.body.style.position = 'relative';
    }
  };

  return (
    <>
      <NavigationItemLink role="link" className={classNames.join(' ')} onClick={onClick}>
        {iconName && <NavigationItemIcon name={iconName} variant={iconVariant} />}
        {label && <NavigationItemText>{label}</NavigationItemText>}
        {children}

        {subnavigation && (
          <Icon className="chevron" name={expandSubnavigation ? 'expand_more' : 'chevron_left'} size="22" />
        )}
      </NavigationItemLink>
      <SubNavigationFold expanded={expandSubnavigation}>{subnavigation}</SubNavigationFold>
    </>
  );
};

const DesktopNavigationItem = ({ to, iconName, iconVariant, label, subnavigation, children }) => {
  const match = useRouteMatch(to);
  const { setShowSubNavigation } = useNavigationContext();

  useEffect(() => {
    if (match?.path === to) {
      setShowSubNavigation(!!subnavigation);
    }
  }, [match, subnavigation]);

  return (
    <>
      <NavigationItemLink role="link" className={`navlink ${!!match ? 'active' : ''}`} as={Link} to={to}>
        {iconName && <NavigationItemIcon name={iconName} variant={iconVariant} />}
        {label && <NavigationItemText>{label}</NavigationItemText>}
        {children}
        {!!match && !!subnavigation && <SubNavigationPortal>{subnavigation}</SubNavigationPortal>}
      </NavigationItemLink>
    </>
  );
};

export const NavigationItem = (props) => {
  const isMobile = useCheckMobileScreen();
  const childProps = {
    iconName: props.icon?.split('-')[0],
    iconVariant: props.icon?.split('-')[1],
    ...props,
  };

  if (isMobile) {
    return <MobileNavigationItem {...childProps} />;
  }

  return <DesktopNavigationItem {...childProps} />;
};

NavigationItem.Text = NavigationItemText;
NavigationItem.Icon = NavigationItemIcon;
