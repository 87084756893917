import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { Button } from 'components/lib';
import { STATUSES } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';
import NoConditions from './NoConditions';
import AddConditionModal from './AddConditionModal';
import { CONNECTOR } from './constants';
import Condition from './Condition';
import AddConditionButton from './AddConditionButton';
import Preview from './Preview';

const ModalBody = styled.div``;

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 10px;
  max-height: calc(100vh - 340px);
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  padding: 0 11px;
`;

const GroupWrapper = styled.div`
  width: 477px;
  margin-bottom: 10px;
  margin-left: -11px;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px var(--line-standard);
  background-color: #fafafa;
`;

const ArrayField = styled.div`
  &:nth-last-child(2) {
    .or {
      display: none;
    }
  }
`;

const Or = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--salmon);
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const MAX_NUMBER_OF_CONDITIONS = 4;

const CustomLeadQualificationModal = ({
  isModalOpen,
  selectedItem,
  totalItemsCount,
  onSave,
  onClose,
  selectedItems,
  excludedItems,
  rules = [],
}) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const [isAddConditionModalOpen, setIsAddConditionModalOpen] = useState({ open: false, index: null });
  const { t } = useTranslation();

  const openAddConditionModal = (index) => {
    setIsAddConditionModalOpen({ open: true, index });
  };

  const closeAddConditionModal = () => {
    setIsAddConditionModalOpen(false);
  };

  const handleAddRule = (onAdd) => (value) => {
    closeAddConditionModal();
    onAdd(value);
  };

  const handleRemoveRule = (onRemove, index) => () => {
    onRemove(index);
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    await onSave(values);

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const handleAddConditionButtonClick = (values, setFieldValue) => {
    const newGroup = [...values.groups, { rules: [] }];
    const index = newGroup.length - 1;
    setFieldValue('groups', newGroup);
    openAddConditionModal(index);
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const filteredGroups = selectedItem?.leadQualificationRules?.groups?.filter((group) => group.rules.length > 0);

  const initialValues = {
    groups: filteredGroups?.length > 0 ? filteredGroups : [{ rules: [] }],
  };
  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue }) => (
        <SettingsModal
          loading={loading}
          open={isModalOpen}
          onModalClose={() => handleClose(resetForm)}
          size="tiny"
          width={540}
        >
          <SettingsModalHeader
            title={`Unqualified ${t('lead')} filter`}
            community={selectedItem || null}
            totalItemsCount={totalItemsCount}
            type="header"
          />
          <ModalBody type="body">
            <If condition={!values.groups.every((item) => item.rules.length === 0)}>
              <Preview values={values} selectedItems={selectedItems} excludedItems={excludedItems} />
            </If>
            <BodyText>
              <If condition={values.groups.every((item) => item.rules.length === 0)}>
                <NoConditions onClick={() => openAddConditionModal(0)} />
              </If>
              {values?.groups?.map((rule, index) => (
                <FieldArray name={`groups[${index}].rules`}>
                  {({ remove, push }) => (
                    <ArrayField key={index}>
                      <If condition={rule.rules.length > 0}>
                        <GroupWrapper key={index}>
                          {rule.rules.map((o, i) => {
                            const specificRule = rules.find((r) => r.id === o.ruleId);
                            return (
                              <Condition
                                preview
                                rule={specificRule}
                                selectedRule={o}
                                onRemove={handleRemoveRule(remove, i)}
                              />
                            );
                          })}
                          <If condition={rule.rules.length < MAX_NUMBER_OF_CONDITIONS}>
                            <AddConditionButton
                              type={CONNECTOR.AND}
                              text="Add AND condition"
                              onClick={() => openAddConditionModal(index)}
                            />
                          </If>
                        </GroupWrapper>
                        <If condition={values.groups.filter((group) => group.rules.length >= 1).length > 1}>
                          <Or className="or">{CONNECTOR.OR}</Or>
                        </If>
                      </If>
                      <If condition={isAddConditionModalOpen.open && isAddConditionModalOpen.index === index}>
                        <AddConditionModal
                          isModalOpen
                          onClose={closeAddConditionModal}
                          onChange={handleAddRule(push)}
                          rules={rules}
                          currentRules={values.groups[index].rules}
                        />
                      </If>
                    </ArrayField>
                  )}
                </FieldArray>
              ))}
              <If condition={!values.groups.every((item) => item.rules.length === 0)}>
                <AddConditionButton
                  type={CONNECTOR.OR}
                  text="Add OR condition"
                  onClick={() => handleAddConditionButtonClick(values, setFieldValue)}
                />
              </If>
            </BodyText>
          </ModalBody>
          <SettingsModalActions
            onSave={handleSubmit}
            onCancel={() => handleClose(resetForm)}
            type="actions"
            disabled={!dirty || !touched}
            saveText="Save"
          >
            <If condition={selectedItems.length > 1}>
              <Button primary color="blue" onClick={handleSubmit} type="button">
                Reset all
              </Button>
            </If>
          </SettingsModalActions>
        </SettingsModal>
      )}
    </Formik>
  );
};

export default CustomLeadQualificationModal;
