import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { Form, Radio } from 'components/lib';
import { STATUSES, SETTING_STATE_ON_OFF } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';

const ModalBody = styled.div``;

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 10px;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const RadioWrapperItem = styled.div`
  margin-left: 30px;
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:first-child) {
    margin-left: 30px;
  }
  > span {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 6px;
  }
`;

export const TranscriptsModal = ({ isModalOpen, selectedItem, flagName, totalItemsCount, onSave, onClose }) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    const value = !values[flagName] ? null : values[flagName];

    await onSave({ [flagName]: value });

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const initialValues = { [flagName]: String(selectedItem?.[flagName]) };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue }) => (
        <SettingsModal
          loading={loading}
          open={isModalOpen}
          onModalClose={() => handleClose(resetForm)}
          size="tiny"
          width={540}
        >
          <SettingsModalHeader
            title="Transcripts"
            community={selectedItem || null}
            totalItemsCount={totalItemsCount}
            type="header"
          />
          <ModalBody type="body">
            <BodyText>
              Turn phone call transcripts on or off. If on, you will be able to read transcripts on the lead
              conversation screen.
            </BodyText>
            <RadioWrapper>
              <CustomRadio>
                <Form.Field
                  control={Radio}
                  name={flagName}
                  value={String(SETTING_STATE_ON_OFF.ON.value)}
                  checked={String(values[flagName]) === String(SETTING_STATE_ON_OFF.ON.value)}
                  onChange={() => setFieldValue(flagName, String(SETTING_STATE_ON_OFF.ON.value))}
                />
                <span>{SETTING_STATE_ON_OFF.ON.text}</span>
              </CustomRadio>
              <CustomRadio>
                <Form.Field
                  control={Radio}
                  name={flagName}
                  value={String(SETTING_STATE_ON_OFF.OFF.value)}
                  checked={String(values[flagName]) === String(SETTING_STATE_ON_OFF.OFF.value)}
                  onChange={() => setFieldValue(flagName, String(SETTING_STATE_ON_OFF.OFF.value))}
                />
                <span>{SETTING_STATE_ON_OFF.OFF.text}</span>
              </CustomRadio>
            </RadioWrapper>
          </ModalBody>
          <SettingsModalActions
            onSave={handleSubmit}
            onCancel={() => handleClose(resetForm)}
            type="actions"
            disabled={!dirty || !touched}
            saveText="Save"
          />
        </SettingsModal>
      )}
    </Formik>
  );
};
