import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Logo } from '../../Logo';
import { Testimonials } from './Testimonials';
import { LetsTalk } from './LetsTalk';
import backgsplash from '../../../assets/images/auth-backsplash.jpg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  width: 35%;
  height: 100%;
  background-image: url(${backgsplash});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: -300px;
  background-position-y: center;
  background-size: cover;
  overflow: hidden;
  color: white;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
  }

  * {
    position: relative;
  }

  & > * {
    flex: 1;
  }

  svg#further-logo {
    width: 9rem;
  }
`;

export const Sidebar = () => (
  <Wrapper>
    <Link to="/login">
      <Logo />
    </Link>
    <LetsTalk />
    <Testimonials />
  </Wrapper>
);
