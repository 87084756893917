import React, { useContext } from 'react';

import { OnboardingContext } from '.';

export const Steps = () => {
  const { currentStep } = useContext(OnboardingContext);

  if (!currentStep) {
    return null;
  }

  return <currentStep.component />;
};
