import styled from 'styled-components';

export const ProfilePicture = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  font-size: ${({ size }) => `${size}px`};
  border-radius: 50%;
  transition: opacity 0.3s ease;
  background-color: ${({ colors }) => colors.background};
  border: 1px solid ${({ colors, image }) => (image ? 'tarnsparent' : colors.border)};
  background-image: ${({ image }) => `url('${image}')`};
  color: ${({ colors }) => colors.border};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  ::before {
    content: ${({ initials, image }) => `'${image ? '' : initials}'`};
    text-transform: uppercase;
    font-family: AvenirLTPro-Medium;
    font-size: ${({ initials }) => (initials.length > 1 ? '10px' : '12px')};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: ${({ initials }) => (initials.length > 1 ? '1' : '12px')};
    letter-spacing: normal;
    text-align: center;
    padding-top: ${({ initials }) => (initials.length > 1 ? '1px' : '2px')};
  }
`;
