import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Message, Popup, Table } from 'components/lib';
import { useContactRules } from './ContactRuleContext';
import ContactRuleCommunityList from './ContactRuleCommunityList';

const Clickable = styled.div`
  cursor: pointer;
  margin: 0;
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--link);

  :hover {
    text-decoration: underline;
  }
`;

const ContactRuleList = () => {
  const { t } = useTranslation();
  const { contactRules, totalPages, filters, handlePageChange, handleOpenModal } = useContactRules();
  const { page } = filters;

  if (!contactRules) {
    return null;
  }

  if (contactRules.length === 0) {
    return <Message info icon="search" content="No contact rules found" />;
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Match</Table.HeaderCell>
          <Table.HeaderCell>Condition</Table.HeaderCell>
          <Table.HeaderCell>Value</Table.HeaderCell>
          <Table.HeaderCell>Emails</Table.HeaderCell>
          <Table.HeaderCell>Communities</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {contactRules.map((row, index) => (
          <Table.Row key={index}>
            <Table.Cell>
              <Clickable onClick={() => handleOpenModal({ contactRuleId: row.id })}>{row.name}</Clickable>
            </Table.Cell>
            <Table.Cell>{row.matchName}</Table.Cell>
            <Table.Cell>{row.conditionName}</Table.Cell>
            <Table.Cell>{row.value}</Table.Cell>
            <Table.Cell width={6}>{row.emails.join(', ')}</Table.Cell>
            <Table.Cell>
              <Popup
                content={<ContactRuleCommunityList contactRuleId={row.id} communitiesIds={row.communities} />}
                trigger={
                  <span>
                    {row.communities.length} {t('communities')} selected
                  </span>
                }
                hoverable
                position="bottom right"
                hideOnScroll
                disabled={row.communities.length === 0}
                positionFixed
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan={6}>
            {totalPages > 1 && <Table.Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />}
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default ContactRuleList;
