import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { setSalesAssistantCommunityNotation, resetLeadConversationDetailsCache } from 'apis';
import { Button, TextArea } from 'components/lib';
import { ConversationContext } from '../ConversationContext';
import { InputField } from '../../common';
import { STATUSES } from '../../../constants';

const StyledInputField = styled(InputField)`
  &&&& {
    textarea {
      border-color: var(--line-standard);
      background-color: var(--input-field-fill);
      padding: 10px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: end;
`;

export const CommunityNotesEditPanel = ({ onAction }) => {
  const { selectedConversation, setSelectedConversation, conversationCacheStatus, setConversationCacheStatus } =
    useContext(ConversationContext);

  const onSave = async ({ notes }) => {
    try {
      setConversationCacheStatus(STATUSES.LOADING);

      const response = await setSalesAssistantCommunityNotation(selectedConversation.community.id, { notes });
      await resetLeadConversationDetailsCache(selectedConversation.id);

      setSelectedConversation({
        ...selectedConversation,
        ...{ community: { ...selectedConversation.community, ...{ sales_assistant_community_notes: response.notes } } },
      });

      toast.success('Messaging Assistant Notes updated.');
      setConversationCacheStatus(STATUSES.LOADED);
    } catch (error) {
      toast.error('Trouble saving Messaging Assistant Notes!');
      setConversationCacheStatus(STATUSES.FAILURE);
      console.error(error.message);
    }

    onAction();
  };

  const conversationCacheReloading = conversationCacheStatus === STATUSES.LOADING;
  const initialValues = {
    notes: selectedConversation.community.sales_assistant_community_notes,
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onSave}>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <StyledInputField
            fluid="true"
            control={TextArea}
            rows={16}
            name="notes"
            disabled={conversationCacheReloading}
            placeholder="Messaging Assistant Notes..."
          />
          <Actions>
            <Button primary color="blue" content="Save" loading={conversationCacheReloading} onClick={handleSubmit} />
            <Button basic content="Cancel" disabled={conversationCacheReloading} onClick={onAction} />
          </Actions>
        </Form>
      )}
    </Formik>
  );
};
