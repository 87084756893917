import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Header } from 'semantic-ui-react';

export const PAGE_HEADER_MARGIN = {
  NONE: '0rem',
  SMALL: '1rem',
  MEDIUM: '2rem',
  LARGE: '4rem',
};

export const PAGE_HEADER_PADDING = {
  NONE: '0rem',
  DEFAULT: '.8rem 1rem',
  VERTICAL: '.8rem 0rem',
  HORIZONTAL: '0rem 1rem',
};

const Wrapper = styled.div`
  margin-bottom: ${(props) => props.margin};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.padding};

  &.block {
    background-color: #f3f4f5;
    border: 1px solid #d4d4d5;
    border-radius: 0.28571429rem;
  }

  .ui.header {
    margin-bottom: 0rem !important;

    .sub.header {
      line-height: 1.5rem;
    }
  }

  .ui.labeled.icon.button,
  .ui.labeled.icon.buttons .button {
    padding: 0.9rem;
    font-size: 1rem;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 1ch;
`;

export const PageHeader = ({
  as = 'h3',
  title,
  subtitle,
  actions,
  margin = PAGE_HEADER_MARGIN.LARGE,
  padding = PAGE_HEADER_PADDING.DEFAULT,
  block,
}) => (
  <Wrapper className={classNames({ block })} margin={margin} padding={padding}>
    <Header as={as}>
      <Header.Content>
        {title}
        <Header.Subheader>{subtitle}</Header.Subheader>
      </Header.Content>
    </Header>
    <Actions>{actions}</Actions>
  </Wrapper>
);

PageHeader.MARGIN = PAGE_HEADER_MARGIN;
PageHeader.PADDING = PAGE_HEADER_PADDING;
