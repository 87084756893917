import React from 'react';
import styled from 'styled-components';
import { Icon, Step } from 'semantic-ui-react';

import { Healthcare } from 'components/Icons';
import { Content } from 'components/common';
import { UNIT_LEVEL_PRICING_STEPS, useUnitLevelPricing } from './context';
import CareTypesStep from './CareTypesStep';
import { MEDIA_BREAK_POINTS } from '../../constants';
import LivingAccommodationStep from './LivingAccommodationStep';
import PriceStep from './PriceStep';
import AvailabilityStep from './AvailabilityStep';
import GettingStartedStep from './GettingStartedStep';
import SummaryStep from './SummaryStep';
import CompletedStep from './CompletedStep';

const ContentBox = styled(Content)`
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    padding: 2rem 5rem;
  }
`;

const StepperBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const UnitLevelPricingStepper = () => {
  const [{ activeStep }] = useUnitLevelPricing();

  const STEPS = [
    {
      id: UNIT_LEVEL_PRICING_STEPS.GETTING_STARTED,
      title: 'Getting started',
      description: 'Finding why',
      icon:
        activeStep === UNIT_LEVEL_PRICING_STEPS.GETTING_STARTED ? (
          <Healthcare
            fill="rgba(0,0,0,.85)"
            stroke="rgba(0,0,0,.85)"
            width="2.5rem"
            height="2.5rem"
            style={{ marginRight: '1rem' }}
          />
        ) : (
          <Icon />
        ),
    },
    {
      id: UNIT_LEVEL_PRICING_STEPS.CARE_TYPES,
      title: 'Care Types',
      description: 'Define care types',
      icon: 'building',
    },
    {
      id: UNIT_LEVEL_PRICING_STEPS.LIVING_ACCOMMODATION,
      title: 'Living Accommodation',
      description: 'Define unit details',
      icon: 'bed',
    },
    {
      id: UNIT_LEVEL_PRICING_STEPS.PRICE,
      title: 'Price',
      description: 'Define monthly prices',
      icon: 'dollar',
    },
    {
      id: UNIT_LEVEL_PRICING_STEPS.AVAILABILITY,
      title: 'Availability',
      description: 'Define units availability',
      icon: 'book',
    },
    {
      id: UNIT_LEVEL_PRICING_STEPS.SUMMARY,
      title: 'Summary',
      description: 'Confirm your data',
      icon: 'file alternate outline',
    },
  ];

  const stepComponents = {
    [UNIT_LEVEL_PRICING_STEPS.GETTING_STARTED]: <GettingStartedStep />,
    [UNIT_LEVEL_PRICING_STEPS.CARE_TYPES]: <CareTypesStep />,
    [UNIT_LEVEL_PRICING_STEPS.LIVING_ACCOMMODATION]: <LivingAccommodationStep />,
    [UNIT_LEVEL_PRICING_STEPS.PRICE]: <PriceStep />,
    [UNIT_LEVEL_PRICING_STEPS.AVAILABILITY]: <AvailabilityStep />,
    [UNIT_LEVEL_PRICING_STEPS.SUMMARY]: <SummaryStep />,
    [UNIT_LEVEL_PRICING_STEPS.COMPLETED]: <CompletedStep />,
  };

  return (
    <>
      <StepperBox>
        <Step.Group widths={STEPS.length} size="tiny">
          {STEPS.map((step) => (
            <Step
              key={step?.id}
              id={step?.id}
              active={activeStep === step?.id}
              completed={step?.id < activeStep}
              disabled={step?.id > activeStep}
              title={step?.title}
              description={step?.description}
              icon={step?.icon}
            />
          ))}
        </Step.Group>
      </StepperBox>
      <ContentBox>{stepComponents[activeStep]}</ContentBox>
    </>
  );
};

export default UnitLevelPricingStepper;
