import React from 'react';

import { Dropdown } from 'components/lib';

const MessagingViewOptions = ({
  conversation,
  onUnarchiveConversation,
  onArchiveConversation,
  onMarkAsRead,
  onMarkAsUnread,
}) => {
  const { messages_archived, has_unread } = conversation;

  return (
    <>
      {messages_archived ? (
        <Dropdown.Item onClick={onUnarchiveConversation}>Unarchive</Dropdown.Item>
      ) : (
        <Dropdown.Item onClick={onArchiveConversation}>Archive</Dropdown.Item>
      )}
      {has_unread ? (
        <Dropdown.Item onClick={onMarkAsRead}>Mark As Read</Dropdown.Item>
      ) : (
        <Dropdown.Item onClick={onMarkAsUnread}>Mark As Unread</Dropdown.Item>
      )}
    </>
  );
};

export default MessagingViewOptions;
