import React from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div.attrs(({ $width, $height }) => ({
  style: {
    width: $width,
    height: $height,
  },
}))`
  width: 240px;
  height: 5px;
  border-radius: 5px;
  background-color: #eaeaeb;
`;

const Fill = styled.div.attrs(({ $width, $background }) => ({
  style: {
    width: `${$width}%`,
    background: $background,
  },
}))`
  transition: width 0.5s ease-in-out;
  height: inherit;
  border-radius: 5px;
`;

export const ProgressBar = ({
  progress,
  width = '240px',
  height = '5px',
  color = 'linear-gradient(to right, #275aff, #27b4ff)',
}) => {
  return (
    <ProgressBarContainer $width={width} $height={height}>
      <Fill $width={progress} $background={color} />
    </ProgressBarContainer>
  );
};
