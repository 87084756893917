import React from 'react';
import { useField } from 'formik';
import { Checkbox, Grid, Table, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { capitalize } from 'utils/helpers';

export const TeaserCommunitiesField = ({ chatProperties, ...props }) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(props);
  const propertyTeaserPlacements = chatProperties.map((p) => ({
    id: p.id,
    community_name: p.community_name,
    enabled: field.value.some((cp) => cp.id === p.id),
  }));
  const { touched, error } = meta;

  const onToggle = (toggled) => {
    helpers.setValue(
      propertyTeaserPlacements
        .filter((ptp) => (ptp.id === toggled.id ? !ptp.enabled : ptp.enabled))
        .map((ptp) => ({ id: ptp.id }))
    );
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          {touched && error && <Message color="red" header={error} />}

          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{capitalize(t('community'))} Name</Table.HeaderCell>
                <Table.HeaderCell>Enabled</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {propertyTeaserPlacements
                ?.sort((a, b) => a.community_name < b.community_name)
                .map((p, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{p.community_name}</Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        toggle
                        checked={p.enabled}
                        onChange={() => {
                          onToggle(p);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
