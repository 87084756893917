import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { If } from 'components/If';
import { getMessagingRelatedCommunities } from 'apis';
import { capitalize } from 'utils/helpers';
import { CampaignModal, CampaignModalActions, RelatedCommunities } from './index';
import { useCampaigns } from '../CampaignsContext';

const ModalHeader = styled.div`
  line-height: 28px;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-family: var(--font-family-bold);
`;

const ModalSubtitle = styled.div`
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 14px;
  color: #89919d;
  margin-top: 8px;
`;

const BodyWrapper = styled.div`
  width: 100%;
`;

export const CampaignCommunitiesModal = ({
  campaignId,
  open,
  onClose,
  relatedCommunitiesCount,
  communitiesCount,
  isAllCommunities,
}) => {
  const {
    state: { relatedCommunities },
    dispatch,
    actions,
  } = useCampaigns();
  const { t } = useTranslation();

  const fetchRelatedCommunities = async () => {
    const isInRelatedCommunities = relatedCommunities?.find((community) => community?.campaignId === campaignId);
    if (isInRelatedCommunities) {
      return;
    }
    try {
      dispatch({ type: actions.SET_RELATED_COMMUNITIES_LOADING, data: true });
      const communities = await getMessagingRelatedCommunities(campaignId);
      dispatch({ type: actions.SET_RELATED_COMMUNITIES, data: { communities: communities?.results, campaignId } });
      dispatch({ type: actions.SET_RELATED_COMMUNITIES_LOADING, data: false });
    } catch (e) {
      console.error(e);
      toast.error('Error loading related communities.');
      dispatch({ type: actions.SET_RELATED_COMMUNITIES_LOADING, data: false });
    }
  };

  useEffect(() => {
    fetchRelatedCommunities();
  }, []);

  const campaign = relatedCommunities?.find((community) => community?.campaignId === campaignId);
  return (
    <CampaignModal open={open} onModalClose={onClose} size="tiny" width={570} height={380}>
      <ModalHeader type="header">
        <ModalTitle>{capitalize(t('communities'))} in This Campaign</ModalTitle>
        <ModalSubtitle>
          <If condition={relatedCommunitiesCount > 0 && !isAllCommunities}>
            {relatedCommunitiesCount}/{communitiesCount}
          </If>
          <If condition={isAllCommunities}>All {t('communities')}</If>
        </ModalSubtitle>
      </ModalHeader>
      <BodyWrapper type="body">
        <RelatedCommunities campaignId={campaignId} relatedCommunities={relatedCommunities} campaign={campaign} />
      </BodyWrapper>
      <CampaignModalActions onCancel={onClose} type="actions" cancelText="Close" hideUpdate />
    </CampaignModal>
  );
};
