import React, { useContext } from 'react';
import styled from 'styled-components';
import { DimmerDimmable, DimmerInner, Loader } from 'semantic-ui-react';

import { CommunityCareTypesList } from './CommunityCareTypesList';
import { CareTypesContext } from './CareTypesContext';
import { CommunityCareTypesAssign } from './CommunityCareTypesAssign';
import { STATUSES } from '../../../constants';

const CareTypesForm = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const CommunityCareTypesForm = () => {
  const { status } = useContext(CareTypesContext);

  return (
    <CareTypesForm>
      <DimmerDimmable>
        <DimmerInner active={status === STATUSES.LOADING} inverted>
          <Loader active />
        </DimmerInner>
        <CommunityCareTypesAssign />
        <CommunityCareTypesList />
      </DimmerDimmable>
    </CareTypesForm>
  );
};
