import React from 'react';
import styled from 'styled-components';

import { getChangeIcon } from 'components/common';
import { Loader } from 'components/lib';
import { formatCount } from 'components/utils';

const PreviusPeriodStats = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .stat {
    font-size: 1rem;
    text-align: center;

    .value {
      font-size: 1.5rem;
    }
  }
`;

const NO_CHANGES = <div className="value">=</div>;

const PrevStat = ({ label, value, prevValue, lessIsBetter = false, formatter = formatCount }) => {
  return (
    <div className="stat">
      {getChangeIcon(value, prevValue, lessIsBetter, formatter, 'value', NO_CHANGES) || <Loader active size="small" />}
      <div className="label">{label}</div>
    </div>
  );
};

const PrevStats = ({ data }) => {
  return <PreviusPeriodStats>{data && data.map((d) => <PrevStat key={d.label} {...d} />)}</PreviusPeriodStats>;
};

export default PrevStats;
