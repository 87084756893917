export const CAMPAIGN_BUILD_TYPES = {
  AI: 'ai',
  TEMPLATE: 'TEMPLATE',
};

export const MESSAGE_TYPE = [
  {
    label: 'SMS',
    value: 'sms',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'SMS and Email',
    value: 'emailsms',
  },
];

export const BUILD_REQUEST_STATUS = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  FAILED: 'failed',
};
