import React from 'react';
import styled from 'styled-components';

import { formatAnyDate } from 'components/utils';

const Container = styled.div`
  display: flex;
  align-self: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
`;

const Text = styled.span`
  margin: 0 10px 0 10px;
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 1.38;
  color: var(--light-text);
  white-space: pre-wrap;
`;

export const EventMessage = ({ text, user, timestamp, additional_data }) => {
  let eventText = text[0].toLowerCase() + text.substring(1);

  eventText = `${user?.first_name} ${user?.last_name} ${eventText}`;

  if (additional_data?.traffic_source) {
    eventText += ` (via ${additional_data.traffic_source})`;
  }

  return (
    <Container>
      <Content>
        <Text>{eventText}</Text>
        <Text>{formatAnyDate(timestamp, 'h:mm a')}</Text>
      </Content>
    </Container>
  );
};
