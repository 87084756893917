import React, { useState } from 'react';
import { Message, Pagination, Table } from 'semantic-ui-react';

import InstantResponsesTableRow from './InstantResponsesTableRow';

const InstantResponsesTable = ({
  messages,
  activePage,
  totalPages,
  selectedMessages,
  onPageChange,
  onToggleArchive,
  onSelectedMessagesChange,
}) => {
  const selectable = onSelectedMessagesChange !== undefined;

  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  const handleToggleArchive = (campaignMessage) => () => {
    const { lead_id, is_further_archived } = campaignMessage;

    onToggleArchive(lead_id, { is_further_archived: !is_further_archived });
  };

  const handleToggleSelected = (campaignMessage) => () => {
    const { lead_id } = campaignMessage;

    if (selectedMessages.includes(lead_id)) {
      onSelectedMessagesChange(selectedMessages.filter((id) => id !== lead_id));
    } else {
      onSelectedMessagesChange([...selectedMessages, lead_id]);
    }
  };

  if (messages.length === 0) {
    return <Message icon="mail" content="Messages not found" />;
  }

  return (
    <Table selectable>
      <Table.Header>
        <Table.Row>
          {selectable && <Table.HeaderCell />}
          <Table.HeaderCell>Lead</Table.HeaderCell>
          <Table.HeaderCell>Last Message</Table.HeaderCell>
          <Table.HeaderCell>Message Date</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Replied by Sales</Table.HeaderCell>
          <Table.HeaderCell>Community</Table.HeaderCell>
          <Table.HeaderCell>Community Submitted</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {messages.map((cm) => (
          <InstantResponsesTableRow
            key={cm.lead_id}
            message={cm}
            selectable={selectable}
            selected={selectedMessages?.includes(cm.lead_id)}
            onToggleArchive={handleToggleArchive}
            onToggleSelected={handleToggleSelected}
          />
        ))}
      </Table.Body>
      {totalPages > 1 && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="6" textAlign="left">
              <Pagination activePage={activePage} onPageChange={handlePageChange} totalPages={totalPages} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
};

export default InstantResponsesTable;
