import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Divider } from 'components/lib';
import LoginMagicLinkForm from './LoginMagicLinkForm';
import { AuthContext } from '../context';
import { AuthLayout } from '../AuthLayout';

export const LoginMagicLink = () => {
  const history = useHistory();
  const { email, login, requestMagicLink, loginLoading, magicLinkStatus } = useContext(AuthContext);

  useEffect(() => {
    const requestLink = async () => {
      await requestMagicLink();
    };

    if (!email) {
      history.replace('/login');
    } else {
      requestLink();
    }
  }, []);

  const handleMagicLinkLogin = async (loginCode) => {
    await login({ email, login_code: loginCode });
  };

  return (
    <AuthLayout>
      <h1>Check your email</h1>
      <p>Enter the temporary code from the email we sent you, or simply click on the `Log In` button in the email.</p>
      <Divider horizontal />

      <LoginMagicLinkForm
        loginLoading={loginLoading}
        magicLinkStatus={magicLinkStatus}
        requestMagicLink={requestMagicLink}
        onSubmit={handleMagicLinkLogin}
      />
    </AuthLayout>
  );
};
