import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'components/lib';
import { ReactComponent as Likelihoodwheel } from 'assets/images/likelihoodwheel.svg';
import { capitalize } from 'utils/helpers';
import { MoveinLikelihoodIndicator } from './MoveinLikelihoodIndicator';

const ModalHeader = styled(Modal.Content)`
  &&&& {
    padding: 30px 30px 10px 30px;
    font-family: var(--font-family-bold);
    font-size: 20px;
    line-height: normal;
    color: var(--text);
  }
`;

const Subtitle = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--text);
  padding: 10px 37px 20px 30px;
  line-height: normal;
`;

const ModalContent = styled.div`
  padding: 0 30px;
  display: flex;
  padding-bottom: 48px;
`;

const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
`;

const Right = styled.div`
  width: 50%;
  h3 {
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: normal;
    color: var(--text);
    margin: 0 0 10px 0;
  }
  p {
    margin-bottom: 30px;
    font-family: var(--font-family);
    font-size: 12px;
    line-height: normal;
    color: #89919d;
    span {
      color: #ff5b98;
    }
  }
`;
export const MoveInLikelihoodModal = ({ openModal, setOpenModal }) => {
  const { t } = useTranslation();

  if (!openModal) return null;

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Modal size="small" medium closeIcon open={openModal} setOpen={setOpenModal} onClose={handleClose}>
      <ModalHeader>Further’s {capitalize(t('move_in'))} Prediction</ModalHeader>
      <Subtitle>
        We have analyzed the behavior of millions of website visitors and developed a method to score a {t('lead')} on
        their potential to move into a senior living {t('community')}. This prediction is based on their online behavior
        and will help you focus on the highest quality {t('leads')}.
      </Subtitle>
      <ModalContent>
        <Left>
          <Likelihoodwheel />
          <MoveinLikelihoodIndicator />
        </Left>
        <Right>
          <h3>We use AI to generate predictions</h3>
          <p>
            This is an AI-based digital behavior model, which can differ from actual behavior. Circumstances{' '}
            <span>may prevent</span> a highly likely {t('move_in')}, or a less likely {t('move_in')}{' '}
            <span>may still happen</span>.
          </p>
          <h3>Results may change over time</h3>
          <p>
            We constantly analyze the latest interaction data across many of our {t('leads')}, which means that this may
            change over time.
          </p>
        </Right>
      </ModalContent>
      <Modal.Actions>
        <Button basic onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
