import React from 'react';
import { QueryClientProvider } from 'react-query';
import queryClient from 'utils/queries/clients';
import { GlobalStyleOverrides } from './GlobalStyleOverrides';
import { UserContextProvider } from './UserContext';
import { ThemeContextProvider } from './ThemeContext';
import { NavigationContextProvider } from './navigation';
import ContactCenterContextProvider from './contact-center/ContactCenterContext';

export const GlobalContextProviders = ({ children }) => {
  return (
    <ThemeContextProvider>
      <GlobalStyleOverrides>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <NavigationContextProvider>
              <ContactCenterContextProvider>
                <>{children}</>
              </ContactCenterContextProvider>
            </NavigationContextProvider>
          </UserContextProvider>
        </QueryClientProvider>
      </GlobalStyleOverrides>
    </ThemeContextProvider>
  );
};
