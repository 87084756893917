import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TabsWithNavigation } from 'components/lib';
import EmailPhoneRequirements from '../EmailPhoneRequirements';
import WebAssistantURL from '../WebAssistantURL';
import CustomPhoneInputWarningText from '../CustomPhoneInputWarningText';
import MinimizeVSA from '../MinimizeVSA';
import VSABanner from '../VSABanner';

const PHONE_ASSISTANT_TABS = [
  {
    tabName: 'Email & Phone Requirements',
    path: '/advanced/web-assistant/email-phone-requirements',
    Content: EmailPhoneRequirements,
  },
  {
    tabName: 'URLs',
    path: '/advanced/web-assistant/urls',
    Content: WebAssistantURL,
  },
  {
    tabName: 'Phone Input Warning',
    path: '/advanced/web-assistant/phone-input-warning',
    Content: CustomPhoneInputWarningText,
  },
  {
    tabName: 'VSA Minimizing on Mobile',
    path: '/advanced/web-assistant/vsa-minimizing-on-mobile',
    Content: MinimizeVSA,
  },
  {
    tabName: 'VSA Banner',
    path: '/advanced/web-assistant/vsa-banner',
    Content: VSABanner,
  },
];

const INITIAL_TAB = PHONE_ASSISTANT_TABS[0].path;

export const WebAssistantRoot = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/advanced/web-assistant') {
      history.replace(INITIAL_TAB);
    }
  }, []);

  return <TabsWithNavigation tabs={PHONE_ASSISTANT_TABS} />;
};
