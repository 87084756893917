import React from 'react';
import { Message, Pagination, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import ScheduledMessageListRow from './ScheduledMessageListRow';

const MessagesCountText = styled.div`
  margin: 1rem 0;
  color: rgba(0, 0, 0, 0.6);
`;

const ScheduledMessageList = ({ messages, count, page, totalPages, onPageChange }) => {
  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  if (!messages) {
    return null;
  }

  if (messages.length === 0) {
    return <Message info>No scheduled messages</Message>;
  }

  return (
    <>
      <MessagesCountText>Found total {count} scheduled messages</MessagesCountText>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Lead</Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.HeaderCell>Community</Table.HeaderCell>
            <Table.HeaderCell>Scheduled</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Remove first name from template</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {messages.map((m) => (
            <ScheduledMessageListRow key={m.id} message={m} />
          ))}
        </Table.Body>
        {totalPages > 1 && (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="6" textAlign="center">
                <Pagination activePage={page} onPageChange={handlePageChange} totalPages={totalPages} />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </>
  );
};

export default ScheduledMessageList;
