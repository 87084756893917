import React from 'react';
import styled from 'styled-components';
import { Placeholder } from 'semantic-ui-react';

import { If } from 'components/If';

const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--line-standard);
  padding: 22px 20px 23px 34px;
  &:last-of-type {
    border-bottom: 1px solid var(--line-standard);
  }
`;

const LINE_LENGTH = ['full', 'very long', 'long', 'medium', 'short', 'very short'];

function getRandomLineLength() {
  return LINE_LENGTH[Math.floor(Math.random() * 5)];
}

export const ConversationsListPlaceholder = ({ length = 8, loading = true }) => (
  <If condition={loading}>
    {Array.from({ length }).map((_, index) => (
      <PlaceholderContainer key={index}>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line length={getRandomLineLength()} />
            <Placeholder.Line length={getRandomLineLength()} />
          </Placeholder.Header>
        </Placeholder>
      </PlaceholderContainer>
    ))}
  </If>
);
