import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Icon } from 'components/lib';

const Wrapper = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    margin-bottom: 4px;
  }
`;

const Subtitle = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #89919d;
`;

const ConnectedCalendarsNoUsers = () => {
  return (
    <Wrapper>
      <Title>
        <Icon name="account_box" color="#d7d7d7" className="icon" />
        <span>There are no users to sync.</span>
      </Title>
      <Subtitle>
        You can add users to your account on the{' '}
        <Link to="/settings/user-accounts/new" target="_blank">
          Users
        </Link>{' '}
        page.
      </Subtitle>
    </Wrapper>
  );
};

export default ConnectedCalendarsNoUsers;
