import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Dimmer, Divider, Loader } from 'semantic-ui-react';

import { getDefaultStyles, getEmailTemplates } from 'apis';
import { PageHeader } from 'components/common';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import SearchInput from 'components/SearchInput';
import { Button } from 'components/lib';
import { SettingsDropdown } from '../advanced/common/SettingsDropdown';
import { STATUSES } from '../../constants';
import List from './List';
import EditTemplate from './EditTemplate';
import NewTemplate from './NewTemplate';

const FilterSearch = styled.div`
  width: 400px;
`;

const FiltersBox = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-between;
`;

function simpleSearch(query, dataArray, status = null) {
  const matched = [];
  if (!dataArray) dataArray = {};
  if (!query) query = '';
  if (status !== null) {
    dataArray = dataArray.filter((obj) => obj.isPublished === status);
  }

  dataArray.forEach((obj) => {
    for (const key in obj) {
      if (!obj[key] || !obj[key]) continue;
      if (obj[key].toString().toLowerCase().indexOf(query.toLowerCase()) !== -1 && !matched.includes(obj)) {
        matched.push(obj);
      }
    }
  });
  return matched;
}

const dropdownOptions = [
  {
    key: 'all',
    text: 'Any status',
    value: null,
  },
  {
    key: 'published',
    text: 'Published',
    value: true,
  },
  {
    key: 'drafts',
    text: 'Drafts',
    value: false,
  },
];

export function EmailTemplates() {
  const [loader, setLoader] = useState(STATUSES.IDLE);
  const [defaultStylesLoading, setDefaultStylesLoading] = useState(STATUSES.IDLE);
  const [error, setError] = useState();
  const [results, setResults] = useState([]);
  const [filter, setFilter] = useState('');
  const [filterStatus, setFilterStatus] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [defaultStyles, setDefaultStyles] = useState('');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoader(STATUSES.LOADING);

        const results = await getEmailTemplates();
        setResults(results);
        setFilteredResults(results);

        setLoader(STATUSES.LOADED);
      } catch (e) {
        setResults([]);
        setError('Unable to fetch email templates');
        setLoader(STATUSES.FAILURE);
      }
    };

    const fetchDefaultStyles = async () => {
      try {
        setDefaultStylesLoading(STATUSES.LOADING);

        const results = await getDefaultStyles();
        setDefaultStyles(results);

        setDefaultStylesLoading(STATUSES.LOADED);
      } catch (e) {
        setResults([]);
        setError('Unable to fetch default styles');
        setDefaultStylesLoading(STATUSES.FAILURE);
      }
    };

    if (location.pathname === '/admin/email-templates') {
      fetchTemplates();
      fetchDefaultStyles();
    }
  }, [location]);

  const handleStatusChange = useCallback(
    (_, selected) => {
      setFilterStatus(selected.value);
      setFilteredResults(simpleSearch(filter, results, selected.value));
    },
    [results, filter]
  );

  const handleSearch = useCallback(
    (value) => {
      setFilter(value);
      setFilteredResults(simpleSearch(value, results, filterStatus));
    },
    [results, filterStatus]
  );

  const handleEditTemplate = useCallback(
    (template) => {
      history.push(`/admin/email-templates/${template.id}`);
    },
    [history]
  );

  const createNewTemplate = useCallback(() => {
    history.push('/admin/email-templates/new');
  }, [history]);

  return (
    <>
      <Dimmer active={loader === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <PageHeader
        as="h1"
        title="Email Templates"
        margin={PageHeader.MARGIN.SMALL}
        actions={
          <Button color="blue" primary onClick={createNewTemplate} loading={defaultStylesLoading === STATUSES.LOADING}>
            Add Template
          </Button>
        }
      />
      <FiltersBox>
        <SettingsDropdown
          options={dropdownOptions}
          width={200}
          value={filterStatus}
          onChange={handleStatusChange}
          placeholder="Any status"
        />
        <FilterSearch>
          <SearchInput value={filter} onSearch={handleSearch} placeholder="Search email templates" fluid />
        </FilterSearch>
      </FiltersBox>
      <List templates={filteredResults} onTemplateSelect={handleEditTemplate} columns={4} maxWidth={990} />

      <Divider hidden />
      <If condition={loader === STATUSES.FAILURE}>
        <FailureMessage icon="search" content={error} />
      </If>
      <Switch>
        <Route path="/admin/email-templates/new">
          <NewTemplate defaultStyles={defaultStyles} />
        </Route>
        <Route path="/admin/email-templates/:templateId" component={EditTemplate} />
      </Switch>
    </>
  );
}
