import React, { useContext } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { Table } from 'components/lib';
import { formatAnyDate } from '../../utils';

import { FEATURE_ACTIVE_STATE } from './constants';

import { FeaturesContext } from './FeaturesContext';
import { titleCase } from '../../../utils/helpers';
import { If } from '../../If';

const IndicatorNote = styled.div`
  color: ${(props) => (props.active ? 'green' : 'red')};
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
  }
`;

const FeaturesOverviewCommunityEntries = ({ entries }) => {
  const { FEATURE_ACTORS, FEATURE_STATUSES, FEATURE_TYPES } = useContext(FeaturesContext);

  const formatUserInformation = (user) => {
    return user ? `${user.first_name} ${user.last_name}` : '';
  };

  return (
    <>
      {entries.map((entry) => {
        const timestamp = formatAnyDate(entry.date, 'MMM d, yyyy HH:mm');
        const actor = FEATURE_ACTORS[entry.actor];
        const author_label = formatUserInformation(entry.author);
        const user_label = formatUserInformation(entry.user);
        const feature = FEATURE_TYPES[entry.type];
        const status = FEATURE_STATUSES[entry.status];

        return (
          <Table.Row key={entry.id}>
            <Table.Cell>{timestamp}</Table.Cell>
            <Table.Cell>{actor}</Table.Cell>
            <Table.Cell>
              {entry.author && (
                <VerticalContainer>
                  {entry.author.first_name} {entry.author.last_name}
                  <span>{entry.author.email}</span>
                </VerticalContainer>
              )}
            </Table.Cell>
            <Table.Cell>
              {entry.user && (
                <VerticalContainer>
                  {entry.user.first_name} {entry.user.last_name}
                  <span>{entry.user.email}</span>
                </VerticalContainer>
              )}
            </Table.Cell>
            <Table.Cell>{feature}</Table.Cell>
            <Table.Cell>
              <IndicatorNote active={entry.status === FEATURE_ACTIVE_STATE}>{status}</IndicatorNote>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <If condition={entry.notes}>
                <Popup trigger={<Icon name="question circle outline" />} offset={[12, 0]} wide on="hover">
                  {entry.notes &&
                    Object.entries(entry.notes).map(([key, value]) => (
                      <IndicatorNote key={key} active={value}>
                        {titleCase(key, '_')}: {value ? 'True' : 'False'}
                      </IndicatorNote>
                    ))}
                </Popup>
              </If>
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default FeaturesOverviewCommunityEntries;
