import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { ChatMessage } from 'components/common/chat';
import { CONVERSATION_TYPE, SENDER_TYPE } from 'constants';
import { MessageContext } from './MessageContext';
import { ConversationMessage } from './ConversationMessage';
import { ConversationMessageDebugModal } from './ConversationMessageDebugModal';

const ConversationWrapper = styled.div`
  height: 100%;
  overflow: visible;
  padding: 0 0.4rem;
`;

export const ConversationSMSMessages = () => {
  const { messages, getSMSMessages, lead } = useContext(MessageContext);
  const [debugMessages, setDebugMessages] = useState([]);

  useEffect(() => {
    getSMSMessages();
  }, [getSMSMessages]);

  const messagesNumber = messages.length;

  const showDebugInfo = (messageIndex) => {
    let msg = messages[messageIndex];
    while (msg.sender_type !== 'lead' && messageIndex > 0) {
      messageIndex--;
      msg = messages[messageIndex];
    }
    if (msg.sender_type !== 'lead') {
      return;
    }
    const debugMessages = [msg];
    msg = messageIndex > 0 && messages[--messageIndex];
    while (msg && msg.sender_type === 'lead') {
      debugMessages.unshift(msg);
      msg = messageIndex > 0 && messages[--messageIndex];
    }
    setDebugMessages(debugMessages);
  };

  const onCloseDebugInfo = () => {
    setDebugMessages([]);
  };

  return (
    <ConversationWrapper>
      {debugMessages.length > 0 && (
        <ConversationMessageDebugModal
          lead={lead}
          messageType="sms"
          messages={debugMessages}
          onClose={onCloseDebugInfo}
        />
      )}
      {messages.map((message, i) => {
        const className = classNames({
          left: message.sender_type === 'lead',
          right: message.sender_type !== 'lead',
          [message.sender_type]: true,
        });
        const senderType = SENDER_TYPE[message.sender_type.toUpperCase()];

        return (
          <ConversationMessage
            key={i}
            message={message}
            child={
              <ChatMessage
                className={className}
                text={message.text}
                timestamp={message.created_on}
                furtherReplied={message.further_replied}
                type={senderType}
                sender={message.sender_name}
                onClick={() => showDebugInfo(i)}
              />
            }
            type={CONVERSATION_TYPE.SMS}
            basic
            last={i + 1 === messagesNumber}
          />
        );
      })}
    </ConversationWrapper>
  );
};
