import React, { useEffect, useState } from 'react';
import { getAdminUsersSearch } from 'apis';
import { Dropdown } from 'components/lib';

const AccountManagerDropdown = ({ value, onChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchAccountManagers = async () => {
      try {
        const { results } = await getAdminUsersSearch({ isStaff: true });
        const managers = results?.map((user) => ({
          key: user.id,
          value: user.id,
          text: user.displayName,
        }));
        setOptions([{ key: 'none', value: null, text: 'None' }, ...managers]);
      } catch (error) {
        console.error('Error fetching account managers:', error);
      }
    };

    fetchAccountManagers();
  }, []);

  return (
    <Dropdown
      placeholder="Select account manager"
      fluid
      search
      selection
      options={options}
      value={value}
      onChange={(event, data) => {
        onChange(data.value);
      }}
    />
  );
};

export default AccountManagerDropdown;
