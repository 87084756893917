import { API } from '../api';

export const getApplicants = async (params) => {
  const response = await API.get('/api/chat/applicants', { params });
  return response.data;
};

export const getApplicantById = async (applicantId) => {
  const response = await API.get(`/api/chat/applicants/${applicantId}`);
  return response.data;
};
