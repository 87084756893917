import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Button, Table, Input, Form } from 'semantic-ui-react';

const Wrapper = styled.div`
  .ui.table tfoot {
    .field {
      margin-bottom: 0;
    }
  }
`;

// Not a real form because nesting <form /> within another <form />
// is not allowed.
const PriceForm = ({ onSubmit, onCancel }) => {
  const [name, setName] = useState('');
  const [cost, setCost] = useState('');
  const [nameError, setNameError] = useState(false);
  const [costError, setCostError] = useState(false);
  const nameField = useRef();

  const submit = () => {
    const c = parseFloat(cost).toFixed(2);
    if (!!name && !Number.isNaN(c) && c > 0) {
      if (typeof onSubmit === 'function') {
        onSubmit({
          name,
          cost: c,
        });

        setName('');
        setNameError(false);
        setCost('');
        setCostError(false);
        if (nameField && nameField.current) {
          nameField.current.focus();
        }
      }
    }
    setNameError(name === '');
    setCostError(cost === '' || Number.isNaN(c) || c === 0);
  };

  const cancel = () => {
    setName('');
    setCost('');
    setNameError(false);
    setCostError(false);
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submit();
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      cancel();
    }
  };

  return (
    <Table.Row>
      <Table.HeaderCell width={2}></Table.HeaderCell>
      <Table.HeaderCell>
        <Form.Field error={nameError}>
          <Input
            ref={nameField}
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            onKeyUp={onKeyUp}
          />
        </Form.Field>
      </Table.HeaderCell>
      <Table.HeaderCell>
        <Form.Field error={costError}>
          <Input
            type="number"
            min="0"
            value={cost}
            icon="dollar"
            iconPosition="left"
            onChange={(e) => {
              setCost(e.target.value);
            }}
            onKeyUp={onKeyUp}
          />
        </Form.Field>
      </Table.HeaderCell>
      <Table.HeaderCell>
        <Button.Group floated="right">
          <Button type="button" icon="add" content="Add Price" size="small" basic onClick={submit} />
          <Button type="button" icon="times" size="small" basic onClick={cancel} />
        </Button.Group>
      </Table.HeaderCell>
    </Table.Row>
  );
};

export const Prices = (props) => {
  const [, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const prices = meta?.value ? [...meta.value] : [];

  const addPrice = (price) => {
    setValue([...prices, { ...price, rank: prices.length }]);
  };

  const removePrice = (price) => {
    setValue(prices.filter((p) => p !== price).map((p, i) => ({ ...p, rank: i })));
  };

  const swapRank = (price, i, dir) => {
    const tmp = prices[i + dir];
    prices[i + dir] = price;
    prices[i] = tmp;
    setValue(prices.map((p, i) => ({ ...p, rank: i })));
  };

  return (
    <Wrapper>
      <Table singleLine>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={2}>Rank</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell width={4}>Cost</Table.HeaderCell>
            <Table.HeaderCell width={3}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {prices?.map((p, i) => (
            <Table.Row key={i}>
              <Table.Cell textAlign="center">{p?.rank}</Table.Cell>
              <Table.Cell>{p?.name}</Table.Cell>
              <Table.Cell textAlign="right">${p?.cost}</Table.Cell>
              <Table.Cell textAlign="right">
                <Button.Group floated="right">
                  {prices.length > 1 && (
                    <>
                      <Button
                        floated="right"
                        type="button"
                        icon="chevron down"
                        size="small"
                        basic
                        disabled={i === prices.length - 1}
                        onClick={() => {
                          swapRank(p, i, 1);
                        }}
                      />

                      <Button
                        floated="right"
                        type="button"
                        icon="chevron up"
                        size="small"
                        basic
                        disabled={i === 0}
                        onClick={() => {
                          swapRank(p, i, -1);
                        }}
                      />
                    </>
                  )}
                  <Button
                    floated="right"
                    type="button"
                    icon="times"
                    size="small"
                    basic
                    onClick={() => {
                      removePrice(p);
                    }}
                  />
                </Button.Group>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer fullWidth>
          <PriceForm onSubmit={addPrice} />
        </Table.Footer>
      </Table>
    </Wrapper>
  );
};
