import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Button, Table, Dropdown, Form, Header, Message } from 'semantic-ui-react';

import { If } from 'components/If';
import { isValidHttpUrl } from '../../utils';

const NEW = '0';
const TEXT = '1';
const LINK = '2';
const MAX_VALUE_LENGTH = 500;

const TRAIT_TYPES = {
  [NEW]: 'New',
  [TEXT]: 'Text',
  [LINK]: 'Link',
};

const TRAIT_TYPE_OPTIONS = Object.entries(TRAIT_TYPES)
  .filter((tt) => tt[0] !== NEW)
  .map(([k, v], i) => ({ key: i, text: v, value: k }));

const Wrapper = styled.div`
  .ui.table {
    tfoot {
      .field {
        margin-bottom: 0;
      }
    }

    .trait-value {
      overflow-wrap: anywhere;
    }
  }
`;

const InvalidModuleText = styled.div`
  color: var(--warning);

  .delete {
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 700;
  }
`;

const getModuleList = (modules) =>
  modules.map((m) => ({
    key: m.id,
    value: m.id,
    text: `Module ${m?.number} [${m?.module_type}]`,
    search: `Module ${m?.number} [${m?.module_type} ${m?.text}]`,
    content: <Header as="h5" content={`Module ${m?.number} [${m?.module_type}]`} subheader={m?.text} />,
  }));

const getTraitsList = (traits) =>
  traits.map((t) => ({
    key: t.id,
    value: t.value,
    text: t.value,
    content: t.value,
    description: TRAIT_TYPES[t.valueType],
  }));

const ModuleDisplay = ({ moduleId, modules }) => {
  if (!moduleId || !modules) {
    return '-';
  }

  const module = modules.find((m) => m.id === moduleId);

  if (!module) {
    return (
      <InvalidModuleText>
        <div>Invalid module</div>
        <div className="delete">Safe to delete</div>
      </InvalidModuleText>
    );
  }

  return (
    <div>
      Module {module.number} [{module.module_type}]
    </div>
  );
};

// Not a real form because nesting <form /> within another <form />
// is not allowed.
const AddModuleTrait = ({ modules, traits, onAddTrait, onSubmit }) => {
  const [traitType, setTraitType] = useState('');
  const [traitTypeError, setTraitTypeError] = useState();
  const [module, setModule] = useState('');
  const [moduleError, setModuleError] = useState(false);
  const [value, setValue] = useState('');
  const [valueError, setValueError] = useState(false);

  const moduleList = getModuleList(modules);
  const traitList = getTraitsList(traits);

  const reset = () => {
    setTraitType('');
    setTraitTypeError(false);
    setValue('');
    setValueError(false);
    setModule('');
    setModuleError(false);
  };

  const submit = () => {
    let valid = true;

    if (!traitType) {
      valid = false;
      setTraitTypeError(true);
    }

    if (traitType === LINK && !isValidHttpUrl(value)) {
      valid = false;
      setValueError({ content: 'Please enter full url' });
    }

    if (value === '') {
      valid = false;
      setValueError(true);
    }

    if (value.length > MAX_VALUE_LENGTH) {
      valid = false;
      setValueError({ content: `The value cannot be longer than ${MAX_VALUE_LENGTH} characters` });
    }

    if (!module || module === '') {
      valid = false;
      setModuleError(true);
    }

    if (valid) {
      if (typeof onSubmit === 'function') {
        onSubmit({
          value,
          value_type: traitType,
          module,
        });

        reset();
      }
    }
  };

  return (
    <Table.Row>
      <Table.HeaderCell verticalAlign="top">
        <Form.Field
          error={traitTypeError}
          control={Dropdown}
          fluid
          placeholder="Trait Type"
          selection
          options={TRAIT_TYPE_OPTIONS}
          value={traitType}
          onChange={(e, { value }) => {
            setTraitType(value);
            setTraitTypeError(false);
          }}
        />
      </Table.HeaderCell>

      <Table.HeaderCell verticalAlign="top">
        <Form.Field
          control={Dropdown}
          placeholder="Select or create trait"
          fluid
          options={traitList}
          selection
          allowAdditions
          search
          value={value}
          additionLabel={<i>Add new trait: </i>}
          onAddItem={(e, { value }) => {
            onAddTrait(value);
            setValue(value);
            setValueError(value === '');
          }}
          onChange={(e, { value }) => {
            setValue(value);
            setValueError(false);
            const trait = traits.find((t) => t.value === value);
            if (trait) {
              if (trait.valueType && trait.valueType !== NEW) {
                setTraitType(trait.valueType.toString());
              } else {
                setTraitType('');
              }
            }
          }}
          error={valueError}
        />
      </Table.HeaderCell>

      <Table.HeaderCell verticalAlign="top">
        <Form.Field
          error={moduleError}
          control={Dropdown}
          fluid
          placeholder="Select module"
          selection
          options={moduleList}
          search={(options, query) => options.filter((o) => o.search.includes(query.toLowerCase()))}
          value={module}
          onChange={(e, d) => {
            setModule(d.value);
            setModuleError(false);
          }}
        />
      </Table.HeaderCell>

      <Table.HeaderCell verticalAlign="top">
        <Button.Group floated="right">
          <Button type="button" icon="add" content="Add Trait" size="small" basic onClick={submit} />
        </Button.Group>
      </Table.HeaderCell>
    </Table.Row>
  );
};

export const ModuleTraits = ({ modules, traits, ...props }) => {
  const [, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const moduleTraits = [...meta.value];
  const [communityTraits, setCommunityTraits] = useState([]);

  useEffect(() => {
    setCommunityTraits(traits);
  }, [traits]);

  const addModuleTrait = (trait) => {
    const { value, value_type, module } = trait;
    setCommunityTraits(
      communityTraits.map((ct) => {
        if (ct.value === value && ct.valueType === NEW) {
          return { value, valueType: value_type, module };
        }
        if (ct.value === value && ct.valueType === value_type) {
          return { value, valueType: value_type, module };
        }

        return ct;
      })
    );
    setValue([...moduleTraits, { module, value, value_type }]);
  };

  const removeModuleTrait = (index) => () => {
    moduleTraits.splice(index, 1);
    setValue(moduleTraits);
  };

  const addCommunityTrait = (value) => {
    setCommunityTraits([...communityTraits, { value, valueType: NEW }]);
  };

  const filteredModules = modules.filter((m) => !moduleTraits.some((t) => t.module === m.id));
  const missingModuleTraits = modules.some(
    (item) => item.module_type === 'link' && !moduleTraits.some((t) => t.module === item.id)
  );

  return (
    <Wrapper>
      <If condition={missingModuleTraits}>
        <Message color="orange">
          Chat contains modules for which you need to add value for them to work properly. Please review the chat
          modules and add a new module property to add value to the corresponding module.
        </Message>
      </If>

      <Table>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={2}>Trait Type</Table.HeaderCell>
            <Table.HeaderCell width={7}>Value</Table.HeaderCell>
            <Table.HeaderCell width={4}>Module</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {moduleTraits?.map((mt, index) => (
            <Table.Row key={index}>
              <Table.Cell textAlign="center">{TRAIT_TYPES[mt.value_type]}</Table.Cell>
              <Table.Cell className="trait-value">{mt.value}</Table.Cell>
              <Table.Cell textAlign="center">
                <ModuleDisplay moduleId={mt.module} modules={modules} />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Button.Group floated="right">
                  <Button type="button" icon="times" size="small" basic onClick={removeModuleTrait(index)} />
                </Button.Group>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer fullWidth>
          {filteredModules.length > 0 && (
            <AddModuleTrait
              modules={filteredModules}
              traits={communityTraits}
              onAddTrait={addCommunityTrait}
              onSubmit={addModuleTrait}
            />
          )}
        </Table.Footer>
      </Table>
    </Wrapper>
  );
};
