import React from 'react';
import styled from 'styled-components';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Modal } from 'components/lib';
import { If } from 'components/If';
import { DEFAULT_CAMPAIGN_REFERRAL_SOURCE } from './constants';
import { usePhoneSystem } from './PhoneSystemContext';
import { EditPhoneSystemForm } from './fields';
import { groupCampaignNumbersByReferral } from './utils';

const Header = styled.div`
  margin: 29px 0 10px 30px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  color: var(--text);
  font-family: var(--font-family-bold);
`;

const Content = styled.div`
  margin: 10px 0px;
`;

const SubHeader = styled.div`
  font-family: var(--font-family);
  color: var(--light-text);
  font-weight: 500;
  font-size: 14px !important;
  line-height: 1.43;
`;

const CommunityLink = styled.a`
  color: var(--link);
`;

const validationSchema = Yup.object({
  areaCode: Yup.number().nullable().notRequired().min(0).max(999),
  inheritQuerySelector: Yup.bool(),
  querySelector: Yup.string(),
  poolEnabled: Yup.bool(),
  dedicatedNumbersEnabled: Yup.bool().when('poolEnabled', {
    is: false,
    then: Yup.bool().oneOf([true], 'At least one item should be selected'),
  }),
  dedicatedNumbers: Yup.array().when('dedicatedNumbersEnabled', {
    is: true,
    then: Yup.array().of(
      Yup.object({
        referralOriginType: Yup.string().required('Campaign must be defined'),
        referralOrigin: Yup.string().required('Campaign must be defined'),
        quantity: Yup.number().required('Quantity must be positive integer'),
      })
    ),
    otherwise: Yup.array(),
  }),
});

export const EditPhoneSystemModal = ({ open, onClose = () => false }) => {
  const { selectedCommunity, handlePhoneSystemEdit, isSaving, globalQuerySelector, onPhoneSystemDeactivate } =
    usePhoneSystem();

  if (!selectedCommunity) return null;

  const initialFormValues = {
    areaCode: selectedCommunity.areaCode,
    poolEnabled: selectedCommunity.poolEnabled,
    dedicatedNumbersEnabled: selectedCommunity?.dedicatedPhoneNumbers?.length > 0,
    inheritQuerySelector: selectedCommunity.inheritQuerySelector,
    querySelector: selectedCommunity.inheritQuerySelector ? globalQuerySelector : selectedCommunity.querySelector,
    existingDedicatedNumbers: groupCampaignNumbersByReferral(selectedCommunity.dedicatedPhoneNumbers).map(
      (phoneNumber) => {
        return {
          referralOriginType: phoneNumber.isCustom ? DEFAULT_CAMPAIGN_REFERRAL_SOURCE : phoneNumber.referralOrigin,
          referralOrigin: phoneNumber.referralOrigin,
          quantity: phoneNumber.phoneNumbers.length,
          phoneNumbers: phoneNumber.phoneNumbers,
        };
      }
    ),
    dedicatedNumbers: [],
  };

  const handleClose = () => {
    if (!onClose) return;

    if (isSaving) return;

    onClose();
  };

  // Remove all existing dedicated phone numbers, or just some of them
  const getPhoneNumbersToRemove = (values) => {
    if (!values.dedicatedNumbersEnabled) {
      return selectedCommunity.dedicatedPhoneNumbers.map((number) => number.id);
    }

    const existingReferralOrigins = values.existingDedicatedNumbers.map((number) => number.referralOrigin);

    return selectedCommunity.dedicatedPhoneNumbers
      .filter((number) => !existingReferralOrigins.includes(number.referralOrigin))
      .map((number) => number.id);
  };

  const handleConfirm = async (values) => {
    const requestData = {
      areaCode: values.areaCode || null,
      querySelector: values.querySelector || null,
      inheritQuerySelector: values.inheritQuerySelector,
      poolEnabled: values.poolEnabled,
      dedicatedPhoneNumbers: values.dedicatedNumbersEnabled
        ? values.dedicatedNumbers.map((number) => ({
            quantity: number.quantity,
            referralOrigin: number.referralOrigin,
          }))
        : [],
      dedicatedPhoneNumbersToRemove: getPhoneNumbersToRemove(values),
    };

    await handlePhoneSystemEdit(selectedCommunity, requestData);
    onClose();
  };

  const handleDeactivate = () => {
    onPhoneSystemDeactivate(selectedCommunity);
    onClose();
  };

  if (!selectedCommunity) return null;

  return (
    <Modal open={open} closeIcon onClose={handleClose} size="small">
      <Header>
        Call Tracking
        <SubHeader>
          for <CommunityLink href={`/communities/${selectedCommunity.id}`}>{selectedCommunity.name}</CommunityLink>
        </SubHeader>
      </Header>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={handleConfirm}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {({ handleSubmit, isValid, dirty, values }) => {
          const shouldShowDeactivateButton = !values.poolEnabled && !values.dedicatedNumbersEnabled;
          return (
            <>
              <Content>
                <EditPhoneSystemForm />
              </Content>
              <Modal.Actions>
                <If condition={shouldShowDeactivateButton}>
                  <Button primary color="red" disabled={isSaving} loading={isSaving} onClick={handleDeactivate}>
                    Deactivate
                  </Button>
                </If>
                <If condition={!shouldShowDeactivateButton}>
                  <Button
                    primary
                    color="blue"
                    disabled={!isValid || isSaving || !dirty}
                    loading={isSaving}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </If>
                <Button basic onClick={handleClose}>
                  Close
                </Button>
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
