import React, { useEffect, useRef } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import styled from 'styled-components';

import useCountDown from 'utils/hooks/useCountDown';

const MessageCountdownWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 1em 0;
`;

export const MessageCountdown = ({ countdown, onTimeElapsed }) => {
  const startedCounterRef = useRef();
  const [timeLeft, { start }] = useCountDown(countdown * 1000);

  useEffect(() => {
    start();
    startedCounterRef.current = true;
  }, [start]);

  if (startedCounterRef.current && timeLeft <= 0) {
    onTimeElapsed();
  }

  const timeLeftMinutes = Math.floor(timeLeft / 1000 / 60);
  const timeLeftSeconds = timeLeft / 1000 - timeLeftMinutes * 60;

  if (!timeLeft) {
    return null;
  }

  return (
    <MessageCountdownWrapper>
      <Label color="yellow">
        <Icon loading name="osi" />
        This message has been postponed and will be sent to community in {timeLeftMinutes}m {timeLeftSeconds}s
      </Label>
    </MessageCountdownWrapper>
  );
};
