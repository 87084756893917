import { USER_TYPES } from '../../../constants';

export const ANALYTICS_TYPE_CUSTOM = 'custom';
export const ANALYTICS_CHAT_MODULE_SUBMIT_TYPE = 'submit';

export const ANALYTICS_MENU_ITEMS = [
  {
    key: 'analytics-integrations',
    label: 'Analytics',
    to: '/chats/analytics',
    exact: true,
    allowedTypes: [USER_TYPES.STAFF],
  },
  {
    key: 'analytics-modules',
    label: 'Chat Submit Modules',
    to: '/chats/analytics/details',
    exact: false,
    allowedTypes: [USER_TYPES.STAFF],
  },
];
