import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { getReportMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { Icon, Popup, ProgressBar, Table } from 'components/lib';
import queryConstants from 'utils/queries/constants';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import { CsvExport } from '../../common/CsvExport';
import { PageSection } from '../../common';
import { formatCount, formatDurationAsTime, percent } from '../../utils';
import { CommunityCell } from '../common/CommunityCell';

const csvHeaders = [
  'Community Name',
  'Calls',
  'Answered Calls',
  'Avg. Duration',
  'Lead Calls',
  'Lead Answered Calls',
  'Lead Calls Avg. Duration',
  'Callers',
  'Callers with Multiple Calls',
  'New Leads',
];
const metrics = [
  'calls.total',
  'calls.missed',
  'calls.duration',
  'calls.lead',
  'calls.lead.missed',
  'calls.lead.duration',
  'calls.callers',
  'calls.multicallers',
  'calls.lead_created',
];
const groupBy = 'community_id';

const CsvDownloadContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTableCell = styled(Table.HeaderCell)`
  &&& {
    padding-left: 0;
  }
`;

const CallsCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  line-height: 1.33;
  color: var(--text);
  p {
    margin: 0;
    font-size: 14px;
  }
  span {
    color: #89919d;
    font-size: 12px;
  }
  h5 {
    font-family: var(--font-family);
    font-size: 12px;
    margin: 0;
  }
`;

const StyledTableHeaderCell = styled(Table.HeaderCell)`
  &&& {
    width: 70px;
    padding: 0;
  }
`;

const CallsCell = ({ total, missed, duration }) => {
  const answered = total - missed;
  const answeredPercent = percent(answered, total).toFixed(0);

  return (
    <CallsCellContainer>
      <p>
        {formatCount(total)}&nbsp;<span>{formatDurationAsTime(duration)} avg.</span>
      </p>
      <h5>{answeredPercent}% answered</h5>
      <ProgressBar height="2px" width="100px" progress={answeredPercent} />
    </CallsCellContainer>
  );
};

const CallersCell = ({ total, multicallers }) => {
  const multicallersPercent = percent(multicallers, total).toFixed(0);

  return (
    <CallsCellContainer>
      <p>{formatCount(total)}</p>
      <h5>{multicallersPercent}% made multiple calls</h5>
      <ProgressBar height="2px" width="100px" progress={multicallersPercent} />
    </CallsCellContainer>
  );
};
const getFirstImage = (images) => {
  if (!images || images.length === 0) {
    return null;
  }
  return images[0];
};

const getFullAddress = (community) => {
  if (!community) {
    return '';
  }
  // conditionaly add address parts if we have them, otherwise skip
  const addressParts = [community.community_address, community.community_city, community.community_state];
  return addressParts.filter((part) => !!part).join(', ');
};

const CommunityBreakdown = ({ filters, publicId, limit, communities = [] }) => {
  const [ordering, setOrdering] = useState('-name');

  const { data, error, status, isFetching } = useQuery(
    [queryConstants.METRICS, { publicId, filters, metrics, groupBy, limit }],
    () => getReportMetrics({ public_id: publicId, filters: { ...filters, metrics, group_by: groupBy, limit } }),
    { placeholderData: {} }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch calls data'));
  }

  const getCommunity = (id) => {
    return communities?.find((c) => c.id === id);
  };

  const parsedData = useMemo(() => {
    if (!data || !data['calls.total']) {
      return [];
    }
    const metricsLookup = metrics.reduce((acc, m) => {
      acc[m] = data[m].groups.reduce((acc, curr) => {
        acc[curr.community_id] = curr.value;
        return acc;
      }, {});
      return acc;
    }, {});
    return data['calls.total'].groups.map((c) => {
      const community = getCommunity(c.community_id) || {};
      return {
        id: c.community_id,
        name: c.community_name,
        picture: getFirstImage(community?.community_images),
        address: getFullAddress(community),
        ...metrics.reduce((acc, m) => {
          acc[m.replace('calls.', '')] = metricsLookup[m][c.community_id];
          return acc;
        }, {}),
      };
    });
  }, [data]);

  const csvContent = useMemo(() => {
    return parsedData?.map((c) => [
      c.name,
      c.total,
      c.total - c.missed,
      Math.round(c.duration),
      c.lead,
      c.lead - c['lead.missed'],
      Math.round(c['lead.duration']),
      c.callers,
      c.multicallers,
      c.lead_created,
    ]);
  }, [parsedData]);

  const handleReordering = (column) => {
    if (ordering === column) {
      setOrdering(`-${column}`);
    } else {
      setOrdering(column);
    }
  };

  const descending = ordering && ordering[0] === '-';
  const orderAs = ordering && (descending ? 'descending' : 'ascending');
  const orderingColumn = (descending && ordering.substring(1)) || ordering;

  const isSortedBy = (column) => (orderingColumn === column && orderAs) || undefined;

  const parsedDataSorted = parsedData?.sort((a, b) => {
    if (ordering === '-name') {
      return a.name.localeCompare(b.name);
    }
    if (ordering === 'total') {
      return a.total - b.total;
    }
    if (ordering === '-total') {
      return b.total - a.total;
    }
    if (ordering === 'lead') {
      return a.lead - b.lead;
    }
    if (ordering === '-lead') {
      return b.lead - a.lead;
    }
    if (ordering === 'callers') {
      return a.callers - b.callers;
    }
    if (ordering === '-callers') {
      return b.callers - a.callers;
    }
    if (ordering === 'lead_created') {
      return a.lead_created - b.lead_created;
    }
    if (ordering === '-lead_created') {
      return b.lead_created - a.lead_created;
    }
    return b.name.localeCompare(a.name);
  });

  return (
    <PageSection loaderStatus={loaderStatus}>
      <Table>
        <Table.Header>
          <Table.Row textAlign="right">
            <StyledTableCell
              textAlign="left"
              sortable
              sorted={isSortedBy('name')}
              onClick={() => handleReordering('name')}
            >
              <span className="title">Community</span>
            </StyledTableCell>
            <Table.HeaderCell sortable sorted={isSortedBy('total')} onClick={() => handleReordering('total')}>
              <Table.HeaderWithInfo info="Total number of calls that have occurred">
                <span className="title">Total Calls</span>
              </Table.HeaderWithInfo>
            </Table.HeaderCell>
            <Table.HeaderCell sortable sorted={isSortedBy('lead')} onClick={() => handleReordering('lead')}>
              <Table.HeaderWithInfo info="The number of calls where the caller intended to connect with sales, resulting in generated leads">
                <span className="title">Lead Calls</span>
              </Table.HeaderWithInfo>
            </Table.HeaderCell>

            <Table.HeaderCell sortable sorted={isSortedBy('callers')} onClick={() => handleReordering('callers')}>
              <CsvDownloadContainer>
                <Table.HeaderWithInfo info="The count of distinct individuals who have initiated calls">
                  <span className="title">Callers</span>
                </Table.HeaderWithInfo>
              </CsvDownloadContainer>
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              sorted={isSortedBy('lead_created')}
              onClick={() => handleReordering('lead_created')}
            >
              <CsvDownloadContainer>
                <Table.HeaderWithInfo info="The count of leads that were created with calls">
                  <span className="title">New Leads</span>
                </Table.HeaderWithInfo>
              </CsvDownloadContainer>
            </Table.HeaderCell>
            <StyledTableHeaderCell>
              <CsvDownloadContainer>
                <CsvExport data={csvContent} headers={csvHeaders} filename="calls_by_community.csv" />
              </CsvDownloadContainer>
            </StyledTableHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {parsedDataSorted?.map((m, i) => (
            <Table.Row textAlign="right" key={i}>
              <CommunityCell community={m}></CommunityCell>
              <Table.Cell textAlign="left">
                <CallsCell total={m.total} missed={m.missed} duration={m.duration} />
              </Table.Cell>
              <Table.Cell textAlign="left">
                <CallsCell total={m.lead} missed={m['lead.missed']} duration={m['lead.duration']} />
              </Table.Cell>
              <Table.Cell textAlign="left">
                <CallersCell total={m.callers} multicallers={m.multicallers} />
              </Table.Cell>
              <Table.Cell textAlign="left">{formatCount(m.lead_created)}</Table.Cell>
              <Table.Cell width={1} />
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </PageSection>
  );
};

export default CommunityBreakdown;
