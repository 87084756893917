export const SYNC_INTERVAL = 5000;

export const COMMUNITY_STATUS = {
  NONE: 'NONE',
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
};

export const ACTION_TYPE = {
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
};

export const MODULE_UPLOAD_STATUS = {
  NONE: 'NONE',
  PENDING: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
  FAILED: 4,
  READY_FOR_REMOVAL: 5,
};

export const MODULE_UPLOAD_ACTIONS = {
  REQUIRED: 1,
  OPTIONAL: 2,
  NOT_ALLOWED: 3,
};

export const MODULE_ACTION_STATUS = {
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  FAILED: 'FAILED',
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
  NOT_PLAYABLE: 'NOT_PLAYABLE',
  NOT_ALLOWED: 'NOT_ALLOWED',
};

export const MODULE_TYPE_VALUES = {
  option: 'Option',
  multi_option: 'MultiOption',
  link: 'Links',
  text: 'Text Input',
  text_display: 'Text Display',
  email: 'Email Input',
  phone: 'Phone Input',
  date_picker: 'Calendar',
  submit: 'Submit',
  sms: 'Sms',
  slack: 'Slack',
  eval: 'Eval',
  out_of_budget: 'Out Of Budget',
  out_of_budget_slider: 'User Input',
  click: 'Click',
  submit_job_applicant: 'Submit Job Applicant',
  caller_phone_submit: 'Submit Caller Phone',
  voicemail: 'Voicemail',
  dial_community: 'Dial Community',
  start_call_recording: 'Start Call Recording',
};
