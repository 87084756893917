import React from 'react';
import styled from 'styled-components';
import { Form, useFormikContext } from 'formik';

import { ReactComponent as LogoType } from 'assets/images/new-logotype.svg';

import { Button, Input } from 'components/lib';
import { InputField } from 'components/common';

import { NextStep } from './NextStep';

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 60px 16px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & > label {
    flex: 1 0 auto;
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: 1.14;
    text-align: right;
    color: #222;
    margin-right: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100px;
    word-break: keep-all;
  }
`;

const StyledInput = styled(Input)`
  &&&& {
    input {
      width: 100% !important;
      max-width: 360px !important;
    }
  }
`;

const FullWidthForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 10px;
`;

export const CreateAccountForm = () => {
  const { values, isValid, isSubmitting } = useFormikContext();

  const disabled = values?.email?.length === 0 || values?.password?.length === 0 || !isValid;
  return (
    <FullWidthForm>
      <LogoType width={28} height={28} />
      <h2>Create an Account</h2>
      <p>
        To save what you made and make it appear on your website,
        <br /> create a <b>free Further account</b> below.
      </p>
      <InputsWrapper>
        <NameContainer>
          <InputContainer>
            <label>First Name</label>
            <InputField
              autoComplete="off"
              control={StyledInput}
              fluid
              type="text"
              name="firstName"
              placeholder="Enter your first name"
              newError
            />
          </InputContainer>
          <InputContainer>
            <label>Last Name</label>
            <InputField
              autoComplete="off"
              control={StyledInput}
              fluid
              type="text"
              name="lastName"
              placeholder="Enter your last name"
              newError
            />
          </InputContainer>
        </NameContainer>
        <InputContainer>
          <label>Email</label>
          <InputField
            autoComplete="off"
            control={StyledInput}
            fluid
            type="text"
            name="email"
            placeholder="Enter your email address"
            newError
          />
        </InputContainer>
        <InputContainer>
          <label>New Password</label>
          <InputField
            autoComplete="off"
            control={StyledInput}
            fluid
            type="password"
            name="password"
            placeholder="Create a password"
            newError
          />
        </InputContainer>
      </InputsWrapper>
      <Button size="big" fluid primary color="blue" type="submit" disabled={disabled} loading={isSubmitting}>
        Create Account
      </Button>
      <NextStep text="Create Account" disabled={disabled} loading={isSubmitting} />
    </FullWidthForm>
  );
};
