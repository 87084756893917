import React from 'react';
import { Route, Switch } from 'react-router';

import { Content } from 'components/common';
import { WithTopBar } from 'components/WithTopBar';
import Applicant from './Applicant';
import ApplicantList from './ApplicantList';

const ApplicantRoot = () => (
  <WithTopBar>
    <Content>
      <Switch>
        <Route exact path="/job-applicants">
          <ApplicantList />
        </Route>
        <Route exact path="/job-applicants/:applicantId">
          <Applicant />
        </Route>
      </Switch>
    </Content>
  </WithTopBar>
);

export default ApplicantRoot;
