import React from 'react';

import { Icon } from 'components/lib';
import conversationalIVR from 'assets/images/ProductImages/conversationalIVR.webp';
import conversationalWebForms from 'assets/images/ProductImages/conversationalWebForms.webp';
import instantAnswers from 'assets/images/ProductImages/instantAnswers.webp';
import messagingAssistant from 'assets/images/ProductImages/messagingAssistant.webp';
import virtualSalesAssistant from 'assets/images/ProductImages/virtualSalesAssistant.webp';
import { capitalize } from 'utils/helpers';

export const DEFAULT_OVERVIEW_RANGE = 12;

export const OVERVIEW_RANGE_OPTIONS = [
  {
    key: 12,
    text: 'Last 12 months',
    value: 12,
  },
  {
    key: 6,
    text: 'Last 6 months',
    value: 6,
  },
  {
    key: 3,
    text: 'Last 3 months',
    value: 3,
  },
  {
    key: 1,
    text: 'Last 1 month',
    value: 1,
  },
];

export const PRODUCTS = (t) => [
  {
    ProductIcon: (props) => <Icon name="phone" color="var(--emerald-green)" {...props} />,
    id: 'iVR',
    productImage: conversationalIVR,
    iconBancground: '#defff3',
    name: 'Conversational IVR',
    subtitle: 'Automated phone conversations',
    description: [
      `${capitalize(
        t('leads')
      )} are able to interact with a computer-operated telephone system through the use of voice and keypad input`,
      `Special phone numbers are assigned to your existing ${t(
        'community'
      )} websites, and they take over any call automatically`,
      'Allows recording playback and various analytics regarding phone call data.',
    ],
  },
  {
    ProductIcon: (props) => <Icon className="flipped" name="switch_access_shortcut" color="#ff5b98" {...props} />,
    id: 'iA',
    productImage: instantAnswers,
    iconBancground: '#ffebf5',
    name: 'Instant Answers',
    subtitle: 'Automated chat answers',
    description: [
      `We provide robust answers to simple keywords that ${t('leads')} type into the VSA chat interface`,
      'You are able to associate keywords with chat responses yourself, and we will remind you to do so periodically',
      `Ensures that your ${t('leads')} are provided with relevant answers even with minimal keyboard input.`,
    ],
  },
  {
    ProductIcon: (props) => <Icon name="question_answer" color="#2791ff" variant="outlined" {...props} />,
    id: 'vSA',
    productImage: virtualSalesAssistant,
    iconBancground: '#ebf5ff',
    name: 'Virtual Sales Assistant',
    subtitle: 'Pop-up chat assistant',
    description: [
      `A chat system that pops up on a ${t('community')} website from a corner. It responds to ${t(
        'leads'
      )}’ questions`,
      `${capitalize(
        t('leads')
      )} can also follow intuitive flows through simple button selection, which guides them towards a ${t('move-in')}`,
      'An extensive analytics view is provided, helping you make the right decisions from captured data.',
    ],
  },
  {
    ProductIcon: (props) => <Icon name="wysiwyg" color="#5f5fff" {...props} />,
    id: 'cW',
    productImage: conversationalWebForms,
    iconBancground: '#f0f0ff',
    name: 'Conversational Web Forms',
    subtitle: 'Full screen wizard assistant',
    description: [
      `A full screen experience that appears seamlessly after a ${t('lead')} clicks a button on your website`,
      `It uses a wizard interface to guide ${t('leads')} by asking relevant questions and capturing contact info`,
      'An extensive analytics view is provided, helping you make the right decisions from captured data.',
    ],
  },
  {
    ProductIcon: (props) => <Icon name="support_agent" color="#f6bb5f" {...props} />,
    id: 'mA',
    productImage: messagingAssistant,
    iconBancground: '#fff4d4',
    name: 'Messaging Assistant',
    subtitle: 'Further’s messaging help',
    description: [
      `We intercept and reply to incoming messages from ${t(
        'leads'
      )} before they are sent to you. You receive the messages a couple of minutes later, and have full overview of what happened`,
      `Our experienced team handles questions, ${t('tour')} and call scheduling, and much more`,
      `Saves time and makes sure your ${t('leads')} are contacted quickly and efficiently.`,
    ],
  },
];
