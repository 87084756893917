import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { formatAnyDate } from 'components/utils';
import { LEAD_STATUS } from '../conversations/utils';
import { LEAD_STATUS_STYLE } from './constants';
import { CONVERSATION_EVENT, CALL_DIRECTION_TYPES } from './actions/constants';

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeadStatusDescription = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 12px;
  font-size: 12px;
`;

const LeadStatusContainer = styled(VerticalContainer)`
  color: var(--salmon);
  text-align: left;

  &.processing {
    color: var(--vivid-blue);
  }

  &.submitted {
    color: var(--emerald-green);
  }

  &.bold {
    text-align: right;
    font-family: var(--font-family-bold);

    ${LeadStatusDescription} {
      color: black;
      font-family: var(--font-family);
      font-size: 10px;
      line-height: 10px;
    }
  }
`;

const ConversationLeadStatus = ({ lead, style }) => {
  const { t } = useTranslation();

  if (!lead) {
    return null;
  }

  const getStatusReason = (lead = {}) => {
    const calendarRequest = lead.tour || lead.call;
    const calendarRequestType = calendarRequest?.request_type || CONVERSATION_EVENT.TOUR;
    const statusResolver = LEAD_STATUS(t, calendarRequestType);

    let status = LEAD_STATUS.NONE;

    if (lead.move_in_date) {
      const moveInDate = formatAnyDate(lead.move_in_date, 'MMM dd yyyy');
      status = `${statusResolver.MOVED_IN}: ${moveInDate}`;
    }

    if (lead.financially_unqualified) {
      status = statusResolver.FINANCIALLY_UNQUALIFIED;
    }

    if (lead.phone_call) {
      status =
        lead.phone_call.direction === CALL_DIRECTION_TYPES.INBOUND
          ? statusResolver.INBOUND_CALL
          : statusResolver.OUTBOUND_CALL;
    }

    if (calendarRequest) {
      const calendarRequestDate = calendarRequest.start
        ? formatAnyDate(calendarRequest.start, 'MMM dd yyyy')
        : 'Date Unspecified';

      if (calendarRequest.confirmed) {
        status = `${statusResolver.CALENDAR_REQUEST_CONFIRMED}: ${calendarRequestDate}`;
      } else if (Boolean(calendarRequest.reschedule_id)) {
        status = `${statusResolver.CALENDAR_REQUEST_OFFERED}`;
      } else {
        status = `${statusResolver.CALENDAR_REQUEST_SCHEDULED}: ${calendarRequestDate}`;
      }
    }
    return status;
  };

  const getStatus = (lead) => {
    if (lead?.lead_submitted) {
      return 'Submitted';
    }

    if (lead?.processing?.is_active || lead?.is_processing_active) {
      return 'Ongoing conversation';
    }

    return 'Blocked';
  };
  const status = getStatus(lead?.details || lead);
  const reason = getStatusReason(lead);

  const classes = classNames({
    bold: style === LEAD_STATUS_STYLE.BOLD,
    submitted: lead.lead_submitted || lead?.details?.lead_submitted,
    processing: lead.processing?.is_active || lead?.details?.is_processing_active,
  });

  return (
    <LeadStatusContainer className={classes}>
      <span>{status}</span>
      <LeadStatusDescription>{reason}</LeadStatusDescription>
    </LeadStatusContainer>
  );
};

export default ConversationLeadStatus;
