import { Icon, List, Table } from 'semantic-ui-react';
import React from 'react';

export const SEOAnalysisRow = ({ title, value }) => {
  let valueElement = value;

  if (typeof value === 'boolean') {
    valueElement = <Icon name={value ? 'check' : 'close'} color={value ? 'green' : 'red'} size="small" />;
  } else if (Array.isArray(value)) {
    valueElement = (
      <List horizontal divided>
        {value.map((attribute) => (
          <List.Item key={attribute} content={attribute} />
        ))}
      </List>
    );
  } else if (typeof value === 'object') {
    valueElement = (
      <List>
        <List.Item content={value?.name} />
        <List.Item content={value?.address} />
        <List.Item content={value?.phone} />
      </List>
    );
  }

  return (
    <Table.Row>
      <Table.Cell>{title}</Table.Cell>
      <Table.Cell textAlign="right">{valueElement || 'n/a'}</Table.Cell>
    </Table.Row>
  );
};
