import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';

import { If } from '../../If';

const ImageListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ImageContainer = styled.div`
  display: flex;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin: 10px;
  padding: 10px;
  box-shadow: 2px 2px 2px lightgrey;
  border: 2px solid transparent;

  &.selected {
    border: 2px solid lightblue;
  }

  &:hover {
    opacity: 0.85;
    border: 2px solid lightblue;
  }

  i {
    margin: 5px;
    position: absolute;
  }

  img {
    height: auto;
    width: auto;
    max-height: 150px;
    max-width: 200px;
    align-self: center;
  }
`;

export const UserCommunityImagesSelection = ({ images, selection, onChange }) => {
  const onSelection = (imgURL) => {
    let newSelection;
    const index = selection.findIndex((i) => i === imgURL);

    if (index !== -1) {
      selection.splice(index, 1);
      newSelection = [...selection];
    } else {
      newSelection = [...selection, imgURL];
    }

    onChange(newSelection);
  };

  return (
    <ImageListContainer>
      {images.map((imgUrl) => (
        <ImageContainer
          key={imgUrl}
          className={classNames({ selected: selection.includes(imgUrl) })}
          onClick={() => onSelection(imgUrl)}
        >
          <If condition={selection.includes(imgUrl)}>
            <Icon name="check circle" size="large" color="teal" />
          </If>
          <img src={imgUrl} alt={imgUrl} />
        </ImageContainer>
      ))}
    </ImageListContainer>
  );
};
