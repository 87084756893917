import { toast } from 'react-toastify';
import { format } from 'date-fns';

import {
  getAttributes,
  getQuestionReviews,
  getQuestionReviewsStatistics,
  updateQuestionReview,
  exportQuestionReviews,
} from 'apis';
import { downloadFile } from 'components/utils';
import { buildOrdering, parseErrorResponse } from 'apis/utils';
import {
  setQuestionsCount,
  setAttributes,
  setLoaderStatus,
  setQuestions,
  setCurrentQuestionId,
  setFilters,
  setUpdateLoaderStatus,
} from './actions';
import { IA_ATTRIBUTE_TYPES, PAGINATION, STATUSES } from '../../../../constants';

export const fetchQuestionsCount = async (dispatch) => {
  try {
    const response = await getQuestionReviewsStatistics();

    dispatch(setQuestionsCount(response));
  } catch (e) {
    toast.error(parseErrorResponse(e, 'Unable to fetch questions count'));
  }
};

export const fetchAttributes = async (dispatch) => {
  try {
    const response = await getAttributes({
      attribute_type: IA_ATTRIBUTE_TYPES.SENIOR_LIVING,
    });

    dispatch(
      setAttributes(
        response.map((a) => ({
          key: a.id,
          value: a.id,
          text: a.name,
        }))
      )
    );
  } catch (e) {
    console.error(e);
    toast.error(parseErrorResponse(e, 'Unable to fetch attributes'));
  }
};

export const fetchQuestions = async (
  dispatch,
  { page, pageSize, search, includeExported, isIgnored, isLabeled, ordering }
) => {
  try {
    dispatch(setLoaderStatus(STATUSES.LOADING));
    const { results, count } = await getQuestionReviews({
      page,
      page_size: pageSize,
      search,
      is_ignored: isIgnored,
      is_labeled: isLabeled,
      include_exported: includeExported,
      ordering: buildOrdering(ordering),
    });

    dispatch(setQuestions(results, count));
    dispatch(setLoaderStatus(STATUSES.LOADED));
  } catch (e) {
    console.error(e);
    toast.error(parseErrorResponse(e, 'Error fetching questions'));
    setLoaderStatus(STATUSES.FAILURE);
  }
};

export const exportQuestions = async (dispatch, { includeExported, isIgnored, isLabeled }) => {
  try {
    dispatch(setLoaderStatus(STATUSES.LOADING));
    const questionReviewDate = format(new Date(), 'yyyyMMdd_hhmmss');
    const response = await exportQuestionReviews({
      is_ignored: isIgnored,
      is_labeled: isLabeled,
      include_exported: includeExported,
    });
    downloadFile(response, `${isLabeled ? 'labeled' : 'ignored'}_question_reviews_${questionReviewDate}.zip`);
  } catch (e) {
    console.error(e);
    toast.error(parseErrorResponse(e, 'Unable to download CSV file.'));
  } finally {
    dispatch(setLoaderStatus(STATUSES.LOADED));
  }
};

export const editQuestionReview = async (dispatch, questionReviewId, attributes, isIgnored) => {
  try {
    dispatch(setUpdateLoaderStatus(STATUSES.LOADING));
    dispatch(setCurrentQuestionId(questionReviewId));

    const response = await updateQuestionReview(questionReviewId, {
      attributes,
      is_ignored: isIgnored,
    });

    toast.success(`Question ${isIgnored ? 'removed' : 'attribute set'} successfully.`);
    dispatch(setUpdateLoaderStatus(STATUSES.LOADED));

    return response;
  } catch (e) {
    dispatch(setUpdateLoaderStatus(STATUSES.FAILURE));
    toast.error(parseErrorResponse(e, 'Unable to update question. Please try again.'));
  } finally {
    dispatch(setCurrentQuestionId(null));
  }
};

export const refreshQuestionsList = (state, dispatch, questionReviewId) => {
  const { questions, questionsCount } = state;
  const filteredQuestions = questions.filter((q) => q.id !== questionReviewId);

  if (filteredQuestions.length === 0) {
    dispatch(setFilters({ page: PAGINATION.PAGE }));
  } else {
    dispatch(setQuestions(filteredQuestions, questionsCount - 1));
  }
};
