import React, { useContext } from 'react';
import { Message } from 'semantic-ui-react';
import { ChatsContext } from '../ChatsContext';

export const AnalyticsTemplatePreview = ({ values }) => {
  const { analytics } = useContext(ChatsContext);

  let template_preview = analytics.templates[values.type];
  analytics.fields[values.type].forEach((field, index) => {
    if (values.fields[index]) {
      template_preview = template_preview.replace(`{${field}}`, values.fields[index]);
    }
  });

  return (
    <Message info>
      <h5>Analytics Template Preview</h5>
      <pre>{template_preview}</pre>
    </Message>
  );
};
