import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { USER_TYPES, useUserContext } from 'components/UserContext';
import { If } from 'components/If';
import { Icon, Button, Dimmer, Loader } from 'components/lib';
import {
  Column,
  Header,
  HeaderIconWrapper,
  HeaderTitle,
  HeaderSubtitle,
  ListWrapper,
  ListRow,
  CampaignListButtonWrapper,
  StatisticsContent,
  StatisticsTypesContent,
  campaignRow,
  CampaignListIcons,
} from './common';
import { EmptyCampaigns } from './common/EmptyCampaigns';
import { useCampaigns } from './CampaignsContext';
import { CAMPAIGN_TYPE, NURTURE_CAMPAIGNS_ROUTE, CAMPAIGN_TYPE_LABEL } from '../constants';

const Beta = styled.div`
  width: 46px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #f0f0ff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5f5fff;
  text-transform: uppercase;
  padding-top: 3px;
  margin-left: 10px;
  margin-top: -4px;
`;

const AddCampaignWrapper = styled.div`
  .trigger {
    width: 160px;
    height: 40px;
    margin-top: 20px;
    border-radius: 4px;
    background-color: var(--vivid-blue);
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
`;

const getFollowUpCampaigns = (campaigns) => {
  return campaigns?.filter((campaign) => campaign?.campaignType === CAMPAIGN_TYPE.FOLLOW_UP);
};

const CampaignFollowUpList = () => {
  const {
    state: {
      campaigns,
      campaignsLoading,
      statisticsLoading,
      statistics,
      communitiesCount: totalCommunitiesCount,
      invalidCampaignIds,
    },
    dispatch,
    actions,
  } = useCampaigns();
  const { t } = useTranslation();
  const { isUserType } = useUserContext();
  const history = useHistory();

  const campaignsList = getFollowUpCampaigns(campaigns);

  const viewCampaignDetails = (campaign) => {
    history.push({
      pathname: `${NURTURE_CAMPAIGNS_ROUTE}/${CAMPAIGN_TYPE.FOLLOW_UP}/details/${campaign.id}`,
    });
  };

  const openAddCampaignModal = () => {
    dispatch({
      type: actions.SET_ADD_CAMPAIGN_MODAL_OPEN,
      data: { isOpen: true, campaignType: CAMPAIGN_TYPE.FOLLOW_UP, label: CAMPAIGN_TYPE_LABEL.FOLLOW_UP },
    });
  };

  const listCampaigns = campaignsList?.map((campaign) =>
    campaignRow({
      campaign,
      totalCommunitiesCount,
      isUserType,
      statistics,
      t,
      invalidCampaignIds,
    })
  );

  return (
    <Column>
      <Dimmer active={!campaignsList || campaignsLoading} inverted>
        <Loader active size="large" transparent />
      </Dimmer>
      <Header>
        <HeaderIconWrapper className="follow">
          <Icon name="emoji_events" size="24" color="#ff5b98" />
        </HeaderIconWrapper>
        <div>
          <HeaderTitle>
            Follow-up Campaigns
            <Beta>Beta</Beta>
          </HeaderTitle>
          <HeaderSubtitle>Campaigns for newly created {t('leads')} who have not responded yet.</HeaderSubtitle>
        </div>
      </Header>
      <If condition={!campaignsLoading && campaignsList?.length === 0}>
        <EmptyCampaigns
          title="There are no follow-up campaigns."
          subtitle={
            isUserType([USER_TYPES.STAFF, USER_TYPES.REGULAR])
              ? 'Click below to add a campaign that follows up on leads.'
              : ''
          }
        >
          <If condition={isUserType([USER_TYPES.STAFF, USER_TYPES.REGULAR])}>
            <AddCampaignWrapper>
              <Button onClick={openAddCampaignModal} primary="true" color="blue" type="button" className="trigger">
                Add Campaign
              </Button>
            </AddCampaignWrapper>
          </If>
        </EmptyCampaigns>
      </If>
      <If condition={campaignsList?.length > 0}>
        <ListWrapper>
          {listCampaigns?.map((campaign) => {
            return (
              <ListRow key={campaign?.id} onClick={() => viewCampaignDetails(campaign)}>
                <CampaignListIcons campaign={campaign} />
                <StatisticsContent campaign={campaign} statistics={statistics} statisticsLoading={statisticsLoading} />
                <StatisticsTypesContent campaign={campaign} />
              </ListRow>
            );
          })}
          <If condition={isUserType([USER_TYPES.STAFF, USER_TYPES.REGULAR])}>
            <ListRow>
              <CampaignListButtonWrapper justifyContent="flex-end">
                <Button onClick={openAddCampaignModal} primary="true" color="blue" type="button" className="add-button">
                  Add campaign
                </Button>
              </CampaignListButtonWrapper>
            </ListRow>
          </If>
        </ListWrapper>
      </If>
    </Column>
  );
};

export default CampaignFollowUpList;
