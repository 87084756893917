import React, { createContext, useState, useContext, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UserContext } from 'components/UserContext';
import { logIn, sendOTPLink } from '../../api';
import { STATUSES } from '../../constants';

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [email, setEmail] = useState(null);
  const [loginStatus, setLoginStatus] = useState(STATUSES.IDLE);
  const [magicLinkStatus, setMagicLinkStatus] = useState(STATUSES.IDLE);
  const [loginQueryParams, setLoginQueryParams] = useState();

  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const isLoginRoot = useRouteMatch('/login').isExact;

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const next = queryParams.get('next') || '/';

    if (isLoginRoot) {
      setLoginQueryParams(queryParams);
    }

    if (user) {
      const params = loginQueryParams || queryParams;
      params.delete('next');
      history.push(`${next}?${params}`);
    }
  }, [user, history]);

  const login = async (loginCredentials) => {
    try {
      setLoginStatus(STATUSES.LOADING);
      const response = await logIn(loginCredentials);
      setUser(response);
      setLoginStatus(STATUSES.LOADED);
    } catch (error) {
      console.error(error);

      setUser(null);
      setLoginStatus(STATUSES.FAILURE);

      if (error?.response?.status === 401) {
        throw error;
      } else {
        toast.error(`Something went wrong and we couldn't log you in. Please try again later.`);
      }
    }
  };

  const requestMagicLink = async () => {
    try {
      setMagicLinkStatus(STATUSES.LOADING);
      await sendOTPLink({ email });
      setMagicLinkStatus(STATUSES.LOADED);
    } catch (error) {
      console.error(error);
      toast.error(`Something went wrong and we couldn't send you a magic link. Please try again later.`);
      setUser(null);
      setMagicLinkStatus(STATUSES.FAILURE);
    }
  };

  const provide = {
    email,
    setEmail,
    login,
    requestMagicLink,
    loginQueryParams,
    loginLoading: loginStatus === STATUSES.LOADING,
    magicLinkLoading: magicLinkStatus === STATUSES.LOADING,
    magicLinkStatus,
  };

  return <AuthContext.Provider value={provide} {...props} />;
};

export function useAuthContext() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }
  return context;
}
