import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { PageHeader } from 'components/common';
import { Dimmer, Loader } from 'components/lib';
import { parseErrorResponse } from 'apis/utils';
import { getCRMTypes, getDashboardStats, getDashboardStatsAccountManagers, getDashboardStatsCustomers } from 'apis';
import { useUrlSearchParams } from 'utils/hooks';
import { PAGINATION, STATUSES } from 'constants';
import DashboardFilterDropdowns from './DashboardFilterDropdowns';
import DashboardTable from './DashboardTable';
import { parseNumberArrayQueryParam } from './utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
`;

const Header = styled.div`
  font-size: 60px;
  font-family: var(--font-family-bold);
  margin-bottom: 20px;
`;

const useDashboardFilters = () => {
  const { setUrlSearchParams } = useUrlSearchParams();
  const queryParams = new URLSearchParams(window.location.search);

  const [filters, setFilters] = useState({
    page: queryParams.get('page') || PAGINATION.PAGE,
    pageSize: queryParams.get('pageSize') || PAGINATION.PAGE_SIZE,
    accountManager: parseNumberArrayQueryParam(queryParams.get('accountManager')),
    crmType: parseNumberArrayQueryParam(queryParams.get('crmType')),
    customer: parseNumberArrayQueryParam(queryParams.get('customer')),
  });

  useEffect(() => {
    setUrlSearchParams(filters, true);
  }, [filters]);

  return [filters, setFilters];
};

const Dashboard = () => {
  const [error, setError] = useState();
  const [loader, setLoader] = useState(STATUSES.IDLE);
  const [filters, setFilters] = useDashboardFilters();
  const [totalPages, setTotalPages] = useState();
  const [dataRows, setDataRows] = useState([]);

  const [crmTypes, setCRMTypes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [accountManagers, setAccountManagers] = useState([]);

  useEffect(() => {
    const loadDropdownFilters = async () => {
      try {
        const [crmTypes, customers, accountManagers] = await Promise.all([
          getCRMTypes(),
          getDashboardStatsCustomers(),
          getDashboardStatsAccountManagers(),
        ]);

        setCRMTypes(crmTypes);
        setCustomers(customers);
        setAccountManagers(accountManagers);
      } catch (e) {
        const message = parseErrorResponse(e, 'Unable to load data. Please try again.');
        setError(message);
      }
    };

    loadDropdownFilters();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoader(STATUSES.LOADING);

        const { results, count } = await getDashboardStats(filters);
        setDataRows(results);
        setTotalPages(Math.ceil(count / filters.pageSize));

        setLoader(STATUSES.LOADED);
      } catch (e) {
        const message = parseErrorResponse(e, 'Unable to load data. Please try again.');
        setError(message);
        setLoader(STATUSES.FAILURE);
      }
    };

    fetch();
  }, [filters]);

  const handleFilterChange = (filter) => (value) => {
    setFilters({
      ...filters,
      [filter]: value || [],
      page: PAGINATION.PAGE,
    });
  };

  const handlePageChange = (page) =>
    setFilters({
      ...filters,
      page,
    });

  return (
    <Container>
      <Dimmer active={loader === STATUSES.LOADING} inverted>
        <Loader active />
      </Dimmer>

      <PageHeader
        as="h2"
        title="Customer Move Ins Stats"
        padding={PageHeader.PADDING.VERTICAL}
        margin={PageHeader.MARGIN.NONE}
      />
      <DashboardFilterDropdowns
        accountManagers={accountManagers}
        crmTypes={crmTypes}
        customers={customers}
        filters={filters}
        handleManagerChange={handleFilterChange('accountManager')}
        handleCustomerChange={handleFilterChange('customer')}
        handleCrmTypeChange={handleFilterChange('crmType')}
      />
      <DashboardTable
        dataRows={dataRows}
        error={error}
        handlePageChange={handlePageChange}
        loader={loader}
        page={filters.page}
        totalPages={totalPages}
      />
    </Container>
  );
};

export default Dashboard;
