import React, { useRef, useState } from 'react';

import { Button, Popup } from 'components/lib';

export const PlaceholderButton = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleOpen = () => {
    setIsOpen(true);
    navigator.clipboard.writeText(`{${children}}`);

    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 1000);
  };

  const handleClose = () => {
    setIsOpen(false);
    clearTimeout(timeoutRef.current);
  };

  return (
    <Popup
      key={children}
      trigger={
        <Button
          size="mini"
          compact
          // we are using style here instead of styled components because otherwise the tooltip position is broken
          style={{ margin: '3px 5px', backgroundColor: '#e1f0ff', color: '#2791ff', fontSize: '10px' }}
        >
          {children}
        </Button>
      }
      content="Copied to clipboard!"
      on="click"
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      className="compact"
    />
  );
};
