import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { InputField } from 'components/common';
import { CsvField } from 'components/CsvField';
import { Dropdown, FURHTER_ICON, Form, MultiSelectDropdown, Icon } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { useContactRules } from './ContactRuleContext';

const SelectedCommunitiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 30px;
  max-height: 200px;
  overflow-y: auto;
`;

const SelectedCommunity = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px 2px 10px;
  border-radius: 12px;
  background-color: rgba(16, 42, 97, 0.1);
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--menu-navy);
  .${FURHTER_ICON} {
    margin-left: 10px;
  }
`;

const ContactRuleForm = ({ values, setFieldValue }) => {
  const { t } = useTranslation();
  const { formConfig, communities } = useContactRules();

  const handleCommunitiesChange = (value, setFieldValue) => {
    setFieldValue(
      'communities',
      value.map((community) => community.value)
    );
  };

  const handleCommunityRemove = (values, communityId, setFieldValue) => {
    setFieldValue(
      'communities',
      values.communities.filter((id) => id !== communityId)
    );
  };

  return (
    <Form>
      <Form.Field>
        <InputField type="text" label="Name" name="name" placeholder="Name" />
      </Form.Field>
      <Form.Group>
        <Form.Field width={6}>
          <InputField
            type="text"
            label="Match"
            name="match"
            basic
            placeholder="Match"
            control={Dropdown}
            options={formConfig.matchOptions.map((o) => ({
              key: o.value,
              value: o.value,
              text: o.text,
            }))}
            fluid
            search
            selection
            clearable
          />
        </Form.Field>
        <Form.Field width={4}>
          <InputField
            type="text"
            label="Condition"
            name="condition"
            placeholder="Condition"
            basic
            control={Dropdown}
            options={formConfig.conditionOptions.map((o) => ({
              key: o.value,
              value: o.value,
              text: o.text,
            }))}
            fluid
            search
            selection
            clearable
          />
        </Form.Field>
        <Form.Field width={6}>
          <InputField type="text" label="Value" name="value" placeholder="Value" />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <label>Emails</label>
        <CsvField
          name="emails"
          placeholder="Emails"
          csvElementValidator={Yup.string().email('Email is not valid')}
          error={true}
        />
      </Form.Field>
      <Form.Field width="16">
        <label>{capitalize(t('communities'))}</label>
        <MultiSelectDropdown
          options={communities}
          placeholder={`Search ${t('communities')} to add`}
          selectedValues={values.communities}
          onChange={(value) => handleCommunitiesChange(value, setFieldValue)}
        />
      </Form.Field>
      <SelectedCommunitiesContainer>
        {values.communities.map((communityId) => {
          const community = communities.find((c) => c.id === communityId);
          if (!community) return null;

          return (
            <SelectedCommunity key={community.id} color={values.color}>
              {community.name}
              <Icon size="14" name="close" onClick={() => handleCommunityRemove(values, community.id, setFieldValue)} />
            </SelectedCommunity>
          );
        })}
      </SelectedCommunitiesContainer>
    </Form>
  );
};

export default ContactRuleForm;
