import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { List, Grid, Popup } from 'semantic-ui-react';

import DotsActionButton from './DotsActionButton';
import DeleteCommunitySubjectOverrideModal from './DeleteCommunitySubjectOverrideModal';
import { useEmailSubjects } from './EmailSubjectsContext';
import { STATUSES } from '../../../constants';

const HeaderRow = styled(Grid.Row)`
  font-weight: 800;
  border-bottom: 1px solid lightgrey;
`;

const ContentRow = styled(Grid.Row)`
  border-bottom: 1px solid lightgrey;
`;

const CommunitiesContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  max-width: 200px;
`;

const StyledList = styled(List)`
  max-height: 400px;
  overflow-y: auto;
`;

const CommunityEmailSubjectsTable = ({
  currentClientId,
  loaderStatus,
  communityEmailSubjects,
  onCommunityEmailSubjectDelete,
}) => {
  const { emailTypes } = useEmailSubjects();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedEmailSubject, setSelectedEmailSubject] = useState(null);

  const setOpenModal = (userOverride) => () => {
    setSelectedEmailSubject(userOverride);
    setDeleteModalOpen(true);
  };

  const onConfirm = async () => {
    if (!selectedEmailSubject) return;

    await onCommunityEmailSubjectDelete(selectedEmailSubject);

    setSelectedEmailSubject(null);
    setDeleteModalOpen(false);
  };

  const onCloseModal = () => {
    setSelectedEmailSubject(null);
    setDeleteModalOpen(false);
  };

  return (
    <>
      <Grid stackable>
        <HeaderRow>
          <Grid.Column width={7}>Subject Override</Grid.Column>
          <Grid.Column width={3}>Type</Grid.Column>
          <Grid.Column width={4}>Community</Grid.Column>
          <Grid.Column width={2} />
        </HeaderRow>
        {communityEmailSubjects.map((communityOverride) => (
          <ContentRow key={`${communityOverride.type}-${communityOverride.text}`} verticalAlign="middle">
            <Grid.Column width={7}>
              <Link
                to={{
                  pathname: `/admin/custom-email-subjects/${currentClientId}/edit/community`,
                  state: { selectedEmailSubject: communityOverride },
                }}
              >
                {communityOverride.text}
              </Link>
            </Grid.Column>
            <Grid.Column width={3}>{emailTypes[communityOverride.type]}</Grid.Column>
            <Grid.Column width={4}>
              <Popup
                trigger={
                  <CommunitiesContainer>
                    {communityOverride.communities.map((item) => item.name).join(', ')}
                  </CommunitiesContainer>
                }
                hoverable
                wide
                basic
              >
                <StyledList>
                  {communityOverride.communities.map((c) => (
                    <List.Item key={c.id} content={c.name} />
                  ))}
                </StyledList>
              </Popup>
            </Grid.Column>
            <Grid.Column width={2}>
              <DotsActionButton onClick={setOpenModal(communityOverride)} title="Delete Customizations" />
            </Grid.Column>
          </ContentRow>
        ))}
      </Grid>

      <DeleteCommunitySubjectOverrideModal
        open={isDeleteModalOpen}
        loading={loaderStatus === STATUSES.LOADING}
        onClose={onCloseModal}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default CommunityEmailSubjectsTable;
