import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header, Table, Button, Checkbox, Dimmer, Loader, Message, Modal } from 'semantic-ui-react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getChatProperties } from 'apis';
import { setChatLive } from '../../api';
import { STATUSES } from '../../constants';

const Wrapper = styled.div`
  position: relative;
  min-height: 35rem;
  .ui.header {
    margin-bottom: 4rem;
  }
`;

const ModalText = styled.p`
  font-size: 1.1rem;
`;

export const SetLive = () => {
  const { chatId } = useParams();
  const [chatProperties, setChatProperties] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [confirmPublish, setConfirmPublish] = useState(false);
  const [loadingSetLive, setLoadingSetLive] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const loadCommunities = async () => {
      setLoaderStatus(STATUSES.LOADING);
      try {
        const response = await getChatProperties({ chat: chatId });
        if (response?.results) {
          const sortedProps = response.results.sort((p1, p2) => (p1.community_name < p2.community_name ? -1 : 1));
          setChatProperties(sortedProps);
        }
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        setLoaderStatus(STATUSES.FAILURE);
      }
    };
    loadCommunities();
  }, [chatId]);

  const submit = async () => {
    try {
      setLoadingSetLive(true);
      await setChatLive(chatId, {
        community_chat_visibility: chatProperties.map((p) => ({ community: p.community, show_chat: p.show_chat })),
      });
      toast.success('Chat was set live across selected locations');
      history.push(`/chats/${chatId}`);
    } catch (e) {
      toast.error('Error: something went wrong and the chat could not be set live.');
    } finally {
      setConfirmPublish(false);
      setLoadingSetLive(false);
    }
  };

  return (
    <>
      <Wrapper>
        <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        {loaderStatus === STATUSES.LOADED && (
          <>
            <Header as="h2">
              <Header.Content>
                Set Chat Live
                <Header.Subheader>Chat will be visible for the locations selected below.</Header.Subheader>
              </Header.Content>
              <Button
                floated="right"
                primary
                content="Set Live"
                onClick={() => {
                  setConfirmPublish(true);
                }}
              />
            </Header>

            <Table singleLine basic>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Location</Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">
                    <Checkbox
                      label="Show Chat"
                      checked={chatProperties?.every((p) => p.show_chat)}
                      onChange={(_, d) => {
                        setChatProperties(chatProperties.map((p) => ({ ...p, show_chat: d.checked })));
                      }}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {chatProperties &&
                  chatProperties.map((prop, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{prop?.community_name}</Table.Cell>

                      <Table.Cell textAlign="center">
                        <Checkbox
                          checked={prop.show_chat}
                          onChange={(e, d) => {
                            setChatProperties(
                              chatProperties.map((p) => ({
                                ...p,
                                show_chat: p.id === prop.id ? d.checked : p.show_chat,
                              }))
                            );
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </>
        )}

        {loaderStatus === STATUSES.FAILURE && (
          <Message negative>
            <Message.Header>Couldn't load locations</Message.Header>
            <p>Something went wrong and we couldn't load available locations. Please try again later.</p>
          </Message>
        )}
      </Wrapper>

      <Modal size="mini" open={confirmPublish}>
        <Modal.Content>
          <ModalText>
            <strong>Are you sure you want to make these changes?</strong>
            <br />
            <br />
            This will make the chat visible only for the selected locations.
          </ModalText>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={loadingSetLive}
            onClick={() => {
              setConfirmPublish(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            loading={loadingSetLive}
            disabled={loadingSetLive}
            onClick={() => {
              submit();
            }}
          >
            Set Live
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
