import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { capitalize } from 'utils/helpers';
import { Form, Radio, TextArea, Dropdown, Popup } from 'components/lib';
import { STATUSES } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';

const ModalBody = styled.div``;

const RadioWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  margin-bottom: 10px;
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  > span {
    margin-left: 10px;
    padding-top: 6px;
  }
`;

const RadioText = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
`;

const RadioTextTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--text);
`;

const TextAreaField = styled(Form.Field)`
  height: 102px;
  textarea {
    width: 409px;
    height: 102px;
    padding: 8px 5px 0 12px !important;
    border: solid 1px var(--line-standard) !important;
    background-color: var(--input-field-fill) !important;
    margin-bottom: 20px;
    :disabled::placeholder {
      opacity: 0.5;
    }
  }

  label {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--text);
    vertical-align: top;
    margin-right: 10px;
    display: inline-block;
    margin-top: 5px;
    width: 60px;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  label {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--text);
    margin-right: 10px;
    width: 60px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    width: 182px;
  }
`;

const Note = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  padding-left: 73px;
  margin-bottom: 20px;
`;

const Insert = styled.div`
  width: 409px;
  height: 30px;
  border: solid 1px var(--line-standard);
  border-top: none;
  background-color: #fff;
  margin-bottom: 20px;
  margin-left: 70px;
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  padding: 0 10px;
`;

const Placeholder = styled.span`
  height: 20px;
  padding: 3px 6px 3px 5px;
  border-radius: 4px;
  background-color: #e1f0ff;
  font-size: 10px;
  color: #2791ff;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

const OUTBOUND_SMS_NOTIFICATION_DELAY = [
  { key: '1', text: '1 minute before call', value: 1 },
  { key: '2', text: '2 minutes before call', value: 2 },
  { key: '3', text: '3 minutes before call', value: 3 },
  { key: '4', text: '4 minutes before call', value: 4 },
  { key: '5', text: '5 minutes before call', value: 5 },
];

const VALIDATION_SCHEMA = Yup.object({
  enable_sms_notifications_for_outbound_calls: Yup.boolean(),
  outbound_sms_notification_delay: Yup.number().required('Send delay is missing.'),
  outbound_sms_notification_text: Yup.string().when('enable_sms_notifications_for_outbound_calls', {
    is: true,
    then: Yup.string().required('Message is required when Pre-Call SMS is enabled.'),
  }),
});

export const PreCallSmsModal = ({ isModalOpen, selectedItem, flagName, totalItemsCount, onSave, onClose }) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { t } = useTranslation();

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    await onSave(values);

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const initialValues = {
    outbound_sms_notification_text: selectedItem?.outbound_sms_notification_text || '',
    [flagName]: selectedItem?.[flagName],
    outbound_sms_notification_delay: selectedItem?.outbound_sms_notification_delay || 1,
  };

  const tags = [
    { key: '{community_name}', label: `${capitalize(t('community'))} Name`, enabled: true },
    { key: '{first_name}', label: 'First Name', enabled: true },
    { key: '{last_name}', label: 'Last Name', enabled: true },
  ];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ dirty, handleSubmit, values, resetForm, setFieldValue, isValid }) => {
        return (
          <SettingsModal
            loading={loading}
            open={isModalOpen}
            onModalClose={() => handleClose(resetForm)}
            size="tiny"
            width={540}
          >
            <SettingsModalHeader
              title="Pre-Call SMS"
              community={selectedItem || null}
              totalItemsCount={totalItemsCount}
              type="header"
            />
            <ModalBody type="body">
              <RadioText>
                <RadioTextTitle>Pre-Call SMS</RadioTextTitle>
                Allow salespeople to send an automated pre-call SMS to prospects, notifying them that they will be
                receiving a call soon.
              </RadioText>
              <RadioWrapper>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name={flagName}
                    value={true}
                    checked={Boolean(values[flagName])}
                    onChange={() => {
                      setFieldValue(flagName, true);
                    }}
                  />
                  <span>On</span>
                </CustomRadio>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name={flagName}
                    value={false}
                    checked={String(values[flagName]) === String(false)}
                    onChange={() => {
                      setFieldValue(flagName, false);
                    }}
                  />
                  <span>Off</span>
                </CustomRadio>
              </RadioWrapper>
              <FieldWrapper>
                <label>Send</label>
                <Form.Field
                  control={StyledDropdown}
                  fluid
                  placeholder="Select"
                  selection
                  disabled={!values[flagName]}
                  options={OUTBOUND_SMS_NOTIFICATION_DELAY}
                  value={values.outbound_sms_notification_delay}
                  onChange={(_, d) => {
                    setFieldValue('outbound_sms_notification_delay', d.value);
                  }}
                  name="outbound_sms_notification_delay"
                />
              </FieldWrapper>
              <Note>Note that salespeople can still choose to skip this and call immediately.</Note>
              <TextAreaField
                control={TextArea}
                name="outbound_sms_notification_text"
                label="Message"
                disabled={!values[flagName]}
                value={values.outbound_sms_notification_text}
                placeholder={`Enter SMS message (i.e. 'You are about to receive a call from a representative of ${capitalize(
                  t('community')
                )} Name.')`}
                onChange={(e, { value }) => {
                  setFieldValue('outbound_sms_notification_text', value);
                }}
              />
              <Insert>
                Insert
                {tags.map((tag) => {
                  return (
                    <Popup
                      key={tag.key}
                      disabled={!tag.enabled || !tag.text}
                      content={tag.text}
                      trigger={
                        <Placeholder
                          onClick={() =>
                            setFieldValue(
                              'outbound_sms_notification_text',
                              `${values.outbound_sms_notification_text || ''} ${tag.key}`
                            )
                          }
                          disabled={!tag.enabled}
                        >
                          {tag.label}
                        </Placeholder>
                      }
                    />
                  );
                })}
              </Insert>
            </ModalBody>
            <SettingsModalActions
              onSave={handleSubmit}
              onCancel={() => handleClose(resetForm)}
              type="actions"
              disabled={!dirty || !isValid}
              saveText="Save"
            />
          </SettingsModal>
        );
      }}
    </Formik>
  );
};
