import React, { createContext } from 'react';

import useDataList from 'utils/hooks/useDataList';

export const UserAccountsContext = createContext();

export const UserAccountsContextProvider = (props) => {
  const provide = useDataList();

  return <UserAccountsContext.Provider value={provide} {...props} />;
};
