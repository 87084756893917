import React from 'react';
import styled, { css } from 'styled-components';

import { useCheckMobileScreen } from 'utils/hooks';
import { NavigationItemsContainer } from './NavigationItemsContainer';
import { Sidebar } from './Sidebar';

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 0 12px 0;

  h4 {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.43px;
    color: var(--light-text);
    width: 100%;
    padding: 7px 20px 4px 20px;
    text-transform: upperCase;
  }
`;

const DesktopContainer = styled(Sidebar)`
  left: 230px;
  width: 210px;
  border-right: 1px solid var(--line-standard);
  background-color: var(--input-field-fill);

  .navlink {
    span {
      color: #222222;
    }

    .btn-icon {
      color: var(--light-text);
    }

    &:hover,
    &.active {
      border-top: 1px solid var(--line-standard);
      border-bottom: 1px solid var(--line-standard);
      background-color: var(--white);
      .btn-icon {
        color: #fa7550;
      }
    }
    &:hover + &.active,
    &.active + &:hover {
      border-top-color: transparent;
    }
  }
`;

const MobileContainer = styled.div`
  background-color: #0c214e;
`;

export const SubNavigation = ({ title, children }) => {
  const isMobile = useCheckMobileScreen();
  const Container = isMobile ? MobileContainer : DesktopContainer;

  return (
    <Container>
      {!isMobile && (
        <Title>
          <h4>{title}</h4>
        </Title>
      )}
      <NavigationItemsContainer>{children}</NavigationItemsContainer>
    </Container>
  );
};
