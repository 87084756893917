import styled from 'styled-components';

export const Main = styled.div`
  flex: 5;
  padding: 4rem;
  min-height: 85vh;

  & > .ui.header {
    margin-bottom: 4rem;
  }

  .ui.fixed.table {
    .ui.form {
      .field {
        position: relative;

        .prompt.label {
          position: absolute;
          left: 0;
          bottom: -2.5rem;
          z-index: 1;
        }
      }
    }
    .ui.dropdown > .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 99%;
    }

    tr.ui.form {
      td,
      th {
        overflow: visible;
      }
    }
  }
`;
