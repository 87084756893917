import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import FontPicker from 'font-picker-react';
import styled from 'styled-components';

import { If } from './If';

const FINISHED_STATUS = 'finished';

export const WrappedFontPicker = ({
  name,
  label = 'Or choose one',
  placeholder = 'Chose font',
  className = '',
  fontSizeFieldName = 'font_size',
}) => {
  const [field, , { setValue }] = useField({ name });
  const [value, setLocalValue] = useState(undefined);
  const [fontSizeField] = useField({ name: fontSizeFieldName });
  const picker = useRef();
  const fieldValue = field.value || '';

  useEffect(() => {
    const {
      current: {
        fontManager: { fonts },
      },
    } = picker;
    if (picker.current?.state.loadingStatus === FINISHED_STATUS) {
      setLocalValue(fonts.get(fieldValue)?.family || '');
    } else {
      // have to do pooling to know when picker loaded fonts :(
      const interval = setInterval(() => {
        if (picker.current?.state.loadingStatus !== FINISHED_STATUS) {
          return;
        }
        setLocalValue(fonts.get(fieldValue)?.family || '');
        clearInterval(interval);
      }, 200);
    }
  }, [fieldValue]);

  const handleFontChange = (nextFont) => {
    if (nextFont.family) {
      setValue(nextFont.family);
    }
  };

  return (
    <Wrapper className={className}>
      <label>{label}</label>
      <If condition={placeholder && !value}>
        <Placeholder>{placeholder}</Placeholder>
      </If>
      <FontPicker
        ref={picker}
        apiKey={process.env.REACT_APP_GOOGLE_FONTS_API_KEY}
        activeFontFamily={value}
        onChange={handleFontChange}
        filter={(font) => !font.family.toLowerCase().startsWith('material')}
      />
      <If condition={value}>
        <p
          className="apply-font"
          style={{ padding: '5px', fontSize: parseInt(fontSizeField.value), fontFamily: field.value }}
        >
          Font preview
        </p>
      </If>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  label {
    display: inline-block;
    margin-bottom: 0.6rem;
  }

  button {
    background: transparent !important;
  }

  ul {
    background: white !important;
  }

  &.top-padded {
    padding-top: 8px;
  }

  div[id^='font-picker'] {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 3px;
    box-shadow: unset;
    width: 100%;

    &.expanded {
      border-color: #85b7d9;
    }
  }
`;

const Placeholder = styled.div`
  color: #c7c7c7;
  position: absolute;
  top: 40px;
  left: 20px;
`;
