import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'components/lib';
import { UserContext } from 'components/UserContext';
import { capitalize } from 'utils/helpers';
import { If } from '../../If';
import { USER_TYPES } from '../../../constants';

const ConversationViewOptions = ({
  conversation,
  onResubmitToCRM,
  onResubmitAdditionalActionToCRM,
  onResubmitTourToCRM,
  onResubmitCallToCRM,
  onDeleteLead,
}) => {
  const { t } = useTranslation();
  const { getSessionOriginalUser } = useContext(UserContext);
  const user = getSessionOriginalUser();
  const isStaffUser = user.type === USER_TYPES.STAFF;
  const hasAdditionalCRMLeadActions = conversation?.details?.crm_lead_action_result !== null;
  const hasCompletedAdditionalCRMLeadActions = !!conversation?.details?.crm_lead_action_result;
  const hasTourSubmittedToCRM =
    conversation?.details?.lead_submitted &&
    conversation?.details?.has_crm_tour_submitted !== null &&
    !conversation?.details?.has_crm_tour_submitted;

  const hasCallSubmittedToCRM =
    conversation?.details?.lead_submitted &&
    conversation?.details?.has_crm_call_submitted !== null &&
    !conversation?.details?.has_crm_call_submitted;

  return (
    <>
      <Dropdown.Item onClick={onResubmitToCRM}>Submit to CRM</Dropdown.Item>
      <If condition={hasAdditionalCRMLeadActions && !hasCompletedAdditionalCRMLeadActions}>
        <Dropdown.Item onClick={onResubmitAdditionalActionToCRM}>
          Resubmit additional {t('lead')} action to CRM
        </Dropdown.Item>
      </If>
      <If condition={hasTourSubmittedToCRM}>
        <Dropdown.Item onClick={onResubmitTourToCRM}>Resubmit tour to CRM</Dropdown.Item>
      </If>
      <If condition={hasCallSubmittedToCRM}>
        <Dropdown.Item onClick={onResubmitCallToCRM}>Resubmit call to CRM</Dropdown.Item>
      </If>
      <If condition={isStaffUser}>
        <Dropdown.Item onClick={onDeleteLead}>Delete {capitalize(t('lead'))}</Dropdown.Item>
      </If>
    </>
  );
};

export default ConversationViewOptions;
