import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Accordion, Message, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { listSEOAnalyses, runSEOAnalysis } from 'apis';
import { PAGE_HEADER_MARGIN, PAGE_HEADER_PADDING, PageHeader, PromptButton } from '../../common';
import { SEOAnalysisPreview } from './SEOAnalysisPreview';
import { STATUSES } from '../../../constants';
import { If } from '../../If';

const SEOAnalysis = () => {
  const { communityId } = useParams();
  const { t } = useTranslation();
  const [analyses, setAnalyses] = useState([]);
  const [analysesFetchStatus, setAnalysesFetchStatus] = useState(STATUSES.IDLE);
  const [analysisStatus, setAnalysisStatus] = useState(STATUSES.IDLE);

  useEffect(() => {
    const fetchSEOAnalysis = async () => {
      try {
        setAnalysesFetchStatus(STATUSES.LOADING);
        const response = await listSEOAnalyses(parseInt(communityId));
        setAnalyses(response);
        setAnalysesFetchStatus(STATUSES.LOADED);
      } catch (e) {
        setAnalysesFetchStatus(STATUSES.FAILURE);
        toast.error('Trouble fetching SEO analysis results!');
      }
    };

    fetchSEOAnalysis();
  }, [communityId]);

  const onRunAnalysis = async () => {
    try {
      setAnalysisStatus(STATUSES.LOADING);
      const response = await runSEOAnalysis(communityId);
      const newAnalyses = [response, ...analyses];
      setAnalyses(newAnalyses);
      setAnalysisStatus(STATUSES.LOADED);
      toast.success('Analysis finished successfully.');
    } catch (e) {
      setAnalysisStatus(STATUSES.FAILURE);
      toast.error('Error while running SEO analysis!');
    }
  };

  const panels = analyses.map((analysis, index) => {
    const formatted_date = format(new Date(analysis.created_on), 'MMMM, dd yyyy HH:mm');
    const title = index === 0 ? `${formatted_date} (Latest)` : formatted_date;
    return {
      key: `${analysis.created_on}`,
      title,
      content: {
        content: <SEOAnalysisPreview analysis={analysis} />,
      },
    };
  });

  const hasAnalyses = analyses.length > 0;

  return (
    <Segment basic loading={analysesFetchStatus === STATUSES.LOADING}>
      <PageHeader
        title="Currently available analyses"
        subtitle="Analysis list is sorted in descending order of creation date."
        margin={PAGE_HEADER_MARGIN.MEDIUM}
        padding={PAGE_HEADER_PADDING.NONE}
        actions={
          <PromptButton
            title="Are you sure you want to run SEO analysis?"
            loading={analysisStatus === STATUSES.LOADING}
            description="Analysis could take some time and some of the parameter calculation will be delayed."
            content="Run Analysis"
            onConfirm={onRunAnalysis}
          />
        }
      />
      <Message info content={`No analysis available for this $${t('community')}.`} hidden={hasAnalyses} />
      <If condition={hasAnalyses}>
        <Accordion defaultActiveIndex={0} styled fluid panels={panels} />
      </If>
    </Segment>
  );
};

export default SEOAnalysis;
