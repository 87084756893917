import React, { useState } from 'react';
import { Button, Icon, Label, Message, Popup, Table } from 'semantic-ui-react';

import { toast } from 'react-toastify';
import { parseErrorResponse } from 'apis/utils';
import { sendUserInvite } from 'apis';
import { formatAnyDate } from 'components/utils';
import { STATUSES } from '../../../constants';

const UserAccountInvitation = ({ userInvitation }) => {
  const [invitation, setInvitation] = useState(userInvitation);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const handleSendInvitation = async () => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const { sent_dates, activated } = await sendUserInvite(invitation.id);

      setInvitation((draft) => ({
        ...draft,
        sent_dates,
        activated,
      }));
      setLoaderStatus(STATUSES.LOADED);
      toast.success('Invite email sent successfully');
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to send user invitation. Please try again.'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const handleCopySignupURL = () => {
    navigator.clipboard.writeText(invitation.signup_url);
    toast.success('Signup URL copied to clipboard');
  };

  if (!invitation) {
    return (
      <Message
        warning
        icon="cog"
        header="Invitation is not set"
        content="Please edit user details and enable invitation."
      />
    );
  }

  if (invitation.activated) {
    return (
      <Message
        info
        icon="info"
        header="Invitation accepted!"
        content="User accepted the invitation and registered successfully."
      />
    );
  }

  return (
    <Table definition>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={3}>Invites sent on</Table.Cell>
          <Table.Cell>
            <Label.Group circular>
              {invitation.sent_dates?.map((sd, i) => (
                <Label key={i}>{formatAnyDate(sd, 'MMMM do yyyy, h:mm:ss a')}</Label>
              ))}
            </Label.Group>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={3}>Expires in days</Table.Cell>
          <Table.Cell>{invitation.expires_in}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={3}>Signup URL</Table.Cell>
          <Table.Cell>
            <Label basic>
              {invitation.signup_url}
              <Label.Detail>
                <Popup
                  content="Copy to clipboard"
                  inverted
                  trigger={<Icon link name="copy" onClick={handleCopySignupURL} />}
                />
              </Label.Detail>
            </Label>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell colSpan="2">
            <Button
              primary
              content="Send invitation"
              size="tiny"
              floated="right"
              onClick={handleSendInvitation}
              loading={loaderStatus === STATUSES.LOADING}
              disabled={loaderStatus === STATUSES.LOADING}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default UserAccountInvitation;
