import React from 'react';

import styled from 'styled-components';

import { Divider, Tab } from 'components/lib';
import { UserCommunityHomeSidebar } from './UserCommunityHomeSidebar';
import { UserCommunityHomePeople } from './UserCommunityHomePeople';
import { UserCommunityHomeHeader } from './UserCommunityHomeHeader';

const CommunityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    font-family: var(--font-family-bold);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--text);
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px;
`;

export const UserCommunityHome = () => (
  <Tab.Pane attached={false}>
    <CommunityWrapper>
      <UserCommunityHomeHeader />
      <Divider />
      <MainContent>
        <UserCommunityHomePeople />
        <UserCommunityHomeSidebar />
      </MainContent>
    </CommunityWrapper>
  </Tab.Pane>
);
