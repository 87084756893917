import React, { useEffect, useState } from 'react';
import { Dropdown } from 'components/lib';
import { getAdminUsers } from 'apis';

const ManageUsersDropdown = ({ onChange, value }) => {
  const [options, setOptions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { results } = await getAdminUsers({ role: 'regular' });
        const users = results.map((user) => ({
          key: user.id,
          value: user.id,
          text: `${user.email}`,
        }));
        setOptions(users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    setSelectedIds(value.map((item) => item.id));
  }, [value]);

  return (
    <Dropdown
      placeholder="Select users"
      fluid
      search
      selection
      options={options}
      onChange={(event, data) => {
        setSelectedIds(data.value);
        onChange(data.value);
      }}
      value={selectedIds}
      multiple
    />
  );
};

export default ManageUsersDropdown;
