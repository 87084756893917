import React from 'react';
import { useField } from 'formik';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledToggleSwitch = styled(Form.Radio)`
  &&& {
    display: flex;
    .ui.toggle.checkbox {
      min-height: 26px;
    }
    .ui.toggle.checkbox input {
      width: 50px;
      height: 26px;
      padding: 0;
    }
    .ui.toggle.checkbox label {
      min-height: 26px;
    }

    .ui.toggle.checkbox label:before {
      width: 50px;
      height: 26px;
      box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.25);
      border: solid 1px rgba(171, 171, 171, 0.29);
      background-image: linear-gradient(to bottom, #efefef, #dedbdb);
      transition: all 0.2s ease;
    }

    .ui.toggle.checkbox label:after {
      left: 3px;
      top: 3px;
      width: 20px;
      height: 20px;
      border: none;
      transition: all 0.2s ease;
    }

    .ui.toggle.checkbox input:checked ~ label:after {
      left: 26px;
      transition: all 0.2s ease;
    }

    .ui.toggle.checkbox input:checked ~ label:before {
      transition: all 0.2s ease;
      box-shadow: inset 0 2px 4px 0 #3ea3b9;
      background: var(--highlight-blue) !important;
    }
  }
`;

export const ToggleSwitch = ({ onChanged, ...props }) => {
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;

  const handleChange = (e, d) => {
    const checked = d ? d.checked : e.target.value;
    setValue(checked);

    if (typeof onChanged === 'function') {
      onChanged(checked);
    }
  };

  return <StyledToggleSwitch toggle {...props} checked={!!value} onChange={handleChange} />;
};
