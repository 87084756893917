import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { Dimmer } from 'components/lib';
import { USER_TYPES, useUserContext } from 'components/UserContext';
import { MEDIA_BREAK_POINTS } from '../../../../constants';
import { CampaignCommunitiesModal } from './CampaignCommunitiesModal';

const StatisticsDataWrapperContainer = styled.div`
  display: flex;
  position: relative;
  min-width: 258px;
`;

const StatisticsDataContainer = styled.div`
  margin-right: 3px;
`;

const StatisticsDataNumberContainer = styled.span`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-right: 3px;
`;

const StatisticsDataTitleContainer = styled.span`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
`;

const StatisticsWrapperContainer = styled.div`
  margin-left: 10px;
`;

const StatisticsTypeWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
`;

const StatisticsTypesContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: flex-end;
`;

const StatisticsTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  width: 48px;
  height: 18px;
  border-radius: 9px;
  text-transform: uppercase;
  font-family: var(--font-family-bold);
  font-size: 9px;
  line-height: normal;
  letter-spacing: normal;
  &.email {
    background-color: rgba(15, 113, 253, 0.15);
    color: #2791ff;
    margin-right: 5px;
  }
  &.sms {
    background-color: rgba(10, 183, 68, 0.15);
    color: var(--emerald-green);
  }
`;

const StatisticsCommunitiesContainer = styled.div`
  font-family: var(--font-family);
  font-size: 10px;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #89919d;
  color: ${(props) => (props.isClickable ? 'var(--link)' : '#89919d;')};
  &.hover {
    cursor: pointer;
  }
`;

const StatisticsTitleContainer = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  max-width: 480px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.TABLET}px) {
    max-width: 440px;
  }
`;

export const StatisticsDataWrapper = ({ children }) => {
  return <StatisticsDataWrapperContainer>{children}</StatisticsDataWrapperContainer>;
};

export const StatisticsData = ({ children }) => {
  return <StatisticsDataContainer>{children}</StatisticsDataContainer>;
};

export const StatisticsDataNumber = ({ children }) => {
  return <StatisticsDataNumberContainer>{children}</StatisticsDataNumberContainer>;
};

export const StatisticsDataTitle = ({ children }) => {
  return <StatisticsDataTitleContainer>{children}</StatisticsDataTitleContainer>;
};

export const StatisticsWrapper = ({ children }) => {
  return <StatisticsWrapperContainer>{children}</StatisticsWrapperContainer>;
};

export const StatisticsTypeWrapper = ({ children }) => {
  return (
    <StatisticsTypeWrapperContainer onClick={(e) => e.stopPropagation()}>{children}</StatisticsTypeWrapperContainer>
  );
};

export const StatisticsTypes = ({ children }) => {
  return <StatisticsTypesContainer>{children}</StatisticsTypesContainer>;
};

export const StatisticsType = ({ children, type }) => {
  return <StatisticsTypeContainer className={type}>{children}</StatisticsTypeContainer>;
};

export const StatisticsCommunities = ({ children, onClick, isClickable }) => {
  return (
    <StatisticsCommunitiesContainer onClick={onClick} isClickable={isClickable}>
      {children}
    </StatisticsCommunitiesContainer>
  );
};

export const StatisticsTitle = ({ children }) => {
  return <StatisticsTitleContainer>{children}</StatisticsTitleContainer>;
};

const RelatedCommunitiesWrapper = styled.div`
  min-width: 100px;

  &.hover {
    cursor: pointer;
  }
`;

export const StatisticsContent = ({ campaign, statistics, statisticsLoading }) => {
  const { t } = useTranslation();
  return (
    <StatisticsWrapper>
      <StatisticsTitle>{campaign?.name}</StatisticsTitle>
      <StatisticsDataWrapper>
        <Dimmer active={!statistics || statisticsLoading} inverted />
        <StatisticsData>
          <StatisticsDataNumber>{campaign?.matchingStatisticsData?.leadsCount}</StatisticsDataNumber>
          <StatisticsDataTitle>{t('leads')} &#183;</StatisticsDataTitle>
        </StatisticsData>
        <StatisticsData>
          <StatisticsDataNumber>{campaign?.matchingStatisticsData?.replyRate}%</StatisticsDataNumber>
          <StatisticsDataTitle>replied &#183;</StatisticsDataTitle>
        </StatisticsData>
        <StatisticsData>
          <StatisticsDataNumber>{campaign?.matchingStatisticsData?.toursCount}</StatisticsDataNumber>
          <StatisticsDataTitle>{t('tours')} &#183;</StatisticsDataTitle>
        </StatisticsData>
        <StatisticsData>
          <StatisticsDataNumber>{campaign?.matchingStatisticsData?.moveInsCount}</StatisticsDataNumber>
          <StatisticsDataTitle>{t('move_ins')}</StatisticsDataTitle>
        </StatisticsData>
      </StatisticsDataWrapper>
    </StatisticsWrapper>
  );
};

export const StatisticsTypesContent = ({ campaign }) => {
  const { isUserType } = useUserContext();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnClick = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const onModalClose = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };
  const hasRelatedCommunities = campaign?.relatedCommunitiesCount > 0;
  return (
    <StatisticsTypeWrapper>
      <StatisticsTypes>
        <If condition={campaign?.hasEmail}>
          <StatisticsType type="email">Email</StatisticsType>
        </If>
        <If condition={campaign?.hasSms}>
          <StatisticsType type="sms">SMS</StatisticsType>
        </If>
      </StatisticsTypes>
      <If condition={isUserType([USER_TYPES.REGIONAL])}>
        <RelatedCommunitiesWrapper>
          <StatisticsCommunities>
            {campaign?.communitiesCount} {t('communities')}
          </StatisticsCommunities>
        </RelatedCommunitiesWrapper>
      </If>
      <If condition={isUserType([USER_TYPES.STAFF, USER_TYPES.REGULAR])}>
        <RelatedCommunitiesWrapper className={!campaign?.isAllCommunities && 'hover'}>
          <If condition={campaign?.isAllCommunities}>
            <StatisticsCommunities isClickable={hasRelatedCommunities} onClick={handleOnClick}>
              All {t('communities')}
            </StatisticsCommunities>
          </If>
          <If condition={!campaign?.isAllCommunities}>
            <StatisticsCommunities onClick={handleOnClick} isClickable={hasRelatedCommunities}>
              {campaign?.relatedCommunitiesCount}/{campaign?.communitiesCount} {t('communities')}
            </StatisticsCommunities>
          </If>
          <If condition={isModalOpen && hasRelatedCommunities}>
            <CampaignCommunitiesModal
              campaignId={campaign?.id}
              open={isModalOpen}
              onClose={onModalClose}
              relatedCommunitiesCount={campaign?.relatedCommunitiesCount}
              communitiesCount={campaign?.communitiesCount}
              isAllCommunities={campaign?.isAllCommunities}
            />
          </If>
        </RelatedCommunitiesWrapper>
      </If>
    </StatisticsTypeWrapper>
  );
};
