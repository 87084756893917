import React from 'react';

import { formatAnyDate } from 'components/utils';
import BusinessActivityValueChange from './ValueChange';

export const DateValueChange = ({ value }) => {
  if (!value) {
    return '-';
  }

  const title = formatAnyDate(value, 'MMM d, yyyy');

  return <BusinessActivityValueChange title={title} />;
};
