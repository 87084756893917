import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { If } from 'components/If';
import { NumberIndicator } from './NumberIndicator';
import { LinkWebAssistentModal } from './LinkWebAssistentModal';
import { GlobalQuerySelectorModal } from './GlobalQuerySelectorModal';
import { usePhoneSystem } from './PhoneSystemContext';
import { DEFAULT_PHONE_NUMBER_USAGE_VALUE, PHONE_NUMBER_USAGE_OPTIONS } from './constants';
import { EditPhoneSystemModal } from './EditPhoneSystemModal';
import { ActivatePhoneSystemModal } from './ActivatePhoneSystemModal';

import {
  CommunityRow,
  SettingsBooleanContainer,
  SettingsContainer,
  SettingsDescription,
  SettingsDropdown,
  SettingsFiltersContainer,
  SettingsHeader,
  SettingsOnOffIndicator,
  SettingsPagination,
  SettingsSearch,
  SettingsTable,
  SettingsTableBody,
  SettingsTableCelContent,
  SettingsTableHeaders,
  SettingsTableRow,
  SettingsTableRowContainWithMultipleColumns,
  SettingsValueText,
} from '../common';
import CallTrackingExportModal from './CallTrackingExportModal';

const GlobalSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  label {
    width: 136px;
    height: 21px;
    font-family: var(--font-family-bold);
    font-size: 14px;
    text-align: right;
    color: var(--text);
  }
`;

const GlobalSelector = styled.div`
  width: 250px;
  height: 36px;
  padding: 8px 8px 7px 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--vivid-blue);
  background-color: #f5f8ff;
  display: flex;
  cursor: pointer;
  margin-left: 10px;

  span {
    margin-left: auto;
    user-select: none;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }
`;

const FiltersBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const columnWidths = ['2fr', '63px', '55px', '70px', '101px', '160px'];
const columnGap = '5vw';

export const CallTracking = () => {
  // modal states
  const [linkChatModalOpen, setLinkChatModalOpen] = useState(false);
  const [globalQuerySelectorModalOpen, setGlobalQuerySelectorModalOpen] = useState(false);
  const [isActivateModalOpen, setActivateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const { t } = useTranslation();

  const {
    communities,
    fetchCommunities,
    fetchGlobalQuerySelector,
    filters,
    isLoading,
    getCommunitySyncStatus,
    globalQuerySelector,
    handleFiltersChange,
    handleGlobalsUpdate,
    handlePageChange,
    setSelectedCommunity,
    totalCount,
    totalPages,
  } = usePhoneSystem();

  const TABLE_HEADERS = [
    `${t('community')}`,
    'WEBSITE NUMBERS',
    'BACKUP NUMBER',
    'CAMPAIGN NUMBERS',
    'WEB ASSISTANT LINKED',
    '',
  ];

  useEffect(() => {
    fetchCommunities(filters);
    fetchGlobalQuerySelector();
    return () => {
      handleFiltersChange(null, DEFAULT_PHONE_NUMBER_USAGE_VALUE);
    };
  }, []);

  const openPhoneSystemActivateModal = (community) => {
    if (community.isEnabled) return;

    setSelectedCommunity(community);
    setActivateModalOpen(true);
  };

  const closePhoneSystemActivateModal = () => {
    setSelectedCommunity(null);
    setActivateModalOpen(false);
  };

  const openPhoneSystemEditModal = (community) => {
    if (community.queuedToSync) return;
    if (!community.isEnabled) {
      openPhoneSystemActivateModal(community);
      return;
    }

    setSelectedCommunity(community);
    setEditModalOpen(true);
  };

  const closePhoneSystemEditModal = () => {
    setSelectedCommunity(null);
    setEditModalOpen(false);
  };

  const handleDropdownChange = (e, { value }) => {
    handleFiltersChange(filters.search, value);
  };

  const handleOnSearch = (value) => {
    handleFiltersChange(value, filters.filter);
  };

  const openChatInNewTab = (chatId) => {
    window.open(`/chats/${chatId}`, '_blank');
    if (window.focus) {
      window.focus();
    }
  };

  return (
    <SettingsContainer loading={isLoading} totalCount={totalCount}>
      <SettingsDescription>
        Activate and assign
        <strong> phone numbers </strong>
        for tracking, Conversational IVR and more.
      </SettingsDescription>
      <SettingsHeader>
        <GlobalSelectorContainer onClick={() => setGlobalQuerySelectorModalOpen(true)}>
          <label>Global Web Selector</label>
          <GlobalSelector>
            <p>{globalQuerySelector || 'Select a global web selector'}</p>
            <Icon name="navigate_next" color="var(--vivid-blue)" />
          </GlobalSelector>
        </GlobalSelectorContainer>
        <FiltersBox>
          <SettingsFiltersContainer>
            <SettingsDropdown
              width={260}
              options={PHONE_NUMBER_USAGE_OPTIONS}
              onChange={handleDropdownChange}
              placeholder="Any status"
              value={filters.filter}
            />
            <SettingsSearch
              onSearch={handleOnSearch}
              value={filters.search}
              placeholder={`Search ${t('communities')}`}
              loading={isLoading}
            />
          </SettingsFiltersContainer>
          <CallTrackingExportModal />
        </FiltersBox>
      </SettingsHeader>
      <SettingsTable currentPage={filters.page} items={communities}>
        <SettingsTableHeaders headers={TABLE_HEADERS} headersWidths={columnWidths} columnGap={columnGap} />
        <SettingsTableBody marginTop="0">
          {communities?.map((item, i) => {
            const syncStatus = getCommunitySyncStatus(item);
            return (
              <SettingsTableRow item={item} key={i} onClick={() => openPhoneSystemEditModal(item)}>
                <SettingsTableRowContainWithMultipleColumns columnWidths={columnWidths} columnGap={columnGap}>
                  <CommunityRow name={item.name} picture={item.pictureUrl} clickable={false} />
                  <SettingsTableCelContent>
                    <If condition={item.isEnabled}>
                      <NumberIndicator
                        phoneNumbers={item.regularPhoneNumbers}
                        allowedSize={item.poolSize}
                        enabled={item.isEnabled && !item.queuedToSync}
                      />
                    </If>
                  </SettingsTableCelContent>
                  <SettingsTableCelContent>
                    <If condition={item.isEnabled}>
                      <NumberIndicator
                        phoneNumbers={item.directPhoneNumber ? [item.directPhoneNumber] : []}
                        allowedSize={1}
                        enabled={item.poolEnabled}
                      />
                    </If>
                  </SettingsTableCelContent>
                  <SettingsTableCelContent>
                    <If condition={item.isEnabled}>
                      <NumberIndicator
                        phoneNumbers={item.dedicatedPhoneNumbers}
                        enabled={item.isEnabled && !item.queuedToSync}
                      />
                    </If>
                  </SettingsTableCelContent>
                  <SettingsTableCelContent>
                    <If condition={item.isEnabled}>
                      <SettingsOnOffIndicator
                        status={item.ivrEnabled}
                        onTrue={() => openChatInNewTab(item.chatId)}
                        onFlase={() => setLinkChatModalOpen(true)}
                      />
                    </If>
                  </SettingsTableCelContent>
                  <SettingsBooleanContainer>
                    <SettingsValueText
                      className="wrap-text"
                      color={syncStatus.color}
                      text={syncStatus.text}
                      loading={syncStatus.loading}
                    />
                  </SettingsBooleanContainer>
                </SettingsTableRowContainWithMultipleColumns>
              </SettingsTableRow>
            );
          })}
        </SettingsTableBody>
      </SettingsTable>
      <SettingsPagination
        filters={filters}
        totalPages={totalPages}
        onPageChange={(_, data) => handlePageChange(data.activePage)}
      />
      <LinkWebAssistentModal open={linkChatModalOpen} onClose={() => setLinkChatModalOpen(false)} />
      <GlobalQuerySelectorModal
        isModalOpen={globalQuerySelectorModalOpen}
        onClose={() => setGlobalQuerySelectorModalOpen(false)}
        onSave={handleGlobalsUpdate}
      />
      <ActivatePhoneSystemModal open={isActivateModalOpen} onClose={closePhoneSystemActivateModal} />
      <EditPhoneSystemModal open={isEditModalOpen} onClose={closePhoneSystemEditModal} />
    </SettingsContainer>
  );
};
