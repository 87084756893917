import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Dropdown, Icon, Popup } from 'components/lib';

const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown {
    display: flex;
    height: 51px;
    padding: 10px 10px 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px var(--line-standard);
    background-color: #fff;
    min-width: 175px;
  }
  &&&&& > i {
    padding-right: 0;
  }
  &&& .item span.text {
    font-size: 12px;
  }
`;

const StyledDropdownTitle = styled.div`
  display: flex;
  div {
    &.title-wrapper {
      display: flex;
      flex-direction: column;
    }
    &.icon-wrapper {
      background-color: #f0f0ff;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
    }
  }

  span {
    &.title {
      margin-left: 5px;
      font-size: 12px;
      text-overflow: ellipsis;
      max-width: 120px;
      overflow: hidden;
      white-space: nowrap;
    }
    &.subtitle {
      margin-left: 5px;
      font-size: 10px;
      color: #5f5fff;
      overflow: visible;
    }
  }
`;

const DropDownTitle = ({ text }) => (
  <StyledDropdownTitle>
    <div className="icon-wrapper">
      <Icon name="wysiwyg" variant="outlined" color="#5f5fff" size="10" />
    </div>
    <div className="title-wrapper">
      <Popup
        className="tight"
        basic
        wide
        size="small"
        content={text}
        trigger={<span className="title">{text}</span>}
        on="hover"
      />
      <span className="subtitle">Conversational Web Form</span>
    </div>
  </StyledDropdownTitle>
);

export const WebAssistantDropdownField = (props) => {
  const { options } = props;
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue, setTouched } = helpers;

  const onSelect = (_, d) => {
    setValue(d.value);
    setTouched(true);
  };

  useEffect(() => {
    if (value) {
      setTouched(true);
    }
  }, []);
  const text = options.find((option) => option.value === value)?.text || 'Select Survey VSA';

  return (
    <StyledDropdown {...props} trigger={<DropDownTitle text={text} />} onChange={onSelect} className="selection" />
  );
};
