import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MinimizedCall } from './MinimizedCall';
import { CallCenterModal } from './CallCenterModal';
import { useContactCenter } from './ContactCenterContext';
import { CALL_STATUS } from './constants';

const ContactCenter = () => {
  const history = useHistory();
  const { loading, phoneCall, status, muted, acceptCall, hangupCall, muteCall, completeCall, editCall } =
    useContactCenter();
  const [open, setOpen] = useState(false);
  const [minimized, setMinimized] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (phoneCall && phoneCall.leadId) {
      history.push(`/conversations/${phoneCall.leadId}`);
    }
  }, [phoneCall]);

  useEffect(() => {
    let timeout;
    if (status.value === CALL_STATUS.CLOSED && !isEditMode) {
      timeout = setTimeout(() => {
        setOpen(false);
      }, 2000);
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }
      setOpen(!!phoneCall);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [phoneCall, status, isEditMode]);

  const toggleMinimized = () => {
    setMinimized(!minimized);
  };

  const handleClose = () => {
    completeCall();
    setOpen(false);
  };

  const handleSaveNote = async (note) => {
    try {
      await editCall({ note });
    } catch (e) {
      toast.error('Unable to update call note. Please try again.');
    } finally {
      if (status.value === CALL_STATUS.CLOSED) {
        setOpen(false);
      }
    }
  };

  if (!open) {
    return null;
  }

  return minimized ? (
    <MinimizedCall
      loading={loading}
      phoneCall={phoneCall}
      status={status}
      muted={muted}
      onAcceptCall={acceptCall}
      onHangupCall={hangupCall}
      onMute={muteCall}
      onMaximize={toggleMinimized}
      onClose={handleClose}
      onSaveNote={handleSaveNote}
      onEditMode={setIsEditMode}
    />
  ) : (
    <CallCenterModal onMinimize={toggleMinimized} />
  );
};

export default ContactCenter;
