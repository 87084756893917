import humps from 'humps';

import { API } from '../api';

export const getLeadsConversations = async (params) => {
  const response = await API.get('/api/conversations/', { params });
  return response.data;
};

export const exportLeadsConversations = async (params) => {
  const response = await API.get('/api/conversations/export', { params });
  return response.data;
};

export const getExportLeadConversationsOptions = async () => {
  const response = await API.get('/api/conversations/export/options');
  return response.data;
};

export const getConversations = async (params) => {
  const query = new URLSearchParams(params).toString();
  const response = await API.get(`/api/conversations/leads?${query}`);
  return response.data;
};

export const getArchivedConversations = async () => {
  const response = await API.get(`/api/conversations/leads?is_archived=True`);
  return response.data;
};

export const getMessagesSummary = async () => {
  const response = await API.get(`/api/conversations/leads/summary`);
  return response.data;
};

export const setArchivedConversation = async (id, data) => {
  const response = await API.put(`/api/conversations/leads/${id}/archive`, data);
  return response.data;
};

export const resetLeadConversationDetailsCache = async (id) => {
  const response = await API.get(`/api/conversations/leads/${id}/reset`);
  return response;
};

export const setConversationStatus = async (id, new_status) => {
  const response = await API.put(`/api/conversations/leads/${id}/status`, {
    new_status,
  });
  return response.data;
};

export const setConversationUnread = async (id) => setConversationStatus(id, 'received');
export const setConversationRead = async (id) => setConversationStatus(id, 'read');

export const getLeadConversationDetails = async (leadHash) => {
  const response = await API.get(`/api/conversations/leads/${leadHash}`);
  return response.data;
};

export const sendLeadMessage = async (id, data) => {
  const response = await API.post(`/api/conversations/leads/${id}/messages/send`, data);
  return response.data;
};

export const checkNewMessages = async (id, data) => {
  const response = await API.post(`/api/conversations/leads/${id}/messages/check-new`, data);
  return response.data;
};

export const archiveConversations = async (data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.post('/api/conversations/leads/archive', body);
  return humps.camelizeKeys(response.data);
};

export const messageResend = async (messageType, messageId) => {
  const response = await API.put(`/api/conversations/messages/${messageType}/${messageId}/resend`);
  return humps.camelizeKeys(response.data);
};

export const addLeadNote = async (id, data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.post(`api/leads/${id}/notes`, body);
  return humps.camelizeKeys(response.data);
};

export const updateLeadNote = async (id, data, noteId) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.put(`api/leads/${id}/notes/${noteId}`, body);
  return humps.camelizeKeys(response.data);
};
