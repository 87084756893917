import React from 'react';
import styled from 'styled-components';

import { WithTopBar } from 'components/WithTopBar';
import { ScheduledMessages } from './ScheduledMessages';
import { NurtureFilterProvider } from '../NurtureFilterContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--white);
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.1429rem;
  width: 100%;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 0.5714rem;
`;

const Header = styled.h2`
  font-family: var(--font-family-bold);
  margin: 0rem;
  font-size: 4.2857rem;
  color: var(--text);
`;

const UpHeader = styled.span`
  font-family: var(--font-family-bold);
  font-size: 0.8571rem;
  letter-spacing: 0.0357rem;
  color: var(--light-text);
`;

export const MemberScheduledMessages = () => {
  return (
    <NurtureFilterProvider>
      <WithTopBar>
        <Container>
          <HeaderContainer>
            <UpHeader>TODAY'S TASKS</UpHeader>
            <Header>Scheduled Messages</Header>
          </HeaderContainer>
          <ScheduledMessages />
        </Container>
      </WithTopBar>
    </NurtureFilterProvider>
  );
};
