import React from 'react';
import { useTranslation } from 'react-i18next';

import { BooleanSettingsPage } from '../common';

const SendUnqualifiedLeads = () => {
  const { t } = useTranslation();
  return (
    <BooleanSettingsPage
      flagName="send_unqualified_leads_to_providers"
      modalText={`Choose whether unqualified ${t('leads')} are sent to third party financial providers.`}
      modalTitle={`Send unqualified ${t('leads')} to third party financial providers`}
    />
  );
};

export default SendUnqualifiedLeads;
