import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { ConversationCreateLeadModal } from './ConversationsCreateLeadModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const StyledPopup = styled(Popup)`
  && {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 400;
    color: var(--text);
    width: 200px;
    margin: 2px 46px 30px 82px;
    padding: 14px 15px 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px var(--line-standard);
    background-color: var(--white);
    letter-spacing: normal;
  }
`;

const StyledModal = styled(Modal)`
  && {
    width: 540px;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    font-family: var(--font-family);
    min-width: 136px;
    max-width: 150px;
    height: 40px;
    border-radius: 4px;
    font-weight: 900;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--vivid-blue);
    color: var(--white);
  }
`;

export const ConversationCreateLead = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const handelModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Wrapper>
      <StyledPopup
        position="bottom center"
        offset={[0, 5]}
        trigger={<Icon name="question circle outline" size="large" color="grey" />}
        content={`Add ${t('lead')} who is not currently in the Further system, then text or email them.`}
        on="hover"
      />
      <StyledModal
        open={modalOpen}
        onClose={handleModalClose}
        closeIcon
        trigger={
          <div>
            <StyledButton primary color="blue" onClick={handelModalOpen}>
              Add {capitalize(t('lead'))}
            </StyledButton>
          </div>
        }
        content={<ConversationCreateLeadModal handleModalClose={handleModalClose} onClose={handleModalClose} />}
      />
    </Wrapper>
  );
};
