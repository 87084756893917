import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EmailSubjectsMangement from './EmailSubjectsManagement';
import EmailSubjectsCustomizationNew from './EmailSubjectsCustomizationNew';
import EmailSubjectsCustomizationEdit from './EmailSubjectsCustomizationEdit';
import EmailSubjectsContextProvider from './EmailSubjectsContext';
import EditCommunityEmailSubjectOverview from './EditCommunityEmailSubjectsOverview';
import EditGlobalEmailSubjectOverview from './EditGlobalEmailSubjectOverview';

const EmailSubjectsRoot = () => (
  <EmailSubjectsContextProvider>
    <Switch>
      <Route path="/admin/custom-email-subjects" component={EmailSubjectsMangement} exact />
      <Route path="/admin/custom-email-subjects/new" component={EmailSubjectsCustomizationNew} exact />
      <Route path="/admin/custom-email-subjects/:userId/edit" component={EmailSubjectsCustomizationEdit} exact />
      <Route path="/admin/custom-email-subjects/:userId/edit/global" component={EditGlobalEmailSubjectOverview} exact />
      <Route
        path="/admin/custom-email-subjects/:userId/edit/community"
        component={EditCommunityEmailSubjectOverview}
        exact
      />
    </Switch>
  </EmailSubjectsContextProvider>
);

export default EmailSubjectsRoot;
