import styled from 'styled-components';

export const Container = styled.div.attrs(({ maxWidth }) => ({
  style: {
    maxWidth,
  },
}))`
  width: 100%;
  height: auto;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 4.2857rem 0;
  align-items: center;
  background-color: white;
  &.centered {
    justify-content: center;
  }
  h2 {
    margin: 0;
    margin-top: 1.4286rem;
    margin-bottom: 2rem;
    font-family: var(--font-family-bold);
    font-size: 4.2857rem;
    line-height: 0.97;
    text-align: center;
    color: #222;
    white-space: pre;
  }
  p {
    margin: 0;
    white-space: pre;
    font-family: var(--font-family);
    font-size: 16px;
    text-align: center;
    color: #222;
    b {
      font-family: var(--font-family-bold);
      font-weight: unset;
    }
  }
  button {
    max-width: 500px;
  }
`;
