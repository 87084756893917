import React from 'react';

import { MultiSelectFilter } from 'components/common';
import { LABEL_OPTIONS } from 'components/common/automated_answers/constants';

const LabelsFilter = ({ selectedValues, onChange, allItemsSelectedText = 'All Labels', name = 'Labels', ...other }) => {
  return (
    <MultiSelectFilter
      name={name}
      allItemsSelectedText={allItemsSelectedText}
      options={LABEL_OPTIONS}
      selectedValues={selectedValues}
      onChange={onChange}
      {...other}
    />
  );
};

export default LabelsFilter;
