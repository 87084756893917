import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { getInstantResponsesMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { formatCount, formatPercent, formatDuration } from 'components/utils';
import { PageSection, Statistic, getChangeIcon } from 'components/common';
import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import { useSystemStatsFilters } from './SystemStatsFiltersContext';
import SalesAssistantBasicStats from './SalesAssistantBasicStats';

const SalesAssistantReport = () => {
  const { t } = useTranslation();
  const [metrics, setMetrics] = useState();
  const [prevMetrics, setPrevMetrics] = useState();
  const [loading, setLoading] = useState(false);
  const { dateRange, getPreviousDateRange, selectedUsers, selectedCommunities } = useSystemStatsFilters();

  useEffect(() => {
    const fetchMetrics = async (dateRange, onMetricsLoaded) => {
      try {
        setLoading(true);
        const params = { ...dateRange };
        if (selectedUsers && selectedUsers.length) {
          params.sa_users = selectedUsers.join(',');
        }
        if (selectedCommunities && selectedCommunities.length) {
          params.sa_communities = selectedCommunities.join(',');
        }
        const _metrics = await getInstantResponsesMetrics(params);
        onMetricsLoaded(_metrics);
      } catch (e) {
        toast.error(parseErrorResponse('Error fetching messaging campaign metrics'));
      }
      setLoading(false);
    };

    fetchMetrics(dateRange, setMetrics);
    // fetch stats for previous period
    fetchMetrics(getPreviousDateRange(), setPrevMetrics);
  }, [dateRange, selectedUsers, selectedCommunities]);

  return (
    <div>
      <Dimmer active={!metrics || loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <If condition={metrics}>
        <PageSection>
          <SalesAssistantBasicStats metrics={metrics} prevMetrics={prevMetrics} />
          <Statistic.Group name="Statuses" marginTop="2rem">
            <Statistic
              label="Scheduled Tours"
              value={formatCount(metrics?.scheduled_tours)}
              changeIcon={getChangeIcon(metrics?.scheduled_tours, prevMetrics?.scheduled_tours)}
              tooltip={`Leads that have status set to '${capitalize(t('tour'))} Scheduled'`}
            />
            <Statistic
              label="Scheduled Calls"
              value={formatCount(metrics?.scheduled_calls)}
              changeIcon={getChangeIcon(metrics?.scheduled_calls, prevMetrics?.scheduled_calls)}
              tooltip="Leads that have status set to 'Call Scheduled'"
            />
            <Statistic
              label="Financially Unqualified"
              value={formatCount(metrics?.financially_unqualified)}
              changeIcon={getChangeIcon(metrics?.financially_unqualified, prevMetrics?.financially_unqualified)}
              tooltip="Leads that have status set to 'Financially Unqualified'"
            />
            <Statistic
              label="Total Unqualified"
              value={formatCount(metrics?.total_unqualified)}
              changeIcon={getChangeIcon(metrics?.total_unqualified, prevMetrics?.total_unqualified)}
              tooltip="Leads that have status set to any status that disqualifies lead"
            />
            <Statistic
              label="Waiting for Leads"
              value={formatCount(metrics?.waiting_for_lead)}
              changeIcon={getChangeIcon(metrics?.waiting_for_lead, prevMetrics?.waiting_for_lead, true)}
              tooltip="Active leads that do not have status set and we are waiting to their reply"
            />
          </Statistic.Group>
          <Statistic.Group name="Further Stats" marginTop="2rem">
            <Statistic
              label="Leads Conversations"
              value={formatCount(metrics?.further_replied_leads)}
              changeIcon={getChangeIcon(metrics?.further_replied_leads, prevMetrics?.further_replied_leads)}
              secondaryValue={formatPercent(metrics?.further_replied_leads, metrics?.total_replied_leads)}
              tooltip="Active leads that Further replied to at least once"
            />
            <Statistic
              label="Replied Messages"
              value={formatCount(metrics?.further_replied_messages)}
              changeIcon={getChangeIcon(metrics?.further_replied_messages, prevMetrics?.further_replied_messages)}
              secondaryValue={formatPercent(metrics?.further_replied_messages, metrics?.total_replied_messages)}
              tooltip="Number of replied lead messages by Further"
            />
            <Statistic
              label="Avg. Response Time"
              value={metrics?.further_avg_response_humanized}
              changeIcon={getChangeIcon(
                metrics?.further_avg_response,
                prevMetrics?.further_avg_response,
                true,
                formatDuration
              )}
              tooltip={`Average time between ${t('lead')}'s message and a reply message`}
            />
            <Statistic
              label="Avg. First Message Resp. Time"
              value={metrics?.further_avg_response_first_message_humanized}
              changeIcon={getChangeIcon(
                metrics?.further_avg_response_first_message,
                prevMetrics?.further_avg_response_first_message,
                true,
                formatDuration
              )}
              tooltip={`Average time between ${t('lead')}'s message and a reply message for the very first ${t(
                'lead'
              )}'s message`}
            />
            <Statistic
              label="Avg. First Msg. Resp. T. Work H."
              value={metrics?.further_avg_response_first_message_working_hours_humanized}
              changeIcon={getChangeIcon(
                metrics?.further_avg_response_first_message_working_hours,
                prevMetrics?.further_avg_response_first_message_working_hours,
                true,
                formatDuration
              )}
              tooltip={`Average time between ${t('lead')}'s message and a reply message for the very first ${t(
                'lead'
              )}'s message that is received within working hours`}
            />
            <Statistic
              label="Rereplies"
              value={formatCount(metrics?.rereplies)}
              changeIcon={getChangeIcon(metrics?.rereplies, prevMetrics?.rereplies, true)}
              tooltip="Cases when community replied to the same message as Further"
            />
          </Statistic.Group>
          <Statistic.Group name="Communities Stats" marginTop="2rem">
            <Statistic
              label="Leads Conversations"
              value={formatCount(metrics?.community_replied_leads)}
              changeIcon={getChangeIcon(metrics?.community_replied_leads, prevMetrics?.community_replied_leads)}
              secondaryValue={formatPercent(metrics?.community_replied_leads, metrics?.total_replied_leads)}
              tooltip="Active leads that community replied to at least once"
            />
            <Statistic
              label="Replied Messages"
              value={formatCount(metrics?.community_replied_messages)}
              changeIcon={getChangeIcon(metrics?.community_replied_messages, prevMetrics?.community_replied_messages)}
              secondaryValue={formatPercent(metrics?.community_replied_messages, metrics?.total_replied_messages)}
              tooltip="Number of replied lead messages by a community"
            />
            <Statistic
              label="Avg. Response Time"
              value={metrics?.community_avg_response_humanized}
              tooltip={`Average time between ${t('lead')}'s message and a reply message`}
              changeIcon={getChangeIcon(
                metrics?.community_avg_response,
                prevMetrics?.community_avg_response,
                true,
                formatDuration
              )}
            />
            <Statistic
              label="Avg. First Message Resp. Time"
              value={metrics?.community_avg_response_first_message_humanized}
              changeIcon={getChangeIcon(
                metrics?.community_avg_response_first_message,
                prevMetrics?.community_avg_response_first_message,
                true,
                formatDuration
              )}
              tooltip={`Average time between ${t('lead')}'s message and a reply message for the very first ${t(
                'lead'
              )}'s message`}
            />
          </Statistic.Group>
        </PageSection>
      </If>
    </div>
  );
};

export default SalesAssistantReport;
