import React from 'react';
import { Label, Table } from 'semantic-ui-react';
import { Field, useFormikContext } from 'formik';

import { InputField } from 'components/common';
import { CARE_TYPES } from './context';
import UnitDetails from './UnitDetails';

const AvailabilityStepTable = () => {
  const { values } = useFormikContext();

  if (!values) {
    return null;
  }

  const { units } = values;

  if (!units) {
    return null;
  }

  return (
    <Table celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Unit Type</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Care Level</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Rate</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Units Available</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {units.map((u, unitIndex) =>
          u?.careTypes?.map((ct, careTypeIndex) => {
            const careType = CARE_TYPES.find((c) => c?.id === ct?.id);
            const hasCareTypePrice = ct?.minPrice && ct?.maxPrice;

            return (
              <Table.Row key={careTypeIndex}>
                <Table.Cell>
                  <UnitDetails unit={u} />
                </Table.Cell>
                <Table.Cell textAlign="center">{careType?.title}</Table.Cell>
                <Table.Cell textAlign="center">
                  {hasCareTypePrice ? (
                    <Label color="blue">
                      $ {ct?.minPrice} - $ {ct?.maxPrice}
                    </Label>
                  ) : (
                    <Label content="Not defined" />
                  )}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Field type="hidden" name={`units[${unitIndex}].careTypes[${careTypeIndex}].minPrice`} />
                  <Field type="hidden" name={`units[${unitIndex}].careTypes[${careTypeIndex}].maxPrice`} />
                  <InputField
                    placeholder="Availability"
                    name={`units[${unitIndex}].careTypes[${careTypeIndex}].unitsAvailable`}
                    type="number"
                    fluid
                    disabled={!hasCareTypePrice}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })
        )}
      </Table.Body>
    </Table>
  );
};

export default AvailabilityStepTable;
