import React, { useContext } from 'react';
import styled from 'styled-components';

import { useCheckMobileScreen } from 'utils/hooks';
import { Loader } from 'components/lib';
import SearchInput from 'components/SearchInput';
import { STATUSES, MEDIA_BREAK_POINTS } from '../../constants';
import { MessagesContext } from './MessagesContext';
import { ConversationsList } from './ConversationsList';
import MessagesNavigation from './MessagesNavigation';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--line-standard);
  background-color: var(--white);
  width: 100%;
  height: 100vh;
  flex: 1 0 auto;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex: initial;
    max-width: 340px;
  }
`;

const SearchBox = styled.div`
  padding: 9px 9px 10px 10px;
`;

export const MessagesSidebar = () => {
  const { search, loaderStatus, setSearch } = useContext(MessagesContext);
  const isMobile = useCheckMobileScreen();

  const handleSearch = (value) => {
    setSearch(value);
  };

  const loading = loaderStatus === STATUSES.LOADING;

  return (
    <Container>
      <Loader overlay active={loading && isMobile} size="large" />
      <MessagesNavigation variant="dark" padding="menu" />
      <SearchBox>
        <SearchInput fluid placeholder="" value={search} onSearch={handleSearch} />
      </SearchBox>
      <ConversationsList />
    </Container>
  );
};
