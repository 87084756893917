import React, { useState } from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Icon, Button, Popup } from 'components/lib';
import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import { CAMPAIGN_MESSAGE_TYPE } from '../../constants';

const NoCampaignsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 20px 0 30px;
`;

const NoCampaignsTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  margin-bottom: 6px;
`;

const NoCampaignsSubtitle = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #89919d;
`;

const PopupStyle = {
  borderRadius: 0,
  backgroundColor: 'transparent',
  padding: 0,
  border: 'none',
  boxShadow: 'none',
  zIndex: 0,
};

const AddCampaingWrapper = styled.div`
  && {
    .trigger {
      width: 250px;
      height: 40px;
      border: 1px solid var(--vivid-blue);
      margin-top: 20px;
      &:hover {
        border: 1px solid var(--vivid-blue);
        background-color: #ffffff;
      }
    }
  }
`;

const Options = styled.div`
  &,
  a {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
  }
  width: 250px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #ffffff;
`;

const Option = styled.div`
  height: 36px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid var(--line-standard);
  }
`;

export const EmptyMessages = ({ onClick, messages }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuClick = () => setMenuOpen(!menuOpen);
  const { hasEditDeletePermission } = useCampaignDetails();
  return (
    <NoCampaignsWrapper>
      <If condition={messages?.length === 0}>
        <NoCampaignsTitle>There are no messages</NoCampaignsTitle>
        <If condition={hasEditDeletePermission}>
          <NoCampaignsSubtitle>You can add an SMS message or an email below</NoCampaignsSubtitle>
        </If>
      </If>
      <If condition={hasEditDeletePermission}>
        <Popup
          trigger={
            <AddCampaingWrapper>
              <Button primary color="white" onClick={handleMenuClick} type="button" className="trigger">
                <Icon name="add" size="24" color="#275aff" />
                Add Message
              </Button>
            </AddCampaingWrapper>
          }
          position="bottom center"
          style={PopupStyle}
          basic
          offset={[-2, -10]}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          hoverable
        >
          <Options>
            <Option onClick={() => onClick(CAMPAIGN_MESSAGE_TYPE.SMS)}>SMS</Option>
            <Option onClick={() => onClick(CAMPAIGN_MESSAGE_TYPE.EMAIL)}>Email (blank)</Option>
            <Option onClick={() => onClick(CAMPAIGN_MESSAGE_TYPE.EMAIL_TEMPLATE)}>Email (from template)</Option>
          </Options>
        </Popup>
      </If>
    </NoCampaignsWrapper>
  );
};
