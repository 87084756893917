import React from 'react';
import { FieldArray } from 'formik';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Dropdown, Checkbox, Form, Radio, Icon } from 'components/lib';
import { TOUR_AVAILABILTY_TIME_SLOTS, DAYS } from '../../../constants';

const FieldName = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 1.14;
  color: var(--text);
  width: 90px;
  height: 38px;
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const ChecboxLabel = styled.label`
  display: flex;
  align-items: center;
  width: 110px;
  margin-right: 20px;
  padding-left: 23px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDropdown = styled(Dropdown)`
  &&&& {
    min-width: auto;
    width: 110px !important;
    margin-right: 20px;
  }
`;

const SameDayDropdown = styled(Dropdown)`
  &&&& {
    min-width: auto;
    width: 120px !important;
    margin-right: 20px;
  }
`;

const Day = styled.span`
  font-family: var(--font-weight-bold);
  font-size: 14px;
  font-weight: 900;
  color: var(--text);
  margin-left: 10px;
  padding-top: 5px;
`;

const FormField = styled(Form.Field)`
  &&&& {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  display: flex;
  margin-right: 10px;
  &.opacity {
    opacity: 0.3;
  }
`;

const RadioRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &.first {
    justify-content: flex-start;
    margin-bottom: 20px;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  height: 38px;
`;

const CustomRadio = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-left: 10px;
    margin-right: 30px;
    padding-top: 6px;
  }
`;

const Text = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  color: #89919d;
  width: 425px;
`;

const IconWrapper = styled.div`
  width: 18px;
  height: 18px;
  padding: 2px;
  background-color: var(--emerald-green);
  border-radius: 50%;
  margin-left: 7px;
`;

const FirstWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding-top: 7px;
`;

const Note = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  color: #89919d;
  margin: 10px 0 30px 90px;
`;

const same_day_tour_buffer_minutes = [
  { key: '30', text: '30 minutes', value: 30 },
  { key: '60', text: '1 hour', value: 60 },
  { key: '120', text: '2 hours', value: 120 },
  { key: '180', text: '3 hours', value: 180 },
];

const TourAvailabilityModalContent = ({ values, setFieldValue }) => {
  const { t } = useTranslation();
  return (
    <Form>
      <FieldArray name="days">
        {() => (
          <>
            <Row>
              <FieldName>Days</FieldName>
              <CheckboxWrapper>
                {DAYS.map((day, index) => (
                  <ChecboxLabel key={index}>
                    <Checkbox
                      name={`days[${index}]`}
                      checked={values.days[index]}
                      onChange={() => {
                        const updatedDays = [...values.days];
                        updatedDays[index] = !updatedDays[index];
                        setFieldValue(`days`, updatedDays);
                      }}
                    />
                    <Day>{day}</Day>
                  </ChecboxLabel>
                ))}
              </CheckboxWrapper>
            </Row>
            <Row>
              <FieldName>First appt.</FieldName>
              {DAYS.map((_, index) => (
                <FormField
                  control={StyledDropdown}
                  name={`firstAppointments[${index}]`}
                  selection
                  options={TOUR_AVAILABILTY_TIME_SLOTS}
                  disabled={!values.days[index] || values.has_same_daily_visit_periods === 'On'}
                  value={values.firstAppointments[index]}
                  onChange={(e, { value }) => {
                    const updatedFirstAppointments = [...values.firstAppointments];
                    updatedFirstAppointments[index] = value;
                    setFieldValue(`firstAppointments`, updatedFirstAppointments);
                  }}
                />
              ))}
            </Row>
            <Row>
              <FieldName>Last appt.</FieldName>
              {DAYS.map((_, index) => (
                <FormField
                  control={StyledDropdown}
                  name={`lastAppointments[${index}]`}
                  selection
                  options={TOUR_AVAILABILTY_TIME_SLOTS.filter((slot) => slot.value > values.firstAppointments[index])}
                  disabled={!values.days[index] || values.has_same_daily_visit_periods === 'On'}
                  value={values?.lastAppointments[index]}
                  onChange={(e, { value }) => {
                    const updatedLastAppointments = [...values.lastAppointments];
                    updatedLastAppointments[index] = value;
                    setFieldValue(`lastAppointments`, updatedLastAppointments);
                  }}
                />
              ))}
            </Row>
          </>
        )}
      </FieldArray>
      <Note>Note that Thanksgiving and New Year’s Day are never available.</Note>
      <RadioRow>
        <div>
          <Label>Use the same first and last appointment time slots every day</Label>
          <RadioWrapper>
            <CustomRadio>
              <FormField
                control={Radio}
                name="has_same_daily_visit_periods"
                value="On"
                checked={values.has_same_daily_visit_periods === 'On'}
                onChange={(e, { value }) => {
                  setFieldValue(`has_same_daily_visit_periods`, value);
                }}
              />
              <span>On</span>
            </CustomRadio>
            <CustomRadio>
              <FormField
                control={Radio}
                name="has_same_daily_visit_periods"
                value="Off"
                checked={values.has_same_daily_visit_periods === 'Off'}
                onChange={(e, { value }) => {
                  setFieldValue(`has_same_daily_visit_periods`, value);
                }}
              />
              <span>Off</span>
            </CustomRadio>
          </RadioWrapper>
          <FirstWrapper>
            <div>
              <Label className={values.has_same_daily_visit_periods === 'Off' && 'opacity'}>First</Label>
              <FormField
                control={StyledDropdown}
                name="sameFirst"
                selection
                options={TOUR_AVAILABILTY_TIME_SLOTS}
                value={values.sameFirst}
                disabled={values.has_same_daily_visit_periods === 'Off' || !values.has_same_daily_visit_periods}
                onChange={(e, { value }) => {
                  setFieldValue(`sameFirst`, value);
                }}
              />
            </div>
            <div>
              <Label className={values.has_same_daily_visit_periods === 'Off' && 'opacity'}>Last</Label>
              <FormField
                control={StyledDropdown}
                name="sameLast"
                selection
                options={TOUR_AVAILABILTY_TIME_SLOTS.filter((slot) => slot.value > values.sameFirst)}
                value={values.sameLast}
                disabled={values.has_same_daily_visit_periods === 'Off' || !values.has_same_daily_visit_periods}
                onChange={(e, { value }) => {
                  setFieldValue(`sameLast`, value);
                }}
              />
            </div>
          </FirstWrapper>
        </div>
        <div>
          <Label>
            Real-time availability{' '}
            <IconWrapper>
              <Icon name="sync" size={14} color="#ffffff" />
            </IconWrapper>
          </Label>
          <Text>
            Display available appointment slots in web assistants based on availability in salespeople’s calendars.
            Requires turning on{' '}
            <Link to="/advanced/connected-calendars" target="_blank">
              connected calendars
            </Link>
            , and at least one {t('community')} user syncing their calendar.
          </Text>
          <RadioWrapper>
            <CustomRadio>
              <FormField
                control={Radio}
                name="tour_use_cronofy_availability"
                value="On"
                onChange={(e, { value }) => {
                  setFieldValue('tour_use_cronofy_availability', value);
                }}
                checked={values.tour_use_cronofy_availability === 'On'}
              />
              <span>On</span>
            </CustomRadio>
            <CustomRadio>
              <FormField
                control={Radio}
                name="tour_use_cronofy_availability"
                value="Off"
                onChange={(e, { value }) => {
                  setFieldValue('tour_use_cronofy_availability', value);
                }}
                checked={values.tour_use_cronofy_availability === 'Off'}
              />
              <span>Off</span>
            </CustomRadio>
          </RadioWrapper>
        </div>
      </RadioRow>

      <RadioRow className="first">
        <div>
          <Label>Allow booking on the same day</Label>
          <RadioWrapper>
            <CustomRadio>
              <FormField
                control={Radio}
                name="same_day_tour"
                value="On"
                onChange={(e, { value }) => {
                  setFieldValue('same_day_tour', value);
                }}
                checked={values.same_day_tour === 'On'}
              />
              <span>On</span>
            </CustomRadio>
            <CustomRadio>
              <FormField
                control={Radio}
                name="same_day_tour"
                value="Off"
                onChange={(e, { value }) => {
                  setFieldValue('same_day_tour', value);
                }}
                checked={values.same_day_tour === 'Off'}
              />
              <span> Off</span>
            </CustomRadio>
            {values.same_day_tour === 'On' && (
              <Flex>
                <Label>Earliest in</Label>
                <FormField
                  control={SameDayDropdown}
                  name="same_day_tour_buffer_minutes"
                  selection
                  options={same_day_tour_buffer_minutes}
                  value={values.same_day_tour_buffer_minutes}
                  onChange={(e, { value }) => {
                    setFieldValue(`same_day_tour_buffer_minutes`, value);
                  }}
                />
              </Flex>
            )}
          </RadioWrapper>
        </div>
      </RadioRow>

      <RadioRow className="first">
        <div>
          <Label>Allow booking on the next day</Label>
          <RadioWrapper>
            <CustomRadio>
              <FormField
                control={Radio}
                name="next_day_tour"
                value="On"
                onChange={(e, { value }) => {
                  setFieldValue('next_day_tour', value);
                }}
                checked={values.next_day_tour === 'On'}
              />
              <span>On</span>
            </CustomRadio>
            <CustomRadio>
              <FormField
                control={Radio}
                name="next_day_tour"
                value="Off"
                onChange={(e, { value }) => {
                  setFieldValue('next_day_tour', value);
                }}
                checked={values.next_day_tour === 'Off'}
              />
              <span> Off</span>
            </CustomRadio>
          </RadioWrapper>
        </div>
      </RadioRow>
    </Form>
  );
};

export default TourAvailabilityModalContent;
