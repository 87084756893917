import React from 'react';
import styled from 'styled-components';

import { Accordion as SemanticAccordion } from 'semantic-ui-react';

import { FURHTER_ICON } from './Icon';

const StyledAccordion = styled(SemanticAccordion)`
  &&&&& {
    .title {
      display: flex;
      align-items: center;
      font-family: var(--font-family-bold);
      border-bottom: solid 1px var(--line-standard);

      i {
        margin-left: 4px;
        margin-right: 4px;
      }

      .${FURHTER_ICON} {
        &.caret {
          transform: rotate(0deg);
          transition: transform 0.2s ease-in-out;
        }
      }

      &.active .${FURHTER_ICON} {
        &.caret {
          transform: rotate(90deg);
          transition: transform 0.2s ease-in-out;
        }
      }
    }

    .content {
      padding: 0;
      &:not(:last-child) {
        border-bottom: solid 1px var(--line-standard);
      }
    }

    border-top: solid 1px var(--line-standard);
  }
`;

export const Accordion = ({ ...props }) => <StyledAccordion {...props} />;

Accordion.propTypes = { ...StyledAccordion.propTypes };

Accordion.Accordion = SemanticAccordion.Accordion;
Accordion.Content = SemanticAccordion.Content;
Accordion.Title = SemanticAccordion.Title;
Accordion.Panel = SemanticAccordion.Panel;
