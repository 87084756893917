import React from 'react';

import AnalyticsRoot from './AnalyticsRoot';
import { AnalyticsProvider } from './context/AnalyticsContext';
import { WithTopBar } from '../WithTopBar';

const Analytics = () => (
  <AnalyticsProvider>
    <WithTopBar>
      <AnalyticsRoot />
    </WithTopBar>
  </AnalyticsProvider>
);

export default Analytics;
