import { USER_TYPES } from '../../../constants';

export const ORDER_BY = {
  CREATED_ON: 'created_on',
};

export const ACTION_TYPE = {
  SUPPORT_MODE: 1,
  MESSAGING_ASSISTANT_USER: 2,
  MESSAGING_ASSISTANT_COMMUNITY_OVERRIDES: 3,
  MESSAGING_ASSISTANT_COMMUNITY: 4,
  MESSAGING_ASSISTANT_CAMPAIGN: 5,
  CAMPAIGN_UPDATE: 6,
  CAMPAIGN_COMMUNITIES_ADD: 7,
  CAMPAIGN_COMMUNITIES_REMOVE: 8,
  LEAD_MOVE_IN_DATE_ADD: 9,
  LEAD_MOVE_IN_DATE_UPDATE: 10,
  CAMPAIGN_DELETE: 11,
  LEAD_DELETE: 12,
  LEAD_CREATED_MANUALLY: 13,
  COMMUNITY_REASSIGN: 14,
  COMMUNITY_OFFBOARDING: 15,
  CHAT_PROPERTY_CREATE: 16,
  CHAT_PROPERTY_DELETE: 17,
  CHAT_COMMUNITIES_ADD: 18,
  ANSWER_UPDATE: 20,
  ANSWER_DELETE: 21,
  LEAD_SUBMIT_TO_CRM_MANUAL: 22,
};

export const ACTION_TYPE_NAME = {
  [ACTION_TYPE.SUPPORT_MODE]: 'Entered Support Mode',
  [ACTION_TYPE.MESSAGING_ASSISTANT_USER]: 'Messaging Assistant',
  [ACTION_TYPE.MESSAGING_ASSISTANT_COMMUNITY]: 'Messaging Assistant (Community)',
  [ACTION_TYPE.MESSAGING_ASSISTANT_CAMPAIGN]: 'Messaging Assistant (Campaign)',
  [ACTION_TYPE.CAMPAIGN_UPDATE]: 'Edited Campaign',
  [ACTION_TYPE.CAMPAIGN_COMMUNITIES_ADD]: 'Added Communities to Campaign',
  [ACTION_TYPE.CAMPAIGN_COMMUNITIES_REMOVE]: 'Removed Communities from Campaign',
  [ACTION_TYPE.MESSAGING_ASSISTANT_COMMUNITY_OVERRIDES]: 'Messaging Assistant (Override Communities)',
  [ACTION_TYPE.LEAD_MOVE_IN_DATE_ADD]: 'Added Move-In Date',
  [ACTION_TYPE.LEAD_MOVE_IN_DATE_UPDATE]: 'Edited Move-In Date',
  [ACTION_TYPE.CAMPAIGN_DELETE]: 'Deleted Campaign',
  [ACTION_TYPE.LEAD_DELETE]: 'Deleted Lead',
  [ACTION_TYPE.LEAD_CREATED_MANUALLY]: 'Manually Added Lead',
  [ACTION_TYPE.COMMUNITY_REASSIGN]: 'Community Reassigned',
  [ACTION_TYPE.COMMUNITY_OFFBOARDING]: 'Community Removed',
  [ACTION_TYPE.CHAT_PROPERTY_CREATE]: 'Added Community to Web Assistant',
  [ACTION_TYPE.CHAT_PROPERTY_DELETE]: 'Removed Community from Web Assistant',
  [ACTION_TYPE.CHAT_COMMUNITIES_ADD]: 'Added Communities to Web Assistant',
  [ACTION_TYPE.ANSWER_UPDATE]: 'Updated Answer in Instant Responses',
  [ACTION_TYPE.ANSWER_DELETE]: 'Deleted Answer in Instant Responses',
  [ACTION_TYPE.LEAD_SUBMIT_TO_CRM_MANUAL]: 'Manually Submitted Lead to CRM',
};

export const DEFAULT_AUTHOR_ROLE_VALUE = 'any';
export const AUTHOR_SYSTEM_VALUE = 'system';
export const AUTHOR_ROLE_OPTIONS = [
  { value: DEFAULT_AUTHOR_ROLE_VALUE, text: 'Any author role' },
  { value: USER_TYPES.STAFF, text: 'Staff' },
  { value: USER_TYPES.REGULAR, text: 'Regular' },
  { value: USER_TYPES.MEMBER, text: 'Member' },
  { value: USER_TYPES.REGIONAL, text: 'Regional' },
  { value: AUTHOR_SYSTEM_VALUE, text: 'System' },
];

export const DEFAULT_ACTION_TYPE_VALUE = '0';
export const ACTION_TYPE_OPTIONS = [
  { value: DEFAULT_ACTION_TYPE_VALUE, text: 'Any action' },
  ...Object.values(ACTION_TYPE)
    .map((k) => ({ value: k, text: ACTION_TYPE_NAME[k] }))
    .sort((a, b) => a.text.localeCompare(b.text)),
];
