import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  font-family: var(--font-family);
`;

const Accent = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--emerald-green);
  margin-left: -13px;
  margin-right: 7px;
`;

export const Intent = ({ children, accent }) => (
  <Container>
    {accent ? <Accent /> : null}
    <span>{children.replaceAll('_', ' ')}</span>
  </Container>
);
