import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getFeaturesInfo } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES } from '../../../constants';
import { titleCase } from '../../../utils/helpers';

export const FeaturesContext = createContext();

export const FeaturesContextProvider = (props) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [FEATURE_ACTORS, setFeatureActors] = useState({});
  const [FEATURE_TYPES, setFeatureTypes] = useState({});
  const [FEATURE_STATUSES, setFeatureStatuses] = useState({});
  const [featuresCounts, setFeaturesCounts] = useState([]);

  useEffect(() => {
    const fetchFeaturesMapping = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const results = await getFeaturesInfo();

        [results.actor, results.feature, results.status].forEach((featureMapping) => {
          Object.keys(featureMapping).forEach((key) => (featureMapping[key] = titleCase(featureMapping[key], '_')));
        });

        setFeatureActors(results.actor);
        setFeatureTypes(results.feature);
        setFeatureStatuses(results.status);
        setFeaturesCounts(results.count);

        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch features information');
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    fetchFeaturesMapping();
  }, []);

  const provide = {
    loaderStatus,
    featuresCounts,
    FEATURE_ACTORS,
    FEATURE_STATUSES,
    FEATURE_TYPES,
  };

  return <FeaturesContext.Provider value={provide} {...props} />;
};
