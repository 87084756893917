import humps from 'humps';

import { API } from '../api';

export const getModuleRecordingsSettings = async (chatId) => {
  const response = await API.get(`api/chat/${chatId}/communities/module-recording-settings`);
  return humps.camelizeKeys(response.data);
};

export const getCommunityModuleRecordingSettings = async (chatId, communityId) => {
  const response = await API.get(`api/chat/${chatId}/communities/${communityId}/module-recording-settings`);
  return humps.camelizeKeys(response.data);
};

export const changeChatModuleRecordingsStatus = async (chatId, communityId, data) => {
  const requestData = humps.decamelizeKeys(data);

  const response = await API.patch(
    `api/chat/${chatId}/communities/${communityId}/module-recordings/status-update`,
    requestData
  );
  return humps.camelizeKeys(response.data);
};

export const checkCommunitySettingsActivation = async (chatId, communityId) => {
  const response = await API.get(`api/chat/${chatId}/communities/${communityId}/module-recordings/activation/check`);
  return humps.camelizeKeys(response.data);
};

export const getCommunityModuleRecordings = async (chatId, communityId) => {
  const response = await API.get(`api/chat/${chatId}/communities/${communityId}/module-recordings`);
  return humps.camelizeKeys(response.data);
};

export const updateCommunityModuleRecordings = async (chatId, communityId, recordingId, data) => {
  const response = await API.patch(
    `api/chat/${chatId}/communities/${communityId}/module-recordings/${recordingId}`,
    data
  );
  return humps.camelizeKeys(response.data);
};

export const deleteCommunityModuleRecordings = async (chatId, communityId, recordingId) => {
  const response = await API.delete(`api/chat/${chatId}/communities/${communityId}/module-recordings/${recordingId}`);
  return response.data;
};
