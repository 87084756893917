import React from 'react';
import styled from 'styled-components';

import { Button, Icon, Message, Table } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { MARKET_TYPE_LABEL } from '../../constants';
import ManagementUserInvite from './ManagementUserInvite';

const Container = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  white-space: nowrap;
`;

const Date = styled.div`
  font-size: 14px;
  line-height: 1.14;
  color: var(--text);
`;

const Time = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: #1d252d;
`;

const ManagementUserList = ({ managementUsers, readOnly, onAdministrate, onSendInvite }) => {
  const handleAdministrate = (user) => () => onAdministrate(user);

  if (!managementUsers) {
    return null;
  }

  if (managementUsers.length === 0) {
    return (
      <Message warning icon>
        <Icon name="feedback" size="40" variant="outlined" />
        <Message.Content>
          <Message.Header>There are no users that you're able to manage!</Message.Header>
        </Message.Content>
      </Message>
    );
  }

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Phone</Table.HeaderCell>
          <Table.HeaderCell>Last Managed At</Table.HeaderCell>
          <Table.HeaderCell>Market Type</Table.HeaderCell>
          <Table.HeaderCell>Invitation</Table.HeaderCell>
          {!readOnly && <Table.HeaderCell>Actions</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {managementUsers.map((mu) => (
          <Table.Row key={mu.id}>
            <Table.Cell>{mu.user.name}</Table.Cell>
            <Table.Cell>{mu.user.email}</Table.Cell>
            <Table.Cell>{mu.user.phone}</Table.Cell>
            <Table.Cell>
              {mu.lastManagedAt ? (
                <Container>
                  <Date>{formatAnyDate(mu.lastManagedAt, 'MMM d, yyyy')}</Date>
                  <Time>{formatAnyDate(mu.lastManagedAt, 'hh:mm a')}</Time>
                </Container>
              ) : (
                '-'
              )}
            </Table.Cell>
            <Table.Cell>{MARKET_TYPE_LABEL[mu.user.marketType]}</Table.Cell>
            <Table.Cell>
              <ManagementUserInvite managementUser={mu} onSendInvite={onSendInvite} />
            </Table.Cell>
            {!readOnly && (
              <Table.Cell>
                <Button basic onClick={handleAdministrate(mu)}>
                  Administrate
                </Button>
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ManagementUserList;
