import { USER_TYPES, USER_TYPES_DEFAULT_ROUTES } from '../../../constants';

export const getDefaultRouteForUser = (user) => {
  const userType = user?.type ? user.type : USER_TYPES.REGULAR;
  let defaultRoute = USER_TYPES_DEFAULT_ROUTES[userType.toUpperCase()];
  if (defaultRoute === '/stats' && user?.show_conversion_dashboard) {
    // TODO remove this once /overview is set as default for all users
    defaultRoute = '/overview';
  }
  return defaultRoute;
};
