import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PercentCircle, ReportComponent, SimpleMetric } from 'components/common/analytics';
import { Grid } from 'components/lib';
import { formatCount, formatPercent } from 'components/utils';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const moveinMetrics = [
  'leads.move_ins',
  'leads.move_ins.replied',
  'leads.move_ins.communities',
  'leads.move_ins.ma_active',
  'leads.move_ins.ma_active.communities',
];

const maMetrics = ['leads.move_ins', 'leads.total', 'leads.replied', 'leads.ma_active'];
const zeroMaMetrics = maMetrics.reduce((res, m) => ({ ...res, [m]: { value: 0 } }), {});

const useMoveinTotals = (filters) => {
  const maMoveinFilters = { ...filters, date_field: 'created_date' };
  return useQuery(
    [queryConstants.ADMIN_METRICS, { ...maMoveinFilters, moveinMetrics }],
    async () => {
      return getMetrics(moveinMetrics, maMoveinFilters).then(async (res) => {
        // the only way to get comparable leads counts needed for conversion rate is to get those numbers for communities that have moveins.
        // we can do it by getting list of communities and then querying with communities filter and we need to use group by community_id.
        // since we are grouping by community_id, we can get other metrics as well if we order by moveins
        const moveinCommunities = res['leads.move_ins.communities']?.value || 0;
        if (moveinCommunities) {
          const maTotalFilters = { ...filters, group_by: 'community_id', limit: moveinCommunities };
          const communityStats = await getMetrics(maMetrics, maTotalFilters);
          return { ...res, ...aggregateCommunityStats(communityStats) };
        }
        return { ...res, ...zeroMaMetrics };
      });
    },
    { placeholderData: {} }
  );
};

const aggregateCommunityStats = (data) =>
  Object.keys(data).reduce((vals, m) => {
    vals[m] = { value: data[m].groups.reduce((total, g) => total + g.value, 0) };
    return vals;
  }, {});

const LeadMoveins = () => {
  const { saleAssistantFilters: filters } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };

  const { data, status, error, isFetching } = useMoveinTotals(maFilters);

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching || !data['leads.total']);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch lead movein metrics'));
  }

  const moveinData = useMemo(
    () => ({
      'Active Leads': data['leads.move_ins.replied']?.value || 0,
      'Inactive Leads': (data['leads.move_ins']?.value || 0) - (data['leads.move_ins.replied']?.value || 0),
    }),
    [data]
  );

  const maMoveinData = useMemo(
    () => ({
      'MA Involved': data['leads.move_ins.ma_active']?.value || 0,
      'No Involvement': (data['leads.move_ins.replied']?.value || 0) - (data['leads.move_ins.ma_active']?.value || 0),
    }),
    [data]
  );

  return (
    <ReportComponent
      title="Effect on Move-ins"
      tooltip="MA affect on move-ins that happened within selected time period."
      contentMargins="20px 0"
      contentHeight="150px"
    >
      <Grid columns={2} stackable>
        <Grid.Row>
          <Grid.Column>
            <PercentCircle
              data={moveinData}
              units="Move-ins"
              loaderStatus={loaderStatus}
              radius={150}
              circleWidth={15}
              additionalMetrics={
                <>
                  <SimpleMetric
                    metric={formatPercent(data['leads.move_ins']?.value, data['leads.total']?.value)}
                    unit="conversion rate"
                    fontSize="24px"
                    unitFontSize="16px"
                  />
                  <SimpleMetric
                    metric={formatCount(data['leads.move_ins.communities']?.value)}
                    unit="communities"
                    fontSize="16px"
                    unitFontSize="16px"
                  />
                </>
              }
            />
          </Grid.Column>
          <Grid.Column>
            <PercentCircle
              data={maMoveinData}
              units="Move-ins"
              loaderStatus={loaderStatus}
              radius={150}
              circleWidth={15}
              additionalMetrics={
                <>
                  <SimpleMetric
                    metric={formatPercent(data['leads.move_ins.replied']?.value, data['leads.replied']?.value)}
                    unit="active leads conversion rate"
                    fontSize="24px"
                    unitFontSize="16px"
                  />
                  <SimpleMetric
                    metric={formatPercent(data['leads.move_ins.ma_active']?.value, data['leads.ma_active']?.value)}
                    unit="MA conversion rate"
                    fontSize="16px"
                    unitFontSize="16px"
                  />
                </>
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ReportComponent>
  );
};

export { useMoveinTotals };
export default LeadMoveins;
