import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { UserCircle } from 'components/UserCircle';
import { formatAnyDate } from 'components/utils';
import { MessageTypePill, MESSAGE_PILL_TYPE } from 'components/common/chat';
import { VSA_LINE_TYPE_NAME_SHORT } from 'constants';
import ChatMessageAttachment from './ChatMessageAttachment';
import { CONVERSATION_MESSAGE_NAME, CONVERSATION_MESSAGE_TYPE } from '../constants';

const Container = styled.div`
  display: flex;
  padding: 22px 20px 17px 20px;
  border-radius: 20px 20px 20px 0;
  box-shadow: 0 3px 5px 0 #d8d8d8;
  border: solid 1px var(--line-standard);
  background-color: var(--white);
  align-self: flex-start;
  max-width: 450px;
  justify-content: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 100%;
`;

const Text = styled.span`
  font-family: var(--font-family);
  font-size: 13px;
  line-height: 1.38;
  color: var(--light-text);
  word-break: break-word;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const NameTimeBox = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.span`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: 1.43;
  color: #222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
`;

const Time = styled.span`
  font-family: var(--font-family);
  font-size: 10px;
  line-height: 1.6;
  color: var(--light-text);
  margin-left: 20px;
  margin-right: 10px;
`;

const MESSAGE_TYPE_TO_PILL_TYPE = {
  [CONVERSATION_MESSAGE_TYPE.SMS]: MESSAGE_PILL_TYPE.USER_SMS,
  [CONVERSATION_MESSAGE_TYPE.EMAIL]: MESSAGE_PILL_TYPE.USER_EMAIL,
  [CONVERSATION_MESSAGE_TYPE.VSA]: MESSAGE_PILL_TYPE.USER_VSA,
};

const newlineRegex = /(\r\n|\r|\n)/g;

const FormattedText = ({ text }) => {
  const formattedText = text.split(newlineRegex).map((line, index) => {
    if (!line.match(newlineRegex) && line.replaceAll(/\s/g, '') !== '>') {
      return <p key={index}>{line}</p>;
    }
    return '';
  });
  return formattedText;
};

export const UserMessage = ({ text, user, timestamp, messageType, attachment, onAttachmentLoad, vsa_type }) => {
  const formattedTime = formatAnyDate(timestamp, 'h:mm a');

  let pillText;
  if (messageType === CONVERSATION_MESSAGE_TYPE.VSA) {
    pillText = VSA_LINE_TYPE_NAME_SHORT[vsa_type];
  } else {
    pillText = CONVERSATION_MESSAGE_NAME[messageType];
  }

  return (
    <Container>
      <UserCircle user={user} />
      <Content>
        <ContentHeader>
          <NameTimeBox>
            <Name>{`${user?.first_name} ${user?.last_name}`}</Name>
            <Time>{formattedTime}</Time>
          </NameTimeBox>
          <MessageTypePill text={pillText} type={MESSAGE_TYPE_TO_PILL_TYPE[messageType]} />
        </ContentHeader>
        <Text>
          <FormattedText text={text} />
          <If condition={attachment}>
            <ChatMessageAttachment url={attachment} onImgLoad={onAttachmentLoad} />
          </If>
        </Text>
      </Content>
    </Container>
  );
};
