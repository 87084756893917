import React, { memo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const CARD = 'card';

export const Card = memo(({ id, content, moveCard }) => {
  const ref = useRef(null);

  const [{ isDragging, handlerId }, connectDrag] = useDrag({
    item: { type: CARD, id },
    collect: (monitor) => {
      const result = {
        handlerId: monitor.getHandlerId(),
        isDragging: monitor.isDragging(),
      };
      return result;
    },
  });
  const [, connectDrop] = useDrop({
    accept: CARD,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        moveCard(draggedId, id);
      }
    },
  });
  connectDrag(ref);
  connectDrop(ref);

  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {content}
    </div>
  );
});
