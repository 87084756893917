import React, { useContext } from 'react';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { formatAnyDate } from 'components/utils';
import { Table } from 'components/lib';
import ResultCount from 'components/common/ResultCount';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ConversationsContext } from './ConversationsContext';
import { If } from '../If';
import { ProfileCircle } from '../common/profile/ProfileCircle';
import { ConversationsTablePagination } from './ConversationsTablePagination';
import { ConversationsTableHeader } from './ConversationsTableHeader';
import { ConversationsTableLastMessage } from './ConversationsTableLastMessage';
import ConversationLeadStatus from '../conversation/ConversationLeadStatus';
import { ConversationsTableLeadPopup } from './ConversationsTableLeadPopup';

const ProspectLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: var(--text-color);

  :hover {
    color: var(--text-color);
  }
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmallText = styled.span`
  font-size: 12px;
`;

const LeftPaddedContent = styled.div`
  padding-left: 20px;
`;

const MessageContainer = styled.div`
  padding: 2rem;
`;

const StyledTable = styled(Table)`
  &.ui.table.basic.selectable {
    margin: 0;
    tr:last-child td {
      border-bottom: 1px solid var(--line-standard);
    }

    td {
      overflow-wrap: anywhere;
    }

    td:hover {
      ${ProspectLink} {
        .name {
          text-decoration: underline;
        }
      }
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-right: 20px;
`;

const ProfileCircleWrapper = styled.div`
  position: relative;
`;

export const ConversationsTable = () => {
  const { leads, totalPages, leadsCount } = useContext(ConversationsContext);

  if (!leads || leads.length === 0) {
    return (
      <MessageContainer>
        <Message warning icon="users" content="No conversations matching selected filters. Please change filters." />
      </MessageContainer>
    );
  }

  return (
    <>
      <StyledTable basic selectable striped>
        <ConversationsTableHeader />
        <Table.Body>
          {leads?.map((lead) => (
            <Table.Row key={lead.id}>
              <Table.Cell>
                <LeftPaddedContent>
                  <ProspectLink to={`/conversations/${lead.id}`} state={{ fromConversations: true }}>
                    <ProfileCircleWrapper>
                      <ProfileCircle size="30" name={lead.name} />
                      <If condition={lead.latest_note}>
                        <ConversationsTableLeadPopup note={lead.latest_note} />
                      </If>
                    </ProfileCircleWrapper>
                    <VerticalContainer>
                      <span className="name">{lead.name}</span>
                      <ConversationsTableLastMessage lead={lead} />
                    </VerticalContainer>
                  </ProspectLink>
                </LeftPaddedContent>
              </Table.Cell>
              <Table.Cell>
                <VerticalContainer>
                  <span>{lead.phone || '-'}</span>
                  <SmallText>{lead.email || '-'}</SmallText>
                </VerticalContainer>
              </Table.Cell>
              <Table.Cell>{lead.community.name}</Table.Cell>
              <Table.Cell>
                <VerticalContainer>
                  <span>{lead.vsa?.traffic_source || '-'}</span>
                  <SmallText>{lead.vsa?.channel_source || '-'}</SmallText>
                </VerticalContainer>
              </Table.Cell>
              <Table.Cell>
                <ConversationLeadStatus lead={lead} />
              </Table.Cell>
              <Table.Cell>
                <VerticalContainer>
                  <span>{formatAnyDate(lead.created_on, 'MMM dd yyyy')}</span>
                  <SmallText>{formatAnyDate(lead.created_on, 'h:mm a')}</SmallText>
                </VerticalContainer>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </StyledTable>
      <Footer>
        <div>
          <If condition={totalPages > 1}>
            <ConversationsTablePagination />
          </If>
        </div>
        <ResultCount current={leads?.length} total={leadsCount} />
      </Footer>
    </>
  );
};
