import React from 'react';
import styled from 'styled-components';

import { Table } from 'components/lib';
import { ProfileCircle } from 'components/common/profile/ProfileCircle';

const StyledTableCell = styled(Table.Cell)`
  &&& {
    padding-left: 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  h5 {
    margin: 0;
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: 1.14;
    color: var(--text);
  }
  span {
    font-family: var(--font-family);
    font-size: 12px;
    color: #89919d;
    line-height: 15px;
  }
`;

export const CommunityCell = ({ community }) => {
  const { name, picture, address } = community;
  return (
    <StyledTableCell textAlign="left">
      <Container>
        <ProfileCircle name={name} picture={picture} size="30" />
        <NameContainer>
          <h5>{name}</h5>
          <span>{address}</span>
        </NameContainer>
      </Container>
    </StyledTableCell>
  );
};
