import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { ChatMessage } from 'components/common/chat';
import { CONVERSATION_TYPE, SENDER_TYPE } from 'constants';
import { MessageContext } from './MessageContext';
import { ConversationMessage } from './ConversationMessage';
import { ConversationContainer } from './ConversationContainer';

export const ConversationEmailMessages = () => {
  const { messageId } = useParams();
  const { messages, getEmailMessages } = useContext(MessageContext);

  useEffect(() => {
    getEmailMessages();
  }, [getEmailMessages]);

  const messagesNumber = messages.length;
  return (
    <ConversationContainer info={!messagesNumber}>
      {messages.map((message, i) => {
        const className = classNames({
          left: message.sender_type === 'lead',
          right: message.sender_type !== 'lead',
          [message.sender_type]: true,
        });

        return (
          <ConversationMessage
            key={message.id}
            message={message}
            child={
              <ChatMessage
                className={className}
                text={message.text}
                timestamp={message.created_on}
                furtherReplied={message.further_replied}
                type={SENDER_TYPE[message.sender_type.toUpperCase()]}
                sender={message.sender_name}
              />
            }
            type={CONVERSATION_TYPE.EMAIL}
            basic
            last={!messageId && i + 1 === messagesNumber}
          />
        );
      })}
    </ConversationContainer>
  );
};
