import React from 'react';
import { useTranslation } from 'react-i18next';

import { BooleanSettingsPage } from '../common';

export default function CaptureWebforms() {
  const { t } = useTranslation();
  return (
    <BooleanSettingsPage
      flagName="capture_webforms"
      modalText="Choose whether to capture webforms."
      modalDescription="The complete functionality of this feature relies on an additional setup: mapping webforms from the website to Further. For assistance with this process, please reach out to our technical support team."
      modalTitle="Webform Listener"
      modalSaveText="Save and Publish"
    />
  );
}
