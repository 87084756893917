import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { getPhoneNumbers } from 'apis';
import { Button, Dropdown, Loader } from 'components/lib';
import PromptModal from 'components/common/PromptModal';
import { If } from 'components/If';
import { useContactCenter } from 'components/contact-center/ContactCenterContext';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone-in-talk.svg';
import { CallModal } from './CallModal';

const CallIcon = styled(PhoneIcon)`
  margin-right: 6px;
`;

const CallLabel = styled.span`
  display: flex;
  align-items: center;
`;

const SelectPhoneNumberWrapper = styled.span`
  display: block;
  padding-top: 10px;
  label {
    font-size: 14px;
    font-weight: 900;
    margin-right: 10px;
  }
`;

export default function CallAction({ lead }) {
  const { initialized, initLoading, isReady, makeCall, configureOutboundCalls } = useContactCenter();
  const [openCallLead, setOpenCallLead] = useState(false);
  const [communityPhoneNumbers, setCommunityPhoneNumbers] = useState([]);

  const phoneNumbers = communityPhoneNumbers.map(({ phoneNumber }) => ({
    key: phoneNumber,
    value: phoneNumber,
    text: phoneNumber,
  }));

  const [callerId, setCallerId] = useState(null);
  const fetchPhoneNumbers = useCallback(async () => {
    const results = await getPhoneNumbers(lead.id);
    setCommunityPhoneNumbers(results);
    setCallerId(results.find((number) => number.isDefault)?.phoneNumber);
  }, [setCommunityPhoneNumbers, lead.id]);

  useEffect(() => {
    fetchPhoneNumbers();
  }, [lead.id]);

  const cancelCall = useCallback(() => {
    setOpenCallLead(false);
  }, []);

  const toggleOpenCallLead = async () => {
    if (!openCallLead) {
      await configureOutboundCalls();
    }

    setOpenCallLead((openCallLead) => !openCallLead);
  };

  const handleMakeCall = useCallback(() => {
    setOpenCallLead(false);
    makeCall(lead.id, callerId);
  }, [lead, setOpenCallLead, makeCall, callerId]);

  const handlePhoneNumberChange = useCallback(
    (event, data) => {
      setCallerId(data.value);
    },
    [setCallerId]
  );

  return (
    <>
      {initialized && (
        <Button primary disabled={initLoading} color="green" onClick={toggleOpenCallLead}>
          <CallIcon width={20} height={20} /> Call
        </Button>
      )}
      <If condition={!lead?.community?.enable_sms_notifications_for_outbound_calls}>
        <PromptModal
          open={openCallLead}
          title={`Call ${lead?.name} now?`}
          actionColor="green"
          actionLabel={
            <CallLabel>
              <CallIcon width={18} height={18} /> Call
            </CallLabel>
          }
          description={
            <>
              The call will be displayed above the conversation.
              <If condition={lead?.community?.enable_outbound_call_recoding}>
                <br /> You will be able to replay the call and view its summary later.
              </If>
              <If condition={communityPhoneNumbers.length > 1}>
                <SelectPhoneNumberWrapper>
                  <label htmlFor="callerId">Call From</label>
                  <Dropdown
                    id="callerId"
                    selection
                    options={phoneNumbers}
                    value={callerId}
                    onChange={handlePhoneNumberChange}
                  />
                </SelectPhoneNumberWrapper>
              </If>
            </>
          }
          onConfirm={handleMakeCall}
          disabled={!isReady}
          onClose={cancelCall}
        />
      </If>
      <If condition={openCallLead && lead?.community?.enable_sms_notifications_for_outbound_calls}>
        <CallModal
          lead={lead}
          phoneNumbers={phoneNumbers}
          handlePhoneNumberChange={handlePhoneNumberChange}
          callerId={callerId}
          closeModal={cancelCall}
          makeCall={handleMakeCall}
          disabled={!isReady}
          CallIcon={CallIcon}
          delay={lead?.community?.outbound_sms_notification_delay}
        />
      </If>
    </>
  );
}
