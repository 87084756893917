import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { getTourByHash, updateTour } from 'apis';
import { STATUSES } from '../../constants';
import { UserContext } from '../UserContext';

export const ToursContext = createContext();

export const ToursContextProvider = (props) => {
  const { tourHash } = useParams();
  const { user } = useContext(UserContext);

  const [tour, setTour] = useState(null);
  const [tourStatus, setTourStatus] = useState(STATUSES.IDLE);
  const [updateStatus, setUpdateStatus] = useState(STATUSES.IDLE);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    const fetchTour = async () => {
      try {
        setTourStatus(STATUSES.LOADING);
        const tour = await getTourByHash(tourHash);

        setTour(tour);

        const { lead } = tour;
        const { community } = lead;

        if (!lead || !community) {
          setTourStatus(STATUSES.FAILURE);
          toast.error('Trouble fetching lead information!');
          return;
        }

        setTourStatus(STATUSES.LOADED);
      } catch (e) {
        setTourStatus(STATUSES.FAILURE);
        console.error(e);
        toast.error('Trouble fetching calendar request information!');
      }
    };

    fetchTour();
  }, [tourHash, user]);

  const handleTourSubmit = async (values) => {
    try {
      setUpdateStatus(STATUSES.LOADING);

      await updateTour(tourHash, {
        ...values,
        reason_for_cancellation: values.reason_for_cancellation || null,
      });

      toast.success(`${tour?.request_type} ${values.cancelled ? 'cancelled' : 'updated'} successfully!`);

      setTour({
        ...tour,
        cancelled: values.cancelled,
      });
      setUpdateStatus(STATUSES.LOADED);
      setShowCancelModal(false);
    } catch (e) {
      console.error(e);
      setUpdateStatus(STATUSES.FAILURE);
      toast.error('Something went wrong');
    }
  };

  const toggleCloseModal = () => {
    setShowCancelModal((draft) => !draft);
  };

  const provide = {
    tour,
    tourStatus,
    setTour,
    setTourStatus,
    updateStatus,
    setUpdateStatus,
    showCancelModal,
    setShowCancelModal,
    toggleCloseModal,
    handleTourSubmit,
  };

  return <ToursContext.Provider value={provide} {...props} />;
};
