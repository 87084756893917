import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Grid, Icon, Dropdown, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import SearchInput from 'components/SearchInput';
import { If } from 'components/If';
import CommunityEmailSubjectsTable from './CommunityEmailSubjectsTable';
import DefaultEmailSubjectsTable from './DefaultEmailSubjectsTable';
import DeleteCommunitySubjectOverrideModal from './DeleteCommunitySubjectOverrideModal';
import { MEDIA_BREAK_POINTS, STATUSES } from '../../../constants';

const CommandsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    height: 40px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  div.divider.text,
  .menu .item span {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
`;

const ChooseClientDropdownContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
`;

const EmailSubjectCustomizationOverview = ({
  loaderStatus,
  eligibleClients,
  userEmailSubjects,
  communityEmailSubjects,
  onUserEmailSubjectDelete,
  onCommunityEmailSubjectDelete,
  onAllCommunityEmailSubjectsDelete,
}) => {
  const { t } = useTranslation();
  const [searchCommunity, setSearchCommunity] = useState('');
  const [communityOverrides, setCommunityOverrides] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isDeleteCommunitySubjectsModalOpen, setDeleteCommunitySubjectsModalOpen] = useState(false);

  useEffect(() => {
    if (eligibleClients && eligibleClients.length === 1) {
      setSelectedClient(eligibleClients[0].id);
    }
  }, [eligibleClients]);

  useEffect(() => {
    setCommunityOverrides(communityEmailSubjects.length > 0);
  }, [communityEmailSubjects]);

  const clientOptions = useMemo(
    () =>
      eligibleClients?.map((client) => ({
        key: client.id,
        value: client.id,
        text: client.full_name ? `${client.email}\n${client.full_name}` : `${client.email}`,
      })) || [],
    [eligibleClients]
  );

  const communityItems = useMemo(() => {
    const communityData = [];
    communityEmailSubjects.forEach((subject) => {
      const foundIndex = communityData.findIndex((item) => item.type === subject.type && item.text === subject.text);
      if (foundIndex === -1) {
        communityData.push({
          type: subject.type,
          text: subject.text,
          communities: [subject.community],
        });
      } else {
        communityData[foundIndex].communities.push(subject.community);
      }
    });

    if (searchCommunity) {
      return communityData.filter((subject) =>
        subject.communities.find((community) => community.name.toLowerCase().includes(searchCommunity.toLowerCase()))
      );
    }

    return communityData;
  }, [communityEmailSubjects, searchCommunity]);

  const onCommunityOverrideChange = async () => {
    if (communityEmailSubjects.length > 0 && communityOverrides) {
      setDeleteCommunitySubjectsModalOpen(true);
      return;
    }

    setCommunityOverrides(!communityOverrides);
  };

  const onChangeSelectedClient = (_, { value }) => {
    setSelectedClient(value);
  };

  const onConfirm = async () => {
    await onAllCommunityEmailSubjectsDelete();
    setDeleteCommunitySubjectsModalOpen(false);
    setCommunityOverrides(!communityOverrides);
  };

  const onCancel = () => {
    setDeleteCommunitySubjectsModalOpen(false);
  };

  return (
    <>
      <ChooseClientDropdownContainer>
        <StyledDropdown
          placeholder="Select Client"
          clearable
          search
          selection
          disabled={clientOptions && clientOptions?.length === 1}
          value={selectedClient}
          options={clientOptions}
          onChange={onChangeSelectedClient}
        />
      </ChooseClientDropdownContainer>
      <DefaultEmailSubjectsTable
        currentClientId={selectedClient}
        userEmailSubjects={userEmailSubjects}
        onEmailSubjectDelete={onUserEmailSubjectDelete}
      />
      <Grid>
        <Grid.Row stretched>
          <Grid.Column width={16}>
            <CommandsWrapper>
              <Checkbox
                toggle
                label="Customize per Community"
                checked={communityOverrides}
                onChange={onCommunityOverrideChange}
              />
              <If condition={communityOverrides}>
                <div>
                  <Button
                    disabled={!selectedClient}
                    as={Link}
                    to={{
                      pathname: `/admin/custom-email-subjects/${selectedClient}/edit/community`,
                      state: { selectedEmailSubject: null },
                    }}
                  >
                    <Icon name="plus" /> Add
                  </Button>
                  <SearchInput
                    disabled={!selectedClient}
                    value={searchCommunity}
                    onSearch={setSearchCommunity}
                    placeholder={`Search ${t('communities')}...`}
                  />
                </div>
              </If>
            </CommandsWrapper>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <If condition={communityOverrides}>
            <Grid.Column width={16}>
              {communityEmailSubjects?.length ? (
                <CommunityEmailSubjectsTable
                  loaderStatus={loaderStatus}
                  currentClientId={selectedClient}
                  communityEmailSubjects={communityItems}
                  onCommunityEmailSubjectDelete={onCommunityEmailSubjectDelete}
                />
              ) : (
                <Message info> There are no overrides found.</Message>
              )}
            </Grid.Column>
          </If>
        </Grid.Row>
      </Grid>
      <DeleteCommunitySubjectOverrideModal
        loading={loaderStatus === STATUSES.LOADING}
        open={isDeleteCommunitySubjectsModalOpen}
        onClose={onCancel}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default EmailSubjectCustomizationOverview;
