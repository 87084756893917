import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { parseErrorResponse } from 'apis/utils';
import { resendInvites } from 'apis';
import { Loader, Dimmer } from 'components/lib';
import { STATUSES } from '../../../constants';
import { SettingsModal } from '../common';
import { ResendInvitesModalContent } from './ResendInvitesModalContent';
import { EMAIL_INTEGRATION_STATUS, INVITABLE_PROVIDERS } from './constants';

const ModalBody = styled.div`
  height: 336px;
  position: relative;
`;

const ModalHeader = styled.div``;

const ModalHeaderTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 20px;
  color: var(--text);
  font-weight: 500;
  letter-spacing: normal;
`;

const ModalHeaderSubtitle = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  padding-top: 10px;
`;

const Actions = styled.div`
  height: 40px;
`;

export const ResendInvitesModal = ({ isModalOpen, onClose, items, selectedItem }) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const onSubmit = async (values, { setErrors }) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      await resendInvites(values);
      setLoaderStatus(STATUSES.LOADED);
      toast.success('Resend invites successfully!');
      handleClose();
    } catch (e) {
      console.error(e);
      setErrors(e?.response?.data);
      toast.error(parseErrorResponse(e), 'Error resending invites!');
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  return (
    <SettingsModal open={isModalOpen} onModalClose={handleClose} size="tiny" width={540} height={502}>
      <ModalHeader type="header">
        <ModalHeaderTitle>Resend Invites</ModalHeaderTitle>
        <ModalHeaderSubtitle>
          Resend invites to Google or Microsoft domain email addresses. They will be sent a follow-up email invite
          asking them to authenticate.
        </ModalHeaderSubtitle>
      </ModalHeader>
      <ModalBody type="body">
        <ResendInvitesModalContent
          items={items
            .filter((item) => item.status !== EMAIL_INTEGRATION_STATUS.active)
            .filter((item) => INVITABLE_PROVIDERS.includes(item.provider))}
          selectedItem={selectedItem}
          onSubmit={onSubmit}
          loading={loading}
          handleClose={handleClose}
        />
      </ModalBody>
      <Actions type="actions" />
    </SettingsModal>
  );
};
