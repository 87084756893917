import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { UserContext } from '../UserContext';

const TabMenu = ({ items, size = 'large' }) => {
  const { user } = useContext(UserContext);

  return (
    <Menu size={size} secondary pointing>
      {items
        .filter((item) => item.allowedTypes.includes(user.type))
        .map((item) => (
          <TabItem key={item.key} item={item} />
        ))}
    </Menu>
  );
};

const TabItem = ({ item }) => {
  const isTabActive = !!useRouteMatch({ path: item.to, exact: item.exact });

  return (
    <Menu.Item key={item.key} as={Link} to={item.to} active={isTabActive}>
      {item.label}
    </Menu.Item>
  );
};

export default TabMenu;
