import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { If } from 'components/If';
import { Checkbox, Dimmer, Loader, Icon } from 'components/lib';
import { parseErrorResponse } from 'apis/utils';
import { getConnectedCalendarsUsers } from 'apis';
import { pluralize } from 'utils/helpers';
import { STATUSES, CONNECTED_CALENDARS_STATUSES, CONNECTED_CALENDARS_ACTIONS } from '../../../constants';
import { useSettings } from '../context/SettingsContext';
import ConnectedCalendarsNoUsers from './ConnectedCalendarsNoUsers';
import SyncedCalendarsModal from './SyncedCalendarsModal';
import { UserWrapper } from './UserWrapper';

const UsersWrapper = styled.div`
  width: 447px;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  &.synced {
    margin-left: 198px;
    margin-right: 20px;
    width: 47px;
  }
  > span {
    margin-left: 5px;
  }
  &:last-child {
    margin-left: auto;
    margin-right: 11px;
  }
`;

const UsersList = styled.div`
  display: flex;
  flex-direction: column;
  height: 293px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const CommunityName = styled.div`
  width: 100%;
  height: 20px;
  background-color: #ecedee;
  display: flex;
  align-items: center;
  font-family: var(--font-family-bold);
  font-size: 10px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  padding-left: 10px;
  margin-bottom: 12px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  .remove {
    cursor: pointer;
    margin-left: auto;
  }
  margin-bottom: 10px;
`;

const Synced = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.isSynced && !props.isUserInUsersToRemove ? 'var(--emerald-green)' : '#222222')};
  width: 47px;
  margin-left: 86px;
  text-align: center;
  flex-shrink: 0;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 31px;
  span {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
    margin-left: 10px;
  }
  flex-shrink: 0;
`;

const CalendarsNumber = styled.div`
  display: flex;
  width: 64px;
  flex-direction: column;
  align-items: flex-start;
`;

const View = styled.div`
  color: var(--link);
  cursor: pointer;
`;

const ConnectedCalendarsUsers = ({ values, setFieldValue }) => {
  const [users, setUsers] = useState({});
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [{ selectedItems, excludedItems }] = useSettings();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const fetchUsers = useCallback(
    async ({ include, exclude }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const params = {};
        if (include?.length > 0) {
          params.include = include.join(',');
        }
        if (exclude?.length > 0) {
          params.exclude = exclude.join(',');
        }
        const response = await getConnectedCalendarsUsers(params);
        const organizedData = response.reduce((acc, user) => {
          user.communities.forEach((community) => {
            const { communityId, status, communityName } = community;
            if (!acc[communityId]) {
              acc[communityId] = { status, communityName, users: [] };
            }
            acc[communityId].users.push(user);
          });
          return acc;
        }, {});
        setUsers(organizedData);
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch data');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setUsers]
  );

  useEffect(() => {
    fetchUsers({ include: selectedItems, exclude: excludedItems });
  }, [fetchUsers]);

  const handleOnClick = (user, communityId, userCalendars) => {
    setFieldValue('usersToRemove', [
      ...values.usersToRemove,
      {
        userId: user.id,
        communityId: parseInt(communityId),
        action: CONNECTED_CALENDARS_ACTIONS.REMOVE,
        calendars: userCalendars.map((calendar) => calendar.id),
      },
    ]);
  };

  const handleOnModalClose = () => {
    setIsModalOpen(false);
  };

  const handleViewClick = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const onSendRemindersChange = (e, { checked }) => {
    if (checked) {
      const temp = [];
      Object.keys(users).forEach((communityId) =>
        users[communityId]?.users?.forEach((user) => {
          const status = user.communities?.[0]?.status;
          if (status !== CONNECTED_CALENDARS_STATUSES.CONNECTED) {
            const isPending = status === CONNECTED_CALENDARS_STATUSES.PENDING;
            temp.push({
              userId: user.id,
              communityId: parseInt(communityId),
              action: isPending
                ? CONNECTED_CALENDARS_ACTIONS.RESEND_REMINDER
                : CONNECTED_CALENDARS_ACTIONS.SEND_REMINDER,
              calendars: [],
            });
          }
        })
      );
      setFieldValue('users', temp);
      setFieldValue('sendReminders', checked);
    } else {
      setFieldValue('users', []);
      setFieldValue('sendReminders', checked);
    }
  };

  const userWithStatus = (user, communityId) => {
    const nameParts = user.name.split(' ');
    const first_name = nameParts[0];
    const last_name = nameParts[1];
    const status = user.communities?.[0]?.status;
    const isSynced = status === CONNECTED_CALENDARS_STATUSES.CONNECTED;
    const isPending = status === CONNECTED_CALENDARS_STATUSES.PENDING;
    const isNotConnected = status === CONNECTED_CALENDARS_STATUSES.NOT_CONNECTED;
    const isUserInUsers =
      values.users.findIndex(
        (userItem) => userItem.userId === user.id && userItem.communityId === parseInt(communityId)
      ) !== -1;

    const isUserInUsersToRemove =
      values.usersToRemove.findIndex(
        (userItem) => userItem.userId === user.id && userItem.communityId === parseInt(communityId)
      ) !== -1;

    let checkboxLabel;
    if (isPending) {
      checkboxLabel = 'Resend reminder';
    }
    if (isNotConnected) {
      checkboxLabel = 'Send reminder';
    }
    return {
      ...user,
      first_name,
      last_name,
      status,
      isSynced,
      isPending,
      isNotConnected,
      isUserInUsers,
      isUserInUsersToRemove,
      checkboxLabel,
    };
  };

  const onCheckboxChange = (checked, user, communityId) => {
    const userObject = {
      userId: user.id,
      communityId: parseInt(communityId),
      action: checked
        ? user.isPending
          ? CONNECTED_CALENDARS_ACTIONS.RESEND_REMINDER
          : CONNECTED_CALENDARS_ACTIONS.SEND_REMINDER
        : null,
      calendars: [],
    };

    if (checked) {
      setFieldValue('users', [...values.users, userObject]);
    } else {
      const updatedUsers = values.users.filter(
        (item) => !(item.userId === user.id && item.communityId === parseInt(communityId))
      );
      setFieldValue('users', updatedUsers);
    }
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const numberOfNotSyncedUsers = Object.keys(users).reduce((accumulator, communityId) => {
    const communityUsers = users[communityId]?.users || [];

    const notSyncedCount = communityUsers.reduce((innerAccumulator, user) => {
      const status = user.communities?.[0]?.status;
      if (status !== CONNECTED_CALENDARS_STATUSES.CONNECTED) {
        return innerAccumulator + 1;
      }
      return innerAccumulator;
    }, 0);

    return accumulator + notSyncedCount;
  }, 0);
  return (
    <UsersWrapper>
      <If condition={isModalOpen}>
        <SyncedCalendarsModal
          open={isModalOpen}
          onClose={handleOnModalClose}
          user={selectedUser}
          values={values}
          fetchUsers={fetchUsers}
        />
      </If>
      <Dimmer active={loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <If condition={Object.keys(users)?.length > 0}>
        <Header>
          <Column>Users</Column>
          <Column className="synced">Synced</Column>
          <Column>
            <Checkbox
              name="sendReminders"
              checked={
                values.users.length > 0 && values.users.length === numberOfNotSyncedUsers && values.sendReminders
              }
              onChange={onSendRemindersChange}
            />
            <span>Send reminders</span>
          </Column>
        </Header>
        <UsersList>
          {Object.keys(users)?.map((communityId) => {
            const communityName = users[communityId]?.communityName;
            return (
              <div key={communityId}>
                <CommunityName>{communityName}</CommunityName>
                {users[communityId]?.users
                  ?.map((user) => userWithStatus(user, communityId))
                  .map((user) => {
                    const userCalendars = user.calendars?.filter(
                      (calendar) => calendar.communityId === parseInt(communityId)
                    );
                    return (
                      <Row key={user.id}>
                        <UserWrapper user={user} />
                        <Synced isSynced={user.isSynced} isUserInUsersToRemove={user.isUserInUsersToRemove}>
                          {user.isSynced && !user.isUserInUsers && !user.isUserInUsersToRemove ? (
                            <CalendarsNumber>
                              {pluralize(userCalendars?.length, 'calendars', 'calendar')}
                              <View
                                onClick={() =>
                                  handleViewClick({
                                    ...user,
                                    filteredCalendars: userCalendars,
                                    communityId,
                                  })
                                }
                              >
                                View
                              </View>
                            </CalendarsNumber>
                          ) : (
                            'No'
                          )}
                        </Synced>
                        <If condition={!user.isSynced}>
                          <CheckboxWrapper>
                            <Checkbox
                              name="action"
                              checked={user.isUserInUsers}
                              onChange={(e, { checked }) => onCheckboxChange(checked, user, communityId)}
                            />

                            <span>{user.checkboxLabel}</span>
                          </CheckboxWrapper>
                        </If>
                        <If condition={user.isSynced && !user.isUserInUsersToRemove}>
                          <Icon
                            name="close"
                            size="16"
                            color="#89919d"
                            className="remove"
                            onClick={() => handleOnClick(user, communityId, userCalendars)}
                          />
                        </If>
                      </Row>
                    );
                  })}
              </div>
            );
          })}
        </UsersList>
      </If>
      <If condition={Object.keys(users)?.length === 0}>
        <ConnectedCalendarsNoUsers />
      </If>
    </UsersWrapper>
  );
};

export default ConnectedCalendarsUsers;
