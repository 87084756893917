import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Rating } from 'components/lib';
import testimonial1 from '../../../assets/images/testimonial_1.jpeg';
import testimonial2 from '../../../assets/images/testimonial_2.jpeg';
import testimonial3 from '../../../assets/images/testimonial_3.jpeg';
import testimonial4 from '../../../assets/images/testimonial_4.jpeg';

const TESTIMONIALS = [
  {
    avatarURL: testimonial1,
    author: 'Coryanne Graham | VP Marketing & Brand, Oxford Senior Living',
    quote:
      "Within the first couple of weeks, I was getting messages from our sales directors saying, 'Oh my gosh! I love this.'",
  },
  {
    avatarURL: testimonial2,
    author: 'Amy Elliott | Sales & Marketing Manager, Life Care Services',
    quote:
      'Deploying FURTHER was the right move for our communities. We’ve seen a direct impact to increase conversions on our websites and drive valuable leads to our sales teams.',
  },
  {
    avatarURL: testimonial3,
    author: 'Larry Miramontes | VP Marketing, Always Best Care',
    quote: "The employee recruitment capability is win for us. It's simple and quick - exactly what job seekers want",
  },
  {
    avatarURL: testimonial4,
    author: 'Cindy Robinson | VP Sales & Marketing, Capri Senior Living',
    quote:
      'Every dollar we spend needs to make a difference and FURTHER has helped us be seen on google, leverage our web presence, and stretched our marketing across the digital spectrum. This partnership has been a game changer for us.',
  },
];

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Testimonial = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const Slide = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  bottom: 0;
`;

const Avatar = styled.div`
  background-image: url(${(p) => p.url});
  background-size: cover;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  max-width: 100%;
`;

const Details = styled.div`
  flex: 3;
  line-height: 1.7;
`;

const Name = styled.div`
  font-size: 12px;
`;
const Quote = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.69);
`;

export const Testimonials = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const randomTestimotional = Math.floor(Math.random() * 4);
    setActiveSlide(randomTestimotional);
  }, []);

  return (
    <Wrapper>
      {TESTIMONIALS.map((t, i) => (
        <Slide key={i} visible={i === activeSlide}>
          <Testimonial>
            <Avatar url={t.avatarURL} />
            <Details>
              <Rating />
              <Name>{t.author}</Name>
              <Quote>“{t.quote}”</Quote>
            </Details>
          </Testimonial>
        </Slide>
      ))}
    </Wrapper>
  );
};
