import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { Icon, Table } from 'semantic-ui-react';

import { parseErrorResponse } from 'apis/utils';
import { restoreSubjectOverrideToSystemDefaults } from 'apis';
import DotsActionButton from './DotsActionButton';
import DeleteSubjectOverridesModal from './DeleteSubjectOverridesModal';
import { useEmailSubjects } from './EmailSubjectsContext';
import { STATUSES } from '../../../constants';

const CellSubItem = styled.div`
  font-size: 11px;
  color: black;
`;

const EmailSubjectOverridesTable = () => {
  const { emailSubjectOverrides, setEmailSubjectOverrides } = useEmailSubjects();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoadingStatus] = useState(STATUSES.IDLE);
  const [currentItem, setCurrentItem] = useState(null);

  const setOpenModal = (userOverride) => () => {
    setCurrentItem(userOverride);
    setDeleteModalOpen(true);
  };

  const onConfirm = async () => {
    try {
      setLoadingStatus(STATUSES.LOADING);
      await restoreSubjectOverrideToSystemDefaults(currentItem.id);
      setEmailSubjectOverrides(emailSubjectOverrides.filter((item) => item.id !== currentItem.id));
      setLoadingStatus(STATUSES.LOADED);
    } catch (error) {
      toast.error(parseErrorResponse(error));
      setLoadingStatus(STATUSES.FAILURE);
    }

    setCurrentItem(null);
    setDeleteModalOpen(false);
  };

  const onCloseModal = () => {
    setCurrentItem(null);
    setDeleteModalOpen(false);
  };

  return (
    <>
      <Table striped basic>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell textAlign="left">Client Name</Table.HeaderCell>
            <Table.HeaderCell>System Overrides</Table.HeaderCell>
            <Table.HeaderCell>Community Overrides</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {emailSubjectOverrides.map((userOverride) => (
            <Table.Row key={userOverride.id} textAlign="center">
              <Table.Cell textAlign="left">
                <Link to={`/admin/custom-email-subjects/${userOverride.id}/edit`}>
                  {userOverride.full_name}
                  <br />
                  <CellSubItem>{userOverride.email}</CellSubItem>
                </Link>
              </Table.Cell>
              <Table.Cell>
                {userOverride.has_user_override && <Icon size="large" name="check circle" color="green" />}
              </Table.Cell>
              <Table.Cell>
                {userOverride.has_community_override && <Icon size="large" name="check circle" color="green" />}
              </Table.Cell>
              <Table.Cell>
                {userOverride.last_override_date && format(parseISO(userOverride.last_override_date), 'MM/dd/yyyy')}
              </Table.Cell>
              <Table.Cell>
                <DotsActionButton onClick={setOpenModal(userOverride)} title="Delete All Overrides" />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <DeleteSubjectOverridesModal
        open={isDeleteModalOpen}
        loading={loading === STATUSES.LOADING}
        onClose={onCloseModal}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default EmailSubjectOverridesTable;
