import { useEffect, useState } from 'react';
import { MEDIA_BREAK_POINTS } from '../../constants';

export const useCheckSmallMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= MEDIA_BREAK_POINTS.SMALL_MOBILE;
};
