import { API } from '../api';

export const getCommunities = async (params) => {
  const query = new URLSearchParams(params).toString();
  const url = `/api/ia/communities?${query}`;
  const response = await API.get(url);
  return response.data;
};

export const getCommunityDetails = async (community_id) => {
  const url = `/api/ia/communities/${community_id}`;
  const response = await API.get(url);
  return response.data;
};

export const getCommunityAttributes = async (community_id) => {
  const url = `/api/ia/communities/${community_id}/attributes`;
  const response = await API.get(url);
  return response.data;
};

export const getDefaultAttributes = async (profile_id) => {
  const url = `/api/ia/communities/${profile_id}/default-attributes`;
  const response = await API.get(url);
  return response.data;
};

export const getAttributeAnswers = async (profile_id, attribute_id) => {
  const url = `/api/ia/communities/${profile_id}/attributes/${attribute_id}`;
  const response = await API.get(url);
  return response.data;
};

export const saveAttributeAnswers = async (profile_id, attribute_id, data) => {
  const url = `/api/ia/communities/${profile_id}/attributes/${attribute_id}`;
  const response = await API.post(url, data);
  return response.data;
};

export const getAttributes = async (params) => {
  const query = new URLSearchParams(params).toString();
  const url = `/api/ia/attributes?${query}`;
  const response = await API.get(url);
  return response.data;
};

export const getQuestionReviews = async (params) => {
  const query = new URLSearchParams(params).toString();
  const url = `/api/ia/question-reviews?${query}`;
  const response = await API.get(url);
  return response.data;
};

export const updateQuestionReview = async (question_attribute_id, data) => {
  const url = `/api/ia/question-reviews/${question_attribute_id}`;
  const response = await API.patch(url, data);
  return response.data;
};

export const exportQuestionReviews = async (params) => {
  const url = '/api/ia/question-reviews/export';
  return API.get(url, { responseType: 'blob', params });
};

export const clearQuestionReviews = async () => {
  const url = '/api/ia/question-reviews/clear';
  return API.put(url);
};

export const getQuestionReviewsStatistics = async () => {
  const url = `/api/ia/question-reviews/statistics`;
  const response = await API.get(url);
  return response.data;
};

export const getPlaceholders = async () => {
  const url = `/api/ia/placeholders`;
  const response = await API.get(url);
  return response.data;
};

export const deleteIA = async (id) => {
  const url = `api/ia/communities/${id}/remove`;
  const response = await API.delete(url);
  return response.data;
};

export const getActions = async () => {
  const url = '/api/ia/actions';
  const response = await API.get(url);
  return response.data;
};

export const getAttributeTypes = async () => {
  const url = '/api/ia/attribute-types';
  const response = await API.get(url);
  return response.data;
};

export const createAttribute = async (data) => {
  const url = '/api/ia/attributes';
  const response = await API.post(url, data);
  return response.data;
};

export const exportIA = async (id) => {
  const url = `api/ia/communities/${id}/export`;
  const response = await API.get(url);
  return response.data;
};

export const importIA = async (id, data) => {
  const response = await API.post(`/api/ia/communities/${id}/import`, data);
  return response.data;
};

export const toggleIA = async (id, data) => {
  const response = await API.post(`/api/ia/communities/${id}`, data);
  return response.data;
};
