import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon, Loader, Message } from 'components/lib';
import { CommunitiesContext } from './CommunitiesContext';
import { STATUSES } from '../../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  h4 {
    font-family: var(--font-family-bold);
  }
`;

export const UserCommunitiesNoneSelected = () => {
  const { state } = useContext(CommunitiesContext);
  const { t } = useTranslation();

  const { status } = state;
  if (status === STATUSES.LOADING) {
    return <Loader active fullwidth size="large" />;
  }
  if (status === STATUSES.FAILURE) {
    return (
      <Container>
        <Message negative>
          <Message.Header>We're sorry we can't fetch user communities</Message.Header>
          <p>Something went wrong and we couldn't load user communities. Please try again later.</p>
        </Message>
      </Container>
    );
  }
  return (
    <Container>
      <Icon name="holiday_village" variant="outlined" size="60" color="var(--line-standard)" />
      <h4>Please select a {t('community')}</h4>
    </Container>
  );
};
