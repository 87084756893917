import React from 'react';
import styled from 'styled-components';

import { Button, Icon } from 'components/lib';

const ButtonWrapper = styled.div`
  margin-left: auto;
`;

const StyledIcon = styled(Icon)`
  &&&& {
    opacity: 1;
  }
`;

export const MyProfileButton = ({ iconName, text, onClick }) => {
  return (
    <ButtonWrapper>
      <Button primary color="blue" onClick={onClick}>
        <StyledIcon name={iconName} color="white" size="24" />
        {text}
      </Button>
    </ButtonWrapper>
  );
};
