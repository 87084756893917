import { useEffect, useState } from 'react';
import { getUploadedImages } from 'apis';

function extractImageFilename(location) {
  return location.split('/').pop();
}

function mapImagesToOptions(image) {
  return { value: image.location, title: extractImageFilename(image.location) };
}

export default function useUploadedImages() {
  const [userFiles, setUserFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserFiles = async () => {
      setIsLoading(true);
      try {
        const response = await getUploadedImages();
        setUserFiles(response.results.map(mapImagesToOptions));
      } catch (e) {
        console.error(e);
      }
      setIsLoading(false);
    };

    fetchUserFiles();
  }, []);

  return { userFiles, isLoading };
}
