import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { getAIOverviewMetrics } from 'apis';
import { If } from 'components/If';
import { Dimmer, Loader } from 'components/lib';
import queryConstants from 'utils/queries/constants';
import { queryStatusToLoaderStatus } from 'utils/helpers';

import { MEDIA_BREAK_POINTS, STATUSES } from '../../constants';
import { ConversionEngine, DigitalTourConversion, NextMonths, OverviewHeader, ProductTable, ValueAdded } from '.';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
`;

const TopSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-around;
  padding-top: 30px;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
    & > div {
      width: calc(100% / 3 - 20px);
    }
  }
`;

export const Overview = () => {
  const { data, status, isFetching } = useQuery(queryConstants.HOURLY_BREAKDOWN, getAIOverviewMetrics, {
    placeholderData: {},
  });
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  return (
    <Container>
      <OverviewHeader />
      <If condition={loaderStatus !== STATUSES.LOADING}>
        <TopSectionsContainer>
          <DigitalTourConversion tours={data?.tours} visitors={data?.visitors} />
          <ValueAdded leads={data?.leads} moveIns={data?.moveIns} />
          <NextMonths
            leadsWithoutFurther={data?.leadsProjectionWithoutFurther}
            leadsWithFurther={data?.leadsProjectionWithFurther}
            moveInsWithoutFurther={data?.moveInsProjectionWithoutFurther}
            moveInsWithFurther={data?.moveInsProjectionWithFurther}
          />
        </TopSectionsContainer>
        <ConversionEngine />
        <ProductTable productData={data?.featureMetrics} communitiesCount={data?.communities} />
      </If>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </Container>
  );
};
