import React, { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import classNames from 'classnames';
import { divMod } from 'utils/helpers';
import { If } from 'components/If';
import { ConversationContext } from '../ConversationContext';

const Wrapper = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  height: 310px;
  overflow-y: scroll;
`;

const Tabs = styled.div`
  display: flex;
  font-size: 12px;
  div {
    cursor: pointer;
    padding: 5px 11px 5px 12px;
    border-radius: 13px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    &.active {
      background-color: var(--vivid-blue);
      color: var(--white);
    }
  }
  div:first-child {
    margin-right: 10px;
  }
`;

const TabHeader = styled.p`
  margin: 20px 7px 10px 0;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 900;
  color: var(--text);

  span.ai-badge {
    padding: 0 6px;
    font-size: 12px;
    border-radius: 4px;
    margin-right: 5px;
    background-color: #ffe0ef;
    color: #ff5b98;
  }
`;

const Summary = styled(ReactMarkdown)`
  margin: 0 7px 20px 0 !important;
  padding-right: 15px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  white-space: pre-wrap;
  ul {
    line-height: 1;
    margin: 0;
    padding-inline-start: 1em;
  }
`;

const NoTranscript = styled.p`
  margin: 20px 7px 0 30px !important;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
`;

const Transcript = styled.div`
  display: grid;
  grid-template-columns: minmax(0, max-content) 24px 1fr;
  grid-row-gap: 13px;
  margin-bottom: 20px;
`;

const SentenceSpeaker = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  color: #89919d;

  & span {
    max-width: 100px;
    word-break: break-word;
    color: var(--text);
  }
`;

const SentenceDivider = styled.div`
  min-width: 4px;
  margin: 0 10px;
  background-color: ${({ speaker }) => (speaker === 'agent' ? 'var(--line-standard)' : 'var(--vivid-blue)')};
`;

const SentenceText = styled.span`
  color: var(--text);
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
`;

export const ForwardedCallTranscript = ({ transcript, summary }) => {
  const { selectedConversation } = useContext(ConversationContext);
  const [activeTab, setActiveTab] = useState('transcript');

  const transcriptSentences = transcript?.sentences || [];
  const showTabs = transcriptSentences.length > 0 && !!summary && summary.length > 0;

  useEffect(() => {
    if (showTabs) {
      setActiveTab('summary');
    }
  }, []);

  if (!transcript || !transcript.sentences) return <NoTranscript>No transcript available.</NoTranscript>;

  return (
    <Wrapper>
      <If condition={showTabs}>
        <Tabs activeTab={activeTab}>
          <div className={classNames({ active: activeTab === 'summary' })} onClick={() => setActiveTab('summary')}>
            Summary
          </div>
          <div
            className={classNames({ active: activeTab === 'transcript' })}
            onClick={() => setActiveTab('transcript')}
          >
            Transcript
          </div>
        </Tabs>
      </If>
      <If condition={activeTab === 'transcript'}>
        <TabHeader>Transcript</TabHeader>
        <Transcript>
          {transcriptSentences.map((sentence, index) => {
            const { text, speaker, start_time } = sentence;
            const [min, s] = divMod(start_time, 60);
            const [h, m] = divMod(min, 60);

            const minutes = String(m).padStart(2, '0');
            const seconds = String(s).padStart(2, '0');

            const speakerName = speaker === 'agent' ? 'Sales' : selectedConversation.name;
            return (
              <React.Fragment key={index}>
                <SentenceSpeaker>
                  <span title={speakerName}>{speakerName}</span>
                  <p>{`${h}:${minutes}:${seconds}`}</p>
                </SentenceSpeaker>
                <SentenceDivider speaker={speaker} />
                <SentenceText>{text}</SentenceText>
              </React.Fragment>
            );
          })}
        </Transcript>
      </If>

      <If condition={activeTab === 'summary'}>
        <TabHeader>
          Summary <span className="ai-badge">AI</span>
        </TabHeader>
        <Summary>{summary}</Summary>
      </If>
    </Wrapper>
  );
};
