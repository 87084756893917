import React from 'react';
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';

import { If } from 'components/If';

const CandidateInfoWrapper = styled.div`
  line-height: 1.7;

  .label {
    margin-left: 0 !important;
    margin-top: 0.5rem;
  }
`;
export const CandidateInfo = ({ mappingCandidate }) => {
  return (
    <CandidateInfoWrapper>
      <div>
        Form location:&nbsp;
        <a href={mappingCandidate?.form.url_location} target="_blank" rel="noopener noreferrer">
          {mappingCandidate?.form.url_location}
        </a>
      </div>

      <div>
        Form query selector:&nbsp;<code>{mappingCandidate?.form.query_selector}</code>
      </div>

      <If condition={mappingCandidate?.mapped_to_hash}>
        <div>
          Mapped to:&nbsp;<code>{mappingCandidate?.mapped_to_hash}</code>
        </div>
      </If>

      <div>
        User:&nbsp;<code>{mappingCandidate?.user}</code>
      </div>

      <If condition={mappingCandidate?.ignored}>
        <Label color="orange" size="large">
          IGNORED
        </Label>
      </If>
    </CandidateInfoWrapper>
  );
};
