import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dimmer, Loader, Message, Pagination, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getCommunities } from 'apis';
import SearchInput from 'components/SearchInput';
import { capitalize } from 'utils/helpers';
import { PAGINATION, STATUSES } from '../../../constants';

export const StickyTableHeader = styled(Table.Header)`
  tr:first-child > th {
    position: sticky !important;
    top: 0px;
    z-index: 2;
  }
`;

const FailedToFetch = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledLabel = styled.h5`
  color: ${(props) => (props.enabled ? 'green' : 'red')};
`;

const Wrapper = styled.div`
  position: relative;
  min-height: 60vh;
  padding: 17px 30px 0 30px;
`;

export const InstantAnswersCommunities = () => {
  const { t } = useTranslation();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [activePage, setActivePage] = useState(PAGINATION.PAGE);
  const [totalPages, setTotalPages] = useState(undefined);
  const [search, setSearch] = useState('');
  const [communities, setCommunities] = useState([]);

  const fetchCommunities = async (params) => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      const response = await getCommunities(params);
      const { results, count } = response;

      setCommunities(results);
      setTotalPages(Math.ceil(count / PAGINATION.PAGE_SIZE));
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      setCommunities(null);
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  useEffect(() => {
    fetchCommunities({ page: activePage, page_size: PAGINATION.PAGE_SIZE, search });
  }, [activePage, search]);

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const handleSearch = (search) => {
    setActivePage(PAGINATION.PAGE);
    setSearch(search);
  };

  return (
    <Wrapper>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <SearchInput
        value={search}
        onSearch={handleSearch}
        loading={loaderStatus === STATUSES.LOADING}
        placeholder={`Search by ${t('community')} ID, name, email...`}
        fluid
      />
      {communities?.length !== 0 && (
        <Table>
          <StickyTableHeader>
            <Table.Row>
              <Table.HeaderCell width={2}>{capitalize(t('community'))} ID</Table.HeaderCell>
              <Table.HeaderCell>{capitalize(t('community'))} Name</Table.HeaderCell>
              <Table.HeaderCell>Client Email</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
            </Table.Row>
          </StickyTableHeader>
          <Table.Body>
            {communities.map((community, i) => (
              <Table.Row key={i}>
                <Table.Cell width={2}>{community.provider_profile}</Table.Cell>
                <Table.Cell>
                  <Link to={`/settings/ia/${community.provider_profile}`}>{community.name}</Link>
                </Table.Cell>
                <Table.Cell>{community.client}</Table.Cell>
                <Table.Cell textAlign="center">
                  <StyledLabel enabled={community?.use_instant_answers}>
                    {community?.use_instant_answers ? 'Enabled' : 'Disabled'}
                  </StyledLabel>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          {totalPages > 1 && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  <Pagination activePage={activePage} onPageChange={handlePageChange} totalPages={totalPages} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      )}
      {communities?.length === 0 && <Message icon="search" content={`${capitalize(t('community'))} not found.`} />}
      {loaderStatus === STATUSES.FAILURE && (
        <FailedToFetch>
          <Message error icon="search" content={`${capitalize(t('communities'))} could not be loaded`} />
          <Button
            size="small"
            onClick={() => {
              window.location.reload();
            }}
          >
            Try again?
          </Button>
        </FailedToFetch>
      )}
    </Wrapper>
  );
};
