import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Route } from 'react-router-dom';

import { Loader } from 'components/lib';
import { parseErrorResponse } from 'apis/utils';
import { messageResend } from 'apis';
import { useMessagesContext } from 'components/messages/MessagesContext';
import EmailPreviewModal from 'components/conversation/chat/EmailPreviewModal';
import { ConversationMessageList } from './ConversationMessageList';
import { STATUSES } from '../../constants';
import { If } from '../If';
import { ConversationContext } from './ConversationContext';
import { ConversationHeader } from './ConversationHeader';
import { ConversationMessageComposer } from './ConversationMessageComposer';
import { ConversationSidebar } from './sidebar/ConversationSidebar';
import {
  AlternativeDatesModal,
  CallInfoModal,
  CancelModal,
  ConfirmTourCallModal,
  CronofyIntegrationModal,
  MoreOptionsModal,
  ScheduleTourCallModal,
  ScheduleReminderModal,
  SetUpAnotherCalendarModal,
} from './actions';
import { ResendMessageModal } from './chat/ResendMessageModal';
import { CONVERSATION_MESSAGE_TYPE } from './constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const ConversationMain = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

const ConversationMessages = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;

export const Conversation = () => {
  const { isViewBreakpoint, selectedConversation, messageLoader, markAsRead, sidebarExpanded } =
    useContext(ConversationContext);
  const { refreshLoadedPages = () => {} } = useMessagesContext();

  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState();
  const [resendLoading, setResendLoading] = useState(false);

  const handleSetMessages = (messages, add) => {
    setMessages((oldMessages) => (add ? [...messages, ...oldMessages] : messages));
  };

  useEffect(() => {
    window?.analytics?.track('Conversation Viewed', {});
  }, []);

  useEffect(() => {
    if (selectedConversation?.conversation) {
      handleSetMessages(selectedConversation.conversation);
    }
  }, [selectedConversation]);

  const handleOpenResendMessageModal = (message) => {
    setSelectedMessage(message);
  };

  const handleCloseResendMessageModal = () => {
    setSelectedMessage(null);
  };

  const handleResendMessage = async () => {
    try {
      setResendLoading(true);
      const { messageType, messageId } = selectedMessage;
      const messageTypeName = messageType === CONVERSATION_MESSAGE_TYPE.SMS ? 'sms' : 'email';
      const { status, messageSentAt } = await messageResend(messageTypeName, messageId);

      setMessages((draft) =>
        draft.map((message) => {
          const { message_type, data } = message;

          if (message_type === CONVERSATION_MESSAGE_TYPE.SMS || message_type === CONVERSATION_MESSAGE_TYPE.EMAIL) {
            const { id } = data;

            if (id === messageId) {
              return {
                ...message,
                created_on: messageSentAt,
                data: { ...data, status, message_sent_at: messageSentAt },
              };
            }
          }

          return message;
        })
      );

      refreshLoadedPages();
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to send message again'));
    } finally {
      setResendLoading(false);
      setSelectedMessage(null);
    }
  };

  const messagesClasses = classNames({
    expanded: sidebarExpanded && !isViewBreakpoint,
  });

  return (
    <Wrapper
      onClick={() => {
        if (selectedConversation?.has_unread) {
          markAsRead(selectedConversation.id);
        }
      }}
    >
      <Loader fullwidth active={messageLoader === STATUSES.LOADING} />

      <If condition={[STATUSES.LOADED, STATUSES.FAILURE].includes(messageLoader)}>
        <ConversationHeader />
        <ConversationMain>
          <If condition={messageLoader === STATUSES.LOADED}>
            <ConversationMessages className={messagesClasses}>
              <ConversationMessageList
                conversation={messages}
                lead={selectedConversation}
                viewPortContained
                onResendMessage={handleOpenResendMessageModal}
              />
              <ConversationMessageComposer />
            </ConversationMessages>
          </If>
          <ConversationSidebar />
        </ConversationMain>
      </If>

      <AlternativeDatesModal />
      <CancelModal />
      <ConfirmTourCallModal />
      <CronofyIntegrationModal />
      <SetUpAnotherCalendarModal />
      <MoreOptionsModal />
      <ScheduleTourCallModal />
      <ScheduleReminderModal />
      <CallInfoModal />
      <ResendMessageModal
        open={!!selectedMessage}
        loading={resendLoading}
        onSend={handleResendMessage}
        onCancel={handleCloseResendMessageModal}
      />
      <Route exact path="/conversations/:leadId/:type/:messageId">
        <EmailPreviewModal />
      </Route>
    </Wrapper>
  );
};
