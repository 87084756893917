import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Radio } from 'components/lib';
import { PromptButton } from 'components/common';
import { updateConnectedCalendarsSettings } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { pluralize } from 'utils/helpers';
import { STATUSES, CONNECTED_CALENDARS_ACTIONS } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';
import { useSettings } from '../context/SettingsContext';
import { UserWrapper } from './UserWrapper';

const MODAL_WIDTH = 540;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:first-child) {
    margin-left: 30px;
  }
  > span {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 6px;
  }
`;

const UserData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-bottom: 10px;
`;

const Calendars = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--emerald-green);
`;

const FilteredCalendars = styled.div`
  height: 321px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  padding-top: 20px;
`;

const Divider = styled.div`
  width: ${MODAL_WIDTH}px;
  height: 1px;
  background-color: var(--line-standard);
  position: absolute;
  bottom: 0;
  left: -30px;
`;

const PromptWrapper = styled.span`
  .prompt {
    border: solid 1px var(--salmon);
    background-color: rgba(250, 117, 80, 0.09);
    color: var(--salmon);
    font-family: var(--font-family-bold);
    width: 120px;
    height: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    &:hover,
    &:focus,
    &:active {
      border: solid 1px var(--salmon);
      background-color: #fde8e2;
      color: var(--salmon);
    }
    &.disabled {
      opacity: 0.5 !important;
    }
  }
`;

const SyncedCalendarsModal = ({ open, onClose, user, values, fetchUsers }) => {
  const [{ selectedItems, excludedItems }] = useSettings();
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const { filteredCalendars, name, id, communityId, email } = user;
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { t } = useTranslation();

  const handleConfirm = async () => {
    setLoaderStatus(STATUSES.LOADING);

    const data = {
      includeCommunities: selectedItems,
      excludeCommunities: excludedItems,
      connectedCalendar: !values.connectedCalendar ? null : values.connectedCalendar,
      includeAllSalesEmailsInCalendarInvite: values.includeAllSalesEmailsInCalendarInvite,
      users: [
        {
          userId: id,
          communityId: parseInt(communityId),
          action: CONNECTED_CALENDARS_ACTIONS.REMOVE,
          calendars: [selectedCalendar],
        },
      ],
    };
    try {
      await updateConnectedCalendarsSettings(data);
      toast.success('Settings updated successfully!');
    } catch (error) {
      const errorText = 'Trouble updating settings!';
      const msg = parseErrorResponse(error, errorText);
      console.error(msg, error?.response);
      toast.error(msg);
      setLoaderStatus(STATUSES.FAILURE);
    } finally {
      fetchUsers({ include: selectedItems, exclude: excludedItems });
      onClose();
      setLoaderStatus(STATUSES.LOADED);
    }
  };

  return (
    <SettingsModal open={open} onModalClose={onClose} width={MODAL_WIDTH} height={502} size="tiny">
      <SettingsModalHeader
        title={name ? `${name}'s Synced Calendars` : `${email} Synced Calendars`}
        type="header"
        hideCommunites
        noCapitalize={!name}
      />
      <div type="body">
        <UserData>
          <UserWrapper user={user} />
          <Calendars> {pluralize(filteredCalendars?.length, 'calendars', 'calendar')}</Calendars>
          <Divider />
        </UserData>
        <FilteredCalendars>
          {filteredCalendars?.map((calendar) => (
            <RadioWrapper key={calendar.id}>
              <CustomRadio>
                <Radio
                  name="calendaer"
                  value={calendar.id}
                  checked={selectedCalendar === calendar.id}
                  onChange={() => setSelectedCalendar(calendar.id)}
                />
                <span>{calendar.email}</span>
              </CustomRadio>
            </RadioWrapper>
          ))}
        </FilteredCalendars>
      </div>
      <SettingsModalActions onCancel={onClose} type="actions" hideSave cancelText="Close">
        <PromptWrapper>
          <PromptButton
            content="Remove"
            title="Remove Calendar?"
            description={`The calendar set up with ${email} will be removed from all ${t(
              'communities'
            )} it applied to. Are you sure you want to do this?`}
            onConfirm={handleConfirm}
            loading={loaderStatus === STATUSES.LOADING}
            triggerClassName="prompt"
            disabled={!selectedCalendar}
          />
        </PromptWrapper>
      </SettingsModalActions>
    </SettingsModal>
  );
};

export default SyncedCalendarsModal;
