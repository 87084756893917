import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import {
  checkCommunitySettingsActivation,
  changeChatModuleRecordingsStatus,
  getModuleRecordingsSettings,
  getCommunityModuleRecordingSettings,
  getCommunityModuleRecordings,
  updateCommunityModuleRecordings,
  deleteCommunityModuleRecordings,
} from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { COMMUNITY_STATUS, MODULE_UPLOAD_STATUS } from './constants';

const ModuleRecordingsContext = createContext();

const actions = {
  SET_COMMUNITIES: 'module-recordings/SET_COMMUNITIES',
  SET_SELECTED_COMMUNITY: 'module-recordings/SET_SELECTED_COMMUNITY',
  SET_ELIGIBLE_FOR_ACTIVATION: 'module-recordings/SET_ELIGIBLE_FOR_ACTIVATION',
  SET_COMMUNITY_MODULE_RECORDINGS: 'module-recordings/SET_COMMUNITY_MODULE_RECORDINGS',
  UPDATE_COMMUNITIES: 'module-recordings/UPDATE_COMMUNITIES',
  UPDATE_SELECTED_COMMUNITY: 'module-recordings/UPDATE_SELECTED_COMMUNITY',
  UPDATE_COMMUNITY_MODULE_RECORDING: 'module-recordings/UPDATE_COMMUNITY_MODULE_RECORDING',
  DELETE_COMMUNITY_MODULE_RECORDING: 'module-recordings/DELETE_COMMUNITY_MODULE_RECORDING',
  UPDATE_SELECTED_COMMUNITY_STATUS: 'module-recordings/UPDATE_SELECTED_COMMUNITY_STATUS',
  SET_LOADING_COMMUNITIES: 'module-recordings/SET_LOADING_COMMUNITIES',
  SET_LOADING_MODULES: 'module-recordings/SET_LOADING_MODULES',
  SET_COMMUNITY_SAVING: 'module-recordings/SET_COMMUNITY_SAVING',
  SET_MODULE_SAVING: 'module-recordings/SET_MODULE_SAVING',
};

const INITIAL_STATE = {
  communities: [],
  communityModuleRecordings: [],
  selectedCommunity: null,
  eligibleForActivation: false,
  isLoadingCommunities: false,
  isLoadingModules: false,
  isCommunitySaving: false,
  isModuleSaving: false,
};

function moduleRecordingsReducer(state, action) {
  switch (action.type) {
    case actions.SET_COMMUNITIES: {
      return { ...state, communities: action.data };
    }
    case actions.SET_SELECTED_COMMUNITY: {
      return { ...state, selectedCommunity: action.data };
    }
    case actions.SET_ELIGIBLE_FOR_ACTIVATION: {
      return { ...state, eligibleForActivation: action.data };
    }
    case actions.SET_COMMUNITY_MODULE_RECORDINGS: {
      return { ...state, communityModuleRecordings: action.data };
    }
    case actions.SET_LOADING_COMMUNITIES: {
      return { ...state, isLoadingCommunities: action.data };
    }
    case actions.SET_LOADING_MODULES: {
      return { ...state, isLoadingModules: action.data };
    }
    case actions.SET_COMMUNITY_SAVING: {
      return { ...state, isCommunitySaving: action.data };
    }
    case actions.SET_MODULE_SAVING: {
      return { ...state, isModuleSaving: action.data };
    }
    case actions.UPDATE_SELECTED_COMMUNITY: {
      const communities = state.communities.map((community) =>
        community.id === action.data.id ? action.data : community
      );

      return { ...state, communities, selectedCommunity: { ...state.selectedCommunity, ...action.data } };
    }
    case actions.UPDATE_SELECTED_COMMUNITY_STATUS: {
      const communities = state.communities.map((community) =>
        community.id === action.data.id ? action.data : community
      );

      return { ...state, communities, selectedCommunity: { ...state.selectedCommunity, ...action.data } };
    }
    case actions.UPDATE_COMMUNITY_MODULE_RECORDING: {
      const communityModuleRecordings = state.communityModuleRecordings.map((recording) =>
        recording.id === action.data.id ? action.data : recording
      );
      const completedModuleRecordings = communityModuleRecordings.filter(
        (module) => module?.recording?.uploadStatus === MODULE_UPLOAD_STATUS.COMPLETED
      );
      const hasModuleRecordings = completedModuleRecordings.length > 0;

      const selectedCommunity = {
        ...state.selectedCommunity,
        hasModuleRecordings,
      };

      const communities = state.communities.map((community) =>
        community.id === selectedCommunity.id ? { ...community, hasModuleRecordings } : community
      );

      return { ...state, communities, selectedCommunity, communityModuleRecordings };
    }
    case actions.DELETE_COMMUNITY_MODULE_RECORDING: {
      const communityModuleRecordings = state.communityModuleRecordings.map((recording) =>
        recording.id === action.data.id ? { ...action.data, recording: null } : recording
      );
      const completedModuleRecordings = communityModuleRecordings.filter(
        (module) => module?.recording?.uploadStatus === MODULE_UPLOAD_STATUS.COMPLETED
      );
      const hasModuleRecordings = completedModuleRecordings.length > 0;

      const selectedCommunity = {
        ...state.selectedCommunity,
        hasModuleRecordings,
      };

      const communities = state.communities.map((community) =>
        community.id === selectedCommunity.id ? { ...community, hasModuleRecordings } : community
      );

      return { ...state, communities, selectedCommunity, communityModuleRecordings };
    }
    default: {
      return state;
    }
  }
}

export const ModuleRecordingsProvider = ({ children }) => {
  const { chatId } = useParams();
  const [state, dispatch] = useReducer(moduleRecordingsReducer, INITIAL_STATE);

  useEffect(() => {
    fetchAssignedCommunities();
  }, []);

  const fetchAssignedCommunities = useCallback(async () => {
    dispatch({ type: actions.SET_LOADING_COMMUNITIES, data: true });

    try {
      const results = await getModuleRecordingsSettings(chatId);
      dispatch({ type: actions.SET_COMMUNITIES, data: results.results });
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to fetch communities'));
    }

    dispatch({ type: actions.SET_LOADING_COMMUNITIES, data: false });
  }, [chatId]);

  const fetchCommunitySettings = async () => {
    try {
      const community = await getCommunityModuleRecordingSettings(chatId, state.selectedCommunity.id);
      dispatch({ type: actions.UPDATE_SELECTED_COMMUNITY, data: community });
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to fetch community settings'));
    }
  };

  const fetchChatModuleRecordings = async (community) => {
    dispatch({ type: actions.SET_LOADING_MODULES, data: true });

    try {
      const { results } = await getCommunityModuleRecordings(chatId, community.id);
      dispatch({ type: actions.SET_COMMUNITY_MODULE_RECORDINGS, data: results });
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to fetch community modules'));
    }

    dispatch({ type: actions.SET_LOADING_MODULES, data: false });
  };

  const fetchCommunityActivationStatus = async (community) => {
    try {
      const { isActionAllowed } = await checkCommunitySettingsActivation(chatId, community.id);
      dispatch({ type: actions.SET_ELIGIBLE_FOR_ACTIVATION, data: isActionAllowed });
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to fetch community status'));
    }
  };

  const changeCommunitySettingsStatus = async ({ isEnabled }) => {
    dispatch({ type: actions.SET_COMMUNITY_SAVING, data: true });

    try {
      await changeChatModuleRecordingsStatus(chatId, state.selectedCommunity.id, { isEnabled });
      await fetchCommunitySettings();
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to change status.'));
    }

    dispatch({ type: actions.SET_COMMUNITY_SAVING, data: false });
  };

  const updateModuleRecording = async (selectedModule, audio) => {
    dispatch({ type: actions.SET_MODULE_SAVING, data: true });

    try {
      const formData = new FormData();

      if (audio) {
        formData.append('audio', audio);

        const module = await updateCommunityModuleRecordings(
          chatId,
          state.selectedCommunity.id,
          selectedModule.id,
          formData
        );

        dispatch({ type: actions.UPDATE_COMMUNITY_MODULE_RECORDING, data: module });
      } else {
        await deleteCommunityModuleRecordings(chatId, state.selectedCommunity.id, selectedModule.id);
        dispatch({ type: actions.DELETE_COMMUNITY_MODULE_RECORDING, data: selectedModule });
      }

      await fetchCommunityActivationStatus(state.selectedCommunity);
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to set module recording.'));
    } finally {
      dispatch({ type: actions.SET_MODULE_SAVING, data: false });
    }
  };

  const setSelectedCommunity = useCallback(
    async (selectedCommunity) => {
      if (selectedCommunity?.id && selectedCommunity?.id !== state?.selectedCommunity?.id) {
        dispatch({ type: actions.SET_SELECTED_COMMUNITY, data: selectedCommunity });
        await fetchChatModuleRecordings(selectedCommunity);
        await fetchCommunityActivationStatus(selectedCommunity);
      }
    },
    [chatId]
  );

  const getCommunitySyncStatus = (community) => {
    const audioSettings = community?.chatModuleRecordingSettings;

    if (
      (audioSettings === null || audioSettings === undefined || !audioSettings?.isEnabled) &&
      !community.hasModuleRecordings
    ) {
      return COMMUNITY_STATUS.NONE;
    }

    if (community?.hasModuleRecordings && !audioSettings?.isEnabled) {
      return COMMUNITY_STATUS.DRAFT;
    }

    if (audioSettings?.isEnabled) {
      return COMMUNITY_STATUS.ACTIVE;
    }

    return COMMUNITY_STATUS.NONE;
  };

  const value = {
    state,
    // API
    updateModuleRecording,
    getCommunitySyncStatus,
    setSelectedCommunity,
    changeCommunitySettingsStatus,
  };

  return <ModuleRecordingsContext.Provider value={value}>{children}</ModuleRecordingsContext.Provider>;
};

export const useModuleRecordings = () => {
  const ctx = useContext(ModuleRecordingsContext);

  if (!ctx) {
    throw new Error('useModuleRecordings must be used within the ModuleRecordingsProvider');
  }

  return ctx;
};
