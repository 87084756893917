export const VIEW_TYPE = {
  MESSAGING: 1,
  CONVERSATION: 2,
};

export const VIEW_TYPE_BREAKPOINT = {
  [VIEW_TYPE.MESSAGING]: 1540,
  [VIEW_TYPE.CONVERSATION]: 1200,
};

export const MESSAGE_SENDER_TYPE = {
  LEAD: 'lead',
  COMMUNITY_MANAGER: 'community_manager',
  SALES_ASSISTANT: 'sales_assistant',
  SYSTEM: 'system',
  AUTOMATED: 'automated',
};

export const SIDEBAR_TAB = {
  COMMUNITY: 'community',
  LEAD: 'lead',
  CALLS: 'calls',
  PROCESSING: 'processing',
};

export const CONVERSATION_MESSAGE_TYPE = {
  SMS: 1,
  EMAIL: 2,
  VSA: 3,
  CALL: 4,
  CONVERSATION_STOPPED: 5,
  NOTE: 6,
};

export const CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS = {
  ...CONVERSATION_MESSAGE_TYPE,
  OTHER_EVENTS: 'other_events',
};

export const CONVERSATION_MESSAGE_TYPE_NAME = {
  [CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS.SMS]: 'SMS',
  [CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS.EMAIL]: 'Email',
  [CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS.VSA]: 'VSA',
  [CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS.CALL]: 'Call',
  [CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS.CONVERSATION_STOPPED]: 'Conversation Stopped',
  [CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS.NOTE]: 'Notes',
  [CONVERSATION_MESSAGE_TYPE_FILTER_OPTIONS.OTHER_EVENTS]: 'Other Events',
};

export const CALENDAR_REQUEST_SOURCE_TYPE = {
  VIRTUAL_SALES_ASSISTANT: 1,
  MESSAGING_ASSISTANT: 2,
};

export const CALENDAR_REQUEST_SOURCE = {
  [CALENDAR_REQUEST_SOURCE_TYPE.VIRTUAL_SALES_ASSISTANT]: "Further's Virtual Sales Assistant",
  [CALENDAR_REQUEST_SOURCE_TYPE.MESSAGING_ASSISTANT]: "Further's Messaging Assistant",
};

export const CONVERSATION_MESSAGE_NAME = {
  [CONVERSATION_MESSAGE_TYPE.SMS]: 'SMS',
  [CONVERSATION_MESSAGE_TYPE.EMAIL]: 'Email',
  [CONVERSATION_MESSAGE_TYPE.VSA]: 'VSA',
};

export const MORE_OPTIONS_MENU_WIDTH = 140;

export const MESSAGE_STATUS = {
  SENT: 'sent',
  DELIVERED: 'delivered',
  FAILED: 'failed',
  PENDING: 'pending',
};

export const ROW_TYPE_CLASS_NAME = {
  USER_EVENT: 'user-event',
  OTHER_TYPES: 'other-types',
};

export const INPUT_FIELD_ROWS = {
  ONE: 1,
  TWO: 2,
};

export const LEAD_STATUS_STYLE = {
  LIGHT: 'light',
  BOLD: 'bold',
};

export const OPT_OUT_CONVERSATION_TYPE = {
  SMS: 'sms',
  EMAIL: 'email',
};

export const OPT_OUT_CONVERSATION_TYPE_NAME = {
  [OPT_OUT_CONVERSATION_TYPE.SMS]: 'SMS',
  [OPT_OUT_CONVERSATION_TYPE.EMAIL]: 'Email',
};
