import React from 'react';
import { useTranslation } from 'react-i18next';

import { BooleanSettingsPage } from '../common';

export const MoveinLikelihood = () => {
  const { t } = useTranslation();
  return (
    <BooleanSettingsPage
      flagName="show_likelihood_to_movein_in_email"
      modalText={`Choose whether to show that the ${t('lead')} is likely to ${t('move_in')} in emails.`}
      modalTitle={`Indicator of high chance to ${t('move_in')}`}
    />
  );
};
