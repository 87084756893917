import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { If } from 'components/If';

import { ACTIONS, CommunitiesContext } from '../common/communities/CommunitiesContext';
import { UserCommunityHeader } from './UserCommunityHeader';
import { UserCommunityPanels } from './UserCommunityPanels';
import { UserCommunityAssignModal } from './UserCommunityAssignModal';
import { USER_COMMUNITY_ACTION_MODALS } from './constants';
import { UserCommunityRemoveModal } from './UserCommunityRemoveModal';

const CommunityContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UserCommunity = () => {
  const { communityId } = useParams();
  const { state, dispatch } = useContext(CommunitiesContext);
  const { activeModal } = state;

  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_COMMUNITY,
      payload: { communityId: parseInt(communityId) },
    });
  }, [communityId]);

  return (
    <CommunityContainer>
      <UserCommunityHeader />
      <UserCommunityPanels />
      <If condition={activeModal === USER_COMMUNITY_ACTION_MODALS.ASSIGN_COMMUNITY}>
        <UserCommunityAssignModal />
      </If>
      <If condition={activeModal === USER_COMMUNITY_ACTION_MODALS.REMOVE_COMMUNITY}>
        <UserCommunityRemoveModal />
      </If>
    </CommunityContainer>
  );
};
