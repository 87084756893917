import React from 'react';
import styled from 'styled-components';

const DEFAULT_METRIC_FONT_SIZE = 'inherit';

const SimpleMetric = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2px 5px;
  align-items: baseline;
  overflow: hidden;

  font-family: var(--font-family);
  font-size: ${(p) => p.fontSize || DEFAULT_METRIC_FONT_SIZE};
  .metric {
    color: var(--text);
    font-family: ${(p) => p.metricFontFamily || 'var(--font-family-bold)'};
    margin-right: 5px;
  }

  .unit {
    font-size: ${(p) => p.unitFontSize || p.fontSize || DEFAULT_METRIC_FONT_SIZE};
    color: ${(p) => p.unitColor || '#89919d'};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default ({ metric, unit, ...other }) => (
  <SimpleMetric {...other}>
    <div className="metric">{metric}</div>
    <div className="unit" title={unit}>
      {unit}
    </div>
  </SimpleMetric>
);
