import axios from 'axios';

import { loginPathWithNext, shouldRedirectSsoUnauthorized } from 'utils/helpers';
import { getChecksum } from './apis/utils';

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

const publicRoutes = [
  '/login',
  '/login/pwd',
  '/login/otp',
  '/signup',
  '/public-report',
  '/tours',
  '/login/forgot-password',
  '/reset-password',
  '/sso-unauthorized',
  '/unit-level-pricing',
  '/otp-reset',
  '/status',
  '/self-service-onboarding',
  '/unsubscribe',
  '/settings/email-integrations/oauth',
];

// Add request interceptor which attaches X-CHECKSUM header
API.interceptors.request.use((config) => {
  try {
    let checksum = '';
    if (config.method === 'get' && config.params) {
      const uri = axios.getUri(config);
      const searchQueryString = uri.split('?')[1];
      checksum = getChecksum(searchQueryString);
    } else if (config.data) {
      const requestBody = config.data;
      checksum = getChecksum(requestBody);
    } else {
      checksum = getChecksum('');
    }

    config.headers['X-CHECKSUM'] = checksum;
  } catch (e) {
    console.log('error', e);
  }

  return config;
});

// Add a response interceptor
API.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const responseStatus = error?.response?.status;
    if (
      responseStatus === 401 &&
      !publicRoutes.some((r) => window.location.pathname.includes(r)) &&
      !shouldRedirectSsoUnauthorized()
    ) {
      const loginPath = loginPathWithNext();
      window.location.assign(loginPath);
    } else if (responseStatus === 403) {
      window.location.replace(`/forbidden?q=${window.location.pathname}`);
    }
    return Promise.reject(error);
  }
);

export const getChatConfig = async (url) => {
  const response = await API.get(url);
  return response.data;
};

// for chat edit page

export const getAllChats = async (params) => {
  let url = '/api/v1/chats/';
  const { vsa_type, search } = params || {};

  if (vsa_type || search) {
    const query = new URLSearchParams({ vsa_type, search }).toString();
    url = `${url}?${query}`;
  }

  const response = await API.get(url);
  return response.data;
};

export const postUnsubscribe = async (token) => {
  const response = await API.post(`/api/chat/unsubscribe/${token}`);
  return response.data;
};

export const getChatTypes = async () => {
  const response = await API.get(`/api/v1/chats/types`);
  return response.data;
};

export const getChat = async (chatId) => {
  const response = await API.get(`/api/v1/chats/${chatId}/`);
  return response.data;
};

export const getChatPublishStatus = async (chatId) => {
  const response = await API.get(`/api/chat/${chatId}/publish-status`);
  return response.data;
};

export const getChatModules = async (chatId) => {
  // tmp flag, for testing purposes; will remove in next MR
  const searchParams = new URLSearchParams(window.location.search);

  let url = `/api/v1/chats/${chatId}/modules/`;

  if (searchParams.get('useActionsModel')) {
    url += '?use_action_model=true';
  }

  const response = await API.get(url);
  return response.data;
};

export const updateChatById = async (chatId, data) => {
  const response = await API.patch(`/api/v1/chats/${chatId}/`, data);
  return response.data;
};

export const updateChatModules = async (chatId, modules) => {
  const response = await API.put(`/api/v1/chats/${chatId}/update_modules/`, { modules });
  return response.data;
};

export const validateChat = async (chat_id) => {
  const response = await API.get(`/api/chat/${chat_id}/validation`);
  return response.data;
};

export const copyChatDemoInstantAnswers = async (chatId, data) => {
  const response = await API.put(`/api/v1/chats/${chatId}/copy_demo_instant_answers/`, data);
  return response.data;
};

export const uploadIcon = async (image) => {
  const blob = new Blob([image], { type: image.type });

  const formData = new FormData();
  formData.append('icon', blob, image.name);

  const response = await API.post(`/api/v1/chats/save_icon/`, formData);
  return response.data;
};

export const sendPublishChange = async (chatId) => {
  const response = await API.post(`/api/v1/chats/${chatId}/publish_changes/`, {});
  return response.data;
};

export const createPublishChats = async (data) => {
  const response = await API.post('/api/chat/publish', data);
  return response.data;
};

export const createChat = async (data) => {
  const response = await API.post(`/api/v1/chats/`, data);
  return response.data;
};

export const cloneChat = async (chatId, data) => {
  const response = await API.post(`/api/v1/chats/${chatId}/clone_chat/`, data);
  return response.data;
};

export const setChatLive = async (chatId, data) => {
  const response = await API.patch(`/api/v1/chats/${chatId}/set_live/`, data);
  return response.data;
};

export const logIn = async (credentials) => {
  const response = await API.post(`/api/chat/login`, credentials);
  return response.data;
};

export const logOut = async () => {
  await API.post(`/api/chat/logout`);
};

export const getLoggedInUser = async () => {
  const response = await API.get(`/api/chat/login`);
  return response.data;
};

export const validateUserEmailAddress = async ({ email }) => {
  const response = await API.post('/api/chat/validate-user', { email });
  return response.data;
};

export const sendOTPLink = async ({ email }) => {
  const response = await API.post('/api/chat/send-otp-link', { email });
  return response.data;
};

export const signUp = async ({ firstName, lastName, email, password, inviteToken }) => {
  const response = await API.post(`/api/chat/signup`, {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    invite_token: inviteToken,
  });
  return response.data;
};

export const getInviteLocations = async () => {
  const response = await API.get(`/api/chat/user-invite/locations`);
  return response.data;
};

export const saveRoomAndServiceOptions = async (data) => {
  const response = await API.post(`/api/chat/room_and_service_options`, data);
  return response.data;
};

export const updatePricing = async (data) => {
  const response = await API.patch(`/api/chat/pricing`, data);
  return response.data;
};

export const activateAnalytics = async (chatId) => {
  const response = await API.put(`/api/v1/chats/${chatId}/activate_analytics/`);
  return response.data;
};

export const uploadMoveInData = async (data) => {
  const response = await API.post('/api/chat/leads_move_in', data);
  return response.data;
};

export const confirmMoveInData = async (data) => {
  const response = await API.put('/api/chat/leads_move_in', data);
  return response.data;
};

export const uploadPhoneNumbers = async (data) => {
  const response = await API.post('/api/communities/phone-numbers-import', data);
  return response.data;
};

export const confirmPhoneNumbersImport = async (data) => {
  const response = await API.put('/api/communities/phone-numbers-import', data);
  return response.data;
};

export const resetOtpLink = async (data) => {
  const response = await API.post('/api/chat/otp-reset', data);
  return response.data;
};
