import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

import { If } from 'components/If';

const CRMSettingsRemoveModal = ({ open, loading, crmSettings, onCancel, onConfirm }) => {
  if (!crmSettings) {
    return null;
  }

  return (
    <Modal size="tiny" open={open} onClose={onCancel} closeOnDimmerClick={!loading}>
      <Modal.Header>Delete CRM Settings</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to delete <b>{crmSettings.name}</b> CRM settings?
        </p>
        <If condition={crmSettings.communities_count}>
          <p>
            All <b>{crmSettings.communities_count}</b> communities we'll be moved to default CRM settings.
          </p>
        </If>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel} disabled={loading}>
          No
        </Button>
        <Button negative onClick={onConfirm} loading={loading} disabled={loading}>
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CRMSettingsRemoveModal;
