import React from 'react';
import { Route } from 'react-router';

import { FeaturesContextProvider } from './FeaturesContext';
import FeaturesOverviewUsers from './FeaturesOverviewUsers';
import FeaturesOverviewCommunities from './FeaturesOverviewCommunities';
import FeaturesOverviewCommunity from './FeaturesOverviewCommunity';
import { PageHeader } from '../../common';
import { FeaturesOverviewGlobal } from './FeaturesOverviewGlobal';
import FeaturesOverviewNavigation from './FeaturesOverviewNavigation';

const FeaturesOverview = () => (
  <FeaturesContextProvider>
    <PageHeader
      as="h2"
      title="Features Overview"
      subtitle="Preview change in feature usage and status condition changes for each user community."
      padding={PageHeader.PADDING.VERTICAL}
      margin={PageHeader.MARGIN.NONE}
    />

    <Route exact path="/admin/features/:userId?/:communityId?">
      <FeaturesOverviewNavigation />
    </Route>

    <Route exact path="/admin/features">
      <FeaturesOverviewGlobal />
      <FeaturesOverviewUsers />
    </Route>
    <Route exact path="/admin/features/:userId">
      <FeaturesOverviewCommunities />
    </Route>
    <Route exact path="/admin/features/:userId/:communityId">
      <FeaturesOverviewCommunity />
    </Route>
  </FeaturesContextProvider>
);

export default FeaturesOverview;
