import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';

import { WithTopBar } from 'components/WithTopBar';
import { Content } from 'components/common';
import { useUserContext } from 'components/UserContext';
import UserManagement from 'components/user-management/UserManagement';
import CRMAdminLogs from 'components/crm-admin-logs/CRMAdminLogs';
import CommunityData from './CommunityData';
import QuestionReviews from './QuestionReviews';
import ClientManagement from './ClientManagement';
import InstantResponsesRoutes from './InstantResponses/InstantResponsesRoutes';
import SystemStats from './SystemStats/SystemStats';
import SEOAnalyses from './SEOAnalysis';
import EmailSubjectsRoot from './EmailSubjects';
import { ScheduledMessages } from './ScheduledMessages';
import BusinessActivities from './BusinessActivities';
import Dashboard from './Dashboard';
import { WebFormsAdmin } from './WebForms/WebFormsAdmin';
import FeaturesOverview from './FeaturesOverview';
import { EmailTemplates } from '../email-templates';
import { ManageAnswers } from './ManageAnswers/ManageAnswers';

const Admin = () => {
  const history = useHistory();
  const isAdminRoot = useRouteMatch('/admin').isExact;
  const { getSessionOriginalUser } = useUserContext();
  const user = getSessionOriginalUser();

  useEffect(() => {
    if (isAdminRoot) {
      history.push('/admin/client-management');
    }
  }, [isAdminRoot, history]);

  const isUserManagementRoute = location.pathname === '/admin/user-management';
  const isCRMAdminLogsRoute = location.pathname.startsWith('/admin/crm-admin-logs');

  return (
    <WithTopBar>
      <Content noPadding={isUserManagementRoute || isCRMAdminLogsRoute}>
        <Switch>
          <Route exact path="/admin/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/admin/client-management">
            <ClientManagement />
          </Route>
          <Route path="/admin/features">
            <FeaturesOverview />
          </Route>
          <Route exact path="/admin/questions-labeling">
            <QuestionReviews />
          </Route>
          <Route path="/admin/instant-responses">
            <InstantResponsesRoutes />
          </Route>
          <Route path="/admin/answers">
            <ManageAnswers />
          </Route>
          <Route path="/admin/stats">
            <SystemStats />
          </Route>
          <Route path="/admin/seo-analyses">
            <SEOAnalyses />
          </Route>
          <Route path="/admin/custom-email-subjects">
            <EmailSubjectsRoot />
          </Route>
          <Route path="/admin/scheduled-messages">
            <ScheduledMessages />
          </Route>
          <Route path="/admin/events">
            <BusinessActivities />
          </Route>
          <Route path="/admin/web-forms">
            <WebFormsAdmin />
          </Route>
          <Route path="/admin/communities/:communityId">
            <CommunityData />
          </Route>
          {user.is_superuser && (
            <Route path="/admin/email-templates">
              <EmailTemplates />
            </Route>
          )}
          <Route path="/admin/user-management">
            <UserManagement />
          </Route>
          <Route path="/admin/crm-admin-logs">
            <CRMAdminLogs />
          </Route>
        </Switch>
      </Content>
    </WithTopBar>
  );
};

export default Admin;
