import React from 'react';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import { Grid, Statistic, Header } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { getHourlyBreakdown } from 'apis';
import queryConstants from 'utils/queries/constants';
import { capitalize, queryStatusToLoaderStatus } from 'utils/helpers';
import { PageSection } from '../../common';
import { formatPercent } from '../../utils';

const Wrapper = styled.div`
  .padded-top {
    padding-top: 2rem;
  }

  .ui.horizontal.statistics {
    .statistic {
      width: 100%;

      .value {
        white-space: nowrap;
      }

      .label {
        width: 100%;
        text-align: right;
      }
    }
  }
`;

const toAmPm = (hour) => {
  if (hour === 0) return 'Midnight';
  if (hour === 12) return 'Noon';

  return `${hour > 12 ? hour - 12 : hour} ${hour > 11 ? 'PM' : 'AM'}`;
};

const HourlyBreakdown = ({ filters, publicId }) => {
  const { t } = useTranslation();
  const { data, status, isFetching } = useQuery(
    [queryConstants.HOURLY_BREAKDOWN, { publicId, filters }],
    () => getHourlyBreakdown({ public_id: publicId, filters }),
    { placeholderData: { hourly_breakdown: [], after_hours_stats: {}, totals: {} } }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  const hourly = data?.hourly_breakdown;
  const afterHours = data?.after_hours_stats;
  const totals = data?.totals;

  const breakdownData = {
    labels: hourly?.map((d) => toAmPm(d.hour)),
    datasets: [
      {
        label: 'Website Visitors',
        data: hourly?.map((d) => d.visitors),
        backgroundColor: 'rgba(31, 87, 189, 0.9)',
      },
      {
        label: 'VSA Users',
        data: hourly?.map((d) => d.interactions),
        backgroundColor: 'rgba(54, 162, 235, 0.9)',
      },
      {
        label: `New ${t('leads')}`,
        data: hourly?.map((d) => d.leads),
        backgroundColor: 'rgba(255, 127, 14, 0.9)',
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            borderDash: [2, 10],
            drawBorder: false,
          },
          ticks: {
            padding: 16,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2, 10],
            drawBorder: false,
          },
          ticks: {
            min: 0,
            padding: 16,
          },
        },
      ],
    },
  };

  return (
    <PageSection title="Hourly Breakdown" loaderStatus={loaderStatus}>
      <Wrapper>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={13}>
              <Bar height={300} data={breakdownData} options={options} />
            </Grid.Column>
            <Grid.Column width={3} className="padded-top">
              <Header as="h3">Percentage of Conversations After Hours & on Weekends</Header>

              <Statistic.Group size="tiny" horizontal>
                <Statistic
                  label="Conversations"
                  value={formatPercent(afterHours?.interactions_after_hours, totals?.interactions_total)}
                  size="mini"
                />
                <Statistic
                  label={capitalize(t('leads'))}
                  value={formatPercent(afterHours?.leads_after_hours, totals?.leads_total)}
                  size="mini"
                />
                <Statistic
                  label={capitalize(t('move_ins'))}
                  value={formatPercent(afterHours?.move_ins_after_hours, totals?.move_ins_total)}
                  size="mini"
                />
              </Statistic.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Wrapper>
    </PageSection>
  );
};

export default HourlyBreakdown;
