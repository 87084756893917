import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

import { Rating, Checkbox, Table } from 'components/lib';
import { If } from 'components/If';
import ResultCount from 'components/common/ResultCount';
import UserCommunityTestimonialsModal from './UserCommunityTestimonialsModal';
import UserCommunityTestimonialsDeleteModal from './UserCommunityTestimonialsDeleteModal';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
`;

const StyledTableHeaderCell = styled(Table.HeaderCell)`
  .table-header-cell-content {
    display: block;
  }
`;

const UserCommunityTestimonialsTable = ({
  testimonials = [],
  loading,
  onEdit,
  onDelete,
  page,
  totalPages,
  totalCount,
  onPageChange,
}) => {
  const [openTestimonialsModal, setOpetTestimonialsModal] = useState(false);
  const [openDeleteTestimonialsModal, setOpenDeleteTestimonialsModal] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);

  const onShowTestimonialChange = async (testimonial) => {
    setSelectedTestimonial(testimonial.id);
    await onEdit(testimonial.id, { show_on_embedded_vsa: !testimonial.show_on_embedded_vsa });
    setSelectedTestimonial(null);
  };

  const onUpdateTestimonial = async (testimonialData) => {
    await onEdit(selectedTestimonial.id, testimonialData);
    onUpdateTestimonialClose();
  };

  const onDeleteTestimonial = async () => {
    await onDelete(selectedTestimonial.id);
    onDeleteTestimonialClose();
  };

  const onUpdateTestimonialHandle = (testimonial) => () => {
    setSelectedTestimonial(testimonial);
    setOpetTestimonialsModal(true);
  };

  const onUpdateTestimonialClose = () => {
    setSelectedTestimonial(null);
    setOpetTestimonialsModal(false);
  };

  const onDeleteTestimonialHandle = (testimonial) => () => {
    setSelectedTestimonial(testimonial);
    setOpenDeleteTestimonialsModal(true);
  };

  const onDeleteTestimonialClose = () => {
    setSelectedTestimonial(null);
    setOpenDeleteTestimonialsModal(false);
  };

  const handlePageChange = (activePage) => {
    onPageChange(activePage);
  };

  return (
    <>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <StyledTableHeaderCell width={2}>Name</StyledTableHeaderCell>
            <StyledTableHeaderCell width={3}>Rating</StyledTableHeaderCell>
            <StyledTableHeaderCell width={6}>Testimonial / Fact</StyledTableHeaderCell>
            <StyledTableHeaderCell textAlign="center" width={3}>
              Show On VSA
            </StyledTableHeaderCell>
            <StyledTableHeaderCell width={2} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {testimonials.map((r) => (
            <Table.Row key={r.id} disabled={selectedTestimonial === r.id} verticalAlign="top">
              <Table.Cell>
                {r.first_name} {r.last_name}
              </Table.Cell>
              <Table.Cell>{r.type === 'testimonial' ? <Rating number={r.rating}></Rating> : 'Fact'}</Table.Cell>
              <Table.Cell>{r.text}</Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox toggle checked={r.show_on_embedded_vsa} onChange={() => onShowTestimonialChange(r)} />
              </Table.Cell>
              <Table.Cell>
                <Button.Group floated="right">
                  <Button basic type="button" icon="edit" size="small" onClick={onUpdateTestimonialHandle(r)} />
                  <Button basic type="button" icon="times" size="small" onClick={onDeleteTestimonialHandle(r)} />
                </Button.Group>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <If condition={totalPages > 1}>
        <Footer>
          <div>
            <Table.Pagination page={page} onPageChange={handlePageChange} totalPages={totalPages} />
          </div>
          <div style={{ marginRight: '20px' }}>
            <ResultCount current={testimonials?.length} total={totalCount} />
          </div>
        </Footer>
      </If>
      <UserCommunityTestimonialsModal
        open={openTestimonialsModal}
        testimonial={selectedTestimonial}
        loading={loading}
        onSave={onUpdateTestimonial}
        onClose={onUpdateTestimonialClose}
      />
      <If condition={selectedTestimonial !== null}>
        <UserCommunityTestimonialsDeleteModal
          open={openDeleteTestimonialsModal}
          testimonial={selectedTestimonial}
          loading={loading}
          onDelete={onDeleteTestimonial}
          onClose={onDeleteTestimonialClose}
        />
      </If>
    </>
  );
};

export default UserCommunityTestimonialsTable;
