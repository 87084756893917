import React from 'react';

import { Dropdown } from 'components/lib';
import { LEAD_USER_REMINDER_SCHEDULE_TYPE_OPTIONS } from '../../constants';
import { InputField } from '../common/fields/InputField';
import { scrollIntoView } from '../utils';
import { Actions, ActionTitle } from './ConversationMessageComposer';

export const ConversationMessageComposerReminder = ({ disabled }) => (
  <Actions>
    <ActionTitle>Remind in</ActionTitle>
    <InputField
      selection
      basic
      floating
      onError={scrollIntoView}
      control={Dropdown}
      name="reminder"
      options={LEAD_USER_REMINDER_SCHEDULE_TYPE_OPTIONS}
      placeholder="Select"
      disabled={disabled}
    />
  </Actions>
);
