import React, { useContext, useMemo } from 'react';
import { Icon, Label, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { MessageContext } from './MessageContext';

const Condition = styled.span`
  font-size: 80%;
  margin-left: 3px;
`;

const LabelContent = styled.a`
  position: relative;

  .tooltip {
    position: absolute;
    top: -3px;
    left: -3px;
    opacity: 0;
    color: #767676;
  }
  :hover .tooltip {
    opacity: 90;
  }
  .tooltip:hover {
    opacity: 90;
  }
`;

export const stringifyCondition = (c, label) => {
  if (c.type === 'exists') {
    return c.field === label ? 'defined' : c.field;
  }
  if (c.type === 'undefined') {
    return c.field === label ? 'undefined' : `no ${c.field}`;
  }
  if (c.type === 'equals') {
    if (c.field === label) {
      if (typeof c.value === 'boolean') {
        return c.value ? 'yes' : 'no';
      }
      return c.value;
    }
    return `${c.field}=${c.value}`;
  }
  if (c.type === 'contains') {
    return `${c.field} contains ${c.value}`;
  }
  if (c.type === 'greater_than') {
    return `${c.field}>${c.value}`;
  }
  if (c.type === 'less_than') {
    return `${c.field}<${c.value}`;
  }
  if (c.type === 'one_of') {
    return `${c.field} in ${c.value}`;
  }
  return JSON.stringify(c);
};

const EnrichedLabel = ({ id, label, displayText, answer, conditions, conditionStatus, selected, options }) => {
  const { addLabel, removeLabel } = useContext(MessageContext);

  const onClick = !selected
    ? (event) => {
        addLabel({ id, label, answer });
        event.stopPropagation();
      }
    : undefined;

  const onRemove = selected
    ? (event) => {
        removeLabel({ id });
        event.stopPropagation();
      }
    : undefined;

  const tooltip = (
    <Popup trigger={<Icon className="tooltip" name="info circle" size="small" />} content={answer} on="hover" />
  );

  let info;
  if (conditionStatus.startsWith('missing:')) {
    info = (
      <Popup
        trigger={<Icon name="exclamation circle" color="orange" />}
        content={`Missing value for field ${conditionStatus.substring(8)}`}
        on="hover"
      />
    );
  } else if (conditionStatus === 'inapplicable') {
    info = (
      <Popup
        trigger={<Icon name="exclamation circle" color="red" />}
        content="Conditions are not met for this label. Doublecheck if info you are sending are right"
        on="hover"
      />
    );
  } else if (conditionStatus === 'applicable' && conditions?.length) {
    const undefinedFields = conditions.filter((c) => c.type === 'undefined').map((c) => c.field);
    if (undefinedFields.length) {
      info = (
        <Popup
          trigger={<Icon name="exclamation circle" color="grey" />}
          content={`Conditions are satisfied but condition for ${undefinedFields} is that it is not defined.`}
          on="hover"
        />
      );
    }
  }
  let optionsPopup;
  if (options?.length > 1) {
    optionsPopup = (
      <Popup
        trigger={<Icon name="ellipsis horizontal" size="small" style={{ marginLeft: '5px' }} />}
        content={<LabelOptions options={options} />}
        on="hover"
        hoverable
        wide
      />
    );
  }
  const color = selected ? 'teal' : undefined;
  const content = (
    <>
      {tooltip}
      {info}
      {displayText || label}
      {optionsPopup}
    </>
  );
  return (
    <Label
      as={LabelContent}
      content={content}
      color={color}
      removeIcon="delete"
      onClick={onClick}
      onRemove={onRemove}
    />
  );
};

const LabelOptions = ({ options }) => {
  return (
    <div>
      {options.map((o) => {
        const displayText = o.conditions?.map((c) => stringifyCondition(c, o.intent)).join(' & ') || 'text';
        return (
          <EnrichedLabel
            key={o.id}
            id={o.id}
            label={o.intent}
            displayText={displayText}
            answer={o.text}
            conditions={o.conditions}
            conditionStatus={o.condition_status}
            selected={o.selected}
          />
        );
      })}
    </div>
  );
};

const statusToNumber = (s) => {
  if (s === 'applicable') {
    return 1;
  }
  if (s === 'unconditional') {
    return 1;
  }
  if (s.startsWith('missing:')) {
    return 3;
  }
  return 4;
};

export const LabelItem = ({ label, options }) => {
  const orderedOptions = useMemo(
    () =>
      [...options].sort((o1, o2) => {
        const statusCompare = statusToNumber(o1.condition_status) - statusToNumber(o2.condition_status);
        if (statusCompare === 0) {
          return o2.priority - o1.priority;
        }
        return statusCompare;
      }),
    [options]
  );

  const selectedOptions = useMemo(() => options.filter((o) => o.selected), [options]);
  const prettyLabel = label.replaceAll('_', ' ');

  if (selectedOptions?.length) {
    return (
      <>
        {selectedOptions.map((o) => {
          let content = prettyLabel;
          if (selectedOptions.length > 1 && o.conditions?.length) {
            content = (
              <>
                {prettyLabel}
                {o.conditions.map((c, i) => (
                  <Condition key={i}>{stringifyCondition(c, label)}</Condition>
                ))}
              </>
            );
          }
          return (
            <EnrichedLabel
              key={o.id}
              id={o.id}
              label={o.intent}
              displayText={content}
              answer={o.text}
              conditions={o.conditions}
              conditionStatus={o.condition_status}
              selected
            />
          );
        })}
      </>
    );
  }
  const { id, text: answer, conditions, condition_status: conditionStatus } = orderedOptions[0];
  return (
    <EnrichedLabel
      id={id}
      label={label}
      displayText={prettyLabel}
      answer={answer}
      conditions={conditions}
      conditionStatus={conditionStatus}
      options={orderedOptions}
    />
  );
};
