import React from 'react';

import { Button } from 'components/lib';
import List from 'components/email-templates/List';
import { CampaignModal } from '../common';

export const SelectTemplateModal = ({ templates, onClose, onTemplateSelect }) => {
  return (
    <CampaignModal open onModalClose={onClose} size="tiny" width={795} scrollableContent={500}>
      <div type="header">Choose Email Template</div>
      <div type="body">
        <List templates={templates} onTemplateSelect={onTemplateSelect} columns={3} />
      </div>
      <div type="actions">
        <Button primary onClick={onClose}>
          Cancel
        </Button>
      </div>
    </CampaignModal>
  );
};
