import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';

const SERVICE_BADGE_TYPES = {
  WEB_ASSISTENT: {
    name: 'Web Assistant',
    icon: 'view_agenda',
    iconColor: '#b3b7be',
    border: '#ced0d4',
    backgroundImage:
      'linear-gradient(315deg, rgba(192,193,195,1) 0%, rgba(242,244,249,1) 45%, rgba(242,244,249,1) 54%, rgba(192,193,195,1) 100%)',
  },
  MESSAGING_ASSISTANT: {
    name: 'Messaging Assistant',
    icon: 'question_answer',
    iconColor: '#c09b20',
    border: '#cdb742',
    backgroundImage:
      'linear-gradient(315deg, rgba(196,146,81,1) 0%, rgba(241,212,88,1) 45%, rgba(241,212,88,1) 54%, rgba(196,146,81,1) 100%)',
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & + & {
    margin-left: 10px;
  }
`;

const Circle = styled.div.attrs(({ service }) => ({
  style: {
    border: `1px solid ${service.border}`,
    backgroundImage: service.backgroundImage,
  },
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  margin: 0 8px 10px 7px;
  padding: 12px;
  border: solid 1px #ced0d4;
`;

const Text = styled.span`
  font-family: var(--font-family);
  font-size: 9px;
  text-align: center;
  color: var(--light-text);
`;

export const ServiceBadge = ({ type }) => {
  const serviceType = SERVICE_BADGE_TYPES[type] || {};
  return (
    <Container>
      <Circle service={serviceType}>
        <Icon name={serviceType?.icon} size="1.5em" color={serviceType?.iconColor} variant="outlined" />
      </Circle>
      <Text>{serviceType?.name}</Text>
    </Container>
  );
};
