import React, { useEffect } from 'react';
import { Tab } from 'semantic-ui-react';

import SearchInput from 'components/SearchInput';
import {
  fetchQuestions,
  useQuestionReviews,
  setFilters,
  changeSort,
  editQuestionReview,
  updateQuestionsCount,
  refreshQuestionsList,
} from './context';
import QuestionReviewList from './QuestionReviewList';
import { PAGINATION, STATUSES } from '../../../constants';

const NewQuestionsTab = () => {
  const [state, dispatch] = useQuestionReviews();
  const { questions, totalPages, loaderStatus, filters } = state;

  useEffect(() => {
    fetchQuestions(dispatch, { ...filters, isLabeled: false, isIgnored: false });
  }, [dispatch, filters]);

  const handlePageChange = (page) => {
    dispatch(setFilters({ page }));
  };

  const handleSearch = (search) => {
    dispatch(setFilters({ page: PAGINATION.PAGE, search }));
  };

  const handleOrdering = (orderBy) => {
    dispatch(changeSort(orderBy));
  };

  const handleQuestionUpdate = async (questionReviewId, attributes, isIgnored) => {
    const response = await editQuestionReview(dispatch, questionReviewId, attributes, isIgnored);

    if (response) {
      dispatch(
        updateQuestionsCount({
          newQuestionsCount: -1,
          labeledQuestionCount: !!attributes && 1,
          ignoredQuestionsCount: isIgnored && 1,
        })
      );

      refreshQuestionsList(state, dispatch, questionReviewId);
    }
  };

  return (
    <Tab.Pane>
      <SearchInput
        value={filters.search}
        onSearch={handleSearch}
        loading={loaderStatus === STATUSES.LOADING}
        placeholder="Search question..."
      />
      <QuestionReviewList
        loaderStatus={loaderStatus}
        questions={questions}
        activePage={filters.page}
        totalPages={totalPages}
        ordering={filters.ordering}
        onPageChange={handlePageChange}
        onQuestionUpdate={handleQuestionUpdate}
        onOrdering={handleOrdering}
      />
    </Tab.Pane>
  );
};

export default NewQuestionsTab;
