import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Form, Input } from 'components/lib';

import { OptionalField } from '../../../OptionalField';
import { AnswersField } from './AnswersField';
import { MultiOptionAnswersField } from '../../MultiOptionAnswersField';
import { ActionField } from '../../ActionField';
import { ACTION_TYPES } from '../../ModuleGraph';
import { ToggleSwitch } from '../../../../common';
import {
  MODULE_ROLES_OPTIONS,
  MODULE_TYPES,
  ROLE_MODULES,
  TEXT_MODULES,
  ANSWERS_MODULES,
  OPTIONS_MODULES,
} from '../../constants';
import ModuleFormText from './ModuleFormText';
import ModuleFormLink from './ModuleFormLink';
import ModuleFormRole from './ModuleFormRole';
import ModuleFormType from './ModuleFormType';
import ModuleFormHeader from './ModuleFormHeader';

const Wrapper = styled.div`
  .ui.form {
    textarea {
      background-color: #eaeff7;
      position: relative;
      border-radius: 1.71rem 1.71rem 1.71rem 0.21rem;
      padding: 1rem 1.85rem 1.1rem 1.5rem;
      line-height: 1.43rem;
    }

    .field:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  .error {
    color: var(--danger);
    padding: 5px 0px 10px;
  }
`;

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, (list) => list.length === new Set(list.map(mapper)).size);
});

const validationSchema = Yup.object({
  module: Yup.object().shape({
    text: Yup.string()
      .test('is-required', 'Please enter the text. This field is required to proceed.', function (value) {
        const requiredModuleTypes = [
          'date_picker',
          'email',
          'multi_option',
          'text',
          'text_display',
          'phone',
          'locations',
        ];

        const formValues = this.parent;
        if (requiredModuleTypes.includes(formValues?.module_type) && !value) {
          return false;
        }
        return true;
      })
      .nullable(),
    module_type: Yup.string().required('Required'),
    role: Yup.string()
      .oneOf(MODULE_ROLES_OPTIONS.map((r) => r.value))
      .nullable(),
    action: Yup.number().positive().integer().nullable(),
    answers: Yup.array()
      .of(
        Yup.object().shape({
          text: Yup.string().max(100, `Can't be longer than 100 characters`),
          rank: Yup.number().integer().min(0).required(),
          action: Yup.number().integer().min(0).nullable(),
        })
      )
      .unique('Options must be unique', (a) => a.text),
    save_as: Yup.string().max(300, `Can't be longer than 300 characters`).nullable(),
    skip_answered: Yup.bool().when('save_as', {
      is: (v) => !v,
      then: Yup.bool().oneOf([false], 'Answer must have specified key'),
    }),
    first_question: Yup.boolean(),
  }),
});

export const ModuleForm = ({ node, onSubmit, onCancel }) => {
  const [formNode, setFormNode] = useState({ ...node });

  const submit = (values, actions) => {
    const cleanupAnswers = (module) => {
      const { module_type } = module;
      let { answers } = module;
      if (ANSWERS_MODULES.includes(module_type)) {
        answers = answers.filter((a) => !!a.text).map((a) => ({ ...a, text: a.text?.trim() || null }));
      }
      if (module_type === MODULE_TYPES.MULTI_OPTION) {
        answers = answers.map((a) => ({ ...a, action: null }));
      }
      return answers;
    };

    const cleanupModuleAction = (module, answers) => {
      const { module_type, action } = module;
      const noNextStepAnswer = answers?.every((answer) => answer.meta.actionType !== ACTION_TYPES.STEP);
      const isOptionsModuleType = OPTIONS_MODULES.includes(module_type);
      if (!isOptionsModuleType && noNextStepAnswer) {
        return action;
      }
      return action;
    };

    if (typeof onSubmit === 'function') {
      const { module } = values;
      const answers = cleanupAnswers(module);
      const action = cleanupModuleAction(module, answers);
      const submitValues = {
        module: {
          ...module,
          answers,
          action,
        },
      };

      onSubmit(submitValues);
    }
  };

  const initialValues = { ...formNode };

  return (
    <Wrapper>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
        {({ values, dirty, isSubmitting, submitForm, setFieldValue, isValid, errors }) => (
          <Form>
            <ModuleFormHeader
              number={values.module.number}
              disabled={!dirty || isSubmitting || !isValid}
              submitForm={submitForm}
              onCancel={onCancel}
            />
            <ErrorMessage name="module.text" component="div" className="error" />
            <ModuleFormType />

            {ROLE_MODULES.includes(values.module.module_type) && <ModuleFormRole />}
            {TEXT_MODULES.includes(values.module.module_type) && <ModuleFormText module={values.module} />}
            {[MODULE_TYPES.LINK].includes(values.module.module_type) && <ModuleFormLink />}
            {(ANSWERS_MODULES.includes(values.module.module_type) ||
              (values.module.module_type !== MODULE_TYPES.MULTI_OPTION && values.module.answers.length > 0)) && (
              <AnswersField name="module.answers" />
            )}

            {[MODULE_TYPES.MULTI_OPTION].includes(values.module.module_type) && (
              <MultiOptionAnswersField name="module.answers" />
            )}

            {![MODULE_TYPES.VOICEMAIL, MODULE_TYPES.DIAL_COMMUNITY, MODULE_TYPES.AGENT_ASSIGNMENT_AND_DIAL].includes(
              values.module.module_type
            ) && <ActionField name="module.action" />}

            <Form.Field>
              <OptionalField
                as={Input}
                name="module.save_as"
                fieldLabel="Save response as"
                enabled={values.module.save_as !== null}
              />
            </Form.Field>
            <Form.Field>
              <ToggleSwitch
                name="module.skip_answered"
                label="Skip if answered"
                disabled={values.module.save_as == null || values.module.number === 1}
              />
            </Form.Field>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};
