import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MultiSelectFilter } from 'components/common';
import { capitalize } from 'utils/helpers';
import { ConversationsContext } from './ConversationsContext';

const ACTIVITIES = {
  MOVE_IN: 'moveInDate',
  NO_MOVE_IN: 'noMoveInDate',
  TOUR_SCHEDULED: 'tourScheduled',
  NO_TOUR_SCHEDULED: 'noTourScheduled',
  FINANCIALLY_UNQUALIFIED: 'financiallyUnqualified',
  INBOUND_CALL: 'inboundCall',
  OUTBOUND_CALL: 'outboundCall',
  IS_SURVEY_SUBMITTED: 'isSurveySubmitted',
  IS_NOTE_ADDED: 'isNoteAdded',
};

export const ConversationsDropdownActivity = () => {
  const { filters, handleFiltersChange } = useContext(ConversationsContext);
  const { t } = useTranslation();
  const [selectedActivities, setSelectedActivities] = useState(null);

  useEffect(() => {
    const activities = [];
    if (filters.moveInDate !== undefined) {
      activities.push(filters.moveInDate ? ACTIVITIES.MOVE_IN : ACTIVITIES.NO_MOVE_IN);
    }

    if (filters.tourScheduled !== undefined) {
      activities.push(filters.tourScheduled ? ACTIVITIES.TOUR_SCHEDULED : ACTIVITIES.NO_TOUR_SCHEDULED);
    }

    if (filters.financiallyUnqualified) {
      activities.push(ACTIVITIES.FINANCIALLY_UNQUALIFIED);
    }

    if (filters.inboundCall) {
      activities.push(ACTIVITIES.INBOUND_CALL);
    }
    if (filters.outboundCall) {
      activities.push(ACTIVITIES.OUTBOUND_CALL);
    }
    if (filters.isSurveySubmitted) {
      activities.push(ACTIVITIES.IS_SURVEY_SUBMITTED);
    }
    if (filters.isNoteAdded) {
      activities.push(ACTIVITIES.IS_NOTE_ADDED);
    }
    setSelectedActivities(activities.length > 0 ? activities : null);
  }, [
    filters.moveInDate,
    filters.tourScheduled,
    filters.financiallyUnqualified,
    filters.inboundCall,
    filters.outboundCall,
    filters.isSurveySubmitted,
    filters.isNoteAdded,
  ]);

  const onActivityChange = (activities) => {
    const moveIn = activities?.includes(ACTIVITIES.MOVE_IN);
    const noMoveIn = activities?.includes(ACTIVITIES.NO_MOVE_IN);
    const tourScheduled = activities?.includes(ACTIVITIES.TOUR_SCHEDULED);
    const noTourScheduled = activities?.includes(ACTIVITIES.NO_TOUR_SCHEDULED);
    const surveySubmitted = activities?.includes(ACTIVITIES.IS_SURVEY_SUBMITTED);
    const noteAdded = activities?.includes(ACTIVITIES.IS_NOTE_ADDED);

    const moveInValue = moveIn !== noMoveIn ? moveIn && !noMoveIn : undefined;
    const tourScheduledValue = tourScheduled !== noTourScheduled ? tourScheduled && !noTourScheduled : undefined;

    const filtersChange = {
      [ACTIVITIES.MOVE_IN]: moveInValue,
      [ACTIVITIES.TOUR_SCHEDULED]: tourScheduledValue,
      [ACTIVITIES.FINANCIALLY_UNQUALIFIED]: !!activities?.includes(ACTIVITIES.FINANCIALLY_UNQUALIFIED),
      [ACTIVITIES.INBOUND_CALL]: activities?.includes(ACTIVITIES.INBOUND_CALL),
      [ACTIVITIES.OUTBOUND_CALL]: activities?.includes(ACTIVITIES.OUTBOUND_CALL),
      [ACTIVITIES.IS_SURVEY_SUBMITTED]: surveySubmitted,
      [ACTIVITIES.IS_NOTE_ADDED]: noteAdded,
    };

    handleFiltersChange(filtersChange);
  };

  return (
    <MultiSelectFilter
      name="Activity"
      placeholder="Any activity"
      options={[
        { id: ACTIVITIES.MOVE_IN, name: `${capitalize(t('move_in'))} Date` },
        { id: ACTIVITIES.NO_MOVE_IN, name: `No ${capitalize(t('move_in'))} Date` },
        { id: ACTIVITIES.TOUR_SCHEDULED, name: `${capitalize(t('tour'))} Scheduled` },
        { id: ACTIVITIES.NO_TOUR_SCHEDULED, name: `No ${capitalize(t('tour'))} Scheduled` },
        { id: ACTIVITIES.FINANCIALLY_UNQUALIFIED, name: 'Financially Unqualified' },
        { id: ACTIVITIES.INBOUND_CALL, name: 'Incoming Call' },
        { id: ACTIVITIES.OUTBOUND_CALL, name: 'Outgoing Call' },
        { id: ACTIVITIES.IS_SURVEY_SUBMITTED, name: 'Survey Completed' },
        { id: ACTIVITIES.IS_NOTE_ADDED, name: 'Note Added' },
      ]}
      selectedValues={selectedActivities}
      onChange={onActivityChange}
    />
  );
};
