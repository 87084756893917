import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Dimmer, Loader } from 'components/lib';
import { InformantBox, MessageBox, CommunityNameBox } from '../common';

const ItemsBox = styled.div`
  max-height: 190px;
  width: 100%;
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }

  .row-item {
    height: 28px;
    min-height: 28px;
    width: 100%;
    padding-left: 5px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .name {
    font-size: 12px;
    color: var(--text);
  }
`;

const FieldBox = styled.div`
  margin: 20px 0px;

  && label {
    font-weight: 900;

    &:before {
      border: 2px solid #777777;
      top: -3px;
    }
    &:after {
      top: -3px;
    }
  }
`;

export const EmailIntegrationTab = ({ isLoading, data }) => {
  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader active inverted />
      </Dimmer>
      <MessageBox>
        The targeted communities listed below do not have email addresses set up to send from. Please use the{' '}
        <Link to={{ pathname: '/advanced/marketing-email/community-assignment' }} target="_blank">
          Email integration page
        </Link>{' '}
        (opens in new tab) to set this up.
      </MessageBox>

      <InformantBox text="Email integration is missing from:" />
      <ItemsBox>
        {data.map((community) => (
          <div className="row-item" key={community.id}>
            <CommunityNameBox name={community.name} />
          </div>
        ))}
      </ItemsBox>
    </>
  );
};
