import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import humps from 'humps';

import { Dimmer, Loader, Form } from 'components/lib';
import { CAMPAIGN_TYPE, NURTURE_CAMPAIGNS_ROUTE } from 'components/nurture/constants';
import { If } from 'components/If';
import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import { MEDIA_BREAK_POINTS } from '../../../../constants';
import { StatisticsOverview, PageHeader } from './form';
import { NameField, StatusDropdownField } from './form/fields';
import { SalesCampaignSidebar, FollowupCampaignSidebar } from './sidebar';
import { WarningsModal } from './warnings';
import { DeleteCampaignModal } from './DeleteCampaignModal';
import { EditModal, EmptyMessages, Message } from '../EditMessage';
import { CRITERIA_VALUE_TYPE, FLOW_TYPE, CONNECTOR } from './sidebar/constants';
import { ReviewCommunitiesModal } from '../CampaignMessages/ReviewCommunitiesModal';
import { CAMPAIGN_MESSAGE_TYPE } from '../../constants';
import { SelectTemplateModal } from './SelectTemplateModal';
import { ActivateCampaignModal } from './ActivateCampaignModal';
import OneTimeCampaignSidebar from './sidebar/OneTimeCampaignSidebar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 30px;
`;

const PageContent = styled.div`
  display: flex;
`;

const BaseFieldsContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 25px;
  width: 100%;
  justify-content: flex-start;
`;

const MessagesContainer = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center;
  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.WEB}px) {
    width: 55%;
  }
`;

const RightColumn = styled.div`
  display: flex;
  width: 30%;
  justify-content: center;
  min-width: 375px;
  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.WEB}px) {
    width: 35%;
    padding: 0 30px;
  }
`;

const NameFieldWrapper = styled.div`
  width: 600px;
  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.WEB}px) {
    width: 70%;
  }
`;

const CRITERIA_VALIDATION_SCHEMA = Yup.array().of(
  Yup.object().shape({
    isTrigger: Yup.boolean().required(),
    name: Yup.string().required(),
    valueType: Yup.string().required(),
    value: Yup.string().when('valueType', (valueType, schema) => {
      switch (valueType) {
        case CRITERIA_VALUE_TYPE.INTEGER:
          return Yup.number().required('Please insert a number').typeError('Must be a valid number');
        case CRITERIA_VALUE_TYPE.STRING:
          return Yup.string().required('Please enter a value');
        case CRITERIA_VALUE_TYPE.BOOLEAN:
          return Yup.boolean().required('Please select a value');
        case CRITERIA_VALUE_TYPE.RANGE_INTEGER:
          return Yup.array()
            .of(
              Yup.number()
                .required('Please insert a number')
                .typeError('Must be a valid number')
                .min(1, 'Must be greater than 0')
                .max(90, 'Must be less than 90')
            )
            .test({
              name: 'input-values',
              message: 'Invalid range. Second value must be greater than first value.',
              test: (value) => {
                if (!value || value.length !== 2) return false;
                const [v1, v2] = value;
                return v2 > v1;
              },
            });
        case CRITERIA_VALUE_TYPE.OPTION:
          return Yup.array().of(Yup.number()).required('Please select option');
        case CRITERIA_VALUE_TYPE.OPTION_DYNAMIC:
          return Yup.array().of(Yup.number()).required('Please select option');
        default:
          return schema.nullable();
      }
    }),
    operator: Yup.string().when('valueType', {
      is: (v) =>
        [
          CRITERIA_VALUE_TYPE.OPTION,
          CRITERIA_VALUE_TYPE.OPTION_DYNAMIC,
          CRITERIA_VALUE_TYPE.INTEGER,
          CRITERIA_VALUE_TYPE.STRING,
        ].includes(v),
      then: Yup.string().required('Please select operator'),
      otherwise: Yup.string().nullable(),
    }),
  })
);

const VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required('Campaign name is required.'),
  entryCriteriaRootGroup: Yup.object().shape({
    criteria: CRITERIA_VALIDATION_SCHEMA,
  }),
  exitCriteriaRootGroup: Yup.object().shape({
    criteria: CRITERIA_VALIDATION_SCHEMA,
  }),
  relatedCommunities: Yup.array().required('Please select below'),
  startDate: Yup.date(),
});

const buildRootCriteriaGroupPayload = (data) => ({
  ...data,
  criteria: data.criteria.map(({ value, ...ci }) => {
    const options =
      ci.valueType === CRITERIA_VALUE_TYPE.OPTION
        ? value.map((id) => ({ value: id, id: ci.options.find((v) => v.value === id)?.id }))
        : [];
    const dynamicOptions =
      ci.valueType === CRITERIA_VALUE_TYPE.OPTION_DYNAMIC
        ? value.map((id) => ({ value: id, id: ci.dynamicOptions.find((v) => v.value === id)?.id }))
        : [];
    const values =
      ci.valueType !== CRITERIA_VALUE_TYPE.OPTION && ci.valueType !== CRITERIA_VALUE_TYPE.OPTION_DYNAMIC
        ? [{ value: value?.toString() || null, id: ci.values[0]?.id }]
        : [];

    return {
      ...ci,
      values,
      options,
      dynamicOptions,
    };
  }),
});

export const CreateEditCampaign = () => {
  const { campaignId, campaignType } = useParams();
  const history = useHistory();
  const [isWarningsModalOpen, setIsWarningsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);

  const {
    state,
    createCampaign,
    updateCampaign,
    deleteCampaign,
    actions,
    dispatch,
    hasEditDeletePermission,
    createMessage,
    updateMessage,
    deleteMessage,
    validateCampaign,
  } = useCampaignDetails();
  const {
    campaign,
    campaignSaving,
    campaignLoading,
    campaignInvalidSections,
    statistics,
    statisticsLoading,
    isEditModalOpen,
    surveyMessageWarnings,
    isSelectTemplateModalOpen,
    templates,
  } = state;

  useEffect(() => {
    validateCampaign(campaign);
  }, [campaign]);

  const initialFormValues = {
    name: campaign?.name || '',
    isActive: campaign?.isActive,
    useMessagingAssistant: campaign?.useNurtureConversationSupport,
    keepSalesNotified: campaign?.sendCampaignRemindersToSales,
    filters: campaign?.filters || [],
    relatedCommunities: campaign?.relatedCommunities?.map((item) => item.id) || [],
    entryCriteriaRootGroup: campaign?.entryCriteriaRootGroup || {
      connector: CONNECTOR.AND,
      flow_type: FLOW_TYPE.ENTRY,
      parent: null,
      criteria: [],
    },
    exitCriteriaRootGroup: campaign?.exitCriteriaRootGroup || {
      connector: CONNECTOR.OR,
      flow_type: FLOW_TYPE.EXIT,
      parent: null,
      criteria: [],
    },
  };

  const setCampaignStatus = useCallback(
    async (status) => {
      setIsActivateModalOpen(false);
      if (!hasEditDeletePermission) return;
      const requestData = humps.decamelizeKeys({
        isActive: status,
      });

      await updateCampaign(requestData, () => {});
    },
    [hasEditDeletePermission, setIsActivateModalOpen, updateCampaign]
  );

  const updateCampaignStatus = useCallback(
    async (status) => {
      if (status) {
        setIsActivateModalOpen(true);
      } else {
        return setCampaignStatus(status);
      }
    },
    [setCampaignStatus, setIsActivateModalOpen]
  );

  const onSubmit = async (values, { resetForm }) => {
    if (!hasEditDeletePermission) return;

    let requestData = humps.decamelizeKeys({
      name: values.name,
      isActive: values.isActive,
      useNurtureConversationSupport: values.useMessagingAssistant,
      sendCampaignRemindersToSales: values.keepSalesNotified,
      filtersData: values.filters?.filter((filter) => filter[0] && filter[1]) || [],
      relatedCommunitiesIds: values.relatedCommunities || [],
      entryCriteriaRootGroup: buildRootCriteriaGroupPayload(values.entryCriteriaRootGroup),
      exitCriteriaRootGroup: buildRootCriteriaGroupPayload(values.exitCriteriaRootGroup),
    });

    if (campaign) {
      await updateCampaign(requestData, resetForm);
      return;
    }

    const campaignType = humps.decamelizeKeys({ campaignType });

    requestData = {
      ...requestData,
      ...campaignType,
    };

    await createCampaign(requestData, resetForm);
  };

  const onCancel = () => {
    history.replace(NURTURE_CAMPAIGNS_ROUTE);
  };

  const handleCampaignDelete = async () => {
    if (!hasEditDeletePermission) return;

    if (!campaign?.id) return;

    await deleteCampaign(campaign.id);

    onDeleteModalStatusChange();
    history.replace(NURTURE_CAMPAIGNS_ROUTE);
  };

  const onDeleteModalStatusChange = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const setCurrentMessage = (message) => {
    dispatch({ type: actions.SET_CURRENT_MESSAGE, data: message });
    dispatch({ type: actions.SET_IS_EDIT_MODAL_OPEN, data: true });
  };

  const handleEditModalClose = () => {
    dispatch({ type: actions.SET_CURRENT_MESSAGE, data: null });
    dispatch({ type: actions.SET_IS_EDIT_MODAL_OPEN, data: false });
  };

  const handleNewMessageClick = (messageType) => {
    if (messageType === CAMPAIGN_MESSAGE_TYPE.EMAIL_TEMPLATE) {
      dispatch({ type: actions.SET_IS_SELECT_TEMPLATE_MODAL_OPEN, data: true });
    } else {
      dispatch({ type: actions.SET_CURRENT_MESSAGE, data: { messageType } });
      dispatch({ type: actions.SET_IS_EDIT_MODAL_OPEN, data: true });
    }
  };

  const handleSelectTemplateModalClose = useCallback(() => {
    dispatch({ type: actions.SET_IS_SELECT_TEMPLATE_MODAL_OPEN, data: false });
  }, [dispatch]);

  const handleTemplateSelect = useCallback(
    (template) => {
      handleSelectTemplateModalClose();
      dispatch({
        type: actions.SET_CURRENT_MESSAGE,
        data: {
          messageType: CAMPAIGN_MESSAGE_TYPE.EMAIL,
          subject: template.name,
          message: template.html,
          style: template.style,
        },
      });
      dispatch({ type: actions.SET_IS_EDIT_MODAL_OPEN, data: true });
    },
    [handleSelectTemplateModalClose]
  );

  const handleCreateUpdateMessagesArray = async (messageValues) => {
    // this is a hack to make sure that the attachment is not removed by humps.decamelizeKeys
    const messageWithoutAttachment = { ...messageValues };
    const attachment = messageWithoutAttachment.attachment;
    delete messageWithoutAttachment.attachment;
    const message = humps.decamelizeKeys(messageWithoutAttachment);
    message.attachment = attachment;
    if (message.id) {
      // update message
      await updateMessage(message);
    } else {
      // crete message
      await createMessage(message);
    }
    await validateCampaign(campaign);
  };

  const handleDeleteMessage = async (message) => {
    await deleteMessage(message);
    await validateCampaign(campaign);
  };

  const messages = state?.messages || [];
  const hasErrors = campaignInvalidSections?.length > 0;

  return (
    <>
      <Dimmer active={campaignLoading || campaignSaving} inverted>
        <Loader active inverted />
      </Dimmer>
      <Wrapper>
        <Formik
          validationSchema={VALIDATION_SCHEMA}
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnMount={true}
        >
          {({ handleSubmit, isValid, resetForm, dirty, setFieldValue, values }) => {
            return (
              <Form>
                <PageHeader
                  campaignId={campaignId}
                  loading={campaignSaving}
                  saveDisabled={!isValid || !dirty}
                  showActions={hasEditDeletePermission}
                  onSave={handleSubmit}
                  onCancel={onCancel}
                  warningButton={hasErrors}
                  openWarningModal={() => setIsWarningsModalOpen(true)}
                />
                <PageContent>
                  <LeftColumn>
                    <BaseFieldsContainer>
                      <StatusDropdownField
                        campaignId={campaignId}
                        updateCampaignStatus={updateCampaignStatus}
                        disabled={!hasEditDeletePermission}
                        hasErrors={hasErrors}
                        onDeleteCampaign={onDeleteModalStatusChange}
                        hasDeletePermission={hasEditDeletePermission}
                        name="isActive"
                      />
                      <NameFieldWrapper>
                        <NameField disabled={!hasEditDeletePermission} />
                      </NameFieldWrapper>
                    </BaseFieldsContainer>
                    <MessagesContainer>
                      <If condition={!!campaignId}>
                        <StatisticsOverview statistics={statistics} loading={!campaignLoading && statisticsLoading} />
                      </If>
                      {messages?.map((message) => (
                        <Message
                          emailStyle={message.style || campaign?.emailStyle}
                          key={message.id}
                          data={humps.camelizeKeys(message)}
                          onClick={() => setCurrentMessage(humps.camelizeKeys(message))}
                        />
                      ))}
                      <EmptyMessages onClick={handleNewMessageClick} messages={messages} />
                    </MessagesContainer>
                  </LeftColumn>
                  <RightColumn>
                    <If condition={campaignType === CAMPAIGN_TYPE.SALES}>
                      <SalesCampaignSidebar />
                    </If>
                    <If condition={campaignType === CAMPAIGN_TYPE.FOLLOW_UP}>
                      <FollowupCampaignSidebar />
                    </If>
                    <If condition={campaignType === CAMPAIGN_TYPE.ONE_TIME}>
                      <OneTimeCampaignSidebar />
                    </If>
                  </RightColumn>
                </PageContent>
                <If condition={!!state?.currentMessage}>
                  <EditModal
                    campaign={campaign}
                    open={isEditModalOpen}
                    onClose={handleEditModalClose}
                    onSave={(message) => handleCreateUpdateMessagesArray(message, setFieldValue, values)}
                    onDelete={(message) => handleDeleteMessage(message, setFieldValue, values)}
                    campaignType={campaignType}
                  />
                </If>
                <If condition={isSelectTemplateModalOpen}>
                  <SelectTemplateModal
                    onClose={handleSelectTemplateModalClose}
                    onTemplateSelect={handleTemplateSelect}
                    templates={templates}
                  />
                </If>
              </Form>
            );
          }}
        </Formik>
        <DeleteCampaignModal
          isOpen={isDeleteModalOpen}
          onSave={handleCampaignDelete}
          onClose={onDeleteModalStatusChange}
        />
        <ActivateCampaignModal
          isOpen={isActivateModalOpen}
          onSave={() => setCampaignStatus(true)}
          onClose={() => setIsActivateModalOpen(false)}
        />
        <WarningsModal isModalOpen={isWarningsModalOpen} setModalOpen={setIsWarningsModalOpen} />
        <ReviewCommunitiesModal
          isModalOpen={Boolean(surveyMessageWarnings)}
          onClose={() => dispatch({ type: actions.SET_SURVEY_MESSAGE_WARNINGS, data: null })}
        />
      </Wrapper>
    </>
  );
};
