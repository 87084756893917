import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES } from 'constants';
import { Metric } from 'components/analytics/common';
import { ReportComponent } from 'components/common/analytics';
import { Popup } from 'components/lib';
import { formatDuration } from 'components/utils';
import { pluralize, queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const ResponseTimes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const metrics = [
  'messages.sales_assistant',
  'messages.sales_assistant.avg_response_time',
  'messages.sales_assistant.first',
  'messages.sales_assistant.first.avg_response_time',
  'messages.sales_assistant.local_working_hours',
  'messages.sales_assistant.local_working_hours.avg_response_time',
  'messages.sales_assistant.further_working_hours',
  'messages.sales_assistant.further_working_hours.avg_response_time',
];

const useMessagesResponseTimes = (filters) =>
  useQuery(
    [queryConstants.ADMIN_METRICS, { ...filters, metrics }],
    () => {
      return getMetrics(metrics, filters);
    },
    { placeholderData: {} }
  );

const getDuration = (duration, messages) => {
  if (!messages) {
    return '-';
  }
  return formatDuration(duration);
};

const getDesc = (messages) => {
  if (messages > 0) {
    return `Based on ${pluralize(messages, 'messages', 'message')}`;
  }
  return null;
};

const MessagesResponseTimes = () => {
  const { saleAssistantFilters: filters } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };

  const { data, error, status, isFetching } = useMessagesResponseTimes(maFilters);

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch messages response times metrics'));
  }

  return (
    <ReportComponent
      title="MA Avg. Response Times"
      tooltip="MA response times for different message categories"
      contentMargins="20px 0"
      loading={loaderStatus === STATUSES.LOADING}
    >
      <ResponseTimes>
        <Popup
          position="top left"
          content="Avg. MA response time for all messages"
          trigger={
            <Metric
              label="All Messages"
              value={getDuration(
                data['messages.sales_assistant.avg_response_time']?.value,
                data['messages.sales_assistant']?.value
              )}
              description={getDesc(data['messages.sales_assistant']?.value)}
            />
          }
        />
        <Popup
          position="top center"
          content="Avg. MA response time for first lead messages"
          trigger={
            <Metric
              label="First lead messages"
              value={getDuration(
                data['messages.sales_assistant.first.avg_response_time']?.value,
                data['messages.sales_assistant.first']?.value
              )}
              description={getDesc(data['messages.sales_assistant.first']?.value)}
            />
          }
        />
        <Popup
          position="top center"
          content="Avg. MA response time for lead messages that came within local working hours"
          trigger={
            <Metric
              label="Local Working Hours Messages"
              value={getDuration(
                data['messages.sales_assistant.local_working_hours.avg_response_time']?.value,
                data['messages.sales_assistant.local_working_hours']?.value
              )}
              description={getDesc(data['messages.sales_assistant.local_working_hours']?.value)}
            />
          }
        />
        <Popup
          position="top center"
          content="Avg. MA response time for lead messages that came within Further working hours"
          trigger={
            <Metric
              label="Further Working Hours Messages"
              value={getDuration(
                data['messages.sales_assistant.further_working_hours.avg_response_time']?.value,
                data['messages.sales_assistant.further_working_hours']?.value
              )}
              description={getDesc(data['messages.sales_assistant.further_working_hours']?.value)}
            />
          }
        />
      </ResponseTimes>
    </ReportComponent>
  );
};

export { useMessagesResponseTimes };
export default MessagesResponseTimes;
