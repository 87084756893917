import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ProfilePicture } from './ProfilePicture';
import { USER_CIRCLE_COLORS } from './constants';

const Container = styled.div`
  display: flex;
`;

export const ProfileCircle = ({ size, name, picture, parts = 2, ...props }) => {
  const color = name ? name.length % USER_CIRCLE_COLORS.length : 0;
  const colors = USER_CIRCLE_COLORS[color];

  const alt = name || '';

  const cleanName = name?.replace(/[^a-zA-Z0-9]/g, '');
  const nameParts = cleanName?.split(' ');

  const initials = nameParts?.map((part) => part.slice(0, 1)).join('');
  const part_initials = name ? initials.slice(0, parts) : '';

  return (
    <Container {...props}>
      <ProfilePicture image={picture} alt={alt} colors={colors} initials={part_initials} size={size} />
    </Container>
  );
};

ProfileCircle.propTypes = {
  size: PropTypes.oneOf(['18', '20', '30', '40', '60']),
};

ProfileCircle.defaultProps = {
  size: '30',
};
