import React, { useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Button, Dropdown, Icon, Loader, Table } from 'semantic-ui-react';

import { If } from 'components/If';
import { STATUSES } from '../../../constants';

const TableRow = styled(Table.Row)`
  && {
    display: table-row !important; // Transition breaks display row
  }
`;

const QuestionCell = styled(Table.Cell)`
  overflow-wrap: anywhere;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > i {
    margin: 6px;
  }
`;

const QuestionReviewListRow = ({
  questionReview,
  currentQuestionId,
  loaderStatus,
  attributes,
  onIgnoreQuestion,
  onAttributesChange,
  ...props
}) => {
  const actionsDisabled = questionReview.id === currentQuestionId && loaderStatus === STATUSES.LOADING;
  const [questionAttributes, setQuestionAttributes] = useState(questionReview.attributes);
  const [hasSelectedNewAttributes, setHasSelectedNewAttributes] = useState(false);

  const handleIgnoreQuestion = () => {
    // If it's not already ignored
    if (!questionReview.is_ignored) {
      onIgnoreQuestion(questionReview.id);
    }
  };

  const handleChangeAttributes = (e, { value }) => {
    setQuestionAttributes(value);
    setHasSelectedNewAttributes(JSON.stringify(value) !== JSON.stringify(questionReview.attributes));
  };

  const handleSubmit = async () => {
    await onAttributesChange(questionReview.id, questionAttributes);
    setHasSelectedNewAttributes(false);
  };

  const questionReviewDate = questionReview?.updated_on && format(new Date(questionReview.updated_on), 'MM/dd/yyyy');

  return (
    <TableRow {...props} disabled={actionsDisabled}>
      <QuestionCell>
        <>
          <If condition={questionReview.processed_at}>
            <Icon name="check" color="green" size="small" />
          </If>
          {questionReview.text}
        </>
      </QuestionCell>
      <Table.Cell>{questionReviewDate}</Table.Cell>
      <Table.Cell width={5}>
        <Dropdown
          placeholder="Select attributes"
          disabled={actionsDisabled}
          fluid
          search
          selectOnBlur={false}
          selectOnNavigation={false}
          selection
          options={attributes}
          onChange={handleChangeAttributes}
          defaultValue={questionAttributes}
          multiple
        />
      </Table.Cell>
      <Table.Cell>
        <Actions>
          <Loader active={actionsDisabled} size="small" inline="centered" />

          <If condition={!actionsDisabled}>
            <Button icon onClick={handleSubmit} disabled={!hasSelectedNewAttributes}>
              <Icon color="green" name="check" />
            </Button>
            <Button icon onClick={handleIgnoreQuestion} disabled={questionReview.is_ignored}>
              <Icon color="red" name="trash alternate outline" />
            </Button>
          </If>
        </Actions>
      </Table.Cell>
    </TableRow>
  );
};

export default QuestionReviewListRow;
