import { useField } from 'formik';
import React, { useState } from 'react';
import { Checkbox } from 'semantic-ui-react';

export const ElementDisplayField = (props) => {
  const [field, , helpers] = useField(props);
  const isVisible = field.value === 'block';

  return (
    <Checkbox
      toggle
      label="Visible"
      checked={isVisible}
      onChange={() => {
        helpers.setValue(isVisible ? 'none' : 'block');
      }}
    />
  );
};
