export const MODULE_TYPES = {
  OPTION: 'option',
  CARE_OPTION: 'care_option',
  MULTI_OPTION: 'multi_option',
  LINK: 'link',
  TEXT: 'text',
  MULTI_LINE_TEXT: 'multi_line_text',
  TEXT_DISPLAY: 'text_display',
  EMAIL: 'email',
  PHONE: 'phone',
  DATE_PICKER: 'date_picker',
  SUBMIT: 'submit',
  SMS: 'sms',
  CLICK: 'click',
  EVAL: 'eval',
  OUT_OF_BUDGET: 'out_of_budget',
  OUT_OF_BUDGET_SLIDER: 'out_of_budget_slider',
  SLACK: 'slack',
  SUBMIT_JOB_APPLICANT: 'submit_job_applicant',
  INSTANT_ANSWERS: 'instant_answers',
  CLICKABLE_IA: 'clickable_ia',
  LOCATIONS: 'locations',
  FINANCIAL_RESULTS: 'financial_result',
  PRICING_LOADER: 'pricing_loader',
  CONTACT_INFO: 'contact_info',
  INTERSTITIAL: 'interstitial',
  VOICEMAIL: 'voicemail',
  DIAL_COMMUNITY: 'dial_community',
  SUBMIT_SURVEY: 'submit_survey',
  START_CALL_RECORDING: 'start_call_recording',
  AGENT_ASSIGNMENT_AND_DIAL: 'agent_assignment_and_dial',
};

export const MODULE_ROLES = {
  GALLERY: 'gallery',
  PRICING: 'pricing',
  PRICING_V2: 'pricing_v2',
  PRICING_V3: 'pricing_v3',
  PRICING_V4: 'pricing_v4',
  PRICING_PER_CARE: 'pricing_per_care',
  PRICING_PER_CARE_FALLBACK: 'pricing_per_care_fallback',
  FLOORPLAN: 'floorplan',
  ADVANCED_FLOORPLAN: 'advanced_floorplan',
  VIDEOS: 'videos',
  DIGITAL_TOURS: 'digital_tours',
  GALLERY_PREVIEW: 'gallery_preview',
  INCHAT_GALLERY: 'inchat_gallery',
  INCHAT_FLOORPLAN: 'inchat_floorplan',
  INCHAT_DIGITAL_TOURS: 'inchat_digital_tours',
  SMS_FLOW_START: 'sms_flow_start',
  CALLER_PHONE_NUMBER_CHANGE_FLOW: 'caller_phone_number_change_flow',
  STAFF_INFO: 'staff_info',
  TIME_RULE_BASED_FLOW: 'time_rule_based_flow',
  INTERSTITIAL_TESTIMONIAL: 'interstitial_testimonial',
  INTERSTITIAL_FACT: 'interstitial_fact',
};

export const MODULE_TYPES_OPTIONS = [
  { key: 'none', text: 'None', value: null },
  { key: MODULE_TYPES.OPTION, text: 'Options', value: MODULE_TYPES.OPTION },
  { key: MODULE_TYPES.MULTI_OPTION, text: 'Multi-Select Options', value: MODULE_TYPES.MULTI_OPTION },
  { key: MODULE_TYPES.CARE_OPTION, text: 'Care Options', value: MODULE_TYPES.CARE_OPTION },
  { key: MODULE_TYPES.LINK, text: 'Link', value: MODULE_TYPES.LINK },
  { key: MODULE_TYPES.TEXT, text: 'Text', value: MODULE_TYPES.TEXT },
  { key: MODULE_TYPES.MULTI_LINE_TEXT, text: 'Multi Line Text', value: MODULE_TYPES.MULTI_LINE_TEXT },
  { key: MODULE_TYPES.TEXT_DISPLAY, text: 'Text Display', value: MODULE_TYPES.TEXT_DISPLAY },
  { key: MODULE_TYPES.EMAIL, text: 'Email', value: MODULE_TYPES.EMAIL },
  { key: MODULE_TYPES.PHONE, text: 'Phone', value: MODULE_TYPES.PHONE },
  { key: MODULE_TYPES.DATE_PICKER, text: 'Date Picker', value: MODULE_TYPES.DATE_PICKER },
  { key: MODULE_TYPES.SUBMIT, text: 'Submit', value: MODULE_TYPES.SUBMIT },
  { key: MODULE_TYPES.SMS, text: 'SMS', value: MODULE_TYPES.SMS },
  { key: MODULE_TYPES.CLICK, text: 'Click', value: MODULE_TYPES.CLICK },
  { key: MODULE_TYPES.EVAL, text: 'Eval', value: MODULE_TYPES.EVAL },
  { key: MODULE_TYPES.OUT_OF_BUDGET, text: 'Out-of-Budget', value: MODULE_TYPES.OUT_OF_BUDGET },
  { key: MODULE_TYPES.OUT_OF_BUDGET_SLIDER, text: 'Out-of-Budget Slider', value: MODULE_TYPES.OUT_OF_BUDGET_SLIDER },
  { key: MODULE_TYPES.SLACK, text: 'Slack', value: MODULE_TYPES.SLACK },
  { key: MODULE_TYPES.SUBMIT_SURVEY, text: 'Submit Survey', value: MODULE_TYPES.SUBMIT_SURVEY },
  { key: MODULE_TYPES.SUBMIT_JOB_APPLICANT, text: 'Submit Job Applicant', value: MODULE_TYPES.SUBMIT_JOB_APPLICANT },
  { key: MODULE_TYPES.INSTANT_ANSWERS, text: 'Instant Answers', value: MODULE_TYPES.INSTANT_ANSWERS },
  { key: MODULE_TYPES.CLICKABLE_IA, text: 'Clickable IA', value: MODULE_TYPES.CLICKABLE_IA },
  { key: MODULE_TYPES.LOCATIONS, text: 'Nearest Locations', value: MODULE_TYPES.LOCATIONS },
  { key: MODULE_TYPES.FINANCIAL_RESULTS, text: 'Financial Results', value: MODULE_TYPES.FINANCIAL_RESULTS },
  { key: MODULE_TYPES.PRICING_LOADER, text: 'Pricing Loader', value: MODULE_TYPES.PRICING_LOADER },
  { key: MODULE_TYPES.CONTACT_INFO, text: 'Contact Info', value: MODULE_TYPES.CONTACT_INFO },
  { key: MODULE_TYPES.INTERSTITIAL, text: 'Interstitial', value: MODULE_TYPES.INTERSTITIAL },
  { key: MODULE_TYPES.VOICEMAIL, text: 'Voicemail', value: MODULE_TYPES.VOICEMAIL },
  { key: MODULE_TYPES.DIAL_COMMUNITY, text: 'Dial Community', value: MODULE_TYPES.DIAL_COMMUNITY },
  { key: MODULE_TYPES.START_CALL_RECORDING, text: 'Start Call Recording', value: MODULE_TYPES.START_CALL_RECORDING },
  {
    key: MODULE_TYPES.AGENT_ASSIGNMENT_AND_DIAL,
    text: 'Agent Assignment And Dial',
    value: MODULE_TYPES.AGENT_ASSIGNMENT_AND_DIAL,
  },
];

export const TEXT_MODULES = [
  MODULE_TYPES.OPTION,
  MODULE_TYPES.MULTI_OPTION,
  MODULE_TYPES.CARE_OPTION,
  MODULE_TYPES.TEXT,
  MODULE_TYPES.MULTI_LINE_TEXT,
  MODULE_TYPES.TEXT_DISPLAY,
  MODULE_TYPES.EMAIL,
  MODULE_TYPES.PHONE,
  MODULE_TYPES.DATE_PICKER,
  MODULE_TYPES.SUBMIT,
  MODULE_TYPES.SMS,
  MODULE_TYPES.CLICK,
  MODULE_TYPES.EVAL,
  MODULE_TYPES.OUT_OF_BUDGET,
  MODULE_TYPES.OUT_OF_BUDGET_SLIDER,
  MODULE_TYPES.SLACK,
  MODULE_TYPES.SUBMIT_JOB_APPLICANT,
  MODULE_TYPES.VOICEMAIL,
  MODULE_TYPES.DIAL_COMMUNITY,
  MODULE_TYPES.SUBMIT,
  MODULE_TYPES.SUBMIT_SURVEY,
  MODULE_TYPES.LOCATIONS,
  MODULE_TYPES.START_CALL_RECORDING,
  MODULE_TYPES.CONTACT_INFO,
];
export const ROLE_MODULES = [MODULE_TYPES.OPTION, MODULE_TYPES.TEXT, MODULE_TYPES.INTERSTITIAL];

export const ANSWERS_MODULES = [
  MODULE_TYPES.OPTION,
  MODULE_TYPES.LINK,
  MODULE_TYPES.OUT_OF_BUDGET,
  MODULE_TYPES.CARE_OPTION,
  MODULE_TYPES.OUT_OF_BUDGET_SLIDER,
];

export const OPTIONS_MODULES = [
  MODULE_TYPES.OPTION,
  MODULE_TYPES.LINK,
  MODULE_TYPES.OUT_OF_BUDGET,
  MODULE_TYPES.CARE_OPTION,
  MODULE_TYPES.MULTI_OPTION,
];

export const MODULE_ROLES_OPTIONS = [
  { key: 'none', text: 'None', value: null },
  { key: MODULE_ROLES.GALLERY, text: 'Gallery', value: MODULE_ROLES.GALLERY },
  { key: MODULE_ROLES.STAFF_INFO, text: 'Staff information', value: MODULE_ROLES.STAFF_INFO },
  { key: MODULE_ROLES.PRICING, text: 'Pricing', value: MODULE_ROLES.PRICING },
  { key: MODULE_ROLES.PRICING_V2, text: 'Pricing V2', value: MODULE_ROLES.PRICING_V2 },
  { key: MODULE_ROLES.PRICING_V3, text: 'Pricing V3', value: MODULE_ROLES.PRICING_V3 },
  { key: MODULE_ROLES.PRICING_V4, text: 'Pricing V4', value: MODULE_ROLES.PRICING_V4 },
  { key: MODULE_ROLES.PRICING_PER_CARE, text: 'Pricing per care', value: MODULE_ROLES.PRICING_PER_CARE },
  {
    key: MODULE_ROLES.PRICING_PER_CARE_FALLBACK,
    text: 'Pricing per care fallback',
    value: MODULE_ROLES.PRICING_PER_CARE_FALLBACK,
  },
  { key: MODULE_ROLES.FLOORPLAN, text: 'Floorplans', value: MODULE_ROLES.FLOORPLAN },
  { key: MODULE_ROLES.ADVANCED_FLOORPLAN, text: 'Advanced Floorplans', value: MODULE_ROLES.ADVANCED_FLOORPLAN },
  { key: MODULE_ROLES.VIDEOS, text: 'Videos', value: MODULE_ROLES.VIDEOS },
  { key: MODULE_ROLES.DIGITAL_TOURS, text: 'Digital Tours', value: MODULE_ROLES.DIGITAL_TOURS },
  { key: MODULE_ROLES.GALLERY_PREVIEW, text: 'Gallery preview', value: MODULE_ROLES.GALLERY_PREVIEW },
  { key: MODULE_ROLES.INCHAT_GALLERY, text: 'Inchat gallery', value: MODULE_ROLES.INCHAT_GALLERY },
  { key: MODULE_ROLES.INCHAT_FLOORPLAN, text: 'Inchat floorplan', value: MODULE_ROLES.INCHAT_FLOORPLAN },
  { key: MODULE_ROLES.INCHAT_DIGITAL_TOURS, text: 'Inchat digital tour', value: MODULE_ROLES.INCHAT_DIGITAL_TOURS },
  { key: MODULE_ROLES.SMS_FLOW_START, text: 'SMS flow start', value: MODULE_ROLES.SMS_FLOW_START },
  {
    key: MODULE_ROLES.CALLER_PHONE_NUMBER_CHANGE_FLOW,
    text: 'Caller phone number change flow',
    value: MODULE_ROLES.CALLER_PHONE_NUMBER_CHANGE_FLOW,
  },
  {
    key: MODULE_ROLES.TIME_RULE_BASED_FLOW,
    text: 'Time based rule flow',
    value: MODULE_ROLES.TIME_RULE_BASED_FLOW,
  },
  {
    key: MODULE_ROLES.INTERSTITIAL_TESTIMONIAL,
    text: 'Interstitial testimonial',
    value: MODULE_ROLES.INTERSTITIAL_TESTIMONIAL,
  },
  { key: MODULE_ROLES.INTERSTITIAL_FACT, text: 'Interstitial fact', value: MODULE_ROLES.INTERSTITIAL_FACT },
];

export const TIME_RULES = [
  {
    key: 'Midnight',
    text: 'Midnight',
    value: 0,
  },
  {
    key: '1 AM',
    text: '1 AM',
    value: 1,
  },
  {
    key: '2 AM',
    text: '2 AM',
    value: 2,
  },
  {
    key: '3 AM',
    text: '3 AM',
    value: 3,
  },
  {
    key: '4 AM',
    text: '4 AM',
    value: 4,
  },
  {
    key: '5 AM',
    text: '5 AM',
    value: 5,
  },
  {
    key: '6 AM',
    text: '6 AM',
    value: 6,
  },
  {
    key: '7 AM',
    text: '7 AM',
    value: 7,
  },
  {
    key: '8 AM',
    text: '8 AM',
    value: 8,
  },
  {
    key: '9 AM',
    text: '9 AM',
    value: 9,
  },
  {
    key: '10 AM',
    text: '10 AM',
    value: 10,
  },
  {
    key: '11 AM',
    text: '11 AM',
    value: 11,
  },
  {
    key: 'Noon',
    text: 'Noon',
    value: 12,
  },
  {
    key: '1 PM',
    text: '1 PM',
    value: 13,
  },
  {
    key: '2 PM',
    text: '2 PM',
    value: 14,
  },
  {
    key: '3 PM',
    text: '3 PM',
    value: 15,
  },
  {
    key: '4 PM',
    text: '4 PM',
    value: 16,
  },
  {
    key: '5 PM',
    text: '5 PM',
    value: 17,
  },
  {
    key: '6 PM',
    text: '6 PM',
    value: 18,
  },
  {
    key: '7 PM',
    text: '7 PM',
    value: 19,
  },
  {
    key: '8 PM',
    text: '8 PM',
    value: 20,
  },
  {
    key: '9 PM',
    text: '9 PM',
    value: 21,
  },
  {
    key: '10 PM',
    text: '10 PM',
    value: 22,
  },
  {
    key: '11 PM',
    text: '11 PM',
    value: 23,
  },
];

export const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
