import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Divider, Dropdown, Form, Message, Checkbox } from 'components/lib';
import { Link } from 'react-router-dom';

import { InputField, PageHeader, PAGE_HEADER_MARGIN, StyledToggleSwitch } from 'components/common';
import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import { MARKET_TYPE_LABEL } from '../../constants';
import { USER_ROLES } from './constants';
import ManageUsersDropdown from './UserManagementManageUsersDropdown';
import AccountManagerDropdown from './UserManagementAccountManagerDropdown';

const PageContainer = styled.div`
  width: 100%;
  padding-bottom: ${({ showInvite }) => (showInvite ? '0' : '100px')};
`;

const InviteFieldContainer = styled.div`
  padding-bottom: 100px;
`;

const StyledCheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
`;
const PageHeaderWrapper = styled.div`
  .ui.header > .content {
    font-size: 40px;
    margin-left: -7px;
    padding-top: 5px;
  }
  .ui.header .sub.header {
    padding-top: 13px;
  }
`;
const USER_TYPE_OPTIONS = [
  { key: 'regular', value: 'null', text: 'Regular' },
  ...USER_ROLES.map((userType) => ({
    key: userType,
    value: userType,
    text: capitalize(userType),
  })),
];

const MARKET_TYPE_OPTIONS = Object.entries(MARKET_TYPE_LABEL).map(([value, text]) => ({
  key: value,
  value,
  text,
}));
const FormFields = ({
  user,
  values,
  loading,
  isEditMode,
  handleSubmit,
  setFieldValue,
  inviteSectionEnabled,
  handleAgencyCheckboxChange,
  handleToggleChange,
  isAgencyChecked,
}) => {
  const showInvite = !user?.lastLogin;
  const resendInvite = user?.invite && !user?.lastLogin;
  const selectedRegional = values.type === 'regional';

  const handleRoleChange = (event, data, setFieldValue) => {
    const { value } = data;
    setFieldValue('type', value);
  };
  return (
    <>
      <PageContainer showInvite={showInvite}>
        <PageHeaderWrapper>
          <PageHeader
            title={`${isEditMode ? 'Update' : 'Add'} user`}
            subtitle={`${isEditMode ? 'Manage an existing' : 'Create a new'} user`}
            margin={PAGE_HEADER_MARGIN.MEDIUM}
            actions={
              <>
                <Link to="/admin/user-management">
                  <Button
                    className="basic"
                    content={`${isEditMode ? 'Close' : 'Cancel'}`}
                    loading={loading}
                    disabled={loading}
                  />
                </Link>
                <Button
                  primary
                  className="blue"
                  type="submit"
                  content={`${isEditMode ? 'Update' : 'Add'}`}
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={loading}
                />
              </>
            }
          />
        </PageHeaderWrapper>
        <Form>
          <Form.Field>
            <InputField
              type="text"
              label="First Name"
              name="firstName"
              placeholder="First name"
              disabled={values.activated}
            />
          </Form.Field>
          <Form.Field>
            <InputField
              type="text"
              label="Last Name"
              name="lastName"
              placeholder="Last name"
              disabled={values.activated}
            />
          </Form.Field>
          <Form.Field>
            <InputField type="email" label="Email" name="email" placeholder="Email" disabled={values.activated} />
          </Form.Field>
          <Form.Field>
            <InputField type="tel" label="Phone" name="phone" placeholder="Phone" disabled={values?.activated} />
          </Form.Field>
          <Form.Field>
            <InputField
              type="text"
              label="Market Type"
              name="marketType"
              placeholder="Select Market Type"
              control={Dropdown}
              value={values.marketType}
              options={MARKET_TYPE_OPTIONS}
              fluid
              selection
            />
          </Form.Field>
          <Form.Field>
            <InputField
              type="text"
              label="Company name"
              name="companyName"
              placeholder="Company name"
              disabled={values.activated}
            />
          </Form.Field>
          <Form.Field>
            <label>Account Manager</label>
            <AccountManagerDropdown
              value={values?.accountManager}
              onChange={(value) => setFieldValue('accountManager', value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Types</label>
            <StyledCheckboxContainer>
              <Form.Field>
                <Checkbox
                  label="Demo"
                  name="demoAccount"
                  checked={values.demoAccount}
                  onChange={() => setFieldValue('demoAccount', !values.demoAccount)}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Pilot"
                  name="inPilot"
                  checked={values.inPilot}
                  onChange={() => setFieldValue('inPilot', !values.inPilot)}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Paying Customer"
                  name="payingCustomer"
                  checked={values.payingCustomer}
                  onChange={() => setFieldValue('payingCustomer', !values.payingCustomer)}
                />
              </Form.Field>
            </StyledCheckboxContainer>
          </Form.Field>

          <Form.Field>
            <label>Role</label>
            <Dropdown
              name="type"
              placeholder="Select Role"
              fluid
              selection
              value={values.type === null ? 'null' : values.type}
              options={USER_TYPE_OPTIONS}
              onChange={(event, data) => {
                handleRoleChange(event, data, setFieldValue);
              }}
            />
          </Form.Field>

          <If condition={selectedRegional}>
            <Form.Field>
              <Checkbox
                label="Agency"
                name="isAgency"
                checked={isAgencyChecked}
                onChange={() => handleAgencyCheckboxChange(setFieldValue, values)}
              />
            </Form.Field>
            {isAgencyChecked && (
              <Form.Field>
                <ManageUsersDropdown
                  onChange={(value) => setFieldValue('manageUsers', value)}
                  value={values?.managingUsers}
                />
              </Form.Field>
            )}
          </If>

          <If condition={showInvite}>
            <Divider section horizontal>
              Invitation
            </Divider>
            <If condition={!user?.lastLogin}>
              <StyledToggleSwitch
                toggle
                label={resendInvite ? 'Resend Invite' : 'Send Invite'}
                checked={inviteSectionEnabled}
                onChange={() => {
                  handleToggleChange(setFieldValue, values);
                }}
              />
              <InviteFieldContainer>
                <If condition={inviteSectionEnabled}>
                  <Form.Field>
                    <InputField
                      type="number"
                      label="Invite expires in days"
                      name="expiresIn"
                      placeholder="Invite expires in"
                    />
                  </Form.Field>
                </If>
                <If condition={user?.invite && user.invite?.expired && !user?.lastLogin}>
                  <Message
                    info
                    icon="exclamation triangle"
                    header="Invitation Expired!"
                    content="User invitation has expired."
                  />
                </If>
                <If condition={user?.invite && user.invite?.isValid}>
                  <Message
                    info
                    icon="info"
                    header={`Invitation Sent: ${new Date(user?.invite?.inviteSentAt).toLocaleString()}`}
                    content={`User invitation is valid. ${Math.ceil(
                      (new Date(user?.invite?.inviteSentAt).getTime() +
                        user?.invite?.expiresIn * 86400000 -
                        Date.now()) /
                        (1000 * 3600 * 24)
                    )} days left until it expires.`}
                  />
                </If>
              </InviteFieldContainer>
            </If>
          </If>
        </Form>
      </PageContainer>
    </>
  );
};

export default FormFields;
