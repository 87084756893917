import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { updateTour } from 'apis';
import { Button } from 'components/lib';
import { ConversationContext } from 'components/conversation/ConversationContext';
import { parseErrorResponse } from 'apis/utils';
import { formatAnyDate } from 'components/utils';

import { utcToZonedTime } from 'date-fns-tz';
import { capitalize } from 'utils/helpers';
import { REASON_FOR_CANCELLATION, CONVERSATION_ACTIONS } from './constants';
import {
  ConversationModal,
  ConversationModalContent,
  ConversationModalFooter,
  ConversationModalHeader,
} from './ConversationModal';

const Description = styled.p`
  margin-top: 10px;
  font-family: var(--font-family);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--light-text);
  span {
    color: var(--text);
  }
`;

const RightActions = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: end;
`;

export const CancelModal = () => {
  const { t } = useTranslation();
  const { eventType, fetchConversationDetails, selectedConversation, activeModal, showModal, eventTypeName } =
    useContext(ConversationContext);
  const [isLoading, setIsLoading] = useState(false);

  const event = selectedConversation && selectedConversation[eventType];
  const eventTypeUpper = capitalize(eventTypeName);

  const handleEventCancel = async () => {
    try {
      setIsLoading(true);

      await updateTour(event.hash, {
        cancelled: true,
        reason_for_cancellation: REASON_FOR_CANCELLATION.PLACEHOLDER,
      });

      toast.success(`The ${eventTypeName} has been canceled.`);
      fetchConversationDetails(selectedConversation.id);
      setIsLoading(false);
      showModal(null);
    } catch (error) {
      setIsLoading(false);
      console.error('error', error);
      toast.error(parseErrorResponse(error, `The ${eventTypeName} was not canceled.`));
    }
  };

  const dateTimezoned = event?.start && utcToZonedTime(event?.start, selectedConversation?.community.timezone);
  const date = formatAnyDate(dateTimezoned, "MMM do yyyy 'at' h:mm a");
  const dateInfo = date ? (
    <>
      &nbsp;on <span>{date}</span>&nbsp;
    </>
  ) : (
    <>&nbsp;</>
  );

  return (
    <ConversationModal
      closeIcon
      onClose={() => showModal(null)}
      onOpen={() => showModal(CONVERSATION_ACTIONS.CANCEL)}
      open={activeModal === CONVERSATION_ACTIONS.CANCEL}
      size="tiny"
    >
      <ConversationModalContent>
        <ConversationModalHeader>
          <h4>Cancel {eventTypeName}</h4>
        </ConversationModalHeader>

        <Description>
          The {eventTypeName} for <span>{selectedConversation?.name}</span>
          {dateInfo}will be canceled. The {t('lead')} will be notified. If you would like to schedule a different{' '}
          {eventTypeName} later, use the Schedule a {eventTypeUpper} button.
        </Description>
      </ConversationModalContent>
      <ConversationModalFooter>
        <RightActions>
          <Button primary color="blue" onClick={handleEventCancel} loading={isLoading}>
            Cancel {eventTypeUpper}
          </Button>
          <Button basic onClick={() => showModal(CONVERSATION_ACTIONS.MORE_OPTIONS)} disabled={isLoading}>
            Back
          </Button>
        </RightActions>
      </ConversationModalFooter>
    </ConversationModal>
  );
};
