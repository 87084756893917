import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, TextArea, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { InputField } from 'components/common/fields/InputField';
import { ToursContext } from './ToursContext';
import { STATUSES } from '../../constants';

const VALIDATION_SCHEMA = Yup.object({
  reason_for_cancellation: Yup.string().required('Reason for cancellation is required'),
});

const TourCancelModal = () => {
  const { tour, updateStatus, toggleCloseModal, showCancelModal, handleTourSubmit } = useContext(ToursContext);

  const onSubmit = (values) => {
    handleTourSubmit({
      ...values,
      cancelled: true,
    });
  };

  const loading = updateStatus === STATUSES.LOADING;

  return (
    <Formik initialValues={tour} validationSchema={VALIDATION_SCHEMA} onSubmit={onSubmit} enableReinitialize>
      {({ handleSubmit }) => (
        <Modal onClose={toggleCloseModal} open={showCancelModal} size="tiny">
          <Modal.Header>
            You are going to cancel {tour.request_type.toLowerCase()} for {tour.community_name}
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Field>
                  <InputField
                    type="text"
                    control={TextArea}
                    label="Reason for cancellation"
                    name="tour.reason_for_cancellation"
                    placeholder="Reason for cancellation"
                  />
                </Form.Field>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color="black" content="Close" onClick={toggleCloseModal} />
            <Button primary content="Save" onClick={handleSubmit} loading={loading} disabled={loading} />
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

export default TourCancelModal;
