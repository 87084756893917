import React, { useContext, useRef, useState } from 'react';
import { Button, Divider, Form, Message, Segment } from 'semantic-ui-react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { updateTrackingAnalytics } from '../../../apis';
import { PAGE_HEADER_MARGIN, PageSection } from '../../common';
import { STATUSES } from '../../../constants';
import { ChatsContext } from '../ChatsContext';
import { AnalyticsChatForm } from './AnalyticsChatsForm';
import { AnalyticsTrackingForm } from './AnalyticsTrackingForm';
import { AnalyticsTemplatePreview } from './AnalyticsTemplatePreview';
import { If } from '../../If';
import { ANALYTICS_TYPE_CUSTOM } from './constants';

export const AnalyticsIntegration = () => {
  const { loaderStatus } = useContext(ChatsContext);
  const [updateStatus, setUpdateStatus] = useState(STATUSES.IDLE);

  const formRef = useRef();

  const onAdd = () => {
    if (formRef.current) {
      if (!formRef.current?.isValid) {
        toast.warning('Please fill out all fields.');
        return;
      }
      formRef.current.handleSubmit();
    }
  };

  const onSubmit = async (data) => {
    try {
      setUpdateStatus(STATUSES.LOADING);
      await updateTrackingAnalytics(data);
      setUpdateStatus(STATUSES.LOADED);
      toast.success('Successfully updated tracking analytics!');
    } catch (e) {
      setUpdateStatus(STATUSES.FAILURE);
      toast.error('Failed to update tracking analytics!');
      console.error(e);
    }
  };

  const initialValues = {
    chats: [],
    type: undefined,
    fields: [],
  };

  const validationSchema = Yup.object({
    chats: Yup.array().required('Please select at least one chat!'),
    type: Yup.string().required('Please select analytics type!'),
    fields: Yup.array().of(Yup.string().required('Please fill all the fields!')),
  });

  return (
    <PageSection
      title="Analytics Integration"
      subtitle="Define parameters for individual tracking analytics integrations."
      margin={PAGE_HEADER_MARGIN.LARGE}
      actions={<Button primary content="Add" icon="add" loading={updateStatus === STATUSES.LOADING} onClick={onAdd} />}
    >
      <Message info>
        <h5>Setup tracking code for one or multiple chats</h5>
        <ul>
          <li>Select chats you wish to change</li>
          <li>Choose analytics tracking type</li>
          <li>Provide values for all fields</li>
        </ul>
      </Message>

      <Segment basic loading={loaderStatus === STATUSES.LOADING}>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <AnalyticsChatForm setFieldValue={setFieldValue} />
              <Divider content="Analytics Settings" horizontal />
              <AnalyticsTrackingForm values={values} setFieldValue={setFieldValue} />
              <If condition={values.type && values.type !== ANALYTICS_TYPE_CUSTOM}>
                <AnalyticsTemplatePreview values={values} />
              </If>
            </Form>
          )}
        </Formik>
      </Segment>
    </PageSection>
  );
};
