import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Button } from 'components/lib';
import { If } from '../If';

export const PickerButton = styled(Button)`
  &&&& {
    width: calc(50% - 10px);
    min-width: 95px;
    height: 36px;
    margin: 0;
    padding: 0;
    justify-content: center;
    margin-bottom: 10px;
    font-family: var(--font-family);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: var(--light-text);
    &.active {
      border-color: ${({ $color }) => $color};
      background-color: ${({ $fadedColor }) => $fadedColor};
      color: ${({ $color }) => $color};
    }
    &.full {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const MessageContainer = styled.div`
  color: var(--light-text);
  text-align: center;
`;

export const Picker = ({ options = [], type, onChange, multiple, initial = [] }) => {
  const [selected, setSelected] = useState(initial);
  const { color, fadedColor } = type;

  const handleClick = (value) => {
    if (selected.includes(value)) {
      setSelected((prevSelected) => prevSelected.filter((s) => s !== value));
    } else if (multiple) {
      setSelected((prevSelected) => [...prevSelected, value]);
    } else {
      setSelected([value]);
    }
  };

  useEffect(() => {
    onChange(multiple ? selected : selected[0]);
  }, [selected]);

  const maxSelected = selected.length > 0 && !multiple;
  const containerClasses = classNames({ maxSelected });

  return (
    <Container className={containerClasses}>
      <If condition={options.length === 0}>
        <MessageContainer>No available time slots for the selected day.</MessageContainer>
      </If>
      {options.map((option, i) => {
        const { text, value } = option;
        const active = selected.find((s) => s === value);
        const classes = classNames({ active });
        return (
          <PickerButton
            primary
            key={i}
            $color={color}
            $fadedColor={fadedColor}
            onClick={() => handleClick(value)}
            className={classes}
          >
            {text}
          </PickerButton>
        );
      })}
    </Container>
  );
};
