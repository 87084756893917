import React, { useContext } from 'react';
import { Segment, Message, Label } from 'semantic-ui-react';

import { If } from 'components/If';
import { MessageContext } from './MessageContext';
import { ANSWER_TEXT_PLACEHOLDER, ANSWER_TYPES } from './constants';

export const ConversationResponseBlocks = () => {
  const { labels, removeLabel } = useContext(MessageContext);

  const noAppliedLabels = !labels || labels.length === 0;

  return (
    <Segment disabled={noAppliedLabels} style={{ minHeight: 250, maxHeight: 400, overflow: 'auto' }}>
      <If condition={noAppliedLabels}>
        <Label attached="top" icon="info" color="blue" content="Select label from sidebar to generate message!" />
      </If>

      {labels.map((l) => (
        <Message key={l.id} content={l.answer} onDismiss={() => removeLabel({ id: l.id })} />
      ))}
    </Segment>
  );
};
