import React, { useState, useCallback, useEffect, useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import { Dimmer, Divider, Loader, Tab } from 'semantic-ui-react';

import { leadFurtherArchive, getInstantResponses, getInstantResponsesLastMessage } from 'apis';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import useInterval from 'utils/hooks/useInterval';
import { PAGINATION, STATUSES } from '../../../constants';
import InstantResponsesMetrics from './InstantResponsesMetrics';
import InstantResponsesList from './InstantResponsesList';
import InstantResponsesTakeOverBox from './InstantResponsesTakeOverBox';
import { PageHeader } from '../../common';
import { MessageContext } from './MessageContext';
import { SALES_ASSISTANT_TABS, SALES_ASSISTANT_TAB_FILTERS } from './constants';
import { AutomationReview } from './AutomationReview';

const FETCH_IA_DELAY = 30 * 1000; // 30 seconds;

const InstantResponses = () => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { activeTab, setActiveTab, messagesList, lead, resetMessagePreview } = useContext(MessageContext);
  const tabsRef = useRef();
  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters } = messagesList;
  const [lastMessageDate, setLastMessageDate] = useState(new Date());
  const [newMessagesCount, setNewMessagesCount] = useState(0);

  const fetchInstantResponses = useCallback(
    async ({
      page,
      pageSize,
      search,
      isArchived,
      hasNurtureAssistant,
      hasFurtherMessages,
      hasUnconfirmedCalendarRequests,
      financiallyUnqualified,
      replied,
    }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const params = {
          page,
          page_size: pageSize,
          search,
          has_nurture_assistant: hasNurtureAssistant,
          has_unconfirmed_calendar_requests: hasUnconfirmedCalendarRequests,
        };
        if (hasFurtherMessages) {
          params.has_further_messages = true;
        } else {
          params.is_archived = isArchived;
        }
        if (typeof financiallyUnqualified !== 'undefined') {
          params.financially_unqualified = financiallyUnqualified;
        }
        if (typeof replied !== 'undefined') {
          params.replied = replied;
        }
        const { results, count } = await getInstantResponses(params);

        setNewMessagesCount(0);
        setItems({
          results,
          count,
        });

        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        toast.error('Unable to load messages');
        setItems({
          results: null,
          count: 0,
        });
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useInterval(async () => {
    const { hasNurtureAssistant, search, financiallyUnqualified, replied } = filters;

    try {
      const params = {
        from_date: lastMessageDate,
        has_nurture_assistant: hasNurtureAssistant,
        is_archived: false,
      };
      if (activeTab <= SALES_ASSISTANT_TABS.FIN_UNQUALIFIED) {
        params.search = search;
      }
      if (typeof financiallyUnqualified !== 'undefined') {
        params.financially_unqualified = financiallyUnqualified;
      }
      if (typeof replied !== 'undefined') {
        params.replied = replied;
      }
      const { has_new_messages, new_messages_count, last_message_date } = await getInstantResponsesLastMessage(params);

      if (has_new_messages) {
        setNewMessagesCount(newMessagesCount + new_messages_count);
        setLastMessageDate(last_message_date);
      }
    } catch (e) {
      console.error(e);
    }
  }, FETCH_IA_DELAY);

  useEffect(() => {
    fetchInstantResponses(filters);
  }, [fetchInstantResponses, filters]);

  useEffect(() => {
    if (lead) {
      resetMessagePreview();
    }
  }, [lead]);

  const handleTabChange = (e, { activeIndex }) => {
    setActiveTab(activeIndex);

    setFilters(SALES_ASSISTANT_TAB_FILTERS[activeIndex]);
    setNewMessagesCount(0);
  };

  const handleNurtureAssistantChange = (e, { checked }) => {
    setFilters({ page: PAGINATION.PAGE, hasNurtureAssistant: checked });
    setNewMessagesCount(0);
  };

  const handleToggleArchive = async (leadId, data) => {
    const { is_further_archived } = data;

    try {
      setLoaderStatus(STATUSES.LOADING);

      await leadFurtherArchive(leadId, data);
      await fetchInstantResponses(filters);

      setLoaderStatus(STATUSES.LOADED);
      toast.success(`Message ${is_further_archived ? 'archived' : 'restored'} successfully`);
    } catch (e) {
      toast.error(`Unable to ${is_further_archived ? 'archive' : 'restore'} messages`);
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const handleNewMessages = () => {
    tabsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setNewMessagesCount(0);
    let targetTab = activeTab;
    let search = filters.search;
    if (activeTab > SALES_ASSISTANT_TABS.FIN_UNQUALIFIED) {
      targetTab = SALES_ASSISTANT_TABS.CAMPAIGN_MESSAGES;
      search = '';
      setActiveTab(targetTab);
    }
    setFilters({
      ...filters,
      ...SALES_ASSISTANT_TAB_FILTERS[targetTab],
      page: PAGINATION.PAGE,
      search,
    });
  };

  const renderCommonInstantResponse = (selectable = false) => (
    <Tab.Pane as="div">
      <InstantResponsesList
        messages={items}
        filters={filters}
        totalPages={totalPages}
        newMessagesCount={newMessagesCount}
        selectable={selectable}
        onPageChange={setPage}
        onSearch={setSearch}
        onNurtureAssistantToggle={handleNurtureAssistantChange}
        onToggleArchive={handleToggleArchive}
        onNewMessages={handleNewMessages}
      />
    </Tab.Pane>
  );

  const panes = [
    {
      menuItem: 'All New Messages',
      render: () => renderCommonInstantResponse(true),
    },
    {
      menuItem: 'Not Fin. Unqualified',
      render: () => renderCommonInstantResponse(),
    },
    {
      menuItem: 'Fin. Unqualified',
      render: () => renderCommonInstantResponse(),
    },
    {
      menuItem: 'Archived',
      render: () => renderCommonInstantResponse(),
    },
    {
      menuItem: 'Further Sent Messages',
      render: () => renderCommonInstantResponse(),
    },
    {
      menuItem: 'Tours/Calls Not Confirmed',
      render: () => renderCommonInstantResponse(),
    },
    {
      menuItem: 'New Conversations',
      render: () => renderCommonInstantResponse(),
    },
    {
      menuItem: 'Automation Review',
      render: () => <AutomationReview />,
    },
  ];

  return (
    <>
      <PageHeader as="h2" title="Instant Responses" />
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <InstantResponsesMetrics />

      <div ref={tabsRef}>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={activeTab}
          onTabChange={handleTabChange}
        />
      </div>

      <If condition={loaderStatus === STATUSES.FAILURE}>
        <Divider hidden />
        <FailureMessage icon="search" content="Messages could not be loaded" />
      </If>
    </>
  );
};

export default InstantResponses;
