import React, { useMemo } from 'react';
import { Divider, Dropdown, Form, Grid } from 'semantic-ui-react';

import { CheckboxField } from 'components/common';
import { InputField } from 'components/common/fields/InputField';
import CRMField from './fields';

const CRMTypeFormFields = ({ crmType }) => {
  if (!crmType) {
    return null;
  }

  const columns = crmType.fields.length % 2 === 0 || crmType.fields?.length >= 4 ? 2 : 'equal';
  const messagingColumns =
    crmType.messaging_fields.length % 2 === 0 || crmType.messaging_fields?.length >= 4 ? 2 : 'equal';

  return (
    <>
      <Grid divided="vertically">
        <Grid.Row columns={columns}>
          {crmType.fields.map((f) => (
            <CRMField
              key={f.name}
              fieldType={crmType.field_types[f.name]}
              name={f.name}
              value={f.value}
              defaultValue={crmType.defaults[f.name]}
              fieldValues={crmType.field_values[f.name]}
            />
          ))}
        </Grid.Row>
      </Grid>
      {crmType.messaging_fields.length !== 0 && (
        <>
          <Divider horizontal>Messaging</Divider>
          <Grid divided="vertically">
            <Grid.Row columns={messagingColumns}>
              {crmType.messaging_fields.map((f) => (
                <CRMField key={f.name} fieldType="any" name={f.name} value={f.value} />
              ))}
            </Grid.Row>
          </Grid>
        </>
      )}
    </>
  );
};

export const CRMForm = ({ onCRMTypeChanged, crmTypes, selectedCRMType }) => {
  const handleCRMTypeChanged = (crmTypeId) => {
    onCRMTypeChanged(crmTypeId);
  };

  const crmTypeOptions = useMemo(
    () =>
      crmTypes.map((type) => ({
        key: type.value,
        text: type.text,
        value: type.value,
      })),
    [crmTypes]
  );

  return (
    <>
      <InputField
        label="CRM Type"
        name="integration_type"
        control={Dropdown}
        placeholder="Select CRM type"
        fluid
        search
        selection
        options={crmTypeOptions}
        onChanged={handleCRMTypeChanged}
      />
      <InputField name="name" label="Name" placeholder="CRM settings name" />
      <Form.Group>
        <CheckboxField name="is_default" label="Default" />
        <CheckboxField name="is_enabled" label="Enabled" />
      </Form.Group>
      <Divider hidden />
      <CRMTypeFormFields crmType={selectedCRMType} />
    </>
  );
};
