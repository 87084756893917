import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Icon, Segment } from 'semantic-ui-react';

import { Accordion, Dimmer, Dropdown, Form, Input, Loader, Message } from 'components/lib';
import { CsvField } from 'components/CsvField';
import { ThreeStateToggle } from 'components/ThreeStateToggle';
import { parseErrorResponse } from 'apis/utils';
import { Formik } from 'formik';
import { InputField } from 'components/common';
import { getSurveyData, setSurveyData } from 'apis';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import { STATUSES } from '../../../constants';
import { CommunitiesContext } from '../../common/communities/CommunitiesContext';
import { UserCommunityPanelHeader } from './UserCommunityPanelHeader';

const emptyArray = [];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .update-button {
    margin-top: 20px;
  }

  .content {
    margin: 10px 10px 20px 10px;
  }
`;

const TogglesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    min-width: 30%;
  }
`;

const NULLABLE_FIELDS = [
  'age_limit',
  'entrance_fee',
  'lease_term',
  'pets',
  'physical_therapy',
  'second_person_fee',
  'wheelchair',
  'number_residents',
];

const makeTextOptions = (values) =>
  values.map((text) => ({
    key: text,
    value: text,
    text,
  }));

const WHEELCHAIR_ACCESS_OPTIONS = [
  { key: 'fully', text: 'Fully wheelchair accessible', value: 'Fully wheelchair accessible' },
  { key: 'mostly', text: 'Mostly wheelchair accessible', value: 'Mostly wheelchair accessible' },
  { key: 'limited', text: 'Limited wheelchair accessibility', value: 'Limited wheelchair accessibility' },
  { key: 'none', text: 'Not wheelchair accessible', value: 'Not wheelchair accessible' },
  { key: 'dont_know', text: "Don't Know", value: null },
];

const PETS_ALLOWED_OPTIONS = [
  { key: 'allowed', text: 'Yes/It depends', value: 'Yes/It depends' },
  { key: 'not_allowed', text: 'No pets allowed', value: 'No pets allowed' },
  { key: 'dont_know', text: "Don't Know", value: null },
];

const PETS_POLICY_OPTIONS = makeTextOptions([
  'Cats allowed',
  'Small dogs allowed (under 25 lbs.)',
  'Large dogs allowed (over 25 lbs.)',
  'Service animals allowed',
  'All pets welcome',
]);

const PHYSICAL_THERAPY_OPTIONS = [
  {
    key: 'onsite-facility',
    text: 'Onsite physical therapy (provided by facility)',
    value: 'Onsite physical therapy (provided by facility)',
  },
  {
    key: 'onsite-3rd-party',
    text: 'Onsite physical therapy (third party provider)',
    value: 'Onsite physical therapy (third party provider)',
  },
  { key: 'offsite', text: 'Offsite physical therapy', value: 'Offsite physical therapy' },
  { key: 'none', text: 'Physical therapy is not available', value: 'Physical therapy is not available' },
  { key: 'dont_know', text: "Don't Know", value: null },
];

const EXCERCISE_FITNESS_OPTIONS = makeTextOptions([
  'Exercise classes',
  'Exercise pool',
  'Gym or fitness room',
  'Organized sports',
  'Other',
  'Outdoor activities',
  'Swimming pool',
  'Yoga',
]);

const ROOM_AMENITIES_OPTIONS = makeTextOptions([
  'Air conditioning',
  'Dishwasher',
  'Furnished Rooms',
  'Individual climate control',
  'Microwaves',
  'Private balcony',
  'Private kitchen',
  'Private kitchenette',
  'Private outdoor area',
  'Private patio',
  'Refrigerators',
  'Safety-assist bathroom',
  'Walk-in Tub',
  'Walk-in closet',
  'Walk-in shower',
  'Washer & Dryer',
]);

const INCLUDED_OPTIONS = makeTextOptions([
  '24/7 Dining',
  'Basic Cable',
  'Breakfast',
  'Dinner',
  'Housekeeping',
  'Internet/WiFi',
  'Linen Service',
  'Lunch',
  'None',
  'Scheduled Transportation',
  'Utilities',
]);

const ACTIVITIES_OPTIONS = makeTextOptions([
  'Activity programs',
  'Arts and crafts',
  'Billiards',
  'Book clubs',
  'Card playing',
  'Cooking classes',
  'Exercise classes',
  'Exercise programs',
  'Game nights',
  'Indoor group activities',
  'Intergenerational programs',
  'Lectures and discussions',
  'Live entertainment',
  'Meditation',
  "Men's groups",
  'Movie nights',
  'Musical instruments',
  'Organized sports (indoor)',
  'Physical fitness program',
  'Piano',
  'Resident-run activities',
  'Stories',
  'TV (cable/satellite)',
  'Tai Chi',
  'Wellness programs',
  'Wii games',
  "Women's groups",
  'Yoga',
]);

const OUTDOOR_ACTIVITIES_OPTIONS = makeTextOptions([
  'Accompanied walks',
  'Cycling',
  'Day trips',
  'Events and outings',
  'Gardening',
  'Organized sports (outdoor)',
  'Outdoor group activities',
  'Park visits',
  'Volunteer opportunities',
  'Walking trails',
]);

const OUTDOOR_AREAS_OPTIONS = makeTextOptions([
  'Balcony/terrace',
  'Courtyard',
  'Garden',
  'Golf course',
  'Landscaped areas',
  'Outdoor areas suitable for walking',
  'Patio',
  'Porch',
  'Resident-run garden',
  'Scenic views',
  'Walking paths',
  'Yard/lawn',
]);

const CARE_TYPES_OPTIONS = makeTextOptions([
  'Assisted Living',
  'CCRC',
  'Independent Living',
  'Memory Care',
  'Respite Care (Short Term)',
  'Secure Memory Care Unit',
  'Skilled Nursing',
]);

const MEALS_OPTIONS = makeTextOptions([
  'Beverages and snacks',
  'Changing menu',
  'Chef-prepared',
  'Continental breakfast',
  'Ethnic options (e.g., Chinese)',
  'Family-style meals',
  'Fine dining',
  'Menu choices',
  'Nightly specials',
  'No Dining',
  'Popcorn machine',
  'Seasonal menus',
  'Self-prepared',
]);

const DINING_AREAS_OPTIONS = makeTextOptions([
  'Bistro dining',
  'Buffet dining',
  'Café seating',
  'Coffee shop',
  'Dining room',
  'Ice cream parlor',
  'In-room dining',
  'Private dining room available',
  'Restaurant-style meal service',
]);

const DIETARY_NEEDS_OPTIONS = makeTextOptions([
  'Clean foods',
  'Diabetic options',
  'Gluten-free',
  'Halal',
  'Kosher',
  'Low sugar/salt',
  'Nutritious snacks',
  'Organic food',
  'Therapeutic diets',
  'Vegan',
  'Vegetarian',
]);

const AMENITIES_OPTIONS = makeTextOptions([
  'Activity center',
  'Aviary',
  'Bar/pub',
  'Beauty salon/services',
  'Business center',
  'Communal kitchen',
  'Community pets',
  'Conference room',
  'Elevators',
  'Exercise pool',
  'Exercise room',
  'Fireplace',
  'Fitness room',
  'General store',
  'Greenhouse',
  'Grocery store',
  'Gym',
  'Hot tub',
  'Individual mailboxes',
  'Internet (WiFi)',
  'Internet (high-speed)',
  'Internet café',
  'Jacuzzi',
  'Library',
  'Local shopping',
  'Lounge area',
  'Movie theater',
  'Near golf course',
  'Near hospitals',
  'Near parks',
  'Near water',
  'On-site clinic',
  'On-site market',
  'Outdoor seating',
  'Parking on premises',
  'Party space',
  'Pharmacy',
  'Putting green',
  'Rehabilitation room',
  'Sauna',
  'Secure memory care wing',
  'Separate male/female memory care areas',
  'Shared computers',
  'Spa',
  'Storage areas',
  'Sun room',
  'Swimming pool',
  'Therapy gym',
  'Washer/dryer',
]);

const LANGUAGES_OPTIONS = makeTextOptions([
  'Arabic',
  'Chinese (Cantonese)',
  'Chinese (Mandarin)',
  'English',
  'French',
  'German',
  'Haitian Creole',
  'Korean',
  'Russian',
  'Spanish',
  'Tagalog',
  'Vietnamese',
]);

const SMOKING_OPTIONS = makeTextOptions([
  'In-room smoking available',
  'Indoor smoking areas',
  'Outdoor smoking areas',
  'Smoking Not Allowed',
]);

const SECURITY_OPTIONS = makeTextOptions([
  'Staff background checks',
  '24-hour security',
  'Security Gate',
  'Secure Memory Care Unit',
]);

const CLEANING_SERVICES_OPTIONS = makeTextOptions([
  'Dry cleaning available',
  'Full-service laundry available',
  'Housekeeping included',
  'Linen services included',
  'None',
]);

const RELIGIOUS_SERVICES_OPTIONS = makeTextOptions([
  'Devotional areas',
  'Religious services',
  'Spiritual activities',
  'Chapel',
  'Chaplain',
]);

const OTHER_DAILY_SERVICES_OPTIONS = makeTextOptions([
  '24-hour staffing',
  'Accompaniment to medical appointments',
  'Bathing assistance',
  'Check-in care (visit room, prompt for medication)',
  'Concierge services',
  'Dietitian services',
  'Dressing assistance',
  'Eating assistance',
  'Emergency pendant system',
  'Errand assistance',
  'Grocery shopping assistance',
  'Grooming assistance',
  'Incontinence care',
  'Life Alert/Lifeline',
  'Medication management',
  'Nutritionist',
  'Patient-lift accessibility',
  'Pedicure/manicure',
  'Prescription drop off/pick up',
  'Reading assistance',
  'Shopping assistance',
  'Shopping trips',
  'Transfer assistance',
  'Other daily living services',
]);

const TRANSPORTATION_OPTIONS = makeTextOptions([
  'Scheduled local transportation',
  'Transportation arrangement',
  'Transportation for personal errands',
  'Transportation to events',
  'Transportation to medical appointments',
  'Transportation to religious services',
  'Travel companion available',
  'Wheelchair-accessible transportation',
]);

const BUDGET_ASSISTANCE_OPTIONS = makeTextOptions([
  'HUD accepted',
  'Medicaid accepted',
  'Medicare accepted',
  'None of the above',
  'Section 8 accepted',
]);

const ROOM_TYPES_OPTIONS = makeTextOptions([
  'Private Rooms',
  'Semi-private Rooms',
  'Furnished Rooms',
  'Studios',
  'Deluxe Studios',
  '1 Bedroom / 1 Bath',
  '2 Bedroom / 1 Bath',
  '2 Bedroom / 1.5 Bath',
  '2 Bedroom / 2 Bath',
  'Suites',
  'Companion Suites',
  'Recovery Suites',
  'Cottages',
]);

const VISITING_HOURS_OPTIONS = makeTextOptions([
  'Morning visiting hours',
  'Afternoon visiting hours',
  'Evening visiting hours',
  'Flexible visiting hours',
  'Guests at mealtimes',
  'On-site parking for guests',
  'Overnight guests allowed',
]);

const UserCommunitySurveyData = ({ communityId }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [initialValues, setInitialValues] = useState({});
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [formLoading, setFormLoading] = useState(false);
  const loading = loaderStatus === STATUSES.LOADING || formLoading;

  const fetchSurveyData = useCallback(async () => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const surveyData = await getSurveyData(communityId);
      setInitialValues(surveyData);
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      if (e.response?.status === 404) {
        setLoaderStatus('UNAVAILABLE');
        return;
      }
      toast.error(parseErrorResponse(e, 'Unable to fetch survey data.'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  }, [communityId]);

  useEffect(() => {
    fetchSurveyData();
  }, [fetchSurveyData]);

  const handleSubmit = async (values) => {
    try {
      setFormLoading(true);
      NULLABLE_FIELDS.forEach((field) => {
        if (!values[field]) {
          values[field] = null;
        }
      });

      await setSurveyData(communityId, values);
      toast.success('Survey data updated successfully');
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to update survey data.'));
    } finally {
      setFormLoading(false);
    }
  };

  const toggleSections = (e, { index }) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Segment basic>
      <Container>
        <Dimmer active={loading} inverted>
          <Loader inverted active>
            Loading
          </Loader>
        </Dimmer>
        <If condition={loaderStatus === STATUSES.LOADED}>
          <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
              <Form>
                <UserCommunityPanelHeader
                  title="Edit Survey Data"
                  subtitle="Enter new information about the community."
                  disabled={loaderStatus !== STATUSES.LOADED}
                  loading={loaderStatus === STATUSES.LOADING}
                  onUpdate={() => handleSubmit()}
                />
                <Accordion>
                  <Accordion.Title active={activeIndex === 0} index={0} onClick={toggleSections}>
                    <Icon name="dropdown" />
                    URLs
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 0}>
                    <Form.Field>
                      <InputField
                        type="text"
                        label="Activities Calendar URL"
                        name="activities_calendar_url"
                        placeholder="Enter Activities Calendar URL..."
                        control={Input}
                      />
                    </Form.Field>
                    <Form.Field>
                      <InputField
                        type="text"
                        label="Amenities URL"
                        name="amenities_url"
                        placeholder="Enter Amenities URL..."
                        control={Input}
                      />
                    </Form.Field>
                    <Form.Field>
                      <InputField
                        type="text"
                        label="Floorplans URL"
                        name="floorplans_url"
                        placeholder="Enter Floorplans URL..."
                        control={Input}
                      />
                    </Form.Field>
                    <Form.Field>
                      <InputField
                        type="text"
                        label="Images URL"
                        name="images_url"
                        placeholder="Enter Images URL..."
                        control={Input}
                      />
                    </Form.Field>
                    <Form.Field>
                      <InputField
                        type="text"
                        label="Jobs URL"
                        name="jobs_url"
                        placeholder="Enter Jobs URL..."
                        control={Input}
                      />
                    </Form.Field>
                  </Accordion.Content>
                  <Accordion.Title active={activeIndex === 1} index={1} onClick={toggleSections}>
                    <Icon name="dropdown" />
                    Activities, Amenities & Services
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 1}>
                    <CsvField sortable name="activities" label="Activities" options={ACTIVITIES_OPTIONS} />
                    <CsvField sortable name="group_activities" label="Group Activities" options={emptyArray} />
                    <CsvField
                      sortable
                      name="outdoor_activities"
                      label="Outdoor Activities"
                      options={OUTDOOR_ACTIVITIES_OPTIONS}
                    />
                    <CsvField sortable name="outdoor_areas" label="Outdoor Areas" options={OUTDOOR_AREAS_OPTIONS} />
                    <CsvField sortable name="amenities" label="Amenities" options={AMENITIES_OPTIONS} />
                    <CsvField
                      sortable
                      name="budget_assistance"
                      label="Budget Assistance"
                      options={BUDGET_ASSISTANCE_OPTIONS}
                    />
                    <CsvField sortable name="care_types" label="Care Types" options={CARE_TYPES_OPTIONS} />
                    <CsvField sortable name="room_types" label="Room Types" options={ROOM_TYPES_OPTIONS} />
                    <CsvField
                      sortable
                      name="cleaning_services"
                      label="Cleaning Services"
                      options={CLEANING_SERVICES_OPTIONS}
                    />
                    <CsvField
                      sortable
                      name="religious_services"
                      label="Religious Services"
                      options={RELIGIOUS_SERVICES_OPTIONS}
                    />
                    <CsvField sortable name="dining_areas" label="Dining Areas" options={DINING_AREAS_OPTIONS} />
                    <CsvField sortable name="dietary_needs" label="Dietary Needs" options={DIETARY_NEEDS_OPTIONS} />
                    <CsvField
                      sortable
                      name="exercise_fitness"
                      label="Exercise Fitness"
                      options={EXCERCISE_FITNESS_OPTIONS}
                    />
                    <CsvField sortable name="included" label="Included" options={INCLUDED_OPTIONS} />
                    <CsvField sortable name="languages" label="Languages" options={LANGUAGES_OPTIONS} />
                    <CsvField sortable name="meals" label="Meal Options" options={MEALS_OPTIONS} />
                    <CsvField sortable name="room_amenities" label="Room Amenities" options={ROOM_AMENITIES_OPTIONS} />
                    <CsvField sortable name="smoking" label="Smoking Areas" options={SMOKING_OPTIONS} />
                    <CsvField sortable name="transportation" label="Transportation" options={TRANSPORTATION_OPTIONS} />
                    <CsvField
                      sortable
                      name="services"
                      label="Other Daily Services"
                      options={OTHER_DAILY_SERVICES_OPTIONS}
                    />
                    <CsvField sortable name="care_services" label="Care Services" options={emptyArray} />
                    <CsvField sortable name="security" label="Security" options={SECURITY_OPTIONS} />
                    <CsvField
                      sortable
                      name="visiting_hours"
                      label="Visiting and Guest Features"
                      options={VISITING_HOURS_OPTIONS}
                    />
                    <Form.Field>
                      <InputField
                        name="pets"
                        label="Pets Allowed"
                        control={Dropdown}
                        options={PETS_ALLOWED_OPTIONS}
                        placeholder="Don't Know"
                        simple
                        basic
                      />
                    </Form.Field>
                    <CsvField sortable name="pets_policy" label="Pets Policy" options={PETS_POLICY_OPTIONS} />
                    <Form.Field>
                      <InputField
                        name="physical_therapy"
                        label="Physical Therapy"
                        control={Dropdown}
                        options={PHYSICAL_THERAPY_OPTIONS}
                        placeholder="Don't Know"
                        simple
                        basic
                      />
                    </Form.Field>
                    <Form.Field>
                      <InputField
                        name="wheelchair"
                        label="Wheelchair Access"
                        control={Dropdown}
                        options={WHEELCHAIR_ACCESS_OPTIONS}
                        placeholder="Don't Know"
                        simple
                        basic
                      />
                    </Form.Field>
                    <Form.Field>
                      <InputField type="number" label="Resident capacity" name="number_residents" control={Input} />
                    </Form.Field>
                  </Accordion.Content>
                  <Accordion.Title active={activeIndex === 2} index={2} onClick={toggleSections}>
                    <Icon name="dropdown" />
                    Lease Terms & Fees
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 2}>
                    <Form.Field>
                      <InputField
                        type="number"
                        label="Entrance Fee"
                        name="entrance_fee"
                        min="0"
                        placeholder="Enter Entrance Fee..."
                        control={Input}
                      />
                    </Form.Field>
                    <Form.Field>
                      <InputField
                        type="number"
                        label="Second Person Fee"
                        name="second_person_fee"
                        min="0"
                        placeholder="Enter Second Person Fee..."
                        control={Input}
                      />
                    </Form.Field>
                    <Form.Field>
                      <InputField
                        type="number"
                        label="Lease Term Months"
                        name="lease_term"
                        min="0"
                        placeholder="Enter Min Lease Term Months..."
                        control={Input}
                      />
                    </Form.Field>
                    <Form.Field>
                      <InputField
                        type="number"
                        label="Age Limit"
                        name="age_limit"
                        min="0"
                        placeholder="Enter Age Limit..."
                        control={Input}
                      />
                    </Form.Field>
                    <ThreeStateToggle name="adult_day_care" label="Adult Day Care" />
                    <Form.Field>
                      <InputField
                        type="text"
                        label="Adult Day Care Hours"
                        name="adult_day_care_hours"
                        placeholder="Enter Addult Day Care Hours..."
                        control={Input}
                      />
                    </Form.Field>
                  </Accordion.Content>
                  <Accordion.Title active={activeIndex === 3} index={3} onClick={toggleSections}>
                    <Icon name="dropdown" />
                    Various Flags
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 3}>
                    <TogglesContainer>
                      <ThreeStateToggle name="car_allowed" label="Car Allowed" />
                      <ThreeStateToggle name="couples_allowed" label="Couples Allowed" />
                      <ThreeStateToggle name="hospice" label="Hospice" />
                      <ThreeStateToggle name="long_term_insurance" label="Long Term Insurance" />
                      <ThreeStateToggle name="parking" label="Parking" />
                      <ThreeStateToggle name="private_aides" label="Private Aides" />
                      <ThreeStateToggle name="respite_care" label="Respite Care" />
                      <ThreeStateToggle name="skilled_nursing" label="Skilled Nursing" />
                      <ThreeStateToggle name="speech_therapy" label="Speech Therapy" />
                      <ThreeStateToggle name="vision_impaired" label="Vision Impaired" />
                      <ThreeStateToggle name="men_women_separated" label="Men and women separated" />
                    </TogglesContainer>
                  </Accordion.Content>
                </Accordion>
              </Form>
            )}
          </Formik>
        </If>
        <If condition={loaderStatus === 'UNAVAILABLE'}>
          <Message error icon="warning" content="Survey data is only available for assisted living communities." />
        </If>
        <If condition={loaderStatus === STATUSES.FAILURE}>
          <FailureMessage icon="warning" content="Failed to fetch Survey Data." onTryAgain={fetchSurveyData} />
        </If>
      </Container>
    </Segment>
  );
};

const CommunitiesContextAwareUserCommunitySurveyData = (props) => {
  const { state } = useContext(CommunitiesContext);
  const { activeCommunity } = state;
  return <UserCommunitySurveyData communityId={activeCommunity.id} />;
};

export { CommunitiesContextAwareUserCommunitySurveyData };
export default UserCommunitySurveyData;
