import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { Divider, SubtitledCheckboxField } from 'components/lib';
import { DEFAULT_DEDICATED_NUMBER_ITEM } from '../constants';
import { usePhoneSystem } from '../PhoneSystemContext';
import { AreaCodeDisclaimerField } from './AreaCodeDisclaimerField';
import { WebSelectorField } from './WebSelectorField';
import { CampaignNumbersListField } from './CampaignNumbersListField';

const FormBlock = styled.div`
  margin: 20px 30px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--light-text);
  font-family: var(--font-family);
`;

const CampaignNumbersContainer = styled.div`
  display: flex;
  padding: 30px 0px 30px 30px;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  > .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  &.disabled {
    opacity: 0.3;
  }
`;

export const ActivatePhoneSystemForm = () => {
  const { t } = useTranslation();
  const { globalQuerySelector, selectedCommunity } = usePhoneSystem();
  const { setFieldValue, setFieldTouched, values } = useFormikContext();

  const onDedicatedNumbersCheck = () => {
    setFieldTouched('dedicatedNumbers', false, false);

    if (!values.dedicatedNumbersEnabled) {
      setFieldValue('dedicatedNumbers', [DEFAULT_DEDICATED_NUMBER_ITEM], false);
    } else {
      setFieldValue('dedicatedNumbers', [], false);
    }
  };

  return (
    <>
      <FormBlock>
        <AreaCodeDisclaimerField
          hasErrors={!!selectedCommunity.purchaseErrorStatus}
          hasPurchasedNumbers={selectedCommunity.hasPurchasedPhoneNumbers}
        />
      </FormBlock>
      <FormBlock>
        <SubtitledCheckboxField
          name="poolEnabled"
          title="Website phone numbers"
          subtitle={`Fetches five website numbers and a backup number, which replace the targeted selector in the HTML of the
          ${t(
            'community'
          )} website. These numbers are used to record and analyze phone calls, as well as enable use of the
         Conversational IVR web assistant, which converses with customers automatically.`}
        />
        <WebSelectorField
          disabled={!values.poolEnabled}
          globalQuerySelector={globalQuerySelector}
          dropdownFieldName="inheritQuerySelector"
          inputFieldName="querySelector"
        />
      </FormBlock>
      <Divider />
      <FormBlock>
        <SubtitledCheckboxField
          title="Campaign phone numbers"
          subtitle="Adds phone numbers to be used for Google or Facebook Ads, or similar. You can add a custom purpose, too."
          name="dedicatedNumbersEnabled"
          onChange={onDedicatedNumbersCheck}
        />
        <CampaignNumbersContainer className={!values.dedicatedNumbersEnabled ? 'disabled' : ''}>
          <CampaignNumbersListField startingIndex={0} enableItemRemoval={values.dedicatedNumbers.length > 1} />
        </CampaignNumbersContainer>
      </FormBlock>
    </>
  );
};
