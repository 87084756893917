import React from 'react';
import { isValid } from 'date-fns';
import styled from 'styled-components';
import { formatAnyDate } from 'components/utils';
import { If } from '../../../If';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.54px;
  text-align: center;
  color: rgba(25, 25, 25, 0.86);
`;

const Time = styled.div`
  display: inline-table;
  height: 30px;
  margin: 5px 0 0;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.75px;
  line-height: 30px;
  text-align: center;
  color: #1f57bd;
`;

const TimeWarning = styled.span`
  opacity: 0.8;
`;

export const TourDateTimeCard = ({ date, label, children }) => {
  const isDateValid = isValid(date);
  const tourDateTime = isDateValid ? formatAnyDate(date, "yyyy-MM-dd 'at' h:mm a") : null;

  return (
    <Wrapper>
      <Label>{label}</Label>
      <If condition={isDateValid}>
        <Time>{tourDateTime}</Time>
      </If>
      <If condition={!isDateValid}>
        <TimeWarning>Date and time not specified</TimeWarning>
      </If>
      {children}
    </Wrapper>
  );
};
