import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { formatRelative, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';

import { Icon } from 'components/lib';
import { If } from 'components/If';
import { UserCircle } from 'components/UserCircle';
import { splitName } from 'utils/helpers';
import { MESSAGE_STATUS } from 'components/conversation/constants';
import { MEDIA_BREAK_POINTS, LEAD_EVENT_TYPE } from '../../constants';

const Text = styled.span`
  font-family: var(--font-family);
  font-size: 13px;
  line-height: 18px;
  color: ${({ failed }) => (failed ? 'var(--salmon)' : 'var(--light-text)')};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 74vw;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    width: 244px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: solid 1px var(--line-standard);
  background-color: var(--white);
  padding: 22px 20px 22px 11px;
  position: relative;
  cursor: pointer;
  width: 100%;
  &.active {
    box-shadow: 0 3px 5px 0 #d8d8d8;
    z-index: 1;
    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      background-color: #fa7550;
    }
  }
  &.hasUnread {
    background-color: rgba(50, 82, 175, 0.06);
    ${Text} {
      color: var(--link);
      font-family: var(--font-family-bold);
    }
  }
  &:last-of-type {
    border-bottom: solid 1px var(--line-standard);
  }
`;

const Status = styled.div`
  display: flex;
  width: 12px;
  height: 7px;
  margin-right: 11px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const Name = styled.h4`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: 1.43;
  color: var(--text-color);
  margin: 0;
`;

const Time = styled.span`
  font-family: var(--font-family);
  font-size: 10px;
  line-height: 1.6;
  text-align: right;
  color: var(--light-text);
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Community = styled.h5`
  font-family: var(--font-family);
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
  margin: 0;
`;

export const ConversationsListItem = ({ active, conversation, onMessageSelect }) => {
  const { first_name, last_name } = splitName(conversation.name);
  const lead = {
    id: conversation.id,
    first_name,
    last_name,
    email: conversation.email,
  };
  const formatRelativeLocale = {
    // had to cary over the formatting from moment.js
    lastWeek: 'EEE h:mm a',
    yesterday: "'Yesterday' h:mm a",
    today: 'h:mm a',
    tomorrow: "'Tomorrow' h:mm a",
    nextWeek: 'EEE h:mm a',
    other: 'MMM d h:mm a',
  };

  const locale = {
    ...enUS,
    formatRelative: (token) => formatRelativeLocale[token],
  };

  const lastMessageAt = formatRelative(parseISO(conversation.last_message_at), new Date(), { locale });
  const lastMessageFailed = conversation.last_message_status === MESSAGE_STATUS.FAILED;
  const lastMessageText = lastMessageFailed ? `Not delivered: ${conversation.last_message}` : conversation.last_message;

  const classes = classNames({
    active,
    hasUnread: conversation.has_unread,
  });

  return (
    <Container onClick={() => onMessageSelect(conversation.id)} className={classes}>
      <Status>
        <If condition={conversation.has_unread}>
          <Icon name="fiber_manual_record" color="var(--vivid-blue)" size="10" />
        </If>
      </Status>
      <UserCircle user={lead} />
      <Content>
        <SpaceBetween>
          <Name>{conversation.name}</Name>
          {lastMessageAt && <Time>{lastMessageAt}</Time>}
        </SpaceBetween>
        <Community>{conversation.community_name}</Community>
        <Text failed={lastMessageFailed}>{lastMessageText}</Text>
      </Content>
    </Container>
  );
};
