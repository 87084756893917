import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CSVDownload } from 'assets/images/csv-file.svg';

import { Button, Dimmer, Icon, Loader, Modal } from 'components/lib';
import useDownloadAsCSV from 'utils/hooks/useDownloadAsCSV';

const StyledModelHeader = styled(Modal.Header)`
  &&& {
    display: flex;
    justify-content: space-between;
    border: none;
    font-family: var(--font-family-bold);
    padding: 25px 25px 10px 30px;

    .icon {
      color: var(--light-text);
      cursor: pointer;
    }
  }
`;

const StyledModelContent = styled(Modal.Content)`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--light-text);
    font-family: var(--font-family);
    font-weight: 500;

    padding: 0px 30px 35px 30px;

    div {
      margin-bottom: 16px;
    }

    .highlight {
      color: var(--text-color);
    }

    .fake-link {
      color: var(--link);
      cursor: pointer;
      user-select: none;
    }
  }
`;

const StyledModelActions = styled(Modal.Actions)`
  &&& {
    display: flex;
    justify-content: flex-end;
    background-color: white;
    border-top: 1px solid var(--line-standard);
    padding: 20px 30px;

    .ui.button {
      height: 40px;

      &.confirm {
        width: 160px;
      }

      &.cancel {
        color: var(--text-color);
        width: 120px;
      }
    }
  }
`;

export const CsvDownloadModal = ({
  open,
  onCloseModal,
  data,
  isLoading,
  headers,
  filename,
  exportAction,
  skipDownload,
}) => {
  const { exportAsCSV, exportProgressStatus } = useDownloadAsCSV();

  const onDownload = async () => {
    await exportAsCSV({
      exportAction,
      headers,
      data,
      filename,
      skipDownload,
    });

    onCloseModal();
  };

  const onCancel = () => {
    onCloseModal();
  };

  return (
    <Modal open={open} onClose={onCancel} size="tiny">
      <Dimmer active={isLoading} inverted>
        <Loader active size="large" transparent />
      </Dimmer>
      <StyledModelHeader>
        <span>Download Spreadsheet?</span>
        <Icon name="close" onClick={onCancel} />
      </StyledModelHeader>

      <StyledModelContent>
        <div>
          The data specified with your selected filters will be downloaded as a <span className="highlight">.csv</span>{' '}
          spreadsheet file to your computer.
        </div>
        <CSVDownload />
      </StyledModelContent>

      <StyledModelActions>
        <Button className="confirm primary blue" loading={exportProgressStatus} onClick={onDownload}>
          Download .csv
        </Button>
        <Button className="cancel raised" disabled={exportProgressStatus} onClick={onCancel}>
          Cancel
        </Button>
      </StyledModelActions>
    </Modal>
  );
};
