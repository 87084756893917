import React from 'react';

import { QuestionReviewsProvider } from './context';
import QuestionReviewsTabs from './QuestionReviewsTabs';

const QuestionReviews = () => (
  <QuestionReviewsProvider>
    <QuestionReviewsTabs />
  </QuestionReviewsProvider>
);

export default QuestionReviews;
