import React, { useContext, useEffect } from 'react';
import { Icon, Button, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { MEDIA_BREAK_POINTS } from '../../../constants';
import { If } from '../../If';
import { UserContext } from '../../UserContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 1rem;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    height: calc(100vh - 9rem);
  }

  ${Message} {
    .ui {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  ${Button} {
    a {
      width: 400px;
      background-color: red;
    }
  }
`;

export const AuthenticationSuccess = () => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      window?.analytics?.track('Calendar integration', {
        userId: user.id,
        email: user.email,
      });
    }
  }, [user]);

  return (
    <Wrapper>
      <Message success size="large">
        <Icon name="check circle outline" size="massive" fitted />
        <h3>Calendar authentication and integration completed successfully!</h3>
      </Message>
      <If condition={user}>
        <Button as={Link} to="/" content="Go to Dashboard" size="large" primary />
      </If>
    </Wrapper>
  );
};
