import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import { If } from 'components/If';
import DotsActionButton from './DotsActionButton';
import DeleteUserSubjectOverrideModal from './DeleteUserSubjectOverrideModal';
import { useEmailSubjects } from './EmailSubjectsContext';
import { DEFAULT_EMAIL_STATUS } from './constants';
import { STATUSES } from '../../../constants';
import { getDefaultEmailSubjectBySelectedType } from './utils';

const HeaderRow = styled(Grid.Row)`
  font-weight: 800;
`;

const ContentRow = styled(Grid.Row)`
  background: #f9fafb;
  margin-bottom: 5px;
`;

const CellSubItem = styled.span`
  font-size: 11px;
  font-weight: 800;
`;

const DefaultEmailSubjectsTable = ({ loaderStatus, currentClientId, userEmailSubjects, onEmailSubjectDelete }) => {
  const [selectedEmailSubject, setSelectedEmailSubject] = useState(null);
  const [restoreModalOpen, setRestoreModalOpen] = useState(false);
  const { defaultEmailSubjects, emailTypes } = useEmailSubjects();

  const defaultSubjects = useMemo(
    () =>
      defaultEmailSubjects.map((subject) => {
        const userSubjectOverride = userEmailSubjects.find((item) => item.type === subject.type);
        return userSubjectOverride
          ? {
              id: userSubjectOverride.id,
              text: userSubjectOverride.text,
              type: userSubjectOverride.type,
              status: DEFAULT_EMAIL_STATUS.CUSTOM,
            }
          : {
              id: null,
              text: subject.text,
              type: subject.type,
              status: DEFAULT_EMAIL_STATUS.SYSTEM_DEFAULT,
            };
      }),
    [defaultEmailSubjects, userEmailSubjects]
  );

  const setOpenModal = (userOverride) => () => {
    setSelectedEmailSubject(userOverride);
    setRestoreModalOpen(true);
  };

  const onConfirm = async () => {
    if (!selectedEmailSubject) return;

    await onEmailSubjectDelete(selectedEmailSubject);

    setSelectedEmailSubject(null);
    setRestoreModalOpen(false);
  };

  const onCloseModal = () => {
    setSelectedEmailSubject(null);
    setRestoreModalOpen(false);
  };

  return (
    <>
      <Grid stackable>
        <HeaderRow>
          <Grid.Column width={7}>Default Subject</Grid.Column>
          <Grid.Column width={3}>Type</Grid.Column>
          <Grid.Column width={4}>Community</Grid.Column>
          <Grid.Column />
        </HeaderRow>
        {defaultSubjects.map((subject) => (
          <ContentRow verticalAlign="middle" key={subject.type}>
            <Grid.Column width={7}>
              {currentClientId ? (
                <Link
                  to={{
                    pathname: `/admin/custom-email-subjects/${currentClientId}/edit/global`,
                    state: { selectedEmailSubject: subject },
                  }}
                >
                  {subject.text}
                </Link>
              ) : (
                <span>{subject.text}</span>
              )}
              <br />
              <CellSubItem>{subject.status}</CellSubItem>
            </Grid.Column>
            <Grid.Column width={3}>{emailTypes[subject.type]}</Grid.Column>
            <Grid.Column width={4}>Any Community</Grid.Column>
            <Grid.Column>
              <If condition={subject.status !== DEFAULT_EMAIL_STATUS.SYSTEM_DEFAULT}>
                <DotsActionButton onClick={setOpenModal(subject)} title="Reset To System Default" />
              </If>
            </Grid.Column>
          </ContentRow>
        ))}
      </Grid>
      <DeleteUserSubjectOverrideModal
        open={restoreModalOpen}
        loading={loaderStatus === STATUSES.LOADING}
        onClose={onCloseModal}
        onConfirm={onConfirm}
        emailType={emailTypes[selectedEmailSubject?.type]}
        defaultEmailSubjectText={getDefaultEmailSubjectBySelectedType(selectedEmailSubject, defaultEmailSubjects)}
      />
    </>
  );
};

export default DefaultEmailSubjectsTable;
