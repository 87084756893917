import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { formatPhoneNumber } from 'utils/helpers';
import { If } from 'components/If';
import { ConversationContext } from '../ConversationContext';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Container = styled.div`
  padding: 10px 20px;
  border-radius: 40px;
  box-shadow: 0 3px 5px 0 rgba(216, 216, 216, 0.85);
  background-color: var(--white);
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: min(95%, 335px);
  p {
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: var(--text);
    margin: 0;
    margin-left: 8px;
    span {
      margin-top: 2px;
    }
  }
`;

const Time = styled.span`
  font-family: var(--font-family);
  font-size: 10px;
  line-height: 1.6;
  padding-top: 3px;
  text-align: right;
  color: #89919d;
  flex: 1 0 auto;
`;

const ViewTranscriptLink = styled.span`
  color: var(--link);
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

const TranscriptSummary = styled.div`
  display: flex;
  border-radius: 20px;
  border: solid 1px #8eddc6;
  margin-top: -30px;
  z-index: 0;
  padding: 30px 20px 10px 20px;
  background-color: #e0f4ec;
  span.summary-title {
    margin-right: 5px;
  }
  span.ai-badge {
    width: 24px;
    height: 16px;
    padding: 0 6px;
    font-size: 12px;
    border-radius: 4px;
    margin-right: 5px;
    background-color: #ffe0ef;
    color: #ff5b98;
  }
  span.summary-content {
    margin-left: 15px;
    color: var(--emerald-green);
    ul {
      margin: 0;
      padding: 0;
    }
  }
`;

export const ForwardedCallEventMessage = ({ data }) => {
  const { created_on, additional_data } = data;
  const { forwardedCallFromEndTime, onViewTranscriptLinkClick } = useContext(ConversationContext);

  const forwardedCall = forwardedCallFromEndTime(created_on);

  const handleViewTranscript = () => onViewTranscriptLinkClick(forwardedCall);

  let calledNumber = formatPhoneNumber(additional_data?.called_number?.replace(/\+1/g, ''));

  if (calledNumber === 'Invalid phone number') {
    calledNumber = additional_data?.called_number;
  }

  let keyItemsFormatted = forwardedCall?.transcript_summary?.substring(0, 300);

  try {
    keyItemsFormatted = forwardedCall?.transcript_summary.split('\n\n')[0];
  } catch (e) {
    console.warn('Error parsing key items from transcript summary', e);
  }

  return (
    <>
      <Wrapper>
        <Container>
          <Icon name="phone_forwarded" size="24" color="var(--emerald-green)" />
          <p>
            <span>Forwarded to {calledNumber}</span>
            <If condition={forwardedCall}>
              <ViewTranscriptLink onClick={handleViewTranscript}>View Transcript</ViewTranscriptLink>
            </If>
          </p>
          <Time>{formatAnyDate(created_on, 'h:mm a')}</Time>
        </Container>
      </Wrapper>
      <If condition={forwardedCall?.transcript_summary}>
        <TranscriptSummary>
          <span className="summary-title">Summary</span>
          <span className="ai-badge">AI</span>
          <If condition={!!keyItemsFormatted}>
            <span className="summary-content">
              <ReactMarkdown>{keyItemsFormatted}</ReactMarkdown>
            </span>
          </If>
        </TranscriptSummary>
      </If>
    </>
  );
};
