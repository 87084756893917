import { Button, Dimmer, Loader, Sticky } from 'semantic-ui-react';
import React, { useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import SearchInput from 'components/SearchInput';
import { SettingsContext } from '../SettingsContext';
import { DiscountsForm } from './DiscountsForm';
import { STATUSES } from '../../../constants';
import { PageHeader } from '../../common';

const Wrapper = styled.div`
  position: relative;
  min-height: 60vh;
  padding: 17px 30px 0 30px;

  h4.ui.header {
    color: #666;
  }
`;

export const Discounts = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { communities, loaderStatus, updateCommunityDiscounts } = useContext(SettingsContext);

  const formRef = useRef();
  const wrapperRef = useRef();

  const onSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const onSave = async (values) => {
    try {
      // Conditional assigment ensures empty strings are sent as None values
      const pricing = values.prices.map((price) => ({
        id: price.id,
        cost: price.cost,
        discount_text: price.discount_text || null,
        discount_amount: price.discount_amount || null,
      }));

      setLoading(true);
      await updateCommunityDiscounts(pricing);
      toast.success('Discount options saved');
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error('Error updating discount options. Please try again later, or contact us for help.');
      setLoading(false);
    }
  };

  const communitiesList = useMemo(
    () =>
      !searchTerm || !communities
        ? communities
        : communities.filter((community) => community?.name?.toLowerCase().includes(searchTerm.toLowerCase())),
    [communities, searchTerm]
  );

  return (
    <Wrapper ref={wrapperRef}>
      <Sticky context={wrapperRef}>
        <PageHeader
          title="Discount Options"
          subtitle="Define discounts for available pricing options"
          block
          actions={
            <Button
              floated="right"
              primary
              type="submit"
              content="Save"
              onClick={onSubmit}
              loading={loading}
              disabled={loading}
            />
          }
        />
      </Sticky>

      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <SearchInput
        value={searchTerm}
        fluid
        onSearch={setSearchTerm}
        placeholder={`Search ${t('communities')} by name`}
        style={{ marginBottom: '1em' }}
      />

      <DiscountsForm communities={communitiesList} formRef={formRef} onSubmit={onSave} />
    </Wrapper>
  );
};
