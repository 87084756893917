import React from 'react';

import { BooleanSettingsPage } from '../common';

export const MinimizeVSA = () => {
  return (
    <BooleanSettingsPage
      flagName="minimize_chat_on_outside_click"
      modalText="Choose if the VSA is automatically minimized on outside tap on mobile devices."
      modalTitle="Minimize VSA when tapping outside on mobile"
      modalSaveText="Save and Publish"
      settingsDescription="Choose if the VSA is automatically minimized on outside tap on mobile devices."
    />
  );
};
