import React from 'react';
import { useField } from 'formik';
import { Form, Input } from 'semantic-ui-react';
import styled from 'styled-components';
import { ToggleSwitch } from 'components/common';

const Container = styled.div`
  display: inline;
  position: relative;
  width: 100%;
`;

const Error = styled.span`
  position: absolute;
  padding-top: 5px;
  font-size: 0.8571rem;
  color: var(--validation-red);
`;

export const InputField = ({ control, onError, onChanged, newError, hideError, validateOnKeyUp, ...props }) => {
  const [field, meta, { setValue, setTouched }] = useField(props);
  const { touched, error } = meta;
  const { onBlur, ...fieldProps } = field;
  const _control = control || (typeof fieldProps.value === 'boolean' ? ToggleSwitch : Input);

  const handleChange = (e, d) => {
    let value = e.target.value;
    if (d) {
      value = d.type === 'checkbox' ? d.checked : d.value;
    }
    setValue(value);

    if (typeof onChanged === 'function') {
      onChanged(value);
    }
  };

  const handleBlur = (e) => {
    if (!e.target.name) {
      e.target.name = props?.name;
    }
    onBlur(e);
  };

  const handleKeyUp = () => {
    validateOnKeyUp && setTouched(true);
  };

  if (error && touched) {
    if (typeof onError === 'function') {
      onError(field.name);
    }
  }

  const Element = (
    <Form.Field
      style={{ position: 'relative' }}
      control={_control}
      {...fieldProps}
      {...props}
      error={!hideError && !newError && touched && error}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
    />
  );

  if (!newError && !hideError) {
    return Element;
  }

  return (
    <Container>
      {Element}
      {!hideError && newError && touched && <Error>{error}</Error>}
    </Container>
  );
};
