import React from 'react';
import { useTranslation } from 'react-i18next';

import { BooleanSettingsPage } from '../common';

export const Faq = () => {
  const { t } = useTranslation();
  return (
    <BooleanSettingsPage
      flagName="show_lead_email_faq"
      modalText={`Choose whether to include FAQs at the bottom of ${t('lead')} emails.`}
      modalTitle="FAQs in emails"
    />
  );
};
