import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as FileDownload } from 'assets/images//file-download-black.svg';
import { ConversationsDownloadModal } from './ConversationsDownloadModal';

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 36px;
  box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
  border: solid 1px var(--line-standard);
  background-color: var(--white);
`;

export const ConversationsDownload = () => {
  const [open, setOpen] = useState(false);

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Wrapper onClick={() => setOpen(true)}>
        <FileDownload />
      </Wrapper>
      <ConversationsDownloadModal open={open} onCloseModal={onCloseModal} />
    </>
  );
};
