import styled from 'styled-components';

export const GrabHandleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 2rem;
  cursor: grab;

  i.icon {
    padding: 0;
    margin: 0;
    width: 0.3rem;
    line-height: 1.1;
    opacity: 0.1;
  }
  &:hover {
    i.icon {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
`;
