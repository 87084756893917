import React from 'react';
import styled from 'styled-components';

import { useUserContext } from 'components/UserContext';
import { toast } from 'react-toastify';
import { Loader, Toggle } from 'components/lib';
import { useContactCenter } from './ContactCenterContext';

const Container = styled.div`
  display: flex;
  margin-right: 30px;

  &&&& label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ContactCenterSession = () => {
  const { initLoading, isOnline, configureInboundCalls } = useContactCenter();
  const { isImpersonatedSession, user } = useUserContext();

  const toggleContactCenter = (e, { checked }) => {
    if (initLoading) {
      return;
    }

    try {
      configureInboundCalls(checked);
    } catch (e) {
      toast.error(`Failed to ${checked ? 'enable' : 'disable'} contact center`);
    }
  };

  if (isImpersonatedSession) {
    return null;
  }

  if (!user.is_contact_center_enabled) {
    return null;
  }

  return (
    <Container>
      {initLoading && <Loader size="tiny" transparent active />}
      <Toggle label="Contact Center" fitted checked={isOnline} onClick={toggleContactCenter} disabled={initLoading} />
    </Container>
  );
};

export default ContactCenterSession;
