import React from 'react';

import { InputField } from 'components/common/fields/InputField';
import { OperationSign, OperationBox } from './wrappers';

const UrlLocationRule = ({ baseFieldName, disabled }) => {
  return (
    <>
      <OperationBox>
        <OperationSign>contains</OperationSign>
        <div>
          <InputField name={`${baseFieldName}.keyword`} placeholder="Keyword Value" type="text" disabled={disabled} />
        </div>
      </OperationBox>
      <OperationBox>
        <OperationSign>then</OperationSign>
        <div>
          <InputField placeholder="Result Value" name={`${baseFieldName}.result_value`} disabled={disabled} />
        </div>
      </OperationBox>
    </>
  );
};

export default UrlLocationRule;
