import React from 'react';
import styled from 'styled-components';
import { Route, Switch, Link } from 'react-router-dom';
import classNames from 'classnames';

import { Icon } from 'components/lib';
import { If } from 'components/If';
import { WithTopBar } from 'components/WithTopBar';
import { MEDIA_BREAK_POINTS, USER_TYPES } from '../../constants';
import Main from './Main';
import LeadReturns from './LeadReturns';
import TourAvailability from './TourAvailability';
import { ItemProvider, useItemContext } from './Main/ItemContext';
import { SettingsProvider } from './context/SettingsContext';
import Faq from './Faq';
import MoveinLikelihood from './MoveinLikelihood';
import MessagingAssistant from './MessagingAssistant';
import CaptureWebforms from './CaptureWebforms';
import ConversionInsights from './ConversionInsights';
import SendNotificationEmails from './SendNotificationEmails';
import SendUnqualifiedLeads from './SendUnqualifiedLeads';
import ConnectedCalendars from './ConnectedCalendars';
import ParticipateInBenchmark from './ParticipateInBenchmark';
import AdditionalEmailNote from './AdditionalEmailNote';
import PhoneAssistantRoot from './PhoneAssistant';
import { InstantAnswers } from './InstantAnswers';
import WebAssistantRoot from './WebAssistant';
import { StaffRoute, UserRoute } from '../common/routes';
import MarketingEmailRoot from './MarketingEmail';
import CustomLeadQualification from './CustomLeadQualification';
import OutbandCallsRoot from './OutbandCalls';
import Amenities from './Amenities';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0;
  @media (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    padding-top: 30px;
  }
`;

const HeadWrapper = styled.div`
  padding: 0 30px;
`;

const BodyWrapper = styled.div`
  padding: 0 30px;
  &.no-padding {
    padding: 0;
  }
`;

const SettingsTitle = styled.h3`
  font-family: var(--font-family-bold);
  font-size: 60px;
  line-height: 0.97;
  color: var(--text);
  && {
    margin-bottom: 30px;
  }
`;

const BreadCrumbs = styled.div`
  font-family: var(--font-family-bold);
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #89919d;
  text-transform: uppercase;
`;

const SectionTitle = styled.div`
  display: flex;
  margin-top: 14px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: ${(props) => props?.iconWrapperBgColor || 'var(--white)'};
  box-shadow: ${(props) => props?.iconWrapperBoxShadow || 'unset'};
  background-image: ${(props) => props?.iconWrapperBgImage || 'unset'};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  > span {
    transform: ${(props) => props.iconRotate || 'none'};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const Title = styled.h3`
  margin: 0;
  font-family: var(--font-family-bold);
  font-size: 40px;
  color: var(--text);
  text-transform: capitalize;
`;

const SubTitle = styled.h4`
  margin: 0;
  font-family: var(--font-family);
  font-size: 14px;
  color: #89919d;
`;

const Component = () => {
  const { selectedItem } = useItemContext();
  const bodyClassNames = classNames({ 'no-padding': selectedItem?.noBodyPadding });
  return (
    <WithTopBar bgColor={selectedItem ? '#ffffff' : '#f6f7fa'}>
      <Wrapper>
        <HeadWrapper>
          <If condition={!selectedItem}>
            <SettingsTitle>Settings</SettingsTitle>
          </If>
          <If condition={selectedItem}>
            <BreadCrumbs>
              <Link to="/advanced">Settings</Link> &#183; <span>{selectedItem?.sectionTitle}</span>
            </BreadCrumbs>
            <SectionTitle>
              <IconWrapper {...selectedItem}>
                <Icon
                  name={selectedItem?.iconName}
                  color={selectedItem?.iconColor}
                  variant={selectedItem?.iconVariant}
                />
              </IconWrapper>
              <TitleWrapper>
                <Title>{selectedItem?.title}</Title>
                <If condition={!selectedItem?.hideSubtitle}>
                  <SubTitle>{selectedItem?.subTitle}</SubTitle>
                </If>
              </TitleWrapper>
            </SectionTitle>
          </If>
        </HeadWrapper>
        <BodyWrapper className={bodyClassNames}>
          <Switch>
            <Route exact path="/advanced">
              <Main />
            </Route>
            <UserRoute exact path="/advanced/leads-return" allowedTypes={[USER_TYPES.REGULAR]}>
              <LeadReturns />
            </UserRoute>
            <UserRoute exact path="/advanced/tour-availability" allowedTypes={[USER_TYPES.REGULAR]}>
              <TourAvailability />
            </UserRoute>
            <UserRoute exact path="/advanced/faq" allowedTypes={[USER_TYPES.REGULAR]}>
              <Faq />
            </UserRoute>
            <UserRoute exact path="/advanced/movein-likelihood" allowedTypes={[USER_TYPES.REGULAR]}>
              <MoveinLikelihood />
            </UserRoute>
            <UserRoute path="/advanced/messaging-assistant" allowedTypes={[USER_TYPES.REGULAR]}>
              <MessagingAssistant />
            </UserRoute>
            <StaffRoute path="/advanced/instant-answers">
              <InstantAnswers />
            </StaffRoute>
            <UserRoute exact path="/advanced/capture-webforms" allowedTypes={[USER_TYPES.REGULAR]}>
              <CaptureWebforms />
            </UserRoute>
            <UserRoute exact path="/advanced/conversion-insights" allowedTypes={[USER_TYPES.REGULAR]}>
              <ConversionInsights />
            </UserRoute>
            <UserRoute exact path="/advanced/send-notification-emails" allowedTypes={[USER_TYPES.REGULAR]}>
              <SendNotificationEmails />
            </UserRoute>
            <UserRoute exact path="/advanced/send-unqualified-leads" allowedTypes={[USER_TYPES.REGULAR]}>
              <SendUnqualifiedLeads />
            </UserRoute>
            <UserRoute exact path="/advanced/connected-calendars" allowedTypes={[USER_TYPES.REGULAR]}>
              <ConnectedCalendars />
            </UserRoute>
            <UserRoute exact path="/advanced/unqualified-lead-filter" allowedTypes={[USER_TYPES.REGULAR]}>
              <CustomLeadQualification />
            </UserRoute>
            <UserRoute exact path="/advanced/participate-in-benchmark" allowedTypes={[USER_TYPES.REGULAR]}>
              <ParticipateInBenchmark />
            </UserRoute>
            <UserRoute exact path="/advanced/additional-email-note" allowedTypes={[USER_TYPES.REGULAR]}>
              <AdditionalEmailNote />
            </UserRoute>
            <UserRoute path="/advanced/phone-assistant" allowedTypes={[USER_TYPES.STAFF, USER_TYPES.REGULAR]}>
              <PhoneAssistantRoot />
            </UserRoute>
            <UserRoute path="/advanced/web-assistant" allowedTypes={[USER_TYPES.STAFF, USER_TYPES.REGULAR]}>
              <WebAssistantRoot />
            </UserRoute>
            <UserRoute path="/advanced/marketing-email" allowedTypes={[USER_TYPES.REGULAR]}>
              <MarketingEmailRoot />
            </UserRoute>
            <UserRoute path="/advanced/outbound-calls" allowedTypes={[USER_TYPES.STAFF, USER_TYPES.REGULAR]}>
              <OutbandCallsRoot />
            </UserRoute>
            <UserRoute path="/advanced/amenities" allowedTypes={[USER_TYPES.STAFF, USER_TYPES.REGULAR]}>
              <Amenities />
            </UserRoute>
          </Switch>
        </BodyWrapper>
      </Wrapper>
    </WithTopBar>
  );
};

export const Advanced = () => (
  <SettingsProvider>
    <ItemProvider>
      <Component />
    </ItemProvider>
  </SettingsProvider>
);
