import React from 'react';

import BusinessActivityValueChange from './ValueChange';

export const BooleanValueChange = ({ value, positiveTitle = 'ON', negativeTitle = 'OFF' }) => {
  const color = value ? 'green' : 'orange';
  const title = value ? positiveTitle : negativeTitle;

  return <BusinessActivityValueChange title={title} color={color} />;
};
