import React, { useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Form } from 'components/lib';
import { validateLoginField } from '../utils';
import { AuthButton } from '../AuthButton';
import { PasswordInput } from '../PasswordInput';

const VALIDATION_SCHEMA = Yup.object({
  password: Yup.string().min(8, 'Enter a minimum of 8 characters'),
});

const ResetPasswordForm = ({ loading, onSubmit }) => {
  const passwordFieldRef = useRef();

  const initialValues = {
    password: '',
  };

  const submit = async ({ password }, { setFieldError }) => {
    const isValid = await validateLoginField({ password }, VALIDATION_SCHEMA, setFieldError);
    if (!isValid) {
      passwordFieldRef?.current?.focus();
      return;
    }

    setFieldError('password', null);
    onSubmit(password);
  };

  return (
    <Formik initialValues={initialValues} validateOnBlur={false} validateOnChange={false} onSubmit={submit}>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <PasswordInput ref={passwordFieldRef} autoFocus label="New Password" />
          <AuthButton label="Create Password" loading={loading} disabled={loading} />
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
