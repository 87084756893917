import React, { useRef, useState } from 'react';
import { FieldArray, Field, getIn } from 'formik';
import { useDrag, useDrop } from 'react-dnd';
import { Button, Form, Icon, Label, List, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { IA_TYPES } from '../../../constants';
import { InputField } from '../../common/fields/InputField';
import { createContextMenuFromEvent, humanizeString, scrollIntoView } from '../../utils';

const ErrorMessage = ({ name }) => (
  <Field name={name}>
    {({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? error : null;
    }}
  </Field>
);

const TrashIcon = styled(Icon)`
  &.icon {
    margin-left: auto;
    display: none;
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.2s ease;
    &:hover {
      transition: all 0.2s ease;
      opacity: 1;
    }
  }
`;

const MoveSegment = styled.div`
  background: #fff;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
  margin: 1rem 0;
  padding: 1em 1em;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  cursor: move;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    h4 {
      ${TrashIcon} {
        display: inline-block;
      }
    }
  }
`;

const AttributeHeader = styled.h4`
  display: flex;
`;

const DragHandle = styled.div`
  display: inline-flex;
  margin-right: 1rem;

  .icon {
    margin: 0;
    width: 0.5em;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;

  .field {
    width: 100%;
  }

  &:hover {
    ${TrashIcon} {
      margin-left: 1em;
      margin-bottom: 1em;
      display: inline-block;
    }
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddListItem = styled(Button)`
  align-self: flex-end;
`;

export const Answer = ({ id, index, answer, moveCard, onRemoveItem, onSelectPlaceholder, values, placeholders }) => {
  const ref = useRef();
  const contextMenuRef = useRef();
  const [openPlaceholderOptions, setOpenPlaceholderOptions] = useState(false);
  const [currentInputName, setCurrentInputName] = useState();

  const [{ handlerId }, drop] = useDrop({
    accept: 'Card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'Card' },
    begin: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const opacity = isDragging ? 0 : 1;
  const isListEmpty = answer.type === IA_TYPES.LIST && values[answer.id].length === 0;

  const setInputPlaceholder = (inputName, data) => {
    const el = document.getElementsByName(inputName)[0];
    const [start, end] = [el.selectionStart, el.selectionEnd];

    el.setRangeText(data.content, start, end);
    el.focus();
    // Set cursor right after the placeholder
    el.setSelectionRange(el.selectionStart + data.content.length, el.selectionStart + data.content.length);

    onSelectPlaceholder(inputName, el.value);
  };

  const handlePlaceholderClick = (e, data) => {
    e.preventDefault();
    setInputPlaceholder(currentInputName, data);
    setOpenPlaceholderOptions(false);
    setCurrentInputName(null);
  };

  const handlePopupOpen = (e, inputName) => {
    e.preventDefault();
    contextMenuRef.current = createContextMenuFromEvent(e);
    setCurrentInputName(inputName);
    setOpenPlaceholderOptions(true);
  };

  const handlePopupClose = () => {
    setOpenPlaceholderOptions(false);
    setCurrentInputName(null);
  };

  return (
    <MoveSegment ref={preview} style={{ opacity }} data-handler-id={handlerId}>
      <AttributeHeader ref={ref}>
        <DragHandle>
          <Icon name="ellipsis vertical" />
          <Icon name="ellipsis vertical" />
        </DragHandle>
        <Icon name={answer.type === IA_TYPES.TEXT ? 'align left' : 'ordered list'} />
        {humanizeString(answer.type)}
        <TrashIcon color="red" name="trash alternate outline" onClick={() => onRemoveItem(answer)} />
      </AttributeHeader>
      {answer.type === IA_TYPES.TEXT ? (
        <Form.Field key={answer.id}>
          <InputField
            name={answer.id}
            onError={scrollIntoView}
            onContextMenu={(e) => handlePopupOpen(e, answer.id)}
            icon={<Icon name="code" circular link onClick={(e) => handlePopupOpen(e, answer.id)} />}
          />
        </Form.Field>
      ) : (
        <FieldArray
          name={`${answer.id}`}
          render={(arrayHelpers) => (
            <ListContainer>
              {values[answer.id]?.map((item, index) => (
                <ListItem key={index}>
                  <InputField
                    name={`${answer.id}.${index}`}
                    onError={scrollIntoView}
                    onContextMenu={(e) => handlePopupOpen(e, `${answer.id}.${index}`)}
                    icon={
                      <Icon name="code" circular link onClick={(e) => handlePopupOpen(e, `${answer.id}.${index}`)} />
                    }
                  />
                  <TrashIcon color="red" name="trash alternate outline" onClick={() => arrayHelpers.remove(index)} />
                </ListItem>
              ))}

              {isListEmpty && (
                <Form.Field>
                  <Label prompt>List can't be empty. Please add items or remove this list.</Label>
                </Form.Field>
              )}
              <AddListItem positive icon labelPosition="left" type="button" onClick={() => arrayHelpers.push('')}>
                <Icon name="add" />
                Add List Item
              </AddListItem>
              <ErrorMessage name={`${answer.id}.${index}`} />
            </ListContainer>
          )}
        />
      )}
      {placeholders && placeholders.length !== 0 && (
        <Popup basic context={contextMenuRef} onClose={handlePopupClose} open={openPlaceholderOptions}>
          <List divided selection items={placeholders.map((p) => p.name)} onItemClick={handlePlaceholderClick} />
        </Popup>
      )}
    </MoveSegment>
  );
};
