import React, { memo } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const Image = styled.div`
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 0.25rem 0.5rem;
  position: relative;
  cursor: move;

  &:hover {
    i {
      display: block;
    }
  }

  i {
    position: absolute;
    right: 5px;
    top: 5px;
    display: none;
  }
  img {
    height: auto;
    width: auto;
    max-height: 150px;
    max-width: 200px;
    border-radius: 5px;
  }
`;

export const DraggableImage = memo(({ item, onRemove }) => (
  <Image>
    <img src={item.img} alt={item.img} />
    <Icon name="trash alternate" inverted circular link color="red" onClick={onRemove} />
  </Image>
));
