import humps from 'humps';
import { API } from '../api';

export const getCRMTypes = async () => {
  const response = await API.get(`/api/crm/types`);
  return response.data;
};

export const getCRMLocationCodeRules = async () => {
  const response = await API.get(`/api/crm/location-code-rules`);
  return response.data;
};

export const getCRMGlobalSettings = async () => {
  const response = await API.get(`/api/crm/me`);
  return response.data;
};

export const updateCRMGlobalSettings = async (data) => {
  const response = await API.patch(`/api/crm/me`, data);
  return response.data;
};

export const getCRMSettings = async (userId) => {
  const response = await API.get(`/api/crm/${userId}/settings`);
  return response.data;
};

export const createCRMSettings = async (userId, data) => {
  const response = await API.post(`/api/crm/${userId}/settings`, data);
  return response.data;
};

export const updateCRMSettings = async (userId, crmSettingsId, data) => {
  const response = await API.put(`/api/crm/${userId}/settings/${crmSettingsId}`, data);
  return response.data;
};

export const removeCRMSettings = async (userId, crmSettingsId) => {
  const response = await API.delete(`/api/crm/${userId}/settings/${crmSettingsId}`);
  return response.data;
};

export const getCRMSettingsDetails = async (userId, id) => {
  const response = await API.get(`/api/crm/${userId}/settings/${id}`);
  return response.data;
};

export const getCRMCommunities = async (userId, params) => {
  const response = await API.get(`/api/crm/${userId}/communities`, { params });
  return response.data;
};

export const updateCRMCommunity = async (userId, communityId, data) => {
  const response = await API.put(`/api/crm/${userId}/communities/${communityId}`, data);
  return response.data;
};

export const deleteCommunityCRMSettings = async (userId, communityId) => {
  const response = await API.delete(`/api/crm/${userId}/communities/${communityId}`);
  return response.data;
};

export const sendTestLead = async (userId, communityId, data) => {
  const response = await API.post(`/api/crm/${userId}/communities/${communityId}/send-test-lead`, data);
  return response.data;
};

export const getCRMCommunitiesInvalidSettings = async (userId) => {
  const response = await API.get(`/api/crm/${userId}/communities-invalid-settings`);
  return response.data;
};

export const getCRMCommunity = async (userId, communityId) => {
  const response = await API.get(`/api/crm/${userId}/communities/${communityId}`);
  return response.data;
};

export const getCRMLogsLeads = async (params) => {
  const snakeCaseParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/crm/logs/leads', { params: snakeCaseParams });
  return humps.camelizeKeys(response.data);
};

export const getCRMLogsLeadsDetails = async (leadId) => {
  const response = await API.get(`/api/crm/logs/leads/${leadId}`);
  return humps.camelizeKeys(response.data);
};
export const getCRMLogsToursDetails = async (toursId) => {
  const response = await API.get(`/api/crm/logs/tours/${toursId}`);
  return humps.camelizeKeys(response.data);
};

export const getCRMLogsTours = async (params) => {
  const snakeCaseParams = humps.decamelizeKeys(params);
  const response = await API.get(`/api/crm/logs/tours`, { params: snakeCaseParams });
  return humps.camelizeKeys(response.data);
};

export const getCRMLogsLeadsStatistics = async (params) => {
  const snakeCaseParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/crm/logs/leads/statistics', { params: snakeCaseParams });
  return humps.camelizeKeys(response.data);
};

export const getCRMLogsToursStatistics = async (params) => {
  const snakeCaseParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/crm/logs/tours/statistics', { params: snakeCaseParams });
  return humps.camelizeKeys(response.data);
};
export const getCRMTypesSearch = async () => {
  const response = await API.get(`/api/crm/types/search`);
  return humps.camelizeKeys(response.data);
};
