import React from 'react';
import { Tab } from 'semantic-ui-react';

import { useAnalytics } from '../context/AnalyticsContext';
import TrafficByKeyword from './TrafficByKeyword';

const KeywordsTab = () => {
  const [{ filters, publicId }] = useAnalytics();

  return (
    <Tab.Pane>
      <TrafficByKeyword filters={filters} publicId={publicId} />
    </Tab.Pane>
  );
};

export default KeywordsTab;
