import React, { useState } from 'react';
import styled from 'styled-components';

import { If } from 'components/If';

const OptionsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
`;

const OptionBox = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 14px 20px;
  border-radius: 4px;
  border: solid 1px ${(props) => (props.selected ? '#275aff' : '#89919d')};
  background-color: ${(props) => (props.selected ? '#eef4fe' : '#fff')};
  cursor: pointer;
`;

const RadioBox = styled.div`
  display: flex;
  margin-top: 7.5px;
  margin-right: 20px;
`;

const LabelBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
`;

const LabelTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--text);
  text-transform: capitalize;
`;

const LabelSubtitle = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--link);
`;

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  align-self: center;
`;

const RadioButton = styled.label`
  input[type='radio'] {
    appearance: none;
    margin: 0;
    border: solid 1px #797979;
    background-color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    /* Windows High Contrast Mode */
    background-color: #fff;
  }

  input[type='radio']:checked {
    background: #0f71fd;
    border: none;
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
`;

export const OptionsList = ({ options, selectedOnMount = '', onChange }) => {
  const [selected, setSelected] = useState(selectedOnMount);

  const handleChange = (value) => {
    onChange(value);
    setSelected(value);
  };

  return (
    <OptionsListContainer>
      {options.map((option) => (
        <OptionBox key={option.id} selected={selected === option.value} onClick={() => handleChange(option.value)}>
          <RadioBox>
            <RadioButton>
              <input
                type="radio"
                name="radio"
                checked={selected === option.value}
                onChange={() => handleChange(option.value)}
              />
            </RadioButton>
          </RadioBox>
          <LabelBox>
            <LabelTitle>{option.title}</LabelTitle>
            <LabelSubtitle>{option.description}</LabelSubtitle>
          </LabelBox>
          {option.Icon && (
            <IconBox>
              <option.Icon fill={selected === option?.value ? '#275AFF' : '#eaeaeb'} />
            </IconBox>
          )}
        </OptionBox>
      ))}
    </OptionsListContainer>
  );
};
