import React from 'react';
import styled from 'styled-components';

import { NextArrow, FloorPlanIcon } from './icons';
import { formatCurrency } from '../../utils';

export class Pricing extends React.Component {
  constructor() {
    super();
    this.state = {
      isAmenitiesOpen: false,
      initialAmenitiesNumber: 5,
      overallMaxAmenitiesNumber: 8,
    };
  }

  renderFloorPlanCard = () => {
    const { floorPlans, floorPlanModule, floorPlanUrl } = this.props;
    if (floorPlans && floorPlanModule) {
      return <PricingCard img={floorPlans[0].image} text="View Floor Plans" onClick={this.goToFloorplans} />;
    }
    if (floorPlanUrl !== null && floorPlanUrl !== '') {
      return <PricingIconCard fillColor="#2185d0" text="View Floor Plans" onClick={this.openFloorPlanUrl} />;
    }
  };

  renderGalleryCard = () => {
    const { galleryImages, galleryModule } = this.props;
    return galleryImages && galleryImages.length && galleryModule ? (
      <PricingCard img={galleryImages[0]} text="View Photos" onClick={this.goToGallery} />
    ) : null;
  };

  renderPricing = () => {
    const { detailedPricing, startingCost, pricingType, chosenCareType, shouldFilterPricing } = this.props;
    const formattedPrice = formatCurrency(startingCost);

    if (!startingCost && !detailedPricing) {
      return <div className="top">Pricing is not currently available</div>;
    }

    if (!detailedPricing || !detailedPricing.length) {
      return (
        <div className="top">
          Starting from {formattedPrice}
          <span>{pricingType}</span>
        </div>
      );
    }

    return (
      <>
        {detailedPricing.map((pr, i) => {
          if (shouldFilterPricing && chosenCareType && chosenCareType.toLowerCase() !== pr.name.toLowerCase()) {
            return null;
          }
          return <DetailedPricingRow key={i} cost={pr.cost} name={pr.name} />;
        })}
      </>
    );
  };

  renderAmenities = () => {
    const { amenities, detailedPricing, pricingServicesText, text } = this.props;
    const { isAmenitiesOpen, initialAmenitiesNumber, overallMaxAmenitiesNumber } = this.state;

    if (!amenities || amenities.length < 1) {
      return (
        <div className="empty">
          <p>{text}</p>
        </div>
      );
    }
    let amenitiesToRender = amenities.slice(0, overallMaxAmenitiesNumber);
    let showMore = false;
    let className = 'more';
    const showDetailedPricing = detailedPricing && detailedPricing.length > 0;
    if (showDetailedPricing) {
      className += ' left';
    }

    if (amenities.length > initialAmenitiesNumber && !isAmenitiesOpen) {
      // We will show up to 5 amenities if there is no detailed pricing, but if there is detailed pricing, we only show 3.
      const amenitySliceNumber = showDetailedPricing ? 3 : initialAmenitiesNumber;
      amenitiesToRender = amenities.slice(0, amenitySliceNumber);
      showMore = true;
    }
    return (
      <>
        <p>{pricingServicesText}:</p>

        <ul>
          {amenitiesToRender.map((am, i) => (
            <li key={i} className="amenity">
              {am}
            </li>
          ))}
        </ul>
        {showMore ? (
          <button className={className} type="button" onClick={this.openAmenities}>
            More
          </button>
        ) : null}
      </>
    );
  };

  render() {
    // We should only show previews for the gallery and floor plans if those modules exist, and they are in the config. Probably a way to simplify this.
    return (
      <PricingWrapper>
        <PricingHeader>
          {this.renderPricing()}
          {this.renderAmenities()}
        </PricingHeader>
        <PricingBasement>
          {this.renderFloorPlanCard()}
          {this.renderGalleryCard()}
        </PricingBasement>
      </PricingWrapper>
    );
  }
}

const DetailedPricing = styled.div`
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  display: flex;

  div.name {
    color: #141233;
    font-size: 14px;
    font-weight: normal;
  }

  span {
    font-size: 12px;
    color: rgba(20, 18, 51, 0.6);

    b {
      color: #2185d0;
    }
  }
`;

const PricingHeader = styled.div`
  position: relative;

  div.top {
    color: ${(props) => props.theme.color};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    padding: 10px 15px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    span {
      border: 2px solid rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 4px 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 19px;
      color: rgba(20, 18, 51, 0.4);
    }
  }

  div.empty {
    padding: 15px 0;
  }

  p {
    font-size: 13px;
    padding: 0 15px;
    color: #141233;
    margin: 8px 0;
  }

  button.more {
    font-size: 14px;
    text-align: center;
    color: #2f80ed;
    background: rgba(${(props) => props.theme.brandColorRGB}, 0.1);
    border: 0;
    border-radius: 4px;
    padding: 8px 18px;
    margin-top: 3px;
    margin-left: 15px;
    cursor: pointer;

    &:hover {
      background: rgba(${(props) => props.theme.brandColorRGB}, 0.3);
    }
  }

  button.more.left {
    position: absolute;
    right: 15px;
    bottom: 0;
    padding: 3px 8px;
  }

  ul {
    margin: 0 0 5px;
    list-style-type: none;
    padding-left: 16px;

    li {
      font-weight: 400;
      font-size: 12px;
      color: #222;
      margin-bottom: 0px;
      line-height: 24px;

      &:before {
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        content: '•';
        color: rgba(20, 18, 51, 0.2);
      }
    }
  }
`;

const PricingCardDiv = styled.div`
  border-radius: 6px;
  width: 50%;
  height: 130px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 5px 0 5px 5px;

  &:last-child {
    padding-right: 5px;
  }

  .image {
    background-size: 100%;
    background-position: center center;
    height: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .icon {
    height: 100%;
    border-top-left-radius: 6px;
    padding: 10px;
    border-top-right-radius: 6px;
    text-align: center;
    font-size: 30px;
    color: #2185d0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
    border-bottom: 0;
  }

  .text {
    font-style: normal;
    font-size: 14px;
    color: #2185d0;
    background-color: white;
    line-height: 34px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid #f0f0f0;

    svg {
      margin-bottom: 1px;
    }
  }

  &:hover {
    .text {
      color: #fff;
      background: #2185d0;
      border: 1px solid #2185d0;
    }
  }
`;

export const DetailedPricingRow = (props) => {
  const { name, cost } = props;
  const formattedCost = formatCurrency(cost);
  return (
    <DetailedPricing>
      <div className="name">{name}</div>
      <span>
        starting from <b>{formattedCost}</b>
      </span>
    </DetailedPricing>
  );
};

export const PricingCard = (props) => {
  const { img, text, onClick } = props;
  return (
    <PricingCardDiv img={img} onClick={onClick}>
      <div className="image" style={{ backgroundImage: `url(${img})` }} />
      <div className="text">
        <span>{text}</span>{' '}
        <span>
          <NextArrow />
        </span>
      </div>
    </PricingCardDiv>
  );
};

export const PricingIconCard = (props) => {
  const { text, onClick, fillColor } = props;
  return (
    <PricingCardDiv onClick={onClick} fillColor={fillColor}>
      <div className="icon">
        <FloorPlanIcon fillColor={fillColor} />
      </div>
      <div className="text">
        <span>{text}</span>{' '}
        <span>
          <NextArrow />
        </span>
      </div>
    </PricingCardDiv>
  );
};

const PricingBasement = styled.div`
  border-top: 1px solid #f0f0f0;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const PricingWrapper = styled.div`
  box-shadow: 0px 2px 9px rgba(217, 217, 217, 0.5);
  border-radius: 11px;
  margin-bottom: 10px;
`;
