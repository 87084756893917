import React, { useState } from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { parseErrorResponse, parseFieldErrors } from 'apis/utils';
import { createManagedUser } from 'apis';
import { STATUSES } from '../../../constants';
import UserAccountForm from './UserAccountForm';

const UserAccountCreate = () => {
  const history = useHistory();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const handleCreateUserAccount = async (values, { setErrors }) => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      const { id } = await createManagedUser(values);

      toast.success('User created successfully');
      setLoaderStatus(STATUSES.LOADED);

      history.push(`/settings/user-accounts/${id}`);
    } catch (e) {
      setErrors(parseFieldErrors(e));
      toast.error(parseErrorResponse(e, 'Unable to create user.'));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <Segment basic>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <UserAccountForm loading={loaderStatus === STATUSES.LOADING} onSubmit={handleCreateUserAccount} />
    </Segment>
  );
};

export default UserAccountCreate;
