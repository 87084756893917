import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { WithTopBar, WITH_TOP_BAR_ID } from 'components/WithTopBar';
import CleanInboxImage from 'assets/images/clean-inbox.jpeg';
import ZeroInboxImage from 'assets/images/zero-inbox.png';
import { Button } from 'components/lib';
import { useHistory } from 'react-router';
import { MEDIA_BREAK_POINTS } from '../../constants';
import MessagesNavigation from './MessagesNavigation';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${CleanInboxImage});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;

  & #${WITH_TOP_BAR_ID} {
    background: transparent;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 100px;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    align-items: center;
    padding: 0 10px;
  }
`;

const Message = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 25px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(106deg, rgba(255, 255, 255, 0.4) 27%, rgba(255, 255, 255, 0.24) 75%);
  max-width: 560px;
  gap: 25px;
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  max-width: 225px;

  & .title {
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  & .description {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;

    & a {
      color: var(--white);
      text-decoration: underline;
    }
  }
`;

const NavigationBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const MessagesEmpty = () => {
  const history = useHistory();

  const goToArchivedConversations = () => {
    history.push('/messages/archived');
  };

  return (
    <Container>
      <WithTopBar variant="light" contentClasses={classNames('contained')}>
        <NavigationBox>
          <MessagesNavigation borderless />
        </NavigationBox>
        <MessageContainer>
          <Message>
            <ImageBox>
              <img src={ZeroInboxImage} alt="zero_inbox_image" width="79px" height="80px" />
            </ImageBox>
            <MessageContent>
              <div className="title">All done. Good job!</div>
              <div className="description">We will notify you of any new messages, replies and reminders.</div>
            </MessageContent>
            <div>
              <Button content="View Archived" basic inverted onClick={goToArchivedConversations} />
            </div>
          </Message>
        </MessageContainer>
      </WithTopBar>
    </Container>
  );
};

export default MessagesEmpty;
