import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

const StickyHeader = styled(Table.HeaderCell)`
  position: sticky;
  top: 0;
  z-index: 1;

  &.unsortable {
    cursor: not-allowed !important;
  }

  &.wrappable {
    white-space: normal !important;
  }
`;

export default StickyHeader;
