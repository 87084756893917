import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { NavBar } from 'components/navigation';
import { MEDIA_BREAK_POINTS, SSO_PAGE } from '../constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  height: calc(100vh - 10.42rem);
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    height: calc(100vh - 4.42rem);
  }
`;

const TextCenter = styled.div`
  text-align: center;
`;

export const SsoUnauthorized = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const error = urlSearchParams.has('error');

  const messageHeader = error ? SSO_PAGE.UNEXPECTED_ERROR_HEADER : SSO_PAGE.UNAUTHORIZED_HEADER;
  const message = error ? SSO_PAGE.UNEXPECTED_ERROR_MESSAGE : SSO_PAGE.UNAUTHORIZED_MESSAGE;

  return (
    <div>
      <NavBar />
      <Wrapper>
        <Icon size="massive" name="shield alternate" color="teal" />
        <Message warning>
          <TextCenter>
            <Message.Content>
              <Message.Header>{messageHeader}</Message.Header>
              {message}
            </Message.Content>
          </TextCenter>
        </Message>
      </Wrapper>
    </div>
  );
};
