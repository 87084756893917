import { API } from '../api';

export const getInstantResponses = async (params) => {
  const response = await API.get('/api/conversations/instant-responses', { params });
  return response.data;
};

export const getInstantResponsesLastMessage = async (params) => {
  const response = await API.get('/api/conversations/instant-responses/last-message', { params });
  return response.data;
};

export const getInstantResponsesStatistics = async (period) => {
  const response = await API.get(`/api/conversations/instant-responses/statistics/${period}`);
  return response.data;
};

export const getInstantResponsesMetrics = async (params) => {
  const response = await API.get('/api/conversations/instant-responses/metrics', { params });
  return response.data;
};

export const getOverviewMetrics = async (params) => {
  const response = await API.get('/api/conversations/instant-responses/overview', { params });
  return response.data;
};

export const getCommunityMetrics = async (params) => {
  const response = await API.get('/api/conversations/instant-responses/community-metrics', { params });
  return response.data;
};

export const getInstantResponsesUsers = async () => {
  const response = await API.get('/api/conversations/instant-responses/users');
  return response.data;
};

export const getInstantResponsesCommunities = async () => {
  const response = await API.get('/api/conversations/instant-responses/communities');
  return response.data;
};

export const getLeadInfo = async (lead_id) => {
  const response = await API.get(`/api/conversations/instant-responses/leads/${lead_id}`);
  return response.data;
};

export const getLeadConversation = async (lead_id, type) => {
  const response = await API.get(`/api/conversations/instant-responses/leads/${lead_id}/${type}`);
  return response.data;
};

export const getLeadAutomatedAnswer = async (lead_id, type) => {
  const response = await API.get(`/api/conversations/instant-responses/leads/${lead_id}/${type}/automated-answer`);
  return response.data;
};

export const getAutomatedAnswerDebug = async (lead_id, type, message_id) => {
  const response = await API.get(
    `/api/conversations/instant-responses/leads/${lead_id}/${type}/${message_id}/automated-answer-debug`
  );
  return response.data;
};

export const getAutomatedAnswerReplay = async (lead_id, type, message_id) => {
  const response = await API.get(
    `/api/conversations/instant-responses/leads/${lead_id}/${type}/${message_id}/automated-answer-replay`
  );
  return response.data;
};

export const getAnswerLabels = async (lead_id, type) => {
  const response = await API.get(`/api/conversations/instant-responses/leads/${lead_id}/labels`);
  return response.data;
};

export const getAnswers = async (params) => {
  const response = await API.get(`/api/conversations/instant-responses/answers`, { params });
  return response.data.results;
};

export const getAnswerOverrides = async (community, params) => {
  const response = await API.get(`/api/conversations/instant-responses/answers/overrides/${community}`, { params });
  return response.data;
};

export const getAssignedReviews = async () => {
  const response = await API.get(`/api/conversations/instant-responses/review/`);
  return response.data;
};

export const acceptReview = async (review) => {
  const response = await API.put(`/api/conversations/instant-responses/review/${review.automated_answer_id}/accept`);
  return response.data;
};

export const declineReview = async (review, data) => {
  await API.put(`/api/conversations/instant-responses/review/${review.automated_answer_id}/decline`, data);
  return review;
};

export const assignMoreReviews = async () => {
  const response = await API.post(`/api/conversations/instant-responses/review/assign`);
  return response.status === 201;
};

export const updateAnswer = async (answer) => {
  const response = await API.put(`/api/conversations/instant-responses/answers/${answer.id}`, answer);
  return response.data;
};

/**
 * See AnswerTextOverrideBulkSerializer for `payload` schema.
 */
export const bulkOverrideAnswer = async (payload) => {
  const response = await API.put(`/api/conversations/instant-responses/answers/overrides/bulk`, payload);
  return response.data;
};

/**
 * See AnswerTextOverrideResetSerializer for `payload` schema.
 */
export const resetOverrides = async (payload) => {
  const response = await API.put(`/api/conversations/instant-responses/answers/overrides/reset`, payload);
  return response.data;
};

export const getAnswerPlaceholders = async (params) => {
  const response = await API.get(`/api/conversations/instant-responses/placeholders`, { params });
  return response.data;
};

export const markLeadMessageUnread = async (lead_id) => {
  const response = await API.put(`/api/conversations/instant-responses/leads/${lead_id}/mark-unread`);
  return response.data;
};

export const forwardLeadConversationMessage = async (lead_id, message_id, type) => {
  const response = await API.put(`/api/conversations/instant-responses/leads/${lead_id}/${type}/${message_id}`);
  return response.data;
};

export const sendLeadConversationMessage = async (lead_id, type, data) => {
  const response = await API.post(`/api/conversations/instant-responses/leads/${lead_id}/${type}/response`, data);
  return response.data;
};

export const getTakeOverMessagingSession = async () => {
  const response = await API.get(`/api/conversations/instant-responses/take-over`);
  return response.data;
};

export const startTakeOverMessagingSession = async () => {
  const response = await API.post(`/api/conversations/instant-responses/take-over/start`, {});
  return response.data;
};

export const completeTakeOverMessagingSession = async () => {
  const response = await API.put(`/api/conversations/instant-responses/take-over`);
  return response.data;
};

export const getSalesAssistantLeadStatuses = async () => {
  const response = await API.get(`/api/conversations/instant-responses/leads/statuses`);
  return response.data;
};

export const setSalesAssistantLeadNotation = async (lead_id, data) => {
  const response = await API.post(
    `/api/conversations/instant-responses/leads/${lead_id}/set-sales-assistant-notation`,
    data
  );
  return response.data;
};

export const requestEmailFromCommunity = async (lead_id, data) => {
  const response = await API.post(`/api/conversations/instant-responses/leads/${lead_id}/request_email`, data);
  return response.data;
};

export const getSalesAssistantCommunityNotation = async (communityId) => {
  const response = await API.get(
    `/api/conversations/instant-responses/communities/${communityId}/sales-assistant-notation`
  );
  return response.data;
};

export const setSalesAssistantCommunityNotation = async (communityId, data) => {
  const response = await API.post(
    `/api/conversations/instant-responses/communities/${communityId}/sales-assistant-notation`,
    data
  );
  return response.data;
};

export const setBulkBudgetQualification = async (payload) => {
  const response = await API.put(`/api/conversations/instant-responses/budget-qualification/bulk`, payload);
  return response.data;
};
