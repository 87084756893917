import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';

import { Dimmer, Loader } from 'components/lib';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--line-standard);
  width: 100%;
  padding: 0 30px;
  gap: 30px;
`;

const Tab = styled(Link)`
  position: relative;
  padding: 10px 0;
  font-family: var(--font-family);
  font-size: 14px;
  color: #89919d;
  &.active,
  &:hover {
    color: var(--text);
    font-family: var(--font-family-bold);
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--text);
      bottom: -1px;
      position: absolute;
    }
  }
`;

const ContentWrapper = styled.div`
  padding: ${({ $contentPadding }) => $contentPadding};
  position: relative;
  height: 100%;
`;

export const TabsWithNavigation = ({ tabs, initialActiveTab, contentPadding = '20px 30px', tabContentLoading }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(initialActiveTab || tabs[0]);

  useEffect(() => {
    const activeTab = tabs.find((tab) => tab.path === location.pathname);
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }, [location.pathname, tabs]);

  return (
    <Wrapper>
      <TabsContainer>
        {tabs.map(({ tabName, path }) => (
          <Tab to={path} key={tabName} className={classNames({ active: activeTab.path === path })}>
            {tabName}
          </Tab>
        ))}
      </TabsContainer>
      <ContentWrapper $contentPadding={contentPadding}>
        <Dimmer active={tabContentLoading} inverted>
          <Loader transparent active={tabContentLoading} />
        </Dimmer>
        <activeTab.Content />
      </ContentWrapper>
    </Wrapper>
  );
};
