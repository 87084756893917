import React, { useContext, useState, useMemo } from 'react';
import { Label, Message, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { format, parse, parseISO, setHours, startOfToday } from 'date-fns';

import { Button } from 'components/lib';
import { CalendarCreateTour } from './calendar/CalendarCreateTour';
import { MessageContext } from './MessageContext';
import { LabelItem } from './Labelitem';
import { STATUSES } from '../../../constants';
import { CALENDAR_REQUEST_TYPE } from './calendar/constants';

const StyledAnswer = styled.div`
  margin-bottom: 5px;

  .other-answers {
    margin: 2px 5px;
    color: #888;
  }

  .missed-answers {
    margin-top: 10px;
    .answer {
      margin: 2px 5px;
      font-style: italic;
    }
  }
`;

export const ScheduleTourCallSuggestion = () => {
  const { automatedAnswer } = useContext(MessageContext);
  const [selectedSlot, setSelectedSlot] = useState(undefined);

  const type = useMemo(() => {
    const confirmIntent = automatedAnswer?.intents?.find(
      (i) => i.intent === 'tour_confirm' || i.intent === 'call_confirm'
    );
    if (!confirmIntent) {
      return undefined;
    }
    return confirmIntent?.intent === 'tour_confirm' ? CALENDAR_REQUEST_TYPE.TOUR : CALENDAR_REQUEST_TYPE.CALL;
  }, [automatedAnswer]);

  const dateEntities = useMemo(() => {
    const tourDates = automatedAnswer?.entities?.filter((e) => e.type === 'tour_date') || [];
    if (tourDates.length && automatedAnswer.entities.filter((e) => e.type === 'tour_date_changed').length === 0) {
      return tourDates;
    }
    const dates = automatedAnswer?.entities?.filter((e) => e.type === 'date') || [];
    return [...tourDates, ...dates];
  }, [automatedAnswer]);

  const onSlotSelected = (slot) => {
    const start = slot && parseISO(slot);
    setSelectedSlot(start && { start });
  };

  if (!type || !automatedAnswer || !dateEntities[0]) {
    return null;
  }
  return (
    <>
      <Message positive>
        <Message.Header>Schedule a {type}?</Message.Header>
        <Message.Content>
          {dateEntities.map((e, i) => (
            <div>
              <Button key={i} onClick={() => onSlotSelected(e.value.replace('~', ''))} size="small">
                {e.value.replace('~', '')}
              </Button>
            </div>
          ))}
        </Message.Content>
      </Message>
      <CalendarCreateTour type={type} slot={selectedSlot} onCloseModal={() => setSelectedSlot(undefined)} />
    </>
  );
};
