import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon, Popup } from 'components/lib';
import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import { DetailsRowValue, DetailsRow } from './helpers';
import { AudioPlayPauseWidget } from '../../conversations/AudioPlayPauseWidget';
import { ConversationContext } from '../ConversationContext';
import { CALL_DIRECTION_TYPES } from '../actions/constants';

const ViewTranscriptLink = styled.span`
  color: var(--link);
  cursor: pointer;
  text-align: right;
`;

const Recording = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > span {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
    .further-icon {
      margin-right: 10px;
    }
  }
  a {
    word-break: break-all;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--link);
  }
`;

export const CallDetails = ({ call = {} }) => {
  const { t } = useTranslation();
  const { onViewTranscriptLinkClick } = useContext(ConversationContext);
  const callDurationInSeconds = call.call_duration_seconds || 0;
  const callDurationInMinutes = callDurationInSeconds / 60;
  const callDurationSeconds = callDurationInSeconds % 60;
  const callDuration = `${
    callDurationInMinutes >= 1 ? `${Math.floor(callDurationInMinutes)} minutes and` : ''
  } ${callDurationSeconds} seconds`;
  const isOutbound = call.direction === CALL_DIRECTION_TYPES.OUTBOUND;

  const firstForwardedCall = call.forwarded_calls?.at(0);
  const callerUser = call.caller_user && `${call.caller_user.name} (${call.caller_user.email})`;

  return (
    <>
      <DetailsRow>
        <Recording>
          <span>
            <Icon name="fiber_manual_record" color="#e33f3f" size="12" />
            Recording
          </span>
          {call.is_playable ? (
            <If condition={call?.id}>
              <Popup
                flowing
                trigger={<AudioPlayPauseWidget text="Playing call" callId={call.id} forceOpen={true} />}
                content="Listen to call"
              />
            </If>
          ) : (
            'No recording available'
          )}
        </Recording>
      </DetailsRow>
      <DetailsRow>
        <span>Direction</span>
        <DetailsRowValue>{call.direction}</DetailsRowValue>
      </DetailsRow>
      {firstForwardedCall?.transcript ? (
        <DetailsRow>
          <span>Transcript</span>
          <ViewTranscriptLink onClick={() => onViewTranscriptLinkClick(firstForwardedCall)}>View</ViewTranscriptLink>
        </DetailsRow>
      ) : null}
      <DetailsRow className="center">
        <span>Call Duration</span>
        <DetailsRowValue>
          <span>{call.call_duration_seconds ? callDuration : '-'}</span>
          {callDurationInSeconds && <span className="footnote">({callDurationInSeconds} seconds)</span>}
        </DetailsRowValue>
      </DetailsRow>
      <DetailsRow>
        <span>Caller Name</span>
        <DetailsRowValue>
          <span>{call.caller_name || callerUser || '-'}</span>
        </DetailsRowValue>
      </DetailsRow>
      <If condition={isOutbound}>
        <DetailsRow>
          <span>Tracking number:</span>
          <DetailsRowValue>
            <span>{call.tracking_number || '-'}</span>
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <span>Called number:</span>
          <DetailsRowValue>
            <span>{call.destination_number || '-'}</span>
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <span>Note</span>
          <DetailsRowValue>
            <span>{call.note}</span>
          </DetailsRowValue>
        </DetailsRow>
      </If>
      <If condition={!isOutbound}>
        <DetailsRow className="center">
          <span>Caller Address</span>
          <DetailsRowValue>
            <span>
              {call.caller_city || '-'}, {call.caller_state || '-'}
            </span>
            {call.caller_zip && <span className="footnote">{call.caller_zip}, US</span>}
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <span> Caller Number</span>
          <DetailsRowValue>
            <span>{call.tracking_number || '-'}</span>
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <span>Tracking Number</span>
          <DetailsRowValue>
            <span>{call.destination_number || '-'}</span>
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <span>IP Address</span>
          <DetailsRowValue>
            <span>{call.ip_address || '-'}</span>
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <span>Operating System</span>
          <DetailsRowValue>
            <span>{call.operating_system || '-'}</span>
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <span>Browser</span>
          <DetailsRowValue>
            <span>{call.browser || '-'}</span>
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow className="center">
          <span>Device Type & Make</span>
          <DetailsRowValue>
            <span>{call.device_type || '-'}</span>
            {call.device_make && <span className="footnote">{call.device_make}</span>}
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <span>Spam</span>
          <DetailsRowValue>
            <span style={{ textTransform: 'capitalize' }}>{`${call.is_spam || '-'}`}</span>
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <span>{capitalize(t('lead'))} Campaign</span>
          <DetailsRowValue>
            <span>{call.campaign || '-'}</span>
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <span>{capitalize(t('lead'))} Content</span>
          <DetailsRowValue>
            <span>{call.content || '-'}</span>
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <span>{capitalize(t('lead'))} Keyword</span>
          <DetailsRowValue>
            <span>{call.keyword || '-'}</span>
          </DetailsRowValue>
        </DetailsRow>
        <DetailsRow>
          <Recording>
            <span>Landing URL</span>
            {call.lading_page_url ? (
              <a rel="noreferrer" target="_blank" href={call.lading_page_url}>
                {call.lading_page_url}
              </a>
            ) : (
              '-'
            )}
          </Recording>
        </DetailsRow>
      </If>
    </>
  );
};
