import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 587px;
  background-color: #fafafa;
`;
export const MainContaner = ({ children }) => {
  return <Container>{children}</Container>;
};
