import React, { createContext, useReducer, useEffect } from 'react';

import { createAiCampaign, getCampaignBuildRequests, createCampaignFromTemplate } from 'apis';
import { toast } from 'react-toastify';
import { CAMPAIGN_MESSAGE_TYPE } from 'components/nurture/constants';
import { AI_STEPS, TEMPLATE_STEPS } from './TemplateWizard/constants';
import { CAMPAIGN_BUILD_TYPES } from './constants';
import { useCampaigns } from './CampaignsContext';

const CampaignsTemplateContext = createContext();

const actions = {
  SET_INITIALIZED: 'campaign_templates/SET_INITIALIZED',
  SET_COMMUNITIES: 'campaign_templates/SET_COMMUNITIES',
  SET_COMMUNITIES_LOADING: 'campaign_templates/SET_COMMUNITIES_LOADING',
  SET_WIZARD_ACTIVE: 'campaign_templates/SET_WIZARD_ACTIVE',
  SET_NEXT_STEP: 'campaign_templates/SET_NEXT_STEP',
  SET_PREV_STEP: 'campaign_templates/SET_PREV_STEP',
  SET_TEMPLATES: 'campaign_templates/SET_TEMPLATES',
  SET_TEMPLATES_LOADING: 'campaign_templates/SET_TEMPLATES_LOADING',
  SET_CREATING_BUILD_REQUEST: 'campaign_templates/SET_CREATING_BUILD_REQUEST',
  SET_RESET_STATE: 'campaign_templates/SET_RESET_STATE',
  SET_BUILD_REQUEST_ID: 'campaign_templates/SET_BUILD_REQUEST_ID',
};

export const INITIAL_STATE = {
  initialized: false,
  isWizardActive: false,
  communities: [],
  communitiesLoading: false,
  currentStep: 1,
  wizardValues: {},
  templates: [],
  templatesLoading: false,
  isCreatingCampaignsModalOpen: false,
  buildRequestId: null,
};

function campaignsTemplateReducer(state, action) {
  switch (action.type) {
    case actions.SET_INITIALIZED: {
      return { ...state, initialized: action.data };
    }
    case actions.SET_COMMUNITIES: {
      return { ...state, communities: action.data };
    }
    case actions.SET_COMMUNITIES_LOADING: {
      return { ...state, communitiesLoading: action.data };
    }
    case actions.SET_WIZARD_ACTIVE: {
      return { ...state, isWizardActive: action.data, currentStep: 1, wizardValues: {} };
    }
    case actions.SET_NEXT_STEP: {
      const STEPS = state.wizardValues.source === CAMPAIGN_BUILD_TYPES.TEMPLATE ? TEMPLATE_STEPS : AI_STEPS;
      return {
        ...state,
        currentStep: Math.min(state.currentStep + 1, STEPS.length),
        wizardValues: {
          ...state.wizardValues,
          ...action.data,
        },
      };
    }
    case actions.SET_PREV_STEP: {
      return { ...state, currentStep: state.currentStep - 1 };
    }
    case actions.SET_TEMPLATES: {
      return { ...state, templates: action.data };
    }
    case actions.SET_TEMPLATES_LOADING: {
      return { ...state, templatesLoading: action.data };
    }
    case actions.SET_RESET_STATE: {
      return { ...INITIAL_STATE, initialized: true };
    }
    case actions.SET_CREATING_BUILD_REQUEST: {
      return { ...state, isCreatingCampaignsModalOpen: true, isWizardActive: false };
    }
    case actions.SET_BUILD_REQUEST_ID: {
      return {
        ...state,
        buildRequestId: action.data,
        isCreatingCampaignsModalOpen: true,
        isWizardActive: false,
      };
    }
    default:
      console.error('Unrecognized action type');
  }
}

function CampaignsTemplateProvider({ children }) {
  const [state, dispatch] = useReducer(campaignsTemplateReducer, INITIAL_STATE);
  const { fetchCampaigns } = useCampaigns();

  useEffect(() => {
    const fetchBuildRequests = async () => {
      try {
        const { results } = await getCampaignBuildRequests({ ai_build: true });
        if (results.length > 0) {
          const { id } = results[0];
          dispatch({ type: actions.SET_BUILD_REQUEST_ID, data: id });
        }
      } catch (error) {
        toast.error('Failed to fetch AI build requests');
      } finally {
        dispatch({ type: actions.SET_INITIALIZED, data: true });
      }
    };

    fetchBuildRequests();
  }, []);

  const createBuildRequest = async (values) => {
    dispatch({ type: actions.SET_CREATING_BUILD_REQUEST });
    const smsAndEmail = `${CAMPAIGN_MESSAGE_TYPE.EMAIL}${CAMPAIGN_MESSAGE_TYPE.SMS}`;
    const data = {
      communities: values.communities,
      campaignTemplates: values.campaignTemplates,
      aiPrompt: values.aiPrompt,
      includeSms: values.messageType === CAMPAIGN_MESSAGE_TYPE.SMS || values.messageType === smsAndEmail,
      includeEmail: values.messageType === CAMPAIGN_MESSAGE_TYPE.EMAIL || values.messageType === smsAndEmail,
    };
    try {
      const { buildRequestId } = await createAiCampaign(data);
      dispatch({ type: actions.SET_BUILD_REQUEST_ID, data: buildRequestId });
    } catch (e) {
      console.error(e);
      toast.error('Error creating campaign');
      dispatch({ type: actions.SET_RESET_STATE });
    }
  };

  const createCampaingsFromTemplate = async (values) => {
    dispatch({ type: actions.SET_CREATING_BUILD_REQUEST });

    const data = {
      communities: values.communities,
      campaignTemplates: values.campaignTemplates,
    };
    try {
      await createCampaignFromTemplate(data);
      await fetchCampaigns();

      dispatch({ type: actions.SET_RESET_STATE });
    } catch (e) {
      console.error(e);
      toast.error('Error creating campaign');
      dispatch({ type: actions.SET_RESET_STATE });
    }
  };

  const handleNextStep = (values, type = CAMPAIGN_BUILD_TYPES.AI) => {
    if (type === CAMPAIGN_BUILD_TYPES.AI && state.currentStep === AI_STEPS.length) {
      createBuildRequest({ ...state.wizardValues, ...values }, type);
    } else if (type === CAMPAIGN_BUILD_TYPES.TEMPLATE && state.currentStep === TEMPLATE_STEPS.length) {
      createCampaingsFromTemplate({ ...state.wizardValues, ...values });
    }
    dispatch({ type: actions.SET_NEXT_STEP, data: values });
  };

  const handlePreviousStep = () => {
    dispatch({ type: actions.SET_PREV_STEP });
  };

  const value = {
    state,
    dispatch,
    actions,
    handleNextStep,
    handlePreviousStep,
    createBuildRequest,
  };
  return <CampaignsTemplateContext.Provider value={value}>{children}</CampaignsTemplateContext.Provider>;
}

function useCampaignsTemplate() {
  const context = React.useContext(CampaignsTemplateContext);
  if (context === undefined) {
    throw new Error('useCampaignsTemplate must be used within a CampaignsTemplateProvider');
  }
  return context;
}

export { CampaignsTemplateProvider, useCampaignsTemplate };
