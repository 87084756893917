import React from 'react';
import { Field as FormikField } from 'formik';
import { Form, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { ToggleSwitch, InputField } from 'components/common';
import { capitalize } from 'utils/helpers';

export const InstantAnswersSection = ({ chat }) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Group>
        <Form.Field width={3}>
          <label>Maximum failed attempts</label>
          <FormikField
            as={Input}
            name="ia_max_attempts"
            type="number"
            min="1"
            label={{ content: 'times' }}
            labelPosition="right"
          />
        </Form.Field>
        <Form.Field width={13}>
          <InputField
            name="ia_proceed_message"
            label="Proceed message"
            placeholder={`Sorry we were not able to answer this question. We are going to ask a member of our team at the ${t(
              'community'
            )} to answer. Please click Proceed and we will connect you with them directly. Thank you!`}
          />
        </Form.Field>

        <Form.Field width={3}>
          <InputField
            name="ia_schedule_tour_button_text"
            label={`Schedule ${t('tour')} button text`}
            placeholder={`Schedule A ${capitalize(t('tour'))}`}
          />
        </Form.Field>
      </Form.Group>
      <ToggleSwitch name="show_ia_feedback_buttons" label="Show feedback buttons" />
      <ToggleSwitch name="ia_proceed_on_error" label="Proceed on error" />
    </>
  );
};
