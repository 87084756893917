import React from 'react';
import styled from 'styled-components';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';
import LabelsFilter from '../AutomatedAnswerReport/LabelsFilter';
import { ScoreFilter } from '../AutomatedAnswerReport/ScoreFilter';
import StatusFilter from '../AutomatedAnswerReport/StatusFilter';
import { ReviewersFilter } from './ReviewersFilter';

const Filters = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  > div {
    margin-left: 10px;
  }

  .spacer {
    flex: 1;
  }
`;

export const AutomationFilters = () => {
  const {
    additionalFilters: { used_labels, reviewers, ma_statuses, score_min, score_max, review__suggested_labels },
    setAdditionalFilter,
  } = useSystemStatsFilters();
  const usedLabels = used_labels ? used_labels.split(',') : [];
  const reviewSuggestedLabels = review__suggested_labels ? review__suggested_labels.split(',') : [];
  const reviewerEmails = reviewers ? reviewers.split(',') : [];
  const maStatuses = ma_statuses ? ma_statuses.split(',').map((id) => parseInt(id)) : [];
  const scoreMin = score_min !== undefined ? parseInt(score_min) : undefined;
  const scoreMax = score_max !== undefined ? parseInt(score_max) : undefined;

  const setUsedLabels = (values) => {
    setAdditionalFilter('used_labels', values?.join(','));
  };

  const setReviewSuggestedLabels = (values) => {
    setAdditionalFilter('review__suggested_labels', values?.join(','));
  };

  const setReviewers = (values) => {
    setAdditionalFilter('reviewers', values?.join(','));
  };

  const setMAStatuses = (values) => {
    setAdditionalFilter('ma_statuses', values?.join(','));
  };

  const setScoreMin = (min) => setAdditionalFilter('score_min', min);
  const setScoreMax = (max) => setAdditionalFilter('score_max', max);

  return (
    <>
      <Filters>
        <LabelsFilter
          name="Used Labels"
          allItemsSelectedText="All Used Labels"
          selectedValues={usedLabels}
          onChange={setUsedLabels}
        />
        <LabelsFilter
          name="Reviewer Suggested Labels"
          allItemsSelectedText="All Labels Suggested in Reviews"
          selectedValues={reviewSuggestedLabels}
          onChange={setReviewSuggestedLabels}
        />
        <ReviewersFilter selectedValues={reviewerEmails} onChange={setReviewers} />
      </Filters>
      <Filters>
        <StatusFilter selectedValues={maStatuses} onChange={setMAStatuses} />
        <ScoreFilter min={scoreMin} max={scoreMax} onChangeMin={setScoreMin} onChangeMax={setScoreMax} />
      </Filters>
    </>
  );
};
