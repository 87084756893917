import React, { useState } from 'react';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { If } from 'components/If';
import { InputField } from 'components/common';
import { Form, Input, CustomMessage } from 'components/lib';
import { parseErrorResponse } from 'apis/utils';
import { addDomainEmails } from 'apis';
import StepActions from './StepActions';
import { StepsHeader } from './StepsHeader';
import { StepsContent } from './StepsContent';
import { STATUSES } from '../../../constants';
import { MICROSOFT } from './constants';

const InputWrapper = styled.div`
  display: flex;
  .field {
    width: 230px;
  }
`;

const InputSuffix = styled.div`
  width: 230px;
  height: 36px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #fff;
  font-family: var(--font-family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  padding: 8px 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AddAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--link);
  cursor: pointer;
  margin-top: 10px;
`;
const MessageWrapper = styled.div`
  margin-bottom: 20px;
`;

const initialFormValues = {
  emails: [''],
};

const validationSchema = Yup.object({
  emails: Yup.array().of(Yup.string().required('Email is required')).min(1, 'At least one email address is required'),
});

export const AddEmailAddressStep = (props) => {
  const [isEmailsSaving, setIsEmailSaving] = useState(STATUSES.IDLE);
  const { state, handleClose, fetchEmails, filters } = props;
  const { wizardValues } = state;

  const onSubmit = async (values, { setErrors }) => {
    const { wizardValues } = state;
    const { domain } = wizardValues;
    const data = {
      emails: values?.emails?.map((email) => `${email}@${domain?.domain}`),
    };
    try {
      setIsEmailSaving(STATUSES.LOADING);
      await addDomainEmails(domain?.id, data);
      setIsEmailSaving(STATUSES.LOADED);
      toast.success('Emails added successfully!');
      fetchEmails(filters);
      handleClose();
    } catch (e) {
      console.error(e);
      setErrors(e?.response?.data);
      toast.error(parseErrorResponse(e), 'Error saving emails!');
      setIsEmailSaving(STATUSES.FAILURE);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values, isValid, handleSubmit }) => (
        <Form>
          <StepsHeader title="Add Email Address" subtitle="Add one or more email addresses for this domain." />
          <StepsContent>
            <If condition={wizardValues?.domain?.provider?.toLowerCase() === MICROSOFT}>
              <MessageWrapper>
                <CustomMessage
                  type="warning"
                  title="Addresses for this domain will be invited to authenticate."
                  text="This usually applies to Google or Microsoft domains."
                />
              </MessageWrapper>
            </If>
            <FieldArray name="emails">
              {({ push }) => (
                <>
                  {values.emails.map((_, i) => (
                    <InputWrapper key={i}>
                      <InputField control={Input} name={`emails.${i}`} />
                      <InputSuffix>@{wizardValues?.customDomain || wizardValues?.domain?.domain}</InputSuffix>
                    </InputWrapper>
                  ))}
                  <AddAddress onClick={() => push('')}>+ Add another address</AddAddress>
                </>
              )}
            </FieldArray>
          </StepsContent>
          <StepActions
            {...props}
            disabled={!isValid}
            loading={isEmailsSaving === STATUSES.LOADING}
            onSave={handleSubmit}
          />
        </Form>
      )}
    </Formik>
  );
};
