import { useImmerReducer } from 'use-immer';

import { percent, roundTo } from 'components/utils';
import { useCallback } from 'react';

export const formatAvgTableCell = (internalValue, industryValue) => {
  const internal = roundTo(internalValue);
  const industry = roundTo(industryValue);
  const trend = internal !== industry ? (internal > industry ? 'asc' : 'desc') : null;

  return {
    internal,
    industry,
    trend,
  };
};

export const formatPercentageTableCell = (internalValue, industryValue) => {
  const internal = roundTo(internalValue);
  const industry = roundTo(industryValue);
  const trend = internal !== 0 && internal !== industry ? (internal > industry ? 'asc' : 'desc') : null;

  return {
    internal: `${internal.toFixed(1)} %`,
    industry: `${industry.toFixed(1)} %`,
    trend,
  };
};

export const buildAverageColumn = (internalCount, internalTotalCount, industryCount, industryTotalCount) => {
  const internalAvg = internalCount / internalTotalCount || 0;
  const industryAvg = industryCount / industryTotalCount || 0;

  return formatAvgTableCell(internalAvg, industryAvg);
};

const makeOverview = ({ internal, internalProviderProfilesCount, industry, industryProviderProfilesCount }) => {
  const websiteVisitors = buildAverageColumn(
    internal.visitors_count,
    internalProviderProfilesCount,
    industry.visitors_count,
    industryProviderProfilesCount
  );
  const vsaUsers = buildAverageColumn(
    internal.interactions_count,
    internalProviderProfilesCount,
    industry.interactions_count,
    industryProviderProfilesCount
  );
  const vsaConversion = formatPercentageTableCell(
    percent(internal.leads_count, internal.interactions_count),
    percent(industry.leads_count, industry.interactions_count)
  );
  const leads = buildAverageColumn(
    internal.leads_count,
    internalProviderProfilesCount,
    industry.leads_count,
    industryProviderProfilesCount
  );
  const financiallyUnqualified = formatPercentageTableCell(
    percent(internal.financially_unqualified_count, internal.leads_count),
    percent(industry.financially_unqualified_count, industry.leads_count)
  );
  const avgLeadScore = formatAvgTableCell(internal.avg_lead_value_score, industry.avg_lead_value_score);
  const tours = buildAverageColumn(
    internal.tours_count,
    internalProviderProfilesCount,
    industry.tours_count,
    industryProviderProfilesCount
  );
  const moveIns = buildAverageColumn(
    internal.move_ins_count,
    internal.profiles_with_move_ins_count,
    industry.move_ins_count,
    industry.profiles_with_move_ins_count
  );

  return {
    websiteVisitors,
    vsaUsers,
    vsaConversion,
    leads,
    financiallyUnqualified,
    avgLeadScore,
    tours,
    moveIns,
  };
};

export const makeTraffic = ({
  internal,
  internalProviderProfilesCount,
  industry,
  industryProviderProfilesCount,
  key = 'source',
}) => {
  const trafficSources = [...new Set(internal.map((e) => e[key]).concat(industry.map((e) => e[key])))];

  return trafficSources.map((source) => {
    const internalItem = internal.find((e) => e[key] === source);
    const industryItem = industry.find((e) => e[key] === source);
    return {
      source,
      websiteVisitors: buildAverageColumn(
        internalItem?.visitors_count,
        internalProviderProfilesCount,
        industryItem?.visitors_count,
        industryProviderProfilesCount
      ),
      leads: buildAverageColumn(
        internalItem?.leads_count,
        internalProviderProfilesCount,
        industryItem?.leads_count,
        industryProviderProfilesCount
      ),
      financiallyUnqualified: formatPercentageTableCell(
        percent(internalItem?.financially_unqualified_count, internalItem?.leads_count),
        percent(industryItem?.financially_unqualified_count, industryItem?.leads_count)
      ),
      avgLeadScore: formatAvgTableCell(internalItem?.avg_lead_value_score, industryItem?.avg_lead_value_score),
      tours: buildAverageColumn(
        internalItem?.tours_count,
        internalProviderProfilesCount,
        industryItem?.tours_count,
        industryProviderProfilesCount
      ),
      moveIns: buildAverageColumn(
        internalItem?.move_ins_count,
        internalItem?.profiles_with_move_ins_count,
        industryItem?.move_ins_count,
        industryItem?.profiles_with_move_ins_count
      ),
    };
  });
};

const reducer = (draft, action) => {
  switch (action.type) {
    case 'SET_BENCHMARKS': {
      const { internal, industry } = action.payload;
      const {
        overview: internalOverview,
        organic_traffic: internalOrganicTraffic,
        advertising: internalAdvertising,
        provider_profiles_count: internalProviderProfilesCount,
      } = internal;
      const {
        overview: industryOverview,
        organic_traffic: industryOrganicTraffic,
        advertising: industryAdvertising,
        provider_profiles_count: industryProviderProfilesCount,
      } = industry;

      draft.overview = makeOverview({
        internal: internalOverview,
        internalProviderProfilesCount,
        industry: industryOverview,
        industryProviderProfilesCount,
      });
      draft.organicTraffic = makeTraffic({
        internal: internalOrganicTraffic,
        internalProviderProfilesCount,
        industry: industryOrganicTraffic,
        industryProviderProfilesCount,
      });
      draft.advertising = makeTraffic({
        internal: internalAdvertising,
        internalProviderProfilesCount,
        industry: industryAdvertising,
        industryProviderProfilesCount,
      });
      break;
    }
    default:
      console.warn(`Unhandled action ${JSON.stringify(action)}`);
      break;
  }

  return draft;
};

const useBenchmarks = () => {
  const [state, dispatch] = useImmerReducer(reducer, {
    overview: undefined,
    organicTraffic: undefined,
    advertising: undefined,
  });

  const setBenchmarks = useCallback((payload) => dispatch({ type: 'SET_BENCHMARKS', payload }), [dispatch]);

  return { state, setBenchmarks };
};

export default useBenchmarks;
