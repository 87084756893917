import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TabsWithNavigation } from 'components/lib';

import { Availability } from './Availability';
import { AutomatedAnswers } from './AutomatedAnswers';
import { BudgetQualification } from './BudgetQualification/BudgetQualification';

const MESSAGING_ASSISTANT_TABS = [
  {
    tabName: 'Availability',
    path: '/advanced/messaging-assistant/availability',
    Content: Availability,
  },
  {
    tabName: 'Automated Answers',
    path: '/advanced/messaging-assistant/automated-answers',
    Content: AutomatedAnswers,
  },
  {
    tabName: 'Budget Qualification',
    path: '/advanced/messaging-assistant/budget-qualification',
    Content: BudgetQualification,
  },
];

const INITIAL_TAB = MESSAGING_ASSISTANT_TABS[0].path;

const MessagingAssistant = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/advanced/messaging-assistant') {
      history.replace(INITIAL_TAB);
    }
  }, []);

  return (
    <div>
      <TabsWithNavigation tabs={MESSAGING_ASSISTANT_TABS} />
    </div>
  );
};

export default MessagingAssistant;
