import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { TourCard } from './TourNotFound';

const LabelWrapper = styled.div`
  display: flex;
  color: rgba(25, 25, 25, 0.86);
`;

export const TourLeadCancelled = () => {
  const { t } = useTranslation();

  return (
    <TourCard>
      <LabelWrapper>
        <Icon name="info circle" size="large" color="blue" />
        <p>Thank you for your feedback!</p>
      </LabelWrapper>
      <br />
      <p>We've sent message to the {t('community')}. Someone will contact you soon.</p>
    </TourCard>
  );
};
