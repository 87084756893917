import React from 'react';
import styled from 'styled-components';
import { Table } from 'components/lib';

const LeadPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  margin-bottom: 1rem;

  .ui.table {
    width: 100%;
  }

  .nested-values {
    word-break: break-all;
    line-height: 1.5;
    padding-left: 2ch;
  }

  td > .nested-values {
    padding-left: 0;
  }
`;

const FormattedValue = ({ value }) => {
  if (!!value && typeof value === 'object') {
    return Object.entries(value).map(([key, value]) => (
      <div className="nested-values" key={key}>
        <strong>{key}</strong>: <FormattedValue value={value} />
      </div>
    ));
  }

  return String(value) || '-';
};

export const LeadPreview = ({ leadInfo }) => {
  return (
    <LeadPreviewWrapper>
      {leadInfo?.id ? <h3>Generated Lead</h3> : <h3>Lead Preview</h3>}
      {leadInfo && (
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Field</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.entries(leadInfo).map(([key, value]) => (
              <Table.Row key={key}>
                <Table.Cell>{key}</Table.Cell>
                <Table.Cell>
                  <FormattedValue value={value} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </LeadPreviewWrapper>
  );
};
