import React from 'react';
import styled from 'styled-components';

import { Pagination as SemanticPagination } from 'semantic-ui-react';

const StyledPagination = styled(SemanticPagination)`
  &.ui.pagination.menu {
    border: none;
    border-radius: 18px;
    background-color: var(--input-field-fill);
    margin-left: 20px;
    align-items: center;
    color: blue;
    font-family: var(--font-family);
    font-size: 12px;
    padding: 7px 5px;
    box-shadow: none;

    .item {
      width: 22px;
      height: 22px;
      min-width: 20px;
      border-radius: 50px;
      padding: 0;
      text-align: center;
      justify-content: center;

      &:before {
        display: none;
      }

      &.active {
        box-shadow: 0 2px 6px 0 #d8d8d8;
        background-color: var(--white);
      }

      &.icon {
        &:nth-last-child(-n + 2):hover,
        &:nth-child(-n + 2):hover {
          background: none;
        }
        .ellipsis {
          font-size: 10px;
          color: var(--line-standard);
        }
      }
    }
  }
`;

export const Pagination = ({ ...props }) => <StyledPagination {...props} />;

Pagination.propTypes = { ...StyledPagination.propTypes };

Pagination.Item = SemanticPagination.Item;
