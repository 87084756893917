import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { MoveinLikelihood } from 'components/common/MoveinLikelihood';
import { If } from 'components/If';
import { MEDIA_BREAK_POINTS, USER_TYPES } from '../../constants';

import { ConversationSidebarNavigation } from './sidebar/ConversationSidebarNavigation';
import { ConversationContext } from './ConversationContext';
import { MoveInDatePicker } from './actions/MoveInDatePicker';
import { UserContext } from '../UserContext';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
`;

const Contact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  a {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 500;
    color: var(--link);
  }

  &:first-child {
    margin-right: 20px;
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    margin-bottom: 0;
    flex-wrap: nowrap;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-right: auto;
  &.hasLikelihood {
    margin-right: unset;
    margin-left: auto;
    ${Contact} {
      flex-direction: row-reverse;
      &:first-child {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-wrap: wrap;
    padding-bottom: 6px;

    ${Contact} > a {
      color: var(--link);
    }
  }
`;

export const ConversationHeaderBottom = () => {
  const { user: { type: userType } = {} } = useContext(UserContext);
  const { selectedConversation = {} } = useContext(ConversationContext);

  const updateMoveInDateAllowed = [USER_TYPES.REGULAR, USER_TYPES.STAFF].includes(userType);
  const likelihood = selectedConversation?.details?.score;
  const hasLikelihood = likelihood > 66;
  return (
    <Container>
      <ConversationSidebarNavigation />
      {updateMoveInDateAllowed && <MoveInDatePicker />}
      <ContactInfo className={classNames({ hasLikelihood })}>
        <Contact>
          <a href={`tel:${selectedConversation?.phone}`}>{selectedConversation?.phone}</a>
        </Contact>
        <Contact>
          <a href={`mailto:${selectedConversation?.email}`}>{selectedConversation?.email}</a>
        </Contact>
      </ContactInfo>
      <If condition={hasLikelihood}>
        <MoveinLikelihood />
      </If>
    </Container>
  );
};
