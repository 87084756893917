import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { formatCount, formatDurationAsTime } from 'components/utils';
import { getReportMetrics } from 'apis';
import { toast } from 'react-toastify';
import { parseErrorResponse } from 'apis/utils';
import queryConstants from 'utils/queries/constants';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import { PercentCircle, SimpleMetric } from 'components/common/analytics';

const metrics = ['calls.total', 'calls.missed', 'calls.followup', 'calls.duration'];

const Totals = ({ filters, publicId }) => {
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.METRICS, { publicId, filters, metrics }],
    () => getReportMetrics({ public_id: publicId, filters: { ...filters, metrics } }),
    { placeholderData: {} }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  const parsedData = useMemo(() => {
    if (!data || !data['calls.total']) {
      return data;
    }
    const total = data['calls.total'].value;
    const missed = data['calls.missed'].value;
    const answered = total - missed;
    const followup = data['calls.followup'].value;
    const avgDuration = data['calls.duration'].value;
    const chartData = {
      Answered: answered,
      Missed: missed,
    };

    return { chartData, total, missed, answered, followup, avgDuration };
  }, [data]);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch calls data'));
  }

  return (
    <PercentCircle
      loaderStatus={loaderStatus}
      data={parsedData?.chartData}
      radius={120}
      circleWidth={12}
      units="total calls"
      color="var(--vivid-blue)"
      backColor="var(--line-standard)"
      additionalMetrics={
        <>
          <SimpleMetric
            metric={formatDurationAsTime(parsedData?.avgDuration)}
            unit="avg. call"
            fontSize="20px"
            unitFontSize="14px"
          />
          <SimpleMetric
            metric={formatCount(parsedData?.followup)}
            unit="calls were repeated"
            fontSize="14px"
            unitFontSize="14px"
          />
        </>
      }
    />
  );
};

export default Totals;
