const LABELS = [
  'activities',
  'additional_resources',
  'address',
  'adult_day_care',
  'age_limit',
  'amenities',
  'availability',
  'brochure',
  'budget_assistance',
  'call_confirm',
  'call_request',
  'call_to_tour_suggest',
  'cancel_tour',
  'car_allowed',
  'care_services',
  'care_types',
  'chose_another',
  'costs',
  'conversation_end',
  'couples_allowed',
  'dietary_needs',
  'dining_areas',
  'directions',
  'email',
  'email_confirm',
  'entrance_fee',
  'executive_director',
  'exercise_fitness',
  'facility_name',
  'floorplans',
  'general_info',
  'hospice',
  'images',
  'included',
  'information_request',
  'jobs',
  'languages',
  'lease_term',
  'long_term_insurance',
  'low_income',
  'meals',
  'medication_management',
  'missing_info',
  'not_interested',
  'not_ready',
  'outdoor_activities',
  'parking',
  'pets',
  'phone',
  'physical_therapy',
  'private_aides',
  'research',
  'resistant_to_moving',
  'respite_care',
  'room_amenities',
  'room_types',
  'sales',
  'second_person_fee',
  'security',
  'send_to_community',
  'services',
  'skilled_nursing',
  'smoking',
  'speech_therapy',
  'still_interested',
  'sympathy',
  'thx_response',
  'tour',
  'tour_confirm',
  'tour_reschedule',
  'tour_scheduled',
  'tour_suggest',
  'transportation',
  'unknown_budget',
  'veterans',
  'vision_impaired',
  'volunteer',
  'website',
  'wheelchair',
];
export const LABEL_OPTIONS = LABELS.map((label) => ({ id: label, name: label.replace(/_/g, ' ') }));

export const SENDER_TYPES_OPTIONS = [
  { id: 'sales_assistant', name: 'Sales Assistant' },
  { id: 'community_manager', name: 'Community' },
  { id: 'automated', name: 'Automated' },
];
