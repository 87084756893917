import React from 'react';
import { Icon, Message, Table, Button, Label, Popup, Pagination } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import CRMCommunityLocationCodeOverridesPopup from './CRMCommunityLocationCodeOverridesPopup';

const CRMCommunityList = ({ communities, totalPages, activePage, onPageChange, onEdit, onSendTestLead }) => {
  const { t } = useTranslation();
  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  const handleSendTestLead = (community) => () => {
    onSendTestLead(community);
  };

  const handleEdit = (community) => () => {
    onEdit(community);
  };

  if (!communities) {
    return null;
  }

  if (!communities.length) {
    return <Message content={`No ${t('communities')} found`} icon="search" />;
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>CRM Location Code</Table.HeaderCell>
          <Table.HeaderCell>CRM Settings</Table.HeaderCell>
          <Table.HeaderCell>CRM Integration</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Send Test Lead</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {communities.map((c) => (
          <Table.Row key={c.id}>
            <Table.Cell>
              <If condition={c.has_errors}>
                <Popup
                  content={`${capitalize(t('community'))} CRM settings configuration error!`}
                  trigger={<Icon name="warning circle" color="red" />}
                  wide
                />
              </If>
              {c.name}
            </Table.Cell>
            <Table.Cell>
              {c.crm_location_code} <CRMCommunityLocationCodeOverridesPopup community={c} />
            </Table.Cell>
            <Table.Cell>
              {c.inherits_crm_settings ? (
                <Popup
                  flowing
                  content={
                    <p>
                      {capitalize(t('community'))} inherits <b>{c.crm_settings_name}</b> CRM user settings
                    </p>
                  }
                  trigger={<Label content="Inherits default" color="teal" />}
                />
              ) : (
                c.crm_settings_name
              )}
            </Table.Cell>
            <Table.Cell>{c.crm_settings_integration_name}</Table.Cell>
            <Table.Cell textAlign="center">
              <Button
                primary
                content="Send Test Lead"
                onClick={handleSendTestLead(c)}
                disabled={!c.crm_location_code || c.crm_settings_configuration_error}
                size="tiny"
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Icon name="edit" color="green" link onClick={handleEdit(c)} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="8" textAlign="left">
            {totalPages > 1 && (
              <Pagination activePage={activePage} onPageChange={handlePageChange} totalPages={totalPages} />
            )}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default CRMCommunityList;
