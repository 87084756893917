import React from 'react';
import styled from 'styled-components';

import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import Display from './Display';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Description = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--light-text);
`;

const Constant = ({ criterion }) => {
  const { state } = useCampaignDetails();
  const { criteria } = state;
  const { description } = criteria.find((c) => c.id === criterion.criterionId);

  return (
    <Container>
      <Display size="lg">{criterion.displayName}</Display>
      <Description>{description}</Description>
    </Container>
  );
};

export default Constant;
