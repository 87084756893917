import React from 'react';
import styled from 'styled-components';

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserStatusContainer = styled(VerticalContainer)`
  color: var(--salmon);

  &.loggedIn {
    color: var(--emerald-green);
  }

  &.bold {
    text-align: right;
    font-family: var(--font-family-bold);
  }
`;

const UserStatus = ({ lastLogin, invite, style }) => {
  const getStatus = (lastLogin, invite) => {
    if (lastLogin) {
      return 'Logged In';
    }
    if (invite) {
      return 'Invitation Sent';
    }
    return 'Not Logged In';
  };

  const status = getStatus(lastLogin, invite);

  const classes = ['bold'];
  if (status !== 'Not Logged In') {
    classes.push('loggedIn');
  }

  if (style === 'bold') {
    classes.push('bold');
  }

  return (
    <UserStatusContainer className={classes.join(' ')}>
      <span>{status}</span>
    </UserStatusContainer>
  );
};

export default UserStatus;
