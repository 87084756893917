import humps from 'humps';

import { API } from '../api';

export const getPhoneSystemData = async (params) => {
  const queryParams = humps.decamelizeKeys(params);
  const response = await API.get('/api/communities/phone-number-system', { params: queryParams });
  return humps.camelizeKeys(response.data);
};

export const activatePhoneSystem = async (communityId, data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.patch(`/api/communities/${communityId}/phone-number-system/activate`, body);
  return humps.camelizeKeys(response.data);
};

export const deactivatePhoneSystem = async (communityId) => {
  const response = await API.patch(`/api/communities/${communityId}/phone-number-system/deactivate`, {});
  return humps.camelizeKeys(response.data);
};

export const editPhoneSystem = async (communityId, data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.patch(`/api/communities/${communityId}/phone-number-system/edit`, body);
  return humps.camelizeKeys(response.data);
};

export const getGlobalSelector = async () => {
  const response = await API.get('/api/users/me/settings');
  return humps.camelizeKeys(response.data);
};

export const updatePhoneSystemGlobals = async (data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.patch('/api/communities/phone-number-system/globals', body);
  return humps.camelizeKeys(response.data);
};

export const checkPhoneCommunitiesSync = async (data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.post('/api/communities/phone-number-system/check', body);
  return humps.camelizeKeys(response.data);
};

export const getPhoneCallDetails = async (callId) => {
  const response = await API.get(`/api/chat/call-details/${callId}`);
  return humps.camelizeKeys(response.data);
};

export const phoneSettingsGetCommunities = async (params) => {
  const response = await API.get(`/api/communities/phone-number-system/settings`, { params });
  return response.data;
};

export const phoneSettingsUpdateCommunities = async (data) => {
  const response = await API.post(`/api/communities/phone-number-system/settings`, data);
  return response.data;
};

export const getVoices = async () => {
  const response = await API.get('/api/communities/phone-number-system/voices');
  return humps.camelizeKeys(response.data);
};

export const getPhoneNumbers = async (leadId) => {
  const response = await API.get(`/api/leads/${leadId}/phone-numbers`);
  return humps.camelizeKeys(response.data);
};

export const sendOutboundCallSMSNotification = async (leadId) => {
  const response = await API.post(`/api/calls/outbound/send-notification/${leadId}`);
  return humps.camelizeKeys(response.data);
};
