import React from 'react';
import { Icon, Label, List, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import {
  LEAD_PROCESSING_ACTION_DISPLAY,
  LEAD_PROCESSING_ACTION,
  LEAD_PROCESSING_ACTION_STATUS,
} from '../../../constants';

const ACTION_COLOR = {
  [LEAD_PROCESSING_ACTION_STATUS.PENDING]: 'blue',
  [LEAD_PROCESSING_ACTION_STATUS.COMPLETED]: 'green',
  [LEAD_PROCESSING_ACTION_STATUS.FAILED]: 'orange',
};

const ACTION_ICON = {
  [LEAD_PROCESSING_ACTION_STATUS.PENDING]: 'spinner',
  [LEAD_PROCESSING_ACTION_STATUS.COMPLETED]: 'check circle',
  [LEAD_PROCESSING_ACTION_STATUS.FAILED]: 'warning sign',
};

const LeadProcessingAction = ({ action }) => {
  const { t } = useTranslation();
  return (
    <List.Item>
      <List.Icon name={ACTION_ICON[action.status]} color={ACTION_COLOR[action.status]} />
      <List.Content>{LEAD_PROCESSING_ACTION_DISPLAY[action.action_type]}</List.Content>
    </List.Item>
  );
};

const LeadProcessing = ({ processing }) => {
  if (!processing) {
    return null;
  }

  return (
    <Popup
      content={
        <List>
          {processing.actions
            .filter((action) => action.action_type !== LEAD_PROCESSING_ACTION.PREPROCESS)
            .map((action) => (
              <LeadProcessingAction key={action.action_type} action={action} />
            ))}
        </List>
      }
      trigger={
        <Label color="orange">
          <Icon name="info circle" />
          Lead processing in progress.
          <Label.Detail>
            While the messaging assistant is active all notifications for the community will be delayed.
          </Label.Detail>
        </Label>
      }
    />
  );
};

export default LeadProcessing;
