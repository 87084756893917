import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { isSameMinute } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { rescheduleTour } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { Button, CalendarPicker, Dropdown, Icon, Modal } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { ConversationContext } from '../ConversationContext';
import { MEDIA_BREAK_POINTS, STATUSES } from '../../../constants';
import { CONVERSATION_ACTIONS, SCHEDULE_TYPES, TIME_SLOTS } from './constants';
import { CalendarSelector } from './CalendarSelector';
import { ConversationModal } from './ConversationModal';
import { fetchDayTimeSlots } from './utils';

const StyledModal = styled(ConversationModal)`
  &&&& {
    @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      width: 810px;
    }
  }
`;

const ModalContent = styled(Modal.Content)`
  &&&& {
    padding: 30px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h4 {
    font-family: var(--font-family-bold);
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: var(--text);
    text-transform: capitalize;
    margin: 0;
    margin-left: 10px;
  }
  p {
    margin-top: 15px;
    font-family: var(--font-family);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #89919d;
  }
`;

const Container = styled.div`
  display: flex;
  border-top: 1px solid var(--line-standard);

  > div:not(:last-child) {
    position: relative;
    ::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: var(--line-standard);
    }
  }

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: column;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DropdownContainer = styled.div`
  margin: 20px 35px;
`;

const OPTIONS = {
  FIRST: 0,
  SECOND: 1,
  THIRD: 2,
};

export const AlternativeDatesModal = () => {
  const { t } = useTranslation();
  const {
    eventType,
    selectedConversation,
    selectedCalendar,
    fetchConversationDetails,
    activeModal,
    showModal,
    eventTypeName,
  } = useContext(ConversationContext);

  const actionType = SCHEDULE_TYPES(t)[eventType];
  const [currentSelection, setCurrentSelection] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDates, setSelectedDates] = useState([null, null, null]);
  const [selectedTime, setSelectedTime] = useState([null, null, null]);
  const [isLoading, setIsLoading] = useState(STATUSES.IDLE);
  const [timeSlots, setTimeSlots] = useState([[], [], []]);
  const event = selectedConversation && selectedConversation[eventType];

  const handleDayChange = (date, slot) => {
    setCurrentSelection(slot);
    setSelectedDate(date);

    selectedDates[slot] = date;
    setSelectedDates([...selectedDates]);
  };

  useEffect(() => {
    const getTimeSlots = async (date) => {
      try {
        setIsLoading(STATUSES.LOADING);
        const community = selectedConversation?.community;
        const slots = await fetchDayTimeSlots(date, community);

        timeSlots[currentSelection] = slots;
        selectedTime[currentSelection] = null;

        setTimeSlots([...timeSlots]);
        setSelectedTime([...selectedTime]);
      } catch (e) {
        toast.warning('Trouble fetching calendar time slots. Loading predefined slots.');
      } finally {
        setIsLoading(STATUSES.LOADED);
      }
    };

    if (selectedDate) {
      getTimeSlots(selectedDate);
    }
  }, [currentSelection, selectedDate]);

  const handleModalClose = () => {
    showModal(null);
    setSelectedDates([null, null, null]);
    setSelectedTime([null, null, null]);
    setTimeSlots([[], [], []]);
  };

  const handleTimeChange = (time, slot) => {
    const tempDates = selectedDates;
    tempDates[slot].setHours(time);
    setSelectedDates([...tempDates]);

    const tempTimes = selectedTime;
    tempTimes[slot] = time;
    setSelectedTime([...tempTimes]);
  };

  const handleConfirm = async () => {
    setIsLoading(STATUSES.LOADING);
    try {
      const filteredDates = selectedDates.filter(Boolean).map((date) => formatAnyDate(date, "yyy-MM-dd'T'HH:mm:ss"));

      await rescheduleTour(event.hash, { dates: filteredDates, calendar: selectedCalendar });

      window?.analytics?.track('Tour rescheduled by user', {
        community: selectedConversation?.community?.name,
        community_id: selectedConversation?.community?.id,
        lead_source: selectedConversation?.details?.lead_source,
      });

      fetchConversationDetails(selectedConversation.id);

      handleModalClose();
      toast.success(`${actionType.label} dates sent successfully`);
      setIsLoading(STATUSES.LOADED);
    } catch (error) {
      console.error('error', error);
      toast.error(parseErrorResponse(error, 'Unable to send alternative dates'));
      setIsLoading(STATUSES.FAILURE);
    }
  };

  const hasDuplicates = selectedDates.some((date, index) =>
    selectedDates.filter((d, i) => i !== index).some((date2) => isSameMinute(date, date2))
  );

  const filteredDates = selectedDates.filter((date) => !!date);
  const filteredTime = selectedTime.filter((date) => !!date);

  const disableConfirm =
    filteredDates.length < 1 ||
    filteredTime.length < 1 ||
    hasDuplicates ||
    filteredDates.length !== filteredTime.length;

  const loading = isLoading === STATUSES.LOADING;
  const disableFirstOption = !selectedDates[OPTIONS.FIRST] || loading;
  const disableSecondOption = !selectedDates[OPTIONS.SECOND] || loading;
  const disableThirdOption = !selectedDates[OPTIONS.THIRD] || loading;

  return (
    <StyledModal
      closeIcon
      onClose={handleModalClose}
      onOpen={() => showModal(CONVERSATION_ACTIONS.ALTERNATIVE_DATES)}
      open={activeModal === CONVERSATION_ACTIONS.ALTERNATIVE_DATES}
    >
      <ModalContent>
        <ModalHeader>
          <Icon name={actionType?.icon} variant="outlined" size="22" color={actionType?.color} />
          <h4>{`Offer Alternative ${actionType?.label} Dates`}</h4>
          <p>
            {`Use this if you have not been in touch with the ${t(
              'lead'
            )} and would like them to choose from 3 alternative
            dates for their ${eventTypeName}. They will be invited to make this choice via email.`}
          </p>
          <CalendarSelector />
        </ModalHeader>
      </ModalContent>
      <Container>
        <OptionContainer>
          <CalendarPicker
            title="Option 1"
            color={actionType?.color}
            fadedColor={actionType?.fadedColor}
            disabled={loading}
            onChange={(date) => handleDayChange(date, OPTIONS.FIRST)}
          />
          <DropdownContainer>
            <Dropdown
              fluid
              basic
              selection
              options={timeSlots[OPTIONS.FIRST]}
              placeholder="Select"
              onChange={(e, { value }) => handleTimeChange(value, OPTIONS.FIRST)}
              disabled={disableFirstOption}
            />
          </DropdownContainer>
        </OptionContainer>
        <OptionContainer>
          <CalendarPicker
            title="Option 2"
            color={actionType?.color}
            fadedColor={actionType?.fadedColor}
            disabled={loading}
            onChange={(date) => handleDayChange(date, OPTIONS.SECOND)}
          />
          <DropdownContainer>
            <Dropdown
              fluid
              basic
              selection
              options={timeSlots[OPTIONS.SECOND]}
              placeholder="Select"
              onChange={(e, { value }) => handleTimeChange(value, OPTIONS.SECOND)}
              disabled={disableSecondOption}
            />
          </DropdownContainer>
        </OptionContainer>
        <OptionContainer>
          <CalendarPicker
            title="Option 3"
            color={actionType?.color}
            fadedColor={actionType?.fadedColor}
            disabled={loading}
            onChange={(date) => handleDayChange(date, OPTIONS.THIRD)}
          />
          <DropdownContainer>
            <Dropdown
              fluid
              basic
              selection
              options={timeSlots[OPTIONS.THIRD]}
              placeholder="Select"
              onChange={(e, { value }) => handleTimeChange(value, OPTIONS.THIRD)}
              disabled={disableThirdOption}
            />
          </DropdownContainer>
        </OptionContainer>
      </Container>

      <Modal.Actions>
        <Button primary color={actionType?.class} onClick={handleConfirm} disabled={disableConfirm} loading={loading}>
          Confirm and Send
        </Button>
        <Button primary onClick={handleModalClose} disabled={loading}>
          Cancel
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
};
