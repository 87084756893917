import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Dimmer, Icon, Loader, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import SearchInput from 'components/SearchInput';
import EmailSubjectOverridesTable from './EmailSubjectOverridesTable';
import { useEmailSubjects } from './EmailSubjectsContext';
import { STATUSES } from '../../../constants';
import { PageHeader, PAGE_HEADER_MARGIN } from '../../common';

const EmailSubjectsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmailSubjectsMangement = () => {
  const { loaderStatus, emailSubjectOverrides, fetchEmailSubjectOverrides } = useEmailSubjects();
  const [searchClient, setSearchClient] = useState('');

  useEffect(() => {
    fetchEmailSubjectOverrides(searchClient);
  }, [searchClient, fetchEmailSubjectOverrides]);

  return (
    <>
      <PageHeader
        title="Custom Email Subjects"
        subtitle="Customize email subjects for certain clients."
        margin={PAGE_HEADER_MARGIN.SMALL}
      />
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <EmailSubjectsHeader>
        <Button as={Link} to="/admin/custom-email-subjects/new">
          <Icon name="plus" /> Add Override
        </Button>
        <SearchInput
          value={searchClient}
          onSearch={setSearchClient}
          loading={loaderStatus === STATUSES.LOADING}
          placeholder="Search clients..."
        />
      </EmailSubjectsHeader>
      {loaderStatus !== STATUSES.LOADING && emailSubjectOverrides.length === 0 ? (
        <Message info>There are no custom email subject overrides.</Message>
      ) : (
        <EmailSubjectOverridesTable emailSubjectOverrides={emailSubjectOverrides} />
      )}
    </>
  );
};

export default EmailSubjectsMangement;
