import React from 'react';

import { MultiSelectFilter } from 'components/common';
import { useQuery } from 'react-query';
import { getSalesAssistantLeadStatuses } from 'apis';
import queryConstants from 'utils/queries/constants';

const StatusFilter = ({
  selectedValues,
  onChange,
  allItemsSelectedText = 'All Statuses',
  name = 'Statuses',
  ...other
}) => {
  const { data: statusOptions, isFetching } = useQuery(
    [queryConstants.SALES_ASSISTANT_LEAD_STATUSES],
    () => getSalesAssistantLeadStatuses(),
    {
      placeholderData: [],
      select: (data) =>
        data?.results?.map((leadStatus) => ({
          id: leadStatus.id,
          name: leadStatus.description,
        })) || [],
    }
  );

  return (
    <MultiSelectFilter
      name={name}
      loading={isFetching}
      allItemsSelectedText={allItemsSelectedText}
      options={statusOptions}
      selectedValues={selectedValues}
      onChange={onChange}
      {...other}
    />
  );
};

export default StatusFilter;
