export const PARSABLE_OPTIONS = [
  { key: 'any', value: undefined, text: 'Any parsable status' },
  { key: 'parsable', value: 'parsable', text: 'Parsable' },
  { key: 'not_parsable', value: 'not_parsable', text: 'Not parsable' },
];

export const VALID_OPTIONS = [
  { key: 'any', value: undefined, text: 'Any valid status' },
  { key: 'valid', value: 'valid', text: 'Valid' },
  { key: 'not_valid', value: 'not_valid', text: 'Not valid' },
];

export const REQUEST_TYPE_OPTIONS = [
  { key: 'all', value: 'all', text: 'Any request type' },
  { key: 'tour', value: 'tour', text: 'Tour' },
  { key: 'call', value: 'call', text: 'Call' },
];

export const ENTITY_TYPE = {
  LEADS: 'leads',
  TOURS: 'tours',
};

export const ENTITY_TYPE_LABEL = {
  [ENTITY_TYPE.LEADS]: 'Leads',
  [ENTITY_TYPE.TOURS]: 'Tours',
};

export const CRMChartTheme = {
  colors: {
    pink: '#ff5b98',
    blue: '#5f5fff',
    grey: '#AAAAAA',
    white: '#fff',
    lightPink: '#ff94c9',
    lightBlue: '#9898ff',
    darkGrey: '#888888',
    grayishBlue: '#89919d',
    textColor: '#333333',
  },
};
