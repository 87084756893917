import React from 'react';

import { NavigationSection } from './NavigationSection';
import { NavigationItem } from './NavigationItem';
import { SubNavigation } from './SubNavigation';

export const UserNavigationLabeling = () => {
  return (
    <NavigationSection>
      <NavigationItem
        to="/admin"
        label="Admin"
        icon="admin_panel_settings"
        subnavigation={
          <SubNavigation title="Admin">
            <NavigationItem to="/admin/questions-labeling" label="Questions labeling" icon="smart_toy-outlined" />
          </SubNavigation>
        }
      />
    </NavigationSection>
  );
};
