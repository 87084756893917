import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Button, Input, Modal } from 'components/lib';
import { InputField } from 'components/common';
import { STATUSES } from 'constants';
import { createLead } from 'apis';
import { capitalize } from 'utils/helpers';
import { CheckboxField } from '../common';
import { ConversationsContext } from './ConversationsContext';
import { PHONE_REGEX } from '../../constants';

const ModalHeader = styled.div`
  background-color: var(--white);
  padding: 30px 30px 20px 30px;
`;

const ModalDescription = styled(Modal.Description)`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  color: #89919d;
`;

const ModalTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  font-weight: 500;
  margin-bottom: 11px;
`;

const ModalContent = styled(Modal.Content)`
  && {
    padding: 0px 30px;
  }
`;

const ModalActions = styled(Modal.Actions)`
  && {
    display: flex;
    justify-content: flex-end;
    && > .delete-button {
      margin-left: 0;
      margin-right: auto;
    }
  }
`;

const StyledInput = styled(InputField)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap 10px;

  label {
    min-width: 72px;
    text-align: right;
    font-family: var(--font-family-bold);
    color: var(--text);
  }

  &&&& {
    .input {
      flex-grow: 1;
      input {
          border: solid 1px var(--line-standard);
          background-color: var(--input-field-fill);

          &:focus {
           border: solid 1px var(--vivid-blue);
          }
        }
      }
  }

  .ui.dropdown {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px var(--line-standard);
    background-color: var(--white);
    .divider.default.text {
      font-family: var(--font-family);
      color: var(--text);
    }
  }

  &.error {
    .prompt {
      position: absolute;
      left: 40%;
      margin-top: 80px;
      z-index: 1;
    }
  }
`;

const StyledCheckbox = styled(CheckboxField)`
  margin-left: 82px;

  label {
    font-family: var(--font-family);
    font-weight: 500;
    color: var(--text);
  }
  .input {
    color: #89919d;
  }
`;

const ModalContentLead = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

const ModalContentCommunity = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: solid 1px var(--line-standard);
  border-bottom: solid 1px var(--line-standard);
`;

const StyledButton = styled(Button)`
  &&& {
    font-family: var(--font-family-bold);
    width: 120px;
    height: 40px;
    padding: 10px 38.5px 10px 37.5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
    border: solid 1px var(--line-standard);
    background-color: var(--white);
    color: var(--text);

    &.confirm {
      color: var(--white);
      background-color: var(--vivid-blue);
    }
  }
`;

export const ConversationCreateLeadModal = ({ handleModalClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { communities } = useContext(ConversationsContext);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    community_id: '',
    crm_sync: true,
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().max(30, 'First name has too many characters').required('Please enter a first name'),
    last_name: Yup.string().max(45, 'Last name has too many characters').required('Please enter a last name'),
    email: Yup.string().max(45, 'Email has too many characters'),
    phone: Yup.string()
      .trim()
      .matches(PHONE_REGEX, 'Please enter a valid phone number')
      .required('Please enter a valid phone number'),
    community_id: Yup.number().required(`Please select ${t('community')}`),
  });

  const handleLeadSubmit = async (data) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const result = await createLead(data);
      setLoaderStatus(STATUSES.LOADED);
      toast.success('Lead successfully created');
      history.push(`/conversations/${result.id}`);
    } catch (e) {
      toast.error('Trouble creating new lead');
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const communitiesDropdownItems = communities.map((community) => ({
    key: community.id,
    text: community.name,
    value: community.id,
  }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleLeadSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isValid, dirty }) => (
        <>
          <ModalHeader>
            <ModalTitle>Add {capitalize(t('lead'))}</ModalTitle>
            <ModalDescription>
              Add {t('lead')} who is not currently in the Further system, then text or email them.
            </ModalDescription>
          </ModalHeader>
          <ModalContent>
            <ModalContentLead>
              <StyledInput
                control={Input}
                label="First Name"
                name="first_name"
                placeholder={`Enter the ${t('lead')}'s first name`}
              />
              <StyledInput
                control={Input}
                label="Last Name"
                name="last_name"
                placeholder={`Enter the ${t('lead')}'s last name`}
              />
              <StyledInput
                control={Input}
                label="Email"
                name="email"
                placeholder={`Enter the ${t('lead')}'s email address`}
              />
              <StyledInput
                control={Input}
                label="Phone"
                name="phone"
                placeholder={`Enter the ${t('lead')}'s phone number`}
              />
            </ModalContentLead>
            <ModalContentCommunity>
              <StyledInput
                control={Dropdown}
                fluid
                search
                selection
                label={capitalize(t('community'))}
                name="community_id"
                options={communitiesDropdownItems}
                placeholder={`Select a ${t('community')} to add the ${t('lead')} to`}
              />
              <StyledCheckbox name="crm_sync" label={`Sync this ${t('lead')} with the CRM`} />
            </ModalContentCommunity>
          </ModalContent>
          <ModalActions>
            <StyledButton
              className="confirm"
              type="submit"
              onClick={handleSubmit}
              loading={loaderStatus === STATUSES.LOADING}
              disabled={!isValid || !dirty || loaderStatus === STATUSES.LOADING}
            >
              Add
            </StyledButton>
            <StyledButton onClick={handleModalClose} disabled={loaderStatus === STATUSES.LOADING}>
              Cancel
            </StyledButton>
          </ModalActions>
        </>
      )}
    </Formik>
  );
};
