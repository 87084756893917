import React from 'react';
import styled from 'styled-components';

import { CheckboxField } from 'components/common';

const LabelTitle = styled.div`
  color: var(--text);
  font-family: var(--font-family-bold);
  font-size: 14px;
`;

const LabelSubtitle = styled.div`
  margin-top: 5px;
  color: var(--light-text);
  font-family: var(--font-family);
  font-size: 12px;
`;

export const SubtitledCheckboxField = ({ title, subtitle, name, onChange = () => {} }) => (
  <CheckboxField
    label={
      <label>
        <div>
          <LabelTitle>{title}</LabelTitle>
          {subtitle && <LabelSubtitle>{subtitle}</LabelSubtitle>}
        </div>
      </label>
    }
    name={name}
    handleOnChange={onChange}
  />
);
