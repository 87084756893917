import React from 'react';
import styled from 'styled-components';

import { Message as SemanticMessage } from 'semantic-ui-react';

const StyledMessage = styled(SemanticMessage)``;

export const Message = ({ ...props }) => <StyledMessage {...props} />;

Message.Header = SemanticMessage.Header;
Message.Content = SemanticMessage.Content;
Message.Item = SemanticMessage.Item;
Message.List = SemanticMessage.List;
