import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Message, Dimmer } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Tab, Loader } from 'components/lib';
import {
  getCommunityTestimonials,
  createCommunityTestimonial,
  updateCommunityTestimonial,
  deleteCommunityTestimonial,
} from 'apis/CommunitiesAPI';
import { If } from 'components/If';
import { UserCommunityPanelHeader } from './UserCommunityPanelHeader';

import { PAGINATION, STATUSES } from '../../../constants';
import { DEFAULT_SHOW_VSA_STATUS, DEFAULT_RATING_ORDER_OPTION } from '../constants';
import UserCommunityTestimonialsTable from './UserCommunityTestimonialsTable';
import UserCommunityTestimonialsFilters from './UserCommunityTestimonialsFilters';
import UserCommunityTestimonialsModal from './UserCommunityTestimonialsModal';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const UserCommunityTestimonials = () => {
  const { communityId } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(STATUSES.IDLE);
  const [testimonials, setTestimonials] = useState([]);
  const [testimonialsCount, setTestimonialsCount] = useState(0);
  const [openTestimonialsModal, setOpetTestimonialsModal] = useState(false);

  const [filters, setFilters] = useState({
    page: PAGINATION.PAGE,
    page_size: PAGINATION.PAGE_SIZE,
    ordering: DEFAULT_RATING_ORDER_OPTION,
    show_on_embedded_vsa: DEFAULT_SHOW_VSA_STATUS,
  });

  useEffect(() => {
    fetchCommunityTestimonials();
  }, [communityId, filters]);

  const fetchCommunityTestimonials = async () => {
    setLoading(STATUSES.LOADING);

    try {
      const parsedFilters = {
        ...filters,
        show_on_embedded_vsa:
          filters.show_on_embedded_vsa === DEFAULT_SHOW_VSA_STATUS ? null : filters.show_on_embedded_vsa,
      };
      const response = await getCommunityTestimonials(communityId, parsedFilters);
      setTestimonials(response?.results);
      setTestimonialsCount(response?.count);
      setLoading(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error("Couldn't fetch testimonials! Please try again");
      setLoading(STATUSES.FAILURE);
    }
  };

  const totalPages = useMemo(
    () => (testimonialsCount && filters.page_size ? Math.ceil(testimonialsCount / filters.page_size) : 0),
    [testimonialsCount, filters]
  );

  const createTestimonial = async (testimonial) => {
    try {
      setLoading(STATUSES.LOADING);

      await createCommunityTestimonial(communityId, { ...testimonial, community_id: communityId });
      await fetchCommunityTestimonials();

      setLoading(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error("Couldn't create testimonial! Please try again");
      setLoading(STATUSES.FAILURE);
    }
  };

  const updateTestimonial = async (testimonialId, testimonialData) => {
    try {
      setLoading(STATUSES.LOADING);
      await updateCommunityTestimonial(communityId, testimonialId, testimonialData);
      await fetchCommunityTestimonials();
      setLoading(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error("Couldn't update testimonial! Please try again");
      setLoading(STATUSES.FAILURE);
    }
  };

  const deleteTestimonial = async (testimonialId) => {
    try {
      setLoading(STATUSES.LOADING);
      await deleteCommunityTestimonial(communityId, testimonialId);
      await fetchCommunityTestimonials();
      setLoading(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error("Couldn't delete testimonial! Please try again");
      setLoading(STATUSES.FAILURE);
    }
  };

  const onFiltersChange = (value) => {
    setFilters({ ...filters, ...value, ...{ page: PAGINATION.PAGE, page_size: PAGINATION.PAGE_SIZE } });
  };

  const onPageChange = (page) => {
    setFilters({ ...filters, ...{ page } });
  };

  return (
    <Tab.Pane padding="30px">
      <>
        <Wrapper>
          <UserCommunityPanelHeader
            title="Manage your testimonials and facts"
            subtitle="Manage testimonials and facts. Set their visibility on the VSA"
            content="Create"
            onUpdate={() => setOpetTestimonialsModal(true)}
          />

          <UserCommunityTestimonialsFilters filters={filters} onChange={onFiltersChange} />
          <If condition={loading === STATUSES?.LOADING}>
            <Dimmer active={loading === STATUSES?.LOADING} inverted>
              <Loader active size="large">
                Loading
              </Loader>
            </Dimmer>
          </If>
          <If condition={loading !== STATUSES.LOADING}>
            <If condition={testimonialsCount === 0}>
              <Message warning content={`No testimonials found related to this ${t('community')}.`} />
            </If>
            <If condition={loading === STATUSES.LOADED && testimonialsCount > 0}>
              <UserCommunityTestimonialsTable
                testimonials={testimonials}
                loading={loading === STATUSES.LOADING}
                page={filters?.page}
                totalCount={testimonialsCount}
                totalPages={totalPages}
                onPageChange={onPageChange}
                onEdit={updateTestimonial}
                onDelete={deleteTestimonial}
              />
            </If>
          </If>
        </Wrapper>
        <UserCommunityTestimonialsModal
          open={openTestimonialsModal}
          loading={loading === STATUSES.LOADING}
          onSave={createTestimonial}
          onClose={() => setOpetTestimonialsModal(false)}
        />
      </>
    </Tab.Pane>
  );
};
