import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { Button, Icon } from 'components/lib';
import { If } from 'components/If';
import { RouterPrompt } from 'components/RouterPrompt';
import { usePreventUnload } from 'components/chats/usePreventUnload';
import { MEDIA_BREAK_POINTS } from '../../../../../constants';
import { CAMPAIGN_TYPE, CAMPAIGN_TYPE_LABEL, NURTURE_CAMPAIGNS_ROUTE } from '../../../constants';
import { HeaderIconWrapper, PageHeaderTitle } from '../../common';

const PageHeaderContainer = styled.div`
  margin-bottom: 15px;
`;

const BreadCrumbs = styled.div`
  font-family: var(--font-family-bold);
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #89919d;
  text-transform: uppercase;
`;

const SectionTitle = styled.div`
  display: flex;
  margin-top: 14px;
`;

const PageTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.WEB}px) {
    width: calc(100% - 200px);
  }
`;

const WarningButton = styled(Button)`
  display: flex;
  &&&& {
    box-shadow: 0 0 0 1px var(--salmon) inset !important;
    color: var(--salmon) !important;
    background-color: #fff2ef !important;
    i {
      margin: 0;
      height: 22px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;

  && button {
    height: 40px;
    width: 120px;
  }
`;

const HEADER_PARTS = {
  [CAMPAIGN_TYPE.SALES]: {
    label: CAMPAIGN_TYPE_LABEL.SALES,
    icon: <Icon name="campaign" size="24" color="#2791ff" />,
  },
  [CAMPAIGN_TYPE.FOLLOW_UP]: {
    label: CAMPAIGN_TYPE_LABEL.FOLLOW_UP,
    icon: <Icon name="emoji_events" size="24" color="#ff5b98" />,
  },
  [CAMPAIGN_TYPE.ONE_TIME]: {
    label: CAMPAIGN_TYPE_LABEL.ONE_TIME,
    icon: <Icon name="event_available" size="24" color="#0ab776" />,
  },
};

export const PageHeader = ({
  campaignId,
  loading = false,
  saveDisabled = false,
  showActions = true,
  onSave = () => {},
  onCancel = () => {},
  warningButton = false,
  openWarningModal = () => {},
}) => {
  const { campaignType } = useParams();
  const { dirty } = useFormikContext();
  const [, setDirty] = usePreventUnload();

  useEffect(() => {
    setDirty(dirty);
  }, [dirty]);

  return (
    <PageHeaderContainer>
      <BreadCrumbs>
        <Link to={NURTURE_CAMPAIGNS_ROUTE}>Messaging</Link> &#183; <span> {HEADER_PARTS[campaignType].label} </span>
      </BreadCrumbs>
      <SectionTitle>
        <HeaderIconWrapper>{HEADER_PARTS[campaignType].icon}</HeaderIconWrapper>
        <PageTitleWrapper>
          <PageHeaderTitle>
            {campaignId ? 'Edit' : 'Add'} {HEADER_PARTS[campaignType].label} Campaign
          </PageHeaderTitle>
          <If condition={showActions}>
            <ButtonWrapper>
              {warningButton && (
                <WarningButton basic icon="warning circle" content="Warnings" color="red" onClick={openWarningModal} />
              )}
              <Button
                type="submit"
                primary
                color="blue"
                content="Save"
                loading={loading}
                disabled={saveDisabled}
                onClick={onSave}
              />
              <Button basic content="Cancel" onClick={onCancel} />
            </ButtonWrapper>
          </If>
        </PageTitleWrapper>
      </SectionTitle>
      <RouterPrompt when={dirty} />
    </PageHeaderContainer>
  );
};
