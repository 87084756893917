import React from 'react';
import styled from 'styled-components';

import NurtureTabs from './NurtureTabs';
import NurtureRoutes from './NurtureTabRoutes';
import { NurtureFilterProvider } from './NurtureFilterContext';
import { NURTURE_CAMPAIGNS_ROUTE } from './constants';
import { NurtureFilters } from './NurtureFilters';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const MessagingHeader = styled.div`
  font-family: var(font-family-bold);
  font-size: 60px;
  line-height: 0.97;
  letter-spacing: normal;
  color: var(--text);
  padding-left: 30px;
  padding-top: 17px;
`;

const NurtureRoot = ({ hideDateFilter }) => {
  return (
    <Container>
      <MessagingHeader>Messaging</MessagingHeader>
      <NurtureTabs />
      {location.pathname === NURTURE_CAMPAIGNS_ROUTE ? (
        <NurtureRoutes />
      ) : (
        <NurtureFilterProvider>
          <NurtureFilters hideDateFilter={hideDateFilter} />
          <NurtureRoutes />
        </NurtureFilterProvider>
      )}
    </Container>
  );
};

export default NurtureRoot;
