import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 590px;
`;

const StyledIcon = styled(Icon)`
  transform: scale(-1, 1);
  background-image: linear-gradient(to top, #de75ff, var(--vivid-blue));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  margin-top: 10px;
`;

const Description = styled.div`
  max-width: 415px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #89919d;

  b {
    color: var(--text);
  }
`;

const ArchivedConversationNotSelected = () => {
  return (
    <Container>
      <Content>
        <div>
          <StyledIcon name="inventory_2" variant="outlined" size="45" />
        </div>
        <Header>Select an archived conversation.</Header>
        <Description>
          Conversations that you have <b>archived</b> appear here. They will return to the Ongoing tab if the anyone
          replies, or a reminder activates.
        </Description>
      </Content>
    </Container>
  );
};

export default ArchivedConversationNotSelected;
