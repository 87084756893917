import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { Form, Button, Modal, TextArea, Label, Message, Dimmer } from 'components/lib';
import { getLeadInfo, requestEmailFromCommunity } from 'apis';
import { InputField } from '../../../common';
import { CALENDAR_MAX_NOTE_LENGTH } from './constants';
import { MessageContext } from '../MessageContext';
import { formatAnyDate } from '../../../utils';

const StyledForm = styled(Form)`
  margin: 30px;
`;

const validationSchema = Yup.object({
  note: Yup.string().max(CALENDAR_MAX_NOTE_LENGTH, `Can't be longer than 1000 characters`).required('Required'),
});

const DATE_TIME_FORMAT = 'MMM dd, yyyy, hh:mm a';
const EMAIL_REQUEST_TYPE = 'email_request';

const EmailRequestModal = ({ onClose, open, ...props }) => {
  const { lead } = useContext(MessageContext);
  const formikRef = useRef();
  const noRecipients = lead?.community?.sales_contact_emails?.length === 0;

  const { isLoading, mutate } = useMutation({
    mutationFn: async (data) => requestEmailFromCommunity(lead.id, data),
    onSuccess: () => {
      formikRef.current?.resetForm();
      onClose();
      toast.success('Email sent successfully!');
    },
  });

  const {
    data: leadInfo,
    refetch: refetchLeadInfo,
    isLoading: isLoadingLeadInfo,
  } = useQuery({
    queryKey: 'email_request_info',
    queryFn: () => getLeadInfo(lead.id),
    enabled: false,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    // fetch fresh last email sent information whenever we open the modal because it gets updated asynchronously
    if (open) {
      refetchLeadInfo();
    }
  }, [open, refetchLeadInfo]);

  const lastEmailSent = useMemo(() => {
    const lastEmail = leadInfo?.email_tracking.filter((e) => e.email_type === EMAIL_REQUEST_TYPE)[0];
    return lastEmail?.created_on;
  }, [leadInfo]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ note: 'Please email general information for the prospect/lead to review.' }}
      validationSchema={validationSchema}
      onSubmit={mutate}
    >
      {({ values, handleSubmit, isValid }) => (
        <Modal onClose={onClose} open={open} {...props}>
          <Dimmer active={isLoadingLeadInfo} inverted />
          <Modal.Header>Send Email to Community</Modal.Header>
          <Modal.Content>
            <StyledForm error={noRecipients}>
              {lastEmailSent ? (
                <Message>
                  <p>
                    Email to community last sent on <b>{formatAnyDate(lastEmailSent, DATE_TIME_FORMAT)}</b>
                  </p>
                </Message>
              ) : null}
              <Form.Field>
                <label>Recipients</label>
                {lead?.community?.sales_contact_emails.map((email, idx) => (
                  <Label key={idx}>{email}</Label>
                ))}
                {noRecipients && <span>None</span>}
              </Form.Field>
              <InputField control={TextArea} name="note" label="Add note for the community" rows={8} />
              {noRecipients && (
                <Message error content="Unable to send the email request. Community Contact email(s) not found." />
              )}
            </StyledForm>
          </Modal.Content>
          <Modal.Actions>
            <Button basic disabled={isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button
              primary
              color="blue"
              disabled={isLoading || !isValid || noRecipients}
              onClick={handleSubmit}
              type="submit"
            >
              Send
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

export const EmailRequest = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Button fluid size="small" icon="mail" content="Send Email Request" onClick={() => setIsModalOpen(true)} />
      {isModalOpen ? <EmailRequestModal open={isModalOpen} onClose={() => setIsModalOpen(false)} /> : null}
    </>
  );
};
