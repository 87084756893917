import React, { useState } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { formatAnyDate } from 'components/utils';
import { Button, Icon } from 'components/lib';
import { If } from 'components/If';
import { CAMPAIGN_TYPE_NAME } from 'components/nurture/constants';
import { CONVERSATION_MESSAGE_NAME, CONVERSATION_MESSAGE_TYPE } from '../constants';
import RemoveScheduledMessageModal from '../RemoveScheduledMessageModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  max-width: 450px;
  border-radius: 20px;
  border-bottom-right-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
  border: solid 1px var(--line-standard);
  background-color: #edeef4;
  margin-bottom: 1rem;
  img {
    max-width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 30px 17px;
`;

const Text = styled(ReactMarkdown)`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #89919d;
  word-break: break-word;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 13px;
    margin: 0;
    font-family: var(--font-family);
    line-height: 1.4285em;
    font-weight: 400;
  }
`;

const Header = styled.div`
  display: flex;
  gap: 10px;
  padding: 7px 22px 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-image: linear-gradient(to left, #adb3bc, #adb3bc 0%);
  align-items: center;

  .text {
    flex-grow: 1;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    margin-left: 0;
    margin-top: 13px;
    background-color: var(--white);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--text);
    border: solid 1px var(--line-standard);
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);

    &:hover,
    &:focus,
    &:active {
      border-color: var(--light-text);
      background-color: var(--white);
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.8 !important;
    }
  }
`;

const BlueButton = styled(StyledButton)`
  &&&& {
    background-color: #275aff;
    color: var(--white);
    &:hover,
    &:focus,
    &:active {
      background-color: #275aff;
      opacity: 0.9;
    }

    &.disabled {
      opacity: 0.8 !important;
    }
  }
}`;

const SecondLine = styled.span`
  display: block;
  opacity: 0.7;
  a,
  a:visited {
    text-decoration: underline;
    color: var(--white);
  }
`;

const Type = styled.div`
  width: fit-content;
  height: 18px;
  padding: 4px 8.5px 1px;
  border-radius: 10px;
  background-color: var(--emerald-green);
  font-family: var(--font-family-bold);
  font-size: 9px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  &.email {
    background-color: #2791ff;
  }
`;

const Subject = styled.div`
  color: var(--text);
  font-size: 13px;
  font-weight: 500;

  strong {
    font-weight: 900;
  }
`;

const ScheduledMessage = ({ lead, message, loading, onRemoveFromOutreach, campaign }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const { t } = useTranslation();

  const { message_type, scheduled_at, text, campaign_type } = message;
  const formattedDate = formatAnyDate(scheduled_at, 'MMM d, yyyy');
  const formattedTime = formatAnyDate(scheduled_at, 'h:mm a');
  const campaignTypeName = CAMPAIGN_TYPE_NAME[campaign_type].toLowerCase();
  const isEmail = message_type === CONVERSATION_MESSAGE_TYPE.EMAIL;

  const toggleOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const handleRemoveFromOutreach = async () => {
    await onRemoveFromOutreach();
    setOpenConfirm(false);
  };

  return (
    <Container>
      <Header>
        <Icon name="campaign" variant="outlined" color="var(--white)" size="16" />
        <span className="text">
          Scheduled for {formattedDate} at {formattedTime}{' '}
          <SecondLine title={`${campaignTypeName} campaign`}>
            via campaign{' '}
            <Link to={`/messaging/campaigns/${campaign_type}/details/${campaign.id}`}>{campaign.name}</Link>
          </SecondLine>
        </span>
        <Type className={`${isEmail ? 'email' : ''}`}>{CONVERSATION_MESSAGE_NAME[message_type]}</Type>
      </Header>
      <Content>
        <If condition={isEmail}>
          <Subject>
            <strong>Subject:</strong> {message.subject}{' '}
          </Subject>
        </If>
        <If condition={!isEmail}>
          <Text>{text}</Text>
        </If>
        <If condition={isEmail}>
          <Link to={`/conversations/${lead.id}/scheduled/${message.message_id}`}>
            <BlueButton fluid>View Email</BlueButton>
          </Link>
        </If>
        <StyledButton fluid onClick={toggleOpenConfirm}>
          Remove from Outreach
        </StyledButton>
      </Content>

      <RemoveScheduledMessageModal
        open={openConfirm}
        leadName={lead.name}
        loading={loading}
        onConfirm={handleRemoveFromOutreach}
        onClose={toggleOpenConfirm}
      />
    </Container>
  );
};

export default ScheduledMessage;
