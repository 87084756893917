import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FURHTER_ICON, Icon, AiIcon } from 'components/lib';
import { capitalize, numberFormatter } from 'utils/helpers';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: fit-content;
  h3 {
    font-family: var(--font-family-bold);
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: var(--text);
    margin: 0 0 0 10px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ChartContainer = styled.div`
  position: relative;
  max-width: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 150px;
  width: 100%;
  border-bottom: 1px solid var(--line-standard);
  padding: 0 15px;
  gap: 75px;
  & > .group {
    display: flex;
    gap: 30px;
  }
`;

const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 34px;
  overflow-x: visible;
`;

const Bar = styled.div`
  width: 34px;
  height: ${({ $height }) => $height}px;
  border: solid 1px;
  border-color: ${({ $borderColor }) => $borderColor};
  background-image: ${({ $backgoround }) => $backgoround};
  position: relative;
  .${FURHTER_ICON} {
    position: absolute;
    top: 0;
    right: -12px;
  }
`;

const ValuesContainer = styled.p`
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  span {
    position: relative;
    .${FURHTER_ICON} {
      position: absolute;
      left: -15px;
    }
  }
`;

const LegendContainer = styled.div`
  position: absolute;
  bottom: -25px;
  left: 20px;
  & > span {
    position: relative;
    margin-right: 10px;
    padding-right: 20px;
    &::before {
      content: '';
      position: absolute;
      top: 3px;
      left: -20px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: solid 1px var(--line-standard);
      background-image: linear-gradient(to bottom, #eee, #d8d8d8);
    }
    &.with {
      &::before {
        border: solid 1px #ff5b98;
        background-image: linear-gradient(to bottom, #ff94c8, #ff5b98);
      }
    }
  }
`;

const colors = {
  without: { background: 'linear-gradient(to bottom, #eee, #d8d8d8)', borderColor: 'var(--line-standard)' },
  with: { background: 'linear-gradient(to bottom, #ff94c8, #ff5b98);', borderColor: '#ff5b98' },
};

export const NextMonths = ({ leadsWithoutFurther, leadsWithFurther, moveInsWithoutFurther, moveInsWithFurther }) => {
  const { t } = useTranslation();
  const MAX_BAR_HEIGHT = 90;

  const singleLeadHeight = MAX_BAR_HEIGHT / leadsWithFurther;
  const barHeightWithoutFurther = leadsWithoutFurther * singleLeadHeight;
  const barHeightWithFurther = leadsWithFurther * singleLeadHeight;

  const singleMoveInHeight = (MAX_BAR_HEIGHT - 30) / moveInsWithFurther;
  const moveInHeightWithoutFurther = moveInsWithoutFurther * singleMoveInHeight;
  const moveInHeightWithFurther = moveInsWithFurther * singleMoveInHeight;

  return (
    <Container>
      <Header>
        <AiIcon>AI</AiIcon>
        <h3>Next 12 months</h3>
      </Header>
      <ChartContainer>
        <div className="group">
          <BarContainer>
            <ValuesContainer>
              <span>{numberFormatter(leadsWithoutFurther, 1)}</span>
              {capitalize(t('leads'))}
            </ValuesContainer>
            <Bar
              $backgoround={colors.without.background}
              $borderColor={colors.without.borderColor}
              $height={barHeightWithoutFurther}
            />
          </BarContainer>
          <BarContainer>
            <ValuesContainer>
              <span>
                <Icon name="arrow_upward" size="14" color="#ff5898" /> {numberFormatter(leadsWithFurther, 1)}
              </span>
              {capitalize(t('leads'))}
            </ValuesContainer>
            <Bar
              $backgoround={colors.with.background}
              $borderColor={colors.with.borderColor}
              $height={barHeightWithFurther}
            >
              <Icon name="auto_awesome" size="24" color="#fad650" variant="outlined" />
            </Bar>
          </BarContainer>
        </div>
        <div className="group">
          <BarContainer>
            <ValuesContainer>
              <span>{numberFormatter(moveInsWithoutFurther, 1)}</span>
              {capitalize(t('move_ins'))}
            </ValuesContainer>
            <Bar
              $backgoround={colors.without.background}
              $borderColor={colors.without.borderColor}
              $height={moveInHeightWithoutFurther}
            />
          </BarContainer>
          <BarContainer>
            <ValuesContainer>
              <span>
                <Icon name="arrow_upward" size="14" color="#ff5898" />
                {numberFormatter(moveInsWithFurther, 1)}
              </span>
              {capitalize(t('move_ins'))}
            </ValuesContainer>
            <Bar
              $backgoround={colors.with.background}
              $borderColor={colors.with.borderColor}
              $height={moveInHeightWithFurther}
            >
              <Icon name="auto_awesome" size="24" color="#fad650" variant="outlined" />
            </Bar>
          </BarContainer>
        </div>
        <LegendContainer>
          <span className="without">Without Further</span>
          <span className="with">With Further</span>
        </LegendContainer>
      </ChartContainer>
    </Container>
  );
};
