import styled from 'styled-components';

const ChatRow = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;

  &.center {
    align-items: center;
    text-align: center;
    > div {
      background-color: #ebebeb;
      color: #81888e;
    }
  }

  &.left {
    align-items: flex-start;
    text-align: left;
    > div {
      background-color: #e9e9eb;
      border-bottom-left-radius: 0;
    }
  }

  &.right {
    align-items: flex-end;
    text-align: right;
    > div {
      background-color: #0082f9;
      border-bottom-right-radius: 0;
      color: white;
    }
    &.community_manager {
      > div {
        background-color: #284ad5;
      }
    }
    &.automated {
      > div {
        background-color: #299a32;
      }
    }
  }
  h6 {
    margin: 0;

    &:empty {
      display: none;
    }
  }
`;

export default ChatRow;
