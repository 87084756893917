import React from 'react';
import { Tab } from 'semantic-ui-react';

import { useAnalytics } from '../context/AnalyticsContext';
import Engagement from './Engagement';
import OverviewGraph from './OverviewGraph';

const OverviewTab = () => {
  const [{ filters, publicId }] = useAnalytics();

  return (
    <Tab.Pane>
      <Engagement filters={filters} publicId={publicId} />
      <OverviewGraph filters={filters} publicId={publicId} />
    </Tab.Pane>
  );
};

export default OverviewTab;
