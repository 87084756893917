import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { InputField } from 'components/common/fields/InputField';
import { useCRM } from './context';
import { OperationBox, OperationSign } from '../MappingRules';

const CRMSourceMappingTrafficSourceRule = ({ baseFieldName, value }) => {
  const [{ eligibleTrafficSources }] = useCRM();
  const [trafficSourceOptions, setTrafficSourceOptions] = useState(
    eligibleTrafficSources.map((trafficSource) => ({
      key: trafficSource,
      value: trafficSource,
      text: trafficSource,
    }))
  );

  useEffect(() => {
    if (!trafficSourceOptions.find((x) => x.value === value)) {
      setTrafficSourceOptions([...trafficSourceOptions, { key: value, value, text: value }]);
    }
  }, [trafficSourceOptions, value]);

  return (
    <>
      <OperationBox>
        <OperationSign>equals</OperationSign>
        <div>
          <InputField
            control={Dropdown}
            name={`${baseFieldName}.value`}
            search
            placeholder="Traffic Source Value"
            type="text"
            selection
            options={trafficSourceOptions}
          />
        </div>
      </OperationBox>
      <OperationBox>
        <OperationSign>then</OperationSign>
        <div>
          <InputField placeholder="Result Value" name={`${baseFieldName}.result_value`} type="text" />
        </div>
      </OperationBox>
    </>
  );
};

export default CRMSourceMappingTrafficSourceRule;
