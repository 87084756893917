import React from 'react';
import styled from 'styled-components';

import { Loader } from 'components/lib';

const SettingsValue = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  text-align: right;
  color: ${({ $color }) => $color};
  width: max-content;
  max-width: 363px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 5px;
  span {
    padding-top: 3px;
    word-break: break-all;
  }
  &.wrap-text {
    width: 100%;
    span {
      white-space: normal;
      word-break: break-word;
      width: 100%;
    }
  }
`;

export const SettingsValueText = ({ text, color = 'var(--light-text)', loading = false, ...props }) => {
  return (
    <SettingsValue $color={color} {...props}>
      <Loader size="mini" active={loading} transparent />
      <span>{text}</span>
    </SettingsValue>
  );
};
