import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { ProgressBar } from 'components/lib';
import Img1 from 'assets/images/campaign_ai_animation.png';
import { CAMPAIGN_BUILD_TYPES } from '../constants';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .onboarding-highlight {
    font-family: var(--font-family);
    color: #222;
  }

  .onboarding-modal-heading {
    font-family: var(--font-family-bold);
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 20px;
    text-align: left;
    width: 100%;
  }

  .onboarding-grey {
    color: #89919d;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    width: 100%;
  }

  .onboarding-loading-img {
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    height: 118px;
  }

  .onboarding-img-1 {
    width: 139px;
    background-image: url(${Img1});
    background-size: 139px 115px;
    background-repeat: no-repeat;
  }

  .onboarding-loading {
    width: 300px;
    margin: 0 auto;
  }

  .onboarding-loading-label {
    display: inline-block;
    font-size: 14px;
    font-family: var(--font-family-bold);
    margin-bottom: 10px;
  }

  div.onboarding-spinner {
    position: relative;
    width: 22px;
    height: 22px;
    display: inline-block;
    top: 5px;
    margin-right: 10px;
  }

  div.onboarding-spinner div {
    width: 10%;
    height: 26%;
    background: #c1c1c1;
    position: absolute;
    left: 49%;
    top: 43%;
    opacity: 0;
    border-radius: 50px;
    animation: onboarding-fade 1s linear infinite;
  }

  @keyframes onboarding-fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.25;
    }
  }

  div.onboarding-spinner div.bar1 {
    transform: rotate(0deg) translate(0, -130%);
    animation-delay: 0s;
  }

  div.onboarding-spinner div.bar2 {
    transform: rotate(30deg) translate(0, -130%);
    animation-delay: -0.9167s;
  }

  div.onboarding-spinner div.bar3 {
    transform: rotate(60deg) translate(0, -130%);
    animation-delay: -0.833s;
  }
  div.onboarding-spinner div.bar4 {
    transform: rotate(90deg) translate(0, -130%);
    animation-delay: -0.7497s;
  }
  div.onboarding-spinner div.bar5 {
    transform: rotate(120deg) translate(0, -130%);
    animation-delay: -0.667s;
  }
  div.onboarding-spinner div.bar6 {
    transform: rotate(150deg) translate(0, -130%);
    animation-delay: -0.5837s;
  }
  div.onboarding-spinner div.bar7 {
    transform: rotate(180deg) translate(0, -130%);
    animation-delay: -0.5s;
  }
  div.onboarding-spinner div.bar8 {
    transform: rotate(210deg) translate(0, -130%);
    animation-delay: -0.4167s;
  }
  div.onboarding-spinner div.bar9 {
    transform: rotate(240deg) translate(0, -130%);
    animation-delay: -0.333s;
  }
  div.onboarding-spinner div.bar10 {
    transform: rotate(270deg) translate(0, -130%);
    animation-delay: -0.2497s;
  }
  div.onboarding-spinner div.bar11 {
    transform: rotate(300deg) translate(0, -130%);
    animation-delay: -0.167s;
  }
  div.onboarding-spinner div.bar12 {
    transform: rotate(330deg) translate(0, -130%);
    animation-delay: -0.0833s;
  }

  .animate__animated {
    animation-duration: 3.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }

  @keyframes onboardingAnimate {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    25% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    75% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }

  @keyframes onboardingAnimate {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    25% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    75% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }
  .animate__onboardingAnimate {
    animation-name: onboardingAnimate;
    animation-name: onboardingAnimate;
  }
`;

export const CreatingAnimation = ({ visible, current, total, source }) => {
  const progress = (current / total) * 100;
  if (!visible) return null;

  return (
    <Wrapper>
      <If condition={source !== CAMPAIGN_BUILD_TYPES.TEMPLATE}>
        <h5 className="onboarding-modal-heading">Creating your campaigns with AI…</h5>
        <div className="onboarding-grey">
          AI is generating the appropriate campaigns and messages for your communities. <br />
          This can take <span className="onboarding-highlight">a minute or two</span>.
        </div>
      </If>
      <If condition={source === CAMPAIGN_BUILD_TYPES.TEMPLATE}>
        <h5 className="onboarding-modal-heading">Creating your campaigns from templaates</h5>
        <div className="onboarding-grey">
          This can take <span className="onboarding-highlight">a few seconds</span>.
        </div>
      </If>

      <div className="onboarding-loading-img animate__animated animate__onboardingAnimate onboarding-img-1" />

      <div className="onboarding-loading">
        <div className="onboarding-spinner">
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
          <div className="bar4"></div>
          <div className="bar5"></div>
          <div className="bar6"></div>
          <div className="bar7"></div>
          <div className="bar8"></div>
          <div className="bar9"></div>
          <div className="bar10"></div>
          <div className="bar11"></div>
          <div className="bar12"></div>
        </div>
        <div className="onboarding-loading-label">Please wait...</div>
        <ProgressBar width="300px" progress={progress} />
      </div>
    </Wrapper>
  );
};
