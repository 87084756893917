import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { resetPassword } from 'apis';
import { Divider } from 'components/lib';
import { UserContext } from 'components/UserContext';
import { logIn } from '../../../api';
import { AuthLayout } from '../AuthLayout';
import ResetPasswordForm from './ResetPasswordForm';
import { STATUSES } from '../../../constants';

export const ResetPassword = () => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { uidb64, token } = useParams();
  const { setUser } = useContext(UserContext);
  const history = useHistory();

  const handleResetPassword = async (password) => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const { email } = await resetPassword({ uidb64, token, password });
      const user = await logIn({ email, password });

      setLoaderStatus(STATUSES.LOADED);
      toast.success('Password changed successfully. Welcome back');
      setUser(user);
      history.push('/');
    } catch (e) {
      toast.error('Unable to change the password. Please try again later.');
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <AuthLayout>
      <h1>Create password</h1>
      <p>Create your new password below. Eight characters minimum.</p>
      <Divider horizontal />

      <ResetPasswordForm loading={loaderStatus === STATUSES.LOADING} onSubmit={handleResetPassword} />
    </AuthLayout>
  );
};
