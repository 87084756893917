import styled from 'styled-components';

export const MultiOptionButton = styled.div`
  background-color: white;
  color: #595d61;
  display: inline-block;
  padding: 0.7rem 1rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 2rem;
  box-shadow: 0 2px 6px #d8d8d8;
  user-select: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
`;
