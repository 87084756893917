import React from 'react';

import { MultiSelectFilter } from 'components/common';
import { SENDER_TYPES_OPTIONS } from 'components/common/automated_answers/constants';

const SenderTypesFilter = ({
  selectedValues,
  onChange,
  allItemsSelectedText = 'All Sender Types',
  name = 'Sender Types',
  ...other
}) => {
  return (
    <MultiSelectFilter
      name={name}
      allItemsSelectedText={allItemsSelectedText}
      options={SENDER_TYPES_OPTIONS}
      selectedValues={selectedValues}
      onChange={onChange}
      {...other}
    />
  );
};

export default SenderTypesFilter;
