import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Route, Switch, useHistory, useRouteMatch, Link } from 'react-router-dom';

import { WithTopBar } from 'components/WithTopBar';
import { UserRoute } from '../common/routes';
import { Pricing } from './Pricing';
import { ContactInfo } from './ContactInfo';
import { Discounts } from './Discounts';
import { SettingsContextProvider } from './SettingsContext';
import { MoveInUpload } from './MoveIn';
import { CRM } from './CRM';
import { InstantAnswers, InstantAnswersCommunities } from './InstantAnswers';
import { USER_TYPES } from '../../constants';
import UserAccounts from './UserAccounts';
import AnalyticsSettings from './AnalyticsSettings';
import JobApplicantEmails from './JobApplicantEmails';
import { CommunitiesGroups } from './CommunitiesGroups';
import CareTypes from './CareTypes';
import { ContactRules } from './ContactRules';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const SettingsTitle = styled.h3`
  a {
    font-family: var(--font-family-bold);
    font-size: 12px;
    letter-spacing: 0.5px;
    color: var(--link);
    text-transform: uppercase;
  }
  margin: 0 30px;
`;

export const Settings = () => {
  const history = useHistory();
  const isSettingsRoot = useRouteMatch('/settings').isExact;

  useEffect(() => {
    if (isSettingsRoot) {
      history.push('/settings/pricing');
    }
  }, [isSettingsRoot, history]);

  return (
    <SettingsContextProvider>
      <WithTopBar>
        <Wrapper>
          <SettingsTitle>
            <Link to="/advanced">Settings</Link>
          </SettingsTitle>
          <Switch>
            <Route exact path="/settings/pricing">
              <Pricing />
            </Route>
            <Route exact path="/settings/care-types/:communityId?">
              <CareTypes />
            </Route>
            <Route exact path="/settings/discount">
              <Discounts />
            </Route>
            <Route exact path="/settings/contact">
              <ContactInfo />
            </Route>
            <Route exact path="/settings/contact-rules" allowedTypes={[USER_TYPES.STAFF, USER_TYPES.REGULAR]}>
              <ContactRules />
            </Route>
            <Route exact path="/settings/job-applicant-emails">
              <JobApplicantEmails />
            </Route>
            <Route exact path="/settings/move-ins">
              <MoveInUpload />
            </Route>
            <UserRoute
              exact
              path="/settings/crm"
              allowedTypes={[USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.REGIONAL]}
            >
              <CRM />
            </UserRoute>
            <UserRoute
              exact
              path="/settings/ia/:community_id"
              allowedTypes={[USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.REGIONAL]}
            >
              <InstantAnswers />
            </UserRoute>
            <UserRoute
              exact
              path="/settings/ia"
              allowedTypes={[USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.REGIONAL]}
            >
              <InstantAnswersCommunities />
            </UserRoute>
            <UserRoute path="/settings/user-accounts" allowedTypes={[USER_TYPES.STAFF, USER_TYPES.REGULAR]}>
              <UserAccounts />
            </UserRoute>
            <UserRoute
              path="/settings/analytics"
              component={AnalyticsSettings}
              allowedTypes={[USER_TYPES.STAFF, USER_TYPES.REGULAR]}
              exact
            />
            <UserRoute
              path="/settings/community-groups"
              component={CommunitiesGroups}
              allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.REGIONAL]}
              exact
            />
          </Switch>
        </Wrapper>
      </WithTopBar>
    </SettingsContextProvider>
  );
};
