import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { sendReminder } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { MessageContext } from '../MessageContext';
import { MEDIA_BREAK_POINTS, STATUSES } from '../../../../constants';
import { CalendarRequestContext } from './CalendarRequests';
import { CALENDAR_REQUEST_TYPE } from './constants';
import { If } from '../../../If';
import { formatAnyDate } from '../../../utils';

const Container = styled.div`
  display: flex;
  padding-bottom: 30px;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.TABLET}px) {
    flex-wrap: wrap;
  }
`;

const LastReminder = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  justify-content: center;
  min-width: 50%;
`;
const ReminderHeader = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
`;

const ReminderDate = styled.div`
  color: var(--light-text);
  font-size: 12px;
`;

const StyledButton = styled(Button)`
  && {
    font-size: 16px;
  }
`;

export const CalendarRequestReminder = () => {
  const { leadId } = useParams();
  const { lead, getLead } = useContext(MessageContext);
  const { type } = useContext(CalendarRequestContext);

  const [reminderStatus, setReminderStatus] = useState(STATUSES.IDLE);

  const onSendReminder = async () => {
    try {
      setReminderStatus(STATUSES.LOADING);
      const calendarRequest =
        (type === CALENDAR_REQUEST_TYPE.TOUR && lead.tour) || (type === CALENDAR_REQUEST_TYPE.CALL && lead.call);
      const { hash, note } = calendarRequest;
      await sendReminder(hash, { action_type: type, note });
      await getLead(leadId);

      setReminderStatus(STATUSES.LOADED);
    } catch (e) {
      setReminderStatus(STATUSES.FAILURE);
      const message = parseErrorResponse(e, 'Please try again latter.');
      toast.error(`Unable to send a reminder. ${message}`);
    }
  };

  const hasTourReminder = type === CALENDAR_REQUEST_TYPE.TOUR && lead.tour?.reminder_at;
  const hasCallReminder = type === CALENDAR_REQUEST_TYPE.CALL && lead.call?.reminder_at;
  const hasReminder = hasTourReminder || hasCallReminder;

  const buttonLabel = hasReminder ? 'Send Another' : 'Send Reminder';
  const last_reminder_at = (hasTourReminder && lead.tour.reminder_at) || (hasCallReminder && lead.call.reminder_at);

  const reminderSending = reminderStatus === STATUSES.LOADING;
  return (
    <Container>
      <If condition={hasReminder}>
        <LastReminder>
          <ReminderHeader>Reminder Sent</ReminderHeader>
          <ReminderDate>{formatAnyDate(last_reminder_at, 'MMM dd, yyyy, hh:mm a')}</ReminderDate>
        </LastReminder>
      </If>
      <StyledButton
        disabled={reminderSending || !lead?.lead_submitted}
        loading={reminderSending}
        fluid
        onClick={onSendReminder}
      >
        {buttonLabel}
      </StyledButton>
    </Container>
  );
};
