import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import React from 'react';
import styled from 'styled-components';

const SIZE_WIDTH = {
  sm: '100px',
  md: '220px',
  lg: '100%',
};

const Container = styled.div`
  box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
  border: solid 1px var(--line-standard);
  border-radius: 0px;
  background-color: white;
  width: ${({ width }) => width};
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: var(--text-color);
  padding: 6px;
  min-height: 36px;
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
`;

const Display = ({ children, size, className }) => {
  const { hasEditDeletePermission } = useCampaignDetails();

  return (
    <Container className={className} width={SIZE_WIDTH[size]} disabled={!hasEditDeletePermission}>
      {children}
    </Container>
  );
};

Display.defaultProps = {
  size: 'lg',
};

export default Display;
