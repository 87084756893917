import React from 'react';
import styled from 'styled-components';

import { Toggle } from 'components/lib';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';
import LabelsFilter from './LabelsFilter';
import SendersFilter from './SendersFilter';
import { ScoreFilter } from './ScoreFilter';
import StatusFilter from './StatusFilter';
import SenderTypesFilter from './SenderTypesFilter';

const Filters = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  > div {
    margin-left: 10px;
  }

  .spacer {
    flex: 1;
  }
`;

const AutomatedAnswerFilters = () => {
  const {
    additionalFilters: {
      automated_only,
      first_message,
      suggested_labels,
      used_labels,
      sender_ids,
      sender_types,
      score_min,
      score_max,
      ma_statuses,
    },
    setAdditionalFilter,
  } = useSystemStatsFilters();
  const firstMessage = first_message === 'true';
  const automatedOnly = automated_only === 'true';
  const suggestedLabels = suggested_labels ? suggested_labels.split(',') : [];
  const usedLabels = used_labels ? used_labels.split(',') : [];
  const senderIds = sender_ids ? sender_ids.split(',').map((id) => parseInt(id)) : [];
  const senderTypes = sender_types ? sender_types.split(',') : [];
  const maStatuses = ma_statuses ? ma_statuses.split(',').map((id) => parseInt(id)) : [];
  const scoreMin = score_min !== undefined ? parseInt(score_min) : undefined;
  const scoreMax = score_max !== undefined ? parseInt(score_max) : undefined;

  const toggleFirstMessage = () => {
    setAdditionalFilter('first_message', !firstMessage);
  };

  const toggleAutomatedOnly = () => {
    setAdditionalFilter('automated_only', !automatedOnly);
  };

  const setSuggestedLabels = (values) => {
    setAdditionalFilter('suggested_labels', values?.join(','));
  };

  const setUsedLabels = (values) => {
    setAdditionalFilter('used_labels', values?.join(','));
  };

  const setSenderIds = (values) => {
    setAdditionalFilter('sender_ids', values?.join(','));
  };

  const setSenderTypes = (values) => {
    setAdditionalFilter('sender_types', values?.join(','));
  };

  const setMAStatuses = (values) => {
    setAdditionalFilter('ma_statuses', values?.join(','));
  };

  const setScoreMin = (min) => setAdditionalFilter('score_min', min);
  const setScoreMax = (max) => setAdditionalFilter('score_max', max);

  return (
    <>
      <Filters>
        <LabelsFilter
          name="Suggested Labels"
          allItemsSelectedText="All Suggested Labels"
          selectedValues={suggestedLabels}
          onChange={setSuggestedLabels}
        />
        <LabelsFilter
          name="Used Labels"
          allItemsSelectedText="All Used Labels"
          selectedValues={usedLabels}
          onChange={setUsedLabels}
        />
        <SendersFilter selectedValues={senderIds} onChange={setSenderIds} />
        <SenderTypesFilter selectedValues={senderTypes} onChange={setSenderTypes} />
      </Filters>
      <Filters>
        <StatusFilter selectedValues={maStatuses} onChange={setMAStatuses} />
        <ScoreFilter min={scoreMin} max={scoreMax} onChangeMin={setScoreMin} onChangeMax={setScoreMax} />
        <Toggle label="First Lead Messages Only" checked={firstMessage} onChange={toggleFirstMessage} />
        <Toggle label="Automated Messages Only" checked={automatedOnly} onChange={toggleAutomatedOnly} />
      </Filters>
    </>
  );
};

export default AutomatedAnswerFilters;
