import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { Form, Dropdown } from 'components/lib';
import { getSettingsEmailIntegrations } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES, PAGINATION } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';

const ModalBody = styled.div``;

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 10px;
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    width: 376px;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  label {
    font-family: var(--font-family-bold);
    font-size: 14px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--text);
    margin-right: 10px;
  }
`;

export const CommunityAssignmentModal = ({ isModalOpen, selectedItem, totalItemsCount, onSave, onClose }) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [emails, setEmails] = useState([]);
  const { t } = useTranslation();

  const fetchEmails = useCallback(async () => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const { results } = await getSettingsEmailIntegrations({
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.MAX_PAGE_SIZE,
      });
      setEmails(results);
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e), 'Unable to fetch data');
      setLoaderStatus(STATUSES.FAILURE);
    }
  }, [setEmails]);

  useEffect(() => {
    fetchEmails();
  }, [fetchEmails]);

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);
    await onSave(values);
    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const initialValues = { email_integration_id: selectedItem?.email_integration?.id };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue }) => (
        <SettingsModal
          loading={loading}
          open={isModalOpen}
          onModalClose={() => handleClose(resetForm)}
          size="tiny"
          width={540}
        >
          <SettingsModalHeader
            title="Assign Email Address"
            community={selectedItem || null}
            totalItemsCount={totalItemsCount}
            type="header"
          />
          <ModalBody type="body">
            <BodyText>
              Choose the address to send campaign email from.
              <If condition={!selectedItem}>
                <div>Any active addresses on these {t('communities')} will be replaced with the one you select.</div>
              </If>
            </BodyText>

            <FieldWrapper>
              <label>Email Address</label>
              <Form.Field
                control={StyledDropdown}
                fluid
                placeholder="Select"
                selection
                options={emails?.map((email) => ({
                  key: email.id,
                  text: email.email,
                  value: email.id,
                }))}
                value={values.email_integration_id}
                onChange={(_, d) => {
                  setFieldValue('email_integration_id', d.value);
                }}
                name="email_integration_id"
              />
            </FieldWrapper>
          </ModalBody>
          <SettingsModalActions
            onSave={handleSubmit}
            onCancel={() => handleClose(resetForm)}
            type="actions"
            disabled={!dirty || !touched}
            saveText="Save"
          />
        </SettingsModal>
      )}
    </Formik>
  );
};
