import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Segment, Button } from 'semantic-ui-react';
import styled from 'styled-components';

import { setSalesAssistantCommunityNotation } from 'apis';
import { If } from 'components/If';
import { MessageContext } from './MessageContext';
import { SalesAssistantNotesModal } from './SalesAssistantNotesModal';

const Note = styled.p`
  white-space: pre-line;
  word-break: break-word;
`;

export const CommunityNotes = () => {
  const { lead } = useContext(MessageContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (lead) {
      setNotes(lead.community.sales_assistant_community_notes || '');
    }
  }, [lead]);

  const onNotesSave = async (value) => {
    try {
      const data = { notes: value };
      await setSalesAssistantCommunityNotation(lead.community.id, data);
      toast.success('Messaging Assistant Notes updated.');
      setNotes(value);
      setIsModalOpen(false);
    } catch (error) {
      toast.error('Trouble saving Messaging Assistant Notes!');
      console.error(error.message);
    }
  };

  if (!lead) {
    return null;
  }

  return (
    <Segment.Group>
      <Segment secondary>
        <If condition={notes}>
          <h4>Messaging Assistant Notes</h4>
          <Note>{notes}</Note>
        </If>
        <Button
          compact
          size="small"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {notes ? 'Edit Messaging Assistant Notes' : 'Add Messaging Assistant Notes'}
        </Button>
      </Segment>
      <SalesAssistantNotesModal
        text={notes}
        handleNotesSave={onNotesSave}
        openModal={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Segment.Group>
  );
};
