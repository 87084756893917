import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, Dimmer, Loader, Dropdown, Grid, Form } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { If } from 'components/If';
import { InputField, RelatedCommunitiesField } from 'components/common';
import { createOrUpdateCommunityEmailSubject, getUserCommunities } from 'apis';
import DeleteCommunitySubjectOverrideModal from './DeleteCommunitySubjectOverrideModal';
import DotsActionButton from './DotsActionButton';
import SelectPlaceholderSection from './SelectPlaceholderSection';
import SubjectPreviewSection from './SubjectPreviewSection';
import { PageHeader, PAGE_HEADER_MARGIN } from '../../common';
import { useEmailSubjects } from './EmailSubjectsContext';
import { STATUSES } from '../../../constants';

const VALIDATION_SCHEMA = Yup.object({
  text: Yup.string().required('Email subject text is required.'),
  type: Yup.string().required('Email Type is required'),
  communities: Yup.array().required('Community items are required'),
});

const EditCommunityEmailSubjectOverview = () => {
  const history = useHistory();
  const location = useLocation();
  const { userId } = useParams();

  const [createMode, setIsCreateMode] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [openDeleteOverridesModal, setOpenDeleteOverridesModal] = useState(false);
  const [enteredSubject, setEnteredSubject] = useState('');
  const [selectedEmailType, setSelectedEmailType] = useState('');
  const [selectedEmailSubject, setSelectedEmailSubject] = useState(null);
  const [communitiesOptions, setCommunitiesOptions] = useState([]);
  const [placeholdersByType, setPlaceholdersByType] = useState([]);
  const { emailTypes, placeholders } = useEmailSubjects();

  const emailTypeOptions = Object.entries(emailTypes).map(([key, value]) => ({ key, value: key, text: value }));

  const initialFormValues = {
    text: enteredSubject,
    type: selectedEmailType,
    communities: selectedEmailSubject?.communities || [],
  };

  useEffect(() => {
    if (!location.state) {
      history.replace('/admin/custom-email-subjects');
      return;
    }

    setSelectedEmailSubject(location.state.selectedEmailSubject || null);
  }, [location, history]);

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const response = await getUserCommunities(userId);
        setCommunitiesOptions(response);
        setLoaderStatus(STATUSES.LOADED);
      } catch (error) {
        toast.error('Trouble with loading communities!');
        console.error(error.message);
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    fetchCommunities();
  }, [userId]);

  useEffect(() => {
    setEnteredSubject(selectedEmailSubject?.text || '');
    setSelectedEmailType(selectedEmailSubject?.type || '');
    setIsCreateMode(!selectedEmailSubject);
  }, [selectedEmailSubject]);

  useEffect(() => {
    if (!selectedEmailType) return;
    setPlaceholdersByType(placeholders.filter((pl) => pl.type === selectedEmailType));
  }, [selectedEmailType, placeholders]);

  const onPlaceholderSelect = (value) => {
    setEnteredSubject(`${enteredSubject}${value}`);
  };

  const onActionCancel = () => {
    if (!history) return;
    history.goBack();
  };

  const onFormSubmit = async (values) => {
    try {
      setLoaderStatus(STATUSES.LOADING);

      let communityIdsToDelete = [];
      const communityIds = values.communities.map((community) => community.id);

      if (!createMode && selectedEmailSubject) {
        const updatedCommunities = new Set(communityIds);
        const initialCommunities = new Set(selectedEmailSubject.communities.map((community) => community.id));
        communityIdsToDelete = [...initialCommunities].filter((x) => !updatedCommunities.has(x));
      }

      await createOrUpdateCommunityEmailSubject(userId, {
        text: enteredSubject,
        type: selectedEmailType,
        community_ids: communityIds,
        community_ids_to_delete: communityIdsToDelete,
      });

      setLoaderStatus(STATUSES.LOADED);
      toast.success('Email subject successfully updated!');
      history.replace(`/admin/custom-email-subjects/${userId}/edit`);
    } catch (error) {
      toast.error('Trouble with updating email subject!');
      console.error(error.message);
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  const onConfirm = async () => {
    await createOrUpdateCommunityEmailSubject(userId, {
      text: enteredSubject,
      type: selectedEmailType,
      community_ids: [],
      community_ids_to_delete: selectedEmailSubject.communities.map((community) => community.id),
    });
    setOpenDeleteOverridesModal(false);
    history.replace(`/admin/custom-email-subjects/${userId}/edit`);
  };

  if (loaderStatus === STATUSES.LOADING) {
    return (
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted />
      </Dimmer>
    );
  }

  return (
    <>
      <Formik initialValues={initialFormValues} validationSchema={VALIDATION_SCHEMA} onSubmit={onFormSubmit}>
        {({ handleSubmit, isValid }) => (
          <Form>
            <PageHeader
              title="Edit Community Email Subject"
              margin={PAGE_HEADER_MARGIN.MEDIUM}
              actions={
                <>
                  <Button type="submit" onClick={handleSubmit} disabled={!isValid}>
                    Save
                  </Button>{' '}
                  <Button onClick={onActionCancel}>Cancel</Button>
                  <If condition={selectedEmailSubject?.communities?.length > 0}>
                    <DotsActionButton onClick={() => setOpenDeleteOverridesModal(true)} title="Delete Customizations" />
                  </If>
                </>
              }
            />
            <Grid>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Form.Field>
                    <InputField
                      label="Email Subject"
                      name="text"
                      placeholder="Email Subject Override"
                      onChanged={setEnteredSubject}
                      value={enteredSubject}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Form.Field>
                    <InputField
                      control={Dropdown}
                      label="Type"
                      name="type"
                      placeholder="Email Type"
                      search
                      selection
                      disabled={createMode === false}
                      options={emailTypeOptions}
                      value={selectedEmailType}
                      onChanged={setSelectedEmailType}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <If condition={selectedEmailType && placeholdersByType.length > 0}>
                <Grid.Row>
                  <Grid.Column>
                    <SelectPlaceholderSection placeholders={placeholdersByType} onLabelClick={onPlaceholderSelect} />
                  </Grid.Column>
                </Grid.Row>
              </If>
              <Grid.Row>
                <Grid.Column>
                  <SubjectPreviewSection value={enteredSubject} allowedPlaceholders={placeholdersByType} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <RelatedCommunitiesField
                    name="communities"
                    label="Communities"
                    communities={communitiesOptions}
                    width={15}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
      <DeleteCommunitySubjectOverrideModal
        open={openDeleteOverridesModal}
        loading={loaderStatus === STATUSES.LOADING}
        onClose={() => setOpenDeleteOverridesModal(false)}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default EditCommunityEmailSubjectOverview;
