import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Dropdown,
  Form,
  List,
  Button,
  ListContent,
  ListHeader,
  Message,
  Dimmer,
  Segment,
  Header,
  Loader,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';

import { Divider, Tab } from 'components/lib';
import { getCitiesByState } from 'apis';
import { debounce } from 'components/utils';
import { If } from 'components/If';
import { InputField } from '../../common/fields/InputField';
import { DEBOUNCE_TIMEOUT, STATUSES, US_CANADA_POSTAL_CODE_REGEX } from '../../../constants';
import { UserCommunityPanelHeader } from './UserCommunityPanelHeader';
import { CommunitiesContext } from '../../common/communities/CommunitiesContext';
import { CommunityAssetsContext } from '../CommunityAssetsContext';

const INITIAL_CONTACT_INFO_STATE = {
  zip: '',
  state: '',
  city: '',
  address: '',
  phone: '',
};

export const UserCommunityContact = () => {
  const { t } = useTranslation();
  const { state } = useContext(CommunitiesContext);
  const { scrapeResults, states, updateStatus, onUpdateAssets } = useContext(CommunityAssetsContext);

  const formRef = useRef();
  const { activeCommunity } = state;

  const [loaderState, setLoaderState] = useState(STATUSES.IDLE);
  const [contactInfo, setContactInfo] = useImmer(INITIAL_CONTACT_INFO_STATE);
  const [scrapedResultError, setScrapedResultError] = useState();

  useEffect(() => {
    const { zip, city, address, phone } = activeCommunity?.location || {};
    setContactInfo((draft) => {
      draft.zip = zip;
      draft.state = city?.state?.code;
      draft.city = city?.id;
      draft.address = address;
      draft.phone = phone;
    });
  }, [activeCommunity, setContactInfo]);

  const stateOptions =
    states
      ?.map((item) => ({ key: item.name, text: item.name, value: item.two_letter_code }))
      .sort((a, b) => a.text.localeCompare(b.text)) || [];

  const hasAddressInfo = scrapeResults?.addresses?.length;
  const hasPhoneInfo = scrapeResults?.phones?.length;
  const hasScrapedInfo = hasAddressInfo || hasPhoneInfo;
  const updateStatusActive = updateStatus === STATUSES.LOADING;

  const [stateCities, setStateCities] = useState([]);
  const [stateCitiesLoaderState, setStateCitiesLoaderState] = useState(STATUSES.IDLE);

  const getStateCities = async (stateCode, search = '') => {
    setStateCitiesLoaderState(STATUSES.LOADING);
    try {
      const response = await getCitiesByState(stateCode, search);
      const { results } = response;

      setStateCities(results.map((item) => ({ key: item.name, text: item.name, value: item.id })));
      setStateCitiesLoaderState(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error(`Unable to fetch cities for ${stateCode}. Please try again`);
      setStateCitiesLoaderState(STATUSES.FAILURE);
    }
  };

  useEffect(() => {
    const initFetch = async () => {
      setLoaderState(STATUSES.LOADING);
      try {
        const { city } = activeCommunity?.location || {};
        await getStateCities(city.state.code, city.name);
        setLoaderState(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error('Something went wrong! Please try again');
        setLoaderState(STATUSES.FAILURE);
      }
    };

    if (activeCommunity?.location?.city) {
      initFetch();
    }
  }, [activeCommunity]);

  const buildNewCityId = (value) => `new-city-${value.split(' ').join('-')}`;

  const handleAddNewCity = (value) => {
    const cityId = buildNewCityId(value);

    setStateCities((draft) => [...draft, { key: value, text: value, value: cityId }]);

    return cityId;
  };

  // Remove leading and trailing commas with spaces
  const clearName = (value) => value?.replace(/(^[,()\s]+)|([,()\s]+$)/g, '');

  const handleSelectScrapedAddress = async (address) => {
    setLoaderState(STATUSES.LOADING);
    const scrapingErrorsList = [];

    if (!address.zip) {
      scrapingErrorsList.push('Zip could not be found');
    }

    const clearedState = clearName(address.state);
    const clearedCity = clearName(address.city);
    const scrapedState = states.find((item) => item.two_letter_code === clearedState || item.name === clearedState);

    try {
      if (scrapedState) {
        const response = await getCitiesByState(scrapedState.two_letter_code, clearedCity);
        const { results } = response;

        if (results.length === 0) {
          // Scraped city does not exists
          setContactInfo((draft) => {
            draft.city = buildNewCityId(clearedCity);
          });

          scrapingErrorsList.push(`City "${clearedCity}" could not be found`);
        } else {
          // City exists in our database
          setStateCities(results.map((item) => ({ key: item.name, text: item.name, value: item.id })));
          setContactInfo((draft) => {
            draft.city = results[0].id;
          });
        }
      } else {
        scrapingErrorsList.push(`State "${clearedState}" could not be found`);
        scrapingErrorsList.push(`City "${clearedCity}" could not be found`);
      }

      setContactInfo((draft) => {
        draft.zip = address.zip || '';
        draft.state = scrapedState?.two_letter_code || '';
        draft.address = clearName(address.address) || '';
      });

      formRef.current.resetForm();

      setScrapedResultError(scrapingErrorsList);

      setLoaderState(STATUSES.LOADED);
    } catch (e) {
      toast.error('Unable to select scraped address');
      console.error(e);
      setLoaderState(STATUSES.FAILURE);
    }
  };

  const onSubmit = (values) => {
    const city = stateCities.find((item) => item.value === values.city);
    setScrapedResultError(null);

    onUpdateAssets({ contact: { ...values, city: city.text } });
  };

  const debouncedGetStateCities = debounce(getStateCities, DEBOUNCE_TIMEOUT);

  const validationSchema = Yup.object({
    zip: Yup.string()
      .trim()
      .matches(US_CANADA_POSTAL_CODE_REGEX, 'Postal code must match US or Canada standard')
      .required('Required'),
    state: Yup.string()
      .required('Required')
      .oneOf(
        stateOptions.map((item) => item.value),
        'State is required'
      ),
    city: Yup.string()
      .required('Required')
      .oneOf(
        stateCities.map((item) => item.value.toString()),
        'City is required'
      ),
    address: Yup.string(),
    phone: Yup.string(),
  });

  const scrapedAddresses = scrapeResults?.addresses?.map((address, index) => (
    <List.Item key={index}>
      <ListContent floated="right">
        <Button size="tiny" onClick={() => handleSelectScrapedAddress(address)}>
          Select
        </Button>
      </ListContent>
      <ListContent>
        <ListHeader>
          {address.zip} {address.state} {address.city} {address.address}
        </ListHeader>
      </ListContent>
    </List.Item>
  ));

  const scrapedPhones = scrapeResults?.phones
    ?.filter((phone) => !!phone)
    .map((phone, index) => (
      <List.Item key={index}>
        <ListContent floated="right">
          <Button
            size="tiny"
            onClick={() =>
              setContactInfo((draft) => {
                draft.phone = phone;
              })
            }
          >
            Select
          </Button>
        </ListContent>
        <ListContent>
          <ListHeader>{phone}</ListHeader>
        </ListContent>
      </List.Item>
    ));

  return (
    <Tab.Pane padding="30px">
      <Dimmer active={loaderState === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <Formik
        innerRef={formRef}
        initialValues={contactInfo}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <UserCommunityPanelHeader
              title="Edit Contact Info"
              subtitle={`Enter new contact information for ${t('community')} and preview scraped contact information`}
              disabled={updateStatusActive}
              loading={updateStatusActive}
              onUpdate={() => handleSubmit()}
            />
            <Divider section horizontal>
              Current information
            </Divider>

            <Message
              onDismiss={() => setScrapedResultError(null)}
              hidden={!scrapedResultError || (scrapedResultError && scrapedResultError.length === 0)}
              error
              header="There was some errors with scraped address"
              list={scrapedResultError}
            />

            <Form>
              <InputField name="zip" label="ZIP" placeholder="ZIP Code" />

              <InputField
                control={Dropdown}
                fluid
                search
                selection
                label="State"
                name="state"
                options={stateOptions}
                placeholder="State"
                onChanged={() => {
                  setFieldValue('city', '');
                  setStateCities([]);
                }}
              />

              <InputField
                control={Dropdown}
                options={stateCities}
                name="city"
                label="City"
                placeholder="City"
                search
                selection
                fluid
                allowAdditions
                onAddItem={(e, { value }) => {
                  const newCityId = handleAddNewCity(value);
                  setFieldValue('city', newCityId);
                }}
                onOpen={() => getStateCities(values.state)}
                onSearchChange={(e, { searchQuery }) => debouncedGetStateCities(values.state, searchQuery)}
                loading={stateCitiesLoaderState === STATUSES.LOADING}
                disabled={!values?.state}
              />

              <InputField name="address" label="Address" placeholder="Street Address" />
              <InputField name="phone" label="Phone" placeholder="Phone number" />
            </Form>
          </>
        )}
      </Formik>

      <If condition={hasScrapedInfo}>
        <Divider section horizontal>
          Scraped information
        </Divider>
        <Header as="h5" attached="top">
          Address
        </Header>
        <Segment attached>
          <If condition={hasAddressInfo}>
            <List divided verticalAlign="middle">
              {scrapedAddresses}
            </List>
          </If>
          <If condition={!hasAddressInfo}>
            <Message info>No contact address information found!</Message>
          </If>
        </Segment>

        <Header as="h5" attached="top">
          Phone Number
        </Header>
        <Segment attached basic>
          <If condition={hasPhoneInfo}>
            <List divided verticalAlign="middle">
              {scrapedPhones}
            </List>
          </If>
          <If condition={!hasPhoneInfo}>
            <Message info>No phone number information found!</Message>
          </If>
        </Segment>
      </If>
    </Tab.Pane>
  );
};
