export const GENERAL_SIDEBAR_VARIANT = 'general';
export const COMMUNITIES_SIDEBAR_VARIANT = 'communities';

export const IS_CONNECTOR = 'IS';
export const IS_NOT_CONNECTOR = 'IS_NOT';

export const FLOW_TYPE = {
  ENTRY: 'entry',
  EXIT: 'exit',
};

export const CONNECTOR = {
  AND: 'and',
  OR: 'or',
};

export const CRITERIA_VALUE_TYPE = {
  STRING: 'string',
  INTEGER: 'integer',
  BOOLEAN: 'boolean',
  CONSTANT: 'constant',
  OPTION: 'option',
  RANGE_INTEGER: 'range_integer',
  DATE: 'date',
  DATE_RANGE: 'date_range',
  OPTION_DYNAMIC: 'option_dynamic',
};

export const CRITERIA_DEFAULT_VALUE = {
  [CRITERIA_VALUE_TYPE.STRING]: '',
  [CRITERIA_VALUE_TYPE.INTEGER]: 0,
  [CRITERIA_VALUE_TYPE.BOOLEAN]: true,
  [CRITERIA_VALUE_TYPE.CONSTANT]: null,
  [CRITERIA_VALUE_TYPE.OPTION]: [],
  [CRITERIA_VALUE_TYPE.DATE]: new Date(),
  [CRITERIA_VALUE_TYPE.DATE_RANGE]: [new Date(), new Date()],
  [CRITERIA_VALUE_TYPE.OPTION_DYNAMIC]: [],
};

export const OPERATORS = {
  IS: 'is',
  IS_NOT: 'is_not',
  IS_ANY_OF: 'is_any_of',
};
