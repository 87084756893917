import React, { useRef } from 'react';
import { useFormikContext } from 'formik';
import { Sticky } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button } from 'components/lib';
import { LeadPreview } from './LeadPreview';

const Wrapper = styled.div`
  flex: 0 0 30rem;

  .preview-panel {
    position: relative;
    top: 1rem;
    width: 30rem;
    background: #f9f9f9;
    padding: 1rem;
    border-radius: 0.25rem;

    .validation-error {
      color: red;
    }

    pre {
      white-space: break-spaces;
    }
  }
`;

const ValidPreviewPanel = ({ mappingCandidate, saveMappingConfig, leadPreview, ignoreMappingCandidate }) => {
  return (
    <>
      <LeadPreview leadInfo={leadPreview} />
      {!(mappingCandidate?.mapped_to || mappingCandidate?.ignored) && (
        <Button.Group vertical>
          <Button onClick={saveMappingConfig} primary>
            Save Mapping Config
          </Button>
          <Button onClick={ignoreMappingCandidate} primary>
            Ignore Mapping Candidate
          </Button>
        </Button.Group>
      )}
    </>
  );
};

export const PreviewPanel = ({
  mappingCandidate,
  lead,
  leadPreview,
  forceSubmit,
  generateLead,
  ignoreMappingCandidate,
}) => {
  const stickyContainerRef = useRef();
  const { values, errors, isValid, submitForm } = useFormikContext();
  const errorMessages =
    errors &&
    Object.entries(errors)
      // we use Yup to validate mappings, so we don't want to show those errors here
      .filter(([key, value]) => key !== 'mappings' && value)
      .map(([key, value]) => value);

  if (mappingCandidate?.mapped_to_hash) {
    return (
      <Wrapper ref={stickyContainerRef}>
        <Sticky context={stickyContainerRef}>
          <div className="preview-panel">
            Mapped to form #{mappingCandidate.mapped_to}: {mappingCandidate.mapped_to_hash}
            <br />
            <br />
            {lead ? (
              <ValidPreviewPanel
                mappingCandidate={mappingCandidate}
                leadPreview={lead}
                saveMappingConfig={submitForm}
                ignoreMappingCandidate={ignoreMappingCandidate}
              />
            ) : (
              <Button onClick={generateLead} primary fluid>
                Generate a Lead
              </Button>
            )}
          </div>
        </Sticky>
      </Wrapper>
    );
  }

  return (
    <Wrapper ref={stickyContainerRef}>
      <Sticky context={stickyContainerRef}>
        <div className="preview-panel">
          {isValid && leadPreview && (
            <ValidPreviewPanel
              mappingCandidate={mappingCandidate}
              leadPreview={leadPreview}
              saveMappingConfig={submitForm}
              ignoreMappingCandidate={ignoreMappingCandidate}
            />
          )}
          {!isValid && (
            <div>
              <h3>Validation Error</h3>
              <div>
                {errorMessages.map((message, i) => (
                  <pre key={i} className="validation-error">
                    {message}
                  </pre>
                ))}
              </div>

              <Button.Group vertical>
                <Button onClick={ignoreMappingCandidate} primary>
                  Ignore Mapping Candidate
                </Button>
                {errors?.lead && (
                  <Button
                    onClick={() => {
                      forceSubmit(values);
                    }}
                    primary
                  >
                    Save Anyway
                  </Button>
                )}

                {errors?.web_form && (
                  <Button
                    primary
                    onClick={() => {
                      forceSubmit(values);
                    }}
                  >
                    Link to existing WebForm
                  </Button>
                )}
              </Button.Group>
            </div>
          )}
        </div>
      </Sticky>
    </Wrapper>
  );
};
