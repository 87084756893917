import React from 'react';
import { Divider, Grid, Header, List } from 'semantic-ui-react';
import { useHistory } from 'react-router';

import GoBackLink from 'components/common/GoBackLink';
import { ApplicantConversation } from './ApplicantConversation';

const ApplicantDetails = ({ applicant }) => {
  const history = useHistory();

  if (!applicant) {
    return null;
  }

  return (
    <>
      <GoBackLink to="/job-applicants" goBack={!!history.location.state?.fromJobApplicants}>
        Back to Job Applicants
      </GoBackLink>
      <Divider hidden />
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <Header as="h3" dividing>
              Details
            </Header>
            <List relaxed="very">
              <List.Item>
                <List.Header>Name</List.Header>
                <List.Description>{applicant.full_name}</List.Description>
              </List.Item>
              <List.Item>
                <List.Header>Email</List.Header>
                <List.Description>{applicant.email}</List.Description>
              </List.Item>
              <List.Item>
                <List.Header>Phone</List.Header>
                <List.Description>{applicant.phone}</List.Description>
              </List.Item>
              <List.Item>
                <List.Header>Community</List.Header>
                <List.Description>{applicant.community_name}</List.Description>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={10}>
            <Header as="h3" dividing>
              Conversation
            </Header>
            <ApplicantConversation conversation={applicant.conversation} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default ApplicantDetails;
