import React, { useCallback, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Divider, Popup } from 'semantic-ui-react';

import { getCRMGlobalSettings, updateCRMGlobalSettings } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { Radio } from 'components/lib';
import { useCRM, setCRMGlobalSettings } from './context';
import { UserContext } from '../../UserContext';
import { USER_TYPES } from '../../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RadioBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export default function CRMGlobalSettings() {
  const [{ crmGlobalSettings }, dispatch] = useCRM();
  const { user } = useContext(UserContext);
  const isRegularUser = user?.type === USER_TYPES.REGULAR;

  const fetchCRMGlobalSettings = useCallback(async () => {
    try {
      const results = await getCRMGlobalSettings();
      dispatch(setCRMGlobalSettings(results));
    } catch (error) {
      toast.error(parseErrorResponse(error));
    }
  }, [dispatch]);

  useEffect(() => {
    isRegularUser && fetchCRMGlobalSettings();
  }, [dispatch, isRegularUser]);

  const handleChange = useCallback(async (_, target) => {
    dispatch(setCRMGlobalSettings({ ...crmGlobalSettings, [target.name]: target.checked }));
    const response = await updateCRMGlobalSettings({ [target.name]: target.checked });
    dispatch(setCRMGlobalSettings(response));
    toast.success('CRM Settings successfully updated!');
  });

  if (!isRegularUser) return null;

  return (
    <>
      <Divider />
      <Container>
        <Popup
          content="Enable this option to sync leads with CRM. When disabled, leads will not be synced with CRM, regardless of CRM integrations status."
          trigger={
            <RadioBox>
              <Radio
                toggle
                label="Sync with CRM"
                name="crm_enabled"
                onChange={handleChange}
                checked={crmGlobalSettings?.crm_enabled}
              />
            </RadioBox>
          }
        />
        <Popup
          content="Enable this option to send webform leads to CRM. If disabled, webform leads will not be sent to CRM"
          trigger={
            <RadioBox>
              <Radio
                toggle
                label="Submit Webform Leads to CRM"
                name="crm_submit_web_form_leads"
                onChange={handleChange}
                checked={crmGlobalSettings?.crm_submit_web_form_leads}
                disabled={!crmGlobalSettings?.crm_enabled}
              />
            </RadioBox>
          }
        />
        <Popup
          content="Notify the support team on Slack when CRM Integration is not set up for the community and leads are submitted."
          trigger={
            <RadioBox>
              <Radio
                toggle
                label="Notify on Missing CRM Integration"
                name="send_missing_crm_notifications"
                onChange={handleChange}
                checked={crmGlobalSettings?.send_missing_crm_notifications}
                disabled={!crmGlobalSettings?.crm_enabled}
              />
            </RadioBox>
          }
        />
      </Container>
      <Divider />
    </>
  );
}
