import React from 'react';
import { Dropdown } from 'components/lib';

const scoreOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((score) => ({
  key: score,
  value: score,
  text: `${score}%`,
}));

export const ScoreFilter = ({ min, max, onChangeMax, onChangeMin }) => {
  return (
    <>
      <Dropdown
        selection
        basic
        options={scoreOptions}
        placeholder="Min score"
        value={min}
        onChange={(e, d) => onChangeMin(d.value)}
        clearable
      />
      <Dropdown
        selection
        basic
        options={scoreOptions}
        placeholder="Max score"
        value={max}
        onChange={(e, d) => onChangeMax(d.value)}
        clearable
      />
    </>
  );
};
