import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

import { ChatModulesContext } from './ChatModulesContext';
import { ModuleWrapper } from './ModuleWrapper';
import { ModuleForm } from './modules/ModuleForm/ModuleForm';
import { ACTION_TYPES } from './ModuleGraph';

const Wrapper = styled.div`
  width: 25rem;
`;

export const NoModules = () => {
  const { addRootModule } = useContext(ChatModulesContext);
  const [showModuleForm, setShowModuleForm] = useState(false);
  const rootModule = {
    text: null,
    module_type: 'option',
    role: null,
    action: null,
    number: 1,
    answers: [
      {
        text: 'Close Bot',
        rank: 0,
        action: null,
        meta: {
          actionType: ACTION_TYPES.CLOSE,
        },
      },
    ],
    module_traits: [],
  };

  const submit = ({ module }) => {
    addRootModule(module);
  };

  return (
    <Wrapper>
      <ModuleWrapper>
        {showModuleForm && (
          <ModuleForm
            node={{ module: rootModule }}
            onSubmit={submit}
            onCancel={() => {
              setShowModuleForm(false);
            }}
          />
        )}
        {!showModuleForm && (
          <>
            <p>
              This chat doesn't have any dialog flows. <br /> To create one, start by adding a root chat module.
            </p>
            <Button
              primary
              icon="add"
              content="Add Root Module"
              onClick={() => {
                setShowModuleForm(true);
              }}
            />
          </>
        )}
      </ModuleWrapper>
    </Wrapper>
  );
};
