import React, { useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { useField } from 'formik';
import { If } from 'components/If';

export const OptionalField = ({
  as: Comp,
  satellite: SatelliteComp,
  satelliteClass = '',
  fieldLabel,
  enabled,
  defaultValue,
  ...props
}) => {
  const [checked, setChecked] = useState(!!enabled);
  const _defaultValue = typeof defaultValue !== 'undefined' ? defaultValue : '';
  const [field, , helpers] = useField(props);
  const { value, ...otherFieldProps } = field;
  const { setValue } = helpers;

  const toggleEnabled = () => {
    if (!checked) {
      setValue(value !== null ? value : _defaultValue);
    } else {
      setValue(null);
    }
    setChecked(!checked);
  };

  return (
    <>
      <label>
        <Checkbox label={fieldLabel} checked={checked} onChange={toggleEnabled} />
      </label>
      <Comp {...otherFieldProps} {...props} disabled={!checked} value={value !== null ? value : ''} />
      {/* If we only use If component to display this optional SatelliteComp component then we get a warning in the console because for some reason it tries to render it. */}
      <If condition={checked}>{!!SatelliteComp && <SatelliteComp name={props.name} className={satelliteClass} />}</If>
    </>
  );
};
