import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getContactRules, createContactRule, getContactRuleOptions, updateContactRule, deleteContactRule } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PAGINATION } from '../../../constants';
import { SettingsContext } from '../SettingsContext';
import { ANY_MATCH_FILTER } from './constants';

const ContactRuleContext = createContext();
const INITIAL_STATE = {
  filters: {
    search: '',
    match: ANY_MATCH_FILTER,
    page: PAGINATION.PAGE,
    pageSize: PAGINATION.PAGE_SIZE,
  },
  formConfig: {
    matchOptions: [],
    conditionOptions: [],
  },
};

export const ContactRulesProvider = ({ children }) => {
  const { communities } = useContext(SettingsContext);
  const [contactRules, setContactRules] = useState();
  const [filters, setFilters] = useState(INITIAL_STATE.filters);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [formConfig, setFormConfig] = useState(INITIAL_STATE.formConfig);
  const [contactRuleId, setContactRuleId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = useCallback(async () => {
    try {
      setLoading(true);
      const { matchOptions, conditionOptions } = await getContactRuleOptions();

      setFormConfig({
        matchOptions: matchOptions.map((o) => ({ value: o[0], text: o[1] })),
        conditionOptions: conditionOptions.map((o) => ({ value: o[0], text: o[1] })),
      });
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to fetch contact rules'));
    }
  }, []);

  const fetchContactRules = useCallback(async (filters) => {
    try {
      setLoading(true);
      const { search, page, pageSize, match } = filters;
      const { results, count } = await getContactRules({
        search,
        page,
        pageSize,
        match: match === ANY_MATCH_FILTER ? null : match,
      });

      setContactRules(results);
      setTotalPages(Math.ceil(count / filters.pageSize));
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to fetch contact rules'));
    } finally {
      setLoading(false);
    }
  }, []);

  const handleFiltersChange = (values) => {
    setFilters({ ...filters, ...values, page: PAGINATION.PAGE });
  };

  const handlePageChange = (page) => {
    setFilters((draft) => ({ ...draft, page }));
  };

  const handleOpenModal = ({ contactRuleId } = { contactRuleId: null }) => {
    setContactRuleId(contactRuleId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setContactRuleId(null);
    setOpenModal(false);
  };

  const addContactRule = async (values) => {
    try {
      setCreateLoading(true);
      const response = await createContactRule(values);
      setContactRules([...contactRules, response]);
      toast.success('Contact rule created successfully');
      setOpenModal(false);
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to create contact rule'));
    } finally {
      setCreateLoading(false);
    }
  };

  const editContactRule = async (values) => {
    try {
      setUpdateLoading(true);
      const response = await updateContactRule(contactRuleId, values);
      setContactRules(
        contactRules.map((c) => {
          if (c.id === response.id) {
            return { ...c, ...response };
          }
          return c;
        })
      );
      toast.success('Contact rule updated successfully');
      setOpenModal(false);
      setContactRuleId(null);
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e, 'Unable to update contact rule'));
    } finally {
      setUpdateLoading(false);
    }
  };

  const removeContactRule = async () => {
    try {
      setDeleteLoading(true);
      await deleteContactRule(contactRuleId);
      setContactRules(contactRules.filter((c) => c.id !== contactRuleId));
      setContactRuleId(null);
      setOpenModal(false);
      toast.success('Contact rule removed successfully');
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e, 'Unable to remove contact rule'));
    } finally {
      setDeleteLoading(false);
    }
  };

  const value = {
    // State
    communities,
    contactRules,
    totalPages,
    loading,
    filters,
    formConfig,
    createLoading,
    contactRuleId,
    openModal,
    updateLoading,
    deleteLoading,
    // API
    fetchContactRules,
    handleFiltersChange,
    handleOpenModal,
    handleCloseModal,
    addContactRule,
    editContactRule,
    removeContactRule,
    handlePageChange,
  };

  return <ContactRuleContext.Provider value={value}>{children}</ContactRuleContext.Provider>;
};

export const useContactRules = () => {
  const ctx = useContext(ContactRuleContext);

  if (!ctx) {
    throw new Error('useContactRules must be used within the ContactRulesProvider');
  }

  return ctx;
};
