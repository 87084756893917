import React, { useMemo } from 'react';

import styled from 'styled-components';
import { FieldArray } from 'formik';

import { InputField } from 'components/common/fields/InputField';
import { MEDIA_BREAK_POINTS } from '../../../constants';
import { OperationSign, OperationBox } from './wrappers';
import CRMSourceMappingUrlParameterRuleTable from './UrlParameterRuleTable';

const ThenOperationBox = styled.div`
  display: flex;
  margin: 0 !important;
  height: fit-content;

  & > div {
    flex-grow: 1;
    padding: 0 5px;
  }

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    & > div {
      padding: 0;
    }
  }
`;

const UrlParameterRule = ({ baseFieldName, rule, urlParameterOptions, disabled }) => {
  return (
    <>
      <OperationBox>
        <FieldArray name={`${baseFieldName}.clauses`}>
          {({ remove, push, replace }) => (
            <CRMSourceMappingUrlParameterRuleTable
              ruleId={rule.id}
              name={`${baseFieldName}.clauses`}
              urlParameterOptions={urlParameterOptions}
              clauses={rule.clauses}
              onPush={push}
              onRemove={remove}
              onReplace={replace}
              disabled={disabled}
            />
          )}
        </FieldArray>
      </OperationBox>
      <ThenOperationBox>
        <OperationSign>then</OperationSign>
        <div>
          <InputField placeholder="Result Value" name={`${baseFieldName}.result_value`} disabled={disabled} />
        </div>
      </ThenOperationBox>
    </>
  );
};

export default UrlParameterRule;
