import React, { useContext } from 'react';
import { Icon } from 'semantic-ui-react';

import { Table } from 'components/lib';
import { FeaturesContext } from './FeaturesContext';

import { FEATURE_ACTIVE_STATE } from './constants';

const FeaturesOverviewCommunitiesStatuses = ({ community }) => {
  const { FEATURE_TYPES } = useContext(FeaturesContext);

  return (
    <>
      {Object.keys(FEATURE_TYPES).map((featureType) => {
        const feature = community.features.find((feature) => feature.type === parseInt(featureType));
        return (
          <Table.Cell key={featureType} textAlign="center">
            {feature && feature.status === FEATURE_ACTIVE_STATE ? (
              <Icon name="check circle" color="green" />
            ) : (
              <Icon name="close" color="red" />
            )}
          </Table.Cell>
        );
      })}
    </>
  );
};

export default FeaturesOverviewCommunitiesStatuses;
