import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Pagination } from 'semantic-ui-react';

import { Table } from 'components/lib';
import { getFeaturesOverviewUserCommunities } from 'apis';
import { STATUSES } from '../../../constants';
import useDataList from '../../../utils/hooks/useDataList';

import { parseErrorResponse } from '../../../apis/utils';
import SearchInput from '../../SearchInput';
import FeaturesOverviewCommunitiesList from './FeaturesOverviewCommunitiesList';
import FeaturesOverviewListHeader from './FeaturesOverviewListHeaders';

const FeaturesOverviewCommunities = () => {
  const { userId } = useParams();

  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { items, totalPages, filters, setItems, setPage, setSearch } = useDataList({
    initialState: { items: [] },
  });

  useEffect(() => {
    const fetchCommunities = async ({ page, pageSize, search }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await getFeaturesOverviewUserCommunities(userId, {
          page,
          page_size: pageSize,
          search,
        });

        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch user communities for feature usage overview');
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    fetchCommunities(filters);
  }, [filters]);

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading communities...</Loader>
      </Dimmer>

      <SearchInput value={filters.search} fluid onSearch={handleSearch} placeholder="Search communities by name" />
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <FeaturesOverviewListHeader />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <FeaturesOverviewCommunitiesList communities={items} />
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="8" textAlign="left">
              {totalPages > 1 && (
                <Pagination activePage={filters.page} onPageChange={handlePageChange} totalPages={totalPages} />
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default FeaturesOverviewCommunities;
