import React from 'react';
import styled from 'styled-components';

import { Button, Icon } from 'components/lib';
import { createExperiment } from 'apis';
import { useExperiments } from './context/ExperimentsContext';
import { ExperimentEffects } from './ExperimentEffects';

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  width: 100%;
  gap: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  h2 {
    font-family: var(--font-family-bold);
    font-size: 60px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.97;
    letter-spacing: normal;
    color: #222;
    margin: 0;
  }
  .beta {
    padding: 5px 10px 3px;
    border-radius: 4px;
    background-color: #f0f0ff;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5f5fff;
    margin-left: 10px;
    align-self: flex-start;
  }
  .create-test {
    margin-left: auto;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background-color: #5f5fff;
  border-radius: 10px;
  margin-right: 15px;
`;

export const ExperimentsRootTopSection = () => {
  const { experimentEffects, getExperiments } = useExperiments();

  // Assuming we'll only run experiments on a single (regular VSA) chat, initially
  const effects = experimentEffects && experimentEffects[0]?.effects;

  const handleCreateExperiment = async () => {
    try {
      const chatId = prompt('Please enter the chat ID for which you want to create an experiment');
      if (!chatId) {
        return;
      }
      await createExperiment({ chat: parseInt(chatId), types: ['FLOW'] });
      await getExperiments();
    } catch (error) {
      console.error('Error creating experiment', error);
    }
  };

  return (
    <TopSection>
      <HeadingContainer>
        <IconContainer>
          <Icon name="merge_type" size={24} color="white" />
        </IconContainer>
        <h2>Experiments</h2>
        <span className="beta">BETA</span>
        <Button primary color="blue" className="create-test" onClick={handleCreateExperiment}>
          Create
        </Button>
      </HeadingContainer>
      <ExperimentEffects effects={effects} />
    </TopSection>
  );
};

export default ExperimentsRootTopSection;
