import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Tab } from 'components/lib';
import { UserCommunityHome } from './panels/UserCommunityHome';
import { UserCommunityScrape } from './scrape/UserCommunityScrape';
import { UserContext } from '../UserContext';
import { UserCommunityImages } from './panels/UserCommunityImages';
import { UserCommunityFloorPlans } from './panels/UserCommunityFloorPlans';
import { UserCommunityContact } from './panels/UserCommunityContact';
import { UserCommunityIcon } from './panels/UserCommunityIcon';
import { UserCommunityTestimonials } from './panels/UserCommunityTestimonials';
import { USER_TYPES } from '../../constants';
import UserCommunityMessagingAssistantNotes from './panels/UserCommunityMessagingAssistantNotes';
import { UserCommunityVideos } from './panels/UserCommunityVideos';
import UserCommunityStaff from './panels/UserCommunityStaff';
import { CommunitiesContextAwareUserCommunitySurveyData } from './panels/UserCommunitySurveyData';
import { CommunitiesContext } from '../common/communities/CommunitiesContext';

const panes = [
  {
    menuItem: 'Home',
    render: () => <UserCommunityHome />,
  },
  {
    menuItem: 'Images',
    render: () => <UserCommunityImages />,
  },
  {
    menuItem: 'Videos',
    render: () => <UserCommunityVideos />,
  },
  {
    menuItem: 'Floor Plan',
    render: () => <UserCommunityFloorPlans />,
  },
  {
    menuItem: 'Contact Info',
    render: () => <UserCommunityContact />,
  },
  {
    menuItem: 'Testimonials & Facts',
    render: () => <UserCommunityTestimonials />,
  },
  {
    menuItem: 'Icon',
    render: () => <UserCommunityIcon />,
  },
  {
    menuItem: 'Scrape',
    visibile: [USER_TYPES.STAFF],
    render: () => <UserCommunityScrape />,
  },
  {
    menuItem: 'Messaging Assistant Notes',
    visibile: [USER_TYPES.STAFF],
    render: () => <UserCommunityMessagingAssistantNotes />,
  },
  {
    menuItem: 'Staff',
    visibile: [USER_TYPES.STAFF],
    render: () => <UserCommunityStaff />,
  },
  {
    menuItem: 'Survey Data',
    visibile: (user, community) =>
      community?.facility_type === 'assisted_living' && user?.impersonated_session?.type === USER_TYPES.STAFF,
    render: () => <CommunitiesContextAwareUserCommunitySurveyData />,
  },
];

export const UserCommunityPanels = () => {
  const { user } = useContext(UserContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(panes[0].menuItem);
  const {
    state: { activeCommunity },
  } = useContext(CommunitiesContext);

  const onTabChange = (e, { panes, activeIndex }) => {
    setActiveTab(panes[activeIndex].menuItem);
    setActiveIndex(activeIndex);
  };

  const panesFinal = useMemo(
    () =>
      panes.filter((pane) => {
        if (!pane.visibile) {
          return true;
        }
        if (typeof pane.visibile === 'function') {
          return pane.visibile(user, activeCommunity);
        }
        return user?.impersonated_session?.type === USER_TYPES.STAFF || pane.visibile?.includes(user?.type);
      }),
    [user, activeCommunity]
  );

  useEffect(() => {
    if (panesFinal.findIndex((pane) => pane.menuItem === activeTab) < 0) {
      setActiveTab(panesFinal[0].menuItem);
      setActiveIndex(0);
    }
  }, [panesFinal, activeTab]);

  return (
    <Tab
      panes={panesFinal}
      activeIndex={activeIndex}
      onTabChange={onTabChange}
      menu={{ secondary: true, pointing: true }}
      wrap
    />
  );
};
