import React, { useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { getAutomatedAnswerDebug, getAutomatedAnswerReplay } from 'apis';
import { Button, Loader, Modal } from 'components/lib';
import { STATUSES } from 'constants';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { If } from 'components/If';
import { set } from 'date-fns';

const PromptTitle = styled.h3`
  font-family: var(--font-family-bold);
  font-size: 20px;
  padding-right: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 24px;
`;

const ModalContent = styled(Modal.Content)`
  &&&& {
    font-family: var(--font-family);
    padding: 30px 20px 30px 30px;
  }
`;

const Step = styled.div`
  margin-bottom: 20px;
  border-radius: 5px;
  margin-right: 10px;

  > div {
    padding: 5px 20px;
    background-color: inherit;
  }

  .title {
    border-radius: 5px 5px 0 0;
    background-color: inherit;
    filter: brightness(95%);
    font-family: var(--font-family-bold);
  }
`;

const ProspectMessage = styled(Step)`
  background-color: #f5f5f5;
`;

const Entities = styled(Step)`
  background-color: #fff4e8;

  .entities {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Simplifier = styled(Step)`
  background-color: #fff5d7;

  > ul {
    padding-bottom: 10px;
  }
`;

const QUModel = styled(Step)`
  background-color: #efffea;
`;

const Postprocessors = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Processor = styled(Step)`
  background-color: #effdff;
  flex-grow: 1;
`;

const Results = styled(Step)`
  background-color: #f5f5f5;
`;

const Flags = styled(Step)`
  background-color: #fde5e5;
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
`;

const Label = styled.div`
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: inherit;
  filter: brightness(95%);

  > span {
    background-color: white;
    font-family: var(--font-family-bold);
    font-size: 12px;
    margin-left: 5px;
    padding: 3px 5px;
    border-radius: 10px;
  }
`;

const Labels = ({ labels }) => {
  if (!labels) {
    return null;
  }
  return (
    <LabelsWrapper>
      {Object.keys(labels).map((label) => (
        <Label key={label}>
          {label}
          <span>{labels[label]}</span>
        </Label>
      ))}
    </LabelsWrapper>
  );
};

export const ConversationMessageDebugModal = ({ lead, messageType, messages, onClose }) => {
  const lastMessage = messages[messages.length - 1];
  const leadId = lead.id;
  const messageId = lastMessage.id;
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.AUTOMATED_ANSWER_DEBUG, { leadId, messageType, messageId }],
    () => getAutomatedAnswerDebug(leadId, messageType, messageId),
    { placeholderData: {} }
  );
  const [replay, setReplay] = useState({ loading: false, data: null });

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  const onReplay = async () => {
    setReplay({ loading: true, data: null });
    const replayData = await getAutomatedAnswerReplay(leadId, messageType, messageId);
    setReplay({ loading: false, data: replayData });
  };

  const onDownloadContext = () => {
    // TODO: implement downloading context needed to rerun processing
  };

  const message = messages.map((msg) => msg.text).join('\n');
  const displayData = replay.loading || replay.data ? replay.data : data;
  const simplifiedMessages = displayData?.debug_info?.simplified_messages || [];
  const flags = displayData?.flags || {};
  return (
    <Modal size="tiny" closeIcon open={messages.length > 0} onClose={onClose}>
      <ModalContent>
        <PromptTitle>Automated answer debug details</PromptTitle>
        <ProspectMessage>
          <div className="title">Prospect message{messages.length > 1 ? 's' : ''}</div>
          <div>{message}</div>
        </ProspectMessage>
        <Loader active={!displayData?.model} />
        <If condition={displayData?.model}>
          {displayData?.entities?.length > 0 && (
            <Entities>
              <div className="title">Entities</div>
              <div className="entities">
                {displayData.entities.map((entity, i) => (
                  <Label key={i}>
                    {entity.value}
                    <span>{entity.type}</span>
                  </Label>
                ))}
              </div>
            </Entities>
          )}
          {simplifiedMessages?.length > 0 && (
            <Simplifier>
              <div className="title">Simplifier</div>
              <ul>
                {simplifiedMessages.map((msg, i) => (
                  <li key={i}>{msg}</li>
                ))}
              </ul>
            </Simplifier>
          )}
          <QUModel>
            <div className="title">Model - {displayData?.model || 'N/As'}</div>
            <Labels labels={displayData?.debug_info?.model} />
          </QUModel>
          {displayData?.debug_info?.changes?.length > 0 && (
            <Postprocessors>
              {displayData.debug_info.changes.map((change, i) => (
                <Processor key={i}>
                  <div className="title">{change.processor}</div>
                  <Labels labels={change.intents} />
                </Processor>
              ))}
            </Postprocessors>
          )}
          {displayData?.used_labels && (
            <Results>
              <div className="title">Used labels</div>
              <div>{displayData?.used_labels?.join(', ')}</div>
            </Results>
          )}
          {Object.keys(flags).length > 0 && (
            <Flags>
              <div className="title">Flags</div>
              <Labels labels={flags} />
            </Flags>
          )}
        </If>
      </ModalContent>
      <Modal.Actions>
        <Button primary onClick={onDownloadContext} disabled>
          Download Context
        </Button>
        <Button primary onClick={onReplay} disabled={replay.loading}>
          Replay
        </Button>
        <Button basic onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
