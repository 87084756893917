import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const UserAccountConfirmDelete = ({ open, user, loading, onClose, onConfirm }) => {
  if (!user) {
    return null;
  }

  return (
    <Modal open={open}>
      <Modal.Header>Delete user account</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to delete <b>{user.email}</b> user account?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} content="Cancel" disabled={loading} />
        <Button onClick={onConfirm} negative content="Delete" disabled={loading} loading={loading} />
      </Modal.Actions>
    </Modal>
  );
};

export default UserAccountConfirmDelete;
