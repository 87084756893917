import { useEffect, useState } from 'react';
import { PAGINATION } from '../../../constants';
import { getDateTimeRange, useUrlSearchParams } from '../../../utils/hooks';
import { formatAnyDate } from '../../utils';
import { DEFAULT_FEATURES_DROPDOWN_VALUE } from './constants';

export const useFeatureOverviewFilters = () => {
  const { setUrlSearchParams } = useUrlSearchParams();
  const queryParams = new URLSearchParams(window.location.search);
  const [startDate, endDate] = getDateTimeRange(queryParams, 7);

  const [filters, setFilters] = useState({
    page: queryParams.get('page') || PAGINATION.PAGE,
    pageSize: queryParams.get('pageSize') || PAGINATION.PAGE_SIZE,
    startDate: formatAnyDate(startDate, 'yyyy-MM-dd'),
    endDate: formatAnyDate(endDate, 'yyyy-MM-dd'),
    actor: queryParams.get('actor') || DEFAULT_FEATURES_DROPDOWN_VALUE,
    feature: queryParams.get('feature') || DEFAULT_FEATURES_DROPDOWN_VALUE,
    status: queryParams.get('status') || DEFAULT_FEATURES_DROPDOWN_VALUE,
  });

  useEffect(() => {
    setUrlSearchParams(filters, true);
  }, [filters]);

  return [filters, setFilters];
};
