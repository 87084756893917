import React from 'react';
import styled from 'styled-components';

import { Modal as ComponentsModal, ProgressBar } from 'components/lib';
import { useCampaignsTemplate } from '../CampaignsTemplateContext';
import { TEMPLATE_STEPS } from '../TemplateWizard/constants';

const Modal = styled(ComponentsModal)`
  && {
    && > .header {
      border-bottom: 1px solid var(--line-standard);
      height: 70px;
      padding: 0 25px 0 32px;
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      background-position: left center;
    }
    &.tiny,
    &.large {
      width: 942px;
      height: 640px;
    }
  }
`;

const HeaderText = styled.div`
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-right: 213px;
  span {
    color: #ff5b98;
  }
`;

const Content = styled.div`
  height: 489px;
  padding-top: 20px;
`;

export const WizardModal = ({ open }) => {
  const { state, dispatch, actions } = useCampaignsTemplate();
  const { currentStep } = state;

  const handleModalClose = () => {
    dispatch({ type: actions.SET_WIZARD_ACTIVE, data: false });
  };

  const progress = (currentStep / TEMPLATE_STEPS.length) * 100;
  const Step = TEMPLATE_STEPS[currentStep - 1]?.component || null;

  return (
    <Modal open={open} closeIcon size="large" onClose={handleModalClose}>
      <Modal.Header>
        <HeaderText>Create from Template</HeaderText>
        <ProgressBar progress={progress} width="180px" />
      </Modal.Header>
      <Modal.Content>
        <Content>
          <Step />
        </Content>
      </Modal.Content>
    </Modal>
  );
};
