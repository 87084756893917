import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { DateRangePicker, MultiSelectFilter } from 'components/common';
import { Icon } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { last30Days } from '../NurtureFilterContext';
import { MEDIA_BREAK_POINTS, PAGINATION } from '../../../constants';
import { useCampaigns } from './CampaignsContext';
import { CreateWithAI } from './AI';
import { CreateFromTemplate } from './Template';

const GroupIcon = (props) => <Icon name="local_offer" variant="outlined" {...props} />;
const GROUPS = 'groups';

export const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-flow: wrap;
  padding: 1rem;
  justify-content: space-between;
`;

const Subfilters = styled.div`
  display: flex;
  gap: 5px;
  flex-flow: nowrap;
  flex-direction: column;
  justify-content: flex-end;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.WEB}px) {
    justify-content: flex-start;
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
  }

  .ui.button.reset-filters {
    height: 2.53rem;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.92857143rem;
    background-color: #f9f9f9;
    border: 1px solid rgba(34, 36, 38, 0.05);

    &:hover {
      color: rgba(0, 0, 0, 0.9);
    }
  }
`;

const PerformanceText = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--text);
  margin-right: 10px;
  flex-shrink: 0;
`;

const CampaignsWrapper = styled.div`
  padding: 0 30px;
  display: flex;
  max-width: 1440px;
  margin-top: 20px;
  justify-content: flex-start;

  @media only screen and (max-width: 1400px) {
    flex-wrap: wrap;
  }
`;

const PickerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CampaignsFilters = ({ hideDatePicker }) => {
  const { t } = useTranslation();
  const {
    state: { communities, filters, availableCampaigns, groups },
    dispatch,
    actions,
    setPage,
  } = useCampaigns();
  const [render, setRender] = useState(1);
  const [selectedType, setSelectedType] = useState(filters?.groups?.length > 0 ? GROUPS : 'communities');

  useEffect(() => {
    if (filters?.communities?.length > 0) {
      setSelectedType('communities');
    }
    if (filters?.groups?.length > 0) {
      setSelectedType(GROUPS);
    }
  }, [filters.communities, filters.groups]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    dispatch({ type: actions.SET_FILTERS_DATEPICKER, data: { ...filters, start_date: startDate, end_date: endDate } });
  };

  const onSelectedCommunitiesChange = (selectedCommunities) => {
    dispatch({ type: actions.SET_RESET_CAMPAIGNS, data: null });
    dispatch({ type: actions.SET_FILTERS, data: { ...filters, communities: selectedCommunities, groups: [] } });
    setPage(PAGINATION.PAGE);
  };

  const onSelectedGroupsChange = (selectedGroups) => {
    dispatch({ type: actions.SET_RESET_CAMPAIGNS, data: null });
    dispatch({ type: actions.SET_FILTERS, data: { ...filters, groups: selectedGroups, communities: [] } });
    setPage(PAGINATION.PAGE);
  };

  const onSelectedChange = (selected, st) => {
    setSelectedType(st);
    switch (st) {
      case 'groups':
        onSelectedGroupsChange(selected);
        break;
      case 'communities':
        onSelectedCommunitiesChange(selected);
        break;
      default:
        break;
    }
  };

  const resetFilters = () => {
    dispatch({
      type: actions.SET_FILTERS,
      data: {
        ...filters,
        start_date: last30Days,
        communities: null,
        selected_campaigns: null,
        groups: null,
      },
    });
    dispatch({ type: actions.SET_RESET_CAMPAIGNS, data: null });
    // For some reason, the above dispatch doesn't trigger this component to re-render, so we force it here
    setRender(render + 1);
  };

  const options = {
    communities,
    groups: groups && groups.map((g) => ({ ...g, Icon: GroupIcon.bind(null, { color: g.color }) })),
  };

  return (
    <CampaignsWrapper>
      <If condition={!hideDatePicker}>
        <PickerWrapper>
          <PerformanceText>Performance in</PerformanceText>
          <DateRangePicker dateRange={filters} onFilterChange={onDateRangeChange} resetFilterChange />
        </PickerWrapper>
      </If>
      <Subfilters>
        <MultiSelectFilter
          closeOnApply
          name={capitalize(t('communities'))}
          placeholder={`Filter ${capitalize(t('communities'))} or Groups`}
          options={options}
          selectedValues={filters && filters[selectedType]}
          onChange={onSelectedChange}
          selectedOptionTypeDefault={selectedType}
        />
        <Button icon="close" className="reset-filters" onClick={resetFilters} />
      </Subfilters>
      <CreateFromTemplate />
      <CreateWithAI />
    </CampaignsWrapper>
  );
};
