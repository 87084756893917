import { useEffect, useState } from 'react';

const INIT_TIMER = { seconds: undefined, minutes: undefined };

function useCallTimer(date) {
  const [totalSeconds, setTotalSeconds] = useState();
  const [formattedTime, setFormattedTime] = useState();
  const [timer, setTimer] = useState(INIT_TIMER);

  useEffect(() => {
    if (!date) {
      return;
    }
    const diffUntilNow = new Date().getTime() - date.getTime();
    setTotalSeconds(Math.abs(diffUntilNow / 1000));
    const interval = setInterval(() => {
      setTotalSeconds((t) => t + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [date]);

  useEffect(() => {
    if (totalSeconds) {
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = Math.floor(totalSeconds % 60);
      setTimer({ seconds, minutes });
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      setFormattedTime(`${formattedMinutes}:${formattedSeconds}`);
    } else {
      setTimer(INIT_TIMER);
      setFormattedTime(null);
    }
  }, [totalSeconds]);

  return { timer, formattedTime };
}

export default useCallTimer;
