import React, { useContext, useMemo, useState } from 'react';
import { Icon, Label, Accordion } from 'semantic-ui-react';
import styled from 'styled-components';

import { If } from 'components/If';

import { AutomatedAnswer } from './AutomatedAnswer';
import { LabelItem } from './Labelitem';
import { MessageContext } from './MessageContext';
import { ScheduleTourCallSuggestion } from './ScheduleTourCallSuggestion';

const LabelGroup = styled.div`
  margin-bottom: 10px;

  .title {
    margin-bottom: 5px;
  }
`;

const STARRED_LABELS = [
  'additional_resources',
  'address',
  'availability',
  'budget_assistance',
  'call_request',
  'call_confirm',
  'call_to_tour_suggest',
  'conversation_end',
  'costs',
  'information_request',
  'low_income',
  'email_confirm',
  'entrance_fee',
  'facility_name',
  'general_info',
  'jobs',
  'not_interested',
  'not_ready',
  'phone',
  'still_interested',
  'thx_response',
  'tour',
  'tour_reschedule',
  'tour_scheduled',
  'tour_confirm',
  'tour_suggest',
  'unknown_budget',
];

export const Labels = () => {
  const { labels, contentLabels } = useContext(MessageContext);
  const [allLabelsVisible, setAllLabelsVisible] = useState(true);
  const groupedLabels = useMemo(() => {
    const groups = {};
    const selectedIds = labels?.map(({ id }) => id);
    contentLabels?.forEach((l) => {
      let intentGroup = groups[l.intent];
      if (!intentGroup) {
        intentGroup = [];
        groups[l.intent] = intentGroup;
      }
      intentGroup.push({ ...l, selected: selectedIds.includes(l.id) });
    });
    return groups;
  }, [contentLabels, labels]);

  const toggleAllLabels = () => setAllLabelsVisible(!allLabelsVisible);

  return (
    <If condition={contentLabels}>
      <LabelGroup>
        <div className="title">Automated Answer</div>
        <AutomatedAnswer groupedLabels={groupedLabels} />
        <ScheduleTourCallSuggestion />
      </LabelGroup>
      <LabelGroup>
        <div className="title">Starred Answers</div>
        <Label.Group>
          {STARRED_LABELS?.map((intent, i) => {
            const intentLabels = groupedLabels[intent];
            return intentLabels && <LabelItem key={i} label={intent} options={intentLabels} />;
          })}
        </Label.Group>
      </LabelGroup>
      <Accordion>
        <Accordion.Title active={allLabelsVisible} onClick={toggleAllLabels}>
          <Icon name="dropdown" />
          All Answers
        </Accordion.Title>
        <Accordion.Content active={allLabelsVisible}>
          <LabelGroup>
            <Label.Group>
              {Object.keys(groupedLabels).map((intent) => {
                const intentLabels = groupedLabels[intent];
                return <LabelItem key={intent} label={intent} options={intentLabels} />;
              })}
            </Label.Group>
          </LabelGroup>
        </Accordion.Content>
      </Accordion>
    </If>
  );
};
