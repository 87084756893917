import React, { useContext } from 'react';
import styled from 'styled-components';
import { Header, Message } from 'semantic-ui-react';

import { If } from 'components/If';
import TourForm from './TourForm';
import TourCancelModal from './TourCancelModal';
import { ToursContext } from './ToursContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 4.42rem);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50rem;
  margin: 3rem auto;
  padding: 4rem;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.3rem;
  box-shadow: 0 5px 5px -2px rgb(190 206 234);

  .ui {
    &.form {
      & > p {
        margin-bottom: 0;
      }

      .field {
        margin-bottom: 1.5rem;
      }
    }

    &.button {
      margin-top: 0.6rem;
    }
  }
`;

const StyledMessage = styled(Message)`
  width: 100%;
`;

const CancelTourText = styled.div`
  margin: 1.5rem 0;
`;

const CancelTourLink = styled.button`
  color: #4183c4;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

export const TourDetails = () => {
  const { tour, toggleCloseModal } = useContext(ToursContext);

  return (
    <Wrapper>
      <Container>
        <Header as="h2">Edit {tour?.request_type}</Header>

        <StyledMessage warning hidden={!tour.cancelled}>
          <Message.Header>{tour?.request_type} is cancelled!</Message.Header>
          <p>You are not able to update cancelled {tour?.request_type.toLowerCase()}.</p>
        </StyledMessage>

        <p>
          <b>{tour.lead.name}</b> would like to {tour?.request_type.toLowerCase()} <b>{tour.community_name}?</b>
        </p>

        <TourForm />

        <If condition={!tour.cancelled}>
          <>
            <CancelTourText>
              If you want to cancel your {tour?.request_type.toLowerCase()}, please click{' '}
              <CancelTourLink onClick={toggleCloseModal}>here</CancelTourLink>
            </CancelTourText>
            <TourCancelModal />
          </>
        </If>
      </Container>
    </Wrapper>
  );
};
