import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Segment } from 'semantic-ui-react';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PercentCircle, SimpleMetric } from 'components/common/analytics';
import { formatCount } from 'components/utils';
import { STATUSES } from 'constants';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const metrics = ['answers.sent', 'answers.automated', 'answers.conversations', 'answers.conversations.automated'];

const Automated = () => {
  const { automatedAnswerFilters: filters } = useSystemStatsFilters();
  const { data, error, status, isFetching } = useQuery(
    [queryConstants.ADMIN_METRICS, { ...filters, metrics }],
    () => getMetrics(metrics, filters),
    { placeholderData: {} }
  );

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch calls metrics'));
  }

  const circleData = useMemo(
    () =>
      data
        ? {
            Automated: data['answers.automated']?.value,
            Manual: data['answers.sent']?.value - data['answers.automated']?.value,
          }
        : {},
    [data]
  );

  return (
    <Segment basic loading={loaderStatus === STATUSES.LOADING}>
      <PercentCircle
        data={circleData}
        units="Sent Messages"
        loaderStatus={loaderStatus}
        radius={150}
        circleWidth={15}
        additionalMetrics={
          <>
            <SimpleMetric
              metric={formatCount(data['answers.conversations']?.value)}
              unit="conversations"
              fontSize="24px"
              unitFontSize="16px"
            />
            <SimpleMetric
              metric={formatCount(data['answers.conversations.automated']?.value)}
              unit="fully automated conv."
              fontSize="16px"
              unitFontSize="16px"
            />
          </>
        }
      />
    </Segment>
  );
};

export default Automated;
