import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Table, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { getTrafficSourcesBreakdown } from 'apis';
import { useQuery } from 'react-query';
import { capitalize, queryStatusToLoaderStatus } from 'utils/helpers';
import { parseErrorResponse } from 'apis/utils';
import queryConstants from 'utils/queries/constants';
import ConversationsLink from './ConversationsLink';
import TouchModeSelector from './TouchModeSelector';
import { ANALYTICS_CHANNELS } from '../constants';
import { PageSection } from '../../common';
import { CsvExport } from '../../common/CsvExport';
import { PercentCell } from '../../common/PercentCell';
import { formatCount, percent } from '../../utils';

const ALL_ANALYTICS_CHANNELS = Object.keys(ANALYTICS_CHANNELS);

const prepareCsvContent = (sourcesBreakdown, t) => {
  const totalLeads = sourcesBreakdown.reduce((r, v) => r + v.leads, 0);

  const headers = [
    'Source',
    'Website Visitors',
    'VSA Users',
    'Interaction Rate (%)',
    capitalize(t('leads')),
    `% of ${capitalize(t('leads'))} (%)`,
    `Avg. ${capitalize(t('move_ins'))} Likelihood`,
    'Visitor Conversion Rate (%)',
    'VSA User Conversion Rate (%)',
    'Financially Unqualified (%)',
    `Requested ${capitalize(t('tours'))}`,
    capitalize(t('move_ins')),
  ];
  const content = sourcesBreakdown.map((s) => [
    s.source,
    s.visitors,
    s.interactions,
    percent(s.interactions, s.visitors, { zeroBased: true }).toFixed(4),
    s.leads,
    percent(s.leads, totalLeads, { zeroBased: true }).toFixed(4),
    s.avg_lead_score?.toFixed(1) || '-',
    percent(s.leads, s.visitors, { zeroBased: true }).toFixed(4),
    percent(s.leads, s.interactions, { zeroBased: true }).toFixed(4),
    percent(s.unqualified, s.leads, { zeroBased: true }).toFixed(4),
    s.tours,
    s.moveins,
  ]);

  return [headers, content];
};

const TrafficSources = ({ filters, publicId }) => {
  const [touchMode, setTouchMode] = useState('multi');
  const { t } = useTranslation();
  const {
    data: sourcesBreakdown = [],
    status,
    error,
    isFetching,
  } = useQuery(
    [queryConstants.TRAFFIC_SOURCES_BREAKDOWN, { publicId, filters, touchMode }],
    () => getTrafficSourcesBreakdown({ public_id: publicId, filters: { ...filters, touch_mode: touchMode } }),
    {
      placeholderData: [],
    }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch traffic sources'));
  }

  const [filter, setFilter] = useState('');
  const totalLeads = sourcesBreakdown.reduce((r, v) => r + v.leads, 0);

  const [csvHeaders, csvContent] = prepareCsvContent(sourcesBreakdown, t);

  const channelSources = filters.channels && filters.channels.length ? filters.channels : ALL_ANALYTICS_CHANNELS;

  const onTouchModeChange = (e, { value }) => {
    setTouchMode(value);
  };

  return (
    <>
      <PageSection
        title="Traffic Sources Breakdown"
        loaderStatus={loaderStatus}
        actions={<TouchModeSelector value={touchMode} onChange={onTouchModeChange} />}
      >
        <Table className="fixed-size">
          <Table.Header>
            <Table.Row textAlign="right">
              <Table.HeaderCell textAlign="left">
                <Input
                  size="small"
                  placeholder="Find Traffic Sources"
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                  style={{ width: '15rem' }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>Website Visitors</Table.HeaderCell>
              <Table.HeaderCell>VSA Users</Table.HeaderCell>
              <Table.HeaderCell>Interaction Rate</Table.HeaderCell>
              <Table.HeaderCell>{capitalize(t('leads'))}</Table.HeaderCell>
              <Table.HeaderCell>% of {capitalize(t('leads'))}</Table.HeaderCell>
              <Table.HeaderCell>Avg. {capitalize(t('move_ins'))} Likelihood</Table.HeaderCell>
              <Table.HeaderCell>Visitor Conversion Rate</Table.HeaderCell>
              <Table.HeaderCell>VSA User Conversion Rate</Table.HeaderCell>
              <Table.HeaderCell>Financially Unqualified</Table.HeaderCell>
              <Table.HeaderCell>Requested {capitalize(t('tours'))}</Table.HeaderCell>
              <Table.HeaderCell>{capitalize(t('move_ins'))}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {sourcesBreakdown
              .filter((s) => s.source.toLowerCase().includes(filter.toLowerCase().trim()))
              .map((s, i) => (
                <Table.Row textAlign="right" key={i}>
                  <Table.Cell textAlign="left">{s.source}</Table.Cell>
                  <Table.Cell>{formatCount(s.visitors)}</Table.Cell>
                  <Table.Cell>{formatCount(s.interactions)}</Table.Cell>
                  <Table.Cell>
                    <PercentCell value={percent(s.interactions, s.visitors)} />
                  </Table.Cell>
                  <Table.Cell>
                    <ConversationsLink
                      value={formatCount(s.leads)}
                      startDate={filters.start_date}
                      endDate={filters.end_date}
                      communities={filters.communities}
                      groups={filters.groups}
                      channelSources={channelSources}
                      trafficSources={[s.source]}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <PercentCell value={percent(s.leads, totalLeads)} />
                  </Table.Cell>
                  <Table.Cell>{s.avg_lead_score?.toFixed(1) || '-'}</Table.Cell>
                  <Table.Cell>
                    <PercentCell value={percent(s.leads, s.visitors)} />
                  </Table.Cell>
                  <Table.Cell>
                    <PercentCell value={percent(s.leads, s.interactions)} />
                  </Table.Cell>
                  <Table.Cell>
                    <ConversationsLink
                      value={<PercentCell value={percent(s.unqualified, s.leads)} />}
                      startDate={filters.start_date}
                      endDate={filters.end_date}
                      communities={filters.communities}
                      groups={filters.groups}
                      channelSources={channelSources}
                      trafficSources={[s.source]}
                      financiallyUnqualified
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <ConversationsLink
                      value={formatCount(s.tours)}
                      startDate={filters.start_date}
                      endDate={filters.end_date}
                      communities={filters.communities}
                      groups={filters.groups}
                      channelSources={channelSources}
                      trafficSources={[s.source]}
                      tourScheduled
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <ConversationsLink
                      value={formatCount(s.moveins)}
                      startDate={filters.start_date}
                      endDate={filters.end_date}
                      communities={filters.communities}
                      groups={filters.groups}
                      channelSources={channelSources}
                      trafficSources={[s.source]}
                      moveInDate
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={8} textAlign="right">
                <CsvExport data={csvContent} headers={csvHeaders} filename="traffic_source_breakdown.csv" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </PageSection>
    </>
  );
};

export default TrafficSources;
