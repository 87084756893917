import React from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';

import { Loader } from 'components/lib';

const Container = styled.div`
  font-family: var(--font-family);
  padding: 13px 11px 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #071b45;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.27);
  border: solid 1px rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'unset')};
`;

const LeftContainerBox = styled.div`
  display: flex;
  align-items: center;
`;

const LeftIcon = styled.div`
  margin-right: 10px;
`;

const TextBox = styled.div`
  line-height: 14px;
  width: 130px;
`;

const Description = styled.div`
  font-size: 9px;
  font-weight: 500;
  color: var(--white);
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SupportModeBox = ({ leftIcon, title, description, rightIcon, loading, onClick }) => {
  const isClickable = typeof onClick === 'function';

  const handleClick = () => {
    if (isClickable) {
      onClick();
    }
  };

  return (
    <Container onClick={handleClick} isClickable={isClickable}>
      <LeftContainerBox>
        <LeftIcon>{leftIcon}</LeftIcon>
        <TextBox>
          <Description>{description}</Description>
          <Popup
            trigger={<Title>{title}</Title>}
            content={title}
            size="mini"
            inverted
            position="bottom right"
            mouseEnterDelay={500}
          />
        </TextBox>
      </LeftContainerBox>
      {loading ? <Loader active size="small" transparent /> : rightIcon}
    </Container>
  );
};

export default SupportModeBox;
