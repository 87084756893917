import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { If } from 'components/If';

const Container = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: flex-end;
  .close {
    margin-left: auto;
  }
`;

const RuleConstant = ({ rule, onChange, onRemove, preview }) => {
  const handleOnClick = () => {
    if (preview) {
      return;
    }
    const data = {
      id: rule.ruleId || rule.id,
      value: null,
      operator: null,
      options: [],
      ruleId: rule.id,
    };
    onChange(data);
  };
  return (
    <Container>
      <If condition={!preview}>
        <Icon name="add_circle" size="20" color="#007aff" onClick={handleOnClick} />
      </If>
      <If condition={preview}>
        <Icon name="close" size="24" color="#89919d" onClick={onRemove} className="close" />
      </If>
    </Container>
  );
};

export default RuleConstant;
