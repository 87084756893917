import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';

const Setting = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin-left: auto;
  ${(props) => (props.fullWidth ? 'width: 100%' : '')};
`;

export const SettingsBooleanContainer = ({ children, ...props }) => {
  return (
    <Setting {...props}>
      {children}
      <Icon name="chevron_right" size="24" color="#89919d" />
    </Setting>
  );
};
