import React, { useContext, useMemo } from 'react';
import { Table, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { If } from '../../If';
import { CommunityCareTypesListItemEdit } from './CommunityCareTypesListItemEdit';
import { CommunityCareTypesListItem } from './CommunityCareTypesListItem';
import { CareTypesContext } from './CareTypesContext';

const CareTypesList = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 2rem 0;
`;

const sortByRank = (a, b) => a.rank - b.rank;

export const CommunityCareTypesList = () => {
  const { t } = useTranslation();
  const { editCareType, communityCareTypes } = useContext(CareTypesContext);

  const sortedCommunityCareTypes = useMemo(() => [...communityCareTypes].sort(sortByRank), [communityCareTypes]);

  return (
    <CareTypesList>
      <Table basic selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Rank</Table.HeaderCell>
            <Table.HeaderCell>Care Type</Table.HeaderCell>
            <Table.HeaderCell>Display Name</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedCommunityCareTypes.map((careType, i) => {
            if (careType.id !== editCareType)
              return <CommunityCareTypesListItem key={careType.id} careType={careType} />;

            return <CommunityCareTypesListItemEdit key={careType.id} careType={careType} />;
          })}
        </Table.Body>
      </Table>
      <If condition={communityCareTypes.length === 0}>
        <Message warning content={`This ${t('community')} doesn't have assigned care types!`} />
      </If>
    </CareTypesList>
  );
};
