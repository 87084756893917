import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Dimmer, Form, Loader, Segment } from 'semantic-ui-react';
import humps from 'humps';

import useDownloadAsCSV from 'utils/hooks/useDownloadAsCSV';
import { PageHeader, PAGE_HEADER_MARGIN, PAGE_HEADER_PADDING } from 'components/common';
import { useUrlSearchParams } from 'utils/hooks';
import { getCampaignStatisticsTeamResponse } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { useNurtureFilter } from 'components/nurture/NurtureFilterContext';
import { PAGINATION, STATUSES } from '../../../constants';
import TeamResponseStatisticsTable from './TeamResponseStatisticsTable';
import { Metrics } from './Metrics';

const Content = styled(Segment)`
  padding: 0;
`;

const WorkingHoursOnlyToggle = styled(Form.Radio)`
  margin-top: 10px;
`;

const searchParams = new URLSearchParams(window.location.search);
const INITIAL_WORKING_HOURS_ONLY = searchParams.get('workingHoursOnly') === 'true';

export const TeamResponseStatistics = () => {
  const { setUrlSearchParams } = useUrlSearchParams();
  const { state, buildQueryParams } = useNurtureFilter();
  const { filters } = state;
  const [page, setPage] = useState(PAGINATION.PAGE);
  const [sortKey, setSortKey] = useState('messageRepliesCount');
  const [sortReverse, setSortReverse] = useState(true);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [workingHoursOnly, setWorkingHoursOnly] = useState(INITIAL_WORKING_HOURS_ONLY);
  const [metrics, setMetrics] = useState({});
  const { exportAsCSV } = useDownloadAsCSV();
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  useEffect(() => {
    setPage(PAGINATION.PAGE);
  }, [filters]);

  useEffect(() => {
    const fetchTeamResponseStatistics = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const queryParams = {
          page,
          pageSize: PAGINATION.PAGE_SIZE,
          ...filters,
          workingHoursOnly: workingHoursOnly || undefined,
          sort_by: humps.decamelize(sortKey),
          sort_reverse: sortReverse.toString(),
        };
        setUrlSearchParams(queryParams);
        const params = buildQueryParams(queryParams);
        const { results, metrics, count } = await getCampaignStatisticsTeamResponse(params);
        setItems(results);
        setTotalItemsCount(count);
        setTotalPages(Math.ceil(count / PAGINATION.PAGE_SIZE));
        setMetrics(metrics);
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        toast.error(parseErrorResponse(e, 'Unable to fetch team response statistics'));
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    fetchTeamResponseStatistics();
  }, [filters, page, workingHoursOnly, sortKey, sortReverse]);

  const handleExportAsCSV = useCallback(async () => {
    await exportAsCSV({
      exportAction: () =>
        getCampaignStatisticsTeamResponse(buildQueryParams({ ...filters, workingHoursOnly, export: true })),
      filename: 'team_response_statistics',
    });
  }, [filters, exportAsCSV]);

  const toggleWorkingHoursOnly = () => {
    setWorkingHoursOnly(!workingHoursOnly);
    setPage(PAGINATION.PAGE);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSortChange = (nextKey) => {
    if (nextKey === sortKey) {
      setSortReverse(!sortReverse);
    } else {
      setSortKey(nextKey);
      setSortReverse(true);
    }
  };

  return (
    <Content basic>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <PageHeader
        as="h2"
        title="Team Response Statistics"
        subtitle={
          <>
            Track how well your sales team is performing
            <WorkingHoursOnlyToggle
              toggle
              checked={!!workingHoursOnly}
              onChange={toggleWorkingHoursOnly}
              label="Only working hour messages"
            />
          </>
        }
        margin={PAGE_HEADER_MARGIN.MEDIUM}
        padding={PAGE_HEADER_PADDING.NONE}
        actions={<Metrics metrics={metrics} />}
      />

      <TeamResponseStatisticsTable
        items={items}
        totalCount={totalItemsCount}
        page={page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
        sortKey={sortKey}
        sortReverse={sortReverse}
        exportAsCSV={handleExportAsCSV}
      />
    </Content>
  );
};
