import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getEmailTemplate, updateEmailTemplate, deleteEmailTemplate } from 'apis';
import EditTemplateModal from './EditTemplateModal';

export default function EditTemplate() {
  const history = useHistory();
  const [template, setTemplate] = useState({});
  const { templateId } = useParams();

  const close = useCallback(() => {
    history.push(`/admin/email-templates`);
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const templateData = await getEmailTemplate(templateId);
      setTemplate(templateData);
    }
    fetchData();
  }, [templateId]);

  const onSave = useCallback(
    async (values) => {
      await updateEmailTemplate(templateId, values);
      close();
    },
    [templateId, close]
  );

  const handleDeleteTemplate = useCallback(async () => {
    await deleteEmailTemplate(templateId);
    close();
  }, [templateId]);

  if (!template.id) return null;

  return <EditTemplateModal onClose={close} initialValues={template} onSave={onSave} onDelete={handleDeleteTemplate} />;
}
