import React, { useEffect } from 'react';
import { Message, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { capitalize } from 'utils/helpers';
import { useCRM, fetchCommunityInvalidCRMSettings } from './context';

const CRMCommunityListInvalidSettings = () => {
  const { t } = useTranslation();
  const [{ userId, crmCommunityInvalidSettings }, dispatch] = useCRM();

  useEffect(() => {
    if (userId) {
      fetchCommunityInvalidCRMSettings(userId, dispatch);
    }
  }, [dispatch, userId]);

  if (!crmCommunityInvalidSettings) {
    return null;
  }

  if (crmCommunityInvalidSettings.length === 0) {
    return null;
  }

  return (
    <Message error>
      <Message.Header>{capitalize(t('community'))} CRM settings configuration errors detected</Message.Header>
      <p>Please review and fix {t('community')} CRM settings marked in the table below</p>
      <List bulleted>
        {crmCommunityInvalidSettings.map((c) => (
          <List.Item key={c.id}>{c.name}</List.Item>
        ))}
      </List>
    </Message>
  );
};

export default CRMCommunityListInvalidSettings;
