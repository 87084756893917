import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import { MARKET_TYPE } from '../constants';

const marketLanguage = {
  [MARKET_TYPE.DEFAULT]: 'en',
  [MARKET_TYPE.SENIOR_LIVING]: 'sl',
  [MARKET_TYPE.HOME_HEALTH]: 'hh',
  [MARKET_TYPE.BEHAVIORAL_HEALTH]: 'bh',
  [MARKET_TYPE.DENTIST]: 'de',
  [MARKET_TYPE.VET]: 've',
  [MARKET_TYPE.MED_SPA]: 'ms',
  [MARKET_TYPE.MULTI_FAMILY]: 'mf',
  [MARKET_TYPE.AGENCY]: 'ag',
};

export const setMarketLanguage = (marketType) => {
  i18n.changeLanguage(marketLanguage[marketType]);
};

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    fallbackLng: marketLanguage[MARKET_TYPE.DEFAULT],
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    debug: false,
  });

export default 18n;
