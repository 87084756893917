import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Modal as ComponentsModal, Icon, Button, Form, Popup, Dimmer, Loader, Radio } from 'components/lib';
import { If } from 'components/If';
import { InputField } from 'components/common';
import useUploadedImages from 'components/editor/hooks/useUploadedImages';
import { getAllPlaceholders } from 'apis';
import { debounce } from 'utils/helpers';
import EmailEditor from '../nurture/Campaigns/EditMessage/EmailEditor';
import DeleteModal from './DeleteModal';
import StyleEditor from './StyleEditor';

const Modal = styled(ComponentsModal)`
  && {
    & > .header {
      border-bottom: none;
    }
    &.tiny,
    &.large {
      width: 1172px;
      height: 87vh;
      margin: 0 auto !important;
      top: 50px !important;
    }
    & > .content {
      height: 100%;
    }
  }
  .ui.form {
    height: 100%;
  }
  .tox-tinymce {
    height: 100% !important;
  }
  .cm-editor {
    height: calc(87vh - 156px);
  }
`;

const ContentWrapper = styled.div`
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  && {
    .field {
      margin: 0;
      position: relative;
    }
    .ui.pointing.label {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 1;
    }
  }
`;

const Sidebar = styled.div`
  width: 232px;
  min-height: 100%;
  border-right: 1px solid var(--line-standard);
  padding: 20px;
`;

const MessageType = styled.div`
  width: fit-content;
  padding: 4px 8.5px 1px;
  border-radius: 10px;
  background-color: var(--emerald-green);
  font-family: var(--font-family-bold);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  &.email {
    background-color: #2791ff;
  }
`;

const PlaceholdersTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-top: 30px;
  margin-bottom: 10px;
`;

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Placeholder = styled.div`
  padding: 3px 6px 3px 5px;
  border-radius: 4px;
  background-color: #e1f0ff;
  font-family: var(--font-family);
  font-size: 10px;
  line-height: normal;
  letter-spacing: normal;
  color: #2791ff;
  margin-right: 5px;
  margin-bottom: 5px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Content = styled.div`
  height: 100%;
  width: 940px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 76px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
`;

const NameWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const NameInputTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const NameInput = styled(InputField)`
  &&&&& {
    input {
      font-family: var(--font-family);
      font-size: 14px;
      line-height: normal;
      letter-spacing: normal;
      color: var(--text);
      width: 700px;
      height: 36px;
      border: solid 1px var(--line-standard);
      background-color: var(--input-field-fill);
      margin-left: 10px;
      &:focus {
        outline: none;
      }
    }
  }
`;

const HeaderActions = styled.div`
  height: 100%;
  display: flex;
  width: 141px;
  align-items: center;
  justify-content: flex-end;
  > span {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

const Body = styled.div`
  width: 100%;
  background-color: var(--input-field-fill);
  height: calc(100% - 156px);
`;

const Footer = styled.div`
  width: 100%;
  height: 80px;
  border-top: 1px solid var(--line-standard);
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-between;
`;
const ActionsWrapper = styled.div`
  margin-left: auto;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:first-child) {
    margin-left: 20px;
  }
  > span {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 6px;
  }
`;
export default function EditTemplateModal({ initialValues, onSave, onClose, onDelete }) {
  const [isSaving, setIsSaving] = useState(false);
  const [placeholders, setPlaceholders] = useState([]);
  const [templateStyle, setTemplateStyle] = useState(initialValues?.style);
  const [currentEditor, setCurrentEditor] = useState('html');
  const [isPlaceholdersLoading, setIsPlaceholdersLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const editorRef = useRef(null);
  const handleOnSave = useCallback(
    async (values) => {
      setIsSaving(true);
      onSave(values);
    },
    [onSave]
  );

  const handleEditorChange = useCallback(
    (value) => (event) => {
      event.preventDefault();
      setCurrentEditor(value);
    },
    [setCurrentEditor]
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(256, `Can't be longer than 256 characters`).required('Required'),
    html: Yup.string().required('Required'),
    isPublished: Yup.boolean(),
  });

  const onDeleteClick = useCallback(() => {
    setIsDeleteModalOpen(true);
  }, [setIsDeleteModalOpen]);

  const onDeleteModalClose = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, [setIsDeleteModalOpen]);

  const updateEditorStyle = useCallback(
    debounce((value) => {
      setTemplateStyle(value);
    }, 1000),
    [setTemplateStyle, editorRef]
  );

  const { userFiles, isLoading: isUserFilesLoading } = useUploadedImages();

  useEffect(() => {
    async function fetchPlaceholders() {
      try {
        setIsPlaceholdersLoading(true);
        const response = await getAllPlaceholders();
        setIsPlaceholdersLoading(false);
        setPlaceholders(response?.results.map((tag) => ({ ...tag, enabled: true })));
      } catch (e) {
        console.error(e);
        toast.error('Error fetching placeholders');
        setIsPlaceholdersLoading(false);
      }
    }
    fetchPlaceholders();
  }, []);

  const onTagClick = (key) => {
    if (editorRef?.current) {
      return editorRef.current.execCommand('mceInsertContent', false, `{${key}}`);
    }
  };

  return (
    <Modal open width={1172} size="large" onClose={onClose}>
      <Modal.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSave}
          enableReinitialize
        >
          {({ values, handleSubmit, setFieldValue, resetForm, isValid, dirty, touched }) => (
            <Form onSubmit={handleSubmit}>
              <ContentWrapper>
                <Sidebar>
                  <MessageType className="email">email</MessageType>

                  <PlaceholdersTitle>Placeholders</PlaceholdersTitle>
                  <PlaceholderWrapper>
                    {placeholders.map((tag) => {
                      return (
                        <React.Fragment key={tag.key}>
                          <Dimmer active={isPlaceholdersLoading} inverted>
                            <Loader active inverted />
                          </Dimmer>

                          <Popup
                            key={tag.name}
                            content={tag.key}
                            trigger={<Placeholder onClick={() => onTagClick(tag.name)}>{tag.key}</Placeholder>}
                          />
                        </React.Fragment>
                      );
                    })}
                  </PlaceholderWrapper>
                </Sidebar>
                <Content>
                  <Header>
                    <NameWrapper>
                      <NameInputTitle>Template name</NameInputTitle>
                      <NameInput name="name" placeholder="Template name" />
                      <HeaderActions>
                        <If condition={onDelete && values?.id}>
                          <Icon name="delete_outline" size="24" color="#fa7550" onClick={onDeleteClick} />
                        </If>
                        <Icon name="close" size="24" color="#89919d" onClick={onClose} />
                      </HeaderActions>
                    </NameWrapper>
                  </Header>
                  <Body>
                    <If condition={currentEditor === 'css'}>
                      <StyleEditor
                        onChange={(value) => {
                          setFieldValue('style', value);
                          updateEditorStyle(value);
                        }}
                        value={values.style}
                      />
                    </If>
                    <If condition={currentEditor === 'html'}>
                      <EmailEditor
                        emailStyle={templateStyle}
                        tags={placeholders}
                        loading={isPlaceholdersLoading || isUserFilesLoading}
                        imageList={userFiles}
                        editorRef={editorRef}
                        initialValue={values.html}
                        onChange={(e, value) => {
                          setFieldValue('html', value);
                        }}
                      />
                    </If>
                  </Body>
                  <Footer>
                    <Button size="small" onClick={handleEditorChange('css')}>
                      CSS
                    </Button>
                    <Button size="tiny" onClick={handleEditorChange('html')}>
                      HTML
                    </Button>
                    <RadioWrapper>
                      <CustomRadio>
                        <Form.Field
                          control={Radio}
                          name="isPublished"
                          value={true}
                          checked={values.isPublished === true}
                          onChange={() => setFieldValue('isPublished', true)}
                        />
                        <span>Published</span>
                      </CustomRadio>
                      <CustomRadio>
                        <Form.Field
                          control={Radio}
                          name="isPublished"
                          value={false}
                          checked={values.isPublished === false}
                          onChange={() => setFieldValue('isPublished', false)}
                        />
                        <span>Draft</span>
                      </CustomRadio>
                    </RadioWrapper>
                    <ActionsWrapper>
                      <Button
                        primary
                        color="blue"
                        type="button"
                        onClick={handleSubmit}
                        disabled={!dirty || !touched || !isValid}
                        loading={isSaving}
                      >
                        Confirm
                      </Button>
                      <Button primary type="button" onClick={onClose}>
                        Cancel
                      </Button>
                    </ActionsWrapper>
                  </Footer>
                </Content>
                <DeleteModal
                  isOpen={isDeleteModalOpen}
                  onClose={onDeleteModalClose}
                  onSave={() => onDelete(resetForm)}
                />
              </ContentWrapper>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}
