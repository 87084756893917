import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Label, Form, Icon } from 'semantic-ui-react';
import { debounce } from 'components/utils';

import { SortableGrid, DraggableLabel } from 'components/common/sortableGrid';
import { Dropdown } from './lib';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .ui.label.accepted-value {
    margin: 0 0.5rem 0.5rem 0;
  }
`;

export const CsvField = ({ options, csvElementValidator, autoFocus, onChange, onError, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const values = meta.value || [];
  const { error } = meta;
  const { setValue } = helpers;
  const [tmp, setTmp] = useState('');
  const [tmpError, setTmpError] = useState('');
  const { disabled, sortable, label } = props;
  const remainingOptions = useMemo(() => options?.filter((o) => !values.includes(o.value)), [options, values]);

  const validate = (v) => {
    if (!csvElementValidator) {
      return v !== '';
    }

    try {
      const parts = v.split(',').map((v) => v.trim());
      parts.forEach((p) => csvElementValidator.validateSync(p));
      setTmpError('');
      return true;
    } catch (e) {
      setTmpError(e.message);
      return false;
    }
  };

  const addValue = (v) => {
    const newValue = [...new Set([...values, v].map((v) => v.trim()).filter((v) => !!v))];
    setValue(newValue);
    setTmp('');
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  const removeValue = (v) => () => {
    const newValue = values.filter((value) => value !== v);
    setValue(newValue);
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  const onOrderValues = debounce((newMappedValues) => {
    const newValue = newMappedValues.map((v) => v.value);
    setValue(newValue);
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  }, 500);

  const parseValue = (e) => {
    const v = e.target.value?.trim();
    const isEnter = e.key === 'Enter';

    if (isEnter) {
      e.preventDefault();
    }

    if (isEnter && validate(v)) {
      addValue(v);
    }
  };

  const onBlur = (e) => {
    const v = e.target.value?.trim();
    if (validate(v)) {
      addValue(v);
    }
    e.target.name = props.name;
    field.onBlur(e);
  };

  if (error) {
    if (typeof onError === 'function') {
      onError(field.name);
    }
  }

  const sortableValues = useMemo(() => values.map((v, i) => ({ id: i, value: v })), [values]);

  return (
    <Form.Field>
      {label && <label>{label}</label>}
      <Wrapper>
        {sortable ? (
          <SortableGrid items={sortableValues} onUpdate={onOrderValues} contentComponent={DraggableLabel} />
        ) : (
          values.map((v, i) => (
            <Label key={i} className="accepted-value">
              {v}
              <Icon name="delete" onClick={removeValue(v)} />
            </Label>
          ))
        )}
        {options ? (
          <Dropdown
            placeholder={props.placeholder || '＋ Add new'}
            options={remainingOptions}
            basic
            search
            select
            fluid
            value={null}
            allowAdditions
            handleAddition={(e, v) => addValue(v.value)}
            onChange={(e, v) => addValue(v.value)}
            selectOnBlur={false}
          />
        ) : (
          <input
            type="text"
            name={props.name}
            value={tmp}
            disabled={disabled}
            onChange={(e) => {
              setTmp(e.target.value);
            }}
            onKeyPress={parseValue}
            onBlur={onBlur}
            placeholder={props.placeholder || '＋ Add new'}
            autoFocus={autoFocus}
          />
        )}
        {(tmpError || error) && (
          <Label prompt pointing>
            {tmpError || error}
          </Label>
        )}
      </Wrapper>
    </Form.Field>
  );
};
