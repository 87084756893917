import React from 'react';
import { Switch } from 'react-router-dom';

import { UserRoute } from 'components/common/routes';
import { USER_TYPES } from '../../constants';
import { CreateEditCampaign } from './Campaigns/EditCampaigns';
import { NURTURE_CAMPAIGNS_ROUTE, BASE_NURTURE_PATHNAME } from './constants';
import NurtureRoot from './NurtureRoot';
import { CampaignDetailsProvider } from './Campaigns/CampaignDetailsContext';

const NurtureRoutes = () => (
  <Switch>
    <UserRoute
      path={[
        `${NURTURE_CAMPAIGNS_ROUTE}/:campaignType/details`,
        `${NURTURE_CAMPAIGNS_ROUTE}/:campaignType/details/:campaignId`,
      ]}
      allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.REGIONAL]}
      exact
    >
      <CampaignDetailsProvider>
        <CreateEditCampaign />
      </CampaignDetailsProvider>
    </UserRoute>
    <UserRoute
      path={[
        NURTURE_CAMPAIGNS_ROUTE,
        `${BASE_NURTURE_PATHNAME}/conversations`,
        `${BASE_NURTURE_PATHNAME}/conversations/:leadId`,
        `${BASE_NURTURE_PATHNAME}/campaign-statistics`,
        `${BASE_NURTURE_PATHNAME}/team-response-statistics`,
      ]}
      allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.REGIONAL]}
      exact
    >
      <NurtureRoot />
    </UserRoute>
    <UserRoute
      path={[`${BASE_NURTURE_PATHNAME}/campaign-scheduled-messages`]}
      allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.REGIONAL, USER_TYPES.MEMBER]}
      exact
    >
      <NurtureRoot hideDateFilter />
    </UserRoute>
  </Switch>
);

export default NurtureRoutes;
