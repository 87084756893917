import React, { useState } from 'react';

import { If } from 'components/If';
import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import { Sidebar, SidebarBox, SideBarTypeOptions } from './common';
import { GENERAL_SIDEBAR_VARIANT, COMMUNITIES_SIDEBAR_VARIANT, FLOW_TYPE } from './constants';
import { CommunitiesMenu } from './Communities';
import Criteria from './Criteria';
import StartDateField from '../form/fields/StartDateField';

export default () => {
  const [selectedOptionType, setSelectedOptionType] = useState(GENERAL_SIDEBAR_VARIANT);
  const { state, hasEditDeletePermission } = useCampaignDetails();
  const { eligibleCommunities, eligibleCommunitiesLoading } = state;

  return (
    <Sidebar>
      <SideBarTypeOptions
        optionTypes={[GENERAL_SIDEBAR_VARIANT, COMMUNITIES_SIDEBAR_VARIANT]}
        selectedOptionType={selectedOptionType}
        setSelectedOptionType={setSelectedOptionType}
      />
      <If condition={selectedOptionType === GENERAL_SIDEBAR_VARIANT}>
        <SidebarBox title="Entry Criteria">
          <Criteria flowType={FLOW_TYPE.ENTRY} />
        </SidebarBox>
        <SidebarBox title="Exit Criteria">
          <Criteria flowType={FLOW_TYPE.EXIT} />
        </SidebarBox>
      </If>
      <If condition={selectedOptionType === COMMUNITIES_SIDEBAR_VARIANT}>
        <CommunitiesMenu
          disabled={!hasEditDeletePermission}
          communities={eligibleCommunities}
          loading={eligibleCommunitiesLoading}
        />
      </If>
    </Sidebar>
  );
};
