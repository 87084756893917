import React from 'react';
import { Button, Dimmer, Image, Loader, Modal } from 'semantic-ui-react';

import LaunchingChatImage from 'assets/launching-chat-loop.gif';
import { If } from 'components/If';
import ChatValidation from './ChatValidation';

const ChatValidationModal = ({ open, loading, publishing, validationErrors, onClose, onConfirm }) => (
  <Modal size="tiny" open={open}>
    <Modal.Header>Chat Validation</Modal.Header>
    <Dimmer.Dimmable as={Modal.Content} dimmed={loading} style={{ minHeight: '200px' }}>
      <Dimmer active={loading} inverted>
        <Loader active size="large">
          Validating chat
        </Loader>
      </Dimmer>

      <If condition={!publishing}>
        <ChatValidation data={validationErrors} />
      </If>

      <If condition={publishing}>
        <Image src={LaunchingChatImage} />
      </If>
    </Dimmer.Dimmable>
    <If condition={!loading}>
      <Modal.Actions>
        <Button onClick={onClose} disabled={publishing} content="Cancel" />
        <Button
          primary
          onClick={onConfirm}
          disabled={publishing}
          content={validationErrors?.invalid ? 'Publish Anyway' : 'Publish'}
          loading={publishing}
        />
      </Modal.Actions>
    </If>
  </Modal>
);

export default ChatValidationModal;
