import React from 'react';
import { Label, Segment } from 'semantic-ui-react';

import { FloorPlanIcon } from './icons';

export const Floorplans = () => (
  <>
    <Segment attached="top" secondary>
      <Label color="blue">Show floorplans (full-screen)</Label>
    </Segment>

    <Segment attached="bottom">
      <FloorPlanIcon width="100" height="100" />
    </Segment>
  </>
);
