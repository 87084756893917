import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { WithTopBar } from 'components/WithTopBar';
import { Loader } from 'components/lib';
import { MessagesMainPanel } from './MessagesMainPanel';
import { MessagesSidebar } from './MessagesSidebar';
import { useMessagesContext } from './MessagesContext';
import MessagesEmpty from './MessagesEmpty';
import { STATUSES } from '../../constants';
import { CONVERSATION_TYPES } from './constants';

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 100%;
  height: 100vh;
`;

const MessagesContainer = () => {
  const { totalCount, search, loaderStatus, conversationType, loadedInitial } = useMessagesContext();

  if (!loadedInitial && loaderStatus === STATUSES.LOADING) {
    return (
      <LoaderContainer>
        <Loader active />
      </LoaderContainer>
    );
  }

  // Better transition when switching ongoing and archived pages
  if (totalCount === -1) {
    return null;
  }

  if (
    totalCount === 0 &&
    !search &&
    loaderStatus === STATUSES.LOADED &&
    conversationType === CONVERSATION_TYPES.ONGOING
  ) {
    return <MessagesEmpty />;
  }

  return (
    <>
      <MessagesSidebar />
      <WithTopBar contentClasses={classNames('contained')}>
        <MessagesMainPanel />
      </WithTopBar>
    </>
  );
};

export default MessagesContainer;
