import React from 'react';
import styled from 'styled-components';

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .list-item {
    h4 {
      font-family: var(--font-family);
    }
  }
`;

export const SettingsTableRowContainer = ({ children }) => {
  return <RowWrapper>{children}</RowWrapper>;
};
