import React from 'react';
import PropTypes from 'prop-types';

import { ProfileCircle } from './common/profile/ProfileCircle';

export const UserCircle = ({ size, user, ...props }) => {
  if (!user) return null;

  const { first_name, last_name, email } = user;
  const name = (first_name && last_name && `${first_name} ${last_name}`) || first_name || email;

  return <ProfileCircle size={size} name={name} picture={user?.profile_picture} {...props} />;
};

UserCircle.propTypes = {
  size: PropTypes.oneOf(['18', '20', '30', '40', '60']),
};

UserCircle.defaultProps = {
  size: '30',
};
