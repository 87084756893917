import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Device } from '@twilio/voice-sdk';
import { Worker as TaskRouterWorker } from 'twilio-taskrouter';

import {
  getContactCenterTaskBySid,
  getContactCenterMe,
  getPhoneCallToken,
  updateContactCenterMe,
  createPhoneCall,
  updatePhoneCall,
  updateContactCenterTaskStatusBySid,
} from 'apis';
import { useUserContext } from 'components/UserContext';
import { CALL_STATUS } from './constants';

const ContactCenterContext = createContext();

const ContactCenterContextProvider = ({ children }) => {
  const { user } = useUserContext();
  const [initialized, setInitialized] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [session, setSession] = useState();
  const [device, setDevice] = useState();
  const [worker, setWorker] = useState();
  const [call, setCall] = useState();
  const [reservation, setReservation] = useState();
  const [loading, setLoading] = useState(false);
  const [phoneCall, setPhoneCall] = useState();
  const [status, setStatus] = useState({ value: null, updatedAt: null });
  const [muted, setMuted] = useState();

  const fetchSession = useCallback(
    async (inbound, outbound) => {
      const includeInbound = inbound && !worker;
      const includeOutbound = outbound && !device;
      if (!includeInbound && !includeOutbound) {
        return;
      }

      const session = await getPhoneCallToken({ inbound: includeInbound, outbound: includeOutbound });
      setSession(session);
    },
    [log]
  );

  useEffect(() => {
    const initialize = async () => {
      try {
        setInitLoading(true);
        if (user.is_contact_center_enabled) {
          const { isOnline } = await getContactCenterMe();
          if (isOnline) {
            fetchSession(true, true);
          }
        }
      } catch (e) {
        log(`Error initializing contact center: ${e}`);
      } finally {
        setInitLoading(false);
        setInitialized(true);
      }
    };

    if (user) {
      initialize();
    }
  }, [log, user]);

  useEffect(() => {
    if (!session) {
      return;
    }

    const initialize = async () => {
      try {
        const { workerSid, workerToken, voiceToken } = session;
        if (workerSid && workerToken) {
          await initializeWorker(workerSid, workerToken);
        }
        if (voiceToken) {
          await initializeDevice(voiceToken);
        }
      } catch (e) {
        log(`Error initializing contact center: ${e}`);
      }
    };

    initialize();

    return () => {
      if (device) {
        device.destroy();
      }
      if (worker) {
        worker.disconnect();
      }
    };
  }, [session, initializeWorker, initializeDevice, log]);

  const fetchTask = useCallback(async (taskSid) => {
    try {
      setLoading(true);
      const { lead, community, caller, called } = await getContactCenterTaskBySid(taskSid);
      setPhoneCall((draft) => ({
        ...draft,
        leadId: lead?.id,
        leadName: lead?.displayName,
        leadPhoneNumber: caller,
        communityId: community.id,
        communityName: community.name,
        communityPhoneNumber: called,
      }));
    } catch (e) {
      log(`Error fetching task: ${e}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const updateCallStatus = useCallback((value) => {
    setStatus({ value, updatedAt: new Date() });
  }, []);

  const initializeCall = useCallback(
    (call) => {
      log(`Initializing call ${JSON.stringify(call.parameters)}`);
      call.on('accept', () => {
        log('Call accepted');
        updateCallStatus(CALL_STATUS.OPEN);
      });

      call.on('ringing', () => {
        updateCallStatus(CALL_STATUS.RINGING);
      });

      call.on('disconnect', () => {
        log('Call disconnected');
        updateCallStatus(CALL_STATUS.CLOSED);
      });

      call.on('reject', () => {
        log('Call rejected');
        updateCallStatus(CALL_STATUS.CLOSED);
      });

      updateCallStatus(call.status());
      setCall(call);
      setMuted(call.isMuted());
    },
    [log, updateCallStatus]
  );

  const initializeWorker = useCallback(
    async (workerSid, token) => {
      log(`Initializing worker ${workerSid}`);
      const worker = new TaskRouterWorker(token);

      worker.on('disconnected', () => {
        log('Worker disconnected');
      });

      worker.on('error', (error) => {
        log(`Error: ${error}`);
      });

      worker.on('activityUpdated', (worker) => {
        log(`Worker ${worker.sid} activity updated to ${worker.activity.name}`);
      });

      worker.on('ready', (w) => {
        log(`Worker ${w.sid} is ready`);
      });

      worker.on('reservationCreated', (reservation) => {
        log(`Reservation ${reservation.sid} created`);
        setReservation(reservation);
        fetchTask(reservation.task.sid);

        reservation.on('accepted', (acceptedReservation) => {
          log(`Reservation ${acceptedReservation.sid} accepted`);
        });
      });

      worker.on('tokenExpired', async () => {
        log(`Worker ${worker.sid} token expired`);
        const { workerToken } = await getPhoneCallToken({ inbound: true, outbound: true });
        worker.updateToken(workerToken);
      });

      setWorker(worker);
    },
    [log, fetchTask]
  );

  const initializeDevice = useCallback(
    async (token) => {
      log(`Initializing device`);
      const device = new Device(token, { codecPreferences: ['opus', 'pcmu'] });

      device.on('registered', () => {
        setDevice(device);
        log('Device registered');
      });

      device.on('unregistered', () => {
        setDevice(null);
        log('Device unregistered');
      });

      device.on('error', (error) => {
        console.error(error);
        log(`Error: ${error}`);
      });

      device.on('tokenWillExpire', async () => {
        const { voiceToken } = await getPhoneCallToken({ inbound: false, outbound: true });
        device.updateToken(voiceToken);
        log('Token updated');
      });

      device.on('incoming', (call) => {
        initializeCall(call);
      });

      await device.register();
    },
    [log]
  );

  const log = useCallback((message) => {
    setLogs((prevLogs) => [
      {
        timestamp: new Date(),
        message,
      },
      ...prevLogs,
    ]);
  }, []);

  const configureOutboundCalls = async () => {
    if (device) {
      return;
    }

    try {
      setInitLoading(true);
      log('Configure outbound calls');
      await fetchSession(false, true);
    } catch (e) {
      log(`Error setting outbound calls: ${e}`);
      throw e;
    } finally {
      setInitLoading(false);
    }
  };

  const configureInboundCalls = async (enable) => {
    try {
      setInitLoading(true);
      log(`Configure inbound calls ${enable ? 'enabled' : 'disabled'}`);
      if (enable) {
        await fetchSession(true, true);
      } else {
        setWorker(null);
      }

      await updateContactCenterMe({ isAvailable: enable });
    } catch (e) {
      log(`Error configuring inbound calls: ${e}`);
      throw e;
    } finally {
      setInitLoading(false);
    }
  };

  const makeCall = async (identity, callerId) => {
    log(`Making call to ${identity}`);
    if (!device) {
      log('Device not ready');
      return;
    }

    try {
      setLoading(true);
      const { id, lead, community } = await createPhoneCall({ leadId: identity, trackingNumber: callerId });
      setPhoneCall({
        id,
        leadId: lead.id,
        leadPhoneNumber: lead.phone,
        leadName: lead.displayName,
        communityId: community.id,
        communityName: community.name,
        communityPhoneNumber: callerId,
      });
      const call = await device.connect({
        params: { context: JSON.stringify({ phoneCallId: id }) },
      });
      initializeCall(call);
    } catch (e) {
      log('Unable to make a call');
    } finally {
      setLoading(false);
    }
  };

  const acceptCall = async () => {
    log('Accepting call');
    call.accept();
  };

  const hangupCall = async () => {
    log('Hanging up call');

    if (status.value === CALL_STATUS.PENDING) {
      if (reservation) {
        updateContactCenterTaskStatusBySid(reservation.task.sid, { status: 'canceled' });
      }

      call.reject();
    } else {
      call.disconnect();
    }
  };

  const muteCall = () => {
    log(`Muting call ${!muted ? 'off' : 'on'}`);
    call.mute(!muted);
    setMuted(!muted);
  };

  const completeCall = async () => {
    log('Completing call');

    setCall(null);
    setReservation(null);
    setPhoneCall(null);
  };

  const editCall = async ({ note }) => {
    if (!phoneCall.id) {
      return;
    }

    try {
      await updatePhoneCall(phoneCall.id, { note });
      setPhoneCall((draft) => ({ ...draft, note }));
    } catch (e) {}
  };

  const value = {
    initialized,
    initLoading,
    logs,
    isReady: device?.state === 'registered',
    isOnline: device?.state === 'registered' && worker?.activity?.available,
    loading,
    phoneCall,
    status,
    muted,
    configureOutboundCalls,
    configureInboundCalls,
    makeCall,
    editCall,
    acceptCall,
    hangupCall,
    muteCall,
    completeCall,
  };
  return <ContactCenterContext.Provider value={value}>{children}</ContactCenterContext.Provider>;
};

export function useContactCenter() {
  const context = useContext(ContactCenterContext);

  if (!context) {
    throw new Error('useContactCenter must be used within a CallContextProvider');
  }

  return context;
}

export default ContactCenterContextProvider;
