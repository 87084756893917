import React, { useContext } from 'react';
import styled from 'styled-components';

import { Button, ProgressBar } from 'components/lib';
import { FurtherIconWithText } from 'components/common';
import { If } from 'components/If';

import { OnboardingContext } from '.';

const OnboardingHeaderWrapper = styled.div`
  position: sticky;
  min-height: 4.2857rem;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  border-bottom: 1px solid var(--line-standard);
  z-index: 1;
  background-color: var(--white);
  & > div {
    width: calc(100% / 3);
  }
`;

const FurtherIconContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: flex-end;
  > #nextStepContainer {
    margin-left: 7px;
  }
`;

const ProgressWarpper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  & > span {
    font-family: var(--font-family);
    font-size: 12px;
    text-align: center;
    color: #222;
    line-height: 1em;
  }
`;

export const OnboardingHeader = () => {
  const { currentStep, currentStepNumber, handlePreviousStep, steps, hidePreviousStep } = useContext(OnboardingContext);
  const progress = (currentStepNumber / steps.length) * 100;

  if (!currentStep) return null;

  return (
    <OnboardingHeaderWrapper>
      <FurtherIconContainer>
        <FurtherIconWithText />
      </FurtherIconContainer>
      <If condition={!currentStep.hideProgressBar}>
        <ProgressWarpper>
          <ProgressBar progress={progress} />
          <span>{`Step ${currentStepNumber} of ${steps.length}: ${currentStep?.name}`}</span>
        </ProgressWarpper>
      </If>
      <ButtonContainer>
        <If condition={currentStep?.hasPreviousStep && !hidePreviousStep}>
          <Button size="small" primary onClick={handlePreviousStep}>
            {currentStep?.previusButtonText || 'Previous Step'}
          </Button>
        </If>
        <div id="nextStepContainer"></div>
      </ButtonContainer>
    </OnboardingHeaderWrapper>
  );
};
