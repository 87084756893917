import React from 'react';
import { Table } from 'semantic-ui-react';

const ComparisonCell = ({ valueA, valueB, trend, ascColor = 'green', descColor = 'red' }) => {
  const getValueColor = () => {
    switch (trend) {
      case 'asc':
        return ascColor;
      case 'desc':
        return descColor;
      default:
        return 'inherit';
    }
  };

  return (
    <Table.Cell>
      <span style={{ color: getValueColor() }}>{valueA}</span> | {valueB}
    </Table.Cell>
  );
};

export default ComparisonCell;
