import React, { useState } from 'react';
import styled from 'styled-components';
import { Header, Menu, Input, Popup } from 'semantic-ui-react';
import { Icon } from 'components/lib';
import { Link, useHistory, useParams } from 'react-router-dom';

import { useModuleRecordings } from './ModuleRecordingsContext';
import { COMMUNITY_STATUS } from './constants';

const CommunitiesMenuWrapper = styled.div`
  padding: 0.5rem;

  .ui.header .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .basic.tiny.button {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .ui.input {
    margin-bottom: 2rem;
  }

  .ui.vertical.menu {
    margin: 0;

    span {
      display: inline-block;
      width: 90%;
    }

    .item > div.item-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .item > i.icon {
      color: #a9a9a9;

      &:hover {
        color: #333;
      }
    }
  }
`;

const MenuIcon = styled(Icon)`
  && {
    display: unset;
    margin: 0;
    width: 14px;
    height: 14px;
  }
`;

const DEFAULT_SYNC_STATUS_ITEM = {
  display: false,
  text: null,
  formDisabled: false,
  loading: false,
  component: null,
};

const SYNC_STATUS_COMPONENTS = {
  [COMMUNITY_STATUS.NONE]: DEFAULT_SYNC_STATUS_ITEM,
  [COMMUNITY_STATUS.ACTIVE]: {
    ...DEFAULT_SYNC_STATUS_ITEM,
    display: true,
    text: 'Active',
    component: <MenuIcon size="small" name="done_all" color="green" />,
  },
  [COMMUNITY_STATUS.DRAFT]: {
    ...DEFAULT_SYNC_STATUS_ITEM,
    display: true,
    text: 'Draft',
    component: <MenuIcon size="small" name="pending_actions" color="grey" />,
  },
};

export const CommunityMenuItem = ({ community }) => {
  const { chatId, communityId } = useParams();
  const { getCommunitySyncStatus } = useModuleRecordings();

  const syncStatusVal = getCommunitySyncStatus(community);
  const syncStatus = SYNC_STATUS_COMPONENTS[syncStatusVal];

  return (
    <Menu.Item
      key={community.id}
      active={community.id === parseInt(communityId)}
      as={Link}
      to={`/chats/${chatId}/module-recordings/communities/${community.id}`}
    >
      <div className="item-wrapper">
        <div>
          <span>{community.name}</span>
        </div>
        {syncStatus.component && <Popup content={syncStatus.text} trigger={<div>{syncStatus.component}</div>} />}
      </div>
    </Menu.Item>
  );
};

export const CommunitiesMenu = () => {
  const { chatId } = useParams();
  const history = useHistory();

  const [filter, setFilter] = useState('');
  const { state } = useModuleRecordings();
  const { communities } = state;

  const visibleCommunities =
    communities?.filter((p) => !filter || p.name?.toLowerCase().includes(filter.toLowerCase())) || [];

  const goToFiltered = () => {
    if (visibleCommunities.length > 0) {
      const id = visibleCommunities[0].id;
      setFilter('');
      history.push(`/chats/${chatId}/module-recordings/communities/${id}`);
    }
  };

  return (
    <CommunitiesMenuWrapper>
      <Header as="h4">
        <Header.Content>LINKED COMMUNITIES</Header.Content>
      </Header>
      <Input
        transparent
        icon="search"
        iconPosition="left"
        placeholder="Filter by name..."
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            goToFiltered();
          }
          if (e.key === 'Escape') {
            setFilter('');
          }
        }}
      />
      <Menu secondary vertical fluid>
        {visibleCommunities.map((p, i) => (
          <CommunityMenuItem key={p.id} community={p} />
        ))}
      </Menu>
    </CommunitiesMenuWrapper>
  );
};
