import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

import { Dimmer, Loader, Button } from 'components/lib';
import { parseErrorResponse } from 'apis/utils';
import FailureMessage from 'components/common/FailureMessage';
import { administrateUser, createManagementUserInvite, getManagementUsers } from 'apis/ManagementAPI';
import { useUserContext } from 'components/UserContext';
import { If } from 'components/If';
import UserManagerList from './ManagementUserList';
import ManagementUserConfirm from './ManagementUserConfirm';
import { ManagementAddUserModal } from './ManagementAddUserModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
`;

const Header = styled.div`
  font-size: 60px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const ManagementUsers = () => {
  const history = useHistory();
  const [managementUsers, setManagementUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [managedUser, setManagedUser] = useState();
  const [administrateLoading, setAdministrateLoading] = useState(false);
  const { isImpersonatedSession } = useUserContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const readOnly = isImpersonatedSession;

  const fetchUsers = async () => {
    try {
      setError(null);
      const { results } = await getManagementUsers();

      setManagementUsers(results);
    } catch (error) {
      setError(parseErrorResponse(error, 'Unable to fetch users'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAdministrate = async (managedUser) => {
    setManagedUser(managedUser);
  };

  const handleConfirmAdministrate = async () => {
    try {
      setAdministrateLoading(true);
      await administrateUser(managedUser.user.id, { administrate: true });
      history.push('/management/session');
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e, 'Unable to administrate user'));
    } finally {
      setAdministrateLoading(false);
      setManagedUser(null);
    }
  };

  const handleCancelAdministrate = async () => {
    setManagedUser(null);
  };

  const handleOnClose = () => {
    setIsModalOpen(false);
  };

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  const updateManagementUsersList = (user) => {
    setManagementUsers((prev) => [...prev, user].sort((a, b) => a.user.email.localeCompare(b.user.email)));
  };

  const handleSendInvite = async (managedUser) => {
    try {
      await createManagementUserInvite(managedUser.id);
      toast.success(`Invite sent to ${managedUser.user.email}`);
      setManagementUsers((prev) =>
        prev.map((user) => (user.id === managedUser.id ? { ...user, sendInvite: true } : user))
      );
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to send invite'));
    }
  };

  return (
    <Container>
      <Dimmer active={loading} inverted>
        <Loader active />
      </Dimmer>
      <Header>Management</Header>
      {error && <FailureMessage content={error} />}
      <If condition={!readOnly}>
        <ButtonContainer>
          <Button color="blue" onClick={handleOnClick}>
            Add User
          </Button>
        </ButtonContainer>
      </If>
      <UserManagerList
        managementUsers={managementUsers}
        readOnly={readOnly}
        onAdministrate={handleAdministrate}
        onSendInvite={handleSendInvite}
      />
      <ManagementUserConfirm
        managedUser={managedUser}
        loading={administrateLoading}
        onConfirm={handleConfirmAdministrate}
        onCancel={handleCancelAdministrate}
      />
      <ManagementAddUserModal
        open={isModalOpen}
        onClose={handleOnClose}
        updateManagementUsersList={updateManagementUsersList}
      />
    </Container>
  );
};

export default ManagementUsers;
