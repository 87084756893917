import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { phoneSettingsUpdateCommunities, phoneSettingsGetCommunities, getVoices } from 'apis';
import useDataList from 'utils/hooks/useDataList';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES, PAGINATION } from '../../../constants';

import {
  CommunityRow,
  SettingsBooleanContainer,
  SettingsContainer,
  SettingsDescription,
  SettingsDropdown,
  SettingsFiltersContainer,
  SettingsHeader,
  SettingsPagination,
  SettingsSearch,
  SettingsSelectedCountModal,
  SettingsTable,
  SettingsTableBody,
  SettingsTableRow,
  SettingsTableRowContainer,
  SettingsTableToggle,
  SettingsValueText,
} from '../common';
import { useSettings } from '../context/SettingsContext';
import {
  setResetAllItemsSelected,
  setResetExcludedItems,
  setResetIsMultipleSelection,
  setResetSelectedPages,
  setSelectedItem,
  resetTableSelection,
} from '../context/actions';
import { getUrlFilterAndSearch } from '../common/constants';
import { VoiceModal } from './VoiceModal';

const flagName = 'selected_voice';

export const Voice = () => {
  const { urlFilterValue, urlSearchValue } = getUrlFilterAndSearch({ flagName });
  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters, totalItemsCount } = useDataList({
    initialState: {
      filters: {
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.PAGE_SIZE,
        search: urlSearchValue,
        [flagName]: urlFilterValue,
      },
      setUrlParams: true,
    },
  });
  const [{ isMultipleSelection, selectedItems, excludedItems }, dispatch] = useSettings();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const [voices, setVoices] = useState([]);
  const defaultVoice = voices?.find((voice) => voice.isDefault);

  const dropdownOptions = [
    { key: 0, text: 'Any voice', value: null },
    ...voices.map((voice) => ({ key: voice.id, text: voice.name, value: voice.id })),
  ];

  const fetchCommunities = useCallback(
    async ({ page, pageSize, search, [flagName]: flag }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await phoneSettingsGetCommunities({
          page,
          page_size: pageSize,
          search,
          [flagName]: flag,
          show_existing_settings: true,
        });
        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch data');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  const fetchVoices = useCallback(async () => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const response = await getVoices();
      setVoices(response);
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e), 'Unable to fetch voices!');
      setLoaderStatus(STATUSES.FAILURE);
    }
  }, [setItems]);

  useEffect(() => {
    fetchCommunities(filters);
    if (voices.length === 0) {
      fetchVoices();
    }
  }, [fetchCommunities, filters]);

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
  };

  const handleDropdownChange = (e, { value }) => {
    setFilters({ [flagName]: value });
    dispatch(resetTableSelection());
  };

  const handleOnSearch = (value) => {
    setSearch(value);
    dispatch(resetTableSelection());
  };

  const handleOnRowClick = (item) => {
    if (!isMultipleSelection) {
      setIsModalOpen(true);
      dispatch(setSelectedItem({ id: item.id, items, currentPage: filters.page }));
    }
  };

  const handleOnModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetTableSelection());
  };

  const handleOnSelectedCountModalClick = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (values) => {
    setLoaderStatus(STATUSES.LOADING);
    const data = {
      include_communities: selectedItems,
      exclude_communities: excludedItems,
      ...values,
    };

    try {
      await phoneSettingsUpdateCommunities(data);
      toast.success('Settings updated successfully!');
    } catch (error) {
      const errorText = 'Trouble updating settings!';
      const msg = parseErrorResponse(error, errorText);
      console.error(msg, error?.response);
      toast.error(msg);
      setLoaderStatus(STATUSES.FAILURE);
    } finally {
      handleOnModalClose();
      dispatch(setResetExcludedItems());
      dispatch(setResetAllItemsSelected());
      dispatch(setResetSelectedPages());
      dispatch(setResetIsMultipleSelection());
      setLoaderStatus(STATUSES.LOADED);
      setFilters({
        page: PAGINATION.PAGE,
        page_size: PAGINATION.PAGE_SIZE,
        search: null,
        [flagName]: null,
      });
    }
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const selectedItem = selectedItems.length === 1 ? items.find((item) => item.id === selectedItems[0]) : null;

  return (
    <SettingsContainer loading={loading} totalItemsCount={totalItemsCount}>
      <If condition={!isModalOpen}>
        <SettingsSelectedCountModal
          totalItemsCount={totalItemsCount}
          items={items}
          filters={filters}
          totalPages={totalPages}
          onClick={handleOnSelectedCountModalClick}
        />
      </If>
      <VoiceModal
        isModalOpen={isModalOpen}
        selectedItem={selectedItem}
        totalItemsCount={totalItemsCount}
        onSave={handleSubmit}
        onClose={handleOnModalClose}
        isMultipleSelection={isMultipleSelection}
        voices={voices}
        defaultVoice={defaultVoice}
      />

      <SettingsDescription>
        <strong>Select a voice</strong> to use with the automated phone system.
      </SettingsDescription>
      <SettingsHeader>
        <SettingsTableToggle />
        <SettingsFiltersContainer>
          <SettingsDropdown
            options={dropdownOptions}
            value={parseInt(filters[flagName])}
            onChange={handleDropdownChange}
            placeholder="Any voice"
          />
          <SettingsSearch
            onSearch={handleOnSearch}
            value={filters.search}
            placeholder={`Search ${t('communities')}`}
            loading={loading}
          />
        </SettingsFiltersContainer>
      </SettingsHeader>
      <SettingsTable tableName={`${t('community')}`} currentPage={filters.page} items={items}>
        <SettingsTableBody>
          {items?.map((item, i) => (
            <SettingsTableRow item={item} key={i} onClick={() => handleOnRowClick(item)} items={items}>
              <SettingsTableRowContainer>
                <CommunityRow name={item.name} picture={item.picture_url} clickable={!isMultipleSelection} />
                <If condition={!isMultipleSelection}>
                  <SettingsBooleanContainer>
                    <SettingsValueText text={item.voice_name || defaultVoice?.name} />
                  </SettingsBooleanContainer>
                </If>
              </SettingsTableRowContainer>
            </SettingsTableRow>
          ))}
        </SettingsTableBody>
      </SettingsTable>
      <SettingsPagination filters={filters} totalPages={totalPages} onPageChange={handlePageChange} />
    </SettingsContainer>
  );
};
