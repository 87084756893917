import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { If } from 'components/If';

const Row = styled(Grid.Row)`
  margin: 5px;
`;

const ValidChatText = styled.div`
  padding: 0.5rem;
`;

const ErrorText = styled.div`
  font-size: 1.1rem;
`;

const ChatValidationDetails = ({ validationErrors }) => {
  if (!validationErrors) {
    return null;
  }

  if (!validationErrors.invalid) {
    return (
      <Grid>
        <ValidChatText>
          <p>Chat is valid. Are you sure you want to publish changes?</p>
          <p>This will make the changes you made here visible to your website visitors.</p>
        </ValidChatText>
      </Grid>
    );
  }

  return (
    <Grid columns={2}>
      <If condition={validationErrors.severe_errors?.length}>
        <Row style={{ backgroundColor: '#ffdede' }}>
          <Grid.Column width={2}>
            <Icon name="delete" size="large" color="red" />
          </Grid.Column>
          <Grid.Column width={14}>
            {validationErrors.severe_errors?.map((errorType, i) => (
              <div key={i}>
                <ErrorText>{errorType.text}</ErrorText>
                <ul>
                  {errorType.values?.map((error, i) => (
                    <li key={i}>
                      {error.type ? (
                        <p>
                          Module {error.number} [{error.type}]
                        </p>
                      ) : (
                        <p>{error}</p>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Grid.Column>
        </Row>
      </If>
      <If condition={validationErrors.warnings?.length}>
        <Row style={{ backgroundColor: '#fffae3' }}>
          <Grid.Column width={2}>
            <Icon name="exclamation" size="large" color="orange" />
          </Grid.Column>
          <Grid.Column width={14}>
            {validationErrors.warnings?.map((warning, i) => (
              <div key={i}>
                <ErrorText>{warning.text}</ErrorText>
                <ul key={i}>
                  {warning.values?.map((error, i) => (
                    <li key={i}>{error}</li>
                  ))}
                </ul>
              </div>
            ))}
          </Grid.Column>
        </Row>
      </If>
    </Grid>
  );
};

export default ChatValidationDetails;
