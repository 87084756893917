import React from 'react';

import { ContactRulesProvider } from './ContactRuleContext';
import ContactRulesRoot from './ContactRulesRoot';

const ContactRules = () => {
  return (
    <ContactRulesProvider>
      <ContactRulesRoot />
    </ContactRulesProvider>
  );
};

export default ContactRules;
