import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Header, Menu } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import { ChatsContext } from '../ChatsContext';
import { FilterInput } from '../../common/FilterInput';

const Wrapper = styled.div`
  overflow: auto;
`;

export const AnalyticsChatModulesMenu = () => {
  const { chatId } = useParams();
  const { chats } = useContext(ChatsContext);
  const [filteredChats, setFilteredChats] = useState(chats);

  return (
    <Wrapper>
      <Header as="h4" content="CHATS" />
      <FilterInput items={chats} placeholder="Filter chats..." filterBy={['name']} onFilter={setFilteredChats} />
      <Menu secondary vertical fluid>
        {filteredChats?.map((chat) => (
          <Menu.Item
            key={chat.id}
            active={chat.id === parseInt(chatId)}
            as={Link}
            to={`/chats/analytics/details/${chat.id}`}
          >
            {chat.name}
          </Menu.Item>
        ))}
      </Menu>
    </Wrapper>
  );
};
