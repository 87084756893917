import React, { useContext } from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Button } from 'components/lib';
import ButtonBG from 'assets/images/ai_bg_1.png';
import { UserContext } from 'components/UserContext';
import { MEDIA_BREAK_POINTS, USER_TYPES } from '../../../../constants';
import { WizardModal } from './WizardModal';
import { CampaignsTemplateProvider, useCampaignsTemplate } from '../CampaignsTemplateContext';
import { CreatingCampaignsModal } from '../TemplateWizard/CreatingCampaignsModal';

const StyledButton = styled(Button)`
  &&& {
    width: 144px;
    background-image: url(${ButtonBG});
    margin-left: auto;
    margin-right: 10px;
    @media only screen and (max-width: ${MEDIA_BREAK_POINTS.WEB}px) {
      margin-top: 30px;
    }
  }
`;

const AI = styled.span`
  color: #ff5b98;
`;

const Component = () => {
  const { state, dispatch, actions } = useCampaignsTemplate();
  const { isWizardActive, isCreatingCampaignsModalOpen, initialized } = state;

  const handleOnClick = () => {
    dispatch({ type: actions.SET_WIZARD_ACTIVE, data: true });
  };

  return (
    <>
      <StyledButton primary onClick={handleOnClick} disabled={!initialized}>
        Create with&nbsp;<AI>AI</AI>
      </StyledButton>
      <If condition={isWizardActive}>
        <WizardModal open={isWizardActive} />
      </If>
      <If condition={isCreatingCampaignsModalOpen}>
        <CreatingCampaignsModal open={isCreatingCampaignsModalOpen} />
      </If>
    </>
  );
};

export const CreateWithAI = () => {
  const { getSessionOriginalUser } = useContext(UserContext);
  const user = getSessionOriginalUser();
  const isStaffUser = user.type === USER_TYPES.STAFF;

  if (!isStaffUser) {
    return null;
  }

  return (
    <CampaignsTemplateProvider>
      <Component />
    </CampaignsTemplateProvider>
  );
};
