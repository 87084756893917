export const ANALYTIC_ITEM_DESCRIPTIONS = {
  NEW_WEBSITE_VISITOR: 1,
  VSA_USERS: 2,
  VSA_INTERACTION_RATE: 3,
  NEW_LEADS: 4,
  VISITOR_CONVERSION: 5,
  VSA_USER_CONVERSION: 6,
  TOURS_SCHEDULED: 7,
  CONFIRMED_MOVE_INS: 8,
};

export const ANALYTICS_TABS = {
  OVERVIEW: 'overview',
  INSIGHTS: 'insights',
  LEAD_VALUE_SCORE: 'lead_value_score',
  KEYWORDS: 'keywords',
  INSTANT_ANSWERS: 'instant_answers',
  BENCHMARKS: 'benchmarks',
};

export const ANALYTICS_CHANNELS = {
  chat_api: 'Chat',
  conversational_webform: 'Conversational Webform',
  web_form_submit: 'Legacy Webform',
  conversational_ivr: 'Conversational IVR',
};
