import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/lib';
import { SCHEDULE_TYPES } from './constants';
import { ReminderTime } from './ReminderTime';

const Container = styled.div`
  padding: 10px 20px 6px;
  border-radius: 16px;
  box-shadow: 0 3px 5px 0 rgba(216, 216, 216, 0.85);
  border: solid 1px var(--salmon);
  background-color: #fff1ee;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-family: var(--font-family);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--text);
    margin: 0 20px 0 10px;
  }
`;

export const ConversationFloatingReminderCanceled = ({ type, data }) => {
  const t = useTranslation();
  const reminderType = SCHEDULE_TYPES(t)[type];

  const { created_on, extra_data, user } = data;

  return (
    <Container>
      <Icon name={reminderType.icon} size={16} color={reminderType.color} />
      <p>
        {user?.name} canceled a {type} that was requested for {extra_data?.start}
      </p>
      <ReminderTime date={created_on} />
    </Container>
  );
};
