import React from 'react';
import styled from 'styled-components';

import { Pagination } from 'components/lib';
import { If } from 'components/If';

const StyledPagination = styled(Pagination)`
  &&&& {
    margin-top: 10px;
    margin-left: 0;
  }
`;

export const SettingsPagination = ({ totalPages, filters, onPageChange }) => {
  return (
    <If condition={totalPages > 1}>
      <StyledPagination activePage={filters?.page} onPageChange={onPageChange} totalPages={totalPages} />
    </If>
  );
};
