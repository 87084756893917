import React, { useContext } from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { SettingsContext } from '../SettingsContext';
import { useContactRules } from './ContactRuleContext';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: var(--text);
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--light-text);
  .view-all {
    color: var(--link);
    cursor: pointer;
  }
`;

const ContactRuleCommunityList = ({ contactRuleId, communitiesIds }) => {
  const { handleOpenModal } = useContactRules();
  const { communities: allCommunities } = useContext(SettingsContext);
  const communities = allCommunities.filter((c) => communitiesIds.includes(c.id));
  const noOfCommunitiesShown = Math.min(communities.length, 10);
  const hasMore = communities.length - noOfCommunitiesShown;

  return (
    <Container>
      {communities.slice(0, noOfCommunitiesShown).map((c) => (
        <div>{c.name}</div>
      ))}
      <If condition={hasMore}>
        <span className="view-all" onClick={() => handleOpenModal({ contactRuleId })}>
          &nbsp;and {hasMore} more
        </span>
      </If>
    </Container>
  );
};

export default ContactRuleCommunityList;
