import React from 'react';
import { Icon, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { formatCurrency, formatPrices } from 'components/utils';

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const IndicatorTableCell = ({ isQualified, forWhat }) => (
  <Table.Cell title={isQualified ? `Lead is qualified for ${forWhat}` : `Lead is not qualified for ${forWhat}`}>
    <Icon name={isQualified ? 'check circle' : 'minus circle'} color={isQualified ? 'green' : 'red'} />
  </Table.Cell>
);

export const BudgetQualificationTable = ({ lead, automatedAnswer }) => {
  if (!lead?.community?.budget_qualification_config || !automatedAnswer) {
    return (
      <Table compact singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">Budget Qualification</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>
    );
  }
  const { budget_qualification_config: config } = lead.community;

  return (
    <Table compact singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">Budget Qualification</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Lead Budget</Table.Cell>
          <Table.Cell colSpan="2">{lead.latest_budget ? formatCurrency(lead.latest_budget) : 'Unknown'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Use thresholds</Table.Cell>
          <Table.Cell colSpan="2">{config.use_thresholds ? 'Yes' : 'No'}</Table.Cell>
        </Table.Row>
        {config.use_thresholds ? (
          <>
            <Table.Row>
              <Table.Cell>Tour threshold</Table.Cell>
              <Table.Cell>{config.tour_threshold}%</Table.Cell>
              <IndicatorTableCell isQualified={lead.qualifies_for_tour} forWhat="tour" />
            </Table.Row>
            <Table.Row>
              <Table.Cell>Call threshold</Table.Cell>
              <Table.Cell>{config.call_threshold}%</Table.Cell>
              <IndicatorTableCell isQualified={lead.qualifies_for_call} forWhat="call" />
            </Table.Row>
          </>
        ) : null}
      </Table.Body>
    </Table>
  );
};
