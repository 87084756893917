import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { STATUSES } from '../../../constants';
import { assignCommunityCareType } from '../../../apis';
import { DropDownPicker } from '../../DropDownPicker';
import { Button } from '../../lib';
import { CareTypesContext } from './CareTypesContext';

const CareTypeSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
`;

export const CommunityCareTypesAssign = () => {
  const { communityId } = useParams();
  const { status, careTypes, setStatus, refreshCareTypes } = useContext(CareTypesContext);

  const [selectedCareTypes, setSelectedCareTypes] = useState([]);

  useEffect(() => {
    setSelectedCareTypes([]);
  }, [communityId]);

  const handleCareTypesSelect = (careTypesSelection) => {
    setSelectedCareTypes(careTypesSelection);
  };

  const onAssignCareTypes = async () => {
    try {
      setStatus(STATUSES.LOADING);
      await assignCommunityCareType(communityId, { care_types: selectedCareTypes });
      refreshCareTypes();
      setSelectedCareTypes([]);
      setStatus(STATUSES.LOADED);
    } catch (e) {
      setStatus(STATUSES.FAILURE);
    }
  };

  const loading = status === STATUSES.LOADING;
  const buttonDisabled = loading || selectedCareTypes.length < 1;

  return (
    <CareTypeSelector>
      <DropDownPicker
        placeholder="Care Types."
        allItemsSelectedText="All Care Types"
        options={careTypes}
        disabled={loading}
        selectedValues={selectedCareTypes}
        onSelect={handleCareTypesSelect}
      />
      <Button onClick={onAssignCareTypes} disabled={buttonDisabled}>
        Add
      </Button>
    </CareTypeSelector>
  );
};
