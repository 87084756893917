import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 52px 62px 0;
  height: 179px;
  background-color: #ffffff;
  border-bottom: 1px solid var(--line-standard);
`;

export const MainContainerTop = ({ children }) => {
  return <Container>{children}</Container>;
};
