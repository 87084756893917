import React from 'react';
import styled from 'styled-components';

import { Popup as SemanticPopup } from 'semantic-ui-react';

const StyledPopup = styled(SemanticPopup)`
  &&:not(.menu) {
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 5px 0 #d8d8d8;
    border: solid 1px var(--line-standard);
    background-color: var(--white);
    z-index: 2100;
    &.wide {
      max-width: 370px;
      &.very {
        max-width: 550px;
      }
    }
    &.no-padding {
      padding: 0;
    }
    &.tight {
      padding: 10px;
    }
    &.attached {
      padding: 0;
      border-radius: 0px;
      padding: 7px 10px 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px var(--line-standard);
      background-color: var(--white);
      margin: 0;
    }
  }
  &&.menu {
    padding: 0;
  }
  &&:not(.menu).compact {
    padding: 1rem;
  }
`;

export const Popup = ({ ...props }) => <StyledPopup {...props} />;
