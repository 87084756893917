import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Button, Icon } from 'components/lib';
import { UserContext } from '../../UserContext';
import { Container } from './common';
import { NextStep } from './NextStep';

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 30px;
  width: 100%;
  p {
    margin-bottom: 60px;
  }
  h2 {
    margin-top: 40px;
  }
`;

export const Success = () => {
  const { fetchUser } = useContext(UserContext);
  const history = useHistory();
  const handleOpenDashboard = () => {
    fetchUser();
    history.push('/');
  };
  return (
    <Container maxWidth="35.7143rem">
      <SuccessContainer>
        <Icon name="done" size="60" color="var(--emerald-green)" />
        <h2>Success!</h2>
        <p>
          You have set up your web assistant!
          <br />
          You can open the Further dashboard to fine-tune its details.
        </p>
        <Button size="big" fluid primary color="blue" onClick={handleOpenDashboard}>
          Open Dashboard
        </Button>
        <NextStep text="Open Dashboard" onNextStep={handleOpenDashboard} />
      </SuccessContainer>
    </Container>
  );
};
