import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { phoneSettingsUpdateCommunities, phoneSettingsGetCommunities } from 'apis';
import useDataList from 'utils/hooks/useDataList';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES, PAGINATION } from '../../../constants';
import { CallWhisperModal } from './CallWhisperModal';

import {
  CommunityRow,
  SettingsBooleanContainer,
  SettingsContainer,
  SettingsDescription,
  SettingsDropdown,
  SettingsFiltersContainer,
  SettingsHeader,
  SettingsPagination,
  SettingsSearch,
  SettingsSelectedCountModal,
  SettingsTable,
  SettingsTableBody,
  SettingsTableRow,
  SettingsTableRowContainer,
  SettingsTableToggle,
  SettingsValueText,
  SettingsOff,
  SettingsOn,
} from '../common';
import { useSettings } from '../context/SettingsContext';
import {
  setResetAllItemsSelected,
  setResetExcludedItems,
  setResetIsMultipleSelection,
  setResetSelectedPages,
  setSelectedItem,
  resetTableSelection,
} from '../context/actions';
import { getUrlFilterAndSearch } from '../common/constants';

const dropdownOptions = [
  { key: '1', text: 'Any status', value: null },
  { key: '2', text: 'On', value: 'on' },
  { key: '3', text: 'On & allow accepting/rejecting call', value: 'on_accept' },
  { key: '4', text: 'Off', value: 'off' },
];

const dropdownValues = {
  on: {
    use_call_whispering: true,
    use_call_screening: false,
  },
  on_accept: {
    use_call_screening: true,
  },
  off: {
    use_call_whispering: false,
  },
};

const flagName = 'use_call_whispering';

export const CallWhisper = () => {
  const { urlFilterValue, urlSearchValue } = getUrlFilterAndSearch({ flagName });
  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters, totalItemsCount } = useDataList({
    initialState: {
      filters: {
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.PAGE_SIZE,
        search: urlSearchValue,
        [flagName]: urlFilterValue,
      },
      setUrlParams: true,
    },
  });
  const [{ isMultipleSelection, selectedItems, excludedItems }, dispatch] = useSettings();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const fetchCommunities = useCallback(
    async ({ page, pageSize, search, [flagName]: flag }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await phoneSettingsGetCommunities({
          page,
          page_size: pageSize,
          search,
          show_existing_settings: true,
          ...dropdownValues[flag],
        });
        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch data');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchCommunities(filters);
  }, [fetchCommunities, filters]);

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
  };

  const handleDropdownChange = (e, { value }) => {
    setFilters({ [flagName]: value });
    dispatch(resetTableSelection());
  };

  const handleOnSearch = (value) => {
    setSearch(value);
    dispatch(resetTableSelection());
  };

  const handleOnRowClick = (item) => {
    if (!isMultipleSelection) {
      setIsModalOpen(true);
      dispatch(setSelectedItem({ id: item.id, items, currentPage: filters.page }));
    }
  };

  const handleOnModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetTableSelection());
  };

  const handleOnSelectedCountModalClick = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (values) => {
    setLoaderStatus(STATUSES.LOADING);
    const data = {
      include_communities: selectedItems,
      exclude_communities: excludedItems,
      ...values,
    };

    try {
      await phoneSettingsUpdateCommunities(data);
      toast.success('Settings updated successfully!');
    } catch (error) {
      const errorText = 'Trouble updating settings!';
      const msg = parseErrorResponse(error, errorText);
      console.error(msg, error?.response);
      toast.error(msg);
      setLoaderStatus(STATUSES.FAILURE);
    } finally {
      handleOnModalClose();
      dispatch(setResetExcludedItems());
      dispatch(setResetAllItemsSelected());
      dispatch(setResetSelectedPages());
      dispatch(setResetIsMultipleSelection());
      setLoaderStatus(STATUSES.LOADED);
      setFilters({
        page: PAGINATION.PAGE,
        page_size: PAGINATION.PAGE_SIZE,
        search: null,
        [flagName]: null,
      });
    }
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const selectedItem = selectedItems.length === 1 ? items.find((item) => item.id === selectedItems[0]) : null;

  return (
    <SettingsContainer loading={loading} totalItemsCount={totalItemsCount}>
      <If condition={!isModalOpen}>
        <SettingsSelectedCountModal
          totalItemsCount={totalItemsCount}
          items={items}
          filters={filters}
          totalPages={totalPages}
          onClick={handleOnSelectedCountModalClick}
        />
      </If>
      <CallWhisperModal
        isModalOpen={isModalOpen}
        selectedItem={selectedItem}
        totalItemsCount={totalItemsCount}
        onSave={handleSubmit}
        flagName={flagName}
        onClose={handleOnModalClose}
        isMultipleSelection={isMultipleSelection}
      />

      <SettingsDescription>
        Turn call <strong>whisper message</strong> on or off, and specify whether the person answering the call is able
        to choose whether to start call or hang up.
      </SettingsDescription>
      <SettingsHeader>
        <SettingsTableToggle />
        <SettingsFiltersContainer>
          <SettingsDropdown
            options={dropdownOptions}
            value={filters[flagName]}
            onChange={handleDropdownChange}
            placeholder="Any status"
          />
          <SettingsSearch
            onSearch={handleOnSearch}
            value={filters.search}
            placeholder={`Search ${t('communities')}`}
            loading={loading}
          />
        </SettingsFiltersContainer>
      </SettingsHeader>
      <SettingsTable tableName={`${t('community')}`} currentPage={filters.page} items={items}>
        <SettingsTableBody>
          {items?.map((item, i) => (
            <SettingsTableRow item={item} key={i} onClick={() => handleOnRowClick(item)} items={items}>
              <SettingsTableRowContainer>
                <CommunityRow name={item.name} picture={item.picture_url} clickable={!isMultipleSelection} />
                <If condition={!isMultipleSelection}>
                  <SettingsBooleanContainer>
                    <If condition={item.use_call_whispering && !item.use_call_screening}>
                      <SettingsOn />
                    </If>
                    <If condition={item.use_call_whispering && item.use_call_screening}>
                      <div>
                        <SettingsOn />
                        <SettingsValueText text="Allow accepting/rejecting call" color="#0ab776" />
                      </div>
                    </If>
                    <If condition={!item[flagName]}>
                      <SettingsOff />
                    </If>
                  </SettingsBooleanContainer>
                </If>
              </SettingsTableRowContainer>
            </SettingsTableRow>
          ))}
        </SettingsTableBody>
      </SettingsTable>
      <SettingsPagination filters={filters} totalPages={totalPages} onPageChange={handlePageChange} />
    </SettingsContainer>
  );
};
