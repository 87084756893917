import React from 'react';

import { CommunitiesGroupsContextProvider, CommunitiesGroupsIndex } from '.';

export const CommunitiesGroups = () => {
  return (
    <CommunitiesGroupsContextProvider>
      <CommunitiesGroupsIndex />
    </CommunitiesGroupsContextProvider>
  );
};
