import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { settingsGetCommunities, settingsUpdateCommunities } from 'apis';
import useDataList from 'utils/hooks/useDataList';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES, PAGINATION } from '../../../constants';
import {
  SettingsTable,
  SettingsContainer,
  SettingsDropdown,
  SettingsFiltersContainer,
  SettingsHeader,
  SettingsSearch,
  SettingsTableBody,
  SettingsTableToggle,
  SettingsTableRow,
  SettingsPagination,
  SettingsOff,
  SettingsTableRowContainer,
  SettingsBooleanContainer,
  SettingsSelectedCountModal,
  CommunityRow,
  SettingsOn,
  SettingsDescription,
} from '../common';
import { useSettings } from '../context/SettingsContext';
import { setSelectedItem, resetTableSelection, resetState } from '../context/actions';
import EditModal from './EditModal';
import { FILTER_OPTIONS, FILTER_QUERY } from './constants';
import { getUrlFilterAndSearch } from '../common/constants';

const flagName = 'filter';

const WebAssistantURL = () => {
  const { urlFilterValue, urlSearchValue } = getUrlFilterAndSearch({ flagName });
  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters, totalItemsCount } = useDataList({
    initialState: {
      filters: {
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.PAGE_SIZE,
        search: urlSearchValue,
        [flagName]: urlFilterValue,
      },
      setUrlParams: true,
    },
  });
  const [{ isMultipleSelection, selectedItems, excludedItems }, dispatch] = useSettings();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const fetchCommunities = useCallback(
    async ({ page, pageSize, search, filter }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const q = FILTER_QUERY[filter] || {};
        const { results, count } = await settingsGetCommunities({
          page,
          page_size: pageSize,
          search,
          ...q,
        });
        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch data');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchCommunities(filters);
  }, [fetchCommunities, filters]);

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
  };

  const handleDropdownChange = (e, { value }) => {
    setFilters({ filter: value });
    dispatch(resetTableSelection());
  };

  const handleOnSearch = (value) => {
    setSearch(value);
    dispatch(resetTableSelection());
  };

  const handleOnRowClick = (data) => {
    if (!isMultipleSelection) {
      setIsModalOpen(true);
      dispatch(setSelectedItem(data));
    }
  };

  const handleOnModalClose = () => {
    setIsModalOpen(false);
    dispatch(resetTableSelection());
  };

  const handleOnSelectedCountModalClick = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (values) => {
    setLoaderStatus(STATUSES.LOADING);

    const data = {
      include_communities: selectedItems,
      exclude_communities: excludedItems,
      ...values,
    };

    try {
      await settingsUpdateCommunities(data);
      toast.success('Settings updated successfully!');
    } catch (error) {
      const errorText = 'Trouble updating settings!';
      const msg = parseErrorResponse(error, errorText);
      console.error(msg, error?.response);
      toast.error(msg);
      setLoaderStatus(STATUSES.FAILURE);
    } finally {
      handleOnModalClose();
      dispatch(resetState());
      setLoaderStatus(STATUSES.LOADED);
      setFilters({
        page: PAGINATION.PAGE,
        page_size: PAGINATION.PAGE_SIZE,
        search: null,
        filter: null,
      });
    }
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const selectedItem = selectedItems.length === 1 ? items.find((item) => item.id === selectedItems[0]) : null;

  return (
    <SettingsContainer loading={loading} totalItemsCount={totalItemsCount}>
      <SettingsDescription>
        Choose if web assistants show on a strict URL rather than the main page.
      </SettingsDescription>
      <If condition={!isModalOpen}>
        <SettingsSelectedCountModal
          totalItemsCount={totalItemsCount}
          items={items}
          filters={filters}
          totalPages={totalPages}
          onClick={handleOnSelectedCountModalClick}
        />
      </If>
      <EditModal
        isModalOpen={isModalOpen}
        selectedItem={selectedItem}
        totalItemsCount={totalItemsCount}
        onSave={handleSubmit}
        onClose={handleOnModalClose}
      />
      <SettingsHeader>
        <SettingsTableToggle />
        <SettingsFiltersContainer>
          <SettingsDropdown
            options={FILTER_OPTIONS}
            value={filters.filter}
            onChange={handleDropdownChange}
            placeholder="Any status"
          />
          <SettingsSearch
            onSearch={handleOnSearch}
            value={filters.search}
            placeholder={`Search ${t('communities')}`}
            loading={loading}
          />
        </SettingsFiltersContainer>
      </SettingsHeader>
      <SettingsTable tableName={`${t('community')}`} currentPage={filters.page} items={items}>
        <SettingsTableBody>
          {items?.map((item, i) => (
            <SettingsTableRow
              item={item}
              key={i}
              onClick={() => handleOnRowClick({ id: item.id, items, currentPage: filters.page })}
              items={items}
              currentPage={filters.page}
            >
              <SettingsTableRowContainer>
                <CommunityRow key={i} name={item.name} picture={item.picture_url} clickable={!isMultipleSelection} />
                <If condition={!isMultipleSelection}>
                  <SettingsBooleanContainer fullWidth>
                    {item.strict_chat_url ? (
                      <SettingsOn text="On" description={item.is_fallback_chat_config ? 'Including subpages' : null} />
                    ) : (
                      <SettingsOff />
                    )}
                  </SettingsBooleanContainer>
                </If>
              </SettingsTableRowContainer>
            </SettingsTableRow>
          ))}
        </SettingsTableBody>
      </SettingsTable>
      <SettingsPagination filters={filters} totalPages={totalPages} onPageChange={handlePageChange} />
    </SettingsContainer>
  );
};

export default WebAssistantURL;
