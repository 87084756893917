import React from 'react';

import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import BaseOption from './BaseOption';

const OptionDynamic = ({ name, criterion }) => {
  const { state, hasEditDeletePermission } = useCampaignDetails();
  const { criteria } = state;
  const { operators, dynamicOptions } = criteria.find((c) => c.id === criterion.criterionId);

  return (
    <BaseOption
      name={name}
      criterion={criterion}
      options={dynamicOptions}
      canEditDelete={hasEditDeletePermission}
      operators={operators}
    />
  );
};

export default OptionDynamic;
