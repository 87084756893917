import React, { Suspense, lazy } from 'react';

const LazyEditor = lazy(() => import('./CodeMirror'));

export default function StyleEditor({ onChange, value }) {
  return (
    <Suspense fallback={false}>
      <LazyEditor onChange={onChange} value={value} />
    </Suspense>
  );
}
