import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import classNames from 'classnames';

import { MEDIA_BREAK_POINTS } from '../../constants';

const Title = styled.h6`
  font-family: var(--font-family);
  position: absolute;
  top: -24px;
  left: 0;
  width: 100%;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  z-index: 1;
`;

const StyledDatePicker = styled.div`
  width: 100%;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  opacity: ${({ $disabled }) => ($disabled ? 0.6 : 1)};
  position: relative;
  & > div {
    width: 100%;
  }
  .react-datepicker {
    width: 100%;
    border: none;
    display: flex;
    position: relative;
  }
  .react-datepicker__month-container {
    float: none;
    width: 100%;
  }
  .react-datepicker__header {
    background-color: var(--white);
    border-bottom: none;
  }

  .react-datepicker__navigation {
    font-size: 12px;
    width: 24px;
    height: 24px;
    border: none;
    top: 15px;
    ::before {
      font-family: 'Material Icons';
      font-size: 24px;
      position: relative;
      display: block;
      width: 24px;
      height: 24px;
      font-feature-settings: 'liga' 1;
      text-indent: 0;
    }
    &.react-datepicker__navigation--previous {
      :before {
        content: '\\e5cb'; // Left caret
      }
    }
    &.react-datepicker__navigation--next {
      :before {
        content: '\\e5cc'; // Right caret
      }
    }
  }
  .react-datepicker__current-month {
    font-family: var(--font-family-bold);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: var(--text);
    margin-top: 11px;
    margin-bottom: 27px;
    padding-bottom: 7px;
    border-bottom: solid 1px var(--line-standard);
  }
  .react-datepicker__month {
    margin: 6px 16px 16px 16px;
  }

  .react-datepicker__day-name {
    font-family: var(--font-family-bold);
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1d252d;
    margin: 0 5px;
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__day {
    font-family: var(--font-family);
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    padding-top: 2px;
    text-align: center;
    color: var(--text);
    margin: 3px;
    width: 28px;
    height: 28px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__day--disabled {
    color: var(--line-standard);
  }

  .react-datepicker__day:hover:not(.react-datepicker__day--disabled),
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
    border: solid 1px;
    border-color: ${({ $color }) => $color};
    background-color: ${({ $fadedColor }) => $fadedColor};
    color: ${({ $color }) => $color};
    border-radius: 50px;
  }

  &.small {
    @media only screen and (max-width: ${MEDIA_BREAK_POINTS.TABLET}px) {
      .react-datepicker__day {
        width: 20px;
        height: 20px;
      }
      .react-datepicker__day-name {
        width: 16px;
      }
    }
  }
`;

export const CalendarPicker = ({
  color = 'var(--emerald-green)',
  fadedColor = '#effaf6',
  title,
  onChange,
  selectedDate = null,
  minDate,
  disabled,
  small = false,
  ...props
}) => {
  const [selected, setSelected] = useState(selectedDate);

  const handleChange = (date) => {
    setSelected(date);
    onChange(date);
  };

  const classes = classNames({ small });

  return (
    <StyledDatePicker className={classes} $color={color} $fadedColor={fadedColor} $disabled={disabled}>
      {title && <Title>{title}</Title>}
      <DatePicker
        inline
        disabledKeyboardNavigation
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
        selected={selected}
        minDate={minDate || new Date()}
        onChange={handleChange}
        {...props}
      />
    </StyledDatePicker>
  );
};
