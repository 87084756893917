import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { FormField } from 'semantic-ui-react';

import { debounce } from 'components/utils';

const ColorPickerInput = styled.input`
  position: relative;
  top: -1px;
  width: 2.69rem;
  height: calc(2.69rem + 2px);
  padding: 0;
  -webkit-appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.25rem;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  height: 2.25rem;
  font-size: 1rem;
  font-weight: bold;

  input[type='text'][data-color] {
    border: none;
    display: inline-block;
    width: calc(100% - 3rem);
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    color: ${(p) => (p.error ? 'red' : '#444')};
  }
`;

const expandColorHex = (short) =>
  short
    .split('')
    .map((bit) => (bit === '#' ? bit : `${bit}${bit}`))
    .join('');

export const ColorPicker = (props) => {
  const BLACK = '#000000';
  const hexColorRegexShort = /^#(?:[0-9a-fA-F]{3})$/;
  const hexColorRegexLong = /^#(?:[0-9a-fA-F]{3}){2}$/;
  const validColorChars = /^[#0-9a-fA-F]*$/;

  const [, meta, helpers] = useField(props);
  const value = meta.value || BLACK;
  const { touched, error } = meta;
  const { setValue, setTouched } = helpers;
  const { label } = props;

  useEffect(() => {
    if (value.length && value[0] !== '#') {
      setValue(`#${value}`);
    }
    if (!touched && hexColorRegexShort.test(value)) {
      setValue(expandColorHex(value));
    }
  }, [value, hexColorRegexShort, setValue, touched]);

  const onPickerChange = debounce((value) => {
    setValue(value);
  }, 3);

  const onTextChange = (e) => {
    if (validColorChars.test(e.target.value)) {
      setValue(e.target.value);
      setTouched(true);
    }
  };

  const onBlur = (e) => {
    const v = e.target.value;
    if (!hexColorRegexLong.test(v)) {
      if (validColorChars.test(v) && v.length === 4) {
        setValue(expandColorHex(v));
      } else {
        setValue(BLACK);
      }
    }
  };

  return (
    <FormField>
      <label>{label}</label>
      <Wrapper error={touched && error}>
        <ColorPickerInput
          type="color"
          value={(hexColorRegexLong.test(value) && value) || '#000000'}
          onChange={(e) => {
            onPickerChange(e.target.value);
          }}
          disabled={props.disabled}
        />
        <input value={value} onChange={onTextChange} onBlur={onBlur} {...props} type="text" data-color maxLength="7" />
      </Wrapper>
    </FormField>
  );
};
