import styled from 'styled-components';

const ChatBubble = styled.div`
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  white-space: pre-wrap;
  max-width: 80%;
`;

export default ChatBubble;
