import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  width: 100%;
  .add-button {
    width: 155px;
    height: 40px;
    margin-right: 0px;
    border-radius: 4px;
    background-color: var(--vivid-blue);
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
`;

export const CampaignListButtonWrapper = ({ children, justifyContent = 'space-between' }) => {
  return <ButtonWrapper justifyContent={justifyContent}>{children}</ButtonWrapper>;
};
