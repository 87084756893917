import React from 'react';
import styled from 'styled-components';

import { DateRangePicker, MultiSelectFilter } from 'components/common';
import { useSystemStatsFilters } from './SystemStatsFiltersContext';

const Filters = styled.div`
  display: flex;
  flex-flow: wrap;

  margin-top: 1.5rem;
  margin-bottom: 2rem;
`;

const MainFilters = styled.div`
  flex: 2;
`;

const Subfilters = styled.div`
  display: flex;
  flex: 3;
  align-items: stretch;
  gap: 1rem;
  flex-flow: nowrap;

  & > div {
    flex: 1;
  }
`;

const SystemStatsFilters = () => {
  const {
    dateRange,
    setDateRange,
    salesAssistantUsers,
    selectedUsers,
    setSelectedUsers,
    salesAssistantCommunities,
    selectedCommunities,
    setSelectedCommunities,
  } = useSystemStatsFilters();

  return (
    <Filters>
      <MainFilters>
        <DateRangePicker dateRange={dateRange} onFilterChange={setDateRange} />
      </MainFilters>
      <Subfilters>
        <MultiSelectFilter
          placeholder="Filter Campaign Users"
          name="Users"
          disabled={!salesAssistantUsers}
          options={salesAssistantUsers}
          selectedValues={selectedUsers}
          onChange={setSelectedUsers}
        />
        <MultiSelectFilter
          placeholder="Filter Campaign Communities"
          name="Communities"
          disabled={!salesAssistantCommunities}
          options={salesAssistantCommunities}
          selectedValues={selectedCommunities}
          onChange={setSelectedCommunities}
        />
      </Subfilters>
    </Filters>
  );
};

export default SystemStatsFilters;
