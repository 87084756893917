import React from 'react';
import { Route } from 'react-router';

import { getCommunitiesByUserId } from 'apis';
import { UserCommunities } from '../../common/communities/UserCommunities';
import { CommunityCareTypes } from './CommunityCareTypes';
import { UserCommunitiesNoneSelected } from '../../common/communities/UserCommunitiesNoneSelected';

export const CareTypes = () => {
  return (
    <UserCommunities linkPrefix="/settings/care-types/" communitiesCallback={getCommunitiesByUserId}>
      <Route exact path="/settings/care-types/:communityId">
        <CommunityCareTypes />
      </Route>
      <Route exact path="/settings/care-types/">
        <UserCommunitiesNoneSelected />
      </Route>
    </UserCommunities>
  );
};
