import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { getCommunitiesByUserId } from 'apis';
import { UserContext } from './UserContext';
import { getDefaultRouteForUser } from './common/routes/common';
import { USER_TYPES, PAGINATION } from '../constants';

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
`;

export const StartUp = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);

  // listen and notify Segment of client-side page updates
  let prevPath = null;
  history.listen((location) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname;
      return window?.analytics?.page(location.pathname, user);
    }
  });

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await getCommunitiesByUserId({ page_size: PAGINATION.PAGE });
        const redirectRoute = getDefaultRouteForUser(user);

        if (user.type !== USER_TYPES.REGULAR) {
          history.push(redirectRoute);
          return;
        }

        if (!response || !response.results || response.results.length === 0) {
          history.push('/onboarding');
        } else {
          history.push(redirectRoute);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchProfiles();
  }, [history, user]);

  return (
    <>
      <Wrapper>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Wrapper>
    </>
  );
};
