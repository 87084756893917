import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { If } from 'components/If';
import { settingsGetCommunities, settingsUpdateCommunities } from 'apis';
import useDataList from 'utils/hooks/useDataList';
import { parseErrorResponse } from 'apis/utils';
import { STATUSES, PAGINATION, TOUR_AVAILABILTY_TIME_SLOTS, DAYS_OF_WEEK } from '../../../constants';

import {
  SettingsTable,
  SettingsContainer,
  SettingsDropdown,
  SettingsFiltersContainer,
  SettingsHeader,
  SettingsSearch,
  SettingsTableBody,
  SettingsTableToggle,
  SettingsTableRow,
  SettingsPagination,
  SettingsTableRowContainer,
  SettingsSelectedCountModal,
  CommunityRow,
  SettingsModal,
  SettingsModalHeader,
  SettingsModalActions,
} from '../common';
import { useSettings } from '../context/SettingsContext';
import { resetState, setSelectedItem } from '../context/actions';
import { Tooltip } from './Tooltip';
import TourAvailabilityModalContent from './TourAvailabilityModalContent';
import { URL_SEARCH_NAME, getUrlFilterAndSearch, parseBooleanQueryParam } from '../common/constants';
import { hasValue } from './constants';

const DaysWrapper = styled.div`
  display: flex;
`;

const DayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40px;
  align-items: center;
  margin-right: 20px;
`;

const Day = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--text);
  text-transform: capitalize;
`;

const Time = styled.div`
  font-family: var(--font-family);
  font-size: 10px;
  color: #89919d;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 44px;
`;

const VALIDATION_SCHEMA = Yup.object({
  same_day_tour: Yup.string().required('Required.'),
  next_day_tour: Yup.string().required('Required'),
  tour_use_cronofy_availability: Yup.string().required('Required'),
});

export const TourAvailability = () => {
  const { urlSearchValue } = getUrlFilterAndSearch({});
  const queryParams = new URLSearchParams(window.location.search);
  const initialParam = {};
  Array.from(queryParams).forEach((param) => {
    if (param && param[0] !== URL_SEARCH_NAME) {
      initialParam.name = param[0];
      initialParam.value = param[1];
    }
  });
  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters, totalItemsCount } = useDataList({
    initialState: {
      filters: {
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.PAGE_SIZE,
        search: urlSearchValue,
        [initialParam.name]: parseBooleanQueryParam(initialParam.value),
      },
      setUrlParams: true,
    },
  });
  const [{ isMultipleSelection, selectedItems, excludedItems }, dispatch] = useSettings();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const fetchCommunities = useCallback(
    async ({ page, pageSize, search, ...restProps }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await settingsGetCommunities({
          page,
          page_size: pageSize,
          search,
          ...restProps,
        });

        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        toast.error(parseErrorResponse(e), 'Unable to fetch data');
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchCommunities(filters);
  }, [fetchCommunities, filters]);

  const dropdownOptions = [
    { key: '1', text: 'Any status', value: null },
    { key: '2', text: 'Real-time availability on', value: 'tour_use_cronofy_availability_true' },
    { key: '3', text: 'Real-time availability off', value: 'tour_use_cronofy_availability_false' },
    { key: '4', text: 'Allows booking on same day', value: 'same_day_tour_true' },
    { key: '5', text: 'Allows booking on next day', value: 'next_day_tour_true' },
  ];

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
  };

  const handleDropdownChange = (value, currentFilters) => {
    const tempFilters = { ...currentFilters };
    const keysToRemove = ['tour_use_cronofy_availability', 'same_day_tour', 'next_day_tour'];
    for (const key of keysToRemove) {
      if (key in tempFilters) {
        tempFilters[key] = null;
      }
    }
    const key = value?.replace(/_(true|false)$/, '');
    const isTrue = value?.endsWith('true');
    tempFilters[key] = isTrue;
    setFilters(tempFilters);
    dispatch(resetState());
  };

  const getCurrentFilterValue = (currentFilters) => {
    if (Object.keys(currentFilters).length > 3) {
      if (
        currentFilters.tour_use_cronofy_availability !== null &&
        currentFilters.tour_use_cronofy_availability !== undefined
      ) {
        return currentFilters.tour_use_cronofy_availability
          ? 'tour_use_cronofy_availability_true'
          : 'tour_use_cronofy_availability_false';
      }
      if (hasValue(currentFilters.same_day_tour)) {
        return 'same_day_tour_true';
      }
      if (hasValue(currentFilters.next_day_tour)) {
        return 'next_day_tour_true';
      }
    }
    return null;
  };

  const handleOnSearch = (value) => {
    setSearch(value);
    dispatch(resetState());
  };

  const handleOnRowClick = (data) => {
    if (!isMultipleSelection) {
      setIsModalOpen(true);
      dispatch(setSelectedItem(data));
    }
  };

  const handleOnModalClose = (resetForm) => {
    setIsModalOpen(false);
    resetForm();
    dispatch(resetState());
  };

  const handleOnSelectedCountModalClick = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);
    const visit_periods = {};
    for (let i = 0; i < DAYS_OF_WEEK.length; i++) {
      if (values.days[i]) {
        visit_periods[DAYS_OF_WEEK[i]] =
          values?.has_same_daily_visit_periods === 'On'
            ? [values.sameFirst, values.sameLast]
            : [values.firstAppointments[i], values.lastAppointments[i]];
      } else {
        visit_periods[DAYS_OF_WEEK[i]] = [];
      }
    }

    let data = {
      ...Object.keys(values).reduce((acc, key) => {
        acc[key] = values[key] === 'On' ? true : values[key] === 'Off' ? false : values[key];
        return acc;
      }, {}),
    };

    // filter data that we send to API
    data = {
      include_communities: selectedItems,
      exclude_communities: excludedItems,
      tour_use_cronofy_availability: data.tour_use_cronofy_availability,
      same_day_tour: data.same_day_tour,
      next_day_tour: data.next_day_tour,
      same_day_tour_buffer_minutes: data.same_day_tour_buffer_minutes,
      visit_periods,
    };

    try {
      await settingsUpdateCommunities(data);
      toast.success('Settings updated successfully!');
    } catch (error) {
      const errorText = 'Trouble updating settings!';
      const msg = parseErrorResponse(error, errorText);
      console.error(msg, error?.response);
      toast.error(msg);
      setLoaderStatus(STATUSES.FAILURE);
    } finally {
      handleOnModalClose(resetForm);
      dispatch(resetState());
      setLoaderStatus(STATUSES.LOADED);
      setFilters({
        page: PAGINATION.PAGE,
        page_size: PAGINATION.PAGE_SIZE,
        search: null,
        tour_use_cronofy_availability: null,
        same_day_tour: null,
        next_day_tour: null,
      });
    }
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const currentItem = items?.find((item) => item.id === selectedItems[0]);
  let initialValues = {
    days: [true, true, true, true, true, false, false],
    firstAppointments: Array(7).fill(9),
    lastAppointments: Array(7).fill(16),
    has_same_daily_visit_periods: '',
    tour_use_cronofy_availability: '',
    same_day_tour: '',
    next_day_tour: '',
    sameFirst: 9,
    sameLast: 16,
    same_day_tour_buffer_minutes: 60,
  };
  if (selectedItems.length === 1 && currentItem) {
    const keysToExclude = [
      'days',
      'firstAppointments',
      'lastAppointments',
      'sameFirst',
      'sameLast',
      'same_day_tour_buffer_minutes',
    ];

    initialValues = {
      ...initialValues,
      ...currentItem,
      days: [
        !!currentItem?.visit_periods?.monday?.length,
        !!currentItem?.visit_periods?.tuesday?.length,
        !!currentItem?.visit_periods?.wednesday?.length,
        !!currentItem?.visit_periods?.thursday?.length,
        !!currentItem?.visit_periods?.friday?.length,
        !!currentItem?.visit_periods?.saturday?.length,
        !!currentItem?.visit_periods?.sunday?.length,
      ],
    };
    if (currentItem?.visit_periods) {
      // Iterate through the days of the week
      DAYS_OF_WEEK.forEach((day, index) => {
        initialValues.firstAppointments[index] = currentItem.visit_periods?.[day]?.[0] || 9;
        initialValues.lastAppointments[index] = currentItem.visit_periods?.[day]?.[1] || 16;
      });
    }

    // Check if currentItem has_same_daily_visit_periods is true
    if (currentItem.has_same_daily_visit_periods) {
      // Find the first day with available time slots and set sameFirst and sameLast accordingly
      const firstAvailableDayIndex = DAYS_OF_WEEK.findIndex((day) => !!currentItem.visit_periods[day]);
      if (firstAvailableDayIndex !== -1) {
        initialValues.sameFirst = currentItem.visit_periods[DAYS_OF_WEEK[firstAvailableDayIndex]][0] || 9;
        initialValues.sameLast = currentItem.visit_periods[DAYS_OF_WEEK[firstAvailableDayIndex]][1] || 16;
      }
    }

    for (const key in initialValues) {
      if (!keysToExclude.includes(key)) {
        initialValues[key] = initialValues[key] ? 'On' : 'Off';
      }
    }
  }

  return (
    <SettingsContainer loading={loading} totalItemsCount={totalItemsCount}>
      <If condition={!isModalOpen}>
        <SettingsSelectedCountModal
          totalItemsCount={totalItemsCount}
          items={items}
          filters={filters}
          totalPages={totalPages}
          onClick={handleOnSelectedCountModalClick}
        />
      </If>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit, dirty, touched, isValid, resetForm }) => (
          <SettingsModal
            loading={loading}
            open={isModalOpen}
            onModalClose={() => handleOnModalClose(resetForm)}
            size="large"
          >
            <SettingsModalHeader
              title="Tour availability"
              community={selectedItems.length === 1 ? items?.find((item) => item.id === selectedItems[0]) : null}
              totalItemsCount={totalItemsCount}
              type="header"
            />
            <TourAvailabilityModalContent type="body" values={values} setFieldValue={setFieldValue} />
            <SettingsModalActions
              onSave={handleSubmit}
              onCancel={() => handleOnModalClose(resetForm)}
              type="actions"
              disabled={!dirty || !touched}
              saveText="Save and Publish"
            />
          </SettingsModal>
        )}
      </Formik>
      <SettingsHeader>
        <SettingsTableToggle />
        <SettingsFiltersContainer>
          <SettingsDropdown
            options={dropdownOptions}
            value={getCurrentFilterValue(filters)}
            onChange={(e, { value }) => handleDropdownChange(value, filters)}
            placeholder="Any status"
          />
          <SettingsSearch
            onSearch={handleOnSearch}
            value={filters.search}
            placeholder={`Search ${t('communities')}`}
            loading={loading}
          />
        </SettingsFiltersContainer>
      </SettingsHeader>
      <SettingsTable tableName={`${t('community')}`} currentPage={filters.page} items={items}>
        <SettingsTableBody>
          {items?.map((item, i) => (
            <SettingsTableRow
              item={item}
              key={i}
              onClick={() => handleOnRowClick({ id: item.id, items, currentPage: filters.page })}
              items={items}
              currentPage={filters.page}
            >
              <SettingsTableRowContainer>
                <CommunityRow name={item.name} picture={item.picture_url} clickable={!isMultipleSelection} />
                <DaysWrapper>
                  {item?.tour_use_cronofy_availability && (
                    <Tooltip text="Real-time availability is on. Web assistants will display available slots based on availability in salespeople’s calendars." />
                  )}
                  {DAYS_OF_WEEK.map((day, i) => (
                    <DayWrapper key={i}>
                      <Day>{day.substring(0, 3)}</Day>
                      <Time>
                        <span>
                          {TOUR_AVAILABILTY_TIME_SLOTS.find((slot) => slot.value === item?.visit_periods[day]?.[0])
                            ?.text || '-'}
                        </span>{' '}
                        <span>
                          {TOUR_AVAILABILTY_TIME_SLOTS.find((slot) => slot.value === item?.visit_periods[day]?.[1])
                            ?.text || '-'}
                        </span>
                      </Time>
                    </DayWrapper>
                  ))}
                </DaysWrapper>
              </SettingsTableRowContainer>
            </SettingsTableRow>
          ))}
        </SettingsTableBody>
      </SettingsTable>
      <SettingsPagination filters={filters} totalPages={totalPages} onPageChange={handlePageChange} />
    </SettingsContainer>
  );
};
