import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { formatAnyDate } from 'components/utils';
import { DEFAULT_BRANDING_COLORS, SENDER_TYPE } from '../../../constants';
import ChatBubble from './ChatBubble';
import ChatRow from './ChatRow';
import ChatMessageAttachment from './ChatMessageAttachment';

import { If } from '../../If';

const ByFurther = styled.span`
  color: #${DEFAULT_BRANDING_COLORS.FURTHER_BRAND_COLOR};
`;

const Text = styled(ReactMarkdown)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 14px;
    margin: 0;
    font-family: var(--font-family);
    line-height: 1.4285em;
    font-weight: 400;
  }
`;

export const ChatMessage = ({
  className,
  text,
  attachment,
  type,
  sender,
  timestamp,
  furtherReplied,
  messageType,
  onAttachmentLoad,
  onClick,
}) => (
  <ChatRow className={className}>
    <ChatBubble onClick={onClick}>
      <If condition={attachment}>
        <ChatMessageAttachment url={attachment} onImgLoad={onAttachmentLoad} />
      </If>
      <p>
        <Text skipHtml>{text}</Text>
      </p>
    </ChatBubble>
    <h6>
      {sender && type === SENDER_TYPE.SALES_ASSISTANT ? sender : type}
      {type && timestamp && ' - '}
      {timestamp && formatAnyDate(timestamp, 'MM/dd/yyyy hh:mm a')}
      {messageType && ` - ${messageType.toUpperCase()}`}
      <If condition={furtherReplied}>
        <ByFurther> | by Further</ByFurther>
      </If>
    </h6>
  </ChatRow>
);
