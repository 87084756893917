import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { removeLeadUserReminder } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { useCheckMobileScreen } from 'utils/hooks';
import { Button, Icon, Image, Modal, Picker, PickerButton } from 'components/lib';
import { If } from 'components/If';
import img from 'assets/message-list.jpg';
import { ConversationContext } from 'components/conversation/ConversationContext';
import {
  LEAD_USER_REMINDER_TYPE,
  LEAD_USER_REMINDER_SCHEDULE_TYPE_OPTIONS,
  LEAD_USER_REMINDER_SCHEDULE_TYPE,
} from 'constants';
import { DEFAULT_LEAD_USER_REMINDER } from 'components/admin/InstantResponses/constants';
import { formatAnyDate } from 'components/utils';
import { CONVERSATION_ACTIONS, SCHEDULE_TYPES } from './constants';
import {
  ConversationModal,
  ConversationModalFooter,
  ConversationModalHeader,
  ConversationModalSubtitle,
} from './ConversationModal';

const ModalHeader = styled(ConversationModalHeader)`
  h4 {
    color: var(--text-color);
    margin-top: 3px;
  }
  span {
    margin-right: 10px;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  padding: 30px;
  border-right: solid 1px var(--line-standard);
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: stretch;
  div {
    flex: 1 0 50%;
  }
`;

const LeftActions = styled.div`
  display: flex;
  justify-content: start;
`;

const RightActions = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: end;
`;

const Right = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ScheduleReminderModal = ({ type = 'reminder' }) => {
  const { t } = useTranslation();
  const {
    leadUserReminder,
    setLeadUserReminder,
    handleSetLeadUserReminder,
    selectedConversation,
    setShowArchiveSuggestion,
    fetchConversationDetails,
    activeModal,
    showModal,
  } = useContext(ConversationContext);

  const [scheduleType, setScheduleType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await handleSetLeadUserReminder({ scheduleType });

      if (!selectedConversation.messages_archived) {
        setShowArchiveSuggestion(true);
      }

      showModal(null);
      setIsLoading(false);
      fetchConversationDetails(selectedConversation?.id);
    } catch (error) {
      setIsLoading(false);
      toast.error(parseErrorResponse(error));
    }
  };

  const handleRemove = async () => {
    try {
      setIsLoading(true);

      const { id } = leadUserReminder;
      await removeLeadUserReminder(selectedConversation?.id, LEAD_USER_REMINDER_TYPE.MESSAGES, id);

      setLeadUserReminder(DEFAULT_LEAD_USER_REMINDER);
      showModal(null);
      setIsLoading(false);
      toast.success('Reminder removed');
      fetchConversationDetails(selectedConversation?.id);
    } catch (e) {
      setIsLoading(false);
      toast.error(parseErrorResponse(e));
    }
  };

  const isMobile = useCheckMobileScreen();
  const hasActive = classNames({ active: !scheduleType }, 'full');
  const actionType = SCHEDULE_TYPES(t)[type];
  const reminderOptions = LEAD_USER_REMINDER_SCHEDULE_TYPE_OPTIONS.filter(
    (item) => item.value !== LEAD_USER_REMINDER_SCHEDULE_TYPE.NO_REMINDER
  );
  const setDate = leadUserReminder && formatAnyDate(leadUserReminder.scheduled_at, "MMM do yyyy 'at' h:mm a");

  return (
    <ConversationModal
      closeIcon
      onClose={() => showModal(null)}
      open={activeModal === CONVERSATION_ACTIONS.REMINDER}
      size="small"
    >
      <Modal.Header>
        <ModalHeader>
          <Icon name={actionType.icon} variant="outlined" size="22" color={actionType.color} />
          <h4>{actionType.modalHeader}</h4>
        </ModalHeader>
        <ConversationModalSubtitle>{actionType.subtitle}</ConversationModalSubtitle>
      </Modal.Header>
      <Modal.Content>
        <ModalContent>
          <If condition={!isMobile}>
            <Left>
              <Image src={img} />
            </Left>
          </If>
          <Right>
            <If condition={leadUserReminder?.id}>
              <PickerButton primary $color={actionType.color} $fadedColor={actionType.fadedColor} className={hasActive}>
                {setDate}
              </PickerButton>
            </If>
            <Picker options={reminderOptions} type={actionType} onChange={setScheduleType} />
          </Right>
        </ModalContent>
      </Modal.Content>
      <ConversationModalFooter>
        <If condition={leadUserReminder?.id}>
          <LeftActions>
            <Button color={actionType.class} className="link" onClick={handleRemove} disabled={isLoading}>
              Delete {!isMobile && actionType.label}
            </Button>
          </LeftActions>
        </If>
        <RightActions>
          <Button primary color={actionType.class} disabled={!scheduleType} onClick={handleSave} loading={isLoading}>
            Save
          </Button>
          <Button basic onClick={() => showModal(null)} disabled={isLoading}>
            Cancel
          </Button>
        </RightActions>
      </ConversationModalFooter>
    </ConversationModal>
  );
};
