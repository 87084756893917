import React, { useState } from 'react';
import styled from 'styled-components';

import { MyProfileImage } from './MyProfileImage';
import { MyProfileField } from './MyProfileField';
import { MyProfileButton } from './MyProfileButton';
import { MyProfileInfoModal } from './MyProfileInfoModal';

const Container = styled.div`
  display: flex;
  padding: 30px 62px 0;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
`;

export const MyProfileInfo = ({ data = {}, setProfileData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Container>
      <MyProfileImage data={data} />
      <DataWrapper>
        <MyProfileField label="Name" text={`${data.firstName} ${data.lastName}`} />
        <MyProfileField label="Email" text={data.email} />
        <MyProfileField label="Phone" text={data.phone} />
      </DataWrapper>
      <MyProfileButton iconName="edit_note" text="Edit" onClick={handleOnClick} />
      <MyProfileInfoModal open={isModalOpen} onClose={handleCloseModal} data={data} setProfileData={setProfileData} />
    </Container>
  );
};
