import React, { useContext } from 'react';
import styled from 'styled-components';
import { Dimmer, Grid, Loader } from 'semantic-ui-react';

import { STATUSES } from '../../../constants';
import { InstantAnswersContext } from './InstantAnswersContext';
import { InstantAnswersAttributesList } from './InstantAnswersAttributesList';
import { InstantAnswersAttributeValues } from './InstantAnswersAttributeValues';

const GridContainer = styled.div`
  margin-top: 16px;
`;

export const InstantAnswersAttributes = () => {
  const { loaderStatus } = useContext(InstantAnswersContext);

  return (
    <GridContainer>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <InstantAnswersAttributesList />
          </Grid.Column>
          <Grid.Column width={12}>
            <InstantAnswersAttributeValues />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </GridContainer>
  );
};
