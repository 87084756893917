import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { ReportComponent, TimeSeriesChart, getLabels } from 'components/common/analytics';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { STATUSES } from 'constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const METRIC_DATASET = {
  lead: {
    label: 'Leads Messages',
    borderColor: '#cfcf1f',
    backgroundColor: 'transparent',
  },
  'messages.sales_assistant': {
    label: 'MA Messages',
    borderColor: '#17bd2a',
    backgroundColor: 'transparent',
  },
  'messages.rereply': {
    label: 'Re-reply Messages',
    borderColor: '#2b43a1',
    backgroundColor: 'transparent',
  },
  'messages.lead.stop': {
    label: 'Stop Messages',
    borderColor: '#b33024',
    backgroundColor: 'transparent',
  },
  'messages.lead.sequential': {
    label: 'Bundled Lead Messages',
    borderColor: '#289eb5',
    backgroundColor: 'transparent',
  },
};

const tooltip = (
  <ul>
    <li>Lead Messages: Number of messages received from leads that are not STOP messages.</li>
    <li>MA Messages: Leads that were included in a campaign.</li>
    <li>Re-reply Messages: MA messages that were followed by community manager message.</li>
    <li>Stop Messages: Lead messages that were STOP message.</li>
    <li>
      Bundled Lead Messages: Lead messages that follow other lead message - usually cases when lead sends a single
      message as multiple SMS messages.
    </li>
  </ul>
);

const groupBy = 'time';
const metrics = [
  'messages.lead',
  'messages.sales_assistant',
  'messages.rereply',
  'messages.lead.stop',
  'messages.lead.sequential',
];

const MessagesTrendsGraph = () => {
  const { dateRange, saleAssistantFilters: filters } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };

  const { data, error, status, isFetching } = useQuery(
    [queryConstants.ADMIN_METRICS, { ...maFilters, metrics }],
    () => getMetrics(metrics, maFilters, groupBy),
    { placeholderData: {} }
  );

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch lead trend metrics'));
  }

  const chartData = useMemo(() => {
    const labels = getLabels(dateRange.start_date, dateRange.end_date);

    if (!data || !data[metrics[0]]) {
      return { labels, datasets: [] };
    }
    const dataLookup = metrics.reduce((lookup, m) => {
      lookup[m] = data[m].groups.reduce((r, obj) => {
        r[obj.time] = obj.value;
        return r;
      }, {});
      return lookup;
    }, {});

    const datasets = [
      {
        ...METRIC_DATASET.lead,
        data: labels.map((l) => (dataLookup['messages.lead'][l] || 0) - (dataLookup['messages.lead.stop'][l] || 0)),
      },
      ...metrics
        .filter((m) => METRIC_DATASET[m])
        .map((m) => ({
          ...METRIC_DATASET[m],
          data: labels.map((l) => dataLookup[m][l] || 0),
        })),
    ];

    return { labels, datasets };
  }, [data, dateRange]);

  return (
    <ReportComponent title="Messages over Time" tooltip={tooltip} loading={loaderStatus === STATUSES.LOADING}>
      <TimeSeriesChart data={chartData} height={300} />
    </ReportComponent>
  );
};

export default MessagesTrendsGraph;
