import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { ProfileCircle } from 'components/common/profile/ProfileCircle';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: solid 1px var(--line-standard);
  padding: 22px 20px 22px 34px;
  position: relative;
  cursor: pointer;
  width: 100%;
  border-top: none;
  padding: 0;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const Name = styled.h4`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: 1.43;
  color: var(--text-color);
  margin: 0;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CommunityRow = ({ name, picture, clickable, hideProfilePicture }) => {
  return (
    <Container clickable={clickable}>
      <If condition={!hideProfilePicture}>
        <ProfileCircle name={name} picture={picture} />
      </If>
      <Content>
        <SpaceBetween>
          <Name>{name}</Name>
        </SpaceBetween>
      </Content>
    </Container>
  );
};
