import { subDays } from 'date-fns';

export const parseArray = (csv) => {
  if (csv) {
    return csv.split(',');
  }
  return null;
};

export const parseIds = (idsCsv) => {
  try {
    if (idsCsv) {
      return idsCsv?.split(',').map((id) => parseInt(id));
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const isInt = (str) => {
  if (typeof str !== 'string') return false;
  return !isNaN(str) && !isNaN(parseInt(str));
};

export const parseQueryParam = (value, defaultValue = undefined) => {
  if (value === null) {
    return defaultValue;
  }

  return value;
};

export const parseBool = (value, defaultValue = undefined) => {
  if (value === null) {
    return defaultValue;
  }

  if (typeof value === 'string') {
    const valueLowerCase = value.toLowerCase();
    return valueLowerCase === 'true' || valueLowerCase === '1';
  }

  return value === 1;
};

export const parseNumber = (value, defaultValue = undefined) => {
  if (value === null) {
    return defaultValue;
  }

  return parseInt(value);
};

export const getDateTimeRange = (queryParams, defaultDeltaDays) => {
  const today = new Date();
  const deltaDaysAgo = subDays(today, defaultDeltaDays);
  const startDate = queryParams.get('startDate') || deltaDaysAgo;
  const endDate = queryParams.get('endDate') || today;

  return [startDate, endDate];
};

export const useUrlSearchParams = () => {
  const urlSearchParams = {};
  for (const [key, value] of new URLSearchParams(window.location.search).entries()) {
    if (value === 'undefined') {
      continue;
    } else if (value === 'true') {
      urlSearchParams[key] = true;
    } else if (value === 'false') {
      urlSearchParams[key] = false;
    } else if (isInt(value)) {
      urlSearchParams[key] = parseInt(value);
    } else {
      urlSearchParams[key] = value;
    }
  }

  const isEmptyParam = (p) => p === '' || (Array.isArray(p) && !p.length);

  const setUrlSearchParams = (searchParams, emptyAsNull) => {
    const url = new URL(window.location);
    Object.entries(searchParams).forEach((p) => {
      if (p[1] === null || p[1] === undefined || (emptyAsNull && isEmptyParam(p[1]))) {
        url.searchParams.delete(p[0]);
      } else {
        url.searchParams.set(p[0], p[1]);
      }
    });
    window.history.pushState({}, '', url);
  };

  return { urlSearchParams, setUrlSearchParams };
};
