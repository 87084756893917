import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { USER_TYPES, useUserContext } from 'components/UserContext';
import { If } from 'components/If';
import { Icon, Button, Popup, Dimmer, Loader } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import {
  Column,
  Header,
  HeaderIconWrapper,
  HeaderTitle,
  HeaderSubtitle,
  ListWrapper,
  ListRow,
  CampaignListButtonWrapper,
  StatisticsContent,
  StatisticsTypesContent,
  campaignRow,
  CampaignListIcons,
} from './common';
import { EmptyCampaigns } from './common/EmptyCampaigns';
import { useCampaigns } from './CampaignsContext';
import { CAMPAIGN_TYPE, NURTURE_CAMPAIGNS_ROUTE, CAMPAIGN_TYPE_LABEL } from '../constants';

const AddCampaignWrapper = styled.div`
  .trigger {
    width: 160px;
    height: 40px;
    margin-top: 20px;
  }
`;

const CampaignDateBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 10px;
  font-size: 14px;
  color: #89919d;
`;

const getOneTimeCampaigns = (campaigns) => {
  return campaigns?.filter((campaign) => campaign?.campaignType === CAMPAIGN_TYPE.ONE_TIME);
};

export default () => {
  const { t } = useTranslation();
  const {
    state: {
      campaigns,
      campaignsLoading,
      statisticsLoading,
      statistics,
      communitiesCount: totalCommunitiesCount,
      invalidCampaignIds,
    },
    dispatch,
    actions,
  } = useCampaigns();
  const { isUserType } = useUserContext();
  const history = useHistory();

  const campaignsList = getOneTimeCampaigns(campaigns);

  const viewCampaignDetails = (campaign) => {
    history.push({
      pathname: `${NURTURE_CAMPAIGNS_ROUTE}/${CAMPAIGN_TYPE.ONE_TIME}/details/${campaign.id}`,
    });
  };

  const openAddCampaignModal = () => {
    dispatch({
      type: actions.SET_ADD_CAMPAIGN_MODAL_OPEN,
      data: { isOpen: true, campaignType: CAMPAIGN_TYPE.ONE_TIME, label: CAMPAIGN_TYPE_LABEL.ONE_TIME },
    });
  };

  const listCampaigns = campaignsList?.map((campaign) =>
    campaignRow({
      campaign,
      totalCommunitiesCount,
      isUserType,
      statistics,
      t,
      invalidCampaignIds,
    })
  );

  return (
    <Column>
      <Dimmer active={!campaignsList || campaignsLoading} inverted>
        <Loader active size="large" transparent />
      </Dimmer>
      <Header>
        <HeaderIconWrapper bgColor="linear-gradient(to bottom, #f1fffa, #defff3)">
          <Icon name="event_available" size="24" color="#0ab776" />
        </HeaderIconWrapper>
        <div>
          <HeaderTitle>One-Time Campaigns</HeaderTitle>
          <HeaderSubtitle>Campaigns that only runs once.</HeaderSubtitle>
        </div>
      </Header>
      <If condition={!campaignsLoading && campaignsList?.length === 0}>
        <EmptyCampaigns
          title="There are no one-time campaigns."
          subtitle={
            isUserType([USER_TYPES.STAFF, USER_TYPES.REGULAR]) &&
            'Click below to add a campaign that follows up on leads.'
          }
        >
          <If condition={isUserType([USER_TYPES.STAFF, USER_TYPES.REGULAR])}>
            <AddCampaignWrapper>
              <Button onClick={openAddCampaignModal} primary="true" color="blue" type="button" className="trigger">
                Add Campaign
              </Button>
            </AddCampaignWrapper>
          </If>
        </EmptyCampaigns>
      </If>
      <If condition={campaignsList?.length > 0}>
        <ListWrapper>
          {listCampaigns?.map((campaign) => {
            const startDate = new Date(campaign?.triggerCriterion?.value || false);
            const isCompleted = startDate < new Date();

            return (
              <ListRow key={campaign?.id} onClick={() => viewCampaignDetails(campaign)}>
                <CampaignListIcons campaign={campaign} isCompleted={isCompleted} />
                <StatisticsContent campaign={campaign} statistics={statistics} statisticsLoading={statisticsLoading} />
                <CampaignDateBox>
                  {isCompleted && 'Completed'} {formatAnyDate(startDate, 'MMMM d yyyy')}
                </CampaignDateBox>
                <StatisticsTypesContent campaign={campaign} />
              </ListRow>
            );
          })}
          <If condition={isUserType([USER_TYPES.STAFF, USER_TYPES.REGULAR])}>
            <ListRow>
              <CampaignListButtonWrapper justifyContent="flex-end">
                <Button onClick={openAddCampaignModal} primary="true" color="blue" type="button" className="add-button">
                  Add campaign
                </Button>
              </CampaignListButtonWrapper>
            </ListRow>
          </If>
        </ListWrapper>
      </If>
    </Column>
  );
};
