import React from 'react';
import styled from 'styled-components';

import SearchInput from 'components/SearchInput';
import { Dropdown } from 'components/lib';
import { DateRangePicker } from 'components/common';
import { MEDIA_BREAK_POINTS } from '../../../constants';
import { ACTION_TYPE_OPTIONS, AUTHOR_ROLE_OPTIONS } from './constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  gap: 30px;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    gap: 10px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
  }
`;

const SecondRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SearchBox = styled.div`
  width: 400px;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    width: 100%;
  }
`;

const DropdownBox = styled.div`
  width: 300px;
`;

export const BusinessActivityFilters = ({ filters, onChange }) => {
  const { search, authorRole, actionType, startDate, endDate } = filters;

  const handleSearch = (value) => {
    onChange({ search: value });
  };

  const handleAuthorRoleChange = (e, { value }) => {
    onChange({ authorRole: value });
  };

  const handleDateRangeChange = ({ startDate, endDate }) => {
    onChange({ startDate, endDate });
  };

  const handleActionTypeChange = (e, { value }) => {
    onChange({ actionType: value });
  };

  return (
    <Container>
      <FirstRow>
        <div>
          <DateRangePicker
            dateRange={{ start_date: startDate, end_date: endDate }}
            onFilterChange={handleDateRangeChange}
          />
        </div>
        <SearchBox>
          <SearchInput value={search} fluid onSearch={handleSearch} />
        </SearchBox>
      </FirstRow>
      <SecondRow>
        <DropdownBox>
          <Dropdown
            basic
            fluid
            selection
            options={AUTHOR_ROLE_OPTIONS}
            value={authorRole}
            onChange={handleAuthorRoleChange}
          />
        </DropdownBox>
        <DropdownBox>
          <Dropdown
            basic
            fluid
            selection
            options={ACTION_TYPE_OPTIONS}
            value={actionType}
            onChange={handleActionTypeChange}
          />
        </DropdownBox>
      </SecondRow>
    </Container>
  );
};
