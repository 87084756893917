import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  width: 100%;
  height: 36px;
  padding: 8px;
  border-radius: 4px;
  background-color: #f1f1f1;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

const Content = styled.div`
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
`;

export default function Container({ header, children }) {
  return (
    <div>
      <Header>{header}</Header>
      <Content>{children}</Content>
    </div>
  );
}
