import { IA_TYPE_VALIDATION, IA_TYPES } from '../../../constants';

export const getInitialValues = (answers) =>
  Object.assign(
    { order: answers.map((a) => a.id) },
    ...answers.map((a) => ({
      [a.id]: a.type === IA_TYPES.TEXT ? a.values[0]?.value : a.values.map((answer) => answer?.value),
    }))
  );

export const array_move = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    return arr;
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const formatValues = (value, formValue) => {
  if (value.type === IA_TYPES.TEXT) {
    return [
      {
        id: value.values[0].id,
        index: 0,
        value: formValue[value.id],
      },
    ];
  }

  return formValue[value.id]?.map((listItem, index) => ({
    id: index,
    index,
    value: listItem,
  }));
};

export const validateForm = (values, activeValue) => {
  const errors = {};

  Object.keys(values).map((v) => {
    if (activeValue.find((a) => a.id.toString() === v)) {
      if (typeof values[v] === 'string') {
        if (values[v] === '') {
          errors[v] = IA_TYPE_VALIDATION.TEXT;
        }
      } else {
        const testArr = values[v].map((x) => (x === '' ? IA_TYPE_VALIDATION.LIST : null));
        if (values[v].length === 0) {
          errors[v] = "List can't be empty";
        }
        if (testArr.some((x) => x !== null)) {
          errors[v] = testArr;
        }
      }
      return null;
    }
    return null;
  });

  return errors;
};
