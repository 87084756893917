import React, { useMemo, useState } from 'react';
import { Table, Input } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { getMoveIns } from 'apis';
import queryConstants from 'utils/queries/constants';
import { capitalize, queryStatusToLoaderStatus } from 'utils/helpers';
import ConversationsLink from './ConversationsLink';
import { ANALYTICS_CHANNELS } from '../constants';
import { CsvExport } from '../../common/CsvExport';
import { PageSection, PercentCell } from '../../common';
import { formatCount, percent } from '../../utils';

const ALL_ANALYTICS_CHANNELS = Object.keys(ANALYTICS_CHANNELS);

const MoveIns = ({ filters, publicId, groups }) => {
  const [filter, setFilter] = useState('');
  const { t } = useTranslation();
  const { data, status, isFetching } = useQuery(
    [queryConstants.MOVE_INS, { publicId, filters }],
    () => getMoveIns({ public_id: publicId, filters }),
    { placeholderData: [] }
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  const communities = useMemo(() => {
    if (!data) return [];
    const filteredData = filter
      ? data.filter((c) => c.community_name.toLowerCase().includes(filter.toLowerCase()))
      : data;

    return filteredData.sort((a, b) => a.community_name.localeCompare(b.community_name));
  }, [data, filter]);

  const totalLeads = useMemo(() => data?.reduce((r, v) => r + v.leads, 0), [data]);

  const groupsLookup = useMemo(() => {
    if (!groups) return {};
    return groups.reduce((lookup, g) => {
      const updated = { ...lookup };
      g.community_ids.forEach((id) => {
        updated[id] = [...(updated[id] || []), g.name];
      });
      return updated;
    }, {});
  }, [groups]);

  const [csvHeaders, csvContent] = useMemo(() => {
    const headers = [
      `${capitalize(t('community'))} Name`,
      'Website Visitors',
      'VSA Users',
      'Interaction Rate (%)',
      capitalize(t('leads')),
      `% of ${capitalize(t('leads'))} (%)`,
      `Avg. ${capitalize(t('move_ins'))} Likelihood`,
      'Visitor Conversion Rate (%)',
      'VSA User Conversion Rate (%)',
      'Financially Unqualified (%)',
      `Requested ${capitalize(t('tours'))}`,
      capitalize(t('move_ins')),
      `${capitalize(t('community'))} Groups`,
    ];

    const content = communities.map((c) => [
      c.community_name,
      c.visitors,
      c.interactions,
      percent(c.interactions, c.visitors, { zeroBased: true }).toFixed(4),
      c.leads,
      percent(c.leads, totalLeads, { zeroBased: true }).toFixed(4),
      c.avg_lead_score?.toFixed(1) || '-',
      percent(c.leads, c.visitors, { zeroBased: true }).toFixed(4),
      percent(c.leads, c.interactions, { zeroBased: true }).toFixed(4),
      percent(c.unqualified, c.leads, { zeroBased: true }).toFixed(4),
      c.tours,
      c.moveins,
      groupsLookup[c.community_id]?.join(',') || '',
    ]);

    return [headers, content];
  }, [communities, totalLeads, groupsLookup]);

  const channelSources = filters.channels && filters.channels.length ? filters.channels : ALL_ANALYTICS_CHANNELS;

  return (
    <PageSection title={`${capitalize(t('communities'))} Breakdown`} loaderStatus={loaderStatus}>
      <Table className="fixed-size">
        <Table.Header>
          <Table.Row textAlign="right">
            <Table.HeaderCell textAlign="left">
              <Input
                size="small"
                placeholder="Find Community"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                style={{ width: '15rem' }}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>Website Visitors</Table.HeaderCell>
            <Table.HeaderCell>VSA Users</Table.HeaderCell>
            <Table.HeaderCell>Interaction Rate</Table.HeaderCell>
            <Table.HeaderCell>{capitalize(t('leads'))}</Table.HeaderCell>
            <Table.HeaderCell>% of {capitalize(t('leads'))}</Table.HeaderCell>
            <Table.HeaderCell>Avg. {capitalize(t('move_ins'))} Likelihood</Table.HeaderCell>
            <Table.HeaderCell>Visitor Conversion Rate</Table.HeaderCell>
            <Table.HeaderCell>VSA User Conversion Rate</Table.HeaderCell>
            <Table.HeaderCell>Financially Unqualified</Table.HeaderCell>
            <Table.HeaderCell>Requested {capitalize(t('tours'))}</Table.HeaderCell>
            <Table.HeaderCell>{capitalize(t('move_ins'))}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {communities?.map((c, i) => (
            <Table.Row textAlign="right" key={i}>
              <Table.Cell textAlign="left">{c.community_name}</Table.Cell>
              <Table.Cell>{formatCount(c.visitors)}</Table.Cell>
              <Table.Cell>{formatCount(c.interactions)}</Table.Cell>
              <Table.Cell>
                <PercentCell value={percent(c.interactions, c.visitors)} />
              </Table.Cell>
              <Table.Cell>
                <ConversationsLink
                  value={formatCount(c.leads)}
                  startDate={filters.start_date}
                  endDate={filters.end_date}
                  communities={[c.community_id]}
                  channelSources={channelSources}
                  trafficSources={filters.traffic_sources}
                />
              </Table.Cell>
              <Table.Cell>
                <PercentCell value={percent(c.leads, totalLeads)} />
              </Table.Cell>
              <Table.Cell>{c.avg_lead_score?.toFixed(1) || '-'}</Table.Cell>
              <Table.Cell>
                <PercentCell value={percent(c.leads, c.visitors)} />
              </Table.Cell>
              <Table.Cell>
                <PercentCell value={percent(c.leads, c.interactions)} />
              </Table.Cell>
              <Table.Cell>
                <ConversationsLink
                  value={<PercentCell value={percent(c.unqualified, c.leads)} />}
                  startDate={filters.start_date}
                  endDate={filters.end_date}
                  communities={[c.community_id]}
                  channelSources={channelSources}
                  trafficSources={filters.traffic_sources}
                  financiallyUnqualified
                />
              </Table.Cell>
              <Table.Cell>
                <ConversationsLink
                  value={formatCount(c.tours)}
                  startDate={filters.start_date}
                  endDate={filters.end_date}
                  communities={[c.community_id]}
                  channelSources={channelSources}
                  trafficSources={filters.traffic_sources}
                  tourScheduled
                />
              </Table.Cell>
              <Table.Cell>
                <ConversationsLink
                  value={formatCount(c.moveins)}
                  startDate={filters.start_date}
                  endDate={filters.end_date}
                  communities={[c.community_id]}
                  channelSources={channelSources}
                  trafficSources={filters.traffic_sources}
                  moveInDate
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={5} textAlign="right">
              <CsvExport data={csvContent} headers={csvHeaders} filename="moveins_by_community.csv" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </PageSection>
  );
};

export default MoveIns;
