import React, { useMemo } from 'react';
import { useField } from 'formik';
import { Form, Dropdown } from 'semantic-ui-react';

export const RelatedCommunitiesField = ({ communities, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { touched, error } = meta;

  const options = useMemo(
    () =>
      communities
        ? communities.map((community) => ({
            key: community.id,
            text: community.name,
            value: community.id,
          }))
        : [],
    [communities]
  );

  const errors = useMemo(() => (typeof error === 'string' ? error : null), [error]);

  const communityIds = field.value?.map((v) => v?.id);

  const onChange = (event, extraData) => {
    const newCommunityIds = extraData.value;
    const newValue = communities.filter((p) => newCommunityIds.includes(p.id));
    helpers.setValue(newValue);
  };

  return (
    <Form.Field
      control={Dropdown}
      placeholder="Communities"
      fluid
      multiple
      search
      selection
      options={options}
      clearable={communities?.length > 0 || false}
      {...props}
      error={touched && errors}
      value={communityIds}
      onChange={onChange}
    />
  );
};
