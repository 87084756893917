import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Segment } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Loader, Dimmer, Form } from 'components/lib';
import { parseErrorResponse } from 'apis/utils';
import { getJobApplicantEmails, createJobApplicantEmails } from 'apis';
import { getArrayDifference } from 'utils/helpers';
import { PAGINATION } from '../../../constants';
import JobApplicantEmailTable from './JobApplicantEmailTable';
import JobApplicantEmailHeader from './JobApplicantEmailHeader';
import JobApplicantEmailFilter from './JobApplicantEmailFilter';

const Container = styled.div`
  position: relative;
  min-height: 60vh;

  .sticky {
    background-color: var(--white);
  }
`;

const INIT_FILTERS = {
  search: '',
  showOnlyWithoutEmails: false,
};

const VALIDATION_SCHEMA = Yup.object({
  communities: Yup.array().of(
    Yup.object({
      emails: Yup.array().of(Yup.string().email('Must be a valid email address')).required(false),
    })
  ),
});

const JobApplicantEmails = () => {
  const [loading, setLoading] = useState(false);
  const [communities, setCommunities] = useState();
  const [filters, setFilters] = useState(INIT_FILTERS);
  const containerRef = useRef();

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        setLoading(true);
        const { results } = await getJobApplicantEmails({ pageSize: PAGINATION.MAX_LARGE_PAGE_SIZE });

        setCommunities(results);
      } catch (e) {
        toast.error(parseErrorResponse(e, 'Unable to fetch communities. Please try again'));
      } finally {
        setLoading(false);
      }
    };

    fetchCommunities();
  }, []);

  const handelSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const data = getArrayDifference(communities, values.communities);

      await createJobApplicantEmails(data);

      setFilters(INIT_FILTERS);
      setCommunities((draft) =>
        draft.map((c1) => {
          const c2 = values.communities.find((c) => c.id === c1.id);
          if (c2) {
            return { ...c1, ...c2 };
          }

          return c1;
        })
      );
      resetForm();
      toast.success('Job applicant email addresses updated successfully');
    } catch (e) {
      console.error(e);
      toast.error('Error updating job applicant email address');
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = (values) => {
    setFilters(values);
  };

  const filteredCommunities = useMemo(() => {
    if (!communities) {
      return null;
    }

    const { search, showOnlyWithoutEmails } = filters;

    return communities.filter((community) => {
      const appliedFilters = [];
      if (search) {
        appliedFilters.push(community.name.toLowerCase().includes(search.toLowerCase()));
      }
      if (showOnlyWithoutEmails) {
        appliedFilters.push(community.emails.length === 0);
      }

      return appliedFilters.every((f) => f === true);
    });
  }, [communities, filters]);

  return (
    <Container ref={containerRef}>
      <Formik
        initialValues={{ communities: filteredCommunities }}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handelSubmit}
        enableReinitialize
      >
        {({ handleSubmit, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <Dimmer active={loading} inverted>
              <Loader active={loading} />
            </Dimmer>
            <JobApplicantEmailHeader context={containerRef} loading={loading} disabled={!dirty} />
            <Segment basic>
              <JobApplicantEmailFilter filters={filters} onFilter={handleFilter} disabled={dirty} />
              <JobApplicantEmailTable communities={filteredCommunities} />
            </Segment>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default JobApplicantEmails;
