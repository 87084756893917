import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/lib';
import { capitalize } from 'utils/helpers';

const DetailsRowValue = styled.div`
  text-align: right;
  color: var(--text-color);
  font-family: var(--font-family-bold);
  display: grid;
  word-break: break-word;

  &.left {
    text-align: left;
  }
`;

const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 30px;
  border-bottom: solid 1px #eaeaeb;
  font-family: var(--font-family);
  gap: 20px;

  &:nth-child(odd) {
    background-color: #fcfcfc;
  }
`;

const LeadNotSubmitted = styled.div`
  padding: 8px 30px;
  color: var(--text-color);
`;

export const CRMResult = ({ data }) => {
  const { t } = useTranslation();

  if (!data) {
    return <LeadNotSubmitted>{capitalize(t('lead'))} is not submitted to the CRM</LeadNotSubmitted>;
  }

  const { lead_data, is_valid, is_parsable, raw_result } = data;

  return (
    <>
      <DetailsRow>
        <span>{capitalize(t('lead'))} Data</span>
        <DetailsRowValue>{lead_data}</DetailsRowValue>
      </DetailsRow>
      <DetailsRow>
        <span>Result Valid</span>
        <DetailsRowValue>{is_valid ? <Icon name="check" color="green" /> : '-'}</DetailsRowValue>
      </DetailsRow>
      <DetailsRow>
        <span>Result Parsable</span>
        <DetailsRowValue>{is_parsable ? <Icon name="check" color="green" /> : '-'}</DetailsRowValue>
      </DetailsRow>
      <DetailsRow>
        <span>Result</span>
        <DetailsRowValue className="left">{raw_result}</DetailsRowValue>
      </DetailsRow>
    </>
  );
};
