import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Chart from 'chart.js';

import { CRMChartTheme } from './constants';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 600px;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: ${CRMChartTheme.colors.white};
`;

const ParentContainer = styled.div`
  display: flex;
  background-color: ${CRMChartTheme.colors.white};
  position: relative;
  max-width: 600px;
  height: 235px;
  border-radius: 1px;
  overflow: hidden;
`;

const ChartContainer = styled.div`
  flex: 1;
  position: relative;
`;

const About = styled.div`
  padding: 20px 30px 20px 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    height: 25px;
    margin: 0 46px 0 0;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
  }

  .subtitle {
    display: flex;
    align-items: center;
  }

  .Rectangle {
    width: 56px;
    height: 20px;
    margin: 3px 2px 9px 144px;
    padding: 2px 0;
    border-radius: 9px;
    background-color: rgba(10, 183, 68, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Text {
    width: 34px;
    height: 12px;
    font-family: Avenir;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--emerald-green);
    margin-bottom: 5px;
  }
  .subsubtitle {
    width: 130px;
    height: 16px;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${CRMChartTheme.colors.grey};
  }
`;

const TooltipCanvas = styled.canvas`
  position: absolute;
  top: 60px;
  left: 75px;
`;

const ChartIcon = styled.button`
  position: absolute;
  top: 130px;
  left: 50%;
  width: 30px;
  margin-left: -15px;
  border: 0;
  background: transparent;
  outline: none;
  opacity: 0.6;

  svg {
    fill: ${CRMChartTheme.colors.grey};
  }
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 50px;
  width: 200px;

  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .legend-color {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      border-radius: 100px;
    }
    .legend-text .value {
      margin-left: 10px;
    }
    .legend-text {
      font-size: 14px;
      color: ${CRMChartTheme.colors.textColor};
      display: flex;
      align-items: center;
      font-family: Avenir;
      font-weight: 500;
      line-height: normal;

      .percentage {
        color: ${CRMChartTheme.colors.grayishBlue};
        margin-left: 4px;
      }
    }
  }
`;

const ChartComponent = ({ data, labels, title = 'Chart.js', subtitle = 'Subtitle', subsubtitle }) => {
  const canvasRef = useRef(null);
  const tooltipCanvasRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const tooltipCanvas = tooltipCanvasRef.current;
    const ctx = canvas.getContext('2d');
    const tooltipCtx = tooltipCanvas.getContext('2d');

    const gradients = {
      blue: createGradient(ctx, CRMChartTheme.colors.lightBlue, CRMChartTheme.colors.blue),
      pink: createGradient(ctx, CRMChartTheme.colors.lightPink, CRMChartTheme.colors.pink),
      grey: createGradient(ctx, CRMChartTheme.colors.darkGrey, CRMChartTheme.colors.grey),
    };

    window.arcSpacing = 0.15;
    window.segmentHovered = false;

    const textInCenter = (value, label) => {
      tooltipCtx.clearRect(0, 0, tooltipCanvas.width, tooltipCanvas.height);

      drawText(
        tooltipCtx,
        value,
        '34px sans-serif',
        CRMChartTheme.colors.textColor,
        tooltipCanvas.width,
        tooltipCanvas.height / 2
      );
      drawText(
        tooltipCtx,
        label,
        '9px sans-serif',
        CRMChartTheme.colors.grey,
        tooltipCanvas.width,
        tooltipCanvas.height / 2 - 35
      );
    };

    const customTooltips = (tooltip) => {
      if (tooltip.body) {
        const line = tooltip.body[0].lines[0];
        const parts = line.split(': ');
        textInCenter(parts[1], parts[0].split('').join(' ').toUpperCase());
        window.segmentHovered = true;
      } else {
        window.segmentHovered = false;
      }
    };

    const config = {
      type: 'doughnut',
      data: {
        labels: labels || ['First', 'Second', 'Third'],
        datasets: [
          {
            data: data || [400, 540, 290],
            backgroundColor: [gradients.pink, gradients.blue, gradients.grey],
          },
        ],
      },
      options: {
        cutoutPercentage: 80,
        elements: {
          arc: {
            borderWidth: 2,
          },
        },
        legend: {
          display: false,
        },
        animation: {
          onComplete: () => {
            if (!window.segmentHovered) {
              const total = chartRef.current.data.datasets[0].data.reduce((a, b) => a + b, 0);
              const label = 'T O T A L';
              textInCenter(total, label);
            }
          },
        },
        tooltips: {
          enabled: false,
          custom: customTooltips,
        },
      },
    };

    chartRef.current = new Chart(ctx, config);

    return () => {
      chartRef.current.destroy();
    };
  }, [data, labels]);

  const colors = [CRMChartTheme.colors.pink, CRMChartTheme.colors.blue, CRMChartTheme.colors.grey];
  const legendLabels = labels || ['First', 'Second', 'Third'];
  const total = data ? data.reduce((a, b) => a + b, 0) : 0;

  return (
    <MainContainer>
      <About>
        <div>
          <h3>{title}</h3>
          {subsubtitle && <div className="subsubtitle">{subsubtitle}</div>}
        </div>
        <div className="subtitle">
          <div className="Rectangle">
            <div className="Text">{subtitle}</div>
          </div>
        </div>
      </About>
      <ParentContainer>
        <ChartContainer>
          <canvas ref={canvasRef} height="200"></canvas>
          <TooltipCanvas ref={tooltipCanvasRef} width="150" height="100"></TooltipCanvas>
          <ChartIcon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.4 61.2">
              <path d="M57.6 0h8.7c2.8 0 5 2.2 5 5v51.1c0 2.8-2.2 5-5 5h-8.7c-2.8 0-5-2.2-5-5V5c0-2.7 2.2-5 5-5zM5 24.9h8.7c2.8 0 5 2.2 5 5v26.2c0 2.8-2.2 5-5 5H5c-2.8 0-5-2.2-5-5V29.9c0-2.8 2.2-5 5-5zM31.4 17.8h8.7c2.8 0 5 2.2 5 5v33.4c0 2.8-2.2 5-5 5h-8.7c-2.8 0-5-2.2-5-5V22.8c0-2.8 2.3-5 5-5z" />
            </svg>
          </ChartIcon>
        </ChartContainer>
        <LegendContainer>
          {legendLabels.map((label, index) => {
            const value = data ? data[index] : 0;
            const percentage = total ? ((value / total) * 100).toFixed() : 0;
            return (
              <div className="legend-item" key={index}>
                <div className="legend-color" style={{ backgroundColor: colors[index] }}></div>
                <div className="legend-text">
                  {label}
                  <span className="value">{value}</span>
                  <span className="percentage">{percentage}%</span>
                </div>
              </div>
            );
          })}
        </LegendContainer>
      </ParentContainer>
    </MainContainer>
  );
};

const createGradient = (ctx, color1, color2) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, 150);
  gradient.addColorStop(0, color1);
  gradient.addColorStop(1, color2);
  return gradient;
};

const drawText = (ctx, text, font, color, canvasWidth, y) => {
  ctx.fillStyle = color;
  ctx.font = font;
  const x = Math.round((canvasWidth - ctx.measureText(text).width) / 2);
  ctx.fillText(text, x, y);
};

export default ChartComponent;
