import React from 'react';
import styled from 'styled-components';

import { PhoneNumbersDropdown } from './PhoneNumbersDropdown';
import { PhoneNumbersList } from './PhoneNumbersList';

const PhoneListWrapper = styled.div`
  font-size: 12px;
  > div {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const RegularPhoneNumbersDropdown = ({ phoneNumbers, allowedSize, enabled, onEdit }) => {
  const eligiblePhoneNumbers = phoneNumbers ? phoneNumbers.filter((number) => !!number) : [];
  const hasPhoneNumbers = eligiblePhoneNumbers.length > 0;

  return (
    <PhoneNumbersDropdown text={`${eligiblePhoneNumbers.length}/${allowedSize}`} disabled={!enabled} onEdit={onEdit}>
      {hasPhoneNumbers && (
        <PhoneListWrapper>
          <PhoneNumbersList phoneNumbers={eligiblePhoneNumbers} />
        </PhoneListWrapper>
      )}
    </PhoneNumbersDropdown>
  );
};
