import React from 'react';
import styled from 'styled-components';

import { PhoneNumbersDropdown } from './PhoneNumbersDropdown';
import { formatPhoneNumber, groupCampaignNumbersByReferral } from '../utils';

const CampaignPhoneNumberInfo = styled.div`
  && {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    color: var(--text);
    font-size: 12px;
    word-wrap: break-word;
  }
`;

const CampaignDetails = styled.div`
  && {
    color: var(--link);
    font-size: 12px;
  }
`;

const ReferralOriginDetails = styled.div`
  && {
    white-space: nowrap;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CampaignPhoneNumbersDropdown = ({ phoneNumbers, enabled, onEdit }) => {
  const hasPhoneNumbers = phoneNumbers?.length > 0;
  const campaignPhoneNumbers = hasPhoneNumbers ? groupCampaignNumbersByReferral(phoneNumbers) : [];
  const buttonText = hasPhoneNumbers ? `${phoneNumbers.length}` : '-';

  const resolveCampaignInfoText = (phoneNumbers) => {
    if (phoneNumbers.length > 1) return `${phoneNumbers.length} numbers`;

    return formatPhoneNumber(phoneNumbers[0]);
  };

  return (
    <PhoneNumbersDropdown text={buttonText} disabled={!enabled} onEdit={onEdit}>
      {hasPhoneNumbers &&
        campaignPhoneNumbers.map((phoneNumber, index) => (
          <CampaignPhoneNumberInfo key={index}>
            <ReferralOriginDetails>{phoneNumber.referralOrigin}</ReferralOriginDetails>
            <CampaignDetails>{resolveCampaignInfoText(phoneNumber.phoneNumbers)}</CampaignDetails>
          </CampaignPhoneNumberInfo>
        ))}
    </PhoneNumbersDropdown>
  );
};
