import { API } from '../api';

export const getEligibleTrafficSources = async () => {
  const url = 'api/chat/browserdata/eligible-traffic-sources';
  const response = await API.get(url);
  return response.data;
};

export const getEligibleLeadSources = async () => {
  const url = 'api/chat/browserdata/eligible-lead-sources';
  const response = await API.get(url);
  return response.data;
};

export const getEligibleUrlParameters = async () => {
  const url = 'api/chat/browserdata/eligible-url-parameters';
  const response = await API.get(url);
  return response.data;
};
