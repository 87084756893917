import React from 'react';
import styled, { css } from 'styled-components';

import { Tab as SemanticTab } from 'semantic-ui-react';

const StyledTab = styled(SemanticTab)`
  .ui.menu,
  .ui.segment {
    font-family: var(--font-family);
    padding: 0 30px;
  }

  .ui.segment {
    box-shadow: none;
    padding: 0;
    margin: 0;

    ${(props) =>
      props.showBorder
        ? ''
        : css`
            border: none;
          `}
  }

  .ui.secondary.pointing.menu {
    border-color: #c6ccd6;
    flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'intial')};
    &&&& .item {
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--light-text);
      padding: 0.85714286em 0;
      &:not(:first-child) {
        margin-left: 30px;
      }
      &.active {
        color: var(--text-color);
        border-color: var(--text-color);
      }
    }
  }
`;

const StyledPane = styled(SemanticTab.Pane).attrs(({ padding }) => ({
  style: {
    padding: padding || '0',
  },
}))`
  display: flex;
`;

export const FloatingTab = styled(StyledTab)`
  .ui.tabular.menu {
    padding-left: 0;
    .item {
      border: none;
      margin-right: 27px;
      padding-right: 0;
      padding-left: 0;
      color: #89919d;

      &.active {
        border-bottom: 1px solid #222;
        color: var(--text-color);
      }
    }
  }
`;

export const Tab = ({ ...props }) => <StyledTab {...props} />;

Tab.Pane = ({ ...props }) => <StyledPane {...props} />;
