import React from 'react';
import styled from 'styled-components';

const TableBody = styled.div`
  border-radius: 10px;
  border: solid 1px #d7d7d7;
  background-color: #ffffff;
  margin-top: ${(props) => props?.marginTop};
`;

export const SettingsTableBody = ({ children, marginTop = '15px' }) => {
  return <TableBody marginTop={marginTop}>{children}</TableBody>;
};
