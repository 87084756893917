import { differenceInDays, subDays } from 'date-fns';

import { PAGINATION } from '../../constants';
import { formatAnyDate } from '../utils';

const today = new Date();
const monthAgo = subDays(today, 30);

export const ORDERING = {
  DESC_CREATED_ON: '-created_on',
  ASC_CREATED_ON: 'created_on',
  DESC_UPDATED_ON: '-updated_on',
  ASC_UPDATED_ON: 'updated_on',
};

export const DEFAULT_FILTER = {
  financiallyUnqualified: false,
  moveInDate: undefined,
  tourScheduled: undefined,
  inboundCall: false,
  outboundCall: false,
  leadStatus: undefined,
  trafficSources: [],
  channelSources: [],
  communities: [],
  groups: [],
  page: PAGINATION.PAGE,
  pageSize: PAGINATION.PAGE_SIZE,
  diff: differenceInDays(today, monthAgo),
  search: '',
  startDate: formatAnyDate(monthAgo, 'yyyy-MM-dd'),
  endDate: formatAnyDate(today, 'yyyy-MM-dd'),
  ordering: ORDERING.DESC_CREATED_ON,
  isSurveySubmitted: false,
  isNoteAdded: false,
};
