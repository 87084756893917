import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Navigation } from 'components/navigation';
import Routes from './Routes';
import { UserContext } from './UserContext';
import { STATUSES } from '../constants';
import { If } from './If';
import { DemoRoutesOnly } from './DemoRoutesOnly';
import ContactCenter from './contact-center/ContactCenter';

export const Dashboard = () => {
  const url = new URL(window.location.href);
  const hideNavigation = url.searchParams.get('hideNavigation') === 'true';
  const { status: userLoaderStatus } = useContext(UserContext);
  if ([STATUSES.IDLE, STATUSES.LOADING].includes(userLoaderStatus)) return null;

  return (
    <Router
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <If condition={!hideNavigation}>
        <Navigation />
      </If>
      <DemoRoutesOnly>
        <Routes />
      </DemoRoutesOnly>
      <ContactCenter />
    </Router>
  );
};
