import React from 'react';
import { useField } from 'formik';
import { Form } from 'semantic-ui-react';

export const ThreeStateToggle = ({ onChange, disabled, inline = true, label, name, ...props }) => {
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const onValueChange = (v) => {
    setValue(v);
    if (typeof onChange === 'function') {
      onChange(v);
    }
  };

  return (
    <Form.Field>
      {label && <label>{label}</label>}
      <Form.Group>
        <Form.Checkbox
          value={true}
          checked={value === true}
          inline={inline}
          onChange={() => onValueChange(true)}
          label="Yes"
          {...props}
        />
        <Form.Checkbox
          value={false}
          checked={value === false}
          inline={inline}
          onChange={() => onValueChange(false)}
          label="No"
          {...props}
        />
        <Form.Checkbox
          value={undefined}
          checked={value === undefined || value === null}
          inline={inline}
          onChange={() => onValueChange(null)}
          label="Don't Know"
          {...props}
        />
      </Form.Group>
    </Form.Field>
  );
};
