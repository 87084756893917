import React from 'react';
import styled from 'styled-components';

import { Button, Icon, Popup } from 'components/lib';
import { PageHeader, PAGE_HEADER_MARGIN } from 'components/common';
import { If } from 'components/If';
import { useModuleRecordings } from './ModuleRecordingsContext';

const Title = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledIcon = styled(Icon)`
  position: relative;
  top: -2px;
`;

export const ModuleRecordingsPageHeader = ({ onEnableSettings, onDisableSettings }) => {
  const { state } = useModuleRecordings();

  const { selectedCommunity, eligibleForActivation } = state;
  const isFeatureEnabled = selectedCommunity?.chatModuleRecordingSettings?.isEnabled;

  const getTitleMessage = () => {
    return (
      <Title>
        <span>Custom Module Recordings</span>
        <If condition={!isFeatureEnabled && selectedCommunity?.hasModuleRecordings}>
          <Icon size="small" name="pending_actions" color="grey" />
        </If>
        <If condition={isFeatureEnabled}>
          <Icon size="small" name="play_arrow" color="green" />
        </If>
      </Title>
    );
  };

  const getHeaderMessage = () => {
    if (!selectedCommunity?.chatModuleRecordingSettings?.isEnabled) {
      return (
        <span>
          Enable module recordings for <strong>{selectedCommunity?.name}</strong> to have customized voice experience
          during phone calls.
        </span>
      );
    }

    if (selectedCommunity?.chatModuleRecordingSettings?.isEnabled) {
      return (
        <span>
          <strong>{selectedCommunity?.name}</strong> has active module recordings. For any updates, first proceed with
          deactivation.
        </span>
      );
    }
  };

  return (
    <PageHeader
      title={getTitleMessage()}
      subtitle={getHeaderMessage()}
      block
      margin={PAGE_HEADER_MARGIN.MEDIUM}
      actions={
        <>
          <If condition={!isFeatureEnabled}>
            <Popup
              hoverable
              disabled={eligibleForActivation}
              content="Some required recordings are missing"
              trigger={
                <Button disabled={!eligibleForActivation} onClick={onEnableSettings}>
                  <StyledIcon name="play_arrow" color="var(--emerald-green)" size={16} /> Activate
                </Button>
              }
            />
          </If>
          <If condition={isFeatureEnabled}>
            <Button onClick={onDisableSettings}>
              <StyledIcon name="pause" color="var(--text)" size={16} /> Deactivate
            </Button>
          </If>
        </>
      }
    />
  );
};
