import React from 'react';
import styled from 'styled-components';

import { Button } from 'components/lib';

const CustomButton = styled(Button)`
  &&& {
    height: 48px;
    padding: 0px;
    box-shadow: none;
  }
`;

const TextLabel = styled.div`
  width: 100%;
  line-height: 48px;
  display: inline-block;
  font-weight: normal;
  color: white;
`;

export const AuthButton = ({ label, loading, disabled, onClick }) => (
  <CustomButton type="submit" primary color="orange" fluid loading={loading} disabled={disabled} onClick={onClick}>
    <TextLabel>{label}</TextLabel>
  </CustomButton>
);
