import React, { useContext } from 'react';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { ChatsContext } from '../ChatsContext';
import { InputField } from '../../common';

const ChatsInput = styled(InputField)`
  width: 100%;
`;

const SelectAllButton = styled(Button)`
  background-color: white !important;
  padding-left: 20px;
  padding-right: 0px;
  min-width: fit-content;
  padding-top: 20px;
  height: fit-content;
  padding-top: 33px !important;
`;

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

export const AnalyticsChatForm = ({ setFieldValue }) => {
  const { chats } = useContext(ChatsContext);

  const chatOptions = React.useMemo(
    () =>
      chats?.map((chat) => ({
        key: chat.id,
        text: chat.name,
        value: chat.id,
      })) || [],
    [chats]
  );

  const selectAll = (setFieldValue) => () => {
    setFieldValue(
      'chats',
      chats.map((v) => v.id)
    );
  };

  return (
    <Wrapper>
      <Form.Group>
        <ChatsInput
          label="User Chats"
          control={Dropdown}
          name="chats"
          placeholder="Select Chats..."
          fluid
          multiple
          search
          selection
          options={chatOptions}
          clearable
        />
        <SelectAllButton type="button" icon="check" content="Select All Chats" onClick={selectAll(setFieldValue)} />
      </Form.Group>
    </Wrapper>
  );
};
