import React from 'react';
import styled from 'styled-components';
import { Label, Icon } from 'semantic-ui-react';

import { If } from 'components/If';
import { Popup } from 'components/lib';
import { Module } from './Module';
import { Message } from './Message';
import { jumpToModule } from '../../utils';

const LinkBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LinkItem = styled.div`
  color: #1f57bd;
  font-size: 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 10px;
`;

export const DialCommunity = ({ node }) => {
  const { module } = node;
  const { text } = module;

  const nextModule = () => {
    jumpToModule(module.action);
  };

  return (
    <Module node={node}>
      <HeaderWrapper style={{ marginBottom: '10px' }}>
        <Label color="grey">Background Operation</Label>
        <Label color="green">
          <Icon name="phone" /> Dial Community
        </Label>
      </HeaderWrapper>
      {text && <Message>{text}</Message>}
      <If condition={!!module.action}>
        <LinkBox>
          <LinkItem onClick={nextModule}>Go to next module</LinkItem>
        </LinkBox>
      </If>
    </Module>
  );
};
