import React, { createContext, useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getConversations, setConversationRead } from 'apis';

import { useNavigationContext } from 'components/navigation';
import { STATUSES, PAGINATION } from '../../constants';
import { CONVERSATION_TYPES } from './constants';

export const MessagesContext = createContext();

export const MessagesContextProvider = ({ conversationType, ...props }) => {
  const history = useHistory();
  const { loadMessagesSummary } = useNavigationContext();

  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [search, setSearch] = useState('');
  const [conversationPages, setConversationPages] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [moreLoader, setMoreLoader] = useState(STATUSES.IDLE);
  const [totalCount, setTotalCount] = useState(-1);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [loadedInitial, setLoadedInitial] = useState(false);
  const [visiblePages, setVisiblePages] = useState([]);
  const [showArchiveSuggestion, setShowArchiveSuggestion] = useState(false);

  const conversationPagesSorted = useMemo(() => {
    return Object.keys(conversationPages)
      .sort()
      .map((pageNumber) => ({ items: conversationPages[pageNumber], pageNumber }));
  }, [conversationPages]);

  useEffect(() => {
    const fetch = async () => {
      setLoaderStatus(STATUSES.LOADING);
      setConversationPages({});
      setVisiblePages(['1']);
      await refreshConversationPages(['1']);
      setLoaderStatus(STATUSES.LOADED);
    };

    fetch();
  }, [search]);

  const refreshConversation = async () => {
    // Reset search if set to execute side effect of fetching data
    // If search not set then reload manually
    if (search) {
      setSearch('');
    } else {
      setLoaderStatus(STATUSES.LOADING);
      setConversationPages({});
      setVisiblePages(['1']);
      setLoadedInitial(false);
      await refreshConversationPages(['1']);
      setLoaderStatus(STATUSES.LOADED);
    }

    await loadMessagesSummary();
  };

  const refreshConversationPages = async (pageNumbers) => {
    setHasMorePages(false);

    const promises = pageNumbers.map((pageNumber) =>
      getConversations({
        search,
        page: pageNumber,
        is_archived: conversationType === CONVERSATION_TYPES.ARCHIVED,
        page_size: PAGINATION.PAGE_SIZE,
      })
        .then((response) => ({ pageNumber, response }))
        .catch((error) => {
          error.pageNumber = pageNumber;
          throw error;
        })
    );

    return Promise.all(promises)
      .then((responses) => {
        responses.forEach(({ pageNumber, response }) => {
          setConversationPages((conversationPages) => ({ ...conversationPages, [pageNumber]: response.results }));
          setTotalCount(response.count);
          setHasMorePages(!!response.next);
          setLoaderStatus(STATUSES.LOADED);
          setLoadedInitial(true);
        });
      })
      .catch((e) => {
        if (e?.response?.data?.detail === 'Invalid page.') {
          delete conversationPages[e.pageNumber];
        }
      });
  };

  const refreshVisiblePages = useCallback(async () => {
    return refreshConversationPages(visiblePages);
  }, [visiblePages]);

  const refreshLoadedPages = useCallback(async () => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      const loadedPages = Object.keys(conversationPages).sort();
      await refreshConversationPages(loadedPages);
      setLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      setLoaderStatus(STATUSES.FAILURE);
    }
  }, [conversationPages]);

  const loadMoreConversations = async () => {
    try {
      setMoreLoader(STATUSES.LOADING);
      const nextPage = Math.max(0, ...Object.keys(conversationPages).map((k) => parseInt(k))) + 1;
      const response = await getConversations({
        search,
        page: nextPage,
        is_archived: conversationType === CONVERSATION_TYPES.ARCHIVED,
        page_size: PAGINATION.PAGE_SIZE,
      });

      setConversationPages({ ...conversationPages, [nextPage]: response.results });
      setTotalCount(response.count);
      setMoreLoader(STATUSES.LOADED);
      setCurrentPage(nextPage);
      setHasMorePages(!!response.next);
    } catch (e) {
      toast.warning('Unable to load conversations');
      console.error(e);
      setMoreLoader(STATUSES.FAILURE);
    }
  };

  const setIsVisible = useCallback(
    (pageNumber, isVisible) => {
      if (isVisible) {
        setVisiblePages((visiblePages) => [...new Set([...visiblePages, pageNumber])]);
      } else {
        setVisiblePages((visiblePages) => visiblePages.filter((pn) => pn !== pageNumber));
      }
    },
    [visiblePages]
  );

  const handleSelectConversation = async (id) => {
    if (conversationType === CONVERSATION_TYPES.ONGOING) {
      history.push(`/messages/${id}`);
    } else if (conversationType === CONVERSATION_TYPES.ARCHIVED) {
      history.push(`/messages/archived/${id}`);
    }
    await setConversationRead(id);

    await refreshVisiblePages();
  };

  const provide = {
    loaderStatus,
    setLoaderStatus,
    search,
    setSearch,
    conversationType,
    moreLoader,
    setMoreLoader,
    conversationPages,
    setConversationPages,
    currentPage,
    setCurrentPage,
    totalCount,
    setTotalCount,
    loadedInitial,
    setLoadedInitial,
    showArchiveSuggestion,
    setShowArchiveSuggestion,
    visiblePages,
    setVisiblePages,
    conversationPagesSorted,
    setIsVisible,
    hasMorePages,
    refreshLoadedPages,
    refreshVisiblePages,
    handleSelectConversation,
    loadMoreConversations,
    refreshConversation,
  };

  return <MessagesContext.Provider value={provide} {...props} />;
};

export const useMessagesContext = () => {
  const context = useContext(MessagesContext);
  if (context === undefined) {
    return {};
  }

  return context;
};
