import humps from 'humps';

import { API } from '../api';

export const getContactCenterMe = async () => {
  const response = await API.get('/api/phone-calls/contact-center/me');
  return humps.camelizeKeys(response.data);
};

export const updateContactCenterMe = async (data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.patch('/api/phone-calls/contact-center/me', body);
  return humps.camelizeKeys(response.data);
};

export const getPhoneCallToken = async (data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.post('/api/phone-calls/token', body);
  return humps.camelizeKeys(response.data);
};

export const getContactCenterTaskBySid = async (taskSid) => {
  const response = await API.get(`/api/phone-calls/contact-center/tasks/sid/${taskSid}`);
  return humps.camelizeKeys(response.data);
};

export const updateContactCenterTaskStatusBySid = async (taskSid, data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.put(`/api/phone-calls/contact-center/tasks/sid/${taskSid}`, body);
  return humps.camelizeKeys(response.data);
};

export const createPhoneCall = async (data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.post('/api/phone-calls/', body);
  return humps.camelizeKeys(response.data);
};

export const updatePhoneCall = async (id, data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.patch(`/api/phone-calls/${id}`, body);
  return humps.camelizeKeys(response.data);
};
