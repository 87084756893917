import React, { useContext } from 'react';
import { capitalize } from 'utils/helpers';

import { StyledMDropdown } from './UserManagementDropdownMarket';
import { USER_ROLES } from './constants';

const UserManagementDropdownRole = ({ value, onChange }) => {
  const roleOptions = [
    { key: 'any', value: '', text: 'Any role' },
    { key: 'regular', value: 'regular', text: 'Regular' },
    ...USER_ROLES.map((role) => ({
      key: role,
      value: role,
      text: capitalize(role),
    })),
  ];

  const onRoleChange = (e, { value }) => {
    onChange(value);
  };

  return (
    <StyledMDropdown
      name="Role"
      placeholder="Any role"
      selection
      value={value}
      options={roleOptions}
      onChange={onRoleChange}
    />
  );
};

export default UserManagementDropdownRole;
