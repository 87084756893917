import React, { useContext } from 'react';
import styled from 'styled-components';

import { People } from '../People';
import { CommunitiesContext } from '../../common/communities/CommunitiesContext';

const PeopleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 390px;
`;

const PeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserCommunityHomePeople = () => {
  const { state } = useContext(CommunitiesContext);
  const { activeCommunity } = state;

  const hasPeople = activeCommunity?.sales_users?.length > 0;

  if (!hasPeople) return null;

  return (
    <PeopleWrapper>
      <h3>People</h3>
      <PeopleContainer>
        {activeCommunity?.sales_users?.map((user) => (
          <People key={user.id} user={user} />
        ))}
      </PeopleContainer>
    </PeopleWrapper>
  );
};
