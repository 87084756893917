import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  min-width: 570px;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  background-color: #fff;
`;

export const ListWrapper = ({ children }) => {
  return <Container>{children}</Container>;
};
