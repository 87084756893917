import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { activateAnalytics } from '../../../api';

const ModalText = styled.p`
  font-size: 1.1rem;
`;

export const ActivateAnalytics = () => {
  const [confirmActivate, setConfirmActivate] = useState(false);
  const [loadingActivate, setLoadingActivate] = useState(false);
  const { chatId } = useParams();

  const onActivateAnalytics = async () => {
    try {
      setLoadingActivate(true);
      await activateAnalytics(chatId);
      toast.success('Analytics code successfully activated.');
    } catch (e) {
      console.error(e);
      toast.error('Something went wrong. Analytics activation did not succeed!');
    } finally {
      setLoadingActivate(false);
      setConfirmActivate(false);
    }
  };

  return (
    <>
      <Button
        type="button"
        floated="right"
        content="Activate Analytics"
        onClick={() => {
          setConfirmActivate(true);
        }}
      />
      <Modal size="mini" open={confirmActivate}>
        <Modal.Content>
          <ModalText>
            Are you sure you want to activate chat analytics?
            <br />
            <br />
            This will make changes of tracking codes to all <b>Submit</b> type modules.
          </ModalText>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={loadingActivate}
            onClick={() => {
              setConfirmActivate(false);
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            disabled={loadingActivate}
            loading={loadingActivate}
            onClick={() => {
              onActivateAnalytics();
            }}
          >
            Activate
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
