import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Button, Dimmer, Divider, Label, Loader, Segment, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { deleteManagedUser, getManagedUserDetails } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PageHeader, PAGE_HEADER_MARGIN } from 'components/common';
import FailureMessage from 'components/common/FailureMessage';
import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import { TRAILING_30_DAYS_REPORT_FREQUENCY, STATUSES, USER_TYPES } from '../../../constants';
import UserAccountInvitation from './UserAccountInvitation';
import UserAccountConfirmDelete from './UserAccountConfirmDelete';

const TRAILING_30_DAYS_REPORT_FREQUENCY_LOOKUP = Object.keys(TRAILING_30_DAYS_REPORT_FREQUENCY).reduce((lookup, f) => {
  lookup[TRAILING_30_DAYS_REPORT_FREQUENCY[f]] = capitalize(f.toLowerCase());
  return lookup;
}, {});

const getTrailing30DaysReportDetails = (user) => {
  const frequency = TRAILING_30_DAYS_REPORT_FREQUENCY_LOOKUP[user.trailing_30days_report_frequency];
  if (!frequency) {
    return 'N/A';
  }
  if (
    user.trailing_30days_report_frequency === TRAILING_30_DAYS_REPORT_FREQUENCY.NEVER ||
    !(user.trailing_30days_report_public_report || user.split_regional_report_per_owner)
  ) {
    return frequency;
  }
  let details = frequency;
  if (user.trailing_30days_report_public_report) {
    details += ', without dashboard access from report';
  }
  if (user.split_regional_report_per_owner) {
    details += ', split report per owner';
  }
  return details;
};

const UserAccountDetails = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [deleteLoaderStatus, setDeleteLoaderStatus] = useState(STATUSES.IDLE);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const response = await getManagedUserDetails(userId);

        setUser(response);
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        toast.error(parseErrorResponse(e), 'Unable to fetch user details');
        setLoaderStatus(STATUSES.FAILURE);
      }
    };

    fetchUser();
  }, [userId]);

  const toggleConfirmDelete = () => {
    setOpenConfirmDelete((draft) => !draft);
  };

  const handleDeleteUser = async () => {
    try {
      setDeleteLoaderStatus(STATUSES.LOADING);

      await deleteManagedUser(userId);

      setDeleteLoaderStatus(STATUSES.LOADED);
      toast.success('User deleted successfully');
      history.push('/settings/user-accounts');
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e, 'Unable to delete user account'));
      setDeleteLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <Segment basic>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      {user && (
        <>
          <PageHeader
            title="User details"
            subtitle="User account details and invitation status"
            block
            margin={PAGE_HEADER_MARGIN.MEDIUM}
            actions={
              <>
                <Link to="/settings/user-accounts">
                  <Button content="Close" />
                </Link>
                <Button negative content="Delete" onClick={toggleConfirmDelete} />
                <Link to={`/settings/user-accounts/${userId}/edit`}>
                  <Button primary content="Edit" />
                </Link>
              </>
            }
          />

          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={3}>Email</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={3}>Type</Table.Cell>
                <Table.Cell>{capitalize(user.type)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Name</Table.Cell>
                <Table.Cell>
                  {user.first_name} {user.last_name}
                </Table.Cell>
              </Table.Row>
              <If condition={user.type === USER_TYPES.REGIONAL}>
                <Table.Row>
                  <Table.Cell>Trailing 30 days Report</Table.Cell>
                  <Table.Cell>{getTrailing30DaysReportDetails(user)}</Table.Cell>
                </Table.Row>
              </If>
              <Table.Row>
                <Table.Cell>{capitalize(t('communities'))}</Table.Cell>
                <Table.Cell>
                  <Label.Group circular>
                    {user.communities.map((pp) => (
                      <Label key={pp.id}>{pp.name}</Label>
                    ))}
                  </Label.Group>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider section horizontal>
            Invitation
          </Divider>
          <UserAccountInvitation userInvitation={user.invitation} />
        </>
      )}

      <If condition={loaderStatus === STATUSES.FAILURE}>
        <FailureMessage icon="search" content="Unable to fetch user details." />
      </If>

      <UserAccountConfirmDelete
        open={openConfirmDelete}
        user={user}
        loading={deleteLoaderStatus === STATUSES.LOADING}
        onClose={toggleConfirmDelete}
        onConfirm={handleDeleteUser}
      />
    </Segment>
  );
};

export default UserAccountDetails;
