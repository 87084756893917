import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Form, Radio, TextArea, Popup } from 'components/lib';
import { STATUSES } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';
import { TAGS } from './constants';

const ModalBody = styled.div``;

const RadioWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  margin-bottom: 10px;
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  > span {
    margin-left: 10px;
    padding-top: 6px;
  }
`;

const RadioText = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
`;

const RadioTextTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--text);
`;

const TextAreaField = styled(Form.Field)`
  height: 102px;
  textarea {
    width: 412px;
    height: 102px;
    padding: 8px 5px 0 12px !important;
    border: solid 1px var(--line-standard) !important;
    background-color: var(--input-field-fill) !important;
  }
  label {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--text);
    vertical-align: top;
    margin-right: 10px;
    display: inline-block;
    margin-top: 5px;
  }
`;

const Insert = styled.div`
  width: 413px;
  height: 30px;
  border: solid 1px var(--line-standard);
  border-top: none;
  background-color: #fff;
  margin-bottom: 20px;
  margin-left: 62px;
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  padding: 0 10px;
`;

const Placeholder = styled.span`
  height: 20px;
  padding: 3px 6px 3px 5px;
  border-radius: 4px;
  background-color: #e1f0ff;
  font-size: 10px;
  color: #2791ff;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

export const CallRecordingWhisperModal = ({
  isModalOpen,
  selectedItem,
  flagName,
  totalItemsCount,
  onSave,
  onClose,
  isMultipleSelection,
}) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { t } = useTranslation();

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    await onSave(values);

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;

  const validationSchema = Yup.object({
    outbound_call_recording_announcement: Yup.string().when(flagName, {
      is: true,
      then: Yup.string().required('Whisper message is required'),
    }),
  });

  const initialValues = {
    outbound_call_recording_announcement: selectedItem?.outbound_call_recording_announcement || '',
    [flagName]: selectedItem?.[flagName],
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue, isValid }) => {
        return (
          <SettingsModal
            loading={loading}
            open={isModalOpen}
            onModalClose={() => handleClose(resetForm)}
            size="tiny"
            width={540}
          >
            <SettingsModalHeader
              title="Call Recording & Whisper"
              community={selectedItem || null}
              totalItemsCount={totalItemsCount}
              type="header"
            />
            <ModalBody type="body">
              <RadioText>
                <RadioTextTitle>Outbound Call Recording &amp; Whisper</RadioTextTitle>
                Enable outbound call recording and add a whisper message played to the person answering the call,
                notifying them that they will be recorded.
              </RadioText>
              <RadioWrapper>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name={flagName}
                    value={true}
                    checked={Boolean(values[flagName])}
                    onChange={() => {
                      setFieldValue(flagName, true);
                    }}
                  />
                  <span>On</span>
                </CustomRadio>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name={flagName}
                    value={false}
                    checked={String(values[flagName]) === String(false)}
                    onChange={() => {
                      setFieldValue(flagName, false);
                    }}
                  />
                  <span>Off</span>
                </CustomRadio>
              </RadioWrapper>
              <TextAreaField
                control={TextArea}
                name="outbound_call_recording_announcement"
                label="Whisper"
                value={values.outbound_call_recording_announcement}
                placeholder="Enter whisper message to be played back (i.e. 'This call is being recorded.')"
                onChange={(e, { value }) => {
                  setFieldValue('outbound_call_recording_announcement', value);
                }}
              />
              <Insert>
                Insert
                {TAGS(t).map((tag) => {
                  return (
                    <Popup
                      key={tag.key}
                      disabled={!tag.enabled || !tag.text}
                      content={tag.text}
                      trigger={
                        <Placeholder
                          onClick={() =>
                            setFieldValue(
                              'outbound_call_recording_announcement',
                              `${values.outbound_call_recording_announcement || ''} ${tag.key}`
                            )
                          }
                          disabled={!tag.enabled}
                        >
                          {tag.label}
                        </Placeholder>
                      }
                    />
                  );
                })}
              </Insert>
            </ModalBody>
            <SettingsModalActions
              onSave={handleSubmit}
              onCancel={() => handleClose(resetForm)}
              type="actions"
              disabled={!dirty || !touched || !isValid}
              saveText="Save"
            />
          </SettingsModal>
        );
      }}
    </Formik>
  );
};
