import { API } from '../api';

export const getTeasers = async (chatId) => {
  const response = await API.get(`/api/chat/${chatId}/teasers`);
  return response.data;
};

export const createTeaser = async (chatId, teaser) => {
  const response = await API.post(`/api/chat/${chatId}/teasers`, teaser);
  return response.data;
};

export const updateTeaser = async (chatId, teaserId, values) => {
  const response = await API.put(`/api/chat/${chatId}/teasers/${teaserId}`, values);
  return response.data;
};

export const deleteTeaser = async (chatId, teaserId) => {
  const response = await API.delete(`/api/chat/${chatId}/teasers/${teaserId}`);
  return response.data;
};
