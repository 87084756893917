import React from 'react';
import styled from 'styled-components';
import { CommunitiesContextProvider } from './CommunitiesContext';
import { UserCommunitiesList } from './UserCommunitiesList';

const UserCommunitiesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const UserCommunitiesContent = styled.div`
  display: flex;
  width: 100%;
`;

// Top level component that provider filterable user communities menu
// linkPrefix - route prefix to be used when generating links for menu items: "{LinkPrefix}{CommunityID}"

export const UserCommunities = ({ linkPrefix, communitiesCallback, children }) => (
  <CommunitiesContextProvider linkPrefix={linkPrefix}>
    <UserCommunitiesWrapper>
      <UserCommunitiesList communitiesCallback={communitiesCallback} />
      <UserCommunitiesContent>{children}</UserCommunitiesContent>
    </UserCommunitiesWrapper>
  </CommunitiesContextProvider>
);
