import React, { useEffect, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { subDays } from 'date-fns';

import { Dimmer, Loader, Dropdown, Button } from 'components/lib';
import useDataList from 'utils/hooks/useDataList';
import { formatAnyDate } from 'components/utils';
import { If } from 'components/If';
import { getCRMLogsLeads, getCRMTypesSearch, getAdminUsersSearch } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import SearchInput from 'components/SearchInput';
import FailureMessage from 'components/common/FailureMessage';
import { DateRangePicker as BaseDateRangePicker, MultiSelectFilter } from 'components/common';
import { MEDIA_BREAK_POINTS, STATUSES, PAGINATION } from '../../constants';
import CRMAdminLogsLeadsTable from './CRMAdminLogsLeadsTable';
import { VALID_OPTIONS, PARSABLE_OPTIONS } from './constants';

const DateRangePicker = styled(BaseDateRangePicker)``;

const PageContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 30px;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    gap: 10px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
  }
`;

const SecondRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    gap: 1rem;
  }
`;

const SearchBox = styled.div`
  width: 400px;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    width: 100%;
  }
`;
const DropdownBox = styled.div`
  width: 196px;
  .ui.dropdown {
    .text {
      padding-left: 6px;
    }
  }

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    width: 100%;
  }
`;

const CustomMultiSelectFilter = styled(MultiSelectFilter)`
  .ui.dropdown {
    min-width: unset;
    width: 196px;
    @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      width: 100%;
    }
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    color: var(--link);
    display: ${(props) => (props.show ? 'block' : 'none')};
  }
`;

const CRMAdminLogsLeads = () => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const { items, totalPages, filters, setItems, setPage, setSearch, setFilters } = useDataList({
    initialState: {
      filters: {
        startDate: formatAnyDate(subDays(new Date(), 30), 'yyyy-MM-dd'),
        endDate: formatAnyDate(new Date(), 'yyyy-MM-dd'),
        page: PAGINATION.PAGE,
        pageSize: PAGINATION.PAGE_SIZE,
        search: '',
        crmType: null,
        user: null,
        isParsable: undefined,
        isValid: undefined,
      },
    },
  });
  const [crmTypeOptions, setCrmTypeOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);

  const updateFilterApplied = useCallback(() => {
    const defaultStartDate = formatAnyDate(subDays(new Date(), 30), 'yyyy-MM-dd');
    const defaultEndDate = formatAnyDate(new Date(), 'yyyy-MM-dd');
    const isApplied =
      (filters.search && filters.search !== '') ||
      (filters.user && filters.user !== null) ||
      (filters.crmType && filters.crmType !== null) ||
      (filters.isParsable && filters.isParsable !== undefined) ||
      (filters.isValid && filters.isValid !== undefined) ||
      filters.startDate !== defaultStartDate ||
      filters.endDate !== defaultEndDate;
    setFilterApplied(isApplied);
  }, [filters]);

  useEffect(() => {
    updateFilterApplied();
  }, [filters, updateFilterApplied]);

  const fetchLeads = useCallback(
    async ({ page, pageSize, search, startDate, endDate, crmType, user, isParsable, isValid }) => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const { results, count } = await getCRMLogsLeads({
          page,
          pageSize,
          search,
          startDate,
          endDate,
          crmType: crmType ? crmType.join(',') : null,
          ...(user && { user }),
          ...(isParsable !== undefined && { isParsable: isParsable === 'parsable' }),
          ...(isValid !== undefined && { isValid: isValid === 'valid' }),
        });
        setItems({ results, count });
        setLoaderStatus(STATUSES.LOADED);
      } catch (error) {
        toast.error(parseErrorResponse(error, 'Unable to fetch leads'));
        setLoaderStatus(STATUSES.FAILURE);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchLeads(filters);
  }, [fetchLeads, filters]);

  const fetchInitialData = useCallback(async () => {
    try {
      const [crmTypes, adminUsers] = await Promise.all([getCRMTypesSearch(), getAdminUsersSearch({ search: '' })]);

      setCrmTypeOptions(crmTypes);

      setUserOptions([
        { key: 'all', value: null, text: 'Any user' },
        ...adminUsers.results.map((user) => ({
          key: user.id,
          value: user.id,
          text: user.email,
        })),
      ]);
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to fetch initial data'));
    }
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const fetchAdminUsers = useCallback(async (searchQuery = '') => {
    try {
      const { results } = await getAdminUsersSearch({ search: searchQuery });
      setUserOptions([
        { key: 'all', value: null, text: 'Any user' },
        ...results.map((user) => ({
          key: user.id,
          value: user.id,
          text: user.email,
        })),
      ]);
    } catch (error) {
      toast.error(parseErrorResponse(error, 'Unable to fetch users'));
    }
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSearch = (value) => {
    setSearch(value);
    setFilters({ ...filters, search: value });
  };

  const handleDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const handleClearFilters = () => {
    const defaultStartDate = formatAnyDate(subDays(new Date(), 30), 'yyyy-MM-dd');
    const defaultEndDate = formatAnyDate(new Date(), 'yyyy-MM-dd');
    setSearch('');
    setFilters({
      page: 1,
      pageSize: filters.pageSize,
      search: '',
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      crmType: null,
      user: null,
      isParsable: undefined,
      isValid: undefined,
    });

    fetchLeads({
      page: 1,
      pageSize: filters.pageSize,
      search: '',
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      crmType: null,
      user: null,
      isParsable: undefined,
      isValid: undefined,
    });

    fetchAdminUsers('');

    setFilterApplied(false);
  };

  const handleFilterChange =
    (key) =>
    (_, { value }) => {
      setFilters({ ...filters, [key]: value });
      if (key === 'user' && value === null) {
        setSearch('');
        fetchLeads({
          page: filters.page,
          pageSize: filters.pageSize,
          search: '',
          startDate: filters.startDate,
          endDate: filters.endDate,
          crmType: filters.crmType,
          user: null,
          isParsable: filters.isParsable || undefined,
          isValid: filters.isValid || undefined,
        });
      }
    };

  return (
    <PageContainer>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {loaderStatus !== STATUSES.FAILURE && (
        <>
          <Container>
            <FirstRow>
              <DateRangePicker
                dateRange={{ start_date: filters.startDate, end_date: filters.endDate }}
                onFilterChange={handleDateRangeChange}
              />
              <SearchBox>
                <SearchInput
                  value={filters.search}
                  fluid
                  onSearch={handleSearch}
                  placeholder="Search leads by name, email, community"
                />
              </SearchBox>
            </FirstRow>
            <SecondRow>
              <DropdownBox>
                <Dropdown
                  basic
                  fluid
                  search
                  selection
                  options={userOptions}
                  value={filters.user}
                  onSearchChange={(e, { searchQuery }) => fetchAdminUsers(searchQuery)}
                  onChange={(e, { value }) => {
                    handleFilterChange('user')(e, { value });
                    if (value === null) {
                      fetchAdminUsers('');
                    }
                  }}
                  placeholder="Select user"
                />
              </DropdownBox>
              <CustomMultiSelectFilter
                name="Types"
                placeholder="Select CRM type"
                options={crmTypeOptions}
                selectedValues={filters.crmType}
                onChange={(value) => handleFilterChange('crmType')(null, { value })}
                closeOnApply={true}
              />
              <DropdownBox>
                <Dropdown
                  basic
                  fluid
                  selection
                  options={PARSABLE_OPTIONS}
                  value={filters.isParsable || undefined}
                  onChange={handleFilterChange('isParsable')}
                  placeholder="Select parsable"
                />
              </DropdownBox>
              <DropdownBox>
                <Dropdown
                  basic
                  fluid
                  selection
                  options={VALID_OPTIONS}
                  value={filters.isValid || undefined}
                  onChange={handleFilterChange('isValid')}
                  placeholder="Select valid"
                />
              </DropdownBox>
              <StyledButton className="link" show={filterApplied ? 'true' : undefined} onClick={handleClearFilters}>
                Clear Filters
              </StyledButton>
            </SecondRow>
          </Container>
          <CRMAdminLogsLeadsTable
            leads={items}
            activePage={filters.page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
      <If condition={loaderStatus === STATUSES.FAILURE}>
        <FailureMessage icon="search" content="Leads could not be loaded" />
      </If>
    </PageContainer>
  );
};

export default CRMAdminLogsLeads;
