import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { If } from 'components/If';
import { Form, Radio, TextArea, Popup } from 'components/lib';
import { STATUSES } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';
import { TAGS } from './constants';

const ModalBody = styled.div``;

const RadioWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  margin-bottom: 10px;
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  > span {
    margin-left: 10px;
    padding-top: 6px;
  }
`;

const RadioText = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
`;

const RadioTextTitle = styled.div`
  font-size: 14px;
  font-weight: 900;
  color: var(--text);
`;

const TextAreaField = styled(Form.Field)`
  height: 102px;
  textarea {
    width: 412px;
    height: 102px;
    padding: 8px 5px 0 12px !important;
    border: solid 1px var(--line-standard) !important;
    background-color: var(--input-field-fill) !important;
  }
  label {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--text);
    vertical-align: top;
    margin-right: 10px;
    display: inline-block;
    margin-top: 5px;
  }
`;

const Insert = styled.div`
  width: 413px;
  height: 30px;
  border: solid 1px var(--line-standard);
  border-top: none;
  background-color: #fff;
  margin-bottom: 20px;
  margin-left: 66px;
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  padding: 0 10px;
`;

const Placeholder = styled.span`
  height: 20px;
  padding: 3px 6px 3px 5px;
  border-radius: 4px;
  background-color: #e1f0ff;
  font-size: 10px;
  color: #2791ff;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

const Error = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: var(--validation-red);
  margin-left: 66px;
  margin-bottom: 20px;
`;

export const CallWhisperModal = ({ isModalOpen, selectedItem, totalItemsCount, onSave, onClose }) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const { t } = useTranslation();

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    await onSave(values);

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;

  const validationSchema = Yup.object().shape({
    use_call_whispering: Yup.boolean().required(),
    call_whisper_text: Yup.string().when('use_call_whispering', {
      is: true,
      then: Yup.string().required('Please add a message'),
    }),
    use_call_screening: Yup.boolean().test(
      'is-whispering-on',
      'Call Whispering must be enabled if Allow Accepting/Rejecting Call is enabled',
      function (value) {
        // eslint-disable-next-line react/no-this-in-sfc
        const { use_call_whispering, call_whisper_text } = this.parent;
        if (value) {
          if (!use_call_whispering) {
            // eslint-disable-next-line react/no-this-in-sfc
            return this.createError({
              path: 'use_call_whispering',
              message: 'Call Whispering must be enabled if Allow Accepting/Rejecting Call is enabled',
            });
          }
          if (!call_whisper_text) {
            // eslint-disable-next-line react/no-this-in-sfc
            return this.createError({
              path: 'call_whisper_text',
              message: 'Please add a message',
            });
          }
        }
        return true;
      }
    ),
  });

  const initialValues = {
    call_whisper_text: selectedItem?.call_whisper_text || '',
    use_call_whispering: selectedItem?.use_call_whispering,
    use_call_screening: selectedItem?.use_call_screening,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue, isValid, errors }) => {
        return (
          <SettingsModal
            loading={loading}
            open={isModalOpen}
            onModalClose={() => handleClose(resetForm)}
            size="tiny"
            width={540}
          >
            <SettingsModalHeader
              title="Call Whisper"
              community={selectedItem || null}
              totalItemsCount={totalItemsCount}
              type="header"
            />
            <ModalBody type="body">
              <RadioText>
                <RadioTextTitle>Call Whisper</RadioTextTitle>
                Add a short message that plays to the person answering the phone before they are connected to the
                caller.{' '}
              </RadioText>
              <RadioWrapper>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name="use_call_whispering"
                    value={true}
                    onChange={(e, { value }) => {
                      setFieldValue('use_call_whispering', value);
                    }}
                    checked={values.use_call_whispering}
                  />
                  <span>On</span>
                </CustomRadio>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name="use_call_whispering"
                    value={false}
                    onChange={(e, { value }) => {
                      setFieldValue('use_call_whispering', value);
                    }}
                    checked={!values.use_call_whispering}
                  />
                  <span>Off</span>
                </CustomRadio>
              </RadioWrapper>
              <TextAreaField
                control={TextArea}
                name="call_whisper_text"
                label="Message"
                value={values.call_whisper_text}
                placeholder="Enter whisper message to be played back"
                onChange={(e, { value }) => {
                  setFieldValue('call_whisper_text', value);
                }}
              />
              <Insert>
                Insert
                {TAGS(t).map((tag) => {
                  return (
                    <Popup
                      key={tag.key}
                      disabled={!tag.enabled || !tag.text}
                      content={tag.text}
                      trigger={
                        <Placeholder
                          onClick={() => {
                            console.log('tag.key: ', tag.key, values);
                            setFieldValue('call_whisper_text', `${values.call_whisper_text || ''} ${tag.key}`);
                          }}
                          disabled={!tag.enabled}
                        >
                          {tag.label}
                        </Placeholder>
                      }
                    />
                  );
                })}
              </Insert>
              <If condition={Object.keys(errors).length}>
                <Error>
                  {Object.keys(errors).map((key) => {
                    return <div key={key}>{errors[key]}</div>;
                  })}
                </Error>
              </If>
              <RadioText>
                <RadioTextTitle>Allow Accepting/Rejecting Call</RadioTextTitle>
                If this is on, the person answering the call will be able to choose whether to start the call by
                pressing a button, or hang up. Please ensure that the whisper message above contains proper instructions
                for this (i.e. Incoming call from Company. Press any button to accept. Hang up the call to decline.).
              </RadioText>
              <RadioWrapper>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name="use_call_screening"
                    value={true}
                    onChange={(e, { value }) => {
                      setFieldValue('use_call_screening', value);
                    }}
                    checked={values.use_call_screening}
                    disabled={!values.use_call_whispering}
                  />
                  <span>On</span>
                </CustomRadio>
                <CustomRadio>
                  <Form.Field
                    control={Radio}
                    name="use_call_screening"
                    value={false}
                    onChange={(e, { value }) => {
                      setFieldValue('use_call_screening', value);
                    }}
                    checked={!values.use_call_screening}
                    disabled={!values.use_call_whispering}
                  />
                  <span>Off</span>
                </CustomRadio>
              </RadioWrapper>
            </ModalBody>
            <SettingsModalActions
              onSave={handleSubmit}
              onCancel={() => handleClose(resetForm)}
              type="actions"
              disabled={!dirty || !touched || !isValid}
              saveText="Save"
            />
          </SettingsModal>
        );
      }}
    </Formik>
  );
};
