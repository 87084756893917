import React, { useState } from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Dropdown, RangeInput, Icon } from 'components/lib';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .range-input {
    margin-bottom: 0;
  }
  .close {
    margin-left: auto;
  }
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    width: 130px;
  }
`;

const RangeWrapper = styled.div`
  width: 116px;
`;

const RangeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 163px;
  margin: 0 10px;
`;

const Value = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-left: 10px;
  padding-top: 5px;
  width: 17px;
`;

const RuleSlider = ({ rule, onChange, onRemove, preview, selectedRule }) => {
  const { operators, minValue, maxValue, defaultValue } = rule;
  const options = operators.map((operator) => ({ key: operator.id, text: operator.displayName, value: operator.id }));
  const [selectedOperator, setSelectedOperator] = useState(selectedRule?.operator || rule?.operators[1].id);
  const [selectedValue, setSelectedValue] = useState(selectedRule?.value || defaultValue);
  const handleOnClick = () => {
    if (preview) {
      return;
    }
    const data = {
      id: rule.ruleId || rule.id,
      value: selectedValue,
      operator: selectedOperator,
      ruleId: rule.id,
      options: [],
    };
    onChange(data);
  };
  return (
    <Container preview={preview}>
      <If condition={!preview}>
        <StyledDropdown
          placeholder="Select"
          fluid
          selection
          options={options}
          value={selectedOperator}
          onChange={(e, { value }) => setSelectedOperator(value)}
          disabled={preview}
        />
        <RangeInputWrapper>
          <RangeWrapper>
            <RangeInput
              min={minValue}
              max={maxValue}
              step={1}
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
              className="range-input"
              hideFloatingValue
              disabled={preview}
            />
          </RangeWrapper>
          <Value>{selectedValue}</Value>
        </RangeInputWrapper>
      </If>
      <If condition={!preview}>
        <Icon name="add_circle" size="20" color="#007aff" onClick={handleOnClick} />
      </If>
      <If condition={preview}>
        <Icon name="close" size="24" color="#89919d" onClick={onRemove} className="close" />
      </If>
    </Container>
  );
};

export default RuleSlider;
