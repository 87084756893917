import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';

const MetricContainer = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 100px;
  cursor: pointer;
  h2,
  h6,
  span {
    margin: 0;
    color: var(--text);
  }
`;

const MetricLabel = styled.h6`
  font-size: 12px;
  letter-spacing: 0.5px;
  font-family: var(--font-family);
  text-transform: uppercase;
  font-weight: normal;
`;

const MetricValue = styled.h2`
  font-family: var(--font-family);
  font-size: 26px;
`;

const MetricDescription = styled.span`
  font-family: var(--font-family);
  font-size: 12px;
`;

export const Metric = ({ label, value = 0, description }) => {
  return (
    <MetricContainer>
      <If condition={label}>
        <MetricLabel>{label}</MetricLabel>
      </If>
      <MetricValue>{value}</MetricValue>
      <If condition={description}>
        <MetricDescription>{description}</MetricDescription>
      </If>
    </MetricContainer>
  );
};
