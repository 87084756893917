import { createDefaultUnitType } from './utils';
import constants, { UNIT_LEVEL_PRICING_FIRST_STEP, UNIT_LEVEL_PRICING_LAST_STEP } from './constants';

const reducer = (draft, action) => {
  switch (action.type) {
    case constants.SET_LOADER_STATUS:
      draft.loaderStatus = action.payload;
      break;
    case constants.SET_INVITATION: {
      const { invitation, token } = action.payload;

      draft.invitation = invitation;
      draft.token = token;
      draft.isTokenInvalid = false;
      break;
    }
    case constants.SET_TOKEN_INVALID:
      draft.isTokenInvalid = true;
      break;
    case constants.SET_NEXT_STEP:
      if (draft.activeStep <= UNIT_LEVEL_PRICING_LAST_STEP) draft.activeStep += 1;
      break;
    case constants.SET_PREV_STEP:
      if (draft.activeStep >= UNIT_LEVEL_PRICING_FIRST_STEP) draft.activeStep -= 1;
      break;
    case constants.SET_CARE_TYPES:
      draft.careTypes = action.payload;
      draft.units = [createDefaultUnitType(draft.careTypes)];
      break;
    case constants.SET_UNITS:
      draft.units = action.payload;
      break;
    default:
      console.warn(`Unhandled action ${JSON.stringify(action)}`);
      break;
  }

  return draft;
};

export default reducer;
