import React from 'react';
import styled from 'styled-components';
import { formatCount } from 'components/utils';
import { STATUSES } from 'constants';
import { Dimmer, Loader } from 'components/lib';
import SeriesDescription from './SeriesDescription';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--text);

  .chart {
    position: relative;
  }

  .legend {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;

    .units {
      font-family: var(--font-family-bold);
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.5;
      margin-left: 5px;
    }

    .total {
      font-family: var(--font-family);
      line-height: 57px;
      font-size: 42px;
      margin-left: 5px;
    }

    .series {
      display: flex;
      flex-direction: column;
    }
  }
`;

const DEFAULT_HEIGHT = 200;
const DEFAULT_LINE_WIDTH = 10;
const DEFAULT_COLOR = '#1f57bd';
const DEFAULT_BACK_COLOR = '#bfd2f5';

const Line = styled.div`
  height: ${(p) => p.height}px;
  width: ${(p) => p.lineWidth}px;
  display: flex;
  align-items: flex-end;
  background-color: ${(p) => p.backColor || DEFAULT_BACK_COLOR};
  border-radius: 5px;

  > div {
    background-color: ${(p) => p.color || DEFAULT_COLOR};
    border-radius: 5px;
    height: ${(p) => p.perc * 100}%;
    width: 100%;
    content: '';
  }
`;

const PercentLine = ({
  data,
  units,
  height = DEFAULT_HEIGHT,
  lineWidth = DEFAULT_LINE_WIDTH,
  loaderStatus,
  color = DEFAULT_COLOR,
  backColor = DEFAULT_BACK_COLOR,
}) => {
  let first = '-';
  let second = '-';
  let firstName = '-';
  let secondName = '-';
  let total = null;
  let perc = 0;
  if (data) {
    first = Object.values(data)[0];
    second = Object.values(data)[1];
    firstName = Object.keys(data)[0];
    secondName = Object.keys(data)[1];
    total = first + second;
    perc = (first * 1.0) / total;
  }

  if (!Number.isFinite(total)) {
    total = null;
    perc = 0;
  }

  return (
    <Wrapper>
      <Dimmer active={!data || loaderStatus === STATUSES.LOADING} inverted>
        <Loader active size="large" />
      </Dimmer>
      <div className="chart">
        <Line perc={perc} height={height} lineWidth={lineWidth} color={color} backColor={backColor}>
          <div />
        </Line>
      </div>
      <div className="legend">
        <div className="units">{units}</div>
        <div className="total">{formatCount(total)}</div>
        <div className="series">
          <SeriesDescription
            name={secondName}
            value={second}
            total={total}
            color={backColor}
            fontSize="14px"
            unitFontSize="12px"
            nameFontFamily="var(--font-family)"
          />
          <SeriesDescription
            name={firstName}
            value={first}
            total={total}
            color={color}
            fontSize="14px"
            unitFontSize="12px"
            nameFontFamily="var(--font-family)"
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default PercentLine;
