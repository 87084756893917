export const URL_SEARCH_NAME = 'search';

export const parseBooleanQueryParam = (value) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return null;
};

export const getUrlFilterAndSearch = ({ flagName, searcName = URL_SEARCH_NAME }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const urlFilterValue = queryParams.get(flagName);
  const urlSearchValue = queryParams.get(searcName);

  return {
    urlFilterValue,
    urlSearchValue,
  };
};
