import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { ProfileCircle } from 'components/common/profile/ProfileCircle';
import { Loader } from 'components/lib';
import { If } from 'components/If';
import { SearchField } from '../EditCampaigns/sidebar/Communities/SearchField';

const Container = styled.div`
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 10px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  text-align: left;
  line-height: 1.7;
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  input {
    font-size: 14px;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  cursor: pointer;
  &:hover {
    h4 {
      text-decoration: underline;
    }
  }
`;

const SearchWrapper = styled.div`
  width: 480px;
`;

const Name = styled.h4`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: 1.43;
  color: var(--text-color);
  margin: 0 0 0 16px;
`;

export const RelatedCommunities = ({ campaign }) => {
  const [searchValue, setSearchValue] = useState('');
  const communities = campaign?.communities;
  const history = useHistory();
  const filteredCommunities = useMemo(() => {
    let newCommunities = communities;

    if (!newCommunities || newCommunities.length === 0) {
      return [];
    }

    if (searchValue) {
      newCommunities = communities.filter((community) =>
        community.name?.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return newCommunities;
  }, [communities, searchValue]);

  const changeSearchValue = (value) => {
    setSearchValue(value);
  };

  const handleCommunityClick = (communityId) => {
    history.push(`/communities/${communityId}`);
  };

  return (
    <>
      <If condition={campaign?.communities.length > 0}>
        <SearchWrapper>
          <SearchField placeholder="Search" value={searchValue} onSearch={changeSearchValue} />
        </SearchWrapper>
      </If>
      <Container>
        <If condition={!campaign}>
          <Loader active transparent />;
        </If>
        {filteredCommunities.map((community) => (
          <NameWrapper onClick={() => handleCommunityClick(community.id)} communityId={community.id}>
            <ProfileCircle name={community?.name} picture={community?.pictureUrl} />
            <Name key={community.id}>{community.name}</Name>
          </NameWrapper>
        ))}
      </Container>
    </>
  );
};

export default RelatedCommunities;
