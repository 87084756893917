import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Message, Table } from 'components/lib';
import { CsvField } from 'components/CsvField';
import { capitalize } from 'utils/helpers';

const emailValidator = Yup.string().email('Must be a valid email address');

const JobApplicantEmailTable = ({ communities }) => {
  const { t } = useTranslation();

  if (!communities) {
    return null;
  }

  if (communities.length === 0) {
    return <Message info>{capitalize(t('communities'))} not found.</Message>;
  }

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={6}>{capitalize(t('community'))}</Table.HeaderCell>
          <Table.HeaderCell>Emails</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {communities.map((community, index) => (
          <Table.Row key={community.id}>
            <Table.Cell>{community.name}</Table.Cell>
            <Table.Cell>
              <CsvField name={`communities[${index}].emails`} csvElementValidator={emailValidator} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default JobApplicantEmailTable;
