import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Label, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import classNames from 'classnames';

import { If } from 'components/If';
import { ItemsPopover } from 'components/ItemsPopover';

export const ALL_ITEMS = 'ALL';
const NO_RESULTS_FOUND = 'No results found.';

const Container = styled.div`
  position: relative;
  width: 100%;

  > .search.selection.dropdown {
    padding-right: 20px;
  }

  > .search.selection.dropdown.hasMore {
    padding-right: 8rem;
  }
`;
const ClearIcon = styled(Icon)`
  position: absolute;
  right: 7px;
  top: 9px;
  cursor: pointer;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  &.close.icon {
    font-size: 12px;
  }
`;

const MoreItems = styled.div`
  position: absolute;
  right: 25px;
  top: 1px;
  padding: 7px;
  background-color: #fff;
  z-index: 10;
`;

const PopoverTrigger = styled.span`
  color: var(--global-color-primary);
  cursor: pointer;
  text-decoration: underline;
`;

const SelectAllContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 0;
`;

export const DropDownPicker = ({
  options = [],
  selectedValues,
  onSelect,
  allItemsSelectedText,
  placeholder,
  disabled,
  className,
}) => {
  const initSelectedState = (options, selectedValues = []) => {
    const array = options && options.filter((c) => selectedValues === ALL_ITEMS || selectedValues?.includes(c.id));
    return array?.map((a) => a.id);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [allItemsSelected, setAllItemsSelected] = useState(
    selectedValues === ALL_ITEMS || selectedValues?.length === options?.length
  );
  const [itemsChecked, setItemsChecked] = useState(selected);

  useEffect(() => {
    setSelected(initSelectedState(options, selectedValues));
  }, [options, selectedValues]);

  useEffect(() => {
    setAllItemsSelected(selected?.length === options?.length);
  }, [selected, options]);

  const handleOnBlur = (newValues) => {
    const sortSelected = selectedValues !== ALL_ITEMS && selectedValues.sort((a, b) => a - b);
    if (
      sortSelected?.length !== newValues.length ||
      !sortSelected.every((value, index) => value === newValues[index])
    ) {
      onSelect(newValues);
    }
    setIsOpen(false);
  };

  const handlePopupOpen = () => {
    const object = {};
    selected.forEach((s) => {
      object[s] = true;
    });
    setItemsChecked(object);
  };

  const handlePopupClose = () => {
    const newValues = Object.keys(itemsChecked)
      .filter((id) => itemsChecked[id])
      .map(Number);
    const sortSelected = selected.sort((a, b) => a - b);
    if (sortSelected.length !== newValues.length || !sortSelected.every((value, index) => value === newValues[index])) {
      onSelect(newValues);
    }
  };

  const handleChange = (e, { value }) => setSelected(value);

  const handlePopupValueChange = (items) => setItemsChecked(items);

  const handleSelectAll = () => {
    const allItems = options.map((c) => c.id);
    setIsOpen(false);
    setTimeout(() => {
      onSelect(allItems);
    }); // Need this because blur event is triggered with selected items, and we need all the items.
  };

  const handleItemRemove = (item) => {
    const newValue = selected.filter((s) => s !== item.key);
    onSelect(newValue);
  };

  if (!options) {
    return null;
  }

  const dropDownValues = options.map((c) => ({
    key: c.id,
    text: c.name,
    value: c.id,
  }));

  const classes = classNames({
    hasMore: selected?.length > 2,
  });

  const hasSelected = selected?.length !== 0;
  const hasMore = selected?.length > 2;
  const values = selected?.map((s) => s?.id || s);
  const noResultsMessage = (allItemsSelected && allItemsSelectedText) || NO_RESULTS_FOUND;

  return (
    <Container className={className}>
      <Dropdown
        fluid
        search
        multiple
        selection
        open={isOpen}
        value={values}
        className={classes}
        options={dropDownValues}
        placeholder={placeholder}
        disabled={disabled}
        noResultsMessage={noResultsMessage}
        onChange={handleChange}
        onFocus={() => setIsOpen(true)}
        onBlur={() => handleOnBlur(selected)}
        renderLabel={(item, index) =>
          index < 2 ? <Label onRemove={() => handleItemRemove(item)} content={item.text} /> : null
        }
        header={
          <If condition={allItemsSelectedText && !allItemsSelected}>
            <SelectAllContainer>
              <Button basic color="blue" onClick={handleSelectAll} size="mini">
                Select all
              </Button>
            </SelectAllContainer>
          </If>
        }
      />
      <If condition={hasSelected}>
        <ClearIcon onClick={() => handleOnBlur([])} name="close" />
      </If>
      <If condition={hasMore}>
        <MoreItems>
          <Popup
            hoverable
            onMount={handlePopupOpen}
            onUnmount={handlePopupClose}
            trigger={<PopoverTrigger>+ {selected?.length - 2} more</PopoverTrigger>}
            content={<ItemsPopover options={options} selected={selected} onChangeValue={handlePopupValueChange} />}
          />
        </MoreItems>
      </If>
    </Container>
  );
};
