import styled from 'styled-components';

export const Sidebar = styled.div`
  flex: 0 0 auto;
  background-color: #f9fafb;
  border: 1px solid #e4e4e4;
  overflow: hidden;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  margin: -1px;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.25rem; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1); /* color of the scroll thumb */
    border-radius: 0.25rem; /* roundness of the scroll thumb */
  }
`;
