import React from 'react';
import styled from 'styled-components';

import { Checkbox as SemanticCheckbox, Form } from 'semantic-ui-react';

const StyledCheckbox = styled(SemanticCheckbox)`
  ${({ toggle, slider }) =>
    !toggle &&
    !slider &&
    `
      &&& {
    display: flex;
    label:before {
      border: 2px solid #777777;
      width: 18px;
      height: 18px;
    }
    input:checked ~ label:before {
      background: var(--emerald-green);
      border-color: var(--emerald-green);
    }
    input:checked ~ label:after {
      width: 18px;
      height: 18px;
      color: #ffffff;
      content: '\\e5ca';
      font-family: 'Material Icons';
      padding-top: 1px;
    }
  }
  `}
`;

export const Checkbox = ({ ...props }) => <StyledCheckbox {...props} />;
