import { URL_MAPPING_RULES, URL_MAPPING_TYPES } from '../MappingRules';
import CRMSourceMappingLeadChannelSourceRule from './CRMSourceMappingLeadChannelSourceRule';
import CRMSourceMappingTrafficSourceRule from './CRMSourceMappingTrafficSourceRule';
import CRMSourceMappingUrlParameterRule from './CRMSourceMappingUrlParameterRule';

export const SOURCE_MAPPING_TYPES = {
  TRAFFIC_SOURCE: 'traffic_source',
  CHANNEL_SOURCE: 'channel_source',
  URL_PARAMETERS: URL_MAPPING_TYPES.URL_PARAMETERS,
  URL_LOCATION: URL_MAPPING_TYPES.URL_LOCATION,
  URL_PARAMETERS_MATCH: URL_MAPPING_TYPES.URL_PARAMETERS_MATCH,
};

export const SOURCE_MAPPING_RULES = [
  {
    mappingType: SOURCE_MAPPING_TYPES.TRAFFIC_SOURCE,
    name: 'Traffic Source',
    component: CRMSourceMappingTrafficSourceRule,
    defaults: {
      value: '',
      result_value: '',
    },
  },
  {
    mappingType: SOURCE_MAPPING_TYPES.CHANNEL_SOURCE,
    name: 'Channel Source',
    component: CRMSourceMappingLeadChannelSourceRule,
    defaults: {
      value: '',
      result_value: '',
    },
  },
  ...URL_MAPPING_RULES.map((mr) => {
    if (mr.mappingType === SOURCE_MAPPING_TYPES.URL_PARAMETERS) {
      return {
        ...mr,
        component: CRMSourceMappingUrlParameterRule,
      };
    }
    return mr;
  }),
];
