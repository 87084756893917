import React, { useContext } from 'react';
import styled from 'styled-components';

import { MessageContext } from '../MessageContext';
import { CALENDAR_REQUEST_TYPE } from './constants';
import { CalendarRequestContext } from './CalendarRequests';

const NoteHeader = styled.div`
  font-size: 14px;
  font-family: var(--font-family-bold);
`;

export const CalendarRequestNote = () => {
  const { lead } = useContext(MessageContext);
  const { type } = useContext(CalendarRequestContext);

  const hasTourReminder = type === CALENDAR_REQUEST_TYPE.TOUR && lead.tour?.note;
  const hasCallReminder = type === CALENDAR_REQUEST_TYPE.CALL && lead.call?.note;

  const requestNote = (hasTourReminder && lead.tour?.note) || (hasCallReminder && lead.call.note);

  if (!requestNote) return null;

  return (
    <div>
      <NoteHeader>Note:</NoteHeader>
      <div>{requestNote}</div>
    </div>
  );
};
