import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dimmer, Loader, Message, Grid } from 'semantic-ui-react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getCommunitiesForLoggedUser } from 'apis';
import { capitalize } from 'utils/helpers';
import { STATUSES } from '../../../constants';
import { If } from '../../If';
import { AssignCommunity } from './AssignCommunity';
import { CommunitiesContext, ACTIONS } from '../../common/communities/CommunitiesContext';
import { CommunitiesMenu } from './CommunitiesMenu';
import { Community } from './Community';
import { PageHeader } from '../../common';
import { PAGE_HEADER_MARGIN } from '../../common/PageHeader';

const CommunitysWrapper = styled.div`
  position: relative;
  min-height: 20rem;

  .ui.active.tab {
    border: none;
    padding-top: 0.5rem;
  }

  .ui.secondary.vertical.menu > .item.disabled {
    padding: 0;
    margin-bottom: 2rem;
  }
`;

export const Communities = () => {
  const { t } = useTranslation();
  const { chatId } = useParams();
  const history = useHistory();
  const { dispatch } = useContext(CommunitiesContext);
  const [loadingStatus, setLoadingStatus] = useState(STATUSES.IDLE);

  useEffect(() => {
    const loadCommunities = async () => {
      setLoadingStatus(STATUSES.LOADING);
      try {
        const response = await getCommunitiesForLoggedUser({ with_chat_id: chatId });
        const assignedCommunities = response?.results;

        if (assignedCommunities.length > 0) {
          const sorted = assignedCommunities.sort((p1, p2) => (p1.name < p2.name ? -1 : 1));

          dispatch({ type: ACTIONS.ADD_ASSIGNED_COMMUNITIES, payload: { communities: sorted } });
          history.push(`/chats/${chatId}/communities/${sorted[0].id}`);
          setLoadingStatus(STATUSES.LOADED);
        }
      } catch (e) {
        console.error(e);
        setLoadingStatus(STATUSES.FAILURE);
      }
    };
    loadCommunities();
  }, [chatId, dispatch, history]);

  return (
    <CommunitysWrapper>
      <PageHeader
        title={`Related ${capitalize(t('communities'))}`}
        subtitle="Define where your chat is shown"
        block
        margin={PAGE_HEADER_MARGIN.SMALL}
      />
      <Dimmer active={loadingStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <If condition={loadingStatus === STATUSES.LOADED}>
        <Grid>
          <Grid.Row>
            <Switch>
              <Route exact path="/chats/:chatId/communities/assign">
                <Grid.Column width={4}>
                  <CommunitiesMenu />
                </Grid.Column>
                <Grid.Column width={12}>
                  <AssignCommunity />
                </Grid.Column>
              </Route>
              <Route path={['/chats/:chatId/communities/:communityId', '/chats/:chatId/communities']}>
                <Grid.Column width={4}>
                  <CommunitiesMenu />
                </Grid.Column>
                <Grid.Column width={12}>
                  <Community />
                </Grid.Column>
              </Route>
            </Switch>
          </Grid.Row>
        </Grid>
      </If>

      <If condition={loadingStatus === STATUSES.FAILURE}>
        <Message negative>
          <Message.Header>We're sorry we can't fetch your {t('communities')}</Message.Header>
          <p>Something went wrong and we couldn't load your {t('communities')}. Please try again later.</p>
        </Message>
      </If>
    </CommunitysWrapper>
  );
};
