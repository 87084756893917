import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UserManagementList from './UserManagementList';
import UserManagementCreate from './UserManagementCreate';
import UserManagementEdit from './UserManagementEdit';

const UserManagement = () => (
  <Switch>
    <Route path="/admin/user-management" component={UserManagementList} exact />
    <Route path="/admin/user-management/user" component={UserManagementCreate} exact />
    <Route path="/admin/user-management/user/:userId" component={UserManagementEdit} exact />
  </Switch>
);

export default UserManagement;
