import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Forbidden from 'components/403';
import { WithTopBar } from 'components/WithTopBar';
import { ConversationsRoutes } from './conversations';
import { Analytics } from './analytics';
import { OverviewRoot } from './overview';
import { Login, Signup, OtpReset, ResetPassword } from './auth';
import { MessagesRoot } from './messages';
import { StartUp } from './StartUp';
import { Onboarding } from './onboarding';
import { CommunitiesDashboard } from './communities';
import { Settings } from './settings';
import { Nurture } from './nurture';
import { MemberScheduledMessages } from './nurture/ScheduledMessages/MemberScheduledMessages';
import { Admin } from './admin';
import { TourRoutes } from './tours/TourRoutes';
import { AuthRoute, PublicRoute, UserRoute } from './common/routes';
import { ChatsRoutes } from './chats/ChatsRoutes';
import { OwnersDashboard } from './owners-dashboard';
import { USER_TYPES } from '../constants';
import { SsoUnauthorized } from './SsoUnauthorized';
import { IndexRoute } from './IndexRoute';
import UnitLevelPricing from './unitLevelPricing';
import { StatusRoutes } from './common/status/StatusRoutes';
import Applicants from './applicants';
import SupportMode from './support-mode';
import { CONVERSATION_TYPES } from './messages/constants';
import Management from './management';
import { Onboarding as SelfServiceOnboarding } from './self-service-onboarding';
import { Advanced } from './advanced';
import Unsubscribe from './unsubscribe/Unsubscribe';
import { MyProfileRoutes } from './myprofile';
import { ExperimentsRoot } from './experiments';
import { EmailAuthentication } from './advanced/MarketingEmail/EmailAuthentication';
import UserManagement from './user-management';

const Routes = () => (
  <Switch>
    <PublicRoute path="/status" component={StatusRoutes} />
    <PublicRoute path="/sso-unauthorized" component={SsoUnauthorized} />
    <PublicRoute path="/otp-reset" component={OtpReset} />
    <PublicRoute path="/self-service-onboarding" component={SelfServiceOnboarding} />
    <PublicRoute path="/signup" component={Signup} />
    <PublicRoute path="/login" component={Login} />
    <PublicRoute path="/tours/:tourHash" component={TourRoutes} />
    <PublicRoute path="/public-report/:public_id" component={Analytics} />
    <PublicRoute path="/reset-password/:uidb64/:token" component={ResetPassword} />
    <PublicRoute path="/unit-level-pricing/:token" component={UnitLevelPricing} />
    <PublicRoute path="/unsubscribe/:token" component={Unsubscribe} />
    <PublicRoute path="/settings/email-integrations/oauth/:provider" component={EmailAuthentication} />
    <AuthRoute path="/startup" component={StartUp} />
    <AuthRoute path="/conversations" component={ConversationsRoutes} />
    <UserRoute path="/messages/archived/:leadId?" allowedTypes={[USER_TYPES.MEMBER]}>
      <MessagesRoot conversationType={CONVERSATION_TYPES.ARCHIVED} />
    </UserRoute>
    <UserRoute path="/messages/:leadId?" allowedTypes={[USER_TYPES.MEMBER]}>
      <MessagesRoot conversationType={CONVERSATION_TYPES.ONGOING} />
    </UserRoute>
    <UserRoute path="/scheduled-messages" allowedTypes={[USER_TYPES.MEMBER]}>
      <MemberScheduledMessages />
    </UserRoute>
    {/* for backward compatibility - old links and bookmarks */}
    <Route path="/engagement">
      <Redirect to="/stats" />
    </Route>
    <UserRoute path="/stats" component={Analytics} allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.REGIONAL]} />
    <UserRoute path="/overview" component={OverviewRoot} allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.REGIONAL]} />
    <UserRoute path="/chats" component={ChatsRoutes} allowedTypes={[USER_TYPES.REGULAR]} />
    <UserRoute
      path="/communities"
      component={CommunitiesDashboard}
      allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.REGIONAL]}
    />
    <UserRoute path="/onboarding" component={Onboarding} allowedTypes={[USER_TYPES.REGULAR]} hideNavigation={true} />
    <UserRoute
      path="/nurture/*" // For backward compatibility, for old links and bookmarks to work
      component={Nurture}
      allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.MEMBER, USER_TYPES.REGIONAL]}
    />
    <UserRoute
      path="/messaging"
      component={Nurture}
      allowedTypes={[USER_TYPES.REGULAR, USER_TYPES.MEMBER, USER_TYPES.REGIONAL]}
    />
    <AuthRoute path="/settings" component={Settings} />
    <AuthRoute path="/advanced" component={Advanced} />
    <AuthRoute path="/my-profile" component={MyProfileRoutes} hideNavigation />
    <UserRoute path="/admin" component={Admin} allowedTypes={[USER_TYPES.LABELING]} />
    <UserRoute
      path="/owners-dashboard/:managementCompanyId?/:communityId?"
      component={OwnersDashboard}
      allowedTypes={[USER_TYPES.OWNER]}
    />
    <UserRoute path="/experiments" component={ExperimentsRoot} allowedTypes={[USER_TYPES.REGULAR]} />
    <AuthRoute path="/job-applicants" component={Applicants} />
    <AuthRoute path="/support-mode" component={SupportMode} />
    <AuthRoute path="/management" component={Management} />
    <PublicRoute path="/forbidden" component={Forbidden} />
    <PublicRoute path="/" component={IndexRoute} />
    <Redirect to="/startup" />
  </Switch>
);

export default Routes;
