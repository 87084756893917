import React, { Suspense } from 'react';
import { Slide } from 'react-toastify';

import { StyledToastContainer } from './StyledToastContainer';
import { Segment } from './Segment';
import { Smartlook } from './Smartlook';
import { Dashboard } from './Dashboard';
import { GlobalContextProviders } from './GlobalContextProviders';
import { Loader } from './lib';

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <GlobalContextProviders>
        <Segment />
        <Smartlook />
        <Dashboard />
        <StyledToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          transition={Slide}
        />
      </GlobalContextProviders>
    </Suspense>
  );
}

export default App;
