import React from 'react';

import { Module } from './Module';
import { Role } from './Role';
import { Message } from './Message';
import { MultiOptionButton } from './MultiOptionButton';

export const MultiOption = ({ node }) => {
  const { module } = node;
  const { text, answers } = module;

  return (
    <Module node={node}>
      {text && <Message>{text}</Message>}
      <Role module={module} />
      {answers.map((a, i) => (
        <MultiOptionButton key={i}>{a.text}</MultiOptionButton>
      ))}
    </Module>
  );
};
