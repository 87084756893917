import React, { useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { If } from 'components/If';
import { capitalize } from 'utils/helpers';
import { ConversationContext } from '../ConversationContext';
import { SIDEBAR_TAB } from '../constants';

const NavigationItem = styled.span`
  display: flex;
  padding-top: 20px;
  padding-bottom: 12px;
  margin-left: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }

  &.active {
    border-bottom: 1px solid var(--text-color);
  }
`;

const Navigation = styled.div`
  display: flex;

  &.expanded {
    width: 95%;
    margin-bottom: 30px;
    ${NavigationItem} {
      flex-grow: 1;
    }
  }
`;

export const ConversationSidebarNavigationItems = () => {
  const { t } = useTranslation();
  const { isViewBreakpoint, sidebarExpanded, sidebarActiveTab, setSidebarActiveTab, selectedConversation } =
    useContext(ConversationContext);
  const { phone_calls } = selectedConversation || {};
  const navigationClasses = classNames({ expanded: sidebarExpanded && isViewBreakpoint });

  return (
    <Navigation className={navigationClasses}>
      <NavigationItem
        className={classNames({ active: sidebarActiveTab === SIDEBAR_TAB.COMMUNITY })}
        onClick={() => setSidebarActiveTab(SIDEBAR_TAB.COMMUNITY)}
      >
        {capitalize(t('community'))}
      </NavigationItem>
      <NavigationItem
        className={classNames({ active: sidebarActiveTab === SIDEBAR_TAB.LEAD })}
        onClick={() => setSidebarActiveTab(SIDEBAR_TAB.LEAD)}
      >
        Details
      </NavigationItem>
      <If condition={phone_calls?.length}>
        <NavigationItem
          className={classNames({ active: sidebarActiveTab === SIDEBAR_TAB.CALLS })}
          onClick={() => setSidebarActiveTab(SIDEBAR_TAB.CALLS)}
        >
          Calls
        </NavigationItem>
      </If>
      <NavigationItem
        className={classNames({ active: sidebarActiveTab === SIDEBAR_TAB.PROCESSING })}
        onClick={() => setSidebarActiveTab(SIDEBAR_TAB.PROCESSING)}
      >
        Processing
      </NavigationItem>
    </Navigation>
  );
};
