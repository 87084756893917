import React from 'react';
import styled from 'styled-components';
import { Icon, Modal, Button } from 'semantic-ui-react';
import { formatAnyDate } from 'components/utils';

const StyledModal = styled(Modal)`
  &&& {
    top: 20%;
    width: 60% !important;
  }
`;

const ModalContent = styled(Modal.Content)`
  &&& {
    display: flex;
    padding: 2em;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    background-color: #f7f8fa;
    border-radius: 10px;
  }

  p {
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: right;
  }

  strong {
    font-weight: bold;
    color: #444;
    margin-right: 10px;
  }
`;

const ScrollableTextbox = styled.div`
  height: 300px;
  overflow-y: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  flex: 2;
  margin-left: 20px;
`;

const InfoSection = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const ModalActions = styled(Modal.Actions)`
  &&& {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
  }
`;

const CRMAdminLogsLeadsModal = ({ isOpen, onClose, leadDetails, rawResult }) => {
  if (!leadDetails) return null;

  return (
    <StyledModal open={isOpen} onClose={onClose} size="small" centered>
      <Modal.Header>{`${leadDetails.lead.firstName} ${leadDetails.lead.lastName}`}</Modal.Header>
      <ModalContent>
        <InfoSection>
          <p>
            <strong>Email:</strong> {leadDetails.lead.email}
          </p>
          <p>
            <strong>Phone:</strong> {leadDetails.lead.phone}
          </p>
          <p>
            <strong>Community:</strong> {leadDetails.community?.name ?? '-'}
          </p>
          <p>
            <strong>User Email:</strong> {leadDetails.user?.email ?? '-'}
          </p>
          <p>
            <strong>CRM Type:</strong> {leadDetails.crmType?.name ?? '-'}
          </p>
          <p>
            <strong>Parsable:</strong>
            {leadDetails.isParsable ? (
              <Icon size="small" name="check circle" color="green" />
            ) : (
              <Icon size="small" name="times circle" color="red" />
            )}
          </p>
          <p>
            <strong>Valid:</strong>
            {leadDetails.isValid === true ? (
              <Icon size="small" name="check circle" color="green" />
            ) : leadDetails.isValid === false ? (
              <Icon size="small" name="times circle" color="red" />
            ) : (
              '-'
            )}
          </p>
          <p>
            <strong>Created On:</strong> {formatAnyDate(leadDetails.createdOn, 'MMMM d, yyyy')}
          </p>
        </InfoSection>
        <ScrollableTextbox>{rawResult || 'No raw result available'}</ScrollableTextbox>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} primary>
          Close
        </Button>
      </ModalActions>
    </StyledModal>
  );
};

export default CRMAdminLogsLeadsModal;
