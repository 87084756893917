import React from 'react';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';

import { WithTopBar } from 'components/WithTopBar';
import { ConversationMain } from 'components/conversation/ConversationMain';
import { VIEW_TYPE } from 'components/conversation/constants';
import EmailPreviewModal from 'components/conversation/chat/EmailPreviewModal';
import { Conversations } from './Conversations';

export const ConversationsRoutes = () => (
  <Switch>
    <Route exact path="/conversations">
      <WithTopBar>
        <Conversations />
      </WithTopBar>
    </Route>
    <Route path="/conversations/:leadId">
      <WithTopBar contentClasses={classNames('contained')}>
        <ConversationMain viewType={VIEW_TYPE.CONVERSATION} />
      </WithTopBar>
    </Route>
  </Switch>
);
