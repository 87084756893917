import React from 'react';

import { If } from 'components/If';
import { useUserContext } from 'components/UserContext';
import { NavigationSection } from './NavigationSection';
import { NavigationItem } from './NavigationItem';

export const UserNavigationStaff = () => {
  const { getSessionOriginalUser } = useUserContext();
  const user = getSessionOriginalUser();

  return (
    <NavigationSection>
      <NavigationItem to="/admin/client-management" label="Client management" icon="people-outlined" />
      <NavigationItem to="/admin/features" label="Features Overview" icon="checklist-outlined" />
      <NavigationItem to="/admin/dashboard" label="Dashboard" icon="stacked_bar_chart-outlined" />
      <NavigationItem to="/admin/questions-labeling" label="Questions labeling" icon="smart_toy-outlined" />
      <NavigationItem to="/admin/instant-responses" label="Instant responses" icon="auto_awesome-outlined" />
      <NavigationItem to="/admin/answers" label="Manage Answers" icon="keyboard" />
      <NavigationItem to="/admin/stats" label="System stats" icon="donut_large-outlined" />
      <NavigationItem to="/admin/seo-analyses" label="SEO Analyses" icon="travel_explore-outlined" />
      <NavigationItem to="/admin/custom-email-subjects" label="Custom Email Subjects" icon="email-outlined" />
      <NavigationItem to="/admin/scheduled-messages" label="Scheduled Messages" icon="message-outlined" />
      <NavigationItem to="/admin/events" label="Events" icon="update-outlined" />
      <NavigationItem to="/admin/web-forms" label="WebForms" icon="view_quilt-outlined" />

      <If condition={user.is_superuser}>
        <NavigationItem to="/admin/email-templates" label="Email Templates" icon="email-outlined" />
      </If>
      <NavigationItem to="/admin/user-management" label="User Management" icon="view_quilt-outlined" />
      <NavigationItem to="/admin/crm-admin-logs/statistics" label="CRM Admin Logs" icon="history-outlined" />
    </NavigationSection>
  );
};
