import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { Button, Icon } from 'components/lib';
import { MAIN_GOAL_OPTIONS } from '../constants';
import { NextStep } from './NextStep';

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.4286rem;
  margin: 4.2857rem 0;
`;

const Option = styled.div`
  width: 300px;
  height: 72px;
  padding: 15px 30px 15px 20px;
  border-radius: 36px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d7d7d7;
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: var(--hover-white);
  }
  & p {
    margin: 0 0 0 10px;
    font-family: var(--font-family);
    font-size: 14px;
    color: #222;
    text-align: left;
    padding-top: 3px;
    white-space: pre-line;
  }
  &.active {
    box-shadow: 0 5px 10px 0 rgba(39, 90, 255, 0.1);
    border: solid 1px #275aff;
    background-color: #f2f5fb;
    p {
      color: #275aff;
    }
  }
`;

export const MainGoalForm = () => {
  const { values, setFieldValue, handleSubmit, isValid } = useFormikContext();

  const handleOptionSelect = (value, setFieldValue) => {
    setFieldValue('mainGoal', value);
  };

  const disabled = values.mainGoal.length === 0 || !isValid;

  return (
    <>
      <OptionsContainer>
        {MAIN_GOAL_OPTIONS.map((option, i) => (
          <Option
            key={i}
            onClick={() => handleOptionSelect(option.value, setFieldValue)}
            className={classNames({ active: values.mainGoal === option.value })}
          >
            <Icon
              name={option.icon}
              variant="outlined"
              color={values.mainGoal === option.value ? '#275aff' : '#222222'}
            />
            <p>{option.text}</p>
          </Option>
        ))}
      </OptionsContainer>
      <Button size="big" fluid primary color="blue" type="submit" onClick={handleSubmit} disabled={disabled}>
        Next Step
      </Button>
      <NextStep disabled={disabled} />
    </>
  );
};
