import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const BUBBLE_BG = {
  VSA: 'linear-gradient(to left, #284ad5, #2141C5 0%)',
  CW: 'linear-gradient(to left, #284ad5, #5F5FFF 0%)',
  Survey: 'var(--salmon);',
  IVR: 'linear-gradient(to left, #284ad5, #0AB776 0%)',
};

const Container = styled.div`
  display: flex;
  padding: 22px 20px 17px 20px;
  border-radius: 20px 20px 0 20px;
  box-shadow: 0 3px 5px 0 #d8d8d8;
  border: solid 1px var(--line-standard);
  background: linear-gradient(to left, #284ad5, #4642ee 0%);
  background: ${({ vsaType }) => BUBBLE_BG[vsaType]};
  align-self: flex-end;
  max-width: 450px;
  justify-content: flex-start;
  img {
    max-width: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 13px;
    margin: 0;
    font-family: var(--font-family);
    line-height: 1.4285em;
    font-weight: 400;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 100%;
`;

const Text = styled(ReactMarkdown)`
  font-family: var(--font-family);
  font-size: 13px;
  line-height: 1.38;
  color: var(--white);
  white-space: pre-wrap;
`;

const Name = styled.span`
  font-family: var(--font-family-bold);
  font-size: 14px;
  line-height: 1.43;
  color: var(--white);
`;

export const VSAMessage = ({ text, vsaType }) => {
  return (
    <Container vsaType={vsaType}>
      <Name>{vsaType}</Name>
      <Content>
        <Text>{text}</Text>
      </Content>
    </Container>
  );
};
