import { useCallback } from 'react';
import { useImmerReducer } from 'use-immer';

import { makeTraffic } from './useBenchmarks';

const reducer = (draft, action) => {
  switch (action.type) {
    case 'SET_MOVE_IN_KEYWORDS': {
      const { internal, industry } = action.payload;
      const {
        performing: internalPerforming,
        no_move_ins: internalNoMoveIns,
        provider_profiles_count: internalProviderProfilesCount,
      } = internal;
      const {
        performing: industryPerforming,
        no_move_ins: industryNoMoveIns,
        provider_profiles_count: industryProviderProfilesCount,
      } = industry;

      draft.performing = makeTraffic({
        internal: internalPerforming,
        internalProviderProfilesCount,
        industry: industryPerforming,
        industryProviderProfilesCount,
        key: 'keyword',
      });
      draft.no_move_ins = makeTraffic({
        internal: internalNoMoveIns,
        internalProviderProfilesCount,
        industry: industryNoMoveIns,
        industryProviderProfilesCount,
        key: 'keyword',
      });

      break;
    }
    default:
      console.warn(`Unhandled action ${JSON.stringify(action)}`);
      break;
  }

  return draft;
};

const useMoveInKeywords = () => {
  const [state, dispatch] = useImmerReducer(reducer, {
    performing: undefined,
    no_move_ins: undefined,
  });

  const setMoveInKeywords = useCallback((payload) => dispatch({ type: 'SET_MOVE_IN_KEYWORDS', payload }), [dispatch]);

  return { state, setMoveInKeywords };
};

export default useMoveInKeywords;
