import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { setNestedObjectValues, useFormikContext } from 'formik';

import { getMappingTargets } from 'apis';
import { InputField, CheckboxField } from 'components/common';
import { Table, Dropdown, Button, Icon } from 'components/lib';
import { FieldInfo } from './FieldInfo';
import { LeadPropModal } from './LeadPropModal';

const Wrapper = styled.div`
  .ui .field {
    label.map-to {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        gap: 0.5ch;
        cursor: pointer;
        color: #777;

        &:hover {
          color: #333;
        }
      }
    }
  }

  td > .field {
    min-width: 350px;
  }
`;

export const FieldsTable = ({ mappingCandidate }) => {
  const { values, validateForm, setTouched } = useFormikContext();
  const [showLeadPropModal, setShowLeadPropModal] = useState(false);
  const [mappingTargets, setMappingTargets] = useState([]);

  const fields = mappingCandidate ? Object.values(mappingCandidate.form.fields) : [];
  const mappingTargetsOptions = mappingTargets.map((mt, i) => ({
    key: i + 1,
    text: mt.label,
    value: mt.path,
  }));
  mappingTargetsOptions.unshift({ key: 0, text: 'Select a field', value: '' });

  const parseTypes = mappingCandidate?.parse_types?.map((mt, i) => ({
    key: i,
    text: mt.label,
    value: mt.parse_type,
  }));

  useEffect(() => {
    const triggerValidation = async () => {
      const validationErrors = await validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setTouched(setNestedObjectValues(validationErrors, true));
      }
    };

    if (mappingCandidate) {
      triggerValidation();
    }
  }, [mappingCandidate, values]);

  const fetchMappingTargets = useCallback(async () => {
    const { results } = await getMappingTargets();
    setMappingTargets(results);
  }, []);

  useEffect(() => {
    fetchMappingTargets();
  }, []);

  const handleModalClose = (created) => {
    if (created) {
      fetchMappingTargets();
    }
    setShowLeadPropModal(false);
  };

  return (
    <Wrapper>
      <Table size="small" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={6}>Field</Table.HeaderCell>
            <Table.HeaderCell width={6}>Lead Attribute</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {mappingCandidate &&
            values.mappings.map((mapping, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <FieldInfo field={fields[mapping.index]} name={`mappings[${i}].field_name`} />
                </Table.Cell>
                <Table.Cell>
                  <InputField
                    control={Dropdown}
                    basic
                    fluid
                    selection
                    name={`mappings[${i}].map_to`}
                    options={mappingTargetsOptions}
                    disabled={mapping.ignored}
                    label={
                      <label className="map-to">
                        Map to
                        <button
                          type="button"
                          onClick={() => {
                            setShowLeadPropModal(true);
                          }}
                        >
                          <Icon name="add" size={15} />
                          Add a new Mapping Target
                        </button>
                      </label>
                    }
                  />

                  <InputField
                    control={Dropdown}
                    basic
                    fluid
                    selection
                    name={`mappings[${i}].parse_as`}
                    options={parseTypes}
                    disabled={mapping.ignored}
                    label="Parse as"
                  />

                  <div className="flags">
                    <CheckboxField name={`mappings[${i}].required`} label="Required" disabled={mapping.ignored} />
                    <CheckboxField name={`mappings[${i}].ignored`} label="Ignored" />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <LeadPropModal isOpen={showLeadPropModal} mappingTargets={mappingTargets} onClose={handleModalClose} />
    </Wrapper>
  );
};
