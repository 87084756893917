import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Button } from 'semantic-ui-react';

import { createCRMSettings, getCRMSettings, removeCRMSettings, updateCRMSettings } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PageHeader, PAGE_HEADER_MARGIN, PAGE_HEADER_PADDING } from 'components/common';
import { STATUSES } from '../../../constants';
import CRMSettingsList from './CRMSettingsList';
import CRMSettingsManageModal from './CRMSettingsManageModal';
import CRMSettingsRemoveModal from './CRMSettingsRemoveModal';
import { useCRM, setCRMSettings, setLoaderStatus } from './context';

const CRMSettings = () => {
  const [{ userId, crmSettings, loaderStatus }, dispatch] = useCRM();
  const [createLoaderStatus, setCreateLoaderStatus] = useState(STATUSES.IDLE);
  const [deleteLoaderStatus, setDeleteLoaderStatus] = useState(STATUSES.IDLE);
  const [selectedCRMSettings, setSelectedCRMSettings] = useState();
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [openCRMSettingsModal, setOpenCRMSettingsModal] = useState();

  const fetchCRMSettings = useCallback(
    async (userId) => {
      try {
        dispatch(setLoaderStatus(STATUSES.LOADING));
        const results = await getCRMSettings(userId);

        dispatch(setCRMSettings(results));
        dispatch(setLoaderStatus(STATUSES.LOADED));
      } catch (e) {
        toast.error(parseErrorResponse(e));
        dispatch(setLoaderStatus(STATUSES.FAILURE));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (userId) {
      fetchCRMSettings(userId);
    }
  }, [fetchCRMSettings, userId]);

  const handleOpenAddCRMSettings = () => {
    setOpenCRMSettingsModal(true);
  };

  const handleCloseCRMSettingsModal = () => {
    setOpenCRMSettingsModal(false);
    setSelectedCRMSettings(null);
  };

  const handleAddCRMSettings = async (data, resetForm) => {
    try {
      setCreateLoaderStatus(STATUSES.LOADING);
      await createCRMSettings(userId, { ...data, user: userId });

      toast.success('CRM settings created successfully');
      handleCloseCRMSettingsModal();
      resetForm();
      fetchCRMSettings(userId);
      setCreateLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e, 'Unable to create CRM settings.'));
      setCreateLoaderStatus(STATUSES.FAILURE);
    }
  };

  const handleOpenEditCRMSettings = (crmSettings) => {
    setSelectedCRMSettings(crmSettings);
    setOpenCRMSettingsModal(true);
  };

  const handleEditCRMSettings = async (data) => {
    try {
      setCreateLoaderStatus(STATUSES.LOADING);
      const { id } = selectedCRMSettings;

      await updateCRMSettings(userId, id, { ...data, user: userId });

      toast.success('CRM settings updated successfully');
      setSelectedCRMSettings(null);
      handleCloseCRMSettingsModal();
      fetchCRMSettings(userId);
      setCreateLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e, 'Unable to update CRM settings.'));
      setCreateLoaderStatus(STATUSES.FAILURE);
    }
  };

  const handleOpenRemoveConfirm = (crmSettingsId) => {
    setConfirmRemove(true);
    setSelectedCRMSettings(crmSettingsId);
  };

  const handleCloseRemoveConfirm = () => {
    setConfirmRemove(false);
    setSelectedCRMSettings(null);
  };

  const handleRemoveCRMSettings = async () => {
    try {
      setDeleteLoaderStatus(STATUSES.LOADING);
      const { id } = selectedCRMSettings;
      await removeCRMSettings(userId, id);

      toast.success('CRM settings removed successfully');
      fetchCRMSettings(userId);
      setConfirmRemove(false);
      setSelectedCRMSettings(null);
      setDeleteLoaderStatus(STATUSES.LOADED);
    } catch (e) {
      console.error(e);
      toast.error(parseErrorResponse(e, 'Unable to remove CRM settings.'));
      setDeleteLoaderStatus(STATUSES.FAILURE);
    }
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <PageHeader
        actions={<Button primary content="Add CRM Settings" onClick={handleOpenAddCRMSettings} />}
        margin={PAGE_HEADER_MARGIN.NONE}
        padding={PAGE_HEADER_PADDING.NONE}
      />

      <CRMSettingsList
        crmSettings={crmSettings}
        onRemoveCRMSettings={handleOpenRemoveConfirm}
        onEditCRMSettings={handleOpenEditCRMSettings}
      />

      <CRMSettingsManageModal
        open={openCRMSettingsModal}
        onClose={handleCloseCRMSettingsModal}
        crmSettings={selectedCRMSettings}
        onAddCRMSettings={handleAddCRMSettings}
        onEditCRMSettings={handleEditCRMSettings}
        loading={createLoaderStatus === STATUSES.LOADING}
      />

      <CRMSettingsRemoveModal
        crmSettings={selectedCRMSettings}
        open={confirmRemove}
        onCancel={handleCloseRemoveConfirm}
        onConfirm={handleRemoveCRMSettings}
        loading={deleteLoaderStatus === STATUSES.LOADING}
      />
    </>
  );
};

export default CRMSettings;
