import React from 'react';
import styled from 'styled-components';
import { Delta } from './Delta';

const PercentCellWrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: ${(p) => p.minWidth};
  text-align: right;

  .answer-percentage-bar-bg {
    background-color: #1f57bd33;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    height: 4px;

    .answer-percentage-bar {
      background-color: #1f57bdaa;
      position: absolute;
      left: 0;
      height: 100%;
    }
  }

  .category {
    margin-left: 5px;
  }
`;

export const PercentCell = ({ value, delta = null, category = null, header = null, cellWidth = null }) => {
  const val = value ? `${value.toFixed(1)}%` : '0.0%';
  const width = value ? Math.min(value, 100) : 0;

  const minWidth = cellWidth || (delta !== null ? '12ch' : '6ch');
  return (
    <PercentCellWrapper minWidth={minWidth}>
      {header}
      {val}&nbsp;
      {category && <span className="category">{category}</span>}
      {delta !== null && (
        <>
          (<Delta value={delta} formatter={(v) => `${v?.toFixed(1)}%`} />)
        </>
      )}
      <div className="answer-percentage-bar-bg">
        <div
          className="answer-percentage-bar"
          style={{
            width: `${width}%`,
          }}
        />
      </div>
    </PercentCellWrapper>
  );
};
