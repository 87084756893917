import React from 'react';
import styled from 'styled-components';

import { Dropdown } from 'components/lib';

const StyledDropdown = styled(Dropdown)`
  &&& {
    width: ${(props) => `${props.width}px`};
  }
`;

export const SettingsDropdown = ({ value, options, placeholder, onChange, width }) => {
  return (
    <StyledDropdown
      value={value}
      options={options}
      selection
      placeholder={placeholder}
      onChange={onChange}
      width={width}
    />
  );
};
