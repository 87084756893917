import React from 'react';
import styled from 'styled-components';

import { Node } from './Node';

const Wrapper = styled.div`
  .ui.header:first-child {
    margin-top: 0;
  }
`;

export const ChatFlowTree = ({ chatInteractions = [] }) => {
  const _nodes = {};

  chatInteractions.forEach((d) => {
    _nodes[d.source] = {
      ...d,
      adjecent: [...new Set(chatInteractions.filter((_d) => _d.source === d.source).map((a) => a.target))],
    };
  });

  const constructTree = (rootNode) => {
    if (!rootNode) {
      return null;
    }

    const nodes = [];
    const edges = [];
    const jumps = [];

    const bfs = (node) => {
      const queue = [node];
      const visited = [node.source];
      while (queue.length > 0) {
        const v = queue.shift();
        nodes.push(v.source);

        v.adjecent.forEach((adjecentNodeNumber) => {
          if (!visited.includes(adjecentNodeNumber)) {
            const w = _nodes[adjecentNodeNumber];
            if (w) {
              edges.push([v.source, adjecentNodeNumber]);
              queue.push(w);
              visited.push(adjecentNodeNumber);
            }
          } else {
            jumps.push([v.source, adjecentNodeNumber]);
          }
        });
      }
    };

    bfs(rootNode);

    return { nodes, edges, jumps };
  };

  const tree = constructTree(_nodes[1]);
  const root = chatInteractions.find((d) => d.source === 1);

  const answers = chatInteractions.filter((d) => d.source === 1);
  const answerCount = answers.reduce((r, v) => r + v.count, 0);

  return (
    tree && (
      <Wrapper>
        <Node node={root} data={chatInteractions} tree={tree} parentCount={answerCount} />
      </Wrapper>
    )
  );
};
