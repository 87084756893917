import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getMetrics } from 'apis';
import { ReportComponent } from 'components/common/analytics';
import { formatDuration } from 'components/utils';
import queryConstants from 'utils/queries/constants';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';
import PrevStats from '../common/PrevStats';
import { useMessagesResponseTimes } from './MessagesResponseTimes';

const metrics = [
  'messages.sales_assistant.avg_response_time',
  'messages.sales_assistant.first.avg_response_time',
  'messages.sales_assistant.local_working_hours.avg_response_time',
  'messages.sales_assistant.further_working_hours.avg_response_time',
];

const MessagesResponseTimesPrevPeriod = () => {
  const { saleAssistantFilters: filters, getPreviousDateRange } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };

  const prevDateRange = getPreviousDateRange();
  const prevFilters = { ...maFilters, ...prevDateRange };

  const respTimes = useMessagesResponseTimes(maFilters);
  const prevRespTimes = useQuery(
    [queryConstants.ADMIN_METRICS, { ...prevFilters, metrics }],
    () => {
      return getMetrics(metrics, prevFilters);
    },
    { placeholderData: {} }
  );

  const prevData = useMemo(() => {
    return [
      {
        label: 'All Messages',
        value: respTimes.data['messages.sales_assistant.avg_response_time']?.value,
        prevValue: prevRespTimes.data['messages.sales_assistant.avg_response_time']?.value,
        lessIsBetter: true,
        formatter: formatDuration,
      },
      {
        label: 'First Lead Messages',
        value: respTimes.data['messages.sales_assistant.first.avg_response_time']?.value,
        prevValue: prevRespTimes.data['messages.sales_assistant.first.avg_response_time']?.value,
        lessIsBetter: true,
        formatter: formatDuration,
      },
      {
        label: 'Local Working Hours Messages',
        value: respTimes.data['messages.sales_assistant.local_working_hours.avg_response_time']?.value,
        prevValue: prevRespTimes.data['messages.sales_assistant.local_working_hours.avg_response_time']?.value,
        lessIsBetter: true,
        formatter: formatDuration,
      },
      {
        label: 'Further Working Hours Messages',
        value: respTimes.data['messages.sales_assistant.further_working_hours.avg_response_time']?.value,
        prevValue: prevRespTimes.data['messages.sales_assistant.further_working_hours.avg_response_time']?.value,
        lessIsBetter: true,
        formatter: formatDuration,
      },
    ];
  }, [respTimes, prevRespTimes]);

  return (
    <ReportComponent
      title={`Compared to previous ${prevDateRange.day_span} days`}
      tooltip={`Changes in messages metrics compared to previous time period, from ${prevDateRange.start_date} to ${prevDateRange.end_date}`}
      contentMargins="20px 0"
      contentHeight="50px"
    >
      <PrevStats data={prevData} />
    </ReportComponent>
  );
};

export default MessagesResponseTimesPrevPeriod;
