import React from 'react';
import { Form } from 'semantic-ui-react';

import { MODULE_TYPES, MODULE_TYPES_OPTIONS } from '../../constants';
import { SelectField } from '../../../../common';

const ModuleFormType = () => (
  <Form.Field>
    <label>Type</label>
    <SelectField
      fluid
      name="module.module_type"
      options={MODULE_TYPES_OPTIONS}
      placeholder="Module type..."
      size="small"
    />
  </Form.Field>
);

export default ModuleFormType;
