import styled from 'styled-components';

export const SidebarItem = styled.div`
  position: relative;
  top: -2px;
  margin: 0 -1px;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  color: #333;
  background-color: ${(p) => (p.active ? 'white' : p.expanded ? '#f5f5f5' : '#f9fafb')};
  border: 1px solid ${(p) => (p.active || p.expanded ? '#e4e4e4' : 'transparent')};

  &:hover {
    background-color: white;
    color: #000;
    border-color: #e4e4e4;
    cursor: pointer;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.25rem;
    background-color: ${(p) => (p.active ? '#1f57bd' : 'transparent')};
  }
`;

SidebarItem.SubItem = styled(SidebarItem)`
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
  color: #777;

  &:hover {
    background-color: #f9fafb;
    border: 1px solid ${(p) => (p.active ? '#e4e4e4' : 'transparent')};
  }
`;
