import React, { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { Form, Button } from 'components/lib';
import { InputField } from 'components/common';
import { CommunitiesContext } from '../../common/communities/CommunitiesContext';
import { CommunityAssetsContext } from '../CommunityAssetsContext';

const Container = styled.div`
  display: flex;

  .field {
    flex-grow: 1;
    padding-right: 10px;
  }
`;
const UserCommunityDefaultFloorPlanForm = () => {
  const { state } = useContext(CommunitiesContext);
  const { onUpdateAssets } = useContext(CommunityAssetsContext);
  const { activeCommunity } = state;

  const onSubmit = (values) => {
    onUpdateAssets(values);
  };

  const validationSchema = Yup.object({
    floor_plan_url: Yup.string().url().nullable(),
  });

  const initialValues = { floor_plan_url: activeCommunity?.media?.floor_plan_url };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
      {({ dirty, isSubmitting, handleSubmit }) => (
        <Form>
          <Container>
            <InputField name="floor_plan_url" label="Floor plan fallback URL" placeholder="Webpage URL" />
            <Button content="Save" disabled={!dirty} loading={isSubmitting} onClick={handleSubmit} />
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default UserCommunityDefaultFloorPlanForm;
