import React from 'react';

import SaveReportBox from './SaveReportBox';
import SendAsPDFBox from './SendAsPDFBox';

const ReportCommandsBox = ({ isPublicView, user, report, filters }) => (
  <>
    {!isPublicView && <SaveReportBox report={report} filters={filters} />}
    {user && <SendAsPDFBox report={report} filters={filters} user={user} />}
  </>
);

export default ReportCommandsBox;
