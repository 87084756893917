import React, { useEffect, useState } from 'react';
import { Dimmer, Divider, Loader } from 'semantic-ui-react';
import { startOfToday, subDays } from 'date-fns';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getProfileCommunities, getApplicants } from 'apis';
import { DateRangePicker, PageHeader } from 'components/common';
import SearchInput from 'components/SearchInput';
import { formatAnyDate } from 'components/utils';
import { DropDownPicker } from 'components/DropDownPicker';
import { Icon } from 'components/lib';
import { If } from 'components/If';
import FailureMessage from 'components/common/FailureMessage';
import { isInt, useUrlSearchParams } from 'utils/hooks';
import { capitalize } from 'utils/helpers';
import { PAGINATION, STATUSES } from '../../constants';
import ApplicantTable from './ApplicantTable';

const DatePickerExportBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CommunitiesPickerBox = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  & > div {
    flex-basis: 50%;
    flex-grow: 0;
  }
`;

const useApplicantFilters = () => {
  const today = startOfToday();
  const monthAgo = subDays(today, 30);

  const { setUrlSearchParams } = useUrlSearchParams();
  const queryParams = new URLSearchParams(window.location.search);
  const startDate = queryParams.get('startDate') || monthAgo;
  const endDate = queryParams.get('endDate') || today;

  const [filters, setFilters] = useState({
    page: queryParams.get('page') || PAGINATION.PAGE,
    pageSize: queryParams.get('pageSize') || PAGINATION.PAGE_SIZE,
    startDate: formatAnyDate(startDate, 'yyyy-MM-dd'),
    endDate: formatAnyDate(endDate, 'yyyy-MM-dd'),
    communities: queryParams
      .get('communities')
      ?.split(',')
      .filter((id) => isInt(id))
      .map((id) => parseInt(id)),
    search: queryParams.get('search') || '',
  });

  useEffect(() => {
    setUrlSearchParams(filters, true);
  }, [filters]);

  return [filters, setFilters];
};

const buildQueryParams = (filters) => {
  const { page, pageSize, startDate, endDate, communities, search } = filters;
  return {
    page,
    page_size: pageSize,
    start_date: startDate,
    end_date: endDate,
    community_id: communities?.join(','),
    search: search || undefined,
  };
};

const ApplicantList = () => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(STATUSES.IDLE);
  const [applicants, setApplicants] = useState();
  const [totalPages, setTotalPages] = useState();
  const [filters, setFilters] = useApplicantFilters();
  const [communities, setCommunities] = useState([]);

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        setLoader(STATUSES.LOADING);
        const queryParams = buildQueryParams(filters);

        const { results, count } = await getApplicants(queryParams);

        setApplicants(results.map((r) => ({ ...r, created_on: new Date(r.created_on) })));
        setTotalPages(Math.ceil(count / filters.pageSize));
        setLoader(STATUSES.LOADED);
      } catch (e) {
        setLoader(STATUSES.FAILURE);
        toast.error('Unable to fetch job applicants');
      }
    };

    fetchApplicants();
  }, [filters]);

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        const { results } = await getProfileCommunities();

        setCommunities(results);
      } catch (e) {
        toast.error('Unable to fetch communities');
        console.error(e);
      }
    };

    fetchCommunities();
  }, []);

  const handleExport = async () => {
    try {
      setLoader(STATUSES.LOADING);
      const queryParams = {
        ...buildQueryParams(filters),
        export: true,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      return await getApplicants(queryParams);
    } catch (e) {
      console.error(e);
      toast.error('Unable to download CSV file.');
    } finally {
      setLoader(STATUSES.LOADED);
    }
  };

  const handleSearch = (value) => {
    setFilters((f) => ({ ...f, search: value, page: PAGINATION.PAGE }));
  };

  const handleDateFilterChange = ({ startDate, endDate }) => {
    setFilters((f) => ({ ...f, startDate, endDate, page: PAGINATION.PAGE }));
  };

  const handleCommunitiesChange = (communities) => {
    setFilters((f) => ({ ...f, communities, page: PAGINATION.PAGE }));
  };

  const handlePageChange = (page) => {
    setFilters((f) => ({ ...f, page }));
  };

  return (
    <>
      <Dimmer active={loader === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <PageHeader as="h2" title="Job Applicants" margin={PageHeader.MARGIN.MEDIUM} />
      <DatePickerExportBox>
        <DateRangePicker
          dateRange={{ start_date: filters.startDate, end_date: filters.endDate }}
          onFilterChange={handleDateFilterChange}
        />
      </DatePickerExportBox>
      <CommunitiesPickerBox>
        <DropDownPicker
          placeholder={`${capitalize(t('communities'))}`}
          allItemsSelectedText={`All ${t('communities')}`}
          options={communities}
          selectedValues={filters.communities || []}
          onSelect={handleCommunitiesChange}
        />
      </CommunitiesPickerBox>
      <SearchInput
        placeholder={`Search by name, email or ${t('community')}`}
        value={filters.search}
        onSearch={handleSearch}
        loading={loader === STATUSES.LOADING}
        fluid
      />
      <ApplicantTable
        applicants={applicants}
        page={filters.page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        handleExport={handleExport}
      />
      <If condition={loader === STATUSES.FAILURE}>
        <Divider hidden />
        <FailureMessage icon="search" content="Job applicants could not be loaded" />
      </If>
    </>
  );
};
export default ApplicantList;
