import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  createLeadUserReminder,
  getLeadConversationDetails,
  getLeadUserReminder,
  updateLeadUserReminder,
  setConversationRead,
  sendLeadMessage,
  setArchivedConversation,
  setConversationUnread,
  submitLeadCRM,
  submitLeadActionsToCRM,
  getCalendars,
  submitCallToCRM,
  submitTourToCRM,
  deleteLead,
  cancelCampaignScheduledLeadMessages,
  addLeadNote,
  updateLeadNote,
} from 'apis';
import { parseErrorResponse, parseFieldErrors } from 'apis/utils';
import { useBreakpoint } from 'utils/hooks/useBreakpoint';
import { CAMPAIGN_TYPE_NAME } from 'components/nurture/constants';
import { capitalize } from 'utils/helpers';
import { LEAD_USER_REMINDER_TYPE, STATUSES, USER_TYPES } from '../../constants';
import { UserContext } from '../UserContext';
import { useNavigationContext } from '../navigation';
import { DEFAULT_LEAD_USER_REMINDER } from '../admin/InstantResponses/constants';
import { useInterval } from '../../utils/hooks';
import { ConversationArchivedToast } from './ConversationArchivedToast';
import { useMessagesContext } from '../messages/MessagesContext';
import { SIDEBAR_TAB, VIEW_TYPE, VIEW_TYPE_BREAKPOINT } from './constants';
import {
  CONVERSATION_ACTION_QUERY_PARAMETER,
  CONVERSATION_ACTIONS,
  USER_CALENDAR_PROMPT_ACTIONS,
} from './actions/constants';
import { CALENDAR_REQUEST_TYPE, CALENDAR_REQUEST_TYPE_NAME } from '../admin/InstantResponses/calendar/constants';

export const ConversationContext = createContext();

const REFRESH_INTERVAL = 20000;
const MAX_REFRESH_FAILS_BEFORE_BACKOFF = 10;

export const ConversationContextProvider = ({ viewType, onModalClose, onArchived, onUnarchived, ...props }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { leadId } = useParams();

  const { user } = useContext(UserContext);
  const { refreshVisiblePages = () => {}, refreshLoadedPages = () => {} } = useMessagesContext();

  const isViewBreakpoint = useBreakpoint(VIEW_TYPE_BREAKPOINT[viewType]);
  const defaultSidebarTab = viewType === VIEW_TYPE.MESSAGING ? SIDEBAR_TAB.COMMUNITY : SIDEBAR_TAB.LEAD;

  const { loadMessagesSummary } = useNavigationContext();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [messageLoader, setMessageLoader] = useState(STATUSES.IDLE);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [search, setSearch] = useState('');
  const [leadUserReminder, setLeadUserReminder] = useState(DEFAULT_LEAD_USER_REMINDER);
  const [failedRefreshCount, setFailedRefreshCount] = useState(0);
  const [showArchiveSuggestion, setShowArchiveSuggestion] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(!isViewBreakpoint);
  const [sidebarActiveTab, setSidebarActiveTab] = useState(defaultSidebarTab);
  const [conversationCacheStatus, setConversationCacheStatus] = useState(STATUSES.IDLE);
  const [removeOutreachLoading, setRemoveOutreachLoading] = useState(false);
  const [resubmitStatus, setResubmitStatus] = useState(STATUSES.IDLE);
  const [eventType, setEventType] = useState(CALENDAR_REQUEST_TYPE.TOUR);
  const [eventTypeName, setEventTypeName] = useState(CALENDAR_REQUEST_TYPE_NAME(t)[CALENDAR_REQUEST_TYPE.TOUR]);
  const [activeModal, setActiveModal] = useState(null);
  const [deleteLeadLoading, setDeleteLeadLoading] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [calendarsStatus, setCalendarStatus] = useState(STATUSES.IDLE);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [queryModalAction, setQueryModalAction] = useState(null);
  const [nextModalAction, setNextModalAction] = useState(null);
  const [selectedForwardedCall, setSelectedForwardedCall] = useState(null);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);
  const [isNoteLoading, setIsNoteLoading] = useState(false);

  useEffect(() => {
    if (eventType) {
      setEventTypeName(CALENDAR_REQUEST_TYPE_NAME(t)[eventType]);
    }
  }, [eventType]);

  const fetchConversationDetails = async (id, showLoader = true) => {
    if (showLoader) {
      setMessageLoader(STATUSES.LOADING);
    }

    try {
      const response = await getLeadConversationDetails(id);

      setSelectedConversation(response);
      setFailedRefreshCount(0);

      if (showLoader) {
        setMessageLoader(STATUSES.LOADED);
      }
    } catch (e) {
      console.error(e);

      if (showLoader) {
        toast.warning('Unable to load conversation details');
        setMessageLoader(STATUSES.FAILURE);
      }

      setFailedRefreshCount(failedRefreshCount + 1);
    }
  };

  useEffect(() => {
    setSidebarExpanded(!isViewBreakpoint);
  }, [isViewBreakpoint]);

  useEffect(() => {
    if (leadId) {
      fetchConversationDetails(leadId);
    } else {
      setSelectedConversation(null);
    }

    setShowArchiveSuggestion(false);
  }, [leadId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (user?.type === USER_TYPES.MEMBER && queryParams.has(CONVERSATION_ACTION_QUERY_PARAMETER)) {
      const queryModalAction = queryParams.get(CONVERSATION_ACTION_QUERY_PARAMETER);

      // Currently allowed query modal actions
      if (USER_CALENDAR_PROMPT_ACTIONS.includes(queryModalAction)) {
        setQueryModalAction(queryModalAction);
      }

      history.replace({
        search: '',
      });
    }
  }, []);

  useEffect(() => {
    const calendarsLoaded = calendarsStatus === STATUSES.LOADED;
    const isTourEventType = eventType === CALENDAR_REQUEST_TYPE.TOUR;
    const eventRequestValid =
      isTourEventType &&
      selectedConversation?.tour &&
      !(selectedConversation.tour.confirmed || selectedConversation.tour.cancelled);

    if (calendarsLoaded && eventRequestValid && queryModalAction) {
      showModal(queryModalAction);
      setQueryModalAction(null);
    }
  }, [calendarsStatus]);

  useEffect(() => {
    const loadCommunityCalendars = async () => {
      try {
        setCalendarStatus(STATUSES.LOADING);
        const communityCalendars = await getCalendars(selectedConversation.community.id);

        const userCalendar = communityCalendars.find((calendar) => calendar.user.id === user.id);
        const userCalendarId = userCalendar && userCalendar.id;
        const communityCalendarId = communityCalendars.length && communityCalendars[0].id;
        const selectedCalendarId = userCalendarId || communityCalendarId;

        setCalendars(communityCalendars);
        setSelectedCalendar(selectedCalendarId);
        setCalendarStatus(STATUSES.LOADED);
      } catch (e) {
        setCalendarStatus(STATUSES.FAILURE);
        toast.warning('Trouble fetching community linked calendars!');
      }
    };

    if (calendars.length === 0 && selectedConversation) {
      loadCommunityCalendars();
    }

    if (selectedConversation) {
      fetchLeadUserReminder(selectedConversation.id);
    }
  }, [selectedConversation]);

  useInterval(() => {
    try {
      if (failedRefreshCount < MAX_REFRESH_FAILS_BEFORE_BACKOFF) {
        refreshVisiblePages();

        if (leadId && conversationCacheStatus !== STATUSES.LOADING) {
          fetchConversationDetails(leadId, false);
        }

        if (user && user.type === USER_TYPES.MEMBER) {
          loadMessagesSummary();
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, REFRESH_INTERVAL);

  const refreshUnreadCount = async () => {
    if (user && user.type === USER_TYPES.MEMBER) {
      const response = await loadMessagesSummary();
      return response;
    }
  };

  const archiveConversation = async (conversation) => {
    try {
      await setArchivedConversation(conversation.id, {
        messages_archived: true,
      });

      toast.success(() => <ConversationArchivedToast conversation={conversation} undo={unarchiveConversation} />, {
        pauseOnHover: true,
        closeButton: false,
        style: {
          backgroundColor: 'var(--emerald-green)',
          color: 'white',
        },
      });

      await refreshUnreadCount();
      await refreshVisiblePages();

      if (onArchived instanceof Function) {
        onArchived(conversation);
      }
    } catch (e) {
      toast.warning('We cannot archive the conversation at this time. Please try again later.');
      console.error(e);
    }
  };

  const unarchiveConversation = async (conversation) => {
    try {
      await setArchivedConversation(conversation.id, {
        messages_archived: false,
      });

      await refreshUnreadCount();
      await refreshVisiblePages();

      if (onUnarchived instanceof Function) {
        onUnarchived(conversation);
      }
    } catch (e) {
      toast.warning('We cannot un-archive the conversation at this time. Please try again later.');
      console.error(e);
    }
  };

  const markAsRead = async (conversationId) => {
    try {
      await setConversationRead(conversationId);
      await refreshUnreadCount();
      await refreshVisiblePages();

      setSelectedConversation({ ...selectedConversation, has_unread: false });
    } catch (e) {
      toast.warning('Unable to mark this conversation as read at this time. Please try again later.');
      console.error(e);
    }
  };

  const markAsUnread = async (conversationId) => {
    try {
      await setConversationUnread(conversationId);
      await refreshUnreadCount();
      await refreshVisiblePages();

      setSelectedConversation({ ...selectedConversation, has_unread: true });
    } catch (e) {
      toast.warning('Unable to mark this conversation as unread at this time. Please try again later.');
      console.error(e);
    }
  };

  const fetchLeadUserReminder = async (leadId) => {
    try {
      const response = await getLeadUserReminder(leadId, LEAD_USER_REMINDER_TYPE.MESSAGES);
      setLeadUserReminder({ ...DEFAULT_LEAD_USER_REMINDER, ...response });
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to get follow-up reminder'));
    }
  };

  const handleSetLeadUserReminder = async ({ scheduleType, reminderDate = null }) => {
    try {
      const { id } = leadUserReminder;
      const data = {
        schedule_type: scheduleType,
        reminder_date: reminderDate,
      };

      const response = id
        ? await updateLeadUserReminder(selectedConversation.id, LEAD_USER_REMINDER_TYPE.MESSAGES, id, data)
        : await createLeadUserReminder(selectedConversation.id, LEAD_USER_REMINDER_TYPE.MESSAGES, data);

      setLeadUserReminder(response);
      toast.success(id ? 'Conversation reminder updated' : 'Conversation reminder created');

      window?.analytics?.track(`Conversation reminder ${id ? 'updated' : 'set'}`, {
        community: selectedConversation?.community?.name,
        community_id: selectedConversation?.community?.id,
        lead_source: selectedConversation?.details?.lead_source,
      });
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Unable to set follow-up reminder'));
    }
  };

  const sendMessage = async (id, data) => {
    await sendLeadMessage(id, data);
    await refreshLoadedPages();
  };

  const removeFromOutreach = async () => {
    try {
      setRemoveOutreachLoading(true);
      const { scheduled_message } = selectedConversation;
      const { id: leadId, name: leadName } = selectedConversation;
      const campaignType = CAMPAIGN_TYPE_NAME[scheduled_message.campaign_type];

      await cancelCampaignScheduledLeadMessages(scheduled_message.campaign_id, leadId);
      await fetchConversationDetails(leadId, false);

      toast.success(`${campaignType} campaign scheduled messages for ${leadName} have been cancelled.`);
    } catch (e) {
      toast.error(parseErrorResponse(e, `Unable to remove ${t('lead')} from campaign.`));
    } finally {
      setRemoveOutreachLoading(false);
    }
  };

  const resubmitToCRM = async () => {
    try {
      setResubmitStatus(STATUSES.LOADING);
      const response = await submitLeadCRM(leadId);

      setSelectedConversation((draft) => {
        draft.details.crm_result = response;
        return draft;
      });
      toast.success(`${capitalize(t('lead'))} successfully submitted to CRM.`);
      setResubmitStatus(STATUSES.LOADED);
      return true;
    } catch (e) {
      toast.error(parseErrorResponse(e, `Trouble submitting ${t('lead')} to CRM. Please try again later.`));
      setResubmitStatus(STATUSES.FAILURE);
      return false;
    }
  };

  const resubmitLeadActionsToCRM = async () => {
    try {
      setResubmitStatus(STATUSES.LOADING);
      const response = await submitLeadActionsToCRM(leadId);

      setSelectedConversation((draft) => {
        draft.details.crm_lead_action_result = response?.success || false;
        return draft;
      });
      toast.success(`${capitalize(t('lead'))} actions successfully submitted to CRM.`);
      setResubmitStatus(STATUSES.LOADED);
      return true;
    } catch (e) {
      toast.error(parseErrorResponse(e, `Trouble submitting ${t('lead')} actions to CRM. Please try again later.`));
      setResubmitStatus(STATUSES.FAILURE);
      return false;
    }
  };

  const resubmitTourToCRM = async () => {
    try {
      setResubmitStatus(STATUSES.LOADING);
      const response = await submitTourToCRM(leadId);

      setSelectedConversation((draft) => {
        draft.details.has_crm_tour_submitted = response?.success || false;
        return draft;
      });
      toast.success(`${capitalize(t('tour'))} successfully submitted to CRM.`);
      setResubmitStatus(STATUSES.LOADED);
      return true;
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Trouble submitting tour to CRM. Please try again later.'));
      setResubmitStatus(STATUSES.FAILURE);
      return false;
    }
  };

  const resubmitCallToCRM = async () => {
    try {
      setResubmitStatus(STATUSES.LOADING);
      const response = await submitCallToCRM(leadId);

      setSelectedConversation((draft) => {
        draft.details.has_crm_call_submitted = response?.success || false;
        return draft;
      });
      toast.success('Call successfully submitted to CRM.');
      setResubmitStatus(STATUSES.LOADED);
      return true;
    } catch (e) {
      toast.error(parseErrorResponse(e, 'Trouble submitting call to CRM. Please try again later.'));
      setResubmitStatus(STATUSES.FAILURE);
      return false;
    }
  };

  const handleDeleteLead = async () => {
    try {
      setDeleteLeadLoading(true);
      await deleteLead(leadId);
      toast.success(`${capitalize(t('lead'))} deleted successfully.`);

      if (!!history.location.state?.fromConversations) {
        history.goBack();
      } else {
        history.push('/conversations');
      }
    } catch (e) {
      toast.error(parseErrorResponse(e, `Unable to delete ${t('lead')}. Please try again later.`));
    } finally {
      setDeleteLeadLoading(false);
    }
  };

  const showModal = (action) => {
    const allowedAction = Object.values(CONVERSATION_ACTIONS).includes(action);
    const hasLinkedUserCalendar = calendars.find((calendar) => calendar.user.id === user.id);

    if (!allowedAction) {
      setActiveModal(null);
      return;
    }

    if (action === CONVERSATION_ACTIONS.REMINDER) {
      setActiveModal(action);
      return;
    }

    if (
      CONVERSATION_ACTIONS.LINK !== activeModal &&
      USER_CALENDAR_PROMPT_ACTIONS.includes(action) &&
      !hasLinkedUserCalendar
    ) {
      setNextModalAction(action);
      setActiveModal(CONVERSATION_ACTIONS.LINK);
      return;
    }

    setActiveModal(action);
  };

  const forwardedCallFromEndTime = (forwardedCallEventCreatedOn) => {
    /*
    FORWARDED_CALL_CONNECTED event is created once the call is ended, so we can
    only match by end time
     */

    const forwardedCallEndedOn = new Date(forwardedCallEventCreatedOn);

    const forwardedCall = selectedConversation.phone_calls.reduce((acc, call) => {
      if (acc) return acc;

      const forwardedCallFound = call.forwarded_calls.find((forwardedCall) => {
        const forwardedCallEndTime = new Date(forwardedCall.end_time);
        return (
          forwardedCallEndTime <= forwardedCallEndedOn &&
          forwardedCallEndedOn <= new Date(forwardedCallEndTime.getTime() + 5000)
        );
      });

      if (!forwardedCallFound) return null;

      return forwardedCallFound;
    }, null);

    return forwardedCall;
  };

  const onViewTranscriptLinkClick = async (forwardedCall) => {
    setSelectedForwardedCall(forwardedCall);
    showModal(CONVERSATION_ACTIONS.CALL_INFO);
  };

  const saveNote = async (values, setErrors) => {
    const toastErrorMessage = currentNote ? 'Unable to update note!' : 'Unable to add note!';
    const toastSuccessMessage = currentNote ? 'Note updated successfully!' : 'Note added successfully!';
    const noteId = currentNote?.id;
    const noteAPI = noteId ? updateLeadNote : addLeadNote;
    const data = {
      content: values.content,
    };
    try {
      setIsNoteLoading(true);
      await noteAPI(leadId, data, noteId);
      await fetchConversationDetails(leadId, false);
      toast.success(toastSuccessMessage);
      setIsNoteModalOpen(false);
      setIsNoteLoading(false);
      setCurrentNote(null);
    } catch (e) {
      setErrors(parseFieldErrors(e));
      toast.error(parseErrorResponse(e, toastErrorMessage));
    } finally {
      setIsNoteLoading(false);
    }
  };

  const provide = {
    viewType,
    isViewBreakpoint,
    selectedConversation,
    loaderStatus,
    setLoaderStatus,
    setSelectedConversation,
    search,
    setSearch,
    leadUserReminder,
    setLeadUserReminder,
    handleSetLeadUserReminder,
    messageLoader,
    setMessageLoader,
    fetchConversationDetails,
    sendMessage,
    showArchiveSuggestion,
    setShowArchiveSuggestion,
    sidebarExpanded,
    setSidebarExpanded,
    sidebarActiveTab,
    setSidebarActiveTab,
    conversationCacheStatus,
    setConversationCacheStatus,
    archiveConversation,
    unarchiveConversation,
    markAsRead,
    markAsUnread,
    onModalClose,
    removeOutreachLoading,
    removeFromOutreach,
    resubmitToCRM,
    resubmitLeadActionsToCRM,
    resubmitCallToCRM,
    resubmitTourToCRM,
    resubmitStatus,
    eventType,
    setEventType,
    eventTypeName,
    calendars,
    setCalendars,
    selectedCalendar,
    setSelectedCalendar,
    activeModal,
    showModal,
    nextModalAction,
    setNextModalAction,
    handleDeleteLead,
    deleteLeadLoading,
    forwardedCallFromEndTime,
    onViewTranscriptLinkClick,
    selectedForwardedCall,
    isNoteModalOpen,
    setIsNoteModalOpen,
    currentNote,
    setCurrentNote,
    saveNote,
    isNoteLoading,
  };

  return <ConversationContext.Provider value={provide} {...props} />;
};
