import React from 'react';
import styled from 'styled-components';

import { Branch } from './Branch';

const Divider = styled.div`
  height: 0.5rem;
  background-color: #f9fafb;
  border: 1px solid #f9fafb;
  border-left-color: rgba(34, 36, 38, 0.1);
  border-right-color: rgba(34, 36, 38, 0.1);
  margin: -1px 0;
`;

const AnswersWrapper = styled.div`
  position: relative;
  background: white;
  margin: -1px;

  .ui.table {
    margin-top: -2px;

    tfoot {
      th {
        font-weight: bold;
      }
    }
  }
`;

export const Answers = ({ node, answers, data, tree, source, sourceModuleType, answerCount, parentCount }) => {
  const branches = tree.edges.filter((e) => e[0] === source);
  const jumps = tree.jumps
    .filter((j) => j[0] === source)
    .map((j) => data.find((d) => d.source === j[0] && d.target === j[1]))
    .concat(answers.filter((a) => !data.some((d) => d.source === a.target)));

  const getChildNode = (branch) => data.find((d) => d.source === branch[1]);

  const getBranchCount = (branch) =>
    ['submit', 'eval'].includes(sourceModuleType)
      ? parentCount
      : answers.filter((a) => a.target === branch[1]).reduce((r, v) => r + v.count, 0);

  const shouldIndent = () => branches.length > 1 || jumps.length > 1;

  return (
    <AnswersWrapper>
      {branches.map((b, i) => (
        <Branch
          key={i}
          childNode={getChildNode(b)}
          answers={answers.filter((a) => a.target === b[1])}
          totalCount={answerCount}
          parentCount={parentCount}
          branchCount={getBranchCount(b)}
          showTableHeader={i === 0}
          parent={node}
          tree={tree}
          data={data}
          indent={shouldIndent(b)}
        />
      ))}

      {jumps.length > 0 && (
        <Branch
          answers={jumps}
          totalCount={answerCount}
          showTableHeader={branches.length === 0}
          parentCount={parentCount}
          branchCount={jumps.reduce((r, v) => r + v.count, 0)}
        />
      )}

      <Divider />
    </AnswersWrapper>
  );
};
