import styled from 'styled-components';

const AnalyticsTabs = styled.div`
  margin-top: 2rem;
  .ui.segment.tab {
    border: none;
    padding: 1.25rem;
  }
  .ui.large.secondary.pointing.menu {
    flex-wrap: wrap;
    margin: 1rem 0;
    padding: 1px;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    font-size: 1.2rem;
  }
`;

export default AnalyticsTabs;
