import React, { useMemo } from 'react';

import { ReportComponent } from 'components/common/analytics';
import { formatPercentNumber, percent } from 'components/utils';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';
import PrevStats from '../common/PrevStats';
import { useMoveinTotals } from './LeadMoveins';

const aggregateCommunityStats = (data) =>
  Object.keys(data).reduce((vals, m) => {
    vals[m] = data[m].groups.reduce((total, g) => total + g.value, 0);
    return vals;
  }, {});

const LeadMoveinsPrevPeriod = () => {
  const { saleAssistantFilters: filters, getPreviousDateRange } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };

  const prevDateRange = getPreviousDateRange();
  const prevMaMoveinFilters = { ...maFilters, ...prevDateRange };

  const maMoveins = useMoveinTotals(maFilters);
  const prevMaMoveins = useMoveinTotals(prevMaMoveinFilters);

  const prevData = useMemo(() => {
    return [
      {
        label: 'Move-ins',
        value: maMoveins.data['leads.move_ins']?.value,
        prevValue: prevMaMoveins.data['leads.move_ins']?.value,
      },
      {
        label: 'Active Lead Move-ins',
        value: maMoveins.data['leads.move_ins.replied']?.value,
        prevValue: prevMaMoveins.data['leads.move_ins.replied']?.value,
      },
      {
        label: 'MA Involved Move-ins',
        value: maMoveins.data['leads.move_ins.ma_active']?.value,
        prevValue: prevMaMoveins.data['leads.move_ins.ma_active']?.value,
      },
      {
        label: 'Overall Conversion Rate',
        value:
          maMoveins.data['leads.total']?.value &&
          percent(maMoveins.data['leads.move_ins']?.value, maMoveins.data['leads.total']?.value),
        prevValue:
          prevMaMoveins.data['leads.total']?.value &&
          percent(prevMaMoveins.data['leads.move_ins']?.value, prevMaMoveins.data['leads.total']?.value),
        formatter: formatPercentNumber,
      },
      {
        label: 'Active Leads Conversion Rate',
        value:
          maMoveins.data['leads.replied']?.value &&
          percent(maMoveins.data['leads.move_ins.replied']?.value, maMoveins.data['leads.replied']?.value),
        prevValue:
          prevMaMoveins.data['leads.replied']?.value &&
          percent(prevMaMoveins.data['leads.move_ins.replied']?.value, prevMaMoveins.data['leads.replied']?.value),
        formatter: formatPercentNumber,
      },
      {
        label: 'MA Leads Conversion Rate',
        value:
          maMoveins.data['leads.replied']?.value &&
          percent(maMoveins.data['leads.move_ins.ma_active']?.value, maMoveins.data['leads.ma_active']?.value),
        prevValue:
          prevMaMoveins.data['leads.replied']?.value &&
          percent(prevMaMoveins.data['leads.move_ins.ma_active']?.value, prevMaMoveins.data['leads.ma_active']?.value),
        formatter: formatPercentNumber,
      },
    ];
  }, [maMoveins.data, prevMaMoveins.data]);

  return (
    <ReportComponent
      title={`Compared to previous ${prevDateRange.day_span} days`}
      tooltip={`Changes in lead move-ins compared to previous time period, from ${prevDateRange.start_date} to ${prevDateRange.end_date}.`}
      contentMargins="20px 0"
      contentHeight="50px"
    >
      <PrevStats data={prevData} />
    </ReportComponent>
  );
};

export default LeadMoveinsPrevPeriod;
