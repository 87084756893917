import React, { useContext } from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { ConversationContext } from './ConversationContext';

const Container = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--link);
  display: flex;
  align-items: center;
  position: absolute;
  top: -8px;
  right: 45px;
  cursor: pointer;
`;

export const ConversationAddNoteButton = () => {
  const { setIsNoteModalOpen } = useContext(ConversationContext);

  const handleOnClick = () => {
    setIsNoteModalOpen(true);
  };

  return (
    <Container onClick={handleOnClick}>
      <Icon name="add" size="18" color="var(--link)" />
      Add Note
    </Container>
  );
};
