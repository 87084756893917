import React from 'react';

import ChatValidationDetails from './ChatValidationDetails';
import ChatValidationList from './ChatValidationList';

const ChatValidation = ({ data }) => {
  if (Array.isArray(data)) {
    return <ChatValidationList validationErrors={data} />;
  }

  return <ChatValidationDetails validationErrors={data} />;
};

export default ChatValidation;
