import React from 'react';
import styled from 'styled-components';
import { PageSection } from 'components/common';
import { Grid } from 'components/lib';
import AutomatedAnswerFilters from './AutomatedAnswerFilters';
import Totals from './Totals';
import Sent from './Sent';
import SuccessGraph from './SuccessGraph';
import TrendsGraph from './TrendsGraph';
import TopNGraph from './TopNGraph';
import Automated from './Automated';

const StyledGrid = styled(Grid)`
  width: 100%;
`;

const AutomatedAnswerReport = () => {
  return (
    <PageSection>
      <AutomatedAnswerFilters />
      <StyledGrid stackable>
        <Grid.Row columns={3}>
          <Grid.Column>
            <Totals />
          </Grid.Column>
          <Grid.Column>
            <Sent />
          </Grid.Column>
          <Grid.Column>
            <Automated />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <SuccessGraph />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <TrendsGraph />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <TopNGraph
              metric="answers.answered"
              groupBy="suggested_labels"
              title="Top Suggested Labels"
              tooltip="Labels suggested to a user regardless of whether they were used or not."
              backgroundColor="#f7c86fbb"
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <TopNGraph
              metric="answers.accepted"
              groupBy="suggested_labels"
              title="Top Accepted Labels"
              tooltip="Labels suggested to a user and used in the answer without any changes."
              backgroundColor="#17bd2abb"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <TopNGraph
              metric="answers.changed"
              groupBy="suggested_labels"
              title="Top Changed Labels"
              tooltip="Labels suggested to a user but not used in the answer."
              backgroundColor="#2b43a1bb"
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <TopNGraph
              metric="answers.sent"
              groupBy="used_labels"
              title="Top Used Labels"
              tooltip="Labels used in the answer regardless of whether they were suggested or not."
              backgroundColor="#c4931fbb"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <TopNGraph
              metric="answers.changed"
              groupBy="suggested_labels,used_labels"
              title="Top Changes"
              backgroundColor="#b33024bb"
              tooltip="Changes made to the answer as suggested label -> used label."
              toLabel={(b) => `${b.suggested_labels} -> ${b.used_labels}`}
            />
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
    </PageSection>
  );
};

export default AutomatedAnswerReport;
