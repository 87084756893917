import React from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { Icon, Popup } from 'components/lib';
import { IconsWrapper } from './IconsWrapper';

const IconsTriggerWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

const SecondRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const CampaignListIcons = ({ campaign, isCompleted }) => {
  const offset = campaign?.useNurtureConversationSupport ? [-12, 0] : [-5, 15];
  return (
    <IconsWrapper>
      <If condition={!isCompleted}>
        <If condition={!campaign?.isActive}>
          <Icon name="pause" size="24" color="#89919d" />
        </If>
        <If condition={campaign?.isActive}>
          <Icon name="play_arrow" size="24" color="#0ab776" />
        </If>
      </If>
      <If condition={isCompleted}>
        <Icon name="stop" size="24" color="#89919d" />
      </If>
      <SecondRow>
        <IconsTriggerWrapper>
          <If condition={campaign?.invalid}>
            <Icon name="error" size="15" color="#fa7550" />
          </If>
        </IconsTriggerWrapper>
        <Popup
          trigger={
            <IconsTriggerWrapper>
              <If condition={campaign?.useNurtureConversationSupport}>
                <Icon name="support_agent" size="15" color="#0ab776" />
              </If>
            </IconsTriggerWrapper>
          }
          content={campaign?.messagingAssistantText}
          position="top left"
          offset={offset}
          hoverable
        />
      </SecondRow>
    </IconsWrapper>
  );
};
