import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { deleteCommunityCRMSettings } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { capitalize } from 'utils/helpers';
import { STATUSES } from '../../../constants';
import { removeCommunityCRMSettings, fetchCommunityInvalidCRMSettings, useCRM } from './context';

const CRMCommunityRemoveModal = ({ open, community, onConfirm, onClose }) => {
  const { t } = useTranslation();
  const [{ userId }, dispatch] = useCRM();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const handleConfirm = async () => {
    try {
      setLoaderStatus(STATUSES.LOADING);
      await deleteCommunityCRMSettings(userId, community.id);

      dispatch(removeCommunityCRMSettings(community.id));
      toast.success(`${capitalize(t('community'))} CRM settings removed successfully`);

      if (community.has_errors) {
        await fetchCommunityInvalidCRMSettings(userId, dispatch);
      }

      setLoaderStatus(STATUSES.LOADED);
      onConfirm();
    } catch (e) {
      toast.error(parseErrorResponse(e, `Unable to remove ${t('community')} CRM settings`));
      setLoaderStatus(STATUSES.FAILURE);
    }
  };

  if (!community) {
    return null;
  }

  const loading = loaderStatus === STATUSES.LOADING;

  return (
    <Modal size="tiny" open={open} onClose={onClose} closeOnDimmerClick={!loading}>
      <Modal.Header>Remove {t('community')} CRM Settings</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to remove CRM settings from <b>{community.name}</b>?
        </p>
        <p>{capitalize(t('community'))} will inherit default CRM settings.</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} disabled={loading}>
          No
        </Button>
        <Button negative onClick={handleConfirm} loading={loading} disabled={loading}>
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CRMCommunityRemoveModal;
