import styled from 'styled-components';

export const FormWrapper = styled.div`
  position: relative;
  min-height: 20rem;

  .ui.divider {
    margin-bottom: 4rem;
    margin-top: 4rem;
    text-transform: uppercase;
    color: #999;
    font-size: 1.1rem;
    font-weight: bolder;
  }

  .ui.form .field {
    margin-bottom: 2rem;
  }
  .ui.form .fields {
    margin-bottom: 2rem;
  }

  .ui.form .fields .field {
    margin-bottom: 0;
  }

  .ui.form .inline.fields {
    margin-bottom: 2rem;
  }

  .ui.form .field > label {
    margin-bottom: 0.6rem;
    padding-left: 0.05rem;
    color: #444;
  }
`;
