export const VSA_TYPE = {
  REGULAR: 'regular',
  EMBEDDED: 'embedded',
  SURVEY: 'survey',
  PHONE: 'phone',
  DYNAMIC: 'dynamic',
};

export const EMBEDDED_VSA_LABEL = 'CONVERSATIONAL WEB FORM';
export const SURVEY_LABEL = 'CONVERSATIONAL WEB FORM (SURVEY)';
export const REGULAR_VSA_LABEL = 'POP-UP CHAT';
export const VOICE_BASED_VSA_LABEL = 'PHONE';
export const DYNAMIC_VSA_LABEL = 'DYNAMIC VSA';

export const VSA_TYPE_LABEL = {
  embedded: EMBEDDED_VSA_LABEL,
  survey: SURVEY_LABEL,
  regular: REGULAR_VSA_LABEL,
  phone: VOICE_BASED_VSA_LABEL,
  dynamic: DYNAMIC_VSA_LABEL,
};

export const CHAT_TMPLATE_ORDER_BY_TYPE_MAPING = {
  [VSA_TYPE.REGULAR]: 1,
  [VSA_TYPE.EMBEDDED]: 2,
  [VSA_TYPE.SURVEY]: 3,
  [VSA_TYPE.PHONE]: 4,
  [VSA_TYPE.DYNAMIC]: 5,
};
