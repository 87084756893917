import React from 'react';
import styled from 'styled-components';
import { Icon as SemanticIcon, Table as SemanticTable } from 'semantic-ui-react';

import { Icon, Pagination, Popup } from '.';
import { SORT_ORDER } from '../../constants';

export const StyledTable = styled(SemanticTable)`
  &&& {
    border: none;
    font-family: var(--font-family);
    color: var(--text-color);

    &.striped tbody > tr:nth-child(2n) {
      background-color: rgba(216, 216, 216, 0.07);
    }

    &.selectable {
      tbody > tr:hover {
        background: rgba(216, 216, 216, 0.07) !important;
        box-shadow: 0 2px 6px 0 #d8d8d8;
      }
    }

    thead > tr > th {
      font-family: var(--font-family-bold);
      text-transform: uppercase;
      background-color: var(--white);
    }
  }
`;

const StyledPagination = styled(Pagination)`
  &&&& {
    margin: 0;
  }
`;

const StyledIcon = styled(Icon)`
  margin: 0 4px;
`;

const HeaderCellContent = styled.div`
  display: flex;
  align-items: center;
  ${({ sortable }) => sortable && 'cursor: pointer;'}

  gap: 5px;
  .title {
    padding-top: 4px;
    font-size: 12px;
  }
`;

const HeaderWithInfo = ({ children, info }) => (
  <HeaderCellContent>
    {children}
    <div
      style={{ display: 'inline-flex' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popup
        hoverable
        position="top center"
        content={info}
        trigger={<Icon name="help_outline" variant="outlined" size="16" color="#89919d" />}
      />
    </div>
  </HeaderCellContent>
);

const TablePagination = ({ page, totalPages, onPageChange }) => {
  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  if (totalPages === 1) {
    return null;
  }

  return (
    <StyledPagination
      activePage={page}
      onPageChange={(e, { activePage }) => onPageChange(activePage)}
      totalPages={totalPages}
      ellipsisItem={{ content: <SemanticIcon name="ellipsis horizontal" />, icon: true }}
      firstItem={{ content: <SemanticIcon name="angle double left" />, icon: true, disabled: isFirstPage }}
      lastItem={{ content: <SemanticIcon name="angle double right" />, icon: true, disabled: isLastPage }}
      prevItem={{ content: <SemanticIcon name="angle left" />, icon: true, disabled: isFirstPage }}
      nextItem={{ content: <SemanticIcon name="angle right" />, icon: true, disabled: isLastPage }}
    />
  );
};

const HeaderCell = ({ children, sortable, sorted, ...props }) => {
  return (
    <SemanticTable.HeaderCell {...props}>
      <HeaderCellContent className="table-header-cell-content" sortable={sortable}>
        <span>{children}</span>
        {sorted ? <StyledIcon name={sorted === SORT_ORDER.DESC ? 'expand_more' : 'expand_less'} size="26" /> : null}
      </HeaderCellContent>
    </SemanticTable.HeaderCell>
  );
};

export const Table = ({ ...props }) => <StyledTable {...props} />;

Table.propTypes = { ...StyledTable.propTypes };

Table.Header = SemanticTable.Header;
Table.Row = SemanticTable.Row;
Table.HeaderCell = HeaderCell;
Table.Body = SemanticTable.Body;
Table.Cell = SemanticTable.Cell;
Table.Footer = SemanticTable.Footer;
Table.Pagination = TablePagination;
Table.HeaderWithInfo = HeaderWithInfo;
