import React, { useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Form } from 'components/lib';
import { validateUserEmailAddress } from '../../../api';
import { AuthButton } from '../AuthButton';
import { LoginInputField } from '../LoginInputField';
import { validateLoginField } from '../utils';
import { EMAIL_FIELD_VALIDATION_MESSAGE } from '../constants';

const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().email(EMAIL_FIELD_VALIDATION_MESSAGE).required(EMAIL_FIELD_VALIDATION_MESSAGE),
});

const LoginEmailFieldForm = ({ loading, onSubmit }) => {
  const emailFieldRef = useRef();

  const initialValues = {
    email: '',
  };

  const submit = async ({ email }, { setFieldError }) => {
    const isValid = await validateLoginField({ email }, VALIDATION_SCHEMA, setFieldError);

    if (!isValid) {
      emailFieldRef?.current?.focus();
      return;
    }

    try {
      await validateUserEmailAddress({ email });
      setFieldError('email', null);
    } catch (e) {
      setFieldError('email', EMAIL_FIELD_VALIDATION_MESSAGE);
      emailFieldRef?.current?.focus();
      return;
    }

    onSubmit(email);
  };

  return (
    <Formik initialValues={initialValues} validateOnBlur={false} validateOnChange={false} onSubmit={submit}>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <LoginInputField
            type="email"
            autoFocus
            ref={emailFieldRef}
            name="email"
            label="Email address"
            disabled={loading}
          />
          <AuthButton label="Next" loading={loading} disabled={loading} />
        </Form>
      )}
    </Formik>
  );
};

export default LoginEmailFieldForm;
