import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';

const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-right: 20px;
`;

const Circle = styled.div`
  width: 18px;
  height: 18px;
  background-color: var(--emerald-green);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TooltipContent = styled.div`
  position: absolute;
  background-color: #000;
  color: white;
  padding: 5px;
  bottom: ${(props) => (props.position === 'above' ? '24px' : '-88px')};
  left: 50%;
  transform: translateX(-50%);
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  pointer-events: ${(props) => (props.isVisible ? 'auto' : 'none')};
  transition: opacity 0.3s;
  z-index: 1;
  font-family: var(--font-family);
  font-size: 12px;
  width: 215px;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #000;
    top: ${(props) => (props.position === 'above' ? '100%' : '-8px')};
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Tooltip = ({ text }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState('below');
  const tooltipRef = useRef(null);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltipHeight = tooltipRef.current.offsetHeight;
      const windowHeight = window.innerHeight;
      const circleRect = tooltipRef.current.getBoundingClientRect();

      if (circleRect.top - tooltipHeight < 0) {
        setTooltipPosition('above');
      } else {
        setTooltipPosition('below');
      }
    }
  }, [isTooltipVisible]);

  return (
    <TooltipWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Circle ref={tooltipRef}>
        <Icon name="sync" size={14} color="#ffffff" />
      </Circle>
      <TooltipContent isVisible={isTooltipVisible} position={tooltipPosition}>
        {text}
      </TooltipContent>
    </TooltipWrapper>
  );
};
