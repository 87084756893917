import React from 'react';

import { InstantAnswersContextProvider } from './InstantAnswersContext';
import { InstantAnswersCommunity } from './InstantAnswersCommunity';

export const InstantAnswers = () => (
  <InstantAnswersContextProvider>
    <InstantAnswersCommunity />
  </InstantAnswersContextProvider>
);
