import React, { createContext, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getCommunitiesById, fetchChatTemplates } from 'apis';
import { VSA_TYPE } from 'components/chats/constants';
import { STATUSES } from '../../constants';
import { getAllChats } from '../../api';

export const OnboardingContext = createContext();

export const OnboardingContextProvider = (props) => {
  const [chats, setChats] = useState(null);
  const [chatsStatus, setChatsStatus] = useState(STATUSES.IDLE);
  const [communities, setCommunities] = useState(null);
  const [chatTemplates, setChatTemplates] = useState([]);

  const fetchChats = useCallback(async () => {
    try {
      setChatsStatus(STATUSES.LOADING);
      const response = await getAllChats({ vsa_type: VSA_TYPE.REGULAR, search: '' });
      if (response.count) {
        setChats(response.results);
      }
      setChatsStatus(STATUSES.LOADED);
    } catch (e) {
      setChatsStatus(STATUSES.FAILURE);
      console.error(e);
      toast.error('Error loading user chats!');
    }
  }, []);

  const fetchCommunities = async () => {
    try {
      const communities_ids = communities?.map((community) => community.id);
      if (communities_ids.length === 0) return;

      const response = await getCommunitiesById(communities_ids);
      if (response.length) {
        setCommunities(response);
      }
    } catch (e) {
      console.error(e);
      toast.error('Error fetching user communities!');
    }
  };

  const getChatTemplates = async () => {
    setChatsStatus(STATUSES.LOADING);
    try {
      const response = await fetchChatTemplates({ vsa_type: VSA_TYPE.REGULAR });
      if (response.count) {
        setChatTemplates(response.results);
      }
    } catch (e) {
      console.error(e);
      toast.error('Error fetching chat templates!');
    }
  };

  useEffect(() => {
    fetchChats();
    getChatTemplates();
  }, [fetchChats]);

  const provide = {
    chats,
    chatsStatus,
    communities,
    setCommunities,
    fetchChats,
    fetchCommunities,
    chatTemplates,
  };

  return <OnboardingContext.Provider value={provide} {...props} />;
};
