import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { Form } from 'components/lib';
import { BrandNewImagePicker } from 'components/common';
import { FILE_SIZE_FACTOR, MAX_STAFF_IMAGE_SIZE } from 'components/communities/constants';
import { deleteProfilePicture, uploadProfilePicture } from 'apis';

export const MyProfileImage = ({ data }) => {
  const [profilePicture, setProfilePicture] = useState(data?.profilePicture);

  const onImageSelected = async (image) => {
    if (image?.size > MAX_STAFF_IMAGE_SIZE * FILE_SIZE_FACTOR) {
      toast.warning(`Image size should not be greater than ${MAX_STAFF_IMAGE_SIZE}MB!`);
      return;
    }
    try {
      const blob = new Blob([image], { type: image.type });
      const formData = new FormData();
      formData.append('profile_picture', blob, image.name);

      const resp = await uploadProfilePicture(formData);
      setProfilePicture(resp?.uploadedPicture);
      toast.success('Profile picture saved successfully.');
      return resp?.uploadedPicture;
    } catch (e) {
      toast.error('Error saving profile picture, please try again.');
    }
  };

  const onImageDelete = async () => {
    try {
      const resp = await deleteProfilePicture();
      if (resp) {
        setProfilePicture(null);
        toast.success('Profile picture removed successfully.');
      }
    } catch (e) {
      toast.error('Error removing profile picture, please try again.');
    }
  };

  useEffect(() => {
    setProfilePicture(data?.profilePicture);
  }, [data?.profilePicture]);

  const initialValues = {
    profilePicture,
  };
  return (
    <Formik enableReinitialize initialValues={initialValues}>
      {() => (
        <Form>
          <BrandNewImagePicker
            name="profilePicture"
            openOnClick
            newDesign
            onImageSelected={onImageSelected}
            onImageDelete={onImageDelete}
            trashIconTop="5px"
            trashIconRight="5px"
            value={profilePicture}
          />
        </Form>
      )}
    </Formik>
  );
};
