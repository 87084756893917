import React from 'react';
import styled from 'styled-components';

const Description = styled.h6`
  margin: 0;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: var(--light-text);
  strong {
    color: var(--text);
    font-weight: initial;
  }
`;

export const SettingsDescription = ({ children }) => {
  return <Description>{children}</Description>;
};
