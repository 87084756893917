import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Icon, TableHeaderCell } from 'semantic-ui-react';

import { Message, Pagination, Table, StyledTable } from 'components/lib';
import { formatAnyDate } from 'components/utils';
import { capitalize } from 'utils/helpers';
import { getCRMLogsToursDetails } from 'apis';
import CRMAdminLogsToursModal from './CRMAdminLogsToursModal';

const LeftPaddedContent = styled.div`
  padding-left: 35px;
`;

const RightPaddedContent = styled.div`
  padding-right: 35px;
`;

const SmallText = styled.span`
  font-size: 12px;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-right: 20px;
`;

const TableWrapper = styled.div`
  cursor: pointer;
`;

const StyledMessage = styled(Message)`
  && {
    margin: 0 auto;
    max-width: calc(100% - 60px);
  }
`;

const CRMAdminLogsToursTable = ({ tours, activePage, totalPages, onPageChange }) => {
  const [selectedTour, setSelectedTour] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rawResult, setRawResult] = useState('');

  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  const fetchTourDetails = useCallback(async (tourId) => {
    try {
      const tourDetails = await getCRMLogsToursDetails(tourId);
      setSelectedTour(tourDetails);
      setRawResult(tourDetails.rawResult);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch tour details', error);
    }
  }, []);

  const handleRowClick = (tour) => {
    fetchTourDetails(tour.id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTour(null);
    setRawResult('');
  };

  if (!tours) {
    return null;
  }

  if (tours.length === 0) {
    return (
      <StyledMessage warning icon="users" content="No CRM logs matching selected filters. Please change filters" />
    );
  }

  return (
    <>
      <TableWrapper>
        <StyledTable basic selectable striped>
          <Table.Header>
            <Table.Row>
              <TableHeaderCell>
                <LeftPaddedContent>LEAD</LeftPaddedContent>
              </TableHeaderCell>
              <TableHeaderCell>COMMUNITY</TableHeaderCell>
              <TableHeaderCell>REQUEST TYPE</TableHeaderCell>
              <TableHeaderCell>CRM TYPE</TableHeaderCell>
              <TableHeaderCell>PARSABLE</TableHeaderCell>
              <TableHeaderCell>VALID</TableHeaderCell>
              <TableHeaderCell textAlign="right">
                <RightPaddedContent>CREATED ON</RightPaddedContent>
              </TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tours.map((tour) => (
              <Table.Row key={tour?.id} onClick={() => handleRowClick(tour)}>
                <Table.Cell>
                  <LeftPaddedContent>
                    <VerticalContainer>
                      <span>{`${tour?.lead?.firstName ?? ''} ${tour?.lead?.lastName ?? ''}`}</span>
                      <SmallText>{`${tour?.lead?.email ?? '-'} · ${tour?.lead?.phone ?? '-'}`}</SmallText>
                    </VerticalContainer>
                  </LeftPaddedContent>
                </Table.Cell>
                <Table.Cell>
                  <VerticalContainer>
                    <span>{tour?.community?.name ?? '-'}</span>
                    <SmallText>{tour?.user?.email ?? '-'}</SmallText>
                  </VerticalContainer>
                </Table.Cell>
                <Table.Cell>{capitalize(tour?.requestType) ?? '-'}</Table.Cell>
                <Table.Cell>{tour?.crmType?.name ?? '-'}</Table.Cell>
                <Table.Cell>
                  {tour?.isParsable ? (
                    <Icon name="check circle" color="green" />
                  ) : (
                    <Icon name="times circle" color="red" />
                  )}
                </Table.Cell>
                <Table.Cell>
                  {tour?.isValidated === true ? (
                    <Icon name="check circle" color="green" />
                  ) : tour?.isValidated === false ? (
                    <Icon name="times circle" color="red" />
                  ) : (
                    '-'
                  )}
                </Table.Cell>

                <Table.Cell textAlign="right">
                  <RightPaddedContent> {formatAnyDate(tour?.createdOn, 'MMMM d, yyyy, h:mm a')}</RightPaddedContent>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </StyledTable>
      </TableWrapper>
      <Footer>
        <Pagination activePage={activePage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Footer>

      <CRMAdminLogsToursModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        tour={selectedTour}
        rawResult={rawResult}
      />
    </>
  );
};

export default CRMAdminLogsToursTable;
