import React from 'react';
import styled from 'styled-components';
import { PageSection } from 'components/common';
import { Grid } from 'components/lib';
import { ReviewedPieChart } from './ReviewedPieChart';
import { AcceptedDeclinedPieChart } from './AcceptedDeclinedPieChart';
import { AutomatedPieChart } from './AutomatedPieChart';
import TopNGraph from '../AutomatedAnswerReport/TopNGraph';
import { ReviewsTimeSeriesChart } from './ReviewsTimeSeriesChart';
import { AutomationFilters } from './AutomationFilters';
import { AutomationTimeSeriesChart } from './AutomationTimeSeriesChart';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const StyledGrid = styled(Grid)`
  width: 100%;
`;

export const AutomationReport = () => {
  const { reviewFilters } = useSystemStatsFilters();
  return (
    <PageSection>
      <AutomationFilters />
      <StyledGrid stackable>
        <Grid.Row columns={3}>
          <Grid.Column>
            <AutomatedPieChart />
          </Grid.Column>
          <Grid.Column>
            <ReviewedPieChart />
          </Grid.Column>
          <Grid.Column>
            <AcceptedDeclinedPieChart />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <AutomationTimeSeriesChart />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <ReviewsTimeSeriesChart />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <TopNGraph
              metric="answers.reviewed"
              groupBy="review__reviewer__email"
              timeField="review__reviewed_on"
              title="Top Reviewers"
              tooltip="Reviewers with most reviewed answers"
              backgroundColor="#f7c86fbb"
              withReviewFields
              filters={reviewFilters}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <TopNGraph
              metric="answers.reviewed_declined"
              groupBy="suggested_labels,review__suggested_labels"
              timeField="review__reviewed_on"
              title="Top Declined Labels"
              tooltip="Most frequently declined labels"
              toLabel={(b) => `${b.suggested_labels} -> ${b.review__suggested_labels}`}
              backgroundColor="#fa7550"
              withReviewFields
              filters={reviewFilters}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <TopNGraph
              metric="answers.reviewed"
              groupBy="suggested_labels"
              timeField="review__reviewed_on"
              title="Top Reviewed Labels"
              tooltip="Labels with most reviews"
              backgroundColor="#f7c86fbb"
              withReviewFields
              filters={reviewFilters}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <TopNGraph
              metric="answers.reviewed_declined"
              groupBy="review__suggested_labels"
              timeField="review__reviewed_on"
              title="Top Suggested Labels"
              tooltip="Labels most suggested in declined reviews"
              backgroundColor="#f7c86fbb"
              withReviewFields
              filters={reviewFilters}
            />
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
    </PageSection>
  );
};
