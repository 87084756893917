import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { getExportLeadConversationsOptions } from 'apis';
import { ReactComponent as CSVDownload } from 'assets/images/csv-file.svg';
import { If } from 'components/If';
import { STATUSES } from '../../constants';
import { Button, Icon, Checkbox, Modal } from '../lib';
import { ConversationsContext } from './ConversationsContext';

const StyledModelHeader = styled(Modal.Header)`
  &&& {
    display: flex;
    justify-content: space-between;
    border: none;
    font-family: var(--font-family-bold);
    padding: 25px 25px 10px 30px;

    .icon {
      color: var(--light-text);
      cursor: pointer;
    }
  }
`;

const StyledModelContent = styled(Modal.Content)`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--light-text);
    font-family: var(--font-family);
    font-weight: 500;

    padding: 0px 30px 35px 30px;

    div {
      margin-bottom: 16px;
    }

    .highlight {
      color: var(--text-color);
    }

    .fake-link {
      color: var(--link);
      cursor: pointer;
      user-select: none;
    }
  }
`;

const StyledModelActions = styled(Modal.Actions)`
  &&& {
    display: flex;
    justify-content: flex-end;
    background-color: white;
    border-top: 1px solid var(--line-standard);
    padding: 20px 30px;

    .ui.button {
      height: 40px;

      &.confirm {
        width: 160px;
      }

      &.cancel {
        color: var(--text-color);
        width: 120px;
      }
    }
  }
`;

const AdditionalColumnsWrapper = styled.div`
  .additional-columns-list {
    margin-top: 24px;
    max-height: 600px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    li {
      line-height: 1.25;
      margin-bottom: 8px;
    }
  }

  .additional-column-description {
    div {
      &:last-child {
        color: #5f646b;

        &:hover {
          color: var(--text-color);
        }
      }
    }
  }
`;

const AdditionalColumnsSelector = ({ additionalColumns, onChange }) => {
  return (
    <AdditionalColumnsWrapper>
      <div>
        Select VSA questions from the list below. Each choice will create an additional column filled with answers from
        Leads in your spreadsheet. <br />
        <br />
        Any question that's tagged with "Save response as" in Web Assistant settings will be visible here, unless it's
        already included in the spreadsheet by default.
      </div>

      <div className="additional-columns-list">
        {additionalColumns.map((column, index) => (
          <div key={index}>
            <Checkbox
              label={
                <label className="additional-column-description">
                  <div>
                    <span>TAG: </span>
                    <code>{column.save_as}</code>
                  </div>
                  <div>
                    Tagged questions:
                    <ul>
                      {column.chat_questions?.map((question, index) => (
                        <li key={index}>{question}</li>
                      ))}
                    </ul>
                  </div>
                </label>
              }
              checked={column.checked}
              onChange={() => {
                onChange(index);
              }}
            />
          </div>
        ))}
      </div>
    </AdditionalColumnsWrapper>
  );
};

export const ConversationsDownloadModal = ({ open, onCloseModal }) => {
  const { handleExportAsCSV, csvExportStatus } = useContext(ConversationsContext);
  const [showAdditionalColumnsSelector, setShowAdditionalColumnsSelector] = React.useState(false);
  const [additionalColumns, setAdditionalColumns] = React.useState([]);

  useEffect(() => {
    const fetchExtras = async () => {
      const response = await getExportLeadConversationsOptions();

      setAdditionalColumns(response.extra_columns.map((column) => ({ ...column, checked: false })));
    };

    if (open) {
      fetchExtras();
    }
  }, [open]);

  const handleAdditionalColumnsChange = (index) => {
    setAdditionalColumns(
      additionalColumns.map((c, i) => {
        if (i === index) {
          return {
            ...c,
            checked: !c.checked,
          };
        }

        return c;
      })
    );
  };

  const onDownload = async () => {
    await handleExportAsCSV(additionalColumns.filter((c) => c.checked));
    setShowAdditionalColumnsSelector(false);
    onCloseModal();
  };

  const onCancel = () => {
    setShowAdditionalColumnsSelector(false);
    onCloseModal();
  };

  return (
    <Modal open={open} onClose={onCancel} size="tiny">
      <If condition={!showAdditionalColumnsSelector}>
        <StyledModelHeader>
          <span>Download Spreadsheet?</span>
          <Icon name="close" onClick={onCancel} />
        </StyledModelHeader>

        <StyledModelContent>
          <div>
            The data specified with your selected filters will be downloaded as a{' '}
            <span className="highlight">.csv</span> spreadsheet file to your computer. You can also{' '}
            <span
              className="fake-link"
              onClick={() => {
                setShowAdditionalColumnsSelector(true);
              }}
            >
              include additional data
            </span>{' '}
            in the generated <span className="highlight">.csv</span> file.
          </div>
          <CSVDownload />
        </StyledModelContent>
      </If>

      <If condition={showAdditionalColumnsSelector}>
        <StyledModelHeader>
          <span>Add More Columns to Spreadsheet</span>
          <Icon name="close" onClick={onCancel} />
        </StyledModelHeader>

        <StyledModelContent>
          <AdditionalColumnsSelector additionalColumns={additionalColumns} onChange={handleAdditionalColumnsChange} />
        </StyledModelContent>
      </If>

      <StyledModelActions>
        <Button className="confirm primary blue" loading={csvExportStatus === STATUSES.LOADING} onClick={onDownload}>
          Download .csv
        </Button>
        <Button className="cancel raised" disabled={csvExportStatus === STATUSES.LOADING} onClick={onCancel}>
          Cancel
        </Button>
      </StyledModelActions>
    </Modal>
  );
};
