import React, { useContext, useEffect } from 'react';
import { Segment, Form, TextArea } from 'semantic-ui-react';
import { MessageContext } from './MessageContext';
import { ANSWER_TYPES } from './constants';

export const ConversationResponseTextInput = () => {
  const { content, setContent } = useContext(MessageContext);

  const onChange = (e) => {
    setContent(e.target.value);
  };

  return (
    <Segment secondary>
      <Form>
        <TextArea value={content} onChange={onChange} style={{ minHeight: 80, maxHeight: 300 }} />
      </Form>
    </Segment>
  );
};
