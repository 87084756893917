import React from 'react';
import UnitLevelPricingContextProvider from './context/UnitLevelPricingContext';
import UnitLevelPricing from './UnitLevelPricing';

const UnitLevelPricingRoot = () => (
  <UnitLevelPricingContextProvider>
    <UnitLevelPricing />
  </UnitLevelPricingContextProvider>
);

export default UnitLevelPricingRoot;
