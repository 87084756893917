import React, { useContext } from 'react';
import styled from 'styled-components';

import { Dropdown } from 'components/lib';
import { DateRangePicker } from 'components/common';
import { MEDIA_BREAK_POINTS } from '../../../constants';
import { FeaturesContext } from './FeaturesContext';
import { DEFAULT_FEATURES_DROPDOWN_VALUE } from './constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  gap: 30px;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    gap: 10px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
  }
`;

const SecondRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DropdownBox = styled.div`
  width: 300px;
`;

export const FeaturesOverviewFilters = ({ filters, onChange }) => {
  const { FEATURE_ACTORS, FEATURE_STATUSES, FEATURE_TYPES } = useContext(FeaturesContext);
  const { actor, feature, status, startDate, endDate } = filters;

  const handleDateRangeChange = ({ startDate, endDate }) => {
    onChange({ startDate, endDate });
  };

  const handleActorChange = (e, { value }) => {
    onChange({ actor: value });
  };

  const handleFeatureTypeChange = (e, { value }) => {
    onChange({ feature: value });
  };

  const handleStatusChange = (e, { value }) => {
    onChange({ status: value });
  };

  const actor_options = [
    { value: DEFAULT_FEATURES_DROPDOWN_VALUE, text: 'Any actor' },
    ...Object.entries(FEATURE_ACTORS).map(([key, value]) => ({ value: key, text: value })),
  ];

  const feature_options = [
    { value: DEFAULT_FEATURES_DROPDOWN_VALUE, text: 'Any feature' },
    ...Object.entries(FEATURE_TYPES).map(([key, value]) => ({ value: key, text: value })),
  ];

  const status_options = [
    { value: DEFAULT_FEATURES_DROPDOWN_VALUE, text: 'Any status' },
    ...Object.entries(FEATURE_STATUSES).map(([key, value]) => ({ value: key, text: value })),
  ];

  return (
    <Container>
      <FirstRow>
        <DateRangePicker
          dateRange={{ start_date: startDate, end_date: endDate }}
          onFilterChange={handleDateRangeChange}
          resetFilterChange={handleDateRangeChange}
        />
      </FirstRow>
      <SecondRow>
        <DropdownBox>
          <Dropdown basic fluid selection options={actor_options} value={actor} onChange={handleActorChange} />
        </DropdownBox>
        <DropdownBox>
          <Dropdown
            basic
            fluid
            selection
            options={feature_options}
            value={feature}
            onChange={handleFeatureTypeChange}
          />
        </DropdownBox>
        <DropdownBox>
          <Dropdown basic fluid selection options={status_options} value={status} onChange={handleStatusChange} />
        </DropdownBox>
      </SecondRow>
    </Container>
  );
};
