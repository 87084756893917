import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Table, Button, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { saveCustomKeyword, getKeywordBreakdown } from 'apis';
import { useQuery } from 'react-query';
import { parseErrorResponse } from 'apis/utils';
import { capitalize, queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { CsvExport } from '../../common/CsvExport';
import { PageSection } from '../../common';
import { formatCount, percent } from '../../utils';
import { PercentCell } from '../../common/PercentCell';

const prepareCsvContent = (keywordBreakdown, t) => {
  const totalLeads = keywordBreakdown.reduce((r, v) => r + v.leads, 0);

  const headers = [
    'Keyword',
    'Website Visitors',
    'VSA Users',
    'Interaction Rate (%)',
    capitalize(t('leads')),
    `% of ${capitalize(t('leads'))} (%)`,
    `Avg. ${capitalize(t('move_ins'))} Likelihood`,
    'Visitor Conversion Rate (%)',
    'VSA User Conversion Rate (%)',
    'Financially Unqualified (%)',
    capitalize(t('move_ins')),
  ];

  const content = keywordBreakdown.map((k) => [
    k.keyword_value.includes('+') ? `[broad] ${k.keyword_value}` : k.keyword_value,
    k.visitors,
    k.interactions,
    percent(k.interactions, k.visitors, { zeroBased: true }).toFixed(4),
    k.leads,
    percent(k.leads, totalLeads, { zeroBased: true }).toFixed(4),
    k.avg_lead_score?.toFixed(1) || '-',
    percent(k.leads, k.visitors, { zeroBased: true }).toFixed(4),
    percent(k.leads, k.interactions, { zeroBased: true }).toFixed(4),
    percent(k.unqualified, k.leads, { zeroBased: true }).toFixed(4),
    k.moveins,
  ]);

  return [headers, content];
};

const TrafficByKeyword = ({ filters, publicId }) => {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');
  const [newKeyword, setNewKeyword] = useState('');
  const [keywordBreakdown, setKeywordBreakdown] = useState([]);
  const [editingKeyword, setEditingKeyword] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const totalLeads = keywordBreakdown.reduce((r, v) => r + v.leads, 0);

  const { data, status, error, isFetching, refetch } = useQuery(
    [queryConstants.KEYWORD_BREAKDOWN, { publicId, filters }],
    () => getKeywordBreakdown({ public_id: publicId, filters })
  );
  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch keyword traffic breakdown'));
  }

  useEffect(() => {
    if (data) {
      setKeywordBreakdown(data.traffic_breakdown);
      setKeyword(data.keyword);
    }
  }, [data]);

  const saveKeyword = async () => {
    if (newKeyword.length > 20) {
      toast.error('Keyword is too long');
      return;
    }
    setIsSaving(true);
    try {
      await saveCustomKeyword(newKeyword);
      setKeyword(newKeyword);
      setEditingKeyword(false);
      setNewKeyword('');
      refetch();
    } catch (error) {
      const msg = 'There was an error saving new keyword';
      console.error(msg, error?.response);
      toast.error(msg);
    } finally {
      setIsSaving(false);
    }
  };

  const [csvHeaders, csvContent] = prepareCsvContent(keywordBreakdown, t);

  return (
    <PageSection title="Keyword Breakdown" loaderStatus={loaderStatus}>
      <Table className="fixed-size">
        <Table.Header>
          <Table.Row textAlign="right">
            <Table.HeaderCell textAlign="left">
              {editingKeyword ? (
                <>
                  <Input type="text" size="mini" placeholder="Keyword..." action loading={isSaving}>
                    <input
                      value={newKeyword}
                      onChange={(e) => {
                        setNewKeyword(e.target.value);
                      }}
                    />
                    <Button icon="check" onClick={saveKeyword} />
                    <Button
                      icon="cancel"
                      onClick={() => {
                        setNewKeyword('');
                        setEditingKeyword(false);
                      }}
                    />
                  </Input>
                </>
              ) : (
                <Button
                  icon="edit"
                  label={keyword}
                  labelPosition="left"
                  onClick={() => {
                    if (!publicId) {
                      setNewKeyword(keyword);
                      setEditingKeyword(true);
                    }
                  }}
                />
              )}
            </Table.HeaderCell>
            <Table.HeaderCell>Website Visitors</Table.HeaderCell>
            <Table.HeaderCell>VSA Users</Table.HeaderCell>
            <Table.HeaderCell>Interaction Rate</Table.HeaderCell>
            <Table.HeaderCell>{capitalize(t('leads'))}</Table.HeaderCell>
            <Table.HeaderCell>% of {capitalize(t('leads'))}</Table.HeaderCell>
            <Table.HeaderCell>Avg. {capitalize(t('move_ins'))} Likelihood</Table.HeaderCell>
            <Table.HeaderCell>Visitor Conversion Rate</Table.HeaderCell>
            <Table.HeaderCell>VSA User Conversion Rate</Table.HeaderCell>
            <Table.HeaderCell>Financially Unqualified</Table.HeaderCell>
            <Table.HeaderCell>{capitalize(t('move_ins'))}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {keywordBreakdown.map((k, i) => (
            <Table.Row textAlign="right" key={i}>
              <Table.Cell textAlign="left">{k.keyword_value}</Table.Cell>
              <Table.Cell>{formatCount(k.visitors)}</Table.Cell>
              <Table.Cell>{formatCount(k.interactions)}</Table.Cell>
              <Table.Cell>
                <PercentCell value={percent(k.interactions, k.visitors)} />
              </Table.Cell>
              <Table.Cell>{formatCount(k.leads)}</Table.Cell>
              <Table.Cell>
                <PercentCell value={percent(k.leads, totalLeads)} />
              </Table.Cell>
              <Table.Cell>{k.avg_lead_score?.toFixed(1) || '-'}</Table.Cell>
              <Table.Cell>
                <PercentCell value={percent(k.leads, k.visitors)} />
              </Table.Cell>
              <Table.Cell>
                <PercentCell value={percent(k.leads, k.interactions)} />
              </Table.Cell>
              <Table.Cell>
                <PercentCell value={percent(k.unqualified, k.leads)} />
              </Table.Cell>
              <Table.Cell>{formatCount(k.moveins)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={8} textAlign="right">
              <CsvExport data={csvContent} headers={csvHeaders} filename="keyword_breakdown.csv" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </PageSection>
  );
};

export default TrafficByKeyword;
