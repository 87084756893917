import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { Icon, Dimmer, Loader } from 'semantic-ui-react';
import { useField } from 'formik';

import { If } from '../If';

const TrashIcon = styled(Icon)`
  position: absolute;
  top: ${(props) => props.$topPosition || '15px'};
  right: ${(props) => props.$rightPosition || '15px'};
  z-index: 10;
  cursor: pointer;
  &.icon {
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  background-color: #e8e8e8;
  height: 10rem;
  width: 16rem;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'grab')};
  outline: none;

  opacity: ${(p) => (p.disabled ? 0.6 : 1)};

  &:hover {
    box-shadow: ${(p) =>
      p.disabled
        ? '0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent'
        : '0 0 0 1px rgba(34, 36, 38, 0.52) inset, 0 0 0 0 transparent'};
    ${TrashIcon} {
      &.icon {
        opacity: 1;
      }
    }
  }

  div {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    height: 6rem;
    max-width: 100%;
  }
  p {
    margin-top: 1rem;
  }
  &.full {
    width: 100%;
    height: 100%;
    max-width: 215px;
    img {
      max-height: 215px;
      height: 80%;
      vertical-align: top;
    }
  }
`;

const OptionalLink = styled.a`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

export const ImagePicker = ({ onImageSelected, onImageDelete, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;
  const { disabled, className, openOnClick } = props;
  const [uploading, setUploading] = React.useState(false);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles?.length === 1) {
        const file = acceptedFiles[0];
        if (typeof onImageSelected === 'function') {
          try {
            setUploading(true);
            const resp = await onImageSelected(file);
            setUploading(false);
            setValue(resp);
          } catch (e) {
            console.error(e);
          }
        }
      }
    },
    [onImageSelected, setValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: ['.png', '.jpg', '.jpeg'],
  });

  const onTrashClick = async (e) => {
    e.stopPropagation();
    if (typeof onImageDelete === 'function') {
      try {
        setUploading(true);
        const resp = await onImageDelete();
        setUploading(false);
        setValue(resp);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const rootProps = { ...getRootProps(), disabled };
  const inputProps = { ...getInputProps(), disabled };

  const hasDelete = typeof onImageDelete === 'function';

  const acceptNewImageOnClick = !value || (value && !openOnClick);

  return (
    <Wrapper {...rootProps} className={className}>
      <input {...inputProps} disabled={disabled || !acceptNewImageOnClick} />

      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <div>
          <If condition={value && hasDelete}>
            <TrashIcon
              name="trash"
              color="red"
              onClick={(e) => onTrashClick(e)}
              $topPosition={props.trashIconTop}
              $rightPosition={props.trashIconRight}
            />
          </If>
          {value ? (
            <OptionalLink href={value} target="_blank" rel="noreferrer" disabled={!openOnClick}>
              <img src={value} alt={field.name} />
            </OptionalLink>
          ) : (
            <>
              <Icon name="image outline" size="big" color="grey" />
              <p>
                Drop image here or <br /> click to select file
              </p>
            </>
          )}
          <Dimmer active={uploading}>
            <Loader />
          </Dimmer>
        </div>
      )}
    </Wrapper>
  );
};
