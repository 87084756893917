import { API } from '../api';

export const createValidateChats = async (data) => {
  const response = await API.post('/api/chat/validation', data);
  return response.data;
};

export const createChatTemplate = async (data) => {
  const response = await API.post('/api/v1/chat-templates/', data);
  return response.data;
};

export const updateChatTemplate = async (data) => {
  const response = await API.put(`/api/v1/chat-templates/${data.id}/`, data);
  return response.data;
};

export const fetchChatTemplates = async (params) => {
  const query = new URLSearchParams(params).toString();
  const response = await API.get(`/api/v1/chat-templates/?${query}`);
  return response.data;
};

export const createExperiment = async (data) => {
  const response = await API.post('/api/chat/experiments', data);
  return response.data;
};

export const fetchExperiments = async (params) => {
  const query = new URLSearchParams(params).toString();
  const response = await API.get(`/api/chat/experiments?${query}`);
  return response.data;
};
