import humps from 'humps';

import { API } from '../api';

export const getManagementUsers = async () => {
  const response = await API.get('/api/management/users');
  return humps.camelizeKeys(response.data);
};

export const administrateUser = async (userId, data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.post(`/api/management/users/${userId}/administrate`, body);
  return humps.camelizeKeys(response.data);
};

export const getManagementCurrentUser = async () => {
  const response = await API.get('/api/management/users/current');
  return response.data;
};

export const addManagementUser = async (data) => {
  const body = humps.decamelizeKeys(data);
  const response = await API.post(`/api/management/users`, body);
  return humps.camelizeKeys(response.data);
};

export const createManagementUserInvite = async (id) => {
  const response = await API.post(`/api/management/${id}/invite`);
  return humps.camelizeKeys(response.data);
};
