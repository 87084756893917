import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { Metric } from 'components/analytics/common';
import { ReportComponent } from 'components/common/analytics';
import { formatCount, formatPercent } from 'components/utils';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const nonMaMetrics = ['leads.move_ins', 'leads.total', 'leads.move_ins.replied', 'leads.replied'];

const aggregateCommunityStats = (data) => {
  const moveInCommunities = data['leads.move_ins']?.groups
    .filter(({ value }) => value > 0)
    .map(({ community_id }) => community_id);
  return Object.keys(data).reduce((vals, m) => {
    vals[m] = data[m].groups.reduce((total, g) => {
      if (moveInCommunities.includes(g.community_id)) {
        return total + g.value;
      }
      return total;
    }, 0);
    return vals;
  }, {});
};

const LeadMoveinsOther = () => {
  const { saleAssistantFilters: filters } = useSystemStatsFilters();
  const { start_date, end_date } = filters;

  const nonMaFilters = { start_date, end_date, ma_enabled: false, group_by: 'community_id', limit: 100 };
  const { data, status, error, isFetching } = useQuery(
    [queryConstants.ADMIN_METRICS, { ...nonMaFilters, nonMaMetrics }],
    () => getMetrics(nonMaMetrics, nonMaFilters),
    { placeholderData: {} }
  );

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch non MA lead movein metrics'));
  }

  const nonMaVals = useMemo(() => aggregateCommunityStats(data), [data]);

  return (
    <ReportComponent
      title="Move-ins for Top Communities without MA"
      tooltip="These are stats for 100 communities without MA with most move-ins. It is used for comparison purposes."
      contentMargins="20px 0"
      contentHeight="150px"
    >
      <div>
        <Metric
          label="Overall Conversion Rate"
          value={formatPercent(nonMaVals['leads.move_ins'], nonMaVals['leads.total'])}
          description={`${formatCount(nonMaVals['leads.move_ins'])} move-ins from ${formatCount(
            nonMaVals['leads.total']
          )} leads`}
        />
      </div>
      <div>
        <Metric
          label="Active Leads Conversion Rate"
          value={formatPercent(nonMaVals['leads.move_ins.replied'], nonMaVals['leads.replied'])}
          description={`${formatCount(nonMaVals['leads.move_ins.replied'])} move-ins from ${formatCount(
            nonMaVals['leads.replied']
          )} leads`}
        />
      </div>
    </ReportComponent>
  );
};

export default LeadMoveinsOther;
