import React from 'react';
import { useTranslation } from 'react-i18next';

import { BooleanSettingsPage } from '../common';

export const LeadReturns = () => {
  const { t } = useTranslation();
  return (
    <BooleanSettingsPage
      flagName="enable_lead_revisit_processing"
      modalText={`Choose if an email is sent when a ${t('lead')} retuns to the website at a later date.`}
      modalTitle={`Notify when a ${t('lead')} returns to the website`}
    />
  );
};
