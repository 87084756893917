import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Header, Table, Button, Checkbox, Dimmer, Loader, Message, Form } from 'semantic-ui-react';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { InputField } from 'components/common';
import { getCommunitiesForLoggedUser, fetchChatTemplates } from 'apis';
import { Dropdown } from 'components/lib';
import { capitalize } from 'utils/helpers';
import { createChat } from '../../api';
import { STATUSES } from '../../constants';
import { VSA_TYPE, CHAT_TMPLATE_ORDER_BY_TYPE_MAPING } from './constants';
import { ChatTemplateItem } from './ChatTemplateItem';

const Wrapper = styled.div`
  position: relative;
  min-height: 35rem;

  .ui {
    &.header {
      margin: 2rem 0;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .fields {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
    }

    .field {
      max-width: fit-content;
      &.option {
        margin-top: 5px;
        display: inline-block;
        max-width: none;
      }
    }
  }
`;

const StyledDropdown = styled(InputField)`
  && {
    min-width: 320px;
    max-width: 100%;
    .visible.menu {
      min-width: 320px;
    }
  }
`;

const templateToTypeMapper = {
  SENIOR_LIVING: VSA_TYPE.REGULAR,
  SENIOR_LIVING_HOME_PAGE: VSA_TYPE.REGULAR,
  SHERPA: VSA_TYPE.REGULAR,
  BASIC_EMBEDDED: VSA_TYPE.EMBEDDED,
  POST_TOUR: VSA_TYPE.SURVEY,
  BASIC_IVR: VSA_TYPE.PHONE,
  BASIC_PHONE_NUMBER_SYSTEM: VSA_TYPE.PHONE,
  TIME_DRIVEN_TEMPLATE: VSA_TYPE.PHONE,
};

const blankChatTemplate = {
  id: 0,
  name: 'None (start from scratch)',
  vsa_type: VSA_TYPE.REGULAR,
};

const RelatedCommunitiesField = (props) => {
  const { t } = useTranslation();
  const [, , helpers] = useField(props);
  const [communities, setCommunities] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  useEffect(() => {
    const loadCommunities = async () => {
      setLoaderStatus(STATUSES.LOADING);
      try {
        const response = await getCommunitiesForLoggedUser({});
        if (response?.results) {
          const communities = response.results.map((p) => ({ ...p, linkToClonedChat: false }));
          setCommunities(communities);
        }
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        console.error(e);
        setLoaderStatus(STATUSES.FAILURE);
      }
    };
    loadCommunities();
  }, []);

  const setFieldValue = () => {
    helpers.setValue(communities.filter((p) => p.linkToClonedChat).map((p) => p.id));
  };

  const getChatLinks = (community, vsaType) => {
    if (!community?.chat_properties) return 'NONE';

    const chatProperties = community.chat_properties.filter((property) => property.vsa_type === vsaType);

    if (chatProperties.length === 0) return 'NONE';

    return chatProperties.map((property, index) => (
      <Link key={index} to={`/chats/${property.chat}`}>
        {property.chat}&nbsp;
      </Link>
    ));
  };

  return (
    <>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <Message
        info
        header={`Choose Related ${capitalize(t('communities'))}`}
        content={`Choose which ${capitalize(
          t('communities')
        )} will be linked to the created chat. You won't be able to create a chat unless at least one ${capitalize(
          t('community')
        )} is selected.`}
        attached="top"
      />

      {loaderStatus === STATUSES.LOADED && (
        <Table singleLine attached="bottom">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>{capitalize(t('community'))} name</Table.HeaderCell>
              <Table.HeaderCell>Currently linked chat</Table.HeaderCell>
              <Table.HeaderCell>Currently linked Embedded VSAs</Table.HeaderCell>
              <Table.HeaderCell>Currently linked Voice VSAs</Table.HeaderCell>
              <Table.HeaderCell>Currently linked Survey VSAs</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {communities &&
              communities.map((community, i) => (
                <Table.Row key={i}>
                  <Table.Cell collapsing textAlign="right">
                    <Checkbox
                      onChange={(e, d) => {
                        community.linkToClonedChat = d.checked;
                        setFieldValue();
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>{community?.name}</Table.Cell>
                  <Table.Cell>{getChatLinks(community, VSA_TYPE.REGULAR)}</Table.Cell>
                  <Table.Cell>{getChatLinks(community, VSA_TYPE.EMBEDDED)}</Table.Cell>
                  <Table.Cell>{getChatLinks(community, VSA_TYPE.PHONE)}</Table.Cell>
                  <Table.Cell>{getChatLinks(community, VSA_TYPE.SURVEY)}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      )}

      {loaderStatus === STATUSES.FAILURE && (
        <Message negative>
          <Message.Header>Couldn't load {capitalize(t('communities'))}</Message.Header>
          <p>
            Something went wrong and we couldn't load available {capitalize(t('communities'))}. Please try again later.
          </p>
        </Message>
      )}
    </>
  );
};

export const chatTmplateOrderByType = (type) => CHAT_TMPLATE_ORDER_BY_TYPE_MAPING[type] || 0;

const validationSchema = Yup.object().shape({
  template_id: Yup.number().required('Please choose chat template'),
  related_communities: Yup.array().min(1, 'Please choose at least one community'),
});

export const CreateChat = () => {
  const history = useHistory();
  const [loadingCreateChat, setLoadingCreateChat] = useState(false);
  const [chatTemplates, setChatTemplates] = useState([]);
  const getChatTemplates = async () => {
    const templates = await fetchChatTemplates();
    setChatTemplates([blankChatTemplate, ...templates.results]);
  };

  useEffect(() => {
    getChatTemplates();
  }, []);

  const submit = async ({ related_communities, template_id }) => {
    if (related_communities.length === 0) {
      toast.error('Please choose at least one community to link to the new chat.');
    } else {
      try {
        setLoadingCreateChat(true);

        const { id } = await createChat({
          related_communities,
          template_id,
        });

        toast.success('Chat successfully created');
        history.push(`/chats/${id}`);
      } catch (e) {
        toast.error('Error: something went wrong and the chat could not be created.');
        setLoadingCreateChat(false);
      }
    }
  };

  const initialValues = {
    template: undefined,
    related_communities: [],
  };

  const chatTemplatesList = useMemo(() => {
    return chatTemplates
      .sort((a, b) => chatTmplateOrderByType(a.vsa_type) - chatTmplateOrderByType(b.vsa_type))
      .map(({ id, name, vsa_type }) => ({
        key: id,
        value: id,
        text: name,
        content: <ChatTemplateItem text={name} type={vsa_type} />,
      }));
  }, [chatTemplates]);

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={submit} validationSchema={validationSchema}>
        {({ handleSubmit, values, isValid }) => (
          <>
            <Header as="h2">
              <Header.Content>
                Create New Chat
                <Header.Subheader>Make a new chat from scratch, or choose a predefined chat template.</Header.Subheader>
              </Header.Content>
              <Button
                disabled={loadingCreateChat || !isValid}
                loading={loadingCreateChat}
                floated="right"
                primary
                content="Create"
                type="submit"
                onClick={handleSubmit}
              />
            </Header>

            <Form.Group>
              <label>Chat Template</label>
              <StyledDropdown
                basic
                fluid
                search
                name="template_id"
                control={Dropdown}
                value={values.template}
                options={chatTemplatesList}
                placeholder="Please select chat template"
              />
            </Form.Group>

            <RelatedCommunitiesField name="related_communities" />
          </>
        )}
      </Formik>
    </Wrapper>
  );
};
