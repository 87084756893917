import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'components/lib';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import { fetchCommunityScrapeResults } from 'apis';
import { If } from '../../If';
import { UserCommunityScrapeResults } from './UserCommunityScrapeResults';
import { CommunitiesContext } from '../../common/communities/CommunitiesContext';
import { STATUSES } from '../../../constants';
import { CommunityAssetsContext } from '../CommunityAssetsContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
`;

export const UserCommunityScrape = () => {
  const { t } = useTranslation();
  const { state } = useContext(CommunitiesContext);
  const { scrapeResults, setScrapeResults } = useContext(CommunityAssetsContext);

  const { activeCommunity } = state;

  const [url, setUrl] = useState('');
  const [scrapingStatus, setScrapingStatus] = useState(STATUSES.IDLE);

  const onScrape = async () => {
    try {
      setScrapingStatus(STATUSES.LOADING);
      const response = await fetchCommunityScrapeResults(activeCommunity.id, url);

      setScrapeResults(response);

      toast.success('Asset scraping finished successfully.');
      setScrapingStatus(STATUSES.LOADED);
    } catch (error) {
      const msg = 'Trouble scraping requested URL';
      console.error(msg, error?.response);
      toast.error(msg);
      setScrapingStatus(STATUSES.FAILURE);
    }
  };

  const hasScrapeResults = Object.keys(scrapeResults).length > 0;
  const scraping = scrapingStatus === STATUSES.LOADING;

  return (
    <Container>
      <h5>Provide target website URL to scrape for {t('community')} assets.</h5>
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            placeholder="Website URL"
            disabled={scraping}
            name="weburl"
            onChange={(e) => setUrl(e.target.value)}
            value={url}
          />
          <Button
            size="tiny"
            primary
            disabled={scraping}
            loading={scraping}
            onClick={() => onScrape()}
            content="Scrape"
          />
        </Form.Group>
      </Form>

      <If condition={hasScrapeResults}>
        <UserCommunityScrapeResults />
      </If>
    </Container>
  );
};
