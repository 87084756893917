import React, { useContext } from 'react';
import styled from 'styled-components';

import { Icon } from 'components/lib';
import { ConversationContext } from './ConversationContext';

const ArchivedToastWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ArchivedToastAction = styled.div`
  padding: 0 1rem;
  cursor: pointer;

  &:hover {
    color: #f0f0f0;
  }
`;

export const ConversationArchivedToast = ({ conversation, undo }) => {
  return (
    <ArchivedToastWrapper>
      <Icon name="inventory_2" variant="outlined" />
      <div>
        Conversation with {conversation.name} has been{' '}
        <a style={{ color: 'white', textDecoration: 'underline' }} href={`/messages/archived/${conversation.id}`}>
          archived
        </a>
      </div>
      <ArchivedToastAction
        onClick={() => {
          undo(conversation);
        }}
      >
        Undo
      </ArchivedToastAction>
    </ArchivedToastWrapper>
  );
};
