import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Icon, Label } from 'semantic-ui-react';

const Wrapper = styled.div`
  .ui.label {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const Links = (props) => {
  const [, meta, helpers] = useField(props.name);
  const values = meta.value || [];
  const { setValue } = helpers;
  const [tmp, setTmp] = React.useState('');

  return (
    <Wrapper>
      {values.map((v, i) => (
        <Label key={i}>
          {v}
          <Icon
            name="delete"
            onClick={() => {
              setValue(values.filter((value) => value !== v));
            }}
          />
        </Label>
      ))}
      <input
        type="text"
        value={tmp}
        onChange={(e) => {
          setTmp(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.target.value !== '' && (e.key === ',' || e.key === 'Enter')) {
            const set = new Set([...values, ...e.target.value.split(',')].map((v) => v.trim()));
            setValue([...set]);
          }
        }}
        onKeyUp={(e) => {
          if (e.target.value !== '' && (e.key === ',' || e.key === 'Enter')) {
            setTmp('');
          }
        }}
        placeholder="＋ Add new"
      />
    </Wrapper>
  );
};
