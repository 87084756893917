import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

import { getMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { ReportComponent, TimeSeriesChart, getLabels } from 'components/common/analytics';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { STATUSES } from 'constants';

import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const METRIC_DATASET = {
  'messages.sales_assistant.avg_response_time': {
    label: 'All Messages',
    borderColor: '#cfcf1f',
    backgroundColor: 'transparent',
  },
  'messages.sales_assistant.first.avg_response_time': {
    label: 'First Lead Messages',
    borderColor: '#17bd2a',
    backgroundColor: 'transparent',
  },
  'messages.sales_assistant.local_working_hours.avg_response_time': {
    label: 'Local Working Hours Messages',
    borderColor: '#2b43a1',
    backgroundColor: 'transparent',
  },
  'messages.sales_assistant.further_working_hours.avg_response_time': {
    label: 'Further Working Hours Messages',
    borderColor: '#b33024',
    backgroundColor: 'transparent',
  },
};

const tooltip = (
  <ul>
    <li>All Messages: Avg. MA response time for all messages.</li>
    <li>First Lead Messages: Avg. MA response time for first lead messages.</li>
    <li>Local Working Hours Messages: Avg. MA response time for lead messages that came within local working hours.</li>
    <li>
      Further Working Hours Messages: Avg. MA response time for lead messages that came within Further working hours.
    </li>
  </ul>
);

const groupBy = 'time';
const metrics = [
  'messages.sales_assistant.avg_response_time',
  'messages.sales_assistant.first.avg_response_time',
  'messages.sales_assistant.local_working_hours.avg_response_time',
  'messages.sales_assistant.further_working_hours.avg_response_time',
];

const MessagesResponseTimesTrendsGraph = () => {
  const { dateRange, saleAssistantFilters: filters } = useSystemStatsFilters();
  const maFilters = { ...filters, ma_enabled: true };

  const { data, error, status, isFetching } = useQuery(
    [queryConstants.ADMIN_METRICS, { ...maFilters, metrics }],
    () => getMetrics(metrics, maFilters, groupBy),
    { placeholderData: {} }
  );

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    console.error(error);
    toast.error(parseErrorResponse(error, 'Unable to fetch lead trend metrics'));
  }

  const chartData = useMemo(() => {
    const labels = getLabels(dateRange.start_date, dateRange.end_date);

    if (!data || !data[metrics[0]]) {
      return { labels, datasets: [] };
    }
    const dataLookup = metrics.reduce((lookup, m) => {
      lookup[m] = data[m].groups.reduce((r, obj) => {
        r[obj.time] = Number.parseInt(obj.value);
        return r;
      }, {});
      return lookup;
    }, {});

    const datasets = metrics
      .filter((m) => METRIC_DATASET[m])
      .map((m) => ({
        ...METRIC_DATASET[m],
        data: labels.map((l) => dataLookup[m][l] || 0),
      }));

    return { labels, datasets };
  }, [data, dateRange]);

  return (
    <ReportComponent
      title="Avg. Response Times over Time"
      tooltip={tooltip}
      loading={loaderStatus === STATUSES.LOADING}
    >
      <TimeSeriesChart data={chartData} height={300} />
    </ReportComponent>
  );
};

export default MessagesResponseTimesTrendsGraph;
