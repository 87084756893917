import { API } from 'api';

export const getSupportModeUsers = async (params) => {
  const response = await API.get(`/api/support-mode/users`, { params });
  return response.data;
};

export const createSupportMode = async (data) => {
  const response = await API.post(`/api/support-mode/users`, data);
  return response.data;
};

export const getSupportModeCurrentUser = async () => {
  const response = await API.get('/api/support-mode/current');
  return response.data;
};
