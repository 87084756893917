import React, { useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { useAssignedReviews } from 'components/common/automated_answers';
import { Button, Icon } from 'components/lib';
import { AutomationReviewList } from './AutomationReviewList';
import { AutomationReviewDeclineModal } from './AutomationReviewDeclineModal';

const GetMoreButton = styled(Button)`
  &.ui.button {
    margin: 48px auto 0;
    display: flex;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
`;

export const AutomationReview = () => {
  const { reviews, isLoading, handleAccept, handleNewSession, isFetching, isLoadingNewSession } = useAssignedReviews();
  const [decliningReview, setDecliningReview] = useState();
  const needMore = reviews?.length === 0;
  return (
    <div>
      <Dimmer active={isLoading || (isFetching && needMore)} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {needMore ? (
        <div>
          <GetMoreButton icon primary color="blue" size="big" onClick={handleNewSession} disabled={isLoadingNewSession}>
            <div>
              <Icon size={24} name="rate_review" /> Start new session
            </div>
          </GetMoreButton>
        </div>
      ) : (
        <AutomationReviewList reviews={reviews} onAccept={handleAccept} onDecline={setDecliningReview} />
      )}
      {decliningReview && (
        <AutomationReviewDeclineModal open review={decliningReview} onClose={() => setDecliningReview(null)} />
      )}
    </div>
  );
};
