import styled from 'styled-components';

export const DetailsRowValue = styled.div`
  text-align: right;
  color: var(--text-color);
  font-family: var(--font-family-bold);
  display: grid;

  .footnote {
    font-family: var(--font-family);
    font-size: 10px;
    line-height: 10px;
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 30px;
  border-bottom: solid 1px #eaeaeb;
  font-family: var(--font-family);
  gap: 20px;
  &.center {
    align-items: center;
  }

  &.dense {
    padding: 6px 30px;

    ${DetailsRowValue} {
      line-height: 14px;
    }
  }

  &:nth-child(odd) {
    background-color: #fcfcfc;
  }
`;

export const DetailsListValues = styled.div`
  word-break: break-all;

  ${DetailsRowValue} {
    line-height: 14px;
    margin-bottom: 10px;
  }
`;
