import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { capitalize } from 'utils/helpers';
import { CalendarRequest } from './CalendarRequest';
import { CALENDAR_REQUEST_TYPE } from './constants';
import { MessageContext } from '../MessageContext';

export const CalendarRequestContext = React.createContext();

const StyledTab = styled(Tab)`
  && {
    .tabular.menu {
      flex-wrap: wrap;
    }

    .tabular > .item {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      border-radius: 4px;

      &:not(.active) {
        background-color: #f3f4f5;
        border-top: 1px solid #d4d4d5;

        &:first-child {
          border-left: 1px solid #d4d4d5;
        }

        &:last-child {
          border-right: 1px solid #d4d4d5;
        }
      }
    }
  }
`;

export const CalendarRequests = () => {
  const { t } = useTranslation();
  const { lead } = useContext(MessageContext);

  // Render Call calendar request pane only if no Tour request is present
  const initialPane = (lead?.call && !lead?.tour) || (lead?.call?.confirmed && !lead?.tour?.confirmed) ? 1 : 0;
  const [activePane, setActivePane] = useState(initialPane);

  const handleTabChange = (e, { activeIndex }) => setActivePane(activeIndex);

  const tourMenuItemLabel = lead?.tour
    ? lead.tour.confirmed
      ? `${capitalize(t('tour'))} Confirmed`
      : `${capitalize(t('tour'))} Requested`
    : `Request ${capitalize(t('tour'))}`;
  const callMenuItemLabel = lead?.call ? (lead.call.confirmed ? 'Call Confirmed' : 'Call Requested') : 'Request Call';

  const panes = [
    {
      menuItem: tourMenuItemLabel,
      render: () => (
        <Tab.Pane>
          <CalendarRequest type={CALENDAR_REQUEST_TYPE.TOUR} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: callMenuItemLabel,
      render: () => (
        <Tab.Pane>
          <CalendarRequest type={CALENDAR_REQUEST_TYPE.CALL} />
        </Tab.Pane>
      ),
    },
  ];

  return <StyledTab panes={panes} activeIndex={activePane} onTabChange={handleTabChange} />;
};
