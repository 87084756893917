import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Message, Table } from 'semantic-ui-react';

import { formatAnyDate } from 'components/utils';
import PromptModal from 'components/common/PromptModal';
import { LinkButton } from '../../common/LinkButton';
import TrashCan from '../../common/TrashCan';

const ReportsList = ({ reports, onDelete }) => {
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const toggleConfirmDeleteModal = (publicId) => {
    setConfirmDeleteModalOpen(!confirmDeleteModalOpen);
    setSelectedReport(reports.find((report) => report.publicId === publicId) || null);
  };

  const handleDelete = (publicId) => () => {
    onDelete(publicId);
    toggleConfirmDeleteModal();
  };

  if (!reports) {
    return null;
  }

  if (!reports.length) {
    return <Message>You don't have saved reports</Message>;
  }

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Report name</Table.HeaderCell>
          <Table.HeaderCell>Create Date</Table.HeaderCell>
          <Table.HeaderCell>Show Report</Table.HeaderCell>
          <Table.HeaderCell>Delete</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {reports.map((report) => (
          <Table.Row key={report.publicId}>
            <Table.Cell>{report.name}</Table.Cell>
            <Table.Cell>{formatAnyDate(report.createdOn, 'yyyy-MM-dd')}</Table.Cell>
            <Table.Cell>
              <Link to={`/public-report/${report.publicId}`}>
                <LinkButton>Open</LinkButton>
              </Link>
            </Table.Cell>
            <Table.Cell>
              <TrashCan onClick={() => toggleConfirmDeleteModal(report.publicId)} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <PromptModal
        open={confirmDeleteModalOpen}
        title={`Delete report ${selectedReport?.name}`}
        description="Are you sure you want to delete report? This action cannot be undone."
        onConfirm={handleDelete(selectedReport?.publicId)}
        onClose={toggleConfirmDeleteModal}
      />
    </Table>
  );
};

export default ReportsList;
