import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { Form, Radio, TextArea } from 'components/lib';

import { STATUSES, EMAIL_ADDITIONAL_NOTE } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';

const ModalBody = styled.div``;

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 10px;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const CustomRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:first-child) {
    margin-left: 30px;
  }
  > span {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 6px;
  }
`;
const TextAreaField = styled(Form.Field)`
  textarea {
    width: 436px;
    height: 250px;
    padding: 8px 5px 0 12px !important;
    border: solid 1px var(--line-standard) !important;
    background-color: var(--input-field-fill) !important;
  }
  label {
    line-height: 2;
    margin-right: 10px;
    vertical-align: top;
    font-weight: 900;
  }
`;

export default function AdditionalEmailNoteModal({
  isModalOpen,
  selectedItem,
  flagName,
  totalItemsCount,
  onSave,
  onClose,
}) {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    const value = !values[flagName] ? null : values.note;

    await onSave({ [flagName]: value });

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const initialValues = { [flagName]: selectedItem?.[flagName], note: selectedItem?.[flagName] };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue }) => (
        <SettingsModal
          loading={loading}
          open={isModalOpen}
          onModalClose={() => handleClose(resetForm)}
          size="tiny"
          width={540}
        >
          <SettingsModalHeader
            title="Custom note in emails"
            community={selectedItem || null}
            totalItemsCount={totalItemsCount}
            type="header"
          />
          <ModalBody type="body">
            <BodyText>Include a custom note directed to community users receiving the email.</BodyText>
            <RadioWrapper>
              <CustomRadio>
                <Form.Field
                  control={Radio}
                  name={flagName}
                  value={String(EMAIL_ADDITIONAL_NOTE.ON.value)}
                  checked={Boolean(values[flagName])}
                  onChange={() => {
                    setFieldValue(flagName, EMAIL_ADDITIONAL_NOTE.ON.value);
                  }}
                />
                <span>{EMAIL_ADDITIONAL_NOTE.ON.text}</span>
              </CustomRadio>
              <CustomRadio>
                <Form.Field
                  control={Radio}
                  name={flagName}
                  value={String(EMAIL_ADDITIONAL_NOTE.OFF.value)}
                  checked={String(values[flagName]) === String(EMAIL_ADDITIONAL_NOTE.OFF.value)}
                  onChange={() => {
                    setFieldValue(flagName, EMAIL_ADDITIONAL_NOTE.OFF.value);
                  }}
                />
                <span>{EMAIL_ADDITIONAL_NOTE.OFF.text}</span>
              </CustomRadio>
            </RadioWrapper>
            {values[flagName] && (
              <TextAreaField
                control={TextArea}
                name="note"
                label="Note"
                value={values.note}
                placeholder="Custom Note"
                onChange={(e, { value }) => {
                  setFieldValue('note', value);
                }}
              />
            )}
          </ModalBody>
          <SettingsModalActions
            onSave={handleSubmit}
            onCancel={() => handleClose(resetForm)}
            type="actions"
            disabled={!dirty || !touched || (values[flagName] && !values.note)}
            saveText="Save"
          />
        </SettingsModal>
      )}
    </Formik>
  );
}
