import React, { useContext } from 'react';
import styled from 'styled-components';

import { If } from '../../If';
import { ConversationContext } from '../ConversationContext';
import { PANEL_MODE } from './CommunityPanels';
import { CommunityNotesEditPanel } from './CommunityNotesEditPanel';
import { PanelNoContent } from './PanelNoContent';

const Wrapper = styled.div`
  padding: 20px 30px;
  font-family: var(--font-family);
`;

export const CommunityNotes = ({ mode, onAction }) => {
  const { selectedConversation } = useContext(ConversationContext);

  const missingNotes = selectedConversation.community.sales_assistant_community_notes?.length === 0;

  if (missingNotes && mode === PANEL_MODE.PREVIEW) return <PanelNoContent />;

  return (
    <Wrapper>
      <If condition={mode === PANEL_MODE.PREVIEW}>
        <span>{selectedConversation.community.sales_assistant_community_notes}</span>
      </If>
      <If condition={mode === PANEL_MODE.EDIT}>
        <CommunityNotesEditPanel onAction={onAction} />
      </If>
    </Wrapper>
  );
};
