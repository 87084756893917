import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { getOverviewMetrics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { PageSection, Statistic, getChangeIcon } from 'components/common';
import { formatCount } from 'components/utils';
import { If } from 'components/If';

import { capitalize } from 'utils/helpers';
import { useSystemStatsFilters } from './SystemStatsFiltersContext';

const OverviewReport = () => {
  const { t } = useTranslation();
  const [metrics, setMetrics] = useState();
  const [prevMetrics, setPrevMetrics] = useState();
  const [loading, setLoading] = useState(false);
  const { dateRange, getPreviousDateRange, selectedUsers, selectedCommunities } = useSystemStatsFilters();

  useEffect(() => {
    const fetchMetrics = async (dateRange, onMetricsLoaded) => {
      try {
        setLoading(true);
        const params = {
          ...dateRange,
        };
        if (selectedUsers && selectedUsers.length) {
          params.sa_users = selectedUsers.join(',');
        }
        if (selectedCommunities && selectedCommunities.length) {
          params.sa_communities = selectedCommunities.join(',');
        }
        const _metrics = await getOverviewMetrics(params);
        onMetricsLoaded(_metrics);
      } catch (e) {
        toast.error(parseErrorResponse(e, 'Error fetching overview metrics'));
      }
      setLoading(false);
    };

    fetchMetrics(dateRange, setMetrics);
    // fetch stats for previous period
    fetchMetrics(getPreviousDateRange(), setPrevMetrics);
  }, [dateRange, selectedUsers, selectedCommunities]);

  return (
    <div>
      <Dimmer active={!metrics || loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <If condition={metrics}>
        <PageSection>
          <Statistic.Group name="Emails">
            <Statistic
              label={`Sent to ${capitalize(t('communities'))}`}
              value={formatCount(metrics?.emails_sent_to_communities)}
              tooltip="Number of emails sent to communities"
              changeIcon={getChangeIcon(metrics?.emails_sent_to_communities, prevMetrics?.emails_sent_to_communities)}
            />
            <Statistic
              label={`Opened by ${capitalize(t('communities'))}`}
              value={formatCount(metrics?.email_opened_by_communities)}
              tooltip={`Number of emails opened by ${t('communities')}`}
              changeIcon={getChangeIcon(metrics?.email_opened_by_communities, prevMetrics?.email_opened_by_communities)}
            />
            <Statistic
              label={`${capitalize(t('lead'))} Created Emails`}
              value={formatCount(metrics?.lead_created_emails)}
              tooltip={`Number of email notifications that ${t('lead')} is created`}
              changeIcon={getChangeIcon(metrics?.lead_created_emails, prevMetrics?.lead_created_emails)}
            />
          </Statistic.Group>
          <Statistic.Group name={capitalize(t('leads'))} marginTop="2rem">
            <Statistic
              label="Active"
              value={formatCount(metrics?.active_leads)}
              tooltip={`Number of ${t('leads')} who responded to the campaign message`}
              changeIcon={getChangeIcon(metrics?.active_leads, prevMetrics?.active_leads)}
            />
            <Statistic
              label={`Replied by ${capitalize(t('communities'))}`}
              value={formatCount(metrics?.community_replied_leads)}
              tooltip={`Number of active ${t('leads')} that were replied by sales team`}
              changeIcon={getChangeIcon(metrics?.community_replied_leads, prevMetrics?.community_replied_leads)}
            />
            <Statistic
              label={`Replied to ${capitalize(t('communities'))}`}
              value={formatCount(metrics?.community_active_leads)}
              tooltip={`Number of active ${t('leads')} that replied to sales team messages`}
              changeIcon={getChangeIcon(metrics?.community_active_leads, prevMetrics?.community_active_leads)}
            />
          </Statistic.Group>
          <Statistic.Group name="Status Changes" marginTop="2rem">
            <Statistic
              label={`${capitalize(t('tour'))} Scheduled`}
              value={formatCount(metrics?.scheduled_tours)}
              tooltip={`Number of ${t('leads')} that have ${t('tour')} scheduled`}
              changeIcon={getChangeIcon(metrics?.scheduled_tours, prevMetrics?.scheduled_tours)}
            />
            <Statistic
              label="Call Scheduled"
              value={formatCount(metrics?.scheduled_calls)}
              tooltip={`Number of ${t('leads')} that have call scheduled`}
              changeIcon={getChangeIcon(metrics?.scheduled_calls, prevMetrics?.scheduled_calls)}
            />
          </Statistic.Group>
        </PageSection>
      </If>
    </div>
  );
};

export default OverviewReport;
