import { capitalize } from 'utils/helpers';

export const SYNC_INTERVAL = 5000;

export const DEFAULT_PHONE_NUMBER_USAGE_VALUE = 'ALL';

const INACTIVE_PHONE_SYSTEM = 'INACTIVE_PHONE_SYSTEM';
const ACTIVE_PHONE_SYSTEM = 'ACTIVE_PHONE_SYSTEM';
const SYNCING_PHONE_DATA = 'SYNC_IN_PROGRESS';
const HAS_ERRORS = 'HAS_ERRORS';
const HAS_POOL_AND_PRIMARY_NUMBER = 'HAS_POOL_NUMBERS';
const HAS_CAMPAING_NUMBERS = 'HAS_CAMPAIGN_NUMBERS';
const HAS_CONVERSATIONAL_IVR = 'HAS_CONVERSATIONAL_IVR';
const USES_GLOBAL_SELECTOR = 'USES_GLOBAL_SELECTOR';
const USES_CUSTOM_SELECTOR = 'USES_CUSTOM_SELECTOR';

export const PHONE_NUMBER_FILTERS = {
  [DEFAULT_PHONE_NUMBER_USAGE_VALUE]: { key: 'phone_number_usage', value: undefined },
  [INACTIVE_PHONE_SYSTEM]: { key: 'phone_number_usage', value: false },
  [ACTIVE_PHONE_SYSTEM]: { key: 'phone_number_usage', value: true },
  [SYNCING_PHONE_DATA]: { key: 'active_sync_status', value: true },
  [HAS_ERRORS]: { key: 'has_errors', value: true },
  [HAS_POOL_AND_PRIMARY_NUMBER]: { key: 'has_pool_numbers', value: true },
  [HAS_CAMPAING_NUMBERS]: { key: 'has_dedicated_numbers', value: true },
  [HAS_CONVERSATIONAL_IVR]: { key: 'has_ivr_support', value: true },
  [USES_GLOBAL_SELECTOR]: { key: 'inherit_query_selector', value: true },
  [USES_CUSTOM_SELECTOR]: { key: 'inherit_query_selector', value: false },
};

export const PHONE_NUMBER_USAGE_OPTIONS = [
  { text: 'Any status', value: DEFAULT_PHONE_NUMBER_USAGE_VALUE },
  { text: 'Off', value: INACTIVE_PHONE_SYSTEM },
  { text: 'On', value: ACTIVE_PHONE_SYSTEM },
  { text: 'Syncing phone data', value: SYNCING_PHONE_DATA },
  { text: 'Something went wrong', value: HAS_ERRORS },
  { text: 'Has website and backup numbers', value: HAS_POOL_AND_PRIMARY_NUMBER },
  { text: 'Has campaign number(s)', value: HAS_CAMPAING_NUMBERS },
  { text: 'Has Conversational IVR active', value: HAS_CONVERSATIONAL_IVR },
  { text: 'Uses global query selector', value: USES_GLOBAL_SELECTOR },
  { text: 'Uses custom query selector', value: USES_CUSTOM_SELECTOR },
];

const DEFAULT_SYNC_STATUS = {
  display: false,
  text: null,
  color: null,
  formDisabled: false,
  loading: false,
};

export const SYNC_STATUS = {
  NONE: DEFAULT_SYNC_STATUS,
  ACTIVE: {
    ...DEFAULT_SYNC_STATUS,
    display: true,
    text: 'Syncing phone data…',
    color: 'var(--light-text)',
    formDisabled: true,
    loading: true,
  },
  COMPLETED: {
    ...DEFAULT_SYNC_STATUS,
    display: true,
    text: 'Sync completed',
    color: 'var(--emerald-green)',
  },
  GENERAL_ERROR: {
    ...DEFAULT_SYNC_STATUS,
    display: true,
    text: 'Something went wrong',
    color: 'var(--salmon)',
  },
  GEOLOCATION_PURCHASE_ERROR: {
    ...DEFAULT_SYNC_STATUS,
    display: true,
    text: "Couldn't find phone numbers in that location",
    color: 'var(--salmon)',
  },
  AREA_CODE_PURCHASE_ERROR: {
    ...DEFAULT_SYNC_STATUS,
    display: true,
    text: "Couldn't find phone numbers in that location",
    color: 'var(--salmon)',
  },
  ON: {
    ...DEFAULT_SYNC_STATUS,
    display: true,
    text: 'On',
    color: 'var(--emerald-green)',
  },
  OFF: {
    ...DEFAULT_SYNC_STATUS,
    display: true,
    text: 'Off',
    color: 'var(--salmon)',
  },
};

export const DEFAULT_CAMPAIGN_REFERRAL_SOURCE = 'Custom';
export const GOOGLE_ADS_REFERRAL_SOURCE = 'Google Ads';
export const GOOGLE_SEO_REFERRAL_SOURCE = 'Google SEO';
export const GOOGLE_SEARCH_REFERRAL_SOURCE = 'Google Search';
export const BING_SEO_REFERRAL_SOURCE = 'Bing SEO';
export const BING_ADS_REFERRAL_SOURCE = 'Bing Ads';
export const FACEBOOK_AD_REFERRAL_SOURCE = 'Facebook Ad';
export const ZILLOW_AD_REFERRAL_SOURCE = 'Zillow Ad';
export const STACKADAPT_REFERRAL_SOURCE = 'Stackadapt';
export const SALES_MAIL_REFERRAL_SOURCE = 'Sales Mail';

export const SELECTABLE_REFERRAL_SOURCES = [
  GOOGLE_ADS_REFERRAL_SOURCE,
  GOOGLE_SEO_REFERRAL_SOURCE,
  GOOGLE_SEARCH_REFERRAL_SOURCE,
  BING_SEO_REFERRAL_SOURCE,
  BING_ADS_REFERRAL_SOURCE,
  FACEBOOK_AD_REFERRAL_SOURCE,
  ZILLOW_AD_REFERRAL_SOURCE,
  STACKADAPT_REFERRAL_SOURCE,
  SALES_MAIL_REFERRAL_SOURCE,
];

export const QUERY_SELECTOR_OPTIONS = [
  { key: 'custom', text: 'Custom', value: false },
  { key: 'global', text: 'Global', value: true },
];

export const REFERRAL_SOURCE_OPTIONS = [
  { key: '1', text: 'Custom', value: DEFAULT_CAMPAIGN_REFERRAL_SOURCE },
  { key: '2', text: 'Google Ads', value: 'Google Ads' },
  { key: '3', text: 'Google SEO', value: 'Google SEO' },
  { key: '4', text: 'Google Search', value: 'Google Search' },
  { key: '5', text: 'Google My Business', value: 'Google My Business' },
  { key: '6', text: 'Bing SEO', value: 'Bing SEO' },
  { key: '7', text: 'Bing Ads', value: 'Bing Ads' },
  { key: '8', text: 'Facebook Ad', value: 'Facebook Ad' },
  { key: '9', text: 'Zillow Ad', value: 'Zillow Ad' },
  { key: '10', text: 'Stackadapt', value: 'Stackadapt' },
  { key: '11', text: 'Sales Mail', value: 'Sales Mail' },
];

export const QUANTITY_SELECTOR_OPTIONS = [
  { key: '1', text: '1 phone number', value: 1 },
  { key: '2', text: '2 phone numbers', value: 2 },
  { key: '3', text: '3 phone numbers', value: 3 },
];

export const DEFAULT_DEDICATED_NUMBER_ITEM = {
  referralOriginType: '',
  referralOrigin: '',
  quantity: 1,
};

export const ERROR_STATUS_TYPE = {
  GENERAL_ERROR: 1,
  GEOLOCATION_PURCHASE_ERROR: 2,
  AREA_CODE_PURCHASE_ERROR: 3,
};

export const TAGS = (t) => [
  {
    key: '<PAUSE_1>',
    label: '<PAUSE_1>',
    enabled: true,
    text: 'You can replace 1 with any number of seconds you want.',
  },
  {
    key: '{first_name}',
    label: `${capitalize(t('lead'))} Name`,
    enabled: true,
    text: 'Note that sometimes this field may be empty.',
  },
  { key: '{community_name}', label: `${capitalize(t('community'))} Name`, enabled: true },
  { key: '{community_city}', label: `${capitalize(t('community'))} City`, enabled: true },
];
