import React from 'react';
import styled from 'styled-components';

import { Menu as SemanticMenu } from 'semantic-ui-react';

const StyledMenu = styled(SemanticMenu)``;

export const Menu = (props) => <StyledMenu {...props} />;

Menu.Item = SemanticMenu.Item;
Menu.Header = SemanticMenu.Header;
Menu.Menu = SemanticMenu.Menu;
