import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import { Form, Icon, Dropdown } from 'components/lib';
import { If } from 'components/If';
import { STATUSES } from '../../../constants';
import { SettingsModal, SettingsModalHeader, SettingsModalActions } from '../common';

const ModalBody = styled.div``;

const BodyText = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  color: #89919d;
  margin-bottom: 20px;
`;

const InfoText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  padding: 8px;
  border: 2px solid;
  border-radius: 8px;
`;

const WarningInfoText = styled(InfoText)`
  border: 2px solid var(--warning);
  background-color: var(--light-salmon);
`;

const BasicInfoText = styled(InfoText)`
  border: 2px solid var(--secondary);
  background-color: var(--input-field-fill);
  margin-top: 5px;
  margin-bottom: 5px;
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    width: 85px;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  label {
    font-family: var(--font-family-bold);
    font-size: 14px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--text);
    margin-right: 10px;
  }
`;

const SPEECH_TIMEOUT_OPTIONS = Array.from({ length: 16 }, (_, i) => i).map((i) => ({
  key: i,
  text: i === 0 ? 'Auto' : `${i} s`,
  value: i,
}));

const DIGIT_TIMEOUT_OPTIONS = Array.from({ length: 15 }, (_, i) => i + 1).map((i) => ({
  key: i,
  text: `${i} s`,
  value: i,
}));

export const DigitSpeechModal = ({
  isModalOpen,
  selectedItem,
  totalItemsCount,
  onSave,
  onClose,
  isMultipleSelection,
}) => {
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);

  const handleSubmit = async (values, { resetForm }) => {
    setLoaderStatus(STATUSES.LOADING);

    await onSave(values);

    handleClose(resetForm);
    setLoaderStatus(STATUSES.LOADED);
  };

  const handleClose = (resetForm) => {
    onClose();
    resetForm();
  };

  const loading = loaderStatus === STATUSES.LOADING;
  const initialValues = {
    speech_answer_timeout: selectedItem?.speech_answer_timeout === 0 ? 0 : selectedItem?.speech_answer_timeout || 4,
    digit_answer_timeout: selectedItem?.digit_answer_timeout === 0 ? 0 : selectedItem?.digit_answer_timeout || 8,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({ dirty, touched, handleSubmit, values, resetForm, setFieldValue }) => {
        return (
          <SettingsModal
            loading={loading}
            open={isModalOpen}
            onModalClose={() => handleClose(resetForm)}
            size="tiny"
            width={540}
          >
            <SettingsModalHeader
              title="Answer Timeouts"
              community={selectedItem || null}
              totalItemsCount={totalItemsCount}
              type="header"
            />
            <ModalBody type="body">
              <FieldWrapper>
                <label>Digit Timeout</label>
                <Form.Field
                  control={StyledDropdown}
                  fluid
                  placeholder="Select"
                  selection
                  options={DIGIT_TIMEOUT_OPTIONS}
                  value={values.digit_answer_timeout}
                  onChange={(_, d) => {
                    setFieldValue('digit_answer_timeout', d.value);
                  }}
                  name="digit_answer_timeout"
                />
              </FieldWrapper>
              <BodyText>Maximum wait time for pressing a digit before submitting a response.</BodyText>
              <FieldWrapper>
                <label>Speech Timeout</label>
                <Form.Field
                  control={StyledDropdown}
                  fluid
                  placeholder="Select"
                  selection
                  options={SPEECH_TIMEOUT_OPTIONS}
                  value={values.speech_answer_timeout}
                  onChange={(_, d) => {
                    setFieldValue('speech_answer_timeout', d.value);
                  }}
                  name="speech_answer_timeout"
                />
              </FieldWrapper>
              <BodyText>
                Maximum wait time after a speech pause before starting speech recognition. Note that speech recognition
                may introduce <strong>additional delay</strong> before submitting the response.
                <If condition={values.speech_answer_timeout === 0}>
                  <BasicInfoText>
                    <div>
                      <Icon name="info" color="var(--secondary)" />
                    </div>
                    <div>
                      <span>
                        <strong>Auto mode</strong> automatically recognizes pause in the caller speech and submits the
                        response instantly.
                      </span>
                    </div>
                  </BasicInfoText>
                  <WarningInfoText>
                    <div>
                      <Icon name="warning" color="var(--warning)" />
                    </div>
                    <div>
                      <span>
                        <strong>Use with caution.</strong> Slower speech rate may cause unexpected results.
                      </span>
                    </div>
                  </WarningInfoText>
                </If>
                <If condition={values.speech_answer_timeout < 3 && values.speech_answer_timeout !== 0}>
                  <WarningInfoText>
                    <div>
                      <Icon name="warning" color="var(--warning)" />
                    </div>
                    <div>
                      <span>
                        <strong>Lower value</strong> might result in answers being submitted too quickly.
                      </span>
                    </div>
                  </WarningInfoText>
                </If>
              </BodyText>
            </ModalBody>
            <SettingsModalActions
              onSave={handleSubmit}
              onCancel={() => handleClose(resetForm)}
              type="actions"
              disabled={!isMultipleSelection && (!dirty || !touched)}
              saveText="Save"
            />
          </SettingsModal>
        );
      }}
    </Formik>
  );
};
