import React from 'react';
import styled from 'styled-components';

export const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9fafb;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  border: 1px solid #e4e4e4;
  margin-right: 1rem;
  overflow: hidden;

  img {
    height: 100%;
    max-width: 100%;
  }

  span {
    color: #666;
    font-size: 1.2rem;
  }
`;

export const ManagementCompanyLogo = ({ company }) => {
  const { logo_url, name } = company;
  if (!logo_url) {
    if (name.length <= 3) {
      return <LogoWrapper>{name}</LogoWrapper>;
    }
    const initials = name
      ?.split(' ')
      .map((w) => w[0]?.toUpperCase())
      .join('');

    return <LogoWrapper>{initials}</LogoWrapper>;
  }

  return (
    <LogoWrapper>
      <img src={logo_url} alt={name} />
    </LogoWrapper>
  );
};
