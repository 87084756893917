import React from 'react';

import { FieldArray, useFormikContext } from 'formik';
import { Message } from 'semantic-ui-react';

import { If } from '../../If';
import MappingRulesTable from './MappingRulesTable';
import { URL_MAPPING_RULES } from './constants';

const MappingRules = ({ fieldName, ruleTypes = URL_MAPPING_RULES, orderMessageSize = 'tiny', disabled }) => {
  const { values } = useFormikContext();
  const mappingRules = values && values[fieldName];
  return (
    <>
      <If condition={orderMessageSize !== 'none'}>
        <Message
          info
          size={orderMessageSize}
          header="The rules will be applied in this order"
          content="You can change the order by moving rows."
        />
      </If>
      <FieldArray name={fieldName}>
        {({ push, swap, replace, remove }) => (
          <MappingRulesTable
            ruleTypes={ruleTypes}
            mappingRules={mappingRules}
            onSwap={swap}
            onPush={push}
            onRemove={remove}
            onReplace={replace}
            fieldName={fieldName}
            disabled={disabled}
          />
        )}
      </FieldArray>
    </>
  );
};

export default MappingRules;
