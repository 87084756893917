import React from 'react';
import styled from 'styled-components';

import { CheckSquare } from '../Icons';

const Step = styled.div`
  position: relative;
  top: -1px;
  margin: -1px;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  color: #333;
  background-color: ${(p) => (p.active ? 'white' : '#f9fafb')};
  border: 1px solid #e4e4e4;
`;

const Title = styled.div`
  color: #333333;
  font-size: 1.2rem;
`;
const StepNumber = styled.div`
  color: #979797;
  font-size: 0.9rem;
`;

const IconWrapper = styled.div`
  margin-right: 1.5rem;
  svg {
    stroke: #979797;

    #check-square-checkline {
      stroke: #00c213;
    }
  }
`;

export const StepIndicator = ({ steps, activeStep }) => {
  const stepIndex = activeStep - 1;

  return (
    <div>
      {steps.map((s, i) => (
        <Step key={i} active={i === stepIndex}>
          <IconWrapper>
            {s?.icon && i >= stepIndex && <s.icon />}

            {i < stepIndex && <CheckSquare />}
          </IconWrapper>
          <div>
            <StepNumber>STEP {i + 1}</StepNumber>
            <Title>{s?.title}</Title>
          </div>
        </Step>
      ))}
    </div>
  );
};
