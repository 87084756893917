import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getInstantResponsesStatistics } from 'apis';
import { parseErrorResponse } from 'apis/utils';
import { Statistic } from 'components/common';
import { formatCount } from 'components/utils';
import { capitalize } from 'utils/helpers';
import { MEDIA_BREAK_POINTS, STATUSES } from '../../../constants';
import { roundTo } from '../../utils';

const StatisticGroup = styled.div`
  display: flex;
  > div:not(:first-child) {
    padding-left: 2rem;
  }

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-wrap: wrap;
    > div {
      flex: 50%;
    }
    > div:not(:first-child) {
      padding: 0.5rem 0;
    }
  }
`;

const INITIAL_STATE = {
  system_messages_count: undefined,
  lead_messages_count: undefined,
  total_conversations_count: undefined,
  ongoing_conversations_count: undefined,
};

const InstantResponsesMetricsOverview = ({ period }) => {
  const { t } = useTranslation();
  const [loaderStatus, setLoaderStatus] = useState(STATUSES.IDLE);
  const [data, setData] = useState(INITIAL_STATE);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoaderStatus(STATUSES.LOADING);
        const response = await getInstantResponsesStatistics(period);
        setData(response);
        setLoaderStatus(STATUSES.LOADED);
      } catch (e) {
        setLoaderStatus(STATUSES.FAILURE);
        toast.error(parseErrorResponse(e));
      }
    };

    fetch();
  }, [period]);

  return (
    <Segment basic>
      <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
        <Loader active={loaderStatus === STATUSES.LOADING} />
      </Dimmer>

      <StatisticGroup>
        <Statistic label="Messages Received" value={formatCount(data.number_of_messages)} />
        <Statistic label="Response Percentage" value={`${roundTo(data.percentage_of_replies)} %`} />
        <Statistic label="Avg. Response Time First Message" value={data.average_first_reply_duration} />
        <Statistic label="Avg. Response Time Other Messages" value={data.average_reply_duration} />
        <Statistic
          label={`${capitalize(t('tours'))} Scheduled Percentage`}
          value={`${roundTo(data.percentage_of_conversation_tours)} %`}
        />
      </StatisticGroup>
    </Segment>
  );
};

export default InstantResponsesMetricsOverview;
