import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loader } from 'components/lib';
import { CommunitiesContext } from '../common/communities/CommunitiesContext';
import { UserCommunity } from './UserCommunity';
import { STATUSES } from '../../constants';
import { CommunityAssetsContextProvider } from './CommunityAssetsContext';

export const UserCommunityDetailsOverview = () => {
  const { communityId } = useParams();
  const history = useHistory();

  const { state } = useContext(CommunitiesContext);
  const { assignedCommunities, status } = state;

  if (status !== STATUSES.LOADED) {
    return <Loader active inline="centered" size="large" />;
  }

  const community = assignedCommunities.find((c) => c.id === parseInt(communityId));

  if (!community) {
    history.push(`/communities`);
    toast.error('Unable to fetch community details!');
  }

  return (
    <CommunityAssetsContextProvider>
      <UserCommunity />
    </CommunityAssetsContextProvider>
  );
};
