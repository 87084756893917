import React, { useContext } from 'react';
import { differenceInDays, parseISO } from 'date-fns';

import { DateRangePicker } from '../common';
import { PAGINATION, STATUSES } from '../../constants';
import { ConversationsContext } from './ConversationsContext';
import { formatAnyDate } from '../utils';
import { DATE_RANGE_TYPE } from '../common/DateRangePicker';

export const ConversationsDateRangeFilter = () => {
  const { loaderStatus, filters, handleFiltersChange } = useContext(ConversationsContext);

  const handleDateFilterChange = (date) => {
    const now = formatAnyDate(new Date(), 'yyyy-MM-dd');
    const endDate = date.endDate ? parseISO(date.endDate) : now;
    const startDate = date.startDate ? parseISO(date.startDate) : now;

    handleFiltersChange({
      diff: differenceInDays(endDate, startDate),
      startDate: formatAnyDate(startDate, 'yyyy-MM-dd'),
      endDate: formatAnyDate(endDate, 'yyyy-MM-dd'),
      page: PAGINATION.PAGE,
    });
  };

  return (
    <DateRangePicker
      type={DATE_RANGE_TYPE.LEAD_CREATED}
      disabled={loaderStatus === STATUSES.LOADING}
      dateRange={{ start_date: filters.startDate, end_date: filters.endDate }}
      onFilterChange={handleDateFilterChange}
      resetFilterChange
    />
  );
};
