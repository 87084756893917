import React from 'react';
import styled from 'styled-components';
import { Icon } from 'components/lib';

const TYPES = {
  warning: {
    name: 'error_outlined',
    bgkColor: '#fff4d4',
    color: '#e8a947',
  },
};

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 20px;
  background-color: ${({ type }) => TYPES[type]?.bgkColor};
  .icon {
    width: 24px;
    margin-right: 17px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 900;
  color: ${({ type }) => TYPES[type]?.color};
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
`;

export const CustomMessage = ({ type, title, text }) => {
  return (
    <MessageContainer type={type}>
      <Icon name={TYPES[type]?.name} color={TYPES[type]?.color} size="24" className="icon" />
      <div>
        <Title type={type}>{title}</Title>
        <Text>{text}</Text>
      </div>
    </MessageContainer>
  );
};
