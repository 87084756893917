import React from 'react';

import { Dropdown, Grid } from 'components/lib';
import Mapping from './Mapping';
import Any from './Any';

const CRMField = ({ fieldType, name, value, defaultValue, fieldValues }) => {
  switch (fieldType) {
    case 'mapping':
      return (
        <Grid.Column key={value} width="16">
          <Mapping name={name} value={value} />
        </Grid.Column>
      );
    case 'dropdown':
      return (
        <Grid.Column key={value}>
          <Any
            name={name}
            value={value}
            defaultValue={defaultValue}
            control={Dropdown}
            options={fieldValues.map((i) => ({ key: i.value, value: i.value, text: i.display }))}
            selection
          />
        </Grid.Column>
      );
    default:
      return (
        <Grid.Column key={value}>
          <Any name={name} value={value} defaultValue={defaultValue} />
        </Grid.Column>
      );
  }
};

export default CRMField;
