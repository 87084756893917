import React from 'react';
import styled from 'styled-components';

const ModalHeader = styled.div`
  padding: 30px;
  height: 107px;
`;

const ModalHeaderTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 20px;
  color: var(--text);
  font-weight: 500;
  letter-spacing: normal;
`;

const ModalHeaderSubtitle = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  padding-top: 10px;
  strong {
    font-weight: 500;
    color: var(--text);
  }
`;

export const StepsHeader = ({ title, subtitle }) => {
  return (
    <ModalHeader>
      <ModalHeaderTitle>{title}</ModalHeaderTitle>
      <ModalHeaderSubtitle>{subtitle}</ModalHeaderSubtitle>
    </ModalHeader>
  );
};
