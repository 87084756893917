import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { ReactComponent as CheckBoxIcon } from 'assets/images/checkbox.svg';

const Wrapper = styled.div`
  .ui.dropdown {
    box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15), -2px -2px 4px 0 rgba(255, 255, 255, 0.5);
    border: solid 1px var(--line-standard);
    border-radius: 0px;
    background-color: white;
    font-weight: 500;
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--text-color);
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;

    &.active {
      background-color: #f0f0f0;
    }

    .divider.text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
      flex-grow: 1;
      min-width: 0;
    }
    .dropdown.icon {
      flex-shrink: 0;
    }

    .menu {
      max-height: 70vh;
      box-shadow: 0 8px 17px -4px rgba(0, 0, 0, 0.2);
    }
  }

  &&&& .ui.input {
    .close.icon {
      left: initial;
      right: 0px;
      pointer-events: auto;
    }
  }

  &&&& .ui.button {
    padding: 10px 20px 10px 6px;
  }
`;

const DropdownItem = styled(Dropdown.Item)`
  display: flex !important;
  gap: 10px;
  border-bottom: 1px solid var(--line-standard) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
`;

export const MultiSelect = ({
  name,
  placeholder,
  options,
  disabled,
  onChange,
  className,
  open = false,
  value = [],
}) => {
  const searchInputRef = useRef();
  const [isOpen, setIsOpen] = useState(open);
  const [label, setLabel] = useState(placeholder);
  const selectedValues = value.map((v) => ({ key: v, value: v, text: options.find((o) => o.key === v) }.text));

  const filteredUnselectedItems = useMemo(
    () => options.filter((item) => !selectedValues.some((i) => i?.key === item?.key)),
    [options, value]
  );

  const updateLabel = (items) => {
    if (items.length > 0) {
      const labelText = items.map((i) => i?.text).join(', ');
      setLabel(labelText);
    } else {
      setLabel(placeholder);
    }
  };

  useEffect(() => {
    updateLabel(selectedValues);
  }, [selectedValues]);

  const propageteChange = (items) => {
    onChange({ target: { value: items } });
  };

  const selectItem = (item) => () => {
    const items = [...value, item.value];
    propageteChange(items);
  };

  const unselectItem = (item) => () => {
    const items = value.filter((i) => i !== item?.key);
    propageteChange(items);
  };

  const onDropdownBlur = (e) => {
    if (e.relatedTarget !== searchInputRef.current) {
      setIsOpen(false);
    }
  };

  return (
    <Wrapper className={className}>
      <Dropdown
        className={className}
        scrolling
        pointing
        button
        name={name}
        text={label}
        open={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        onBlur={onDropdownBlur}
        disabled={disabled}
      >
        <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
          {selectedValues.length > 0 && (
            <>
              {selectedValues.map((o, i) => (
                <DropdownItem key={i} onClick={unselectItem(o)}>
                  <CheckBoxIcon />
                  {o?.text}
                </DropdownItem>
              ))}
            </>
          )}

          {filteredUnselectedItems.map((o, i) => (
            <DropdownItem key={i} onClick={selectItem(o)}>
              {o?.text}
            </DropdownItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};
