import React from 'react';
import styled from 'styled-components';

import { Label as SemanticLabel } from 'semantic-ui-react';

const StyledLabel = styled(SemanticLabel)`
  && {
    padding: 2px 7px;
    border-radius: 4px;
    background-color: #f4f4f4;
    font-family: var(--font-family);
    font-size: 9px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--light-text);
  }
`;

export const Label = ({ ...props }) => <StyledLabel {...props} />;
