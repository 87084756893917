import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  margin-bottom: 20px;
  margin-top: 3px;
`;

export const StepSubtitle = ({ children }) => {
  return <Container>{children}</Container>;
};
