import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { createCommunitiesGroup, updateCommunitiesGroup } from 'apis';

import {
  Button,
  ColorPickerDropDown,
  Form,
  FURHTER_ICON,
  Icon,
  Input,
  Modal,
  MultiSelectDropdown,
} from 'components/lib';
import { InputField } from 'components/common';
import { STATUSES } from '../../../constants';

import { SettingsContext } from '../SettingsContext';
import { CommunitiesGroupsContext } from '.';

const ModalHeader = styled(Modal.Content)`
  &&&& {
    padding: 30px 30px 20px 30px;
    border-bottom: solid 1px var(--line-standard);
  }
`;

const ModalTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 12px;
`;

const ModalContent = styled.div`
  && {
    padding: 30px 30px 20px 30px;
    min-height: 330px;
    .communities-dropdown {
      .menu {
        max-height: ${({ $modalContentHeight }) => `min(${$modalContentHeight}px, 500px)`};
      }
    }
  }
`;

const ModalActions = styled(Modal.Actions)`
  && {
    display: flex;
    justify-content: flex-end;
    && > .delete-button {
      margin-left: 0;
      margin-right: auto;
    }
  }
`;

const NameContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  label {
    font-family: var(--font-family-bold);
    font-size: 14px;
    line-height: 1.14;
    color: var(--text);
    margin-right: 10px;
  }
  .input {
    width: 300px;
  }
  & > .field {
    display: flex;
    align-items: center;
  }
  && .prompt {
    position: absolute;
    bottom: -34px;
    left: 30%;
  }
`;

const SelectedCommunitiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 30px;
`;

const SelectedCommunity = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px 2px 10px;
  border-radius: 12px;
  background-color: rgba(16, 42, 97, 0.1);
  font-family: var(--font-family);
  font-size: 12px;
  color: var(--menu-navy);
  .${FURHTER_ICON} {
    margin-left: 10px;
  }
`;

const initialValues = {
  name: 'Untitled Group',
  color: '#49b9d1',
  communities: [],
};

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
});

export const CommunitiesGroupsCreateModal = () => {
  const { t } = useTranslation();
  const modalContentRef = useRef(null);
  const {
    editingGroup,
    getGroups,
    groups,
    handleConfirmDeleteOpen,
    handleModalClose,
    loaderStatus,
    setEditingGroup,
    setLoaderStatus,
  } = useContext(CommunitiesGroupsContext);
  const { communities } = useContext(SettingsContext);
  const [initialFormValues, setInitialFormValues] = useState(initialValues);

  useEffect(() => {
    if (editingGroup) {
      setInitialFormValues(groups.find((group) => group.id === editingGroup));
    }
  }, [editingGroup]);

  const modalContentHeight = modalContentRef.current?.clientHeight || 0;

  const handleFormSubmit = async (values) => {
    setLoaderStatus(STATUSES.LOADING);
    try {
      if (editingGroup) {
        await updateCommunitiesGroup(editingGroup, values);
      } else {
        await createCommunitiesGroup(values);
      }
      setLoaderStatus(STATUSES.LOADED);
      setEditingGroup(null);
      handleModalClose();
      getGroups();
    } catch (error) {
      setLoaderStatus(STATUSES.FAILURE);
      console.error('error', error);
    }
  };

  const handleCommunitiesChange = (value, setFieldValue) => {
    setFieldValue(
      'communities',
      value.map((community) => community.value)
    );
  };

  const handleCommunityRemove = (values, communityId, setFieldValue) => {
    setFieldValue(
      'communities',
      values.communities.filter((id) => id !== communityId)
    );
  };

  const isLoading = loaderStatus === STATUSES.LOADING;

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isValid, values, setFieldValue, dirty }) => (
        <>
          <ModalHeader>
            <ModalTitle>{editingGroup ? 'Edit' : 'Create'} a Group</ModalTitle>
            <NameContainer>
              <Form.Field>
                <InputField control={Input} label="Name" name="name" placeholder="Enter a name for your group" />
              </Form.Field>
              <InputField control={ColorPickerDropDown} label="Color" name="color" />
            </NameContainer>
          </ModalHeader>
          <ModalContent ref={modalContentRef} $modalContentHeight={modalContentHeight - 90}>
            <Form.Group>
              <Form.Field width="12">
                <MultiSelectDropdown
                  open={!editingGroup}
                  options={communities}
                  className="communities-dropdown"
                  placeholder={`Search ${t('communities')} to add`}
                  selectedValues={values.communities}
                  onChange={(value) => handleCommunitiesChange(value, setFieldValue)}
                />
              </Form.Field>
            </Form.Group>
            <SelectedCommunitiesContainer>
              {values?.communities?.map((communityId) => {
                const community = communities.find((c) => c.id === communityId);
                if (!community) return null;
                return (
                  <SelectedCommunity key={community?.id} color={values.color}>
                    {community?.name}
                    <Icon
                      size="14"
                      name="close"
                      onClick={() => handleCommunityRemove(values, community?.id, setFieldValue)}
                    />
                  </SelectedCommunity>
                );
              })}
            </SelectedCommunitiesContainer>
          </ModalContent>
          <ModalActions>
            {editingGroup && (
              <Button className="delete-button link" color="red" onClick={handleConfirmDeleteOpen} disabled={isLoading}>
                Delete Group
              </Button>
            )}
            <Button primary color="blue" onClick={handleSubmit} disabled={!isValid || !dirty || isLoading}>
              {editingGroup ? 'Save' : 'Create'}
            </Button>
            <Button primary onClick={handleModalClose} disabled={isLoading}>
              Cancel
            </Button>
          </ModalActions>
        </>
      )}
    </Formik>
  );
};
