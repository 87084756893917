import React, { useContext } from 'react';
import { Dropdown, Segment, Input } from 'semantic-ui-react';

import { ChatsContext } from '../ChatsContext';
import { InputField } from '../../common';
import { CodeArea } from '../CodeArea';
import { ANALYTICS_TYPE_CUSTOM } from './constants';

export const AnalyticsTrackingForm = ({ values, setFieldValue }) => {
  const { analytics } = useContext(ChatsContext);

  const onChangeAnalytics = (selection) => {
    setFieldValue(
      'fields',
      analytics.fields[selection].map(() => '')
    );
  };

  const dropdownOptions =
    (analytics &&
      Object.entries(analytics.types).map(([type, label]) => ({
        key: type,
        text: label,
        value: type,
      }))) ||
    [];

  return (
    <>
      <InputField
        fluid
        label="Tracking Analytics"
        control={Dropdown}
        name="type"
        placeholder="Select Analytics Type"
        selection
        options={dropdownOptions}
        onChanged={onChangeAnalytics}
      />
      {values?.type && (
        <Segment>
          {analytics.fields[values?.type].map((field, index) => (
            <InputField
              key={field}
              control={values?.type === ANALYTICS_TYPE_CUSTOM ? CodeArea : Input}
              label={field.toUpperCase()}
              name={`fields[${index}]`}
              placeholder={`Enter ${field.toUpperCase()}`}
            />
          ))}
        </Segment>
      )}
    </>
  );
};
