import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding-right: 30px;
`;

export const CommunityPanelTitle = ({ title, action }) => {
  return (
    <Wrapper>
      <div>{title}</div>
      <div>{action}</div>
    </Wrapper>
  );
};
