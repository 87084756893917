import { Header, Table, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCheckMobileScreen } from 'utils/hooks';
import { If } from 'components/If';
import { CsvExport, TableCellStat } from 'components/common';
import { capitalize } from 'utils/helpers';
import { CommunityListPopup } from './CommunityListPopup';

const TableWrapper = styled.div`
  position: relative;
`;

export const ListWrapper = styled.div`
  max-height: 20rem;
  overflow-y: auto;
  padding-bottom: 1px;
  margin: 0.5rem;
`;

export const CampaignStatisticsTable = ({ campaignStatistics, exportAsCSV }) => {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const textAlign = isMobile ? 'left' : 'center';

  return (
    <TableWrapper>
      <Table striped>
        <Table.Header>
          <Table.Row textAlign={textAlign}>
            <Table.HeaderCell textAlign="left">Name</Table.HeaderCell>
            <Table.HeaderCell>Active</Table.HeaderCell>
            <Table.HeaderCell>{capitalize(t('communities'))}</Table.HeaderCell>
            <Table.HeaderCell>{capitalize(t('leads'))}</Table.HeaderCell>
            <Table.HeaderCell>Scheduled {capitalize(t('tours'))}</Table.HeaderCell>
            <Table.HeaderCell>{capitalize(t('move_ins'))}</Table.HeaderCell>
            <Table.HeaderCell>Messages Sent</Table.HeaderCell>
            <Table.HeaderCell>Messages Delivered</Table.HeaderCell>
            <Table.HeaderCell>Messages Clicked</Table.HeaderCell>
            <Table.HeaderCell>Message Replies</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {campaignStatistics?.map((campaign, i) => (
            <Table.Row textAlign={textAlign} key={campaign.id}>
              <Table.Cell textAlign="left">
                <Header as="h4">{campaign.name}</Header>
              </Table.Cell>
              <Table.Cell>
                <Icon
                  name={campaign.isActive ? 'play' : 'pause'}
                  color={campaign.isActive ? 'green' : 'grey'}
                  aria-label={campaign.isActive ? 'Active' : 'Inactive'}
                />
              </Table.Cell>

              <Table.Cell>
                <CommunityListPopup campaign={campaign} />
              </Table.Cell>
              <Table.Cell>
                <TableCellStat count={campaign.leads} />
              </Table.Cell>
              <Table.Cell>
                <TableCellStat count={campaign.scheduledTours} outOf={campaign.leads} />
              </Table.Cell>
              <Table.Cell>
                <TableCellStat count={campaign.moveIns} outOf={campaign.leads} />
              </Table.Cell>
              <Table.Cell>
                <TableCellStat count={campaign.messagesSent} />
              </Table.Cell>
              <Table.Cell>
                <TableCellStat count={campaign.messagesDelivered} outOf={campaign.messagesSent} />
              </Table.Cell>
              <Table.Cell>
                <TableCellStat count={campaign.messagesClicked} outOf={campaign.messagesSent} />
              </Table.Cell>
              <Table.Cell>
                <TableCellStat count={campaign.messageReplies} outOf={campaign.messagesSent} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={10} textAlign="right">
              <If condition={campaignStatistics?.length > 0}>
                <CsvExport exportAction={exportAsCSV} filename="campaign_statistics" content="CSV" />
              </If>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </TableWrapper>
  );
};
