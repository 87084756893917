import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { If } from 'components/If';
import { formatSecondsToTime } from 'utils/helpers';
import { Icon } from '.';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const Text = styled.span`
  && {
    font-family: var (--font-family);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text);
    margin: 0 10px 0 8px;
    flex-grow: 1;
    min-width: ${(props) => (props.textMinWidth ? `${props.textMinWidth}px` : 'auto')};
  }
`;

const Bar = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  width: 176px;
`;

const BarProgres = styled.div`
  height: 2px;
  flex: 1;
  border-radius: 5px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Knob = styled.div`
  position: relative;
  height: 16px;
  width: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px var(--line-standard);
  background-color: var(--white);
  border-radius: 50%;
`;

const Time = styled.span`
  && {
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 10px;
    letter-spacing: normal;
    text-align: right;
    color: #89919d;
  }
`;

export const AudioPlayer = ({
  url,
  duration = 0,
  playPauseIconColor = 'var(--text)',
  text,
  overRidePlayPause,
  outSideState,
  hideProgressBar,
  playingText,
  textMinWidth,
  playIconColor,
  pauseIconColor,
  disabled,
}) => {
  const [audio, setAudio] = useState(undefined);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [clickedTime, setClickedTime] = useState();
  const [localDuration, setLocalDuraction] = useState(duration);

  const progressBarRef = useRef();

  useEffect(() => {
    const audio = new Audio(url);
    setAudio(audio);
    const setAudioData = () => {
      setCurrentTime(audio.currentTime);
      setLocalDuraction(duration || audio.duration);
    };

    const setAudioTime = () => {
      if (audio.currentTime === audio.duration) {
        setPlaying(false);
        setCurrentTime(0);
        audio.currentTime = 0;
        audio.pause();
      } else {
        setCurrentTime(audio.currentTime);
      }
    };

    audio.addEventListener('loadeddata', setAudioData);
    audio.addEventListener('timeupdate', setAudioTime);

    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
      audio.pause();
    };
  }, []);

  useEffect(() => {
    if (clickedTime && clickedTime !== currentTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }
  }, [clickedTime]);

  useEffect(() => {
    if (typeof overRidePlayPause === 'boolean') {
      setPlaying(overRidePlayPause);
    }
  }, [overRidePlayPause]);

  useEffect(() => {
    if (audio) {
      if (playing) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [playing]);

  const handlePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleTogglePlay = (e) => {
    handlePropagation(e);
    setPlaying(!playing);
    outSideState && outSideState(!playing);
  };

  const calcClickedTime = (e) => {
    const clickPositionInPage = e.pageX;
    const bar = progressBarRef?.current;
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = localDuration / barWidth;
    const time = Math.round(clickPositionInBar * timePerPixel);
    return time;
  };

  const onTimeUpdate = (time) => {
    setClickedTime(time);
  };

  const handleTimeDrag = (e) => {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  };

  const progress = (currentTime / localDuration) * 100;

  return (
    <Container onClick={handlePropagation} disabled={disabled}>
      <If condition={playing}>
        <Icon name="pause" onClick={handleTogglePlay} size="24" color={pauseIconColor || playPauseIconColor} />
      </If>
      <If condition={!playing}>
        <Icon name="play_arrow" onClick={handleTogglePlay} size="24" color={playIconColor || playPauseIconColor} />
      </If>
      <If condition={!playingText}>
        <Text textMinWidth={textMinWidth}>{text}</Text>
      </If>
      <If condition={playingText}>
        <If condition={playing}>
          <Text textMinWidth={textMinWidth}>{playingText}</Text>
        </If>
        <If condition={!playing}>
          <Text textMinWidth={textMinWidth}>{text}</Text>
        </If>
      </If>
      <If condition={!hideProgressBar}>
        <Bar>
          <Time>{formatSecondsToTime(currentTime)}</Time>
          <BarProgres
            ref={progressBarRef}
            style={{
              background: `linear-gradient(to right, var(--emerald-green) ${progress}%, #eaeaea 0)`,
            }}
            onMouseDown={(e) => handleTimeDrag(e)}
          >
            <Knob style={{ left: `${progress - 2}%` }} />
          </BarProgres>
          <Time>{formatSecondsToTime(localDuration)}</Time>
        </Bar>
      </If>
    </Container>
  );
};
