import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { createEmailTemplate } from 'apis';
import EditTemplateModal from './EditTemplateModal';
import { EMAIL_TEMPLATE_TYPE } from '../nurture/constants';

const initialValues = {
  name: '',
  html: '',
  style: '',
  isPublished: false,
};

export default function NewTemplate({ defaultStyles }) {
  const history = useHistory();

  const close = useCallback(() => {
    history.push(`/admin/email-templates`);
  }, [history]);

  const onSave = useCallback(
    async (values) => {
      await createEmailTemplate({ ...values, templateType: EMAIL_TEMPLATE_TYPE.FURTHER });
      close();
    },
    [close]
  );

  return <EditTemplateModal onClose={close} initialValues={{ ...initialValues, ...defaultStyles }} onSave={onSave} />;
}
