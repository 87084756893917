import {
  Welcome,
  // MainGoal,
  CustomerIntent,
  QualifyingQuestions,
  CreateAccount,
  InstallCode,
  Success,
  GenerateVSA,
} from './oboarding-steps';

const SITE_MANAGER = process.env.REACT_APP_SITE_MANAGER || '//js.talkfurther.com/talkfurther_init.min.js';

export const ONBOARDING_STEPS = [
  {
    name: 'Welcome',
    slug: 'welcome',
    component: Welcome,
    hasPreviousStep: false,
  },
  // {
  //   name: 'Main Goal',
  //   slug: 'main-goal',
  //   component: MainGoal,
  //   hasPreviousStep: true,
  // },
  {
    name: 'What Do Leads Want',
    slug: 'intents',
    component: CustomerIntent,
    hasPreviousStep: true,
  },
  {
    name: 'Questions for Leads',
    slug: 'qualifying-questions',
    component: QualifyingQuestions,
    hasPreviousStep: true,
  },
  {
    name: 'Create an Account',
    slug: 'create-account',
    component: CreateAccount,
    hasPreviousStep: true,
    nextButtonText: 'Create an Account',
  },
  {
    name: 'Generating a Virtual Sales Assistant',
    slug: 'generate-vsa',
    component: GenerateVSA,
    hasPreviousStep: false,
  },
  {
    name: 'Install Code',
    slug: 'install-script',
    component: InstallCode,
    hasPreviousStep: false,
  },
  {
    name: 'Success',
    slug: 'success',
    component: Success,
    hasPreviousStep: false,
    hideProgressBar: true,
  },
];

export const MAIN_GOAL_OPTIONS = [
  { value: 'call', text: 'To connect via phone immediately', icon: 'call' },
  { value: 'sms', text: 'To connect via SMS immediately', icon: 'sms' },
  { value: 'insurance', text: 'To verify a lead’s insurance', icon: 'sticky_note_2' },
  { value: 'tour', text: 'To schedule a tour \n (virtual or in-person)', icon: 'tour' },
  { value: 'availability', text: 'To confirm availability', icon: 'calendar_month' },
  { value: 'schedule_call', text: 'To schedule a call', icon: 'timer' },
];

export const getScriptCode = (onboardingUUID, onboardingTimestamp) => `<script type='text/javascript'>
    (function () {
        var initScript = document.createElement("script");
        initScript.type = "text/javascript";
        initScript.src =
          document.location.protocol + "${SITE_MANAGER}";
        initScript.async = true;
        window.FurtherOnboardingUUID = "${onboardingUUID}";
        window.FurtherOnboardingTimestamp = "${onboardingTimestamp}";
        document.head.appendChild(initScript);
    })();
</script>`;
