import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Icon, Message, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import ChatValidationDetails from './ChatValidationDetails';

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ViewChatBox = styled.div`
  padding: 0.5rem;
`;

const ChatValidationList = ({ validationErrors }) => {
  const [activeIndex, setActiveIndex] = useState();

  const handleAccordion = (e, { index }) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  if (!validationErrors) {
    return null;
  }

  if (!validationErrors.length) {
    return <Message info>No chats</Message>;
  }

  return validationErrors.map((ve) => (
    <Accordion styled fluid>
      <Accordion.Title active={ve.id === activeIndex} index={ve.id} onClick={handleAccordion}>
        <TitleBox>
          <div>
            <Icon name="dropdown" />
            {ve.name}
          </div>
          {ve.invalid ? <Icon name="warning circle" color="red" /> : <Icon name="check circle" color="green" />}
        </TitleBox>
      </Accordion.Title>
      <Accordion.Content active={ve.id === activeIndex}>
        <Grid>
          <ViewChatBox>
            <Link to={`/chats/${ve.id}`} target="_blank" rel="noopener noreferrer">
              View Chat
            </Link>
          </ViewChatBox>
        </Grid>
        <ChatValidationDetails validationErrors={ve.validation} />
      </Accordion.Content>
    </Accordion>
  ));
};

export default ChatValidationList;
