import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Dropdown, Icon, Input, Message } from 'components/lib';
import { If } from 'components/If';
import { debounce } from 'components/utils';
import { ScheduledMessage } from './ScheduledMessage';
import { MEDIA_BREAK_POINTS } from '../../../constants';
import { Metrics } from './Metrics';

const ScheduledMessagesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
`;

const WarningMessageContainer = styled.div`
  padding: 20px;
`;

const ScheduledMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
  }
`;

const MetricsContainer = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: initial;
  gap: 10px;
  padding: 10px 20px;

  & > .input {
    flex: 1 0 auto;
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
    align-items: flex-end;
    width: 50%;
    padding: 20px 30px;

    && > .dropdown {
      width: 200px;
    }
  }
`;

const FILTER_TYPES = {
  EVERYTHING: 'everything',
  TODAY: 'today',
  WEEK: 'week',
};

const options = [
  {
    key: FILTER_TYPES.EVERYTHING,
    text: 'Everything',
    value: FILTER_TYPES.EVERYTHING,
  },
  {
    key: FILTER_TYPES.TODAY,
    text: 'Scheduled for today',
    value: FILTER_TYPES.TODAY,
  },
  {
    key: FILTER_TYPES.WEEK,
    text: 'Scheduled for this week',
    value: FILTER_TYPES.WEEK,
  },
];

const ScheduledMessagesList = ({
  scheduledMessages,
  metrics,
  search,
  scheduledDate,
  onRemove,
  onSearch,
  onToggleScheduledDate,
}) => {
  const [dropDownValue, setDropDownValue] = useState(scheduledDate || FILTER_TYPES.EVERYTHING);
  const [searchValue, setSearchValue] = useState(search);

  const handleDropdownChange = (e, { value }) => {
    console.log(value);
    setDropDownValue(value);
    onToggleScheduledDate(value);
  };

  const debouncedOnChangeFilter = useCallback(debounce(onSearch, 1000), []);

  useEffect(() => {
    debouncedOnChangeFilter(searchValue);
  }, [searchValue]);

  const handleSearchChange = (e, { value }) => {
    setSearchValue(value);
  };

  return (
    <ScheduledMessagesContainer>
      <TopContainer>
        <MetricsContainer>
          <Metrics metrics={metrics} />
        </MetricsContainer>
        <FilterContainer>
          <Dropdown options={options} selection fluid basic value={dropDownValue} onChange={handleDropdownChange} />
          <Input
            fluid
            iconPosition="left"
            onChange={handleSearchChange}
            value={searchValue}
            icon={<Icon name="search" size="24" variant="outlined" color="var(--light-text)" />}
          />
        </FilterContainer>
      </TopContainer>

      <If condition={!scheduledMessages || scheduledMessages.length === 0}>
        <WarningMessageContainer>
          <Message warning icon>
            <Icon name="feedback" size="40" variant="outlined" />
            <Message.Content>
              <Message.Header>No scheduled messages!</Message.Header>
              <p>Please schedule some messages or reset filters.</p>
            </Message.Content>
          </Message>
        </WarningMessageContainer>
      </If>
      <ScheduledMessagesListContainer>
        {scheduledMessages?.map((scheduledMessage, i) => (
          <ScheduledMessage key={i} scheduledMessage={scheduledMessage} onRemove={onRemove} />
        ))}
      </ScheduledMessagesListContainer>
    </ScheduledMessagesContainer>
  );
};

export default ScheduledMessagesList;
