import React, { useContext } from 'react';
import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';

import { UserContext } from '../UserContext';
import { NURTURE_TAB_ITEMS } from './constants';
import NurtureTab from './NurtureTab';

const Wrapper = styled.div`
  margin-bottom: 5px;
  & > .ui.segment.tab {
    border: none;
    padding: 1.25rem;
  }
  & > .ui.secondary.pointing.menu {
    padding: 1px;
    border-bottom: 1px solid var(--line-standard);
    margin-top: 7px;
    padding-left: 35px;

    & > .item {
      font-family: var(--font-family);
      font-size: 14px;
      line-height: normal;
      letter-spacing: normal;
      color: #89919d;
      padding: 0 0 11px;
      margin-right: 30px;

      &.active {
        font-family: var(--font-family-bold);
        font-size: 14px;
        line-height: normal;
        letter-spacing: normal;
        color: var(--text);
      }
    }
  }
`;

const NurtureTabs = () => {
  const { user } = useContext(UserContext);

  return (
    <Wrapper>
      <Menu size="large" secondary pointing>
        {NURTURE_TAB_ITEMS.filter((item) => item.allowedTypes.includes(user.type)).map((item, i) => (
          <NurtureTab key={i} item={item} />
        ))}
      </Menu>
    </Wrapper>
  );
};

export default NurtureTabs;
