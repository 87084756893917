import React, { useRef, useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';

const EmailIframe = styled.iframe`
  width: ${({ width, scale }) => width / scale}px;
  height: ${({ height, scale }) => height / scale}px;
  border: 0;
  background: #fff;
  transform: scale(${({ scale }) => scale});
  transform-origin: 0 0;
  pointer-events: none;
`;

const Wrapper = styled.div`
  overflow: hidden;
`;

function htmlWrapper(body, style) {
  return `<html><body><style type="text/css">${style}</style>${body}</body></html>`;
}

export default function EmailPreview({
  html,
  width,
  height,
  scale = 1,
  fullEmailHeight = false,
  wrapToHtml = false,
  style,
}) {
  const iframeRef = useRef(null);
  const wrapperRef = useRef(null);
  const [isScaled, setIsScaled] = useState(true);
  const calculatedScale = isScaled ? scale : 1;
  const onEmailLoad = useCallback(() => {
    if (!fullEmailHeight) {
      return false;
    }
    const iframe = iframeRef.current;
    if (iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
      if (iframeDoc) {
        const iframeBody = iframeDoc.body;
        if (iframeBody) {
          const iframeHeight = iframeBody.scrollHeight;
          iframe.style.height = `${iframeHeight + 20 / calculatedScale}px`;
          wrapperRef.current.style.height = `${iframeHeight * calculatedScale + 20}px`;
          if (isScaled && iframeHeight <= 50) {
            setIsScaled(false);
          }
        }
      }
    }
  }, [iframeRef, isScaled]);

  useEffect(() => {
    onEmailLoad();
  }, [isScaled]);

  const srcDoc = wrapToHtml ? htmlWrapper(html, style) : html;

  return (
    <Wrapper ref={wrapperRef} style={{ width }}>
      <EmailIframe
        onLoad={onEmailLoad}
        ref={iframeRef}
        width={width}
        height={height}
        scale={calculatedScale}
        srcDoc={srcDoc}
      />
    </Wrapper>
  );
}
