import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useField } from 'formik';
import DatePicker from 'components/lib/DatePicker';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .helper-text {
    font-size: 12px;
    font-weight: 500;
    color: #89919d;
  }
`;

const StartDateField = ({ name }) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [field, _, formHelpers] = useField(name);

  const handleChangeDate = (value) => {
    formHelpers.setValue(value);
  };

  return (
    <Container>
      <DatePicker
        dateFormat="MMMM d yyyy"
        minDate={tomorrow}
        name={name}
        selected={field.value}
        onChange={handleChangeDate}
      />
    </Container>
  );
};

export default StartDateField;
