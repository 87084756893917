import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { OnboardingContext } from '../OnboardingContext';
import { Container } from './common';

import { CustomerIntentForm } from '.';

const initialFormValues = {
  intents: [],
};

const VALIDATION_SCHEMA = Yup.array().of(Yup.string().required('Please select at least one option.'));

export const CustomerIntent = () => {
  const { handleNextStep, onboarding, intents } = useContext(OnboardingContext);
  const [initialValues, setInitialValues] = useState(initialFormValues);

  useEffect(() => {
    let previouslySelected = [];
    if (onboarding?.collectedData?.intents?.length > 0) {
      previouslySelected = onboarding.collectedData.intents;
    }
    setInitialValues({ intents: previouslySelected });
  }, [onboarding]);

  const onFormSubmit = (values) => {
    handleNextStep(values);
  };

  return (
    <Container maxWidth="90rem">
      <h2>What do your leads want?</h2>
      <p>
        What information or services are leads seeking when they engage with your website?
        <br /> Select up to 5.
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={VALIDATION_SCHEMA}
        enableReinitialize
      >
        <CustomerIntentForm />
      </Formik>
    </Container>
  );
};
