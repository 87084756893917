import React from 'react';
import styled from 'styled-components';

import { useCampaignDetails } from 'components/nurture/Campaigns/CampaignDetailsContext';
import { useField } from 'formik';
import DatePicker from 'components/lib/DatePicker';
import { getTomorrow } from 'utils/helpers';
import Display from './Display';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  .helper-text {
    font-size: 12px;
    font-weight: 500;
    color: #89919d;
    padding-left: 2px;
  }
`;

const Box = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const DateField = ({ name, criterion }) => {
  const { state, hasEditDeletePermission } = useCampaignDetails();
  const { criteria } = state;
  const { helpText } = criteria.find((c) => c.id === criterion.criterionId);
  const tomorrow = getTomorrow();
  const fieldName = `${name}.value`;
  const [field, _, formHelpers] = useField(fieldName);

  const handleChangeDate = (date) => {
    formHelpers.setValue(date.toISOString());
  };

  return (
    <Container>
      <Box>
        <Display size="md">{criterion.displayName}</Display>
        <DatePicker
          dateFormat="MMMM d yyyy"
          minDate={tomorrow}
          selected={new Date(field.value)}
          onChange={handleChangeDate}
          disabled={!hasEditDeletePermission}
        />
      </Box>
      {helpText && <div className="helper-text">{helpText}</div>}
    </Container>
  );
};

export default DateField;
