import styled from 'styled-components';

export const Code = styled.div`
  word-break: break-all;
  font-family: monospace;
  padding: 1rem;
  margin-top: 1rem;
  line-height: 1.5;
  background-color: #eaeff7;
  border-radius: 1rem;
`;
