import React from 'react';
import { Grid, Message } from 'semantic-ui-react';
import { useQuery } from 'react-query';

import { getOwnersSummary } from 'apis';
import { Statistic, PageSection, Delta } from 'components/common';
import { formatCount } from 'components/utils';
import { queryStatusToLoaderStatus } from 'utils/helpers';
import queryConstants from 'utils/queries/constants';
import { useOwnersDashboardContext } from './OwnersDashboardContext';

export const Metrics = ({ metrics, previousMetrics }) => {
  const { filters } = useOwnersDashboardContext();

  const { data, error, status, isFetching } = useQuery(
    [queryConstants.OWNERS_SUMMARY, { filters }],
    () => getOwnersSummary({ filters }),
    { placeholderData: [] }
  );

  const loaderStatus = queryStatusToLoaderStatus(status, isFetching);

  if (error) {
    return (
      <Message error>
        <Message.Header>Error Loading Analytic Data</Message.Header>
        <Message.Content>
          Something went wrong and we couldn't fetch your analytic data. Please try again, or reach out over email for
          help.
        </Message.Content>
      </Message>
    );
  }

  const {
    leads,
    previous_leads,
    avg_lead_value_score,
    previous_avg_lead_value_score,
    tours,
    previous_tours,
    move_ins,
    previous_move_ins,
    predicted_move_ins,
    previous_predicted_move_ins,
  } = data;

  return (
    <PageSection loaderStatus={loaderStatus}>
      <Grid stackable>
        <Grid.Column width={7}>
          <Statistic.Group name="Leads">
            <Statistic
              label="Lead Volume"
              value={formatCount(leads)}
              secondaryValue={<Delta value={leads - previous_leads} />}
            />
            <Statistic
              label="Average Lead Quality"
              value={avg_lead_value_score?.toFixed(1)}
              secondaryValue={<Delta value={(avg_lead_value_score - previous_avg_lead_value_score)?.toFixed(1)} />}
            />
          </Statistic.Group>
        </Grid.Column>
        <Grid.Column width={9}>
          <Statistic.Group name="Move-Ins" justifyContent="space-between">
            <Statistic
              label="Scheduled Tours"
              value={tours}
              secondaryValue={<Delta value={tours - previous_tours} />}
            />
            <Statistic
              label="Predicted Move-Ins"
              value={formatCount(predicted_move_ins?.toFixed(1))}
              secondaryValue={
                <Delta value={predicted_move_ins - previous_predicted_move_ins} formatter={(v) => v?.toFixed(1)} />
              }
            />
            <Statistic
              label="Confirmed Move-Ins"
              value={formatCount(move_ins)}
              secondaryValue={<Delta value={move_ins - previous_move_ins} />}
            />
          </Statistic.Group>
        </Grid.Column>
      </Grid>
    </PageSection>
  );
};
