import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { DEFAULT_BRANDING_COLORS, MEDIA_BREAK_POINTS } from '../../constants';
import { If } from '../If';
import { Logo } from '../Logo';
import { ThemeContext } from '../ThemeContext';
import { UserInfo, UserInfoWrapper } from './UserInfo';

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #${DEFAULT_BRANDING_COLORS.BRAND_COLOR};
  background-color: ${({ backgroundColor }) => `var(--${backgroundColor || 'menu-navy'})`};
  font-weight: 500;
  padding: 0 1rem;
  height: 4.25rem;
  border-bottom: 1px solid #${DEFAULT_BRANDING_COLORS.BRAND_COLOR_DARKER};
  border-bottom: 1px solid var(--global-color-primary-darker);
  box-shadow: 0 1px 2px #00000075;
  max-width: 100vw;
  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    padding: 0 5rem;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;

  .logo {
    margin-right: 3rem;
    svg {
      width: 9rem;
    }
  }
`;

const MobileMenuTrigger = styled.div`
  width: 35px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  z-index: 1002;

  span {
    transition: transform 0.3s ease-in-out;
    width: 100%;
    height: 5px;
    background-color: white;
    border-radius: 5px;
  }

  span:nth-child(2) {
    transform: ${({ open }) => (open ? 'translateX(100%)' : '')};
  }

  span:first-child {
    transform: ${({ open }) => (open ? 'rotate(45deg) translateX(10px) translateY(9px)' : '')};
  }

  span:last-child {
    transform: ${({ open }) => (open ? 'rotate(-45deg) translateX(8px) translateY(-8px)' : '')};
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #${DEFAULT_BRANDING_COLORS.BRAND_COLOR};
  background-color: var(--global-color-primary);
  transform: translateY(-100%);
  transform: ${({ open }) => (open ? 'translateY(0)' : '')};
  transition: transform 0.3s ease-in-out;
  z-index: 1001;

  a {
    color: white;
    font-size: 20px;
    line-height: 30px;
    padding: 20px;
  }

  ${UserInfoWrapper} {
    display: flex;
    width: 100%;
    margin-top: auto;
    align-self: center;
    padding: 10px;
  }

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    display: none;
  }
`;

const NavBar = ({ showLinks, showUserInfo }) => {
  const { isWhitelabeled } = useContext(ThemeContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    document.body.style.position = 'relative';
  }, []);

  const onToggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    document.body.style.position = mobileMenuOpen ? 'relative' : 'fixed';
  };

  return (
    <StyledNav>
      <Links>
        <If condition={!isWhitelabeled}>
          <NavLink to="/" className="logo">
            <Logo />
          </NavLink>
        </If>
      </Links>
      <If condition={showUserInfo && !isWhitelabeled}>
        <UserInfo right />
      </If>
      <If condition={showLinks || showUserInfo}>
        <MobileMenuTrigger onClick={onToggleMobileMenu} open={mobileMenuOpen}>
          <span />
          <span />
          <span />
        </MobileMenuTrigger>
      </If>
      <MobileMenu open={mobileMenuOpen}>
        <If condition={showUserInfo && !isWhitelabeled}>
          <UserInfo />
        </If>
      </MobileMenu>
    </StyledNav>
  );
};

export default NavBar;
