import { useEffect, useContext } from 'react';
import { useHistory } from 'react-router';

import { shouldRedirectSsoUnauthorized } from 'utils/helpers';
import { UserContext } from './UserContext';

export const IndexRoute = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (!user && shouldRedirectSsoUnauthorized()) {
      const search = window.location.search;
      history.push(`/sso-unauthorized${search}&error`);
    } else {
      history.push('/startup');
    }
  });

  return null;
};
