import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { Dimmer, Loader, Message, Sticky } from 'semantic-ui-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { RouterPrompt } from 'components/RouterPrompt';
import { STATUSES } from '../../../constants';
import { ChatModulesContext } from './ChatModulesContext';
import { ModuleGraphEditor } from './ModuleGraphEditor';
import { NoModules } from './NoModules';
import { ChatModulesHeader } from './ChatModulesHeader';
import { If } from '../../If';

const Wrapper = styled.div`
  position: relative;
  min-height: 60vh;

  h4.ui.header {
    color: #666;
  }
`;

export const ChatModulesPreview = () => {
  const { moduleGraph, loaderStatus, chatId, dirty, notification, hideHeader } = useContext(ChatModulesContext);

  const wrapperRef = useRef();
  const showModuleGraphEditor = loaderStatus === STATUSES.LOADED && moduleGraph.rootNode;

  return (
    <DndProvider backend={HTML5Backend}>
      <Wrapper ref={wrapperRef}>
        <If condition={!hideHeader}>
          <Sticky context={wrapperRef}>
            <ChatModulesHeader />
          </Sticky>
        </If>

        {notification}

        <Dimmer active={loaderStatus === STATUSES.LOADING} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <If condition={showModuleGraphEditor}>
          <ModuleGraphEditor />
        </If>

        <If condition={!showModuleGraphEditor}>
          <NoModules chatId={chatId} />
        </If>

        <If condition={loaderStatus === STATUSES.FAILURE}>
          <Message negative>
            <Message.Header>We're sorry we can't fetch your chat modules</Message.Header>
            <p>
              Something went wrong and we couldn't load the modules that make up your chat dialog flows. Please try
              again later.
            </p>
          </Message>
        </If>
        <RouterPrompt when={dirty} />
      </Wrapper>
    </DndProvider>
  );
};
