import React, { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { useParams } from 'react-router';
import { parseArray, parseIds } from 'utils/hooks/useSearchQuery';
import reducer from './reducer';

const AnalyticsContext = createContext();

export const AnalyticsProvider = (props) => {
  const { public_id } = useParams();

  const searchParams = new URLSearchParams(window.location.search);

  const INITIAL_STATE = {
    publicId: undefined,
    filters: {
      start_date: searchParams.get('start_date'),
      end_date: searchParams.get('end_date'),
      communities: parseIds(searchParams.get('communities')),
      groups: parseIds(searchParams.get('groups')),
      traffic_sources: parseArray(searchParams.get('traffic_sources')),
      channels: parseArray(searchParams.get('channels')),
      care_types: parseArray(searchParams.get('care_types')), // Temporarily not used #2640
      use_movein_date: searchParams.get('use_movein_date') !== 'false',
      use_tour_date: searchParams.get('use_tour_date') !== 'false',
    },
    pageTitle: {
      title: 'Chat Analytics Report',
      subtitle: undefined,
    },
    activeTab: 0,
    report: undefined,
    trafficSources: [],
    careTypes: [],
  };

  const [state, dispatch] = useImmerReducer(reducer, {
    ...INITIAL_STATE,
    publicId: public_id,
  });

  const value = [state, dispatch];

  return <AnalyticsContext.Provider value={value}>{props.children}</AnalyticsContext.Provider>;
};

export function useAnalytics() {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error(`useAnalytics must be used within AnalyticsProvider`);
  }

  return context;
}
