import React from 'react';
import styled from 'styled-components';
import { Header, Icon, Button } from 'semantic-ui-react';

import { NursingHome } from 'components/Icons';
import { PageHeader } from 'components/common';
import { MEDIA_BREAK_POINTS } from '../../constants';
import { setNextStep, useUnitLevelPricing } from './context';

const ActionButton = styled(Button)`
  && {
    @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
      width: 15rem;
    }
  }
`;

const HeaderIconBox = styled.div`
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;

  @media only screen and (min-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    flex-direction: row;
  }
`;

const DescriptionItem = styled.div`
  background: rgba(56, 123, 255, 0.1);
  padding: 1rem;
  border-radius: 0.5rem;
  flex: 1 1 33%;
`;

const DescriptionTitleBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const DescriptionTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
`;

const DescriptionContent = styled.div`
  text-align: justify;
`;

const DESCRIPTION_ITEMS = [
  {
    icon: 'question circle',
    title: 'How it works?',
    description:
      'If you want to know  how you can use this wizard and add prices , please take a look into this video (link) where we are going to  walk you through all the steps.',
  },
  {
    icon: 'target',
    title: 'Why you should share price with us?',
    description:
      "If you share your prices with us, we will share the prices of your competitors in your area with you. We'll send you an email with pricing information so you can analyze them and make your prices more competitive in the marketplace",
  },
  {
    icon: 'user circle',
    title: 'Do I need to have user account?',
    description:
      'You dont need to have user account  to open this link and to be able to add  you prices . All info that you share with us, will be saved and only related to this community. ',
  },
];

const GettingStartedStep = () => {
  const [{ invitation }, dispatch] = useUnitLevelPricing();

  const handleContinue = () => {
    dispatch(setNextStep());
  };

  return (
    <>
      <PageHeader
        title="Hello!"
        subtitle="Can you please give us the price of the units you provide, so that we can help you find out the prices of competitors in your area."
        actions={<ActionButton content="Continue" primary onClick={handleContinue} />}
      />

      <HeaderIconBox>
        <NursingHome width="10rem" height="10rem" fill="#4c4747" />
        <Header as="h1" content={invitation.provider_profile_name} color="blue" />
      </HeaderIconBox>
      <DescriptionBox>
        {DESCRIPTION_ITEMS.map((item, index) => (
          <DescriptionItem key={index}>
            <DescriptionTitleBox>
              <Icon name={item?.icon} size="large" />
              <DescriptionTitle>{item?.title}</DescriptionTitle>
            </DescriptionTitleBox>
            <DescriptionContent>{item?.description}</DescriptionContent>
          </DescriptionItem>
        ))}
      </DescriptionBox>
    </>
  );
};
export default GettingStartedStep;
