import React from 'react';
import { Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const ModuleFormLink = () => {
  const { t } = useTranslation();

  return (
    <>
      <Label horizontal color="black">
        Open Link ({t('community')}-specific)
      </Label>
      <p>Note: The link URL can be configured on the related {t('communities')} tab</p>
    </>
  );
};

export default ModuleFormLink;
