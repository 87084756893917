import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NavigationSection } from './NavigationSection';
import { NavigationItem } from './NavigationItem';
import { TasksNavigationItem } from './TasksNavigationItem';
import { useNavigationContext } from './NavigationContext';

export const UserNavigationMember = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { messagesSummary, scheduledMessagesSummary, loadMessagesSummary, loadScheduledMessagesSummary } =
    useNavigationContext();

  useEffect(() => {
    loadMessagesSummary();
    loadScheduledMessagesSummary();
  }, [location]);

  return (
    <>
      <NavigationSection>
        <NavigationSection.Title>Today's Tasks</NavigationSection.Title>
        <TasksNavigationItem
          to="/messages"
          title="New Messages"
          subtitle={
            messagesSummary?.unread_count > 0 ? `Reply to ${t('leads')} now` : `Archive or reply to ${t('leads')}`
          }
          count={messagesSummary?.active_count}
          newTasksIndicatorVisible={messagesSummary?.active_count > 0}
        />
        <TasksNavigationItem
          to="/scheduled-messages"
          title="Scheduled Messages"
          subtitle={`Review ${t('lead')} outreach`}
          count={scheduledMessagesSummary?.notReviewedMessagesCount}
          newTasksIndicatorVisible={false}
        />
      </NavigationSection>

      <NavigationSection.Divider />

      <NavigationSection>
        <NavigationSection.Title>Other</NavigationSection.Title>
        <NavigationItem to="/conversations" label="All Conversations" icon="textsms-outlined" />
        <NavigationItem to="/advanced" label="Settings" icon="tune" />
      </NavigationSection>
    </>
  );
};
