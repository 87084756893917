export default {
  ADMIN_METRICS: 'adminMetrics',
  METRICS: 'metrics',
  OVERVIEW_STATS: 'overviewStats',
  ENGAGEMENT: 'engagement',
  DEVICE_BREAKDOWN: 'deviceBreakDown',
  HOURLY_BREAKDOWN: 'hourlyBreakdown',
  MOVE_INS: 'moveIns',
  TRAFFIC_SOURCES_BREAKDOWN: 'trafficSourcesBreakdown',
  INSTANT_ANSWERS_ANALYTICS: 'instantAnswersAnalytics',
  KEYWORD_BREAKDOWN: 'keywordBreakdown',
  AVERAGE_LEAD_VALUE_SCORE_GRAPH: 'averageLeadValueScoreGraph',
  LEAD_VALUE_SCORES: 'leadValueScores',
  ENGAGEMENT_GRAPH: 'engagementGraph',
  OWNERS_PROFILES: 'ownersProfiles',
  OWNERS_SUMMARY: 'ownersSummary',
  OWNERS_BREAKDOWN: 'ownersBreakdown',
  BENCHMARKS: 'benchmarks',
  MOVE_IN_KEYWORDS: 'moveInKeywords',
  SALES_ASSISTANT_LEAD_STATUSES: 'salesAssistantLeadStatuses',
  INSTANT_RESPONSES_ANSWERS: 'instantResponsesAnswers',
  INSTANT_RESPONSES_REVIEWS: 'instantResponseReviews',
  INSTANT_RESPONSES_PLACEHOLDERS: 'instantResponsesPlaceholders',
  AUTOMATED_ANSWER_CHANGES: 'automatedAnswerChanges',
  AUTOMATED_ANSWER_DEBUG: 'automatedAnswerDebug',
};
