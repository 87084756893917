import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Divider } from 'components/lib';
import { parseErrorResponse, parseFieldErrors } from 'apis/utils';
import { LoginInputField } from './LoginInputField';
import { PasswordInput } from './PasswordInput';
import { AuthButton } from './AuthButton';
import { signUp } from '../../api';
import { UserContext } from '../UserContext';
import { InputField } from '../common/fields/InputField';
import { AuthLayout } from './AuthLayout';

const SubTitle = styled.p`
  && {
    color: var(--text);
    margin-bottom: 30px;
  }
`;

const EmailDisabled = styled(InputField)`
  &&&.disabled {
    opacity: 1;
    .disabled {
      opacity: 1;
      input {
        color: #89919d;
        border: 0;
        height: 36px;
      }
    }
  }
`;

const SignupForm = ({ firstName, lastName, email, loading, onSubmit }) => {
  const initialValues = {
    email: email || '',
    firstName: firstName || '',
    lastName: lastName || '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Must be a valid email address').required('Required'),
    firstName: Yup.string().max(30, `Can't be longer than 30 characters`).required('Required'),
    lastName: Yup.string().max(150, `Can't be longer than 150 characters`).required('Required'),
    password: Yup.string().required('Required').min(8, 'Passwords must be at least 8 characters long'),
  });

  const submit = (values, { setErrors }) => {
    if (typeof onSubmit === 'function') {
      onSubmit(values, setErrors);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <EmailDisabled type="email" name="email" disabled={!!email} />
          </Form.Field>
          <LoginInputField autoFocus label="First name" type="text" name="firstName" />
          <LoginInputField label="Last name" type="text" name="lastName" />
          <PasswordInput />
          <AuthButton label="Next" loading={loading} disabled={loading} />
        </Form>
      )}
    </Formik>
  );
};

export const Signup = () => {
  const { user, setUser } = useContext(UserContext);
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const email = query.get('email');
  const firstName = query.get('first_name');
  const lastName = query.get('last_name');
  const inviteToken = query.get('i');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!inviteToken || user) {
      history.push('/');
    }
  }, [inviteToken, user, history]);

  const signup = async ({ firstName, lastName, email, password }, onErrors) => {
    try {
      setLoading(true);
      const user = await signUp({ firstName, lastName, email, password, inviteToken });

      setUser(user);
    } catch (e) {
      console.error(e);

      const fieldErrors = parseFieldErrors(e);
      onErrors(fieldErrors);

      if (!fieldErrors) {
        toast.error(
          parseErrorResponse(e, 'Your sign-up invite is invalid or expired. Please reach out to us to get a new one.')
        );
      }

      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <h1>Finish signing up</h1>
      <SubTitle>Please finish creating your account below.</SubTitle>
      <Divider horizontal />
      <SignupForm email={email} firstName={firstName} lastName={lastName} loading={loading} onSubmit={signup} />
    </AuthLayout>
  );
};
