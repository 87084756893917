import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ConversationsDropdownChannel } from './ConversationsDropdownChannel';
import { ConversationsDropdownCommunities } from './ConversationsDropdownCommunities';
import { ConversationsDropdownStatus } from './ConversationsDropdownStatus';
import { ConversationsDropdownActivity } from './ConversationsDropdownActivity';
import { ConversationsDropdownSources } from './ConversationsDropdownSources';
import { ConversationsClearFilters } from './ConversationsClearFilters';
import { ConversationsContext } from './ConversationsContext';
import { If } from '../If';
import { DEFAULT_FILTER } from './constants';

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ConversationsDropdownFilters = () => {
  const { filters } = useContext(ConversationsContext);
  const [showClearFilters, setShowClearFilters] = useState(false);

  useEffect(() => {
    const {
      search,
      communities,
      leadStatus,
      moveInDate,
      tourScheduled,
      financiallyUnqualified,
      trafficSources,
      channelSources,
      inboundCall,
      outboundCall,
      isSurveySubmitted,
      isNoteAdded,
    } = filters;

    const showClearButton =
      search !== DEFAULT_FILTER.search ||
      (communities !== undefined && communities.length) ||
      trafficSources?.length ||
      channelSources.length ||
      leadStatus !== DEFAULT_FILTER.leadStatus ||
      moveInDate !== DEFAULT_FILTER.moveInDate ||
      tourScheduled !== DEFAULT_FILTER.tourScheduled ||
      financiallyUnqualified !== DEFAULT_FILTER.financiallyUnqualified ||
      inboundCall !== DEFAULT_FILTER.inboundCall ||
      outboundCall !== DEFAULT_FILTER.outboundCall ||
      isSurveySubmitted !== DEFAULT_FILTER.isSurveySubmitted ||
      isNoteAdded !== DEFAULT_FILTER.isNoteAdded;

    setShowClearFilters(showClearButton);
  }, [filters]);

  return (
    <Wrapper>
      <ConversationsDropdownCommunities />
      <ConversationsDropdownChannel />
      <ConversationsDropdownStatus />
      <ConversationsDropdownActivity />
      <ConversationsDropdownSources />

      <If condition={showClearFilters}>
        <ConversationsClearFilters />
      </If>
    </Wrapper>
  );
};
