import React, { useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { getMessagingCommunities } from 'apis';
import { If } from 'components/If';
import { Checkbox, Dimmer, Loader, Form } from 'components/lib';
import { SearchField } from './SearchField';
import { FilterDropdown } from './FilterDropdown';
import { useCampaignsTemplate } from '../CampaignsTemplateContext';
import { PAGINATION } from '../../../../constants';
import { StepTitle, StepSubtitle, WizardActions, WizardContent } from './index';

const Wrapper = styled.div``;

const StyledCheckbox = styled(Checkbox)`
  && label {
    font-size: 18px;
    font-weight: 900;
    color: var(--text);
    text-transform: capitalize;

    :before {
      border: 2px solid #777777;
      width: 18px;
      height: 18px;
      top: -3px;
    }

    :after {
      top: -3px;
    }
  }
`;

const MainActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .row-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 25px;
  }
`;

const CommunitiesContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  min-height: 327px;
  max-height: 327px;
  padding-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const FILTER_OPTIONS = [
  { key: 1, text: 'All', value: 'all' },
  { key: 2, text: 'Selected', value: true },
  { key: 3, text: 'Unselected', value: false },
];

export const Communities = () => {
  const initialFilterValue = 'all';
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState(initialFilterValue);
  const [selectAll, setSelectAll] = useState(false);
  const { t } = useTranslation();
  const { state, dispatch, actions } = useCampaignsTemplate();
  const { communities, communitiesLoading: loading, wizardValues } = state;
  const formikRef = useRef();
  const formValues = formikRef?.current?.values;

  const initialFormValues = {
    communities: wizardValues?.communities || [],
  };

  const validationSchema = Yup.object({
    communities: Yup.array().min(1, ''),
  });

  const fetchEligibleCommunities = async () => {
    try {
      dispatch({ type: actions.SET_COMMUNITIES_LOADING, data: true });
      const response = await getMessagingCommunities({ pageSize: PAGINATION.MAX_PAGE_SIZE });
      const communities = response?.results?.sort((p1, p2) => p1.name.localeCompare(p2.name));
      dispatch({ type: actions.SET_COMMUNITIES, data: communities });
      dispatch({ type: actions.SET_COMMUNITIES_LOADING, data: false });
    } catch (e) {
      console.error(e);
      toast.error(`Error fetching ${t('communities')}`);
      dispatch({ type: actions.SET_COMMUNITIES_LOADING, data: false });
    }
  };

  useEffect(() => {
    if (communities && communities.length > 0) {
      return;
    }
    fetchEligibleCommunities();
  }, []);
  const selectedCommunities = useMemo(() => {
    if (!formValues?.communities || formValues.communities.length === 0) return {};

    return formValues.communities.reduce((acc, communityId) => ({ ...acc, [communityId]: true }), {});
  }, [formValues?.communities]);

  const filteredCommunities = useMemo(() => {
    let newCommunities = communities;

    if (!newCommunities || newCommunities.length === 0) {
      return [];
    }

    if (searchValue) {
      newCommunities = communities.filter((community) =>
        community.name?.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    if (filterValue === true) {
      newCommunities = newCommunities.filter((community) => selectedCommunities[community.id]);
    }

    if (!filterValue) {
      newCommunities = newCommunities.filter((community) => !selectedCommunities[community.id]);
    }

    return newCommunities;
  }, [communities, selectedCommunities, searchValue, filterValue]);

  const changeSelectFilter = (e, d) => {
    setFilterValue(d.value);
  };

  const changeSearchValue = (value) => {
    setSearchValue(value);
  };

  const changeSelectAll = (e, d, setFieldValue, values) => {
    setSelectAll(!selectAll);

    if (!d.checked) {
      setFieldValue('communities', []);
      return;
    }

    setFieldValue(
      'communities',
      communities.map((community) => community.id)
    );
  };

  const selectCommunity = (e, d, community, setFieldValue, values) => {
    const oldCommunities = values.communities;
    if (oldCommunities.includes(community.id)) {
      const newCommunities = oldCommunities?.filter((i) => i !== community.id);
      setFieldValue('communities', newCommunities);
      return;
    }

    setFieldValue('communities', [...oldCommunities, community.id]);
  };

  return (
    <Dimmer.Dimmable as={Wrapper} dimmed={loading}>
      <Dimmer active={loading} inverted>
        <Loader active inverted />
      </Dimmer>
      <Formik
        initialValues={initialFormValues}
        innerRef={formikRef}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Form>
            <WizardContent>
              <StepTitle>Which {t('communities')} would you like to include?</StepTitle>
              <StepSubtitle>
                Select the {t('communities')} with {t('leads')} you want to be included in your campaign(s).
              </StepSubtitle>
              <MainActionsContainer>
                <div className="row-item">
                  <StyledCheckbox
                    label={<label>{t('communities')}</label>}
                    onChange={(e, d) => changeSelectAll(e, d, setFieldValue, values)}
                    checked={selectAll}
                  />
                </div>
                <div className="row-item">
                  <SearchField placeholder="Search" value={searchValue} onSearch={changeSearchValue} />
                  <FilterDropdown value={filterValue} options={FILTER_OPTIONS} onChange={changeSelectFilter} />
                </div>
              </MainActionsContainer>
              <If condition={filteredCommunities && filteredCommunities.length > 0}>
                <CommunitiesContainer>
                  {filteredCommunities.map((community) => (
                    <Checkbox
                      key={community.id}
                      label={<label>{community.name}</label>}
                      onChange={(e, d) => selectCommunity(e, d, community, setFieldValue, values)}
                      checked={values.communities?.includes(community.id)}
                    />
                  ))}
                </CommunitiesContainer>
              </If>
            </WizardContent>
            <WizardActions disabled={values.communities.length === 0} values={values} />
          </Form>
        )}
      </Formik>
    </Dimmer.Dimmable>
  );
};
