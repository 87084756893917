import React, { useContext } from 'react';
import styled from 'styled-components';
import { Transition } from 'semantic-ui-react';

import { DisqualifiedNotification } from './DisqualifiedNotification';
import { ArchiveSuggestion } from './ArchiveSuggestion';
import { ConversationContext } from '../ConversationContext';
import { If } from '../../If';

const Wrapper = styled.div`
  max-width: 660px;
`;

export const Notifications = () => {
  const { selectedConversation, archiveConversation, showArchiveSuggestion } = useContext(ConversationContext);

  // Only show disqualified notification if status exists and is explicitly doesn't mark lead as qualified
  const showDisqualifiedNotification = selectedConversation.is_qualified === false;
  const showNotification = showArchiveSuggestion || showDisqualifiedNotification;
  const showArchiveNotification =
    showArchiveSuggestion || (!selectedConversation.messages_archived && showDisqualifiedNotification);

  return (
    <Transition visible={showNotification} animation="slide up" duration={100}>
      <Wrapper>
        <If condition={showDisqualifiedNotification}>
          <DisqualifiedNotification />
        </If>

        <If condition={showArchiveNotification}>
          <ArchiveSuggestion
            onClickArchive={() => {
              archiveConversation(selectedConversation);
            }}
          />
        </If>
      </Wrapper>
    </Transition>
  );
};
