import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormikContext, FieldArray } from 'formik';

import { If } from 'components/If';
import { Divider, Icon, SubtitledCheckboxField } from 'components/lib';
import { usePhoneSystem } from '../PhoneSystemContext';
import { AreaCodeDisclaimerField } from './AreaCodeDisclaimerField';
import { PhoneNumbersList } from '../shared';
import { WebSelectorField } from './WebSelectorField';
import { CampaignNumbersListField } from './CampaignNumbersListField';

const FormBlock = styled.div`
  margin: 20px 30px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--light-text);
  font-family: var(--font-family);
`;

const CampaignNumbersContainer = styled.div`
  display: flex;
  padding: 30px 0px 30px 30px;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  > .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  &.disabled {
    opacity: 0.3;
  }
`;

const StaticPhoneNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--text);
  font-weight: 900;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 26px;

  &.disabled {
    opacity: 0.3;
  }

  .numbers {
    color: var(--link);
    font-weight: 500;
  }

  > div {
    display: flex;
  }
`;

const ExistingCampaignNumbersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  color: var(--text);

  > div {
    display: flex;
    gap: 15px;

    &:last-child {
      padding-right: 9px;
    }
  }
`;

const ExistingCampaignNumbers = ({ campaignNumbers, onRemove }) =>
  campaignNumbers.map((number, i) => (
    <ExistingCampaignNumbersContainer key={i}>
      <div>
        <div>{i + 1}.</div>
        <div>{number.referralOrigin}</div>
      </div>
      <div>
        <div>
          <PhoneNumbersList phoneNumbers={number.phoneNumbers} />
        </div>
        <Icon color="#89919d" name="close" onClick={() => onRemove && onRemove(i)} />
      </div>
    </ExistingCampaignNumbersContainer>
  ));

export const EditPhoneSystemForm = () => {
  const { t } = useTranslation();
  const { globalQuerySelector, selectedCommunity } = usePhoneSystem();
  const { setFieldValue, values, setFieldTouched } = useFormikContext();

  const onDedicatedNumbersCheck = () => {
    setFieldTouched('dedicatedNumbers', false, false);
    setFieldValue('dedicatedNumbers', [], false);
  };

  const hasRegularNumbers = selectedCommunity?.regularPhoneNumbers && selectedCommunity.regularPhoneNumbers.length > 0;
  const hasDirectNumber = !!selectedCommunity?.directPhoneNumber;

  return (
    <>
      <FormBlock>
        <AreaCodeDisclaimerField
          hasErrors={!!selectedCommunity.purchaseErrorStatus}
          hasPurchasedNumbers={selectedCommunity.hasPurchasedPhoneNumbers}
        />
      </FormBlock>
      <FormBlock>
        <SubtitledCheckboxField
          name="poolEnabled"
          title="Website phone numbers"
          subtitle={`Fetches five primary numbers and a backup number, which replace the targeted selector in the HTML of the ${t(
            'community'
          )} website. These numbers are used to record and analyze phone calls, as well as enable use of the Conversational IVR web assistant, which converses with customers automatically.`}
        />
        <StaticPhoneNumbersContainer className={!values.poolEnabled ? 'disabled' : ''}>
          <If condition={hasRegularNumbers || hasDirectNumber}>
            <div>
              <span>Primary:&nbsp;</span>
              <PhoneNumbersList phoneNumbers={selectedCommunity.regularPhoneNumbers} />
              <span />
            </div>
            <div>
              <span>Backup:&nbsp;</span>
              <PhoneNumbersList phoneNumbers={[selectedCommunity.directPhoneNumber]} />
            </div>
          </If>
        </StaticPhoneNumbersContainer>
        <WebSelectorField
          disabled={!values.poolEnabled}
          globalQuerySelector={globalQuerySelector}
          dropdownFieldName="inheritQuerySelector"
          inputFieldName="querySelector"
        />
      </FormBlock>
      <Divider />
      <FormBlock>
        <SubtitledCheckboxField
          title="Campaign phone numbers"
          subtitle="Adds phone numbers to be used for Google or Facebook Ads, or similar. You can add a custom purpose, too."
          name="dedicatedNumbersEnabled"
          onChange={onDedicatedNumbersCheck}
        />
        <CampaignNumbersContainer className={!values.dedicatedNumbersEnabled ? 'disabled' : ''}>
          <FieldArray name="existingDedicatedNumbers">
            {({ remove }) => (
              <ExistingCampaignNumbers
                campaignNumbers={values.existingDedicatedNumbers}
                onRemove={values.dedicatedNumbersEnabled && remove}
              />
            )}
          </FieldArray>
          <CampaignNumbersListField
            startingIndex={values.existingDedicatedNumbers?.length || 0}
            enableItemRemoval={values.existingDedicatedNumbers.length > 0 || values.dedicatedNumbers.length > 1}
          />
        </CampaignNumbersContainer>
      </FormBlock>
    </>
  );
};
