import React, { useMemo } from 'react';
import { FieldArray, Formik, useField } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { If } from 'components/If';
import { Dropdown, Checkbox, Form, Icon, Button, Modal } from 'components/lib';
import { MODULE_TYPES, MODULE_ROLES, TIME_RULES, DAYS_OF_WEEK } from 'components/chats/ChatModules/constants';

const ChecboxLabel = styled.label`
  display: flex;
  align-items: center;
  width: 110px;
  margin-right: 20px;
  padding-left: 23px;
  margin-bottom: 10px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const FormField = styled(Form.Field)`
  &&&& {
    margin-bottom: 10px;
  }
`;

const Day = styled.span`
  font-family: var(--font-weight-bold);
  font-size: 13px;
  font-weight: 900;
  color: var(--text);
  margin-left: 10px;
  padding-top: 5px;
  text-transform: capitalize;
`;

const StyledDropdown = styled(Dropdown)`
  &&&& {
    min-width: auto;
    width: 110px !important;
    margin-right: 20px;
  }
`;

const ModuleWraper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-standard);
  padding: 15px;
  height: 230px;
  .icon {
    margin-left: 7px;
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const Content = styled.div`
  padding: 25px 30px;
  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const StyledModal = styled(Modal)`
  &.ui.large.modal {
    width: 1250px;
  }
`;

const Module = styled.div`
  label {
    height: 20px;
  }
  margin-bottom: auto;
  margin-top: 41px;
`;

const DayWrapper = styled.div`
  && {
    .field > label {
      margin-bottom: 10px;
    }
    .field {
      &.error {
        position: relative;
        .pointing.above.prompt.label {
          position: absolute;
          left: 0;
          top: 90%;
          z-index: 1;
          width: 295px;
          &:before {
            left: 0;
            transform: translateX(100%) translateY(-50%) rotate(45deg);
          }
        }
      }
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 30px;
  width: 100%;
  border-top: 1px solid var(--line-standard);
`;

const NoRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoRulesTitle = styled.div`
  font-family: var(--font-family-bold);
  font-size: 14px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
`;

const NoRulesSubtitle = styled.div`
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #89919d;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const getModuleList = (modules) =>
  modules
    .filter((m) => m.module_type === MODULE_TYPES.OPTION && m.role === MODULE_ROLES.TIME_RULE_BASED_FLOW)
    .map((m) => ({
      key: m.id,
      value: m.id,
      text: `Module ${m?.number} [${m?.module_type}]`,
    }));

const getAnswersList = (answers, values) => {
  const checkIfAnswerIsUsed = (answer) => {
    return values.modules.some(
      (m) => (answer.legacy_answer_id && answer.legacy_answer_id === m.answer) || answer.id === m.answer
    );
  };
  return answers?.map((a) => ({
    key: a.legacy_answer_id ? a.legacy_answer_id : a.id,
    value: a.legacy_answer_id ? a.legacy_answer_id : a.id,
    text: `${a.text} - ${a.rank + 1}`,
    disabled: checkIfAnswerIsUsed(a),
  }));
};

const validationSchema = Yup.object().shape({
  modules: Yup.array().of(
    Yup.object().shape({
      module: Yup.string().required('Module is required'),
      answer: Yup.string().required('Answer is required'),
      days: Yup.array().of(
        Yup.object().shape({
          enabled: Yup.boolean(),
          startTime: Yup.number()
            .min(0)
            .max(23)
            .when('enabled', {
              is: true,
              then: Yup.number().required('Start time is required'),
              otherwise: Yup.number().nullable(),
            }),
          endTime: Yup.number()
            .min(0)
            .max(23)
            .when('enabled', {
              is: true,
              then: Yup.number().required('End time is required'),
              otherwise: Yup.number().nullable(),
            }),
        })
      ),
    })
  ),
});

const checkForOverlaps = (modules) => {
  const errors = {};
  modules.forEach((mod, modIndex) => {
    mod.days.forEach((day, dayIndex) => {
      if (day.enabled) {
        const currentStart = parseInt(day.startTime);
        let currentEnd = parseInt(day.endTime);
        currentEnd = currentStart < currentEnd ? currentEnd : currentEnd + 24;

        modules.forEach((otherMod, otherModIndex) => {
          if (mod.module === otherMod.module && modIndex !== otherModIndex) {
            const otherDay = otherMod.days[dayIndex];
            if (otherDay.enabled) {
              const otherStart = parseInt(otherDay.startTime);
              let otherEnd = parseInt(otherDay.endTime);
              otherEnd = otherStart < otherEnd ? otherEnd : otherEnd + 24;
              if (currentStart === otherStart) {
                errors.modules = errors.modules ? [...errors.modules] : [];
                errors.modules[modIndex] = {
                  ...errors.modules[modIndex],
                  days: {
                    ...errors.modules[modIndex]?.days,
                    [dayIndex]: {
                      ...errors.modules[modIndex]?.days?.[dayIndex],
                      startTime: 'Rule collision occurred, time must be changed.',
                      endTime: 'Rule collision occurred, time must be changed.',
                    },
                  },
                };
              }
              if (
                (currentStart > otherEnd && otherEnd > currentStart) ||
                (currentEnd > 24 && currentEnd - 24 > otherEnd)
              ) {
                errors.modules = errors.modules ? [...errors.modules] : [];
                errors.modules[modIndex] = {
                  ...errors.modules[modIndex],
                  days: {
                    ...errors.modules[modIndex]?.days,
                    [dayIndex]: {
                      ...errors.modules[modIndex]?.days?.[dayIndex],
                      startTime: 'Rule collision occurred, time must be changed.',
                      endTime: 'Rule collision occurred, time must be changed.',
                    },
                  },
                };
              }
              if (
                (currentStart < otherEnd && currentEnd > otherEnd) ||
                (otherEnd > 24 && otherEnd - 24 > currentStart)
              ) {
                errors.modules = errors.modules ? [...errors.modules] : [];
                errors.modules[modIndex] = {
                  ...errors.modules[modIndex],
                  days: {
                    ...errors.modules[modIndex]?.days,
                    [dayIndex]: {
                      ...errors.modules[modIndex]?.days?.[dayIndex],
                      startTime: 'Rule collision occurred, time must be changed.',
                      endTime: 'Rule collision occurred, time must be changed.',
                    },
                  },
                };
              }
            }
          }
        });
      }
    });
  });
  return errors;
};

const transformApiResponse = (apiResponse) => {
  return apiResponse.map((rule) => {
    const days = DAYS_OF_WEEK.map((day) => {
      if (rule.active_periods?.[day]) {
        return {
          enabled: true,
          startTime: rule.active_periods[day][0],
          endTime: rule.active_periods[day][1],
        };
      }
      return {
        enabled: false,
        startTime: 9,
        endTime: 17,
      };
    });

    return {
      module: rule.module_id,
      answer: rule.answer_id,
      days,
    };
  });
};

const checkIfAllModulesAndAnswersAreUsed = (modules, values) => {
  const sumOfAnswers = modules
    .filter((m) => m.module_type === MODULE_TYPES.OPTION && m.role === MODULE_ROLES.TIME_RULE_BASED_FLOW)
    .reduce((acc, m) => acc + m.answers.length, 0);
  return values.modules.length === sumOfAnswers;
};

export const ModuleTimeBasedModal = ({ modules, open, onClose, ...props }) => {
  const [, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const moduleTimeRules = [...meta.value];

  const transformFormData = (formData) => {
    return {
      modules: formData.modules.map((module) => ({
        module_id: module.module,
        answer_id: module.answer,
        active_periods: module.days.reduce((acc, day, index) => {
          if (day.enabled) {
            const dayName = DAYS_OF_WEEK[index];
            acc[dayName] = [day.startTime, day.endTime];
          }
          return acc;
        }, {}),
      })),
    };
  };

  const moduleList = getModuleList(modules);

  const initialValues = useMemo(
    () => ({
      modules: transformApiResponse(moduleTimeRules),
    }),
    [moduleTimeRules]
  );

  const answerList = (module, values) => {
    if (!module) return [];
    const moduleObject = modules.find((m) => m.id === module.module);

    const eligibleAnswers = moduleObject?.answers?.filter(
      (a) =>
        !moduleTimeRules.some(
          (tr) => (a.legacy_answer && a.legacy_answer === tr.answer) || (!a.legacy_answer && a.id === tr.answer)
        )
    );

    return getAnswersList(eligibleAnswers, values);
  };

  const handleSubmit = (values) => {
    setValue(transformFormData(values)?.modules);
    onClose();
  };

  return (
    <StyledModal open={open} closeIcon onClose={onClose} size="large">
      <Modal.Header>Edit time based rules</Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={(values) => checkForOverlaps(values.modules)}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {({ values, setFieldValue, handleSubmit, isValid, errors, dirty, touched }) => (
          <Form>
            <Modal.Content>
              <Content>
                <If condition={values.modules.length === 0}>
                  <NoRulesContainer>
                    <NoRulesTitle>There are no rules specified.</NoRulesTitle>
                    <NoRulesSubtitle>Start by adding a rule below.</NoRulesSubtitle>
                  </NoRulesContainer>
                </If>
                <FieldArray name="modules">
                  {({ push, remove }) => (
                    <div>
                      {values.modules.map((module, index) => {
                        return (
                          <ModuleWraper key={index}>
                            <Module>
                              <FormField
                                label="Module"
                                control={StyledDropdown}
                                name={`modules[${index}].module`}
                                selection
                                options={moduleList}
                                search={(options, query) =>
                                  options.filter((o) => o.search.includes(query.toLowerCase()))
                                }
                                value={values.modules[index].module}
                                onChange={(e, { value }) => {
                                  setFieldValue(`modules[${index}].module`, value);
                                }}
                                error={errors.modules?.[index]?.module}
                              />
                            </Module>
                            <Module>
                              <FormField
                                label="Answer"
                                control={StyledDropdown}
                                name={`modules[${index}].answer`}
                                selection
                                options={answerList(module, values)}
                                search={(options, query) =>
                                  options.filter((o) => o.search.includes(query.toLowerCase()))
                                }
                                value={values.modules[index].answer}
                                disabled={!module.module}
                                onChange={(e, { value }) => {
                                  setFieldValue(`modules[${index}].answer`, value);
                                }}
                                error={errors.modules?.[index]?.answer}
                              />
                            </Module>
                            <CheckboxWrapper>
                              {DAYS_OF_WEEK.map((day, dayIndex) => (
                                <DayWrapper key={dayIndex}>
                                  <ChecboxLabel>
                                    <Checkbox
                                      name={`modules[${index}].days[${dayIndex}].enabled`}
                                      checked={values.modules[index].days[dayIndex].enabled}
                                      onChange={() => {
                                        setFieldValue(
                                          `modules[${index}].days[${dayIndex}.enabled`,
                                          !values.modules[index].days[dayIndex].enabled
                                        );
                                      }}
                                    />
                                    <Day>{day.substring(0, 3)}</Day>
                                  </ChecboxLabel>

                                  <div>
                                    <FormField
                                      label="Start Time"
                                      control={StyledDropdown}
                                      name={`modules[${index}].days[${dayIndex}].startTime`}
                                      selection
                                      options={TIME_RULES.map((time) => ({
                                        key: time.key,
                                        text: time.text,
                                        value: time.value,
                                      }))}
                                      value={values.modules[index].days[dayIndex].startTime}
                                      onChange={(e, { value }) => {
                                        setFieldValue(`modules[${index}].days[${dayIndex}].startTime`, value);
                                      }}
                                      disabled={!values.modules[index].days[dayIndex].enabled}
                                      error={errors.modules?.[index]?.days?.[dayIndex]?.startTime}
                                    />

                                    <FormField
                                      label="End Time"
                                      control={StyledDropdown}
                                      name={`modules[${index}].days[${dayIndex}].endTime`}
                                      selection
                                      options={TIME_RULES.map((time) => ({
                                        key: time.key,
                                        text: time.text,
                                        value: time.value,
                                      }))}
                                      value={values.modules[index].days[dayIndex].endTime}
                                      onChange={(e, { value }) => {
                                        setFieldValue(`modules[${index}].days[${dayIndex}].endTime`, value);
                                      }}
                                      disabled={!values.modules[index].days[dayIndex].enabled}
                                      error={errors.modules?.[index]?.days?.[dayIndex]?.endTime}
                                    />
                                  </div>
                                </DayWrapper>
                              ))}
                            </CheckboxWrapper>
                            <Icon name="close" color="var(--text)" onClick={() => remove(index)} />
                          </ModuleWraper>
                        );
                      })}
                      <If condition={!checkIfAllModulesAndAnswersAreUsed(modules, values)}>
                        <ButtonWrapper>
                          <Button
                            primary
                            onClick={() =>
                              push({
                                module: '',
                                answer: '',
                                days: Array(7).fill({ enabled: false, startTime: 9, endTime: 17 }),
                              })
                            }
                            size="mini"
                            type="button"
                            disabled={!isValid}
                          >
                            Add module
                          </Button>
                        </ButtonWrapper>
                      </If>
                    </div>
                  )}
                </FieldArray>
              </Content>
            </Modal.Content>
            <Modal.Actions>
              <Footer>
                <Button
                  primary
                  color="blue"
                  type="button"
                  onClick={handleSubmit}
                  disabled={!dirty || !touched || !isValid}
                >
                  Save
                </Button>
              </Footer>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </StyledModal>
  );
};
