import React, { useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { Button, Popup, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { If } from 'components/If';

const MARKDOWN_TYPES = {
  LINK: 'LINK',
  H1: 'H1',
  BOLD: 'BOLD',
  ITALIC: 'ITALIC',
  CODE: 'CODE',
  IMAGE: 'IMAGE',
};

const MarkdownContainer = styled(ReactMarkdown)`
  background-color: #eaeff7;
  display: inline-block;
  position: relative;
  border-radius: 24px 24px 24px 3px;
  padding: 10px 21px 11px 16px;
  line-height: 20px;
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EditorHelpers = styled.div`
  display: flex;
  margin-bottom: 12px;
  width: 100%;
  .ui.buttons {
    flex: 1;
  }
`;

export const MessageField = (props) => {
  const [field] = useField(props);
  const textareaRef = useRef();
  const { value, onChange, ...otherFieldProps } = field;
  const { setFieldValue } = useFormikContext();

  const addMarkdown = (type) => {
    const selectionStart = textareaRef.current.ref.current.selectionStart;
    const selectionEnd = textareaRef.current.ref.current.selectionEnd;
    switch (type) {
      case MARKDOWN_TYPES.LINK:
        addLink(selectionStart, selectionEnd);
        break;
      case MARKDOWN_TYPES.H1:
        addHeading(selectionStart, selectionEnd);
        break;
      case MARKDOWN_TYPES.BOLD:
        addBold(selectionStart, selectionEnd);
        break;
      case MARKDOWN_TYPES.ITALIC:
        addItalic(selectionStart, selectionEnd);
        break;
      case MARKDOWN_TYPES.CODE:
        addCode(selectionStart, selectionEnd);
        break;
      case MARKDOWN_TYPES.IMAGE:
        addImage(selectionStart, selectionEnd);
        break;

      default:
        break;
    }
  };

  const splitValues = (value, selectionStart, selectionEnd) => {
    const before = value.slice(0, selectionStart);
    const selectedText = value.slice(selectionStart, selectionEnd);
    const end = value.slice(selectionEnd, value.lenth);
    return [before, selectedText, end];
  };

  const addLink = (selectionStart, selectionEnd) => {
    if (selectionStart === selectionEnd) {
      setFieldValue(props.name, `${value} [Link text](https://link.goes.here)`);
    } else {
      const [before, selectedText, end] = splitValues(value, selectionStart, selectionEnd);
      setFieldValue(props.name, `${before}[${selectedText}](https://link.goes.here)${end}`);
    }
  };

  const addImage = (selectionStart, selectionEnd) => {
    if (selectionStart === selectionEnd) {
      setFieldValue(props.name, `${value} ![Image Alt text](https://image.link.goes.here)`);
    } else {
      const [before, selectedText, end] = splitValues(value, selectionStart, selectionEnd);
      setFieldValue(props.name, `${before}![Image Alt text](${selectedText})${end}`);
    }
  };

  const addBold = (selectionStart, selectionEnd) => {
    if (selectionStart === selectionEnd) {
      setFieldValue(props.name, `${value} **Bold text**`);
    } else {
      const [before, selectedText, end] = splitValues(value, selectionStart, selectionEnd);
      setFieldValue(props.name, `${before}**${selectedText}**${end}`);
    }
  };

  const addItalic = (selectionStart, selectionEnd) => {
    if (selectionStart === selectionEnd) {
      setFieldValue(props.name, `${value} *italic text*`);
    } else {
      const [before, selectedText, end] = splitValues(value, selectionStart, selectionEnd);
      setFieldValue(props.name, `${before}*${selectedText}*${end}`);
    }
  };

  const addCode = (selectionStart, selectionEnd) => {
    if (selectionStart === selectionEnd) {
      setFieldValue(props.name, `${value} \`code text\``);
    } else {
      const [before, selectedText, end] = splitValues(value, selectionStart, selectionEnd);
      setFieldValue(props.name, `${before}\`${selectedText}\`${end}`);
    }
  };

  const addHeading = (selectionStart, selectionEnd) => {
    if (selectionStart === selectionEnd) {
      setFieldValue(props.name, `${value} \n # heading`);
    } else {
      const [before, selectedText, end] = splitValues(value, selectionStart, selectionEnd);
      setFieldValue(props.name, `${before !== '' ? `${before}\n` : ''}# ${selectedText}\n${end.trim()}`);
    }
  };

  if (props.preview) {
    return <MarkdownContainer linkTarget="_blank">{value}</MarkdownContainer>;
  }

  return (
    <EditorContainer>
      <If condition={props.markdown}>
        <EditorHelpers>
          <Button.Group>
            <Popup
              content="Add header"
              trigger={<Button icon="heading" type="button" onClick={() => addMarkdown(MARKDOWN_TYPES.H1)} />}
            />
            <Popup
              content="Bold"
              trigger={<Button icon="bold" type="button" onClick={() => addMarkdown(MARKDOWN_TYPES.BOLD)} />}
            />
            <Popup
              content="Itelic feed"
              trigger={<Button icon="italic" type="button" onClick={() => addMarkdown(MARKDOWN_TYPES.ITALIC)} />}
            />
            <Popup
              content="Code"
              trigger={<Button icon="code" type="button" onClick={() => addMarkdown(MARKDOWN_TYPES.CODE)} />}
            />
            <Popup
              content="Add Link"
              trigger={<Button icon="linkify" type="button" onClick={() => addMarkdown(MARKDOWN_TYPES.LINK)} />}
            />
            <Popup
              content="Add Image"
              trigger={<Button icon="image" type="button" onClick={() => addMarkdown(MARKDOWN_TYPES.IMAGE)} />}
            />
          </Button.Group>
        </EditorHelpers>
      </If>
      <TextArea
        {...otherFieldProps}
        {...props}
        preview={undefined}
        markdown={undefined}
        ref={textareaRef}
        onChange={onChange}
        autoFocus
        value={value !== null ? value : ''}
        placeholder="Module text..."
        size="small"
      />
    </EditorContainer>
  );
};
