import { MultiSelectFilter } from 'components/common';
import React, { useContext, useEffect, useState } from 'react';
import { ConversationsContext } from './ConversationsContext';

export const ConversationsDropdownChannel = () => {
  const { filters, handleFiltersChange, channelSources } = useContext(ConversationsContext);
  const [selectedChannels, setSelectedChannels] = useState(null);

  const channelSourceOptions = channelSources.map((cs, id) => ({
    id,
    name: cs.display_name,
    field_name: cs.name,
  }));

  useEffect(() => {
    const selectedChannelOptions = filters.channelSources.map(
      (channelSource) => channelSourceOptions.find((option) => option.field_name === channelSource)?.id
    );

    setSelectedChannels(selectedChannelOptions.length > 0 ? selectedChannelOptions : null);
  }, [filters.channelSources, channelSourceOptions.length]);

  const onChannelChange = (value) => {
    handleFiltersChange({
      channelSources: channelSourceOptions
        .filter((option) => value?.includes(option.id))
        .map((channelSource) => channelSource.field_name),
    });
  };

  return (
    <MultiSelectFilter
      name="Channel"
      placeholder="Any channel"
      options={channelSourceOptions}
      selectedValues={selectedChannels}
      onChange={onChannelChange}
    />
  );
};
