import React from 'react';
import styled from 'styled-components';

import { Grid as SemanticGrid } from 'semantic-ui-react';

const StyledGrid = styled(SemanticGrid)``;

export const Grid = ({ ...props }) => <StyledGrid {...props} />;

Grid.Row = SemanticGrid.Row;
Grid.Column = SemanticGrid.Column;
