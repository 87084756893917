import React from 'react';
import { Header, Divider, Form } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { CARE_TYPES, setNextStep, setUnits, useUnitLevelPricing } from './context';
import UnitLevelPricingStepActions from './UnitLevelPricingStepActions';
import PriceStepTable from './PriceStepTable';

const PriceStep = () => {
  const [{ careTypes, units }, dispatch] = useUnitLevelPricing();
  const selectedCareTypes = CARE_TYPES.filter((c) => careTypes.includes(c.id));
  const initialValues = {
    units,
  };

  const validationSchema = Yup.object({
    units: Yup.array().of(
      Yup.object({
        careTypes: Yup.array().of(
          Yup.object({
            minPrice: Yup.number().nullable().min(1, 'Min price must be grater than 0.'),
            maxPrice: Yup.number().when('minPrice', {
              is: (val) => val > 0,
              then: Yup.number()
                .required('Max price is required.')
                .min(Yup.ref('minPrice'), 'Max price must be grater than or equal to min price.'),
              otherwise: Yup.number().nullable().min(1, 'Max price must be grater than 0.'),
            }),
          })
        ),
      })
    ),
  });

  const handleSubmit = ({ units }) => {
    dispatch(setUnits(units));
    dispatch(setNextStep());
  };

  return (
    <>
      <Header as="h3">
        <Header.Content>
          What is the monthly cost per unit and care type?
          <Header.Subheader>
            Enter monthly rate per unit and care level, and then click continue button to define the number of rooms
            available.
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Divider hidden />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <Form>
            <PriceStepTable careTypes={selectedCareTypes} />
            <UnitLevelPricingStepActions onNextStep={handleSubmit} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PriceStep;
