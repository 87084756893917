import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { ConversationContext } from 'components/conversation/ConversationContext';
import { Dropdown } from 'components/lib';
import { CONVERSATION_ACTIONS, NEW_CALENDAR } from './constants';

const CalendarPickerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  span {
    flex: 1 0 auto;
    font-family: var(--font-family-bold);
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--text);
    margin-right: 20px;
  }
`;

export const CalendarSelector = () => {
  const { calendars, selectedCalendar, setSelectedCalendar, showModal } = useContext(ConversationContext);
  const [calendarOptions, setCalendarOptions] = useState([]);
  const initialCalendarIdRef = useRef(null);

  useEffect(() => {
    const options = calendars?.map((calendar) => ({
      key: calendar.id,
      value: calendar.id,
      text: calendar.email,
    }));

    setCalendarOptions([
      ...options,
      {
        key: options?.length + 1,
        value: NEW_CALENDAR.value,
        text: NEW_CALENDAR.text,
      },
    ]);
    initialCalendarIdRef.current = selectedCalendar;
    return () => {
      setSelectedCalendar(initialCalendarIdRef.current);
    };
  }, [calendars]);

  const handleCalendarChange = (e, { value }) => {
    if (value === NEW_CALENDAR.value) {
      showModal(CONVERSATION_ACTIONS.NEW_CALENDAR);
    }
    setSelectedCalendar(value);
  };
  return (
    <CalendarPickerContainer>
      <span>Add event to calendar:</span>
      <Dropdown
        options={calendarOptions}
        basic
        fluid
        selection
        value={selectedCalendar}
        onChange={handleCalendarChange}
      />
    </CalendarPickerContainer>
  );
};
