import React from 'react';
import styled from 'styled-components';
import { Statistic, Label } from 'semantic-ui-react';

import { formatCount, formatPercent } from '../../utils';

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  border: 1px solid rgba(34, 36, 38, 0.1);
  background: #f9fafb;
  border-bottom: #f9fafb;
  margin: -1px;

  .message {
    background-color: white;
    box-shadow: 0 0.3rem 0.3rem -0.25rem rgb(0 0 0 / 30%);
    border: 1px solid rgb(160 163 166 / 10%);
    position: relative;
    border-radius: 1.71rem 1.71rem 1.71rem 0.21rem;
    padding: 1rem 1.85rem 1.1rem 1.5rem;
    line-height: 1.43rem;
    max-width: 20rem;
    margin-right: 2rem;
  }
`;
export const Question = ({ node, answerCount, parentCount, children }) => {
  const { question, source_type, source_role } = node;

  return (
    <>
      <QuestionWrapper>
        <div className="message">
          {question && (
            <>
              {question}
              <br />
            </>
          )}
          {source_type === 'link' && <Label content="Open External Link" icon="linkify" />}
          {source_role === 'gallery' && <Label content="Open External Link" icon="image outline" />}
        </div>
        <div className="stats">
          <Statistic label="Answers" value={formatCount(answerCount)} size="mini" />
          <Statistic label="Conversion" value={formatPercent(answerCount, parentCount)} size="mini" />
        </div>
      </QuestionWrapper>
      {children}
    </>
  );
};
