import React from 'react';
import styled from 'styled-components';
import { useFormikContext, useField } from 'formik';
import { useDrag } from 'react-dnd';
import { Form, Icon, Button } from 'semantic-ui-react';

import { ACTION_TYPES } from './ModuleGraph';
import { DropZone } from './DropZone';
import { MultiOptionButton } from './MultiOptionButton';
import { GrabHandleWrapper } from './GrabHandleWrapper';

const OptionButton = styled(MultiOptionButton)`
  display: flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  box-shadow: none;
  padding: 0.5rem 0;
  width: 100%;

  &:hover {
    box-shadow: none;
  }

  .ui.basic.button {
    border: none;
    box-shadow: none;
    opacity: 0.1;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const AddButton = styled(MultiOptionButton)`
  background-color: #f0f0f0;
  width: 100%;
  box-shadow: none;
  border: 1px solid #f0f0f0;
  text-align: center;

  &:hover {
    box-shadow: none;
    background-color: #f5f5f5;
  }
`;

const AnswerTextInput = styled.input`
  border: none;
`;

const Answer = ({ answer, index, onChange, onDelete }) => {
  const { values: node } = useFormikContext();
  const { module } = node;
  const [, drag, preview] = useDrag({
    item: { type: `options_in_module_${module?.number}`, value: { answer, index } },
  });

  return (
    <OptionButton ref={preview}>
      <GrabHandleWrapper ref={drag}>
        <Icon name="ellipsis vertical" />
        <Icon name="ellipsis vertical" />
      </GrabHandleWrapper>
      <AnswerTextInput
        autoFocus
        value={answer.text}
        onChange={(e) => {
          if (typeof onChange === 'function') {
            onChange({
              ...answer,
              text: e.target.value,
            });
          }
        }}
      />
      <Button
        basic
        icon="delete"
        onClick={() => {
          if (typeof onDelete === 'function') {
            onDelete();
          }
        }}
      />
    </OptionButton>
  );
};

export const MultiOptionAnswersField = (props) => {
  const [field, , { setValue }] = useField(props);
  const answers = field.value;

  const addAnswer = () => {
    const newAnswer = {
      text: '',
      rank: answers.length,
      action: null,
      meta: {
        actionType: ACTION_TYPES.STEP,
      },
    };
    setValue([...answers, newAnswer]);
  };

  const deleteAnswer = (index) => () => {
    const answerValues = [...answers];
    answerValues.splice(index, 1);

    setValue(answerValues);
  };

  const updateAnswer = (answer, index) => {
    setValue(answers.map((a, i) => (i === index ? answer : a)));
  };

  const onDrop = (droppedItem, destinationIndex) => {
    const { answer, index } = droppedItem.value;
    const newAnswers = [...answers];
    const tmp = newAnswers[destinationIndex];
    newAnswers[destinationIndex] = answer;
    newAnswers[index] = tmp;
    setValue(newAnswers.map((a, i) => ({ ...a, rank: i })));
  };

  return (
    <Form.Field>
      <label>Options</label>
      {answers.map((a, i) => (
        <DropZone
          key={i}
          index={i}
          onDrop={(d) => {
            onDrop(d, i);
          }}
        >
          <Answer
            answer={a}
            index={i}
            onChange={(answer) => {
              updateAnswer(answer, i);
            }}
            onDelete={deleteAnswer(i)}
          />
        </DropZone>
      ))}
      <AddButton onClick={addAnswer}>
        <Icon name="add" />
        Add Option
      </AddButton>
    </Form.Field>
  );
};
