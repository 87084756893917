import React from 'react';
import { PieChart } from './PieChart';
import { useSystemStatsFilters } from '../SystemStatsFiltersContext';

const metrics = ['answers.sent', 'answers.automated'];

export const AutomatedPieChart = () => {
  const { reviewFilters } = useSystemStatsFilters();
  return (
    <PieChart
      metrics={metrics}
      filters={reviewFilters}
      units="Sent Messages"
      getCircleData={(data) =>
        data
          ? {
              Automated: data['answers.automated']?.value,
              Manual: data['answers.sent']?.value - data['answers.automated']?.value,
            }
          : {}
      }
    />
  );
};
