import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useOutsideClick } from 'utils/hooks';
import { Dropdown, Icon } from 'components/lib';
import SearchInput from 'components/SearchInput';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  .ui.fluid.dropdown {
    height: 0;
  }
`;

const DropdownItem = styled(Dropdown.Item)`
  &&&& {
    display: flex;
    align-items: center;
    border-bottom: solid 1px var(--line-standard);
    height: 36px;
    white-space: normal;
  }
`;

export const MultiSelectDropdown = ({ options, onChange, selectedValues, open = false, ...props }) => {
  const wrapperRef = useRef(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(open);

  useOutsideClick(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    let items;

    if (options.some((o) => typeof o === 'string')) {
      // Assumes all options are strings
      items = options?.map((o, i) => ({
        key: i,
        text: o,
        value: o,
      }));
    } else {
      // Assumes all options are {id, name} objects
      items = options?.map((o) => ({
        ...o,
        key: o.id,
        text: o.name,
        value: o.id,
      }));
    }

    setStateOptions(items);
  }, [options]);

  useEffect(() => {
    if (!selectedValues || selectedValues.length === 0 || !stateOptions || stateOptions.length === 0) {
      setSelectedItems([]);
      return;
    }

    const newItems = stateOptions.filter((o) => selectedValues.includes(o.value));
    setSelectedItems(newItems);
  }, [selectedValues, stateOptions]);

  const handleChange = (value) => {
    if (typeof onChange === 'function') onChange(value);
  };

  const handleSearchClick = () => {
    if (isOpen) return;
    setIsOpen(true);
  };

  const selectItem = (item) => {
    const newItems = [...selectedItems, item];
    setSelectedItems(newItems);
    handleChange(newItems);
  };

  const unselectItem = (item) => {
    const newItems = selectedItems.filter((i) => i !== item);
    setSelectedItems(newItems);
    handleChange(newItems);
  };

  const matchesSearch = useCallback(
    (item) => item.text?.toLowerCase().includes(searchQuery.toLowerCase()),
    [searchQuery]
  );

  const filteredUnselectedItems = useMemo(
    () => stateOptions.filter((item) => matchesSearch(item) && !selectedItems.some((i) => i.value === item.value)),
    [stateOptions, selectedItems, searchQuery]
  );

  return (
    <Wrapper ref={wrapperRef}>
      <SearchInput
        fluid
        value={searchQuery}
        onSearch={setSearchQuery}
        placeholder={props.placeholder}
        onClick={handleSearchClick}
        autoFocus={open}
      />
      <Dropdown fluid icon={null} open={isOpen} scrolling {...props} placeholder="">
        <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
          {selectedItems.map((o) => (
            <DropdownItem key={o.key} onClick={() => unselectItem(o)}>
              <Icon name="check" size="20" color="var(--emerald-green)" /> {o.text}
            </DropdownItem>
          ))}

          {filteredUnselectedItems.map((o) => (
            <DropdownItem key={o.key} onClick={() => selectItem(o)}>
              {o.text}
            </DropdownItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};
