import React from 'react';
import styled from 'styled-components';

import { useFormikContext, FieldArray } from 'formik';

import { InputField } from 'components/common';
import { If } from 'components/If';
import { Dropdown, Icon, Input } from 'components/lib';
import {
  REFERRAL_SOURCE_OPTIONS,
  QUANTITY_SELECTOR_OPTIONS,
  DEFAULT_CAMPAIGN_REFERRAL_SOURCE,
  DEFAULT_DEDICATED_NUMBER_ITEM,
} from '../constants';
import { AnotherPurposeButton } from '../shared';

const CampaignNumbersTableRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
  color: var(--text);

  &&&&.error-row {
    margin-bottom: 15px;
  }

  div {
    &:nth-last-child(1) {
    }
    &:nth-last-child(2) {
      width: 170px;
    }
    &:nth-last-child(3) {
      width: 190px;
    }
    &:nth-last-child(4) {
      width: 170px;
    }
    &:nth-last-child(5) {
    }
  }
`;

export const CampaignNumbersListField = ({ startingIndex = 0, enableItemRemoval }) => {
  const { setFieldValue, values, errors, touched } = useFormikContext();

  const hasRowErrorMessage = (rowIndex) => {
    if (!errors.dedicatedNumbers || !touched.dedicatedNumbers) return false;
    return (
      (touched.dedicatedNumbers[rowIndex]?.referralOrigin && errors.dedicatedNumbers[rowIndex]?.referralOrigin) ||
      (touched.dedicatedNumbers[rowIndex]?.referralOriginType && errors.dedicatedNumbers[rowIndex]?.referralOriginType)
    );
  };

  return (
    <FieldArray name="dedicatedNumbers">
      {({ push, remove }) => (
        <>
          {values.dedicatedNumbers.map((_, i) => {
            const rowIndex = startingIndex + i + 1;
            return (
              <CampaignNumbersTableRow key={i} className={hasRowErrorMessage(i) ? 'error-row' : ''}>
                <div>{rowIndex}.</div>
                <div>
                  <InputField
                    name={`dedicatedNumbers[${i}].referralOriginType`}
                    control={Dropdown}
                    basic
                    fluid
                    selection
                    options={REFERRAL_SOURCE_OPTIONS}
                    size="small"
                    onChanged={(value) => {
                      if (value !== DEFAULT_CAMPAIGN_REFERRAL_SOURCE) {
                        setFieldValue(`dedicatedNumbers[${i}].referralOrigin`, value);
                      } else {
                        setFieldValue(`dedicatedNumbers[${i}].referralOrigin`, '');
                      }
                    }}
                    disabled={!values.dedicatedNumbersEnabled}
                    placeholder="Select Campaign"
                    newError
                  />
                </div>
                <div>
                  <If condition={values.dedicatedNumbers[i].referralOriginType === DEFAULT_CAMPAIGN_REFERRAL_SOURCE}>
                    <InputField
                      name={`dedicatedNumbers[${i}].referralOrigin`}
                      control={Input}
                      newError
                      disabled={!values.dedicatedNumbersEnabled}
                    />
                  </If>
                </div>
                <div>
                  <InputField
                    name={`dedicatedNumbers[${i}].quantity`}
                    control={Dropdown}
                    basic
                    fluid
                    selection
                    options={QUANTITY_SELECTOR_OPTIONS}
                    size="small"
                    disabled={!values.dedicatedNumbersEnabled}
                  />
                </div>
                <div>
                  {enableItemRemoval && (
                    <Icon color="#89919d" name="close" onClick={() => values.dedicatedNumbersEnabled && remove(i)} />
                  )}
                </div>
              </CampaignNumbersTableRow>
            );
          })}
          <div className="button-wrapper">
            <AnotherPurposeButton
              onClick={() => push(DEFAULT_DEDICATED_NUMBER_ITEM)}
              disabled={!values.dedicatedNumbersEnabled}
              display={values.dedicatedNumbersEnabled}
            />
          </div>
        </>
      )}
    </FieldArray>
  );
};
