import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import PromptModal from 'components/common/PromptModal';
import { Button, Modal } from 'components/lib';
import { STATUSES } from 'constants';
import { capitalize } from 'utils/helpers';
import { SettingsContext } from '../SettingsContext';
import { CommunitiesGroupsContext, CommunitiesGroupsCreateModal } from '.';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px 20px 30px;
  border-bottom: 1px solid var(--line-standard);
  h2 {
    font-family: var(--font-family-bold);
    font-size: 60px;
    line-height: 0.97;
    color: var(--text);
  }
`;

export const CommunitiesGroupsHeader = () => {
  const { t } = useTranslation();
  const {
    confirmDeleteOpen,
    handelModalOpen,
    handleConfirmDeleteClose,
    handleGroupDelete,
    handleModalClose,
    loaderStatus,
    modalOpen,
  } = useContext(CommunitiesGroupsContext);
  const { communities } = useContext(SettingsContext);

  return (
    <Container>
      <h2>{capitalize(t('community'))} Groups</h2>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        closeIcon
        size="tiny"
        trigger={
          <Button primary color="blue" onClick={handelModalOpen} disabled={communities.length === 0}>
            Create a Group
          </Button>
        }
        content={<CommunitiesGroupsCreateModal onClose={handleModalClose} />}
      />
      <PromptModal
        open={confirmDeleteOpen}
        title="Are you sure you want to delete this group?"
        description="Deleting this groups will unassign all communities and remove it. It will also no longer appear in any Groups filter on various screens."
        loading={loaderStatus === STATUSES.LOADING}
        onConfirm={handleGroupDelete}
        onClose={handleConfirmDeleteClose}
        actionLabel="Delete"
      />
    </Container>
  );
};
