import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';

export const ChatSettingsContext = createContext();

const initialState = {
  chat: null,
};

const SET_CHAT = 'SET_CHAT';
export const ACTIONS = {
  SET_CHAT,
};

const reducer = (state, action) => {
  const actions = {
    SET_CHAT: (state, { chat }) => {
      state.chat = chat;
    },
  };

  if (actions[action.type]) {
    actions[action.type](state, action.payload);
  } else {
    console.error('Unknown reducer type', action);
  }
};

export const ChatSettingsContextProvider = (props) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);

  return <ChatSettingsContext.Provider {...props} value={{ state, dispatch }} />;
};
