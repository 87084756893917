import { USER_TYPES } from '../../../constants';

export const advancedPages = (t) => [
  {
    title: 'Account',
    columnIndex: 0, // Belongs to the first column
    items: [
      {
        title: 'Profile',
        subTitle: `Edit your personal information.`,
        iconName: 'account_box',
        iconColor: 'white',
        iconWrapperBgColor: '#22CFD1',
        blankPagePath: '/my-profile',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.MEMBER, USER_TYPES.REGIONAL],
      },
      {
        title: `${t('community')} groups`,
        subTitle: `Group ${t('communities')} that have common properties together.`,
        iconName: 'local_offer',
        iconColor: 'white',
        iconWrapperBgColor: '#5f5fff',
        path: '/settings/community-groups',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.REGIONAL],
      },
    ],
  },
  {
    title: 'Contacts',
    columnIndex: 0, // Belongs to the first column
    items: [
      {
        title: 'SMS & email contacts',
        subTitle: 'Add email addresses and phone numbers for receiving most sales updates and alerts.',
        iconName: 'contact_mail',
        iconColor: 'white',
        iconWrapperBgColor: '#0ab776',
        path: '/settings/contact',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.MEMBER, USER_TYPES.REGIONAL],
      },
      {
        title: 'Email contact overrides',
        subTitle: 'Apply custom delivery rules for email notifications.',
        iconName: 'outgoing_mail',
        iconColor: 'white',
        iconWrapperBgColor: '#89919d',
        path: '/settings/contact-rules',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Job applicant email contacts',
        subTitle: 'Manage the email contacts who are notified regarding new job applicants.',
        iconName: 'work_outline',
        iconColor: 'white',
        iconWrapperBgColor: '#5f5fff',
        path: '/settings/job-applicant-emails',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.MEMBER, USER_TYPES.REGIONAL],
      },
    ],
  },
  {
    title: 'General',
    columnIndex: 0, // Belongs to the first column
    items: [
      {
        title: 'Users',
        subTitle: `Manage who can view and edit ${t('communities')}.`,
        iconName: 'person',
        iconColor: 'white',
        iconWrapperBgColor: '#275aff',
        path: '/settings/user-accounts',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'CRM',
        subTitle: `Manage CRM settings per ${t('community')}.`,
        iconName: 'fact_check',
        iconColor: 'white',
        iconWrapperBgColor: '#ff5b98',
        path: '/settings/crm',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Care types',
        subTitle: `Manage care levels that  ${t('communities')} offer.`,
        iconName: 'elderly_woman',
        iconColor: 'white',
        iconWrapperBgColor: '#fa7550',
        path: '/settings/care-types',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Pricing',
        subTitle: `Edit pricing per care level for each ${t('community')}.`,
        iconName: 'paid',
        iconColor: 'white',
        iconWrapperBgColor: '#0ab776',
        path: '/settings/pricing',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.MEMBER, USER_TYPES.REGIONAL],
      },
      {
        title: 'Discounts',
        subTitle: 'Define discounts for the available pricing options.',
        iconName: 'discount',
        iconColor: 'white',
        iconWrapperBgColor: '#22cfd1',
        path: '/settings/discount',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.MEMBER, USER_TYPES.REGIONAL],
      },
      {
        title: `${t('move_in')} upload`,
        subTitle: `Upload ${t('move_in')} data.`,
        iconName: 'cloud_upload',
        iconColor: 'white',
        iconWrapperBgColor: '#f6bb5f',
        path: '/settings/move-ins',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Amenities',
        subTitle: `Add amenities offered by each ${t('community')}.`,
        iconName: 'dry_cleaning',
        iconColor: 'white',
        iconVariant: 'outlined',
        iconWrapperBgColor: '#2791ff',
        path: '/advanced/amenities',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
    ],
  },
  {
    title: 'Further Products',
    columnIndex: 1,
    items: [
      {
        title: 'Web Assistant',
        subTitle: 'Manage settings for the Virtual Sales Assistant and the Conversational WebForm.',
        iconName: 'question_answer',
        iconColor: '#007aff',
        iconVariant: 'outlined',
        iconWrapperBoxShadow: '0 2px 4px 0 rgba(0, 122, 255, 0.2)',
        iconWrapperBgImage: 'linear-gradient(to bottom, #f7fbff, #ebf5ff)',
        path: '/advanced/web-assistant',
        noBodyPadding: true,
        subPaths: [
          '/email-phone-requirements',
          '/urls',
          '/phone-input-warning',
          '/vsa-minimizing-on-mobile',
          '/vsa-banner',
        ],
        hideSubtitle: true,
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Phone Assistant',
        subTitle: 'Manage settings and activate and assign numbers for Phone Assistant.',
        iconName: 'call',
        iconColor: '#0ab776',
        iconWrapperBgColor: '#defff3',
        iconWrapperBoxShadow: '0 2px 4px 0 #dbeae4',
        path: '/advanced/phone-assistant',
        noBodyPadding: true,
        subPaths: ['/call-tracking', '/transcripts', '/voice', '/mobile-display', '/call-whisper', '/answer-timeouts'],
        hideSubtitle: true,
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Messaging Assistant',
        subTitle: 'Manage settings and set availability for the Messaging Assistant.',
        iconName: 'support_agent',
        iconColor: '#f6bb5f',
        iconWrapperBgColor: '#fff4d4',
        iconWrapperBoxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
        path: '/advanced/messaging-assistant',
        noBodyPadding: true,
        subPaths: ['/availability', '/automated-answers', '/budget-qualification'],
        hideSubtitle: true,
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Instant Answers',
        subTitle: 'Define replies to common questions entered into the Virtual Sales Assistant.',
        iconName: 'switch_access_shortcut',
        iconColor: '#ff5b98',
        iconWrapperBgColor: '#ffebf5',
        iconWrapperBoxShadow: '0 2px 4px 0 rgba(255, 91, 152, 0.3)',
        iconRotate: 'rotate(-90deg) scaleX(-1)',
        path: '/settings/ia',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR, USER_TYPES.REGIONAL],
      },
      {
        title: 'Web Automated Answers',
        beta: true,
        subTitle: 'Manage settings for the next generation of automated answers.',
        iconName: 'switch_access_shortcut',
        iconColor: '#5f5fff',
        iconWrapperBgColor: '#f0f0ff',
        iconWrapperBoxShadow: '0 2px 4px 0 rgba(92, 95, 255, 0.2);',
        iconRotate: 'rotate(-90deg) scaleX(-1)',
        path: '/advanced/instant-answers',
        allowedTypes: [USER_TYPES.STAFF],
        noBodyPadding: true,
        hideSubtitle: true,
        subPaths: ['/availability', '/automated-answers'],
      },
      {
        title: 'Webform Listener',
        subTitle: 'Choose whether to capture webforms.',
        iconName: 'rule',
        iconColor: '#89919d',
        iconWrapperBgColor: '#e8ecf2',
        iconWrapperBoxShadow: '0 2px 4px 0 #d7dce5',
        path: '/advanced/capture-webforms',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Marketing Email',
        subTitle: 'Integrate email addresses from which campaigns and messages are sent.',
        iconName: 'outgoing_mail',
        iconColor: '#2791ff',
        iconWrapperBoxShadow: '0 2px 4px 0 #d7dce5',
        allowedTypes: [USER_TYPES.REGULAR],
        iconWrapperBgImage: 'linear-gradient(to bottom, #f7fbff, #ebf5ff);',
        path: '/advanced/marketing-email',
        subPaths: ['/email-addresses', '/community-assignment'],
        noBodyPadding: true,
        hideSubtitle: true,
      },
      {
        title: 'Outbound Calls',
        subTitle: 'Activate the ability for salespeople to call prospects from the dashboard.',
        iconName: 'add_ic_call',
        iconColor: '#5f5fff',
        iconWrapperBgImage: 'linear-gradient(to bottom, #f9f9ff, #f0f0ff);',
        iconWrapperBoxShadow: ' 0 2px 4px 0 rgba(92, 95, 255, 0.2)',
        path: '/advanced/outbound-calls',
        noBodyPadding: true,
        subPaths: ['/calls', '/call-recording-whisper', '/pre-call-sms'],
        hideSubtitle: true,
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
    ],
  },
  {
    title: `Qualifying ${t('leads')}`,
    columnIndex: 0, // Belongs to the first column
    items: [
      {
        title: `Unqualified ${t('lead')} filter`,
        subTitle: `Pick the filter used to mark ${t('leads')} as unqualified.`,
        iconName: 'block',
        iconColor: 'white',
        iconWrapperBgColor: '#89919d',
        path: '/advanced/unqualified-lead-filter',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: `Sending unqualified ${t('leads')} to third party financial providers`,
        subTitle: `Choose whether unqualified ${t('leads')} are sent to third party financial providers.`,
        iconName: 'volunteer_activism',
        iconColor: 'white',
        iconWrapperBgColor: '#ff5b98',
        path: '/advanced/send-unqualified-leads',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
    ],
  },
  {
    title: 'Analytics',
    columnIndex: 1,
    items: [
      {
        title: 'Participate in Benchmark data comparison',
        subTitle: `Choose if ${t('community')} performance is anonymously measured against other portfolios.`,
        iconName: 'stacked_bar_chart',
        iconColor: 'white',
        iconWrapperBgColor: '#2791ff',
        path: '/advanced/participate-in-benchmark',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Custom traffic source mapping',
        subTitle: 'Tailor the display of traffic source data based on specific criteria.',
        iconName: 'account_tree',
        iconColor: 'white',
        iconWrapperBgColor: '#f6bb5f',
        path: '/settings/analytics',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
    ],
  },
  {
    title: 'Emails',
    columnIndex: 0, // Belongs to the first column
    items: [
      {
        title: `Notifications about each new ${t('lead')}`,
        subTitle: `Choose if an email is sent each time a new ${t('lead')} is created.`,
        iconName: 'mark_email_read',
        iconColor: 'white',
        iconWrapperBgColor: '#22cfd1',
        path: '/advanced/send-notification-emails',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: `Notify when a ${t('lead')} returns to the website`,
        subTitle: `Choose if an email is sent when a ${t('lead')} returns to the website at a later date.`,
        iconName: 'ads_click',
        iconColor: 'white',
        iconWrapperBgColor: '#5f5fff',
        path: '/advanced/leads-return',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'FAQs in Emails',
        subTitle: `Choose whether to include FAQs at the bottom of ${t('lead')} emails.`,
        iconName: 'quiz',
        iconColor: 'white',
        iconWrapperBgColor: '#2791ff',
        path: '/advanced/faq',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: `Indicator of high chance to ${t('move_in')}`,
        subTitle: `Choose whether to show that the ${t('lead')} is likely to ${t('move_in')} in emails.`,
        iconName: 'auto_awesome',
        iconColor: 'white',
        iconWrapperBgColor: '#ff5b98',
        path: '/advanced/movein-likelihood',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Conversation insights in emails',
        subTitle: 'Choose whether to show data such as engagement, time spent and more in emails.',
        iconName: 'auto_graph',
        iconColor: 'white',
        iconWrapperBgColor: '#89919d',
        path: '/advanced/conversion-insights',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Custom note in emails',
        subTitle: `Include a custom note directed to ${t('community')} users receiving the email.`,
        iconName: 'edit_note',
        iconColor: 'white',
        iconWrapperBgColor: '#275aff',
        path: '/advanced/additional-email-note',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
    ],
  },
  {
    title: `${t('tour')} Scheduling`,
    columnIndex: 1,
    items: [
      {
        title: `${t('tour')} availability`,
        subTitle: `Adjust available days and working hours for ${t('tours')}.`,
        iconName: 'calendar_month',
        iconColor: 'white',
        iconWrapperBgColor: '#fa7550',
        path: '/advanced/tour-availability',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
      {
        title: 'Connected calendars',
        subTitle: 'Choose whether to connect individual calendars.',
        iconName: 'schedule_send',
        iconColor: 'white',
        iconWrapperBgColor: '#22cfd1',
        path: '/advanced/connected-calendars',
        allowedTypes: [USER_TYPES.STAFF, USER_TYPES.REGULAR],
      },
    ],
  },
];
