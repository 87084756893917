import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { Formik } from 'formik';

import * as Yup from 'yup';
import { PricingTable } from './PricingTable';

const PRICING_COLUMN_ORDER = [
  'Independent Living',
  'Assisted Living',
  'Memory Care',
  'Personal Care',
  'Memory Care (all inclusive)',
];

const isPriceEmpty = (price) => !price || price === 0 || price === '0';

export const PricingForm = ({ communities, formRef, onSubmit, tableHeaderOffset }) => {
  const [hasStartingEndingCost, setHasStartingEndingCost] = useState();
  const pricingColumns = [
    ...new Set(communities?.flatMap((community) => community.prices?.map((price) => price?.name))),
  ].sort((a, b) => {
    const aIndex = PRICING_COLUMN_ORDER.indexOf(a);
    const bIndex = PRICING_COLUMN_ORDER.indexOf(b);

    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }
    if (aIndex !== -1) {
      return -1;
    }
    if (bIndex !== -1) {
      return 1;
    }
    return 0; // Preserve the original order
  });

  const initialValues = {
    communities: communities?.map((community) => ({
      ...community,
      starting_cost: community.starting_cost || 0,
      chats: community.chat_ids,
    })),
    pricingColumns,
    prices: communities?.map((community) => {
      const pricesDict = {};
      if (community) {
        community.prices.forEach((price) => {
          pricesDict[price.name] = {
            ...price,
            community: community.id,
          };
        });
      }
      return pricesDict;
    }),
  };

  // For prices matrix generate dynamic validation fields based on available pricing columns
  const validationSchema = Yup.object({
    prices: Yup.array().of(
      Yup.object(
        pricingColumns.reduce(
          (accumulator, current) => ({
            ...accumulator,
            [current]: Yup.object({
              cost: Yup.number().min(0, 'Must be a positive number or zero'),
            }),
          }),
          {}
        )
      )
    ),
    communities: Yup.array().of(
      Yup.object({
        starting_cost: Yup.number().min(0, 'Must be a positive number or zero'),
      })
    ),
  });

  useEffect(() => {
    if (communities && communities.length > 0) {
      setHasStartingEndingCost(communities.some((c) => !isPriceEmpty(c.starting_cost) || !isPriceEmpty(c.ending_cost)));
    }
  }, [communities]);

  const onFormSubmit = (values) => {
    if (typeof onSubmit === 'function') {
      onSubmit(values);
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
      enableReinitialize
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <PricingTable
            values={values}
            headerOffset={tableHeaderOffset}
            hasStartingEndingCost={hasStartingEndingCost}
          />
        </Form>
      )}
    </Formik>
  );
};
