import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ConversationsContext } from './ConversationsContext';
import { Icon, Table } from '../lib';
import { ORDERING } from './constants';
import { If } from '../If';

const LeftPaddedContent = styled.div`
  padding-left: 20px;
`;

const CellWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100px;

  span {
    line-height: 26px;
  }
`;

const StyledIcon = styled(Icon)`
  margin: 0 4px;
`;

export const ConversationsTableHeader = () => {
  const { t } = useTranslation();
  const { filters, handleFiltersChange } = useContext(ConversationsContext);
  const [currentOrdering, setCurrentOrdering] = useState(filters.ordering);

  useEffect(() => {
    setCurrentOrdering(filters.ordering);
  }, [filters]);

  const onChangeOrdering = (ordering) => {
    handleFiltersChange({ ordering });
  };

  const createdOrderingActive = [ORDERING.DESC_CREATED_ON, ORDERING.ASC_CREATED_ON].includes(currentOrdering);
  const nextCreatedOrdering =
    currentOrdering === ORDERING.ASC_CREATED_ON ? ORDERING.DESC_CREATED_ON : ORDERING.ASC_CREATED_ON;

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <LeftPaddedContent>{t('lead')}</LeftPaddedContent>
        </Table.HeaderCell>
        <Table.HeaderCell>Contact</Table.HeaderCell>
        <Table.HeaderCell>{t('community')}</Table.HeaderCell>
        <Table.HeaderCell>Source</Table.HeaderCell>
        <Table.HeaderCell style={{ minWidth: '130px' }}>Status</Table.HeaderCell>
        <Table.HeaderCell onClick={() => onChangeOrdering(nextCreatedOrdering)}>
          <CellWrapper>
            <span>Created</span>
            <If condition={createdOrderingActive}>
              <StyledIcon
                name={currentOrdering === ORDERING.DESC_CREATED_ON ? 'expand_more' : 'expand_less'}
                size="26"
              />
            </If>
          </CellWrapper>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};
