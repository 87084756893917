import React, { useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

const PopoverItems = styled.ul`
  max-height: 30vh;
  width: max-content;
  max-width: 50vw;
  overflow: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding: 5px;
  }
`;

export const ItemsPopover = ({ options, selected, onChangeValue }) => {
  const [checked, setChecked] = useState([]);

  const onChange = (e, data) => {
    const temp = { ...checked, [data.id]: !checked[data.id] };
    setChecked(temp);
    onChangeValue(temp);
  };

  useEffect(() => {
    const object = {};
    selected.forEach((s) => {
      object[s] = true;
    });
    setChecked(object);
  }, [selected]);

  const items = options.filter((c) => selected.includes(c.id));

  return (
    <PopoverItems>
      {items.slice(2).map((s) => (
        <li key={s.id}>
          <Checkbox id={s.id} label={s.name} checked={checked[s.id]} onChange={onChange} />
        </li>
      ))}
    </PopoverItems>
  );
};
