import React, { useEffect, useState, useMemo } from 'react';
import { Table } from 'semantic-ui-react';
import * as Yup from 'yup';
import styled from 'styled-components';

import { InputField } from '../../common/fields/InputField';
import { CsvField } from '../../CsvField';
import { scrollIntoView } from '../../utils';
import { StickyTableHeader } from '../../common/PricingTable';
import { STICKY_OFFSETS } from '../../../constants';
import { CsvExport } from '../../common/CsvExport';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const validationSchema = Yup.object({
  lead_email: Yup.string().email(),
  other_lead_emails: Yup.array().of(Yup.string().email()),
  sales_phones: Yup.array().of(Yup.string().max(20)),
  sales_first_name: Yup.string().nullable(),
});

const Row = React.memo(({ community, index, isTableFooter }) => {
  const emailValidator = Yup.string().email('Must be a valid email address or a list of valid email addresses');
  const phoneNumberValidator = Yup.string().max(20, "Individual phone numbers can't be longer than 20 characters");
  const Cell = isTableFooter ? Table.HeaderCell : Table.Cell;

  const [isDataValid, setIsDataValid] = useState(true);

  useEffect(() => {
    const checkData = async () => {
      const response = await validationSchema.isValid({
        lead_email: community.lead_email,
        other_lead_emails: community.other_lead_emails,
        sales_phones: community.sales_phones,
        sales_first_name: community.sales_first_name,
      });

      setIsDataValid(response);
    };
    checkData();
  }, [community]);

  return (
    <Table.Row negative={!isDataValid} className="ui form">
      <Cell>{community.name}</Cell>
      <Cell>
        <InputField
          name={`communities[${index}].sales_first_name`}
          placeholder="Main Contact First Name"
          onError={scrollIntoView}
          fluid
        />
      </Cell>
      <Cell>
        <InputField
          name={`communities[${index}].lead_email`}
          placeholder="Main Contact"
          onError={scrollIntoView}
          fluid
        />
      </Cell>
      <Cell>
        <CsvField
          name={`communities[${index}].other_lead_emails`}
          placeholder="Secondary Contacts"
          csvElementValidator={emailValidator}
          onError={scrollIntoView}
        />
      </Cell>
      <Cell>
        <CsvField
          name={`communities[${index}].sales_phones`}
          placeholder="Sales Phone Numbers"
          csvElementValidator={phoneNumberValidator}
          onError={scrollIntoView}
        />
      </Cell>
    </Table.Row>
  );
});

export const ContactInfoTable = ({ values, isTableFooter, headerOffset = STICKY_OFFSETS.NO_OFFSET }) => {
  const [csvHeaders, csvContent] = useMemo(() => {
    const headers = [`Location`, 'Main Contact First Name', 'Main Contact Info', 'Secondary Contacts', 'Phone Numbers'];

    const content = values?.communities.map((c) => [
      c.name || '',
      c.sales_first_name || '',
      c.lead_email || '',
      c.other_lead_emails?.join(',') || '',
      c.sales_phones?.join(',') || '',
    ]);

    return [headers, content];
  }, [values]);
  return (
    <Table fixed>
      <StickyTableHeader offset={headerOffset}>
        <Table.Row>
          <Table.HeaderCell width={4}>Location</Table.HeaderCell>
          <Table.HeaderCell width={4}>Main Contact First Name</Table.HeaderCell>
          <Table.HeaderCell width={4}>Main Contact Info</Table.HeaderCell>
          <Table.HeaderCell width={8}>Secondary Contacts</Table.HeaderCell>
          <Table.HeaderCell width={8}>
            <Wrapper>
              Phone Numbers
              <CsvExport data={csvContent} headers={csvHeaders} filename="contact_info" />
            </Wrapper>
          </Table.HeaderCell>
        </Table.Row>
      </StickyTableHeader>

      <Table.Body>
        {values.communities &&
          values.communities.map((community, i) => (
            <Row key={i} community={community} index={i} isTableFooter={isTableFooter} />
          ))}
      </Table.Body>
    </Table>
  );
};
