import React from 'react';
import styled from 'styled-components';

import { FilterDropdown } from 'components/lib';
import { RATING_OPTIONS, SHOW_ON_VSA_STATUS_OPTIONS } from '../constants';
import { MEDIA_BREAK_POINTS } from '../../../constants';

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  @media only screen and (max-width: ${MEDIA_BREAK_POINTS.MOBILE}px) {
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FilterDropwdownWrapper = styled.div`
  width: 200px;
  .ui.selection.dropdown {
    min-width: 14rem;
  }
`;

const OrderDropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const UserCommunityTestimonialsFilters = ({ filters, onChange }) => {
  const handleShowOnVSA = (e, { value }) => {
    onChange({ show_on_embedded_vsa: value });
  };

  const handleChangeSelectedRating = (e, { value }) => {
    onChange({ ordering: value });
  };

  return (
    <FiltersWrapper>
      <FilterDropwdownWrapper>
        <FilterDropdown
          name="Show on VSA"
          selection
          value={filters.show_on_embedded_vsa}
          options={SHOW_ON_VSA_STATUS_OPTIONS}
          onChange={handleShowOnVSA}
        />
      </FilterDropwdownWrapper>

      <OrderDropdownWrapper>
        <div>Sort By:</div>
        <FilterDropwdownWrapper>
          <FilterDropdown
            name="Rating"
            selection
            value={filters.ordering}
            options={RATING_OPTIONS}
            onChange={handleChangeSelectedRating}
          />
        </FilterDropwdownWrapper>
      </OrderDropdownWrapper>
    </FiltersWrapper>
  );
};

export default UserCommunityTestimonialsFilters;
