import React from 'react';
import styled from 'styled-components';

import FailureMessage from 'components/common/FailureMessage';
import { Icon, Message, Table } from 'components/lib';
import { STATUSES } from '../../../constants';
import { BusinessActivityAction } from './BusinessActivityAction';
import { BusinessActivityAuthor } from './BusinessActivityAuthor';
import { BusinessActivityDateTime } from './BusinessActivityDateTime';
import { BusinessActivityChanges } from './BusinessActivityChanges';
import { ORDER_BY } from './constants';

const DropdownBox = styled.div`
  display: none;
`;

const StyledTableRow = styled(Table.Row)`
  &:hover {
    ${DropdownBox} {
      display: inline;
    }
  }
`;

export const BusinessActivityTable = ({
  businessActivities,
  loader,
  error,
  filters,
  totalPages,
  orderingColumn,
  onPageChange,
  onChangeOrdering,
  onViewBusinessActivity,
}) => {
  if (loader === STATUSES.FAILURE) {
    return <FailureMessage content={error} />;
  }

  if (!businessActivities) {
    return null;
  }

  if (businessActivities.length === 0) {
    return (
      <Message warning icon>
        <Icon name="feedback" size="40" variant="outlined" />
        <Message.Content>
          <Message.Header>No business activities!</Message.Header>
          <p>Please change filters.</p>
        </Message.Content>
      </Message>
    );
  }

  const { page } = filters;

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Author</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
          <Table.HeaderCell>Old value</Table.HeaderCell>
          <Table.HeaderCell>New value</Table.HeaderCell>
          <Table.HeaderCell
            sortable
            sorted={orderingColumn.name === ORDER_BY.CREATED_ON ? orderingColumn.order : null}
            onClick={onChangeOrdering(ORDER_BY.CREATED_ON)}
          >
            Date
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {businessActivities.map((be) => (
          <StyledTableRow key={be.id}>
            <Table.Cell>
              <BusinessActivityAuthor author={be.author} owner={be.owner} source={be.changes?.source} />
            </Table.Cell>
            <Table.Cell>
              <BusinessActivityAction actionType={be.actionType} contentObjectName={be.contentObjectName} />
            </Table.Cell>
            <Table.Cell>
              <BusinessActivityChanges id={be.id} actionType={be.actionType} changes={be.changes} showOldValue />
            </Table.Cell>
            <Table.Cell>
              <BusinessActivityChanges
                id={be.id}
                actionType={be.actionType}
                changes={be.changes}
                onViewBusinessActivity={onViewBusinessActivity}
              />
            </Table.Cell>
            <Table.Cell width={1}>
              <BusinessActivityDateTime date={be.createdOn} />
            </Table.Cell>
          </StyledTableRow>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan={6}>
            {totalPages > 1 && <Table.Pagination page={page} totalPages={totalPages} onPageChange={onPageChange} />}
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};
